.carousen-container {
    line-height: 0; }

section.screwed-slider {
    max-width: 1440px;
    margin: 76px auto 0;
    padding-bottom: 192px;
    &.small-screen {
        padding-top: 0px;
        padding-bottom: 122px;
        a.shop-carousel-item .item-bg {
            height: 300px;
            padding-left: 172px;
            h2 {
                font-size: 28px; }
            h5 {
                font-size: 20px;
                font-weight: 400; }
            .main-img {
                img {
                    height: 290px; } }
            .slick-dots {
                top: 248px; } }
        .shop-carousel.is-prev .item-bg .main-img img {
            top: 30px; }
        .shop-carousel.is-next .item-bg .main-img img {
            top: 30px; }
        .dot-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                margin-right: 18px; } } }
    .is-main {
        .slick-dots {
            top: 280px;
            position: absolute; } }

    .slick-slider {
        button {
            cursor: pointer; } }

    .shop-carousel {
        max-height: 320px; }

    .carousen-container {
        z-index: 0;
        text-rendering: geometricPrecision;

        .slick-slide {
            padding-right: 86px; }

        &.is-prev {
            .slick-track {
                left: 36px; } }

        &.is-next {
            .slick-track {
                left: -82px; } } }

    .center-carousen {
        z-index: 1;

        .slick-list {
            margin: 0 100px;
            overflow: visible; } }

    .rectangle {
        position: absolute;
        right: 0;
        left: 0;
        margin: 0 auto;
        filter: drop-shadow(0 18px 36px rgba(75, 88, 105, 0.18)); }

    .slick-arrow.slick-prev {
        left: -36%; }

    .slick-arrow.slick-next {
        right: -36%; }

    a.shop-carousel-item {
        position: relative;
        margin-left: -46px;

        .item-bg {
            margin: 0 auto;
            text-align: left;
            height: 320px;
            position: relative;
            max-width: 1030px;
            padding-top: 36px;
            padding-left: 148px;

            svg {
                position: absolute;
                right: -88px;
                top: 0; }

            h2 {
                margin-bottom: 18px;
                padding-right: 30%;
                z-index: 1;
                position: relative; }

            .subtitle {
                display: flex;
                align-items: flex-start;
                padding-right: 30%;
                z-index: 1;
                position: relative;

                h5 {
                    margin: 0; } }

            button {
                position: absolute;
                bottom: 14px;
                z-index: 1; }

            .main-img {
                z-index: 1;
                position: absolute;
                right: -40px;
                top: initial;
                bottom: 0;

                img {
                    height: 25vw;
                    max-height: 310px; } } }

        @media screen and (min-width: 960px) and (max-width: 1300px) {
            .item-bg {
                h2 {
                    font-size: 24px;
                    line-height: 1.5;
                    margin-bottom: 6px;
                    margin-left: calc( -100vw / 30);
                    padding-right: 54%; }

                h5 {
                    font-size: 18px; }

                .subtitle {
                    margin-left: -6%;
                    padding-right: 47%;
                    img {
                        display: none; } }
                button {
                    min-width: initial; } } } }

    .skew-bg {
        width: 224px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1;

 }        // transform: translateZ(0) skewX(12deg)

    .slick-dots {
        max-width: 1170px;
        padding: 0;
        margin: 64px auto 0;
        display: flex !important;
        justify-content: space-between;
        position: absolute;
        left: 0;
        right: 0;
        top: 316px;
        position: absolute;
        padding: 0;

        // transform: skewX(-12deg)
        li {
            width: 100%;
            background: none;
            margin: 0;

            &.slick-active {
                background: none;

                .bar {
                    display: none; }

                .bar-active {
                    width: 100%;
                    display: flex;
                    justify-content: center;

                    .triangle {
                        width: 0;
                        height: 0;
                        border-left: 10px solid transparent;
                        border-right: 10px solid transparent;
                        top: -10px;
                        position: relative; } } }

            a {
                width: 100%;

                .bar {
                    width: 100%;
                    height: 4px;
                    background-color: #ebebeb;
                    margin-top: 16px;
                    display: block; }

                .bar-active {
                    width: 0;
                    height: 4px;
                    margin-top: 16px; }

                h6 {
                    margin: 0; }

                img {
                    width: 56px;
                    height: 56px;
                    margin-bottom: 12px;
                    border-radius: 50%; } } } }

    .shop-carousel.is-prev {
        svg {
            position: absolute;
            left: -16px;
            top: 80px;
            z-index: -1; }

        .item-bg {
            p {
                padding-left: 20px;
                color: $white;
                font-weight: 500; }

            .main-img {
                position: relative;
                min-width: 86px;

                img {
                    max-height: 100px;
                    position: absolute;
                    right: -56px;
                    top: 20px; } } } }

    .shop-carousel.is-next {
        svg {
            position: absolute;
            right: -16px;
            top: 80px;
            z-index: -1; }

        .item-bg {
            p {
                padding-right: 20px;
                color: $white;
                font-weight: 500; }

            .main-img {
                position: relative;
                min-width: 86px;

                img {
                    max-height: 100px;
                    position: absolute;
                    right: -56px;
                    top: 20px; } } } }

    .shop-carousel.is-prev, .shop-carousel.is-next {
        box-shadow: none;
        background: none;

        .slick-list {
            overflow: visible;

            .slick-slide {
                padding: 0;

                > div {
                    padding: 0; } } } } }
@media screen and (min-width: 960px) and (max-width: 1300px) {
    section.screwed-slider {
        padding-top: 30px; } }
@media screen and (max-width: 960px) {
    section.screwed-slider {
        margin: 0;
        padding: 0 0 150px 0;
        .is-main {
 }            // box-shadow: 0 18px 36px 0 rgba(75, 88, 105, 0.24)
        .carousen-container {
            display: none; }

        .center-carousen {
            display: block;

            .slick-list {
                margin: 0;

 }                // box-shadow: 0 18px 36px 0 rgba(75, 88, 105, 0.24)

            .rectangle {
                display: none; } }

        a.shop-carousel-item {
            .item-bg {
                max-width: initial;
                padding: 36px 60px;
                margin: 0;
                background: $white;

                svg {
                    right: -220px; }

                .main-img {
                    right: -100px;

                    img {
                        height: 100%; } } } } } }

@media screen and (max-width: 600px) {
    section.screwed-slider {
        margin: 0;
        padding: 0 0 150px 0;

        a.shop-carousel-item .item-bg {
            .main-img {
                top: initial;
                bottom: 0;
                right: -70px;

                img {
                    height: 220px; } }

            h2 {
                margin-bottom: 6px;
                padding-right: 36px; }

            .subtitle {
                padding-right: 36px;

                img {
                    display: none; }

                h5 {
                    font-size: 18px;
                    line-height: 1.5;
                    font-weight: 400; } } }

        .carousen-container .slick-slide {
            padding-right: 0; }

        .is-main {
            .slick-dots {
                padding: 0 15px;
                top: 286px; } } } }

section.fui-banner.is-solution {
    padding: 0;
    position: relative;
    min-height: 0;
    height: 450px;
    overflow: hidden;
    z-index: 0;

    img {
        position: absolute;
        width: 100%;
        height: auto;
        z-index: 0;
        left: -100%;
        right: -100%;
        top: -100%;
        bottom: -100%;
        margin: auto; }

    .caption {
        position: absolute;
        width: 100%;
        color: $white;
        bottom: $space * 8.5;
        height: 270px;
        z-index: 7;
        top: 106px;

        .fui-container {
            padding: 0 ($space * 2); }

        h1 {
            font-size: 28px;
            line-height: 42px;
            max-width: 700px; }

        .with-quote::before {
            content: "";
            float: left;
            width: 100%;
            height: 36px;
            background: url(#{$publicURL}/resources/common/images/quotation.png) no-repeat left top;
            background-size: 36px;
            margin-bottom: $space * 1.5; } }

    @media screen and (max-width: 959px) and (min-width: 600px) {
        img {
            height: 100%;
            width: auto; } }

    @media screen and (min-width: 960px) {
        .caption {
            top: 120px;

            h1 {
                font-size: 42px;
                line-height: 63px; } } }

    @media screen and (min-width: 960px) and (max-width: 1400px) {
        img {
            width: auto;
            height: 100%; } }

    @media screen and (min-width: 1400px) {
        img {
            width: auto;
            height: 100%; } } }

.horizontal-cards.slick-slider {
    position: relative;
    margin: 36px 0 30px;
    .fui-card-subtitle {
        color: $gray100; }

    &.rounded-arrow {
        .slick-arrow.slick-prev, .slick-arrow.slick-next {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            background-color: $white;
            box-shadow: 0 18px 36px 0 rgba(75, 88, 105, 0.24);
            top: 180px;
            z-index: 1; }

        .slick-arrow.slick-prev {
            left: -25px; }

        .slick-arrow.slick-next {
            right: -25px; }

        .slick-disabled {
            display: none !important; }

        button::after {
            font-size: 28px !important; } }

    .fui-card.is-card-program {
        padding: 30px 20px;
        background-color: $white;
        display: flex !important;
        flex-direction: column;
        align-items: center;
        position: relative;

        .tag {
            position: absolute;
            left: 0;
            top: 0;

            // background-color: $coral100
            color: $white;
            font-size: 14px;
            padding: 4px 12px;

            &::after {
                // border-top: 28px solid $coral100
                content: "";
                width: 0;
                height: 0;
                border-right: 28px solid transparent;
                position: absolute;
                right: -28px;
                bottom: 0; } }

        .tag-red {
            background-color: $coral100;

            &::after {
                border-top: 28px solid $coral100;
                top: 0px; } }

        .tag-blue {
            background-color: $blue50;

            &::after {
                border-top: 28px solid $blue50;
                top: 0px; } }

        .tag-gray {
            background-color: #eff4f1;
            color: $black50;

            &::after {
                border-top: 28px solid #eff4f1;
                top: 0px; } }

        img {
            margin-top: 34px;
            margin-bottom: 30px;
            width: 100%; }

        ul {
            list-style: none;
            padding-left: 0;

            li {
                display: flex;
                align-items: center; }

            i {
                margin-right: 10px; } }

        .form-group {
            padding: 0 30px;
            left: 0;
            right: 0;
            align-items: center;

            &.two-buttons {
                margin-bottom: 0;
                width: 100%;
                display: flex;
                flex-direction: row;
                margin-top: 20px;
                left: 0;

                a.fui-button {
                    width: 100%;
                    min-width: initial;
                    margin-bottom: 0;
                    margin-right: 12px; }

                a:nth-last-child(1) {
                    margin-right: 0; } } } }

    .slick-arrow {
        cursor: pointer;

        &.slick-prev,
        &.slick-next {
            position: absolute;
            right: 0;
            top: -66px;
            border: 0;
            background: none;
            width: 36px;
            height: 36px;
            color: transparent;

            &.slick-disabled::after {
                color: $gray50; }

            &::after {
                position: absolute;
                font-family: "icomoon" !important;
                speak: none;
                color: $black50;
                width: 28px;
                height: 28px;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
                font-size: 28px;
                font-style: normal;
                font-weight: normal;
                font-variant: normal;
                text-transform: none;
                line-height: 1; } }

        &.slick-prev {
            right: 40px;

            &::after {
                content: ""; } }

        &.slick-next {
            &::after {
                content: ""; } } }

    .slick-list {
        overflow: visible;
        margin: 0 - $space;

        .slick-track {
            display: flex; }

        .slick-slide {
            float: none;
            display: flex;
            outline: 0;
            padding: 0 6px;
            height: auto;
            width: 302px;

            > div {
                display: flex;
                flex: 1 1 0; }

            .fui-card {
                display: flex;
                min-width: 0;
                width: 100%;
                margin: 0;

                .fui-card-description {
                    -webkit-line-clamp: 3;
                    height: auto; } } }
        @media screen and (max-width: 960px) and (min-width: 768px) {
            .slick-slide {
                width: calc( 100vw/3 - 4px ); } } }

    @media screen and (min-width: 768px) {
        .slick-arrow.slick-prev {
            right: 66px; } } }

.shop-carousel {
    &.is-prev,
    &.is-next {
        display: none; }

    .slick-arrow {
        position: absolute;
        width: 50%;
        height: 100%;
        top: 0;
        opacity: 0;
        display: block;
        z-index: 2;

        &.slick-prev {
            left: -50%; }

        &.slick-next {
            right: -50%; } }

    .shop-carousel-item {
        outline: none;

        > div {
            display: block;
            text-align: center;

            img.logo {
                margin: auto; } }

        .shop-name {
            margin: ($space * 3) 0 0;
            line-height: 1.75; } }

    &.is-main {
        margin-left: -15px;
        margin-right: -15px;
        z-index: 9;
        background-color: $navy50;
        background-image: url(#{$publicURL}/resources/common/images/patternd-blue-shop.png);
        background-repeat: no-repeat;
        background-size: 100% auto;

        .slick-slide {
            > div {
                display: flex; } }

        .shop-carousel-item {
            background: none;
            display: flex !important;

            > div {
                color: white;
                padding: 30px 30px 85px;

                .logo {
                    width: auto;
                    max-height: 90px;

                    + h6 {
                        margin: 24px 0 12px; } }

                .shop-name {
                    margin-top: 12px; } } } }

    .slick-dots {
        position: absolute;
        display: block;
        text-align: center;
        list-style: none;
        width: 100%;
        bottom: $space * 5;
        margin: 0;
        padding: 0;

        li {
            display: inline-block;
            width: 40px;
            height: 3px;
            background: $black50;
            margin: 0 3px;

            &.slick-active {
                background: white; }

            button {
                opacity: 0; } } }

    @media screen and (min-width: 960px) {
        &.is-main {
            margin-left: 0;
            margin-right: 0;

            .shop-carousel-item {
                > div {
                    padding: ($space * 6) 30px 115px;

                    .logo {
                        width: auto;
                        max-height: 160px;

                        + h6 {
                            margin-top: $space * 2; } }

                    .shop-name {
                        margin: ($space * 3) 0 0; } } } }

        &.is-prev,
        &.is-next {
            display: block;
            height: 100%;
            box-shadow: 0 24px 48px 0 rgba(75, 88, 105, 0.12);
            background: #FFF;

            .slick-list {
                height: 100%;

                .slick-track {
                    display: flex;
                    height: 100%;

                    .slick-slide {
                        display: flex;

                        > div {
                            display: flex;
                            flex: 1 1 auto;
                            align-content: center;
                            align-items: center;
                            padding: 0 30px; } } } } } } }

.carousel-banner-4 {
    height: 680px;
    width: 100%;

    .fullscreen {
        position: fixed;
        right: 0;
        top: 0;
        z-index: 1000;
        width: 50px;
        height: 50px;
        background-color: #fff; }

    .slide {
        position: relative;
        height: 680px;
        width: 100%;

        .background {
            width: 100%;
            max-height: 680px;
            position: absolute;
            z-index: 0;
            height: 100%;
            top: 0;
            background-position: center;
            background-size: cover; }

        .background-mb {
            display: none;
            width: 100%;
            background-position: center;
            background-size: cover; }

        .img-reveal {
            position: absolute;

            // right: -20vw
            right: 0;
            top: 0;
            height: 100%;
            z-index: -1; } }

    .content-wrapper {
        padding: 30px; }

    .content {
        margin: 118px 0 0 0;
        z-index: 2;
        background: #fff;
        width: 100%;
        height: 443px;
        position: relative;
        display: flex;

        h1 {
            margin: 0 0 12px 0; }

        h5 {
            margin: 0;
            font-weight: normal; }

        .main-img {
            position: relative;
            width: 40%;
            overflow: hidden;
            order: 1;
            min-height: 443px;

            img {
                position: absolute;
                width: auto;
                height: 444px;
                left: -100%;
                right: -100%;
                top: -100%;
                bottom: -100%;
                margin: auto; } }

        .content-info {
            width: 60%;
            order: 0;
            padding: 48px 36px; }

        .head {
            color: $gray100;
            margin-bottom: 12px;
            font-weight: bold; }

        .content-text {
            height: 116px; }

        .social {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 14px;
            width: 100%;
            justify-content: flex-end;

            .view {
                color: $gray100;
                display: flex;
                align-items: center; }

            a {
                color: #000; }

            i {
                font-size: 30px;
                margin-left: 6px; } }

        .bottom {
            display: flex;
            height: 50px;
            align-items: center;

            a {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 50px; }

            button.fui-button.is-arrow,
            button.fui-button.is-arrow:hover,
            button.fui-button.is-arrow:active {
                color: transparent; }

            button:not(.fui-button.is-arrow) {
                background-color: #D3060F;

                // width: 220px
                display: flex;
                align-items: center;
                justify-content: center;
                height: 50px; }

            button.is-video {
                span {
                    opacity: 0; }

                &:hover {
                    span {
                        color: $accent;
                        opacity: 1; } } } } }

    .mb-img-reveal {
        display: none;
        cursor: pointer; }

    .left-triangle {
        width: 0;
        height: 0;
        border-bottom: 115px solid $navy30;
        border-right: 155px solid transparent;
        position: absolute;
        left: 15px;
        bottom: 0px;
        z-index: 1; }

    .right-triangle {
        width: 0;
        height: 0;
        border-top: 115px solid $navy30;
        border-left: 155px solid transparent;
        position: absolute;
        right: 15px;
        top: 120px;
        z-index: 1; }

    .slick-dots {
        position: absolute;
        bottom: 30px;
        z-index: 1;
        list-style: none;
        margin: 0 auto;
        padding: 0;
        left: 0;
        right: 0;
        text-align: center;

        .slick-active {
            button {
                background-color: #fff; } }

        li {
            display: inline-block;
            margin-right: 6px;

            &:nth-last-child(1) {
                margin-right: 0; }

            button {
                background-color: #000;
                cursor: pointer;
                width: 50px;
                height: 4px;
                border: 0;
                vertical-align: top; } } }

    .yt-wrapper {
        width: 100%;
        z-index: -10;
        position: absolute; } }

.slick-slider {
    .slick-dots {
        li {
            cursor: pointer; } } }

.carousel-banner-4 {
    .content {
        height: auto;

        .content-info {
            height: auto;

            h1 {
                @include ellipsis(2); }

            .content-text {
                height: auto;
                margin-bottom: 12px;

                @include ellipsis(3); } }

        .main-img {
            img {
                width: auto;
                height: 100%; } } } }

@media screen and (max-width: 960px) {
    .carousel-banner-4 {
        height: auto;

        .left-triangle, .right-triangle {
            display: none; }

        .background {
            display: none; }

        .content-wrapper {
            padding: 0; }

        .slide {
            height: 100%;
            padding-bottom: 94px;
            padding-top: 20px;
            margin-top: -20px;

            .position-relative {
                position: relative; }

            .main-img {
                display: none; }

            .mb-img {
                position: relative; }

            .content-info {
                width: 100%;
                padding: 0;

                h1 {
                    margin-bottom: 0; }

                h5 {
                    color: #5f6e84; } }

            .slick-dots {
                bottom: 40px; }

            .play {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                display: flex;
                align-items: center;
                justify-content: center;

                .round {
                    width: 60px;
                    height: 60px;
                    box-shadow: 0 11px 48px 0 #{"rgba(32, 32, 32, 0.3)"};
                    z-index: 2;
                    opacity: 0.84;
                    background-color: #fff;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    pointer-events: none;

                    &::after {
                        content: "";
                        width: 0;
                        height: 0;
                        border-style: solid;
                        margin-right: -6px;
                        border-width: 12px 0 12px 16px;
                        border-color: transparent transparent transparent #d30610; } } }

            .mb-img-reveal {
                width: 100%;
                z-index: 1;
                position: relative;
                margin-top: 30px;
                display: block; }

            .img-reveal {
                display: none; }

            .background-mb {
                display: block;
                position: absolute;
                top: 0;
                z-index: 0;
                height: 100%; } }

        .content {
            margin-top: 0;
            padding: 12px 12px 18px 12px;
            width: 100%;
            height: 282px;

            .content-text {
                display: none; }

            .head {
                font-size: 16px;
                margin-bottom: 3px; }

            h1 {
                font-size: 22px;

                // margin-bottom: 0
                line-height: 1.5;
                letter-spacing: 0.3px; } } }

    .horizontal-cards.slick-slider {
        &.rounded-arrow {
            button.slick-arrow.slick-next {
                width: 40px;
                height: 40px;
                box-shadow: 0 12px 24px 0 rgba(32, 32, 32, 0.3);
                right: 0;

                &::after {
                    font-size: 18px !important;
                    right: -5px;
                    top: 8px;
                    font-weight: bold; } }

            button.slick-arrow.slick-prev {
                width: 40px;
                height: 40px;
                left: 0;
                box-shadow: 0 12px 24px 0 rgba(32, 32, 32, 0.3);

                &::after {
                    font-size: 18px !important;
                    left: -5px;
                    top: 8px;
                    font-weight: bold; } } } } }

@media all and (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
    .fui-tab {
        -ms-overflow-style: none;
        overflow: auto;

        &::-webkit-scrollbar {
            display: none; } } }
