.fui-lifecircle-promotion {
    padding-top: 0;

    .slick-arrow {
        cursor: pointer;

        &.slick-prev,
        &.slick-next {
            position: absolute;
            right: 0;
            top: -66px;
            border: 0;
            background: none;
            width: 36px;
            height: 36px;
            color: transparent;

            &.slick-disabled::after {
                color: $gray50; }

            &::after {
                position: absolute;
                font-family: "icomoon" !important;
                speak: none;
                color: $black50;
                width: 28px;
                height: 28px;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
                font-size: 28px;
                font-style: normal;
                font-weight: normal;
                font-variant: normal;
                text-transform: none;
                line-height: 1; } }

        &.slick-prev {
            right: 40px;

            &::after {
                content: ""; } }

        &.slick-next {
            &::after {
                content: ""; } } }

    .slick-track {
        transition-timing-function: ease-in-out !important; }

    .slick-slider .slick-list {
        overflow: visible;

        .slick-slide {
            padding: 0 12px 0 0;
            overflow: hidden;
            height: 430px; }

        a,
        a:link {
            position: relative;
            display: block;
            overflow: hidden;
            height: 430px;

            .bg {
                position: relative;

                img {
                    transition: transform 0.3s ease-in-out;
                    position: absolute;
                    width: auto;
                    height: 430px;
                    left: -100%;
                    right: -100%;
                    margin: 0 auto; } }

            &:hover .bg img {
                transform: scale(1.1); }

            .caption {
                position: absolute;
                display: block;
                width: 100%;
                bottom: 0;
                margin: 0 auto;
                padding: 30px 20px;
                color: $white;

                .fui-button {
                    margin: 0;
                    width: 100%; }

                // text-align: center

                h2 {
                    margin-top: 0;
                    margin-bottom: 12px; }

                h6 {
                    margin: 0 0 30px;
                    font-weight: $font-regular; } } } }

    .fui-lifecycle-icons {
        display: block;
        height: 150px;

        .lifecycle-icon-container {
            position: absolute;
            width: 100%;
            height: 160px;
            opacity: 0;
            z-index: 0;
            display: block;
            white-space: nowrap;
            overflow-x: auto;
            overflow-y: hidden;
            transition: opacity 0.3s ease;
            margin: ($space * 3) -15px 0;
            padding: 10px 15px 0;
            -webkit-overflow-scrolling: touch;
            -ms-overflow-style: none;

            &::-webkit-scrollbar {
                display: none; }

            &.fade-in {
                opacity: 1;
                z-index: 2; }

            .lifecycle-icon {
                display: inline-block;
                text-align: center;
                padding-left: $space * 2;
                padding-right: $space * 2;

                // width: 120px

                &:hover .icon {
                    transform: translateY(-6px); }

                .icon {
                    transition: transform 0.3s ease;

                    img {
                        width: 60px; } }

                .content {
                    h6 {
                        margin: 12px 0 0; } } } } }

    @media screen and (min-width: 768px) {
        .slick-slider .slick-list a .caption .fui-button,
        .slick-slider .slick-list a:link .caption .fui-button {
            width: auto; } }

    @media screen and (min-width: 960px) {
        .slick-slider .slick-list {
            a,
            a:link {
                .caption {
                    display: flex;
                    flex-direction: column;
                    align-content: center;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    top: 0;
                    bottom: 0;
                    margin: auto;

                    .fui-button {
                        width: auto; } } } }

        .fui-lifecycle-icons .lifecycle-icon-container {
            display: flex;
            white-space: wrap;
            width: 100%;

            .lifecycle-icon {
                flex: 1;
                flex-grow: 1;
                width: auto;

                .icon img {
                    width: 80px; } } } } }
.pointer-none {
    cursor: default; }

.fui-cbu-news {
    background: transparent url(#{$publicURL}/resources/cbu/cbu-index/red-trangle-left@2x.png) left bottom no-repeat;
    background-size: 266px auto;

    .fui-tab-pane {
        padding-top: $space * 3; }

    .fui-cards.is-news {
        margin: 0 -15px 0 -6px;
        padding-left: 0;
        padding-right: 0;

        .fui-card {
            flex: 1 1 0;
            max-width: 290px;

            .fui-card-content .fui-card-title {
                -webkit-line-clamp: 3; }

            .fui-card-extra {
                margin-top: $space * 3; }

            @media screen and (min-width: 960px) {
                max-width: none; } } }

    @media screen and (min-width: 960px) {
        background-size: 575px auto; } }

.fui-container .fui-cards.is-cbu-promotion-cards {
    margin: 0 -15px 0 -15px;
    padding-left: 12px;
    padding-right: 0px;
    padding-bottom: 30px;

    .fui-card {
        display: inline-flex;
        flex-grow: 1;
        width: 90%;
        max-width: 330px;
        box-shadow: 0 6px 12px 0 rgba(75, 88, 105, 0.12);

        .fui-card-action {
            display: flex;
            flex-grow: 1; }

        .fui-card-caption {
            .fui-card-title .text {
                display: block;
                font-size: 22px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden; }

            .fui-card-description {
                margin-top: 0; } } }

    @media screen and (max-width: 960px) {
        .fui-card .fui-card-image {
            flex: 1 1 auto; } }

    @media screen and (max-width: 960px) and (min-width: 480px) {
        .fui-card {
            max-width: 270px; } }

    @media screen and (min-width: 960px) {
        white-space: normal;
        flex-wrap: wrap;

        .fui-card {
            width: calc(33.3333% - 12px);
            max-width: none;
            position: relative;

            .fui-card-action {
                .fui-card-caption {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    background: $white;
                    transition: all 0.3s ease;
                    padding-bottom: $space * 2;

                    .fui-card-extra .fui-button.is-arrow,
                    .fui-card-meta {
                        transition: color 0.3s ease; }

                    .fui-card-title {
                        font-size: 24px; } } }

            &:hover .fui-card-caption,
            &:focus .fui-card-caption {
                background: rgba(32, 32, 32, 0.3);
                color: $white !important;

                .fui-card-meta {
                    color: $white !important; }

                .fui-card-extra .fui-button.is-arrow,
                .fui-card-extra .fui-button.is-arrow::after {
                    color: $white !important; } } } } }

.fui-greeting-guide {
    padding: 0;

    .fui-container {
        padding: 0;

        .paper {
            padding-left: 15px;
            padding-right: 30px;
            margin-bottom: 0;
            padding-top: $space * 6;
            background: transparent url(#{$publicURL}/resources/cbu/cbu-index/red-trangle@2x.png) right bottom no-repeat;
            background-size: 266px auto;
            box-shadow: none;

            @media screen and (min-width: 960px) {
                background-color: $white;
                box-shadow: 0 6px 12px 0 rgba(75, 88, 105, 0.12); } } }

    .fui-bubbles-container {
        .fui-emma-chat {
            margin-bottom: 36px;
            opacity: 0;
            transform: translateY(-10px);
            transition: all 0.5s ease;

            &.fade-in {
                opacity: 1;
                transform: translateY(0px); }

            .emma {
                float: left;
                width: 48px;
                height: 48px;
                line-height: 0;
                border-radius: 50%;
                box-shadow: 0 5px 20px rgba(0, 0, 0, 0.3);

                img {
                    width: 100%;
                    line-height: 0; } }

            .content {
                position: relative;
                display: block;
                margin-left: $space * 7.5;

                .slick-slider {
                    margin-right: -30px;

                    &.is-icon-slide .slick-list .slick-track {
                        margin-left: 0;
                        display: flex;

                        .slick-slide {
                            width: 90%;
                            max-width: 140px; } }

                    .slick-list {
                        overflow: visible;

                        .slick-slide {
                            padding: 0 12px 0 0;
                            width: 90%;
                            max-width: 282px;

                            @media screen and (min-width: 960px) {
                                width: auto;
                                max-width: none; } }

                        .bubble.is-card {
                            height: 310px;

                            .bubble-card-content {
                                height: 107px; } } }

                    .slick-arrow {
                        position: absolute;
                        width: 40px;
                        height: 40px;
                        background: $white;
                        border-radius: 20px;
                        overflow: hidden;
                        border: 0;
                        z-index: 1;
                        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
                        color: transparent;
                        top: 0;
                        bottom: 0;
                        margin: auto;
                        opacity: 1;
                        display: none !important;
                        transition: opacity 0.3s ease;

                        @media screen and (min-width: 960px) {
                            display: block !important; }

                        &.slick-disabled {
                            opacity: 0; }

                        &::after {
                            position: absolute;
                            font-family: "icomoon" !important;
                            speak: none;
                            color: $black50;
                            width: 28px;
                            height: 28px;
                            top: 0;
                            bottom: 0;
                            left: 0;
                            right: 0;
                            margin: auto;
                            font-size: 28px;
                            font-style: normal;
                            font-weight: normal;
                            font-variant: normal;
                            text-transform: none;
                            line-height: 1; }

                        &.slick-prev {
                            left: -20px;

                            &::after {
                                content: ""; } }

                        &.slick-next {
                            right: 10px;

                            &::after {
                                content: ""; } } } }

                .fui-bubble-time {
                    margin-top: 12px; }

                .bubble.is-icon {
                    width: 100%;
                    background: $white;
                    padding: 15px ($space * 2);
                    border-radius: $space * 1.5;
                    box-shadow: 0 3px 18px 0 rgba(75, 88, 105, 0.24);
                    text-align: center;

                    &:hover {
                        background: $lightgray50; }

                    .text {
                        margin-top: 6px;
                        font-weight: $font-medium; }

                    .icon img {
                        width: 60px;
                        height: 60px;
                        margin: 0 auto; } }

                .bubble.is-text {
                    background: $white;
                    padding: 12px 15px;
                    border-radius: 12px;
                    font-size: 18px;
                    box-shadow: 0 5px 20px rgba(75, 88, 105, 0.24);
                    max-width: 600px; }

                .bubble.is-card {
                    display: flex;
                    flex-direction: column;
                    background: $white;
                    border-radius: 12px;
                    box-shadow: 0 5px 20px rgba(75, 88, 105, 0.24);
                    overflow: hidden;
                    max-width: 270px;

                    .image {
                        position: relative;
                        height: 0;
                        padding-bottom: 56.25%;

                        // flex: 1 1 auto
                        // flex-grow: 1

                        img {
                            position: absolute;
                            width: auto;
                            height: 100%;
                            left: -100%;
                            right: -100%;
                            margin: auto; } }

                    .subtitle {
                        font-weight: $font-bold;

                        @include ellipsis(1); }

                    .subtitle,
                    .body {
                        font-size: 1rem; }

                    .body {
                        flex: 1 1 auto;
                        flex-grow: 1;

                        @include ellipsis(2); }

                    .bubble-card-content {
                        padding: 12px 15px;
                        flex: 1 1 auto;
                        flex-grow: 1; }

                    .bubble-card-action {
                        a {
                            display: block;
                            height: 48px;
                            line-height: 48px;
                            font-weight: $font-medium;
                            text-align: center;
                            border-top: 2px solid $gray30;

                            span {
                                font-size: 1rem; }

                            &:hover {
                                background: $lightgray50; } } }

                    @media screen and (max-width: 374px) {
                        max-width: 220px; } } } } }

    @media screen and (max-width: 960px) {
        .fui-bubbles-container {
            .fui-emma-chat .content {
                .slick-slider {
                    .slick-list .slick-slide {
                        opacity: 1 !important; }

                    &.is-icon-slide .slick-list .slick-track .slick-slide {
                        width: 90%;
                        max-width: 97px;
                        height: 102px; } }

                .bubble.is-text {
                    font-size: 1rem; }

                .bubble.is-icon {
                    padding: 12px;
                    height: 102px;

                    .icon img {
                        width: 36px;
                        height: 36px; }

                    .text {
                        font-size: 14px;
                        letter-spacing: 0px;
                        line-height: 18px; } } } } }

    @media screen and (min-width: 1025px) {
        padding: 60px 0;

        .fui-container {
            padding: 0 15px;

            .paper {
                padding-left: 30px;
                padding-right: 30px;
                background-size: 500px auto; } }

        .fui-bubbles-container {
            .fui-emma-chat {
                .emma {
                    width: 70px;
                    height: 70px; }

                .content {
                    margin-left: 100px;
                    font-size: 18px;

                    .bubble.is-text {
                        &::before {
                            content: "";
                            position: absolute;
                            left: -24px;
                            top: 14px;
                            border-left: 14px solid transparent;
                            border-top: 10px solid transparent;
                            border-bottom: 10px solid transparent;
                            border-right: 14px solid $white; } }

                    > .bubble.is-card::before {
                        content: "";
                        position: absolute;
                        left: -24px;
                        top: 14px;
                        border-left: 14px solid transparent;
                        border-top: 10px solid transparent;
                        border-bottom: 10px solid transparent;
                        border-right: 14px solid $white; }

                    .bubble.is-text,
                    .bubble.is-card .bubble-card-content {
                        padding: 18px 24px; }

                    .bubble.is-card .body {
                        font-size: 18px; }

                    .slick-slider {
                        margin-right: 0;

                        .slick-list {
                            margin-left: -6px;

                            .slick-slide {
                                opacity: 0;
                                padding: 0 6px;
                                transition: opacity 0.3s ease;

                                &.slick-active {
                                    opacity: 1; }

                                .bubble.is-card {
                                    height: 347px;

                                    .bubble-card-content {
                                        height: 115px; }

                                    .subtitle {
                                        font-size: 18px; } } } } }

                    .bubble.is-card {
                        max-width: 327px; } } } } } }

.is-eservice,
.is-cbu {
    .carousel-banner-3 {
        .bg-img {
            background-position: center;
            background-size: cover; }

        @media screen and (max-width: 768px) {
            .slick-dots {
                bottom: 40px; }

            .slick-slide {
                height: 320px;

                > div,
                .slide {
                    height: 320px; }

                .bg-img {
                    position: absolute;
                    top: 0;
                    width: 100%;
                    height: 320px; }

                .content-wrapper {
                    position: absolute;
                    left: 0;
                    width: 100%;
                    height: 320px; }

                .content {
                    padding-top: 60px;

                    p.subtitle {
                        margin: 0; }

                    h1 {
                        margin-bottom: 20px; } } } } }

    .fui-collapse-menu .collapse-menu-group {
        .collapse-menu-header {
            background: $coral100 url(#{$publicURL}/resources/cbu/discount/collapse-menu-bg@2x.png) no-repeat left center;
            background-size: auto 100%; }

        ul li {
            .active {
                color: $red30; }

            &:hover > p,
            p:hover {
                color: $red30; }

            .item-group {
                background: #f0f2f5;

                &::before {
                    background: linear-gradient(178deg, #f69084, $red30 100%); }

                &::after {
                    border-left-color: $coral30; } } } }

    .fui-collapse-menu.help-center {
        ul li {
            .active {
                color: $business50; } } }

    @media screen and (max-width: 960px) {
        .MuiGrid-root.MuiGrid-container {
            margin-top: 0;
            margin-bottom: 0; }

        .fui-collapse-menu .collapse-menu-group {
            .collapse-menu-header {
                background: none; } } } }
