.fui-instruction-list {
    width: 100%;
    // margin-top: 60px
    margin-bottom: 90px;
    h2 {
        margin-bottom: 60px;
        margin-top: 0; }
    .fui-card {
        cursor: pointer;
        background: $white;
        width: 100%;
        height: 100%;
        padding-bottom: 30px;
        padding-top: 26px;
        img {
            max-width: 160px; } }
    @media screen and (max-width: 960px) {
        margin-bottom: 50px;
        h2 {
            margin-bottom: 36px; }
        .fui-card {
            padding: 32px 12px 18px;
            .fui-card-content {
                padding: 0; }
            img {
                max-width: 100px; }
            .heading-6 {
                font-size: 16px; } } } }
