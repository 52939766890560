.d-inline {
    display: inline !important; }

.d-inline-block {
    display: inline-block !important; }

.d-block {
    display: block !important; }

.d-table {
    display: table !important; }

.d-table-row {
    display: table-row !important; }

.d-table-cell {
    display: table-cell !important; }

.d-flex {
    display: flex !important; }

.d-inline-flex {
    display: inline-flex !important; }

.d-none {
    display: none !important; }

.overflow-visible {
    overflow: visible!important; }

@media (min-width: 600px) {
    .d-sm-inline {
        display: inline !important; }

    .d-sm-inline-block {
        display: inline-block !important; }

    .d-sm-block {
        display: block !important; }

    .d-sm-table {
        display: table !important; }

    .d-sm-table-row {
        display: table-row !important; }

    .d-sm-table-cell {
        display: table-cell !important; }

    .d-sm-flex {
        display: flex !important; }

    .d-sm-inline-flex {
        display: inline-flex !important; }

    .d-sm-none {
        display: none !important; } }

@media screen and (min-width: 960px) {
    .d-md-inline {
        display: inline !important; }

    .d-md-inline-block {
        display: inline-block !important; }

    .d-md-block {
        display: block !important; }

    .d-md-table {
        display: table !important; }

    .d-md-table-row {
        display: table-row !important; }

    .d-md-table-cell {
        display: table-cell !important; }

    .d-md-flex {
        display: flex !important; }

    .d-md-inline-flex {
        display: inline-flex !important; }

    .d-md-none {
        display: none !important; } }

@media screen and (min-width: 1280px) {
    .d-lg-inline {
        display: inline !important; }

    .d-lg-inline-block {
        display: inline-block !important; }

    .d-lg-block {
        display: block !important; }

    .d-lg-table {
        display: table !important; }

    .d-lg-table-row {
        display: table-row !important; }

    .d-lg-table-cell {
        display: table-cell !important; }

    .d-lg-flex {
        display: flex !important; }

    .d-lg-inline-flex {
        display: inline-flex !important; }

    .d-lg-none {
        display: none !important; } }

@media screen and (min-width: 1920px) {
    .d-xl-inline {
        display: inline !important; }

    .d-xl-inline-block {
        display: inline-block !important; }

    .d-xl-block {
        display: block !important; }

    .d-xl-table {
        display: table !important; }

    .d-xl-table-row {
        display: table-row !important; }

    .d-xl-table-cell {
        display: table-cell !important; }

    .d-xl-flex {
        display: flex !important; }

    .d-xl-inline-flex {
        display: inline-flex !important; }

    .d-xl-none {
        display: none !important; } }
