.selected-article {
    margin-bottom: 12px;

    .head {
        background-image: url(#{$publicURL}/resources/cbu/product/images/bg-pattern.png);
        width: 100%;
        height: 60px;
        background-size: cover;
        padding: 15px;

        h6 {
            color: #fff;
            margin: 0;

            @include ellipsis(1); } }

    .top {
        color: $darkgray50;

        small {
            margin-right: 6px;
            font-weight: 500; }

        small:nth-child(2) {
            color: $gray100; } }

    .body {
        display: flex;
        flex-direction: row;
        font-weight: 500;

        p {
            flex: 1;
            padding-right: $space;
            margin: 0;

            @include ellipsis(2); } }

    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;

        li {
            padding: 15px;
            background-color: #F0F2F5;
            border-bottom: 2px solid #EBEBEB;
            display: flex;
            align-items: center;
            cursor: pointer;

            i {
                font-size: 24px;
                display: flex;
                align-items: center; }

            a {
                color: $black50 !important;
                margin: 0;
                width: 100%;
                display: flex;
                justify-content: space-between;
                flex-direction: column;

                &::after {
                    display: none; } }

            a:visited {
                color: $black50;

                p {
                    color: $black50; } }

            p {
                margin: 0; } }

        li:nth-last-child(1) {
            border-bottom: 0; } } }

@media screen and (max-width: 960px) {
    .selected-article {
        margin-top: 0 !important;
        margin-bottom: 0 !important;

        + .side-ad {
            margin: 0 -15px; }

        .body {
            // i
 } }            //   display: none

    .selected-news {
        padding: 20px 0 !important;
        margin: 0 0px !important; } }
