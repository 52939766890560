.fui-search-panel {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    display: none;
    background: #FFF;
    right: -100%;
    transition: all 0.3s ease;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 699;

    .fui-search-header {
        position: relative;
        height: 50px;

        .fui-container {
            height: 50px; }

        .close {
            position: absolute;
            right: 10px;
            font-size: 24px;
            top: 0;
            bottom: 0;
            padding: 0;
            width: 32px;
            height: 32px;
            margin: auto;
            border: 0;
            background: none; }

        .fui-navbar-menu {
            display: none;
            align-content: center;
            align-items: center;
            justify-content: center;
            justify-items: center; } }

    .fui-search-body {
        flex: 1 1 auto;
        padding: ($space * 4) 0 ($space * 2);

        h2 {
            margin-top: 0; }

        .search-helper {
            display: flex;
            width: 100%;
            max-width: 400px;
            align-content: center;
            align-items: center;
            justify-content: space-between;
            justify-items: space-between;
            margin: ($space * 6) auto;

            .search-item {
                display: flex;
                width: 100px;
                flex-direction: column;
                align-content: center;
                align-items: center;
                margin: 0;

                img {
                    width: 60px;
                    height: auto; }

                .text {
                    font-weight: $font-medium;
                    margin-top: $space * 1.5; } } }

        .fui-button.is-label {
            line-height: 26px;

            &:hover {
                color: $black50; } } }

    .fui-search-footer {
        padding: ($space * 3) 0;
        text-align: center; }

    .inline-divider {
        display: inline-block;
        width: 1px;
        height: 20px;
        background: $gray50;
        margin: 0 ($space * 3);
        vertical-align: middle; }

    @media screen and (min-width: 960px) {
        overflow: hidden; } }

header.is-search-open {
    .fui-search-panel {
        position: fixed;
        right: 0;
        display: block;
        overflow-y: auto;
        max-height: calc(100vh - 40px); } }

.fui-search-filter {
    background: $white;

    .fui-container {
        padding: 0; }

    .fui-tab.with-scroller {
        .fui-tab-item::before,
        &::before {
            display: none; }

        .fui-tab-item {
            min-width: 102px; } } }

@media screen and (min-width: 960px) {
    .fui-search-panel {
        position: absolute;
        top: 40px;
        height: 0px;

        // max-height: calc(100% - 40px)
        right: 0;

        .fui-search-header {
            height: 60px;

            .fui-navbar-menu {
                display: flex !important; }

            .close {
                right: 64px;
                top: 6px; } } }

    header.is-search-open {
        .fui-search-panel {
            height: 632px; } } }
