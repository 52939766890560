.news-wrapper {
    padding-top: 60px;
    margin-bottom: -20px;
    p {
        font-size: 18px; }

    .fui-container .fui-cards {
        padding: 0px 0px 60px 0px; }

    .fui-card-action {
        width: 100%;
        height: 270px; }

    @media screen and (min-width: 768px) {
        .fui-cards .fui-card .fui-card-content .fui-card-title {
            -webkit-line-clamp: 3; } } }

@media screen and (max-width: 960px) {
    .news-wrapper {
        padding-top: 60px; }

    .fui-container .fui-cards {
        padding: 0 15px 48px 15px; } }

@media screen and (max-width: 768px) {
    .news-wrapper {
        .fui-card {
            width: 268px;

            .fui-card-action,
            a.fui-card-action {
                height: auto; } } }

    .tech {
        .fui-card {
            margin: 0; } } }
