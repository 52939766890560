section.banner-with-link {
  position: relative;
  .fui-banner {
    min-height: auto;
    height: 295px; }
  .fui-banner.is-product.is-style-2 .caption {
    top: 0;
    h1 {
      font-size: 28px; }
    p.subtitle {
      max-width: 390px; } }
  .paper {
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    a {
      color: $black50; } } }
@media screen and (max-width: 960px) {
  section.banner-with-link {
    .fui-banner.is-product {
      height: 360px;
      .caption {
        padding-top: 138px;
        h1 {
          font-size: 24px; }
        p {
          font-size: 16px; }
        a.fui-button {
          width: 100%;
          height: 36px;
          span {
            height: 36px;
            display: flex;
            align-items: center;
            justify-content: center; } } } } } }
@media screen and (max-width: 640px) {
  section.banner-with-link {
    .caption {
      .subtitle {
        margin-bottom: 0; } } } }
