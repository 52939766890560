.tag-list {
  display: inline-block;
  .tag {
    background-color: $darkgray50;
    color: $white;
    padding: 4px;
    font-size: 16px;
    padding: 2px 16px;
    border-radius: 25px;
    margin-right: 6px;
    display: inline-flex;
    margin-bottom: 6px; } }
