.google-map {
    background-image: url(#{$publicURL}/resources/ebu/images/ebu-lightgray-pattern-1440x460.jpg);
    color: $darkgray50;
    background-size: cover;

    .fui-container {
        padding-top: 60px;
        padding-bottom: 60px;
        height: 270px;

        h4 {
            margin: 0 0 24px 0; } }

    .info-list {
        display: flex;
        margin-bottom: 6px;

        p {
            margin: 0; }

        .is-text-medium {
            margin-right: 12px;
            min-width: 64px; } }

    .google-map-display {
        width: 100%;
        height: 400px;
        margin-bottom: 90px; }

    .map-label {}

    @media screen and (max-width: 960px) {
        .fui-container {
            height: initial;

            h1 {
                min-width: 190px; } } } }
