// .is-{state}-{attributes}
.is-green {
    color: green !important; }

.is-orange {
    color: orange !important; }

.is-red {
    color: red !important; }

.is-green-bg {
    background-color: green !important; }

.is-orange-bg {
    background-color: orange !important; }

.is-red-bg {
    background-color: red !important; }
