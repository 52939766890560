.fui-ruler {
    background-color: #f1f3f5;
    width: 100%;
    // margin-bottom: 6px
    overflow: hidden;

    &.main {
        .percentage {
            height: 48px;
            background-image: linear-gradient(to left, #fc7b66 0%, #ffa188);

            span {
                font-size: 22px;
                margin-top: 10px;

                &::after {
                    border-color: transparent transparent transparent #fc7a66;
                    border-width: 48px 0 0 48px;
                    top: -10px;
                    right: -48px; } } } }
    &.fetnet-coin {
        .percentage {
            height: 48px;
            background-image: linear-gradient(to left, #f64b53 0%, #d3060f);

            span {
                font-size: 22px;
                margin-top: 10px;

                &::after {
                    border-color: transparent transparent transparent #f64b53;
                    border-width: 48px 0 0 48px;
                    top: -10px;
                    right: -48px; } } } }

    .percentage {
        height: 30px;
        background-image: linear-gradient(to right, #9edfe9, #5cc3d2 97%);
        position: relative;

        span {
            position: absolute;
            right: 0;
            font-size: 18px;
            color: #fff;
            font-weight: 500;
            margin-top: 2px;
            div {
                z-index: 1;
                position: relative;
 }                // margin-right: 12px

            &.zero {
                left: 10px;
                color: #5f6e84;
                text-align: left;
                div {
                    margin-right: -5px; }

                &::after {
                    display: none; } }

            &::after {
                content: "";
                width: 0;
                height: 0;
                border-style: solid;
                border-color: transparent transparent transparent #5cc3d2;
                position: absolute;
                border-width: 30px 0 0 30px;
                top: -2px;
                right: -30px; } } } }
.multi-ruler {
    margin-bottom: 60px;
    .fui-ruler {
        &.main {
            height: 48px; }
        .percentage {
            display: inline-block;
            transform: skewX(36deg);
            background-image: none;
            margin-left: -1px;
            color: $darkgray50;
            font-weight: 500;
            .first-block {
                display: block;
                position: absolute;
                left: 0;
                width: 50px;
                height: 100%;
                left: -30px; }
            .last-block {
                display: block;
                position: absolute;
                right: 0;
                width: 50px;
                height: 100%;
                right: -30px; }

            span::after {
                top: -10px;
                display: none; } } }
    .number-block {
        background: initial;
        display: flex;
        .percentage {
            background: initial;
            transform: initial; } } }
.analysis-list {
    .list-block {
        display: inline-flex;
        color: $darkgray50;
        align-items: center;
        margin-right: 60px;
        font-weight: 500;
        margin-bottom: 10px;
        .rectangle {
            width: 15px;
            height: 15px;
            margin-right: 6px; } } }
.bill-ruler {
    margin-bottom: 60px;
    .bill-ruler-wrapper {
        display: flex;
        // margin-bottom: 24px
        align-items: center;
        .middle-border {
            &::before {
                content: '';
                position: absolute;
                left: 33.3%;
                height: 100px;
                border-left: 1px solid $gray30;
                top: -10px;
                z-index: 1; }
            &::after {
                content: '';
                position: absolute;
                left: 66.6%;
                height: 100px;
                border-left: 1px solid $gray30;
                top: -10px;
                z-index: 1; } }
        h5 {
            display: inline-block;
            width: 130px;
            margin: 0; }
        .fui-ruler {
            width: calc( 100% - 130px );
            background-color: initial;
            display: inline-block;
            position: relative;
            overflow: hidden;
            padding-bottom: 15px;
            padding-top: 15px;
            padding-right: 48px;
            &.main {
                .percentage {
                    span {
                        &::after {
                            border-color: transparent transparent transparent #fc7b66; } } }
                .percentage.full-width span::after {
                    background-color: #fc7b66; } }
            &.fetnet-coin {
                .percentage {
                    span {
                        &::after {
                            border-color: transparent transparent transparent #f64b53; } } }
                .percentage.full-width span::after {
                    background-color: #f64b53; } }
            .percentage {
                height: 48px;
                &.full-width {
                    span {
                        &::after {
                            border: 0;
                            background-color: #5cc3d2;
                            width: 48px;
                            height: 48px; } } }
                .zero {
                    margin-top: 8px;
                    width: 40px; }

                span {
                    font-size: 22px;
                    margin-top: 10px;
                    width: 100%;
                    text-align: right;
                    div {
                        white-space: pre; }

                    &::after {
                        border-color: transparent transparent transparent #5cc3d2;
                        border-width: 48px 0 0 48px;
                        top: -10px;
                        right: -48px; } } }
            &::before {
                content: '';
                position: absolute;
                left: 0;
                height: 100px;
                border-left: 1px solid $gray30;
                top: -10px; }
            &::after {
                content: '';
                position: absolute;
                right: 0;
                height: 100px;
                border-right: 1px solid $gray30;
                top: -10px; } }
        &:last-of-type {
            .fui-ruler {
                border-bottom: 1px solid $gray30;
                padding-bottom: 14px; } } } }

@media screen and (max-width: 960px) {
    .fui-ruler {
        &.main {
            .percentage {
                height: 30px;
                span {
                    font-size: 16px;
                    margin-top: 4px; } } }
        .percentage {
            height: 20px;
            span {
                margin-top: 0;
                font-size: 14px; } } }
    .multi-ruler {
        margin-bottom: 48px;
        .fui-ruler {
            &.main {
                height: 30px;
                .percentage {
                    height: 30px;
                    span {
                        font-size: 16px;
                        margin-top: 4px; } } }
            .percentage {
                height: 20px;
                span {
                    margin-top: 0;
                    font-size: 14px; } } } }
    .analysis-list {
        .list-block {
            margin-right: 0;
            width: 50%; } }
    .bill-ruler {
        margin-bottom: 30px;
        .bill-ruler-wrapper {
            h5 {
                width: 85px;
                min-width: 85px;
                margin-right: 12px; }
            .fui-ruler {
                width: calc( 100% - 30px );
                padding-right: 30px;
                &::before,&::after {
                    height: 100px; }
                .middle-border {
                    &::before,&::after {
                        height: 100px; } }
                .percentage {
                    height: 30px;
                    &.full-width span::after {
                        height: 30px;
                        width: 30px; }
                    span {
                        font-size: 14px;
                        margin-top: 6px;
                        &::after {
                            border-width: 30px 0 0 30px;
                            right: -30px;
                            top: -6px; } } } } } } }
