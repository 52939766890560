@charset "UTF-8";
/*
 * Retina Backgrounds for Compass
 * by:              Gaya Kessler
 * last update:     03/11/14
 *
 * Usage:
 * 1. create background image for pixel ratio 1 screens and put it somewhere in your images folder.
 * 2. create background image for pixel ratio 2 screens and put it somewhere in your images folder.
 * 3. include the background-image property in your Sass/Scss using: '@include background-retina(<ratio-1-imagefile>, <ratio-2-imagefile>)'
 */
html, body {
  margin: 0;
  padding: 0;
  font-family: "PingFang TC", "Noto Sans TC", "Microsoft JhengHei", "微軟正黑體", "STHeitiTC-Light", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
  font-size: 16px;
  background: #fafafa; }
  @media screen and (max-width: 768px) {
    html, body {
      font-size: 14px; } }
  html.sidebar-open, body.sidebar-open {
    overflow: hidden; }
  html.no-promotion footer.fui-footer .fui-section-promo, body.no-promotion footer.fui-footer .fui-section-promo {
    display: none; }

.is-loading #root {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh; }

* {
  font-family: "PingFang TC", "Noto Sans TC", "Microsoft JhengHei", "微軟正黑體", "STHeitiTC-Light", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
  box-sizing: border-box;
  outline: 0; }

a, a:link, a:visited {
  display: inline-block;
  color: #202020;
  cursor: pointer;
  text-decoration: none; }

a.info-link {
  color: #EF3123 !important; }
  a.info-link::after {
    display: none !important; }
  a.info-link:hover {
    text-decoration: underline; }
  a.info-link:hover {
    color: #EF3123; }

.hover-underline-link:hover,
a.hover-underline-link:hover {
  text-decoration: underline; }

.hover-underline-link.is-text-black50:after,
a.hover-underline-link.is-text-black50:after {
  background: #202020 !important; }

.underline-link:after,
a.underline-link:after {
  content: "" !important;
  width: 100% !important;
  opacity: 1 !important;
  display: block !important;
  height: 2px !important;
  background: #D3060F !important; }

.underline-link.is-text-black50:after,
a.underline-link.is-text-black50:after {
  background: #202020 !important; }

.animate-underline::after,
.animation-underline::after,
.animate-underline-link::after,
.animation-underline-link::after,
a.animate-underline::after,
a.animation-underline::after,
a.animate-underline-link::after,
a.animation-underline-link::after {
  content: "";
  display: block;
  width: 0;
  height: 2px;
  background: #EF3123;
  opacity: 0;
  transition: all 0.3s ease; }

.animate-underline:hover::after, .animate-underline:active::after,
.animation-underline:hover::after,
.animation-underline:active::after,
.animate-underline-link:hover::after,
.animate-underline-link:active::after,
.animation-underline-link:hover::after,
.animation-underline-link:active::after,
a.animate-underline:hover::after,
a.animate-underline:active::after,
a.animation-underline:hover::after,
a.animation-underline:active::after,
a.animate-underline-link:hover::after,
a.animate-underline-link:active::after,
a.animation-underline-link:hover::after,
a.animation-underline-link:active::after {
  width: 100%;
  opacity: 1 !important; }

a.info-link {
  color: #EF3123; }
  a.info-link::after {
    display: none !important; }
  a.info-link:hover {
    text-decoration: underline; }
  a.info-link:hover {
    color: #EF3123; }

.hover-underline-link-accent:hover {
  opacity: 1 !important;
  color: #EF3123 !important; }

.hover-underline-link:hover,
a.hover-underline-link:hover {
  text-decoration: underline; }

.hover-underline-link.is-text-black50:after,
a.hover-underline-link.is-text-black50:after {
  background: #202020 !important; }

.underline-link:after,
a.underline-link:after {
  content: "" !important;
  width: 100% !important;
  opacity: 1 !important;
  display: block !important;
  height: 2px !important;
  background: #D3060F !important; }

.underline-link.is-text-black50:after,
a.underline-link.is-text-black50:after {
  background: #202020 !important; }

section p a:not(.expand):not(.item):not(.fui-item):not(.fui-card):not(.fui-card-action):not(.fui-button)::after, section p a:link:not(.expand):not(.item):not(.fui-item):not(.fui-card):not(.fui-card-action):not(.fui-button)::after, section p a:visited:not(.expand):not(.item):not(.fui-item):not(.fui-card):not(.fui-card-action):not(.fui-button)::after {
  content: "";
  display: block;
  width: 0;
  height: 2px;
  background: #D3060F;
  transition: all 0.3s ease; }

section p a:not(.expand):not(.item):not(.fui-item):not(.fui-card):not(.fui-card-action):not(.fui-button):hover::after, section p a:not(.expand):not(.item):not(.fui-item):not(.fui-card):not(.fui-card-action):not(.fui-button):active::after, section p a:link:not(.expand):not(.item):not(.fui-item):not(.fui-card):not(.fui-card-action):not(.fui-button):hover::after, section p a:link:not(.expand):not(.item):not(.fui-item):not(.fui-card):not(.fui-card-action):not(.fui-button):active::after, section p a:visited:not(.expand):not(.item):not(.fui-item):not(.fui-card):not(.fui-card-action):not(.fui-button):hover::after, section p a:visited:not(.expand):not(.item):not(.fui-item):not(.fui-card):not(.fui-card-action):not(.fui-button):active::after {
  width: 100%; }

.hover-underline-link:hover,
a.hover-underline-link:hover {
  text-decoration: underline; }

.hover-underline-link.is-text-black50:after,
a.hover-underline-link.is-text-black50:after {
  background: #202020 !important; }

.hover-underline-link-accent:hover {
  opacity: 1 !important;
  color: #EF3123 !important; }

.underline-link:after,
a.underline-link:after {
  content: "" !important;
  width: 100% !important;
  opacity: 1 !important;
  display: block !important;
  height: 2px !important;
  background: #D3060F !important; }

.underline-link.is-text-black50:after,
a.underline-link.is-text-black50:after {
  background: #202020 !important; }

section p a::after, section p a:link::after, section p a:visited::after {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  background: #EF3123;
  opacity: 0; }

section p a:hover::after, section p a:active::after, section p a:link:hover::after, section p a:link:active::after, section p a:visited:hover::after, section p a:visited:active::after {
  opacity: 1 !important; }

.fui-section-collapse a, .fui-section-collapse a:link, .fui-section-collapse a:visited {
  color: #EF3123; }

.hover-underline-link-accent:hover {
  opacity: 1 !important;
  color: #EF3123 !important; }

*.is-system-busy {
  position: relative; }
  *.is-system-busy::after {
    position: absolute;
    content: "系統繁忙中，請稍後再試，謝謝！";
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    padding-top: 140px;
    width: 100%;
    height: 28px;
    line-height: 28px;
    text-align: center;
    display: block;
    margin: auto;
    background: url(/DigService/resources/cbu/e-service/images/error@2x.png) top center no-repeat;
    background-size: 140px;
    z-index: 9; }
  *.is-system-busy > * {
    opacity: 0; }

.fui-card.is-system-busy {
  background: #FFFFFF; }

*.is-api-loading {
  position: relative !important;
  min-height: 300px; }
  *.is-api-loading.account-selector-modal-body {
    min-height: 0px; }
  *.is-api-loading::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: block;
    background: rgba(255, 255, 255, 0.65) url(/DigService/resources/common/images/circle_loading.gif) no-repeat center !important;
    background-size: 60px;
    z-index: 999; }

*.fui-button.is-api-loading {
  min-height: 0;
  pointer-events: none; }
  *.fui-button.is-api-loading::before {
    background-size: 24px !important; }

img {
  border: 0; }

body.is-webview header, body.is-webview footer, body.is-webview .fui-nav-anchor {
  display: none !important; }

body.is-webview main {
  margin-top: 0 !important; }

img {
  line-height: 0; }

hr {
  border: 0;
  border-top: solid 1px #E8ECF0;
  margin-top: 24px;
  margin-bottom: 30px; }

body.dropdown-open .fui-nav-anchor,
body.dropdown-open .fui-nav-tab.is-content-1 {
  z-index: 19; }

.is-component-api-loading {
  position: relative;
  min-height: 300px;
  width: 100%; }
  .is-component-api-loading::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: block;
    background: url(/DigService/resources/common/images/circle_loading.gif) no-repeat center;
    background-size: 60px; }

@font-face {
  font-family: 'icomoon';
  src: url("/DigService/resources/common/fonts/icomoon.eot?t121w2");
  src: url("/DigService/resources/common/fonts/icomoon.eot?t121w2#iefix") format("embedded-opentype"), url("/DigService/resources/common/fonts/icomoon.ttf?t121w2") format("truetype"), url("/DigService/resources/common/fonts/icomoon.woff?t121w2") format("woff"), url("/DigService/resources/common/fonts/icomoon.svg?t121w2#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block; }

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-carousel-next:before {
  content: '\e901'; }

.icon-carousel-prev:before {
  content: '\e902'; }

.icon-check:before {
  content: '\e900'; }

.icon-chevron-down:before {
  content: '\e906'; }

.icon-chevron-left:before {
  content: '\e907'; }

.icon-chevron-right:before {
  content: '\e908'; }

.icon-close:before {
  content: '\e903'; }

.icon-ebu-domain:before {
  content: '\e909'; }

.icon-ebu-email:before {
  content: '\e90a'; }

.icon-ebu-flow:before {
  content: '\e90b'; }

.icon-ebu-internet:before {
  content: '\e90c'; }

.icon-ebu-office365:before {
  content: '\e90d'; }

.icon-facebook:before {
  content: '\e912'; }

.icon-fetnet-arrow:before {
  content: '\e904'; }

.icon-fetnet-arrow-lg:before {
  content: '\e905'; }

.icon-lab:before {
  content: '\e90e'; }

.icon-member-cbu:before {
  content: '\e90f'; }

.icon-member-ebu:before {
  content: '\e910'; }

.icon-minus:before {
  content: '\e911'; }

.icon-office-idea:before {
  content: '\e913'; }

.icon-plus:before {
  content: '\e914'; }

.icon-search:before {
  content: '\e915'; }

.icon-shop-filled:before {
  content: '\e916'; }

.icon-strategy:before {
  content: '\e917'; }

.icon-youtube:before {
  content: '\e918'; }

.icon-view:before {
  content: '\e919'; }

.icon-line-sm:before {
  content: '\e91a'; }

.icon-facebook-sm:before {
  content: '\e91b'; }

.icon-no-more:before {
  content: '\e91c'; }

.icon-first:before {
  content: '\e91d'; }

.icon-last:before {
  content: '\e91e'; }

.icon-phone:before {
  content: '\e91f'; }

.icon-pc:before {
  content: '\e920'; }

.icon-help-center:before {
  content: '\e921'; }

.icon-business-response:before {
  content: '\e922'; }

.icon-business-customer-center:before {
  content: '\e923'; }

.icon-information:before {
  content: '\e924'; }

.icon-hide-view:before {
  content: '\e925'; }

h1,
.heading-1 {
  font-size: 32px;
  line-height: 3rem;
  font-weight: 600; }

h2,
.heading-2 {
  font-size: 28px;
  line-height: 42px;
  font-weight: 600; }

h3,
.heading-3 {
  font-size: 24px;
  line-height: 36px;
  font-weight: 600; }

h4,
.heading-4 {
  font-size: 22px;
  line-height: 33px;
  font-weight: 600; }

h5,
.heading-5 {
  font-size: 20px;
  line-height: 30px;
  font-weight: 600; }

h6,
.heading-6 {
  font-size: 18px;
  line-height: 27px;
  font-weight: 600; }

.subtitle, .fui-card.box .fui-card-content .fui-card-title {
  font-size: 18px;
  line-height: 27px; }

.subtitle-2 {
  font-size: 18px;
  line-height: 32px; }

.body {
  font-size: 18px;
  line-height: 1.75rem; }

p,
.text,
.body-1 {
  font-size: 1rem;
  line-height: 1.75rem; }

small,
.text-sm,
.body-2 {
  font-size: 0.875rem;
  line-height: 1.5rem; }

.text-mini {
  font-size: .75rem; }

.is-text-bold {
  font-weight: 600; }

.is-text-medium {
  font-weight: 500; }

.is-text-regular {
  font-weight: 400; }

.is-text-light {
  font-weight: 300; }

@media screen and (min-width: 960px) {
  h1,
  .heading-1 {
    font-size: 2.625rem;
    line-height: 3.9rem; }
  h2,
  .heading-2 {
    font-size: 2.25rem;
    line-height: 54px; }
  h3,
  .heading-3 {
    font-size: 1.75rem;
    line-height: 42px; }
  h4,
  .heading-4 {
    font-size: 1.5rem;
    line-height: 36px; }
  h5,
  .heading-5 {
    font-size: 1.375rem;
    line-height: 33px; }
  h6,
  .heading-6 {
    font-size: 1.25rem;
    line-height: 30px; }
  .subtitle, .fui-card.box .fui-card-content .fui-card-title {
    font-size: 1.125rem;
    line-height: 27px; }
  .subtitle-2 {
    display: block; } }

.MuiGrid-container {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box; }

.MuiGrid-spacing-xs-2 {
  width: calc(100% + 16px);
  margin: -8px; }
  .MuiGrid-spacing-xs-2 > .MuiGrid-item {
    padding: 8px; }

.MuiGrid-spacing-xs-3 {
  width: calc(100% + 24px);
  margin: -12px; }
  .MuiGrid-spacing-xs-3 > .MuiGrid-item {
    padding: 12px; }

.MuiGrid-spacing-xs-4 {
  width: calc(100% + 32px);
  margin: -16px; }
  .MuiGrid-spacing-xs-4 > .MuiGrid-item {
    padding: 16px; }

.MuiGrid-spacing-xs-5 {
  width: calc(100% + 40px);
  margin: -20px; }
  .MuiGrid-spacing-xs-5 > .MuiGrid-item {
    padding: 20px; }

.MuiGrid-spacing-xs-5 {
  width: calc(100% + 48px);
  margin: -24px; }
  .MuiGrid-spacing-xs-5 > .MuiGrid-item {
    padding: 24px; }

.MuiGrid-item {
  margin: 0;
  box-sizing: border-box; }

.MuiGrid-grid-12 {
  flex-grow: 0;
  max-width: 100%;
  flex-basis: 100%; }

.MuiGrid-grid-1 {
  flex-grow: 0;
  max-width: 8.33333%;
  flex-basis: 8.33333%; }

.MuiGrid-grid-2 {
  flex-grow: 0;
  max-width: 16.66667%;
  flex-basis: 16.66667%; }

.MuiGrid-grid-3 {
  flex-grow: 0;
  max-width: 25%;
  flex-basis: 25%; }

.MuiGrid-grid-4 {
  flex-grow: 0;
  max-width: 33.33333%;
  flex-basis: 33.33333%; }

.MuiGrid-grid-5 {
  flex-grow: 0;
  max-width: 41.66667%;
  flex-basis: 41.66667%; }

.MuiGrid-grid-6 {
  flex-grow: 0;
  max-width: 50%;
  flex-basis: 50%; }

.MuiGrid-grid-7 {
  flex-grow: 0;
  max-width: 58.33333%;
  flex-basis: 58.33333%; }

.MuiGrid-grid-8 {
  flex-grow: 0;
  max-width: 66.66667%;
  flex-basis: 66.66667%; }

.MuiGrid-grid-9 {
  flex-grow: 0;
  max-width: 75%;
  flex-basis: 75%; }

.MuiGrid-grid-10 {
  flex-grow: 0;
  max-width: 83.33333%;
  flex-basis: 83.33333%; }

.MuiGrid-grid-11 {
  flex-grow: 0;
  max-width: 91.66667%;
  flex-basis: 91.66667%; }

.MuiGrid-grid-12 {
  flex-grow: 0;
  max-width: 100%;
  flex-basis: 100%; }

.MuiGrid-grid-xs-12 {
  flex-grow: 0;
  max-width: 100%;
  flex-basis: 100%; }

.MuiGrid-grid-xs-1 {
  flex-grow: 0;
  max-width: 8.33333%;
  flex-basis: 8.33333%; }

.MuiGrid-grid-xs-2 {
  flex-grow: 0;
  max-width: 16.66667%;
  flex-basis: 16.66667%; }

.MuiGrid-grid-xs-3 {
  flex-grow: 0;
  max-width: 25%;
  flex-basis: 25%; }

.MuiGrid-grid-xs-4 {
  flex-grow: 0;
  max-width: 33.33333%;
  flex-basis: 33.33333%; }

.MuiGrid-grid-xs-5 {
  flex-grow: 0;
  max-width: 41.66667%;
  flex-basis: 41.66667%; }

.MuiGrid-grid-xs-6 {
  flex-grow: 0;
  max-width: 50%;
  flex-basis: 50%; }

.MuiGrid-grid-xs-7 {
  flex-grow: 0;
  max-width: 58.33333%;
  flex-basis: 58.33333%; }

.MuiGrid-grid-xs-8 {
  flex-grow: 0;
  max-width: 66.66667%;
  flex-basis: 66.66667%; }

.MuiGrid-grid-xs-9 {
  flex-grow: 0;
  max-width: 75%;
  flex-basis: 75%; }

.MuiGrid-grid-xs-10 {
  flex-grow: 0;
  max-width: 83.33333%;
  flex-basis: 83.33333%; }

.MuiGrid-grid-xs-11 {
  flex-grow: 0;
  max-width: 91.66667%;
  flex-basis: 91.66667%; }

.MuiGrid-grid-xs-12 {
  flex-grow: 0;
  max-width: 100%;
  flex-basis: 100%; }

@media screen and (min-width: 600px) {
  .MuiGrid-grid-sm-1 {
    flex-grow: 0;
    max-width: 8.33333%;
    flex-basis: 8.33333%; }
  .MuiGrid-grid-sm-2 {
    flex-grow: 0;
    max-width: 16.66667%;
    flex-basis: 16.66667%; }
  .MuiGrid-grid-sm-3 {
    flex-grow: 0;
    max-width: 25%;
    flex-basis: 25%; }
  .MuiGrid-grid-sm-4 {
    flex-grow: 0;
    max-width: 33.33333%;
    flex-basis: 33.33333%; }
  .MuiGrid-grid-sm-5 {
    flex-grow: 0;
    max-width: 41.66667%;
    flex-basis: 41.66667%; }
  .MuiGrid-grid-sm-6 {
    flex-grow: 0;
    max-width: 50%;
    flex-basis: 50%; }
  .MuiGrid-grid-sm-7 {
    flex-grow: 0;
    max-width: 58.33333%;
    flex-basis: 58.33333%; }
  .MuiGrid-grid-sm-8 {
    flex-grow: 0;
    max-width: 66.66667%;
    flex-basis: 66.66667%; }
  .MuiGrid-grid-sm-9 {
    flex-grow: 0;
    max-width: 75%;
    flex-basis: 75%; }
  .MuiGrid-grid-sm-10 {
    flex-grow: 0;
    max-width: 83.33333%;
    flex-basis: 83.33333%; }
  .MuiGrid-grid-sm-11 {
    flex-grow: 0;
    max-width: 91.66667%;
    flex-basis: 91.66667%; }
  .MuiGrid-grid-sm-12 {
    flex-grow: 0;
    max-width: 100%;
    flex-basis: 100%; } }

@media screen and (min-width: 960px) {
  .MuiGrid-grid-md-1 {
    flex-grow: 0;
    max-width: 8.33333%;
    flex-basis: 8.33333%; }
  .MuiGrid-grid-md-2 {
    flex-grow: 0;
    max-width: 16.66667%;
    flex-basis: 16.66667%; }
  .MuiGrid-grid-md-3 {
    flex-grow: 0;
    max-width: 25%;
    flex-basis: 25%; }
  .MuiGrid-grid-md-4 {
    flex-grow: 0;
    max-width: 33.33333%;
    flex-basis: 33.33333%; }
  .MuiGrid-grid-md-5 {
    flex-grow: 0;
    max-width: 41.66667%;
    flex-basis: 41.66667%; }
  .MuiGrid-grid-md-6 {
    flex-grow: 0;
    max-width: 50%;
    flex-basis: 50%; }
  .MuiGrid-grid-md-7 {
    flex-grow: 0;
    max-width: 58.33333%;
    flex-basis: 58.33333%; }
  .MuiGrid-grid-md-8 {
    flex-grow: 0;
    max-width: 66.66667%;
    flex-basis: 66.66667%; }
  .MuiGrid-grid-md-9 {
    flex-grow: 0;
    max-width: 75%;
    flex-basis: 75%; }
  .MuiGrid-grid-md-10 {
    flex-grow: 0;
    max-width: 83.33333%;
    flex-basis: 83.33333%; }
  .MuiGrid-grid-md-11 {
    flex-grow: 0;
    max-width: 91.66667%;
    flex-basis: 91.66667%; }
  .MuiGrid-grid-md-12 {
    flex-grow: 0;
    max-width: 100%;
    flex-basis: 100%; } }

.fui-container {
  position: relative;
  width: 100%;
  max-width: 1200px;
  padding: 0 15px;
  margin: 0 auto; }
  .fui-container.mb-120 {
    margin-bottom: 120px; }
  @media screen and (min-width: 960px) {
    .fui-container {
      width: 90%; } }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

.d-none {
  display: none !important; }

.overflow-visible {
  overflow: visible !important; }

@media (min-width: 600px) {
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; }
  .d-sm-none {
    display: none !important; } }

@media screen and (min-width: 960px) {
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; }
  .d-md-none {
    display: none !important; } }

@media screen and (min-width: 1280px) {
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; }
  .d-lg-none {
    display: none !important; } }

@media screen and (min-width: 1920px) {
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; }
  .d-xl-none {
    display: none !important; } }

.is-text-main {
  color: #D3060F !important; }

.is-text-accent {
  color: #EF3123 !important; }

.is-text-error {
  color: #f05c38 !important; }

.is-text-red30 {
  color: #EA574D !important; }

.is-text-red50 {
  color: #CD443A !important; }

.is-text-red70 {
  color: #BC392F !important; }

.is-text-red100 {
  color: #AA362E !important; }

.is-text-coral30 {
  color: #FFA188 !important; }

.is-text-coral50 {
  color: #FF9173 !important; }

.is-text-coral70 {
  color: #FD8768 !important; }

.is-text-coral100 {
  color: #FC7B66 !important; }

.is-text-yellow30 {
  color: #FCDC6D !important; }

.is-text-yellow50 {
  color: #F3CF63 !important; }

.is-text-yellow70 {
  color: #F0C852 !important; }

.is-text-yellow100 {
  color: #ECC141 !important; }

.is-text-blue30 {
  color: #9EDFE9 !important; }

.is-text-blue50 {
  color: #85D1DC !important; }

.is-text-blue70 {
  color: #77CCD8 !important; }

.is-text-blue100 {
  color: #5CC3D2 !important; }

.is-text-business30 {
  color: #3966DA !important; }

.is-text-business50 {
  color: #2F59C4 !important; }

.is-text-business70 {
  color: #204CBE !important; }

.is-text-business100 {
  color: #1642B1 !important; }

.is-text-white {
  color: #FFFFFF !important; }

.is-text-lightgray50 {
  color: #fafafa !important; }

.is-text-lightgray100 {
  color: #E8ECF0 !important; }

.is-text-gray50 {
  color: #D6D6D6 !important; }

.is-text-gray70 {
  color: #BFBFBF !important; }

.is-text-gray100 {
  color: #8A8A8A !important; }

.is-text-gray110 {
  color: #787878 !important; }

.is-text-darkgray50 {
  color: #5F6E84 !important; }

.is-text-darkgray70 {
  color: #4F5E72 !important; }

.is-text-darkgray100 {
  color: #4B5869 !important; }

.is-text-black50 {
  color: #202020 !important; }

.is-text-black70 {
  color: #1B1B1B !important; }

.is-text-black100 {
  color: #141414 !important; }

.is-text-black110 {
  color: #1e1e1e !important; }

.is-text-green50 {
  color: #25b133 !important; }

.is-text-green100 {
  color: #50A64E !important; }

.is-text-darkgreen {
  color: #5f7b80; }

.is-life-circle-gray {
  color: #5f7b80 !important; }

.is-text-grayblue {
  color: #6a798e !important; }

.is-life-circle-gray-70 {
  color: #465a5e !important; }

.is-bg-canary {
  background-color: #f6fb6b; }

.is-bg-initial {
  background-color: transparent !important; }

.is-bg-main {
  background-color: #D3060F !important; }

.is-bg-accent {
  background-color: #EF3123 !important; }

.is-bg-red30 {
  background-color: #EA574D !important; }

.is-bg-red50 {
  background-color: #CD443A !important; }

.is-bg-red70 {
  background-color: #BC392F !important; }

.is-bg-red100 {
  background-color: #AA362E !important; }

.is-bg-coral30 {
  background-color: #FFA188 !important; }

.is-bg-coral50 {
  background-color: #FF9173 !important; }

.is-bg-coral70 {
  background-color: #FD8768 !important; }

.is-bg-coral100 {
  background-color: #FC7B66 !important; }

.is-bg-yellow30 {
  background-color: #FCDC6D !important; }

.is-bg-yellow50 {
  background-color: #F3CF63 !important; }

.is-bg-yellow70 {
  background-color: #F0C852 !important; }

.is-bg-yellow100 {
  background-color: #ECC141 !important; }

.is-bg-blue30 {
  background-color: #9EDFE9 !important; }

.is-bg-blue50 {
  background-color: #85D1DC !important; }

.is-bg-blue70 {
  background-color: #77CCD8 !important; }

.is-bg-blue100 {
  background-color: #5CC3D2 !important; }

.is-bg-business30 {
  background-color: #3966DA !important; }

.is-bg-business50 {
  background-color: #2F59C4 !important; }

.is-bg-business70 {
  background-color: #204CBE !important; }

.is-bg-business100 {
  background-color: #1642B1 !important; }

.is-bg-white {
  background-color: #FFFFFF !important; }

.is-bg-ice {
  background-color: #eff4f1 !important; }

.is-bg-none {
  background: none !important; }

.is-bg-lightgray50 {
  background-color: #fafafa !important; }

.is-bg-lightgray70 {
  background-color: #f1f3f5 !important; }

.is-bg-lightgray100 {
  background-color: #E8ECF0 !important; }

.is-bg-gray30 {
  background-color: #EBEBEB !important; }

.is-bg-gray50 {
  background-color: #D6D6D6 !important; }

.is-bg-gray70 {
  background-color: #BFBFBF !important; }

.is-bg-gray100 {
  background-color: #8A8A8A !important; }

.is-bg-darkgray50 {
  background-color: #5F6E84 !important; }

.is-bg-darkgray70 {
  background-color: #4F5E72 !important; }

.is-bg-darkgray100 {
  background-color: #4B5869 !important; }

.is-bg-black50 {
  background-color: #202020 !important; }

.is-bg-black70 {
  background-color: #1B1B1B !important; }

.is-bg-black100 {
  background-color: #141414 !important; }

.m-auto {
  margin: auto; }

.my-auto {
  margin-top: auto;
  margin-bottom: auto; }

.mx-auto {
  margin-left: auto;
  margin-right: auto; }

.m-0 {
  margin: 0px !important; }

.my-0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important; }

.mx-0 {
  margin-left: 0px !important;
  margin-right: 0px !important; }

.mt-0 {
  margin-top: 0px !important; }

.ml-0 {
  margin-left: 0px !important; }

.mr-0 {
  margin-right: 0px !important; }

.mb-0 {
  margin-bottom: 0px !important; }

.p-0 {
  padding: 0px !important; }

.py-0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important; }

.px-0 {
  padding-left: 0px !important;
  padding-right: 0px !important; }

.pt-0 {
  padding-top: 0px !important; }

.pl-0 {
  padding-left: 0px !important; }

.pr-0 {
  padding-right: 0px !important; }

.pb-0 {
  padding-bottom: 0px !important; }

.m-1 {
  margin: 8px !important; }

.my-1 {
  margin-top: 8px !important;
  margin-bottom: 8px !important; }

.mx-1 {
  margin-left: 8px !important;
  margin-right: 8px !important; }

.mt-1 {
  margin-top: 8px !important; }

.ml-1 {
  margin-left: 8px !important; }

.mr-1 {
  margin-right: 8px !important; }

.mb-1 {
  margin-bottom: 8px !important; }

.p-1 {
  padding: 8px !important; }

.py-1 {
  padding-top: 8px !important;
  padding-bottom: 8px !important; }

.px-1 {
  padding-left: 8px !important;
  padding-right: 8px !important; }

.pt-1 {
  padding-top: 8px !important; }

.pl-1 {
  padding-left: 8px !important; }

.pr-1 {
  padding-right: 8px !important; }

.pb-1 {
  padding-bottom: 8px !important; }

.m-2 {
  margin: 16px !important; }

.my-2 {
  margin-top: 16px !important;
  margin-bottom: 16px !important; }

.mx-2 {
  margin-left: 16px !important;
  margin-right: 16px !important; }

.mt-2 {
  margin-top: 16px !important; }

.ml-2 {
  margin-left: 16px !important; }

.mr-2 {
  margin-right: 16px !important; }

.mb-2 {
  margin-bottom: 16px !important; }

.p-2 {
  padding: 16px !important; }

.py-2 {
  padding-top: 16px !important;
  padding-bottom: 16px !important; }

.px-2 {
  padding-left: 16px !important;
  padding-right: 16px !important; }

.pt-2 {
  padding-top: 16px !important; }

.pl-2 {
  padding-left: 16px !important; }

.pr-2 {
  padding-right: 16px !important; }

.pb-2 {
  padding-bottom: 16px !important; }

.m-3 {
  margin: 24px !important; }

.my-3 {
  margin-top: 24px !important;
  margin-bottom: 24px !important; }

.mx-3 {
  margin-left: 24px !important;
  margin-right: 24px !important; }

.mt-3 {
  margin-top: 24px !important; }

.ml-3 {
  margin-left: 24px !important; }

.mr-3 {
  margin-right: 24px !important; }

.mb-3 {
  margin-bottom: 24px !important; }

.p-3 {
  padding: 24px !important; }

.py-3 {
  padding-top: 24px !important;
  padding-bottom: 24px !important; }

.px-3 {
  padding-left: 24px !important;
  padding-right: 24px !important; }

.pt-3 {
  padding-top: 24px !important; }

.pl-3 {
  padding-left: 24px !important; }

.pr-3 {
  padding-right: 24px !important; }

.pb-3 {
  padding-bottom: 24px !important; }

.m-4 {
  margin: 32px !important; }

.my-4 {
  margin-top: 32px !important;
  margin-bottom: 32px !important; }

.mx-4 {
  margin-left: 32px !important;
  margin-right: 32px !important; }

.mt-4 {
  margin-top: 32px !important; }

.ml-4 {
  margin-left: 32px !important; }

.mr-4 {
  margin-right: 32px !important; }

.mb-4 {
  margin-bottom: 32px !important; }

.p-4 {
  padding: 32px !important; }

.py-4 {
  padding-top: 32px !important;
  padding-bottom: 32px !important; }

.px-4 {
  padding-left: 32px !important;
  padding-right: 32px !important; }

.pt-4 {
  padding-top: 32px !important; }

.pl-4 {
  padding-left: 32px !important; }

.pr-4 {
  padding-right: 32px !important; }

.pb-4 {
  padding-bottom: 32px !important; }

.m-5 {
  margin: 40px !important; }

.my-5 {
  margin-top: 40px !important;
  margin-bottom: 40px !important; }

.mx-5 {
  margin-left: 40px !important;
  margin-right: 40px !important; }

.mt-5 {
  margin-top: 40px !important; }

.ml-5 {
  margin-left: 40px !important; }

.mr-5 {
  margin-right: 40px !important; }

.mb-5 {
  margin-bottom: 40px !important; }

.p-5 {
  padding: 40px !important; }

.py-5 {
  padding-top: 40px !important;
  padding-bottom: 40px !important; }

.px-5 {
  padding-left: 40px !important;
  padding-right: 40px !important; }

.pt-5 {
  padding-top: 40px !important; }

.pl-5 {
  padding-left: 40px !important; }

.pr-5 {
  padding-right: 40px !important; }

.pb-5 {
  padding-bottom: 40px !important; }

.m-6 {
  margin: 48px !important; }

.my-6 {
  margin-top: 48px !important;
  margin-bottom: 48px !important; }

.mx-6 {
  margin-left: 48px !important;
  margin-right: 48px !important; }

.mt-6 {
  margin-top: 48px !important; }

.ml-6 {
  margin-left: 48px !important; }

.mr-6 {
  margin-right: 48px !important; }

.mb-6 {
  margin-bottom: 48px !important; }

.p-6 {
  padding: 48px !important; }

.py-6 {
  padding-top: 48px !important;
  padding-bottom: 48px !important; }

.px-6 {
  padding-left: 48px !important;
  padding-right: 48px !important; }

.pt-6 {
  padding-top: 48px !important; }

.pl-6 {
  padding-left: 48px !important; }

.pr-6 {
  padding-right: 48px !important; }

.pb-6 {
  padding-bottom: 48px !important; }

.m-7 {
  margin: 56px !important; }

.my-7 {
  margin-top: 56px !important;
  margin-bottom: 56px !important; }

.mx-7 {
  margin-left: 56px !important;
  margin-right: 56px !important; }

.mt-7 {
  margin-top: 56px !important; }

.ml-7 {
  margin-left: 56px !important; }

.mr-7 {
  margin-right: 56px !important; }

.mb-7 {
  margin-bottom: 56px !important; }

.p-7 {
  padding: 56px !important; }

.py-7 {
  padding-top: 56px !important;
  padding-bottom: 56px !important; }

.px-7 {
  padding-left: 56px !important;
  padding-right: 56px !important; }

.pt-7 {
  padding-top: 56px !important; }

.pl-7 {
  padding-left: 56px !important; }

.pr-7 {
  padding-right: 56px !important; }

.pb-7 {
  padding-bottom: 56px !important; }

.m-8 {
  margin: 64px !important; }

.my-8 {
  margin-top: 64px !important;
  margin-bottom: 64px !important; }

.mx-8 {
  margin-left: 64px !important;
  margin-right: 64px !important; }

.mt-8 {
  margin-top: 64px !important; }

.ml-8 {
  margin-left: 64px !important; }

.mr-8 {
  margin-right: 64px !important; }

.mb-8 {
  margin-bottom: 64px !important; }

.p-8 {
  padding: 64px !important; }

.py-8 {
  padding-top: 64px !important;
  padding-bottom: 64px !important; }

.px-8 {
  padding-left: 64px !important;
  padding-right: 64px !important; }

.pt-8 {
  padding-top: 64px !important; }

.pl-8 {
  padding-left: 64px !important; }

.pr-8 {
  padding-right: 64px !important; }

.pb-8 {
  padding-bottom: 64px !important; }

.m-9 {
  margin: 72px !important; }

.my-9 {
  margin-top: 72px !important;
  margin-bottom: 72px !important; }

.mx-9 {
  margin-left: 72px !important;
  margin-right: 72px !important; }

.mt-9 {
  margin-top: 72px !important; }

.ml-9 {
  margin-left: 72px !important; }

.mr-9 {
  margin-right: 72px !important; }

.mb-9 {
  margin-bottom: 72px !important; }

.p-9 {
  padding: 72px !important; }

.py-9 {
  padding-top: 72px !important;
  padding-bottom: 72px !important; }

.px-9 {
  padding-left: 72px !important;
  padding-right: 72px !important; }

.pt-9 {
  padding-top: 72px !important; }

.pl-9 {
  padding-left: 72px !important; }

.pr-9 {
  padding-right: 72px !important; }

.pb-9 {
  padding-bottom: 72px !important; }

.m-10 {
  margin: 80px !important; }

.my-10 {
  margin-top: 80px !important;
  margin-bottom: 80px !important; }

.mx-10 {
  margin-left: 80px !important;
  margin-right: 80px !important; }

.mt-10 {
  margin-top: 80px !important; }

.ml-10 {
  margin-left: 80px !important; }

.mr-10 {
  margin-right: 80px !important; }

.mb-10 {
  margin-bottom: 80px !important; }

.p-10 {
  padding: 80px !important; }

.py-10 {
  padding-top: 80px !important;
  padding-bottom: 80px !important; }

.px-10 {
  padding-left: 80px !important;
  padding-right: 80px !important; }

.pt-10 {
  padding-top: 80px !important; }

.pl-10 {
  padding-left: 80px !important; }

.pr-10 {
  padding-right: 80px !important; }

.pb-10 {
  padding-bottom: 80px !important; }

@media screen and (min-width: 768px) {
  .m-md-0 {
    margin: 0px !important; }
  .my-md-0 {
    margin-top: 0px !important;
    margin-bottom: 0px !important; }
  .mx-md-0 {
    margin-left: 0px !important;
    margin-right: 0px !important; }
  .mt-md-0 {
    margin-top: 0px !important; }
  .ml-md-0 {
    margin-left: 0px !important; }
  .mr-md-0 {
    margin-right: 0px !important; }
  .mb-md-0 {
    margin-bottom: 0px !important; }
  .p-md-0 {
    padding: 0px !important; }
  .py-md-0 {
    padding-top: 0px !important;
    padding-bottom: 0px !important; }
  .px-md-0 {
    padding-left: 0px !important;
    padding-right: 0px !important; }
  .pt-md-0 {
    padding-top: 0px !important; }
  .pl-md-0 {
    padding-left: 0px !important; }
  .pr-md-0 {
    padding-right: 0px !important; }
  .pb-md-0 {
    padding-bottom: 0px !important; }
  .m-md-1 {
    margin: 8px !important; }
  .my-md-1 {
    margin-top: 8px !important;
    margin-bottom: 8px !important; }
  .mx-md-1 {
    margin-left: 8px !important;
    margin-right: 8px !important; }
  .mt-md-1 {
    margin-top: 8px !important; }
  .ml-md-1 {
    margin-left: 8px !important; }
  .mr-md-1 {
    margin-right: 8px !important; }
  .mb-md-1 {
    margin-bottom: 8px !important; }
  .p-md-1 {
    padding: 8px !important; }
  .py-md-1 {
    padding-top: 8px !important;
    padding-bottom: 8px !important; }
  .px-md-1 {
    padding-left: 8px !important;
    padding-right: 8px !important; }
  .pt-md-1 {
    padding-top: 8px !important; }
  .pl-md-1 {
    padding-left: 8px !important; }
  .pr-md-1 {
    padding-right: 8px !important; }
  .pb-md-1 {
    padding-bottom: 8px !important; }
  .m-md-2 {
    margin: 16px !important; }
  .my-md-2 {
    margin-top: 16px !important;
    margin-bottom: 16px !important; }
  .mx-md-2 {
    margin-left: 16px !important;
    margin-right: 16px !important; }
  .mt-md-2 {
    margin-top: 16px !important; }
  .ml-md-2 {
    margin-left: 16px !important; }
  .mr-md-2 {
    margin-right: 16px !important; }
  .mb-md-2 {
    margin-bottom: 16px !important; }
  .p-md-2 {
    padding: 16px !important; }
  .py-md-2 {
    padding-top: 16px !important;
    padding-bottom: 16px !important; }
  .px-md-2 {
    padding-left: 16px !important;
    padding-right: 16px !important; }
  .pt-md-2 {
    padding-top: 16px !important; }
  .pl-md-2 {
    padding-left: 16px !important; }
  .pr-md-2 {
    padding-right: 16px !important; }
  .pb-md-2 {
    padding-bottom: 16px !important; }
  .m-md-3 {
    margin: 24px !important; }
  .my-md-3 {
    margin-top: 24px !important;
    margin-bottom: 24px !important; }
  .mx-md-3 {
    margin-left: 24px !important;
    margin-right: 24px !important; }
  .mt-md-3 {
    margin-top: 24px !important; }
  .ml-md-3 {
    margin-left: 24px !important; }
  .mr-md-3 {
    margin-right: 24px !important; }
  .mb-md-3 {
    margin-bottom: 24px !important; }
  .p-md-3 {
    padding: 24px !important; }
  .py-md-3 {
    padding-top: 24px !important;
    padding-bottom: 24px !important; }
  .px-md-3 {
    padding-left: 24px !important;
    padding-right: 24px !important; }
  .pt-md-3 {
    padding-top: 24px !important; }
  .pl-md-3 {
    padding-left: 24px !important; }
  .pr-md-3 {
    padding-right: 24px !important; }
  .pb-md-3 {
    padding-bottom: 24px !important; }
  .m-md-4 {
    margin: 32px !important; }
  .my-md-4 {
    margin-top: 32px !important;
    margin-bottom: 32px !important; }
  .mx-md-4 {
    margin-left: 32px !important;
    margin-right: 32px !important; }
  .mt-md-4 {
    margin-top: 32px !important; }
  .ml-md-4 {
    margin-left: 32px !important; }
  .mr-md-4 {
    margin-right: 32px !important; }
  .mb-md-4 {
    margin-bottom: 32px !important; }
  .p-md-4 {
    padding: 32px !important; }
  .py-md-4 {
    padding-top: 32px !important;
    padding-bottom: 32px !important; }
  .px-md-4 {
    padding-left: 32px !important;
    padding-right: 32px !important; }
  .pt-md-4 {
    padding-top: 32px !important; }
  .pl-md-4 {
    padding-left: 32px !important; }
  .pr-md-4 {
    padding-right: 32px !important; }
  .pb-md-4 {
    padding-bottom: 32px !important; }
  .m-md-5 {
    margin: 40px !important; }
  .my-md-5 {
    margin-top: 40px !important;
    margin-bottom: 40px !important; }
  .mx-md-5 {
    margin-left: 40px !important;
    margin-right: 40px !important; }
  .mt-md-5 {
    margin-top: 40px !important; }
  .ml-md-5 {
    margin-left: 40px !important; }
  .mr-md-5 {
    margin-right: 40px !important; }
  .mb-md-5 {
    margin-bottom: 40px !important; }
  .p-md-5 {
    padding: 40px !important; }
  .py-md-5 {
    padding-top: 40px !important;
    padding-bottom: 40px !important; }
  .px-md-5 {
    padding-left: 40px !important;
    padding-right: 40px !important; }
  .pt-md-5 {
    padding-top: 40px !important; }
  .pl-md-5 {
    padding-left: 40px !important; }
  .pr-md-5 {
    padding-right: 40px !important; }
  .pb-md-5 {
    padding-bottom: 40px !important; }
  .m-md-6 {
    margin: 48px !important; }
  .my-md-6 {
    margin-top: 48px !important;
    margin-bottom: 48px !important; }
  .mx-md-6 {
    margin-left: 48px !important;
    margin-right: 48px !important; }
  .mt-md-6 {
    margin-top: 48px !important; }
  .ml-md-6 {
    margin-left: 48px !important; }
  .mr-md-6 {
    margin-right: 48px !important; }
  .mb-md-6 {
    margin-bottom: 48px !important; }
  .p-md-6 {
    padding: 48px !important; }
  .py-md-6 {
    padding-top: 48px !important;
    padding-bottom: 48px !important; }
  .px-md-6 {
    padding-left: 48px !important;
    padding-right: 48px !important; }
  .pt-md-6 {
    padding-top: 48px !important; }
  .pl-md-6 {
    padding-left: 48px !important; }
  .pr-md-6 {
    padding-right: 48px !important; }
  .pb-md-6 {
    padding-bottom: 48px !important; }
  .m-md-7 {
    margin: 56px !important; }
  .my-md-7 {
    margin-top: 56px !important;
    margin-bottom: 56px !important; }
  .mx-md-7 {
    margin-left: 56px !important;
    margin-right: 56px !important; }
  .mt-md-7 {
    margin-top: 56px !important; }
  .ml-md-7 {
    margin-left: 56px !important; }
  .mr-md-7 {
    margin-right: 56px !important; }
  .mb-md-7 {
    margin-bottom: 56px !important; }
  .p-md-7 {
    padding: 56px !important; }
  .py-md-7 {
    padding-top: 56px !important;
    padding-bottom: 56px !important; }
  .px-md-7 {
    padding-left: 56px !important;
    padding-right: 56px !important; }
  .pt-md-7 {
    padding-top: 56px !important; }
  .pl-md-7 {
    padding-left: 56px !important; }
  .pr-md-7 {
    padding-right: 56px !important; }
  .pb-md-7 {
    padding-bottom: 56px !important; }
  .m-md-8 {
    margin: 64px !important; }
  .my-md-8 {
    margin-top: 64px !important;
    margin-bottom: 64px !important; }
  .mx-md-8 {
    margin-left: 64px !important;
    margin-right: 64px !important; }
  .mt-md-8 {
    margin-top: 64px !important; }
  .ml-md-8 {
    margin-left: 64px !important; }
  .mr-md-8 {
    margin-right: 64px !important; }
  .mb-md-8 {
    margin-bottom: 64px !important; }
  .p-md-8 {
    padding: 64px !important; }
  .py-md-8 {
    padding-top: 64px !important;
    padding-bottom: 64px !important; }
  .px-md-8 {
    padding-left: 64px !important;
    padding-right: 64px !important; }
  .pt-md-8 {
    padding-top: 64px !important; }
  .pl-md-8 {
    padding-left: 64px !important; }
  .pr-md-8 {
    padding-right: 64px !important; }
  .pb-md-8 {
    padding-bottom: 64px !important; }
  .m-md-9 {
    margin: 72px !important; }
  .my-md-9 {
    margin-top: 72px !important;
    margin-bottom: 72px !important; }
  .mx-md-9 {
    margin-left: 72px !important;
    margin-right: 72px !important; }
  .mt-md-9 {
    margin-top: 72px !important; }
  .ml-md-9 {
    margin-left: 72px !important; }
  .mr-md-9 {
    margin-right: 72px !important; }
  .mb-md-9 {
    margin-bottom: 72px !important; }
  .p-md-9 {
    padding: 72px !important; }
  .py-md-9 {
    padding-top: 72px !important;
    padding-bottom: 72px !important; }
  .px-md-9 {
    padding-left: 72px !important;
    padding-right: 72px !important; }
  .pt-md-9 {
    padding-top: 72px !important; }
  .pl-md-9 {
    padding-left: 72px !important; }
  .pr-md-9 {
    padding-right: 72px !important; }
  .pb-md-9 {
    padding-bottom: 72px !important; }
  .m-md-10 {
    margin: 80px !important; }
  .my-md-10 {
    margin-top: 80px !important;
    margin-bottom: 80px !important; }
  .mx-md-10 {
    margin-left: 80px !important;
    margin-right: 80px !important; }
  .mt-md-10 {
    margin-top: 80px !important; }
  .ml-md-10 {
    margin-left: 80px !important; }
  .mr-md-10 {
    margin-right: 80px !important; }
  .mb-md-10 {
    margin-bottom: 80px !important; }
  .p-md-10 {
    padding: 80px !important; }
  .py-md-10 {
    padding-top: 80px !important;
    padding-bottom: 80px !important; }
  .px-md-10 {
    padding-left: 80px !important;
    padding-right: 80px !important; }
  .pt-md-10 {
    padding-top: 80px !important; }
  .pl-md-10 {
    padding-left: 80px !important; }
  .pr-md-10 {
    padding-right: 80px !important; }
  .pb-md-10 {
    padding-bottom: 80px !important; } }

@media screen and (min-width: 960px) {
  .m-lg-0 {
    margin: 0px !important; }
  .my-lg-0 {
    margin-top: 0px !important;
    margin-bottom: 0px !important; }
  .mx-lg-0 {
    margin-left: 0px !important;
    margin-right: 0px !important; }
  .mt-lg-0 {
    margin-top: 0px !important; }
  .ml-lg-0 {
    margin-left: 0px !important; }
  .mr-lg-0 {
    margin-right: 0px !important; }
  .mb-lg-0 {
    margin-bottom: 0px !important; }
  .p-lg-0 {
    padding: 0px !important; }
  .py-lg-0 {
    padding-top: 0px !important;
    padding-bottom: 0px !important; }
  .px-lg-0 {
    padding-left: 0px !important;
    padding-right: 0px !important; }
  .pt-lg-0 {
    padding-top: 0px !important; }
  .pl-lg-0 {
    padding-left: 0px !important; }
  .pr-lg-0 {
    padding-right: 0px !important; }
  .pb-lg-0 {
    padding-bottom: 0px !important; }
  .m-lg-1 {
    margin: 8px !important; }
  .my-lg-1 {
    margin-top: 8px !important;
    margin-bottom: 8px !important; }
  .mx-lg-1 {
    margin-left: 8px !important;
    margin-right: 8px !important; }
  .mt-lg-1 {
    margin-top: 8px !important; }
  .ml-lg-1 {
    margin-left: 8px !important; }
  .mr-lg-1 {
    margin-right: 8px !important; }
  .mb-lg-1 {
    margin-bottom: 8px !important; }
  .p-lg-1 {
    padding: 8px !important; }
  .py-lg-1 {
    padding-top: 8px !important;
    padding-bottom: 8px !important; }
  .px-lg-1 {
    padding-left: 8px !important;
    padding-right: 8px !important; }
  .pt-lg-1 {
    padding-top: 8px !important; }
  .pl-lg-1 {
    padding-left: 8px !important; }
  .pr-lg-1 {
    padding-right: 8px !important; }
  .pb-lg-1 {
    padding-bottom: 8px !important; }
  .m-lg-2 {
    margin: 16px !important; }
  .my-lg-2 {
    margin-top: 16px !important;
    margin-bottom: 16px !important; }
  .mx-lg-2 {
    margin-left: 16px !important;
    margin-right: 16px !important; }
  .mt-lg-2 {
    margin-top: 16px !important; }
  .ml-lg-2 {
    margin-left: 16px !important; }
  .mr-lg-2 {
    margin-right: 16px !important; }
  .mb-lg-2 {
    margin-bottom: 16px !important; }
  .p-lg-2 {
    padding: 16px !important; }
  .py-lg-2 {
    padding-top: 16px !important;
    padding-bottom: 16px !important; }
  .px-lg-2 {
    padding-left: 16px !important;
    padding-right: 16px !important; }
  .pt-lg-2 {
    padding-top: 16px !important; }
  .pl-lg-2 {
    padding-left: 16px !important; }
  .pr-lg-2 {
    padding-right: 16px !important; }
  .pb-lg-2 {
    padding-bottom: 16px !important; }
  .m-lg-3 {
    margin: 24px !important; }
  .my-lg-3 {
    margin-top: 24px !important;
    margin-bottom: 24px !important; }
  .mx-lg-3 {
    margin-left: 24px !important;
    margin-right: 24px !important; }
  .mt-lg-3 {
    margin-top: 24px !important; }
  .ml-lg-3 {
    margin-left: 24px !important; }
  .mr-lg-3 {
    margin-right: 24px !important; }
  .mb-lg-3 {
    margin-bottom: 24px !important; }
  .p-lg-3 {
    padding: 24px !important; }
  .py-lg-3 {
    padding-top: 24px !important;
    padding-bottom: 24px !important; }
  .px-lg-3 {
    padding-left: 24px !important;
    padding-right: 24px !important; }
  .pt-lg-3 {
    padding-top: 24px !important; }
  .pl-lg-3 {
    padding-left: 24px !important; }
  .pr-lg-3 {
    padding-right: 24px !important; }
  .pb-lg-3 {
    padding-bottom: 24px !important; }
  .m-lg-4 {
    margin: 32px !important; }
  .my-lg-4 {
    margin-top: 32px !important;
    margin-bottom: 32px !important; }
  .mx-lg-4 {
    margin-left: 32px !important;
    margin-right: 32px !important; }
  .mt-lg-4 {
    margin-top: 32px !important; }
  .ml-lg-4 {
    margin-left: 32px !important; }
  .mr-lg-4 {
    margin-right: 32px !important; }
  .mb-lg-4 {
    margin-bottom: 32px !important; }
  .p-lg-4 {
    padding: 32px !important; }
  .py-lg-4 {
    padding-top: 32px !important;
    padding-bottom: 32px !important; }
  .px-lg-4 {
    padding-left: 32px !important;
    padding-right: 32px !important; }
  .pt-lg-4 {
    padding-top: 32px !important; }
  .pl-lg-4 {
    padding-left: 32px !important; }
  .pr-lg-4 {
    padding-right: 32px !important; }
  .pb-lg-4 {
    padding-bottom: 32px !important; }
  .m-lg-5 {
    margin: 40px !important; }
  .my-lg-5 {
    margin-top: 40px !important;
    margin-bottom: 40px !important; }
  .mx-lg-5 {
    margin-left: 40px !important;
    margin-right: 40px !important; }
  .mt-lg-5 {
    margin-top: 40px !important; }
  .ml-lg-5 {
    margin-left: 40px !important; }
  .mr-lg-5 {
    margin-right: 40px !important; }
  .mb-lg-5 {
    margin-bottom: 40px !important; }
  .p-lg-5 {
    padding: 40px !important; }
  .py-lg-5 {
    padding-top: 40px !important;
    padding-bottom: 40px !important; }
  .px-lg-5 {
    padding-left: 40px !important;
    padding-right: 40px !important; }
  .pt-lg-5 {
    padding-top: 40px !important; }
  .pl-lg-5 {
    padding-left: 40px !important; }
  .pr-lg-5 {
    padding-right: 40px !important; }
  .pb-lg-5 {
    padding-bottom: 40px !important; }
  .m-lg-6 {
    margin: 48px !important; }
  .my-lg-6 {
    margin-top: 48px !important;
    margin-bottom: 48px !important; }
  .mx-lg-6 {
    margin-left: 48px !important;
    margin-right: 48px !important; }
  .mt-lg-6 {
    margin-top: 48px !important; }
  .ml-lg-6 {
    margin-left: 48px !important; }
  .mr-lg-6 {
    margin-right: 48px !important; }
  .mb-lg-6 {
    margin-bottom: 48px !important; }
  .p-lg-6 {
    padding: 48px !important; }
  .py-lg-6 {
    padding-top: 48px !important;
    padding-bottom: 48px !important; }
  .px-lg-6 {
    padding-left: 48px !important;
    padding-right: 48px !important; }
  .pt-lg-6 {
    padding-top: 48px !important; }
  .pl-lg-6 {
    padding-left: 48px !important; }
  .pr-lg-6 {
    padding-right: 48px !important; }
  .pb-lg-6 {
    padding-bottom: 48px !important; }
  .m-lg-7 {
    margin: 56px !important; }
  .my-lg-7 {
    margin-top: 56px !important;
    margin-bottom: 56px !important; }
  .mx-lg-7 {
    margin-left: 56px !important;
    margin-right: 56px !important; }
  .mt-lg-7 {
    margin-top: 56px !important; }
  .ml-lg-7 {
    margin-left: 56px !important; }
  .mr-lg-7 {
    margin-right: 56px !important; }
  .mb-lg-7 {
    margin-bottom: 56px !important; }
  .p-lg-7 {
    padding: 56px !important; }
  .py-lg-7 {
    padding-top: 56px !important;
    padding-bottom: 56px !important; }
  .px-lg-7 {
    padding-left: 56px !important;
    padding-right: 56px !important; }
  .pt-lg-7 {
    padding-top: 56px !important; }
  .pl-lg-7 {
    padding-left: 56px !important; }
  .pr-lg-7 {
    padding-right: 56px !important; }
  .pb-lg-7 {
    padding-bottom: 56px !important; }
  .m-lg-8 {
    margin: 64px !important; }
  .my-lg-8 {
    margin-top: 64px !important;
    margin-bottom: 64px !important; }
  .mx-lg-8 {
    margin-left: 64px !important;
    margin-right: 64px !important; }
  .mt-lg-8 {
    margin-top: 64px !important; }
  .ml-lg-8 {
    margin-left: 64px !important; }
  .mr-lg-8 {
    margin-right: 64px !important; }
  .mb-lg-8 {
    margin-bottom: 64px !important; }
  .p-lg-8 {
    padding: 64px !important; }
  .py-lg-8 {
    padding-top: 64px !important;
    padding-bottom: 64px !important; }
  .px-lg-8 {
    padding-left: 64px !important;
    padding-right: 64px !important; }
  .pt-lg-8 {
    padding-top: 64px !important; }
  .pl-lg-8 {
    padding-left: 64px !important; }
  .pr-lg-8 {
    padding-right: 64px !important; }
  .pb-lg-8 {
    padding-bottom: 64px !important; }
  .m-lg-9 {
    margin: 72px !important; }
  .my-lg-9 {
    margin-top: 72px !important;
    margin-bottom: 72px !important; }
  .mx-lg-9 {
    margin-left: 72px !important;
    margin-right: 72px !important; }
  .mt-lg-9 {
    margin-top: 72px !important; }
  .ml-lg-9 {
    margin-left: 72px !important; }
  .mr-lg-9 {
    margin-right: 72px !important; }
  .mb-lg-9 {
    margin-bottom: 72px !important; }
  .p-lg-9 {
    padding: 72px !important; }
  .py-lg-9 {
    padding-top: 72px !important;
    padding-bottom: 72px !important; }
  .px-lg-9 {
    padding-left: 72px !important;
    padding-right: 72px !important; }
  .pt-lg-9 {
    padding-top: 72px !important; }
  .pl-lg-9 {
    padding-left: 72px !important; }
  .pr-lg-9 {
    padding-right: 72px !important; }
  .pb-lg-9 {
    padding-bottom: 72px !important; }
  .m-lg-10 {
    margin: 80px !important; }
  .my-lg-10 {
    margin-top: 80px !important;
    margin-bottom: 80px !important; }
  .mx-lg-10 {
    margin-left: 80px !important;
    margin-right: 80px !important; }
  .mt-lg-10 {
    margin-top: 80px !important; }
  .ml-lg-10 {
    margin-left: 80px !important; }
  .mr-lg-10 {
    margin-right: 80px !important; }
  .mb-lg-10 {
    margin-bottom: 80px !important; }
  .p-lg-10 {
    padding: 80px !important; }
  .py-lg-10 {
    padding-top: 80px !important;
    padding-bottom: 80px !important; }
  .px-lg-10 {
    padding-left: 80px !important;
    padding-right: 80px !important; }
  .pt-lg-10 {
    padding-top: 80px !important; }
  .pl-lg-10 {
    padding-left: 80px !important; }
  .pr-lg-10 {
    padding-right: 80px !important; }
  .pb-lg-10 {
    padding-bottom: 80px !important; } }

@media screen and (min-width: 1200px) {
  .m-xl-0 {
    margin: 0px !important; }
  .my-xl-0 {
    margin-top: 0px !important;
    margin-bottom: 0px !important; }
  .mx-xl-0 {
    margin-left: 0px !important;
    margin-right: 0px !important; }
  .mt-xl-0 {
    margin-top: 0px !important; }
  .ml-xl-0 {
    margin-left: 0px !important; }
  .mr-xl-0 {
    margin-right: 0px !important; }
  .mb-xl-0 {
    margin-bottom: 0px !important; }
  .p-xl-0 {
    padding: 0px !important; }
  .py-xl-0 {
    padding-top: 0px !important;
    padding-bottom: 0px !important; }
  .px-xl-0 {
    padding-left: 0px !important;
    padding-right: 0px !important; }
  .pt-xl-0 {
    padding-top: 0px !important; }
  .pl-xl-0 {
    padding-left: 0px !important; }
  .pr-xl-0 {
    padding-right: 0px !important; }
  .pb-xl-0 {
    padding-bottom: 0px !important; }
  .m-xl-1 {
    margin: 8px !important; }
  .my-xl-1 {
    margin-top: 8px !important;
    margin-bottom: 8px !important; }
  .mx-xl-1 {
    margin-left: 8px !important;
    margin-right: 8px !important; }
  .mt-xl-1 {
    margin-top: 8px !important; }
  .ml-xl-1 {
    margin-left: 8px !important; }
  .mr-xl-1 {
    margin-right: 8px !important; }
  .mb-xl-1 {
    margin-bottom: 8px !important; }
  .p-xl-1 {
    padding: 8px !important; }
  .py-xl-1 {
    padding-top: 8px !important;
    padding-bottom: 8px !important; }
  .px-xl-1 {
    padding-left: 8px !important;
    padding-right: 8px !important; }
  .pt-xl-1 {
    padding-top: 8px !important; }
  .pl-xl-1 {
    padding-left: 8px !important; }
  .pr-xl-1 {
    padding-right: 8px !important; }
  .pb-xl-1 {
    padding-bottom: 8px !important; }
  .m-xl-2 {
    margin: 16px !important; }
  .my-xl-2 {
    margin-top: 16px !important;
    margin-bottom: 16px !important; }
  .mx-xl-2 {
    margin-left: 16px !important;
    margin-right: 16px !important; }
  .mt-xl-2 {
    margin-top: 16px !important; }
  .ml-xl-2 {
    margin-left: 16px !important; }
  .mr-xl-2 {
    margin-right: 16px !important; }
  .mb-xl-2 {
    margin-bottom: 16px !important; }
  .p-xl-2 {
    padding: 16px !important; }
  .py-xl-2 {
    padding-top: 16px !important;
    padding-bottom: 16px !important; }
  .px-xl-2 {
    padding-left: 16px !important;
    padding-right: 16px !important; }
  .pt-xl-2 {
    padding-top: 16px !important; }
  .pl-xl-2 {
    padding-left: 16px !important; }
  .pr-xl-2 {
    padding-right: 16px !important; }
  .pb-xl-2 {
    padding-bottom: 16px !important; }
  .m-xl-3 {
    margin: 24px !important; }
  .my-xl-3 {
    margin-top: 24px !important;
    margin-bottom: 24px !important; }
  .mx-xl-3 {
    margin-left: 24px !important;
    margin-right: 24px !important; }
  .mt-xl-3 {
    margin-top: 24px !important; }
  .ml-xl-3 {
    margin-left: 24px !important; }
  .mr-xl-3 {
    margin-right: 24px !important; }
  .mb-xl-3 {
    margin-bottom: 24px !important; }
  .p-xl-3 {
    padding: 24px !important; }
  .py-xl-3 {
    padding-top: 24px !important;
    padding-bottom: 24px !important; }
  .px-xl-3 {
    padding-left: 24px !important;
    padding-right: 24px !important; }
  .pt-xl-3 {
    padding-top: 24px !important; }
  .pl-xl-3 {
    padding-left: 24px !important; }
  .pr-xl-3 {
    padding-right: 24px !important; }
  .pb-xl-3 {
    padding-bottom: 24px !important; }
  .m-xl-4 {
    margin: 32px !important; }
  .my-xl-4 {
    margin-top: 32px !important;
    margin-bottom: 32px !important; }
  .mx-xl-4 {
    margin-left: 32px !important;
    margin-right: 32px !important; }
  .mt-xl-4 {
    margin-top: 32px !important; }
  .ml-xl-4 {
    margin-left: 32px !important; }
  .mr-xl-4 {
    margin-right: 32px !important; }
  .mb-xl-4 {
    margin-bottom: 32px !important; }
  .p-xl-4 {
    padding: 32px !important; }
  .py-xl-4 {
    padding-top: 32px !important;
    padding-bottom: 32px !important; }
  .px-xl-4 {
    padding-left: 32px !important;
    padding-right: 32px !important; }
  .pt-xl-4 {
    padding-top: 32px !important; }
  .pl-xl-4 {
    padding-left: 32px !important; }
  .pr-xl-4 {
    padding-right: 32px !important; }
  .pb-xl-4 {
    padding-bottom: 32px !important; }
  .m-xl-5 {
    margin: 40px !important; }
  .my-xl-5 {
    margin-top: 40px !important;
    margin-bottom: 40px !important; }
  .mx-xl-5 {
    margin-left: 40px !important;
    margin-right: 40px !important; }
  .mt-xl-5 {
    margin-top: 40px !important; }
  .ml-xl-5 {
    margin-left: 40px !important; }
  .mr-xl-5 {
    margin-right: 40px !important; }
  .mb-xl-5 {
    margin-bottom: 40px !important; }
  .p-xl-5 {
    padding: 40px !important; }
  .py-xl-5 {
    padding-top: 40px !important;
    padding-bottom: 40px !important; }
  .px-xl-5 {
    padding-left: 40px !important;
    padding-right: 40px !important; }
  .pt-xl-5 {
    padding-top: 40px !important; }
  .pl-xl-5 {
    padding-left: 40px !important; }
  .pr-xl-5 {
    padding-right: 40px !important; }
  .pb-xl-5 {
    padding-bottom: 40px !important; }
  .m-xl-6 {
    margin: 48px !important; }
  .my-xl-6 {
    margin-top: 48px !important;
    margin-bottom: 48px !important; }
  .mx-xl-6 {
    margin-left: 48px !important;
    margin-right: 48px !important; }
  .mt-xl-6 {
    margin-top: 48px !important; }
  .ml-xl-6 {
    margin-left: 48px !important; }
  .mr-xl-6 {
    margin-right: 48px !important; }
  .mb-xl-6 {
    margin-bottom: 48px !important; }
  .p-xl-6 {
    padding: 48px !important; }
  .py-xl-6 {
    padding-top: 48px !important;
    padding-bottom: 48px !important; }
  .px-xl-6 {
    padding-left: 48px !important;
    padding-right: 48px !important; }
  .pt-xl-6 {
    padding-top: 48px !important; }
  .pl-xl-6 {
    padding-left: 48px !important; }
  .pr-xl-6 {
    padding-right: 48px !important; }
  .pb-xl-6 {
    padding-bottom: 48px !important; }
  .m-xl-7 {
    margin: 56px !important; }
  .my-xl-7 {
    margin-top: 56px !important;
    margin-bottom: 56px !important; }
  .mx-xl-7 {
    margin-left: 56px !important;
    margin-right: 56px !important; }
  .mt-xl-7 {
    margin-top: 56px !important; }
  .ml-xl-7 {
    margin-left: 56px !important; }
  .mr-xl-7 {
    margin-right: 56px !important; }
  .mb-xl-7 {
    margin-bottom: 56px !important; }
  .p-xl-7 {
    padding: 56px !important; }
  .py-xl-7 {
    padding-top: 56px !important;
    padding-bottom: 56px !important; }
  .px-xl-7 {
    padding-left: 56px !important;
    padding-right: 56px !important; }
  .pt-xl-7 {
    padding-top: 56px !important; }
  .pl-xl-7 {
    padding-left: 56px !important; }
  .pr-xl-7 {
    padding-right: 56px !important; }
  .pb-xl-7 {
    padding-bottom: 56px !important; }
  .m-xl-8 {
    margin: 64px !important; }
  .my-xl-8 {
    margin-top: 64px !important;
    margin-bottom: 64px !important; }
  .mx-xl-8 {
    margin-left: 64px !important;
    margin-right: 64px !important; }
  .mt-xl-8 {
    margin-top: 64px !important; }
  .ml-xl-8 {
    margin-left: 64px !important; }
  .mr-xl-8 {
    margin-right: 64px !important; }
  .mb-xl-8 {
    margin-bottom: 64px !important; }
  .p-xl-8 {
    padding: 64px !important; }
  .py-xl-8 {
    padding-top: 64px !important;
    padding-bottom: 64px !important; }
  .px-xl-8 {
    padding-left: 64px !important;
    padding-right: 64px !important; }
  .pt-xl-8 {
    padding-top: 64px !important; }
  .pl-xl-8 {
    padding-left: 64px !important; }
  .pr-xl-8 {
    padding-right: 64px !important; }
  .pb-xl-8 {
    padding-bottom: 64px !important; }
  .m-xl-9 {
    margin: 72px !important; }
  .my-xl-9 {
    margin-top: 72px !important;
    margin-bottom: 72px !important; }
  .mx-xl-9 {
    margin-left: 72px !important;
    margin-right: 72px !important; }
  .mt-xl-9 {
    margin-top: 72px !important; }
  .ml-xl-9 {
    margin-left: 72px !important; }
  .mr-xl-9 {
    margin-right: 72px !important; }
  .mb-xl-9 {
    margin-bottom: 72px !important; }
  .p-xl-9 {
    padding: 72px !important; }
  .py-xl-9 {
    padding-top: 72px !important;
    padding-bottom: 72px !important; }
  .px-xl-9 {
    padding-left: 72px !important;
    padding-right: 72px !important; }
  .pt-xl-9 {
    padding-top: 72px !important; }
  .pl-xl-9 {
    padding-left: 72px !important; }
  .pr-xl-9 {
    padding-right: 72px !important; }
  .pb-xl-9 {
    padding-bottom: 72px !important; }
  .m-xl-10 {
    margin: 80px !important; }
  .my-xl-10 {
    margin-top: 80px !important;
    margin-bottom: 80px !important; }
  .mx-xl-10 {
    margin-left: 80px !important;
    margin-right: 80px !important; }
  .mt-xl-10 {
    margin-top: 80px !important; }
  .ml-xl-10 {
    margin-left: 80px !important; }
  .mr-xl-10 {
    margin-right: 80px !important; }
  .mb-xl-10 {
    margin-bottom: 80px !important; }
  .p-xl-10 {
    padding: 80px !important; }
  .py-xl-10 {
    padding-top: 80px !important;
    padding-bottom: 80px !important; }
  .px-xl-10 {
    padding-left: 80px !important;
    padding-right: 80px !important; }
  .pt-xl-10 {
    padding-top: 80px !important; }
  .pl-xl-10 {
    padding-left: 80px !important; }
  .pr-xl-10 {
    padding-right: 80px !important; }
  .pb-xl-10 {
    padding-bottom: 80px !important; } }

.align-center {
  text-align: center !important; }

.align-left {
  text-align: left !important; }

.align-right {
  text-align: right !important; }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.align-middle {
  vertical-align: middle !important; }

.flex-align-baseline {
  align-items: baseline !important;
  align-content: baseline !important; }

.flex-align-center {
  align-items: center !important;
  align-content: center !important; }

.flex-align-start {
  align-items: flex-start !important;
  align-content: flex-start !important; }

.flex-align-end {
  align-items: flex-end !important;
  align-content: flex-end !important; }

.justify-center {
  justify-content: center !important; }

.justify-start {
  justify-content: flex-start !important;
  justify-items: flex-start !important; }

.justify-end {
  justify-content: flex-end !important;
  justify-items: flex-end !important; }

.justify-between {
  justify-content: space-between !important;
  justify-items: space-between !important; }

.flex-column {
  flex-direction: column; }

.flex-row {
  flex-direction: row; }

@media screen and (min-width: 600px) {
  .align-center-sm {
    text-align: center !important; }
  .align-left-sm {
    text-align: left !important; }
  .align-right-sm {
    text-align: right !important; }
  .float-left-sm {
    float: left !important; }
  .float-right-sm {
    float: right !important; }
  .align-middle-sm {
    vertical-align: middle !important; }
  .flex-align-center-sm {
    align-items: center !important;
    align-content: center !important; }
  .flex-align-start-sm {
    align-items: flex-start !important;
    align-content: flex-start !important; }
  .flex-align-end-sm {
    align-items: flex-end !important;
    align-content: flex-end !important; }
  .justify-start-sm {
    justify-content: flex-start !important;
    justify-items: flex-start !important; }
  .justify-end-sm {
    justify-content: flex-end !important;
    justify-items: flex-end !important; }
  .justify-between-sm {
    justify-content: space-between !important;
    justify-items: space-between !important; }
  .flex-column-sm {
    flex-direction: column; }
  .flex-row-sm {
    flex-direction: row; } }

@media screen and (min-width: 960px) {
  .align-center-md {
    text-align: center !important; }
  .align-left-md {
    text-align: left !important; }
  .align-right-md {
    text-align: right !important; }
  .float-left-md {
    float: left !important; }
  .float-right-md {
    float: right !important; }
  .align-middle-md {
    vertical-align: middle !important; }
  .flex-align-center-md {
    align-items: center !important;
    align-content: center !important; }
  .flex-align-start-md {
    align-items: flex-start !important;
    align-content: flex-start !important; }
  .flex-align-end-md {
    align-items: flex-end !important;
    align-content: flex-end !important; }
  .justify-start-md {
    justify-content: flex-start !important;
    justify-items: flex-start !important; }
  .justify-end-md {
    justify-content: flex-end !important;
    justify-items: flex-end !important; }
  .justify-between-md {
    justify-content: space-between !important;
    justify-items: space-between !important; }
  .flex-column-md {
    flex-direction: column; }
  .flex-row-md {
    flex-direction: row; } }

.fui-table-response {
  position: relative;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  z-index: 0; }
  .fui-table-response table {
    display: table; }

*:not(.show-scrollbar).tableRight {
  -ms-overflow-style: none; }
  *:not(.show-scrollbar).tableRight::-webkit-scrollbar {
    display: none;
    scrollbar-width: none; }
    *:not(.show-scrollbar).tableRight::-webkit-scrollbar td:nth-of-type(1) {
      border-left: 0; }
    *:not(.show-scrollbar).tableRight::-webkit-scrollbar td:nth-last-child(1) {
      border-right: 0; }

.show-scrollbar table {
  overflow: auto;
  display: block; }

*:not(.fui-query-modal) table {
  border-collapse: collapse;
  width: 100%; }
  *:not(.fui-query-modal) table.fui-table tbody tr > td:first-child {
    min-width: 400px;
    width: 400px;
    max-width: 400px;
    white-space: normal; }
    @media screen and (min-width: 960px) {
      *:not(.fui-query-modal) table.fui-table tbody tr > td:first-child {
        min-width: 60%;
        width: 60%;
        max-width: 60%;
        white-space: normal; } }
  *:not(.fui-query-modal) table tr {
    background: #FFFFFF; }
    *:not(.fui-query-modal) table tr th, *:not(.fui-query-modal) table tr td {
      font-size: 0.875rem;
      white-space: nowrap;
      text-align: center;
      border: 2px solid #E8ECF0;
      color: #5F6E84;
      height: 44px;
      line-height: 1.8;
      padding: 4px 8px; }
    *:not(.fui-query-modal) table tr[rowspan="2"] {
      height: 80px; }
    *:not(.fui-query-modal) table tr th {
      background: #FFF; }
  *:not(.fui-query-modal) table > tr:nth-child(2n) {
    background: #f1f3f5; }
  *:not(.fui-query-modal) table thead {
    background: #f1f3f5; }
    *:not(.fui-query-modal) table thead tr {
      background: none; }
      *:not(.fui-query-modal) table thead tr th {
        cursor: default;
        background: none; }
    *:not(.fui-query-modal) table thead.is-hover {
      color: #FFFFFF;
      background: rgba(22, 66, 177, 0.8); }
  *:not(.fui-query-modal) table tbody tr {
    background: #FFFFFF; }
    *:not(.fui-query-modal) table tbody tr td {
      cursor: default;
      position: relative; }
    *:not(.fui-query-modal) table tbody tr:nth-child(2n) {
      background: #f1f3f5; }
    *:not(.fui-query-modal) table tbody tr th .MuiGrid-root.MuiGrid-item {
      display: flex; }
      *:not(.fui-query-modal) table tbody tr th .MuiGrid-root.MuiGrid-item.note {
        font-size: 0.875rem; }
      *:not(.fui-query-modal) table tbody tr th .MuiGrid-root.MuiGrid-item span {
        align-self: center; }
      *:not(.fui-query-modal) table tbody tr th .MuiGrid-root.MuiGrid-item .float-right {
        width: 100%;
        display: block;
        text-align: right; }
  *:not(.fui-query-modal) table.payment-table {
    display: table; }
    *:not(.fui-query-modal) table.payment-table tbody tr th {
      color: #202020;
      height: 88px;
      font-size: 18px;
      padding-left: 18px;
      padding-right: 18px;
      white-space: normal; }
      *:not(.fui-query-modal) table.payment-table tbody tr th .float-right {
        font-size: 20px; }
      *:not(.fui-query-modal) table.payment-table tbody tr th h2.float-right {
        font-size: 28px; }
      *:not(.fui-query-modal) table.payment-table tbody tr th .happygo.MuiGrid-root.MuiGrid-container {
        width: calc(100% + 16px);
        margin: 0 -8px; }
        *:not(.fui-query-modal) table.payment-table tbody tr th .happygo.MuiGrid-root.MuiGrid-container .MuiGrid-root.MuiGrid-item {
          padding: 0 8px; }
        *:not(.fui-query-modal) table.payment-table tbody tr th .happygo.MuiGrid-root.MuiGrid-container .note {
          font-weight: 400;
          text-align: left; }
      @media screen and (min-width: 768px) {
        *:not(.fui-query-modal) table.payment-table tbody tr th {
          padding-left: 30px;
          padding-right: 30px;
          height: 92px;
          font-size: 20px; }
          *:not(.fui-query-modal) table.payment-table tbody tr th .float-right {
            font-size: 22px; }
          *:not(.fui-query-modal) table.payment-table tbody tr th h2.float-right {
            font-size: 36px; }
          *:not(.fui-query-modal) table.payment-table tbody tr th .happygo.MuiGrid-root.MuiGrid-container .note.MuiGrid-root.MuiGrid-item {
            text-align: right;
            font-size: 0.875rem; }
            *:not(.fui-query-modal) table.payment-table tbody tr th .happygo.MuiGrid-root.MuiGrid-container .note.MuiGrid-root.MuiGrid-item span {
              width: 100%;
              display: block; } }
  *:not(.fui-query-modal) table.text-wrap {
    display: table; }
    *:not(.fui-query-modal) table.text-wrap tr th,
    *:not(.fui-query-modal) table.text-wrap tr td {
      white-space: normal; }
  *:not(.fui-query-modal) table.option-table .nowrap td {
    white-space: nowrap; }
  *:not(.fui-query-modal) table.option-table.name, *:not(.fui-query-modal) table.option-table.description {
    min-width: 200px; }
  *:not(.fui-query-modal) table.option-table tr td {
    white-space: normal;
    text-align: left;
    padding: 12px; }
  *:not(.fui-query-modal) table.option-table:not(:first-child) {
    min-width: 120px; }
  *:not(.fui-query-modal) table.option-table label {
    font-size: 1rem;
    margin: 0; }
  *:not(.fui-query-modal) table.option-table input {
    position: absolute;
    border: 0;
    opacity: 0; }
  *:not(.fui-query-modal) table.option-table .radio {
    position: relative;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    border: solid 2px #E8ECF0;
    background: #FFFFFF; }
  *:not(.fui-query-modal) table.option-table input:checked + .radio {
    background: #f05c38;
    border-color: #f05c38; }
    *:not(.fui-query-modal) table.option-table input:checked + .radio::after {
      content: "";
      width: 10px;
      height: 10px;
      border-radius: 5px;
      background: #FFFFFF;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto; }
  *:not(.fui-query-modal) table.with-hover td.is-hover:hover td {
    background-color: rgba(22, 66, 177, 0.15); }
  *:not(.fui-query-modal) table.with-hover td.is-hover:hover {
    color: #FFFFFF;
    background: rgba(22, 66, 177, 0.8); }
    *:not(.fui-query-modal) table.with-hover td.is-hover:hover .emphasize {
      color: #FFFFFF; }
  *:not(.fui-query-modal) table.center-table tr th, *:not(.fui-query-modal) table.center-table tr td {
    text-align: center; }
  *:not(.fui-query-modal) table.center-table tr td:not(:first-child) {
    min-width: auto; }
  *:not(.fui-query-modal) table.modal-table {
    width: 100%; }
    *:not(.fui-query-modal) table.modal-table th {
      width: 40%;
      background: #f1f3f5; }

table {
  position: relative;
  overflow-x: auto;
  display: table;
  -webkit-overflow-scrolling: touch;
  z-index: 0;
  width: 100%;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none; }
  table tbody {
    width: 100%; }
  table.table-285 tr td {
    font-size: 16px;
    padding: 12px 24px; }
  table.table-285 tr td:nth-child(1) {
    width: 285px; }
  table.table-md-285 tr td {
    font-size: 16px;
    padding: 12px 24px; }
  table.table-md-285 tr td:nth-child(1) {
    width: 285px; }
  table.odd tbody tr {
    background-color: #FFFFFF !important; }
  table.odd tbody tr:nth-child(odd) {
    background-color: #f1f3f5 !important; }
  table.table-gray-head th {
    border-right: 2px solid #E8ECF0 !important; }
  table.table-gray-head tbody tr {
    background-color: #FFFFFF !important; }
    table.table-gray-head tbody tr:nth-of-type(1) {
      background-color: #f1f3f5 !important; }
      table.table-gray-head tbody tr:nth-of-type(1) th {
        background-color: transparent;
        background-color: initial; }
  table.table-fixed {
    table-layout: fixed;
    display: table; }
    table.table-fixed td {
      line-break: anywhere;
      background-clip: padding-box; }
  table.gray-th tr th {
    background-color: #f1f3f5;
    font-weight: 500; }
  table.gray-th tr td {
    background-color: #FFFFFF; }
  table.w-142 .tableLeft {
    width: 142px; }
  table.w-142 .tableRight {
    padding-left: 140px; }
  @media screen and (max-width: 960px) {
    table.w-142 .tableLeft {
      width: 90px !important; }
    table.w-142 .tableRight {
      padding-left: 88px !important; } }

@media screen and (max-width: 960px) {
  table {
    display: block; }
    table.table-285 tr td {
      font-size: 14px;
      padding: 6px; }
    table.table-285 tr td:nth-child(1) {
      width: 76px; }
    table.table-md-285 tr td {
      font-size: 14px;
      padding: 6px; }
    table.table-md-285 tr td:nth-child(1) {
      width: auto; } }

.compareTable {
  position: relative; }
  .compareTable .tableHead th {
    border-right: 0; }
  .compareTable .tableLeft {
    position: absolute;
    left: 0;
    right: 0;
    width: 180px;
    z-index: 1; }
    .compareTable .tableLeft table {
      height: 100%;
      display: table; }
    .compareTable .tableLeft th {
      white-space: normal; }
    .compareTable .tableLeft .colspan2 {
      height: 88px; }
  .compareTable .tableRight {
    padding-left: 178px;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    border-right: 0; }
    .compareTable .tableRight::after {
      content: '';
      width: 1px;
      height: 100%;
      position: absolute;
      right: 1px;
      top: 0;
      background-color: #E8ECF0; }
    .compareTable .tableRight.auto-width tr td {
      min-width: auto !important; }
    .compareTable .tableRight.w-200 td {
      width: 200px;
      min-width: 200px; }
    .compareTable .tableRight::-webkit-scrollbar {
      display: none;
      scrollbar-width: none; }
      .compareTable .tableRight::-webkit-scrollbar td:nth-of-type(1) {
        border-left: 0; }
      .compareTable .tableRight::-webkit-scrollbar td:nth-last-child(1) {
        border-right: 0; }
    .compareTable .tableRight.w-164 td {
      min-width: 164px; }
  .compareTable .compareList {
    font-weight: 500;
    outline: 2px solid #E8ECF0;
    outline-offset: -2px; }
    .compareTable .compareList tbody {
      width: 100%;
      display: table; }
    .compareTable .compareList .body1 {
      display: block; }
  .compareTable .emphasize {
    color: #2F59C4;
    display: inline-block;
    margin: 0; }
    .compareTable .emphasize table tr td {
      white-space: nowrap; }
  .compareTable.w-142 .tableLeft {
    width: 142px; }
  .compareTable.w-142 .tableRight {
    padding-left: 140px; }
  @media screen and (max-width: 960px) {
    .compareTable.w-142 .tableLeft {
      width: 90px !important; }
    .compareTable.w-142 .tableRight {
      padding-left: 88px !important; } }
  .compareTable.stick-to-right .tableRight {
    margin-right: -22px; }
    .compareTable.stick-to-right .tableRight::after {
      display: none; }
  @media screen and (min-width: 960px) {
    .compareTable .tableLeft {
      width: calc( 25% + 2px); }
    .compareTable .tableRight {
      padding-left: calc( 25% - 2px);
      border-right: 0; }
    .compareTable.stick-to-right .tableRight {
      margin-right: 0; } }

table.confirm-table tr th, table.confirm-table tr td {
  color: #5f6e84;
  font-size: 16px;
  white-space: normal; }

table.confirm-table tr th {
  width: 50%;
  text-align: center;
  background: #fff;
  font-weight: 600; }

table.confirm-table tr td {
  padding: 4px 60px;
  text-align: left; }

table.confirm-table tr:nth-child(odd) td {
  background: #f1f3f5; }

table.confirm-table tr:nth-child(even) td {
  background: #fff; }

table.history-detail-table tr th, table.history-detail-table tr td {
  color: #5f6e84;
  font-size: 16px;
  white-space: normal; }

table.history-detail-table tr th {
  width: 30%;
  text-align: center;
  background: #fff;
  font-weight: 600; }

table.history-detail-table tr td {
  padding: 4px 30px;
  text-align: left; }

table.history-detail-table tr:nth-child(odd) th,
table.history-detail-table tr:nth-child(odd) td {
  background: #f1f3f5; }

table.history-detail-table tr:nth-child(even) th,
table.history-detail-table tr:nth-child(even) td {
  background: #fff; }

table.modal-roaming-country-table tr th, table.modal-roaming-country-table tr td {
  white-space: normal; }

table.modal-roaming-country-table tr th {
  width: 50%; }

table.modal-roaming-country-table tr td {
  background: #fff; }

table.modal-roaming-package-table tr th, table.modal-roaming-package-table tr td {
  white-space: normal;
  text-align: left; }

table.modal-roaming-package-table tr th {
  width: 25%; }

table.modal-roaming-package-table tr:nth-child(odd) td {
  background: #f1f3f5; }

table.modal-roaming-package-table tr:nth-child(even) td {
  background: #fff; }

@media screen and (min-width: 1200px) {
  .compareTable .tableLeft .colspan2 {
    height: 104px; }
  *:not(.fui-query-modal) table tr th, *:not(.fui-query-modal) table tr td {
    font-size: 1rem;
    height: 52px; } }

@media screen and (max-width: 768px) {
  .compareTable .tableLeft {
    width: 120px; }
  .compareTable .tableRight {
    padding-left: 120px; }
  .compareTable .compareList {
    min-width: 400px; }
  *:not(.fui-query-modal) table.modal-table tr td {
    text-align: left; }
  table.confirm-table {
    margin-bottom: 12px; }
    table.confirm-table tr th, table.confirm-table tr td {
      font-size: 14px; }
    table.confirm-table tr td {
      padding: 4px 8px; }
  table.modal-roaming-package-table tr th {
    width: 40%; } }

@media all and (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
  table::-webkit-scrollbar {
    display: none; } }

*:not(.fui-query-modal) table.defaultTable {
  font-family: serif;
  display: table !important; }
  *:not(.fui-query-modal) table.defaultTable tr, *:not(.fui-query-modal) table.defaultTable th, *:not(.fui-query-modal) table.defaultTable td {
    color: #000 !important;
    border: 0 !important;
    background: none !important; }
  *:not(.fui-query-modal) table.defaultTable thead {
    background: none !important; }
    *:not(.fui-query-modal) table.defaultTable thead th {
      font-size: 20px !important;
      border-top: 1px solid #000 !important;
      border-bottom: 1px solid #000 !important; }

html, body {
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

html {
  overflow-x: hidden; }

body {
  display: flex;
  flex-direction: column;
  overflow: hidden; }

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto; }

.is-ebu:not(.is-help-center) main {
  margin-top: 50px; }
  @media screen and (min-width: 960px) {
    .is-ebu:not(.is-help-center) main {
      margin-top: 100px; }
      .is-ebu:not(.is-help-center) main .is-ebu:not(.is-help-center) .fui-header.is-blank main {
        margin-top: 60px; } }

main {
  position: relative;
  display: block;
  margin-top: 50px; }
  @media screen and (min-width: 960px) {
    main {
      margin-top: 100px; } }

@media screen and (min-width: 960px) {
  .is-help-center header.is-fixed ~ main {
    margin-top: 60px; } }

+ main {
  position: relative;
  min-height: 650px;
  z-index: 3; }
  + main.is-fixed + main {
    display: block;
    margin-top: 50px; }
  @media screen and (min-width: 960px) {
    + main.is-fixed + main {
      margin-top: 100px; }
    + main.is-fixed.is-blank + main,
    + main.is-fixed.is-help-center + main {
      margin-top: 60px; } }

.promotion.expand {
  transform: scaleY(0);
  transform-origin: 50% 100%; }
  .promotion.expand > img {
    width: 100%;
    height: auto; }

.promotion.corner-button {
  width: 58px;
  height: 58px;
  border-radius: 30px;
  border: 0;
  background: #202020;
  color: #FFF; }

.no-shadow {
  box-shadow: none !important; }

.page-action {
  margin-top: 32px; }
  .page-action a.fui-button {
    width: auto; }
  @media screen and (max-width: 640px) {
    .page-action a.fui-button {
      width: 100%; } }

[role="button"] {
  cursor: pointer; }

label.fui-switch {
  position: relative;
  cursor: pointer; }
  label.fui-switch input {
    opacity: 0;
    position: absolute; }
    label.fui-switch input + div.content span.off {
      color: #EF3123; }
    label.fui-switch input:checked + div.content::after {
      left: 24px; }
    label.fui-switch input:checked + div.content span.off {
      color: #202020; }
    label.fui-switch input:checked + div.content span.on {
      color: #2F59C4; }
    label.fui-switch input + .content {
      position: relative;
      padding: 0 28px; }
      label.fui-switch input + .content span {
        position: absolute;
        font-size: 14px;
        top: 0;
        bottom: 0;
        height: 20px;
        line-height: 20px;
        white-space: nowrap;
        margin: auto;
        transition: color 0.3s ease; }
        label.fui-switch input + .content span.off {
          left: 0; }
        label.fui-switch input + .content span.on {
          right: 0; }
      label.fui-switch input + .content::before {
        content: "";
        top: 0;
        bottom: 0;
        display: inline-block;
        width: 48px;
        height: 10px;
        margin: auto 6px;
        background: #484848;
        border-radius: 6px; }
      label.fui-switch input + .content::after {
        content: "";
        position: absolute;
        width: 24px;
        height: 24px;
        display: block;
        transition: all 0.3s ease;
        top: -100%;
        bottom: -100%;
        left: -24px;
        right: 0;
        margin: auto;
        background: #FFFFFF;
        box-shadow: 0 4px 12px 0 rgba(61, 61, 61, 0.24);
        border-radius: 15px; }

header.fui-header {
  width: 100%;
  z-index: 39;
  background: #FFF;
  padding: 0; }
  header.fui-header.is-fixed {
    position: fixed;
    top: 0; }
  header.fui-header a,
  header.fui-header a:link,
  header.fui-header a:visited {
    color: #202020; }
    header.fui-header a:hover, header.fui-header a:active,
    header.fui-header a:link:hover,
    header.fui-header a:link:active,
    header.fui-header a:visited:hover,
    header.fui-header a:visited:active {
      color: #EF3123; }
  header.fui-header .fui-container {
    width: 100%;
    max-width: 1440px; }
  header.fui-header .fui-control-bar,
  header.fui-header .fui-navbar {
    position: relative;
    z-index: 2; }
  header.fui-header .fui-control-bar .fui-container {
    display: flex;
    justify-content: space-between; }
  header.fui-header .fui-sidebar .menu .item {
    display: block; }
  header.fui-header::before {
    content: "";
    position: fixed;
    width: 100vw;
    height: 100vh;
    display: block;
    background: rgba(0, 0, 0, 0.5);
    z-index: -1;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s 0.2s ease; }
  header.fui-header.is-search-open, header.fui-header.is-open {
    transform: translateY(0px) !important; }
    @media screen and (max-width: 960px) {
      header.fui-header.is-search-open, header.fui-header.is-open {
        height: 100%; } }
    header.fui-header.is-search-open::before, header.fui-header.is-open::before {
      pointer-events: all;
      z-index: 29;
      opacity: 1; }
  header.fui-header.is-open .fui-sidebar {
    left: 0vw;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 999; }
  header.fui-header.is-open .menu-trigger {
    border: 0;
    background: none; }
    header.fui-header.is-open .menu-trigger span {
      top: 0;
      bottom: 0;
      margin: auto; }
    header.fui-header.is-open .menu-trigger span:nth-child(2) {
      width: 0; }
    header.fui-header.is-open .menu-trigger span:first-child {
      transform: rotate(45deg); }
    header.fui-header.is-open .menu-trigger span:last-child {
      transform: rotate(-45deg); }
  header.fui-header .right a {
    font-size: 14px; }
  header.fui-header button {
    border: 0;
    background: none; }
  header.fui-header .fui-control-bar {
    display: none;
    height: 40px;
    background-image: linear-gradient(180deg, #FFFFFF 0%, #FBFBFB 100%);
    padding-top: 10px;
    padding-bottom: 10px;
    justify-content: space-between;
    justify-items: space-between; }
  header.fui-header label.fui-switch {
    position: relative; }
    header.fui-header label.fui-switch input {
      opacity: 0;
      position: absolute; }
      header.fui-header label.fui-switch input + div.content span.off {
        color: #EF3123; }
      header.fui-header label.fui-switch input:checked + div.content::after {
        left: 24px; }
      header.fui-header label.fui-switch input:checked + div.content span.off {
        color: #202020; }
      header.fui-header label.fui-switch input:checked + div.content span.on {
        color: #2F59C4; }
      header.fui-header label.fui-switch input + .content {
        position: relative;
        padding: 0 28px; }
        header.fui-header label.fui-switch input + .content span {
          position: absolute;
          font-size: 14px;
          top: 0;
          bottom: 0;
          height: 20px;
          line-height: 20px;
          white-space: nowrap;
          margin: auto;
          transition: color 0.3s ease; }
          header.fui-header label.fui-switch input + .content span.off {
            left: 0; }
          header.fui-header label.fui-switch input + .content span.on {
            right: 0; }
        header.fui-header label.fui-switch input + .content::before {
          content: "";
          top: 0;
          bottom: 0;
          display: inline-block;
          width: 48px;
          height: 10px;
          margin: auto 6px;
          background: #484848;
          border-radius: 6px; }
        header.fui-header label.fui-switch input + .content::after {
          content: "";
          position: absolute;
          width: 24px;
          height: 24px;
          display: block;
          transition: all 0.3s ease;
          top: -100%;
          bottom: -100%;
          left: -24px;
          right: 0;
          margin: auto;
          background: #FFFFFF;
          box-shadow: 0 4px 12px 0 rgba(61, 61, 61, 0.24);
          border-radius: 15px; }
  header.fui-header nav.fui-navbar {
    background: #FFF;
    box-shadow: none !important;
    border: 0 !important; }
  header.fui-header #brand,
  header.fui-header .brand {
    display: inline-block;
    width: 100px;
    height: 36px;
    text-indent: -9990px;
    overflow: hidden;
    background: url(/DigService/resources/common/images/mobile-logo.png) no-repeat center top;
    background-size: 94px 30px; }
  header.fui-header .fui-navbar-menu {
    padding: 8px 0;
    display: flex;
    align-content: center;
    align-items: center;
    justify-items: space-between;
    justify-content: space-between;
    min-height: 50px; }
    header.fui-header .fui-navbar-menu .navbar-center {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 125px;
      display: flex;
      align-items: center;
      margin: auto;
      justify-content: center; }
    header.fui-header .fui-navbar-menu .position-relative {
      position: relative; }
    header.fui-header .fui-navbar-menu .navbar-text {
      color: #8A8A8A;
      font-weight: 600; }
    header.fui-header .fui-navbar-menu .navbar-right {
      text-align: right; }
      header.fui-header .fui-navbar-menu .navbar-right #search-trigger {
        padding-right: 0; }
    header.fui-header .fui-navbar-menu .menu-trigger {
      position: relative;
      border: 0;
      padding: 0;
      background: none;
      width: 22px;
      height: 22px;
      margin-top: 5px; }
      header.fui-header .fui-navbar-menu .menu-trigger span {
        position: absolute;
        width: 20px;
        height: 2px;
        top: 0;
        bottom: 0;
        margin: auto;
        display: block;
        background: #202020;
        transition: all 0.3s ease;
        transform-origin: 50%; }
        header.fui-header .fui-navbar-menu .menu-trigger span:first-child {
          bottom: 14px;
          transform-origin: 0% 50%; }
        header.fui-header .fui-navbar-menu .menu-trigger span:last-child {
          top: 14px;
          transform-origin: 0% 50%; }
    header.fui-header .fui-navbar-menu .navbar-item {
      display: inline-block;
      vertical-align: middle;
      color: #202020;
      line-height: 0;
      padding: 0 15px;
      font-size: 1.2rem;
      font-weight: 600; }
      header.fui-header .fui-navbar-menu .navbar-item::after {
        display: none; }
      header.fui-header .fui-navbar-menu .navbar-item:last-child {
        padding-right: 0; }
      header.fui-header .fui-navbar-menu .navbar-item *[class*="icon-"] {
        font-size: 30px; }
      header.fui-header .fui-navbar-menu .navbar-item[role="button"]:hover {
        color: #D3060F;
        padding: 0; }
      header.fui-header .fui-navbar-menu .navbar-item .fui-dropdown button.fui-dropdown-item {
        padding: 0; }
        header.fui-header .fui-navbar-menu .navbar-item .fui-dropdown button.fui-dropdown-item span {
          display: flex;
          align-content: center;
          align-items: center; }
  header.fui-header .fui-menu-container {
    position: absolute;
    width: 100%;
    top: 40px;
    background: #E8ECF0;
    display: none; }
  header.fui-header.is-help-center .fui-menu-container {
    top: 0; }
  header.fui-header.is-5g-header.fui-header.is-open .fui-menu-container {
    top: 0; }

.fui-header {
  width: 100%;
  z-index: 39;
  background: #FFF;
  padding: 0; }
  .fui-header.is-fixed {
    position: fixed;
    top: 0; }

.header-footer-page {
  height: calc( 100vh - 166px);
  display: flex;
  align-items: center;
  justify-content: center; }
  .header-footer-page img {
    height: 100%;
    object-fit: contain; }
  .header-footer-page.office365 {
    height: calc( 100vh - 194px); }

header.fui-header .menu-action .fui-item {
  cursor: pointer; }
  header.fui-header .menu-action .fui-item:hover {
    color: #EF3123; }

.is-ebu header.fui-header .menu-action .fui-item:hover {
  color: #2F59C4; }

header.fui-header.seednet .seednet-logo img {
  width: 110px; }

header.fui-header.seednet .navbar-center {
  width: auto;
  width: initial; }
  header.fui-header.seednet .navbar-center a:nth-last-child(1) {
    display: flex;
    margin-left: 6px; }
    header.fui-header.seednet .navbar-center a:nth-last-child(1)::before {
      content: "";
      border-left: 1px solid #4F5E72;
      height: 30px;
      margin-top: 10px;
      padding-right: 16px; }

.is-ebu header.fui-header a:hover, .is-ebu header.fui-header a:active,
.is-ebu header.fui-header a:link:hover,
.is-ebu header.fui-header a:link:active,
.is-ebu header.fui-header a:visited:hover,
.is-ebu header.fui-header a:visited:active {
  opacity: 1;
  color: #2F59C4; }

header.fui-header.ebu-login {
  box-shadow: 0 6px 12px 0 rgba(75, 88, 105, 0.12);
  position: relative; }
  header.fui-header.ebu-login .fui-navbar-menu {
    padding: 12px 0;
    height: 74px; }

.sidebar-open header.fui-header {
  position: fixed; }

@media screen and (min-width: 960px) {
  header.fui-header {
    padding: 0; }
    header.fui-header.is-open .fui-menu-container {
      height: 730px;
      top: 40px;
      display: block;
      z-index: 299; }
    header.fui-header.is-open .menu-header::before {
      height: 670px; }
    header.fui-header.is-open .fui-menu-header + .fui-menu-body {
      overflow-y: auto;
      height: calc(100vh - 100px);
      max-height: 670px; }
    header.fui-header.is-open.is-help-center .fui-menu-container {
      height: 640px; }
    header.fui-header.is-open.is-help-center .fui-menu-container .fui-menu-header + .fui-menu-body {
      height: calc(100vh - 60px);
      max-height: 580px; }
    header.fui-header.is-help-center .fui-menu-container .menu-header,
    header.fui-header.is-help-center .fui-menu-container .menu-header::before,
    header.fui-header.is-help-center .fui-menu-container .menu-extra {
      height: 580px; }
    header.fui-header.is-help-center.is-open .fui-menu-container {
      top: 0; }
    header.fui-header .fui-container {
      width: 100%; }
    header.fui-header .fui-control-bar {
      display: flex;
      z-index: 199; }
    header.fui-header .fui-navbar-menu {
      height: 60px; }
      header.fui-header .fui-navbar-menu .navbar-right #search-trigger {
        padding-right: 15px; }
    header.fui-header #brand,
    header.fui-header .brand {
      width: 150px;
      height: 46px;
      background: url(/DigService/resources/common/images/fetnet-logo.png) no-repeat center;
      background-size: 125px 40px; }
    header.fui-header .fui-dropdown {
      height: auto; }
      header.fui-header .fui-dropdown .menu-wrapper .fui-item {
        justify-content: center; }
      header.fui-header .fui-dropdown .menu-wrapper .fui-item:hover {
        color: #EF3123 !important;
        background: #fafafa !important; }
    header.fui-header .fui-menu-container {
      display: none;
      height: 0px;
      background: #FFF;
      overflow: hidden;
      transition: all 0.3s ease; }
      header.fui-header .fui-menu-container .brand {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto; }
      header.fui-header .fui-menu-container .fui-menu-header {
        position: relative;
        height: 60px;
        padding: 0;
        background: #FFF; }
        header.fui-header .fui-menu-container .fui-menu-header > .fui-container {
          position: relative;
          height: 60px; }
        header.fui-header .fui-menu-container .fui-menu-header + .fui-container {
          overflow-y: auto; }
        header.fui-header .fui-menu-container .fui-menu-header .fui-navbar-menu {
          display: flex !important; }
        header.fui-header .fui-menu-container .fui-menu-header .close {
          position: absolute;
          font-size: 24px;
          top: 4px;
          bottom: 0;
          padding: 0;
          width: 24px;
          height: 32px;
          margin: auto;
          border: 0;
          background: none; }
      header.fui-header .fui-menu-container .menu-header {
        display: flex;
        flex-direction: column;
        height: 670px;
        background: #fafafa; }
        header.fui-header .fui-menu-container .menu-header::before {
          content: "";
          position: absolute;
          width: 50vw;
          display: block;
          background: #fafafa;
          right: calc(100% - 16px); }
        header.fui-header .fui-menu-container .menu-header .menu-action {
          padding-bottom: 180px; }
          header.fui-header .fui-menu-container .menu-header .menu-action .fui-item {
            padding-left: 30px; }
            header.fui-header .fui-menu-container .menu-header .menu-action .fui-item img {
              width: auto;
              height: 40px;
              margin-left: -30px;
              margin-right: 12px; }
        header.fui-header .fui-menu-container .menu-header .menu-content {
          flex: 1 1;
          flex-grow: 1;
          padding-top: 24px; }
          header.fui-header .fui-menu-container .menu-header .menu-content .fui-item[role='button']:hover,
          header.fui-header .fui-menu-container .menu-header .menu-content .fui-item[role='button']:active {
            color: #EF3123;
            opacity: 1; }
          header.fui-header .fui-menu-container .menu-header .menu-content .fui-item.is-active {
            background: #ffffff; }
      header.fui-header .fui-menu-container .menu-body {
        display: flex;
        flex-direction: column;
        background: #FFF; }
        header.fui-header .fui-menu-container .menu-body h6 {
          font-size: 1.125rem;
          font-weight: 500;
          margin: 8px 0 0; }
        header.fui-header .fui-menu-container .menu-body .menu-action .action {
          background: #fafafa;
          padding: 12px 40px; }
        header.fui-header .fui-menu-container .menu-body .menu-content {
          flex: 1 1;
          flex-grow: 1;
          padding: 24px 30px 0;
          padding-top: 24px; }
          header.fui-header .fui-menu-container .menu-body .menu-content .fui-item .content {
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis; }
          header.fui-header .fui-menu-container .menu-body .menu-content .fui-item.more {
            display: inline-flex;
            width: auto;
            font-weight: 500; }
            header.fui-header .fui-menu-container .menu-body .menu-content .fui-item.more i[class*='icon-'] {
              font-size: 24px; }
        header.fui-header .fui-menu-container .menu-body .fui-card-container > h6 {
          margin-top: 16px !important; }
        header.fui-header .fui-menu-container .menu-body .fui-card-container .fui-cards.is-flat {
          margin: 12px -6px; }
          header.fui-header .fui-menu-container .menu-body .fui-card-container .fui-cards.is-flat .fui-card {
            flex: 1 1;
            flex-grow: 1; }
            header.fui-header .fui-menu-container .menu-body .fui-card-container .fui-cards.is-flat .fui-card .fui-card-caption .fui-card-content h4.fui-card-title {
              margin-bottom: 0; }
            header.fui-header .fui-menu-container .menu-body .fui-card-container .fui-cards.is-flat .fui-card .fui-card-caption .fui-card-content p.fui-card-description {
              margin-top: 6px; }
      header.fui-header .fui-menu-container .menu-extra {
        position: absolute;
        width: 240px;
        height: 670px;
        overflow: hidden;
        background: #E8ECF0;
        display: none;
        right: 0;
        top: 0px; }
        header.fui-header .fui-menu-container .menu-extra .menu-extra-image {
          position: absolute;
          width: 100%;
          bottom: 0;
          z-index: 0; }
        header.fui-header .fui-menu-container .menu-extra .menu-extra-caption {
          position: relative;
          z-index: 1;
          padding: 56px 24px; }
          header.fui-header .fui-menu-container .menu-extra .menu-extra-caption h3 {
            margin: 0 0 8px; }
          header.fui-header .fui-menu-container .menu-extra .menu-extra-caption p {
            margin: 0 0 24px;
            font-size: 1.125rem; } }

@media screen and (min-width: 1200px) {
  header.fui-header .fui-menu-container .menu-extra {
    display: block; } }

@media screen and (max-width: 1279px) and (min-width: 1200px) {
  header.fui-header .fui-menu-container .menu-body {
    padding-right: 220px; } }

@media screen and (max-width: 1280px) and (min-width: 1300px) {
  header.fui-header .fui-menu-container .menu-body {
    padding-right: 160px; } }

@media screen and (min-width: 1920px) {
  header.fui-header .fui-menu-container .menu-body {
    padding-right: 210px; } }

header.fui-header .fui-sidebar {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  left: -100vw;
  background: #FFFFFF;
  transition: all 0.3s ease;
  overflow-x: hidden;
  overflow-y: auto; }
  header.fui-header .fui-sidebar a::after,
  header.fui-header .fui-sidebar a:link::after,
  header.fui-header .fui-sidebar a:visited::after {
    display: none !important; }
  header.fui-header .fui-sidebar.has-menu-open {
    overflow-y: hidden; }
  header.fui-header .fui-sidebar .sidebar-header {
    position: relative;
    height: 50px;
    display: block;
    box-shadow: 0 2px 0 0 #E9E9E9; }
    header.fui-header .fui-sidebar .sidebar-header .fui-switch {
      position: absolute;
      width: 116px;
      height: 25px;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      margin: auto; }
    header.fui-header .fui-sidebar .sidebar-header .close {
      position: absolute;
      display: block;
      width: 50px;
      height: 50px;
      right: 0; }
      header.fui-header .fui-sidebar .sidebar-header .close::before {
        transform: rotate(45deg); }
      header.fui-header .fui-sidebar .sidebar-header .close::after {
        transform: rotate(-45deg); }
      header.fui-header .fui-sidebar .sidebar-header .close::before, header.fui-header .fui-sidebar .sidebar-header .close::after {
        content: "";
        position: absolute;
        width: 2px;
        height: 24px;
        display: block;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        background: #141414; }
  header.fui-header .fui-sidebar .entrance {
    border-top: 1px solid #E8ECF0;
    display: flex;
    padding: 0 16px;
    background: #F9F9F9;
    box-shadow: 0 1px 0 0 #E3E7EB; }
    header.fui-header .fui-sidebar .entrance > div.entrance-item {
      flex: 1 1;
      flex-grow: 1;
      padding: 6px 0;
      text-align: center; }
      header.fui-header .fui-sidebar .entrance > div.entrance-item .icon {
        text-align: center; }
        header.fui-header .fui-sidebar .entrance > div.entrance-item .icon img {
          width: 48px; }
      header.fui-header .fui-sidebar .entrance > div.entrance-item .text {
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0.2px; }
  header.fui-header .fui-sidebar .user-section {
    padding: 8px 24px;
    display: block;
    font-weight: 600; }
    header.fui-header .fui-sidebar .user-section * {
      display: inline-block;
      vertical-align: middle; }
    header.fui-header .fui-sidebar .user-section + .menu {
      border-top: 1px solid #EBEBEB; }
    header.fui-header .fui-sidebar .user-section .avatar {
      border-radius: 0;
      bottom: -8px; }
    header.fui-header .fui-sidebar .user-section span {
      margin-left: 16px; }
      header.fui-header .fui-sidebar .user-section span.greetings {
        font-size: 18px;
        font-weight: 500; }
        header.fui-header .fui-sidebar .user-section span.greetings + a {
          float: right;
          margin-top: 16px; }
    header.fui-header .fui-sidebar .user-section a:link,
    header.fui-header .fui-sidebar .user-section a:link span {
      font-size: 16px; }
  header.fui-header .fui-sidebar .menu-footer {
    margin-top: 12px;
    margin-bottom: 12px; }
    header.fui-header .fui-sidebar .menu-footer .fui-item {
      height: 64px;
      line-height: 64px;
      padding: 0 30px; }
      header.fui-header .fui-sidebar .menu-footer .fui-item img {
        height: 42px; }
      header.fui-header .fui-sidebar .menu-footer .fui-item .content {
        padding-left: 12px;
        cursor: pointer; }
  header.fui-header .fui-sidebar .menu .item {
    position: relative; }
    header.fui-header .fui-sidebar .menu .item .menu-header {
      display: flex;
      box-shadow: 0 1px 0 0 #E8ECF0;
      align-content: center;
      align-items: center;
      cursor: pointer; }
      header.fui-header .fui-sidebar .menu .item .menu-header .action {
        padding: 0 30px; }
        header.fui-header .fui-sidebar .menu .item .menu-header .action [class*="icon-"] {
          display: block;
          font-size: 20px;
          transition: transform 0.3s ease; }
      header.fui-header .fui-sidebar .menu .item .menu-header .content {
        font-size: 1.125;
        padding: 0 30px;
        flex: 1 1;
        flex-grow: 1 1 0;
        height: 72px;
        line-height: 72px; }
    header.fui-header .fui-sidebar .menu .item .menu-content {
      display: block;
      overflow: hidden;
      width: 100%;
      height: 0;
      background: #F9F9F9; }
      header.fui-header .fui-sidebar .menu .item .menu-content .fui-item {
        background: #F9F9F9;
        border-top: 1px solid #E3E7EB;
        height: 48px;
        line-height: 48px;
        padding: 0 30px;
        cursor: pointer; }
      header.fui-header .fui-sidebar .menu .item .menu-content .scroll {
        padding-left: 10px;
        overflow-x: auto; }
    header.fui-header .fui-sidebar .menu .item.is-collapse::before, header.fui-header .fui-sidebar .menu .item.is-collapse::after {
      transition: all 0.3s ease; }
    header.fui-header .fui-sidebar .menu .item.is-collapse::after {
      content: "";
      top: 0;
      left: 0;
      position: absolute;
      border-left: 12px solid #EF3123;
      border-right: 12px solid transparent;
      border-top: 36px solid transparent;
      border-bottom: 36px solid transparent;
      transform: translateX(-12px);
      z-index: 3; }
    header.fui-header .fui-sidebar .menu .item.is-collapse::before {
      content: "";
      position: absolute;
      width: 12px;
      height: 100%;
      background: #D3060F;
      left: 0;
      top: 0;
      transform: translateX(-12px);
      z-index: 3; }
    header.fui-header .fui-sidebar .menu .item.is-collapse:active .menu-header {
      color: #EF3123; }
    header.fui-header .fui-sidebar .menu .item.is-collapse.menu-open::after, header.fui-header .fui-sidebar .menu .item.is-collapse.menu-open::before {
      transform: translateX(0px); }
    header.fui-header .fui-sidebar .menu .item.is-collapse.menu-open .menu-header {
      color: #EF3123; }
      header.fui-header .fui-sidebar .menu .item.is-collapse.menu-open .menu-header .action [class*="icon-"] {
        transform: rotate(180deg); }
    header.fui-header .fui-sidebar .menu .item.is-collapse.menu-open .menu-content {
      height: auto;
      display: block; }

header.fui-header .fui-sidebar .menu-footer .fui-item:hover {
  color: #EF3123; }

.is-ebu header.fui-header .fui-sidebar .menu-footer .fui-item:hover {
  color: #3966DA; }

.is-ebu header.fui-header .fui-sidebar .menu .item:active .menu-header,
.is-ebu header.fui-header .fui-sidebar .menu .item.menu-open .menu-header {
  color: #3966DA; }

.is-ebu header.fui-header .fui-sidebar .menu .item::before {
  background: #0F3B81; }

.is-ebu header.fui-header .fui-sidebar .menu .item::after {
  border-left-color: #3966DA; }

@media screen and (min-width: 960px) {
  header.fui-header .fui-sidebar {
    display: none; } }

@supports (-webkit-touch-callout: none) {
  header.fui-header .fui-sidebar {
    padding-bottom: 75px; } }

input::-ms-clear,
textarea::-ms-clear {
  display: none !important; }

input[disabled],
textarea[disabled] {
  background: #f1f3f5; }

form.w-100 {
  width: 100%; }

form.fui-container {
  padding-bottom: 80px; }
  form.fui-container .MuiGrid-root .form-group {
    margin-bottom: 16px; }
  form.fui-container button {
    width: 100%; }

.form-editor--list {
  border: solid 2px #E8ECF0;
  border-bottom: 0;
  display: flex;
  flex-direction: row; }
  .form-editor--list:nth-last-child(1) {
    border-bottom: solid 2px #E8ECF0; }
  .form-editor--list--left {
    flex: 0 0 300px;
    border-right: solid 2px #E8ECF0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #5F6E84;
    background-color: #f1f3f5; }
  .form-editor--list--right {
    background-color: #FFFFFF;
    width: 100%; }
    .form-editor--list--right .head {
      padding: 12px 30px;
      border-bottom: solid 2px #E8ECF0;
      color: #5F6E84; }
    .form-editor--list--right .content {
      padding: 30px; }
      .form-editor--list--right .content small {
        color: #8A8A8A;
        display: flex;
        height: 100%;
        align-items: center; }
  .form-editor--list .d-flex .form-editor--list--right:nth-child(2) {
    border-top: 2px solid #E8ECF0; }

.fui-select select {
  display: none; }
  .fui-select select[disabled] + .fui-dropdown {
    pointer-events: none; }
    .fui-select select[disabled] + .fui-dropdown > button {
      background: #f1f3f5;
      color: #BFBFBF; }
  .fui-select select + .fui-dropdown > button[aria-expanded="true"] {
    border-color: #6a798e; }
  .fui-select select + .fui-dropdown + .error-message {
    margin-top: 12px; }

.fui-select .fui-dropdown button {
  display: flex;
  width: 100%;
  height: 50px;
  text-align: left;
  padding-left: 12px;
  border: 2px solid #E8ECF0;
  justify-content: space-between;
  align-content: center;
  align-items: center; }
  .fui-select .fui-dropdown button i {
    float: right;
    margin-top: 4px; }

.form-description {
  margin-bottom: 12px;
  margin-top: 0; }

.required-dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background: #f05c38;
  vertical-align: middle;
  margin-right: 8px; }

.fui-textarea {
  line-height: 0;
  border: 2px solid #E8ECF0;
  margin-bottom: 15px; }
  .fui-textarea.is-focus {
    border-color: #6a798e; }
  .fui-textarea textarea {
    border: 0;
    min-height: 306px;
    background: #FFF;
    padding: 18px 12px;
    line-height: 1.75;
    font-size: 1rem;
    border-bottom: 0 !important;
    width: 100%; }
  .fui-textarea textarea + .max-length-counter {
    background: #E8ECF0;
    color: #5F6E84;
    padding: 8px 18px;
    text-align: right;
    line-height: 1.75;
    height: 40px; }

.input-row .form-group {
  width: 100%;
  margin-top: 0; }

.error-message {
  font-size: 0.875rem;
  color: #f05c38;
  margin-top: 12px;
  text-align: left; }

.form-group {
  margin: 0 0 30px;
  display: flex;
  flex-direction: column; }
  .form-group .text-input {
    min-width: auto; }
  .form-group.is-two label.radio {
    width: calc( 50% - 12px); }
  .form-group.is-two .radio-group .radio.is-icon-box .content {
    align-items: center; }
  .form-group.is-two .content {
    flex-direction: column; }
    .form-group.is-two .content .text {
      color: #5F6E84; }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .form-group.is-popup .radio.is-icon-box .icon {
      width: 100%;
      max-height: 500px; }
    .form-group.is-popup .radio.is-icon-box .content {
      max-height: 600px; } }
  .form-group.is-popup .radio.is-icon-box .content {
    padding: 54px 24px; }
    .form-group.is-popup .radio.is-icon-box .content .text {
      position: absolute;
      bottom: 25px; }
  .form-group.is-popup .radio.is-icon-box .icon {
    position: relative; }
    .form-group.is-popup .radio.is-icon-box .icon .open-image {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      position: absolute;
      right: 20px;
      bottom: 30px;
      background-color: #FFFFFF;
      box-shadow: 0 16px 32px 0 rgba(75, 88, 105, 0.3);
      cursor: pointer; }
      .form-group.is-popup .radio.is-icon-box .icon .open-image i {
        font-size: 30px;
        color: #141414; }
  .form-group.is-popup label.radio .content {
    height: auto; }
    .form-group.is-popup label.radio .content .icon img {
      width: 100%;
      height: auto; }
  @media screen and (min-width: 600px) {
    .form-group.two-buttons {
      display: block; } }
  .form-group .email-validation {
    display: block;
    margin-top: 18px;
    margin-bottom: 48px; }
    @media screen and (max-width: 960px) {
      .form-group .email-validation {
        margin-bottom: 16px; } }
  .form-group .checked-email {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 16px;
    padding: 12px;
    width: 100%;
    border: 2px solid #E8ECF0;
    height: 50px;
    align-items: center; }
    .form-group .checked-email p {
      margin: 0; }
    .form-group .checked-email img {
      width: 30px;
      height: 30px; }
  .form-group .input-row .MuiGrid-root.MuiGrid-container {
    margin-top: 0;
    margin-bottom: 0; }
  .form-group .input-row .MuiGrid-root.MuiGrid-item {
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 24px; }
  .form-group .input-row .text-input,
  .form-group .input-row .text-input input {
    width: 100%; }
  .form-group label + .form-group,
  .form-group .radio-buttons {
    margin-bottom: 0; }
  .form-group.last-full-width .radio-buttons .radio-button:last-child {
    width: 100%; }
  .form-group.is-invalid input,
  .form-group.is-invalid input:focus,
  .form-group.is-invalid .fui-textarea {
    border-color: #f05c38; }
  .form-group .text-input {
    margin-bottom: 0; }
    .form-group .text-input .eye {
      position: absolute;
      width: 50px;
      height: 50px;
      right: 8px;
      top: 0;
      display: flex;
      justify-content: center;
      justify-items: center;
      align-content: center;
      align-items: center; }
      .form-group .text-input .eye + .reset {
        right: 60px; }
      .form-group .text-input .eye.pwd-show {
        opacity: 0.5; }
      .form-group .text-input .eye i {
        font-size: 24px; }
  .form-group .credit-input {
    display: flex;
    align-items: center;
    align-content: center; }
    .form-group .credit-input input {
      min-width: 0; }
    .form-group .credit-input span {
      margin: 0 8px; }
  .form-group.hide-required label.is-required::after {
    display: none; }
  .form-group label {
    display: block;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 18px; }
    .form-group label i [class*="icon-"] {
      font-size: 24px;
      vertical-align: middle; }
    .form-group label.is-required::after {
      content: "";
      display: inline-block;
      width: 8px;
      height: 8px;
      margin-left: 8px;
      border-radius: 4px;
      vertical-align: top;
      background: #f05c38; }
  .form-group input {
    border: solid 2px #E8ECF0; }
  .form-group input,
  .form-group textarea {
    min-width: 100%;
    max-width: 100%;
    color: #202020;
    font-size: 16px;
    padding: 12px;
    left: 0;
    width: 100%; }
  .form-group input:focus,
  .form-group textarea:focus {
    border-color: #6a798e; }
  @media screen and (min-width: 960px) {
    .form-group label {
      min-height: 28px; } }

@media screen and (max-width: 960px) {
  .form-editor--list--left {
    flex: 0 0 86px;
    font-size: 14px;
    padding: 12px;
    text-align: center; }
  .form-editor--list--right .head {
    font-size: 14px;
    padding: 6px 12px; }
  .form-editor--list--right .content {
    padding: 12px 12px; }
    .form-editor--list--right .content .form-group label {
      font-size: 18px; }
    .form-editor--list--right .content .form-group label.radio {
      width: calc( 50% - 6px);
      font-size: 18px; }
    .form-editor--list--right .content .is-two label.radio:nth-last-child(1) {
      margin-right: 0; }
    .form-editor--list--right .content .radio .content .text {
      display: flex;
      flex: 1 1 auto;
      align-items: center; }
  .form-group.is-two label.radio {
    width: 100%;
    margin-right: 0; }
    .form-group.is-two label.radio .content {
      padding: 36px 20px 30px; }
      .form-group.is-two label.radio .content::before {
        bottom: auto;
        top: 12px;
        left: 12px; }
      .form-group.is-two label.radio .content::after {
        bottom: auto;
        top: 22px;
        left: 22px; }
  .form-group.is-popup .radio.is-icon-box .content::before {
    bottom: auto;
    top: 12px;
    left: 12px; }
  .form-group.is-popup .radio.is-icon-box .content::after {
    bottom: auto;
    top: 22px;
    left: 22px; } }

.password-strength {
  display: flex;
  color: #BC392F;
  font-size: 0.875rem;
  margin-top: -15px;
  margin-bottom: 54px;
  align-content: center;
  align-items: center; }
  @media screen and (min-width: 960px) {
    .password-strength {
      margin-top: -45px; } }
  .password-strength.is-short::after {
    display: none; }
  .password-strength::after {
    content: "";
    display: flex;
    height: 12px;
    flex: 1 1;
    flex-grow: 1;
    background: #BC392F;
    margin-left: 12px; }
  .password-strength.is-normal {
    color: #ECC141; }
    .password-strength.is-normal::after {
      background: #ECC141; }
  .password-strength.is-strong {
    color: #5f7b80; }
    .password-strength.is-strong::after {
      background: #5f7b80; }

.with-note .form-group {
  margin-bottom: 0; }

.modal-radio .radio-buttons {
  flex-direction: column; }
  .modal-radio .radio-buttons .radio-button {
    margin-right: 0;
    width: 100%; }
  .modal-radio .radio-buttons .d-flex {
    width: 100%; }
  .modal-radio .radio-buttons input.input-column {
    width: 100%;
    position: relative;
    opacity: 1; }

.manage-input-group {
  margin-bottom: 60px;
  display: flex;
  flex-direction: column; }
  .manage-input-group h4 {
    margin: 0 0 18px 0; }
  .manage-input-group h6 {
    margin: 18px 0; }
  .manage-input-group .radio-buttons {
    margin-bottom: 0; }
  .manage-input-group .input-group input {
    margin-top: 18px; }
  .manage-input-group label {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 12px; }
    .manage-input-group label.is-required::after {
      content: "";
      display: inline-block;
      width: 8px;
      height: 8px;
      border-radius: 4px;
      vertical-align: top;
      background: #f05c38;
      margin-left: 8px; }
    .manage-input-group label::after {
      content: "";
      width: 8px;
      height: 8px;
      background-color: #f05c38;
      border-radius: 50%;
      position: absolute;
      margin-left: 4px; }
  .manage-input-group input {
    width: 100%;
    height: 50px;
    font-size: 16px;
    padding: 15px;
    border: solid 2px #E8ECF0;
    color: #8A8A8A; }

.text-input {
  position: relative;
  display: inline-block;
  min-width: 300px;
  max-width: 100%;
  margin-bottom: 36px; }
  .text-input input {
    font-size: 16px;
    padding: 12px 36px 12px 12px;
    width: 100%;
    border: 2px solid #E8ECF0; }
    .text-input input::-ms-clear {
      display: none; }
    .text-input input:-ms-input-placeholder {
      color: #8A8A8A;
      font-weight: 500; }
    .text-input input::placeholder {
      color: #8A8A8A;
      font-weight: 500; }
    .text-input input:-ms-input-placeholder {
      color: #8A8A8A;
      font-weight: 500; }
    .text-input input::-ms-input-placeholder {
      color: #8A8A8A;
      font-weight: 500; }
  .text-input span {
    margin: 12px 0 -8px 0;
    color: #f05c38;
    font-size: 14px;
    display: block; }
    .text-input span.error-message {
      margin: 12px 0 4px 0; }
  .text-input .invalid {
    border: 2px solid #f05c38; }
  .text-input .reset {
    position: absolute;
    right: 14px;
    height: 20px;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    font-size: 20px;
    cursor: pointer; }

header.is-fixed ~ main.fui-form {
  margin-top: 60px; }
  header.is-fixed ~ main.fui-form .fui-banner.is-form .fui-container {
    margin-top: 0;
    height: 100%; }
  header.is-fixed ~ main.fui-form .fui-banner.is-product .fui-container {
    margin-top: 0;
    height: 100%; }
  header.is-fixed ~ main.fui-form .fui-container {
    margin-top: 50px; }
  header.is-fixed ~ main.fui-form .fui-content.bg-arrow {
    padding-bottom: 0; }

@media screen and (max-width: 960px) {
  .text-input {
    width: 100%;
    min-width: 0;
    min-width: initial; }
    .text-input span.error-message {
      margin: 12px 0 -12px 0; }
  .form-group textarea {
    height: 266px;
    min-height: 0;
    min-height: initial; }
  .form-description {
    margin-top: 0; } }

@media screen and (min-width: 960px) {
  .fui-textarea textarea {
    min-height: 120px; }
  .form-group {
    margin-bottom: 54px; }
    .form-group label {
      font-size: 20px; }
  form.fui-container {
    padding-bottom: 120px; }
    form.fui-container .MuiGrid-root .form-group {
      margin: 0 0 46px; }
    form.fui-container button {
      width: auto; } }

input {
  -webkit-appearance: none; }

input:focus,
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  background: #FFF !important;
  color: #202020 !important;
  box-shadow: 0 0 0 1000px white inset;
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
  -webkit-text-fill-color: #333 !important; }

.is-ebu footer.fui-footer,
.is-help-center footer.fui-footer,
footer.fui-footer {
  position: relative;
  font-size: 0.98rem;
  color: white;
  padding: 30px 0 24px;
  background: #232323;
  z-index: 1; }
  .is-ebu footer.fui-footer a, .is-ebu footer.fui-footer a:link, .is-ebu footer.fui-footer a:visited,
  .is-help-center footer.fui-footer a,
  .is-help-center footer.fui-footer a:link,
  .is-help-center footer.fui-footer a:visited,
  footer.fui-footer a,
  footer.fui-footer a:link,
  footer.fui-footer a:visited {
    color: #FFFFFF; }
    .is-ebu footer.fui-footer a:hover, .is-ebu footer.fui-footer a:link:hover, .is-ebu footer.fui-footer a:visited:hover,
    .is-help-center footer.fui-footer a:hover,
    .is-help-center footer.fui-footer a:link:hover,
    .is-help-center footer.fui-footer a:visited:hover,
    footer.fui-footer a:hover,
    footer.fui-footer a:link:hover,
    footer.fui-footer a:visited:hover {
      opacity: 0.6; }
  .is-ebu footer.fui-footer .fui-section-promo,
  .is-help-center footer.fui-footer .fui-section-promo,
  footer.fui-footer .fui-section-promo {
    margin-bottom: 30px; }
  .is-ebu footer.fui-footer .links,
  .is-help-center footer.fui-footer .links,
  footer.fui-footer .links {
    display: inline-block;
    margin-top: -14px; }
    .is-ebu footer.fui-footer .links a,
    .is-help-center footer.fui-footer .links a,
    footer.fui-footer .links a {
      color: #fff;
      padding-right: 6px;
      border-right: 1px solid #fff;
      margin-right: 6px;
      height: 16px;
      line-height: 16px;
      display: inline-block;
      vertical-align: baseline; }
    .is-ebu footer.fui-footer .links .text-sm,
    .is-help-center footer.fui-footer .links .text-sm,
    footer.fui-footer .links .text-sm {
      display: flex;
      margin-top: 8px; }
    .is-ebu footer.fui-footer .links small,
    .is-help-center footer.fui-footer .links small,
    footer.fui-footer .links small {
      line-height: 16px; }
  .is-ebu footer.fui-footer a.fui-item:link,
  .is-ebu footer.fui-footer a.fui-item[role="button"],
  .is-ebu footer.fui-footer .fui-item,
  .is-help-center footer.fui-footer a.fui-item:link,
  .is-help-center footer.fui-footer a.fui-item[role="button"],
  .is-help-center footer.fui-footer .fui-item,
  footer.fui-footer a.fui-item:link,
  footer.fui-footer a.fui-item[role="button"],
  footer.fui-footer .fui-item {
    font-weight: 300;
    color: #FFFFFF; }
    .is-ebu footer.fui-footer a.fui-item:link:hover, .is-ebu footer.fui-footer a.fui-item:link:focus,
    .is-ebu footer.fui-footer a.fui-item[role="button"]:hover,
    .is-ebu footer.fui-footer a.fui-item[role="button"]:focus,
    .is-ebu footer.fui-footer .fui-item:hover,
    .is-ebu footer.fui-footer .fui-item:focus,
    .is-help-center footer.fui-footer a.fui-item:link:hover,
    .is-help-center footer.fui-footer a.fui-item:link:focus,
    .is-help-center footer.fui-footer a.fui-item[role="button"]:hover,
    .is-help-center footer.fui-footer a.fui-item[role="button"]:focus,
    .is-help-center footer.fui-footer .fui-item:hover,
    .is-help-center footer.fui-footer .fui-item:focus,
    footer.fui-footer a.fui-item:link:hover,
    footer.fui-footer a.fui-item:link:focus,
    footer.fui-footer a.fui-item[role="button"]:hover,
    footer.fui-footer a.fui-item[role="button"]:focus,
    footer.fui-footer .fui-item:hover,
    footer.fui-footer .fui-item:focus {
      color: #FFFFFF;
      opacity: 0.6; }
  .is-ebu footer.fui-footer .fui-dropdown,
  .is-help-center footer.fui-footer .fui-dropdown,
  footer.fui-footer .fui-dropdown {
    display: table !important;
    margin-top: -6px; }
    .is-ebu footer.fui-footer .fui-dropdown .fui-menu,
    .is-help-center footer.fui-footer .fui-dropdown .fui-menu,
    footer.fui-footer .fui-dropdown .fui-menu {
      z-index: 99; }
    .is-ebu footer.fui-footer .fui-dropdown .fui-menu .fui-item,
    .is-help-center footer.fui-footer .fui-dropdown .fui-menu .fui-item,
    footer.fui-footer .fui-dropdown .fui-menu .fui-item {
      color: #202020; }
  .is-ebu footer.fui-footer .fui-collapse,
  .is-help-center footer.fui-footer .fui-collapse,
  footer.fui-footer .fui-collapse {
    margin: 0; }
    .is-ebu footer.fui-footer .fui-collapse .collapse-header,
    .is-help-center footer.fui-footer .fui-collapse .collapse-header,
    footer.fui-footer .fui-collapse .collapse-header {
      margin: 0;
      font-size: 1rem;
      line-height: 1.75;
      font-weight: 300;
      padding: 6px 0; }
      .is-ebu footer.fui-footer .fui-collapse .collapse-header::after, .is-ebu footer.fui-footer .fui-collapse .collapse-header::before,
      .is-help-center footer.fui-footer .fui-collapse .collapse-header::after,
      .is-help-center footer.fui-footer .fui-collapse .collapse-header::before,
      footer.fui-footer .fui-collapse .collapse-header::after,
      footer.fui-footer .fui-collapse .collapse-header::before {
        color: #FFFFFF; }
    .is-ebu footer.fui-footer .fui-collapse .mobile-collapse,
    .is-help-center footer.fui-footer .fui-collapse .mobile-collapse,
    footer.fui-footer .fui-collapse .mobile-collapse {
      padding-top: 0; }
      .is-ebu footer.fui-footer .fui-collapse .mobile-collapse .fui-item,
      .is-help-center footer.fui-footer .fui-collapse .mobile-collapse .fui-item,
      footer.fui-footer .fui-collapse .mobile-collapse .fui-item {
        font-size: 0.875rem;
        font-weight: 300;
        padding: 3px 0;
        line-height: 1.5rem; }
  .is-ebu footer.fui-footer p.text,
  .is-help-center footer.fui-footer p.text,
  footer.fui-footer p.text {
    line-height: 28px; }
    .is-ebu footer.fui-footer p.text *,
    .is-help-center footer.fui-footer p.text *,
    footer.fui-footer p.text * {
      vertical-align: middle; }
    .is-ebu footer.fui-footer p.text i[class*='icon-'],
    .is-help-center footer.fui-footer p.text i[class*='icon-'],
    footer.fui-footer p.text i[class*='icon-'] {
      font-size: 24px; }
  .is-ebu footer.fui-footer .text-gray,
  .is-help-center footer.fui-footer .text-gray,
  footer.fui-footer .text-gray {
    color: #8A8A8A; }
  .is-ebu footer.fui-footer .footer-menu-title,
  .is-help-center footer.fui-footer .footer-menu-title,
  footer.fui-footer .footer-menu-title {
    color: #8A8A8A;
    display: block;
    padding: 8px 0; }
  .is-ebu footer.fui-footer .footer-menu-item,
  .is-help-center footer.fui-footer .footer-menu-item,
  footer.fui-footer .footer-menu-item {
    font-size: 14px;
    display: block;
    color: white;
    padding: 6.4px 0; }
  .is-ebu footer.fui-footer .phone-promo,
  .is-help-center footer.fui-footer .phone-promo,
  footer.fui-footer .phone-promo {
    font-size: 18px;
    line-height: 28px;
    margin-top: 0;
    margin-bottom: 0; }
    .is-ebu footer.fui-footer .phone-promo ~ p,
    .is-help-center footer.fui-footer .phone-promo ~ p,
    footer.fui-footer .phone-promo ~ p {
      margin-top: 0; }
    .is-ebu footer.fui-footer .phone-promo ~ a:link,
    .is-ebu footer.fui-footer .phone-promo ~ a:visited,
    .is-help-center footer.fui-footer .phone-promo ~ a:link,
    .is-help-center footer.fui-footer .phone-promo ~ a:visited,
    footer.fui-footer .phone-promo ~ a:link,
    footer.fui-footer .phone-promo ~ a:visited {
      color: #FFFFFF; }
      .is-ebu footer.fui-footer .phone-promo ~ a:link *,
      .is-ebu footer.fui-footer .phone-promo ~ a:visited *,
      .is-help-center footer.fui-footer .phone-promo ~ a:link *,
      .is-help-center footer.fui-footer .phone-promo ~ a:visited *,
      footer.fui-footer .phone-promo ~ a:link *,
      footer.fui-footer .phone-promo ~ a:visited * {
        vertical-align: middle; }
      .is-ebu footer.fui-footer .phone-promo ~ a:link i,
      .is-ebu footer.fui-footer .phone-promo ~ a:visited i,
      .is-help-center footer.fui-footer .phone-promo ~ a:link i,
      .is-help-center footer.fui-footer .phone-promo ~ a:visited i,
      footer.fui-footer .phone-promo ~ a:link i,
      footer.fui-footer .phone-promo ~ a:visited i {
        font-size: 24px; }
    .is-ebu footer.fui-footer .phone-promo em,
    .is-help-center footer.fui-footer .phone-promo em,
    footer.fui-footer .phone-promo em {
      font-style: normal;
      font-size: 24px; }
  .is-ebu footer.fui-footer .contact-info,
  .is-help-center footer.fui-footer .contact-info,
  footer.fui-footer .contact-info {
    line-height: 1.5; }
    .is-ebu footer.fui-footer .contact-info + a.MuiLink-root,
    .is-help-center footer.fui-footer .contact-info + a.MuiLink-root,
    footer.fui-footer .contact-info + a.MuiLink-root {
      color: white;
      margin-top: 40px; }
  .is-ebu footer.fui-footer .MuiButton-root,
  .is-help-center footer.fui-footer .MuiButton-root,
  footer.fui-footer .MuiButton-root {
    color: #FFF; }
  .is-ebu footer.fui-footer .copyright,
  .is-help-center footer.fui-footer .copyright,
  footer.fui-footer .copyright {
    margin-top: 36px;
    align-content: center;
    align-items: center;
    justify-content: center;
    justify-items: center; }
    .is-ebu footer.fui-footer .copyright img,
    .is-help-center footer.fui-footer .copyright img,
    footer.fui-footer .copyright img {
      width: 93px;
      margin-bottom: 12px; }
  .is-ebu footer.fui-footer .social-links,
  .is-help-center footer.fui-footer .social-links,
  footer.fui-footer .social-links {
    margin-top: 16px;
    margin-bottom: 10px; }
    .is-ebu footer.fui-footer .social-links .socail-item,
    .is-help-center footer.fui-footer .social-links .socail-item,
    footer.fui-footer .social-links .socail-item {
      display: inline-flex;
      width: 40px;
      height: 40px;
      border-radius: 20px;
      color: #FFFFFF;
      font-size: 1.25rem;
      text-align: center;
      margin-right: 24px;
      align-content: center;
      align-items: center;
      justify-content: center;
      justify-items: center;
      vertical-align: middle; }
  .is-ebu footer.fui-footer .MuiGrid-root > span,
  .is-help-center footer.fui-footer .MuiGrid-root > span,
  footer.fui-footer .MuiGrid-root > span {
    margin-bottom: 12px; }

footer.blank-footer {
  padding-top: 24px !important; }
  footer.blank-footer .links {
    text-align: left; }
  footer.blank-footer .MuiGrid-root {
    align-items: center;
    margin-top: 18px;
    order: 2; }
  footer.blank-footer .copyright {
    margin-top: 0 !important; }
    footer.blank-footer .copyright .MuiGrid-item > .fui-dropdown {
      margin: 0 auto;
      width: 100%; }
    footer.blank-footer .copyright img {
      margin-top: -18px; }

footer.fui-footer.mrtg img {
  margin-top: -14px; }

footer.fui-footer.office365 {
  height: 120px;
  padding: 21px 0; }
  footer.fui-footer.office365 img {
    margin-top: -52px; }
  footer.fui-footer.office365 .copyright {
    margin-top: 16px; }
  footer.fui-footer.office365 .links span {
    margin-top: 2px; }

footer.fui-footer.ebu-login {
  height: 92px;
  padding: 21px 0; }
  footer.fui-footer.ebu-login .copyright {
    margin-top: 16px; }
  footer.fui-footer.ebu-login .links span {
    margin-top: 2px; }

footer.fui-footer.eng-footer {
  padding: 32px 0 0 0 !important; }
  footer.fui-footer.eng-footer .footer-fetnet {
    margin-top: 12px; }
  footer.fui-footer.eng-footer .footer-lang {
    margin-top: 0; }
  footer.fui-footer.eng-footer img {
    margin-top: -20px; }
  footer.fui-footer.eng-footer .links span.text-sm {
    margin-top: 0; }

@media screen and (min-width: 960px) {
  .is-ebu footer.fui-footer,
  .is-help-center footer.fui-footer,
  footer.fui-footer {
    padding: 30px 0 24px; }
    .is-ebu footer.fui-footer .fui-section-promo,
    .is-help-center footer.fui-footer .fui-section-promo,
    footer.fui-footer .fui-section-promo {
      margin-bottom: 40px; }
    .is-ebu footer.fui-footer .fui-collapse,
    .is-help-center footer.fui-footer .fui-collapse,
    footer.fui-footer .fui-collapse {
      margin: 0; }
      .is-ebu footer.fui-footer .fui-collapse .collapse-header,
      .is-help-center footer.fui-footer .fui-collapse .collapse-header,
      footer.fui-footer .fui-collapse .collapse-header {
        font-size: 0.875rem; }
    .is-ebu footer.fui-footer .copyright,
    .is-help-center footer.fui-footer .copyright,
    footer.fui-footer .copyright {
      vertical-align: bottom; }
      .is-ebu footer.fui-footer .copyright img,
      .is-help-center footer.fui-footer .copyright img,
      footer.fui-footer .copyright img {
        margin-right: 24px;
        vertical-align: middle; }
    .is-ebu footer.fui-footer .social-links,
    .is-help-center footer.fui-footer .social-links,
    footer.fui-footer .social-links {
      margin-top: 52px; }
    .is-ebu footer.fui-footer .phone-promo,
    .is-help-center footer.fui-footer .phone-promo,
    footer.fui-footer .phone-promo {
      font-size: 14px;
      margin-bottom: 0; }
      .is-ebu footer.fui-footer .phone-promo em,
      .is-help-center footer.fui-footer .phone-promo em,
      footer.fui-footer .phone-promo em {
        font-size: 0.875rem !important; }
    .is-ebu footer.fui-footer .collapse-header,
    .is-help-center footer.fui-footer .collapse-header,
    footer.fui-footer .collapse-header {
      color: #8A8A8A;
      margin-bottom: 0; } }

@media screen and (max-width: 960px) {
  .is-en .copyright .MuiGrid-item > .fui-dropdown {
    margin-bottom: 0 !important; }
  .is-en .footer-lang .fui-dropdown .fui-dropdown-item {
    margin-top: 24px;
    margin-left: 0; }
  .is-ebu footer.fui-footer .copyright .MuiGrid-item:nth-child(1),
  .is-help-center footer.fui-footer .copyright .MuiGrid-item:nth-child(1),
  footer.fui-footer .copyright .MuiGrid-item:nth-child(1) {
    order: 2; }
  .is-ebu footer.fui-footer .copyright .MuiGrid-item > *,
  .is-help-center footer.fui-footer .copyright .MuiGrid-item > *,
  footer.fui-footer .copyright .MuiGrid-item > * {
    display: block;
    text-align: center; }
  .is-ebu footer.fui-footer .copyright .MuiGrid-item > img,
  .is-help-center footer.fui-footer .copyright .MuiGrid-item > img,
  footer.fui-footer .copyright .MuiGrid-item > img {
    margin-left: auto;
    margin-right: auto; }
  .is-ebu footer.fui-footer .copyright .MuiGrid-item > .fui-dropdown,
  .is-help-center footer.fui-footer .copyright .MuiGrid-item > .fui-dropdown,
  footer.fui-footer .copyright .MuiGrid-item > .fui-dropdown {
    margin-bottom: 38px;
    width: 100%; }
  .is-ebu footer.fui-footer .phone-promo ~ p,
  .is-help-center footer.fui-footer .phone-promo ~ p,
  footer.fui-footer .phone-promo ~ p {
    font-size: 1rem; }
  footer.blank-footer.fui-footer .footer-fetnet .d-flex {
    flex-direction: column; }
  footer.blank-footer.fui-footer .links {
    width: 100%;
    margin-top: 0;
    text-align: center; }
  footer.blank-footer.fui-footer .copyright img {
    margin-top: 0; }
  footer.blank-footer.fui-footer .copyright span.text-sm {
    margin-bottom: 12px; }
  footer.blank-footer.fui-footer span {
    justify-content: center; }
  .footer-lang {
    margin-top: 0 !important;
    order: 1 !important; }
    .footer-lang .fui-dropdown {
      float: left; }
      .footer-lang .fui-dropdown .fui-dropdown-item {
        margin: 0 auto; }
  .footer-fetnet {
    display: flex;
    flex-direction: column;
    order: 1;
    margin-top: 0 !important; }
  footer.fui-footer.eng-footer {
    padding: 16px 0 0 0 !important;
    height: auto;
    height: initial; }
    footer.fui-footer.eng-footer .footer-lang {
      text-align: left; }
    footer.fui-footer.eng-footer .copyright .MuiGrid-item img {
      margin: 30px auto 20px !important; }
    footer.fui-footer.eng-footer .links {
      margin-top: 0; }
      footer.fui-footer.eng-footer .links span.text-sm {
        margin-top: 18px;
        padding-bottom: 30px; } }

@media screen and (max-width: 768px) {
  footer.fui-footer .copyright .MuiGrid-item > img {
    margin: 0 auto 12px; }
  footer.fui-footer .copyright .MuiGrid-item .fui-dropdown {
    width: 100%; }
  footer.blank-footer {
    height: auto; }
    footer.blank-footer .MuiGrid-root {
      order: 2;
      margin-top: 24px; }
    footer.blank-footer .links,
    footer.blank-footer footer.fui-footer .links {
      margin-top: 0;
      margin-bottom: 12px; }
    footer.blank-footer .footer-lang .fui-dropdown {
      float: none; } }

@media screen and (min-width: 640px) and (max-width: 768px) {
  .is-ebu footer.fui-footer .copyright .MuiGrid-item > .fui-dropdown,
  footer.fui-footer .copyright .MuiGrid-item > .fui-dropdown {
    margin-right: 60px; } }

@media screen and (max-width: 767px) {
  .is-ebu footer.blank-footer .copyright .MuiGrid-item > *,
  footer.fui-footer .copyright .MuiGrid-item > * {
    text-align: center; } }

figure {
  width: auto;
  margin: 24px 0;
  overflow-x: auto;
  text-align: center; }
  figure.is-inline {
    position: relative;
    display: inline-block;
    width: 100%; }
  figure > img {
    width: 100%;
    height: auto;
    margin: 0; }
  figure figcaption {
    display: inline-block;
    max-width: 894px;
    font-size: 1rem;
    text-align: left;
    padding: 12px 12px 12px 42px;
    margin-top: 18px;
    color: #0A3476;
    background: #fafafa;
    min-width: 285px; }
    figure figcaption::before {
      content: "";
      float: left;
      margin-left: -20px;
      margin-top: 3px;
      border-top: 7px solid transparent;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 7px solid #0A3476; }

.fui-gallery {
  display: flex;
  flex-wrap: wrap;
  margin: 24px -6px; }
  .fui-gallery .fui-figure {
    width: calc(50% - 12px);
    margin: 6px; }
    .fui-gallery .fui-figure a:link::after,
    .fui-gallery .fui-figure a:visited::after {
      display: none !important; }
    .fui-gallery .fui-figure img {
      width: 100%;
      margin: 0; }

@media screen and (min-width: 960px) {
  figure.fui-figure.is-inline {
    position: relative;
    display: inline-block;
    width: calc(50% - 32px);
    margin-left: 16px;
    margin-right: 16px; }
  figure.fui-figure > img {
    max-width: 900px;
    height: auto;
    margin: 0 auto 6px !important; }
  figure.fui-figure figcaption {
    margin-bottom: 48px; }
  .fui-gallery {
    margin: 0 -6px; }
    .fui-gallery a:link::after,
    .fui-gallery a:visited::after {
      display: none; }
    .fui-gallery .fui-figure,
    .fui-gallery.three-column .fui-figure {
      width: calc(33.3333% - 12px); }
    .fui-gallery.four-column .fui-figure {
      width: calc(25% - 12px); } }

.done-manage-newsletter {
  text-align: center;
  margin-top: 196px; }
  .done-manage-newsletter img {
    margin-bottom: 24px;
    width: 220px; }
  .done-manage-newsletter h4 {
    margin: 0 0 12px 0; }
  .done-manage-newsletter p {
    margin: 0 0 24px 0; }
  .done-manage-newsletter .is-secondary {
    margin-right: 15px; }

@media screen and (max-width: 960px) {
  .done-manage-newsletter .button-group button {
    width: 100%;
    max-width: 300px;
    margin-bottom: 12px;
    margin-right: 12px; }
  .done-manage-newsletter .button-group .is-secondary {
    margin-right: 12px;
    height: 50px; } }

.manage-newsletter {
  padding-bottom: 125px; }
  .manage-newsletter h4 {
    margin: 46px 0 18px 0; }
  .manage-newsletter .open-detail {
    margin-bottom: 30px; }
  .manage-newsletter .text-input {
    min-width: 0;
    min-width: initial; }
  .manage-newsletter .option-name {
    position: relative; }
    .manage-newsletter .option-name h6::after {
      content: "";
      width: 8px;
      height: 8px;
      background-color: #f05c38;
      border-radius: 50%;
      position: absolute;
      margin-left: 4px; }
  .manage-newsletter .form-group {
    margin-bottom: 42px; }
  .manage-newsletter .radio-buttons {
    display: flex;
    margin-bottom: 0px; }
    .manage-newsletter .radio-buttons .radio-group {
      display: flex;
      align-items: center; }
      .manage-newsletter .radio-buttons .radio-group p {
        display: inline-block;
        margin: 0; }
      .manage-newsletter .radio-buttons .radio-group .radio-button {
        width: auto;
        margin-right: 72px; }
  .manage-newsletter .recaptcha {
    margin-bottom: 32px; }
  .manage-newsletter .newsletter-checkbox-group {
    margin-bottom: 30px;
    display: flex;
    align-items: center; }
    .manage-newsletter .newsletter-checkbox-group .checkbox {
      height: 30px;
      margin: 0; }
    .manage-newsletter .newsletter-checkbox-group .detail {
      font-weight: 500;
      margin-left: 6px;
      cursor: pointer;
      display: inline-flex; }
      .manage-newsletter .newsletter-checkbox-group .detail i {
        font-size: 24px;
        margin-top: -2px;
        margin-left: 6px; }
  .manage-newsletter .radio-button {
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: flex;
    align-items: center;
    margin-right: 72px; }
    .manage-newsletter .radio-button input {
      position: absolute;
      opacity: 0;
      cursor: pointer; }
    .manage-newsletter .radio-button:hover input ~ .checkmark {
      background-color: #E8ECF0; }
    .manage-newsletter .radio-button input:checked ~ .checkmark {
      background-color: #2F59C4;
      border: 2px solid #2F59C4; }
    .manage-newsletter .radio-button input:checked ~ .checkmark:after {
      display: block; }
    .manage-newsletter .radio-button .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 30px;
      width: 30px;
      border-radius: 50%;
      border: 2px solid #BFBFBF; }
      .manage-newsletter .radio-button .checkmark:after {
        content: "";
        position: absolute;
        display: none;
        top: 8px;
        left: 8px;
        width: 11px;
        height: 11px;
        border-radius: 50%;
        background: white; }

@media screen and (max-width: 960px) {
  .manage-newsletter .fui-banner .fui-container {
    height: 100%; }
  .manage-newsletter .newsletter-checkbox-group {
    align-items: flex-end; }
  .manage-newsletter button {
    width: 100%; }
  .manage-newsletter .radio-buttons .radio-group .radio-button {
    margin-right: 70px; }
    .manage-newsletter .radio-buttons .radio-group .radio-button p {
      font-size: 14px; }
  .manage-newsletter .radio-buttons .radio-group .radio-button:nth-last-child(1) {
    margin-right: 0; } }

.fui-breadcrumb-container + .news-wrapper {
  padding-top: 100px; }

.ebu-news.pt-90 {
  padding-top: 90px; }

.news-wrapper .fui-cards .fui-card .fui-card-content .fui-card-title .text {
  font-size: 24px; }

.ebu-news {
  position: relative; }
  .ebu-news .fui-cards .fui-card .fui-card-content .fui-card-title {
    -webkit-line-clamp: 3; }
  .ebu-news .quote-content {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    white-space: normal;
    -ms-text-overflow: ellipsis; }
  .ebu-news .social {
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    width: 100%;
    margin: -10px 42px 36px 42px;
    justify-content: flex-start; }
    .ebu-news .social .view {
      color: #8A8A8A;
      display: flex;
      align-items: center; }
    .ebu-news .social i {
      font-size: 36px;
      margin-right: 6px; }
    .ebu-news .social a {
      color: #000; }
  .ebu-news hr {
    border-width: 2px; }
  .ebu-news .link {
    margin: 0px 42px 50px 42px;
    display: flex;
    align-items: center; }
    .ebu-news .link .icon-business-customer-center {
      font-size: 36px;
      margin-right: 10px; }
    .ebu-news .link i {
      display: inline-block;
      font-size: 24px; }
    .ebu-news .link p {
      display: inline-block;
      margin: 0 6px 0 0 !important; }
  .ebu-news .quote {
    position: relative;
    margin-top: 20px; }
    .ebu-news .quote .comma {
      font-family: "PingFangTC-Medium";
      font-size: 60px;
      color: #5e748e;
      left: 0;
      top: 0;
      color: #EF3123;
      padding: 0;
      position: absolute; }
    .ebu-news .quote .comma:nth-last-child(1) {
      right: 0px;
      bottom: 0px;
      left: auto;
      left: initial;
      top: auto;
      top: initial; }
    .ebu-news .quote h4 {
      padding: 20px 100px 40px 30px;
      position: relative; }
  .ebu-news .news-list {
    margin-top: 0; }
  .ebu-news .news-wrapper {
    padding-bottom: 40px;
    padding-top: 100px; }
    .ebu-news .news-wrapper h2 {
      margin: 0 0 30px 0;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: block;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      white-space: normal;
      -ms-text-overflow: ellipsis; }
    .ebu-news .news-wrapper h3 {
      color: #2F59C4;
      margin: 0 42px 24px 42px; }
    .ebu-news .news-wrapper p {
      margin: 0 42px 42px 42px; }
    .ebu-news .news-wrapper img {
      padding: 0 0px 0px 0px;
      width: 100%;
      margin-bottom: 24px; }
  .ebu-news .list-wrapper {
    border-bottom: 1px solid #E8ECF0;
    display: flex;
    align-items: center;
    padding: 15px 0;
    margin: 0;
    cursor: pointer; }
    .ebu-news .list-wrapper p, .ebu-news .list-wrapper h5 {
      margin: 0; }
    .ebu-news .list-wrapper p {
      color: #5F6E84; }
    .ebu-news .list-wrapper .date {
      color: #8A8A8A;
      display: inline-block;
      margin-right: 48px; }
    .ebu-news .list-wrapper:hover h5 {
      color: #2F59C4; }
    .ebu-news .list-wrapper a {
      color: #202020; }
  .ebu-news .list-title {
    margin-bottom: 16px; }
    .ebu-news .list-title small {
      display: inline-block;
      color: #5F6E84; }
    .ebu-news .list-title p {
      font-size: 16px;
      font-weight: 500;
      line-height: 1.75;
      letter-spacing: 0.2px;
      color: #5F6E84;
      padding-right: 12px; }
  .ebu-news .list-wrapper:nth-last-child(1) {
    border-bottom: 0;
    padding-bottom: 30px; }
    .ebu-news .list-wrapper:nth-last-child(1) a {
      color: #202020;
      font-weight: 500; }
  .ebu-news .list-container::after {
    content: "";
    width: 100%;
    height: 176px;
    background-image: radial-gradient(circle at 50% -158%, #E8ECF0, rgba(250, 250, 250, 0) 70%);
    display: flex;
    position: absolute;
    pointer-events: none; }
  .ebu-news .news-filter {
    margin-bottom: 34px;
    display: flex; }
  .ebu-news .fui-dropdown {
    display: inline-block;
    background-color: #fff;
    padding: 12px 5px;
    border: 2px solid #E8ECF0;
    width: 200px; }
    .ebu-news .fui-dropdown button {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between; }
      .ebu-news .fui-dropdown button span, .ebu-news .fui-dropdown button i {
        color: #202020;
        font-weight: 500; }
  .ebu-news .filter-list {
    margin-right: 42px;
    margin-bottom: 12px; }
  .ebu-news .fui-nav-anchor {
    background-color: #fff; }
  .ebu-news::after {
    content: "";
    background-image: url(/DigService/resources/ebu/images/news-banner.png);
    background-size: cover;
    width: 100%;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    left: 0;
    height: 40vw;
    z-index: -1; }
  .ebu-news .mb-date {
    display: none;
    line-height: 1.75;
    letter-spacing: 0.2px;
    color: #8A8A8A; }
  .ebu-news .fui-card-date {
    font-weight: 500; }
  .ebu-news .read-more {
    padding-top: 30px;
    margin-bottom: 16px;
    border-top: 1px solid #E8ECF0;
    margin-left: 42px; }
    .ebu-news .read-more:not(.two-buttons) button {
      margin-right: 12px;
      width: 100%; }
    .ebu-news .read-more button:nth-last-child(1) {
      margin-bottom: 0; }

.news-content::after {
  content: "";
  background-image: url(/DigService/resources/ebu/images/news-banner.png);
  background-size: cover;
  width: 100%;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  height: 40vw;
  z-index: -1; }

.fui-nav-anchor.is-fixed + .fui-breadcrumb-container + .news-wrapper {
  padding-top: 165px; }

@media screen and (max-width: 1200px) {
  .ebu-news .fui-card {
    max-width: none; } }

@media screen and (max-width: 960px) {
  .fui-breadcrumb-container + .news-wrapper {
    padding-top: 60px; }
  .fui-nav-anchor.is-fixed + .fui-breadcrumb-container + .news-wrapper {
    padding-top: 105px; }
  .ebu-news.news-content .news-wrapper {
    padding-bottom: 0; }
  .ebu-news .news-list {
    margin-top: 0; }
  .ebu-news .filter-list {
    margin-right: 12px;
    display: inline-block; }
    .ebu-news .filter-list button {
      padding: 0; }
  .ebu-news .news-wrapper img {
    padding: 0; }
  .ebu-news .news-wrapper p, .ebu-news .news-wrapper h3 {
    margin-left: 0;
    margin-right: 0; }
  .ebu-news .news-wrapper .quote h4 {
    padding: 20px 0 40px 0; }
  .ebu-news .mb-date {
    display: block;
    margin-left: 12px; }
  .ebu-news .list-wrapper:nth-last-child(1) {
    border-bottom: 0;
    padding-bottom: 48px !important; }
  .ebu-news .read-more {
    margin-bottom: 72px; }
  .ebu-news .news-filter {
    margin-bottom: 3px;
    display: flex; }
  .ebu-news .list-title {
    margin-bottom: 6px; }
  .ebu-news .link {
    margin: 0 0 50px 0; }
  .ebu-news .social {
    margin-left: 0;
    margin-bottom: 30px; }
  .ebu-news::after {
    display: none; }
    .ebu-news::after a {
      min-width: 0 !important;
      min-width: initial !important;
      width: 100%; }
  .ebu-news .two-buttons a:nth-last-child(1) {
    margin-right: 0; }
  .ebu-news.pt-90 {
    padding-top: 0; }
  .news-content .read-more {
    margin-left: 0; } }

@media screen and (max-width: 768px) {
  .ebu-news .filter-list {
    margin-bottom: 12px;
    width: 100%; }
    .ebu-news .filter-list .fui-dropdown {
      width: 100%; }
    .ebu-news .filter-list:last-child {
      margin-right: 0; }
  .ebu-news .read-more {
    margin-left: 0;
    display: flex;
    justify-content: center; }
    .ebu-news .read-more a {
      width: 100%;
      min-width: 0 !important;
      min-width: initial !important; }
  .ebu-news .list-wrapper p {
    display: inline-block; }
  .ebu-news .list-wrapper .mb-date {
    margin-left: 12px; }
  .ebu-news .news-filter {
    display: flex; }
  .ebu-news .list-container::after {
    width: calc(100% - 30px);
    height: 120px;
    background-image: radial-gradient(circle at 50% -158%, #E8ECF0, rgba(250, 250, 250, 0) 90%); } }

section.search-empty img {
  display: block;
  line-height: 0;
  width: 180px;
  margin: 0 auto; }

section.search-empty h4 {
  margin-top: 58px; }

@media screen and (min-width: 960px) {
  section.search-empty img {
    float: right;
    width: 300px; } }

.search-result-tabpane {
  position: relative;
  z-index: 0;
  overflow: hidden; }

.product-card-sections {
  position: relative;
  z-index: 0; }
  .product-card-sections::before {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    max-width: 1440px;
    height: 120%;
    top: -90px;
    z-index: -1;
    pointer-events: none;
    background: url(/DigService/resources/cbu/search-result/bg-ribbon-sm@2x.png) center top repeat-y;
    background-size: 1440px auto; }
  @media screen and (min-width: 960px) {
    .product-card-sections::before {
      background: url(/DigService/resources/cbu/search-result/bg-ribbon-sm@2x.png) center top repeat-y; } }

.result-container.is-product {
  background: url(/DigService/resources/cbu/search-result/bg-ribbon-sm@2x.png) center top repeat-y;
  background-size: 1440px auto; }
  @media screen and (min-width: 960px) {
    .result-container.is-product::before {
      background: url(/DigService/resources/cbu/search-result/bg-ribbon-sm@2x.png) center top repeat-y; } }

.fui-cards.no-scrollbar.is-product-result,
.fui-product-cards {
  position: relative;
  z-index: 1;
  padding-bottom: 60px; }
  .fui-cards.no-scrollbar.is-product-result .horizontal-cards.slick-slider .slick-list .slick-slide,
  .fui-product-cards .horizontal-cards.slick-slider .slick-list .slick-slide {
    display: block; }
    .fui-cards.no-scrollbar.is-product-result .horizontal-cards.slick-slider .slick-list .slick-slide > div,
    .fui-product-cards .horizontal-cards.slick-slider .slick-list .slick-slide > div {
      flex-grow: 1;
      height: 100%; }
  .fui-cards.no-scrollbar.is-product-result .more,
  .fui-product-cards .more {
    margin-top: 56px; }
  .fui-cards.no-scrollbar.is-product-result .fui-card.is-product-carousel,
  .fui-product-cards .fui-card.is-product-carousel {
    position: relative;
    max-width: 270px; }
    .fui-cards.no-scrollbar.is-product-result .fui-card.is-product-carousel .fui-card-image,
    .fui-product-cards .fui-card.is-product-carousel .fui-card-image {
      height: auto;
      padding: 30px 50px 0; }
      .fui-cards.no-scrollbar.is-product-result .fui-card.is-product-carousel .fui-card-image img,
      .fui-product-cards .fui-card.is-product-carousel .fui-card-image img {
        position: relative;
        max-width: 100%;
        height: auto;
        top: 0;
        bottom: auto;
        left: 0;
        right: auto; }
    .fui-cards.no-scrollbar.is-product-result .fui-card.is-product-carousel.is-product .fui-card-image img,
    .fui-product-cards .fui-card.is-product-carousel.is-product .fui-card-image img {
      width: 140px;
      height: auto; }
    .fui-cards.no-scrollbar.is-product-result .fui-card.is-product-carousel.is-moive .fui-card-image img,
    .fui-product-cards .fui-card.is-product-carousel.is-moive .fui-card-image img {
      width: 190px;
      height: auto; }
    .fui-cards.no-scrollbar.is-product-result .fui-card.is-product-carousel.is-moive h4.fui-card-title,
    .fui-product-cards .fui-card.is-product-carousel.is-moive h4.fui-card-title {
      white-space: nowrap;
      -webkit-line-clamp: 1; }
    .fui-cards.no-scrollbar.is-product-result .fui-card.is-product-carousel.is-ring .fui-card-image img,
    .fui-product-cards .fui-card.is-product-carousel.is-ring .fui-card-image img {
      width: 150px;
      height: auto; }
    .fui-cards.no-scrollbar.is-product-result .fui-card.is-product-carousel.is-ring h4.fui-card-title,
    .fui-product-cards .fui-card.is-product-carousel.is-ring h4.fui-card-title {
      white-space: nowrap;
      -webkit-line-clamp: 1; }
    .fui-cards.no-scrollbar.is-product-result .fui-card.is-product-carousel.is-music .fui-card-image img,
    .fui-product-cards .fui-card.is-product-carousel.is-music .fui-card-image img {
      width: 190px;
      height: auto; }
    .fui-cards.no-scrollbar.is-product-result .fui-card.is-product-carousel.is-music h4.fui-card-title,
    .fui-product-cards .fui-card.is-product-carousel.is-music h4.fui-card-title {
      white-space: nowrap;
      -webkit-line-clamp: 1; }
    .fui-cards.no-scrollbar.is-product-result .fui-card.is-product-carousel .fui-card-action,
    .fui-product-cards .fui-card.is-product-carousel .fui-card-action {
      display: flex;
      height: 100%; }
    .fui-cards.no-scrollbar.is-product-result .fui-card.is-product-carousel .fui-card-caption,
    .fui-product-cards .fui-card.is-product-carousel .fui-card-caption {
      flow: 1;
      flex-grow: 1; }
      .fui-cards.no-scrollbar.is-product-result .fui-card.is-product-carousel .fui-card-caption h4.fui-card-title .text,
      .fui-product-cards .fui-card.is-product-carousel .fui-card-caption h4.fui-card-title .text {
        font-size: 16px;
        font-weight: 400;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis; }
    .fui-cards.no-scrollbar.is-product-result .fui-card.is-product-carousel .fui-card-extra,
    .fui-product-cards .fui-card.is-product-carousel .fui-card-extra {
      display: flex;
      font-weight: 500;
      margin-top: 16px;
      align-content: center;
      align-items: center;
      justify-content: center;
      justify-items: center; }
      .fui-cards.no-scrollbar.is-product-result .fui-card.is-product-carousel .fui-card-extra a,
      .fui-product-cards .fui-card.is-product-carousel .fui-card-extra a {
        flex: 1 1;
        flex-grow: 1;
        text-align: center; }
        .fui-cards.no-scrollbar.is-product-result .fui-card.is-product-carousel .fui-card-extra a .icon img,
        .fui-product-cards .fui-card.is-product-carousel .fui-card-extra a .icon img {
          margin: auto; }
    .fui-cards.no-scrollbar.is-product-result .fui-card.is-product-carousel .fui-card-ribbon,
    .fui-product-cards .fui-card.is-product-carousel .fui-card-ribbon {
      position: absolute;
      left: 0;
      top: 0;
      color: #FFFFFF;
      background: #FC7B66;
      font-size: 0.875rem;
      height: 28px;
      line-height: 28px;
      padding: 0 12px;
      z-index: 9; }
      .fui-cards.no-scrollbar.is-product-result .fui-card.is-product-carousel .fui-card-ribbon::after,
      .fui-product-cards .fui-card.is-product-carousel .fui-card-ribbon::after {
        content: "";
        float: right;
        display: block;
        margin-right: -40px;
        width: 0px;
        height: 0px;
        border-left: 14px solid #FC7B66;
        border-top: 14px solid #FC7B66;
        border-right: 14px solid transparent;
        border-bottom: 14px solid transparent; }
    @media screen and (min-width: 960px) {
      .fui-cards.no-scrollbar.is-product-result .fui-card.is-product-carousel,
      .fui-product-cards .fui-card.is-product-carousel {
        max-width: 100%; }
        .fui-cards.no-scrollbar.is-product-result .fui-card.is-product-carousel .fui-card-caption h4.fui-card-title .text,
        .fui-product-cards .fui-card.is-product-carousel .fui-card-caption h4.fui-card-title .text {
          font-size: 20px; } }

.fui-cards.no-scrollbar.is-product-result {
  margin: 24px -6px;
  padding: 0; }
  .fui-cards.no-scrollbar.is-product-result .fui-card.is-product-carousel {
    width: calc(50% - 12px); }
    .fui-cards.no-scrollbar.is-product-result .fui-card.is-product-carousel .fui-card-image {
      text-align: center;
      padding: 36px 40px 0; }
      .fui-cards.no-scrollbar.is-product-result .fui-card.is-product-carousel .fui-card-image img {
        margin: 0 auto; }
    .fui-cards.no-scrollbar.is-product-result .fui-card.is-product-carousel .fui-card-ribbon {
      font-size: 0.875rem; }
    .fui-cards.no-scrollbar.is-product-result .fui-card.is-product-carousel .fui-card-caption .fui-card-content h4.fui-card-title .text {
      font-size: 1rem; }
  @media screen and (min-width: 768px) {
    .fui-cards.no-scrollbar.is-product-result .fui-card.is-product-carousel {
      width: calc(25% - 12px); } }

section.result-container {
  padding: 30px 0 64px; }
  section.result-container .related-keyword {
    margin-top: 24px; }
    section.result-container .related-keyword .fui-button.is-tag {
      padding: 0 12px;
      margin-bottom: 12px;
      font-size: 0.875rem;
      height: 30px;
      line-height: 26px; }
      @media screen and (min-width: 960px) {
        section.result-container .related-keyword .fui-button.is-tag {
          padding-left: 16px;
          padding-right: 16px;
          font-size: 1rem;
          height: 40px;
          line-height: 36px; } }
  section.result-container .result-list {
    position: relative;
    margin: 8px 0;
    z-index: 2; }
    section.result-container .result-list .result-item {
      padding: 16px 0;
      border-bottom: solid 1px #E8ECF0; }
      section.result-container .result-list .result-item:last-child {
        border: 0; }
      section.result-container .result-list .result-item .result-content,
      section.result-container .result-list .result-item a.result-content:link,
      section.result-container .result-list .result-item a.result-content:visited {
        display: inline-block; }
        section.result-container .result-list .result-item .result-content:hover .result-header > span,
        section.result-container .result-list .result-item .result-content:hover .result-header > span .highlight,
        section.result-container .result-list .result-item a.result-content:link:hover .result-header > span,
        section.result-container .result-list .result-item a.result-content:link:hover .result-header > span .highlight,
        section.result-container .result-list .result-item a.result-content:visited:hover .result-header > span,
        section.result-container .result-list .result-item a.result-content:visited:hover .result-header > span .highlight {
          color: #2F59C4;
          line-height: 0;
          vertical-align: baseline; }
        section.result-container .result-list .result-item .result-content .highlight,
        section.result-container .result-list .result-item a.result-content:link .highlight,
        section.result-container .result-list .result-item a.result-content:visited .highlight {
          color: #EF3123;
          line-height: 0;
          vertical-align: baseline !important; }
        section.result-container .result-list .result-item .result-content .result-header,
        section.result-container .result-list .result-item a.result-content:link .result-header,
        section.result-container .result-list .result-item a.result-content:visited .result-header {
          color: #202020;
          margin: 0 0 6px; }
          section.result-container .result-list .result-item .result-content .result-header i[class*='icon-'],
          section.result-container .result-list .result-item a.result-content:link .result-header i[class*='icon-'],
          section.result-container .result-list .result-item a.result-content:visited .result-header i[class*='icon-'] {
            width: 30px;
            font-size: 30px;
            margin-right: 6px;
            vertical-align: middle; }
        section.result-container .result-list .result-item .result-content .result-description,
        section.result-container .result-list .result-item a.result-content:link .result-description,
        section.result-container .result-list .result-item a.result-content:visited .result-description {
          margin: 0;
          font-size: 0.875rem;
          line-height: 1.5rem;
          color: #5F6E84;
          display: block;
          display: -webkit-box;
          overflow: hidden;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical; }
  section.result-container .result-extra {
    margin: 24px 0 0; }
    section.result-container .result-extra .fui-cards {
      height: auto;
      margin: -20px;
      padding: 56px 16px; }
      section.result-container .result-extra .fui-cards .fui-card a.fui-card-action,
      section.result-container .result-extra .fui-cards .fui-card .fui-card-action {
        min-height: 222px; }
      section.result-container .result-extra .fui-cards .fui-card .fui-card-content h4.fui-card-title {
        margin-bottom: 12px; }
        section.result-container .result-extra .fui-cards .fui-card .fui-card-content h4.fui-card-title .text {
          font-size: 18px;
          line-height: 27px;
          -webkit-line-clamp: unset; }
      section.result-container .result-extra .fui-cards .fui-card .fui-card-content .fui-card-description {
        margin-top: 6px;
        margin-bottom: 6px;
        font-size: 14px;
        line-height: 1.5;
        height: 60px;
        -webkit-line-clamp: unset; }
  @media screen and (max-width: 960px) {
    section.result-container .result-extra .fui-card {
      max-width: 270px; }
      section.result-container .result-extra .fui-card .fui-card-description {
        -webkit-line-clamp: 2; } }
  @media screen and (min-width: 960px) {
    section.result-container .result-list .result-content .result-header i[class*='icon-'] {
      display: none !important; } }

.is-eservice section.result-container .result-list .result-item .result-content:hover .result-header > span,
.is-eservice section.result-container .result-list .result-item .result-content:hover .result-header > span .highlight,
.is-eservice section.result-container .result-list .result-item a.result-content:link:hover .result-header > span,
.is-eservice section.result-container .result-list .result-item a.result-content:link:hover .result-header > span .highlight,
.is-eservice section.result-container .result-list .result-item a.result-content:visited:hover .result-header > span,
.is-eservice section.result-container .result-list .result-item a.result-content:visited:hover .result-header > span .highlight,
.is-cbu section.result-container .result-list .result-item .result-content:hover .result-header > span,
.is-cbu section.result-container .result-list .result-item .result-content:hover .result-header > span .highlight,
.is-cbu section.result-container .result-list .result-item a.result-content:link:hover .result-header > span,
.is-cbu section.result-container .result-list .result-item a.result-content:link:hover .result-header > span .highlight,
.is-cbu section.result-container .result-list .result-item a.result-content:visited:hover .result-header > span,
.is-cbu section.result-container .result-list .result-item a.result-content:visited:hover .result-header > span .highlight {
  color: #EF3123; }

.performance {
  padding-top: 62px;
  background-image: url(/DigService/resources/ebu/images/lightgray-website@2x.jpg);
  background-size: contain; }
  .performance .periodical {
    color: #8A8A8A !important; }
  .performance .article-img {
    width: 216px; }
  .performance .article-content h4 {
    margin: 12px 0 6px 0; }
  .performance .article-content p {
    height: 60px;
    margin: 0; }
  .performance .video-container {
    margin-top: -40px; }
  .performance .fui-container .fui-cards.is-video {
    overflow-x: auto;
    padding: 30px 15px; }

.video-container {
  padding: 0; }
  .video-container .video-head button {
    display: none; }

.performance-mb-ad {
  display: none; }

@media screen and (max-width: 960px) {
  .hide-mb {
    display: none; }
  .performance .hide-mb {
    display: block; }
  .performance .selected-article {
    margin: 0 -15px; }
  .video-container {
    margin: 0 0 -24px 0;
    padding: 0; }
    .video-container .load-more {
      display: none; }
    .video-container .video-head {
      margin-bottom: 20px;
      display: flex;
      align-content: center;
      justify-content: space-between; }
      .video-container .video-head h2 {
        margin: 0; }
      .video-container .video-head button {
        padding: 0;
        text-align: right;
        margin: 0;
        cursor: pointer;
        display: none; }
        .video-container .video-head button i {
          font-size: 24px; } }

@media screen and (max-width: 640px) {
  .performance {
    background-image: none;
    padding-top: 52px; }
    .performance .article-img {
      width: 120px;
      min-width: 0;
      min-width: initial; }
  .performance-mb-ad {
    display: none;
    margin-top: -15px; }
    .performance-mb-ad img {
      width: 100%; } }

.tech .fui-tab-pane {
  display: none;
  opacity: 1; }

.tech .fui-tab-pane.is-active {
  display: block; }

.tech .fui-nav-anchor {
  margin-bottom: 0; }

.tech #tech-content {
  padding-bottom: 112px;
  margin-top: 12px; }

.tech .fui-container img {
  width: 100%; }

.tech .fui-breadcrumb-container {
  position: relative;
  top: 0; }

.tech .fui-nav-anchor + .fui-breadcrumb-container {
  position: relative;
  top: 0;
  margin-bottom: 46px; }

.tech .has-more-tab button {
  background-color: #FAFAFA; }

.tech a.expand {
  color: #202020;
  display: block;
  text-align: center;
  margin: 36px 0;
  font-weight: 500; }
  .tech a.expand::after {
    display: none; }
  .tech a.expand i {
    vertical-align: middle;
    margin-left: 6px; }

.tech .no-more {
  color: #bfbfbf;
  display: flex;
  align-items: center;
  justify-content: center; }
  .tech .no-more i {
    font-size: 30px;
    margin-right: 6px; }

@media screen and (max-width: 960px) {
  .tech .load-more {
    margin-bottom: 0; }
  .tech .info {
    padding: 60px 0 0 0; } }

@media screen and (max-width: 960px) and (max-width: 480px) {
  .tech .selected-article {
    margin: 0 -15px; }
  .tech .img-wrapper .main-body {
    max-height: 194px; }
  .tech #tech-content {
    padding-bottom: 60px; } }

.fui-cbu-promotion {
  position: relative;
  color: #FFFFFF;
  height: 98px;
  padding: 0;
  overflow: hidden;
  background-image: linear-gradient(269deg, #D3060F 100%, #ff644d -6%);
  transition: height 0.3s ease; }
  .fui-cbu-promotion > a {
    display: block; }
  .fui-cbu-promotion .fui-container {
    position: relative;
    height: 269px;
    padding: 20px 30px; }
    .fui-cbu-promotion .fui-container .content {
      text-align: center;
      color: #FFFFFF; }
      .fui-cbu-promotion .fui-container .content * {
        width: 100%; }
      .fui-cbu-promotion .fui-container .content h4 {
        margin: 0;
        line-height: 2rem; }
      .fui-cbu-promotion .fui-container .content p {
        margin: 0;
        line-height: 28px; }
    .fui-cbu-promotion .fui-container .action .fui-button {
      width: 100%;
      margin: 0; }
    .fui-cbu-promotion .fui-container img {
      position: absolute;
      width: auto;
      height: 120px;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0 auto; }
  .fui-cbu-promotion::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: url(/DigService/resources/cbu/cbu-index/patternd-arkgray-50-banner-sm.png) left top no-repeat;
    background-size: 100% auto; }
  .fui-cbu-promotion.is-open {
    height: 269px; }
  @media screen and (max-width: 960px) {
    .fui-cbu-promotion .content {
      display: flex;
      height: 58px;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px; }
      .fui-cbu-promotion .content h4 {
        font-size: 20px; }
    .fui-cbu-promotion.is-open .content {
      height: auto; }
      .fui-cbu-promotion.is-open .content h4 {
        font-size: 20px; } }
  @media screen and (max-width: 374px) {
    .fui-cbu-promotion .content {
      overflow: hidden;
      align-items: flex-start;
      justify-content: flex-start; } }
  @media screen and (min-width: 768px) {
    .fui-cbu-promotion .fui-container .action {
      text-align: center; }
    .fui-cbu-promotion .fui-container .action .fui-button {
      width: auto;
      margin: 0; } }
  @media screen and (min-width: 960px) {
    .fui-cbu-promotion {
      height: 156px !important; }
      .fui-cbu-promotion::before {
        background: url(/DigService/resources/cbu/cbu-index/pattern-ad-red-web@2x.png) center top no-repeat;
        background-size: 1920px auto; }
      .fui-cbu-promotion .fui-container {
        height: 156px !important;
        display: flex;
        align-items: center;
        align-content: center; }
        .fui-cbu-promotion .fui-container img {
          right: auto; }
        .fui-cbu-promotion .fui-container .content {
          padding-left: 336px;
          text-align: left;
          flex: 1 1;
          flex-grow: 1; }
          .fui-cbu-promotion .fui-container .content p {
            margin-bottom: 0; }
        .fui-cbu-promotion .fui-container .action {
          text-align: right; } }

.promotion {
  color: #000; }
  .promotion .fui-breadcrumb a {
    color: #fff !important; }
  .promotion .fui-breadcrumb li::after {
    background: #fff !important; }
  .promotion .bg-img {
    position: relative;
    width: 100%;
    height: 360px;
    background-position: center;
    background-size: cover; }
  .promotion .slick-dots {
    position: absolute;
    display: block;
    text-align: center;
    list-style: none;
    width: 100%;
    bottom: 40px;
    margin: 0;
    padding: 0; }
    .promotion .slick-dots li {
      display: inline-block;
      width: 40px;
      height: 3px;
      background: #202020;
      margin: 0 3px; }
      .promotion .slick-dots li.slick-active {
        background: white; }
      .promotion .slick-dots li button {
        opacity: 0;
        position: relative;
        width: 100% !important;
        top: -10px;
        margin-top: -4px;
        display: inline-block;
        cursor: pointer;
        height: 10px !important;
        vertical-align: middle !important; }
  .promotion .cards {
    margin-top: -18px; }
  .promotion .essential-slider {
    margin-bottom: 60px; }

@media screen and (max-width: 960px) {
  .promotion .essential-slider {
    margin-bottom: 30px; }
  .promotion .cards {
    margin-bottom: -10px;
    margin-top: -46px; } }

.business-login {
  position: relative;
  min-height: calc( 100vh - 107px);
  margin-top: 0; }
  .business-login h2 {
    margin-top: 64px;
    margin-bottom: 10px;
    color: #202020; }
  .business-login p {
    margin: 0;
    font-weight: 500;
    margin-bottom: 30px;
    color: #202020; }
  .business-login .fui-breadcrumb-container {
    position: relative; }
    .business-login .fui-breadcrumb-container .fui-container {
      margin: 0;
      padding: 0; }
  .business-login .side-banner {
    height: 100%; }
  .business-login .id-input {
    margin-bottom: 48px; }
  .business-login .fui-button {
    height: 50px; }
  .business-login .tag-group button {
    margin-right: 24px;
    margin-bottom: 24px; }
  .business-login .side-banner {
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1; }
    .business-login .side-banner img {
      height: 100%; }

header.is-fixed + .business-login {
  margin-top: 0; }

@media screen and (max-width: 960px) {
  .business-login {
    min-height: calc( 100vh - 144px); }
    .business-login .text-input {
      margin-bottom: 24px; }
    .business-login .fui-button {
      height: 40px; }
    .business-login .side-banner {
      display: none; } }

@media screen and (max-width: 640px) {
  .business-login button {
    width: 100%; }
  .business-login h2 {
    text-align: center; } }

.fourOfour-wrapper {
  min-height: 0;
  min-height: initial; }

.fourOfour {
  display: flex;
  flex-direction: row;
  padding: 60px 0 80px 0; }
  .fourOfour--left {
    flex-basis: 50%; }
    .fourOfour--left h2 {
      margin-top: 60px;
      margin-bottom: 12px; }
    .fourOfour--left .subtitle, .fourOfour--left .fui-card.box .fui-card-content .fui-card-title, .fui-card.box .fui-card-content .fourOfour--left .fui-card-title {
      margin: 0; }
    .fourOfour--left .body1 {
      margin-bottom: 48px;
      margin-top: 0; }
  .fourOfour--right {
    flex-basis: 50%; }
    .fourOfour--right img {
      width: 100%; }

@media screen and (max-width: 960px) {
  .fourOfour {
    flex-direction: column;
    padding: 24px 0 44px 0; }
    .fourOfour--left {
      flex-basis: 100%;
      order: 1;
      text-align: center; }
      .fourOfour--left h2 {
        margin-top: 24px; }
      .fourOfour--left .body1 {
        margin-bottom: 24px; }
    .fourOfour--right {
      flex-basis: 100%;
      order: 0;
      text-align: center; }
      .fourOfour--right img {
        width: 186px; } }

.fui-banner.is-zipcode {
  background: #4B5869;
  color: #FFFFFF;
  min-height: 320px;
  background: url(/DigService/resources/cbu/help-center/images/ebu-helpcenter-call-banner-sm.jpg) center no-repeat;
  background-size: cover; }
  .fui-banner.is-zipcode img {
    position: absolute;
    width: 100%;
    top: -100%;
    bottom: -100%;
    left: -100%;
    right: -100%;
    margin: auto; }
  .fui-banner.is-zipcode .fui-container {
    height: 150px;
    top: 0;
    bottom: 0;
    margin: auto;
    padding: 0 16px; }
  @media screen and (min-width: 768px) {
    .fui-banner.is-zipcode {
      min-height: 360px;
      background: url(/DigService/resources/cbu/help-center/images/ebu-helpcenter-call-banner.png) center no-repeat;
      background-size: cover; } }

.help-center-contact .fui-nav-anchor + .fui-banner,
.fui-nav-anchor + .fui-banner.is-help-center {
  margin-top: 0; }

.grid-table.MuiGrid-root .MuiGrid-item {
  margin-left: -1px; }
  .grid-table.MuiGrid-root .MuiGrid-item ~ .MuiGrid-item .fui-table tbody td {
    width: 50%; }
  .grid-table.MuiGrid-root .MuiGrid-item ~ .MuiGrid-item .fui-table thead {
    display: none; }
    @media screen and (min-width: 600px) {
      .grid-table.MuiGrid-root .MuiGrid-item ~ .MuiGrid-item .fui-table thead {
        display: contents; } }

.fui-banner.is-help-center {
  min-height: 360px;
  background-position: top center;
  background-size: cover;
  padding: 80px 0 0;
  background-image: url(/DigService/resources/cbu/help-center/images/ebu-helpcenter-home-banner-sm.jpg);
  overflow: visible; }
  @media screen and (min-width: 600px) and (max-width: 960px) {
    .fui-banner.is-help-center {
      background-position: top right; } }
  @media screen and (min-width: 768px) {
    .fui-banner.is-help-center {
      background-image: url(/DigService/resources/cbu/help-center/images/ebu-help-banner-1440-x-460.png); } }
  @media screen and (min-width: 960px) {
    .fui-banner.is-help-center {
      padding-top: 60px;
      background-position: top center; } }
  .fui-banner.is-help-center .keyword-container .keyword-action {
    height: 40px; }
    .fui-banner.is-help-center .keyword-container .keyword-action .fui-button.is-text > i {
      color: #FFFFFF; }
  .fui-banner.is-help-center.is-faq {
    padding: 28px 0 24px;
    min-height: 148px;
    background-color: #0A3476;
    background-position: left center;
    background-image: url(/DigService/resources/cbu/help-center/images/bg-helpcenter-banner-sm.png);
    background-size: cover; }
    @media screen and (min-width: 960px) {
      .fui-banner.is-help-center.is-faq {
        padding: 84px 0 35px;
        background-image: url(/DigService/resources/cbu/help-center/images/bg-helpcenter-banner.png);
        background-size: auto 100%; } }
  .fui-banner.is-help-center .fui-container {
    height: auto;
    position: relative;
    color: #FFFFFF;
    padding-left: 15px;
    padding-right: 15px; }
    .fui-banner.is-help-center .fui-container h1 {
      margin: 0 0 24px; }
    .fui-banner.is-help-center .fui-container h6 {
      margin: 0 0 36px; }
  .fui-banner.is-help-center .keyword-container {
    padding-right: 0; }
    .fui-banner.is-help-center .keyword-container label {
      color: #FFFFFF; }
  @media screen and (min-width: 960px) {
    .fui-banner.is-help-center {
      background-size: cover; }
      .fui-banner.is-help-center .fui-container h1 {
        margin: 0 0 32px; }
      .fui-banner.is-help-center h6 {
        margin: 0 0 48px; } }

.fui-horizontal-card .fui-container .fui-cards.no-scrollbar {
  margin-left: -20px; }

.fui-horizontal-card.help-center-search-card {
  background: url(/DigService/resources/cbu/img/help-center-bg.svg) center no-repeat; }

.is-en .fui-help-section-form {
  padding-top: 24px; }

@media screen and (min-width: 960px) {
  .is-en .fui-help-section-form {
    padding-top: 0; } }

.fui-help-section-form,
.fui-section-document {
  padding-top: 24px; }
  .fui-help-section-form .fui-collapse-menu.is-dropdown,
  .fui-section-document .fui-collapse-menu.is-dropdown {
    margin-top: 30px;
    margin-bottom: 48px; }
  .fui-help-section-form .fui-document-section,
  .fui-section-document .fui-document-section {
    position: static;
    padding-top: 0;
    padding-bottom: 60px; }
    .fui-help-section-form .fui-document-section .fui-cards,
    .fui-section-document .fui-document-section .fui-cards {
      padding: 24px 0 0;
      margin-left: 0;
      margin-right: 0; }
    .fui-help-section-form .fui-document-section h3,
    .fui-section-document .fui-document-section h3 {
      margin-bottom: 0; }
  .fui-help-section-form .radio-buttons .radio-group .radio-button,
  .fui-section-document .radio-buttons .radio-group .radio-button {
    width: calc(50% - 12px); }
  @media screen and (min-width: 960px) {
    .fui-help-section-form,
    .fui-section-document {
      padding-top: 85px; }
      .fui-help-section-form .fui-collapse-menu.is-dropdown,
      .fui-section-document .fui-collapse-menu.is-dropdown {
        margin-top: 60px; }
      .fui-help-section-form .fui-document-section,
      .fui-section-document .fui-document-section {
        padding-top: 0; } }

.fui-section-faq .side-menu,
.fui-section-document .side-menu {
  position: relative;
  z-index: 99; }
  .fui-section-faq .side-menu ~ *,
  .fui-section-document .side-menu ~ * {
    z-index: 0; }

.fui-section-faq {
  padding: 24px 0; }
  .fui-section-faq .fui-collapse-menu {
    margin-bottom: 36px; }
  @media screen and (min-width: 960px) {
    .fui-section-faq {
      padding: 60px; } }

.help-center-contact section.fui-content > .fui-container > h3 {
  margin-bottom: 0; }
  .help-center-contact section.fui-content > .fui-container > h3 + h4 {
    margin-top: 0; }

.help-center-contact section.fui-content .fui-panel h4 {
  font-weight: 400;
  font-size: 22px;
  color: #202020; }

main.help-center {
  margin-top: 50px; }
  main.help-center .fui-panel {
    padding: 30px !important; }
    main.help-center .fui-panel h4 {
      margin-top: 0; }
    main.help-center .fui-panel h3.fui-panel-title {
      margin-bottom: 24px; }
  main.help-center .fui-collapse.is-open .collapse-body {
    margin-top: 18px; }
  main.help-center .blue-dot-list {
    padding-left: 30px;
    list-style: none; }
    main.help-center .blue-dot-list li {
      position: relative;
      line-height: 1.78; }
      main.help-center .blue-dot-list li:before {
        content: "•";
        color: #2F59C4;
        font-size: 30px;
        position: absolute;
        left: -28px;
        top: -10px; }
    main.help-center .blue-dot-list.with-circle {
      list-style-type: none;
      padding: 12px 0 0 0; }
      main.help-center .blue-dot-list.with-circle li {
        display: flex;
        flex-direction: row;
        align-items: center;
        line-height: 1;
        margin-bottom: 8px;
        padding-left: 0; }
        main.help-center .blue-dot-list.with-circle li::before {
          display: none; }
      main.help-center .blue-dot-list.with-circle .circle {
        font-size: 14px;
        background-color: rgba(57, 102, 218, 0.2);
        width: 24px;
        height: 24px;
        border-radius: 50%;
        margin-right: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #2F59C4;
        font-weight: 400;
        position: relative; }
  main.help-center .fui-card .fui-card-content a:not(.sub-link-title):not(.fui-button):link, main.help-center .fui-card .fui-card-content a:not(.sub-link-title):not(.fui-button):visited, main.help-center .fui-panel a:not(.sub-link-title):not(.fui-button):link, main.help-center .fui-panel a:not(.sub-link-title):not(.fui-button):visited {
    color: #2F59C4; }
  main.help-center .fui-card .fui-card-content a:not(.sub-link-title):not(.fui-button):link::after, main.help-center .fui-card .fui-card-content a:not(.sub-link-title):not(.fui-button):visited::after, main.help-center .fui-panel a:not(.sub-link-title):not(.fui-button):link::after, main.help-center .fui-panel a:not(.sub-link-title):not(.fui-button):visited::after {
    background: #2F59C4 !important; }
  @media screen and (max-width: 960px) {
    main.help-center .fui-panel {
      padding: 15px !important; } }

.faq-container .search-empty {
  width: 100%; }
  .faq-container .search-empty img {
    width: 300px;
    display: block;
    margin: 0 auto 32px; }

.faq-container .highlight {
  color: #EF3123 !important; }

.faq-container .fui-panel {
  padding: 15px 15px; }
  .faq-container .fui-panel h3 {
    color: #2F59C4; }
  .faq-container .fui-panel .panel-more-link {
    margin: 15px -15px -15px;
    background: #fafafa;
    text-align: center; }
    .faq-container .fui-panel .panel-more-link .fui-button {
      margin: 0;
      color: #2F59C4; }
  .faq-container .fui-panel .faq-detail,
  .faq-container .fui-panel .fui-collapse.is-faq {
    padding: 15px;
    background: #f1f3f5;
    margin-bottom: 12px; }
    .faq-container .fui-panel .faq-detail .collapse-body,
    .faq-container .fui-panel .fui-collapse.is-faq .collapse-body {
      margin-bottom: 0; }
    .faq-container .fui-panel .faq-detail .collapse-body > *,
    .faq-container .fui-panel .fui-collapse.is-faq .collapse-body > * {
      padding: 0 0 36px; }
      .faq-container .fui-panel .faq-detail .collapse-body > * article > p:first-child,
      .faq-container .fui-panel .fui-collapse.is-faq .collapse-body > * article > p:first-child {
        margin-top: 0; }
    .faq-container .fui-panel .faq-detail .collapse-header,
    .faq-container .fui-panel .fui-collapse.is-faq .collapse-header {
      font-weight: 500; }
      .faq-container .fui-panel .faq-detail .collapse-header::before,
      .faq-container .fui-panel .fui-collapse.is-faq .collapse-header::before {
        color: #5F6E84; }
    .faq-container .fui-panel .faq-detail hr,
    .faq-container .fui-panel .fui-collapse.is-faq hr {
      border-color: #202020; }
  .faq-container .fui-panel .feedback *:not(.form-group) {
    display: inline-block;
    vertical-align: middle;
    left: 0; }
  .faq-container .fui-panel .feedback form .form-group {
    margin-bottom: 0; }
    .faq-container .fui-panel .feedback form .form-group > label {
      min-height: 0;
      height: auto;
      margin: 0; }
    .faq-container .fui-panel .feedback form .form-group .radio-buttons {
      margin-top: 12px; }
    .faq-container .fui-panel .feedback form .form-group .max-length-counter,
    .faq-container .fui-panel .feedback form .form-group .radio-buttons .radio-group .radio-button,
    .faq-container .fui-panel .feedback form .form-group .max-length-counter {
      width: 100%; }
  .faq-container .fui-panel .feedback form .input-group {
    width: 100%; }
    .faq-container .fui-panel .feedback form .input-group button.fui-button {
      width: calc(50% - 8px);
      padding: 0 26px; }
      @media screen and (min-width: 768px) {
        .faq-container .fui-panel .feedback form .input-group button.fui-button {
          width: auto; } }
  .faq-container .fui-panel .feedback .text {
    margin-right: 20px; }
  .faq-container .fui-panel .feedback .fui-button.is-secondary {
    min-width: 0;
    margin-bottom: 0;
    border-color: #202020; }
    .faq-container .fui-panel .feedback .fui-button.is-secondary .text {
      margin-right: 0; }
    .faq-container .fui-panel .feedback .fui-button.is-secondary::after {
      display: none; }
  @media screen and (max-width: 374px) {
    .faq-container .fui-panel .feedback .text {
      margin-right: 4px; }
    .faq-container .fui-panel .feedback .fui-button.is-secondary + .fui-button.is-secondary {
      margin-right: 0; } }

@media screen and (min-width: 960px) {
  .faq-container .search-empty img {
    float: right; }
  .faq-container .search-empty .content {
    display: block;
    margin-right: 200px; }
  .faq-container .fui-panel {
    padding: 30px 30px; }
    .faq-container .fui-panel .panel-more-link {
      margin: 30px -30px -30px; }
    .faq-container .fui-panel .faq-detail,
    .faq-container .fui-panel .fui-collapse.is-faq {
      padding: 30px; } }

.is-help-center .fui-panel h4 {
  font-weight: 400; }

section.fui-section-complete {
  text-align: center; }
  section.fui-section-complete img {
    width: 186px; }
  section.fui-section-complete .fui-button {
    width: 100%; }
  @media screen and (min-width: 768px) {
    section.fui-section-complete img {
      width: 220px; }
    section.fui-section-complete .fui-button {
      width: auto; } }

section.fui-help-shortcut {
  padding: 44px 0;
  background: #E8ECF0; }
  section.fui-help-shortcut .fui-shortcut-container {
    display: flex;
    width: 100%;
    flex-wrap: wrap; }
    section.fui-help-shortcut .fui-shortcut-container a.fui-shortcut-item,
    section.fui-help-shortcut .fui-shortcut-container a.fui-shortcut-item:link,
    section.fui-help-shortcut .fui-shortcut-container a.fui-shortcut-item:visited {
      display: flex;
      flex-direction: column;
      width: calc(100% / 3);
      transition: all 0.3s ease;
      text-align: center;
      padding: 18px 0 12px; }
      section.fui-help-shortcut .fui-shortcut-container a.fui-shortcut-item::after,
      section.fui-help-shortcut .fui-shortcut-container a.fui-shortcut-item:link::after,
      section.fui-help-shortcut .fui-shortcut-container a.fui-shortcut-item:visited::after {
        display: none !important; }
      section.fui-help-shortcut .fui-shortcut-container a.fui-shortcut-item:hover,
      section.fui-help-shortcut .fui-shortcut-container a.fui-shortcut-item:link:hover,
      section.fui-help-shortcut .fui-shortcut-container a.fui-shortcut-item:visited:hover {
        box-shadow: 0 20px 48px 0 rgba(75, 88, 105, 0.24);
        background: #FFFFFF; }
      section.fui-help-shortcut .fui-shortcut-container a.fui-shortcut-item img,
      section.fui-help-shortcut .fui-shortcut-container a.fui-shortcut-item:link img,
      section.fui-help-shortcut .fui-shortcut-container a.fui-shortcut-item:visited img {
        width: 80px;
        height: 80px;
        margin: auto;
        align-self: flex-start; }
      section.fui-help-shortcut .fui-shortcut-container a.fui-shortcut-item .text,
      section.fui-help-shortcut .fui-shortcut-container a.fui-shortcut-item:link .text,
      section.fui-help-shortcut .fui-shortcut-container a.fui-shortcut-item:visited .text {
        flex: 1 1 auto;
        flex-grow: 1;
        color: #202020;
        margin-top: 12px;
        font-weight: 500; }
  @media screen and (min-width: 960px) {
    section.fui-help-shortcut {
      padding: 60px 0; }
      section.fui-help-shortcut .fui-shortcut-container {
        flex-wrap: nowrap; }
        section.fui-help-shortcut .fui-shortcut-container .fui-shortcut-item {
          width: auto;
          flex: 1 1 auto; }
          section.fui-help-shortcut .fui-shortcut-container .fui-shortcut-item img {
            width: 80px;
            height: 80px; }
          section.fui-help-shortcut .fui-shortcut-container .fui-shortcut-item .text {
            font-size: 20px; } }

section.fui-app-promotion {
  color: #FFF;
  background-color: #5F6E84;
  background-position: left top;
  background-repeat: no-repeat;
  background-size: cover; }
  section.fui-app-promotion .top-area {
    display: inline-block; }
    section.fui-app-promotion .top-area img + div {
      display: block;
      margin-left: 82px; }
    section.fui-app-promotion .top-area h3 {
      display: block;
      vertical-align: top;
      margin-bottom: 6px; }
    section.fui-app-promotion .top-area h5 {
      display: block;
      vertical-align: top; }
  section.fui-app-promotion h3, section.fui-app-promotion h5 {
    margin: 0; }
  section.fui-app-promotion p {
    margin-top: 24px; }
  section.fui-app-promotion .download img {
    margin: 0 auto 10px;
    width: 100%;
    max-width: 144px; }
  section.fui-app-promotion img.float-left.mr-2 {
    max-width: 96px; }
  section.fui-app-promotion a,
  section.fui-app-promotion a *,
  section.fui-app-promotion a:link *,
  section.fui-app-promotion a:visited * {
    color: #FFFFFF;
    width: 100%;
    max-width: 144px; }
    section.fui-app-promotion a img,
    section.fui-app-promotion a * img,
    section.fui-app-promotion a:link * img,
    section.fui-app-promotion a:visited * img {
      width: 100%; }
    section.fui-app-promotion a::after,
    section.fui-app-promotion a *::after,
    section.fui-app-promotion a:link *::after,
    section.fui-app-promotion a:visited *::after {
      display: none; }
  @media screen and (min-width: 768px) and (max-width: 1199px) {
    section.fui-app-promotion {
      background-size: 200% auto; }
      section.fui-app-promotion p {
        margin-top: 0; } }
  @media screen and (min-width: 1200px) {
    section.fui-app-promotion {
      background-position: center; }
      section.fui-app-promotion p {
        margin-top: 0; }
      section.fui-app-promotion .top-area h3 {
        width: 100%; }
      section.fui-app-promotion .top-area h5 {
        width: 100%; } }

.google-map {
  background-image: url(/DigService/resources/ebu/images/ebu-lightgray-pattern-1440x460.jpg);
  color: #5F6E84;
  background-size: cover; }
  .google-map .fui-container {
    padding-top: 60px;
    padding-bottom: 60px;
    height: 270px; }
    .google-map .fui-container h4 {
      margin: 0 0 24px 0; }
  .google-map .info-list {
    display: flex;
    margin-bottom: 6px; }
    .google-map .info-list p {
      margin: 0; }
    .google-map .info-list .is-text-medium {
      margin-right: 12px;
      min-width: 64px; }
  .google-map .google-map-display {
    width: 100%;
    height: 400px;
    margin-bottom: 90px; }
  @media screen and (max-width: 960px) {
    .google-map .fui-container {
      height: auto;
      height: initial; }
      .google-map .fui-container h1 {
        min-width: 190px; } }

.is-fixed ~ main.five-g {
  padding-top: 50px;
  margin-top: 0; }

main.five-g {
  padding-top: 50px; }
  main.five-g section {
    z-index: 1; }
  main.five-g .fui-solution-tab {
    z-index: 2; }
  main.five-g .fui-banner.is-product .slick-slider ul.slick-dots {
    bottom: 80px; }
  main.five-g .fui-banner.is-product.is-style-2 .slick-slider .slick-slide img {
    height: 100%; }
  main.five-g div.fui-banner.is-solution .slick-slider .slick-slide div {
    height: 100%; }
  main.five-g .fui-container {
    z-index: 0; }
  main.five-g .fui-banner {
    padding: 0;
    min-height: 0;
    min-height: initial; }
    main.five-g .fui-banner .caption a {
      height: 50px;
      line-height: 50px; }
  main.five-g section.fui-solution-tab .fui-nav-tab.is-box-tab .text {
    font-size: 18px; }
  main.five-g #video-slider {
    overflow: visible;
    margin-top: -32px;
    padding-top: 0; }
    main.five-g #video-slider .parallax-outer {
      top: -300px; }
  main.five-g .fui-nav-tab .fui-tab {
    margin-bottom: 0; }
  main.five-g .fui-cards .fui-card-title .text {
    font-weight: 500;
    font-size: 1.375rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    white-space: normal;
    -ms-text-overflow: ellipsis; }
  main.five-g .tab-content .fui-tab-pane {
    margin-top: 12px; }
    main.five-g .tab-content .fui-tab-pane p {
      font-size: 18px;
      line-height: 32px;
      letter-spacing: 0.2px;
      color: #4F5E72;
      font-weight: 500;
      margin-bottom: 32px; }
    main.five-g .tab-content .fui-tab-pane h2 {
      margin-top: -32px; }
    main.five-g .tab-content .fui-tab-pane h2.pane-title {
      margin-top: 84px;
      margin-bottom: 12px; }
    main.five-g .tab-content .fui-tab-pane .fui-cards {
      margin: 0;
      background-size: cover;
      height: 500px;
      background-position: center;
      padding: 105px 60px 0; }
      main.five-g .tab-content .fui-tab-pane .fui-cards::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        background: black;
        opacity: 0.15;
        width: 100%;
        height: 100%;
        z-index: 0; }
      main.five-g .tab-content .fui-tab-pane .fui-cards h2, main.five-g .tab-content .fui-tab-pane .fui-cards p {
        z-index: 1; }
  main.five-g .tab-content .five-g-card {
    width: calc( 60% - 60px);
    z-index: 1;
    position: relative; }
    main.five-g .tab-content .five-g-card .fui-card-content {
      height: 218px; }
    main.five-g .tab-content .five-g-card .fui-card-description {
      color: #202020;
      font-size: 16px;
      -webkit-line-clamp: 3;
      line-height: 1.75;
      letter-spacing: 0.2px; }
  main.five-g .tab-content .single-card .fui-card {
    position: absolute;
    right: 0;
    width: 392px; }
  main.five-g .tab-content .tab-content-wrapper {
    margin-right: 60px;
    width: 40%;
    z-index: 1;
    position: relative; }
    main.five-g .tab-content .tab-content-wrapper h2 {
      margin-top: 40px; }
  main.five-g .five-g-news-content {
    position: relative;
    margin: 0 0 -6px 0; }
    main.five-g .five-g-news-content .fui-card-content {
      padding: 60px 48px 0px; }
    main.five-g .five-g-news-content .fui-card-content .fui-card-title {
      font-size: 28px;
      line-height: 1.5; }
    main.five-g .five-g-news-content .five-g-bg-img {
      background-size: cover;
      background-position: center;
      height: 400px;
      width: 100%; }
  main.five-g .fui-card-content {
    min-height: 218px;
    background: #FFFFFF; }
    main.five-g .fui-card-content p {
      margin-bottom: 48px; }
  main.five-g .video-card {
    position: relative;
    height: 460px;
    cursor: pointer; }
    main.five-g .video-card .mask {
      height: 97px;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      background-blend-mode: multiply;
      background-image: linear-gradient(to bottom, rgba(32, 32, 32, 0), rgba(32, 32, 32, 0.6));
      z-index: 0; }
    main.five-g .video-card .video-img {
      height: 460px;
      width: 100%;
      background-size: cover;
      background-position: center; }
    main.five-g .video-card .video-card-text {
      position: absolute;
      left: 30px;
      bottom: 20px;
      border-left: 6px solid #EF3123;
      color: #FFFFFF;
      padding-left: 12px;
      z-index: 1; }
    main.five-g .video-card::before, main.five-g .video-card::after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      z-index: 2; }
    main.five-g .video-card::before {
      width: 60px;
      height: 60px;
      background: #FFFFFF;
      opacity: .84;
      border-radius: 30px;
      box-shadow: 0 11px 48px 0 rgba(32, 32, 32, 0.3); }
    main.five-g .video-card::after {
      left: 20px;
      width: 0;
      height: 0;
      border-left: 16px solid #D3060F;
      border-top: 12px solid transparent;
      border-bottom: 12px solid transparent;
      border-right: 16px solid transparent; }
  main.five-g .lab {
    background-image: url(/DigService/resources/ebu/images/ebu-5g-lab-bkg-800x600.jpg);
    background-size: contain;
    width: 100%;
    height: 576px;
    background-repeat: no-repeat;
    background-color: #E8ECF0;
    background-position: center right;
    padding: 124px 0 120px 0; }
    main.five-g .lab .subtitle, main.five-g .lab .fui-card.box .fui-card-content .fui-card-title, .fui-card.box .fui-card-content main.five-g .lab .fui-card-title {
      max-width: 695px;
      line-height: 1.78;
      letter-spacing: 0.2px; }
    main.five-g .lab .d-flex {
      margin-top: 32px; }
    main.five-g .lab h2 {
      margin-top: 6px;
      margin-bottom: 30px;
      font-weight: 500; }
  main.five-g .milestone .bg {
    height: 100%;
    width: 800px; }
  main.five-g .milestone .slick-slider {
    margin-bottom: 0; }
  main.five-g .milestone .fui-container .slick-list {
    padding-top: 110px; }
  main.five-g .milestone .fui-container .horizontal-cards.slick-slider .slick-list .slick-slide {
    height: -moz-fit-content;
    height: fit-content; }
  main.five-g .milestone .fui-container .milestone-card {
    background: #FFFFFF;
    position: relative;
    padding: 80px 30px 42px 20px; }
    main.five-g .milestone .fui-container .milestone-card ul {
      list-style: none;
      padding: 0; }
      main.five-g .milestone .fui-container .milestone-card ul li {
        display: flex;
        font-weight: 500;
        margin-bottom: 18px; }
        main.five-g .milestone .fui-container .milestone-card ul li img {
          height: 24px;
          object-fit: contain;
          margin-right: 6px; }
    main.five-g .milestone .fui-container .milestone-card h4 {
      background-color: #5F6E84;
      color: #FFFFFF;
      border-radius: 50%;
      width: 100px;
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 16px;
      top: -50px; }
  main.five-g .tab-ebu .parallax-outer {
    z-index: -1; }
  main.five-g .tab-ebu .tab-content-wrapper h2, main.five-g .tab-ebu .tab-content-wrapper p {
    font-weight: 400; }
  main.five-g .tab-cbu .parallax-outer {
    z-index: -1; }
  main.five-g .tab-cbu h2 {
    font-weight: 500; }
  main.five-g .tab-cbu .content img {
    width: 110px;
    height: 110px;
    margin-bottom: 24px; }
  main.five-g .tab-cbu .content ul {
    padding: 0;
    list-style: none; }
    main.five-g .tab-cbu .content ul li {
      display: flex; }
      main.five-g .tab-cbu .content ul li h3 {
        margin-bottom: 18px; }
      main.five-g .tab-cbu .content ul li img {
        height: 24px;
        width: auto;
        width: initial;
        object-fit: contain;
        margin-right: 6px; }
  main.five-g .tab-cbu .cbu-expand-block {
    padding-top: 130px;
    min-height: 364px;
    display: flex;
    padding-bottom: 100px; }
    main.five-g .tab-cbu .cbu-expand-block .d-flex {
      flex-direction: column;
      display: flex;
      padding-right: 88px; }
    main.five-g .tab-cbu .cbu-expand-block .otoro img {
      max-width: 508px; }
    main.five-g .tab-cbu .cbu-expand-block .fui-card-description {
      transition: all 1s;
      overflow: hidden; }
    main.five-g .tab-cbu .cbu-expand-block .fui-read-more-info p {
      margin-top: 0;
      margin-bottom: 18px; }
  main.five-g .tab-cbu .city-bg {
    background-image: url(/DigService/resources/cbu/images/city.png);
    width: 100%;
    height: 640px;
    margin-top: -640px;
    background-repeat-x: repeat;
    background-repeat-y: no-repeat;
    background-position: bottom;
    background-size: contain;
    position: relative;
    z-index: -1; }
  main.five-g .tab-cbu .panel-container {
    background-image: url(/DigService/resources/cbu/images/ribbon-pattern-1920.jpg);
    width: 100%;
    background-color: #FFFFFF;
    background-repeat: repeat;
    background-size: contain; }
    main.five-g .tab-cbu .panel-container .fui-panel {
      box-shadow: none;
      box-shadow: initial;
      background: none; }
  main.five-g .tab-cbu .fui-panel {
    padding: 0; }
  main.five-g .tab-cbu .player-wrapper {
    padding-bottom: 120px; }
    main.five-g .tab-cbu .player-wrapper .player-block {
      display: flex;
      flex-direction: row; }
      main.five-g .tab-cbu .player-wrapper .player-block .left-block {
        min-width: 180px;
        margin-left: -34px; }
        main.five-g .tab-cbu .player-wrapper .player-block .left-block .circle-now {
          width: 90px;
          height: 90px;
          border-radius: 50%;
          background-color: #9edfe9;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 auto; }
          main.five-g .tab-cbu .player-wrapper .player-block .left-block .circle-now h5 {
            margin: 0; }
        main.five-g .tab-cbu .player-wrapper .player-block .left-block img {
          margin-left: -10px; }
        main.five-g .tab-cbu .player-wrapper .player-block .left-block .future {
          margin-top: -120px;
          text-align: center; }
        main.five-g .tab-cbu .player-wrapper .player-block .left-block::after {
          content: '';
          height: 85%;
          width: 2px;
          background-color: #eff1f2;
          z-index: -1;
          display: inline-block;
          margin-left: 89px;
          position: relative;
          margin-top: 10px; }
      main.five-g .tab-cbu .player-wrapper .player-block .right-block {
        display: flex;
        flex-direction: column;
        margin-top: 60px;
        padding-left: 40px; }
  main.five-g .tab-cbu .fui-horzonal-cards {
    margin-top: -214px; }
    main.five-g .tab-cbu .fui-horzonal-cards .fui-card .fui-card-description {
      -webkit-line-clamp: initial;
      height: auto;
      overflow: visible;
      display: block;
      max-height: none;
      max-height: initial; }
  main.five-g .five-g-demo .slick-slide > div {
    padding: 0 !important; }
  main.five-g .five-g-demo .slick-arrow {
    cursor: pointer; }
  main.five-g .five-g-demo .item-bg {
    background-size: cover;
    height: 450px;
    position: relative;
    display: flex !important;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%; }
    main.five-g .five-g-demo .item-bg::after {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      background-color: #000;
      opacity: .3;
      top: 0;
      z-index: 0; }
    main.five-g .five-g-demo .item-bg .shop-name {
      z-index: 1; }
    main.five-g .five-g-demo .item-bg p {
      width: 100%;
      text-align: center;
      position: absolute;
      display: flex;
      align-content: center;
      justify-content: center;
      color: #FFFFFF;
      z-index: 1;
      font-size: 22px; }
    main.five-g .five-g-demo .item-bg .subtitle, main.five-g .five-g-demo .item-bg .fui-card.box .fui-card-content .fui-card-title, .fui-card.box .fui-card-content main.five-g .five-g-demo .item-bg .fui-card-title {
      font-weight: 500;
      z-index: 1; }
    main.five-g .five-g-demo .item-bg img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  main.five-g .feature .d-flex {
    flex-direction: column; }
  main.five-g .five-g-experience h2 {
    width: 100%; }
  main.five-g .five-g-experience .fui-card-description {
    -webkit-line-clamp: 2 !important;
    max-height: none !important;
    max-height: initial !important; }
  main.five-g .five-g-experience .left-content {
    display: flex;
    flex-direction: column;
    align-items: center; }
    main.five-g .five-g-experience .left-content h2 {
      margin: 0; }
  main.five-g .five-g-experience .card-scroller {
    display: flex; }
    main.five-g .five-g-experience .card-scroller .fui-card {
      display: flex;
      width: 50%; }
      main.five-g .five-g-experience .card-scroller .fui-card .fui-card-description ul {
        list-style: none;
        padding: 0; }
        main.five-g .five-g-experience .card-scroller .fui-card .fui-card-description ul li {
          display: flex;
          align-items: flex-start; }
          main.five-g .five-g-experience .card-scroller .fui-card .fui-card-description ul li img {
            height: 24px;
            object-fit: contain;
            margin-right: 6px !important; }
      main.five-g .five-g-experience .card-scroller .fui-card:nth-of-type(1) {
        margin-right: 12px; }
  main.five-g .medical-care {
    background-image: url(/DigService/resources/cbu/images/cbu-5-g-lab-bkg-doctor.jpg);
    background-position: right top;
    background-repeat: no-repeat;
    height: 540px;
    margin-top: 150px;
    background-size: cover;
    position: relative; }
    main.five-g .medical-care h2 {
      padding-top: 90px;
      color: #5F6E84; }
    main.five-g .medical-care p {
      max-width: 50vw; }
  main.five-g .medical-card {
    background-position: top;
    background-color: #eff1f2; }
    main.five-g .medical-card .slick-slider .slick-list .slick-slide .fui-card .fui-card-description {
      max-height: none;
      max-height: initial; }
    main.five-g .medical-card .fui-card .fui-card-action {
      cursor: auto;
      cursor: initial; }
    main.five-g .medical-card .fui-card .fui-card-extra {
      display: none; }
  main.five-g .gradient-bg {
    width: 100%;
    left: 0;
    top: 0;
    height: 540px;
    margin-top: -540px;
    background: linear-gradient(to left, #DAE1EC, #F2F3F5); }
  main.five-g .fin-tech {
    padding-top: 68px;
    padding-bottom: 90px;
    background-color: #eff1f2;
    position: relative; }
    main.five-g .fin-tech::before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-image: url(/DigService/resources/cbu/images/gradient-triangle.svg); }
    main.five-g .fin-tech h2 {
      margin-top: 0 !important; }
    main.five-g .fin-tech h3 {
      padding-top: 32px; }
    main.five-g .fin-tech .fui-card {
      margin-left: 60px;
      display: flex;
      flex-direction: column;
      align-items: center;
      float: right;
      background-color: #FFFFFF;
      padding: 60px 36px; }
      main.five-g .fin-tech .fui-card img {
        width: 80px;
        height: 80px;
        margin-bottom: 18px; }
      main.five-g .fin-tech .fui-card h3 {
        margin: 0;
        padding: 0; }
      main.five-g .fin-tech .fui-card p {
        font-size: 16px;
        margin-top: 24px;
        margin-bottom: 0; }
      main.five-g .fin-tech .fui-card .fui-button {
        display: none; }
  main.five-g .fin-tech-hide {
    display: block; }

@media screen and (max-width: 960px) {
  main.five-g .tab-cbu .panel-container {
    background-image: url(/DigService/resources/cbu/images/ribben-mb.svg); }
  main.five-g .fui-solution-tab .tab-content {
    margin-top: 60px; }
  main.five-g .fui-banner.is-product .slick-slider ul.slick-dots {
    bottom: 30px; }
  main.five-g .fui-banner.is-product .slick-slider .slick-slide {
    min-height: 0;
    min-height: initial; }
    main.five-g .fui-banner.is-product .slick-slider .slick-slide .caption, main.five-g .fui-banner.is-product .slick-slider .slick-slide .fui-banner.is-product .slick-slider .slick-slide > div .caption {
      top: 142px; }
  main.five-g .tab-content .fui-tab-pane h2 {
    margin-top: 0; }
    main.five-g .tab-content .fui-tab-pane h2.pane-title {
      margin-top: 50px;
      margin-bottom: 22px; }
  main.five-g .tab-content .fui-tab-pane .fui-cards {
    padding: 0;
    height: 480px;
    margin-bottom: 100px !important; }
  main.five-g .tab-content .fui-tab-pane .tab-content-wrapper {
    width: 100%;
    margin-right: 0; }
    main.five-g .tab-content .fui-tab-pane .tab-content-wrapper h2 {
      padding: 116px 20px 0 20px;
      font-weight: 500; }
    main.five-g .tab-content .fui-tab-pane .tab-content-wrapper p {
      font-size: 16px;
      padding: 0 20px 0 20px; }
  main.five-g .tab-content .five-g-card {
    width: 100%; }
    main.five-g .tab-content .five-g-card .fui-card {
      width: calc(50% - 6px);
      margin-left: 0;
      margin-right: 12px; }
      main.five-g .tab-content .five-g-card .fui-card:nth-last-child(1) {
        margin-right: 0; }
      main.five-g .tab-content .five-g-card .fui-card .fui-card-meta {
        margin: 0; }
      main.five-g .tab-content .five-g-card .fui-card .fui-card-title .text {
        font-size: 20px !important; }
      main.five-g .tab-content .five-g-card .fui-card .fui-card-description {
        height: auto;
        height: initial;
        -webkit-line-clamp: 3;
        margin: 8px 0; }
  main.five-g .tab-content .single-card .fui-card {
    width: 100%; }
  main.five-g .fui-card-caption .fui-card-content {
    min-height: 0;
    min-height: initial; }
  main.five-g .five-g-news-content {
    display: block; }
    main.five-g .five-g-news-content .five-g-bg-img {
      height: 232px; }
    main.five-g .five-g-news-content img {
      width: 100%;
      height: 230px; }
    main.five-g .five-g-news-content .fui-card-content {
      padding: 30px 20px; }
      main.five-g .five-g-news-content .fui-card-content p {
        margin: 8px 0 30px 0; }
      main.five-g .five-g-news-content .fui-card-content .fui-card-title {
        font-size: 22px;
        margin: 0; }
      main.five-g .five-g-news-content .fui-card-content .fui-card-meta {
        margin: 0; }
      main.five-g .five-g-news-content .fui-card-content .two-button .fui-button {
        width: 50%;
        margin-right: 0; }
      main.five-g .five-g-news-content .fui-card-content .two-button .is-primary {
        margin-right: 12px; }
  main.five-g .fui-container .fui-cards {
    padding: 18px 15px 48px 15px; }
  main.five-g .five-g-bg-img {
    height: 232px; }
  main.five-g #video-slider {
    z-index: 0;
    margin-top: -46px;
    padding: 60px 0 66px 0; }
  main.five-g .video-card {
    height: 420px; }
    main.five-g .video-card .video-img {
      height: 420px; }
    main.five-g .video-card .video-card-text {
      font-size: 18px;
      font-weight: 500; }
  main.five-g .lab {
    padding: 60px 0 0;
    height: 745px;
    background-position: bottom; }
    main.five-g .lab .d-flex {
      margin-top: 20px; }
    main.five-g .lab .subtitle, main.five-g .lab .fui-card.box .fui-card-content .fui-card-title, .fui-card.box .fui-card-content main.five-g .lab .fui-card-title {
      font-size: 16px; }
  main.five-g .milestone .fui-container .milestone-card {
    padding: 40px 30px 50px 20px; }
    main.five-g .milestone .fui-container .milestone-card h4 {
      width: 80px;
      height: 80px; }
  main.five-g .milestone .fui-container .slick-slide {
    width: 290px; }
  main.five-g .tab-cbu .cbu-expand-block {
    padding-top: 30px;
    padding-bottom: 100px;
    flex-direction: column; }
    main.five-g .tab-cbu .cbu-expand-block .d-flex {
      padding-right: 0; }
    main.five-g .tab-cbu .cbu-expand-block .otoro img {
      max-width: 100%; }
  main.five-g .tab-cbu .expand-wrapper .fui-collapse-action {
    display: none; }
  main.five-g .tab-cbu .player-wrapper {
    padding-bottom: 0; }
    main.five-g .tab-cbu .player-wrapper .player-block {
      flex-direction: column; }
      main.five-g .tab-cbu .player-wrapper .player-block .future-block.left-block::after {
        margin-left: 36px;
        margin-top: -4px; }
      main.five-g .tab-cbu .player-wrapper .player-block .left-block {
        margin-left: 0; }
        main.five-g .tab-cbu .player-wrapper .player-block .left-block .circle-now {
          margin-left: 0px; }
        main.five-g .tab-cbu .player-wrapper .player-block .left-block .future {
          margin-left: 16px;
          text-align: left;
          text-align: initial;
          margin-top: -82px; }
        main.five-g .tab-cbu .player-wrapper .player-block .left-block::after {
          min-height: 30px;
          margin-left: 42px;
          width: 4px; }
      main.five-g .tab-cbu .player-wrapper .player-block .right-block {
        padding: 0;
        margin-top: 0; }
  main.five-g .feature {
    display: flex;
    flex-direction: row; }
    main.five-g .feature img {
      margin-right: 15px; }
    main.five-g .feature h3 {
      margin-bottom: 0; }
  main.five-g .five-g-experience h2 {
    text-align: left;
    width: 100%;
    font-weight: 500; }
  main.five-g .five-g-experience .card-scroller {
    display: flex;
    overflow: scroll;
    margin-left: -15px;
    margin-right: 0;
    padding: 0 15px 30px 15px; }
    main.five-g .five-g-experience .card-scroller .fui-card {
      min-width: 270px; }
  main.five-g .five-g-experience .left-content {
    margin-bottom: 16px; }
  main.five-g .medical-care {
    margin-top: 30px;
    background-position: top right;
    background-color: #f1f3f5;
    height: 450px; }
    main.five-g .medical-care h2 {
      padding-top: calc(24vw + 64px);
      font-weight: 500; }
    main.five-g .medical-care p {
      max-width: 85%; }
  main.five-g .gradient-bg {
    display: none; }
  main.five-g .medical-card {
    padding-top: 170px;
    background-color: #f1f3f5; }
  main.five-g .fin-tech {
    padding-bottom: 0;
    background-color: #f1f3f5;
    padding-top: 68px;
    margin-top: 0; }
    main.five-g .fin-tech::before {
      display: none; }
    main.five-g .fin-tech .fui-card {
      width: 100%;
      padding: 30px 24px;
      background-color: #FFFFFF; }
      main.five-g .fin-tech .fui-card .fui-button {
        width: 100%;
        display: block; }
  main.five-g .fin-tech-hide {
    display: none; }
  main.five-g .fin-tech-hide.fin-tech-show {
    display: block; }
  main.five-g .expand-wrapper .fui-collapse-body article p {
    color: #202020;
    font-weight: 400; }
  main.five-g .fui-section-header {
    padding-top: 50px; } }

@media screen and (max-width: 460px) {
  main.five-g .tab-content .fui-tab-pane .fui-cards {
    width: 100% !important;
    margin: 0 0 226px !important; }
  main.five-g .tab-content .fui-tab-pane .city-bg {
    background-image: url(/DigService/resources/cbu/images/city-sm.png); }
  main.five-g .lab .d-flex button {
    flex: 1 1 auto; }
  main.five-g .medical-care h2 {
    padding-top: 36vw;
    padding-top: calc(24vw + 24px); }
  main.five-g .medical-care p {
    max-width: 80%; } }

.fiveg-event .arrow-wrapper {
  position: absolute;
  margin-top: -300px;
  right: 0; }

.fiveg-event .opacity0 {
  opacity: 0;
  position: absolute; }

.fiveg-event .fui-video-card-container .fui-cards.is-video-list .fui-card.is-video {
  width: 100%; }
  .fiveg-event .fui-video-card-container .fui-cards.is-video-list .fui-card.is-video .fui-card-caption {
    display: none; }

.store-finder {
  z-index: 10;
  padding-top: 95px; }
  .store-finder .infobox {
    position: relative; }
  .store-finder .store-wrapper {
    -ms-overflow-style: none; }
    .store-finder .store-wrapper > * {
      -ms-overflow-style: none; }
    .store-finder .store-wrapper .menu-wrapper > .fui-item > .content {
      text-align: center; }
  .store-finder .fui-container {
    position: relative; }
    .store-finder .fui-container::before {
      content: "";
      width: 150px;
      height: 200px;
      background-image: url(/DigService/resources/cbu/img/help-center.svg);
      position: absolute;
      background-repeat: no-repeat;
      left: -120px;
      top: -110px;
      z-index: 1; }
  .store-finder .no-place {
    padding: 30px; }
    .store-finder .no-place img {
      width: 30px;
      height: 30px; }
    .store-finder .no-place h5 {
      margin-top: 8px;
      margin-bottom: 0; }
    .store-finder .no-place p {
      margin-top: 8px;
      margin-bottom: 24px; }
  .store-finder .map {
    width: 100%;
    height: 740px; }
    @media screen and (max-width: 768px) {
      .store-finder .map {
        height: 250px; } }
    .store-finder .map .marker {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      border: 2px solid #FFFFFF;
      background-color: #EF3123;
      cursor: pointer; }
      .store-finder .map .marker:hover {
        background-image: url(/DigService/resources/cbu/img/icon-pin-hover.svg);
        width: 46px;
        height: 55px;
        border: none;
        background-color: transparent;
        background-color: initial;
        margin-left: -12px;
        margin-top: -26px;
        border-radius: 0; }
      .store-finder .map .marker.active {
        width: 46px;
        height: 55px;
        border: none;
        border-radius: 0;
        margin-left: -12px;
        margin-top: -26px;
        background-color: transparent;
        background-color: initial;
        background-image: url(/DigService/resources/cbu/img/icon-pin-seleted.svg); }
  .store-finder .filter {
    background-color: #eff1f2;
    padding: 16px 30px; }
    .store-finder .filter-mrt, .store-finder .filter-city {
      width: calc(100% - 200px);
      display: inline-block; }
    .store-finder .filter .fui-dropdown {
      width: 175px;
      margin: 0 5px;
      display: inline-block;
      align-items: center; }
      .store-finder .filter .fui-dropdown.is-invalid {
        border: 2px solid #f05c38;
        color: #f05c38; }
        .store-finder .filter .fui-dropdown.is-invalid > .fui-dropdown-item {
          color: #f05c38; }
      .store-finder .filter .fui-dropdown span {
        text-align: left; }
      .store-finder .filter .fui-dropdown.disabled {
        pointer-events: none;
        opacity: 0.3; }
      .store-finder .filter .fui-dropdown button {
        height: 50px;
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between; }
        .store-finder .filter .fui-dropdown button i {
          text-align: right; }
    .store-finder .filter .fui-button.is-secondary {
      height: 50px;
      min-width: 160px;
      margin: 0; }
    .store-finder .filter .search-input {
      display: inline-block;
      width: calc(100% - 185px * 2); }
      .store-finder .filter .search-input .filter-button img {
        display: none; }
      .store-finder .filter .search-input .input-wrapper {
        display: inline-block;
        vertical-align: middle;
        position: relative;
        margin-right: 25px;
        width: calc(100% - 185px); }
        .store-finder .filter .search-input .input-wrapper input {
          width: 100%;
          height: 50px;
          font-size: 16px;
          padding: 12px;
          border: none; }
        .store-finder .filter .search-input .input-wrapper i {
          font-size: 24px;
          right: 1.5%;
          top: 14px;
          position: absolute;
          cursor: pointer; }
  .store-finder .searchbox {
    height: 740px;
    background: #FFFFFF;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative; }
    .store-finder .searchbox hr {
      margin: 24px 0; }
    .store-finder .searchbox .list {
      padding: 30px;
      border-bottom: 1px solid #EBEBEB;
      cursor: pointer; }
      .store-finder .searchbox .list h4 {
        margin-bottom: 6px;
        font-weight: 500;
        margin-top: 0; }
      .store-finder .searchbox .list i {
        font-size: 22px; }
      .store-finder .searchbox .list p {
        margin: 0; }
      .store-finder .searchbox .list .contact {
        margin-top: 6px;
        align-items: center; }
        .store-finder .searchbox .list .contact p {
          margin-right: 12px;
          padding-right: 12px;
          border-right: 1px solid #ebebeb; }
        .store-finder .searchbox .list .contact img {
          width: 36px;
          height: 36px;
          margin-right: 10px; }
    .store-finder .searchbox.open {
      position: static; }
  .store-finder .more-info {
    position: absolute;
    left: 0;
    top: 0;
    background: #FFFFFF;
    padding: 48px 30px;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    z-index: 1; }
    .store-finder .more-info .services {
      margin-bottom: 24px;
      display: flex;
      flex-direction: row; }
      .store-finder .more-info .services .item {
        align-items: flex-start;
        width: 50%;
        min-width: 50%;
        vertical-align: top;
        display: inline-flex;
        line-height: 26px; }
        .store-finder .more-info .services .item:nth-child(odd) {
          padding-right: 12px; }
        .store-finder .more-info .services .item img {
          margin-right: 12px;
          width: 24px;
          height: 24px;
          min-width: 24px; }
      .store-finder .more-info .services .services-list {
        width: 100%; }
    .store-finder .more-info .fui-button.is-secondary {
      width: 100%;
      margin-top: 24px;
      padding-bottom: 24px;
      min-width: auto; }
    .store-finder .more-info .close {
      cursor: pointer; }
      .store-finder .more-info .close i {
        font-weight: 400; }
    .store-finder .more-info .wrapper p {
      margin: 0 30px 0 0;
      line-height: 1.75;
      min-width: 92px; }
    .store-finder .more-info p {
      margin: 12px 0 8px; }
    .store-finder .more-info .contact img {
      height: 36px;
      margin-right: 6px; }
    .store-finder .more-info .contact .subtitle, .store-finder .more-info .contact .fui-card.box .fui-card-content .fui-card-title, .fui-card.box .fui-card-content .store-finder .more-info .contact .fui-card-title {
      margin: 0;
      border-right: 1px solid #EBEBEB;
      margin-right: 12px;
      padding-right: 12px; }
      .store-finder .more-info .contact .subtitle::after, .store-finder .more-info .contact .fui-card.box .fui-card-content .fui-card-title::after, .fui-card.box .fui-card-content .store-finder .more-info .contact .fui-card-title::after {
        content: "";
        display: block;
        width: 0;
        height: 2px;
        background: #ef3123 !important;
        transition: width 0.3s ease; }
      .store-finder .more-info .contact .subtitle:hover::after, .store-finder .more-info .contact .fui-card.box .fui-card-content .fui-card-title:hover::after, .fui-card.box .fui-card-content .store-finder .more-info .contact .fui-card-title:hover::after {
        width: 100%; }

@media screen and (max-width: 959px) {
  .store-finder {
    padding-top: 30px; }
    .store-finder .store-wrapper {
      margin: 0 -15px; }
      .store-finder .store-wrapper .menu-wrapper > .fui-item > .content {
        padding: 0 6px;
        text-align: left; }
    .store-finder .searchbox {
      height: auto;
      position: relative; }
      .store-finder .searchbox.open {
        height: 984px; }
    .store-finder .more-info {
      padding: 24px 15px;
      width: 100%;
      position: relative;
      height: auto;
      background-color: #FFFFFF; }
      .store-finder .more-info .time-table {
        flex-direction: column; }
      .store-finder .more-info .services {
        flex-direction: column; }
      .store-finder .more-info .button-group {
        flex-direction: column;
        margin: 0;
        padding: 0; }
        .store-finder .more-info .button-group a.fui-button.is-secondary {
          margin: 0;
          padding: 0; }
          .store-finder .more-info .button-group a.fui-button.is-secondary:nth-last-child(1) {
            margin-top: 12px; }
    .store-finder .fui-container::before {
      right: -40px;
      left: auto;
      left: initial;
      top: -62px;
      height: 143px;
      background-size: contain; }
    .store-finder .filter {
      height: auto;
      display: inline-block;
      width: 100%;
      padding: 12px 1.25%; }
      .store-finder .filter-mrt {
        width: 100%; }
      .store-finder .filter button.is-secondary.filter-button {
        width: 30px;
        border: 0;
        min-width: auto;
        padding: 0;
        margin-left: -8px; }
        .store-finder .filter button.is-secondary.filter-button img {
          display: block; }
        .store-finder .filter button.is-secondary.filter-button span {
          display: none; }
      .store-finder .filter .fui-dropdown {
        width: 100%;
        margin: 0; }
      .store-finder .filter .search-input {
        width: 100%; }
        .store-finder .filter .search-input .input-wrapper {
          width: calc(100% - 55px); } }

@media screen and (max-width: 1120px) {
  .store-finder .filter-city {
    display: block;
    width: 100%; } }

.store-finder.__override__ .filter {
  height: auto;
  min-height: 80px; }
  .store-finder.__override__ .filter .filter-city {
    display: flex;
    flex-wrap: wrap; }
    .store-finder.__override__ .filter .filter-city .search-input {
      display: flex;
      flex: 1 1;
      float: none;
      margin-left: auto;
      width: auto;
      margin-right: 0; }
      .store-finder.__override__ .filter .filter-city .search-input .input-wrapper {
        width: auto;
        flex: 1 1; }
        .store-finder.__override__ .filter .filter-city .search-input .input-wrapper input {
          position: static; }
        .store-finder.__override__ .filter .filter-city .search-input .input-wrapper i.icon-search {
          right: 14px; }

.is-e-service-section {
  position: relative;
  padding: 60px 0;
  height: auto;
  min-height: 0; }
  .is-e-service-section img.arrow,
  .is-e-service-section img.bg {
    position: absolute;
    z-index: 1; }
  .is-e-service-section img.arrow {
    left: 0;
    top: 0;
    height: 70px; }
  .is-e-service-section img.bg {
    width: 100%;
    height: auto;
    right: 0;
    top: auto;
    bottom: 0;
    z-index: 0; }
  .is-e-service-section .fui-container {
    position: relative;
    height: auto;
    padding: 0 15px;
    z-index: 2; }
  .is-e-service-section .fui-card {
    background: #FFFFFF; }
  @media screen and (min-width: 961px) {
    .is-e-service-section {
      padding: 115px 0 95px; }
      .is-e-service-section img.bg {
        width: 100%;
        height: auto;
        top: 0;
        bottom: auto; }
      .is-e-service-section img.arrow {
        top: 30px; } }
  @media screen and (max-width: 1024px) {
    .is-e-service-section img.bg {
      height: 100%;
      width: auto; } }
  @media screen and (min-width: 1280px) {
    .is-e-service-section img.arrow {
      top: 99px;
      height: 144px; } }

@media screen and (min-width: 960px) {
  .is-eservice .fui-collapse-menu .group-name,
  .is-cbu .fui-collapse-menu .group-name {
    background: url(/DigService/resources/cbu/discount/collapse-menu-bg@2x.png) no-repeat;
    background-size: cover; } }

.fui-nav-anchor + .fui-banner.is-form {
  margin-top: 0; }

#root {
  overflow: hidden; }

.page-body {
  z-index: 10; }

.fui-faq-collapse a, .fui-faq-collapse a:link {
  color: #EF3123; }

.form-group label {
  display: flex; }
  .form-group label .fui-tooltip-container {
    display: inline-flex; }
  @media screen and (max-width: 960px) {
    .form-group label {
      font-size: 18px; } }

.fui-image-content-layout {
  position: relative;
  background: url(/DigService/resources/cbu/e-service/images/bg-ribbon.png) repeat-y top left;
  background-size: 100% auto; }
  .fui-image-content-layout .bg {
    position: absolute;
    left: -350px;
    top: 0; }
  .fui-image-content-layout section.fui-image-content {
    padding-top: 30px;
    padding-bottom: 0; }
    .fui-image-content-layout section.fui-image-content + section.fui-image-content {
      padding-top: 0; }
    .fui-image-content-layout section.fui-image-content h3 {
      margin-top: 0px; }
    .fui-image-content-layout section.fui-image-content img {
      width: calc(100% + 30px);
      margin-left: -15px;
      margin-right: -15px; }
  @media screen and (min-width: 600px) and (max-width: 1200px) {
    .fui-image-content-layout {
      padding: 0 16px; } }
  @media screen and (min-width: 768px) {
    .fui-image-content-layout section.fui-image-content {
      padding-top: 60px; } }
  @media screen and (min-width: 960px) {
    .fui-image-content-layout section.fui-image-content h3 {
      margin-top: 80px; }
    .fui-image-content-layout section.fui-image-content + section.fui-image-content {
      padding-top: 60px; } }

section.fui-section-board {
  background: #eff4f1; }

.car-information-body .radio-group {
  display: flex; }
  .car-information-body .radio-group .radio {
    display: flex; }
    .car-information-body .radio-group .radio .content {
      width: 100%; }
    .car-information-body .radio-group .radio img {
      width: 64px;
      height: 64px; }
    @media screen and (min-width: 600px) {
      .car-information-body .radio-group .radio {
        width: calc(25% - 12px) !important; } }

.car-information-list .car-information-item {
  border: 2px solid #E8ECF0;
  margin-bottom: 12px; }
  .car-information-list .car-information-item .car-information-header {
    padding: 12px 30px;
    background: #f1f3f5;
    border-bottom: 2px solid #E8ECF0;
    display: flex;
    align-content: center;
    align-items: center; }
    .car-information-list .car-information-item .car-information-header > .content {
      font-weight: 500;
      color: #5F6E84;
      font-size: 24px;
      flex: 1 1;
      flex-grow: 1; }
    .car-information-list .car-information-item .car-information-header .action {
      display: flex;
      align-items: center;
      align-content: center; }
      .car-information-list .car-information-item .car-information-header .action .checkbox {
        margin: 0; }
        .car-information-list .car-information-item .car-information-header .action .checkbox .content {
          padding-top: 0;
          color: #202020;
          line-height: 30px; }
          .car-information-list .car-information-item .car-information-header .action .checkbox .content::before {
            border-color: rgba(47, 89, 196, 0.2);
            margin-bottom: 0; }
        .car-information-list .car-information-item .car-information-header .action .checkbox .error-message {
          display: none; }
  .car-information-list .car-information-item .car-information-body {
    padding: 15px;
    margin-bottom: 0; }
    .car-information-list .car-information-item .car-information-body .form-group {
      margin-bottom: 0; }
      .car-information-list .car-information-item .car-information-body .form-group > div {
        display: flex;
        align-items: center;
        align-content: center;
        justify-items: center;
        justify-content: center; }
        .car-information-list .car-information-item .car-information-body .form-group > div > .fui-select,
        .car-information-list .car-information-item .car-information-body .form-group > div > input {
          flex: 1 1;
          flex-grow: 1; }
        .car-information-list .car-information-item .car-information-body .form-group > div > span {
          padding: 0 4px; }
    .car-information-list .car-information-item .car-information-body .input-group {
      display: flex; }
      .car-information-list .car-information-item .car-information-body .input-group input {
        width: auto;
        min-width: 0; }
    .car-information-list .car-information-item .car-information-body .fui-button {
      min-width: 0;
      width: 100%; }
  @media screen and (min-width: 768px) {
    .car-information-list .car-information-item .car-information-body {
      padding: 30px; } }
  @media screen and (max-width: 640px) {
    .car-information-list .car-information-item .page-action {
      flex-direction: column; } }

.car-information-action .fui-button[role="button"] {
  width: 100%;
  color: #202020;
  border: 2px solid #E8ECF0; }
  .car-information-action .fui-button[role="button"]::after {
    display: none; }

.fui-banner.is-parkgin-fee {
  position: relative;
  padding: 0;
  z-index: 0;
  min-height: 360px; }
  .fui-banner.is-parkgin-fee > img {
    width: 100%; }
  .fui-banner.is-parkgin-fee .caption {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    padding: 48px;
    color: #FFFFFF; }
    .fui-banner.is-parkgin-fee .caption .fui-container {
      width: 90%; }
    .fui-banner.is-parkgin-fee .caption h1 {
      margin-bottom: 24px; }
    .fui-banner.is-parkgin-fee .caption .fui-list {
      margin-bottom: 40px; }
    .fui-banner.is-parkgin-fee .caption .fui-item {
      padding-top: 4px;
      padding-bottom: 4px; }
      .fui-banner.is-parkgin-fee .caption .fui-item img {
        width: 24px;
        height: 24px;
        margin-right: 8px; }
      .fui-banner.is-parkgin-fee .caption .fui-item .content {
        color: #FFFFFF; }
    .fui-banner.is-parkgin-fee .caption img {
      vertical-align: middle;
      margin-right: 12px; }
    .fui-banner.is-parkgin-fee .caption div.subtitle, .fui-banner.is-parkgin-fee .caption .fui-card.box .fui-card-content div.fui-card-title, .fui-card.box .fui-card-content .fui-banner.is-parkgin-fee .caption div.fui-card-title {
      margin-bottom: 24px; }
    .fui-banner.is-parkgin-fee .caption .fui-container {
      padding: 0 16px; }
  @media screen and (min-width: 600px) and (max-width: 1024px) {
    .fui-banner.is-parkgin-fee > img {
      position: absolute;
      height: 100%;
      width: auto;
      left: -45%;
      right: 0;
      bottom: 0;
      margin: auto; } }
  @media screen and (min-width: 768px) {
    .fui-banner.is-parkgin-fee {
      min-height: 460px;
      height: 460px; }
      .fui-banner.is-parkgin-fee .caption {
        padding: 90px;
        height: 460px; }
        .fui-banner.is-parkgin-fee .caption .fui-item img {
          width: 32px;
          height: 32px; } }
  @media screen and (min-width: 960px) and (max-width: 1024px) {
    .fui-banner.is-parkgin-fee > img {
      left: -20%; } }
  @media screen and (min-width: 1025px) {
    .fui-banner.is-parkgin-fee > img {
      left: 0;
      height: 100%;
      width: auto; } }
  @media screen and (min-width: 1920px) {
    .fui-banner.is-parkgin-fee > img {
      width: 100%;
      height: auto; } }

.parking-area-selector .checkbox-group {
  margin-right: 0; }
  .parking-area-selector .checkbox-group .checkbox.is-icon-box {
    width: 100%;
    margin: 0 0 12px; }
    .parking-area-selector .checkbox-group .checkbox.is-icon-box input:disabled + .content::before {
      display: none; }
    .parking-area-selector .checkbox-group .checkbox.is-icon-box input:checked + .content > .extra .fui-label {
      background: #f05c38; }
    .parking-area-selector .checkbox-group .checkbox.is-icon-box .content {
      display: flex;
      min-height: 88px;
      padding: 12px 12px 12px 48px;
      text-align: left;
      align-items: flex-start;
      align-content: flex-start; }
      .parking-area-selector .checkbox-group .checkbox.is-icon-box .content::after {
        top: 20px;
        bottom: auto; }
      .parking-area-selector .checkbox-group .checkbox.is-icon-box .content::before {
        top: 12px;
        bottom: auto; }
      .parking-area-selector .checkbox-group .checkbox.is-icon-box .content .main {
        flex: 1 1 auto;
        flex-grow: 1 auto; }
      .parking-area-selector .checkbox-group .checkbox.is-icon-box .content .extra {
        align-self: center; }
      .parking-area-selector .checkbox-group .checkbox.is-icon-box .content .text {
        text-align: left;
        color: #202020; }
      .parking-area-selector .checkbox-group .checkbox.is-icon-box .content .description {
        color: #8A8A8A;
        font-size: 0.875rem; }
      .parking-area-selector .checkbox-group .checkbox.is-icon-box .content .fui-label {
        font-size: 0.875rem;
        background: #95a3a4;
        border-radius: 30px;
        padding: 0 12px; }
        .parking-area-selector .checkbox-group .checkbox.is-icon-box .content .fui-label .text {
          white-space: nowrap;
          line-height: 30px;
          color: #FFFFFF; }

@media screen and (min-width: 600px) {
  .parking-area-selector .checkbox-group {
    margin-right: -12px; }
    .parking-area-selector .checkbox-group .checkbox.is-icon-box {
      width: calc(50% - 12px);
      margin: 0 12px 12px 0 !important; }
      .parking-area-selector .checkbox-group .checkbox.is-icon-box:nth-of-type(2n) {
        margin: 0 0 12px 0; } }

@media screen and (min-width: 960px) {
  .parking-area-selector .checkbox-group .checkbox.is-icon-box {
    width: calc(33.3333% - 12px); }
    .parking-area-selector .checkbox-group .checkbox.is-icon-box:nth-of-type(3n) {
      margin: 0 0 12px 0; } }

.e-service-result {
  text-align: center;
  padding-top: 24px; }
  .e-service-result.is-success .content .body1 {
    margin: 0 auto;
    max-width: 600px;
    padding: 0 20px; }
  .e-service-result table tr > th {
    background: #E8ECF0; }
  .e-service-result table tr > td {
    background: #FFFFFF; }
  .e-service-result img {
    width: 186px;
    margin: 0 auto;
    line-height: 0; }
  .e-service-result .action {
    margin-top: 24px; }
    .e-service-result .action > .fui-button {
      width: 100%;
      display: block;
      margin: 0 auto 8px; }
  @media screen and (min-width: 600px) {
    .e-service-result .action > .fui-button {
      min-width: 202px;
      width: auto;
      display: inline-block;
      margin: 0 8px 8px 0; } }
  @media screen and (min-width: 960px) {
    .e-service-result {
      text-align: left;
      min-height: 640px;
      padding: 60px; }
      .e-service-result p.subtitle, .e-service-result .fui-card.box .fui-card-content p.fui-card-title, .fui-card.box .fui-card-content .e-service-result p.fui-card-title {
        margin-top: 12px; }
      .e-service-result h2 {
        margin-top: 60px; }
      .e-service-result .body1 {
        margin-top: 12px; }
      .e-service-result img {
        width: 540px;
        float: right;
        margin-top: -60px;
        margin-left: 40px; }
      .e-service-result .action {
        margin-top: 72px; }
      .e-service-result.is-success {
        padding: 60px;
        text-align: center; }
        .e-service-result.is-success h2 {
          margin-top: 24px; }
        .e-service-result.is-success img {
          float: none;
          width: 220px;
          margin: 0; }
        .e-service-result.is-success .action {
          margin-top: 24px; } }

.e-service-result {
  text-align: center;
  padding-top: 24px; }
  .e-service-result.is-success .content .body1 {
    margin: 0 auto;
    max-width: 600px;
    padding: 0 20px; }
  .e-service-result img {
    width: 186px;
    margin: 0 auto;
    line-height: 0; }
  .e-service-result .action {
    margin-top: 24px; }
    .e-service-result .action > .fui-button {
      width: 100%;
      display: block;
      margin: 0 auto 8px; }
  @media screen and (min-width: 600px) {
    .e-service-result .action > .fui-button {
      min-width: 202px;
      width: auto;
      display: inline-block;
      margin: 0 8px 8px 0; } }
  @media screen and (min-width: 960px) {
    .e-service-result {
      text-align: left;
      min-height: 640px;
      padding: 60px; }
      .e-service-result p.subtitle, .e-service-result .fui-card.box .fui-card-content p.fui-card-title, .fui-card.box .fui-card-content .e-service-result p.fui-card-title {
        margin-top: 12px; }
      .e-service-result h2 {
        margin-top: 60px; }
      .e-service-result .body1 {
        margin-top: 12px; }
      .e-service-result img {
        width: 540px;
        float: right;
        margin-top: -60px;
        margin-left: 40px; }
      .e-service-result .action {
        margin-top: 72px; }
      .e-service-result.is-success {
        padding: 60px;
        text-align: center; }
        .e-service-result.is-success h2 {
          margin-top: 24px; }
        .e-service-result.is-success img {
          float: none;
          width: 220px;
          margin: 0; }
        .e-service-result.is-success .action {
          margin-top: 24px; } }

.page-header {
  padding: 30px 0 0; }

.page-body {
  padding: 48px 0; }
  .page-body a:link,
  .page-body a:visited {
    color: #EF3123; }
  @media screen and (min-width: 960px) {
    .page-body {
      padding: 60px 0; } }

.page-action {
  display: flex;
  flex-direction: column;
  margin-top: 32px; }
  .page-action.action-reverse {
    flex-direction: column-reverse; }
  .page-action .fui-button {
    width: 100%; }
  @media screen and (min-width: 640px) {
    .page-action {
      flex-direction: row; }
      .page-action.action-reverse {
        flex-direction: row-reverse;
        justify-content: flex-end; }
      .page-action .fui-button {
        width: auto; } }

.form-section {
  z-index: 9; }
  .form-section .fui-list .fui-item .prefix,
  .form-section .fui-list a.fui-item .prefix,
  .form-section .fui-list a.fui-item:link .prefix,
  .form-section .fui-list a.fui-item:visited .prefix {
    color: #5F6E84; }
    .form-section .fui-list .fui-item .prefix i.number,
    .form-section .fui-list a.fui-item .prefix i.number,
    .form-section .fui-list a.fui-item:link .prefix i.number,
    .form-section .fui-list a.fui-item:visited .prefix i.number {
      background: #eff4f1; }
    .form-section .fui-list .fui-item .prefix .bulleted-dot,
    .form-section .fui-list a.fui-item .prefix .bulleted-dot,
    .form-section .fui-list a.fui-item:link .prefix .bulleted-dot,
    .form-section .fui-list a.fui-item:visited .prefix .bulleted-dot {
      background-color: #5F6E84; }
  .form-section .fui-list .fui-item .content,
  .form-section .fui-list a.fui-item .content,
  .form-section .fui-list a.fui-item:link .content,
  .form-section .fui-list a.fui-item:visited .content {
    font-weight: 500; }

.paper {
  background: #FFFFFF;
  padding: 30px 20px;
  margin-bottom: 12px;
  box-shadow: 0 6px 12px 0 rgba(75, 88, 105, 0.12);
  position: relative;
  z-index: 1; }
  .paper ~ .page-action {
    position: relative;
    z-index: 0; }
  .paper.merged {
    margin-bottom: 0;
    border-bottom: 1px solid #E8ECF0; }
  .paper a, .paper a:link, .paper a:visited {
    color: #EF3123; }
  .paper:last-child {
    margin-bottom: 60px; }
  .paper:last-child.fui-collapse {
    margin-bottom: 0; }
  .paper .payment-price {
    font-size: 28px;
    color: #f05c38; }
  .paper .barcode-img {
    width: 100%;
    max-width: 300px;
    margin: auto;
    display: block; }
  .paper .rate-plan-box {
    background: #f1f3f5;
    padding: 18px; }
    .paper .rate-plan-box h1, .paper .rate-plan-box h2 {
      margin: 0; }
    .paper .rate-plan-box h4, .paper .rate-plan-box h5 {
      margin-top: 0;
      margin-bottom: 0;
      color: #5F6E84; }
  .paper.merged {
    margin-bottom: 0;
    border-bottom: solid 1px #E8ECF0; }
  @media screen and (max-width: 768px) {
    .paper .fui-table-response {
      padding-right: 20px;
      margin-right: -20px; } }
  @media screen and (min-width: 960px) {
    .paper {
      padding: 30px; }
      .paper .rate-plan-box {
        padding: 24px 18px; } }

.receipt-header {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px; }
  .receipt-header button.is-small {
    padding: 0 10px; }
    .receipt-header button.is-small span {
      font-size: 14px; }
  .receipt-header .main {
    flex: 1 1;
    flex-grow: 1; }
    .receipt-header .main .fui-button {
      margin: 0; }
  .receipt-header .extra {
    margin-top: -10px; }
    .receipt-header .extra .fui-dropdown {
      width: 100%;
      margin-bottom: 10px; }
  @media screen and (min-width: 768px) {
    .receipt-header {
      flex-direction: row; }
      .receipt-header .extra {
        display: flex;
        margin-left: 12px; }
        .receipt-header .extra .fui-dropdown {
          flex: 1 1;
          flex-grow: 1; }
          .receipt-header .extra .fui-dropdown + .fui-dropdown {
            flex: none;
            width: 210px;
            max-width: none;
            margin-left: 8px; }
            .receipt-header .extra .fui-dropdown + .fui-dropdown span {
              white-space: nowrap; } }

.receipt-list .receipt-collapse {
  margin-bottom: 9.6px;
  border: 1px solid #E8ECF0;
  padding: 30px 18px; }
  .receipt-list .receipt-collapse .is-underline:hover {
    text-decoration: underline; }
  .receipt-list .receipt-collapse .fui-item .content {
    font-weight: 500; }
  .receipt-list .receipt-collapse h4.date {
    color: #5F6E84;
    margin: 0; }
  .receipt-list .receipt-collapse h4, .receipt-list .receipt-collapse h5 {
    margin: 0; }
  .receipt-list .receipt-collapse .meta {
    margin-top: 12px;
    margin-bottom: 12px; }
    .receipt-list .receipt-collapse .meta span::after {
      content: ""; }
    .receipt-list .receipt-collapse .meta span:last-child::after {
      display: none; }
  .receipt-list .receipt-collapse .extra {
    display: flex;
    flex-direction: column; }
    .receipt-list .receipt-collapse .extra div[role='button'] {
      font-weight: 500; }
    .receipt-list .receipt-collapse .extra .main {
      flex: 1 1;
      flex-grow: 1; }
    .receipt-list .receipt-collapse .extra .extra {
      text-align: right; }

.receipt-list .receipt-item {
  margin-bottom: 9.6px;
  border: 1px solid #E8ECF0;
  padding: 30px 18px; }
  .receipt-list .receipt-item h4 {
    margin: 0;
    color: #5F6E84; }
  .receipt-list .receipt-item .main {
    display: flex;
    flex-direction: column; }
    .receipt-list .receipt-item .main h5,
    .receipt-list .receipt-item .main h6 {
      margin: 6px 0 0;
      flex: 1 1;
      flex-grow: 1; }

.receipt-list .price {
  font-size: 0.875rem;
  text-align: right;
  margin-top: 12px; }
  .receipt-list .price .amount {
    color: #EF3123;
    font-size: 24px;
    font-weight: 600;
    margin-left: 8px; }

@media screen and (min-width: 768px) {
  .receipt-list .receipt-collapse {
    padding: 30px; }
    .receipt-list .receipt-collapse .extra {
      margin-top: 24px;
      flex-direction: row; }
    .receipt-list .receipt-collapse .meta span::after {
      content: " | "; }
  .receipt-list .price .amount {
    font-size: 28px; }
  .receipt-list .receipt-item {
    padding: 30px; }
    .receipt-list .receipt-item .main {
      flex-direction: row; }
      .receipt-list .receipt-item .main .price {
        margin-top: 0px; } }

.is-eservice .fui-collapse.is-collapse-checkbox .collapse-body {
  max-height: 168px;
  overflow-y: auto; }

.is-eservice .fui-collapse.collapse-table .collapse-header {
  padding: 0; }
  .is-eservice .fui-collapse.collapse-table .collapse-header::after {
    right: 0; }
  .is-eservice .fui-collapse.collapse-table .collapse-header::before {
    right: 0; }

.is-eservice .fui-collapse.collapse-table .collapse-body {
  padding: 0; }

.is-eservice .fui-collapse.is-open .collapse-header::after {
  right: 0; }

.is-eservice .fui-collapse.is-open .collapse-body {
  margin-top: 16px; }
  .is-eservice .fui-collapse.is-open .collapse-body article {
    margin-top: 0; }

.is-eservice .fui-collapse .paper {
  margin-bottom: 12px; }

.is-eservice .fui-collapse .collapse-header {
  font-weight: 500; }

.is-eservice .fui-collapse .collapse-body {
  margin-bottom: 0; }
  .is-eservice .fui-collapse .collapse-body .subtitle, .is-eservice .fui-collapse .collapse-body .fui-card.box .fui-card-content .fui-card-title, .fui-card.box .fui-card-content .is-eservice .fui-collapse .collapse-body .fui-card-title {
    padding-top: 32px; }

.is-eservice .form-group .fui-collapse.is-collapse-checkbox {
  margin-top: 0; }

.is-eservice.is-help-center section.coverage-section {
  padding-top: 120px;
  background: url(/DigService/resources/cbu/e-service/images/help-center.png) left top no-repeat;
  background-size: 150px 200px; }
  .is-eservice.is-help-center section.coverage-section .fui-table-response.coverage-list {
    margin-top: 30px;
    padding: 0 15px; }
    .is-eservice.is-help-center section.coverage-section .fui-table-response.coverage-list > div {
      display: inline-block;
      margin-right: 15px; }
    .is-eservice.is-help-center section.coverage-section .fui-table-response.coverage-list table tr th {
      white-space: normal;
      background: #f1f3f5;
      border: 2px solid #E8ECF0; }
    .is-eservice.is-help-center section.coverage-section .fui-table-response.coverage-list table tbody tr th {
      width: 77px; }
    .is-eservice.is-help-center section.coverage-section .fui-table-response.coverage-list table tr td {
      white-space: nowrap;
      background: #FFFFFF;
      border: 2px solid #E8ECF0; }
    .is-eservice.is-help-center section.coverage-section .fui-table-response.coverage-list div[class*="level-"] {
      height: 15px;
      width: 90px;
      margin: 0 auto; }
    .is-eservice.is-help-center section.coverage-section .fui-table-response.coverage-list .level-1 {
      background: #0eb5be !important; }
    .is-eservice.is-help-center section.coverage-section .fui-table-response.coverage-list .level-2 {
      background: #fd7927 !important; }
    .is-eservice.is-help-center section.coverage-section .fui-table-response.coverage-list .level-3 {
      background: #00ff00 !important; }
    .is-eservice.is-help-center section.coverage-section .fui-table-response.coverage-list .level-4 {
      background: #0000ff !important; }
    .is-eservice.is-help-center section.coverage-section .fui-table-response.coverage-list .level-5 {
      background: #ffff00 !important; }
    .is-eservice.is-help-center section.coverage-section .fui-table-response.coverage-list .level-6 {
      background: #ff0000 !important; }
    .is-eservice.is-help-center section.coverage-section .fui-table-response.coverage-list .level-7 {
      background: #aaaaaa !important; }
  .is-eservice.is-help-center section.coverage-section .search-bar {
    background: #eff1f2;
    padding: 15px;
    margin: 0 -15px;
    height: auto; }
    .is-eservice.is-help-center section.coverage-section .search-bar .fui-select .fui-dropdown.is-button button {
      background: none;
      border: 0; }
    .is-eservice.is-help-center section.coverage-section .search-bar form {
      position: relative; }
    .is-eservice.is-help-center section.coverage-section .search-bar .form-group {
      margin-bottom: 0; }
    .is-eservice.is-help-center section.coverage-section .search-bar .fui-button {
      min-width: 0;
      width: 100%;
      margin: 0; }

.is-eservice.is-help-center .map-contianer {
  margin: 0 -15px;
  display: block;
  height: 740px;
  background: #fafafa; }

@media screen and (max-width: 768px) {
  .is-eservice .fui-collapse.is-open .collapse-body {
    margin-bottom: 0; }
  .is-eservice .fui-collapse.is-open .collapse-header {
    margin-bottom: 0; }
  .is-eservice .fui-collapse.is-collapse-checkbox .collapse-body {
    max-height: 240px;
    margin: 0 15px; }
    .is-eservice .fui-collapse.is-collapse-checkbox .collapse-body article {
      padding: 20px 12px; }
  .is-eservice.is-help-center .map-contianer {
    height: 320px; }
  .is-eservice.is-help-center section.coverage-section {
    padding-top: 30px;
    background: url(/DigService/resources/cbu/e-service/images/help-center-sm.png) no-repeat;
    background-size: auto 98px;
    background-position: top right; }
    .is-eservice.is-help-center section.coverage-section .fui-table-response.coverage-list {
      margin-left: -15px;
      margin-right: -15px; }
      .is-eservice.is-help-center section.coverage-section .fui-table-response.coverage-list div[class*="level-"] {
        width: 48px;
        height: 9px;
        margin: 0 auto; }
      .is-eservice.is-help-center section.coverage-section .fui-table-response.coverage-list table tr th {
        min-width: 77px;
        background: #f1f3f5;
        border: 2px solid #E8ECF0; }
      .is-eservice.is-help-center section.coverage-section .fui-table-response.coverage-list table tr td {
        width: 97px; }
      .is-eservice.is-help-center section.coverage-section .fui-table-response.coverage-list table tr th,
      .is-eservice.is-help-center section.coverage-section .fui-table-response.coverage-list table tbody tr td {
        font-size: 12px;
        padding: 14px 4px; } }

.react-datepicker-wrapper input {
  background: url(/DigService/resources/cbu/e-service/images/icon-calendar.svg) 96% 9px no-repeat; }

.datepicker-divider {
  margin: 0 16px;
  font-size: 20px;
  line-height: 50px;
  font-weight: 600; }

.login-paper {
  padding: 60px;
  background: #FFFFFF;
  margin-bottom: 12px;
  box-shadow: 0 6px 12px 0 rgba(75, 88, 105, 0.12); }
  .login-paper .login-ad {
    width: 100%;
    height: 478px;
    padding: 60px;
    background: url(/DigService/resources/cbu/e-service/images/bg-login-ad.png) no-repeat; }
  .login-paper .tooltip-container + .form-group {
    width: 100%;
    position: relative;
    top: -23px; }

.fui-article-panel-button .fui-button {
  margin-right: 0;
  color: #EF3123 !important;
  position: relative; }
  .fui-article-panel-button .fui-button .text {
    color: #EF3123;
    opacity: 1 !important; }
  .fui-article-panel-button .fui-button:after {
    width: 30px !important;
    height: 30px !important;
    position: absolute !important;
    left: 95% !important;
    transform: translateX(100%) !important;
    content: "" !important;
    background: url(/DigService/resources/common/images/btn-is-arrow.svg) no-repeat !important; }

.roaming-plan {
  padding-top: 60px;
  background-image: url(/DigService/resources/cbu/img/bg-cbu-fcoin-pattern-1920-x-1200@2x.png);
  background-size: 100%;
  width: 100%;
  min-height: 600px;
  background-repeat-y: no-repeat; }
  .roaming-plan.no-bg {
    background: none; }
  .roaming-plan .checkbox input + .content {
    font-size: 1rem; }
  .roaming-plan .sub-head {
    display: flex;
    margin-bottom: 1.5rem; }
  .roaming-plan .search-bar .search-head .fui-switch {
    margin-right: 24px; }
  .roaming-plan .main-card {
    overflow: visible; }
    .roaming-plan .main-card .fui-cards {
      padding: 0; }
    .roaming-plan .main-card .fui-card-title {
      font-size: 24px; }
    .roaming-plan .main-card .left-card,
    .roaming-plan .main-card .right-card {
      overflow: visible;
      margin: 0; }
      .roaming-plan .main-card .left-card .fui-card,
      .roaming-plan .main-card .right-card .fui-card {
        margin: 0; }
      .roaming-plan .main-card .left-card .fui-card-content,
      .roaming-plan .main-card .right-card .fui-card-content {
        padding: 64px 24px;
        min-height: 304px; }
      .roaming-plan .main-card .left-card .fui-card-extra,
      .roaming-plan .main-card .right-card .fui-card-extra {
        padding: 0;
        margin: 0; }
    .roaming-plan .main-card .left-card .fui-card-title {
      margin-bottom: 6px; }
    .roaming-plan .main-card .right-card {
      margin-right: 0; }
      .roaming-plan .main-card .right-card .fui-card-title {
        margin-bottom: 30px; }
      .roaming-plan .main-card .right-card .fui-card-content {
        background: url(/DigService/resources/cbu/e-service/images/bg-login-ad.png) no-repeat;
        background-size: 102%; }
  .roaming-plan .service-entry .animate-red-arrow {
    display: none; }
  .roaming-plan section.service-entry {
    padding-bottom: 30px; }
  .roaming-plan .action-arrow {
    border-left: 1px solid #D6D6D6;
    padding-left: 15px;
    margin-left: 15px;
    font-size: 16px; }
    .roaming-plan .action-arrow span {
      display: block; }
    .roaming-plan .action-arrow img {
      vertical-align: middle; }
  .roaming-plan .is-apply-card {
    padding-bottom: 10px !important; }
    .roaming-plan .is-apply-card .fui-card {
      margin-bottom: 0; }
    .roaming-plan .is-apply-card .action-arrow {
      border-left: 0;
      display: flex;
      justify-content: flex-end; }
  .roaming-plan .keyword-container {
    margin-top: 0;
    margin-right: 0; }
    .roaming-plan .keyword-container .keyword-list .keyword-content .keyword-group {
      white-space: normal; }
    .roaming-plan .keyword-container .keyword-list .label {
      display: none; }
    .roaming-plan .keyword-container .keyword-list .fui-button.is-label {
      margin-bottom: 10px;
      min-width: auto; }
      .roaming-plan .keyword-container .keyword-list .fui-button.is-label.is-active {
        border: solid 2px #202020; }
  .roaming-plan.roaming-plan-search {
    padding-top: 100px;
    padding-bottom: 0;
    background-repeat: no-repeat; }
    .roaming-plan.roaming-plan-search .fui-faq-collapse .fui-tab {
      overflow-x: auto; }
  .roaming-plan.roaming-plan-apply a.hover-underline-link {
    color: #202020 !important; }
    .roaming-plan.roaming-plan-apply a.hover-underline-link:after {
      display: none !important; }
    .roaming-plan.roaming-plan-apply a.hover-underline-link:hover:after {
      display: none !important; }
  .roaming-plan.roaming-plan-apply .fui-panel .text,
  .roaming-plan.roaming-plan-apply .fui-panel p {
    font-size: 16px; }

.menu-autocomplete .fui-item {
  color: #202020 !important; }
  .menu-autocomplete .fui-item:after {
    display: none !important; }
  .menu-autocomplete .fui-item:hover, .menu-autocomplete .fui-item:focus {
    color: #f05c38 !important;
    opacity: 1 !important; }
    .menu-autocomplete .fui-item:hover:after, .menu-autocomplete .fui-item:focus:after {
      display: none !important; }

.menu-autocomplete .menu-header .fui-tab .fui-tab-item {
  display: block; }

.menu-autocomplete .fui-menu-body {
  position: absolute;
  top: 50px;
  left: 0;
  background: #fff;
  box-shadow: 0 16px 32px 0 rgba(75, 88, 105, 0.3); }
  .menu-autocomplete .fui-menu-body .menu-header {
    background: #fafafa; }
    .menu-autocomplete .fui-menu-body .menu-header .menu-content {
      flex: 1 1;
      flex-grow: 1;
      padding-top: 0; }
      .menu-autocomplete .fui-menu-body .menu-header .menu-content .fui-item {
        padding: 12px 24px;
        margin-bottom: 0 !important; }
        .menu-autocomplete .fui-menu-body .menu-header .menu-content .fui-item.is-active {
          background: #fff; }
  .menu-autocomplete .fui-menu-body .menu-body .menu-content {
    max-height: 300px;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 0 30px 0 30px; }
    .menu-autocomplete .fui-menu-body .menu-body .menu-content .category-hot {
      margin-top: 1rem;
      margin-bottom: 0; }
    .menu-autocomplete .fui-menu-body .menu-body .menu-content .category-all {
      margin-top: 2rem;
      margin-bottom: 0; }
    .menu-autocomplete .fui-menu-body .menu-body .menu-content .fui-item {
      padding: 12px 0; }

.fui-nav-anchor.is-fixed + .fui-breadcrumb-container + .fui-banner + .origin-number-roaming {
  margin-top: 45px !important; }

.origin-number-roaming .fui-horzonal-cards .section-bg {
  display: none; }

.origin-number-roaming.with-bg {
  background: url(/DigService/resources/cbu/e-service/images/bg-ribbon.png) repeat-y top left;
  background-size: 100%;
  width: 100%; }

.origin-number-roaming.index {
  padding-bottom: 0; }
  .origin-number-roaming.index .overflow-hidden .fui-horzonal-cards .fui-container {
    width: 100%;
    overflow: hidden; }
  .origin-number-roaming.index .is-section-feature {
    padding-top: 0; }

.origin-number-roaming.prepaid {
  padding-bottom: 0; }
  .origin-number-roaming.prepaid section.apply-steps,
  .origin-number-roaming.prepaid .apply-steps {
    background-size: cover;
    background-position: top left;
    background-repeat: no-repeat; }

.origin-number-roaming.country .other-info ul li {
  display: inline-flex;
  width: 33%; }

.origin-number-roaming.country .empty-block {
  margin-bottom: 60px;
  padding: 30px;
  background: #f1f3f5; }

.origin-number-roaming.remind .fui-tab-pane {
  margin: 12px 0; }
  .origin-number-roaming.remind .fui-tab-pane .paper:last-child {
    margin-bottom: 12px; }

.origin-number-roaming.remind figure figcaption {
  display: block; }

.origin-number-roaming.local-card .card-multi-step {
  padding-bottom: 0 !important;
  background: #f0f1f3; }

.origin-number-roaming.buy-local-card .fui-banner {
  padding-right: 0 !important; }
  .origin-number-roaming.buy-local-card .fui-banner .caption .subtitle, .origin-number-roaming.buy-local-card .fui-banner .caption .fui-card.box .fui-card-content .fui-card-title, .fui-card.box .fui-card-content .origin-number-roaming.buy-local-card .fui-banner .caption .fui-card-title {
    margin-bottom: 36px !important; }

.life-circle {
  padding-top: 0 !important;
  background: url(/DigService/resources/cbu/life-circle-drama/images/background-pattern-light.png) repeat-y;
  background-size: 100% !important; }
  .life-circle .w-100 {
    width: 100%; }
  .life-circle .has-more-tab {
    font-size: 18px; }
    .life-circle .has-more-tab .fui-dropdown button {
      font-size: 18px; }
    .life-circle .has-more-tab .fui-dropdown .fui-menu .fui-item:hover, .life-circle .has-more-tab .fui-dropdown .fui-menu .fui-item:focus {
      opacity: 1;
      background: #fafafa !important; }
      .life-circle .has-more-tab .fui-dropdown .fui-menu .fui-item:hover .content, .life-circle .has-more-tab .fui-dropdown .fui-menu .fui-item:focus .content {
        color: #d3060f; }
    .life-circle .has-more-tab .fui-dropdown .fui-menu .fui-item.active .content {
      color: #202020; }
    .life-circle .has-more-tab .fui-dropdown .fui-menu .fui-item.active:hover .content, .life-circle .has-more-tab .fui-dropdown .fui-menu .fui-item.active:focus .content {
      color: #d3060f; }
  .life-circle .vertical-anchor-nav {
    position: fixed;
    top: 25%;
    right: 0;
    display: flex;
    flex-direction: column;
    z-index: 4;
    padding-left: 0; }
    .life-circle .vertical-anchor-nav li {
      list-style: none; }
    .life-circle .vertical-anchor-nav li,
    .life-circle .vertical-anchor-nav a {
      position: relative;
      width: 140px;
      height: 60px; }
      .life-circle .vertical-anchor-nav li span,
      .life-circle .vertical-anchor-nav a span {
        display: none; }
      .life-circle .vertical-anchor-nav li:before, .life-circle .vertical-anchor-nav li:after,
      .life-circle .vertical-anchor-nav a:before,
      .life-circle .vertical-anchor-nav a:after {
        content: "";
        position: absolute; }
      .life-circle .vertical-anchor-nav li:before,
      .life-circle .vertical-anchor-nav a:before {
        top: 15px;
        right: 20px;
        width: 10px;
        height: 10px;
        border-radius: 10px;
        background: #f05c38; }
      .life-circle .vertical-anchor-nav li:after,
      .life-circle .vertical-anchor-nav a:after {
        top: 36px;
        right: 25px;
        height: 30px;
        border-left: 1px solid #ff644d; }
      .life-circle .vertical-anchor-nav li:last-child:after,
      .life-circle .vertical-anchor-nav a:last-child:after {
        display: none; }
      .life-circle .vertical-anchor-nav li.active,
      .life-circle .vertical-anchor-nav a.active {
        background: url(/DigService/resources/cbu/life-circle-drama/images/time-trangle.svg) no-repeat; }
        .life-circle .vertical-anchor-nav li.active span,
        .life-circle .vertical-anchor-nav a.active span {
          display: block;
          color: #fff;
          font-size: 16px;
          letter-spacing: 0.2px;
          line-height: 28px;
          position: relative;
          top: 6px;
          padding-right: 40px;
          text-align: right; }
        .life-circle .vertical-anchor-nav li.active:before,
        .life-circle .vertical-anchor-nav a.active:before {
          background: #fff; }
  .life-circle section.fui-section-promo .fui-container {
    padding: 0 15px; }
  .life-circle .fui-tab-pane {
    padding-top: 12px; }
    .life-circle .fui-tab-pane img {
      max-width: 100%; }
    .life-circle .fui-tab-pane .MuiGrid-container .MuiGrid-item + .MuiGrid-item {
      padding: 20px 30px 20px 40px;
      background: #eff1f2;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column; }
    .life-circle .fui-tab-pane .MuiGrid-container h2.is-text-regular {
      margin-bottom: 90px; }
  .life-circle .fui-bancard .MuiGrid-container .MuiGrid-item + .MuiGrid-item {
    padding: 20px 30px 20px 40px;
    background: #eff1f2;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column; }
  .life-circle .fui-bancard .fui-bancard-image {
    display: flex; }
    .life-circle .fui-bancard .fui-bancard-image img {
      width: 100%; }
  .life-circle .fui-bancard .fui-bancard-content {
    width: 100%; }
  .life-circle .fui-bancard .checklist {
    margin-bottom: 90px; }
    .life-circle .fui-bancard .checklist img {
      margin-right: 5px;
      vertical-align: bottom; }
  .life-circle section.fui-breadcrumb-container {
    top: 145px; }
  .life-circle .h-0 {
    height: 0 !important; }
  .life-circle .banner {
    position: relative;
    height: 470px; }
    .life-circle .banner .img {
      position: relative; }
    .life-circle .banner .text {
      position: relative;
      z-index: 2;
      transition-delay: 1s; }
    .life-circle .banner.img-right:before {
      content: "";
      position: absolute;
      top: -1px;
      left: 0;
      width: 0;
      height: 472px;
      background: white;
      background: linear-gradient(270deg, white 0%, #fafafa 100%);
      clip-path: polygon(0 0, 57% 0, 100% 100%, 0% 100%);
      z-index: 2;
      transition: all 0.5s ease; }
    .life-circle .banner.img-right .img img {
      position: absolute;
      right: 0; }
    .life-circle .banner.img-right .text {
      top: 90px;
      opacity: 0;
      transition-delay: 1s;
      transition: all 1s ease; }
    .life-circle .banner.img-left:before {
      content: "";
      position: absolute;
      top: -1px;
      right: 0;
      width: 0;
      height: 472px;
      background: white;
      background: linear-gradient(90deg, white 0%, #fafafa 100%);
      clip-path: polygon(0 0, 100% 0, 100% 100%, 43% 100%);
      z-index: 2;
      transition: all 0.5s ease; }
    .life-circle .banner.img-left .text {
      top: -380px;
      opacity: 0;
      transition-delay: 1s;
      transition: all 1s ease; }
  .life-circle .visible .banner {
    transition: all 0.5s ease; }
    .life-circle .visible .banner.img-right:before {
      content: "";
      position: absolute;
      top: -1px;
      left: 0;
      width: 60%;
      height: 472px;
      background: white;
      background: linear-gradient(270deg, white 0%, #fafafa 100%);
      clip-path: polygon(0 0, 57% 0, 100% 100%, 0% 100%);
      z-index: 2;
      transition: all 0.5s ease; }
    .life-circle .visible .banner.img-right .img img {
      position: absolute;
      right: 0; }
    .life-circle .visible .banner.img-right .text {
      top: 90px;
      opacity: 1;
      transition-delay: 1s;
      transition: all 1s ease; }
    .life-circle .visible .banner.img-left:before {
      content: "";
      position: absolute;
      top: -1px;
      right: 0;
      width: 60%;
      height: 472px;
      background: white;
      background: linear-gradient(90deg, white 0%, #fafafa 100%);
      clip-path: polygon(0 0, 100% 0, 100% 100%, 43% 100%);
      z-index: 2;
      transition: all 0.5s ease; }
    .life-circle .visible .banner.img-left .text {
      opacity: 1;
      transition-delay: 1s;
      transition: all 1s ease; }
  .life-circle .more {
    padding: 48px 0 0 0; }
  .life-circle .fui-card {
    min-width: auto; }
    .life-circle .fui-card .fui-card-image {
      padding-bottom: 0; }
      .life-circle .fui-card .fui-card-image img {
        width: 100%;
        position: relative;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0; }
    .life-circle .fui-card .fui-card-content .fui-card-title .text {
      line-height: 1.6; }
  .life-circle .drama-list .horizontal-cards.slick-slider .slick-list .slick-slide {
    padding: 0 30px; }
  .life-circle .drama-list .horizontal-cards.slick-slider .slick-list .fui-card-content {
    padding: 12px 18px;
    background: #fff;
    min-height: 132px; }
  .life-circle .drama-list .horizontal-cards.slick-slider .slick-list .name {
    margin: 0 0 6px 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical; }
  .life-circle .drama-list .horizontal-cards.slick-slider .slick-list p {
    margin: 0;
    color: #8A8A8A; }
  .life-circle .justify-lg-flex-end {
    justify-content: flex-end; }
  .life-circle .horizontal-cards.slick-slider .slick-arrow.slick-prev {
    left: 92%; }
  .life-circle .horizontal-cards.slick-slider .fui-card .fui-card-description {
    margin-top: 0; }
  .life-circle .news-list .fui-card-subtitle {
    color: #8A8A8A; }
  .life-circle .songs-list {
    padding-bottom: 18px; }
    .life-circle .songs-list .fui-horzonal-cards:first-child {
      padding-bottom: 0 !important; }
    .life-circle .songs-list .fui-horzonal-cards:nth-child(2) {
      padding-top: 0 !important; }
    .life-circle .songs-list + .songs-list {
      padding-top: 0;
      padding-bottom: 60px; }
    .life-circle .songs-list .more {
      padding-top: 18px; }
    .life-circle .songs-list .horizontal-cards.slick-slider .slick-list .slick-slide {
      display: block; }
    .life-circle .songs-list .horizontal-cards.slick-slider .slick-list .fui-card {
      margin-right: 12px;
      margin-bottom: 12px; }
    .life-circle .songs-list .horizontal-cards.slick-slider .slick-list .fui-card-action {
      flex-direction: row;
      min-height: 0; }
    .life-circle .songs-list .horizontal-cards.slick-slider .slick-list .fui-card-image {
      width: auto; }
    .life-circle .songs-list .horizontal-cards.slick-slider .slick-list img {
      width: auto;
      margin: 0 !important; }
    .life-circle .songs-list .horizontal-cards.slick-slider .slick-list .fui-card-content {
      padding: 12px;
      position: relative; }
    .life-circle .songs-list .horizontal-cards.slick-slider .slick-list .fui-card-title {
      margin: 0;
      font-size: 18px; }
    .life-circle .songs-list .horizontal-cards.slick-slider .slick-list .fui-card-description {
      margin: 0;
      color: #8A8A8A; }
    .life-circle .songs-list .horizontal-cards.slick-slider .slick-list .fui-button {
      float: right;
      position: absolute;
      right: 12px;
      bottom: 10px;
      min-width: 0 !important;
      margin-bottom: 0 !important;
      height: 30px !important;
      padding: 0 18px !important;
      margin: 0 !important; }
      .life-circle .songs-list .horizontal-cards.slick-slider .slick-list .fui-button .text {
        height: 30px !important;
        line-height: 27px !important; }
  .life-circle .goods-list .fui-card-content {
    padding-bottom: 30px; }
  .life-circle .goods-list .fui-card-title {
    min-height: 60px; }
    .life-circle .goods-list .fui-card-title .text {
      font-size: 1.25rem !important; }
  .life-circle .goods-list .fui-card-price {
    margin: 0; }
  .life-circle .goods-list .fui-card-description {
    min-height: 56px; }
  .life-circle .tools-list .with-comma {
    position: relative;
    display: inline-block; }
    .life-circle .tools-list .with-comma:before, .life-circle .tools-list .with-comma:after {
      position: absolute;
      content: "";
      width: 30px;
      height: 30px;
      background: url(/DigService/resources/cbu/life-circle-drama/images/quotation-white.svg) no-repeat; }
    .life-circle .tools-list .with-comma.is-text-white:before, .life-circle .tools-list .with-comma.is-text-white:after {
      background: url(/DigService/resources/cbu/life-circle-drama/images/quotation-white.svg) no-repeat; }
    .life-circle .tools-list .with-comma:before {
      top: -10px;
      left: -35px;
      transform: rotate(180deg); }
    .life-circle .tools-list .with-comma:after {
      bottom: 0;
      right: -30px; }
  .life-circle .tools-list .border-bottom {
    position: relative;
    display: inline-block;
    margin: 0 5px; }
    .life-circle .tools-list .border-bottom:after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      border-bottom: 12px solid rgba(240, 92, 56, 0.4); }
    .life-circle .tools-list .border-bottom.is-blue30:after {
      border-bottom: 21px solid rgba(155, 223, 223, 0.4); }
    .life-circle .tools-list .border-bottom.is-green30:after {
      border-bottom: 21px solid rgba(0, 255, 218, 0.4); }
  @media screen and (max-width: 960px) {
    .life-circle .tools-list .border-bottom.is-blue30:after {
      border-bottom: 19px solid rgba(155, 223, 223, 0.4); } }
  .life-circle .tools-list .tools-item {
    padding: 50px 50px 0 50px; }
    .life-circle .tools-list .tools-item .MuiGrid-container {
      justify-content: center; }
    .life-circle .tools-list .tools-item .MuiGrid-item {
      width: calc(100% / 7);
      text-align: center; }
      .life-circle .tools-list .tools-item .MuiGrid-item img {
        margin-bottom: 10px;
        transition: all 0.6s;
        position: relative;
        top: 0; }
      .life-circle .tools-list .tools-item .MuiGrid-item .body-1 {
        color: #8A8A8A; }
    .life-circle .tools-list .tools-item .slick-slider .slick-list .slick-slide {
      padding: 0 20px; }
    .life-circle .tools-list .tools-item .slide img {
      transition: transform 0.3s ease; }
    .life-circle .tools-list .tools-item .slide:hover img {
      transform: translateY(-6px); }
  .life-circle .promo-list .fui-container {
    width: 100%;
    padding: 0;
    overflow: hidden; }
  .life-circle .promo-list .section-title {
    margin-bottom: 0 !important; }
  .life-circle .promo-list .horizontal-cards.slick-slider {
    padding-top: 36px; }
    .life-circle .promo-list .horizontal-cards.slick-slider .slick-arrow.slick-prev,
    .life-circle .promo-list .horizontal-cards.slick-slider .slick-arrow.slick-next {
      top: -36px; }
  .life-circle .circle-list .fui-card-image,
  .life-circle .circle-list .fui-card-content {
    max-width: 300px; }
  .life-circle .circle-list img {
    max-width: 300px; }
  .life-circle .circle-list .fui-card-content {
    padding: 24px 0 0 0; }
  .life-circle .circle-list .fui-card.fui-card-life-circle {
    background: none !important;
    box-shadow: none !important; }
  .life-circle .circle-list .fui-card-image {
    padding-bottom: 0; }
    .life-circle .circle-list .fui-card-image img {
      width: 100%;
      position: relative;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0; }
  .life-circle .circle-list .fui-card-caption {
    position: relative; }
    .life-circle .circle-list .fui-card-caption .fui-card-title {
      position: absolute;
      width: 300px;
      top: -160px;
      left: 0;
      text-align: center;
      color: #fff; }
  .life-circle .circle-list:hover, .life-circle .circle-list:focus {
    box-shadow: none !important; }
    .life-circle .circle-list:hover .fui-card-image img, .life-circle .circle-list:focus .fui-card-image img {
      transform: scale(1); }
  .life-circle .circle-list .fui-card-action,
  .life-circle .circle-list a.fui-card-action {
    background: none; }
    .life-circle .circle-list .fui-card-action:hover, .life-circle .circle-list .fui-card-action:focus,
    .life-circle .circle-list a.fui-card-action:hover,
    .life-circle .circle-list a.fui-card-action:focus {
      background: none; }
      .life-circle .circle-list .fui-card-action:hover .fui-card-image img, .life-circle .circle-list .fui-card-action:focus .fui-card-image img,
      .life-circle .circle-list a.fui-card-action:hover .fui-card-image img,
      .life-circle .circle-list a.fui-card-action:focus .fui-card-image img {
        transform: scale(1); }
  .life-circle.index section.fui-breadcrumb-container ul.fui-breadcrumb li,
  .life-circle.index section.fui-breadcrumb-container ul.fui-breadcrumb li a {
    color: #fff; }
  .life-circle.index section.fui-breadcrumb-container ul.fui-breadcrumb li::after {
    background: #fff; }
  .life-circle.index section:not(.fui-section-promo) {
    padding: 0px 0 60px; }
  .life-circle.index section.fui-breadcrumb-container {
    padding: 24px 0; }
  .life-circle.index .main-banner .slick-slide {
    min-width: 300px !important; }
  .life-circle.index .main-banner .fui-tab-pane {
    padding-top: 0; }
  .life-circle.index .main-banner .slide {
    position: relative; }
  .life-circle.index .main-banner .slick-arrow {
    width: 36px;
    height: 36px;
    position: absolute;
    z-index: 2;
    border: 0;
    background: none;
    text-indent: -9999px;
    cursor: pointer; }
  .life-circle.index .main-banner .slick-prev {
    top: 45%;
    left: 20px;
    background: url(/DigService/resources/cbu/life-circle-index/images/slick-prev.svg) no-repeat;
    transition: transform 0.3s ease; }
    .life-circle.index .main-banner .slick-prev:hover {
      transform: translateX(-10px); }
  .life-circle.index .main-banner .slick-next {
    top: 45%;
    right: 20px;
    background: url(/DigService/resources/cbu/life-circle-index/images/slick-next.svg) no-repeat;
    transition: transform 0.3s ease; }
    .life-circle.index .main-banner .slick-next:hover {
      transform: translateX(10px); }
  .life-circle.index .main-banner .slick-arrow-prev-wording,
  .life-circle.index .main-banner .slick-arrow-next-wording {
    position: relative;
    width: 100%;
    height: 100%;
    display: contents; }
    .life-circle.index .main-banner .slick-arrow-prev-wording .slick-list,
    .life-circle.index .main-banner .slick-arrow-next-wording .slick-list {
      max-width: 300px;
      max-height: 32px;
      position: absolute;
      z-index: 2; }
      .life-circle.index .main-banner .slick-arrow-prev-wording .slick-list p,
      .life-circle.index .main-banner .slick-arrow-next-wording .slick-list p {
        margin: 0;
        font-size: 18px;
        color: #fff; }
    .life-circle.index .main-banner .slick-arrow-prev-wording .slick-dots,
    .life-circle.index .main-banner .slick-arrow-next-wording .slick-dots {
      display: none !important; }
  .life-circle.index .main-banner .slick-arrow-prev-wording .slick-list {
    top: 46%;
    left: 60px; }
  .life-circle.index .main-banner .slick-arrow-next-wording .slick-list {
    top: 46%;
    right: 60px;
    text-align: right; }
  .life-circle.index .main-banner .img {
    height: 470px;
    position: relative;
    z-index: 1; }
    .life-circle.index .main-banner .img img {
      height: 470px;
      max-width: inherit; }
  .life-circle.index .main-banner .wording {
    width: 50%;
    margin: 0 25%;
    position: absolute;
    top: 65px;
    z-index: 2;
    text-align: left; }
    .life-circle.index .main-banner .wording .title {
      font-size: 56px;
      margin-bottom: 30px; }
    .life-circle.index .main-banner .wording .subtitle, .life-circle.index .main-banner .wording .fui-card.box .fui-card-content .fui-card-title, .fui-card.box .fui-card-content .life-circle.index .main-banner .wording .fui-card-title {
      margin-bottom: 48px; }
    .life-circle.index .main-banner .wording .with-comma {
      position: relative; }
      .life-circle.index .main-banner .wording .with-comma:before {
        content: "";
        position: absolute;
        top: -5px;
        left: -35px;
        width: 30px;
        height: 30px;
        background: url(/DigService/resources/cbu/life-circle-index/images/quotation.svg) no-repeat; }
    .life-circle.index .main-banner .wording .is-last {
      text-align: center;
      margin: 0 auto;
      padding-top: 140px; }
  .life-circle.index .main-banner .slick-dots {
    width: 100%;
    padding: 0;
    margin: 0;
    position: absolute;
    top: 80%;
    text-align: center; }
    .life-circle.index .main-banner .slick-dots li {
      margin: 0 3px;
      display: inline; }
      .life-circle.index .main-banner .slick-dots li button {
        border: 0;
        width: 50px;
        height: 4px;
        background: rgba(0, 0, 0, 0.5); }
      .life-circle.index .main-banner .slick-dots li.slick-active button {
        background: #fff; }
  .life-circle.index .main-banner + .fui-nav-tab {
    top: -40px;
    z-index: 12; }
  .life-circle.index .tools-list-carousel {
    padding-top: 20px !important; }
    .life-circle.index .tools-list-carousel .fui-container {
      width: 100%;
      padding: 0;
      overflow: hidden; }
      .life-circle.index .tools-list-carousel .fui-container .fui-container {
        padding: 0 15px; }
    .life-circle.index .tools-list-carousel .tools-item {
      padding: 0; }
      .life-circle.index .tools-list-carousel .tools-item .slide {
        text-align: center; }
      .life-circle.index .tools-list-carousel .tools-item img {
        margin: 0 auto 10px auto; }
      .life-circle.index .tools-list-carousel .tools-item .body-1 {
        color: #8A8A8A; }
      .life-circle.index .tools-list-carousel .tools-item .slick-slider .slick-list .slick-slide {
        padding: 0 15px; }
      .life-circle.index .tools-list-carousel .tools-item .slide img {
        transition: transform 0.3s ease; }
      .life-circle.index .tools-list-carousel .tools-item .slide:hover img {
        transform: translateY(-6px); }
  .life-circle.index .ad-carousel {
    padding-bottom: 40px; }
    .life-circle.index .ad-carousel .slick-slide .img img {
      width: 100%; }
    .life-circle.index .ad-carousel .img {
      display: flex; }
      .life-circle.index .ad-carousel .img img {
        min-height: 240px; }
    .life-circle.index .ad-carousel .wording {
      height: 100%;
      min-height: 240px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 30px 200px 30px 30px;
      position: relative;
      background: #ff644d;
      background: linear-gradient(0deg, #ff644d 0%, #d3060f 100%); }
      .life-circle.index .ad-carousel .wording:before {
        content: "";
        width: 340px;
        height: 240px;
        position: absolute;
        top: 0;
        left: 0;
        background: url(/DigService/resources/cbu/life-circle-index/images/path-copy-31.png) no-repeat;
        background-size: 100%; }
      .life-circle.index .ad-carousel .wording > div {
        z-index: 2; }
      .life-circle.index .ad-carousel .wording img {
        margin-right: 10px;
        display: inline;
        vertical-align: middle; }
    .life-circle.index .ad-carousel .slick-dots {
      text-align: center;
      margin: 0;
      position: relative;
      top: -50px; }
      .life-circle.index .ad-carousel .slick-dots li {
        margin: 0 3px;
        display: inline; }
        .life-circle.index .ad-carousel .slick-dots li button {
          border: 0;
          width: 50px;
          height: 4px;
          background: rgba(0, 0, 0, 0.5); }
        .life-circle.index .ad-carousel .slick-dots li.slick-active button {
          background: #fff; }
  .life-circle.index .fui-card .fui-card-content .fui-card-title {
    margin-top: 0; }
  .life-circle.index .coupon-list .slick-slide .fui-card .fui-card-content .fui-card-title .text {
    font-size: 20px; }
  .life-circle.index .coupon-list .slick-slide .fui-card .fui-card-content .fui-card-title .text,
  .life-circle.index .coupon-list .slick-slide .fui-card .fui-card-content .fui-card-title h5 {
    margin: 0; }
  .life-circle.index .fCoin-carousel .fui-card .fui-card-content {
    padding: 24px 24px 12px 24px; }
  .life-circle.index .fCoin-carousel .slick-slide .fui-card .fui-card-content .fui-card-title .text {
    font-size: 20px; }
  .life-circle.index .fCoin-carousel .slick-slide .fui-card .fui-card-content .fui-card-title .text,
  .life-circle.index .fCoin-carousel .slick-slide .fui-card .fui-card-content .fui-card-title h5 {
    margin: 0; }
  .life-circle.index .fCoin-carousel .slick-slide .fui-card-caption {
    padding-bottom: 40px; }
  .life-circle.index .fCoin-carousel .slick-slide .fui-card-price {
    position: absolute;
    bottom: 24px; }
    .life-circle.index .fCoin-carousel .slick-slide .fui-card-price img {
      margin-right: 10px;
      display: inline;
      vertical-align: middle; }
    .life-circle.index .fCoin-carousel .slick-slide .fui-card-price h6 {
      margin: 0;
      display: inline; }
  .life-circle.index .goods-list .slick-slide .tag {
    padding: 4px 0px 0px 12px;
    height: 28px;
    position: absolute;
    top: 0;
    left: 8px;
    font-size: 14px;
    z-index: 2; }
  .life-circle.index .goods-list .slick-slide .tag-green {
    color: #fff;
    background: #5f7b80; }
    .life-circle.index .goods-list .slick-slide .tag-green:after {
      content: "";
      position: relative;
      top: 24px;
      left: 28px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 28px 28px 0 0;
      border-color: #5f7b80 transparent transparent transparent; }
  .life-circle.curation .fui-tab-pane .extra-link-card.fui-card {
    width: 100%; }
  .life-circle.curation .fui-tab-pane .MuiGrid-container .MuiGrid-item + .MuiGrid-item {
    padding: 8px !important;
    background: none; }
  .life-circle.curation .fui-breadcrumb-container {
    margin-bottom: 0; }
  .life-circle.curation .extra-link-card .img-wrapper .periodic-content {
    padding: 0 0 10px 15px;
    min-width: 160px;
    height: 105px;
    background: url(/DigService/resources/cbu/life-circle-curation/images/cbu-article-label-bkg.png) no-repeat; }
    .life-circle.curation .extra-link-card .img-wrapper .periodic-content > div {
      position: absolute;
      bottom: 10px; }
  .life-circle.curation .group-extra-link-card .fui-card-extra .fui-button:hover, .life-circle.curation .group-extra-link-card .fui-card-extra .fui-button:focus {
    color: #f05c38; }
  .life-circle.curation .selected-article .head {
    background: url(/DigService/resources/cbu/life-circle-curation/images/cbu-article-sidebar-bkg.png) no-repeat;
    background-size: cover; }
  .life-circle.curation .fui-container .promotion-container {
    margin: 0; }
  .life-circle.curation #tech-content {
    padding-bottom: 20px !important; }
  .life-circle.article .fui-panel {
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-bottom: 0 !important;
    margin-bottom: 0;
    box-shadow: none; }
    .life-circle.article .fui-panel .fui-container {
      padding: 0 200px; }
    .life-circle.article .fui-panel img {
      max-width: 100%; }
  .life-circle.article .fui-container-article {
    padding: 0 200px; }
  .life-circle.article .article-banner {
    position: relative; }
    .life-circle.article .article-banner .img {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1; }
      .life-circle.article .article-banner .img img {
        height: 100%;
        position: relative;
        display: block; }
    .life-circle.article .article-banner .wording {
      position: relative;
      top: 0;
      left: 0;
      z-index: 2; }
    .life-circle.article .article-banner h1 {
      margin-bottom: 0; }
  .life-circle.article .article-panel {
    background: none; }
  .life-circle.article .article-intro-panel {
    padding-bottom: 60px !important;
    margin-bottom: 0;
    background: url(/DigService/resources/cbu/life-circle-article/images/article-opener-bkg.jpg) no-repeat;
    background-size: 100%;
    box-shadow: none; }
    .life-circle.article .article-intro-panel p {
      color: #5f7b80; }
  .life-circle.article figure.fui-figure {
    min-width: 0;
    margin-top: 0;
    margin-bottom: 36px;
    color: #5F6E84; }
    .life-circle.article figure.fui-figure:before {
      margin-top: 1px;
      border-bottom: 7px solid #EF3123;
      font-size: 18px; }
  .life-circle.article .article-author-tags-panel {
    padding-top: 30px !important;
    padding-bottom: 60px !important;
    background: none; }
    .life-circle.article .article-author-tags-panel .border-author {
      padding-left: 15px;
      margin-bottom: 48px;
      border-left: 6px solid #5f7b80;
      color: #5f7b80;
      font-size: 16px; }
    .life-circle.article .article-author-tags-panel .tags {
      display: flex;
      flex-direction: row; }
      .life-circle.article .article-author-tags-panel .tags .item {
        margin-right: 10px;
        padding: 6px 18px;
        color: #5F6E84;
        font-size: 16px;
        border: 2px solid #5F6E84;
        border-radius: 20px; }
        .life-circle.article .article-author-tags-panel .tags .item::after {
          display: none; }
  .life-circle.article .article-author-panel {
    min-height: 224px;
    padding-bottom: 60px !important;
    background: url(/DigService/resources/cbu/life-circle-article/images/article-author-bkg.jpg) no-repeat;
    background-size: cover; }
    .life-circle.article .article-author-panel .fui-container {
      display: flex; }
    .life-circle.article .article-author-panel .img,
    .life-circle.article .article-author-panel .wording {
      float: left; }
    .life-circle.article .article-author-panel .img {
      width: 120px; }
      .life-circle.article .article-author-panel .img img {
        max-width: 100px;
        height: auto; }
    .life-circle.article .article-author-panel .wording {
      width: calc(100% - 120px); }
      .life-circle.article .article-author-panel .wording * {
        color: #465a5e; }
      .life-circle.article .article-author-panel .wording p {
        margin-bottom: 0; }
  .life-circle.article .goods-list {
    padding-top: 100px !important;
    padding-bottom: 60px !important; }
  .life-circle.article .article-share-panel {
    padding-bottom: 60px !important;
    background: none; }
    .life-circle.article .article-share-panel .fui-container {
      display: flex;
      flex-direction: row;
      align-items: center; }
    .life-circle.article .article-share-panel .img,
    .life-circle.article .article-share-panel .wording {
      float: left; }
    .life-circle.article .article-share-panel a:hover:after,
    .life-circle.article .article-share-panel a:focus:after {
      display: none !important; }
  .life-circle.article .highlight.border-bottom {
    position: relative;
    display: inline-block;
    margin: 0 5px; }
    .life-circle.article .highlight.border-bottom:after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      border-bottom: 12px solid rgba(240, 92, 56, 0.4); }
  .life-circle.article iframe {
    width: 100%;
    height: auto;
    min-height: 400px;
    margin: 0; }
  .life-circle.article .article-citation-panel {
    margin-top: 60px;
    padding-bottom: 60px !important;
    background: #eff1f2; }
    .life-circle.article .article-citation-panel .single-comma {
      position: relative;
      top: 0;
      left: 0;
      padding-left: 52px; }
      .life-circle.article .article-citation-panel .single-comma:before {
        content: "";
        position: absolute;
        top: -20px;
        left: 0;
        width: 48px;
        height: 48px;
        background: url(/DigService/resources/cbu/life-circle-article/images/article-quote-mark.svg) no-repeat; }
  .life-circle .icon-facebook-share {
    width: 48px;
    height: 48px;
    display: block;
    background: url(/DigService/resources/common/images/facebook-share.svg) no-repeat; }
  .life-circle .icon-line-share {
    width: 48px;
    height: 48px;
    display: block;
    background: url(/DigService/resources/common/images/line-share.svg) no-repeat; }

@media screen and (max-width: 1440px) {
  .life-circle .banner.img-right .img img {
    right: -200px; }
  .life-circle .banner.img-left .img img {
    position: relative;
    left: -200px; }
  .life-circle .visible .banner.img-right .img img {
    right: -200px; }
  .life-circle .visible .banner.img-left .img img {
    position: relative;
    left: -200px; }
  .life-circle .drama-list .horizontal-cards.slick-slider .slick-list .slick-slide {
    padding: 0 20px; }
  .life-circle.article .article-banner .img img {
    left: -200px; } }

@media screen and (max-width: 1280px) {
  .life-circle .banner.img-right .img img {
    right: -300px; }
  .life-circle .banner.img-left .img img {
    position: relative;
    left: -300px; }
  .life-circle .visible .banner.img-right .img img {
    right: -300px; }
  .life-circle .visible .banner.img-left .img img {
    position: relative;
    left: -300px; }
  .life-circle .drama-list .horizontal-cards.slick-slider .slick-list .slick-slide {
    padding: 0 20px; }
  .life-circle.article .article-banner .img img {
    left: -300px; }
  .life-circle.article .article-author-tags-panel .border-author {
    margin-bottom: 36px; }
  .life-circle.article .article-author-tags-panel .tags {
    display: inline-block; }
    .life-circle.article .article-author-tags-panel .tags .item {
      margin: 0 10px 10px 0;
      float: left; }
  .roaming-plan .main-card .left-card .fui-card-content,
  .roaming-plan .main-card .right-card .fui-card-content {
    min-height: 340px;
    height: 100%; }
  .roaming-plan .main-card .right-card .fui-card-content {
    background-size: cover; }
  .origin-number-roaming.prepaid section.apply-steps,
  .origin-number-roaming.prepaid .apply-steps {
    background-position: top center; } }

@media screen and (max-width: 960px) {
  .life-circle section.fui-breadcrumb-container {
    display: block;
    position: absolute;
    z-index: 29;
    top: 85px !important; }
  .life-circle .horizontal-cards.slick-slider .slick-arrow.slick-prev {
    left: 85%; }
  .life-circle.article .fui-panel .fui-container {
    padding: 0 15px; }
  .life-circle.article .article-panel .fui-container {
    padding: 0 15px; }
  .life-circle.article .fui-container-article {
    padding: 0 15px; }
  .life-circle.article .article-author-tags-panel .tags {
    display: inline-block; }
    .life-circle.article .article-author-tags-panel .tags .item {
      margin: 0 10px 10px 0;
      float: left; }
  .life-circle.index .main-banner .wording .title {
    font-size: 42px;
    margin-bottom: 30px; }
  .life-circle.index .main-banner .wording .subtitle, .life-circle.index .main-banner .wording .fui-card.box .fui-card-content .fui-card-title, .fui-card.box .fui-card-content .life-circle.index .main-banner .wording .fui-card-title {
    margin-bottom: 30px; }
  .life-circle.index .main-banner .wording .is-last {
    padding-top: 120px; }
  .life-circle.index .main-banner .img {
    position: relative;
    overflow: hidden; }
    .life-circle.index .main-banner .img img {
      position: absolute;
      left: -300px; }
  .life-circle.index .main-banner + .fui-nav-tab {
    top: 0; }
  .life-circle.curation .pb-md-0 {
    padding-bottom: 0; }
  .life-circle.curation .has-more-tab .tab-wrapper .tab {
    padding: 11px 12px; }
  .life-circle.curation .fui-container .promotion-container {
    margin: 0 -15px; }
  .life-circle.curation .info {
    padding-top: 20px !important; }
  .life-circle.curation .section-promotion-5 .mobile {
    background-position: center top; }
    .life-circle.curation .section-promotion-5 .mobile .promotion-button {
      width: 100%;
      margin-top: 0; }
  .life-circle.curation .load-more {
    margin: 30px 0; }
  .roaming-plan .main-card .left-card .fui-card-content,
  .roaming-plan .main-card .right-card .fui-card-content {
    min-height: auto;
    height: 100%; }
  .roaming-plan .main-card .right-card .fui-card {
    margin-bottom: 20px; }
  .roaming-plan .main-card .right-card .fui-card-content {
    background-size: cover; }
  .roaming-plan .fui-cards.is-switch-card {
    margin: 12px 0;
    padding: 0 0px 48px 0px; }
    .roaming-plan .fui-cards.is-switch-card .fui-card {
      margin-left: 0;
      margin-right: 0; }
  .roaming-plan .fui-cards.is-apply-card {
    margin: 12px 0;
    padding: 0 0px 48px 0px; }
    .roaming-plan .fui-cards.is-apply-card .fui-card {
      margin-left: 0;
      margin-right: 0; }
  .origin-number-roaming.country .other-info ul li {
    width: 49%; }
  .service-entry .fui-cards.four-card {
    margin: 0;
    padding: 0 0px 48px 0px; }
    .service-entry .fui-cards.four-card .fui-card {
      margin-left: 0;
      margin-right: 20px;
      width: calc(25% - 15px); }
      .service-entry .fui-cards.four-card .fui-card:last-child {
        margin-right: 0; } }

@media screen and (max-width: 768px) {
  .react-datepicker-popper,
  .react-datepicker__month-container {
    max-width: calc(100vw - 40px) !important; }
  .datepicker-divider {
    width: 100%;
    margin: 0;
    text-align: center;
    line-height: 20px;
    position: relative;
    top: -16px; }
  .parkingfeePage .scroll-table {
    width: calc(160vw); }
  .roaming-plan {
    padding-top: 30px; }
    .roaming-plan .main-card .left-card,
    .roaming-plan .main-card .right-card {
      margin: 0; }
      .roaming-plan .main-card .left-card .fui-card,
      .roaming-plan .main-card .right-card .fui-card {
        margin: 0; }
      .roaming-plan .main-card .left-card .fui-card-content,
      .roaming-plan .main-card .right-card .fui-card-content {
        padding: 30px 20px;
        min-height: auto; }
    .roaming-plan .main-card .right-card {
      margin-bottom: 10px !important; }
    .roaming-plan .is-apply-card .action-arrow {
      padding-left: 0;
      margin-left: 0; }
    .roaming-plan .is-switch-card .search-bar .search-head {
      justify-content: flex-end; }
    .roaming-plan .menu-autocomplete {
      z-index: 9; }
      .roaming-plan .menu-autocomplete .fui-menu-body {
        z-index: 9; }
        .roaming-plan .menu-autocomplete .fui-menu-body .menu-header .menu-content .fui-item {
          padding: 12px 15px; }
        .roaming-plan .menu-autocomplete .fui-menu-body .menu-body .menu-content {
          max-height: 400px;
          padding: 0 20px 12px 20px; }
    .roaming-plan section.service-entry {
      padding: 20px 0 0 0; }
    .roaming-plan.roaming-plan-search {
      padding-top: 40px; }
  .service-entry .fui-cards.four-card {
    margin: 0;
    padding: 0 0px 48px 0px; }
    .service-entry .fui-cards.four-card .fui-card {
      margin-left: 0;
      margin-right: 16px;
      width: calc(50% - 8px); }
      .service-entry .fui-cards.four-card .fui-card:nth-child(even) {
        margin-right: 0; }
  .life-circle {
    background: url(/DigService/resources/cbu/life-circle-drama/images/background-pattern-light-m.png) repeat-y; }
    .life-circle .banner.img-right img {
      right: -400px; }
    .life-circle .banner.img-left img {
      position: relative;
      left: -400px; }
    .life-circle .visible .banner.img-right img {
      right: 0; }
    .life-circle .visible .banner.img-left img {
      position: relative;
      left: 0; }
    .life-circle .vertical-anchor-nav {
      display: none !important; }
    .life-circle .horizontal-cards.slick-slider .slick-arrow.slick-prev {
      left: 85%; }
    .life-circle .fui-tab {
      overflow: scroll; }
      .life-circle .fui-tab a.fui-tab-item,
      .life-circle .fui-tab .fui-tab-item {
        padding: 6px 12px 12px; }
    .life-circle .fui-tab-pane .MuiGrid-container .MuiGrid-item + .MuiGrid-item {
      padding: 30px 20px; }
    .life-circle .fui-tab-pane .MuiGrid-container h2.is-text-regular {
      margin-bottom: 30px; }
    .life-circle .fui-tab-pane .MuiGrid-container .checklist {
      margin-bottom: 30px; }
    .life-circle .fui-tab-pane .MuiGrid-container .fui-button {
      margin-bottom: 0; }
    .life-circle .fui-bancard .MuiGrid-container .MuiGrid-item + .MuiGrid-item {
      padding: 30px 20px; }
    .life-circle .fui-bancard .checklist {
      margin-bottom: 30px; }
    .life-circle .fui-bancard .fui-button {
      margin-bottom: 0; }
    .life-circle section.fui-breadcrumb-container {
      display: none; }
    .life-circle .drama-list .horizontal-cards.slick-slider .slick-list .slick-slide {
      width: 100%;
      padding: 0 6px; }
    .life-circle .drama-list .fui-card-content {
      padding: 10px !important; }
    .life-circle .news-list .horizontal-cards.slick-slider .slick-list .slick-slide {
      width: 270px; }
    .life-circle .goods-list .horizontal-cards.slick-slider .slick-list .slick-slide,
    .life-circle .coupon-list .horizontal-cards.slick-slider .slick-list .slick-slide,
    .life-circle .fCoin-carousel .horizontal-cards.slick-slider .slick-list .slick-slide {
      width: 170px; }
    .life-circle .goods-list .fui-card-title .text,
    .life-circle .coupon-list .fui-card-title .text,
    .life-circle .fCoin-carousel .fui-card-title .text {
      font-size: 18px !important; }
    .life-circle .coupon-list .fui-card .fui-card-content {
      padding: 12px; }
    .life-circle .songs-list .horizontal-cards.slick-slider .slick-list .slick-slide {
      width: calc(100vw - 40px); }
    .life-circle .promo-list .horizontal-cards.slick-slider .slick-list .slick-slide {
      width: 270px; }
    .life-circle .tools-list {
      padding-bottom: 0; }
      .life-circle .tools-list .align-center {
        padding: 0 40px; }
      .life-circle .tools-list .tools-item {
        padding: 36px 24px;
        overflow: scroll; }
        .life-circle .tools-list .tools-item .MuiGrid-container {
          margin: 0 10px;
          overflow: scroll;
          width: calc(100vw);
          flex-wrap: nowrap; }
        .life-circle .tools-list .tools-item .MuiGrid-item {
          width: auto;
          min-width: 120px;
          margin: 0 15px; }
        .life-circle .tools-list .tools-item .slick-slider .slick-list .slick-slide {
          padding: 0 15px; }
    .life-circle .circle-list .fui-card-image,
    .life-circle .circle-list .fui-card-content {
      max-width: 200px; }
    .life-circle .circle-list .horizontal-cards.slick-slider .slick-list .slick-slide {
      padding-right: 20px; }
    .life-circle .circle-list .fui-card-caption .fui-card-title {
      width: 200px;
      top: -120px; }
    .life-circle.article .fui-panel {
      padding-top: 40px !important;
      padding-bottom: 0px !important; }
      .life-circle.article .fui-panel .fui-container {
        padding: 0 15px; }
    .life-circle.article .fui-article-panel-button .fui-button {
      opacity: 1 !important; }
      .life-circle.article .fui-article-panel-button .fui-button .text {
        opacity: 1 !important; }
      .life-circle.article .fui-article-panel-button .fui-button:after {
        left: 105% !important; }
    .life-circle.article figure {
      margin-bottom: 0; }
    .life-circle.article figure.fui-figure {
      margin-bottom: 0; }
    .life-circle.article .article-panel .fui-container {
      padding: 0 15px; }
    .life-circle.article .fui-container-article {
      padding: 0 15px; }
    .life-circle.article .article-banner .img img {
      left: -400px; }
    .life-circle.article .article-banner h1 {
      margin-bottom: 1.5rem; }
    .life-circle.article .article-intro-panel {
      padding: 40px 0 !important;
      background: url(/DigService/resources/cbu/life-circle-article/images/article-opener-bkg_m.jpg) no-repeat;
      background-size: cover; }
    .life-circle.article .article-gallery-panel {
      padding-bottom: 40px !important; }
    .life-circle.article .article-citation-panel {
      padding-bottom: 40px !important; }
      .life-circle.article .article-citation-panel .single-comma {
        padding-left: 0;
        top: 30px; }
        .life-circle.article .article-citation-panel .single-comma:before {
          top: -50px; }
    .life-circle.article .article-gallery-panel {
      padding-bottom: 40px !important; }
    .life-circle.article .article-author-tags-panel {
      padding-bottom: 40px !important; }
      .life-circle.article .article-author-tags-panel .tags {
        display: inline-block; }
        .life-circle.article .article-author-tags-panel .tags .item {
          margin: 0 10px 10px 0;
          float: left; }
    .life-circle.article .article-author-panel {
      padding-bottom: 40px !important;
      background: url(/DigService/resources/cbu/life-circle-article/images/bg-auther-m.png) no-repeat;
      background-size: cover; }
    .life-circle.article .article-share-panel {
      padding-bottom: 40px !important; }
    .life-circle.index section {
      padding: 0px 0 40px; }
    .life-circle.index .main-banner .img {
      height: 360px;
      position: relative;
      overflow: hidden; }
      .life-circle.index .main-banner .img img {
        height: 360px;
        position: absolute;
        left: -480px; }
    .life-circle.index .main-banner .wording {
      top: 35px;
      width: 80%;
      margin: 0 10%; }
      .life-circle.index .main-banner .wording .subtitle, .life-circle.index .main-banner .wording .fui-card.box .fui-card-content .fui-card-title, .fui-card.box .fui-card-content .life-circle.index .main-banner .wording .fui-card-title {
        text-overflow: ellipsis;
        display: block;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        white-space: normal;
        overflow: hidden; }
    .life-circle.index .main-banner .slick-prev {
      left: 0; }
    .life-circle.index .main-banner .slick-next {
      right: 0; }
    .life-circle.index .main-banner .slick-arrow-prev-wording,
    .life-circle.index .main-banner .slick-arrow-next-wording {
      display: none; }
    .life-circle.index .main-banner .slick-dots {
      top: 85%; }
      .life-circle.index .main-banner .slick-dots li button {
        width: 30px; }
    .life-circle.index .horizontal-cards.slick-slider .slick-list .slick-slide {
      padding: 0 6px; }
    .life-circle.index .fui-nav-tab.is-box-tab .fui-tab {
      overflow-x: scroll; }
      .life-circle.index .fui-nav-tab.is-box-tab .fui-tab .fui-tab-item {
        min-width: calc(100vw / 3.5); }
      .life-circle.index .fui-nav-tab.is-box-tab .fui-tab.with-title .fui-tab-item {
        background: none; }
    .life-circle.index .ad-carousel .slide .MuiGrid-container {
      position: relative; }
    .life-circle.index .ad-carousel .slide .MuiGrid-item + .MuiGrid-item {
      position: absolute;
      top: 0;
      left: 0; }
    .life-circle.index .ad-carousel .slide .img img {
      min-height: auto; }
    .life-circle.index .ad-carousel .slide .wording {
      min-height: auto;
      background: none;
      padding: 24px 30px;
      display: block; }
      .life-circle.index .ad-carousel .slide .wording:before {
        display: none; }
      .life-circle.index .ad-carousel .slide .wording h2 {
        margin-bottom: 10px; }
      .life-circle.index .ad-carousel .slide .wording .fui-button {
        margin-top: 10px; }
        .life-circle.index .ad-carousel .slide .wording .fui-button .text {
          line-height: 26px; }
    .life-circle.index .ad-carousel .slick-dots {
      padding: 0;
      top: -40px; }
    .life-circle.index .fCoin-carousel .fui-card .fui-card-content {
      padding: 12px 12px 0 12px; }
    .life-circle.index .fCoin-carousel .fui-card .fui-card-description {
      margin-bottom: 0; }
    .life-circle.index .fCoin-carousel .fui-card .fui-card-extra {
      padding-left: 12px; }
    .life-circle.index .coupon-list .fui-card .fui-card-description {
      margin-bottom: 0; }
    .life-circle.index .coupon-list .fui-card .fui-card-extra {
      padding-left: 12px; }
    .life-circle.index .goods-list .slick-slide .tag {
      display: none; }
    .life-circle section.fui-section-promo {
      padding: 30px 15px; } }

@media screen and (max-width: 600px) {
  .react-datepicker-popper,
  .react-datepicker__month-container {
    max-width: calc(100vw - 40px) !important; }
  .datepicker-divider {
    width: 100%;
    margin: 0;
    text-align: center;
    line-height: 20px;
    transform: rotate(90deg);
    position: relative;
    top: -16px; }
  .parkingfeePage .scroll-table {
    width: calc(160vw); }
  .login-paper {
    padding: 30px 15px; }
    .login-paper .login-ad {
      width: 100%;
      height: auto;
      padding: 30px;
      background-size: cover; }
  .life-circle section {
    padding: 30px 0; }
  .life-circle .justify-flex-start {
    justify-content: flex-start !important; }
  .life-circle .more {
    padding: 18px 0 0 0; }
  .life-circle section.fui-breadcrumb-container {
    display: none; }
  .life-circle .vertical-anchor-nav {
    display: none !important; }
  .life-circle .horizontal-cards.slick-slider .slick-arrow.slick-prev,
  .life-circle .horizontal-cards.slick-slider .slick-arrow.slick-next {
    right: -10px; }
  .life-circle .banner.img-right:before, .life-circle .banner.img-left:before {
    width: 100%;
    transition: all 0.5s ease;
    clip-path: polygon(0 0, 0% 0, 147% 100%, 0% 100%);
    background: linear-gradient(270deg, white 0%, #fafafa 100%); }
  .life-circle .banner.img-right .text {
    top: 140px; }
  .life-circle .banner.img-right .img img {
    right: -310px;
    top: 0px;
    height: 320px; }
  .life-circle .banner.img-left .text {
    top: -130px; }
  .life-circle .banner.img-left .img img {
    left: -190px;
    top: 0px;
    height: 320px;
    position: relative; }
  .life-circle .banner.main-banner.img-right .text {
    top: 45px !important; }
  .life-circle .visible .banner.img-right:before, .life-circle .visible .banner.img-left:before {
    width: 100%;
    transition: all 0.5s ease;
    clip-path: polygon(0 0, 0% 0, 147% 100%, 0% 100%);
    background: linear-gradient(270deg, white 0%, #fafafa 100%); }
  .life-circle .visible .banner.img-right .text {
    top: 140px; }
  .life-circle .visible .banner.img-right .img img {
    right: -310px;
    top: 0px;
    height: 320px; }
  .life-circle .visible .banner.img-left .text {
    top: -130px; }
  .life-circle .visible .banner.img-left .img img {
    left: -190px;
    top: 0px;
    height: 320px;
    position: relative; }
  .life-circle .visible .banner.main-banner.img-right .text {
    top: 45px !important; }
  .life-circle .fui-tab {
    overflow: scroll; }
    .life-circle .fui-tab a.fui-tab-item,
    .life-circle .fui-tab .fui-tab-item {
      padding: 6px 12px 12px; }
  .life-circle .drama-list .horizontal-cards.slick-slider .slick-arrow {
    display: none !important; }
    .life-circle .drama-list .horizontal-cards.slick-slider .slick-arrow .slick-slide {
      padding: 0 10px; }
  .life-circle .horizontal-cards.slick-slider .slick-arrow.slick-prev {
    left: 80%; }
  .life-circle .songs-list .horizontal-cards.slick-slider .slick-slide .fui-card {
    min-width: 100%; }
  .life-circle .goods-list .fui-card-content {
    padding: 10px 10px 20px 10px; }
  .life-circle .goods-list .fui-card-title .text {
    font-size: 18px !important; }
  .life-circle .tools-list {
    padding-bottom: 0; }
    .life-circle .tools-list .align-center {
      padding: 0 40px; }
    .life-circle .tools-list .tools-item {
      padding: 36px 24px;
      overflow: scroll; }
      .life-circle .tools-list .tools-item .MuiGrid-container {
        margin: 0 10px;
        overflow: scroll;
        width: calc(100vw);
        flex-wrap: nowrap; }
      .life-circle .tools-list .tools-item .MuiGrid-item {
        width: auto;
        min-width: 120px;
        margin: 0 15px; }
      .life-circle .tools-list .tools-item .slick-slider .slick-list .slick-slide {
        padding: 0 15px; }
  .life-circle .promo-list > .fui-container {
    padding: 0 15px; }
  .life-circle .circle-list .fui-card-image,
  .life-circle .circle-list .fui-card-content {
    max-width: 200px; }
  .life-circle .circle-list .horizontal-cards.slick-slider .slick-list .slick-slide {
    padding-right: 20px; }
  .life-circle .circle-list .fui-card-caption .fui-card-title {
    width: 200px;
    top: -120px; }
  .life-circle.article .article-author-tags-panel .tags {
    display: inline-block; }
    .life-circle.article .article-author-tags-panel .tags .item {
      margin: 0 10px 10px 0;
      float: left; }
  .life-circle.article iframe {
    min-height: 240px;
    width: auto; } }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .life-circle .visible .banner.img-right:before {
    left: -240px;
    transform: skew(35deg); }
  .life-circle .visible .banner.img-left:before {
    right: -150px;
    transform: skew(-35deg); } }

@supports (-ms-ime-align: auto) {
  .life-circle .visible .banner.img-right:before {
    left: -240px;
    transform: skew(35deg); }
  .life-circle .visible .banner.img-left:before {
    right: -150px;
    transform: skew(-35deg); } }

.is-eservice section.fui-login-section {
  background: url(/DigService/resources/cbu/e-service/images/img-cbu-arrow-pattern.png) no-repeat top 0px left 0px;
  background-size: 49px 70px;
  padding-top: 60px; }
  .is-eservice section.fui-login-section h2 {
    margin-top: 0; }
  .is-eservice section.fui-login-section .tooltip-container-box {
    position: relative; }
  .is-eservice section.fui-login-section .tooltip-container {
    right: 0;
    position: absolute; }
  .is-eservice section.fui-login-section form button.fui-button {
    width: 100%; }
  .is-eservice section.fui-login-section .fui-segments .fui-segment {
    background: none;
    padding: 0px; }
    .is-eservice section.fui-login-section .fui-segments .fui-segment .MuiGrid-root.MuiGrid-container {
      margin-top: 0;
      margin-bottom: 0; }
      .is-eservice section.fui-login-section .fui-segments .fui-segment .MuiGrid-root.MuiGrid-container .MuiGrid-root.MuiGrid-item {
        padding-top: 0;
        padding-bottom: 0; }
        .is-eservice section.fui-login-section .fui-segments .fui-segment .MuiGrid-root.MuiGrid-container .MuiGrid-root.MuiGrid-item:not(:first-child) {
          line-height: 0; }
    .is-eservice section.fui-login-section .fui-segments .fui-segment form {
      padding: 24px 20px; }
  .is-eservice section.fui-login-section .promotion {
    position: relative;
    line-height: 0; }
    .is-eservice section.fui-login-section .promotion img {
      width: 100%;
      line-height: 0; }
    .is-eservice section.fui-login-section .promotion .content {
      position: absolute;
      width: 100%;
      height: 100%;
      padding: 30px;
      left: 0;
      top: 0; }
      .is-eservice section.fui-login-section .promotion .content .fui-button {
        width: 100%; }
  .is-eservice section.fui-login-section .tooltip-container + .form-group {
    display: block; }
    .is-eservice section.fui-login-section .tooltip-container + .form-group .text-input {
      width: 100%; }
  .is-eservice section.fui-login-section .fui-tab-pane {
    padding-top: 10px; }

@media screen and (max-width: 768px) {
  .is-eservice section.fui-login-section ~ .fui-tooltip {
    right: 16px !important;
    left: auto !important;
    margin: auto !important; }
    .is-eservice section.fui-login-section ~ .fui-tooltip::before {
      left: auto;
      right: 28px; } }

@media screen and (min-width: 960px) {
  .is-eservice section.fui-login-section {
    background: url(/DigService/resources/cbu/e-service/images/img-cbu-arrow-pattern.png) no-repeat top 100px left 0px;
    background-size: 101px 144px;
    padding-top: 115px; }
    .is-eservice section.fui-login-section .fui-segments .fui-segment {
      padding: 60px; }
      .is-eservice section.fui-login-section .fui-segments .fui-segment form {
        padding: 0; }
        .is-eservice section.fui-login-section .fui-segments .fui-segment form button.fui-button {
          width: auto; }
    .is-eservice section.fui-login-section .promotion .content {
      padding: 60px; }
      .is-eservice section.fui-login-section .promotion .content .fui-button {
        width: auto; } }

main.rate-plan {
  position: relative; }
  main.rate-plan .fui-banner.is-product.is-style-2 .banner-img > div {
    background-position: right; }
  main.rate-plan .fui-banner.is-product .caption {
    padding-left: 0; }
    main.rate-plan .fui-banner.is-product .caption .subtitle, main.rate-plan .fui-banner.is-product .caption .fui-card.box .fui-card-content .fui-card-title, .fui-card.box .fui-card-content main.rate-plan .fui-banner.is-product .caption .fui-card-title {
      display: block;
      overflow: visible; }
  main.rate-plan .fui-banner .fui-container {
    width: 90%; }
  main.rate-plan .radio-group .radio input + .content::before {
    top: 24px; }
  main.rate-plan .radio-group .radio input + .content::after {
    top: 34px; }
  main.rate-plan .radio-group .radio input:checked + .content::before {
    border: 0; }
  main.rate-plan .fui-banner.is-product.is-style-2 {
    min-height: 0;
    min-height: initial;
    height: 240px; }
    main.rate-plan .fui-banner.is-product.is-style-2 .caption {
      top: 16px; }
  main.rate-plan .radio-group .radio.is-icon-box .content {
    padding: 24px 12px 24px 46px;
    height: 100%;
    border-color: #e8ecf0; }
  main.rate-plan .form-group .radio-group .radio {
    width: calc(50% - 12px); }
  main.rate-plan .form-group:nth-of-type(4) .radio {
    width: calc(25% - 12px); }
  main.rate-plan .result .result-wrapper {
    display: flex;
    flex-direction: row;
    box-shadow: 0 6px 12px 0 rgba(75, 88, 105, 0.12); }
    main.rate-plan .result .result-wrapper .left {
      flex-basis: 392px;
      background-image: url(/DigService/resources/cbu/e-service/images/result-bg.svg);
      background-position: right bottom;
      background-repeat: no-repeat;
      background-size: contain;
      background-color: #EFF4F1;
      padding: 60px 30px; }
      main.rate-plan .result .result-wrapper .left h5 {
        margin-top: 12px;
        margin-bottom: 18px; }
    main.rate-plan .result .result-wrapper .right {
      padding: 30px 60px;
      width: 100%;
      background-color: #FFFFFF; }
      main.rate-plan .result .result-wrapper .right h2 {
        color: #f05c38;
        margin-bottom: 16px; }
        main.rate-plan .result .result-wrapper .right h2 p {
          margin-bottom: 75px;
          margin-top: 0; }
        main.rate-plan .result .result-wrapper .right h2 a {
          margin-bottom: 30px; }
        main.rate-plan .result .result-wrapper .right h2 h6 {
          margin-top: 0;
          margin-bottom: 30px; }
  main.rate-plan .fui-panel-information {
    background-color: #EBEBEB;
    margin-top: 30px;
    padding-top: 60px;
    padding-bottom: 60px;
    color: #5F6E84; }
    main.rate-plan .fui-panel-information .fui-collapse {
      width: 90%;
      margin: 0 auto;
      padding: 0 15px; }
    main.rate-plan .fui-panel-information .collapse-header {
      max-width: 1080px;
      margin: 0 auto;
      font-size: 16px;
      font-weight: 500;
      padding-bottom: 30px; }
    main.rate-plan .fui-panel-information .collapse-body {
      max-width: 1080px;
      margin: 0 auto; }
      main.rate-plan .fui-panel-information .collapse-body article {
        margin: 0;
        padding: 0; }
        main.rate-plan .fui-panel-information .collapse-body article ol {
          padding-left: 18px;
          margin: 0; }
          main.rate-plan .fui-panel-information .collapse-body article ol li {
            font-size: 14px;
            line-height: 24px; }

@media screen and (max-width: 960px) {
  main.rate-plan .fui-banner.is-product.is-style-2 .caption {
    top: -16px; }
  main.rate-plan .form-group:nth-of-type(4) .radio {
    width: calc(50% - 12px); }
  main.rate-plan .result .result-wrapper {
    flex-direction: column; }
    main.rate-plan .result .result-wrapper .left {
      padding: 30px 20px;
      flex-basis: initial; }
    main.rate-plan .result .result-wrapper .right {
      padding: 30px 20px; }
  main.rate-plan .fui-panel-information {
    padding: 30px; }
    main.rate-plan .fui-panel-information .fui-collapse {
      padding: 0;
      width: 100%; }
  main.rate-plan .radio-group .radio input + .content::before {
    top: 0; }
  main.rate-plan .radio-group .radio input + .content::after {
    top: 0; } }

.fui-lifecircle-promotion {
  padding-top: 0; }
  .fui-lifecircle-promotion .slick-arrow {
    cursor: pointer; }
    .fui-lifecircle-promotion .slick-arrow.slick-prev, .fui-lifecircle-promotion .slick-arrow.slick-next {
      position: absolute;
      right: 0;
      top: -66px;
      border: 0;
      background: none;
      width: 36px;
      height: 36px;
      color: transparent; }
      .fui-lifecircle-promotion .slick-arrow.slick-prev.slick-disabled::after, .fui-lifecircle-promotion .slick-arrow.slick-next.slick-disabled::after {
        color: #D6D6D6; }
      .fui-lifecircle-promotion .slick-arrow.slick-prev::after, .fui-lifecircle-promotion .slick-arrow.slick-next::after {
        position: absolute;
        font-family: "icomoon" !important;
        speak: none;
        color: #202020;
        width: 28px;
        height: 28px;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        font-size: 28px;
        font-style: normal;
        font-weight: normal;
        font-feature-settings: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1; }
    .fui-lifecircle-promotion .slick-arrow.slick-prev {
      right: 40px; }
      .fui-lifecircle-promotion .slick-arrow.slick-prev::after {
        content: ""; }
    .fui-lifecircle-promotion .slick-arrow.slick-next::after {
      content: ""; }
  .fui-lifecircle-promotion .slick-track {
    transition-timing-function: ease-in-out !important; }
  .fui-lifecircle-promotion .slick-slider .slick-list {
    overflow: visible; }
    .fui-lifecircle-promotion .slick-slider .slick-list .slick-slide {
      padding: 0 12px 0 0;
      overflow: hidden;
      height: 430px; }
    .fui-lifecircle-promotion .slick-slider .slick-list a,
    .fui-lifecircle-promotion .slick-slider .slick-list a:link {
      position: relative;
      display: block;
      overflow: hidden;
      height: 430px; }
      .fui-lifecircle-promotion .slick-slider .slick-list a .bg,
      .fui-lifecircle-promotion .slick-slider .slick-list a:link .bg {
        position: relative; }
        .fui-lifecircle-promotion .slick-slider .slick-list a .bg img,
        .fui-lifecircle-promotion .slick-slider .slick-list a:link .bg img {
          transition: transform 0.3s ease-in-out;
          position: absolute;
          width: auto;
          height: 430px;
          left: -100%;
          right: -100%;
          margin: 0 auto; }
      .fui-lifecircle-promotion .slick-slider .slick-list a:hover .bg img,
      .fui-lifecircle-promotion .slick-slider .slick-list a:link:hover .bg img {
        transform: scale(1.1); }
      .fui-lifecircle-promotion .slick-slider .slick-list a .caption,
      .fui-lifecircle-promotion .slick-slider .slick-list a:link .caption {
        position: absolute;
        display: block;
        width: 100%;
        bottom: 0;
        margin: 0 auto;
        padding: 30px 20px;
        color: #FFFFFF; }
        .fui-lifecircle-promotion .slick-slider .slick-list a .caption .fui-button,
        .fui-lifecircle-promotion .slick-slider .slick-list a:link .caption .fui-button {
          margin: 0;
          width: 100%; }
        .fui-lifecircle-promotion .slick-slider .slick-list a .caption h2,
        .fui-lifecircle-promotion .slick-slider .slick-list a:link .caption h2 {
          margin-top: 0;
          margin-bottom: 12px; }
        .fui-lifecircle-promotion .slick-slider .slick-list a .caption h6,
        .fui-lifecircle-promotion .slick-slider .slick-list a:link .caption h6 {
          margin: 0 0 30px;
          font-weight: 400; }
  .fui-lifecircle-promotion .fui-lifecycle-icons {
    display: block;
    height: 150px; }
    .fui-lifecircle-promotion .fui-lifecycle-icons .lifecycle-icon-container {
      position: absolute;
      width: 100%;
      height: 160px;
      opacity: 0;
      z-index: 0;
      display: block;
      white-space: nowrap;
      overflow-x: auto;
      overflow-y: hidden;
      transition: opacity 0.3s ease;
      margin: 24px -15px 0;
      padding: 10px 15px 0;
      -webkit-overflow-scrolling: touch;
      -ms-overflow-style: none; }
      .fui-lifecircle-promotion .fui-lifecycle-icons .lifecycle-icon-container::-webkit-scrollbar {
        display: none; }
      .fui-lifecircle-promotion .fui-lifecycle-icons .lifecycle-icon-container.fade-in {
        opacity: 1;
        z-index: 2; }
      .fui-lifecircle-promotion .fui-lifecycle-icons .lifecycle-icon-container .lifecycle-icon {
        display: inline-block;
        text-align: center;
        padding-left: 16px;
        padding-right: 16px; }
        .fui-lifecircle-promotion .fui-lifecycle-icons .lifecycle-icon-container .lifecycle-icon:hover .icon {
          transform: translateY(-6px); }
        .fui-lifecircle-promotion .fui-lifecycle-icons .lifecycle-icon-container .lifecycle-icon .icon {
          transition: transform 0.3s ease; }
          .fui-lifecircle-promotion .fui-lifecycle-icons .lifecycle-icon-container .lifecycle-icon .icon img {
            width: 60px; }
        .fui-lifecircle-promotion .fui-lifecycle-icons .lifecycle-icon-container .lifecycle-icon .content h6 {
          margin: 12px 0 0; }
  @media screen and (min-width: 768px) {
    .fui-lifecircle-promotion .slick-slider .slick-list a .caption .fui-button,
    .fui-lifecircle-promotion .slick-slider .slick-list a:link .caption .fui-button {
      width: auto; } }
  @media screen and (min-width: 960px) {
    .fui-lifecircle-promotion .slick-slider .slick-list a .caption,
    .fui-lifecircle-promotion .slick-slider .slick-list a:link .caption {
      display: flex;
      flex-direction: column;
      align-content: center;
      align-items: center;
      justify-content: center;
      text-align: center;
      top: 0;
      bottom: 0;
      margin: auto; }
      .fui-lifecircle-promotion .slick-slider .slick-list a .caption .fui-button,
      .fui-lifecircle-promotion .slick-slider .slick-list a:link .caption .fui-button {
        width: auto; }
    .fui-lifecircle-promotion .fui-lifecycle-icons .lifecycle-icon-container {
      display: flex;
      white-space: wrap;
      width: 100%; }
      .fui-lifecircle-promotion .fui-lifecycle-icons .lifecycle-icon-container .lifecycle-icon {
        flex: 1 1;
        flex-grow: 1;
        width: auto; }
        .fui-lifecircle-promotion .fui-lifecycle-icons .lifecycle-icon-container .lifecycle-icon .icon img {
          width: 80px; } }

.pointer-none {
  cursor: default; }

.fui-cbu-news {
  background: transparent url(/DigService/resources/cbu/cbu-index/red-trangle-left@2x.png) left bottom no-repeat;
  background-size: 266px auto; }
  .fui-cbu-news .fui-tab-pane {
    padding-top: 24px; }
  .fui-cbu-news .fui-cards.is-news {
    margin: 0 -15px 0 -6px;
    padding-left: 0;
    padding-right: 0; }
    .fui-cbu-news .fui-cards.is-news .fui-card {
      flex: 1 1;
      max-width: 290px; }
      .fui-cbu-news .fui-cards.is-news .fui-card .fui-card-content .fui-card-title {
        -webkit-line-clamp: 3; }
      .fui-cbu-news .fui-cards.is-news .fui-card .fui-card-extra {
        margin-top: 24px; }
      @media screen and (min-width: 960px) {
        .fui-cbu-news .fui-cards.is-news .fui-card {
          max-width: none; } }
  @media screen and (min-width: 960px) {
    .fui-cbu-news {
      background-size: 575px auto; } }

.fui-container .fui-cards.is-cbu-promotion-cards {
  margin: 0 -15px 0 -15px;
  padding-left: 12px;
  padding-right: 0px;
  padding-bottom: 30px; }
  .fui-container .fui-cards.is-cbu-promotion-cards .fui-card {
    display: inline-flex;
    flex-grow: 1;
    width: 90%;
    max-width: 330px;
    box-shadow: 0 6px 12px 0 rgba(75, 88, 105, 0.12); }
    .fui-container .fui-cards.is-cbu-promotion-cards .fui-card .fui-card-action {
      display: flex;
      flex-grow: 1; }
    .fui-container .fui-cards.is-cbu-promotion-cards .fui-card .fui-card-caption .fui-card-title .text {
      display: block;
      font-size: 22px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden; }
    .fui-container .fui-cards.is-cbu-promotion-cards .fui-card .fui-card-caption .fui-card-description {
      margin-top: 0; }
  @media screen and (max-width: 960px) {
    .fui-container .fui-cards.is-cbu-promotion-cards .fui-card .fui-card-image {
      flex: 1 1 auto; } }
  @media screen and (max-width: 960px) and (min-width: 480px) {
    .fui-container .fui-cards.is-cbu-promotion-cards .fui-card {
      max-width: 270px; } }
  @media screen and (min-width: 960px) {
    .fui-container .fui-cards.is-cbu-promotion-cards {
      white-space: normal;
      flex-wrap: wrap; }
      .fui-container .fui-cards.is-cbu-promotion-cards .fui-card {
        width: calc(33.3333% - 12px);
        max-width: none;
        position: relative; }
        .fui-container .fui-cards.is-cbu-promotion-cards .fui-card .fui-card-action .fui-card-caption {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          background: #FFFFFF;
          transition: all 0.3s ease;
          padding-bottom: 16px; }
          .fui-container .fui-cards.is-cbu-promotion-cards .fui-card .fui-card-action .fui-card-caption .fui-card-extra .fui-button.is-arrow,
          .fui-container .fui-cards.is-cbu-promotion-cards .fui-card .fui-card-action .fui-card-caption .fui-card-meta {
            transition: color 0.3s ease; }
          .fui-container .fui-cards.is-cbu-promotion-cards .fui-card .fui-card-action .fui-card-caption .fui-card-title {
            font-size: 24px; }
        .fui-container .fui-cards.is-cbu-promotion-cards .fui-card:hover .fui-card-caption,
        .fui-container .fui-cards.is-cbu-promotion-cards .fui-card:focus .fui-card-caption {
          background: rgba(32, 32, 32, 0.3);
          color: #FFFFFF !important; }
          .fui-container .fui-cards.is-cbu-promotion-cards .fui-card:hover .fui-card-caption .fui-card-meta,
          .fui-container .fui-cards.is-cbu-promotion-cards .fui-card:focus .fui-card-caption .fui-card-meta {
            color: #FFFFFF !important; }
          .fui-container .fui-cards.is-cbu-promotion-cards .fui-card:hover .fui-card-caption .fui-card-extra .fui-button.is-arrow,
          .fui-container .fui-cards.is-cbu-promotion-cards .fui-card:hover .fui-card-caption .fui-card-extra .fui-button.is-arrow::after,
          .fui-container .fui-cards.is-cbu-promotion-cards .fui-card:focus .fui-card-caption .fui-card-extra .fui-button.is-arrow,
          .fui-container .fui-cards.is-cbu-promotion-cards .fui-card:focus .fui-card-caption .fui-card-extra .fui-button.is-arrow::after {
            color: #FFFFFF !important; } }

.fui-greeting-guide {
  padding: 0; }
  .fui-greeting-guide .fui-container {
    padding: 0; }
    .fui-greeting-guide .fui-container .paper {
      padding-left: 15px;
      padding-right: 30px;
      margin-bottom: 0;
      padding-top: 48px;
      background: transparent url(/DigService/resources/cbu/cbu-index/red-trangle@2x.png) right bottom no-repeat;
      background-size: 266px auto;
      box-shadow: none; }
      @media screen and (min-width: 960px) {
        .fui-greeting-guide .fui-container .paper {
          background-color: #FFFFFF;
          box-shadow: 0 6px 12px 0 rgba(75, 88, 105, 0.12); } }
  .fui-greeting-guide .fui-bubbles-container .fui-emma-chat {
    margin-bottom: 36px;
    opacity: 0;
    transform: translateY(-10px);
    transition: all 0.5s ease; }
    .fui-greeting-guide .fui-bubbles-container .fui-emma-chat.fade-in {
      opacity: 1;
      transform: translateY(0px); }
    .fui-greeting-guide .fui-bubbles-container .fui-emma-chat .emma {
      float: left;
      width: 48px;
      height: 48px;
      line-height: 0;
      border-radius: 50%;
      box-shadow: 0 5px 20px rgba(0, 0, 0, 0.3); }
      .fui-greeting-guide .fui-bubbles-container .fui-emma-chat .emma img {
        width: 100%;
        line-height: 0; }
    .fui-greeting-guide .fui-bubbles-container .fui-emma-chat .content {
      position: relative;
      display: block;
      margin-left: 60px; }
      .fui-greeting-guide .fui-bubbles-container .fui-emma-chat .content .slick-slider {
        margin-right: -30px; }
        .fui-greeting-guide .fui-bubbles-container .fui-emma-chat .content .slick-slider.is-icon-slide .slick-list .slick-track {
          margin-left: 0;
          display: flex; }
          .fui-greeting-guide .fui-bubbles-container .fui-emma-chat .content .slick-slider.is-icon-slide .slick-list .slick-track .slick-slide {
            width: 90%;
            max-width: 140px; }
        .fui-greeting-guide .fui-bubbles-container .fui-emma-chat .content .slick-slider .slick-list {
          overflow: visible; }
          .fui-greeting-guide .fui-bubbles-container .fui-emma-chat .content .slick-slider .slick-list .slick-slide {
            padding: 0 12px 0 0;
            width: 90%;
            max-width: 282px; }
            @media screen and (min-width: 960px) {
              .fui-greeting-guide .fui-bubbles-container .fui-emma-chat .content .slick-slider .slick-list .slick-slide {
                width: auto;
                max-width: none; } }
          .fui-greeting-guide .fui-bubbles-container .fui-emma-chat .content .slick-slider .slick-list .bubble.is-card {
            height: 310px; }
            .fui-greeting-guide .fui-bubbles-container .fui-emma-chat .content .slick-slider .slick-list .bubble.is-card .bubble-card-content {
              height: 107px; }
        .fui-greeting-guide .fui-bubbles-container .fui-emma-chat .content .slick-slider .slick-arrow {
          position: absolute;
          width: 40px;
          height: 40px;
          background: #FFFFFF;
          border-radius: 20px;
          overflow: hidden;
          border: 0;
          z-index: 1;
          box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
          color: transparent;
          top: 0;
          bottom: 0;
          margin: auto;
          opacity: 1;
          display: none !important;
          transition: opacity 0.3s ease; }
          @media screen and (min-width: 960px) {
            .fui-greeting-guide .fui-bubbles-container .fui-emma-chat .content .slick-slider .slick-arrow {
              display: block !important; } }
          .fui-greeting-guide .fui-bubbles-container .fui-emma-chat .content .slick-slider .slick-arrow.slick-disabled {
            opacity: 0; }
          .fui-greeting-guide .fui-bubbles-container .fui-emma-chat .content .slick-slider .slick-arrow::after {
            position: absolute;
            font-family: "icomoon" !important;
            speak: none;
            color: #202020;
            width: 28px;
            height: 28px;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            font-size: 28px;
            font-style: normal;
            font-weight: normal;
            font-feature-settings: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1; }
          .fui-greeting-guide .fui-bubbles-container .fui-emma-chat .content .slick-slider .slick-arrow.slick-prev {
            left: -20px; }
            .fui-greeting-guide .fui-bubbles-container .fui-emma-chat .content .slick-slider .slick-arrow.slick-prev::after {
              content: ""; }
          .fui-greeting-guide .fui-bubbles-container .fui-emma-chat .content .slick-slider .slick-arrow.slick-next {
            right: 10px; }
            .fui-greeting-guide .fui-bubbles-container .fui-emma-chat .content .slick-slider .slick-arrow.slick-next::after {
              content: ""; }
      .fui-greeting-guide .fui-bubbles-container .fui-emma-chat .content .fui-bubble-time {
        margin-top: 12px; }
      .fui-greeting-guide .fui-bubbles-container .fui-emma-chat .content .bubble.is-icon {
        width: 100%;
        background: #FFFFFF;
        padding: 15px 16px;
        border-radius: 12px;
        box-shadow: 0 3px 18px 0 rgba(75, 88, 105, 0.24);
        text-align: center; }
        .fui-greeting-guide .fui-bubbles-container .fui-emma-chat .content .bubble.is-icon:hover {
          background: #fafafa; }
        .fui-greeting-guide .fui-bubbles-container .fui-emma-chat .content .bubble.is-icon .text {
          margin-top: 6px;
          font-weight: 500; }
        .fui-greeting-guide .fui-bubbles-container .fui-emma-chat .content .bubble.is-icon .icon img {
          width: 60px;
          height: 60px;
          margin: 0 auto; }
      .fui-greeting-guide .fui-bubbles-container .fui-emma-chat .content .bubble.is-text {
        background: #FFFFFF;
        padding: 12px 15px;
        border-radius: 12px;
        font-size: 18px;
        box-shadow: 0 5px 20px rgba(75, 88, 105, 0.24);
        max-width: 600px; }
      .fui-greeting-guide .fui-bubbles-container .fui-emma-chat .content .bubble.is-card {
        display: flex;
        flex-direction: column;
        background: #FFFFFF;
        border-radius: 12px;
        box-shadow: 0 5px 20px rgba(75, 88, 105, 0.24);
        overflow: hidden;
        max-width: 270px; }
        .fui-greeting-guide .fui-bubbles-container .fui-emma-chat .content .bubble.is-card .image {
          position: relative;
          height: 0;
          padding-bottom: 56.25%; }
          .fui-greeting-guide .fui-bubbles-container .fui-emma-chat .content .bubble.is-card .image img {
            position: absolute;
            width: auto;
            height: 100%;
            left: -100%;
            right: -100%;
            margin: auto; }
        .fui-greeting-guide .fui-bubbles-container .fui-emma-chat .content .bubble.is-card .subtitle, .fui-greeting-guide .fui-bubbles-container .fui-emma-chat .content .bubble.is-card .fui-card.box .fui-card-content .fui-card-title, .fui-card.box .fui-card-content .fui-greeting-guide .fui-bubbles-container .fui-emma-chat .content .bubble.is-card .fui-card-title {
          font-weight: 600;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          display: block;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          white-space: normal;
          -ms-text-overflow: ellipsis; }
        .fui-greeting-guide .fui-bubbles-container .fui-emma-chat .content .bubble.is-card .subtitle, .fui-greeting-guide .fui-bubbles-container .fui-emma-chat .content .bubble.is-card .fui-card.box .fui-card-content .fui-card-title, .fui-card.box .fui-card-content .fui-greeting-guide .fui-bubbles-container .fui-emma-chat .content .bubble.is-card .fui-card-title,
        .fui-greeting-guide .fui-bubbles-container .fui-emma-chat .content .bubble.is-card .body {
          font-size: 1rem; }
        .fui-greeting-guide .fui-bubbles-container .fui-emma-chat .content .bubble.is-card .body {
          flex: 1 1 auto;
          flex-grow: 1;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          display: block;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          white-space: normal;
          -ms-text-overflow: ellipsis; }
        .fui-greeting-guide .fui-bubbles-container .fui-emma-chat .content .bubble.is-card .bubble-card-content {
          padding: 12px 15px;
          flex: 1 1 auto;
          flex-grow: 1; }
        .fui-greeting-guide .fui-bubbles-container .fui-emma-chat .content .bubble.is-card .bubble-card-action a {
          display: block;
          height: 48px;
          line-height: 48px;
          font-weight: 500;
          text-align: center;
          border-top: 2px solid #EBEBEB; }
          .fui-greeting-guide .fui-bubbles-container .fui-emma-chat .content .bubble.is-card .bubble-card-action a span {
            font-size: 1rem; }
          .fui-greeting-guide .fui-bubbles-container .fui-emma-chat .content .bubble.is-card .bubble-card-action a:hover {
            background: #fafafa; }
        @media screen and (max-width: 374px) {
          .fui-greeting-guide .fui-bubbles-container .fui-emma-chat .content .bubble.is-card {
            max-width: 220px; } }
  @media screen and (max-width: 960px) {
    .fui-greeting-guide .fui-bubbles-container .fui-emma-chat .content .slick-slider .slick-list .slick-slide {
      opacity: 1 !important; }
    .fui-greeting-guide .fui-bubbles-container .fui-emma-chat .content .slick-slider.is-icon-slide .slick-list .slick-track .slick-slide {
      width: 90%;
      max-width: 97px;
      height: 102px; }
    .fui-greeting-guide .fui-bubbles-container .fui-emma-chat .content .bubble.is-text {
      font-size: 1rem; }
    .fui-greeting-guide .fui-bubbles-container .fui-emma-chat .content .bubble.is-icon {
      padding: 12px;
      height: 102px; }
      .fui-greeting-guide .fui-bubbles-container .fui-emma-chat .content .bubble.is-icon .icon img {
        width: 36px;
        height: 36px; }
      .fui-greeting-guide .fui-bubbles-container .fui-emma-chat .content .bubble.is-icon .text {
        font-size: 14px;
        letter-spacing: 0px;
        line-height: 18px; } }
  @media screen and (min-width: 1025px) {
    .fui-greeting-guide {
      padding: 60px 0; }
      .fui-greeting-guide .fui-container {
        padding: 0 15px; }
        .fui-greeting-guide .fui-container .paper {
          padding-left: 30px;
          padding-right: 30px;
          background-size: 500px auto; }
      .fui-greeting-guide .fui-bubbles-container .fui-emma-chat .emma {
        width: 70px;
        height: 70px; }
      .fui-greeting-guide .fui-bubbles-container .fui-emma-chat .content {
        margin-left: 100px;
        font-size: 18px; }
        .fui-greeting-guide .fui-bubbles-container .fui-emma-chat .content .bubble.is-text::before {
          content: "";
          position: absolute;
          left: -24px;
          top: 14px;
          border-left: 14px solid transparent;
          border-top: 10px solid transparent;
          border-bottom: 10px solid transparent;
          border-right: 14px solid #FFFFFF; }
        .fui-greeting-guide .fui-bubbles-container .fui-emma-chat .content > .bubble.is-card::before {
          content: "";
          position: absolute;
          left: -24px;
          top: 14px;
          border-left: 14px solid transparent;
          border-top: 10px solid transparent;
          border-bottom: 10px solid transparent;
          border-right: 14px solid #FFFFFF; }
        .fui-greeting-guide .fui-bubbles-container .fui-emma-chat .content .bubble.is-text,
        .fui-greeting-guide .fui-bubbles-container .fui-emma-chat .content .bubble.is-card .bubble-card-content {
          padding: 18px 24px; }
        .fui-greeting-guide .fui-bubbles-container .fui-emma-chat .content .bubble.is-card .body {
          font-size: 18px; }
        .fui-greeting-guide .fui-bubbles-container .fui-emma-chat .content .slick-slider {
          margin-right: 0; }
          .fui-greeting-guide .fui-bubbles-container .fui-emma-chat .content .slick-slider .slick-list {
            margin-left: -6px; }
            .fui-greeting-guide .fui-bubbles-container .fui-emma-chat .content .slick-slider .slick-list .slick-slide {
              opacity: 0;
              padding: 0 6px;
              transition: opacity 0.3s ease; }
              .fui-greeting-guide .fui-bubbles-container .fui-emma-chat .content .slick-slider .slick-list .slick-slide.slick-active {
                opacity: 1; }
              .fui-greeting-guide .fui-bubbles-container .fui-emma-chat .content .slick-slider .slick-list .slick-slide .bubble.is-card {
                height: 347px; }
                .fui-greeting-guide .fui-bubbles-container .fui-emma-chat .content .slick-slider .slick-list .slick-slide .bubble.is-card .bubble-card-content {
                  height: 115px; }
                .fui-greeting-guide .fui-bubbles-container .fui-emma-chat .content .slick-slider .slick-list .slick-slide .bubble.is-card .subtitle, .fui-greeting-guide .fui-bubbles-container .fui-emma-chat .content .slick-slider .slick-list .slick-slide .bubble.is-card .fui-card.box .fui-card-content .fui-card-title, .fui-card.box .fui-card-content .fui-greeting-guide .fui-bubbles-container .fui-emma-chat .content .slick-slider .slick-list .slick-slide .bubble.is-card .fui-card-title {
                  font-size: 18px; }
        .fui-greeting-guide .fui-bubbles-container .fui-emma-chat .content .bubble.is-card {
          max-width: 327px; } }

.is-eservice .carousel-banner-3 .bg-img,
.is-cbu .carousel-banner-3 .bg-img {
  background-position: center;
  background-size: cover; }

@media screen and (max-width: 768px) {
  .is-eservice .carousel-banner-3 .slick-dots,
  .is-cbu .carousel-banner-3 .slick-dots {
    bottom: 40px; }
  .is-eservice .carousel-banner-3 .slick-slide,
  .is-cbu .carousel-banner-3 .slick-slide {
    height: 320px; }
    .is-eservice .carousel-banner-3 .slick-slide > div,
    .is-eservice .carousel-banner-3 .slick-slide .slide,
    .is-cbu .carousel-banner-3 .slick-slide > div,
    .is-cbu .carousel-banner-3 .slick-slide .slide {
      height: 320px; }
    .is-eservice .carousel-banner-3 .slick-slide .bg-img,
    .is-cbu .carousel-banner-3 .slick-slide .bg-img {
      position: absolute;
      top: 0;
      width: 100%;
      height: 320px; }
    .is-eservice .carousel-banner-3 .slick-slide .content-wrapper,
    .is-cbu .carousel-banner-3 .slick-slide .content-wrapper {
      position: absolute;
      left: 0;
      width: 100%;
      height: 320px; }
    .is-eservice .carousel-banner-3 .slick-slide .content,
    .is-cbu .carousel-banner-3 .slick-slide .content {
      padding-top: 60px; }
      .is-eservice .carousel-banner-3 .slick-slide .content p.subtitle, .is-eservice .carousel-banner-3 .slick-slide .content .fui-card.box .fui-card-content p.fui-card-title, .fui-card.box .fui-card-content .is-eservice .carousel-banner-3 .slick-slide .content p.fui-card-title,
      .is-cbu .carousel-banner-3 .slick-slide .content p.subtitle,
      .is-cbu .carousel-banner-3 .slick-slide .content .fui-card.box .fui-card-content p.fui-card-title,
      .fui-card.box .fui-card-content .is-cbu .carousel-banner-3 .slick-slide .content p.fui-card-title {
        margin: 0; }
      .is-eservice .carousel-banner-3 .slick-slide .content h1,
      .is-cbu .carousel-banner-3 .slick-slide .content h1 {
        margin-bottom: 20px; } }

.is-eservice .fui-collapse-menu .collapse-menu-group .collapse-menu-header,
.is-cbu .fui-collapse-menu .collapse-menu-group .collapse-menu-header {
  background: #FC7B66 url(/DigService/resources/cbu/discount/collapse-menu-bg@2x.png) no-repeat left center;
  background-size: auto 100%; }

.is-eservice .fui-collapse-menu .collapse-menu-group ul li .active,
.is-cbu .fui-collapse-menu .collapse-menu-group ul li .active {
  color: #EA574D; }

.is-eservice .fui-collapse-menu .collapse-menu-group ul li:hover > p,
.is-eservice .fui-collapse-menu .collapse-menu-group ul li p:hover,
.is-cbu .fui-collapse-menu .collapse-menu-group ul li:hover > p,
.is-cbu .fui-collapse-menu .collapse-menu-group ul li p:hover {
  color: #EA574D; }

.is-eservice .fui-collapse-menu .collapse-menu-group ul li .item-group,
.is-cbu .fui-collapse-menu .collapse-menu-group ul li .item-group {
  background: #f0f2f5; }
  .is-eservice .fui-collapse-menu .collapse-menu-group ul li .item-group::before,
  .is-cbu .fui-collapse-menu .collapse-menu-group ul li .item-group::before {
    background: linear-gradient(178deg, #f69084, #EA574D 100%); }
  .is-eservice .fui-collapse-menu .collapse-menu-group ul li .item-group::after,
  .is-cbu .fui-collapse-menu .collapse-menu-group ul li .item-group::after {
    border-left-color: #FFA188; }

.is-eservice .fui-collapse-menu.help-center ul li .active,
.is-cbu .fui-collapse-menu.help-center ul li .active {
  color: #2F59C4; }

@media screen and (max-width: 960px) {
  .is-eservice .MuiGrid-root.MuiGrid-container,
  .is-cbu .MuiGrid-root.MuiGrid-container {
    margin-top: 0;
    margin-bottom: 0; }
  .is-eservice .fui-collapse-menu .collapse-menu-group .collapse-menu-header,
  .is-cbu .fui-collapse-menu .collapse-menu-group .collapse-menu-header {
    background: none; } }

.fui-estore-promotion {
  padding: 30px 0;
  background-size: cover; }
  .fui-estore-promotion .promotion-container {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    padding: 0 15px;
    color: #FFFFFF; }
    .fui-estore-promotion .promotion-container h2 {
      margin-top: 3px;
      margin-bottom: 12px; }
    .fui-estore-promotion .promotion-container .fui-button {
      margin: 0; }
  @media screen and (min-width: 960px) {
    .fui-estore-promotion {
      padding: 110px 0; }
      .fui-estore-promotion h2 {
        margin-top: 6px;
        margin-bottom: 30px; } }

section.fui-promo-prod-section .fui-tab a.fui-tab-item::before,
section.fui-promo-prod-section .fui-tab .fui-tab-item::before,
section.fui-promo-prod-section .fui-tab.with-scroller a.fui-tab-item::before,
section.fui-promo-prod-section .fui-tab.with-scroller .fui-tab-item::before {
  height: 4px; }

section.fui-promo-prod-section .fui-tab .active-bar,
section.fui-promo-prod-section .fui-tab.with-scroller .active-bar {
  height: 4px; }

section.fui-promo-prod-section .fui-cards.is-product {
  margin: 0 -6px;
  padding: 0; }
  section.fui-promo-prod-section .fui-cards.is-product .fui-card {
    position: relative;
    width: calc(50% - 12px);
    margin: 0 6px 12px;
    padding-top: 36px;
    background: #FFFFFF; }
    section.fui-promo-prod-section .fui-cards.is-product .fui-card .fui-card-image img {
      width: 150px;
      height: auto; }
    section.fui-promo-prod-section .fui-cards.is-product .fui-card .fui-card-action:hover .fui-card-image img {
      transform: scale(1); }
    section.fui-promo-prod-section .fui-cards.is-product .fui-card .fui-card-caption {
      padding: 18px 12px; }
      section.fui-promo-prod-section .fui-cards.is-product .fui-card .fui-card-caption .fui-card-meta {
        color: #5f7b80;
        font-size: 0.875rem;
        margin-bottom: 3px; }
      section.fui-promo-prod-section .fui-cards.is-product .fui-card .fui-card-caption .body .tag {
        display: inline-block;
        line-height: 20px;
        height: 20px;
        padding: 0 6px;
        font-size: 0.75rem;
        border: 1px solid #202020;
        border-radius: 3px;
        margin-right: 6px;
        vertical-align: middle;
        font-weight: 500; }
      section.fui-promo-prod-section .fui-cards.is-product .fui-card .fui-card-caption .fui-card-price {
        margin-top: 18px;
        font-size: 0.875rem; }
        section.fui-promo-prod-section .fui-cards.is-product .fui-card .fui-card-caption .fui-card-price > div {
          margin-bottom: 3px; }
        section.fui-promo-prod-section .fui-cards.is-product .fui-card .fui-card-caption .fui-card-price .label {
          margin-right: 6px; }
        section.fui-promo-prod-section .fui-cards.is-product .fui-card .fui-card-caption .fui-card-price del {
          color: #8A8A8A; }
        section.fui-promo-prod-section .fui-cards.is-product .fui-card .fui-card-caption .fui-card-price .price.is-text-error {
          font-size: 1rem; }
    section.fui-promo-prod-section .fui-cards.is-product .fui-card .ribbon {
      position: absolute;
      left: 0;
      top: 0;
      color: #FFFFFF;
      height: 26px;
      padding: 0 6px 0;
      line-height: 26px;
      font-size: 0.75rem;
      background-color: #FC7B66;
      z-index: 1; }
      section.fui-promo-prod-section .fui-cards.is-product .fui-card .ribbon > * {
        position: relative;
        z-index: 1; }
      section.fui-promo-prod-section .fui-cards.is-product .fui-card .ribbon::before {
        content: "";
        position: absolute;
        right: -26px;
        z-index: 0;
        border-left: 13px solid #FC7B66;
        border-top: 13px solid #FC7B66;
        border-bottom: 13px solid transparent;
        border-right: 13px solid transparent; }

@media screen and (min-width: 960px) {
  section.fui-promo-prod-section .fui-cards.is-product .fui-card {
    padding-top: 48px;
    width: calc(25% - 12px); }
    section.fui-promo-prod-section .fui-cards.is-product .fui-card .fui-card-image img {
      width: 240px;
      max-width: 100%; }
    section.fui-promo-prod-section .fui-cards.is-product .fui-card .ribbon {
      height: 28px;
      line-height: 28px;
      padding: 0 12px;
      font-size: 0.875rem; }
    section.fui-promo-prod-section .fui-cards.is-product .fui-card .fui-card-price {
      font-size: 1rem;
      margin-top: 12px; }
    section.fui-promo-prod-section .fui-cards.is-product .fui-card .fui-card-caption {
      padding: 24px 30px 30px; }
      section.fui-promo-prod-section .fui-cards.is-product .fui-card .fui-card-caption .body {
        font-size: 20px; }
        section.fui-promo-prod-section .fui-cards.is-product .fui-card .fui-card-caption .body .tag {
          height: 24px;
          line-height: 24px; }
      section.fui-promo-prod-section .fui-cards.is-product .fui-card .fui-card-caption .fui-card-meta {
        margin-bottom: 6px; }
      section.fui-promo-prod-section .fui-cards.is-product .fui-card .fui-card-caption .fui-card-meta,
      section.fui-promo-prod-section .fui-cards.is-product .fui-card .fui-card-caption .body .tag {
        font-size: 0.875rem; } }

.search-bar {
  position: relative;
  background: #eff1f2;
  z-index: 2;
  height: 60px; }
  .search-bar .fui-container {
    padding: 0;
    height: 60px; }
  .search-bar form {
    position: absolute;
    width: 100%;
    display: flex;
    flex-direction: column;
    background: #eff1f2;
    padding: 15px; }
    .search-bar form.with-shadow {
      box-shadow: 0 18px 36px 0 rgba(75, 88, 105, 0.24); }
  .search-bar .search-body {
    flex: 1 1 auto;
    display: flex;
    margin-top: 24px;
    flex-direction: column;
    width: 100%;
    left: 0;
    padding: 0 0 20px;
    top: 100%;
    justify-content: space-between;
    align-content: center;
    align-items: center; }
    .search-bar .search-body .search-groups {
      display: flex;
      flex-direction: column-reverse; }
    .search-bar .search-body .form-group {
      max-width: 404px;
      margin-bottom: 12px; }
      .search-bar .search-body .form-group .reset {
        right: 15px; }
      .search-bar .search-body .form-group label {
        font-size: 18px;
        margin-bottom: 12px; }
    .search-bar .search-body .fui-dropdown {
      min-width: 120px;
      margin: 0 0 8px;
      width: 100%; }
      .search-bar .search-body .fui-dropdown button.fui-dropdown-item {
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between; }
    .search-bar .search-body .fui-buttons {
      margin: 0 -6px; }
      .search-bar .search-body .fui-buttons .fui-button {
        width: calc(50% - 6px);
        margin: 0 6px; }
        .search-bar .search-body .fui-buttons .fui-button:first-child {
          margin-left: 0; }
        .search-bar .search-body .fui-buttons .fui-button:last-child {
          margin-right: 0; }
    .search-bar .search-body > div {
      width: 100%;
      min-height: 50px; }
  .search-bar .search-head {
    width: 100%;
    height: 40px;
    display: flex;
    align-content: center;
    align-items: center; }
    .search-bar .search-head i {
      font-size: 24px; }
    .search-bar .search-head [role="button"] {
      position: absolute;
      right: 0; }
    .search-bar .search-head .form-group {
      margin-bottom: 0; }
  .search-bar .fui-switch {
    margin-left: 12px;
    margin-bottom: 0; }
    .search-bar .fui-switch input + .content {
      display: flex; }
      .search-bar .fui-switch input + .content::before {
        display: block; }
      .search-bar .fui-switch input + .content span.off {
        left: -12px; }
  .search-bar .search-group {
    position: relative; }
    .search-bar .search-group .reset {
      right: 50px; }
    .search-bar .search-group .fui-button {
      position: absolute;
      right: 0;
      margin: 0;
      padding: 0;
      width: 50px;
      height: 50px;
      text-align: center; }
      .search-bar .search-group .fui-button i {
        width: 50px;
        height: 50px;
        transition: none;
        margin: 0 !important; }
      .search-bar .search-group .fui-button:hover i {
        margin: 0 !important; }
  @media screen and (min-width: 960px) {
    .search-bar {
      height: 80px; }
      .search-bar .fui-container {
        height: 80px;
        padding: 0 15px; }
      .search-bar form {
        position: relative;
        flex-direction: row;
        padding: 0;
        box-shadow: none !important;
        height: 80px;
        align-items: center; }
      .search-bar .search-head {
        width: auto;
        height: 50px; }
        .search-bar .search-head .fui-switch {
          margin-right: 94px; }
      .search-bar .search-body {
        flex-direction: row;
        margin-top: 0px;
        padding: 0; }
        .search-bar .search-body .search-groups {
          justify-content: space-between;
          align-content: center;
          align-items: center;
          flex-direction: row; }
        .search-bar .search-body .form-group {
          margin: 0; }
          .search-bar .search-body .form-group .reset {
            right: 50px; }
        .search-bar .search-body .fui-dropdown {
          margin: 0;
          max-width: 120px; } }

.fui-banner.is-find-product {
  min-height: 200px;
  height: 200px;
  display: flex;
  background-size: cover;
  background-position: center;
  color: #FFFFFF;
  padding-top: 60px;
  align-items: center;
  align-content: center; }
  .fui-banner.is-find-product .fui-container {
    padding: 0 15px;
    display: flex;
    width: 100%;
    max-width: 1200px; }
    .fui-banner.is-find-product .fui-container h1 {
      margin-bottom: 0; }
  @media screen and (min-width: 960px) {
    .fui-banner.is-find-product {
      min-height: 360px;
      height: 360px; }
      .fui-banner.is-find-product .fui-container {
        width: 90%; } }

.fui-search-tag-list {
  position: relative;
  display: block;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  margin: -70px -15px 8px;
  z-index: 3;
  padding-left: 15px;
  padding-right: 15px; }
  .fui-search-tag-list .fui-search-tag-item {
    display: inline-block;
    position: relative;
    width: 120px;
    height: 62px;
    border: solid 2px #FFFFFF;
    background-color: rgba(255, 255, 255, 0.24);
    margin-right: 12px;
    padding: 8px 12px;
    font-size: 18px;
    color: #FFFFFF;
    font-weight: 500; }
    .fui-search-tag-list .fui-search-tag-item i.icon-check {
      color: #EF3123;
      display: none;
      font-size: 24px; }
    .fui-search-tag-list .fui-search-tag-item.is-active {
      background: #FFFFFF;
      color: #202020; }
      .fui-search-tag-list .fui-search-tag-item.is-active i.icon-check {
        position: absolute;
        width: 30px;
        height: 30px;
        right: 6px;
        bottom: 6px;
        display: block;
        text-align: center;
        line-height: 30px; }
  @media screen and (min-width: 960px) {
    .fui-search-tag-list {
      margin-top: -130px;
      margin-bottom: 24px;
      overflow-x: visible; }
      .fui-search-tag-list .fui-search-tag-item {
        font-size: 22px;
        width: calc(100% / 6 - 12px);
        height: 106px;
        padding: 18px; }
        .fui-search-tag-list .fui-search-tag-item i.icon-check {
          right: 18px;
          bottom: 18px; } }

.fui-cards.is-product .fui-card.is-prod-promo {
  width: calc(50% - 12px);
  margin: 0 6px 12px; }

@media screen and (min-width: 960px) {
  .fui-cards.is-product.four-card .fui-card.is-prod-promo {
    width: calc(25% - 12px); } }

.fui-card.is-prod-promo .fui-card-image img {
  width: auto;
  height: 100px; }

.fui-card.is-prod-promo .fui-card-image {
  height: 160px; }

.fui-card.is-prod-promo .fui-card-caption {
  padding: 12px;
  background-image: linear-gradient(301deg, #c4d3d6 20%, #f4f9fa); }
  .fui-card.is-prod-promo .fui-card-caption .fui-card-title {
    color: #5f7b80;
    font-size: 18px; }

@media screen and (min-width: 960px) {
  .fui-card.is-prod-promo .fui-card-image {
    height: 244px; }
    .fui-card.is-prod-promo .fui-card-image img {
      width: auto;
      height: 160px; }
  .fui-card.is-prod-promo .fui-card-caption {
    padding: 24px 30px; }
    .fui-card.is-prod-promo .fui-card-caption .fui-card-title {
      font-size: 22px; } }

.fui-card.is-promotion .fui-card-action {
  background-size: cover !important;
  display: flex;
  height: 100%; }
  .fui-card.is-promotion .fui-card-action .fui-card-content {
    background: none;
    color: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 12px;
    padding-right: 12px; }
    .fui-card.is-promotion .fui-card-action .fui-card-content .fui-card-title {
      font-size: 22px; }
    .fui-card.is-promotion .fui-card-action .fui-card-content .fui-card-extra {
      padding: 0; }
      .fui-card.is-promotion .fui-card-action .fui-card-content .fui-card-extra .fui-button {
        min-width: 0;
        width: 100%; }

@media screen and (min-width: 960px) {
  .fui-card.is-promotion .fui-card-action .fui-card-content {
    padding-left: 24px;
    padding-right: 24px; }
    .fui-card.is-promotion .fui-card-action .fui-card-content .fui-card-extra .fui-button {
      min-width: 190px;
      width: auto; } }

.fui-card.bg-red .fui-card-action {
  background-image: url(/DigService/resources/cbu/exclusive/cbu-feature-card-red-390x638.jpg) !important; }

main {
  position: relative;
  transition: padding 0.3s ease; }

section {
  position: relative;
  padding: 60px 0; }
  section h1, section h2, section h3, section h4 {
    margin-top: 0; }
  section .section-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0; }
  section .fui-button.fui-more,
  section a.fui-more:link,
  section a.fui-more:visited {
    color: #202020;
    font-weight: 500;
    margin-top: 25px; }
    section .fui-button.fui-more .text,
    section a.fui-more:link .text,
    section a.fui-more:visited .text {
      line-height: 1.75rem;
      height: 40px;
      margin-right: 5px; }
    section .fui-button.fui-more::after,
    section a.fui-more:link::after,
    section a.fui-more:visited::after {
      display: none; }
    section .fui-button.fui-more i[class*='icon-'],
    section a.fui-more:link i[class*='icon-'],
    section a.fui-more:visited i[class*='icon-'] {
      font-size: 24px;
      height: 40px;
      vertical-align: top; }
  section .fui-container {
    position: relative;
    z-index: 1; }
    section .fui-container .signature-container {
      position: relative;
      display: block;
      max-width: 600px; }
      section .fui-container .signature-container input {
        position: absolute;
        opacity: 0;
        top: 0;
        left: 0;
        margin: 0;
        padding: 0;
        border: 0; }
        section .fui-container .signature-container input + .error-message {
          margin-bottom: 16px; }
      section .fui-container .signature-container .clear-signature {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 9; }
        section .fui-container .signature-container .clear-signature .text {
          height: 26px;
          line-height: 26px; }
        section .fui-container .signature-container .clear-signature + div {
          line-height: 0;
          border: 2px solid transparent; }
          section .fui-container .signature-container .clear-signature + div canvas {
            margin: 0;
            padding: 0;
            line-height: 0;
            border: 0; }
      section .fui-container .signature-container.with-invalid .clear-signature + div {
        border: 2px solid #f05c38; }
      section .fui-container .signature-container .signature-note {
        bottom: 56px;
        text-align: center;
        font-size: .875rem;
        color: #6a798e;
        z-index: 19;
        pointer-events: none; }
      section .fui-container .signature-container .signature-action {
        display: flex;
        align-items: flex-start;
        justify-content: space-between; }
        section .fui-container .signature-container .signature-action .signature-note span {
          vertical-align: middle; }
        section .fui-container .signature-container .signature-action .radius-icon {
          display: inline-flex;
          align-content: center;
          align-items: center;
          justify-content: center;
          justify-items: center;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background: #6a798e;
          margin-right: 4px;
          font-size: 1rem;
          display: block;
          color: white;
          transform: rotate(180deg); }
        section .fui-container .signature-container .signature-action .fui-button .text {
          height: 26px;
          line-height: 26px; }
    section .fui-container .sig-container {
      position: relative;
      background: #DEDEDE;
      text-align: center;
      margin-bottom: 0 !important;
      max-width: 600px; }
    section .fui-container .signature-result {
      position: relative;
      text-align: center;
      margin-bottom: 24px;
      max-width: 600px; }
      section .fui-container .signature-result img {
        border: 1px solid #8A8A8A; }
      section .fui-container .signature-result .signature-note {
        position: absolute;
        display: block;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        left: 0;
        right: 0;
        margin: auto;
        bottom: -6px; }
        section .fui-container .signature-result .signature-note span {
          font-size: .75rem;
          height: 24px;
          line-height: 24px;
          color: #8A8A8A;
          background: white; }
  section .section-title {
    font-size: 28px;
    margin-bottom: 24px; }
  section .section-title a.fui-button.is-text:link,
  section .section-title a.fui-button.is-text:visited,
  section .fui-section-header a.fui-button.is-text:link,
  section .fui-section-header a.fui-button.is-text:visited {
    text-align: right; }
    section .section-title a.fui-button.is-text:link i,
    section .section-title a.fui-button.is-text:visited i,
    section .fui-section-header a.fui-button.is-text:link i,
    section .fui-section-header a.fui-button.is-text:visited i {
      margin-right: 0; }
  section + .fui-tab {
    margin-top: -12px; }
    section + .fui-tab + .fui-cards {
      margin-top: -8px; }
  section > .MuiContainer-root {
    position: relative;
    z-index: 1; }
  section .fui-section-header {
    margin: 0 0 24px;
    display: flex;
    justify-items: space-between;
    justify-content: space-between;
    align-items: center;
    align-content: center; }
    section .fui-section-header h1, section .fui-section-header h2, section .fui-section-header h3, section .fui-section-header h4, section .fui-section-header h5, section .fui-section-header h6 {
      margin: 0; }
    section .fui-section-header .fui-button {
      color: #141414;
      margin: 0 !important; }
      section .fui-section-header .fui-button * {
        vertical-align: middle; }
    section .fui-section-header + .fui-tab {
      margin-bottom: 16px; }
  section .animate-red-arrow {
    position: absolute;
    left: -50px;
    bottom: -50px;
    z-index: 0;
    width: 100%;
    max-width: 705px; }
  section .animate-arrow-left {
    position: absolute;
    top: -210px;
    left: 0;
    z-index: 0;
    width: auto;
    height: 530px; }
    section .animate-arrow-left svg {
      width: 100%;
      height: 100%; }
  section .animate-arrow-right {
    position: absolute;
    right: -100px;
    top: 167px;
    z-index: 0;
    height: 530px; }
    section .animate-arrow-right svg {
      width: 100%;
      height: 100%; }
  @media screen and (max-width: 960px) {
    section {
      padding: 30px 0 60px; } }

.switcher {
  text-align: right;
  position: fixed;
  bottom: 100px;
  right: 0;
  z-index: 100;
  zoom: 0.7;
  opacity: 0.5; }
  .switcher:hover {
    opacity: 1; }
  .switcher .fui-button {
    background-color: #fff !important;
    margin-bottom: 12px !important;
    margin-right: 0 !important; }

@media screen and (max-width: 960px) {
  main.four-point-five #autoConnection .d-flex {
    flex-direction: column; } }

section.is-section-list.fui-content-image .fui-item span.prefix {
  color: #2F59C4; }

section.fui-cards-collapse {
  background: #E8ECF0 url(/DigService/resources/cbu/common/images/gray-pattern.png);
  background-size: cover; }

section.fui-content-image {
  background-color: #E8ECF0;
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: 100% auto;
  color: #5F6E84;
  display: flex;
  flex-direction: column-reverse;
  padding-bottom: 311px; }
  section.fui-content-image .fui-item {
    align-items: flex-start; }
    section.fui-content-image .fui-item > * {
      color: #5F6E84; }
  section.fui-content-image > img {
    position: absolute;
    float: right;
    width: auto;
    height: 281px;
    right: 0;
    bottom: 0;
    margin: 0 0 0 auto;
    display: block; }

section.fui-banner.is-solution + .fui-nav-tab.is-box-tab {
  height: auto;
  margin-top: 0;
  margin-bottom: 60px;
  margin-top: -45px; }
  section.fui-banner.is-solution + .fui-nav-tab.is-box-tab .text {
    font-size: 0.875rem; }
  section.fui-banner.is-solution + .fui-nav-tab.is-box-tab .icon img {
    height: 30px; }

.fui-nav-anchor.is-fixed + section.fui-breadcrumb-container + section + .fui-nav-tab.is-box-tab {
  margin-top: 0; }

section.fui-solution-tab {
  position: relative;
  padding: 0 !important;
  z-index: 1; }
  section.fui-solution-tab .fui-nav-tab.is-box-tab {
    margin-bottom: 76px; }
  section.fui-solution-tab .animate-arrow-left {
    top: -310px;
    left: -200px; }
  section.fui-solution-tab .fui-tab {
    margin-bottom: 12px; }
  section.fui-solution-tab .fui-tab-pane > img {
    margin-bottom: 12px; }
  section.fui-solution-tab .fui-tab-pane .fui-card .fui-card-content .fui-card-title .text {
    font-size: 22px;
    line-height: 33px;
    max-height: 4.5rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
    -ms-text-overflow: ellipsis; }
  section.fui-solution-tab .fui-tab-pane .fui-card .fui-card-content .fui-card-description {
    -webkit-line-clamp: 3;
    height: auto;
    max-height: 5rem; }
  section.fui-solution-tab img.store-map {
    margin: 0 auto;
    max-width: 100%;
    display: block; }
  @media screen and (min-width: 960px) {
    section.fui-solution-tab .fui-nav-tab.is-box-tab {
      margin-top: -45px;
      margin-bottom: 98px; }
      section.fui-solution-tab .fui-nav-tab.is-box-tab .text {
        font-size: 1rem; }
    section.fui-solution-tab img.store-map {
      max-height: 320px;
      margin-bottom: 24px; } }

section.fui-corp-content {
  background: #5F6E84;
  color: #FFFFFF;
  text-align: center;
  background-attachment: fixed;
  background-image: url(/DigService/resources/cbu/product/images/bg-corp.png);
  background-size: cover; }
  @media screen and (-webkit-min-device-pixel-ratio: 0) and (min-color-index: 0) {
    section.fui-corp-content {
      background-attachment: scroll;
      background-attachment: initial; } }
  @media all and (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
    section.fui-corp-content {
      background-attachment: scroll;
      background-attachment: initial; } }
  section.fui-corp-content.is-style-2 {
    background: none;
    text-align: left;
    color: #202020; }
  section.fui-corp-content .fui-marquee-box .marquee-landscape-item .marquee-landscape-txt {
    padding: 0;
    margin: 0 18px; }
    section.fui-corp-content .fui-marquee-box .marquee-landscape-item .marquee-landscape-txt img {
      opacity: 1;
      width: auto;
      max-height: 70px; }

section.query-index {
  min-height: 560px; }
  section.query-index h1 .fui-tooltip-container i {
    font-size: 32px !important; }
  section.query-index .member-login .text-input {
    max-width: 492px; }
  section.query-index i.icon-information {
    cursor: pointer; }
  section.query-index .fui-dropdown.is-button {
    max-width: 208px; }
  section.query-index .paper {
    padding-top: 30px;
    padding-bottom: 60px; }
    section.query-index .paper.merged {
      margin-bottom: 0;
      border-bottom: 1px solid #E8ECF0; }
    section.query-index .paper h4 {
      margin-bottom: 16px;
      margin-top: 40px; }
    section.query-index .paper .border {
      border: 2px solid #E8ECF0; }

.paper .MuiGrid-root.MuiGrid-item.d-flex > div {
  flex: 1 1; }

.frammer {
  margin: 30px 0; }
  .frammer--list {
    border: 2px solid #eff1f2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px;
    margin-top: -2px; }
    .frammer--list h5, .frammer--list h4, .frammer--list h2 {
      margin: 0; }

main.receipt section.fui-faq-collapse {
  padding: 0; }

@media screen and (max-width: 960px) {
  flex-direction: column;
  .receipt-img {
    width: 190px;
    margin-right: auto; }
  section.query-index {
    padding-top: 30px;
    padding-bottom: 60px;
    min-height: auto; }
    section.query-index .body {
      font-size: 16px; }
    section.query-index .fui-dropdown.is-button {
      max-width: none;
      margin: 0 0 30px; }
    section.query-index .paper {
      padding-bottom: 30px; }
      section.query-index .paper h4 {
        margin-top: 0; }
      section.query-index .paper .MuiGrid-item:nth-last-child(1) {
        padding-bottom: 0; }
    section.query-index a.fui-button.is-small {
      height: auto;
      margin-bottom: 0; }
      section.query-index a.fui-button.is-small span {
        font-size: 12px; }
  .frammer--list {
    padding: 32px 18px; }
    .frammer--list h5 {
      max-width: 132px;
      font-size: 18px; }
    .frammer--list h2 {
      text-align: right; }
  section.usage-rate .fui-button.is-small {
    height: auto;
    margin-bottom: 0; }
    section.usage-rate .fui-button.is-small span {
      font-size: 12px; }
  section.usage-rate .rate-group {
    margin-top: 0; } }

@media screen and (max-width: 768px) {
  section.query-index a.fui-button.is-primary {
    width: 100%; }
  section.query-index .page-action a.fui-button.is-primary {
    width: auto; } }

@media screen and (max-width: 640px) {
  section.query-index .page-action a.fui-button.is-primary {
    width: 100%; } }

section.fui-section-collapse {
  padding: 60px 0;
  background: #fafafa;
  background: #ebebeb; }
  section.fui-section-collapse .fui-collapse {
    color: #5F6E84; }
    section.fui-section-collapse .fui-collapse .collapse-header {
      font-weight: 500; }
      section.fui-section-collapse .fui-collapse .collapse-header::after, section.fui-section-collapse .fui-collapse .collapse-header::before {
        color: #5F6E84; }
    section.fui-section-collapse .fui-collapse .collapse-body article {
      font-size: 0.875rem;
      line-height: 1.5rem;
      padding: 0 0 24px; }
      section.fui-section-collapse .fui-collapse .collapse-body article p {
        display: inline; }
    section.fui-section-collapse .fui-collapse .collapse-body p {
      font-size: 0.875rem; }
  section.fui-section-collapse.is-static {
    padding: 30px 28px; }
    section.fui-section-collapse.is-static .collapse-header::after {
      display: none; }
    section.fui-section-collapse.is-static .collapse-body {
      height: auto;
      overflow: visible;
      line-break: anywhere; }

@media screen and (max-width: 960px) {
  section.fui-section-collapse {
    padding: 30px 0; } }

section.fui-faq-collapse {
  padding: 60px 0;
  background: #f1f3f5; }
  section.fui-faq-collapse .fui-collapse-group {
    margin-top: 18px;
    margin-bottom: 18px; }
  section.fui-faq-collapse .fui-collapse {
    color: #5F6E84; }
    section.fui-faq-collapse .fui-collapse .collapse-header {
      font-size: 1rem;
      font-weight: 500;
      padding: 0 32px 0 0; }
      section.fui-faq-collapse .fui-collapse .collapse-header::after, section.fui-faq-collapse .fui-collapse .collapse-header::before {
        color: #6a798e; }
    section.fui-faq-collapse .fui-collapse .collapse-body p {
      font-size: 0.875rem;
      display: inline; }
    section.fui-faq-collapse .fui-collapse .collapse-body article {
      padding: 0; }

section.fui-content {
  position: relative;
  padding-top: 60px;
  padding-bottom: 60px; }
  section.fui-content.is-section-feature {
    background-image: none !important;
    padding-bottom: 0; }
  section.fui-content.is-image {
    display: block;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch; }
  section.fui-content.bg-default {
    background: #E8ECF0; }
  section.fui-content.bg-arrow {
    background-size: auto 160px;
    background-image: url(/DigService/resources/common/images/section-bkg-pattern-1.png);
    background-repeat: no-repeat;
    background-position: top left;
    padding: 60px 0 0; }
    section.fui-content.bg-arrow::after, section.fui-content.bg-arrow::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 1;
      display: block;
      pointer-events: none; }
    section.fui-content.bg-arrow::before {
      opacity: 0.4;
      background-image: linear-gradient(0deg, rgba(250, 250, 250, 0) 0%, #D9E0E8 100%);
      z-index: -1; }
  section.fui-content ~ section.fui-content.bg-arrow {
    background-size: auto 200px;
    background-image: url(/DigService/resources/common/images/section-bkg-pattern-2.png); }
    section.fui-content ~ section.fui-content.bg-arrow::before {
      display: none; }
  section.fui-content > * {
    margin-bottom: 24px; }
  section.fui-content .subtitle, section.fui-content .fui-card.box .fui-card-content .fui-card-title, .fui-card.box .fui-card-content section.fui-content .fui-card-title {
    margin-bottom: 24px; }
  section.fui-content .fui-container > div.text {
    color: #5F6E84; }
  section.fui-content .fui-inline-items {
    margin-top: 24px; }
    section.fui-content .fui-inline-items .fui-item span.prefix {
      color: #2F59C4; }

section.product-map {
  padding: 48px 0 60px;
  background: #dbe4ee; }
  section.product-map .fui-collapse .collapse-trigger {
    display: none; }
  section.product-map .fui-collapse .product-map-list {
    display: flex;
    flex-direction: column; }
  section.product-map .fui-collapse .fui-item .content {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; }
  @media screen and (max-width: 960px) {
    section.product-map .fui-collapse .collapse-body {
      height: auto !important; } }

section.service-tags {
  padding: 48px 0 60px;
  background-color: #E8ECF0;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(/DigService/resources/cbu/img/web-pattern-01.png);
  background-size: cover;
  color: #5F6E84; }
  section.service-tags h3 {
    margin-top: 0; }
  section.service-tags .service-tag-container {
    overflow-x: auto;
    overflow-y: hidden;
    margin: 0; }
    section.service-tags .service-tag-container .fui-button.is-tag {
      min-width: 0;
      padding: 0 18px;
      margin-right: 12px; }

section.fui-horzonal-cards {
  overflow: hidden;
  z-index: 1; }
  section.fui-horzonal-cards .section-title {
    max-width: calc( 100% - 90px); }
  section.fui-horzonal-cards .section-bg img {
    height: 154px; }
  section.fui-horzonal-cards.no-more .horizontal-cards .slick-arrow {
    display: none !important; }
  section.fui-horzonal-cards.is-flexible .collapse {
    overflow: hidden;
    height: auto;
    margin-bottom: 16px;
    transition: height 0.3s ease; }
  section.fui-horzonal-cards.is-flexible .fui-card .fui-card-description {
    font-size: 1rem;
    height: 5em;
    -webkit-line-clamp: 3; }
  section.fui-horzonal-cards.is-narrow {
    z-index: 0;
    padding: 60px 0; }
    section.fui-horzonal-cards.is-narrow + .is-narrow {
      padding-top: 0; }
  section.fui-horzonal-cards .slick-dots {
    display: none; }
  section.fui-horzonal-cards .bg {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%; }
    section.fui-horzonal-cards .bg img {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      margin: auto; }

section.service-entry .fui-cards.four-card {
  white-space: inherit !important;
  overflow: visible; }

section.demo-shop {
  padding-top: 48px;
  padding-bottom: 0;
  overflow: hidden; }
  section.demo-shop .animate-arrow-left {
    z-index: -1; }
  section.demo-shop .MuiContainer-root {
    position: relative;
    z-index: 1; }
  section.demo-shop .shops-logo {
    padding: 32px 0; }
    section.demo-shop .shops-logo > span {
      opacity: 0.5;
      margin: 0 40px; }
  @media screen and (min-width: 960px) {
    section.demo-shop {
      padding-bottom: 90px; }
      section.demo-shop .fui-marquee-box {
        margin-bottom: -90px; } }

.fui-container section.fui-section-promo {
  margin: 0 -15px; }

.global-banner-ad a {
  width: 100%; }

section.fui-section-promo {
  position: relative;
  background-color: #202020;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  z-index: 2;
  padding: 30px 14px;
  min-height: 156px;
  overflow: hidden;
  display: flex;
  align-content: center;
  align-items: center; }
  section.fui-section-promo + section {
    z-index: 0 !important; }
  section.fui-section-promo .fui-container {
    padding: 0; }
  section.fui-section-promo[class*='bg-pattern'] {
    background-position: left top;
    background-size: 100% auto; }
  section.fui-section-promo.bg-pattern-gray {
    background-color: #6a798e;
    background-image: url(/DigService/resources/common/images/patternd-darkgray-banner-sm.png); }
  section.fui-section-promo.bg-pattern-green {
    background-color: #8da0a4;
    background-image: url(/DigService/resources/common/images/cbu_banner_bg_750x522.jpg); }
  section.fui-section-promo.bg-pattern-blue {
    background-color: #0A3476;
    background-image: url(/DigService/resources/common/images/patternd-blue-banner-sm.png); }
  section.fui-section-promo.with-pattern::after {
    display: none; }
  section.fui-section-promo .text-input {
    margin-bottom: 0; }
  section.fui-section-promo .fui-input-group {
    position: relative; }
  section.fui-section-promo .fui-button.is-secondary::after {
    display: none !important; }
  section.fui-section-promo img.fui-section-promo-image {
    position: absolute;
    width: 100%;
    height: auto;
    left: -100%;
    right: -100%;
    top: -100%;
    bottom: -100%;
    margin: auto;
    opacity: 0.7; }
  section.fui-section-promo .MuiGrid-root.MuiGrid-item {
    position: relative;
    display: flex; }
    section.fui-section-promo .MuiGrid-root.MuiGrid-item.action {
      align-content: flex-start;
      align-items: flex-start; }
    section.fui-section-promo .MuiGrid-root.MuiGrid-item h4.fui-section-promo-title {
      margin: 0; }
      section.fui-section-promo .MuiGrid-root.MuiGrid-item h4.fui-section-promo-title + p {
        margin: 6px 0 0; }
    section.fui-section-promo .MuiGrid-root.MuiGrid-item .fui-button {
      margin: 16px 6px 0 0; }
  section.fui-section-promo.is-edm {
    color: #202020;
    padding-left: 15px;
    padding-right: 15px;
    z-index: 0;
    background-image: url(/DigService/resources/cbu/img/web-pattern-01.png);
    background-position: left center;
    background-repeat: repeat-x;
    background-size: auto 100%; }
    section.fui-section-promo.is-edm::before {
      display: none; }
    section.fui-section-promo.is-edm .form-group.is-invalid {
      margin-bottom: 30px; }
      section.fui-section-promo.is-edm .form-group.is-invalid .error-message {
        top: 40px; }
    section.fui-section-promo.is-edm .MuiGrid-root.MuiGrid-item {
      padding: 0 16px; }
    section.fui-section-promo.is-edm .fui-action .fui-button {
      width: 100%; }
    section.fui-section-promo.is-edm .fui-section-promo-title {
      font-size: 18px;
      margin-top: 0;
      margin-bottom: 24px; }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    section.fui-section-promo .content, section.fui-section-promo .action {
      min-height: 100px; } }

section.promotion-article {
  padding-top: 0;
  padding-bottom: 0;
  z-index: 2; }
  section.promotion-article .animate-arrow-right {
    top: -360px; }
  section.promotion-article .promotion-article-container {
    margin-bottom: 60px; }
  section.promotion-article .MuiGrid-item {
    display: flex;
    flex-direction: column; }
    section.promotion-article .MuiGrid-item .MuiCard-root {
      flex: 1 1;
      flex-grow: 1;
      margin-bottom: 12px; }

.selected-article + .hide-mb,
.selected-article + .promotion-container {
  margin: 0 -15px; }
  @media screen and (min-width: 960px) {
    .selected-article + .hide-mb,
    .selected-article + .promotion-container {
      margin: 0;
      height: 620px; } }

.news-content .fui-nav-anchor + .fui-breadcrumb-container {
  position: relative;
  top: 0; }

.news-content .news-subtitle {
  color: #8A8A8A;
  margin-bottom: 12px;
  font-size: 18px; }
  .news-content .news-subtitle span {
    color: #5F6E84;
    font-weight: 500; }

.news-content .selected-article {
  margin-top: 0; }

section.is-screw .slick-center {
  width: 75%; }
  section.is-screw .slick-center img {
    width: 340px;
    position: absolute;
    right: 0; }

ul.step-list-small {
  list-style-type: none;
  padding: 0;
  margin-top: 0px; }
  ul.step-list-small li {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 18px;
    font-size: 16px;
    line-height: 1.75; }
    ul.step-list-small li:nth-last-child(1) .circle::before {
      display: none; }
  ul.step-list-small h6 {
    color: #202020;
    font-weight: normal;
    margin: 0; }
  ul.step-list-small .circle {
    background-color: #eff4f1;
    min-width: 22px;
    min-height: 22px;
    border-radius: 50%;
    margin-right: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #5f7b80;
    font-size: 16px;
    font-weight: 400;
    position: relative; }

ul.step-list {
  list-style-type: none;
  padding: 0;
  margin-top: 48px; }
  ul.step-list li {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 64px;
    align-items: baseline;
    padding-bottom: 28px;
    overflow: hidden;
    margin-bottom: 12px; }
    ul.step-list li:nth-last-child(1) .circle::before {
      display: none; }
  ul.step-list h6 {
    color: #202020;
    font-weight: normal;
    margin: 0; }
  ul.step-list .circle {
    background-color: #eff4f1;
    min-width: 60px;
    min-height: 60px;
    border-radius: 50%;
    margin-right: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #5f7b80;
    font-size: 24px;
    font-weight: 400;
    position: relative;
    line-height: 60px; }
    ul.step-list .circle::before {
      top: 36px;
      left: 18px;
      height: 100vh;
      content: "";
      height: 30px;
      width: 0px;
      border: 1px solid #E8ECF0;
      position: absolute;
      left: 30px;
      top: 76px; }

ol.is-decimal {
  list-style-type: decimal;
  padding-left: 20px;
  margin-bottom: 32px;
  line-height: 28px; }
  @media screen and (max-width: 960px) {
    ol.is-decimal.h-100::before {
      height: 100vh; } }

@media screen and (max-width: 960px) {
  ul.step-list {
    margin-top: 0; }
    ul.step-list li {
      align-items: flex-end; }
      ul.step-list li .circle {
        min-width: 40px;
        min-height: 40px;
        font-size: 24px;
        height: 40px;
        width: 40px;
        line-height: 40px; }
        ul.step-list li .circle::before {
          height: 100%;
          left: 18px;
          top: 46px;
          min-height: 72px; } }

ul.green-dot-list {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 32px; }
  ul.green-dot-list li {
    font-size: 16px;
    line-height: 24px;
    padding-left: 30px;
    position: relative;
    margin-bottom: 6px;
    line-height: 1.75; }
    ul.green-dot-list li::before {
      content: "•";
      color: #5F7B80;
      font-weight: bold;
      font-size: 28px;
      line-height: 0px;
      margin-top: 0px;
      margin-right: 10px;
      vertical-align: sub;
      position: absolute;
      top: 12px;
      left: 6px; }

ul.check-list {
  list-style-type: none;
  padding-left: 0; }
  ul.check-list li {
    font-size: 16px;
    line-height: 2.6;
    color: #f05c38; }
    ul.check-list li::before {
      content: "";
      font-family: "icomoon" !important;
      color: #EF3123;
      font-weight: 400;
      font-size: 20px;
      line-height: 0px;
      margin-top: 0px;
      margin-right: 6px;
      vertical-align: sub;
      position: relative;
      top: 2px; }

ul.green-dot-list li ol {
  list-style-type: none;
  padding: 12px 0 0 0; }
  ul.green-dot-list li ol li {
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: 1.75;
    margin-bottom: 8px;
    padding-left: 0; }
    ul.green-dot-list li ol li::before {
      display: none; }
    ul.green-dot-list li ol li li {
      display: flex;
      flex-direction: row;
      align-items: center;
      line-height: 1;
      margin-bottom: 8px;
      padding-left: 0; }
      ul.green-dot-list li ol li li::before {
        display: none; }
      ul.green-dot-list li ol li li .circle {
        font-size: 14px;
        background-color: #eff4f1;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        margin-right: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #5f7b80;
        font-weight: 400;
        position: relative; }
  ul.green-dot-list li ol ul.is-decimal {
    list-style-type: decimal;
    list-style-position: outside;
    padding-left: 20px; }
    ul.green-dot-list li ol ul.is-decimal li {
      line-height: 28px;
      padding-left: 0; }
      ul.green-dot-list li ol ul.is-decimal li::before {
        display: none;
        justify-content: center;
        color: #5f7b80;
        font-weight: 400;
        position: relative; }

ol.order-list {
  list-style-type: none;
  padding-left: 32px !important;
  list-style-position: outside; }
  ol.order-list li {
    font-size: 16px;
    line-height: 1.75;
    counter-increment: custom;
    position: relative;
    margin-bottom: 10px; }
    ol.order-list li:first-child {
      counter-reset: custom; }
    ol.order-list li .decimal {
      position: absolute;
      left: -28px;
      top: 3px;
      color: #5f7b80;
      font-weight: 500;
      font-size: 16px;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background-color: #eff4f1;
      display: inline-flex;
      background-color: #eff4f1;
      align-items: center;
      justify-content: center; }

.list-info img {
  width: 160px;
  height: 160px;
  display: inline-block; }

.list-info .d-inline-block {
  vertical-align: top; }

.list-info .check-list p {
  color: #5f7b80; }

.list-info .check-list li {
  display: flex;
  align-items: baseline; }

.list-info .uncheck-list li::before {
  content: "\e903";
  font-weight: 600; }

@media screen and (max-width: 960px) {
  .list-info img {
    width: 130px;
    height: 130px; }
  .list-info .d-inline-block {
    width: calc( 100% - 130px); }
    .list-info .d-inline-block .check-list {
      align-items: baseline; }
      .list-info .d-inline-block .check-list .d-inline-block {
        width: 100%; }
      .list-info .d-inline-block .check-list li::before {
        bottom: -2px;
        position: relative; }
    .list-info .d-inline-block .uncheck-list {
      margin-bottom: 0; } }

main.app-list-page {
  margin-top: 50px; }
  main.app-list-page section.fui-breadcrumb-container {
    position: relative;
    top: 0; }
  main.app-list-page .list-wrapper {
    margin-top: 0;
    padding-top: 42px; }
    main.app-list-page .list-wrapper .paper {
      margin: 0; }
      main.app-list-page .list-wrapper .paper img {
        width: 100px;
        height: 100px;
        margin-right: 30px; }
      main.app-list-page .list-wrapper .paper .body {
        font-weight: 500;
        margin-bottom: 10px; }
      main.app-list-page .list-wrapper .paper p {
        font-size: 14px;
        color: #3a485d;
        line-height: normal;
        margin: 0;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: block;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        white-space: normal;
        -ms-text-overflow: ellipsis; }
      main.app-list-page .list-wrapper .paper a img {
        width: 148px;
        height: 45px;
        margin-right: 12px; }
      main.app-list-page .list-wrapper .paper hr {
        margin: 24px 0; }
      main.app-list-page .list-wrapper .paper ul {
        list-style-type: none;
        padding-left: 0;
        margin: 0; }
        main.app-list-page .list-wrapper .paper ul li {
          font-size: 14px;
          padding-left: 30px;
          position: relative;
          color: #3a485d; }
          main.app-list-page .list-wrapper .paper ul li::before {
            content: "•";
            color: #5F7B80;
            font-weight: bold;
            font-size: 28px;
            line-height: 0px;
            margin-top: 0px;
            margin-right: 10px;
            vertical-align: sub;
            position: absolute;
            top: 9px;
            left: 6px; }
  @media screen and (max-width: 960px) {
    main.app-list-page .list-wrapper {
      margin: 0 0 30px !important; }
      main.app-list-page .list-wrapper .paper img {
        width: 70px;
        height: 70px;
        margin-right: 18px; }
      main.app-list-page .list-wrapper .paper .body {
        margin-bottom: 0; }
      main.app-list-page .list-wrapper .paper p {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: block;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        white-space: normal;
        -ms-text-overflow: ellipsis; }
      main.app-list-page .list-wrapper .paper a img {
        width: 118px;
        height: 36px; } }

main.profile-page {
  background-image: url(/DigService/resources/cbu/img/bg-pattern-ribbon@2x.png);
  background-position: 0px 780px;
  background-size: cover; }

section.happy-go .fui-card a.fui-card-action {
  min-height: 153px; }
  section.happy-go .fui-card a.fui-card-action .fui-card-content {
    display: flex;
    flex-direction: column; }
  section.happy-go .fui-card a.fui-card-action .fui-card-extra {
    display: none; }
  section.happy-go .fui-card a.fui-card-action p.fui-card-description {
    height: auto;
    font-size: 18px;
    font-weight: 500;
    align-content: flex-end; }
    section.happy-go .fui-card a.fui-card-action p.fui-card-description * {
      vertical-align: middle; }

@media screen and (max-width: 768px) {
  section.happy-go .fui-card a.fui-card-action .fui-card-content {
    padding: 12px 12px 0 12px; }
    section.happy-go .fui-card a.fui-card-action .fui-card-content h5.fui-card-title {
      font-size: 18px; } }

section.sitemap-tab {
  background: #eff4f1; }

@media screen and (max-width: 768px) {
  .fui-card {
    width: calc(50% - 12px);
    margin-bottom: 12px; } }

@media screen and (max-width: 960px) {
  section.fui-help-shortcut .d-flex {
    margin-top: 48px;
    flex-wrap: wrap; }
    section.fui-help-shortcut .d-flex .fui-shortcut-item .text {
      flex: 1 1;
      flex-grow: 1; }
    section.fui-help-shortcut .d-flex .fui-shortcut-item img {
      width: 60px; } }

section.prepaid-roaming .receipt .form-group .form-group {
  margin-bottom: 0; }

section.usage-rate {
  background-image: url(/DigService/resources/common/images/bg-section-pattern@2x.png);
  background-color: #eff4f1;
  background-position: left top;
  background-size: 100% auto;
  background-repeat: no-repeat;
  min-height: 500px; }
  section.usage-rate .min-height-64 {
    min-height: 64px; }
  section.usage-rate .fui-segments .action {
    bottom: 30px; }
  section.usage-rate .fui-segments .fui-pagination {
    margin-bottom: 30px; }
  section.usage-rate .fui-segments .program-name + .bill-table {
    margin-top: 60px; }
  section.usage-rate .fui-segments .compareTable i {
    transition: all 0.5s;
    display: inline-block; }
  section.usage-rate .fui-segments .compareTable tr.is-bg-lightgray70 td {
    white-space: nowrap; }
  section.usage-rate .fui-segments .compareTable .rotate180 {
    transform: rotate(180deg);
    transition: all 0.5s; }
  section.usage-rate .fui-segments .compareTable .icon-chevron-down {
    font-size: 24px;
    vertical-align: text-top; }
  section.usage-rate .fui-segments .compareTable .compareList {
    min-width: auto;
    font-weight: 400; }
    section.usage-rate .fui-segments .compareTable .compareList.odd-table tr:nth-child(odd) {
      background-color: #f1f3f5; }
  section.usage-rate .fui-segments .compareTable .tableLeft {
    border-right: 0px solid #E8ECF0;
    border-left: 0px solid #E8ECF0;
    margin: 0;
    font-weight: 500; }
    section.usage-rate .fui-segments .compareTable .tableLeft tr {
      background-color: #FFFFFF; }
  section.usage-rate .fui-segments .compareTable .tableRight tr {
    background-color: #FFFFFF; }
    section.usage-rate .fui-segments .compareTable .tableRight tr td {
      min-width: 160px; }
    section.usage-rate .fui-segments .compareTable .tableRight tr:nth-of-type(1) td {
      font-weight: 500; }
  section.usage-rate .fui-segments .compareTable.w-116 .tableLeft {
    width: 116px; }
  section.usage-rate .fui-segments .compareTable.w-116 .tableRight {
    padding-left: 114px; }
    section.usage-rate .fui-segments .compareTable.w-116 .tableRight .tableBody {
      margin-right: -3px; }
  section.usage-rate .fui-segments .sum-table {
    margin-top: -2px; }
    section.usage-rate .fui-segments .sum-table td {
      min-width: 152px; }
      section.usage-rate .fui-segments .sum-table td:nth-last-child(1) {
        width: 35.85%; }
  @media screen and (max-width: 960px) {
    section.usage-rate .fui-segments .fui-pagination {
      margin: 30px 0 0; }
    section.usage-rate .fui-segments .compareTable .icon-chevron-down {
      vertical-align: middle; }
    section.usage-rate .fui-segments .compareTable .compareList {
      min-width: 152px; }
      section.usage-rate .fui-segments .compareTable .compareList td {
        min-width: 154px; }
    section.usage-rate .fui-segments .compareTable .not-fixed {
      table-layout: auto; }
    section.usage-rate .fui-segments .compareTable .tableLeft {
      width: 154px; }
    section.usage-rate .fui-segments .compareTable .tableRight {
      padding-left: 152px; }
    section.usage-rate .fui-segments .compareTable.w-200 .tableLeft {
      width: 200px; }
    section.usage-rate .fui-segments .compareTable.w-200 .tableRight {
      padding-left: 198px; }
    section.usage-rate .fui-segments .sum-table {
      margin-right: -21px; }
      section.usage-rate .fui-segments .sum-table td:nth-last-child(1) {
        width: calc(( 100vw - 224px)/2); } }
  @media screen and (max-width: 540px) {
    section.usage-rate .fui-segments .compareTable .tableRight {
      margin-right: -21px; }
      section.usage-rate .fui-segments .compareTable .tableRight::after {
        display: none; }
    section.usage-rate .fui-segments .sum-table {
      margin-right: -21px; }
      section.usage-rate .fui-segments .sum-table td:nth-last-child(1) {
        width: 154px; } }
  section.usage-rate .fui-segments .fui-collapse .collapse-header {
    padding: 30px;
    margin-bottom: 0;
    font-weight: normal; }
  section.usage-rate .fui-segments .fui-collapse .is-agreement {
    margin-bottom: 16px; }
  section.usage-rate .fui-segments .fui-collapse .collapse-body {
    padding: 0;
    margin: 0; }
    section.usage-rate .fui-segments .fui-collapse .collapse-body small {
      margin-bottom: 12px;
      display: block; }
  section.usage-rate table.bill-table {
    margin-top: 30px;
    table-layout: fixed; }
    section.usage-rate table.bill-table th {
      background-color: #f1f3f5; }
    section.usage-rate table.bill-table td {
      background-color: #FFFFFF; }
  section.usage-rate.no-bg {
    background-image: none;
    padding-top: 0;
    background-color: transparent; }
    section.usage-rate.no-bg .rate-group {
      margin-top: 0; }
      section.usage-rate.no-bg .rate-group .item-wrapper {
        display: flex;
        justify-content: space-around;
        margin-top: 64px; }
      section.usage-rate.no-bg .rate-group .rate-item:nth-last-child(1) {
        margin-bottom: 0; }
      section.usage-rate.no-bg .rate-group .rate-item .number {
        margin-left: -32px; }
        section.usage-rate.no-bg .rate-group .rate-item .number h6::before {
          right: 8px;
          border-bottom: 26px solid #202020;
          border-right: 36px solid #202020;
          border-top-width: 26px; }
        section.usage-rate.no-bg .rate-group .rate-item .number h6::after {
          border-bottom: 26px solid #FFFFFF;
          border-right: 36px solid #FFFFFF; }
    @media screen and (max-width: 768px) {
      section.usage-rate.no-bg.no-bg .item-wrapper {
        display: block; }
      section.usage-rate.no-bg.no-bg .paper {
        margin-bottom: 30px; }
      section.usage-rate.no-bg.no-bg .rate-group .rate-item .number {
        margin-left: auto; }
        section.usage-rate.no-bg.no-bg .rate-group .rate-item .number h1 {
          margin-bottom: 10px; }
        section.usage-rate.no-bg.no-bg .rate-group .rate-item .number h6::before {
          border-right: 32px solid #202020; }
        section.usage-rate.no-bg.no-bg .rate-group .rate-item .number h6::after {
          border-top-width: 30px; } }
  section.usage-rate .rate-group {
    display: block;
    width: 100%;
    margin-top: 40px;
    margin-bottom: 60px; }
    section.usage-rate .rate-group .rate-item {
      display: inline-block;
      width: 200px;
      vertical-align: top;
      text-align: center; }
      section.usage-rate .rate-group .rate-item .number {
        position: relative;
        display: inline-block;
        margin: 0 auto 12px; }
        section.usage-rate .rate-group .rate-item .number h1, section.usage-rate .rate-group .rate-item .number h6 {
          margin: 0; }
        section.usage-rate .rate-group .rate-item .number h1 {
          font-size: 60px;
          text-align: center;
          display: inline-block; }
        section.usage-rate .rate-group .rate-item .number h6 {
          position: absolute;
          right: -36px;
          bottom: -4px;
          display: block;
          z-index: 2; }
          section.usage-rate .rate-group .rate-item .number h6::after, section.usage-rate .rate-group .rate-item .number h6::before {
            content: "";
            position: absolute;
            border-top: 26px solid transparent;
            border-bottom: 26px solid #eff4f1;
            border-right: 36px solid #eff4f1;
            border-left: 36px solid transparent;
            right: 4px;
            bottom: 0;
            z-index: -1; }
          section.usage-rate .rate-group .rate-item .number h6::before {
            right: 8px;
            border-bottom: 26px solid #202020;
            border-right: 36px solid #202020; }
        section.usage-rate .rate-group .rate-item .number.is-text-error h6::before {
          border-bottom: 26px solid #f05c38;
          border-right: 36px solid #f05c38; }
  @media screen and (max-width: 768px) {
    section.usage-rate {
      background-image: url(/DigService/resources/common/images/bg-section-pattern-m@2x.png); }
      section.usage-rate table.bill-table {
        margin-top: 30px; }
        section.usage-rate table.bill-table .fui-tooltip-container {
          display: none; }
      section.usage-rate .rate-group {
        margin-bottom: 0; }
        section.usage-rate .rate-group .rate-item {
          margin-bottom: 56px;
          width: 50%; }
          section.usage-rate .rate-group .rate-item .number h1 {
            font-size: 3rem; }
          section.usage-rate .rate-group .rate-item .number h6 {
            right: -30px; }
            section.usage-rate .rate-group .rate-item .number h6::after, section.usage-rate .rate-group .rate-item .number h6::before {
              border-top-width: 22px;
              border-bottom-width: 22px;
              border-right-width: 30px;
              border-left-width: 30px; }
            section.usage-rate .rate-group .rate-item .number h6::after {
              right: 2px;
              border-bottom-color: #ebefef;
              border-right-color: #ebefef;
              bottom: -1px; }
            section.usage-rate .rate-group .rate-item .number h6::before {
              right: 4px; }
      section.usage-rate .fui-buttons .fui-button {
        display: block;
        width: 100%;
        margin-left: 0;
        margin-right: 0; } }

section .fcoin-record-list .fui-record-item {
  border: solid 2px #E8ECF0;
  display: flex;
  padding: 30px;
  margin-bottom: 12px; }
  section .fcoin-record-list .fui-record-item .extra {
    text-align: right; }
    section .fcoin-record-list .fui-record-item .extra > .fcoin {
      font-size: 28px;
      font-weight: 500; }
      section .fcoin-record-list .fui-record-item .extra > .fcoin * {
        vertical-align: middle; }
      section .fcoin-record-list .fui-record-item .extra > .fcoin img {
        width: 24px;
        margin-right: 6px; }
  section .fcoin-record-list .fui-record-item .content {
    flex: 1 1;
    flex-grow: 1; }
    section .fcoin-record-list .fui-record-item .content h4, section .fcoin-record-list .fui-record-item .content h5 {
      margin: 0 0; }
    section .fcoin-record-list .fui-record-item .content h5 {
      margin-top: 6px; }

section .fui-cards.is-recommand-activity {
  flex-wrap: wrap;
  align-items: initial; }
  section .fui-cards.is-recommand-activity .fui-card {
    box-shadow: none !important;
    height: auto; }
    section .fui-cards.is-recommand-activity .fui-card a.fui-card-action:link,
    section .fui-cards.is-recommand-activity .fui-card a.fui-card-action:visited {
      color: #202020;
      border: solid 2px #E8ECF0;
      display: flex;
      flex-direction: row;
      padding: 16px 12px 16px 16px;
      min-height: 172px;
      align-items: flex-end; }
      section .fui-cards.is-recommand-activity .fui-card a.fui-card-action:link::after,
      section .fui-cards.is-recommand-activity .fui-card a.fui-card-action:visited::after {
        display: none; }
      section .fui-cards.is-recommand-activity .fui-card a.fui-card-action:link .fui-card-extra,
      section .fui-cards.is-recommand-activity .fui-card a.fui-card-action:visited .fui-card-extra {
        padding: 0;
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: center;
        justify-items: center;
        font-size: 24px;
        width: 30px;
        height: 30px; }
      section .fui-cards.is-recommand-activity .fui-card a.fui-card-action:link .fui-card-content,
      section .fui-cards.is-recommand-activity .fui-card a.fui-card-action:visited .fui-card-content {
        flex: 1 1;
        flex-grow: 1;
        padding: 0; }
        section .fui-cards.is-recommand-activity .fui-card a.fui-card-action:link .fui-card-content h5,
        section .fui-cards.is-recommand-activity .fui-card a.fui-card-action:visited .fui-card-content h5 {
          font-size: 22px; }
        section .fui-cards.is-recommand-activity .fui-card a.fui-card-action:link .fui-card-content .fui-card-description,
        section .fui-cards.is-recommand-activity .fui-card a.fui-card-action:visited .fui-card-content .fui-card-description {
          margin-top: 6px;
          height: auto;
          overflow: visible; }
        section .fui-cards.is-recommand-activity .fui-card a.fui-card-action:link .fui-card-content .description *,
        section .fui-cards.is-recommand-activity .fui-card a.fui-card-action:visited .fui-card-content .description * {
          vertical-align: middle; }
        section .fui-cards.is-recommand-activity .fui-card a.fui-card-action:link .fui-card-content .description img,
        section .fui-cards.is-recommand-activity .fui-card a.fui-card-action:visited .fui-card-content .description img {
          width: 30px;
          margin-right: 6px; }

@media screen and (max-width: 960px) {
  section .fcoin-record-list .fui-record-item {
    flex-direction: column;
    padding: 30px 18px;
    min-height: 216px; }
    section .fcoin-record-list .fui-record-item .content h4, section .fcoin-record-list .fui-record-item .content h5 {
      font-size: 20px; }
    section .fcoin-record-list .fui-record-item .extra {
      display: flex;
      justify-content: space-between;
      align-content: center;
      align-items: center; }
      section .fcoin-record-list .fui-record-item .extra .fcoin {
        font-size: 24px; }
  section .fui-cards.is-recommand-activity {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row; }
    section .fui-cards.is-recommand-activity .fui-card {
      width: 100%;
      margin-bottom: 12px; }
      section .fui-cards.is-recommand-activity .fui-card .fui-card-action {
        min-height: 154px; } }

section.fcoin-dashboard {
  padding-top: 152px;
  padding-bottom: 80px;
  background-image: url(/DigService/resources/cbu/img/bg-cbu-fcoin-pattern-1920-x-1200@2x.png);
  background-size: cover; }
  section.fcoin-dashboard h3 {
    margin-bottom: 24px; }
  section.fcoin-dashboard h1.fcoin-number {
    font-size: 90px;
    color: #EF3123;
    margin-top: 24px; }
    section.fcoin-dashboard h1.fcoin-number img {
      margin-right: 6px; }
  section.fcoin-dashboard .gray-block {
    padding: 24px;
    background: #EBEBEB;
    height: 135px;
    margin-top: 32px; }
    section.fcoin-dashboard .gray-block h2 {
      margin-bottom: 0; }
  @media screen and (max-width: 960px) {
    section.fcoin-dashboard {
      padding-top: 48px;
      padding-bottom: 20px;
      background: #FFFFFF url(/DigService/resources/cbu/img/bg-personal-right-pattern-all-m@2x.png) no-repeat top center;
      background-size: 100% auto; }
      section.fcoin-dashboard .fcoin-container {
        text-align: center; }
        section.fcoin-dashboard .fcoin-container h3 {
          font-weight: 400;
          margin-bottom: 6px;
          font-size: 22px; }
        section.fcoin-dashboard .fcoin-container h1.fcoin-number {
          font-size: 60px;
          line-height: 63px;
          margin-top: 0;
          margin-bottom: 0; }
          section.fcoin-dashboard .fcoin-container h1.fcoin-number i {
            font-size: 24px;
            vertical-align: middle; }
          section.fcoin-dashboard .fcoin-container h1.fcoin-number img {
            width: 36px; }
      section.fcoin-dashboard .gray-block {
        padding: 18px;
        margin: 0 20px 6px;
        min-height: 0;
        height: auto; } }

section.login-recommend {
  padding-top: 120px;
  padding-bottom: 80px;
  background-image: url(/DigService/resources/cbu/img/bg-cbu-fcoin-pattern-1920-x-1200@2x.png);
  background-size: cover;
  width: 100%; }
  section.login-recommend .fetnet-coin .percentage {
    height: 30px; }
  section.login-recommend .level-info .d-inline-flex {
    display: flex;
    align-items: center; }
    section.login-recommend .level-info .d-inline-flex h1 {
      margin: 0; }
    section.login-recommend .level-info .d-inline-flex img {
      width: 30px;
      height: 30px;
      margin-right: 6px; }
  section.login-recommend .level-info .body {
    margin-top: 20px; }
  section.login-recommend .member-badge {
    padding: 30px 12px 30px 30px;
    display: flex;
    word-break: break-all; }
    section.login-recommend .member-badge img {
      max-width: 100px;
      width: 100%;
      height: 100%;
      margin-right: 20px; }
    section.login-recommend .member-badge h1 {
      margin: 0; }
  section.login-recommend .ruler {
    background-color: #f1f3f5;
    width: 100%;
    margin-bottom: 6px;
    overflow: hidden; }
    section.login-recommend .ruler.main .percentage {
      height: 48px;
      background-image: linear-gradient(to left, #fc7b66 0%, #ffa188); }
      section.login-recommend .ruler.main .percentage span {
        font-size: 22px;
        margin-top: 10px; }
        section.login-recommend .ruler.main .percentage span::after {
          border-color: transparent transparent transparent #fc7a66;
          border-width: 48px 0 0 48px;
          top: -9px;
          right: -48px; }
    section.login-recommend .ruler .percentage {
      height: 30px;
      background-image: linear-gradient(to right, #ffdf9e, #ffa72d 97%);
      position: relative; }
      section.login-recommend .ruler .percentage span {
        position: absolute;
        right: 0;
        font-size: 18px;
        color: #fff;
        font-weight: 500;
        margin-top: 2px; }
        section.login-recommend .ruler .percentage span.zero {
          left: 10px;
          color: #5f6e84; }
          section.login-recommend .ruler .percentage span.zero::after {
            display: none; }
        section.login-recommend .ruler .percentage span::after {
          content: "";
          width: 0;
          height: 0;
          border-style: solid;
          border-color: transparent transparent transparent #ffa72d;
          position: absolute;
          border-width: 30px 0 0 30px;
          top: -2px;
          right: -30px; }
  section.login-recommend h4 {
    margin-top: 12px;
    margin-bottom: 48px; }
    section.login-recommend h4 a.fui-button.is-text {
      font-size: 24px;
      color: #EF3123;
      line-height: normal;
      margin: 0; }
    section.login-recommend h4 span.member-info {
      border-left: 1px solid #D6D6D6;
      padding: 0 6px 0 15px; }
      section.login-recommend h4 span.member-info:first-child {
        padding-left: 0;
        border-left: 0; }
      section.login-recommend h4 span.member-info * {
        vertical-align: middle; }
      section.login-recommend h4 span.member-info img {
        width: 36px;
        height: auto;
        margin-right: 6px; }
      section.login-recommend h4 span.member-info a:link,
      section.login-recommend h4 span.member-info a:visited {
        color: #202020; }
  section.login-recommend .fui-card img {
    max-width: 236px;
    width: 100%;
    height: auto;
    margin: 0 auto 24px; }
  section.login-recommend .fui-card .fui-card-extra {
    padding: 0; }
  section.login-recommend .fui-card.is-guest .fui-card-content {
    padding: 60px 30px 24px;
    display: flex;
    flex-direction: column; }
    section.login-recommend .fui-card.is-guest .fui-card-content h3 {
      flex: 1 1 auto;
      flex-grow: 1 auto; }
  section.login-recommend .fui-card.is-postpaid .fui-card-content {
    padding: 30px 24px 24px; }
    section.login-recommend .fui-card.is-postpaid .fui-card-content h4,
    section.login-recommend .fui-card.is-postpaid .fui-card-content h1 {
      margin: 0; }
    section.login-recommend .fui-card.is-postpaid .fui-card-content .columns {
      display: flex;
      margin-bottom: 24px !important; }
      section.login-recommend .fui-card.is-postpaid .fui-card-content .columns > .column {
        flex: 1 1;
        flex-grow: 1;
        padding-left: 12px;
        border-left: 1px solid #D6D6D6; }
        section.login-recommend .fui-card.is-postpaid .fui-card-content .columns > .column .description {
          margin-bottom: 0 !important; }
        section.login-recommend .fui-card.is-postpaid .fui-card-content .columns > .column:first-child {
          border-left: 0;
          padding-left: 0;
          padding-right: 20px; }
    section.login-recommend .fui-card.is-postpaid .fui-card-content .description * {
      vertical-align: middle;
      margin: 0; }
    section.login-recommend .fui-card.is-postpaid .fui-card-content .description img.icon {
      width: auto;
      margin-right: 6px; }
  section.login-recommend .fui-card h3 {
    margin-bottom: 30px; }
  section.login-recommend .is-promotion {
    background-image: url(/DigService/resources/cbu/img/subtitle-title-content-copy-2@2x.png);
    background-size: cover; }
    section.login-recommend .is-promotion .fui-card-action {
      color: #FFFFFF;
      background: none; }
      section.login-recommend .is-promotion .fui-card-action .fui-card-content {
        padding-top: 0;
        padding-bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start; }
    section.login-recommend .is-promotion h4 {
      width: 100%;
      display: block;
      margin: 0 0 30px; }
    section.login-recommend .is-promotion a.fui-button {
      margin: 0; }
  @media screen and (max-width: 960px) {
    section.login-recommend {
      padding-top: 60px;
      padding-bottom: 0;
      background: #FFFFFF url(/DigService/resources/cbu/img/bg-personal-right-pattern-all-m@2x.png) no-repeat top center;
      background-size: 100% auto; }
      section.login-recommend .level-info .body {
        margin-top: 8px; }
      section.login-recommend .fui-cards .fui-card {
        width: calc(100% - 12px); }
        section.login-recommend .fui-cards .fui-card .fui-card-extra, section.login-recommend .fui-cards .fui-card .fui-button {
          width: 100%; }
      section.login-recommend .fui-cards .fui-card.is-postpaid .fui-card-content .fui-button {
        width: 100%; }
      section.login-recommend .fui-cards .fui-card.is-guest img {
        width: 160px;
        height: auto; }
      section.login-recommend .fui-cards .fui-card.is-guest .fui-card-content {
        padding: 48px 24px 32px; } }

main.life-circle-app {
  background-image: url(/DigService/resources/cbu/img/bg-cbu-fcoin-pattern-1920-x-1200@2x.png);
  background-size: contain;
  width: 100%; }
  main.life-circle-app .video {
    margin-top: 30px; }
    main.life-circle-app .video h1 {
      margin-bottom: 6px; }
    main.life-circle-app .video img {
      max-width: 800px; }

@media screen and (max-width: 960px) {
  main.life-circle-app {
    background-image: url(/DigService/resources/cbu/img/bg-cbu-fcoin-pattern-m@2x.png);
    background-position-y: 30px;
    background-size: contain; }
    main.life-circle-app .video {
      padding-top: 0; }
      main.life-circle-app .video h3 {
        margin-bottom: 60px; }
      main.life-circle-app .video img {
        width: 100%; } }

@media screen and (max-width: 960px) {
  .discount-layout .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-8 .MuiGrid-root.MuiGrid-item {
    padding-top: 12px;
    padding-bottom: 12px; } }

section.partner {
  background-image: url(/DigService/resources/cbu/img/carousel-partner-bkg@2x.png);
  height: 550px;
  background-size: cover;
  padding-bottom: 60px; }
  section.partner h5 {
    margin: 0; }
  section.partner .brands-wrapper div {
    display: inline-block;
    padding: 0 90px;
    width: 33%; }
    section.partner .brands-wrapper div img {
      max-width: 186px;
      margin: 0 auto; }
  section.partner .horizontal-cards.slick-slider {
    height: 100%; }
  section.partner .slick-dots {
    position: absolute;
    bottom: 56px;
    z-index: 1;
    list-style: none;
    margin: 0 auto;
    padding: 0;
    left: 0;
    right: 0;
    text-align: center; }
    section.partner .slick-dots .slick-active {
      background: #FFFFFF; }
      section.partner .slick-dots .slick-active button {
        opacity: 0;
        background-color: #fff; }
    section.partner .slick-dots li {
      position: relative;
      display: inline-block;
      margin-right: 6px;
      background-color: #8A8A8A;
      height: 4px;
      width: 50px; }
      section.partner .slick-dots li button {
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0;
        left: 0;
        cursor: pointer; }
      section.partner .slick-dots li:nth-last-child(1) {
        margin-right: 0; }
        section.partner .slick-dots li:nth-last-child(1) button {
          cursor: pointer;
          width: 50px;
          height: 4px;
          border: 0;
          vertical-align: top; }
  section.partner .dark-dot .slick-dots .slick-active {
    background-color: #141414; }

@media screen and (max-width: 960px) {
  section.partner {
    height: 390px;
    background-image: url(/DigService/resources/cbu/img/carousel-partner-bkg-m@2x.png); }
    section.partner .brands-wrapper div {
      padding: 0; }
      section.partner .brands-wrapper div img {
        width: 100%;
        max-width: 100px; } }

.w-100 {
  width: 100%; }

main.my-order {
  position: relative; }
  main.my-order .fui-section-collapse {
    padding: 40px 0; }
    main.my-order .fui-section-collapse .collapse-body {
      margin-bottom: 0; }
  main.my-order .my-order-wrapper {
    padding-top: 112px; }
  main.my-order .order-status .paper {
    margin-bottom: 12px; }
    main.my-order .order-status .paper h3 {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: block;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      white-space: normal;
      -ms-text-overflow: ellipsis; }
    main.my-order .order-status .paper h5 {
      font-weight: normal;
      margin-bottom: 0; }
    main.my-order .order-status .paper .has-price h3, main.my-order .order-status .paper .has-price h5 {
      margin: 0; }
  main.my-order .order-status .subtitle, main.my-order .order-status .fui-card.box .fui-card-content .fui-card-title, .fui-card.box .fui-card-content main.my-order .order-status .fui-card-title {
    margin: 0;
    font-weight: normal;
    margin-bottom: 42px; }
  main.my-order .order-status .img-wrapper {
    flex: 0 0 150px;
    margin-right: 40px; }
    main.my-order .order-status .img-wrapper img {
      width: 100%; }
  main.my-order .order-status h2 {
    margin-bottom: 18px; }
  main.my-order .status-list {
    display: flex;
    justify-content: space-between;
    position: relative; }
    main.my-order .status-list::before {
      content: "";
      border-bottom: 2px solid #BFBFBF;
      width: calc( 100% - 46px);
      left: 20px;
      position: absolute;
      top: 6px; }
    main.my-order .status-list .d-flex {
      width: 100%; }
    main.my-order .status-list .subtitle, main.my-order .status-list .fui-card.box .fui-card-content .fui-card-title, .fui-card.box .fui-card-content main.my-order .status-list .fui-card-title {
      margin: 36px 0 0;
      text-align: center;
      line-height: 1.78;
      max-width: 64px;
      color: #BFBFBF;
      position: relative; }
      main.my-order .status-list .subtitle.active, main.my-order .status-list .fui-card.box .fui-card-content .active.fui-card-title, .fui-card.box .fui-card-content main.my-order .status-list .active.fui-card-title {
        color: #5F6E84; }
        main.my-order .status-list .subtitle.active::before, main.my-order .status-list .fui-card.box .fui-card-content .active.fui-card-title::before, .fui-card.box .fui-card-content main.my-order .status-list .active.fui-card-title::before {
          color: #5F6E84;
          font-size: 124px;
          line-height: 70px;
          left: -4px;
          font-family: "Arial";
          top: -65px; }
      main.my-order .status-list .subtitle::before, main.my-order .status-list .fui-card.box .fui-card-content .fui-card-title::before, .fui-card.box .fui-card-content main.my-order .status-list .fui-card-title::before {
        content: "•";
        font-size: 64px;
        position: absolute;
        top: -85px;
        left: 0;
        width: 100%;
        font-family: "Arial"; }
  @media all and (-ms-high-contrast: none) {
    main.my-order .status-list .subtitle::before, main.my-order .status-list .fui-card.box .fui-card-content .fui-card-title::before, .fui-card.box .fui-card-content main.my-order .status-list .fui-card-title::before {
      min-width: 0; } }
  main.my-order.has-bg {
    background-image: url(/DigService/resources/cbu/img/bg-cbu-colorslash-pattern-1440-x-725.svg);
    background-size: cover;
    background-repeat: no-repeat;
    height: auto;
    height: initial; }
    main.my-order.has-bg h2::before {
      content: "";
      background-image: url(/DigService/resources/cbu/img/img-cbu-arrow-pattern.svg);
      background-size: contain;
      position: absolute;
      left: -100px;
      top: 0;
      display: block;
      width: 100px;
      height: 144px; }
  main.my-order .fui-breadcrumb-container {
    margin-bottom: 48px; }
  main.my-order h2 {
    margin-bottom: 48px; }
  main.my-order .paper {
    padding: 60px 30px;
    width: 100%;
    margin-bottom: 0; }
    main.my-order .paper h2 {
      margin-top: 0;
      margin-bottom: 0; }
    main.my-order .paper h5 {
      margin-top: 15px;
      margin-bottom: 60px; }
    main.my-order .paper h6 {
      margin-bottom: 18px; }
    main.my-order .paper .input-wrapper {
      padding-bottom: 60px; }
      main.my-order .paper .input-wrapper .error-message {
        display: block;
        position: absolute; }
      main.my-order .paper .input-wrapper.invalid input {
        border: 2px solid #f05c38; }
      main.my-order .paper .input-wrapper input {
        padding: 12px;
        border: 2px solid #E8ECF0;
        width: 100%;
        font-size: 16px; }
    main.my-order .paper :-ms-input-placeholder {
      color: #BFBFBF; }
    main.my-order .paper ::placeholder {
      color: #BFBFBF; }

@media screen and (max-width: 960px) {
  main.my-order .my-order-wrapper {
    padding-top: 60px; }
  main.my-order .order-status {
    padding-top: 30px;
    min-height: calc( 100vh - 597px); }
    main.my-order .order-status .img-wrapper {
      flex: 0 0 92px;
      margin-right: 12px; }
    main.my-order .order-status .subtitle, main.my-order .order-status .fui-card.box .fui-card-content .fui-card-title, .fui-card.box .fui-card-content main.my-order .order-status .fui-card-title {
      margin-bottom: 0; }
  main.my-order .paper {
    padding: 30px 20px;
    margin: 0; }
    main.my-order .paper h5 {
      margin-bottom: 42px; }
    main.my-order .paper h6 {
      margin-bottom: 10px; }
    main.my-order .paper input {
      margin-bottom: 42px; }
    main.my-order .paper .fui-button {
      min-width: 227px; }
    main.my-order .paper .d-inline .d-inline-flex {
      flex-direction: column; }
    main.my-order .paper p {
      margin-bottom: 0;
      font-size: 14px;
      line-height: 1.7; }
    main.my-order .paper .MuiGrid-spacing-xs-6 > .MuiGrid-item {
      padding: 12px 24px; }
      main.my-order .paper .MuiGrid-spacing-xs-6 > .MuiGrid-item.d-flex {
        margin-top: 30px; }
    main.my-order .paper h3 {
      margin: 0;
      font-size: 20px;
      line-height: 30px; }
    main.my-order .paper a.fui-button {
      margin: 0;
      font-size: 14px;
      padding: 0;
      height: 22px; }
    main.my-order .paper .fui-button {
      padding: 0 10px;
      height: auto;
      min-width: 227px; }
      main.my-order .paper .fui-button span {
        font-size: 12px; }
    main.my-order .paper h5 {
      font-size: 14px;
      margin: 0;
      line-height: 1.7; }
  main.my-order .status-list {
    flex-direction: column;
    margin-top: 30px; }
    main.my-order .status-list::before {
      left: 14px;
      position: absolute;
      top: 20px;
      width: 2px;
      height: calc( 100% - 50px);
      border-left: 2px solid #BFBFBF;
      border-bottom: 0; }
    main.my-order .status-list .d-flex {
      flex-direction: column; }
    main.my-order .status-list .subtitle, main.my-order .status-list .fui-card.box .fui-card-content .fui-card-title, .fui-card.box .fui-card-content main.my-order .status-list .fui-card-title {
      margin: 0 0 32px 42px;
      max-width: 100%;
      text-align: left; }
      main.my-order .status-list .subtitle.active::before, main.my-order .status-list .fui-card.box .fui-card-content .active.fui-card-title::before, .fui-card.box .fui-card-content main.my-order .status-list .active.fui-card-title::before {
        left: -49px;
        top: -21px; }
      main.my-order .status-list .subtitle br, main.my-order .status-list .fui-card.box .fui-card-content .fui-card-title br, .fui-card.box .fui-card-content main.my-order .status-list .fui-card-title br {
        display: none; }
      main.my-order .status-list .subtitle::before, main.my-order .status-list .fui-card.box .fui-card-content .fui-card-title::before, .fui-card.box .fui-card-content main.my-order .status-list .fui-card-title::before {
        top: -40px;
        left: -38px; }
      main.my-order .status-list .subtitle:nth-last-child(1), main.my-order .status-list .fui-card.box .fui-card-content .fui-card-title:nth-last-child(1), .fui-card.box .fui-card-content main.my-order .status-list .fui-card-title:nth-last-child(1) {
        margin-bottom: 12px; }
  main.my-order.has-bg {
    padding-bottom: 30px;
    background-image: url(/DigService/resources/cbu/img/bg-cbu-colorslash-pattern-375-x-704.svg); }
    main.my-order.has-bg h2::before {
      width: 49px;
      height: 70px;
      left: 0px;
      top: -60px; }
  main.my-order .fui-breadcrumb-container {
    margin-bottom: 0; } }

.sitemap a.fui-button.is-text .icon-chevron-right, #product a.fui-button.is-text .icon-chevron-right, #program a.fui-button.is-text .icon-chevron-right, #lifeCircle a.fui-button.is-text .icon-chevron-right, #personal a.fui-button.is-text .icon-chevron-right, #helpCenter a.fui-button.is-text .icon-chevron-right, #discount a.fui-button.is-text .icon-chevron-right {
  margin-right: 0; }

main.store {
  margin-top: 50px; }
  main.store .fui-nav-anchor {
    top: 50px; }
    main.store .fui-nav-anchor.is-fixed {
      top: 0; }
  main.store .fui-nav-anchor + section.fui-breadcrumb-container {
    top: 100px; }
  @media screen and (max-width: 960px) {
    main.store .fui-button.fui-more, main.store section a.fui-more:link, main.store section a.fui-more:visited {
      margin-top: 10px; } }

.fui-member-info.ribbon-bg,
.fcoin-tab-wrapper.ribbon-bg {
  background-image: url(/DigService/resources/cbu/prepaid/images/ribbon-pattern-1920.jpg);
  background-size: cover;
  background-repeat-x: no-repeat;
  z-index: 2;
  width: 100%;
  height: auto;
  position: relative; }
  @media screen and (max-width: 960px) {
    .fui-member-info.ribbon-bg,
    .fcoin-tab-wrapper.ribbon-bg {
      background-image: url(/DigService/resources/cbu/prepaid/images/cbu_ribbon_pattern_bkg@2x.jpg); } }

.fui-member-info {
  padding-bottom: 0; }
  .fui-member-info .member-levels {
    margin: 40px 0; }
    .fui-member-info .member-levels img {
      width: 120px; }
  .fui-member-info .member-benefits-content .member-benefits-item > .icon {
    float: left; }
    .fui-member-info .member-benefits-content .member-benefits-item > .icon > img {
      width: 30px; }
  .fui-member-info .member-benefits-content .member-benefits-item > .content {
    display: block;
    margin-left: 48px;
    line-height: 28px; }
    .fui-member-info .member-benefits-content .member-benefits-item > .content .is-text-darkgray50 .fui-list .fui-item {
      color: #5F6E84; }
      .fui-member-info .member-benefits-content .member-benefits-item > .content .is-text-darkgray50 .fui-list .fui-item .prefix i {
        color: #5F6E84;
        background-color: #5F6E84; }
    .fui-member-info .member-benefits-content .member-benefits-item > .content h5 {
      font-weight: 400;
      margin-bottom: 16px; }
  @media screen and (max-width: 600px) {
    .fui-member-info .member-levels {
      margin: 30px 0; }
      .fui-member-info .member-levels img {
        width: 20%; }
    .fui-member-info .member-benefits-content .member-benefits-item > .content {
      margin-left: 36px; }
      .fui-member-info .member-benefits-content .member-benefits-item > .content h5 {
        margin-bottom: 12px; } }

.ribbon-bg {
  background-image: url(/DigService/resources/cbu/prepaid/images/ribbon-pattern-1920.jpg);
  background-size: 100% auto;
  background-repeat-x: no-repeat;
  width: 100%;
  height: auto;
  position: relative; }

main.prepaid {
  position: relative; }
  main.prepaid #faq {
    width: 100%;
    padding: 0 5px; }
  main.prepaid .mt-30 {
    margin-top: -30px; }
  main.prepaid .compareTable .tableLeft {
    width: 100px; }
  main.prepaid .compareTable .tableRight {
    padding-left: 98px; }
  main.prepaid .ribbon-bg-wrapper .fui-panel {
    background-image: url(/DigService/resources/cbu/prepaid/images/ribbon-pattern-1920.jpg);
    background-size: cover;
    background-repeat-x: no-repeat;
    width: 100%;
    margin: 0; }
  main.prepaid #howTo {
    padding-bottom: 0;
    color: #5F6E84; }
    main.prepaid #howTo::before {
      border-bottom: 7px solid #EF3123; }
  main.prepaid section.fui-faq-collapse {
    padding-top: 30px;
    padding-bottom: 30px; }
  main.prepaid .program-internet-mb {
    display: none; }
  main.prepaid .fui-tab-body section.fui-faq-collapse {
    padding: 0; }
    main.prepaid .fui-tab-body section.fui-faq-collapse .fui-collapse {
      padding: 18px 0; }
      main.prepaid .fui-tab-body section.fui-faq-collapse .fui-collapse ol {
        padding-left: 20px; }
    main.prepaid .fui-tab-body section.fui-faq-collapse .fui-collapse-group {
      margin-bottom: 36px; }
  main.prepaid .fui-tab-pane {
    z-index: 18; }
  main.prepaid .fui-collapse .collapse-body {
    margin-bottom: 0; }
    main.prepaid .fui-collapse .collapse-body article {
      padding: 0; }
      main.prepaid .fui-collapse .collapse-body article ol {
        margin: 0;
        line-height: 1.7;
        font-size: 14px; }
    main.prepaid .fui-collapse .collapse-body table {
      margin-top: 18px; }
  main.prepaid .fui-collapse .collapse-header {
    padding: 0 30px 0 0; }
  main.prepaid .fui-collapse.is-open .collapse-header {
    margin-bottom: 24px; }
  main.prepaid section.fui-section-collapse .collapse-header {
    font-size: 16px; }
  main.prepaid section.fui-section-collapse .collapse-body {
    margin-bottom: 0; }
    main.prepaid section.fui-section-collapse .collapse-body article {
      margin-top: 21px;
      padding-bottom: 0; }
  main.prepaid .fui-nav-tab + .fui-breadcrumb-container, main.prepaid .fui-nav-anchor + .fui-breadcrumb-container {
    top: 45px; }
  main.prepaid .is-text-bold {
    font-weight: 600; }
  main.prepaid .is-text-medium {
    font-weight: 500; }
  main.prepaid .is-text-regular {
    font-weight: 400; }
  main.prepaid .is-text-light {
    font-weight: 300; }
  main.prepaid h6.paper-h6 {
    margin-top: 0;
    margin-bottom: 24px;
    font-weight: 400; }
  main.prepaid h6.is-text-darkgreen {
    margin: 0;
    font-weight: 400; }
  main.prepaid .paper.fui-collapse {
    margin-bottom: 12px; }
  main.prepaid .paper + .fui-button {
    margin-top: 20px; }
  main.prepaid .paper + h2 {
    margin-top: 88px; }
  main.prepaid .paper .h6-title {
    margin: 0;
    font-weight: 500; }
  main.prepaid .paper .rate-plan-box {
    height: 100%; }
  main.prepaid .paper section.fui-section-collapse {
    padding: 30px;
    background-color: #fafafa; }
    main.prepaid .paper section.fui-section-collapse .fui-container {
      padding: 0;
      margin: 0;
      width: 100%; }
  main.prepaid .paper h4 {
    font-weight: 500; }
  main.prepaid .paper .fui-button i {
    font-size: 30px !important;
    font-weight: 400; }
  main.prepaid .paper .fui-button img {
    margin-left: 8px;
    margin-bottom: 12px; }
  main.prepaid section.fui-solution-tab .tab-content {
    padding-bottom: 70px; }
    main.prepaid section.fui-solution-tab .tab-content .slick-slider button::after {
      font-size: 36px; }
    main.prepaid section.fui-solution-tab .tab-content h1 {
      font-size: 36px; }
  main.prepaid section.fui-solution-tab .slick-slide .fui-card ul {
    padding-bottom: 60px; }
  main.prepaid section.fui-solution-tab .slick-slide .fui-card .form-group {
    position: absolute;
    bottom: 36px; }
  main.prepaid section.fui-solution-tab .slick-slide .fui-card img {
    max-width: 240px; }
  main.prepaid .fui-horzonal-cards .fui-more {
    margin-top: 12px; }
  main.prepaid .fui-faq-collapse {
    background: #f1f3f5; }
  main.prepaid .fui-panel-information {
    padding: 60px 0;
    position: relative;
    margin: 0; }
    main.prepaid .fui-panel-information .collapse-header {
      font-weight: 500; }
    main.prepaid .fui-panel-information .collapse-header, main.prepaid .fui-panel-information .collapse-body {
      color: #5F6E84; }
      main.prepaid .fui-panel-information .collapse-header ol, main.prepaid .fui-panel-information .collapse-body ol {
        padding-left: 20px; }
  main.prepaid .airport-info {
    display: flex;
    align-items: center;
    margin-top: 44px;
    justify-content: center; }
    main.prepaid .airport-info img {
      max-width: 400px;
      margin-right: 60px; }
  main.prepaid .select-lang {
    position: relative;
    width: 100%;
    margin-bottom: -62px;
    display: flex;
    z-index: 29;
    padding-top: 24px; }
    main.prepaid .select-lang .fui-dropdown {
      width: 135px;
      background-color: rgba(0, 0, 0, 0.2); }
      main.prepaid .select-lang .fui-dropdown button {
        height: 40px;
        margin: 0 auto; }
        main.prepaid .select-lang .fui-dropdown button i {
          margin-left: 10px; }
      main.prepaid .select-lang .fui-dropdown .fui-item span.content {
        text-align: left;
        margin-left: 12px; }
  main.prepaid .fui-nav-tab.is-box-tab {
    margin-top: -36px !important;
    margin-bottom: 60px; }

#faq-007 .fui-faq-collapse {
  padding: 0;
  background-color: #f1f3f5; }

main.OO7 .ribbon-bg {
  background-image: url(/DigService/resources/cbu/prepaid/images/ribbon-pattern-1920.jpg);
  background-size: 100% auto;
  background-repeat-x: no-repeat;
  width: 100%;
  margin: 0; }

main.OO7 .paper {
  padding: 60px 30px; }
  main.OO7 .paper .body {
    margin-bottom: 48px; }
  main.OO7 .paper .fui-button {
    margin-bottom: 36px; }
  main.OO7 .paper .fui-dropdown {
    margin-bottom: 36px; }

main.OO7 .search-country {
  padding-top: 40px; }
  main.OO7 .search-country .fui-dropdown.is-button {
    width: 272px; }

main.OO7 .collapse-body article {
  line-height: 1.5; }

@media screen and (max-width: 960px) {
  main.OO7 .ribbon-bg {
    display: block; }
  main.OO7 .paper {
    padding: 30px 30px; }
    main.OO7 .paper p.body {
      margin-bottom: 30px; }
    main.OO7 .paper .fui-button {
      margin-bottom: 6px; }
  main.OO7 .fui-banner {
    min-height: 360px; }
  main.OO7 .MuiGrid-spacing-xs-6 > .MuiGrid-item {
    padding-top: 0 !important; }
  main.OO7 .search-country {
    padding-top: 28px; } }

@media screen and (max-width: 480px) {
  main.OO7 .search-country .fui-dropdown.is-button {
    width: 100%; } }

section.resend-bill .fui-dropdown.is-button {
  max-width: max-content;
  min-width: 300px; }

section.resend-bill .email-form {
  border: 2px solid #E8ECF0;
  display: flex;
  margin-bottom: 60px; }
  section.resend-bill .email-form--left {
    padding: 12px 24px;
    color: #5F6E84;
    font-weight: 500;
    flex: 0 0 285px;
    text-align: center; }
  section.resend-bill .email-form--right {
    padding: 12px 24px 12px 70px;
    background-color: #f1f3f5;
    color: #5F6E84;
    width: 100%; }

@media screen and (max-width: 960px) {
  section.resend-bill .email-form {
    margin-bottom: 30px; }
    section.resend-bill .email-form--left {
      flex: 0 0 76px;
      font-size: 14px; }
    section.resend-bill .email-form--right {
      font-size: 14px;
      padding-left: 6px;
      padding-right: 6px; } }

section.why-prepaid {
  padding-top: 0; }
  section.why-prepaid .head {
    font-size: 36px;
    font-weight: 500;
    margin-bottom: 60px; }
  section.why-prepaid ul {
    list-style-type: none;
    display: flex;
    flex-direction: row;
    padding: 0;
    justify-content: space-between; }
    section.why-prepaid ul li {
      display: flex;
      flex-direction: row;
      width: 100%; }
      section.why-prepaid ul li img {
        max-width: 160px;
        width: 100%; }
      section.why-prepaid ul li .info ul {
        display: flex;
        flex-direction: column; }
        section.why-prepaid ul li .info ul li {
          font-size: 18px;
          color: #5F6E84;
          align-items: center;
          margin-bottom: 10px; }
          section.why-prepaid ul li .info ul li i {
            margin-right: 10px; }

section.apply-steps {
  background-position: right;
  background-size: cover;
  padding-bottom: 48px; }
  section.apply-steps h2 {
    color: #5f7b80;
    margin-bottom: 12px; }
  section.apply-steps .subtitle, section.apply-steps .fui-card.box .fui-card-content .fui-card-title, .fui-card.box .fui-card-content section.apply-steps .fui-card-title {
    color: #8da0a4;
    margin-top: 0;
    font-weight: 500; }
  section.apply-steps ul {
    list-style-type: none;
    padding: 0;
    margin-top: 36px; }
    section.apply-steps ul li {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 12px; }
      section.apply-steps ul li .body {
        color: #5f7b80;
        font-weight: normal;
        margin: 0; }
      section.apply-steps ul li .circle {
        border: 2px solid #5f7b80;
        min-width: 36px;
        min-height: 36px;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        margin-right: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #5f7b80;
        font-size: 24px;
        font-weight: 500; }

section.foreign-prepaid {
  margin-top: 100px; }

section.how-to-dial h4 {
  font-weight: 500;
  margin-bottom: 24px; }

section.how-to-dial ul {
  list-style-type: none;
  padding-left: 0; }
  section.how-to-dial ul li {
    font-size: 16px;
    line-height: 1.9; }
    section.how-to-dial ul li::before {
      content: "•";
      color: #5F7B80;
      font-weight: bold;
      font-size: 28px;
      line-height: 0px;
      margin-top: 0px;
      margin-right: 6px;
      vertical-align: sub; }

section.how-to-dial .fui-panel {
  margin-bottom: 0; }

section.how-to-dial .fui-panel-information {
  padding: 30px 60px;
  background: #fafafa;
  box-shadow: 0 6px 12px 0 rgba(75, 88, 105, 0.12); }

section.how-to-dial .wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  white-space: nowrap; }
  section.how-to-dial .wrapper .d-column .d-row {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    white-space: nowrap; }
  section.how-to-dial .wrapper .d-column .d-flex {
    flex-direction: column; }
    section.how-to-dial .wrapper .d-column .d-flex:nth-child(1) {
      margin-right: 12px; }
  section.how-to-dial .wrapper .d-column:nth-child(1) {
    margin-right: 12px; }
  section.how-to-dial .wrapper h4 {
    font-weight: normal;
    margin: 18px 0 12px 0; }
  section.how-to-dial .wrapper .bg-gray {
    background-color: #eff1f2;
    padding: 24px 18px; }
  section.how-to-dial .wrapper > div {
    width: 100%; }
    section.how-to-dial .wrapper > div span {
      font-size: 18px;
      font-weight: normal; }

@media screen and (max-width: 1140px) {
  section.how-to-dial .wrapper h2 {
    font-size: 28px; } }

main.notice {
  position: relative; }
  main.notice .fui-panel {
    width: 100%; }
  main.notice .fui-breadcrumb-container {
    position: relative; }
  main.notice .sidebar .box {
    background-image: url(/DigService/resources/cbu/e-service/images/result-bg.svg);
    padding: 30px 20px;
    border: 0;
    text-align: left;
    box-shadow: none;
    background-size: contain;
    background-position: right bottom;
    background-color: #EFF4F1;
    background-repeat: no-repeat; }
  main.notice .sidebar .mainTitle {
    font-size: 24px;
    display: flex;
    flex-direction: column;
    padding: 0;
    font-weight: 500; }
    main.notice .sidebar .mainTitle::before {
      content: "";
      background-image: url(/DigService/resources/cbu/e-service/images/calculator.svg);
      width: 30px;
      height: 30px;
      position: relative;
      margin-bottom: 12px; }
      main.notice .sidebar .mainTitle::before img {
        margin-right: auto; }
  main.notice .sidebar p {
    font-size: 18px;
    color: #202020;
    margin-bottom: 30px; }
  main.notice .sidebar a {
    font-size: 16px;
    min-width: auto;
    padding: 12px;
    background: none;
    height: auto;
    border: 2px solid #202020;
    font-weight: 500;
    width: 100%;
    line-height: normal;
    line-height: initial; }
  main.notice .fui-panel-information {
    background-color: #FFFFFF;
    box-shadow: 0 6px 12px 0 rgba(75, 88, 105, 0.12);
    margin-bottom: 12px; }
    main.notice .fui-panel-information .collapse-header {
      flex-direction: column; }
    main.notice .fui-panel-information .descbox {
      background-color: #fafafa;
      padding: 30px;
      margin-top: 24px; }
      main.notice .fui-panel-information .descbox .mainTitle {
        color: #5f6e84;
        font-size: 16px;
        font-weight: 500; }
      main.notice .fui-panel-information .descbox .unfold {
        margin-top: 6px; }
      main.notice .fui-panel-information .descbox ol {
        padding-left: 16px; }
        main.notice .fui-panel-information .descbox ol li {
          color: #5f6e84;
          font-size: 14px;
          line-height: 24px;
          font-weight: normal; }
      main.notice .fui-panel-information .descbox .mainTitle {
        padding-left: 14px; }
  main.notice .fui-pagination li.selected a {
    color: #FFFFFF !important; }
  main.notice .fui-pagination li a {
    color: #202020 !important;
    font-weight: 500; }
    main.notice .fui-pagination li a::after {
      display: none !important; }
  main.notice a.fui-button i {
    font-size: 24px !important;
    font-weight: 400; }

@media screen and (max-width: 960px) {
  .fui-ebu.fui-banner .fui-container {
    height: 100%; }
  .fui-ebu.fui-banner .fui-tab-container .fui-tab-body {
    bottom: 30px; }
    .fui-ebu.fui-banner .fui-tab-container .fui-tab-body .tab-content {
      padding-bottom: 0; }
      .fui-ebu.fui-banner .fui-tab-container .fui-tab-body .tab-content .tab-title {
        max-width: 350px; }
      .fui-ebu.fui-banner .fui-tab-container .fui-tab-body .tab-content .action {
        width: 100%;
        display: flex;
        max-width: 62vw; }
        .fui-ebu.fui-banner .fui-tab-container .fui-tab-body .tab-content .action .is-secondary {
          background-color: #FFFFFF; }
  .fui-banner .fui-tab-container .fui-tab-body .fui-tab-pane a.fui-button {
    max-width: none;
    max-width: initial;
    width: 100%;
    min-width: 0;
    min-width: initial; }
    .fui-banner .fui-tab-container .fui-tab-body .fui-tab-pane a.fui-button:nth-last-child(1) {
      margin-right: 0; }
  .fui-cbu.fui-banner .fui-tab-container .fui-tab-body .fui-tab-pane a.fui-button {
    min-width: 160px;
    width: auto;
    display: inline-block; }
  main.prepaid .ribbon-bg {
    display: block; }
  main.prepaid .fui-banner.is-product.is-style-2 {
    min-height: auto;
    height: 360px; }
    main.prepaid .fui-banner.is-product.is-style-2 .caption {
      top: 0; }
    main.prepaid .fui-banner.is-product.is-style-2 p.subtitle h6, main.prepaid .fui-banner.is-product.is-style-2 .fui-card.box .fui-card-content p.fui-card-title h6, .fui-card.box .fui-card-content main.prepaid .fui-banner.is-product.is-style-2 p.fui-card-title h6 {
      margin: 0 !important; }
  main.prepaid .fui-tab-pane section {
    padding: 0; }
  main.prepaid .fui-tab-pane .fui-figure {
    max-width: 300px;
    margin: 0 auto; }
  main.prepaid .program-internet-mb {
    display: block; }
  main.prepaid .program-internet {
    display: none; }
  main.prepaid .fui-panel .fui-container {
    padding: 0; }
  main.prepaid .fui-tab-pane.is-active {
    z-index: 19; }
  main.prepaid .fui-collapse.is-open .collapse-header {
    margin-bottom: 30px; }
  main.prepaid .paper .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-2 {
    display: block; }
  main.prepaid .paper .rate-plan-box {
    padding: 20px;
    margin-bottom: 12px; }
    main.prepaid .paper .rate-plan-box .d-flex {
      flex-direction: column; }
  main.prepaid .paper + .fui-button {
    margin-top: 8px; }
  main.prepaid .paper + h2 {
    margin-top: 60px; }
  main.prepaid .paper img.w-100 {
    width: 100%; }
  main.prepaid .paper h6.paper-h6 {
    margin-bottom: 0; }
  main.prepaid .paper h6.is-text-darkgreen {
    margin-top: 18px; }
  main.prepaid .paper a.fui-button {
    margin-top: 20px; }
  main.prepaid ul.green-dot-list {
    margin: 18px 0 30px 0; }
  main.prepaid ul.step-list {
    margin-top: 18px; }
    main.prepaid ul.step-list li {
      align-items: baseline;
      padding-bottom: 28px;
      overflow: hidden;
      margin-bottom: 0; }
      main.prepaid ul.step-list li .circle {
        min-width: 40px;
        min-height: 40px;
        width: 40px;
        height: 40px;
        font-size: 24px;
        border: 4px solid #fff; }
        main.prepaid ul.step-list li .circle::before {
          top: 36px;
          left: 18px;
          height: 100vh; }
        main.prepaid ul.step-list li .circle::after {
          content: "";
          border: 4px solid #ffffff;
          width: 36px;
          height: 36px;
          border-radius: 50%;
          z-index: 11;
          display: inline-block;
          position: absolute;
          left: -4px;
          top: -4px; }
  main.prepaid section.fui-solution-tab .tab-content {
    padding-bottom: 0; }
    main.prepaid section.fui-solution-tab .tab-content small {
      color: #202020; }
    main.prepaid section.fui-solution-tab .tab-content h1 {
      max-width: 80%;
      font-size: 28px;
      line-height: 1.5; }
      main.prepaid section.fui-solution-tab .tab-content h1.add-value-title {
        max-width: 100%; }
  main.prepaid section.fui-solution-tab .slick-slide .fui-card .subtitle, main.prepaid section.fui-solution-tab .slick-slide .fui-card.box .fui-card-content .fui-card-title {
    font-size: 16px; }
  main.prepaid section.fui-solution-tab .slick-slide .fui-card img {
    max-width: 168px; }
  main.prepaid section.fui-solution-tab .slick-slide .fui-card .form-group .fui-button {
    padding: 0;
    margin: 0; }
  main.prepaid section.fui-solution-tab .slick-slide .fui-card .form-group.two-buttons {
    padding: 0 20px; }
    main.prepaid section.fui-solution-tab .slick-slide .fui-card .form-group.two-buttons .fui-button {
      margin-right: 12px; }
      main.prepaid section.fui-solution-tab .slick-slide .fui-card .form-group.two-buttons .fui-button:nth-last-child(1) {
        margin-right: 0; }
  main.prepaid .fui-panel {
    padding: 30px 20px !important; }
  main.prepaid .fui-panel-tab {
    margin: 0; }
  main.prepaid .fui-faq-collapse {
    padding: 0; }
  main.prepaid .fui-horzonal-cards.is-narrow .slick-arrow {
    display: none !important; }
  main.prepaid section.why-prepaid {
    background-size: cover; }
    main.prepaid section.why-prepaid .head {
      font-size: 28px; }
    main.prepaid section.why-prepaid ul {
      flex-direction: column; }
      main.prepaid section.why-prepaid ul li {
        justify-content: center; }
        main.prepaid section.why-prepaid ul li img {
          max-width: 130px;
          margin-right: 28px; }
      main.prepaid section.why-prepaid ul .info h4 {
        margin-bottom: 18px; }
  main.prepaid section.apply-steps {
    background-color: #eff1f2;
    background-size: contain;
    padding-bottom: 72px;
    background-position: bottom;
    background-repeat: no-repeat; }
    main.prepaid section.apply-steps .subtitle, main.prepaid section.apply-steps .fui-card.box .fui-card-content .fui-card-title, .fui-card.box .fui-card-content main.prepaid section.apply-steps .fui-card-title {
      font-size: 16px; }
    main.prepaid section.apply-steps ul {
      margin-bottom: 0;
      margin-top: 8px; }
      main.prepaid section.apply-steps ul li {
        font-size: 16px;
        line-height: 1.75; }
        main.prepaid section.apply-steps ul li .circle {
          font-size: 20px; }
        main.prepaid section.apply-steps ul li .body {
          font-size: 16px; }
  main.prepaid section.how-to-dial .wrapper {
    flex-direction: column; }
    main.prepaid section.how-to-dial .wrapper .d-column .d-row {
      flex-direction: column; }
  main.prepaid .fui-panel-information {
    padding: 30px 20px; }
  main.prepaid .airport-info {
    flex-direction: column;
    margin-top: 30px; }
    main.prepaid .airport-info img {
      margin-right: 0;
      max-width: 100%; }
  .ribbon-bg {
    background-image: url(/DigService/resources/cbu/prepaid/images/cbu_ribbon_pattern_bkg@2x.jpg); }
  main.notice .fui-panel {
    flex-direction: column; }
  main.notice .sidebar .box a {
    max-width: 190px; } }

@media screen and (max-width: 480px) {
  .fui-ebu.fui-banner .fui-tab-container .fui-tab-body .tab-content .action {
    max-width: 100%; } }

.is-en * {
  font-family: ‘SF Pro’, ‘Segoe UI’, Helvetica Neue, Helvetica, Arial, sans-serif; }

.is-en .fui-horzonal-cards, .is-en .fui-corp-content {
  padding: 40px 0; }

@media screen and (max-width: 960px) {
  .is-en .service-entry .fui-container .fui-card .fui-card-action {
    height: auto; }
  .is-en .fui-banner .fui-tab-container .fui-tab-header .fui-tab .fui-tab-item {
    width: 33.333%; }
  .is-en .tab-content .action {
    width: 100%;
    display: flex; }
  .is-en .fui-banner .fui-tab-container .fui-tab-body {
    bottom: 30px; }
    .is-en .fui-banner .fui-tab-container .fui-tab-body .fui-tab-pane a.fui-button {
      max-width: none;
      max-width: initial;
      width: 100%;
      min-width: 0 !important;
      min-width: initial !important; }
      .is-en .fui-banner .fui-tab-container .fui-tab-body .fui-tab-pane a.fui-button:nth-last-child(1) {
        margin-right: 0; } }

@media screen and (max-width: 768px) {
  .is-en .fui-horzonal-cards {
    padding: 60px 0 0 0; }
    .is-en .fui-horzonal-cards .fui-corp-content {
      padding: 40px 0 0; }
    .is-en .fui-horzonal-cards .service-entry .fui-container .fui-card .fui-card-action {
      height: 76px; }
      .is-en .fui-horzonal-cards .service-entry .fui-container .fui-card .fui-card-action .fui-card-content {
        padding: 12px;
        display: flex;
        align-items: center;
        justify-content: center; }
        .is-en .fui-horzonal-cards .service-entry .fui-container .fui-card .fui-card-action .fui-card-content h4.fui-card-title {
          line-height: 26px; } }

@media screen and (max-width: 400px) {
  .is-en .fui-banner .fui-tab-container .fui-tab-body {
    width: 100%; }
    .is-en .fui-banner .fui-tab-container .fui-tab-body .fui-tab-pane a.fui-button {
      padding: 0 20px; }
    .is-en .fui-banner .fui-tab-container .fui-tab-body .fui-tab-pane h3.tab-title {
      max-width: 340px;
      font-size: 30px;
      line-height: 36px; } }

@media screen and (max-width: 599px) {
  .is-en .fui-banner .fui-banner-bg .main-image {
    width: 100%;
    height: auto;
    top: -40px; }
  .is-en .fui-banner .fui-tab-container .fui-tab-body .fui-tab-pane a.fui-button {
    padding: 0 20px; }
  .is-en .fui-banner .fui-tab-container .fui-tab-body .fui-tab-pane h3.tab-title {
    max-width: 340px;
    font-size: 30px;
    line-height: 36px; } }

@media screen and (max-width: 767px) {
  section.service-tags .service-tag-container .fui-button.is-tag {
    padding-left: 24px;
    padding-right: 24px;
    height: 30px;
    line-height: 26px;
    min-width: 0;
    margin-right: 12px;
    margin-bottom: 12px;
    font-size: 0.875rem; } }

@media screen and (min-width: 768px) {
  section.fui-content {
    padding-top: 60px;
    padding-bottom: 90px; }
    section.fui-content img {
      display: block;
      margin: 30px auto; }
  .fui-container section.fui-section-promo {
    margin-left: 0;
    margin-right: 0; }
    .fui-container section.fui-section-promo:first-child {
      margin-top: 60px; }
  section {
    padding: 40px 0; }
  section.service-tags {
    z-index: 1;
    background-size: cover; }
    section.service-tags .service-tag-container .service-scroller {
      width: 100%;
      text-align: center; }
      section.service-tags .service-tag-container .service-scroller .fui-button {
        line-height: 36px; } }

@media screen and (max-width: 768px) and (min-width: 600px) {
  section.fui-content-image {
    padding-bottom: 170px; } }

@media screen and (min-width: 769px) {
  section.fui-content-image > img {
    position: absolute;
    width: auto;
    height: 100%; }
  section.fui-content-image.is-image-left > img {
    left: 0; }
  section.fui-content-image.is-image-right > img {
    right: 0; } }

@media only screen and (device-width: 812px) and (device-height: 375px) and (orientation: landscape) {
  section.fui-content-image {
    padding-bottom: 190px; }
    section.fui-content-image > img {
      height: 281px; } }

@media screen and (min-width: 960px) {
  section.fui-nav-tab {
    position: relative; }
  section.fui-horzonal-cards .section-title {
    max-width: 100%; }
  section.fui-horzonal-cards .section-bg img {
    height: 312px; }
  section.fui-horzonal-cards .fui-card .fui-card-action .fui-card-content {
    min-height: 240px; }
  section.fui-horzonal-cards .fui-card .fui-card-action > .fui-card-image + .fui-card-caption > .fui-card-content {
    min-height: 0; }
  section.fui-horzonal-cards .bg {
    top: auto;
    bottom: 0; }
  section.fui-content-image {
    display: block;
    padding-bottom: 60px; }
  section.fui-corp-content .fui-marquee-box .marquee-landscape-item .marquee-landscape-txt {
    padding: 0;
    margin: 0 45px; }
    section.fui-corp-content .fui-marquee-box .marquee-landscape-item .marquee-landscape-txt img {
      width: auto;
      max-height: 100px; }
  section.fui-corp-content.is-style-2 .fui-marquee-box .marquee-landscape-item .marquee-landscape-txt {
    padding: 0;
    margin: 0 15px; }
    section.fui-corp-content.is-style-2 .fui-marquee-box .marquee-landscape-item .marquee-landscape-txt img {
      width: 160px;
      height: auto;
      max-height: none;
      opacity: 0.7; }
  section.fui-content ~ section.fui-content.bg-arrow, section.fui-content.bg-arrow {
    background-size: auto 300px;
    padding: 60px 0; }
  section {
    padding: 60px 0 90px; }
    section .section-title,
    section .fui-section-header {
      font-size: 36px;
      margin-bottom: 36px; }
    section .section-title + .fui-cards,
    section .fui-section-header + .fui-cards {
      margin-top: -8px; }
  section.service-tag,
  section.product-map {
    padding: 60px 0; }
  section.product-map .fui-collapse .collapse-body {
    height: 256px;
    overflow: hidden; }
  section.product-map .fui-collapse .collapse-trigger {
    display: block;
    margin-top: 16px; }
  section.product-map .fui-collapse .product-map-list {
    flex-direction: row;
    flex-flow: flow; }
    section.product-map .fui-collapse .product-map-list .produc-map-item {
      flex: 1 1;
      flex-grow: 1;
      max-width: 25%; }
      section.product-map .fui-collapse .product-map-list .produc-map-item .fui-item .cotent {
        display: block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis; }
  section.fui-section-promo {
    min-height: 156px;
    padding: 40px 15px; }
    section.fui-section-promo[class*='bg-pattern'] {
      background-size: auto 100%; }
    section.fui-section-promo.bg-pattern-gray {
      background-image: url(/DigService/resources/common/images/patternd-darkgray-banner.png); }
    section.fui-section-promo.bg-pattern-green {
      background-image: url(/DigService/resources/common/images/cbu-pattern-banner-1920x156.jpg); }
    section.fui-section-promo.bg-pattern-blue {
      background-image: url(/DigService/resources/common/images/patternd-blue-banner.jpg); }
    section.fui-section-promo.with-pattern::after {
      content: "";
      width: 50%;
      background-image: url(/DigService/resources/common/images/patternd-darkgray-banner.png);
      background-size: auto 100%; }
    section.fui-section-promo.with-pattern .MuiGrid-root.MuiGrid-item.action {
      display: flex; }
    section.fui-section-promo .MuiGrid-root.MuiGrid-item {
      align-items: center;
      align-content: center; }
    section.fui-section-promo .MuiGrid-root.MuiGrid-item.action .fui-button {
      margin-top: 0; }
    section.fui-section-promo.is-edm {
      padding-top: 42px;
      padding-bottom: 42px;
      background-size: cover; }
      section.fui-section-promo.is-edm .fui-section-promo-title {
        font-size: 20px;
        margin-bottom: 30px; }
    section.fui-section-promo .MuiGrid-root.MuiGrid-item.action {
      justify-content: flex-end;
      align-content: center;
      align-items: center; }
      section.fui-section-promo .MuiGrid-root.MuiGrid-item.action .fui-button {
        min-width: 190px; } }

section.form-status img {
  width: 232px; }

section.form-status .fui-success-title {
  margin-top: 24px;
  margin-bottom: 0; }

section.form-status .fui-success-description {
  margin-top: 12px;
  margin-bottom: 24px; }

@media screen and (min-width: 960px) {
  section.form-status {
    padding-top: 90px; } }

.fui-footer-promo {
  position: relative;
  background-color: #202020;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: white;
  z-index: 2;
  height: 270px;
  padding: 0;
  overflow: hidden;
  display: flex; }
  .fui-footer-promo .promotion-container {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
    text-align: center; }
  .fui-footer-promo h4.fui-section-promo-title {
    margin: 0;
    font-size: 20px; }
    .fui-footer-promo h4.fui-section-promo-title + p {
      margin: 6px 0 0; }
  .fui-footer-promo .promotion-container > * {
    position: relative;
    display: flex;
    align-items: center;
    align-content: center;
    width: 100%;
    padding: 0; }
    .fui-footer-promo .promotion-container > * h4.fui-section-promo-title {
      margin: 0; }
      .fui-footer-promo .promotion-container > * h4.fui-section-promo-title + p {
        margin: 6px 0 0; }
    .fui-footer-promo .promotion-container > * .promotion-button {
      border: 2px solid #FFFFFF;
      color: #FFFFFF;
      margin: 8px 0;
      padding: 0 8px;
      line-height: 36px;
      width: 100%;
      height: 40px;
      text-align: center;
      min-width: 192px; }
  .fui-footer-promo > a:link,
  .fui-footer-promo > a:visited {
    display: flex;
    color: #FFFFFF;
    width: 100%;
    padding: 20px 30px;
    min-height: 156px;
    cursor: pointer;
    z-index: 2; }
    .fui-footer-promo > a:link:hover,
    .fui-footer-promo > a:visited:hover {
      color: #FFFFFF;
      opacity: 1 !important; }
  @media screen and (min-width: 768px) {
    .fui-footer-promo {
      height: 156px; }
      .fui-footer-promo .promotion-container {
        flex-direction: row; }
        .fui-footer-promo .promotion-container > * {
          width: 50%;
          padding: 0 16px; }
        .fui-footer-promo .promotion-container h4.fui-section-promo-title {
          font-size: 24px;
          text-align: left; }
        .fui-footer-promo .promotion-container > .action {
          display: flex;
          align-content: center;
          align-items: center;
          justify-content: flex-end; }
          .fui-footer-promo .promotion-container > .action .promotion-button {
            width: auto;
            min-width: 190px; } }

main header.fui-header {
  top: 0; }

div[data-cq-data-path] > section.fui-content:not(.video-container) {
  background-size: auto 200px;
  background-image: url(/DigService/resources/common/images/section-bkg-pattern-2.png); }

div[data-cq-data-path*="panelcontent"] + div[data-cq-data-path*="panelbutton"] {
  margin-top: -40px;
  margin-bottom: 40px; }

div[data-cq-data-path*="breadcrumb"] + div section.fui-content:not(.video-container),
div[data-cq-data-path*="banner"] + div section.fui-content:not(.video-container) {
  background-size: auto 160px;
  background-image: url(/DigService/resources/common/images/section-bkg-pattern-1.png); }
  @media screen and (min-width: 960px) {
    div[data-cq-data-path*="breadcrumb"] + div section.fui-content:not(.video-container),
    div[data-cq-data-path*="banner"] + div section.fui-content:not(.video-container) {
      background-size: auto 300px; } }

section.fui-content div[data-cq-data-path]:not(:first-child) h2 {
  margin-top: 60px; }

.section.new.aem-Grid-newComponent {
  margin: 0;
  height: auto; }

.border--bottom {
  border-bottom: 1px solid #E8ECF0; }

.border--right {
  border-right: 1px solid #E8ECF0; }

.border--left {
  border-left: 1px solid #E8ECF0; }

.border--top {
  border-top: 1px solid #E8ECF0; }

input, textarea {
  border-radius: 0; }

.keyword-input-container {
  position: relative;
  z-index: 2;
  box-shadow: 0 2px 6px 0 rgba(75, 88, 105, 0.2); }
  .keyword-input-container:hover, .keyword-input-container:focus {
    box-shadow: 0 6px 12px 0 rgba(75, 88, 105, 0.24); }
  .keyword-input-container .fui-input-group.is-keyword,
  .keyword-input-container .fui-input-group.is-keyword:hover,
  .keyword-input-container .fui-input-group.is-keyword:focus {
    box-shadow: none; }
  .keyword-input-container + .keyword-container {
    z-index: 0; }

.fui-input-group {
  position: relative;
  display: flex;
  box-shadow: 0 18px 36px 0 rgba(75, 88, 105, 0.12);
  z-index: 2; }
  .fui-input-group .error-message {
    text-align: left;
    color: #EA574D;
    font-weight: 500;
    position: absolute;
    bottom: -32px;
    left: 0; }
  .fui-input-group .fui-input {
    flex: 1 1 auto; }
    .fui-input-group .fui-input input {
      display: block;
      width: 100%;
      height: 50px;
      border: 0;
      font-size: 1rem;
      border: 0;
      -webkit-appearance: none;
      box-shadow: none;
      padding: 12px 16.8px; }
      .fui-input-group .fui-input input:-ms-input-placeholder {
        color: #BFBFBF; }
      .fui-input-group .fui-input input::placeholder {
        color: #BFBFBF; }
  .fui-input-group.is-keyword {
    position: relative;
    z-index: 9;
    box-shadow: 0 2px 6px 0 rgba(75, 88, 105, 0.24);
    transition: all 0.3s ease; }
    .fui-input-group.is-keyword:hover, .fui-input-group.is-keyword:focus, .fui-input-group.is-keyword.is-focus {
      box-shadow: 0 6px 12px 0 rgba(75, 88, 105, 0.24); }
    .fui-input-group.is-keyword .fui-input {
      position: relative; }
      .fui-input-group.is-keyword .fui-input input {
        height: 40px;
        border: solid 2px #FFFFFF;
        padding-right: 120px; }
      .fui-input-group.is-keyword .fui-input .reset {
        position: absolute;
        right: 81px;
        top: 0;
        bottom: 0;
        width: 32px;
        height: 24px;
        margin: auto;
        color: #202020; }
        .fui-input-group.is-keyword .fui-input .reset i {
          font-size: 24px;
          margin-top: 2px; }
    .fui-input-group.is-keyword .fui-action {
      position: absolute;
      right: 0;
      top: 0;
      width: 81px; }
      .fui-input-group.is-keyword .fui-action .fui-button.is-primary {
        width: 100%;
        height: 41px; }
        .fui-input-group.is-keyword .fui-action .fui-button.is-primary .text {
          line-height: 41px; }
    .fui-input-group.is-keyword + .fui-dropdown {
      position: absolute;
      width: 100%;
      top: 100%;
      background: #FFFFFF;
      box-shadow: 0 12px 15px 0 rgba(32, 32, 32, 0.3);
      z-index: 9;
      display: block;
      padding: 14px 0;
      overflow-x: hidden;
      overflow-y: auto;
      max-height: 294px; }
      .fui-input-group.is-keyword + .fui-dropdown .fui-item {
        padding-left: 12px;
        padding-right: 12px; }
        .fui-input-group.is-keyword + .fui-dropdown .fui-item.is-selected {
          background: #E8ECF0; }
        .fui-input-group.is-keyword + .fui-dropdown .fui-item .prefix {
          margin-right: 6px; }
          .fui-input-group.is-keyword + .fui-dropdown .fui-item .prefix i {
            opacity: 0.5;
            font-size: 24px;
            color: #202020; }
        .fui-input-group.is-keyword + .fui-dropdown .fui-item .highlight {
          color: #EF3123; }
  .fui-input-group .subscribe {
    position: absolute;
    width: 100%; }
    .fui-input-group .subscribe .email-pending {
      width: 105px;
      min-width: 0px;
      position: absolute;
      right: 0;
      height: 50px; }
  .fui-input-group .fui-action .fui-button.is-primary {
    margin: 0 !important;
    min-width: 80px;
    width: 80px;
    height: 50px;
    white-space: nowrap; }
    .fui-input-group .fui-action .fui-button.is-primary::after {
      display: none; }
  .fui-input-group .fui-action.only-icon {
    width: auto; }
    .fui-input-group .fui-action.only-icon .fui-button {
      width: auto;
      min-width: auto; }

.fui-input-group.no-shadow {
  box-shadow: none; }
  @media screen and (min-width: 769px) {
    .fui-input-group.no-shadow .fui-action .fui-button.is-primary {
      height: 50px;
      width: 105px; } }

@media screen and (min-width: 960px) {
  .fui-input-group.is-keyword .fui-input input {
    height: 50px; }
  .fui-input-group.is-keyword .fui-input .reset {
    right: 105px; }
  .fui-input-group.is-keyword .fui-action {
    width: 105px; }
    .fui-input-group.is-keyword .fui-action .fui-button.is-primary {
      height: 50px;
      width: 105px; }
      .fui-input-group.is-keyword .fui-action .fui-button.is-primary .text {
        height: 50px;
        line-height: 50px; }
    .fui-input-group.is-keyword .fui-action.only-icon {
      width: auto; }
      .fui-input-group.is-keyword .fui-action.only-icon .fui-button {
        width: auto; } }

input {
  -webkit-appearance: none; }

input:focus,
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  background: #FFF !important;
  color: #202020 !important;
  box-shadow: 0 0 0 1000px white inset;
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
  -webkit-text-fill-color: #333 !important; }

.fui-input-group.is-keyword + .fui-dropdown .fui-item {
  cursor: pointer; }
  .fui-input-group.is-keyword + .fui-dropdown .fui-item:hover, .fui-input-group.is-keyword + .fui-dropdown .fui-item:focus {
    color: #202020 !important; }

.fui-search-panel {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  display: none;
  background: #FFF;
  right: -100%;
  transition: all 0.3s ease;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 699; }
  .fui-search-panel .fui-search-header {
    position: relative;
    height: 50px; }
    .fui-search-panel .fui-search-header .fui-container {
      height: 50px; }
    .fui-search-panel .fui-search-header .close {
      position: absolute;
      right: 10px;
      font-size: 24px;
      top: 0;
      bottom: 0;
      padding: 0;
      width: 32px;
      height: 32px;
      margin: auto;
      border: 0;
      background: none; }
    .fui-search-panel .fui-search-header .fui-navbar-menu {
      display: none;
      align-content: center;
      align-items: center;
      justify-content: center;
      justify-items: center; }
  .fui-search-panel .fui-search-body {
    flex: 1 1 auto;
    padding: 32px 0 16px; }
    .fui-search-panel .fui-search-body h2 {
      margin-top: 0; }
    .fui-search-panel .fui-search-body .search-helper {
      display: flex;
      width: 100%;
      max-width: 400px;
      align-content: center;
      align-items: center;
      justify-content: space-between;
      justify-items: space-between;
      margin: 48px auto; }
      .fui-search-panel .fui-search-body .search-helper .search-item {
        display: flex;
        width: 100px;
        flex-direction: column;
        align-content: center;
        align-items: center;
        margin: 0; }
        .fui-search-panel .fui-search-body .search-helper .search-item img {
          width: 60px;
          height: auto; }
        .fui-search-panel .fui-search-body .search-helper .search-item .text {
          font-weight: 500;
          margin-top: 12px; }
    .fui-search-panel .fui-search-body .fui-button.is-label {
      line-height: 26px; }
      .fui-search-panel .fui-search-body .fui-button.is-label:hover {
        color: #202020; }
  .fui-search-panel .fui-search-footer {
    padding: 24px 0;
    text-align: center; }
  .fui-search-panel .inline-divider {
    display: inline-block;
    width: 1px;
    height: 20px;
    background: #D6D6D6;
    margin: 0 24px;
    vertical-align: middle; }
  @media screen and (min-width: 960px) {
    .fui-search-panel {
      overflow: hidden; } }

header.is-search-open .fui-search-panel {
  position: fixed;
  right: 0;
  display: block;
  overflow-y: auto;
  max-height: calc(100vh - 40px); }

.fui-search-filter {
  background: #FFFFFF; }
  .fui-search-filter .fui-container {
    padding: 0; }
  .fui-search-filter .fui-tab.with-scroller .fui-tab-item::before, .fui-search-filter .fui-tab.with-scroller::before {
    display: none; }
  .fui-search-filter .fui-tab.with-scroller .fui-tab-item {
    min-width: 102px; }

@media screen and (min-width: 960px) {
  .fui-search-panel {
    position: absolute;
    top: 40px;
    height: 0px;
    right: 0; }
    .fui-search-panel .fui-search-header {
      height: 60px; }
      .fui-search-panel .fui-search-header .fui-navbar-menu {
        display: flex !important; }
      .fui-search-panel .fui-search-header .close {
        right: 64px;
        top: 6px; }
  header.is-search-open .fui-search-panel {
    height: 632px; } }

body a.fui-button,
body a.fui-button:link,
body a.fui-button:visited,
body button.fui-button,
body .fui-button {
  position: relative;
  display: inline-block;
  white-space: nowrap;
  padding: 0 16px;
  border: 0;
  font-size: 1rem;
  min-width: 140px;
  text-align: center;
  opacity: 1;
  vertical-align: top;
  font-weight: 500;
  margin-right: 8px;
  margin-bottom: 16px;
  transition: all 0.3s ease;
  background: none;
  line-height: 0;
  cursor: pointer;
  height: 40px; }
  body a.fui-button::after,
  body a.fui-button:link::after,
  body a.fui-button:visited::after,
  body button.fui-button::after,
  body .fui-button::after {
    display: none;
    background: none; }
  body a.fui-button.is-block,
  body a.fui-button:link.is-block,
  body a.fui-button:visited.is-block,
  body button.fui-button.is-block,
  body .fui-button.is-block {
    width: 100%; }
  body a.fui-button .text,
  body a.fui-button:link .text,
  body a.fui-button:visited .text,
  body button.fui-button .text,
  body .fui-button .text {
    display: inline-block;
    height: 40px;
    line-height: 40px; }
  body a.fui-button *,
  body a.fui-button:link *,
  body a.fui-button:visited *,
  body button.fui-button *,
  body .fui-button * {
    vertical-align: middle; }
  body a.fui-button.disabled, body a.fui-button[disabled], body a.fui-button[disabled]:focus, body a.fui-button.disabled:focus, body a.fui-button.disabled:hover, body a.fui-button.disabled:active, body a.fui-button[disabled]:hover, body a.fui-button[disabled]:active, body a.fui-button.disabled.hover, body a.fui-button[disabled].hover,
  body a.fui-button:link.disabled,
  body a.fui-button:link[disabled],
  body a.fui-button:link[disabled]:focus,
  body a.fui-button:link.disabled:focus,
  body a.fui-button:link.disabled:hover,
  body a.fui-button:link.disabled:active,
  body a.fui-button:link[disabled]:hover,
  body a.fui-button:link[disabled]:active,
  body a.fui-button:link.disabled.hover,
  body a.fui-button:link[disabled].hover,
  body a.fui-button:visited.disabled,
  body a.fui-button:visited[disabled],
  body a.fui-button:visited[disabled]:focus,
  body a.fui-button:visited.disabled:focus,
  body a.fui-button:visited.disabled:hover,
  body a.fui-button:visited.disabled:active,
  body a.fui-button:visited[disabled]:hover,
  body a.fui-button:visited[disabled]:active,
  body a.fui-button:visited.disabled.hover,
  body a.fui-button:visited[disabled].hover,
  body button.fui-button.disabled,
  body button.fui-button[disabled],
  body button.fui-button[disabled]:focus,
  body button.fui-button.disabled:focus,
  body button.fui-button.disabled:hover,
  body button.fui-button.disabled:active,
  body button.fui-button[disabled]:hover,
  body button.fui-button[disabled]:active,
  body button.fui-button.disabled.hover,
  body button.fui-button[disabled].hover,
  body .fui-button.disabled,
  body .fui-button[disabled],
  body .fui-button[disabled]:focus,
  body .fui-button.disabled:focus,
  body .fui-button.disabled:hover,
  body .fui-button.disabled:active,
  body .fui-button[disabled]:hover,
  body .fui-button[disabled]:active,
  body .fui-button.disabled.hover,
  body .fui-button[disabled].hover {
    cursor: default !important;
    pointer-events: none;
    color: #8A8A8A !important;
    background: #D6D6D6 !important;
    border-color: transparent !important;
    opacity: 0.7; }
    body a.fui-button.disabled .text, body a.fui-button[disabled] .text, body a.fui-button[disabled]:focus .text, body a.fui-button.disabled:focus .text, body a.fui-button.disabled:hover .text, body a.fui-button.disabled:active .text, body a.fui-button[disabled]:hover .text, body a.fui-button[disabled]:active .text, body a.fui-button.disabled.hover .text, body a.fui-button[disabled].hover .text,
    body a.fui-button:link.disabled .text,
    body a.fui-button:link[disabled] .text,
    body a.fui-button:link[disabled]:focus .text,
    body a.fui-button:link.disabled:focus .text,
    body a.fui-button:link.disabled:hover .text,
    body a.fui-button:link.disabled:active .text,
    body a.fui-button:link[disabled]:hover .text,
    body a.fui-button:link[disabled]:active .text,
    body a.fui-button:link.disabled.hover .text,
    body a.fui-button:link[disabled].hover .text,
    body a.fui-button:visited.disabled .text,
    body a.fui-button:visited[disabled] .text,
    body a.fui-button:visited[disabled]:focus .text,
    body a.fui-button:visited.disabled:focus .text,
    body a.fui-button:visited.disabled:hover .text,
    body a.fui-button:visited.disabled:active .text,
    body a.fui-button:visited[disabled]:hover .text,
    body a.fui-button:visited[disabled]:active .text,
    body a.fui-button:visited.disabled.hover .text,
    body a.fui-button:visited[disabled].hover .text,
    body button.fui-button.disabled .text,
    body button.fui-button[disabled] .text,
    body button.fui-button[disabled]:focus .text,
    body button.fui-button.disabled:focus .text,
    body button.fui-button.disabled:hover .text,
    body button.fui-button.disabled:active .text,
    body button.fui-button[disabled]:hover .text,
    body button.fui-button[disabled]:active .text,
    body button.fui-button.disabled.hover .text,
    body button.fui-button[disabled].hover .text,
    body .fui-button.disabled .text,
    body .fui-button[disabled] .text,
    body .fui-button[disabled]:focus .text,
    body .fui-button.disabled:focus .text,
    body .fui-button.disabled:hover .text,
    body .fui-button.disabled:active .text,
    body .fui-button[disabled]:hover .text,
    body .fui-button[disabled]:active .text,
    body .fui-button.disabled.hover .text,
    body .fui-button[disabled].hover .text {
      color: #8A8A8A !important; }
    body a.fui-button.disabled.is-text, body a.fui-button[disabled].is-text, body a.fui-button[disabled]:focus.is-text, body a.fui-button.disabled:focus.is-text, body a.fui-button.disabled:hover.is-text, body a.fui-button.disabled:active.is-text, body a.fui-button[disabled]:hover.is-text, body a.fui-button[disabled]:active.is-text, body a.fui-button.disabled.hover.is-text, body a.fui-button[disabled].hover.is-text,
    body a.fui-button:link.disabled.is-text,
    body a.fui-button:link[disabled].is-text,
    body a.fui-button:link[disabled]:focus.is-text,
    body a.fui-button:link.disabled:focus.is-text,
    body a.fui-button:link.disabled:hover.is-text,
    body a.fui-button:link.disabled:active.is-text,
    body a.fui-button:link[disabled]:hover.is-text,
    body a.fui-button:link[disabled]:active.is-text,
    body a.fui-button:link.disabled.hover.is-text,
    body a.fui-button:link[disabled].hover.is-text,
    body a.fui-button:visited.disabled.is-text,
    body a.fui-button:visited[disabled].is-text,
    body a.fui-button:visited[disabled]:focus.is-text,
    body a.fui-button:visited.disabled:focus.is-text,
    body a.fui-button:visited.disabled:hover.is-text,
    body a.fui-button:visited.disabled:active.is-text,
    body a.fui-button:visited[disabled]:hover.is-text,
    body a.fui-button:visited[disabled]:active.is-text,
    body a.fui-button:visited.disabled.hover.is-text,
    body a.fui-button:visited[disabled].hover.is-text,
    body button.fui-button.disabled.is-text,
    body button.fui-button[disabled].is-text,
    body button.fui-button[disabled]:focus.is-text,
    body button.fui-button.disabled:focus.is-text,
    body button.fui-button.disabled:hover.is-text,
    body button.fui-button.disabled:active.is-text,
    body button.fui-button[disabled]:hover.is-text,
    body button.fui-button[disabled]:active.is-text,
    body button.fui-button.disabled.hover.is-text,
    body button.fui-button[disabled].hover.is-text,
    body .fui-button.disabled.is-text,
    body .fui-button[disabled].is-text,
    body .fui-button[disabled]:focus.is-text,
    body .fui-button.disabled:focus.is-text,
    body .fui-button.disabled:hover.is-text,
    body .fui-button.disabled:active.is-text,
    body .fui-button[disabled]:hover.is-text,
    body .fui-button[disabled]:active.is-text,
    body .fui-button.disabled.hover.is-text,
    body .fui-button[disabled].hover.is-text {
      background: none !important; }
    body a.fui-button.disabled.is-secondary, body a.fui-button[disabled].is-secondary, body a.fui-button[disabled]:focus.is-secondary, body a.fui-button.disabled:focus.is-secondary, body a.fui-button.disabled:hover.is-secondary, body a.fui-button.disabled:active.is-secondary, body a.fui-button[disabled]:hover.is-secondary, body a.fui-button[disabled]:active.is-secondary, body a.fui-button.disabled.hover.is-secondary, body a.fui-button[disabled].hover.is-secondary,
    body a.fui-button:link.disabled.is-secondary,
    body a.fui-button:link[disabled].is-secondary,
    body a.fui-button:link[disabled]:focus.is-secondary,
    body a.fui-button:link.disabled:focus.is-secondary,
    body a.fui-button:link.disabled:hover.is-secondary,
    body a.fui-button:link.disabled:active.is-secondary,
    body a.fui-button:link[disabled]:hover.is-secondary,
    body a.fui-button:link[disabled]:active.is-secondary,
    body a.fui-button:link.disabled.hover.is-secondary,
    body a.fui-button:link[disabled].hover.is-secondary,
    body a.fui-button:visited.disabled.is-secondary,
    body a.fui-button:visited[disabled].is-secondary,
    body a.fui-button:visited[disabled]:focus.is-secondary,
    body a.fui-button:visited.disabled:focus.is-secondary,
    body a.fui-button:visited.disabled:hover.is-secondary,
    body a.fui-button:visited.disabled:active.is-secondary,
    body a.fui-button:visited[disabled]:hover.is-secondary,
    body a.fui-button:visited[disabled]:active.is-secondary,
    body a.fui-button:visited.disabled.hover.is-secondary,
    body a.fui-button:visited[disabled].hover.is-secondary,
    body button.fui-button.disabled.is-secondary,
    body button.fui-button[disabled].is-secondary,
    body button.fui-button[disabled]:focus.is-secondary,
    body button.fui-button.disabled:focus.is-secondary,
    body button.fui-button.disabled:hover.is-secondary,
    body button.fui-button.disabled:active.is-secondary,
    body button.fui-button[disabled]:hover.is-secondary,
    body button.fui-button[disabled]:active.is-secondary,
    body button.fui-button.disabled.hover.is-secondary,
    body button.fui-button[disabled].hover.is-secondary,
    body .fui-button.disabled.is-secondary,
    body .fui-button[disabled].is-secondary,
    body .fui-button[disabled]:focus.is-secondary,
    body .fui-button.disabled:focus.is-secondary,
    body .fui-button.disabled:hover.is-secondary,
    body .fui-button.disabled:active.is-secondary,
    body .fui-button[disabled]:hover.is-secondary,
    body .fui-button[disabled]:active.is-secondary,
    body .fui-button.disabled.hover.is-secondary,
    body .fui-button[disabled].hover.is-secondary {
      background: none !important;
      border-color: #BFBFBF !important; }
    body a.fui-button.disabled::after, body a.fui-button[disabled]::after, body a.fui-button[disabled]:focus::after, body a.fui-button.disabled:focus::after, body a.fui-button.disabled:hover::after, body a.fui-button.disabled:active::after, body a.fui-button[disabled]:hover::after, body a.fui-button[disabled]:active::after, body a.fui-button.disabled.hover::after, body a.fui-button[disabled].hover::after,
    body a.fui-button:link.disabled::after,
    body a.fui-button:link[disabled]::after,
    body a.fui-button:link[disabled]:focus::after,
    body a.fui-button:link.disabled:focus::after,
    body a.fui-button:link.disabled:hover::after,
    body a.fui-button:link.disabled:active::after,
    body a.fui-button:link[disabled]:hover::after,
    body a.fui-button:link[disabled]:active::after,
    body a.fui-button:link.disabled.hover::after,
    body a.fui-button:link[disabled].hover::after,
    body a.fui-button:visited.disabled::after,
    body a.fui-button:visited[disabled]::after,
    body a.fui-button:visited[disabled]:focus::after,
    body a.fui-button:visited.disabled:focus::after,
    body a.fui-button:visited.disabled:hover::after,
    body a.fui-button:visited.disabled:active::after,
    body a.fui-button:visited[disabled]:hover::after,
    body a.fui-button:visited[disabled]:active::after,
    body a.fui-button:visited.disabled.hover::after,
    body a.fui-button:visited[disabled].hover::after,
    body button.fui-button.disabled::after,
    body button.fui-button[disabled]::after,
    body button.fui-button[disabled]:focus::after,
    body button.fui-button.disabled:focus::after,
    body button.fui-button.disabled:hover::after,
    body button.fui-button.disabled:active::after,
    body button.fui-button[disabled]:hover::after,
    body button.fui-button[disabled]:active::after,
    body button.fui-button.disabled.hover::after,
    body button.fui-button[disabled].hover::after,
    body .fui-button.disabled::after,
    body .fui-button[disabled]::after,
    body .fui-button[disabled]:focus::after,
    body .fui-button.disabled:focus::after,
    body .fui-button.disabled:hover::after,
    body .fui-button.disabled:active::after,
    body .fui-button[disabled]:hover::after,
    body .fui-button[disabled]:active::after,
    body .fui-button.disabled.hover::after,
    body .fui-button[disabled].hover::after {
      display: none !important; }
  body a.fui-button.is-text,
  body a.fui-button:link.is-text,
  body a.fui-button:visited.is-text,
  body button.fui-button.is-text,
  body .fui-button.is-text {
    min-width: 0;
    background: none;
    padding-left: 0;
    color: #202020;
    height: auto;
    height: initial; }
    body a.fui-button.is-text i,
    body a.fui-button:link.is-text i,
    body a.fui-button:visited.is-text i,
    body button.fui-button.is-text i,
    body .fui-button.is-text i {
      font-size: 24px !important;
      margin-right: 36px; }
    body a.fui-button.is-text.is-small .text,
    body a.fui-button:link.is-text.is-small .text,
    body a.fui-button:visited.is-text.is-small .text,
    body button.fui-button.is-text.is-small .text,
    body .fui-button.is-text.is-small .text {
      font-size: 14px; }
    body a.fui-button.is-text [class*="icon-"],
    body a.fui-button:link.is-text [class*="icon-"],
    body a.fui-button:visited.is-text [class*="icon-"],
    body button.fui-button.is-text [class*="icon-"],
    body .fui-button.is-text [class*="icon-"] {
      position: relative;
      left: 0;
      margin-left: 6px;
      font-size: 1.2rem;
      transition: all 0.3s ease; }
    body a.fui-button.is-text:hover, body a.fui-button.is-text:active,
    body a.fui-button:link.is-text:hover,
    body a.fui-button:link.is-text:active,
    body a.fui-button:visited.is-text:hover,
    body a.fui-button:visited.is-text:active,
    body button.fui-button.is-text:hover,
    body button.fui-button.is-text:active,
    body .fui-button.is-text:hover,
    body .fui-button.is-text:active {
      opacity: 0.6;
      margin-left: 12px; }
  body a.fui-button.is-arrow,
  body a.fui-button:link.is-arrow,
  body a.fui-button:visited.is-arrow,
  body button.fui-button.is-arrow,
  body .fui-button.is-arrow {
    min-width: 0;
    color: transparent;
    height: 36px;
    line-height: 36px;
    padding-left: 0;
    padding-right: 0;
    transition: all 0.3s ease; }
    body a.fui-button.is-arrow::after,
    body a.fui-button:link.is-arrow::after,
    body a.fui-button:visited.is-arrow::after,
    body button.fui-button.is-arrow::after,
    body .fui-button.is-arrow::after {
      content: "";
      text-align: left;
      position: absolute;
      display: block;
      width: 100%;
      height: 32px;
      font-size: 42px;
      color: #EF3123;
      font-family: "icomoon" !important;
      speak: none;
      transform: translateX(0%);
      left: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      font-style: normal;
      font-weight: normal;
      font-feature-settings: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 0.75;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      transition: all 0.3s ease; }
    body a.fui-button.is-arrow.is-reverse::after,
    body a.fui-button:link.is-arrow.is-reverse::after,
    body a.fui-button:visited.is-arrow.is-reverse::after,
    body button.fui-button.is-arrow.is-reverse::after,
    body .fui-button.is-arrow.is-reverse::after {
      color: #FFFFFF; }
    body a.fui-button.is-arrow .text,
    body a.fui-button:link.is-arrow .text,
    body a.fui-button:visited.is-arrow .text,
    body button.fui-button.is-arrow .text,
    body .fui-button.is-arrow .text {
      display: inline-block;
      opacity: 0;
      vertical-align: middle;
      transition: all 0.2s ease; }
    body a.fui-button.is-arrow.is-reverse.hover, body a.fui-button.is-arrow.is-reverse:hover, body a.fui-button.is-arrow.is-reverse:active,
    body a.fui-button:link.is-arrow.is-reverse.hover,
    body a.fui-button:link.is-arrow.is-reverse:hover,
    body a.fui-button:link.is-arrow.is-reverse:active,
    body a.fui-button:visited.is-arrow.is-reverse.hover,
    body a.fui-button:visited.is-arrow.is-reverse:hover,
    body a.fui-button:visited.is-arrow.is-reverse:active,
    body button.fui-button.is-arrow.is-reverse.hover,
    body button.fui-button.is-arrow.is-reverse:hover,
    body button.fui-button.is-arrow.is-reverse:active,
    body .fui-button.is-arrow.is-reverse.hover,
    body .fui-button.is-arrow.is-reverse:hover,
    body .fui-button.is-arrow.is-reverse:active {
      color: #FFFFFF; }
    body a.fui-button.is-arrow.hover, body a.fui-button.is-arrow:hover, body a.fui-button.is-arrow:active,
    body a.fui-button:link.is-arrow.hover,
    body a.fui-button:link.is-arrow:hover,
    body a.fui-button:link.is-arrow:active,
    body a.fui-button:visited.is-arrow.hover,
    body a.fui-button:visited.is-arrow:hover,
    body a.fui-button:visited.is-arrow:active,
    body button.fui-button.is-arrow.hover,
    body button.fui-button.is-arrow:hover,
    body button.fui-button.is-arrow:active,
    body .fui-button.is-arrow.hover,
    body .fui-button.is-arrow:hover,
    body .fui-button.is-arrow:active {
      color: #141414; }
      body a.fui-button.is-arrow.hover::after, body a.fui-button.is-arrow:hover::after, body a.fui-button.is-arrow:active::after,
      body a.fui-button:link.is-arrow.hover::after,
      body a.fui-button:link.is-arrow:hover::after,
      body a.fui-button:link.is-arrow:active::after,
      body a.fui-button:visited.is-arrow.hover::after,
      body a.fui-button:visited.is-arrow:hover::after,
      body a.fui-button:visited.is-arrow:active::after,
      body button.fui-button.is-arrow.hover::after,
      body button.fui-button.is-arrow:hover::after,
      body button.fui-button.is-arrow:active::after,
      body .fui-button.is-arrow.hover::after,
      body .fui-button.is-arrow:hover::after,
      body .fui-button.is-arrow:active::after {
        transform: translateX(120%); }
      body a.fui-button.is-arrow.hover .text, body a.fui-button.is-arrow:hover .text, body a.fui-button.is-arrow:active .text,
      body a.fui-button:link.is-arrow.hover .text,
      body a.fui-button:link.is-arrow:hover .text,
      body a.fui-button:link.is-arrow:active .text,
      body a.fui-button:visited.is-arrow.hover .text,
      body a.fui-button:visited.is-arrow:hover .text,
      body a.fui-button:visited.is-arrow:active .text,
      body button.fui-button.is-arrow.hover .text,
      body button.fui-button.is-arrow:hover .text,
      body button.fui-button.is-arrow:active .text,
      body .fui-button.is-arrow.hover .text,
      body .fui-button.is-arrow:hover .text,
      body .fui-button.is-arrow:active .text {
        opacity: 1; }
  body a.fui-button.is-tag,
  body a.fui-button:link.is-tag,
  body a.fui-button:visited.is-tag,
  body button.fui-button.is-tag,
  body .fui-button.is-tag {
    min-width: 160px;
    height: 40px;
    border-radius: 20px;
    background: transparent;
    color: #5F6E84;
    padding-left: 32px;
    padding-right: 32px;
    border: solid 2px #5F6E84; }
    body a.fui-button.is-tag .text,
    body a.fui-button:link.is-tag .text,
    body a.fui-button:visited.is-tag .text,
    body button.fui-button.is-tag .text,
    body .fui-button.is-tag .text {
      line-height: 38px; }
    body a.fui-button.is-tag.hover, body a.fui-button.is-tag:active, body a.fui-button.is-tag:hover,
    body a.fui-button:link.is-tag.hover,
    body a.fui-button:link.is-tag:active,
    body a.fui-button:link.is-tag:hover,
    body a.fui-button:visited.is-tag.hover,
    body a.fui-button:visited.is-tag:active,
    body a.fui-button:visited.is-tag:hover,
    body button.fui-button.is-tag.hover,
    body button.fui-button.is-tag:active,
    body button.fui-button.is-tag:hover,
    body .fui-button.is-tag.hover,
    body .fui-button.is-tag:active,
    body .fui-button.is-tag:hover {
      color: #FFFFFF;
      background: #5F6E84; }
  body a.fui-button.is-label,
  body a.fui-button:link.is-label,
  body a.fui-button:visited.is-label,
  body button.fui-button.is-label,
  body .fui-button.is-label {
    min-width: 72px;
    border-radius: 30px;
    padding: 0 12px;
    border: solid 2px #d6d6d6;
    margin-right: 6px;
    margin-bottom: 6px;
    background: #FFFFFF;
    color: #202020;
    font-size: 0.875rem;
    height: 30px;
    white-space: nowrap; }
    body a.fui-button.is-label .text,
    body a.fui-button:link.is-label .text,
    body a.fui-button:visited.is-label .text,
    body button.fui-button.is-label .text,
    body .fui-button.is-label .text {
      line-height: 26px; }
    body a.fui-button.is-label:hover,
    body a.fui-button:link.is-label:hover,
    body a.fui-button:visited.is-label:hover,
    body button.fui-button.is-label:hover,
    body .fui-button.is-label:hover {
      border-color: #202020; }
    body a.fui-button.is-label.is-reverse,
    body a.fui-button:link.is-label.is-reverse,
    body a.fui-button:visited.is-label.is-reverse,
    body button.fui-button.is-label.is-reverse,
    body .fui-button.is-label.is-reverse {
      color: #5F6E84;
      background: #FFFFFF;
      border: 0;
      font-size: 0.875rem;
      height: 36px;
      line-height: 36px;
      border-radius: 18px;
      margin-right: 12px; }
  body a.fui-button.is-tiny,
  body a.fui-button:link.is-tiny,
  body a.fui-button:visited.is-tiny,
  body button.fui-button.is-tiny,
  body .fui-button.is-tiny {
    min-width: 72px;
    height: 30px; }
    body a.fui-button.is-tiny .text,
    body a.fui-button:link.is-tiny .text,
    body a.fui-button:visited.is-tiny .text,
    body button.fui-button.is-tiny .text,
    body .fui-button.is-tiny .text {
      height: 20px !important;
      line-height: 28px !important; }
  @media screen and (max-width: 960px) {
    body a.fui-button.is-tiny,
    body a.fui-button:link.is-tiny,
    body a.fui-button:visited.is-tiny,
    body button.fui-button.is-tiny,
    body .fui-button.is-tiny {
      height: 24px;
      min-width: auto;
      padding: 2px 11px; }
      body a.fui-button.is-tiny .text,
      body a.fui-button:link.is-tiny .text,
      body a.fui-button:visited.is-tiny .text,
      body button.fui-button.is-tiny .text,
      body .fui-button.is-tiny .text {
        font-size: 12px;
        line-height: normal !important; } }
  body a.fui-button.is-small,
  body a.fui-button:link.is-small,
  body a.fui-button:visited.is-small,
  body button.fui-button.is-small,
  body .fui-button.is-small {
    min-width: 72px;
    height: 30px; }
    body a.fui-button.is-small.is-secondary,
    body a.fui-button:link.is-small.is-secondary,
    body a.fui-button:visited.is-small.is-secondary,
    body button.fui-button.is-small.is-secondary,
    body .fui-button.is-small.is-secondary {
      border-color: #202020; }
      body a.fui-button.is-small.is-secondary .text,
      body a.fui-button:link.is-small.is-secondary .text,
      body a.fui-button:visited.is-small.is-secondary .text,
      body button.fui-button.is-small.is-secondary .text,
      body .fui-button.is-small.is-secondary .text {
        height: 20px;
        line-height: 20px; }
    body a.fui-button.is-small.is-secondary.is-text-sm,
    body a.fui-button:link.is-small.is-secondary.is-text-sm,
    body a.fui-button:visited.is-small.is-secondary.is-text-sm,
    body button.fui-button.is-small.is-secondary.is-text-sm,
    body .fui-button.is-small.is-secondary.is-text-sm {
      padding: 0 12px; }
      body a.fui-button.is-small.is-secondary.is-text-sm .text,
      body a.fui-button:link.is-small.is-secondary.is-text-sm .text,
      body a.fui-button:visited.is-small.is-secondary.is-text-sm .text,
      body button.fui-button.is-small.is-secondary.is-text-sm .text,
      body .fui-button.is-small.is-secondary.is-text-sm .text {
        font-size: 0.875rem; }
    body a.fui-button.is-small .text,
    body a.fui-button:link.is-small .text,
    body a.fui-button:visited.is-small .text,
    body button.fui-button.is-small .text,
    body .fui-button.is-small .text {
      height: 24px;
      line-height: 24px; }
  body a.fui-button.is-large,
  body a.fui-button:link.is-large,
  body a.fui-button:visited.is-large,
  body button.fui-button.is-large,
  body .fui-button.is-large {
    min-width: 170px;
    height: 50px;
    padding: 0 40px; }
    @media screen and (max-width: 640px) {
      body a.fui-button.is-large.min-width-auto,
      body a.fui-button:link.is-large.min-width-auto,
      body a.fui-button:visited.is-large.min-width-auto,
      body button.fui-button.is-large.min-width-auto,
      body .fui-button.is-large.min-width-auto {
        min-width: auto;
        padding: 0; } }
    body a.fui-button.is-large .text,
    body a.fui-button:link.is-large .text,
    body a.fui-button:visited.is-large .text,
    body button.fui-button.is-large .text,
    body .fui-button.is-large .text {
      height: 50px;
      line-height: 50px; }
    body a.fui-button.is-large.is-secondary,
    body a.fui-button:link.is-large.is-secondary,
    body a.fui-button:visited.is-large.is-secondary,
    body button.fui-button.is-large.is-secondary,
    body .fui-button.is-large.is-secondary {
      border-color: #BFBFBF !important; }
      body a.fui-button.is-large.is-secondary .text,
      body a.fui-button:link.is-large.is-secondary .text,
      body a.fui-button:visited.is-large.is-secondary .text,
      body button.fui-button.is-large.is-secondary .text,
      body .fui-button.is-large.is-secondary .text {
        height: 46px;
        line-height: 46px; }
      body a.fui-button.is-large.is-secondary:hover, body a.fui-button.is-large.is-secondary:active,
      body a.fui-button:link.is-large.is-secondary:hover,
      body a.fui-button:link.is-large.is-secondary:active,
      body a.fui-button:visited.is-large.is-secondary:hover,
      body a.fui-button:visited.is-large.is-secondary:active,
      body button.fui-button.is-large.is-secondary:hover,
      body button.fui-button.is-large.is-secondary:active,
      body .fui-button.is-large.is-secondary:hover,
      body .fui-button.is-large.is-secondary:active {
        border-color: #EF3123 !important;
        color: #EF3123;
        opacity: 1; }
        body a.fui-button.is-large.is-secondary:hover .text, body a.fui-button.is-large.is-secondary:active .text,
        body a.fui-button:link.is-large.is-secondary:hover .text,
        body a.fui-button:link.is-large.is-secondary:active .text,
        body a.fui-button:visited.is-large.is-secondary:hover .text,
        body a.fui-button:visited.is-large.is-secondary:active .text,
        body button.fui-button.is-large.is-secondary:hover .text,
        body button.fui-button.is-large.is-secondary:active .text,
        body .fui-button.is-large.is-secondary:hover .text,
        body .fui-button.is-large.is-secondary:active .text {
          color: #EF3123; }
        body a.fui-button.is-large.is-secondary:hover::after, body a.fui-button.is-large.is-secondary:active::after,
        body a.fui-button:link.is-large.is-secondary:hover::after,
        body a.fui-button:link.is-large.is-secondary:active::after,
        body a.fui-button:visited.is-large.is-secondary:hover::after,
        body a.fui-button:visited.is-large.is-secondary:active::after,
        body button.fui-button.is-large.is-secondary:hover::after,
        body button.fui-button.is-large.is-secondary:active::after,
        body .fui-button.is-large.is-secondary:hover::after,
        body .fui-button.is-large.is-secondary:active::after {
          color: #EF3123; }
      body a.fui-button.is-large.is-secondary.is-reverse:hover, body a.fui-button.is-large.is-secondary.is-reverse:active,
      body a.fui-button:link.is-large.is-secondary.is-reverse:hover,
      body a.fui-button:link.is-large.is-secondary.is-reverse:active,
      body a.fui-button:visited.is-large.is-secondary.is-reverse:hover,
      body a.fui-button:visited.is-large.is-secondary.is-reverse:active,
      body button.fui-button.is-large.is-secondary.is-reverse:hover,
      body button.fui-button.is-large.is-secondary.is-reverse:active,
      body .fui-button.is-large.is-secondary.is-reverse:hover,
      body .fui-button.is-large.is-secondary.is-reverse:active {
        border-color: #FFFFFF; }
    body a.fui-button.is-large::after,
    body a.fui-button:link.is-large::after,
    body a.fui-button:visited.is-large::after,
    body button.fui-button.is-large::after,
    body .fui-button.is-large::after {
      content: "";
      font-family: "icomoon" !important;
      speak: none;
      position: absolute;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      right: 12px;
      font-size: 24px;
      top: 0;
      bottom: 0;
      margin: auto;
      opacity: 0;
      transform: translateX(-5px);
      background-size: contain;
      transition: all 0.3s ease;
      opacity: 0;
      font-style: normal;
      font-weight: normal;
      font-feature-settings: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale; }
    body a.fui-button.is-large:hover::after, body a.fui-button.is-large:active::after,
    body a.fui-button:link.is-large:hover::after,
    body a.fui-button:link.is-large:active::after,
    body a.fui-button:visited.is-large:hover::after,
    body a.fui-button:visited.is-large:active::after,
    body button.fui-button.is-large:hover::after,
    body button.fui-button.is-large:active::after,
    body .fui-button.is-large:hover::after,
    body .fui-button.is-large:active::after {
      opacity: 1;
      transform: translateX(0px); }
  body a.fui-button.is-primary,
  body a.fui-button:link.is-primary,
  body a.fui-button:visited.is-primary,
  body button.fui-button.is-primary,
  body .fui-button.is-primary {
    color: #FFF;
    background: #D3060F; }
    body a.fui-button.is-primary.hover, body a.fui-button.is-primary:hover, body a.fui-button.is-primary:active,
    body a.fui-button:link.is-primary.hover,
    body a.fui-button:link.is-primary:hover,
    body a.fui-button:link.is-primary:active,
    body a.fui-button:visited.is-primary.hover,
    body a.fui-button:visited.is-primary:hover,
    body a.fui-button:visited.is-primary:active,
    body button.fui-button.is-primary.hover,
    body button.fui-button.is-primary:hover,
    body button.fui-button.is-primary:active,
    body .fui-button.is-primary.hover,
    body .fui-button.is-primary:hover,
    body .fui-button.is-primary:active {
      background: #EF3123; }
    body a.fui-button.is-primary.is-reverse,
    body a.fui-button:link.is-primary.is-reverse,
    body a.fui-button:visited.is-primary.is-reverse,
    body button.fui-button.is-primary.is-reverse,
    body .fui-button.is-primary.is-reverse {
      color: #202020;
      background: #FFFFFF; }
      body a.fui-button.is-primary.is-reverse.is-large,
      body a.fui-button:link.is-primary.is-reverse.is-large,
      body a.fui-button:visited.is-primary.is-reverse.is-large,
      body button.fui-button.is-primary.is-reverse.is-large,
      body .fui-button.is-primary.is-reverse.is-large {
        color: #EF3123; }
  body a.fui-button.is-secondary,
  body a.fui-button:link.is-secondary,
  body a.fui-button:visited.is-secondary,
  body button.fui-button.is-secondary,
  body .fui-button.is-secondary {
    color: #202020;
    border: 2px solid #202020; }
    body a.fui-button.is-secondary .text,
    body a.fui-button:link.is-secondary .text,
    body a.fui-button:visited.is-secondary .text,
    body button.fui-button.is-secondary .text,
    body .fui-button.is-secondary .text {
      display: inline-block;
      height: 36px;
      line-height: 36px; }
    body a.fui-button.is-secondary.hover, body a.fui-button.is-secondary:hover, body a.fui-button.is-secondary:active,
    body a.fui-button:link.is-secondary.hover,
    body a.fui-button:link.is-secondary:hover,
    body a.fui-button:link.is-secondary:active,
    body a.fui-button:visited.is-secondary.hover,
    body a.fui-button:visited.is-secondary:hover,
    body a.fui-button:visited.is-secondary:active,
    body button.fui-button.is-secondary.hover,
    body button.fui-button.is-secondary:hover,
    body button.fui-button.is-secondary:active,
    body .fui-button.is-secondary.hover,
    body .fui-button.is-secondary:hover,
    body .fui-button.is-secondary:active {
      border-color: #EF3123; }
      body a.fui-button.is-secondary.hover .text, body a.fui-button.is-secondary:hover .text, body a.fui-button.is-secondary:active .text,
      body a.fui-button:link.is-secondary.hover .text,
      body a.fui-button:link.is-secondary:hover .text,
      body a.fui-button:link.is-secondary:active .text,
      body a.fui-button:visited.is-secondary.hover .text,
      body a.fui-button:visited.is-secondary:hover .text,
      body a.fui-button:visited.is-secondary:active .text,
      body button.fui-button.is-secondary.hover .text,
      body button.fui-button.is-secondary:hover .text,
      body button.fui-button.is-secondary:active .text,
      body .fui-button.is-secondary.hover .text,
      body .fui-button.is-secondary:hover .text,
      body .fui-button.is-secondary:active .text {
        color: #EF3123;
        transition: all 0.3s ease; }
    body a.fui-button.is-secondary.is-reverse,
    body a.fui-button.is-secondary.is-reverse .text, body a.fui-button.is-secondary.is-reverse:not(.is-small)::after,
    body a.fui-button.is-secondary.is-reverse:hover .text, body a.fui-button.is-secondary.is-reverse:not(.is-small):hover::after,
    body a.fui-button.is-secondary.is-reverse:active .text, body a.fui-button.is-secondary.is-reverse:not(.is-small):active::after,
    body a.fui-button:link.is-secondary.is-reverse,
    body a.fui-button:link.is-secondary.is-reverse .text,
    body a.fui-button:link.is-secondary.is-reverse:not(.is-small)::after,
    body a.fui-button:link.is-secondary.is-reverse:hover .text,
    body a.fui-button:link.is-secondary.is-reverse:not(.is-small):hover::after,
    body a.fui-button:link.is-secondary.is-reverse:active .text,
    body a.fui-button:link.is-secondary.is-reverse:not(.is-small):active::after,
    body a.fui-button:visited.is-secondary.is-reverse,
    body a.fui-button:visited.is-secondary.is-reverse .text,
    body a.fui-button:visited.is-secondary.is-reverse:not(.is-small)::after,
    body a.fui-button:visited.is-secondary.is-reverse:hover .text,
    body a.fui-button:visited.is-secondary.is-reverse:not(.is-small):hover::after,
    body a.fui-button:visited.is-secondary.is-reverse:active .text,
    body a.fui-button:visited.is-secondary.is-reverse:not(.is-small):active::after,
    body button.fui-button.is-secondary.is-reverse,
    body button.fui-button.is-secondary.is-reverse .text,
    body button.fui-button.is-secondary.is-reverse:not(.is-small)::after,
    body button.fui-button.is-secondary.is-reverse:hover .text,
    body button.fui-button.is-secondary.is-reverse:not(.is-small):hover::after,
    body button.fui-button.is-secondary.is-reverse:active .text,
    body button.fui-button.is-secondary.is-reverse:not(.is-small):active::after,
    body .fui-button.is-secondary.is-reverse,
    body .fui-button.is-secondary.is-reverse .text,
    body .fui-button.is-secondary.is-reverse:not(.is-small)::after,
    body .fui-button.is-secondary.is-reverse:hover .text,
    body .fui-button.is-secondary.is-reverse:not(.is-small):hover::after,
    body .fui-button.is-secondary.is-reverse:active .text,
    body .fui-button.is-secondary.is-reverse:not(.is-small):active::after {
      color: #FFFFFF;
      background: none;
      border-color: #FFFFFF !important; }
      body a.fui-button.is-secondary.is-reverse .text,
      body a.fui-button.is-secondary.is-reverse .text .text, body a.fui-button.is-secondary.is-reverse:not(.is-small)::after .text,
      body a.fui-button.is-secondary.is-reverse:hover .text .text, body a.fui-button.is-secondary.is-reverse:not(.is-small):hover::after .text,
      body a.fui-button.is-secondary.is-reverse:active .text .text, body a.fui-button.is-secondary.is-reverse:not(.is-small):active::after .text,
      body a.fui-button:link.is-secondary.is-reverse .text,
      body a.fui-button:link.is-secondary.is-reverse .text .text,
      body a.fui-button:link.is-secondary.is-reverse:not(.is-small)::after .text,
      body a.fui-button:link.is-secondary.is-reverse:hover .text .text,
      body a.fui-button:link.is-secondary.is-reverse:not(.is-small):hover::after .text,
      body a.fui-button:link.is-secondary.is-reverse:active .text .text,
      body a.fui-button:link.is-secondary.is-reverse:not(.is-small):active::after .text,
      body a.fui-button:visited.is-secondary.is-reverse .text,
      body a.fui-button:visited.is-secondary.is-reverse .text .text,
      body a.fui-button:visited.is-secondary.is-reverse:not(.is-small)::after .text,
      body a.fui-button:visited.is-secondary.is-reverse:hover .text .text,
      body a.fui-button:visited.is-secondary.is-reverse:not(.is-small):hover::after .text,
      body a.fui-button:visited.is-secondary.is-reverse:active .text .text,
      body a.fui-button:visited.is-secondary.is-reverse:not(.is-small):active::after .text,
      body button.fui-button.is-secondary.is-reverse .text,
      body button.fui-button.is-secondary.is-reverse .text .text,
      body button.fui-button.is-secondary.is-reverse:not(.is-small)::after .text,
      body button.fui-button.is-secondary.is-reverse:hover .text .text,
      body button.fui-button.is-secondary.is-reverse:not(.is-small):hover::after .text,
      body button.fui-button.is-secondary.is-reverse:active .text .text,
      body button.fui-button.is-secondary.is-reverse:not(.is-small):active::after .text,
      body .fui-button.is-secondary.is-reverse .text,
      body .fui-button.is-secondary.is-reverse .text .text,
      body .fui-button.is-secondary.is-reverse:not(.is-small)::after .text,
      body .fui-button.is-secondary.is-reverse:hover .text .text,
      body .fui-button.is-secondary.is-reverse:not(.is-small):hover::after .text,
      body .fui-button.is-secondary.is-reverse:active .text .text,
      body .fui-button.is-secondary.is-reverse:not(.is-small):active::after .text {
        color: #FFFFFF; }
    body a.fui-button.is-secondary.is-reverse:hover, body a.fui-button.is-secondary.is-reverse:active,
    body a.fui-button:link.is-secondary.is-reverse:hover,
    body a.fui-button:link.is-secondary.is-reverse:active,
    body a.fui-button:visited.is-secondary.is-reverse:hover,
    body a.fui-button:visited.is-secondary.is-reverse:active,
    body button.fui-button.is-secondary.is-reverse:hover,
    body button.fui-button.is-secondary.is-reverse:active,
    body .fui-button.is-secondary.is-reverse:hover,
    body .fui-button.is-secondary.is-reverse:active {
      background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAACCAYAAABytg0kAAAABGdBTUEAALGPC/xhBQAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAAAqADAAQAAAABAAAAAgAAAADO0J6QAAAAFElEQVQIHWP8//+/LwMQMIEIEAAANDwDTjsE0m4AAAAASUVORK5CYII="); }
  body a.fui-button.is-black,
  body a.fui-button:link.is-black,
  body a.fui-button:visited.is-black,
  body button.fui-button.is-black,
  body .fui-button.is-black {
    border: 2px solid #333333; }
  @media screen and (min-width: 960px) {
    body a.fui-button,
    body a.fui-button:link,
    body a.fui-button:visited,
    body button.fui-button,
    body .fui-button {
      min-width: 190px; }
      body a.fui-button .text,
      body a.fui-button:link .text,
      body a.fui-button:visited .text,
      body button.fui-button .text,
      body .fui-button .text {
        height: 40px;
        line-height: 40px; }
      body a.fui-button.is-arrow::after,
      body a.fui-button:link.is-arrow::after,
      body a.fui-button:visited.is-arrow::after,
      body button.fui-button.is-arrow::after,
      body .fui-button.is-arrow::after {
        font-size: 42px; }
      body a.fui-button.is-small,
      body a.fui-button:link.is-small,
      body a.fui-button:visited.is-small,
      body button.fui-button.is-small,
      body .fui-button.is-small {
        min-width: 92px; }
        body a.fui-button.is-small .text,
        body a.fui-button:link.is-small .text,
        body a.fui-button:visited.is-small .text,
        body button.fui-button.is-small .text,
        body .fui-button.is-small .text {
          height: 30px;
          line-height: 30px; }
      body a.fui-button.is-large,
      body a.fui-button:link.is-large,
      body a.fui-button:visited.is-large,
      body button.fui-button.is-large,
      body .fui-button.is-large {
        min-width: 220px; }
        body a.fui-button.is-large .text,
        body a.fui-button:link.is-large .text,
        body a.fui-button:visited.is-large .text,
        body button.fui-button.is-large .text,
        body .fui-button.is-large .text {
          height: 50px;
          line-height: 50px; }
      body a.fui-button.is-secondary .text,
      body a.fui-button:link.is-secondary .text,
      body a.fui-button:visited.is-secondary .text,
      body button.fui-button.is-secondary .text,
      body .fui-button.is-secondary .text {
        height: 36px;
        line-height: 36px; }
      body a.fui-button.is-secondary.is-small .text,
      body a.fui-button:link.is-secondary.is-small .text,
      body a.fui-button:visited.is-secondary.is-small .text,
      body button.fui-button.is-secondary.is-small .text,
      body .fui-button.is-secondary.is-small .text {
        height: 26px;
        line-height: 26px; }
      body a.fui-button.is-secondary.is-large .text,
      body a.fui-button:link.is-secondary.is-large .text,
      body a.fui-button:visited.is-secondary.is-large .text,
      body button.fui-button.is-secondary.is-large .text,
      body .fui-button.is-secondary.is-large .text {
        height: 46px;
        line-height: 46px; }
      body a.fui-button.is-no-arrow:after, body a.fui-button.no-arrow:after,
      body a.fui-button:link.is-no-arrow:after,
      body a.fui-button:link.no-arrow:after,
      body a.fui-button:visited.is-no-arrow:after,
      body a.fui-button:visited.no-arrow:after,
      body button.fui-button.is-no-arrow:after,
      body button.fui-button.no-arrow:after,
      body .fui-button.is-no-arrow:after,
      body .fui-button.no-arrow:after {
        display: none !important; } }

body .fui-banner .fui-button.is-primary,
body .fui-banner a.fui-button.is-primary,
body .fui-banner a.fui-button.is-primary:link,
body .fui-banner .fui-button.is-secondary,
body .fui-banner a.fui-button.is-secondary,
body .fui-banner a.fui-button.is-secondary:link {
  min-width: 202px;
  height: 50px; }
  body .fui-banner .fui-button.is-primary .text,
  body .fui-banner a.fui-button.is-primary .text,
  body .fui-banner a.fui-button.is-primary:link .text,
  body .fui-banner .fui-button.is-secondary .text,
  body .fui-banner a.fui-button.is-secondary .text,
  body .fui-banner a.fui-button.is-secondary:link .text {
    height: 50px;
    line-height: 50px; }
  body .fui-banner .fui-button.is-primary.is-secondary .text,
  body .fui-banner a.fui-button.is-primary.is-secondary .text,
  body .fui-banner a.fui-button.is-primary:link.is-secondary .text,
  body .fui-banner .fui-button.is-secondary.is-secondary .text,
  body .fui-banner a.fui-button.is-secondary.is-secondary .text,
  body .fui-banner a.fui-button.is-secondary:link.is-secondary .text {
    height: 46px;
    line-height: 46px; }
  @media screen and (min-width: 960px) {
    body .fui-banner .fui-button.is-primary,
    body .fui-banner a.fui-button.is-primary,
    body .fui-banner a.fui-button.is-primary:link,
    body .fui-banner .fui-button.is-secondary,
    body .fui-banner a.fui-button.is-secondary,
    body .fui-banner a.fui-button.is-secondary:link {
      min-width: 220px; } }

body .fui-go-top {
  position: fixed;
  display: block;
  width: 40px;
  height: 40px;
  right: 24px;
  bottom: 24px;
  background: #4B5869;
  border-radius: 50%;
  transform: translateY(96px);
  transition: all 0.3s ease;
  z-index: 19;
  background: #6a798e url(/DigService/resources/common/images/go-top.png) center no-repeat;
  cursor: pointer;
  background-size: 30px; }
  body .fui-go-top.is-show {
    transform: translateY(0); }
    body .fui-go-top.is-show + .emma-service {
      transform: translateY(-54px) !important; }
  @media screen and (min-width: 960px) {
    body .fui-go-top {
      width: 48px;
      height: 48px; }
      body .fui-go-top.is-show + .emma-service {
        transform: translateY(-76px) !important; } }

body .fui-card .fui-card-action:hover .fui-button.is-arrow,
body .fui-card .fui-card-action:active .fui-button.is-arrow {
  color: #EF3123; }
  body .fui-card .fui-card-action:hover .fui-button.is-arrow::after,
  body .fui-card .fui-card-action:active .fui-button.is-arrow::after {
    transform: translateX(120%); }
  body .fui-card .fui-card-action:hover .fui-button.is-arrow .text,
  body .fui-card .fui-card-action:active .fui-button.is-arrow .text {
    opacity: 1; }
  body .fui-card .fui-card-action:hover .fui-button.is-arrow.is-reverse,
  body .fui-card .fui-card-action:active .fui-button.is-arrow.is-reverse {
    color: #FFFFFF; }

body .related-keyword button.fui-button.is-tag,
body .related-keyword a.fui-button.is-tag {
  min-width: 0; }

body .button-group .two-buttons .fui-button.is-large {
  margin-bottom: 0;
  padding-right: 24px;
  padding-left: 24px;
  min-width: 220px; }

@media screen and (max-width: 960px) {
  body .fui-card .fui-card-action:hover .fui-button.is-arrow,
  body .fui-card .fui-card-action:active .fui-button.is-arrow,
  body .fui-button.is-arrow {
    color: transparent; }
    body .fui-card .fui-card-action:hover .fui-button.is-arrow:active,
    body .fui-card .fui-card-action:active .fui-button.is-arrow:active,
    body .fui-button.is-arrow:active {
      color: transparent; }
    body .fui-card .fui-card-action:hover .fui-button.is-arrow::after,
    body .fui-card .fui-card-action:active .fui-button.is-arrow::after,
    body .fui-button.is-arrow::after {
      transform: none !important;
      transform: initial !important; }
    body .fui-card .fui-card-action:hover .fui-button.is-arrow .text,
    body .fui-card .fui-card-action:active .fui-button.is-arrow .text,
    body .fui-button.is-arrow .text {
      opacity: 0 !important; }
    body .fui-card .fui-card-action:hover .fui-button.is-arrow.is-reverse,
    body .fui-card .fui-card-action:active .fui-button.is-arrow.is-reverse,
    body .fui-button.is-arrow.is-reverse {
      color: transparent; } }

@media screen and (max-width: 768px) {
  body .button-group .two-buttons .fui-button.is-large {
    width: 49%;
    min-width: auto;
    margin-right: 2%;
    display: inline-block;
    padding: 0 8px !important;
    margin-bottom: 16px; }
    body .button-group .two-buttons .fui-button.is-large:last-child {
      margin-right: 0;
      margin-bottom: 0; }
  body a.fui-button.is-sm-block, body a.fui-button.is-xs-block,
  body a.fui-button:link.is-sm-block,
  body a.fui-button:link.is-xs-block,
  body a.fui-button:visited.is-sm-block,
  body a.fui-button:visited.is-xs-block,
  body button.fui-button.is-sm-block,
  body button.fui-button.is-xs-block,
  body .fui-button.is-sm-block,
  body .fui-button.is-xs-block {
    width: 100%; } }

@media screen and (max-width: 480px) {
  body .button-group .two-buttons .fui-button.is-large {
    width: 100%;
    margin-bottom: 12px; } }

.fui-buttons {
  display: flex;
  margin: 0 -8px; }
  .fui-buttons .fui-button {
    flex: 1 1;
    flex-grow: 1;
    min-width: 0px !important;
    margin: 0 8px 8px; }

.fui-item,
a.fui-item,
a.fui-item:link,
a.fui-item:visited {
  position: relative;
  display: flex;
  padding: 8px 0;
  font-size: 1rem;
  align-content: center;
  align-items: center;
  color: #202020;
  background: none;
  border: 0;
  margin: 0;
  width: 100%; }
  .fui-item .prefix,
  a.fui-item .prefix,
  a.fui-item:link .prefix,
  a.fui-item:visited .prefix {
    min-width: 24px;
    height: 24px;
    margin-right: 6px;
    color: #2F59C4;
    align-self: flex-start; }
    .fui-item .prefix *,
    a.fui-item .prefix *,
    a.fui-item:link .prefix *,
    a.fui-item:visited .prefix * {
      vertical-align: middle; }
    .fui-item .prefix .icon-check,
    a.fui-item .prefix .icon-check,
    a.fui-item:link .prefix .icon-check,
    a.fui-item:visited .prefix .icon-check {
      margin-left: 4px; }
    .fui-item .prefix .bulleted-dot,
    a.fui-item .prefix .bulleted-dot,
    a.fui-item:link .prefix .bulleted-dot,
    a.fui-item:visited .prefix .bulleted-dot {
      display: inline-block;
      margin-left: 8px;
      width: 8px;
      height: 8px;
      border-radius: 4px;
      background: #2F59C4; }
    .fui-item .prefix .number,
    a.fui-item .prefix .number,
    a.fui-item:link .prefix .number,
    a.fui-item:visited .prefix .number {
      display: inline-block;
      width: 24px;
      height: 24px;
      line-height: 24px;
      border-radius: 12px;
      font-style: normal;
      font-weight: 500;
      text-align: center;
      background: rgba(57, 102, 218, 0.2); }
  .fui-item.is-grayblue .prefix .bulleted-dot,
  a.fui-item.is-grayblue .prefix .bulleted-dot,
  a.fui-item:link.is-grayblue .prefix .bulleted-dot,
  a.fui-item:visited.is-grayblue .prefix .bulleted-dot {
    background: #6a798e; }
  .fui-item .content,
  a.fui-item .content,
  a.fui-item:link .content,
  a.fui-item:visited .content {
    flex: 1 1 auto;
    flex-grow: 1; }
  .fui-item .extra,
  a.fui-item .extra,
  a.fui-item:link .extra,
  a.fui-item:visited .extra {
    line-height: 0; }
    .fui-item .extra > i[class*="icon"],
    a.fui-item .extra > i[class*="icon"],
    a.fui-item:link .extra > i[class*="icon"],
    a.fui-item:visited .extra > i[class*="icon"] {
      font-size: 24px; }
  .fui-item.is-disabled,
  .fui-item.is-disabled *,
  a.fui-item.is-disabled,
  a.fui-item.is-disabled *,
  a.fui-item:link.is-disabled,
  a.fui-item:link.is-disabled *,
  a.fui-item:visited.is-disabled,
  a.fui-item:visited.is-disabled * {
    color: #BFBFBF; }
  .fui-item.is-underline,
  a.fui-item.is-underline,
  a.fui-item:link.is-underline,
  a.fui-item:visited.is-underline {
    text-decoration: underline; }
  .fui-item.text-sm,
  a.fui-item.text-sm,
  a.fui-item:link.text-sm,
  a.fui-item:visited.text-sm {
    font-size: 0.875rem; }
  .fui-item.paded,
  a.fui-item.paded,
  a.fui-item:link.paded,
  a.fui-item:visited.paded {
    padding: 12px 30px; }
  .fui-item[role='button']:hover,
  a.fui-item[role='button']:hover,
  a.fui-item:link[role='button']:hover,
  a.fui-item:visited[role='button']:hover {
    opacity: 0.6; }
  .fui-item.is-active,
  a.fui-item.is-active,
  a.fui-item:link.is-active,
  a.fui-item:visited.is-active {
    color: #EF3123; }
    .fui-item.is-active::before,
    a.fui-item.is-active::before,
    a.fui-item:link.is-active::before,
    a.fui-item:visited.is-active::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 4px;
      height: 100%;
      background: #EF3123; }

header.fui-header .fui-item[role='button']:hover {
  opacity: 1;
  color: #EF3123; }

.is-ebu header.fui-header .fui-item[role='button']:hover {
  opacity: 1;
  color: #2F59C4; }

button.fui-item,
a.fui-item {
  color: #202020;
  cursor: pointer; }
  button.fui-item::after,
  a.fui-item::after {
    display: none; }

.fui-inline-items {
  margin-bottom: 48px; }
  .fui-inline-items .fui-item {
    display: inline-flex;
    width: 48%; }

.fui-item.with-image {
  align-content: flex-start;
  align-items: flex-start; }
  .fui-item.with-image .image {
    line-height: 0;
    width: 130px; }
    .fui-item.with-image .image img {
      line-height: 0;
      width: 100%;
      height: auto; }

.fui-item.with-list {
  margin-bottom: 16px; }
  .fui-item.with-list.is-accent > .content {
    padding-top: 0;
    margin-left: 12px; }
    .fui-item.with-list.is-accent > .content .fui-list .fui-item {
      padding: 3px 0; }
      .fui-item.with-list.is-accent > .content .fui-list .fui-item .prefix i {
        color: #EF3123; }
      .fui-item.with-list.is-accent > .content .fui-list .fui-item .content {
        font-size: 18px;
        font-weight: 400;
        color: #5F6E84; }
  .fui-item.with-list > .content {
    width: 100%; }
    .fui-item.with-list > .content .fui-list {
      width: 100%; }

.fui-item > .content h4 {
  font-size: 22px;
  color: #202020;
  margin: 0 0 18px; }

.fui-item > .content .fui-item {
  text-align: left;
  font-weight: 500;
  align-content: flex-start;
  align-items: flex-start;
  padding: 3px 0; }
  .fui-item > .content .fui-item .content {
    font-size: 1rem; }
  .fui-item > .content .fui-item .prefix {
    line-height: 1.2;
    vertical-align: middle; }
  .fui-item > .content .fui-item .prefix i[class*='icon-'] {
    color: #2F59C4; }

.fui-list.contact-with-dot .fui-item {
  font-weight: 500; }
  .fui-list.contact-with-dot .fui-item::before {
    content: "";
    float: left;
    display: block; }
  .fui-list.contact-with-dot .fui-item a {
    display: inline-block;
    margin-left: 4px;
    margin-right: 4px; }

.fui-card .fui-card-content a:not(.sub-link-title):not(.fui-button):link,
.fui-card .fui-card-content a:not(.sub-link-title):not(.fui-button):visited,
.fui-panel a:not(.sub-link-title):not(.fui-button):link,
.fui-panel a:not(.sub-link-title):not(.fui-button):visited {
  color: #EF3123; }
  .fui-card .fui-card-content a:not(.sub-link-title):not(.fui-button):link::after,
  .fui-card .fui-card-content a:not(.sub-link-title):not(.fui-button):visited::after,
  .fui-panel a:not(.sub-link-title):not(.fui-button):link::after,
  .fui-panel a:not(.sub-link-title):not(.fui-button):visited::after {
    content: "";
    display: block;
    width: 0;
    height: 2px;
    background: #EF3123 !important;
    transition: width 0.3s ease; }
  .fui-card .fui-card-content a:not(.sub-link-title):not(.fui-button):link:hover::after,
  .fui-card .fui-card-content a:not(.sub-link-title):not(.fui-button):visited:hover::after,
  .fui-panel a:not(.sub-link-title):not(.fui-button):link:hover::after,
  .fui-panel a:not(.sub-link-title):not(.fui-button):visited:hover::after {
    width: 100%; }

.fui-panel .fui-list > a.fui-item:link,
.fui-panel .fui-list > a.fui-item:visited {
  color: #202020; }
  .fui-panel .fui-list > a.fui-item:link::after,
  .fui-panel .fui-list > a.fui-item:visited::after {
    display: none; }

.fui-panel .fui-custom > a.fui-item:link,
.fui-panel .fui-custom > a.fui-item:visited {
  color: #202020 !important; }
  .fui-panel .fui-custom > a.fui-item:link:hover,
  .fui-panel .fui-custom > a.fui-item:visited:hover {
    color: grey !important; }
  .fui-panel .fui-custom > a.fui-item:link::after,
  .fui-panel .fui-custom > a.fui-item:visited::after {
    display: none !important; }

.is-help-center .fui-card .fui-card-content a:not(.sub-link-title):not(.fui-button):link,
.is-help-center .fui-card .fui-card-content a:not(.sub-link-title):not(.fui-button):visited,
.is-help-center .fui-panel a:not(.sub-link-title):not(.fui-button):link,
.is-help-center .fui-panel a:not(.sub-link-title):not(.fui-button):visited,
.is-ebu .fui-card .fui-card-content a:not(.sub-link-title):not(.fui-button):link,
.is-ebu .fui-card .fui-card-content a:not(.sub-link-title):not(.fui-button):visited,
.is-ebu .fui-panel a:not(.sub-link-title):not(.fui-button):link,
.is-ebu .fui-panel a:not(.sub-link-title):not(.fui-button):visited {
  color: #2F59C4 !important; }
  .is-help-center .fui-card .fui-card-content a:not(.sub-link-title):not(.fui-button):link::after,
  .is-help-center .fui-card .fui-card-content a:not(.sub-link-title):not(.fui-button):visited::after,
  .is-help-center .fui-panel a:not(.sub-link-title):not(.fui-button):link::after,
  .is-help-center .fui-panel a:not(.sub-link-title):not(.fui-button):visited::after,
  .is-ebu .fui-card .fui-card-content a:not(.sub-link-title):not(.fui-button):link::after,
  .is-ebu .fui-card .fui-card-content a:not(.sub-link-title):not(.fui-button):visited::after,
  .is-ebu .fui-panel a:not(.sub-link-title):not(.fui-button):link::after,
  .is-ebu .fui-panel a:not(.sub-link-title):not(.fui-button):visited::after {
    background: #2F59C4 !important; }

.is-help-center header.fui-header a.fui-item,
.is-help-center header.fui-header .fui-item[role="button"] {
  color: #141414; }
  .is-help-center header.fui-header a.fui-item:hover, .is-help-center header.fui-header a.fui-item:focus,
  .is-help-center header.fui-header .fui-item[role="button"]:hover,
  .is-help-center header.fui-header .fui-item[role="button"]:focus {
    color: #EF3123; }
  .is-help-center header.fui-header a.fui-item.is-active,
  .is-help-center header.fui-header .fui-item[role="button"].is-active {
    color: #EF3123; }
    .is-help-center header.fui-header a.fui-item.is-active::before,
    .is-help-center header.fui-header .fui-item[role="button"].is-active::before {
      background: #EF3123; }

.is-ebu header.fui-header a.fui-item,
.is-ebu header.fui-header .fui-item[role="button"] {
  color: #141414; }
  .is-ebu header.fui-header a.fui-item:hover,
  .is-ebu header.fui-header a.fui-item:hover *,
  .is-ebu header.fui-header .fui-item[role="button"]:hover,
  .is-ebu header.fui-header .fui-item[role="button"]:hover * {
    color: #2F59C4; }
  .is-ebu header.fui-header a.fui-item.is-active,
  .is-ebu header.fui-header a.fui-item.is-active *,
  .is-ebu header.fui-header .fui-item[role="button"].is-active,
  .is-ebu header.fui-header .fui-item[role="button"].is-active * {
    color: #2F59C4; }
    .is-ebu header.fui-header a.fui-item.is-active::before,
    .is-ebu header.fui-header a.fui-item.is-active *::before,
    .is-ebu header.fui-header .fui-item[role="button"].is-active::before,
    .is-ebu header.fui-header .fui-item[role="button"].is-active *::before {
      background: #2F59C4; }

@media screen and (min-width: 960px) {
  .fui-inline-items {
    margin-bottom: 60px; }
    .fui-inline-items .fui-item {
      width: 200px; }
  .fui-item.with-list > .content .fui-item {
    padding: 8px 0; }
  .fui-item.with-list > .content .content {
    font-size: 1rem; }
  .fui-item.with-list > .content .fui-list {
    margin: 0;
    max-width: none; } }

@media screen and (min-width: 1200px) {
  .fui-item.with-image {
    flex-direction: row;
    align-items: flex-start;
    align-content: flex-start; }
    .fui-item.with-image .image {
      width: 160px;
      align-content: flex-start; }
      .fui-item.with-image .image img {
        width: 160px;
        margin: 0; }
    .fui-item.with-image > .content {
      text-align: left;
      margin-left: 24px; } }

.fui-section-header {
  position: relative;
  z-index: 9; }

.fui-panel {
  background: #FFF;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  font-size: 18px;
  margin-bottom: 12px;
  box-shadow: 0 24px 48px 0 rgba(75, 88, 105, 0.12); }
  .fui-panel .text,
  .fui-panel .body-1, .fui-panel p {
    font-size: 16px; }
  .fui-panel h4 {
    font-weight: 400;
    margin: 24px 0 12px; }
  .fui-panel .fui-html-content {
    margin-bottom: 48px; }
    .fui-panel .fui-html-content:last-child {
      margin-bottom: 0; }
  .fui-panel .social-media a:not(.sub-link-title):not(.fui-button)::after,
  .fui-panel .social-media a:not(.sub-link-title):not(.fui-button):link::after,
  .fui-panel .social-media a:not(.sub-link-title):not(.fui-button):visited::after {
    display: none; }
  .fui-panel .social-media a:not(.sub-link-title):not(.fui-button):hover,
  .fui-panel .social-media a:not(.sub-link-title):not(.fui-button):link:hover,
  .fui-panel .social-media a:not(.sub-link-title):not(.fui-button):visited:hover {
    opacity: 0.75; }
    .fui-panel .social-media a:not(.sub-link-title):not(.fui-button):hover::after,
    .fui-panel .social-media a:not(.sub-link-title):not(.fui-button):link:hover::after,
    .fui-panel .social-media a:not(.sub-link-title):not(.fui-button):visited:hover::after {
      display: none; }
  .fui-panel .fui-panel-button .fui-button {
    white-space: normal;
    text-align: left; }
    .fui-panel .fui-panel-button .fui-button.is-secondary {
      text-align: center; }
      .fui-panel .fui-panel-button .fui-button.is-secondary::after {
        display: none !important; }
    .fui-panel .fui-panel-button .fui-button * {
      display: inline; }
    .fui-panel .fui-panel-button .fui-button .text {
      display: inline;
      height: auto;
      line-height: 1.5; }
  .fui-panel .panel-button-list {
    margin-bottom: 24px; }
    .fui-panel .panel-button-list .fui-panel-button {
      margin-bottom: 8px; }
      .fui-panel .panel-button-list .fui-panel-button > .fui-button {
        margin-bottom: 0; }
        @media screen and (max-width: 768px) {
          .fui-panel .panel-button-list .fui-panel-button > .fui-button {
            vertical-align: sub; } }
  .fui-panel .sub-link-title,
  .fui-panel a.sub-link-title {
    color: #202020;
    font-size: 22px;
    margin-top: 30;
    display: block; }
    .fui-panel .sub-link-title > *,
    .fui-panel a.sub-link-title > * {
      display: inline-block;
      font-weight: 400; }
      .fui-panel .sub-link-title > *::after,
      .fui-panel a.sub-link-title > *::after {
        content: "";
        display: block;
        width: 100%;
        height: 2px;
        background: transparent; }
    .fui-panel .sub-link-title:hover > *,
    .fui-panel a.sub-link-title:hover > * {
      color: #202020; }
      .fui-panel .sub-link-title:hover > *::after,
      .fui-panel a.sub-link-title:hover > *::after {
        content: "";
        display: block;
        width: 100%;
        height: 2px;
        background: #202020; }
  .fui-panel .fui-flow-container {
    margin-top: 40px;
    margin-bottom: 40px; }
    .fui-panel .fui-flow-container .fui-step {
      position: relative;
      padding-left: 52px; }
      .fui-panel .fui-flow-container .fui-step:last-child::before {
        display: none; }
      .fui-panel .fui-flow-container .fui-step::before {
        content: "";
        display: block;
        position: absolute;
        width: 1px;
        top: 50px;
        left: 20px;
        height: calc(100% - 60px);
        background: #E8ECF0; }
      .fui-panel .fui-flow-container .fui-step .fui-step-header {
        background: #FFF;
        margin: 0 0;
        padding: 0; }
        .fui-panel .fui-flow-container .fui-step .fui-step-header h2 {
          position: absolute;
          left: 0;
          top: 0;
          font-size: 24px;
          line-height: 40px;
          text-align: center;
          color: #2F59C4;
          font-weight: 600;
          margin: 0 0 0;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background: #E8ECF0; }
          .fui-panel .fui-flow-container .fui-step .fui-step-header h2 small {
            display: none;
            font-size: 0.875rem;
            font-weight: 500; }
        .fui-panel .fui-flow-container .fui-step .fui-step-header .text {
          display: block;
          line-height: 40px; }
      .fui-panel .fui-flow-container .fui-step .fui-step-content {
        font-size: 0.875rem;
        color: #5F6E84;
        padding-bottom: 45px; }
  .fui-panel .fui-panel-tab {
    margin: 24px 0; }
    .fui-panel .fui-panel-tab .fui-tab {
      overflow-x: auto; }
      .fui-panel .fui-panel-tab .fui-tab::-webkit-scrollbar {
        display: none; }
    .fui-panel .fui-panel-tab .fui-tab-body {
      padding-top: 16px; }
  .fui-panel .fui-table + .fui-panel-information {
    margin-top: 12px; }
  .fui-panel .fui-panel-information {
    background: #fafafa; }
    .fui-panel .fui-panel-information .fui-collapse {
      padding: 15px; }
      .fui-panel .fui-panel-information .fui-collapse .collapse-body article {
        padding-bottom: 0; }
      .fui-panel .fui-panel-information .fui-collapse .collapse-header {
        font-size: 16px;
        color: #5F6E84;
        font-weight: 500;
        letter-spacing: 0.2px; }
      .fui-panel .fui-panel-information .fui-collapse .collapse-body {
        font-size: 14px;
        line-height: 24px;
        color: #5F6E84; }
      .fui-panel .fui-panel-information .fui-collapse.is-open .collapse-body {
        margin-bottom: 30px; }
  .fui-panel .text a,
  .fui-panel .text a:link,
  .fui-panel .text a:visited {
    color: #2F59C4; }
    .fui-panel .text a:hover, .fui-panel .text a:focus,
    .fui-panel .text a:link:hover,
    .fui-panel .text a:link:focus,
    .fui-panel .text a:visited:hover,
    .fui-panel .text a:visited:focus {
      color: #1642B1; }
  .fui-panel .fui-panel-title {
    margin-top: 24px;
    margin-bottom: 24px; }
    .fui-panel .fui-panel-title:first-child {
      margin-top: 0; }
    .fui-panel .fui-panel-title img.fui-title-icon {
      width: auto;
      height: 50px;
      margin: 0 0 12px; }
  .fui-panel .fui-item .content {
    font-weight: 500; }
  .fui-panel .panel-layout-expand.panel-layout {
    overflow: visible; }
    .fui-panel .panel-layout-expand.panel-layout .fui-collapse-body article {
      overflow: visible;
      -webkit-line-clamp: initial; }
    .fui-panel .panel-layout-expand.panel-layout .expand-wrapper {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center; }
  .fui-panel .panel-layout {
    margin-top: 24px;
    margin-bottom: 24px; }
    .fui-panel .panel-layout img {
      width: 100%;
      margin: 0; }
    .fui-panel .panel-layout .fui-collapse-body {
      transition: height 0.3s 0.05s ease;
      margin-bottom: 16px;
      overflow: hidden; }
      .fui-panel .panel-layout .fui-collapse-body .fui-list {
        margin-bottom: 52px; }
        .fui-panel .panel-layout .fui-collapse-body .fui-list .fui-item {
          padding: 3px 0; }
      .fui-panel .panel-layout .fui-collapse-body article {
        line-height: 1.5;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: block;
        display: -webkit-box;
        -webkit-line-clamp: 8;
        -webkit-box-orient: vertical;
        white-space: normal;
        -ms-text-overflow: ellipsis; }
      .fui-panel .panel-layout .fui-collapse-body.is-open article {
        -webkit-line-clamp: inherit; }
    .fui-panel .panel-layout .fui-collapse-action .fui-button {
      padding-left: 0;
      text-align: left; }
  .fui-panel.is-table {
    padding: 15px; }
  .fui-panel.is-collapse.is-open .fui-collapse-action .fui-button {
    color: #FFF;
    background: #4F5E72; }
  .fui-panel.is-collapse .fui-collapse-body * {
    color: #FFFFFF; }
  .fui-panel.is-collapse .fui-collapse-body .text {
    color: #FFFFFF !important; }
  .fui-panel.is-collapse .fui-collapse-body .fui-panel-title {
    margin-top: 60px;
    padding: 0;
    font-size: 20px; }
    .fui-panel.is-collapse .fui-collapse-body .fui-panel-title img {
      height: 48px; }
    .fui-panel.is-collapse .fui-collapse-body .fui-panel-title:first-child {
      margin-top: 0; }
  .fui-panel.is-collapse .fui-collapse-body .fui-list {
    margin-bottom: 52px; }
    .fui-panel.is-collapse .fui-collapse-body .fui-list .fui-item {
      padding: 3px 0; }
      .fui-panel.is-collapse .fui-collapse-body .fui-list .fui-item .prefix i {
        color: #85D1DC; }
  .fui-panel.is-article, .fui-panel.is-article.is-collapse {
    padding: 30px; }
    .fui-panel.is-article .MuiGrid-root.MuiGrid-container, .fui-panel.is-article.is-collapse .MuiGrid-root.MuiGrid-container {
      flex-direction: row-reverse; }
    .fui-panel.is-article.is-right .MuiGrid-root.MuiGrid-container, .fui-panel.is-article.is-collapse.is-right .MuiGrid-root.MuiGrid-container {
      flex-direction: row; }
    .fui-panel.is-article img, .fui-panel.is-article.is-collapse img {
      width: 100%; }
    .fui-panel.is-article .fui-panel-title, .fui-panel.is-article.is-collapse .fui-panel-title {
      margin: 0 0 24px; }
    .fui-panel.is-article .fui-panel-title,
    .fui-panel.is-article .fui-collapse-body, .fui-panel.is-article.is-collapse .fui-panel-title,
    .fui-panel.is-article.is-collapse .fui-collapse-body {
      padding: 0; }
    .fui-panel.is-article .fui-collapse-action, .fui-panel.is-article.is-collapse .fui-collapse-action {
      margin-top: 24px; }
      .fui-panel.is-article .fui-collapse-action .fui-button, .fui-panel.is-article.is-collapse .fui-collapse-action .fui-button {
        width: auto;
        text-align: left;
        padding-left: 0;
        background: none; }
        .fui-panel.is-article .fui-collapse-action .fui-button i, .fui-panel.is-article.is-collapse .fui-collapse-action .fui-button i {
          margin-left: 8px; }
    .fui-panel.is-article .fui-collapse-body, .fui-panel.is-article.is-collapse .fui-collapse-body {
      background: none;
      height: 190px; }
      .fui-panel.is-article .fui-collapse-body article, .fui-panel.is-article.is-collapse .fui-collapse-body article {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: block;
        display: -webkit-box;
        -webkit-line-clamp: 7;
        -webkit-box-orient: vertical;
        white-space: normal;
        -ms-text-overflow: ellipsis; }
      .fui-panel.is-article .fui-collapse-body *, .fui-panel.is-article.is-collapse .fui-collapse-body * {
        color: #202020;
        padding: 0;
        line-height: 28px; }
    .fui-panel.is-article.is-open .fui-collapse-body article, .fui-panel.is-article.is-collapse.is-open .fui-collapse-body article {
      height: auto;
      -webkit-line-clamp: initial;
      overflow: visible; }
  .fui-panel.is-collapse {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0 !important; }
    .fui-panel.is-collapse.is-open .fui-collapse-body {
      display: block; }
    .fui-panel.is-collapse > .fui-panel-title,
    .fui-panel.is-collapse > .fui-panel-body {
      padding-left: 30px;
      padding-right: 30px; }
    .fui-panel.is-collapse .fui-panel-body {
      padding-bottom: 30px !important; }
    .fui-panel.is-collapse .fui-collapse-body > * {
      padding: 30px; }
    .fui-panel.is-collapse .fui-collapse-body {
      color: #FFFFFF;
      padding: 0;
      background: #5F6E84 url(/DigService/resources/common/images/darkgray-style-2.png) repeat-y left top;
      background-size: 100% auto;
      height: 0;
      display: block;
      overflow: hidden;
      transition: all 0.3s 0.1s ease; }
    .fui-panel.is-collapse .fui-collapse-action .fui-button {
      display: block;
      margin: 0;
      width: 100%;
      background: #fafafa;
      color: #2F59C4;
      text-align: center; }

.fui-panel-title {
  color: #2F59C4; }

* + .fui-panel-title {
  margin-top: 40px; }

@media screen and (max-width: 960px) {
  .fui-panel .panel-layout .MuiGrid-root.MuiGrid-item {
    width: 100%; }
    .fui-panel .panel-layout .MuiGrid-root.MuiGrid-item + .MuiGrid-root.MuiGrid-item {
      padding-top: 0; } }

@media screen and (min-width: 960px) {
  .fui-panel {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
    padding-left: 60px;
    padding-right: 60px; }
    .fui-panel .text,
    .fui-panel .body-1, .fui-panel p {
      font-size: 18px; }
    .fui-panel .fui-panel-title img.fui-title-icon {
      width: auto;
      height: 70px; }
    .fui-panel .fui-panel-information .fui-collapse {
      padding: 30px; }
    .fui-panel.is-table {
      padding: 30px; }
    .fui-panel.is-collapse > .fui-panel-title,
    .fui-panel.is-collapse > .fui-panel-body {
      padding-left: 60px;
      padding-right: 60px;
      padding-bottom: 0px !important; }
    .fui-panel.is-collapse .fui-panel-body {
      padding-bottom: 60px !important; }
    .fui-panel.is-collapse .fui-collapse-body > * {
      padding: 40px 100px 20px; }
    .fui-panel.is-article, .fui-panel.is-article.is-collapse {
      padding: 60px; }
    .fui-panel .fui-flow-container .fui-step {
      padding-left: 120px; }
      .fui-panel .fui-flow-container .fui-step::before {
        left: 30px;
        top: 70px;
        height: calc(100% - 80px); }
      .fui-panel .fui-flow-container .fui-step .fui-step-header h2 {
        line-height: 60px;
        width: 60px;
        height: 60px;
        font-size: 28px; }
        .fui-panel .fui-flow-container .fui-step .fui-step-header h2 + .text {
          line-height: 60px; }
      .fui-panel .fui-flow-container .fui-step .fui-step-content {
        padding-bottom: 55px; } }

.fui-cards {
  position: relative;
  display: block;
  overflow-y: visible;
  overflow-x: auto;
  margin: 0 0;
  padding: 30px 0 60px;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none; }
  .fui-cards .fui-card {
    display: inline-flex;
    vertical-align: top;
    margin: 0 6px; }
  .fui-cards.w-100 {
    margin: 0 !important; }
    .fui-cards.w-100 .fui-card {
      width: 100%;
      margin-left: 0 !important;
      margin-right: 0 !important; }
      .fui-cards.w-100 .fui-card .fui-card-extra {
        padding-left: 0; }
  .fui-cards.is-roaming-plan-card {
    padding: 0 0 12px 0 !important;
    overflow: visible; }
    .fui-cards.is-roaming-plan-card .fui-card {
      margin-bottom: 0; }
    .fui-cards.is-roaming-plan-card .fui-cards {
      overflow: visible;
      margin: 0; }
    .fui-cards.is-roaming-plan-card .fui-card-content {
      position: relative; }
    .fui-cards.is-roaming-plan-card .fui-card-title {
      margin-top: 0 !important; }
    .fui-cards.is-roaming-plan-card .fui-card-tag {
      position: absolute;
      top: 30px;
      right: 30px; }
      .fui-cards.is-roaming-plan-card .fui-card-tag span {
        display: block;
        padding: 3px 10px;
        margin-bottom: 5px;
        text-align: center;
        font-size: 14px;
        border-radius: 15px; }
      .fui-cards.is-roaming-plan-card .fui-card-tag .applied {
        color: #fff;
        background: #85D1DC; }
      .fui-cards.is-roaming-plan-card .fui-card-tag .using {
        color: #fff;
        background: #f05c38; }
      .fui-cards.is-roaming-plan-card .fui-card-tag .over,
      .fui-cards.is-roaming-plan-card .fui-card-tag .cancel {
        background: #fafafa; }
    .fui-cards.is-roaming-plan-card .last-block {
      padding-top: 40px; }
      .fui-cards.is-roaming-plan-card .last-block.d-mobile {
        display: none; }
    .fui-cards.is-roaming-plan-card .fui-progress-box {
      border: 0;
      padding: 0;
      margin-bottom: 24px; }
      .fui-cards.is-roaming-plan-card .fui-progress-box .fui-progress-box {
        border: solid 2px #E8ECF0; }
        .fui-cards.is-roaming-plan-card .fui-progress-box .fui-progress-box .last-block {
          padding-top: 0px;
          font-size: 16px; }
          .fui-cards.is-roaming-plan-card .fui-progress-box .fui-progress-box .last-block .last {
            font-size: 16px; }
        .fui-cards.is-roaming-plan-card .fui-progress-box .fui-progress-box .fui-segment {
          padding: 24px 30px; }
    .fui-cards.is-roaming-plan-card.is-detail .fui-card {
      flex-flow: column;
      margin-bottom: 0; }
    .fui-cards.is-roaming-plan-card.is-detail .fui-section-collapse {
      padding: 0;
      background: #fafafa; }
      .fui-cards.is-roaming-plan-card.is-detail .fui-section-collapse .fui-container {
        width: 100%;
        padding: 0; }
      .fui-cards.is-roaming-plan-card.is-detail .fui-section-collapse .fui-collapse.is-open::before, .fui-cards.is-roaming-plan-card.is-detail .fui-section-collapse .fui-collapse.is-open::after {
        right: 30px; }
      .fui-cards.is-roaming-plan-card.is-detail .fui-section-collapse .fui-collapse.is-open .collapse-header {
        margin-bottom: 0; }
      .fui-cards.is-roaming-plan-card.is-detail .fui-section-collapse .collapse-header {
        padding: 30px;
        font-size: 16px; }
        .fui-cards.is-roaming-plan-card.is-detail .fui-section-collapse .collapse-header::before, .fui-cards.is-roaming-plan-card.is-detail .fui-section-collapse .collapse-header::after {
          right: 30px; }
      .fui-cards.is-roaming-plan-card.is-detail .fui-section-collapse .collapse-body {
        margin-top: 0;
        padding: 0 30px;
        white-space: normal; }
  .fui-cards.card-roaming-multi .fui-card {
    display: flex; }
    .fui-cards.card-roaming-multi .fui-card .action {
      cursor: pointer;
      width: auto;
      display: inline-block;
      align-self: flex-start; }
  .fui-cards.is-with-icon-card .fui-card .fui-card-image {
    width: auto;
    padding-top: 24px;
    padding-left: 24px;
    padding-bottom: 0; }
    .fui-cards.is-with-icon-card .fui-card .fui-card-image img {
      position: relative;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: auto;
      height: auto; }
  .fui-cards.is-with-icon-card .fui-card .fui-card-content {
    padding-top: 10px; }
  .fui-cards.is-switch-card, .fui-cards.is-apply-card {
    display: flex;
    vertical-align: top;
    overflow: visible; }
    .fui-cards.is-switch-card .fui-card, .fui-cards.is-apply-card .fui-card {
      width: 100%;
      padding: 30px;
      background: #fff; }
    .fui-cards.is-switch-card .fui-card-title, .fui-cards.is-apply-card .fui-card-title {
      margin-bottom: 12px;
      font-size: 24px; }
      .fui-cards.is-switch-card .fui-card-title img, .fui-cards.is-apply-card .fui-card-title img {
        margin-left: 16px;
        vertical-align: text-top; }
    .fui-cards.is-switch-card p, .fui-cards.is-apply-card p {
      margin-bottom: 0; }
    .fui-cards.is-switch-card .search-bar, .fui-cards.is-apply-card .search-bar {
      background: #fff;
      display: flex;
      justify-content: flex-end;
      align-items: center; }
      .fui-cards.is-switch-card .search-bar input:checked + div.content span.on, .fui-cards.is-apply-card .search-bar input:checked + div.content span.on {
        color: #202020; }
    .fui-cards.is-switch-card .action-arrow, .fui-cards.is-apply-card .action-arrow {
      height: 100%;
      font-size: 16px;
      align-items: center;
      font-weight: 500; }
  .fui-cards.four-card {
    display: flex; }
  .fui-cards::-webkit-scrollbar {
    display: none; }
  .fui-cards.with-collapse-card {
    display: block !important; }
    .fui-cards.with-collapse-card.two-card .fui-cards {
      width: calc(50% - 12px); }
    .fui-cards.with-collapse-card.three-card .fui-card .fui-card-caption .fui-card-description > div {
      -webkit-line-clamp: 8 !important; }
  .fui-cards.is-video {
    flex-wrap: wrap;
    overflow: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    flex-wrap: nowrap;
    display: flex; }
    .fui-cards.is-video.two-card {
      margin: 0 -21px;
      padding: 0px 15px 60px 15px; }
    .fui-cards.is-video .fui-card-action {
      min-height: 0;
      min-height: initial;
      width: 270px; }
    .fui-cards.is-video .fui-card.is-video .fui-card-image {
      padding: 0;
      display: inline-block;
      height: 152px; }
    .fui-cards.is-video + .load-more {
      text-align: center;
      position: relative;
      margin: 36px 0 90px 0; }
      .fui-cards.is-video + .load-more a.expand {
        color: #202020;
        font-weight: 500; }
        .fui-cards.is-video + .load-more a.expand::after {
          display: none; }
      .fui-cards.is-video + .load-more .no-more {
        color: #bfbfbf;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: default !important; }
        .fui-cards.is-video + .load-more .no-more i {
          font-size: 30px;
          margin-right: 6px; }
  .fui-cards.no-scrollbar {
    overflow: visible;
    white-space: normal;
    flex-wrap: wrap; }
    .fui-cards.no-scrollbar .fui-card {
      width: calc(100% - 12px);
      margin-bottom: 12px; }
    @media screen and (min-width: 640px) {
      .fui-cards.no-scrollbar .fui-card {
        width: calc(50% - 12px); } }
  .fui-cards.news {
    overflow: visible; }
    .fui-cards.news::after {
      display: none; }
  @media screen and (max-width: 768px) {
    .fui-cards.news .fui-card {
      width: 100%;
      margin: 0; }
    .fui-cards.news .fui-card + .fui-card {
      display: none; }
    .fui-cards.four-card {
      flex-wrap: wrap; }
      .fui-cards.four-card .fui-card.service-entry {
        margin-bottom: 12px;
        width: calc(50% - 12px); } }
  .fui-cards.two-card:not(.is-video) .fui-card {
    width: 100%;
    margin: 0 0 12px; }
  .fui-cards a.fui-card-action:hover {
    color: #202020; }
  .fui-cards.is-flat {
    padding: 0 !important;
    overflow: visible; }
    .fui-cards.is-flat .fui-card {
      min-width: 0; }

@media screen and (max-width: 960px) {
  .vertical-card .fui-cards {
    display: block;
    overflow: visible;
    white-space: normal; }
    .vertical-card .fui-cards .fui-card {
      width: calc( 50% - 12px);
      margin-bottom: 12px; }
      .vertical-card .fui-cards .fui-card .fui-card-action {
        width: 100%; }
        .vertical-card .fui-cards .fui-card .fui-card-action .card-image img {
          height: auto; }
  .vertical-card .load-more {
    display: block; } }

.fui-video-card-container .fui-cards.is-video-list {
  flex-wrap: wrap;
  white-space: normal;
  margin: 0;
  padding: 0;
  overflow: visible; }
  .fui-video-card-container .fui-cards.is-video-list .fui-card.is-video {
    width: 100%;
    margin-bottom: 12px;
    margin-left: 0;
    margin-right: 0; }
  @media screen and (min-width: 600px) {
    .fui-video-card-container .fui-cards.is-video-list {
      margin: 0 -6px; }
      .fui-video-card-container .fui-cards.is-video-list .fui-card.is-video {
        margin: 6px;
        width: calc(50% - 12px); } }
  @media screen and (min-width: 960px) {
    .fui-video-card-container .fui-cards.is-video-list .fui-card.is-video {
      width: calc(33.33333% - 12px); } }

.load-more .no-more {
  color: #8A8A8A !important;
  cursor: default !important; }

.fui-container .fui-cards {
  margin: 0 -15px;
  padding: 30px 15px; }

.fui-card {
  display: flex;
  box-shadow: 0 6px 12px 0 rgba(75, 88, 105, 0.12) !important;
  transition: all 0.3s ease; }
  .fui-card:hover {
    box-shadow: 0 18px 36px 0 rgba(75, 88, 105, 0.18) !important; }
  .fui-card.fui-card-with-img {
    margin-bottom: 30px;
    box-shadow: none !important; }
    .fui-card.fui-card-with-img .fui-card-image {
      padding-bottom: 0;
      width: auto; }
      .fui-card.fui-card-with-img .fui-card-image img {
        position: relative;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 160px; }
    .fui-card.fui-card-with-img .fui-card-caption {
      justify-content: center; }
      .fui-card.fui-card-with-img .fui-card-caption .fui-card-title {
        margin-bottom: 6px; }
      .fui-card.fui-card-with-img .fui-card-caption .fui-card-desc {
        margin-top: 0;
        color: #5F6E84; }
  .fui-card .fui-card-action:hover .fui-button.is-arrow {
    color: transparent; }
  .fui-card.is-document {
    position: relative;
    min-height: 72px;
    border: solid 1px #E8ECF0;
    align-content: center;
    align-items: center; }
    .fui-card.is-document .content {
      flex: 1 1;
      flex-grow: 1;
      padding: 22px 20px; }
      .fui-card.is-document .content a,
      .fui-card.is-document .content a:link {
        display: block;
        color: #202020; }
        .fui-card.is-document .content a::after,
        .fui-card.is-document .content a:link::after {
          display: none; }
    .fui-card.is-document .extra {
      padding-left: 12px;
      padding-right: 12px;
      border-left: 1px solid #D6D6D6; }
      .fui-card.is-document .extra .fui-button,
      .fui-card.is-document .extra .fui-button i {
        margin: 0;
        padding: 0;
        color: #0A3476; }
        .fui-card.is-document .extra .fui-button[disabled] i,
        .fui-card.is-document .extra .fui-button i[disabled] i {
          color: #D6D6D6; }
      .fui-card.is-document .extra .fui-button i {
        font-size: 24px; }
  .fui-card.is-price-card .fui-card-action .fui-card-content {
    position: relative;
    text-align: center;
    padding: 30px 20px; }
    .fui-card.is-price-card .fui-card-action .fui-card-content h6 {
      margin: 0; }
    .fui-card.is-price-card .fui-card-action .fui-card-content .ribbon {
      position: absolute;
      left: 0;
      top: 0;
      color: #FFFFFF;
      height: 30px;
      padding: 0 10px 0 12px;
      line-height: 28px;
      background-color: #85D1DC; }
      .fui-card.is-price-card .fui-card-action .fui-card-content .ribbon > * {
        position: relative;
        z-index: 1; }
      .fui-card.is-price-card .fui-card-action .fui-card-content .ribbon::before {
        content: "";
        position: absolute;
        width: 160%;
        height: 100%;
        left: 0;
        background: url(/DigService/resources/cbu/product/images/price-ribbon.png) right center no-repeat;
        background-size: auto 100%;
        z-index: 0; }
    .fui-card.is-price-card .fui-card-action .fui-card-content .fui-price {
      color: #2F59C4;
      font-size: 56px;
      margin-bottom: 30px; }
      .fui-card.is-price-card .fui-card-action .fui-card-content .fui-price span.prefix {
        font-size: 22px;
        vertical-align: top;
        margin-right: 4px; }
      .fui-card.is-price-card .fui-card-action .fui-card-content .fui-price span.unit {
        color: #202020;
        font-size: 0.875rem;
        vertical-align: bottom; }
        .fui-card.is-price-card .fui-card-action .fui-card-content .fui-price span.unit::before {
          content: "";
          display: inline-block;
          width: 1px;
          height: 16px;
          background: #BFBFBF;
          transform: rotate(40deg);
          margin: 0 8px;
          vertical-align: middle; }
      .fui-card.is-price-card .fui-card-action .fui-card-content .fui-price span {
        display: inline-block;
        line-height: 1; }
    .fui-card.is-price-card .fui-card-action .fui-card-content .fui-list {
      display: inline-block; }
    .fui-card.is-price-card .fui-card-action .fui-card-content .fui-item {
      text-align: left;
      color: #5F6E84; }
      .fui-card.is-price-card .fui-card-action .fui-card-content .fui-item .prefix {
        color: #2F59C4; }
  .fui-card.is-simple {
    display: flex;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 12px; }
    .fui-card.is-simple > div {
      width: 100%; }
    .fui-card.is-simple .fui-card-caption {
      background: #FFFFFF;
      min-height: 0; }
      .fui-card.is-simple .fui-card-caption .fui-card-content {
        padding: 30px; }
      .fui-card.is-simple .fui-card-caption .fui-card-description {
        margin-bottom: 0;
        font-size: 1rem;
        line-height: 1.75rem;
        height: auto;
        display: block;
        -webkit-line-clamp: initial; }
      .fui-card.is-simple .fui-card-caption .fui-card-title {
        margin-top: 0; }
    .fui-card.is-simple .fui-card-extra {
      display: none; }
    .fui-card.is-simple.is-blue .fui-card-caption .fui-card-content .fui-card-title {
      color: #2F59C4; }
  .fui-card.is-tooltip .fui-card-content .fui-card-title {
    margin-bottom: 12px; }
    .fui-card.is-tooltip .fui-card-content .fui-card-title.fui-tooltip-container {
      margin-left: 8px; }
    .fui-card.is-tooltip .fui-card-content .fui-card-title + .fui-card-description {
      margin-top: 0; }
  .fui-card.is-collapse {
    display: inline-block;
    flex-direction: column;
    background: #FFFFFF;
    margin-bottom: 12px; }
    .fui-card.is-collapse .fui-card-image {
      width: auto; }
    .fui-card.is-collapse .fui-card-extra {
      display: block;
      padding: 0 !important; }
      .fui-card.is-collapse .fui-card-extra > * {
        display: block;
        padding: 10px 0;
        text-align: center; }
        .fui-card.is-collapse .fui-card-extra > * * {
          vertical-align: middle; }
    .fui-card.is-collapse .fui-card-caption {
      flex: 1 1; }
      .fui-card.is-collapse .fui-card-caption .fui-card-content {
        padding-bottom: 80px; }
        .fui-card.is-collapse .fui-card-caption .fui-card-content + .fui-card-extra {
          margin-top: -40px; }
      .fui-card.is-collapse .fui-card-caption .fui-card-title {
        text-align: center; }
        .fui-card.is-collapse .fui-card-caption .fui-card-title img {
          width: 48px;
          height: 48px;
          display: block;
          margin: 24px auto; }
      .fui-card.is-collapse .fui-card-caption .fui-card-description {
        font-size: 1rem;
        margin-bottom: 0;
        transition: height 0.3s ease;
        -webkit-line-clamp: initial; }
        .fui-card.is-collapse .fui-card-caption .fui-card-description > div {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          display: block;
          display: -webkit-box;
          -webkit-line-clamp: 5;
          -webkit-box-orient: vertical;
          white-space: normal;
          -ms-text-overflow: ellipsis; }
    .fui-card.is-collapse.is-open .fui-card-caption .fui-card-description div.text {
      -webkit-line-clamp: initial !important; }
    .fui-card.is-collapse.is-blue .fui-card-caption .fui-card-title {
      color: #2F59C4; }
    .fui-card.is-collapse.is-blue .fui-card-extra > .fui-button {
      padding-top: 0;
      padding-bottom: 0;
      margin: 0;
      width: 100%;
      color: #2F59C4;
      background: #fafafa; }
  .fui-card a.fui-card-action {
    cursor: pointer; }
  .fui-card .fui-card-action,
  .fui-card a.fui-card-action {
    min-height: 218px;
    display: flex;
    white-space: normal;
    white-space: initial;
    flex-direction: column;
    width: 100%;
    color: #202020;
    background: #FFF; }
    .fui-card .fui-card-action::after,
    .fui-card a.fui-card-action::after {
      display: none; }
    .fui-card .fui-card-action:hover,
    .fui-card a.fui-card-action:hover {
      color: #202020;
      background: #FFF; }
      .fui-card .fui-card-action:hover .fui-card-image img,
      .fui-card a.fui-card-action:hover .fui-card-image img {
        transform: scale(1.1); }
  .fui-card .fui-card-caption {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    text-align: left; }
  .fui-card .fui-card-content {
    flex: 1 1 auto;
    padding: 24px 24px 0; }
    .fui-card .fui-card-content .fui-card-date {
      font-size: 1rem;
      margin-bottom: 8px;
      font-weight: 500; }
    .fui-card .fui-card-content .fui-card-meta {
      color: #8A8A8A;
      font-size: 0.875rem;
      margin-bottom: 8px; }
    .fui-card .fui-card-content .fui-card-title {
      font-weight: 500;
      font-size: 1.5rem;
      margin: 8px 0;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: block;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      white-space: normal;
      -ms-text-overflow: ellipsis; }
      .fui-card .fui-card-content .fui-card-title .text {
        font-size: 1.5rem;
        line-height: 1.5; }
      .fui-card .fui-card-content .fui-card-title .icon {
        width: 48px; }
        .fui-card .fui-card-content .fui-card-title .icon img {
          width: 100%; }
    .fui-card .fui-card-content .fui-card-description {
      font-size: 1rem;
      line-height: 28px;
      display: block;
      display: -webkit-box;
      height: 3.5em;
      overflow: hidden;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical; }
      .fui-card .fui-card-content .fui-card-description * {
        margin: 0 !important; }
      .fui-card .fui-card-content .fui-card-description br {
        display: none !important; }
  .fui-card .fui-card-extra {
    padding: 0 24px 16px; }
  .fui-card .fui-card-image + .fui-caption > .fui-content {
    min-height: 0; }
  .fui-card .fui-card-image {
    position: relative;
    overflow: hidden;
    padding-bottom: 56.25%; }
  .fui-card .fui-card-image,
  .fui-card .fui-card-image img {
    line-height: 0;
    width: 100%; }
  .fui-card .fui-card-image img {
    position: absolute;
    left: -100%;
    right: -100%;
    top: -100%;
    bottom: -100%;
    margin: auto;
    height: 100%;
    transform: scale(1);
    transition: transform 0.3s ease; }
  .fui-card.service-entry {
    margin-bottom: 12px; }
    .fui-card.service-entry .fui-card-action {
      width: 100%;
      min-height: 0;
      background: #FFF; }
      .fui-card.service-entry .fui-card-action:hover {
        background-size: 110% auto; }
      .fui-card.service-entry .fui-card-action .fui-card-content {
        text-align: center;
        padding: 20px 0; }
        .fui-card.service-entry .fui-card-action .fui-card-content .fui-card-title {
          margin: 0; }
          .fui-card.service-entry .fui-card-action .fui-card-content .fui-card-title .text {
            font-size: 1rem; }
      .fui-card.service-entry .fui-card-action .fui-card-extra {
        display: none; }
  .fui-card.box .fui-card-action {
    position: relative;
    min-height: 150px; }
    .fui-card.box .fui-card-action .icon {
      position: absolute;
      color: #FFF;
      right: 0;
      bottom: 0;
      z-index: 3;
      line-height: 0; }
      .fui-card.box .fui-card-action .icon img {
        width: 80px;
        line-height: 0; }
    .fui-card.box .fui-card-action .fui-card-content {
      padding: 20px;
      height: 150px;
      word-break: break-all;
      color: #FFF;
      background: #fafafa;
      background-size: cover;
      background-position: left top; }
      .fui-card.box .fui-card-action .fui-card-content h4 {
        margin-top: 0; }
  .fui-card.box.is-blue .fui-card-action .fui-card-content {
    background-image: url(/DigService/resources/common/images/ebu-theme-pattern-01.png); }
  .fui-card.box.is-business .fui-card-action .fui-card-content {
    background-image: url(/DigService/resources/common/images/ebu-theme-pattern-02.png); }
  .fui-card.box.is-navy .fui-card-action .fui-card-content {
    background-image: url(/DigService/resources/common/images/ebu-theme-pattern-03.png); }
  .fui-card.box.is-gray .fui-card-action .fui-card-content {
    background-image: url(/DigService/resources/common/images/ebu-theme-pattern-04.png); }
  .fui-card.box.is-yellow .fui-card-action .fui-card-content {
    background-image: url(/DigService/resources/cbu/cbu-index/cbu-menu-pattern-1.png); }
  .fui-card.box.is-red .fui-card-action .fui-card-content {
    background-image: url(/DigService/resources/cbu/cbu-index/cbu-menu-pattern-2.png); }
  .fui-card.box.is-sky .fui-card-action .fui-card-content {
    background-image: url(/DigService/resources/cbu/cbu-index/cbu-menu-pattern-3.png); }
  .fui-card.box .fui-card-extra {
    display: none; }
  .fui-card.box .fui-card-content .fui-card-title {
    height: auto; }
  .fui-card.promotion-article {
    min-width: 0;
    width: calc(50% - 16px);
    color: #FFF;
    margin: 0 8px 16px; }
    .fui-card.promotion-article .fui-card-action {
      color: #FFF;
      min-height: 0; }
      .fui-card.promotion-article .fui-card-action:hover {
        color: #FFF; }
    .fui-card.promotion-article .fui-card-extra,
    .fui-card.promotion-article .fui-card-content {
      padding-left: 24px;
      padding-right: 24px; }
    .fui-card.promotion-article .fui-card-content {
      flex: 1 1 auto;
      flex-grow: 1;
      padding-bottom: 0; }
      .fui-card.promotion-article .fui-card-content .fui-card-meta {
        font-size: 0.85rem; }
      .fui-card.promotion-article .fui-card-content .fui-card-title {
        margin: 6px 0;
        font-size: 1.25rem;
        line-height: 30px;
        display: block;
        display: -webkit-box;
        height: 6em;
        overflow: hidden;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical; }
      .fui-card.promotion-article .fui-card-content .fui-card-description {
        font-size: 1rem; }
    .fui-card.promotion-article.is-blue .fui-card-action {
      background: #0A3476; }
    .fui-card.promotion-article.is-blue .fui-card-meta {
      color: #9EDFE9; }
    .fui-card.promotion-article.is-main {
      width: calc(100% + 30px);
      margin-left: -15px;
      margin-right: -15px;
      margin-bottom: 16px; }
      .fui-card.promotion-article.is-main .fui-card-action {
        flex-direction: column-reverse; }
      .fui-card.promotion-article.is-main .fui-card-caption {
        background: #0A3476 url(/DigService/resources/common/images/patternd-blue-article.png) right bottom no-repeat;
        background-size: 508px auto; }
      .fui-card.promotion-article.is-main .fui-card-content {
        padding: 30px; }
        .fui-card.promotion-article.is-main .fui-card-content .fui-card-description {
          height: 5rem;
          -webkit-line-clamp: 3; }
        .fui-card.promotion-article.is-main .fui-card-content .fui-card-title {
          height: auto;
          -webkit-line-clamp: 2; }
          .fui-card.promotion-article.is-main .fui-card-content .fui-card-title .text {
            font-size: 1.75rem;
            line-height: 1.5;
            -webkit-line-clamp: 2; }
      .fui-card.promotion-article.is-main .fui-card-image {
        position: relative;
        padding-bottom: 50%;
        overflow: hidden; }
        .fui-card.promotion-article.is-main .fui-card-image::after {
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          width: 103px;
          height: 75px;
          background: url(/DigService/resources/ebu/images/red-arrow.png) no-repeat left bottom;
          background-size: contain; }
        .fui-card.promotion-article.is-main .fui-card-image img {
          position: absolute;
          width: 100%;
          height: auto;
          top: -100%;
          bottom: -100%;
          left: -100%;
          right: -100%;
          margin: auto; }
  .fui-card.paded .fui-card-content {
    padding: 4rem; }

.promotion-article-list {
  margin: 0 -6px;
  display: flex;
  flex-wrap: wrap; }
  .promotion-article-list .fui-card.promotion-article {
    width: calc(50% - 12px);
    margin: 0 6px 16px; }
    .promotion-article-list .fui-card.promotion-article .fui-card-title {
      height: auto; }

.fui-card.is-video {
  margin-left: 6px;
  margin-right: 6px; }
  .fui-card.is-video .fui-card-image {
    height: 220px; }
    .fui-card.is-video .fui-card-image::before, .fui-card.is-video .fui-card-image::after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      z-index: 2; }
    .fui-card.is-video .fui-card-image::before {
      width: 60px;
      height: 60px;
      background: #FFFFFF;
      opacity: 0.84;
      border-radius: 30px;
      box-shadow: 0 11px 48px 0 rgba(32, 32, 32, 0.3); }
    .fui-card.is-video .fui-card-image::after {
      left: 20px;
      width: 0;
      height: 0;
      border-left: 16px solid #D3060F;
      border-top: 12px solid transparent;
      border-bottom: 12px solid transparent;
      border-right: 16px solid transparent; }

@media all and (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
  .fui-cards.is-video .fui-card.is-video {
    float: left;
    display: block;
    width: auto;
    width: initial; } }

@media screen and (min-width: 600px) {
  .fui-card .fui-card-extra {
    padding: 0 24px 16px; }
  .fui-container .fui-cards {
    padding-left: 0;
    padding-right: 0;
    margin: -8px; }
  .fui-cards.two-card .fui-card {
    width: calc(50% - 12px);
    margin-left: 6px;
    margin-right: 6px; }
  .fui-cards {
    display: flex;
    flex-wrap: no-wrap;
    margin: 48px - 6px; }
    .fui-cards .fui-card {
      margin: 0 6px 12px; }
      .fui-cards .fui-card .fui-card-extra {
        padding-left: 24px;
        padding-right: 24px; }
      .fui-cards .fui-card .fui-card-content {
        padding: 24px 24px 0; }
      .fui-cards .fui-card .fui-card-content .fui-card-title {
        -webkit-line-clamp: initial;
        font-size: 1.5rem; }
    .fui-cards.news .fui-card-content .fui-card-title {
      -webkit-line-clamp: 2; }
    .fui-cards.four-card .fui-card {
      width: calc(25% - 12px); }
    .fui-cards.three-card.is-video {
      flex-wrap: wrap; }
    .fui-cards.three-card .fui-card {
      width: calc(50% - 16px) !important; }
      .fui-cards.three-card .fui-card .fui-card-action {
        width: 100%; }
    .fui-cards.service-entry {
      margin-top: 0; }
      .fui-cards.service-entry .fui-card .fui-card-content {
        min-height: 90px;
        color: #ffffff;
        text-align: center; }
  .fui-card.service-entry .fui-card-action .fui-card-content {
    padding: 32px 0; }
  .fui-card.box .fui-card-action .fui-card-content .fui-card-title {
    font-size: 1.125rem;
    height: auto; }
  .fui-card.promotion-article.is-main {
    background-size: auto 100%; }
  .fui-card.promotion-article.is-main .fui-card-content {
    background-size: auto 100%; }
  .fui-card.promotion-article.is-main .fui-card-content .fui-card-description,
  .fui-card.promotion-article.is-main .fui-card-content .fui-card-title,
  .fui-card.promotion-article .fui-card-content .fui-card-description,
  .fui-card.promotion-article .fui-card-content .fui-card-title {
    -webkit-line-clamp: 3;
    height: auto; } }

@media screen and (min-width: 960px) {
  .fui-cards .fui-card .fui-card-content .fui-card-title {
    font-size: 1.125rem !important; }
  .fui-card .fui-card-action:hover .fui-button.is-arrow {
    color: #EF3123; }
  .fui-cards.two-card:not(.is-video) .fui-card {
    width: calc(50% - 12px);
    margin-left: 6px;
    margin-right: 6px; }
  .fui-cards.is-video .fui-card.is-video {
    width: calc(50% - 12px);
    margin-left: 6px;
    margin-right: 6px;
    background-color: #fff;
    margin-bottom: 12px; }
    .fui-cards.is-video .fui-card.is-video .fui-card-action {
      width: 100%; }
  .fui-container .fui-cards.is-video {
    overflow: visible;
    padding: 30px 15px 0 15px; }
  .fui-cards.is-video {
    height: auto;
    white-space: normal;
    white-space: initial; }
    .fui-cards.is-video .fui-card-image {
      height: 220px !important; }
  .fui-cards.three-card {
    overflow: visible;
    flex-wrap: no-wrap; }
    .fui-cards.three-card .fui-card {
      width: calc(33.33333% - 12px) !important; }
  .fui-card.is-price-card .fui-card-action .fui-card-content {
    padding-top: 60px;
    padding-bottom: 60px; }
  .fui-cards.is-video {
    flex-wrap: wrap !important; }
  .fui-card.is-simple .fui-card-content {
    padding: 60px; }
  .fui-card.is-collapse .fui-card-title img {
    height: 72px; }
  .fui-card.promotion-article {
    margin: 0 0 16px; }
    .fui-card.promotion-article .fui-card-extra {
      padding: 0 24px 16px; }
    .fui-card.promotion-article .fui-card-content {
      padding: 32px 24px 0; }
      .fui-card.promotion-article .fui-card-content .fui-card-title {
        font-size: 1.375rem;
        line-height: 33px; }
    .fui-card.promotion-article.is-main {
      margin: 0;
      width: 100%;
      height: 100%; }
      .fui-card.promotion-article.is-main .fui-card-action {
        display: flex;
        flex-direction: row;
        height: 594px; }
        .fui-card.promotion-article.is-main .fui-card-action .fui-card-caption {
          flex: 1 1;
          flex-grow: 1; }
          .fui-card.promotion-article.is-main .fui-card-action .fui-card-caption .fui-card-content {
            flex: 1 1;
            flex-grow: 1;
            padding-bottom: 0; }
        .fui-card.promotion-article.is-main .fui-card-action .fui-card-image {
          width: 390px; }
          .fui-card.promotion-article.is-main .fui-card-action .fui-card-image img {
            height: 100%;
            width: auto; }
  .promotion-article-list {
    flex-direction: column;
    margin: 0;
    flex: 1 1 auto;
    flex-grow: 1; }
    .promotion-article-list .fui-card.promotion-article {
      width: 100%;
      flex: 1 1;
      flex-grow: 1;
      margin-left: 0;
      margin-right: 0; }
      .promotion-article-list .fui-card.promotion-article:last-child {
        margin-bottom: 0; } }

@media screen and (min-width: 1280px) {
  .fui-cards.is-roaming-plan-card .last-block.d-mobile {
    display: none !important; } }

@media screen and (min-width: 1200px) {
  .fui-card.promotion-article.is-main .fui-card-extra,
  .fui-card.promotion-article.is-main .fui-card-content {
    padding-left: 56px;
    padding-right: 56px; }
  .fui-card.promotion-article.is-main .fui-card-image::after {
    width: 218px;
    height: 160px; }
  .fui-card.promotion-article.is-main .fui-card-content .fui-card-description {
    line-height: 1.75em; }
  .fui-card.promotion-article.is-main .fui-card-content .fui-meta {
    font-size: 0.875rem; }
  .fui-card.promotion-article.is-main .fui-card-content .fui-card-title {
    font-size: 2rem;
    line-height: 1.5em; } }

@media screen and (max-width: 960px) {
  .fui-cards.is-roaming-plan-card .last-block.d-mobile {
    display: block !important; }
  .fui-cards.is-roaming-plan-card .last-block.d-desktop {
    display: none !important; }
  .fui-container .fui-cards {
    padding: 0 8px 48px 8px !important; }
  .fui-cards.is-roaming-plan-card .fui-card .fui-card-extra {
    padding-right: 0; }
  .fui-cards.is-roaming-plan-card .last-block {
    padding-top: 20px;
    padding-bottom: 0px;
    float: none;
    text-align: right; }
  .fui-cards.is-roaming-plan-card .fui-progress-box .fui-progress-box .last-block {
    top: auto;
    bottom: 130px;
    right: 40px;
    float: right; } }

@media screen and (max-width: 768px) {
  .fui-cards.is-switch-card .fui-card, .fui-cards.is-apply-card .fui-card {
    padding: 30px 20px;
    margin: 0; }
    .fui-cards.is-switch-card .fui-card p, .fui-cards.is-apply-card .fui-card p {
      margin-bottom: 30px;
      white-space: normal; }
  .fui-cards.is-roaming-plan-card {
    padding-left: 0 !important;
    padding-right: 0 !important; }
    .fui-cards.is-roaming-plan-card .fui-card .fui-card-title {
      width: calc(100% - 60px); }
    .fui-cards.is-roaming-plan-card .fui-card .fui-card-tag {
      right: 20px; }
    .fui-cards.is-roaming-plan-card .fui-card .fui-card-content {
      padding: 30px 20px 10px 20px; }
    .fui-cards.is-roaming-plan-card .fui-card .fui-card-extra {
      padding-top: 0;
      padding-right: 0; }
      .fui-cards.is-roaming-plan-card .fui-card .fui-card-extra .two-buttons .fui-button {
        width: calc(50% - 4px); }
        .fui-cards.is-roaming-plan-card .fui-card .fui-card-extra .two-buttons .fui-button:last-child {
          margin-right: 0; }
    .fui-cards.is-roaming-plan-card .notice {
      margin-bottom: 0; }
    .fui-cards.is-roaming-plan-card .last-block {
      padding-top: 20px;
      padding-right: 0;
      bottom: 0;
      position: relative; }
    .fui-cards.is-roaming-plan-card .fui-progress-box {
      margin-bottom: 0; }
      .fui-cards.is-roaming-plan-card .fui-progress-box .fui-progress-box .fui-segment {
        padding: 24px 20px 60px 20px; }
        .fui-cards.is-roaming-plan-card .fui-progress-box .fui-progress-box .fui-segment .fui-card-title {
          width: 100%; }
      .fui-cards.is-roaming-plan-card .fui-progress-box .fui-progress-box .last-block {
        top: auto;
        bottom: 130px;
        right: 40px;
        position: absolute; }
      .fui-cards.is-roaming-plan-card .fui-progress-box .fui-progress-box + .fui-card-extra {
        padding-top: 0; }
        .fui-cards.is-roaming-plan-card .fui-progress-box .fui-progress-box + .fui-card-extra .two-buttons .fui-button {
          width: calc(50% - 4px); }
          .fui-cards.is-roaming-plan-card .fui-progress-box .fui-progress-box + .fui-card-extra .two-buttons .fui-button:last-child {
            margin-right: 0; }
        .fui-cards.is-roaming-plan-card .fui-progress-box .fui-progress-box + .fui-card-extra .fui-button {
          width: calc(50% - 4px); }
          .fui-cards.is-roaming-plan-card .fui-progress-box .fui-progress-box + .fui-card-extra .fui-button:last-child {
            margin-right: 0; }
  .fui-cards.is-with-icon-card .fui-card {
    display: flex;
    margin: 0 0 12px 0; } }

@media screen and (max-width: 600px) {
  .fui-cards.is-switch-card .fui-card, .fui-cards.is-apply-card .fui-card {
    padding: 30px 20px;
    margin: 0; }
    .fui-cards.is-switch-card .fui-card p, .fui-cards.is-apply-card .fui-card p {
      margin-bottom: 30px;
      white-space: normal; }
  .fui-cards.is-roaming-plan-card {
    padding-left: 0 !important;
    padding-right: 0 !important; }
    .fui-cards.is-roaming-plan-card .fui-card .fui-card-title {
      width: calc(100% - 60px); }
    .fui-cards.is-roaming-plan-card .fui-card .fui-card-tag {
      right: 20px; }
    .fui-cards.is-roaming-plan-card .fui-card .fui-card-content {
      padding: 30px 20px 10px 20px; }
    .fui-cards.is-roaming-plan-card .fui-card .fui-card-extra {
      padding-right: 0; }
      .fui-cards.is-roaming-plan-card .fui-card .fui-card-extra .two-buttons .fui-button {
        width: calc(50% - 4px); }
        .fui-cards.is-roaming-plan-card .fui-card .fui-card-extra .two-buttons .fui-button:last-child {
          margin-right: 0; }
    .fui-cards.is-roaming-plan-card .last-block {
      padding-right: 0;
      position: relative; }
    .fui-cards.is-roaming-plan-card .fui-progress-box {
      margin-bottom: 0; }
      .fui-cards.is-roaming-plan-card .fui-progress-box .fui-progress-box .fui-segment {
        padding: 24px 20px 60px 20px; }
        .fui-cards.is-roaming-plan-card .fui-progress-box .fui-progress-box .fui-segment .fui-card-title {
          width: 100%; }
      .fui-cards.is-roaming-plan-card .fui-progress-box .fui-progress-box .last-block {
        top: auto;
        bottom: 20px;
        right: 20px;
        position: absolute; }
      .fui-cards.is-roaming-plan-card .fui-progress-box .fui-progress-box + .fui-card-extra {
        padding-top: 0; }
        .fui-cards.is-roaming-plan-card .fui-progress-box .fui-progress-box + .fui-card-extra .two-buttons .fui-button {
          width: calc(50% - 4px); }
          .fui-cards.is-roaming-plan-card .fui-progress-box .fui-progress-box + .fui-card-extra .two-buttons .fui-button:last-child {
            margin-right: 0; }
        .fui-cards.is-roaming-plan-card .fui-progress-box .fui-progress-box + .fui-card-extra .fui-button {
          width: calc(50% - 4px); }
          .fui-cards.is-roaming-plan-card .fui-progress-box .fui-progress-box + .fui-card-extra .fui-button:last-child {
            margin-right: 0; }
  .fui-cards.is-with-icon-card .fui-card {
    display: flex;
    margin: 0 0 12px 0; } }

.fui-tab {
  display: flex;
  width: 100%;
  white-space: nowrap;
  position: relative; }
  .fui-tab.no-scroll {
    overflow: hidden; }
  .fui-tab .active-bar {
    position: absolute;
    height: 6px;
    left: 0;
    width: 100px;
    background: #EF3123;
    display: block;
    z-index: 1;
    bottom: 0;
    transition: all 0.3s ease; }
  .fui-tab a.fui-tab-item,
  .fui-tab .fui-tab-item {
    position: relative;
    flex: 1 1 auto;
    text-align: center;
    font-size: 0.875rem;
    font-weight: 500;
    padding: 6px 12px 12px;
    color: #202020;
    cursor: pointer;
    display: inline-block;
    transition: color 0.3s ease; }
    .fui-tab a.fui-tab-item::before,
    .fui-tab .fui-tab-item::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 6px;
      display: block;
      bottom: 0;
      left: 0;
      background: #D6D6D6; }
    .fui-tab a.fui-tab-item:hover, .fui-tab a.fui-tab-item:focus,
    .fui-tab .fui-tab-item:hover,
    .fui-tab .fui-tab-item:focus {
      color: #000;
      color: initial; }
    .fui-tab a.fui-tab-item.is-active,
    .fui-tab .fui-tab-item.is-active {
      color: #EF3123; }
    .fui-tab a.fui-tab-item small,
    .fui-tab .fui-tab-item small {
      position: relative;
      bottom: -10px; }
  .fui-tab.with-scroller {
    overflow-x: auto;
    overflow-y: hidden;
    -ms-overflow-style: none; }
    .fui-tab.with-scroller::-webkit-scrollbar {
      display: none; }
    .fui-tab.with-scroller::after {
      display: none; }
    .fui-tab.with-scroller a.fui-tab-item,
    .fui-tab.with-scroller .fui-tab-item {
      float: left;
      display: inline-block;
      padding: 8px 10px;
      line-height: 1; }
      .fui-tab.with-scroller a.fui-tab-item::before,
      .fui-tab.with-scroller .fui-tab-item::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 6px;
        display: block;
        bottom: 0;
        left: 0;
        background: #D6D6D6; }
  .fui-tab.with-title small {
    bottom: 0; }
  .fui-tab.with-icon, .fui-tab.with-title {
    border-bottom: 0; }
    .fui-tab.with-icon::after, .fui-tab.with-title::after {
      display: none; }
    .fui-tab.with-icon .active-bar, .fui-tab.with-title .active-bar {
      height: 4px; }
    .fui-tab.with-icon a.fui-tab-item,
    .fui-tab.with-icon .fui-tab-item, .fui-tab.with-title a.fui-tab-item,
    .fui-tab.with-title .fui-tab-item {
      min-height: 80px;
      background-image: linear-gradient(180deg, #FFFFFF 0%, #F5F5F5 100%); }
      .fui-tab.with-icon a.fui-tab-item .icon,
      .fui-tab.with-icon .fui-tab-item .icon, .fui-tab.with-title a.fui-tab-item .icon,
      .fui-tab.with-title .fui-tab-item .icon {
        text-align: center; }
        .fui-tab.with-icon a.fui-tab-item .icon *,
        .fui-tab.with-icon .fui-tab-item .icon *, .fui-tab.with-title a.fui-tab-item .icon *,
        .fui-tab.with-title .fui-tab-item .icon * {
          margin: 0 auto;
          line-height: 0; }
        .fui-tab.with-icon a.fui-tab-item .icon .default,
        .fui-tab.with-icon .fui-tab-item .icon .default, .fui-tab.with-title a.fui-tab-item .icon .default,
        .fui-tab.with-title .fui-tab-item .icon .default {
          display: block; }
        .fui-tab.with-icon a.fui-tab-item .icon .focus,
        .fui-tab.with-icon .fui-tab-item .icon .focus, .fui-tab.with-title a.fui-tab-item .icon .focus,
        .fui-tab.with-title .fui-tab-item .icon .focus {
          display: none; }
      .fui-tab.with-icon a.fui-tab-item::before,
      .fui-tab.with-icon .fui-tab-item::before, .fui-tab.with-title a.fui-tab-item::before,
      .fui-tab.with-title .fui-tab-item::before {
        display: none; }
      .fui-tab.with-icon a.fui-tab-item:hover .icon .default, .fui-tab.with-icon a.fui-tab-item:focus .icon .default, .fui-tab.with-icon a.fui-tab-item.is-active .icon .default,
      .fui-tab.with-icon .fui-tab-item:hover .icon .default,
      .fui-tab.with-icon .fui-tab-item:focus .icon .default,
      .fui-tab.with-icon .fui-tab-item.is-active .icon .default, .fui-tab.with-title a.fui-tab-item:hover .icon .default, .fui-tab.with-title a.fui-tab-item:focus .icon .default, .fui-tab.with-title a.fui-tab-item.is-active .icon .default,
      .fui-tab.with-title .fui-tab-item:hover .icon .default,
      .fui-tab.with-title .fui-tab-item:focus .icon .default,
      .fui-tab.with-title .fui-tab-item.is-active .icon .default {
        display: none; }
      .fui-tab.with-icon a.fui-tab-item:hover .icon .focus, .fui-tab.with-icon a.fui-tab-item:focus .icon .focus, .fui-tab.with-icon a.fui-tab-item.is-active .icon .focus,
      .fui-tab.with-icon .fui-tab-item:hover .icon .focus,
      .fui-tab.with-icon .fui-tab-item:focus .icon .focus,
      .fui-tab.with-icon .fui-tab-item.is-active .icon .focus, .fui-tab.with-title a.fui-tab-item:hover .icon .focus, .fui-tab.with-title a.fui-tab-item:focus .icon .focus, .fui-tab.with-title a.fui-tab-item.is-active .icon .focus,
      .fui-tab.with-title .fui-tab-item:hover .icon .focus,
      .fui-tab.with-title .fui-tab-item:focus .icon .focus,
      .fui-tab.with-title .fui-tab-item.is-active .icon .focus {
        display: block; }
      .fui-tab.with-icon a.fui-tab-item.is-active,
      .fui-tab.with-icon .fui-tab-item.is-active, .fui-tab.with-title a.fui-tab-item.is-active,
      .fui-tab.with-title .fui-tab-item.is-active {
        background: #FFF; }
  .fui-tab#addValue small {
    font-size: 22px;
    margin-bottom: 10px;
    display: block; }
  .fui-tab#addValue .text {
    color: #8A8A8A; }
  .fui-tab#addValue .is-active .text {
    color: #ff9173; }
  .fui-tab#addValue .fui-tab-item:hover .text {
    color: #ff9173; }

.fui-nav-tab,
.fui-nav-tab > div,
.fui-nav-anchor,
.fui-nav-anchor > div {
  position: relative;
  top: 0;
  display: flex;
  width: 100%;
  height: 45px;
  background: #FFFFFF;
  z-index: 9;
  align-content: center;
  align-items: center; }
  .fui-nav-tab.is-fixed + section:not(.fui-breadcrumb-container),
  .fui-nav-tab.is-fixed + section.fui-breadcrumb-container + section:not(.fcoin-dashboard),
  .fui-nav-tab > div.is-fixed + section:not(.fui-breadcrumb-container),
  .fui-nav-tab > div.is-fixed + section.fui-breadcrumb-container + section:not(.fcoin-dashboard),
  .fui-nav-anchor.is-fixed + section:not(.fui-breadcrumb-container),
  .fui-nav-anchor.is-fixed + section.fui-breadcrumb-container + section:not(.fcoin-dashboard),
  .fui-nav-anchor > div.is-fixed + section:not(.fui-breadcrumb-container),
  .fui-nav-anchor > div.is-fixed + section.fui-breadcrumb-container + section:not(.fcoin-dashboard) {
    padding-top: 45px;
    margin-bottom: 0; }
  .fui-nav-tab.is-fixed + section.fui-breadcrumb-container + section:not(.fcoin-dashboard),
  .fui-nav-tab > div.is-fixed + section.fui-breadcrumb-container + section:not(.fcoin-dashboard),
  .fui-nav-anchor.is-fixed + section.fui-breadcrumb-container + section:not(.fcoin-dashboard),
  .fui-nav-anchor > div.is-fixed + section.fui-breadcrumb-container + section:not(.fcoin-dashboard) {
    margin-top: 145px; }
  .fui-nav-tab.is-fixed + section:not(.fui-breadcrumb-container).login-recommend,
  .fui-nav-tab.is-fixed + section.fui-breadcrumb-container + section.login-recommend,
  .fui-nav-tab > div.is-fixed + section:not(.fui-breadcrumb-container).login-recommend,
  .fui-nav-tab > div.is-fixed + section.fui-breadcrumb-container + section.login-recommend,
  .fui-nav-anchor.is-fixed + section:not(.fui-breadcrumb-container).login-recommend,
  .fui-nav-anchor.is-fixed + section.fui-breadcrumb-container + section.login-recommend,
  .fui-nav-anchor > div.is-fixed + section:not(.fui-breadcrumb-container).login-recommend,
  .fui-nav-anchor > div.is-fixed + section.fui-breadcrumb-container + section.login-recommend {
    padding-top: 120px;
    margin-top: 0; }
  .fui-nav-tab .fui-container,
  .fui-nav-tab > div .fui-container,
  .fui-nav-anchor .fui-container,
  .fui-nav-anchor > div .fui-container {
    width: 100%; }
    @media screen and (min-width: 960px) {
      .fui-nav-tab .fui-container,
      .fui-nav-tab > div .fui-container,
      .fui-nav-anchor .fui-container,
      .fui-nav-anchor > div .fui-container {
        width: 100%;
        max-width: 1440px; } }
  .fui-nav-tab .page-anchor .page-anchor-item,
  .fui-nav-tab > div .page-anchor .page-anchor-item,
  .fui-nav-anchor .page-anchor .page-anchor-item,
  .fui-nav-anchor > div .page-anchor .page-anchor-item {
    color: #202020;
    display: none; }
  .fui-nav-tab.is-fixed,
  .fui-nav-tab > div.is-fixed,
  .fui-nav-anchor.is-fixed,
  .fui-nav-anchor > div.is-fixed {
    z-index: 59; }
  .fui-nav-tab.is-fixed > div,
  .fui-nav-tab > div.is-fixed > div,
  .fui-nav-anchor.is-fixed > div,
  .fui-nav-anchor > div.is-fixed > div {
    position: fixed;
    z-index: 59;
    top: 0; }
    .fui-nav-tab.is-fixed > div .fui-button.is-primary + .fui-button.is-secondary.is-small,
    .fui-nav-tab > div.is-fixed > div .fui-button.is-primary + .fui-button.is-secondary.is-small,
    .fui-nav-anchor.is-fixed > div .fui-button.is-primary + .fui-button.is-secondary.is-small,
    .fui-nav-anchor > div.is-fixed > div .fui-button.is-primary + .fui-button.is-secondary.is-small {
      background: #FFFFFF;
      color: #202020;
      border-color: #202020;
      transition: none; }
      .fui-nav-tab.is-fixed > div .fui-button.is-primary + .fui-button.is-secondary.is-small .text,
      .fui-nav-tab > div.is-fixed > div .fui-button.is-primary + .fui-button.is-secondary.is-small .text,
      .fui-nav-anchor.is-fixed > div .fui-button.is-primary + .fui-button.is-secondary.is-small .text,
      .fui-nav-anchor > div.is-fixed > div .fui-button.is-primary + .fui-button.is-secondary.is-small .text {
        transition: none; }
      .fui-nav-tab.is-fixed > div .fui-button.is-primary + .fui-button.is-secondary.is-small:hover,
      .fui-nav-tab > div.is-fixed > div .fui-button.is-primary + .fui-button.is-secondary.is-small:hover,
      .fui-nav-anchor.is-fixed > div .fui-button.is-primary + .fui-button.is-secondary.is-small:hover,
      .fui-nav-anchor > div.is-fixed > div .fui-button.is-primary + .fui-button.is-secondary.is-small:hover {
        border-color: #EF3123; }
        .fui-nav-tab.is-fixed > div .fui-button.is-primary + .fui-button.is-secondary.is-small:hover .text,
        .fui-nav-tab > div.is-fixed > div .fui-button.is-primary + .fui-button.is-secondary.is-small:hover .text,
        .fui-nav-anchor.is-fixed > div .fui-button.is-primary + .fui-button.is-secondary.is-small:hover .text,
        .fui-nav-anchor > div.is-fixed > div .fui-button.is-primary + .fui-button.is-secondary.is-small:hover .text {
          color: #EF3123; }
    .fui-nav-tab.is-fixed > div .fui-button.is-secondary.is-small,
    .fui-nav-tab > div.is-fixed > div .fui-button.is-secondary.is-small,
    .fui-nav-anchor.is-fixed > div .fui-button.is-secondary.is-small,
    .fui-nav-anchor > div.is-fixed > div .fui-button.is-secondary.is-small {
      display: block !important;
      opacity: 1;
      color: #FFFFFF;
      background: #EF3123;
      border-color: #EF3123; }
      .fui-nav-tab.is-fixed > div .fui-button.is-secondary.is-small:hover .text,
      .fui-nav-tab > div.is-fixed > div .fui-button.is-secondary.is-small:hover .text,
      .fui-nav-anchor.is-fixed > div .fui-button.is-secondary.is-small:hover .text,
      .fui-nav-anchor > div.is-fixed > div .fui-button.is-secondary.is-small:hover .text {
        color: #FFFFFF; }
    .fui-nav-tab.is-fixed > div + .fui-tab-pane,
    .fui-nav-tab > div.is-fixed > div + .fui-tab-pane,
    .fui-nav-anchor.is-fixed > div + .fui-tab-pane,
    .fui-nav-anchor > div.is-fixed > div + .fui-tab-pane {
      margin-top: 45px; }
  .fui-nav-tab .fui-container,
  .fui-nav-tab > div .fui-container,
  .fui-nav-anchor .fui-container,
  .fui-nav-anchor > div .fui-container {
    padding: 0 15px;
    display: flex;
    height: 45px;
    align-content: center;
    align-items: center; }
  .fui-nav-tab .content,
  .fui-nav-tab > div .content,
  .fui-nav-anchor .content,
  .fui-nav-anchor > div .content {
    flex: 1 1 auto;
    margin-right: 80px; }
  .fui-nav-tab .extra,
  .fui-nav-tab > div .extra,
  .fui-nav-anchor .extra,
  .fui-nav-anchor > div .extra {
    position: absolute;
    display: flex;
    right: 15px;
    top: 0;
    bottom: 0;
    height: 25px;
    margin: auto; }
    .fui-nav-tab .extra .fui-button.is-small.is-primary,
    .fui-nav-tab > div .extra .fui-button.is-small.is-primary,
    .fui-nav-anchor .extra .fui-button.is-small.is-primary,
    .fui-nav-anchor > div .extra .fui-button.is-small.is-primary {
      border: 2px solid #D3060F; }
    .fui-nav-tab .extra .fui-button.is-small.is-secondary, .fui-nav-tab .extra .fui-button.is-small.is-primary,
    .fui-nav-tab > div .extra .fui-button.is-small.is-secondary,
    .fui-nav-tab > div .extra .fui-button.is-small.is-primary,
    .fui-nav-anchor .extra .fui-button.is-small.is-secondary,
    .fui-nav-anchor .extra .fui-button.is-small.is-primary,
    .fui-nav-anchor > div .extra .fui-button.is-small.is-secondary,
    .fui-nav-anchor > div .extra .fui-button.is-small.is-primary {
      display: block;
      opacity: 1;
      font-size: 12px;
      height: 24px;
      line-height: 0;
      align-self: center;
      vertical-align: middle;
      margin: 0;
      transition: all 0.3s 0.1s ease; }
      .fui-nav-tab .extra .fui-button.is-small.is-secondary .text, .fui-nav-tab .extra .fui-button.is-small.is-primary .text,
      .fui-nav-tab > div .extra .fui-button.is-small.is-secondary .text,
      .fui-nav-tab > div .extra .fui-button.is-small.is-primary .text,
      .fui-nav-anchor .extra .fui-button.is-small.is-secondary .text,
      .fui-nav-anchor .extra .fui-button.is-small.is-primary .text,
      .fui-nav-anchor > div .extra .fui-button.is-small.is-secondary .text,
      .fui-nav-anchor > div .extra .fui-button.is-small.is-primary .text {
        font-size: 12px;
        height: 20px;
        line-height: 20px; }
  .fui-nav-tab .subtitle, .fui-nav-tab .fui-card.box .fui-card-content .fui-card-title, .fui-card.box .fui-card-content .fui-nav-tab .fui-card-title,
  .fui-nav-tab > div .subtitle,
  .fui-nav-tab > div .fui-card.box .fui-card-content .fui-card-title,
  .fui-card.box .fui-card-content .fui-nav-tab > div .fui-card-title,
  .fui-nav-anchor .subtitle,
  .fui-nav-anchor .fui-card.box .fui-card-content .fui-card-title,
  .fui-card.box .fui-card-content .fui-nav-anchor .fui-card-title,
  .fui-nav-anchor > div .subtitle,
  .fui-nav-anchor > div .fui-card.box .fui-card-content .fui-card-title,
  .fui-card.box .fui-card-content .fui-nav-anchor > div .fui-card-title {
    font-size: 1.125rem;
    line-height: 27px;
    font-weight: 600; }
    .fui-nav-tab .subtitle img, .fui-nav-tab .fui-card.box .fui-card-content .fui-card-title img, .fui-card.box .fui-card-content .fui-nav-tab .fui-card-title img,
    .fui-nav-tab > div .subtitle img,
    .fui-nav-tab > div .fui-card.box .fui-card-content .fui-card-title img,
    .fui-card.box .fui-card-content .fui-nav-tab > div .fui-card-title img,
    .fui-nav-anchor .subtitle img,
    .fui-nav-anchor .fui-card.box .fui-card-content .fui-card-title img,
    .fui-card.box .fui-card-content .fui-nav-anchor .fui-card-title img,
    .fui-nav-anchor > div .subtitle img,
    .fui-nav-anchor > div .fui-card.box .fui-card-content .fui-card-title img,
    .fui-card.box .fui-card-content .fui-nav-anchor > div .fui-card-title img {
      vertical-align: middle; }
  .fui-nav-tab .fui-tab-container,
  .fui-nav-tab > div .fui-tab-container,
  .fui-nav-anchor .fui-tab-container,
  .fui-nav-anchor > div .fui-tab-container {
    position: relative; }
  .fui-nav-tab.is-fixed .extra .fui-button.is-small.is-secondary,
  .fui-nav-tab > div.is-fixed .extra .fui-button.is-small.is-secondary,
  .fui-nav-anchor.is-fixed .extra .fui-button.is-small.is-secondary,
  .fui-nav-anchor > div.is-fixed .extra .fui-button.is-small.is-secondary {
    display: block;
    opacity: 1; }

.fui-nav-tab {
  padding: 0;
  height: 60px; }
  .fui-nav-tab .fui-container {
    height: 60px; }
  .fui-nav-tab .subtitle, .fui-nav-tab .fui-card.box .fui-card-content .fui-card-title, .fui-card.box .fui-card-content .fui-nav-tab .fui-card-title {
    font-size: 0.875rem;
    font-weight: 400; }
  .fui-nav-tab .extra .fui-button.is-small.is-secondary {
    display: block;
    opacity: 1; }
  .fui-nav-tab .fui-tab-container.mobile-dropdown button.display {
    display: flex;
    font-weight: 500;
    font-size: 1rem; }
    .fui-nav-tab .fui-tab-container.mobile-dropdown button.display > span {
      text-align: left;
      flex: 1 1 auto; }
    .fui-nav-tab .fui-tab-container.mobile-dropdown button.display i {
      font-size: 24px; }
  .fui-nav-tab .fui-tab-container.mobile-dropdown .fui-tab-item {
    padding-left: 15px;
    padding-right: 15px; }
  .fui-nav-tab.is-content-1 .content {
    margin: 0; }
  .fui-nav-tab.is-content-1 .extra,
  .fui-nav-tab.is-content-1 .subtitle,
  .fui-nav-tab.is-content-1 .fui-card.box .fui-card-content .fui-card-title,
  .fui-card.box .fui-card-content .fui-nav-tab.is-content-1 .fui-card-title {
    display: none; }
  .fui-nav-tab.is-content-1 .fui-tab-container.mobile-dropdown button.display {
    width: 100%; }
  .fui-nav-tab.is-box-tab {
    width: 100%;
    max-width: 1189px;
    margin: 0 auto;
    padding: 0; }
    .fui-nav-tab.is-box-tab .fui-tab {
      width: 100%;
      box-shadow: 0 24px 48px 0 rgba(75, 88, 105, 0.12);
      background-image: linear-gradient(to bottom, #FFFFFF, #f5f5f5);
      margin-bottom: 0; }
      .fui-nav-tab.is-box-tab .fui-tab .active-bar {
        display: none; }
      .fui-nav-tab.is-box-tab .fui-tab .fui-tab-item {
        width: calc(100% / 3);
        min-height: 75px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0; }
        .fui-nav-tab.is-box-tab .fui-tab .fui-tab-item::before {
          display: none; }
        .fui-nav-tab.is-box-tab .fui-tab .fui-tab-item small {
          color: #8A8A8A;
          padding-top: 0;
          bottom: 0;
          transition: all 0.3s ease; }
        .fui-nav-tab.is-box-tab .fui-tab .fui-tab-item .text {
          font-size: 1.125rem;
          margin-top: 4px;
          line-height: 1.2;
          padding: 0 4px;
          white-space: normal; }
      .fui-nav-tab.is-box-tab .fui-tab .fui-tab-item:hover,
      .fui-nav-tab.is-box-tab .fui-tab .fui-tab-item.is-active {
        z-index: 1;
        box-shadow: 0 24px 48px 0 rgba(75, 88, 105, 0.12); }
        .fui-nav-tab.is-box-tab .fui-tab .fui-tab-item:hover small,
        .fui-nav-tab.is-box-tab .fui-tab .fui-tab-item.is-active small {
          color: #EF3123; }
  .fui-nav-tab.is-fixed .extra,
  .fui-nav-tab.is-fixed .subtitle,
  .fui-nav-tab.is-fixed .fui-card.box .fui-card-content .fui-card-title,
  .fui-card.box .fui-card-content .fui-nav-tab.is-fixed .fui-card-title {
    display: block; }
  .fui-nav-tab.is-fixed .content {
    margin-right: 100px; }
  .fui-nav-tab.is-fixed .fui-tab-container.mobile-dropdown button.display {
    width: auto;
    font-size: 1rem; }

.fui-nav-anchor {
  padding: 12px 0; }
  .fui-nav-anchor + .fui-banner.is-product {
    margin-top: 0; }
  .fui-nav-anchor .extra {
    top: 50%;
    margin-top: -12px; }

.fui-section-tab {
  background: #FFF;
  box-shadow: 0 24px 48px 0 rgba(75, 88, 105, 0.12); }
  .fui-section-tab .fui-tab .active-bar {
    display: none; }
  .fui-section-tab .fui-tab .fui-tab-item {
    background: #E8ECF0;
    border-bottom: 0;
    height: 64px;
    display: flex;
    color: #202020;
    align-content: center;
    align-items: center;
    justify-content: center;
    padding: 0 3px;
    box-shadow: 2px 0 0 0 #E8ECF0; }
    .fui-section-tab .fui-tab .fui-tab-item::before {
      display: none; }
    .fui-section-tab .fui-tab .fui-tab-item:last-child {
      box-shadow: none; }
    .fui-section-tab .fui-tab .fui-tab-item .text {
      white-space: normal;
      font-size: 0.875rem;
      line-height: 1.5rem;
      vertical-align: middle; }
    .fui-section-tab .fui-tab .fui-tab-item.is-active {
      color: #202020;
      background: #FFFFFF; }
  .fui-section-tab .fui-tab-body .fui-tab-pane {
    padding: 30px; }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.fui-tab-pane {
  display: none;
  opacity: 0;
  width: 100%; }
  .fui-tab-pane .is-open .collapse-body {
    margin-bottom: 0 !important; }
  .fui-tab-pane.is-active {
    position: relative;
    display: block;
    opacity: 0;
    z-index: 12;
    animation: fadeIn 1s ease forwards; }
    .fui-tab-pane.is-active .fui-dropdown.for-banner {
      width: 72%; }
      .fui-tab-pane.is-active .fui-dropdown.for-banner .fui-dropdown-item {
        width: 100%;
        max-width: 100%;
        font-size: 30px; }
        .fui-tab-pane.is-active .fui-dropdown.for-banner .fui-dropdown-item span {
          flex: 1 1; }

.fui-tab-container .fui-tab-body {
  width: 100%; }

.fui-tab-container .fui-tab-pane {
  padding-top: 16px; }

.fui-tab-container.mobile-dropdown {
  box-shadow: none !important;
  position: static;
  position: initial; }
  .fui-tab-container.mobile-dropdown button.display {
    font-size: 14px;
    padding: 0;
    background: none;
    border: 0;
    font-weight: 500; }
    .fui-tab-container.mobile-dropdown button.display span {
      margin-right: 8px; }
  .fui-tab-container.mobile-dropdown .fui-tab {
    flex-direction: column; }
    .fui-tab-container.mobile-dropdown .fui-tab .fui-tab-item,
    .fui-tab-container.mobile-dropdown .fui-tab .fui-tab-item.is-active {
      border-bottom: 1px solid #E8ECF0; }
      .fui-tab-container.mobile-dropdown .fui-tab .fui-tab-item::before,
      .fui-tab-container.mobile-dropdown .fui-tab .fui-tab-item.is-active::before {
        display: none; }
  .fui-tab-container.mobile-dropdown .fui-menu {
    position: absolute;
    top: 100%;
    left: 0px;
    width: 100%;
    background: #FFF;
    overflow: hidden;
    transition: height 0.3s ease; }
    .fui-tab-container.mobile-dropdown .fui-menu .fui-tab .active-bar {
      display: none; }
    .fui-tab-container.mobile-dropdown .fui-menu .fui-tab .fui-tab-item {
      display: flex;
      padding-left: 15px;
      padding-right: 15px;
      text-align: left;
      min-height: 72px;
      align-content: center;
      align-items: center; }
      .fui-tab-container.mobile-dropdown .fui-menu .fui-tab .fui-tab-item.is-active {
        color: #202020; }
        .fui-tab-container.mobile-dropdown .fui-menu .fui-tab .fui-tab-item.is-active::after {
          content: "";
          position: absolute;
          right: 15px;
          top: -5px;
          bottom: 0;
          margin: auto;
          width: 12px;
          height: 7px;
          border-left: solid 3px #D3060F;
          border-bottom: solid 3px #D3060F;
          transform: rotate(-45deg);
          background-color: none !important; }

@media screen and (min-width: 768px) {
  .fui-tabl a.fui-tab-item,
  .fui-tabl .fui-tab-item {
    font-size: 1rem;
    padding-top: 12px; }
  .fui-tab.with-title small {
    display: block;
    margin-bottom: 3px;
    color: #8A8A8A; }
  .fui-tab.with-title .title {
    font-size: 18px; }
  .fui-tab.with-title .fui-tab-item {
    min-height: 75px; }
  .fui-tab.with-title .is-active small {
    color: #EF3123; }
  .fui-tab-container .fui-tab-body .fui-dropdown.for-banner .fui-dropdown-item {
    width: 100%; } }

@media screen and (min-width: 960px) {
  .fui-tab {
    position: relative;
    display: flex; }
    .fui-tab a.fui-tab-item:hover,
    .fui-tab .fui-tab-item:hover {
      color: #EF3123; }
      .fui-tab a.fui-tab-item:hover small,
      .fui-tab .fui-tab-item:hover small {
        color: #EF3123; }
    .fui-tab.with-icon .active-bar {
      display: none; }
  .fui-section-tab .fui-tab .fui-tab-item {
    height: 60px; }
    .fui-section-tab .fui-tab .fui-tab-item .text {
      font-size: 1rem; }
  .fui-section-tab .fui-tab-body .fui-tab-pane {
    padding: 60px;
    font-size: 1rem;
    line-height: 28px; }
  .fui-tab-container.mobile-dropdown button.display {
    display: none; }
  .fui-tab-container.mobile-dropdown .fui-menu {
    position: relative;
    top: 0;
    height: 45px !important;
    overflow: visible;
    display: block;
    width: 100%;
    text-align: center; }
    .fui-tab-container.mobile-dropdown .fui-menu .fui-tab {
      width: auto;
      display: inline-block;
      text-align: center; }
      .fui-tab-container.mobile-dropdown .fui-menu .fui-tab .active-bar {
        display: block;
        bottom: 0px; }
      .fui-tab-container.mobile-dropdown .fui-menu .fui-tab .fui-tab-item {
        display: inline-block;
        min-height: 45px;
        border-bottom: 0;
        font-size: 0.875rem;
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 32px;
        padding-right: 32px; }
        .fui-tab-container.mobile-dropdown .fui-menu .fui-tab .fui-tab-item .text {
          line-height: 45px; }
        .fui-tab-container.mobile-dropdown .fui-menu .fui-tab .fui-tab-item.is-active {
          color: #EF3123; }
          .fui-tab-container.mobile-dropdown .fui-menu .fui-tab .fui-tab-item.is-active::after {
            display: none; }
  .fui-nav-anchor {
    position: relative;
    padding: 0;
    height: 45px;
    background: #FFFFFF;
    box-shadow: 0 24px 48px 0 rgba(75, 88, 105, 0.12); }
    .fui-nav-anchor .fui-container {
      height: 45px; }
    .fui-nav-anchor .extra {
      display: block; }
    .fui-nav-anchor .content .subtitle, .fui-nav-anchor .content .fui-card.box .fui-card-content .fui-card-title, .fui-card.box .fui-card-content .fui-nav-anchor .content .fui-card-title {
      position: relative;
      display: inline-flex;
      z-index: 3; }
    .fui-nav-anchor .content .page-anchor {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 45px;
      text-align: center; }
      .fui-nav-anchor .content .page-anchor .page-anchor-item,
      .fui-nav-anchor .content .page-anchor a.page-anchor-item:link,
      .fui-nav-anchor .content .page-anchor a.page-anchor-item:visited {
        cursor: pointer;
        display: inline-block;
        color: #202020;
        height: 45px;
        line-height: 45px;
        padding: 0 32px;
        font-size: 14px; }
        .fui-nav-anchor .content .page-anchor .page-anchor-item.active, .fui-nav-anchor .content .page-anchor .page-anchor-item.is-active,
        .fui-nav-anchor .content .page-anchor a.page-anchor-item:link.active,
        .fui-nav-anchor .content .page-anchor a.page-anchor-item:link.is-active,
        .fui-nav-anchor .content .page-anchor a.page-anchor-item:visited.active,
        .fui-nav-anchor .content .page-anchor a.page-anchor-item:visited.is-active {
          color: #EF3123; }
    .fui-nav-anchor .extra .fui-button.is-small {
      height: 30px;
      line-height: 22px;
      min-width: 0;
      border-color: #202020; }
  .fui-nav-tab {
    position: relative;
    top: 0px;
    height: 45px;
    background: #FFFFFF;
    box-shadow: 0 24px 48px 0 rgba(75, 88, 105, 0.12); }
    .fui-nav-tab.is-box-tab {
      padding: 0;
      height: auto;
      background: none;
      margin-top: -37.5px; }
      .fui-nav-tab.is-box-tab .fui-tab .fui-tab-item small {
        font-weight: 400; }
      .fui-nav-tab.is-box-tab .fui-tab .fui-tab-item .text {
        font-weight: 500;
        font-size: 1.25rem; }
    .fui-nav-tab .fui-tab-container.mobile-dropdown {
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      display: inline-block;
      width: auto; }
      .fui-nav-tab .fui-tab-container.mobile-dropdown button.display {
        display: none; }
    .fui-nav-tab .subtitle, .fui-nav-tab .fui-card.box .fui-card-content .fui-card-title, .fui-card.box .fui-card-content .fui-nav-tab .fui-card-title {
      position: absolute;
      left: 0;
      z-index: 9;
      font-size: 20px;
      height: 25px;
      font-weight: 600;
      top: 0;
      bottom: 0;
      margin: auto; }
    .fui-nav-tab .fui-container {
      height: 45px; }
    .fui-nav-tab .content {
      position: relative;
      height: 45px;
      flex: 1 0 auto;
      margin-right: 0 !important; }
    .fui-nav-tab .extra .fui-button.is-small {
      height: 30px;
      line-height: 26px;
      min-width: 0;
      border-color: #202020; }
    .fui-nav-tab.is-content-1 .extra,
    .fui-nav-tab.is-content-1 .subtitle,
    .fui-nav-tab.is-content-1 .fui-card.box .fui-card-content .fui-card-title,
    .fui-card.box .fui-card-content .fui-nav-tab.is-content-1 .fui-card-title {
      display: none !important; }
    .fui-nav-tab.is-content-1.is-fixed .extra,
    .fui-nav-tab.is-content-1.is-fixed .subtitle,
    .fui-nav-tab.is-content-1.is-fixed .fui-card.box .fui-card-content .fui-card-title,
    .fui-card.box .fui-card-content .fui-nav-tab.is-content-1.is-fixed .fui-card-title {
      display: block !important; }
  .fui-tab-pane.is-active .fui-dropdown.for-banner .fui-dropdown-item {
    font-size: 36px; }
  .is-fixed + section:not(.fui-breadcrumb-container).login-recommend,
  .is-fixed + section.fui-breadcrumb-container + section.login-recommend {
    padding-top: 120px;
    margin-top: 145px !important; }
  .is-fixed + section:not(.fui-breadcrumb-container).login-recommend,
  .is-fixed + section.fui-breadcrumb-container + section.login-recommend {
    padding-top: 120px;
    margin-top: 145px !important; }
  .is-fixed + section:not(.fui-breadcrumb-container).login-recommend,
  .is-fixed + section.fui-breadcrumb-container + section.login-recommend {
    padding-top: 120px;
    margin-top: 145px !important; } }

@media screen and (max-width: 960px) {
  .fui-menu .fui-tab {
    width: 100%; }
  .fui-tab {
    overflow: scroll; }
  .fui-tab#addValue small {
    font-size: 18px; }
  .fui-tab#addValue .text {
    font-size: 12px; }
  .fui-tab#addValue + .fui-tab-pane {
    margin-bottom: 72px; }
    .fui-tab#addValue + .fui-tab-pane .slick-slider {
      margin-top: 24px; } }

@media screen and (max-width: 768px) {
  .fui-tab a.fui-tab-item,
  .fui-tab .fui-tab-item {
    padding: 6px 6px 12px; }
    .fui-tab a.fui-tab-item small,
    .fui-tab .fui-tab-item small {
      display: block;
      padding-top: 4px;
      margin-bottom: 4px;
      color: #8A8A8A; }
    .fui-tab a.fui-tab-item .title,
    .fui-tab .fui-tab-item .title {
      font-size: 16px; }
    .fui-tab a.fui-tab-item.is-active small,
    .fui-tab .fui-tab-item.is-active small {
      color: #EF3123 !important; }
  .fui-tab.with-scroller {
    width: auto;
    width: initial;
    margin-right: -15px; }
  .tab-content {
    display: flex;
    flex-direction: column; }
    .tab-content .action .fui-button {
      max-width: 180px; }
  .fui-banner .fui-tab-container .fui-tab-body .fui-tab-pane .tab-content h3.tab-title {
    line-height: 44px; }
  .fui-card .fui-card-content .fui-card-title .fui-card .fui-card-content .fui-card-title {
    margin: 0; } }

.fui-tip {
  display: block;
  padding: 24px;
  background: #FFFFFF;
  border: 1px solid #E8ECF0;
  margin: 12px 0;
  box-shadow: 0 12px 18px 0 rgba(75, 88, 105, 0.06);
  color: #202020;
  letter-spacing: 0.2px;
  line-height: 28px; }
  .fui-tip + .fui-tip {
    margin-top: 6px; }
  .fui-tip * {
    font-size: 0.875rem; }
  .fui-tip .fui-tip-title {
    font-weight: 500;
    padding-left: 40px;
    padding-right: 40px; }
    .fui-tip .fui-tip-title::before {
      content: "";
      float: left;
      display: block;
      margin-left: -40px;
      margin-top: 4px;
      width: 28px;
      height: 24px;
      background: url(/DigService/resources/common/images/tip-icon-blue.png) no-repeat;
      background-size: contain; }
  .fui-tip .fui-tip-body {
    display: block; }
    .fui-tip .fui-tip-body * {
      font-weight: 400;
      padding-top: 16px; }
  .fui-tip.is-collapse .fui-tip-title::after {
    content: "";
    float: right;
    font-size: 20px;
    margin-right: -40px;
    font-family: "icomoon" !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }
  .fui-tip.is-collapse.is-open .fui-tip-title::after {
    content: ""; }
  .fui-tip.is-collapse .fui-tip-body {
    transition: all 0.3s ease;
    overflow: hidden; }

.fui-banner.is-estore,
.fui-banner.is-exclusive {
  height: 500px;
  padding: 0;
  min-height: 0;
  margin-top: 0 !important; }
  .fui-banner.is-estore .fui-arrow,
  .fui-banner.is-exclusive .fui-arrow {
    position: absolute;
    bottom: 30px;
    left: 15px; }
    .fui-banner.is-estore .fui-arrow i,
    .fui-banner.is-exclusive .fui-arrow i {
      font-size: 24px; }
  .fui-banner.is-estore .bg,
  .fui-banner.is-exclusive .bg {
    position: relative;
    left: 0;
    top: 0;
    width: 100%;
    height: 500px; }
    .fui-banner.is-estore .bg img,
    .fui-banner.is-exclusive .bg img {
      position: absolute;
      width: 100%;
      height: auto;
      margin: auto;
      left: 0;
      top: auto;
      bottom: 0; }
  .fui-banner.is-estore .slick-initialized .slick-slide,
  .fui-banner.is-exclusive .slick-initialized .slick-slide {
    position: relative;
    height: 500px;
    overflow: hidden; }
  .fui-banner.is-estore .slick-initialized ul.slick-dots,
  .fui-banner.is-exclusive .slick-initialized ul.slick-dots {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    padding: 0;
    height: 4px;
    bottom: 18px;
    text-align: center;
    list-style: none;
    vertical-align: bottom; }
    .fui-banner.is-estore .slick-initialized ul.slick-dots li,
    .fui-banner.is-exclusive .slick-initialized ul.slick-dots li {
      display: inline-block;
      width: 36px;
      height: 4px;
      margin: 0 3px;
      background-color: #202020;
      opacity: 0.25; }
      .fui-banner.is-estore .slick-initialized ul.slick-dots li button,
      .fui-banner.is-exclusive .slick-initialized ul.slick-dots li button {
        opacity: 0; }
      .fui-banner.is-estore .slick-initialized ul.slick-dots li.slick-active,
      .fui-banner.is-exclusive .slick-initialized ul.slick-dots li.slick-active {
        opacity: 1; }
  .fui-banner.is-estore .caption,
  .fui-banner.is-exclusive .caption {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding-top: 24px; }
    .fui-banner.is-estore .caption .fui-container,
    .fui-banner.is-exclusive .caption .fui-container {
      padding: 0 15px; }
    .fui-banner.is-estore .caption .tag,
    .fui-banner.is-exclusive .caption .tag {
      position: relative;
      color: #EF3123;
      display: inline-block;
      font-size: 12px;
      height: 31px;
      line-height: 31px;
      margin-bottom: 12px;
      font-weight: 500; }
      .fui-banner.is-estore .caption .tag .tag-bg,
      .fui-banner.is-exclusive .caption .tag .tag-bg {
        position: absolute;
        margin: 0 auto;
        width: calc(100% - 36px);
        left: 0;
        right: 0;
        top: 0;
        display: block;
        height: 31px;
        border-top: 3px solid #EF3123;
        border-bottom: 3px solid #EF3123; }
        .fui-banner.is-estore .caption .tag .tag-bg::before, .fui-banner.is-estore .caption .tag .tag-bg::after,
        .fui-banner.is-exclusive .caption .tag .tag-bg::before,
        .fui-banner.is-exclusive .caption .tag .tag-bg::after {
          content: "";
          position: absolute;
          display: block;
          top: -3px;
          background-repeat: no-repeat; }
        .fui-banner.is-estore .caption .tag .tag-bg::before,
        .fui-banner.is-exclusive .caption .tag .tag-bg::before {
          width: 18px;
          height: 45px;
          left: -18px;
          background-image: url(/DigService/resources/cbu/estore/tag-shape-01@2x.png);
          background-position: left top;
          background-size: 19px auto; }
        .fui-banner.is-estore .caption .tag .tag-bg::after,
        .fui-banner.is-exclusive .caption .tag .tag-bg::after {
          width: 18px;
          height: 31px;
          right: -18px;
          background-image: url(/DigService/resources/cbu/estore/tag-shape-02@2x.png);
          background-position: right top;
          background-size: auto 31px; }
    .fui-banner.is-estore .caption h1,
    .fui-banner.is-exclusive .caption h1 {
      margin: 0 0 12px; }
    .fui-banner.is-estore .caption h5,
    .fui-banner.is-exclusive .caption h5 {
      font-size: 18px;
      height: 54px;
      margin: 0 0;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: block;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      white-space: normal;
      -ms-text-overflow: ellipsis; }
    .fui-banner.is-estore .caption .action,
    .fui-banner.is-exclusive .caption .action {
      padding-top: 24px; }
      .fui-banner.is-estore .caption .action .fui-button,
      .fui-banner.is-exclusive .caption .action .fui-button {
        min-width: 166px; }
  @media screen and (min-width: 600px) {
    .fui-banner.is-estore .bg img,
    .fui-banner.is-exclusive .bg img {
      height: 500px;
      width: auto;
      top: 0;
      left: -100%;
      right: -100%; }
    .fui-banner.is-estore .slick-initialized ul.slick-dots,
    .fui-banner.is-exclusive .slick-initialized ul.slick-dots {
      bottom: 48px; }
      .fui-banner.is-estore .slick-initialized ul.slick-dots li,
      .fui-banner.is-exclusive .slick-initialized ul.slick-dots li {
        width: 50px; }
    .fui-banner.is-estore .caption,
    .fui-banner.is-exclusive .caption {
      padding-top: 90px; }
      .fui-banner.is-estore .caption .tag,
      .fui-banner.is-exclusive .caption .tag {
        font-size: 1rem; }
      .fui-banner.is-estore .caption h5,
      .fui-banner.is-exclusive .caption h5 {
        font-size: 22px;
        height: 66px; } }
  @media screen and (min-width: 1440px) {
    .fui-banner.is-estore .bg img,
    .fui-banner.is-exclusive .bg img {
      width: 100%;
      height: auto;
      top: -100%;
      bottom: -100%; } }

.fui-banner.is-exclusive {
  height: 450px; }
  .fui-banner.is-exclusive .bg,
  .fui-banner.is-exclusive .slick-initialized .slick-slide {
    height: 450px; }
  .fui-banner.is-exclusive .caption .action {
    display: flex; }
    .fui-banner.is-exclusive .caption .action > * {
      min-width: 0;
      width: 50%;
      padding-left: 24px;
      padding-right: 24px;
      max-width: 220px; }
  @media screen and (min-width: 768px) {
    .fui-banner.is-exclusive {
      height: 500px; }
      .fui-banner.is-exclusive .bg,
      .fui-banner.is-exclusive .bg img,
      .fui-banner.is-exclusive .slick-initialized .slick-slide {
        height: 500px; }
        .fui-banner.is-exclusive .bg .caption .fui-container,
        .fui-banner.is-exclusive .bg img .caption .fui-container,
        .fui-banner.is-exclusive .slick-initialized .slick-slide .caption .fui-container {
          width: 90%; }
      .fui-banner.is-exclusive .bg img {
        width: auto; } }

.fui-banner {
  position: relative;
  overflow: hidden;
  background-position: left center;
  background-size: cover;
  z-index: 3;
  padding-top: 0;
  min-height: 620px; }
  .fui-banner.is-rate-plan .fui-container {
    display: flex;
    align-items: center;
    position: relative;
    height: 100%; }
    .fui-banner.is-rate-plan .fui-container .caption {
      display: flex;
      flex-direction: column;
      position: relative;
      padding: 0;
      top: 0; }
      .fui-banner.is-rate-plan .fui-container .caption .subtitle, .fui-banner.is-rate-plan .fui-container .caption .fui-card.box .fui-card-content .fui-card-title, .fui-card.box .fui-card-content .fui-banner.is-rate-plan .fui-container .caption .fui-card-title {
        margin-bottom: 0; }
  .fui-banner.is-adaptive {
    min-height: auto;
    background-position: center; }
    .fui-banner.is-adaptive .fui-container {
      height: 100%;
      width: 90%;
      top: 0; }
    .fui-banner.is-adaptive h1 {
      display: flex;
      align-items: center;
      margin: 0;
      color: #FFFFFF;
      height: 100%;
      padding: 0; }
    @media screen and (max-width: 960px) {
      .fui-banner.is-adaptive .fui-container {
        width: 100%;
        padding: 0 15px; } }
  .fui-banner.fui-cbu, .fui-banner.fui-ebu {
    overflow: visible; }
    .fui-banner.fui-cbu .fui-tab-container .fui-tab, .fui-banner.fui-ebu .fui-tab-container .fui-tab {
      height: auto;
      overflow: hidden; }
    .fui-banner.fui-cbu + .fui-tab-pane, .fui-banner.fui-ebu + .fui-tab-pane {
      margin-bottom: 0; }
  .fui-banner.is-search {
    padding: 30px 0 24px;
    min-height: 0;
    background: #E8ECF0;
    overflow: visible;
    background: url(/DigService/resources/common/images/ebu-banner-search.png) center no-repeat;
    background-size: cover; }
    .fui-banner.is-search .fui-container {
      position: relative;
      z-index: 9;
      padding: 0 16px; }
      .fui-banner.is-search .fui-container.keyword-container {
        z-index: 0;
        padding-right: 0; }
    .fui-banner.is-search h2 {
      line-height: 36px;
      font-size: 1.5rem;
      margin-bottom: 12px; }
  .fui-banner.is-form {
    min-height: 170px;
    height: 170px;
    background-color: #D6D6D6;
    color: #FFFFFF;
    background-size: cover;
    background-position: center; }
    .fui-banner.is-form .fui-container {
      top: 0;
      display: flex;
      align-items: center;
      padding: 0 16px;
      margin-top: 0;
      height: 100%; }
    .fui-banner.is-form h1 {
      margin-bottom: 0;
      width: 100%; }
  .fui-banner.is-product {
    min-height: 450px;
    background-color: #0A3476;
    background-position: center;
    background-size: cover;
    padding: 0 !important;
    z-index: 0; }
    .fui-banner.is-product > img .banner-img > div,
    .fui-banner.is-product .slick-slide .banner-img > div {
      background-position: center;
      background-size: cover; }
    .fui-banner.is-product > img img,
    .fui-banner.is-product .slick-slide img {
      top: 0;
      position: absolute;
      height: 100%;
      width: 100%; }
    .fui-banner.is-product.is-section-banner-1 h1 {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: block;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      white-space: normal;
      -ms-text-overflow: ellipsis; }
    .fui-banner.is-product.is-style-2 .banner-img > div {
      top: 0;
      position: absolute;
      height: 100%;
      width: 100%;
      background-position: center;
      background-size: cover; }
    .fui-banner.is-product.is-style-2 .caption {
      top: 80px; }
      .fui-banner.is-product.is-style-2 .caption h1 {
        font-size: 32px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: block;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        white-space: normal;
        -ms-text-overflow: ellipsis; }
      .fui-banner.is-product.is-style-2 .caption .subtitle, .fui-banner.is-product.is-style-2 .caption .fui-card.box .fui-card-content .fui-card-title, .fui-card.box .fui-card-content .fui-banner.is-product.is-style-2 .caption .fui-card-title {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: block;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        white-space: normal;
        -ms-text-overflow: ellipsis; }
        .fui-banner.is-product.is-style-2 .caption .subtitle > p, .fui-banner.is-product.is-style-2 .caption .fui-card.box .fui-card-content .fui-card-title > p, .fui-card.box .fui-card-content .fui-banner.is-product.is-style-2 .caption .fui-card-title > p {
          margin: 0; }
        .fui-banner.is-product.is-style-2 .caption .subtitle h6, .fui-banner.is-product.is-style-2 .caption .fui-card.box .fui-card-content .fui-card-title h6, .fui-card.box .fui-card-content .fui-banner.is-product.is-style-2 .caption .fui-card-title h6 {
          display: flex;
          align-items: center;
          margin: 0; }
          .fui-banner.is-product.is-style-2 .caption .subtitle h6 img, .fui-banner.is-product.is-style-2 .caption .fui-card.box .fui-card-content .fui-card-title h6 img, .fui-card.box .fui-card-content .fui-banner.is-product.is-style-2 .caption .fui-card-title h6 img {
            margin: 0;
            margin-right: 6px; }
          .fui-banner.is-product.is-style-2 .caption .subtitle h6:nth-of-type(1), .fui-banner.is-product.is-style-2 .caption .fui-card.box .fui-card-content .fui-card-title h6:nth-of-type(1), .fui-card.box .fui-card-content .fui-banner.is-product.is-style-2 .caption .fui-card-title h6:nth-of-type(1) {
            margin-top: 34px; }
      .fui-banner.is-product.is-style-2 .caption::before {
        content: none; }
    .fui-banner.is-product .slick-slider ul.slick-dots {
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      padding: 0;
      height: 4px;
      bottom: 30px;
      text-align: center;
      list-style: none;
      vertical-align: bottom; }
      .fui-banner.is-product .slick-slider ul.slick-dots li {
        display: inline-block;
        width: 50px;
        height: 4px;
        margin: 0 3px;
        background-color: #8A8A8A; }
        .fui-banner.is-product .slick-slider ul.slick-dots li button {
          opacity: 0; }
        .fui-banner.is-product .slick-slider ul.slick-dots li.slick-active {
          background-color: #FFFFFF; }
    .fui-banner.is-product .slick-slider .slick-slide,
    .fui-banner.is-product .slick-slider .slick-slide > div {
      position: relative;
      min-height: 468px; }
      .fui-banner.is-product .slick-slider .slick-slide .bg,
      .fui-banner.is-product .slick-slider .slick-slide > div .bg {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-position: center;
        background-size: cover; }
      .fui-banner.is-product .slick-slider .slick-slide .caption,
      .fui-banner.is-product .slick-slider .slick-slide > div .caption {
        position: absolute;
        width: 100%;
        max-width: 700px;
        top: 105px;
        bottom: auto; }
        .fui-banner.is-product .slick-slider .slick-slide .caption h1,
        .fui-banner.is-product .slick-slider .slick-slide > div .caption h1 {
          line-height: 42px;
          font-size: 28px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          display: block;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          white-space: normal;
          -ms-text-overflow: ellipsis; }
    .fui-banner.is-product .caption {
      position: absolute;
      max-width: 620px;
      bottom: auto;
      padding: 46px 15px 0 15px;
      color: #FFFFFF;
      top: 46px; }
      .fui-banner.is-product .caption::before {
        content: "";
        position: absolute;
        width: 100%;
        top: 0;
        height: 36px;
        background: url(/DigService/resources/common/images/quotation.png) no-repeat left top;
        background-size: 36px;
        margin-bottom: 12px; }
      .fui-banner.is-product .caption .subtitle, .fui-banner.is-product .caption .fui-card.box .fui-card-content .fui-card-title, .fui-card.box .fui-card-content .fui-banner.is-product .caption .fui-card-title {
        margin-bottom: 36px; }
  .fui-banner.fade > .fui-banner-bg {
    opacity: 0; }
  .fui-banner.is-360 {
    height: 360px;
    min-height: auto; }
    .fui-banner.is-360 .caption {
      top: 64px; }
    @media screen and (max-width: 960px) {
      .fui-banner.is-360.is-product.is-style-2 {
        height: 200px; }
        .fui-banner.is-360.is-product.is-style-2 .caption {
          top: 0; } }
  .fui-banner .fui-container {
    position: absolute;
    padding: 0;
    left: 0;
    right: 0;
    width: 100%; }
  .fui-banner .fui-tab-container {
    position: absolute;
    width: 100%;
    height: 100%;
    display: block;
    top: 0;
    background: none;
    box-shadow: 0 18px 36px 0 rgba(75, 88, 105, 0.24); }
    @media all and (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
      .fui-banner .fui-tab-container {
        top: 0; } }
    .fui-banner .fui-tab-container .fui-tab-header {
      background: #FFF; }
      .fui-banner .fui-tab-container .fui-tab-header .fui-tab .fui-tab-item {
        padding: 12px 12px 0; }
    .fui-banner .fui-tab-container .fui-tab-body {
      position: absolute;
      bottom: 0;
      background: none;
      z-index: 2; }
      .fui-banner .fui-tab-container .fui-tab-body .fui-tab-pane {
        max-width: 100%;
        padding: 0 15px 48px; }
        .fui-banner .fui-tab-container .fui-tab-body .fui-tab-pane .fui-button {
          height: 50px;
          display: block;
          max-width: 180px; }
          .fui-banner .fui-tab-container .fui-tab-body .fui-tab-pane .fui-button span {
            line-height: 50px; }
          .fui-banner .fui-tab-container .fui-tab-body .fui-tab-pane .fui-button.is-secondary span {
            line-height: 46px; }
        .fui-banner .fui-tab-container .fui-tab-body .fui-tab-pane h3.tab-title {
          font-size: 32px;
          line-height: 48px;
          margin-top: 32px;
          margin-bottom: 0;
          width: 70%;
          max-width: 400px; }
        @media screen and (min-width: 960px) {
          .fui-banner .fui-tab-container .fui-tab-body .fui-tab-pane h3.tab-title {
            width: 100%; }
          .fui-banner .fui-tab-container .fui-tab-body .fui-tab-pane .fui-button {
            display: inline-block; } }
  .fui-banner .fui-banner-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 80px;
    left: 0;
    display: block;
    overflow: hidden; }
    .fui-banner .fui-banner-bg img.kv-bg {
      opacity: 0;
      transition: opacity 2s ease; }
    .fui-banner .fui-banner-bg.fade-out img.kv-bg {
      opacity: 0; }
    .fui-banner .fui-banner-bg.is-active img.kv-bg {
      opacity: 1; }
    .fui-banner .fui-banner-bg .main-image {
      position: absolute;
      width: 100%;
      height: auto;
      top: -40px; }
    .fui-banner .fui-banner-bg img.trapzoidal {
      position: absolute;
      height: 100%;
      left: -90px;
      bottom: 24px; }
    .fui-banner .fui-banner-bg img.triangle {
      position: absolute;
      width: 100px;
      left: 15%;
      top: 18%; }
  .fui-banner.fui-cbu {
    min-height: 570px;
    height: 570px; }
    .fui-banner.fui-cbu .fui-banner-bg {
      height: 490px; }
    .fui-banner.fui-cbu .fui-container {
      height: 100%; }
      .fui-banner.fui-cbu .fui-container .fui-tab-body {
        bottom: 40px; }
        .fui-banner.fui-cbu .fui-container .fui-tab-body .fui-tab-pane {
          padding-bottom: 0; }
          .fui-banner.fui-cbu .fui-container .fui-tab-body .fui-tab-pane .fui-dropdown.for-banner {
            margin-bottom: 0; }
            @media screen and (max-width: 374px) {
              .fui-banner.fui-cbu .fui-container .fui-tab-body .fui-tab-pane .fui-dropdown.for-banner {
                max-width: 72%; } }
          .fui-banner.fui-cbu .fui-container .fui-tab-body .fui-tab-pane .tab-link-content > div {
            position: relative;
            display: block;
            margin-top: 18px; }
            .fui-banner.fui-cbu .fui-container .fui-tab-body .fui-tab-pane .tab-link-content > div:first-child > a.tab-link:link {
              margin-top: 0; }
          .fui-banner.fui-cbu .fui-container .fui-tab-body .fui-tab-pane a.tab-link,
          .fui-banner.fui-cbu .fui-container .fui-tab-body .fui-tab-pane a.tab-link:link {
            font-size: 24px;
            position: relative;
            display: inline-block;
            font-weight: 500; }
            .fui-banner.fui-cbu .fui-container .fui-tab-body .fui-tab-pane a.tab-link::after,
            .fui-banner.fui-cbu .fui-container .fui-tab-body .fui-tab-pane a.tab-link:link::after {
              content: "";
              position: absolute;
              display: block;
              bottom: 0;
              width: 100%;
              height: 2px;
              background: #202020; }
          @media screen and (min-width: 960px) {
            .fui-banner.fui-cbu .fui-container .fui-tab-body .fui-tab-pane {
              padding-bottom: 40px; }
              .fui-banner.fui-cbu .fui-container .fui-tab-body .fui-tab-pane .tab-link-content {
                height: 260px;
                column-width: 180px;
                column-count: 2;
                grid-column-gap: 90px;
                column-gap: 90px;
                column-span: all;
                margin-top: -32px; }
                .fui-banner.fui-cbu .fui-container .fui-tab-body .fui-tab-pane .tab-link-content > div {
                  padding-top: 32px;
                  margin-top: 0; }
                .fui-banner.fui-cbu .fui-container .fui-tab-body .fui-tab-pane .tab-link-content a.tab-link,
                .fui-banner.fui-cbu .fui-container .fui-tab-body .fui-tab-pane .tab-link-content a.tab-link:link {
                  display: block;
                  font-size: 28px; } }
    .fui-banner.fui-cbu .kv-arrow {
      position: absolute;
      max-width: 1920px;
      height: 100%;
      top: 80px;
      max-height: 490px;
      width: 100%;
      overflow: hidden; }
    .fui-banner.fui-cbu .kv-arrow img {
      position: absolute;
      max-width: 1920px;
      height: auto;
      width: 100%;
      top: 0;
      left: -100%;
      right: -100%;
      margin: auto;
      z-index: 1; }
      @media screen and (min-width: 510px) {
        .fui-banner.fui-cbu .kv-arrow img {
          width: 100%;
          height: auto; } }
    .fui-banner.fui-cbu .kv-bg {
      position: absolute;
      max-width: 1920px;
      margin: auto 0 auto auto;
      height: auto;
      width: 100%;
      z-index: 0; }
      @media screen and (min-width: 510px) {
        .fui-banner.fui-cbu .kv-bg {
          right: 0;
          left: auto; } }
      @media screen and (min-width: 510px) and (max-width: 959pc) {
        .fui-banner.fui-cbu .kv-bg {
          height: auto;
          width: 100%; } }
    .fui-banner.fui-cbu .fui-tab.with-icon .fui-tab-item .icon,
    .fui-banner.fui-cbu .fui-tab.with-icon .fui-tab-item .icon * {
      width: 30px;
      margin: 0 auto; }
    .fui-banner.fui-cbu .fui-dropdown {
      max-width: 75%;
      margin-bottom: 0; }
      .fui-banner.fui-cbu .fui-dropdown .fui-dropdown-item i.icon-chevron-down {
        align-self: flex-end;
        margin-bottom: 10px; }
    .fui-banner.fui-cbu .action {
      margin: 24px 0; }
      .fui-banner.fui-cbu .action .fui-button {
        margin: 0 8px 8px 0; }
    .fui-banner.fui-cbu .fui-tab-body {
      padding-top: 52px; }
    .fui-banner.fui-cbu .fui-banner-tags .fui-button.is-label {
      min-width: 0px !important;
      height: 34px;
      line-height: 30px;
      padding: 0 12px;
      text-align: center;
      font-size: 0.875rem;
      border: solid 2px #EBEBEB;
      border-radius: 30px;
      margin-right: 6px;
      margin-bottom: 6px; }
      .fui-banner.fui-cbu .fui-banner-tags .fui-button.is-label:hover {
        border-color: #202020; }
      @media screen and (max-width: 374px) {
        .fui-banner.fui-cbu .fui-banner-tags .fui-button.is-label {
          padding: 0 8px;
          margin-right: 6px; } }
    @media screen and (min-width: 960px) {
      .fui-banner.fui-cbu .fui-container {
        top: 0; }
        .fui-banner.fui-cbu .fui-container .fui-tab-container {
          top: 95px; }
          .fui-banner.fui-cbu .fui-container .fui-tab-container .fui-tab-body {
            bottom: 0; }
            .fui-banner.fui-cbu .fui-container .fui-tab-container .fui-tab-body .fui-tab-pane {
              padding-top: 0; }
      .fui-banner.fui-cbu .kv-arrow,
      .fui-banner.fui-cbu .fui-banner-bg {
        height: 620px;
        max-height: 620px; }
      .fui-banner.fui-cbu .kv-arrow img {
        height: 100%;
        width: auto; }
      .fui-banner.fui-cbu .kv-bg {
        width: auto;
        height: 100%;
        left: -100%;
        right: -100%;
        margin: auto; } }
    @media screen and (min-width: 960px) and (max-width: 1920px) {
      .fui-banner.fui-cbu .kv-bg {
        height: 100%;
        width: auto; } }
    @media screen and (min-width: 1920px) {
      .fui-banner.fui-cbu .kv-bg {
        height: auto;
        width: 100%; } }
  @media screen and (min-width: 600px) and (max-width: 812px) and (orientation: landscape) {
    .fui-banner.is-solution .slick-slider .slick-slide img.d-md-block.d-none,
    .fui-banner img.d-sm-block.d-none {
      display: block !important;
      height: 100%;
      width: auto;
      max-width: none; }
    .fui-banner img.d-sm-none.d-block {
      display: none !important; } }
  @media screen and (max-width: 960px) {
    .fui-banner.fui-cbu .fui-container .fui-tab-body .fui-tab-pane .fui-dropdown {
      max-width: 350px; } }
  @media screen and (max-width: 960px) and (min-width: 540px) {
    .fui-banner.fui-cbu .kv-arrow img {
      top: -20%; } }
  @media screen and (max-width: 540px) and (min-width: 376px) {
    .fui-banner.fui-cbu .kv-arrow img {
      top: -25px; } }
  @media screen and (min-width: 768px) {
    .fui-banner .fui-banner-bg img.triangle {
      left: 15%;
      top: 28%;
      width: 15%; }
    .fui-banner .fui-banner-bg img.trapzoidal {
      height: 120%;
      left: -240px;
      bottom: -1px; }
    .fui-banner .fui-banner-bg .main-image {
      top: 0;
      right: 0;
      width: auto;
      height: 100%; } }
  @media screen and (min-width: 960px) {
    .fui-banner {
      padding: 96px 0; }
      .fui-banner.fui-cbu {
        height: 620px; }
        .fui-banner.fui-cbu .kv-arrow,
        .fui-banner.fui-cbu .fui-banner-bg {
          top: 0; }
        .fui-banner.fui-cbu .fui-dropdown {
          max-width: 75%; }
          .fui-banner.fui-cbu .fui-dropdown .fui-dropdown-item i.icon-chevron-down {
            align-self: center;
            margin: 0; }
        .fui-banner.fui-cbu .fui-tab-container .fui-tab-header .fui-tab .fui-tab-item {
          height: 100px;
          padding-top: 20px; }
      .fui-banner .fui-banner-bg img.triangle {
        left: 55%; }
      .fui-banner.is-search {
        padding: 84px 0 35px;
        min-height: 0px; }
      .fui-banner.is-form {
        min-height: 240px;
        height: 240px; }
        .fui-banner.is-form .fui-container {
          margin-top: 90px;
          height: auto;
          height: initial; }
        .fui-banner.is-form img {
          position: absolute;
          height: auto;
          width: 100%; }
        .fui-banner.is-form h1 {
          max-width: none;
          max-width: initial; }
      .fui-banner.is-product {
        margin-top: 0;
        min-height: 468px;
        padding: 0; }
        .fui-banner.is-product img {
          left: 0;
          right: 0;
          margin: auto; }
        .fui-banner.is-product .caption {
          top: 80px; }
        .fui-banner.is-product.is-style-2 .slick-slider .slick-slide img {
          width: 100%;
          height: auto; }
        .fui-banner.is-product.is-style-2 .caption,
        .fui-banner.is-product.is-style-2 .slick-slider .slick-slide > div .caption {
          top: 80px;
          bottom: auto; }
          .fui-banner.is-product.is-style-2 .caption h1,
          .fui-banner.is-product.is-style-2 .slick-slider .slick-slide > div .caption h1 {
            font-size: 42px; }
        .fui-banner.is-product .slick-slider {
          min-height: 468px; }
          .fui-banner.is-product .slick-slider .slick-slide .caption,
          .fui-banner.is-product .slick-slider .slick-slide > div .caption {
            bottom: 68px; }
            .fui-banner.is-product .slick-slider .slick-slide .caption h1,
            .fui-banner.is-product .slick-slider .slick-slide > div .caption h1 {
              font-size: 42px !important;
              line-height: 63px; }
      .fui-banner .fui-container {
        padding: 0 15px; }
      .fui-banner .fui-tab-container {
        position: relative;
        background: #FFF;
        height: auto;
        max-width: 695px; }
        .fui-banner .fui-tab-container .fui-tab-body {
          position: relative; }
          .fui-banner .fui-tab-container .fui-tab-body .fui-tab-pane {
            max-width: 100%;
            padding: 32px 32px 56px; }
            .fui-banner .fui-tab-container .fui-tab-body .fui-tab-pane h3.tab-title {
              font-size: 42px;
              line-height: 60px;
              margin-bottom: 0; }
      .fui-banner .fui-tab-container .fui-tab-body .fui-tab-pane h3.tab-title {
        max-width: none;
        max-width: initial; } }
  @media screen and (min-width: 1200px) {
    .fui-banner .fui-banner-bg .main-image {
      left: -100%;
      right: -100%;
      width: auto;
      height: 100%;
      margin: auto; }
    .fui-banner .fui-banner-bg img.trapzoidal {
      left: 0;
      width: 75%;
      height: auto; } }
  @media screen and (max-width: 1920px) and (min-width: 960px) {
    .fui-banner.fui-cbu .fui-banner-bg img.kv-bg {
      height: 100%;
      width: auto; } }
  @media screen and (min-width: 1921px) {
    .fui-banner.fui-cbu .fui-banner-bg img.kv-bg {
      height: auto;
      width: 100%;
      max-width: none; } }
  @media screen and (min-width: 1440px) {
    .fui-banner .fui-banner-bg img.trapzoidal {
      width: 60%;
      height: auto; }
    .fui-banner .fui-banner-bg img.triangle {
      left: 50%; } }

.is-eservice .fui-banner.is-form {
  min-height: 147px;
  height: 147px; }
  @media screen and (min-width: 960px) {
    .is-eservice .fui-banner.is-form {
      min-height: 156px;
      height: 156px;
      padding: 0; }
      .is-eservice .fui-banner.is-form .fui-container {
        margin-top: 50px;
        width: 90%; } }

.is-eservice .fui-banner.is-helpcenter {
  min-height: 172px;
  height: 172px; }
  @media screen and (min-width: 960px) {
    .is-eservice .fui-banner.is-helpcenter {
      min-height: 240px;
      height: 240px; }
      .is-eservice .fui-banner.is-helpcenter .fui-container {
        margin-top: 90px; } }

.fui-nav-anchor + .fui-banner {
  margin-top: 60px; }

.fui-banner.fui-cbu .fui-tab-container,
.fui-banner.fui-cbu .fui-banner-bg,
.fui-banner.fui-cbu .kv-arrow {
  opacity: 0; }

.fui-banner.fui-cbu.fade-in .fui-tab-container {
  animation: fadeIn 0.5s 1s ease forwards; }

.fui-banner.fui-cbu.fade-in .fui-banner-bg {
  opacity: 0;
  transform: scale(1.1);
  animation: zoomIn 1s 0.5s ease forwards; }

.fui-banner.fui-cbu.fade-in .kv-arrow {
  opacity: 0;
  transform: scale(1.1);
  animation: zoomIn 1s ease forwards; }

@keyframes menuIn {
  0% {
    opacity: 0;
    transform: scale(0.9); }
  100% {
    opacity: 1;
    transform: scale(1); } }

.fui-dropdown.disabled .fui-dropdown-item {
  pointer-events: none;
  background-color: #f1f3f5;
  border: 2px solid #E8ECF0;
  color: #8A8A8A; }

.fui-dropdown.is-show {
  position: relative;
  z-index: 1000; }

.fui-dropdown .fui-dropdown-item {
  position: relative;
  cursor: pointer;
  display: block;
  font-size: 1rem;
  color: #141414;
  background: none;
  border: 0; }
  .fui-dropdown .fui-dropdown-item i {
    font-size: 24px; }
  .fui-dropdown .fui-dropdown-item[aria-expanded="true"] + .fui-menu {
    display: block;
    opacity: 1; }

.fui-dropdown.is-button {
  width: 100%;
  max-width: 100%; }
  .fui-dropdown.is-button button {
    padding-left: 12px;
    width: 100%;
    text-align: left;
    background: #ffffff;
    height: 50px;
    border: 2px solid #E8ECF0; }
    .fui-dropdown.is-button button i {
      float: right; }
  .fui-dropdown.is-button.disabled button {
    cursor: auto;
    cursor: initial;
    pointer-events: none;
    background-color: #f1f3f5; }

.fui-dropdown.is-reverse .fui-dropdown-item {
  color: #FFFFFF; }

.fui-dropdown.is-border {
  display: inline-block;
  background-color: #fff;
  padding: 12px 5px;
  border: 2px solid #E8ECF0;
  width: 100%;
  height: 50px; }
  .fui-dropdown.is-border button.fui-dropdown-item {
    display: flex;
    align-items: center;
    width: 100% !important;
    justify-content: space-between; }
    .fui-dropdown.is-border button.fui-dropdown-item span, .fui-dropdown.is-border button.fui-dropdown-item i {
      color: #202020;
      font-weight: 500; }

.fui-dropdown.with-arrow {
  font-size: 24px; }
  .fui-dropdown.with-arrow * {
    vertical-align: middle; }
  @media screen and (max-width: 600px) {
    .fui-dropdown.with-arrow button.fui-dropdown-item {
      display: flex;
      align-items: center;
      justify-content: space-between; } }

.fui-dropdown .fui-menu {
  position: absolute;
  min-width: 180px;
  width: 100%;
  background: #FFFFFF;
  padding: 0;
  display: none; }
  .fui-dropdown .fui-menu .fui-item {
    height: 42px;
    font-weight: 500;
    color: #202020;
    text-align: center; }
    .fui-dropdown .fui-menu .fui-item.active .content,
    .fui-dropdown .fui-menu .fui-item.active:hover .content {
      color: #f05c38; }
    .fui-dropdown .fui-menu .fui-item .content {
      line-height: 26px;
      padding: 0; }
    .fui-dropdown .fui-menu .fui-item:hover, .fui-dropdown .fui-menu .fui-item:focus {
      color: #141414;
      font-weight: 700;
      background: #E8ECF0 !important; }

.fui-dropdown .fui-dropdown .menu-wrapper {
  padding: 0; }

.fui-dropdown .menu-wrapper {
  background-color: #fff;
  box-shadow: 0 24px 48px 0 rgba(75, 88, 105, 0.36);
  padding: 8px;
  max-height: 280px;
  overflow-y: auto;
  overflow-x: hidden; }
  .fui-dropdown .menu-wrapper .fui-menu-group {
    border-bottom: 2px solid #EBEBEB;
    padding: 6px 0; }
    .fui-dropdown .menu-wrapper .fui-menu-group:last-child {
      border-bottom: 0; }
  .fui-dropdown .menu-wrapper .fui-item:hover {
    color: #202020 !important; }

.fui-dropdown.is-left .menu-wrapper .fui-item {
  text-align: left;
  padding-left: 14px; }

.fui-dropdown.is-right.is-top .fui-menu {
  transform-origin: 100% 0%; }

.fui-dropdown.is-right .fui-menu {
  right: 0; }

.fui-dropdown.is-top .fui-menu {
  top: 110%; }

.fui-dropdown.is-bottom .fui-menu {
  bottom: 110%; }

.fui-dropdown .active {
  position: relative; }

.fui-dropdown .check-icon.active::after {
  content: "";
  position: absolute;
  right: 18px;
  top: 0px;
  bottom: 0;
  width: 24px;
  height: 24px;
  background: none;
  display: block;
  margin: auto;
  color: #D3060F;
  text-align: center;
  font-size: 16px;
  font-family: icomoon !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 24px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.fui-dropdown.for-banner {
  position: relative;
  display: inline-block;
  margin-bottom: 48px;
  z-index: 2; }
  .fui-dropdown.for-banner .fui-dropdown-item {
    font-size: 36px;
    line-height: 48px;
    border-bottom: solid 4px #EF3123;
    text-align: left;
    display: flex;
    font-weight: 500;
    align-content: center;
    align-items: center;
    padding-left: 0;
    padding-right: 0;
    font-size: 32px; }
  .fui-dropdown.for-banner .fui-menu {
    width: 100%;
    top: 100%;
    bottom: 0;
    margin: auto; }
    .fui-dropdown.for-banner .fui-menu .menu-wrapper {
      margin-top: -40%; }
    .fui-dropdown.for-banner .fui-menu .fui-item {
      height: auto;
      line-height: 1.25;
      font-size: 18px;
      padding: 16px 20px;
      text-align: left; }

@media screen and (min-width: 960px) {
  .fui-dropdown.for-banner {
    margin-bottom: 88px; }
    .fui-dropdown.for-banner .fui-dropdown-item {
      font-size: 42px;
      line-height: 63px; }
    .fui-dropdown.for-banner .fui-menu .menu-wrapper .fui-item {
      font-size: 28px; } }

.fui-collapse-group .fui-collapse {
  border-bottom: 1px solid #6a798e;
  padding: 13px 0; }
  .fui-collapse-group .fui-collapse:first-child:last-child {
    border: 0; }

@media screen and (min-width: 960px) {
  .fui-collapse-group .fui-collapse {
    padding: 18px 0; } }

.fui-collapse-container {
  color: #5F6E84;
  background: #fafafa;
  padding: 30px 15px;
  margin-top: 15px; }
  .fui-collapse-container .fui-collapse .collapse-header {
    font-size: 1rem; }
  .fui-collapse-container .fui-collapse .collapse-body * {
    font-size: 0.875rem; }
  .fui-collapse-container .fui-collapse .collapse-body article {
    padding-top: 0; }

.form-group .fui-collapse.is-collapse-checkbox label {
  position: relative;
  display: inline-block;
  margin: 0; }
  .form-group .fui-collapse.is-collapse-checkbox label::after {
    margin-left: 0; }

.fui-collapse-paper {
  padding: 0; }
  .fui-collapse-paper .fui-collapse {
    box-shadow: 0 6px 12px 0 rgba(75, 88, 105, 0.12);
    border: 0;
    background-color: #fff;
    margin-bottom: 12px;
    padding: 30px; }
    .fui-collapse-paper .fui-collapse .collapse-header h6 {
      margin: 0; }
    .fui-collapse-paper .fui-collapse section.fui-section-collapse {
      background-color: #fafafa;
      padding: 0; }
      .fui-collapse-paper .fui-collapse section.fui-section-collapse .fui-collapse {
        background: none;
        box-shadow: none; }

.fui-collapse {
  margin: 0; }
  .fui-collapse.with-trigger {
    border: 0;
    background: none;
    margin: 30px -30px 0; }
    .fui-collapse.with-trigger .collapse-body {
      box-sizing: content-box;
      padding: 0 30px;
      border: 0;
      box-shadow: none;
      font-size: 1rem !important;
      padding: 0 20px; }
      .fui-collapse.with-trigger .collapse-body h4 {
        font-weight: 400;
        margin: 24px 0 24px; }
    .fui-collapse.with-trigger .collapse-trigger {
      background: #fafafa;
      text-align: center;
      height: 52px;
      line-height: 52px;
      font-weight: 500;
      cursor: pointer; }
      .fui-collapse.with-trigger .collapse-trigger * {
        vertical-align: middle; }
      .fui-collapse.with-trigger .collapse-trigger [class*='icon-'] {
        font-size: 30px; }
  .fui-collapse .has-date {
    position: relative; }
    .fui-collapse .has-date .collapse-header {
      order: 2;
      position: absolute;
      right: 0;
      top: 0;
      width: 50px;
      height: 100%; }
    .fui-collapse .has-date .has-date-body {
      width: calc( 100% - 40px); }
    @media screen and (max-width: 960px) {
      .fui-collapse .has-date .collapse-header {
        order: 0; } }
  .fui-collapse .collapse-body {
    overflow: hidden;
    transition: all 0.3s 0.1s ease; }
    .fui-collapse .collapse-body a.fui-button {
      white-space: normal; }
      .fui-collapse .collapse-body a.fui-button .text {
        line-height: 1.75; }
    .fui-collapse .collapse-body article {
      font-size: 16px; }
    .fui-collapse .collapse-body ol {
      padding-left: 20px; }
  .fui-collapse.is-collapse-checkbox {
    font-size: 0.875rem;
    margin: 24px 0; }
    .fui-collapse.is-collapse-checkbox input {
      display: none; }
    .fui-collapse.is-collapse-checkbox .checkbox {
      margin: 0; }
      .fui-collapse.is-collapse-checkbox .checkbox.is-agreement::before {
        margin-right: 8px !important; }
      .fui-collapse.is-collapse-checkbox .checkbox .content {
        font-size: 0.875rem;
        line-height: 1.5rem;
        display: inline-flex !important;
        align-items: center !important; }
    .fui-collapse.is-collapse-checkbox .collapse-trigger {
      vertical-align: middle;
      display: inline-block;
      line-height: 1em;
      margin-top: -3px;
      margin-left: 8px;
      font-weight: 500;
      cursor: pointer; }
      .fui-collapse.is-collapse-checkbox .collapse-trigger * {
        height: 1;
        line-height: 1;
        vertical-align: middle; }
      .fui-collapse.is-collapse-checkbox .collapse-trigger i[class*='icon'] {
        font-size: 24px; }
    .fui-collapse.is-collapse-checkbox .collapse-body {
      overflow: hidden;
      transition: height 0.3s ease; }
      .fui-collapse.is-collapse-checkbox .collapse-body > article {
        color: #5F6E84;
        padding: 24px 30px;
        background: #ebebeb;
        font-size: 16px; }
        .fui-collapse.is-collapse-checkbox .collapse-body > article p {
          font-size: 14px;
          margin: 0; }
  .fui-collapse .collapse-header {
    position: relative;
    font-size: 1.125rem;
    display: flex;
    padding: 9px 30px 9px 0;
    margin: 0; }
    .fui-collapse .collapse-header .content {
      flex: 1 1 auto; }
    .fui-collapse .collapse-header + .collapse-body {
      transition: all 0.3s 0.1s ease;
      overflow: hidden;
      height: 0; }
      .fui-collapse .collapse-header + .collapse-body > * {
        padding: 15px 0; }
    .fui-collapse .collapse-header + .fui-menu {
      display: none;
      padding: 15px 0; }
    .fui-collapse .collapse-header::after, .fui-collapse .collapse-header::before {
      content: "";
      font-family: "icomoon" !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-feature-settings: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      position: absolute;
      width: 24px;
      height: 24px;
      font-size: 24px;
      display: block;
      color: #202020;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto; }
    .fui-collapse .collapse-header::before {
      content: ""; }
    .fui-collapse .collapse-header::after {
      display: none;
      content: ""; }
  .fui-collapse.is-open > .mobile-collapse {
    display: block; }
  .fui-collapse.is-open .has-date {
    margin-bottom: 36px; }
    .fui-collapse.is-open .has-date + .collapse-body {
      overflow: visible; }
  .fui-collapse.is-open .collapse-header {
    margin-bottom: 16px; }
    .fui-collapse.is-open .collapse-header::before {
      display: none; }
    .fui-collapse.is-open .collapse-header::after {
      display: block; }
  .fui-collapse.is-open .collapse-body article li {
    line-break: anywhere; }
  .fui-collapse.response-collapse.is-open .collapse-header {
    margin-bottom: 0; }

.fui-read-more-info .append-content {
  transition: all 0.5s;
  transform: rotateX(90deg);
  transform-origin: top;
  height: 0; }
  .fui-read-more-info .append-content.appended {
    transform: rotateX(0deg);
    height: auto; }

@media screen and (min-width: 960px) {
  .fui-collapse.is-collapse-checkbox {
    margin: 32px 0 12px; }
    .fui-collapse.is-collapse-checkbox .collapse-body {
      margin-top: 24px; }
  .fui-collapse.response-collapse .collapse-header {
    margin-top: 0;
    margin-bottom: 0 !important;
    font-size: 1.25rem; }
    .fui-collapse.response-collapse .collapse-header::before, .fui-collapse.response-collapse .collapse-header::after {
      display: none; }
    .fui-collapse.response-collapse .collapse-header + .fui-menu {
      display: block !important; }
  .fui-collapse-container {
    padding: 30px;
    margin-top: 30px; } }

@media screen and (max-width: 960px) {
  .fui-collapse-paper {
    margin-left: 0;
    margin-right: 0; }
  .fui-collapse.with-trigger {
    margin: 30px -20px 0; } }

.carousen-container {
  line-height: 0; }

section.screwed-slider {
  max-width: 1440px;
  margin: 76px auto 0;
  padding-bottom: 192px; }
  section.screwed-slider.small-screen {
    padding-top: 0px;
    padding-bottom: 122px; }
    section.screwed-slider.small-screen a.shop-carousel-item .item-bg {
      height: 300px;
      padding-left: 172px; }
      section.screwed-slider.small-screen a.shop-carousel-item .item-bg h2 {
        font-size: 28px; }
      section.screwed-slider.small-screen a.shop-carousel-item .item-bg h5 {
        font-size: 20px;
        font-weight: 400; }
      section.screwed-slider.small-screen a.shop-carousel-item .item-bg .main-img img {
        height: 290px; }
      section.screwed-slider.small-screen a.shop-carousel-item .item-bg .slick-dots {
        top: 248px; }
    section.screwed-slider.small-screen .shop-carousel.is-prev .item-bg .main-img img {
      top: 30px; }
    section.screwed-slider.small-screen .shop-carousel.is-next .item-bg .main-img img {
      top: 30px; }
    section.screwed-slider.small-screen .dot-wrapper {
      display: flex;
      align-items: center;
      justify-content: center; }
      section.screwed-slider.small-screen .dot-wrapper img {
        margin-right: 18px; }
  section.screwed-slider .is-main .slick-dots {
    top: 280px;
    position: absolute; }
  section.screwed-slider .slick-slider button {
    cursor: pointer; }
  section.screwed-slider .shop-carousel {
    max-height: 320px; }
  section.screwed-slider .carousen-container {
    z-index: 0;
    text-rendering: geometricPrecision; }
    section.screwed-slider .carousen-container .slick-slide {
      padding-right: 86px; }
    section.screwed-slider .carousen-container.is-prev .slick-track {
      left: 36px; }
    section.screwed-slider .carousen-container.is-next .slick-track {
      left: -82px; }
  section.screwed-slider .center-carousen {
    z-index: 1; }
    section.screwed-slider .center-carousen .slick-list {
      margin: 0 100px;
      overflow: visible; }
  section.screwed-slider .rectangle {
    position: absolute;
    right: 0;
    left: 0;
    margin: 0 auto;
    filter: drop-shadow(0 18px 36px rgba(75, 88, 105, 0.18)); }
  section.screwed-slider .slick-arrow.slick-prev {
    left: -36%; }
  section.screwed-slider .slick-arrow.slick-next {
    right: -36%; }
  section.screwed-slider a.shop-carousel-item {
    position: relative;
    margin-left: -46px; }
    section.screwed-slider a.shop-carousel-item .item-bg {
      margin: 0 auto;
      text-align: left;
      height: 320px;
      position: relative;
      max-width: 1030px;
      padding-top: 36px;
      padding-left: 148px; }
      section.screwed-slider a.shop-carousel-item .item-bg svg {
        position: absolute;
        right: -88px;
        top: 0; }
      section.screwed-slider a.shop-carousel-item .item-bg h2 {
        margin-bottom: 18px;
        padding-right: 30%;
        z-index: 1;
        position: relative; }
      section.screwed-slider a.shop-carousel-item .item-bg .subtitle, section.screwed-slider a.shop-carousel-item .item-bg .fui-card.box .fui-card-content .fui-card-title, .fui-card.box .fui-card-content section.screwed-slider a.shop-carousel-item .item-bg .fui-card-title {
        display: flex;
        align-items: flex-start;
        padding-right: 30%;
        z-index: 1;
        position: relative; }
        section.screwed-slider a.shop-carousel-item .item-bg .subtitle h5, section.screwed-slider a.shop-carousel-item .item-bg .fui-card.box .fui-card-content .fui-card-title h5, .fui-card.box .fui-card-content section.screwed-slider a.shop-carousel-item .item-bg .fui-card-title h5 {
          margin: 0; }
      section.screwed-slider a.shop-carousel-item .item-bg button {
        position: absolute;
        bottom: 14px;
        z-index: 1; }
      section.screwed-slider a.shop-carousel-item .item-bg .main-img {
        z-index: 1;
        position: absolute;
        right: -40px;
        top: auto;
        top: initial;
        bottom: 0; }
        section.screwed-slider a.shop-carousel-item .item-bg .main-img img {
          height: 25vw;
          max-height: 310px; }
    @media screen and (min-width: 960px) and (max-width: 1300px) {
      section.screwed-slider a.shop-carousel-item .item-bg h2 {
        font-size: 24px;
        line-height: 1.5;
        margin-bottom: 6px;
        margin-left: calc( -100vw / 30);
        padding-right: 54%; }
      section.screwed-slider a.shop-carousel-item .item-bg h5 {
        font-size: 18px; }
      section.screwed-slider a.shop-carousel-item .item-bg .subtitle, section.screwed-slider a.shop-carousel-item .item-bg .fui-card.box .fui-card-content .fui-card-title, .fui-card.box .fui-card-content section.screwed-slider a.shop-carousel-item .item-bg .fui-card-title {
        margin-left: -6%;
        padding-right: 47%; }
        section.screwed-slider a.shop-carousel-item .item-bg .subtitle img, section.screwed-slider a.shop-carousel-item .item-bg .fui-card.box .fui-card-content .fui-card-title img, .fui-card.box .fui-card-content section.screwed-slider a.shop-carousel-item .item-bg .fui-card-title img {
          display: none; }
      section.screwed-slider a.shop-carousel-item .item-bg button {
        min-width: 0;
        min-width: initial; } }
  section.screwed-slider .skew-bg {
    width: 224px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1; }
  section.screwed-slider .slick-dots {
    max-width: 1170px;
    padding: 0;
    margin: 64px auto 0;
    display: flex !important;
    justify-content: space-between;
    position: absolute;
    left: 0;
    right: 0;
    top: 316px;
    position: absolute;
    padding: 0; }
    section.screwed-slider .slick-dots li {
      width: 100%;
      background: none;
      margin: 0; }
      section.screwed-slider .slick-dots li.slick-active {
        background: none; }
        section.screwed-slider .slick-dots li.slick-active .bar {
          display: none; }
        section.screwed-slider .slick-dots li.slick-active .bar-active {
          width: 100%;
          display: flex;
          justify-content: center; }
          section.screwed-slider .slick-dots li.slick-active .bar-active .triangle {
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            top: -10px;
            position: relative; }
      section.screwed-slider .slick-dots li a {
        width: 100%; }
        section.screwed-slider .slick-dots li a .bar {
          width: 100%;
          height: 4px;
          background-color: #ebebeb;
          margin-top: 16px;
          display: block; }
        section.screwed-slider .slick-dots li a .bar-active {
          width: 0;
          height: 4px;
          margin-top: 16px; }
        section.screwed-slider .slick-dots li a h6 {
          margin: 0; }
        section.screwed-slider .slick-dots li a img {
          width: 56px;
          height: 56px;
          margin-bottom: 12px;
          border-radius: 50%; }
  section.screwed-slider .shop-carousel.is-prev svg {
    position: absolute;
    left: -16px;
    top: 80px;
    z-index: -1; }
  section.screwed-slider .shop-carousel.is-prev .item-bg p {
    padding-left: 20px;
    color: #FFFFFF;
    font-weight: 500; }
  section.screwed-slider .shop-carousel.is-prev .item-bg .main-img {
    position: relative;
    min-width: 86px; }
    section.screwed-slider .shop-carousel.is-prev .item-bg .main-img img {
      max-height: 100px;
      position: absolute;
      right: -56px;
      top: 20px; }
  section.screwed-slider .shop-carousel.is-next svg {
    position: absolute;
    right: -16px;
    top: 80px;
    z-index: -1; }
  section.screwed-slider .shop-carousel.is-next .item-bg p {
    padding-right: 20px;
    color: #FFFFFF;
    font-weight: 500; }
  section.screwed-slider .shop-carousel.is-next .item-bg .main-img {
    position: relative;
    min-width: 86px; }
    section.screwed-slider .shop-carousel.is-next .item-bg .main-img img {
      max-height: 100px;
      position: absolute;
      right: -56px;
      top: 20px; }
  section.screwed-slider .shop-carousel.is-prev, section.screwed-slider .shop-carousel.is-next {
    box-shadow: none;
    background: none; }
    section.screwed-slider .shop-carousel.is-prev .slick-list, section.screwed-slider .shop-carousel.is-next .slick-list {
      overflow: visible; }
      section.screwed-slider .shop-carousel.is-prev .slick-list .slick-slide, section.screwed-slider .shop-carousel.is-next .slick-list .slick-slide {
        padding: 0; }
        section.screwed-slider .shop-carousel.is-prev .slick-list .slick-slide > div, section.screwed-slider .shop-carousel.is-next .slick-list .slick-slide > div {
          padding: 0; }

@media screen and (min-width: 960px) and (max-width: 1300px) {
  section.screwed-slider {
    padding-top: 30px; } }

@media screen and (max-width: 960px) {
  section.screwed-slider {
    margin: 0;
    padding: 0 0 150px 0; }
    section.screwed-slider .carousen-container {
      display: none; }
    section.screwed-slider .center-carousen {
      display: block; }
      section.screwed-slider .center-carousen .slick-list {
        margin: 0; }
      section.screwed-slider .center-carousen .rectangle {
        display: none; }
    section.screwed-slider a.shop-carousel-item .item-bg {
      max-width: none;
      max-width: initial;
      padding: 36px 60px;
      margin: 0;
      background: #FFFFFF; }
      section.screwed-slider a.shop-carousel-item .item-bg svg {
        right: -220px; }
      section.screwed-slider a.shop-carousel-item .item-bg .main-img {
        right: -100px; }
        section.screwed-slider a.shop-carousel-item .item-bg .main-img img {
          height: 100%; } }

@media screen and (max-width: 600px) {
  section.screwed-slider {
    margin: 0;
    padding: 0 0 150px 0; }
    section.screwed-slider a.shop-carousel-item .item-bg .main-img {
      top: auto;
      top: initial;
      bottom: 0;
      right: -70px; }
      section.screwed-slider a.shop-carousel-item .item-bg .main-img img {
        height: 220px; }
    section.screwed-slider a.shop-carousel-item .item-bg h2 {
      margin-bottom: 6px;
      padding-right: 36px; }
    section.screwed-slider a.shop-carousel-item .item-bg .subtitle, section.screwed-slider a.shop-carousel-item .item-bg .fui-card.box .fui-card-content .fui-card-title, .fui-card.box .fui-card-content section.screwed-slider a.shop-carousel-item .item-bg .fui-card-title {
      padding-right: 36px; }
      section.screwed-slider a.shop-carousel-item .item-bg .subtitle img, section.screwed-slider a.shop-carousel-item .item-bg .fui-card.box .fui-card-content .fui-card-title img, .fui-card.box .fui-card-content section.screwed-slider a.shop-carousel-item .item-bg .fui-card-title img {
        display: none; }
      section.screwed-slider a.shop-carousel-item .item-bg .subtitle h5, section.screwed-slider a.shop-carousel-item .item-bg .fui-card.box .fui-card-content .fui-card-title h5, .fui-card.box .fui-card-content section.screwed-slider a.shop-carousel-item .item-bg .fui-card-title h5 {
        font-size: 18px;
        line-height: 1.5;
        font-weight: 400; }
    section.screwed-slider .carousen-container .slick-slide {
      padding-right: 0; }
    section.screwed-slider .is-main .slick-dots {
      padding: 0 15px;
      top: 286px; } }

section.fui-banner.is-solution {
  padding: 0;
  position: relative;
  min-height: 0;
  height: 450px;
  overflow: hidden;
  z-index: 0; }
  section.fui-banner.is-solution img {
    position: absolute;
    width: 100%;
    height: auto;
    z-index: 0;
    left: -100%;
    right: -100%;
    top: -100%;
    bottom: -100%;
    margin: auto; }
  section.fui-banner.is-solution .caption {
    position: absolute;
    width: 100%;
    color: #FFFFFF;
    bottom: 68px;
    height: 270px;
    z-index: 7;
    top: 106px; }
    section.fui-banner.is-solution .caption .fui-container {
      padding: 0 16px; }
    section.fui-banner.is-solution .caption h1 {
      font-size: 28px;
      line-height: 42px;
      max-width: 700px; }
    section.fui-banner.is-solution .caption .with-quote::before {
      content: "";
      float: left;
      width: 100%;
      height: 36px;
      background: url(/DigService/resources/common/images/quotation.png) no-repeat left top;
      background-size: 36px;
      margin-bottom: 12px; }
  @media screen and (max-width: 959px) and (min-width: 600px) {
    section.fui-banner.is-solution img {
      height: 100%;
      width: auto; } }
  @media screen and (min-width: 960px) {
    section.fui-banner.is-solution .caption {
      top: 120px; }
      section.fui-banner.is-solution .caption h1 {
        font-size: 42px;
        line-height: 63px; } }
  @media screen and (min-width: 960px) and (max-width: 1400px) {
    section.fui-banner.is-solution img {
      width: auto;
      height: 100%; } }
  @media screen and (min-width: 1400px) {
    section.fui-banner.is-solution img {
      width: auto;
      height: 100%; } }

.horizontal-cards.slick-slider {
  position: relative;
  margin: 36px 0 30px; }
  .horizontal-cards.slick-slider .fui-card-subtitle {
    color: #8A8A8A; }
  .horizontal-cards.slick-slider.rounded-arrow .slick-arrow.slick-prev, .horizontal-cards.slick-slider.rounded-arrow .slick-arrow.slick-next {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #FFFFFF;
    box-shadow: 0 18px 36px 0 rgba(75, 88, 105, 0.24);
    top: 180px;
    z-index: 1; }
  .horizontal-cards.slick-slider.rounded-arrow .slick-arrow.slick-prev {
    left: -25px; }
  .horizontal-cards.slick-slider.rounded-arrow .slick-arrow.slick-next {
    right: -25px; }
  .horizontal-cards.slick-slider.rounded-arrow .slick-disabled {
    display: none !important; }
  .horizontal-cards.slick-slider.rounded-arrow button::after {
    font-size: 28px !important; }
  .horizontal-cards.slick-slider .fui-card.is-card-program {
    padding: 30px 20px;
    background-color: #FFFFFF;
    display: flex !important;
    flex-direction: column;
    align-items: center;
    position: relative; }
    .horizontal-cards.slick-slider .fui-card.is-card-program .tag {
      position: absolute;
      left: 0;
      top: 0;
      color: #FFFFFF;
      font-size: 14px;
      padding: 4px 12px; }
      .horizontal-cards.slick-slider .fui-card.is-card-program .tag::after {
        content: "";
        width: 0;
        height: 0;
        border-right: 28px solid transparent;
        position: absolute;
        right: -28px;
        bottom: 0; }
    .horizontal-cards.slick-slider .fui-card.is-card-program .tag-red {
      background-color: #FC7B66; }
      .horizontal-cards.slick-slider .fui-card.is-card-program .tag-red::after {
        border-top: 28px solid #FC7B66;
        top: 0px; }
    .horizontal-cards.slick-slider .fui-card.is-card-program .tag-blue {
      background-color: #85D1DC; }
      .horizontal-cards.slick-slider .fui-card.is-card-program .tag-blue::after {
        border-top: 28px solid #85D1DC;
        top: 0px; }
    .horizontal-cards.slick-slider .fui-card.is-card-program .tag-gray {
      background-color: #eff4f1;
      color: #202020; }
      .horizontal-cards.slick-slider .fui-card.is-card-program .tag-gray::after {
        border-top: 28px solid #eff4f1;
        top: 0px; }
    .horizontal-cards.slick-slider .fui-card.is-card-program img {
      margin-top: 34px;
      margin-bottom: 30px;
      width: 100%; }
    .horizontal-cards.slick-slider .fui-card.is-card-program ul {
      list-style: none;
      padding-left: 0; }
      .horizontal-cards.slick-slider .fui-card.is-card-program ul li {
        display: flex;
        align-items: center; }
      .horizontal-cards.slick-slider .fui-card.is-card-program ul i {
        margin-right: 10px; }
    .horizontal-cards.slick-slider .fui-card.is-card-program .form-group {
      padding: 0 30px;
      left: 0;
      right: 0;
      align-items: center; }
      .horizontal-cards.slick-slider .fui-card.is-card-program .form-group.two-buttons {
        margin-bottom: 0;
        width: 100%;
        display: flex;
        flex-direction: row;
        margin-top: 20px;
        left: 0; }
        .horizontal-cards.slick-slider .fui-card.is-card-program .form-group.two-buttons a.fui-button {
          width: 100%;
          min-width: 0;
          min-width: initial;
          margin-bottom: 0;
          margin-right: 12px; }
        .horizontal-cards.slick-slider .fui-card.is-card-program .form-group.two-buttons a:nth-last-child(1) {
          margin-right: 0; }
  .horizontal-cards.slick-slider .slick-arrow {
    cursor: pointer; }
    .horizontal-cards.slick-slider .slick-arrow.slick-prev, .horizontal-cards.slick-slider .slick-arrow.slick-next {
      position: absolute;
      right: 0;
      top: -66px;
      border: 0;
      background: none;
      width: 36px;
      height: 36px;
      color: transparent; }
      .horizontal-cards.slick-slider .slick-arrow.slick-prev.slick-disabled::after, .horizontal-cards.slick-slider .slick-arrow.slick-next.slick-disabled::after {
        color: #D6D6D6; }
      .horizontal-cards.slick-slider .slick-arrow.slick-prev::after, .horizontal-cards.slick-slider .slick-arrow.slick-next::after {
        position: absolute;
        font-family: "icomoon" !important;
        speak: none;
        color: #202020;
        width: 28px;
        height: 28px;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        font-size: 28px;
        font-style: normal;
        font-weight: normal;
        font-feature-settings: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1; }
    .horizontal-cards.slick-slider .slick-arrow.slick-prev {
      right: 40px; }
      .horizontal-cards.slick-slider .slick-arrow.slick-prev::after {
        content: ""; }
    .horizontal-cards.slick-slider .slick-arrow.slick-next::after {
      content: ""; }
  .horizontal-cards.slick-slider .slick-list {
    overflow: visible;
    margin: -8px; }
    .horizontal-cards.slick-slider .slick-list .slick-track {
      display: flex; }
    .horizontal-cards.slick-slider .slick-list .slick-slide {
      float: none;
      display: flex;
      outline: 0;
      padding: 0 6px;
      height: auto;
      width: 302px; }
      .horizontal-cards.slick-slider .slick-list .slick-slide > div {
        display: flex;
        flex: 1 1; }
      .horizontal-cards.slick-slider .slick-list .slick-slide .fui-card {
        display: flex;
        min-width: 0;
        width: 100%;
        margin: 0; }
        .horizontal-cards.slick-slider .slick-list .slick-slide .fui-card .fui-card-description {
          -webkit-line-clamp: 3;
          height: auto; }
    @media screen and (max-width: 960px) and (min-width: 768px) {
      .horizontal-cards.slick-slider .slick-list .slick-slide {
        width: calc( 100vw/3 - 4px); } }
  @media screen and (min-width: 768px) {
    .horizontal-cards.slick-slider .slick-arrow.slick-prev {
      right: 66px; } }

.shop-carousel.is-prev, .shop-carousel.is-next {
  display: none; }

.shop-carousel .slick-arrow {
  position: absolute;
  width: 50%;
  height: 100%;
  top: 0;
  opacity: 0;
  display: block;
  z-index: 2; }
  .shop-carousel .slick-arrow.slick-prev {
    left: -50%; }
  .shop-carousel .slick-arrow.slick-next {
    right: -50%; }

.shop-carousel .shop-carousel-item {
  outline: none; }
  .shop-carousel .shop-carousel-item > div {
    display: block;
    text-align: center; }
    .shop-carousel .shop-carousel-item > div img.logo {
      margin: auto; }
  .shop-carousel .shop-carousel-item .shop-name {
    margin: 24px 0 0;
    line-height: 1.75; }

.shop-carousel.is-main {
  margin-left: -15px;
  margin-right: -15px;
  z-index: 9;
  background-color: #0A3476;
  background-image: url(/DigService/resources/common/images/patternd-blue-shop.png);
  background-repeat: no-repeat;
  background-size: 100% auto; }
  .shop-carousel.is-main .slick-slide > div {
    display: flex; }
  .shop-carousel.is-main .shop-carousel-item {
    background: none;
    display: flex !important; }
    .shop-carousel.is-main .shop-carousel-item > div {
      color: white;
      padding: 30px 30px 85px; }
      .shop-carousel.is-main .shop-carousel-item > div .logo {
        width: auto;
        max-height: 90px; }
        .shop-carousel.is-main .shop-carousel-item > div .logo + h6 {
          margin: 24px 0 12px; }
      .shop-carousel.is-main .shop-carousel-item > div .shop-name {
        margin-top: 12px; }

.shop-carousel .slick-dots {
  position: absolute;
  display: block;
  text-align: center;
  list-style: none;
  width: 100%;
  bottom: 40px;
  margin: 0;
  padding: 0; }
  .shop-carousel .slick-dots li {
    display: inline-block;
    width: 40px;
    height: 3px;
    background: #202020;
    margin: 0 3px; }
    .shop-carousel .slick-dots li.slick-active {
      background: white; }
    .shop-carousel .slick-dots li button {
      opacity: 0; }

@media screen and (min-width: 960px) {
  .shop-carousel.is-main {
    margin-left: 0;
    margin-right: 0; }
    .shop-carousel.is-main .shop-carousel-item > div {
      padding: 48px 30px 115px; }
      .shop-carousel.is-main .shop-carousel-item > div .logo {
        width: auto;
        max-height: 160px; }
        .shop-carousel.is-main .shop-carousel-item > div .logo + h6 {
          margin-top: 16px; }
      .shop-carousel.is-main .shop-carousel-item > div .shop-name {
        margin: 24px 0 0; }
  .shop-carousel.is-prev, .shop-carousel.is-next {
    display: block;
    height: 100%;
    box-shadow: 0 24px 48px 0 rgba(75, 88, 105, 0.12);
    background: #FFF; }
    .shop-carousel.is-prev .slick-list, .shop-carousel.is-next .slick-list {
      height: 100%; }
      .shop-carousel.is-prev .slick-list .slick-track, .shop-carousel.is-next .slick-list .slick-track {
        display: flex;
        height: 100%; }
        .shop-carousel.is-prev .slick-list .slick-track .slick-slide, .shop-carousel.is-next .slick-list .slick-track .slick-slide {
          display: flex; }
          .shop-carousel.is-prev .slick-list .slick-track .slick-slide > div, .shop-carousel.is-next .slick-list .slick-track .slick-slide > div {
            display: flex;
            flex: 1 1 auto;
            align-content: center;
            align-items: center;
            padding: 0 30px; } }

.carousel-banner-4 {
  height: 680px;
  width: 100%; }
  .carousel-banner-4 .fullscreen {
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1000;
    width: 50px;
    height: 50px;
    background-color: #fff; }
  .carousel-banner-4 .slide {
    position: relative;
    height: 680px;
    width: 100%; }
    .carousel-banner-4 .slide .background {
      width: 100%;
      max-height: 680px;
      position: absolute;
      z-index: 0;
      height: 100%;
      top: 0;
      background-position: center;
      background-size: cover; }
    .carousel-banner-4 .slide .background-mb {
      display: none;
      width: 100%;
      background-position: center;
      background-size: cover; }
    .carousel-banner-4 .slide .img-reveal {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      z-index: -1; }
  .carousel-banner-4 .content-wrapper {
    padding: 30px; }
  .carousel-banner-4 .content {
    margin: 118px 0 0 0;
    z-index: 2;
    background: #fff;
    width: 100%;
    height: 443px;
    position: relative;
    display: flex; }
    .carousel-banner-4 .content h1 {
      margin: 0 0 12px 0; }
    .carousel-banner-4 .content h5 {
      margin: 0;
      font-weight: normal; }
    .carousel-banner-4 .content .main-img {
      position: relative;
      width: 40%;
      overflow: hidden;
      order: 1;
      min-height: 443px; }
      .carousel-banner-4 .content .main-img img {
        position: absolute;
        width: auto;
        height: 444px;
        left: -100%;
        right: -100%;
        top: -100%;
        bottom: -100%;
        margin: auto; }
    .carousel-banner-4 .content .content-info {
      width: 60%;
      order: 0;
      padding: 48px 36px; }
    .carousel-banner-4 .content .head {
      color: #8A8A8A;
      margin-bottom: 12px;
      font-weight: bold; }
    .carousel-banner-4 .content .content-text {
      height: 116px; }
    .carousel-banner-4 .content .social {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      width: 100%;
      justify-content: flex-end; }
      .carousel-banner-4 .content .social .view {
        color: #8A8A8A;
        display: flex;
        align-items: center; }
      .carousel-banner-4 .content .social a {
        color: #000; }
      .carousel-banner-4 .content .social i {
        font-size: 30px;
        margin-left: 6px; }
    .carousel-banner-4 .content .bottom {
      display: flex;
      height: 50px;
      align-items: center; }
      .carousel-banner-4 .content .bottom a {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px; }
      .carousel-banner-4 .content .bottom button.fui-button.is-arrow,
      .carousel-banner-4 .content .bottom button.fui-button.is-arrow:hover,
      .carousel-banner-4 .content .bottom button.fui-button.is-arrow:active {
        color: transparent; }
      .carousel-banner-4 .content .bottom button:not(.fui-button.is-arrow) {
        background-color: #D3060F;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px; }
      .carousel-banner-4 .content .bottom button.is-video span {
        opacity: 0; }
      .carousel-banner-4 .content .bottom button.is-video:hover span {
        color: #EF3123;
        opacity: 1; }
  .carousel-banner-4 .mb-img-reveal {
    display: none;
    cursor: pointer; }
  .carousel-banner-4 .left-triangle {
    width: 0;
    height: 0;
    border-bottom: 115px solid #0F3B81;
    border-right: 155px solid transparent;
    position: absolute;
    left: 15px;
    bottom: 0px;
    z-index: 1; }
  .carousel-banner-4 .right-triangle {
    width: 0;
    height: 0;
    border-top: 115px solid #0F3B81;
    border-left: 155px solid transparent;
    position: absolute;
    right: 15px;
    top: 120px;
    z-index: 1; }
  .carousel-banner-4 .slick-dots {
    position: absolute;
    bottom: 30px;
    z-index: 1;
    list-style: none;
    margin: 0 auto;
    padding: 0;
    left: 0;
    right: 0;
    text-align: center; }
    .carousel-banner-4 .slick-dots .slick-active button {
      background-color: #fff; }
    .carousel-banner-4 .slick-dots li {
      display: inline-block;
      margin-right: 6px; }
      .carousel-banner-4 .slick-dots li:nth-last-child(1) {
        margin-right: 0; }
      .carousel-banner-4 .slick-dots li button {
        background-color: #000;
        cursor: pointer;
        width: 50px;
        height: 4px;
        border: 0;
        vertical-align: top; }
  .carousel-banner-4 .yt-wrapper {
    width: 100%;
    z-index: -10;
    position: absolute; }

.slick-slider .slick-dots li {
  cursor: pointer; }

.carousel-banner-4 .content {
  height: auto; }
  .carousel-banner-4 .content .content-info {
    height: auto; }
    .carousel-banner-4 .content .content-info h1 {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: block;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      white-space: normal;
      -ms-text-overflow: ellipsis; }
    .carousel-banner-4 .content .content-info .content-text {
      height: auto;
      margin-bottom: 12px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: block;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      white-space: normal;
      -ms-text-overflow: ellipsis; }
  .carousel-banner-4 .content .main-img img {
    width: auto;
    height: 100%; }

@media screen and (max-width: 960px) {
  .carousel-banner-4 {
    height: auto; }
    .carousel-banner-4 .left-triangle, .carousel-banner-4 .right-triangle {
      display: none; }
    .carousel-banner-4 .background {
      display: none; }
    .carousel-banner-4 .content-wrapper {
      padding: 0; }
    .carousel-banner-4 .slide {
      height: 100%;
      padding-bottom: 94px;
      padding-top: 20px;
      margin-top: -20px; }
      .carousel-banner-4 .slide .position-relative {
        position: relative; }
      .carousel-banner-4 .slide .main-img {
        display: none; }
      .carousel-banner-4 .slide .mb-img {
        position: relative; }
      .carousel-banner-4 .slide .content-info {
        width: 100%;
        padding: 0; }
        .carousel-banner-4 .slide .content-info h1 {
          margin-bottom: 0; }
        .carousel-banner-4 .slide .content-info h5 {
          color: #5f6e84; }
      .carousel-banner-4 .slide .slick-dots {
        bottom: 40px; }
      .carousel-banner-4 .slide .play {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center; }
        .carousel-banner-4 .slide .play .round {
          width: 60px;
          height: 60px;
          box-shadow: 0 11px 48px 0 rgba(32, 32, 32, 0.3);
          z-index: 2;
          opacity: 0.84;
          background-color: #fff;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          pointer-events: none; }
          .carousel-banner-4 .slide .play .round::after {
            content: "";
            width: 0;
            height: 0;
            border-style: solid;
            margin-right: -6px;
            border-width: 12px 0 12px 16px;
            border-color: transparent transparent transparent #d30610; }
      .carousel-banner-4 .slide .mb-img-reveal {
        width: 100%;
        z-index: 1;
        position: relative;
        margin-top: 30px;
        display: block; }
      .carousel-banner-4 .slide .img-reveal {
        display: none; }
      .carousel-banner-4 .slide .background-mb {
        display: block;
        position: absolute;
        top: 0;
        z-index: 0;
        height: 100%; }
    .carousel-banner-4 .content {
      margin-top: 0;
      padding: 12px 12px 18px 12px;
      width: 100%;
      height: 282px; }
      .carousel-banner-4 .content .content-text {
        display: none; }
      .carousel-banner-4 .content .head {
        font-size: 16px;
        margin-bottom: 3px; }
      .carousel-banner-4 .content h1 {
        font-size: 22px;
        line-height: 1.5;
        letter-spacing: 0.3px; }
  .horizontal-cards.slick-slider.rounded-arrow button.slick-arrow.slick-next {
    width: 40px;
    height: 40px;
    box-shadow: 0 12px 24px 0 rgba(32, 32, 32, 0.3);
    right: 0; }
    .horizontal-cards.slick-slider.rounded-arrow button.slick-arrow.slick-next::after {
      font-size: 18px !important;
      right: -5px;
      top: 8px;
      font-weight: bold; }
  .horizontal-cards.slick-slider.rounded-arrow button.slick-arrow.slick-prev {
    width: 40px;
    height: 40px;
    left: 0;
    box-shadow: 0 12px 24px 0 rgba(32, 32, 32, 0.3); }
    .horizontal-cards.slick-slider.rounded-arrow button.slick-arrow.slick-prev::after {
      font-size: 18px !important;
      left: -5px;
      top: 8px;
      font-weight: bold; } }

@media all and (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
  .fui-tab {
    -ms-overflow-style: none;
    overflow: auto; }
    .fui-tab::-webkit-scrollbar {
      display: none; } }

.fui-container .fui-marquee-box {
  margin-left: -15px;
  margin-right: -15px;
  width: calc(100% + 30px); }

.fui-marquee-box {
  width: 100%;
  height: 150px;
  position: relative;
  top: 0;
  left: 0;
  overflow: hidden; }

.fui-marquee-box .marquee-landscape-wrap {
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center; }

.fui-marquee-box .marquee-landscape-wrap.no-marquee > .marquee-landscape-item {
  margin: auto;
  float: none; }

.fui-marquee-box .marquee-landscape-wrap .marquee-landscape-item {
  float: left;
  height: 100%;
  white-space: nowrap; }

.fui-marquee-box .marquee-landscape-wrap .marquee-landscape-item .marquee-landscape-txt {
  padding: 0 10px;
  white-space: nowrap;
  display: inline-flex;
  height: 100%;
  align-content: center;
  align-items: center; }
  .fui-marquee-box .marquee-landscape-wrap .marquee-landscape-item .marquee-landscape-txt img {
    vertical-align: middle;
    height: 120px;
    opacity: 0.5; }

.fui-marquee-box .marquee-vertical-wrap {
  width: 100%;
  height: 100%;
  overflow: hidden; }

.fui-marquee-box .marquee-vertical-wrap .marquee-vertical-item .marquee-vertical-txt {
  height: 60px;
  line-height: 60px; }

@media screen and (min-width: 768px) {
  .fui-marquee-box .marquee-landscape-wrap .marquee-landscape-item {
    float: none;
    margin: auto; }
    .fui-marquee-box .marquee-landscape-wrap .marquee-landscape-item + .marquee-landscape-item {
      display: none; } }

.avatar {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 60px;
  overflow: hidden;
  border-radius: 30px; }
  .avatar img {
    position: absolute;
    left: -100%;
    right: -100%;
    top: -100%;
    bottom: -100%;
    width: 100%;
    margin: auto; }

.emma-service {
  position: fixed;
  right: 24px;
  bottom: 24px;
  z-index: 30; }
  .emma-service * {
    box-sizing: border-box; }
  .emma-service .iframe-container {
    position: fixed;
    width: 0px;
    height: 0px;
    bottom: 0;
    right: 0;
    z-index: 29;
    border-radius: 6px 6px 0 0;
    overflow: hidden;
    opacity: 0;
    box-shadow: 0 12px 24px 0 rgba(75, 88, 105, 0.3);
    transition: all 0.3s ease; }
    .emma-service .iframe-container .iframe-header {
      position: absolute;
      background: #202020 !important;
      color: #FFFFFF;
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-content: center;
      align-items: center;
      height: 56px;
      padding: 0 24px;
      z-index: 2; }
    .emma-service .iframe-container iframe {
      position: absolute;
      top: 0;
      left: 0;
      border: 0;
      width: 100%;
      height: 100%;
      margin: 0;
      z-index: 0;
      border-radius: 6px; }
  .emma-service.iframe-open {
    transform: none !important;
    z-index: 40; }
    .emma-service.iframe-open .iframe-container {
      opacity: 1;
      width: 100%;
      height: calc(100% - 50px);
      max-height: 600px; }
      @media screen and (max-width: 959px) {
        .emma-service.iframe-open .iframe-container {
          max-height: none; } }
  @media screen and (max-width: 959px) {
    .fui-go-top.is-show + .emma-service.iframe-open .iframe-container {
      width: 100vw;
      height: calc(100vh - 50px);
      transform: translate(24px, 78px); } }
  .emma-service .emma-service-button {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 46px;
    height: 46px;
    border-radius: 30px;
    background: #FFFFFF;
    box-shadow: 0 14px 28px 0 rgba(32, 32, 32, 0.3);
    z-index: 19;
    cursor: pointer;
    overflow: hidden;
    transition: width 0.3s ease; }
    .emma-service .emma-service-button .text {
      color: #202020 !important; }
  .emma-service a {
    display: block; }
    .emma-service a .text {
      opacity: 0; }
  .emma-service .emma-service-button:hover,
  .emma-service.is-open .emma-service-button {
    width: 124px; }
    .emma-service .emma-service-button:hover .avatar,
    .emma-service.is-open .emma-service-button .avatar {
      border-radius: 0 30px 30px 0; }
    .emma-service .emma-service-button:hover .text,
    .emma-service.is-open .emma-service-button .text {
      font-size: 1rem;
      color: #2F59C4;
      transition: all 0.3s ease;
      opacity: 1; }
  .emma-service.is-inline {
    position: relative;
    display: inline-block;
    transition: none;
    right: auto;
    bottom: auto; }
  .emma-service .icon,
  .emma-service .avatar {
    width: 46px;
    height: 46px; }
  .emma-service .icon {
    float: left;
    text-align: center;
    line-height: 46px; }
    .emma-service .icon * {
      vertical-align: middle; }
  .emma-service .avatar {
    float: right; }
  .emma-service .text {
    display: block;
    font-weight: 500;
    height: 46px;
    line-height: 46px;
    padding-left: 12px; }

.is-en .emma-service .emma-service-button:hover,
.is-en .emma-service.is-open .emma-service-button {
  width: 180px; }

@media screen and (min-width: 960px) {
  .is-ebu .emma-service.is-open .iframe-container,
  .emma-service .iframe-container {
    position: absolute;
    top: auto;
    bottom: 66px;
    border-radius: 6px; }
  .is-ebu .emma-service.is-open .emma-service-button,
  .emma-service .emma-service-button {
    width: 54px;
    height: 54px;
    border-radius: 35px; }
  .is-ebu .emma-service.is-open.iframe-open .iframe-container,
  .emma-service.iframe-open .iframe-container {
    width: 378px;
    height: calc(100vh - 100px);
    max-height: 600px; }
  .fui-go-top.is-show + .emma-service.iframe-open .iframe-container {
    height: calc(100vh - 180px); }
  .is-ebu .emma-service .emma-service-button:hover,
  .is-ebu .emma-service.is-open .emma-service-button,
  .emma-service .emma-service-button:hover,
  .emma-service.is-open .emma-service-button {
    width: 140px; }
  .is-ebu .emma-service.is-ebu.is-open .emma-service-button,
  .is-ebu .emma-service.is-help-center.is-open .emma-service-button,
  .emma-service.is-ebu.is-open .emma-service-button,
  .emma-service.is-help-center.is-open .emma-service-button {
    width: 140px; }
    .is-ebu .emma-service.is-ebu.is-open .emma-service-button .icon,
    .is-ebu .emma-service.is-help-center.is-open .emma-service-button .icon,
    .emma-service.is-ebu.is-open .emma-service-button .icon,
    .emma-service.is-help-center.is-open .emma-service-button .icon {
      width: 54px; }
  .is-ebu .emma-service .icon,
  .emma-service .icon {
    line-height: 54px; }
  .is-ebu .emma-service .icon,
  .is-ebu .emma-service .avatar,
  .emma-service .icon,
  .emma-service .avatar {
    width: 54px;
    height: 54px; }
  .is-ebu .emma-service .text,
  .emma-service .text {
    height: 54px;
    line-height: 54px;
    padding-left: 12px; }
  .is-ebu.is-en .emma-service .emma-service-button:hover,
  .is-ebu.is-en .emma-service.is-open .emma-service-button {
    width: 190px; } }

.fui-nav-anchor.is-fixed + section.fui-breadcrumb-container {
  top: -45px; }

section.fui-breadcrumb-container {
  display: none;
  width: 100vw;
  padding: 30px 0 24px;
  margin: 0;
  background: none !important; }
  section.fui-breadcrumb-container ul.fui-breadcrumb {
    margin: 0;
    padding: 0;
    list-style: none; }
    section.fui-breadcrumb-container ul.fui-breadcrumb li {
      position: relative;
      display: inline-block; }
      section.fui-breadcrumb-container ul.fui-breadcrumb li::after {
        content: "";
        position: absolute;
        right: 0;
        top: 2px;
        bottom: 0;
        margin: auto;
        width: 1px;
        height: 17.6px;
        display: block;
        background: #202020;
        transform: rotate(40deg); }
      section.fui-breadcrumb-container ul.fui-breadcrumb li a {
        color: #202020;
        font-size: 0.875rem;
        font-weight: 300;
        padding: 0 16px; }
        section.fui-breadcrumb-container ul.fui-breadcrumb li a::after {
          display: none; }
      section.fui-breadcrumb-container ul.fui-breadcrumb li:last-child > {
        line-height: 0; }
        section.fui-breadcrumb-container ul.fui-breadcrumb li:last-child > a {
          font-weight: 500;
          display: inline-block;
          overflow: hidden;
          white-space: nowrap;
          vertical-align: middle; }
      section.fui-breadcrumb-container ul.fui-breadcrumb li:last-child::after {
        display: none; }
      section.fui-breadcrumb-container ul.fui-breadcrumb li:first-child > a {
        padding-left: 0; }
    section.fui-breadcrumb-container ul.fui-breadcrumb.is-white li::after {
      background: #FFFFFF; }
    section.fui-breadcrumb-container ul.fui-breadcrumb.is-white li a {
      color: #FFFFFF; }

.fui-nav-tab + .fui-breadcrumb-container,
.fui-nav-anchor + .fui-breadcrumb-container {
  top: 45px; }

.is-help-center header.fui-header ~ main .fui-nav-anchor + .fui-breadcrumb-container {
  top: 105px; }

@media screen and (min-width: 960px) {
  section.fui-breadcrumb-container {
    display: block;
    position: absolute;
    z-index: 19;
    top: 0; } }

.selected-article {
  margin-bottom: 12px; }
  .selected-article .head {
    background-image: url(/DigService/resources/cbu/product/images/bg-pattern.png);
    width: 100%;
    height: 60px;
    background-size: cover;
    padding: 15px; }
    .selected-article .head h6 {
      color: #fff;
      margin: 0;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: block;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      white-space: normal;
      -ms-text-overflow: ellipsis; }
  .selected-article .top {
    color: #5F6E84; }
    .selected-article .top small {
      margin-right: 6px;
      font-weight: 500; }
    .selected-article .top small:nth-child(2) {
      color: #8A8A8A; }
  .selected-article .body {
    display: flex;
    flex-direction: row;
    font-weight: 500; }
    .selected-article .body p {
      flex: 1 1;
      padding-right: 8px;
      margin: 0;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: block;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      white-space: normal;
      -ms-text-overflow: ellipsis; }
  .selected-article ul {
    list-style-type: none;
    padding: 0;
    margin: 0; }
    .selected-article ul li {
      padding: 15px;
      background-color: #F0F2F5;
      border-bottom: 2px solid #EBEBEB;
      display: flex;
      align-items: center;
      cursor: pointer; }
      .selected-article ul li i {
        font-size: 24px;
        display: flex;
        align-items: center; }
      .selected-article ul li a {
        color: #202020 !important;
        margin: 0;
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-direction: column; }
        .selected-article ul li a::after {
          display: none; }
      .selected-article ul li a:visited {
        color: #202020; }
        .selected-article ul li a:visited p {
          color: #202020; }
      .selected-article ul li p {
        margin: 0; }
    .selected-article ul li:nth-last-child(1) {
      border-bottom: 0; }

@media screen and (max-width: 960px) {
  .selected-article {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
    .selected-article + .side-ad {
      margin: 0 -15px; }
  .selected-news {
    padding: 20px 0 !important;
    margin: 0 0px !important; } }

.fui-container .promotion-container {
  margin: 0 -15px; }

.section-promotion-5 {
  position: relative;
  height: 100%;
  margin: 0; }
  .section-promotion-5 .desktop > *,
  .section-promotion-5 .mobile > * {
    position: relative;
    z-index: 1; }
  .section-promotion-5 > a:link,
  .section-promotion-5 > a:visited {
    display: block;
    color: #202020 !important;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center; }
    .section-promotion-5 > a:link::after,
    .section-promotion-5 > a:visited::after {
      display: none !important; }
    .section-promotion-5 > a:link:hover,
    .section-promotion-5 > a:visited:hover {
      color: #202020 !important; }
  .section-promotion-5 .desktop {
    padding: 300px 24px 30px;
    position: relative;
    overflow: hidden;
    min-height: 700px;
    background-repeat: no-repeat;
    background-position: center top;
    background-size: contain; }
    .section-promotion-5 .desktop h3 {
      font-size: 22px;
      font-weight: 700;
      margin: 0 0 12px 0;
      line-height: 1.3;
      color: #202020; }
    .section-promotion-5 .desktop a.fui-button.is-secondary {
      border: 2px solid #333333;
      padding: 0;
      margin: 30px 0 0; }
  .section-promotion-5 h3 {
    font-size: 22px; }
  .section-promotion-5 h6 {
    margin: 0 0 12px 0;
    font-weight: normal;
    font-size: 18px;
    z-index: 2;
    position: relative; }
  .section-promotion-5 .w-100 {
    width: 100%; }
  .section-promotion-5 .mobile {
    height: 270px;
    padding: 30px 24px; }
    .section-promotion-5 .mobile .promotion-button {
      display: inline-block;
      min-width: 140px;
      color: #FFFFFF;
      border-color: #FFFFFF; }
  .section-promotion-5 .promotion-button {
    border: 2px solid #202020;
    color: #202020;
    width: 100%;
    height: 40px;
    line-height: 36px;
    text-align: center;
    margin: 16px 0 0; }
  .section-promotion-5 button {
    z-index: 2; }
  @media screen and (max-width: 959px) {
    .section-promotion-5 {
      width: 100%;
      height: auto;
      color: #FFFFFF;
      background-size: cover; }
      .section-promotion-5 .fui-button.is-secondary {
        border-color: #FFFFFF;
        color: #FFFFFF; }
        .section-promotion-5 .fui-button.is-secondary:hover {
          border-color: #FFFFFF;
          background: #FFFFFF;
          color: #4B5869; }
      .section-promotion-5::after {
        background: #202020;
        opacity: 0.3; }
      .section-promotion-5 h3 {
        margin-bottom: 15px;
        z-index: 2;
        position: relative; }
      .section-promotion-5 h6 {
        margin: 0 0 30px 0;
        font-weight: normal;
        z-index: 2;
        position: relative; }
      .section-promotion-5 .w-100 {
        width: 100%; }
      .section-promotion-5 button {
        z-index: 2; } }
  .section-promotion-5 .mobile {
    display: none; }

@media screen and (max-width: 960px) {
  .section-promotion-5 .desktop {
    display: none; }
  .section-promotion-5 .mobile {
    display: block;
    background-size: cover;
    background-position: bottom center;
    position: relative;
    padding: 20px 30px;
    color: #fff;
    overflow: hidden;
    text-align: center; }
    .section-promotion-5 .mobile h3 {
      margin: 0 0 0 0;
      color: #fff;
      font-size: 20px; }
    .section-promotion-5 .mobile h6 {
      font-weight: 500;
      font-size: 1rem;
      margin-bottom: 0;
      font-weight: 400; }
    .section-promotion-5 .mobile h4 {
      margin: 0 0 18px 0;
      position: relative; }
    .section-promotion-5 .mobile .bg-img {
      filter: brightness(0.8); }
    .section-promotion-5 .mobile img {
      width: 100%;
      height: auto;
      top: -100%;
      bottom: -100%;
      margin: auto; }
    .section-promotion-5 .mobile button {
      position: relative;
      border: 0;
      margin: 0;
      border: 2px solid #fff;
      height: 40px;
      padding: 0;
      min-width: 45%;
      width: 100%; } }

.extra-link-card {
  background-color: #fff;
  box-shadow: 0 24px 48px 0 rgba(75, 88, 105, 0.12);
  height: 100%; }
  .extra-link-card .main-body {
    height: 100%;
    display: flex; }
  .extra-link-card .img-wrapper {
    height: 100%;
    position: relative; }
    .extra-link-card .img-wrapper .periotic-pattern {
      position: absolute;
      left: 0;
      bottom: 0;
      width: auto;
      width: initial; }
    .extra-link-card .img-wrapper .periodic-content {
      position: absolute;
      left: 0;
      bottom: 0; }
      .extra-link-card .img-wrapper .periodic-content h1 {
        color: #9EDFE9;
        display: inline-block;
        font-size: 36px;
        margin: 0 0 -8px 16px; }
      .extra-link-card .img-wrapper .periodic-content .period {
        color: #9EDFE9;
        display: inline-block;
        font-size: 12px;
        margin: 0; }
      .extra-link-card .img-wrapper .periodic-content .date {
        color: #fff;
        font-size: 12px;
        line-height: 12px;
        margin: 0 0 16px 16px; }
    .extra-link-card .img-wrapper .main-bg {
      display: flex;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center center; }
  .extra-link-card h4 {
    margin: 0 0 18px 0; }
  .extra-link-card .card-bottom {
    display: flex; }
    .extra-link-card .card-bottom a {
      width: 80px; }
    .extra-link-card .card-bottom a.is-arrow span {
      color: #EF3123; }
  .extra-link-card .card-wrapper {
    height: 100%;
    background: #fff; }
    .extra-link-card .card-wrapper .card-body {
      padding: 18px 18px 20px 18px;
      border-bottom: 2px solid #EBEBEB; }
  .extra-link-card .social {
    display: flex;
    align-items: center;
    font-size: 14px;
    width: 100%;
    justify-content: flex-end; }
    .extra-link-card .social .view {
      color: #8A8A8A;
      display: flex;
      align-items: center; }
    .extra-link-card .social i {
      font-size: 30px;
      margin-left: 6px;
      cursor: pointer; }
  .extra-link-card .card-list {
    list-style-type: none;
    padding: 0;
    margin: 0; }
    .extra-link-card .card-list li {
      padding: 15px;
      background-color: #fff;
      border-bottom: 2px solid #EBEBEB;
      display: flex;
      align-items: center;
      cursor: pointer; }
      .extra-link-card .card-list li a:link,
      .extra-link-card .card-list li a:visited {
        color: #202020; }
        .extra-link-card .card-list li a:link::hover, .extra-link-card .card-list li a:link::focus,
        .extra-link-card .card-list li a:visited::hover,
        .extra-link-card .card-list li a:visited::focus {
          color: #202020; }
        .extra-link-card .card-list li a:link::after,
        .extra-link-card .card-list li a:visited::after {
          display: none !important; }
      .extra-link-card .card-list li i {
        font-size: 24px;
        display: inline-block; }
      .extra-link-card .card-list li p {
        margin: 0;
        width: 100%;
        display: inline-block; }
    .extra-link-card .card-list li:nth-last-child(1) {
      border-bottom: 0; }

.extra-link-card.flex-column {
  flex-direction: column;
  display: flex; }
  .extra-link-card.flex-column .vertical {
    display: none; }
  .extra-link-card.flex-column .card-body h4 {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
    -ms-text-overflow: ellipsis;
    min-height: 72px;
    height: 72px; }
  .extra-link-card.flex-column .card-list a {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    white-space: normal;
    -ms-text-overflow: ellipsis;
    height: 22px; }

.extra-link-card.flex-row {
  flex-direction: row;
  display: flex; }
  .extra-link-card.flex-row .horizontal {
    display: none; }
  .extra-link-card.flex-row .card-body {
    height: auto;
    height: initial; }
    .extra-link-card.flex-row .card-body h4 {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: block;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      white-space: normal;
      -ms-text-overflow: ellipsis;
      min-height: 72px;
      height: 72px; }
  .extra-link-card.flex-row .img-wrapper {
    width: 50%; }
  .extra-link-card.flex-row .card-wrapper {
    width: 50%;
    background-color: #fff; }
  .extra-link-card.flex-row .card-list a {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    white-space: normal;
    -ms-text-overflow: ellipsis; }
    .extra-link-card.flex-row .card-list a::after {
      display: none; }

@media screen and (max-width: 960px) {
  .extra-link-card.flex-row .card-wrapper {
    width: 100%; }
  .extra-link-card.flex-column {
    height: auto; }
    .extra-link-card.flex-column .card-body {
      height: auto; }
    .extra-link-card.flex-column .img-wrapper {
      height: 310px; } }

@media screen and (max-width: 640px) {
  .extra-link-card .img-wrapper {
    height: 194px; }
  .extra-link-card.flex-row {
    flex-direction: column !important; }
    .extra-link-card.flex-row .img-wrapper {
      width: 100%; }
    .extra-link-card.flex-row .vertical {
      display: none; }
    .extra-link-card.flex-row .horizontal {
      display: block; }
  .extra-link-card.flex-column .img-wrapper {
    height: 194px; }
  .extra-link-card.flex-column h4 {
    margin: 0; } }

.checkbox {
  position: relative;
  display: inline-block;
  margin: 32px 0;
  vertical-align: middle; }
  .checkbox.is-agreement .content {
    padding-left: 0;
    line-height: 30px;
    font-size: 0.875rem; }
    .checkbox.is-agreement .content::before {
      margin-top: -3px; }
    .checkbox.is-agreement .content::before, .checkbox.is-agreement .content::after {
      position: relative;
      float: left;
      cursor: pointer; }
    .checkbox.is-agreement .content::after {
      position: absolute;
      top: 8px;
      margin-left: -43px; }
  .checkbox input {
    position: absolute;
    width: 30px;
    height: 30px;
    margin: 0;
    opacity: 0;
    cursor: pointer;
    top: 0; }
  .checkbox input + .content {
    font-size: 0.875rem;
    font-weight: 400;
    padding-top: 5px;
    padding-left: 40px;
    position: relative; }
    .checkbox input + .content::after, .checkbox input + .content::before {
      position: absolute;
      box-sizing: border-box; }
    .checkbox input + .content::after {
      content: "";
      font-family: "icomoon" !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-feature-settings: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      font-size: 1rem;
      display: block;
      width: 10px;
      height: 10px;
      color: #FFFFFF;
      top: 8px;
      left: 8px;
      opacity: 0;
      transform: scale(0);
      transform-origin: 50%;
      transition: all 0.3s ease; }
    .checkbox input + .content::before {
      content: "";
      display: block;
      top: 0;
      left: 0;
      width: 30px;
      height: 30px;
      margin-right: 12px;
      margin-bottom: 10px;
      background: transparent;
      border: 2px solid #E8ECF0;
      transition: all 0.3s ease; }
  .checkbox input:checked + .content::before {
    background: #f05c38;
    opacity: 1;
    border-color: #f05c38; }
  .checkbox input:checked + .content::after {
    color: #FFFFFF;
    opacity: 1;
    transform: scale(1); }

.checkbox-buttons {
  margin: 16px 0;
  display: flex;
  flex-wrap: wrap; }
  .checkbox-buttons .checkbox {
    width: 100%;
    margin: 9px 0; }
  .checkbox-buttons .checkbox input + .content {
    font-size: 16px; }
  @media screen and (min-width: 960px) {
    .checkbox-buttons .checkbox {
      width: 25%; } }

.is-merge-bill > label {
  font-size: 24px;
  margin-bottom: 24px; }

.is-merge-bill .checkbox-group .checkbox.is-icon-box .content {
  padding: 18px 18px 18px 54px; }
  .is-merge-bill .checkbox-group .checkbox.is-icon-box .content .text {
    text-align: left; }

@media screen and (max-width: 960px) {
  .is-merge-bill > label {
    font-size: 22px; }
  .is-merge-bill .checkbox-group .checkbox.is-icon-box {
    width: 100%;
    margin-right: 0;
    height: 52px; }
    .is-merge-bill .checkbox-group .checkbox.is-icon-box .content {
      padding-left: 46px; } }

.checkbox-group {
  display: flex;
  flex-wrap: wrap;
  margin-right: -12px; }
  .checkbox-group .checkbox {
    position: relative;
    width: calc(50% - 12px);
    margin: 0 12px 12px 0;
    background-color: #fff; }
    .checkbox-group .checkbox input {
      position: absolute;
      opacity: 0; }
    .checkbox-group .checkbox input + .content {
      color: #5F6E84;
      padding-left: 0;
      border: solid 2px #E8ECF0;
      transition: all 0.3s ease;
      font-weight: 500; }
      .checkbox-group .checkbox input + .content::after, .checkbox-group .checkbox input + .content::before {
        position: absolute; }
      .checkbox-group .checkbox input + .content::after {
        top: -4px;
        bottom: 0;
        left: 13.6px;
        margin: auto; }
      .checkbox-group .checkbox input + .content::before {
        top: 0;
        bottom: 0;
        margin: auto;
        left: 6px; }
      .checkbox-group .checkbox input + .content .text {
        line-height: 28px;
        text-align: center; }
    .checkbox-group .checkbox input:checked + .content {
      color: #f05c38;
      border-color: #f05c38; }
    .checkbox-group .checkbox.is-icon-box {
      text-align: center;
      width: calc(50% - 6px); }
      .checkbox-group .checkbox.is-icon-box:nth-of-type(2n) {
        margin: 0 0 12px 0; }
      .checkbox-group .checkbox.is-icon-box .content {
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        line-height: 22px;
        height: 100%;
        width: 100%;
        padding: 24px 40.8px 24px; }
  @media screen and (min-width: 960px) {
    .checkbox-group .checkbox {
      width: calc(33.33333% - 12px); }
      .checkbox-group .checkbox input + .content {
        padding-left: 48px; }
        .checkbox-group .checkbox input + .content::after {
          top: 20px;
          left: 20px;
          margin: auto; }
        .checkbox-group .checkbox input + .content::before {
          top: 12px;
          left: 12px; }
      .checkbox-group .checkbox.is-icon-box .content::before {
        bottom: auto; }
      .checkbox-group .checkbox.is-icon-box .content::after {
        left: 20px;
        bottom: auto; } }

.is-ebu .checkbox input:checked + .content::before,
.is-help-center .checkbox input:checked + .content::before {
  border-color: #2F59C4;
  background: #2F59C4; }

.is-ebu .checkbox-group .checkbox input:checked + .content,
.is-help-center .checkbox-group .checkbox input:checked + .content {
  color: #2F59C4;
  border-color: #2F59C4; }

.input-with-checkbox label.checkbox {
  margin-top: 0px; }
  @media screen and (min-width: 600px) {
    .input-with-checkbox label.checkbox {
      margin-top: 55px; } }

@media screen and (max-width: 960px) {
  .checkbox-group .checkbox.is-icon-box .content {
    padding: 24px 8px 24px 40.8px; }
    .checkbox-group .checkbox.is-icon-box .content .text {
      text-align: left; } }

.group-extra-link-card {
  display: flex;
  margin-top: 18px;
  flex-direction: column; }
  .group-extra-link-card .single-extra-link-card {
    position: relative;
    display: flex;
    margin: 0 0 12px 0;
    box-shadow: 0 24px 48px 0 rgba(75, 88, 105, 0.12); }
    .group-extra-link-card .single-extra-link-card .article-content h4 {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: block;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      white-space: normal;
      -ms-text-overflow: ellipsis; }
  .group-extra-link-card .article-img {
    position: relative;
    flex: 0 0 216px;
    overflow: hidden;
    background-size: cover;
    background-position: center center; }
    .group-extra-link-card .article-img img {
      width: 100%;
      height: 100%; }
  .group-extra-link-card .fui-card-ribbon {
    position: absolute;
    left: 0;
    top: 0;
    color: #FFFFFF;
    background: #FC7B66;
    font-size: 0.875rem;
    height: 28px;
    line-height: 28px;
    z-index: 9;
    padding: 0 6px; }
    .group-extra-link-card .fui-card-ribbon::after {
      content: "";
      float: right;
      display: block;
      margin-right: -34px;
      width: 0px;
      height: 0px;
      border-left: 14px solid #FC7B66;
      border-top: 14px solid #FC7B66;
      border-right: 14px solid transparent;
      border-bottom: 14px solid transparent; }
    @media screen and (min-width: 768px) {
      .group-extra-link-card .fui-card-ribbon {
        padding: 0 12px; }
        .group-extra-link-card .fui-card-ribbon::after {
          margin-right: -40px; } }
  .group-extra-link-card .article-content {
    padding: 18px;
    background-color: #fff;
    width: 100%; }
    .group-extra-link-card .article-content h4 {
      margin-bottom: 6px;
      margin-top: 12px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: block;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      white-space: normal;
      -ms-text-overflow: ellipsis;
      min-height: 72px;
      height: 72px; }
    .group-extra-link-card .article-content p {
      margin: 0;
      height: 62px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: block;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      white-space: normal;
      -ms-text-overflow: ellipsis; }
    .group-extra-link-card .article-content small {
      margin-right: 10px;
      color: #8A8A8A;
      font-weight: 500; }
    .group-extra-link-card .article-content .periodical {
      color: #2F59C4; }
  .group-extra-link-card .fui-card-extra {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0; }
    .group-extra-link-card .fui-card-extra .fui-button .text {
      color: #D3060F; }
  .group-extra-link-card .social {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px; }
    .group-extra-link-card .social i {
      font-size: 30px;
      margin-left: 6px;
      cursor: pointer; }
  .group-extra-link-card .view {
    color: #8A8A8A;
    display: flex;
    align-items: center; }

@media screen and (max-width: 960px) {
  .group-extra-link-card {
    margin: 0; }
    .group-extra-link-card .single-extra-link-card.fui-card {
      margin: 12px 0 0 0;
      max-height: 128px; }
    .group-extra-link-card .article-img {
      flex: 0 0 120px; }
    .group-extra-link-card .article-content {
      padding: 9px 12px; }
      .group-extra-link-card .article-content small {
        font-size: 12px; }
      .group-extra-link-card .article-content h4 {
        font-size: 16px;
        line-height: 1.75;
        margin: 0;
        min-height: 0;
        min-height: initial;
        height: auto; }
      .group-extra-link-card .article-content p {
        display: none; }
    .group-extra-link-card .fui-card-ribbon {
      font-size: 12px; }
    .group-extra-link-card .fui-card-extra {
      margin-top: -3px; }
      .group-extra-link-card .fui-card-extra a.fui-button.is-arrow.hover,
      .group-extra-link-card .fui-card-extra a.fui-button.is-arrow:hover,
      .group-extra-link-card .fui-card-extra a.fui-button.is-arrow:active,
      .group-extra-link-card .fui-card-extra a.fui-button:link.is-arrow.hover,
      .group-extra-link-card .fui-card-extra a.fui-button:link.is-arrow:hover,
      .group-extra-link-card .fui-card-extra a.fui-button:link.is-arrow:active,
      .group-extra-link-card .fui-card-extra a.fui-button:visited.is-arrow.hover,
      .group-extra-link-card .fui-card-extra a.fui-button:visited.is-arrow:hover,
      .group-extra-link-card .fui-card-extra a.fui-button:visited.is-arrow:active,
      .group-extra-link-card .fui-card-extra button.fui-button.is-arrow.hover,
      .group-extra-link-card .fui-card-extra button.fui-button.is-arrow:hover,
      .group-extra-link-card .fui-card-extra button.fui-button.is-arrow:active,
      .group-extra-link-card .fui-card-extra .fui-button.is-arrow.hover,
      .group-extra-link-card .fui-card-extra .fui-button.is-arrow:hover,
      .group-extra-link-card .fui-card-extra .fui-button.is-arrow:active {
        color: transparent; }
    .group-extra-link-card .social {
      display: none; }
    .group-extra-link-card .article-content small {
      color: #8A8A8A; } }

.radio-group {
  display: flex;
  flex-wrap: wrap;
  margin-right: -12px; }
  .radio-group .radio {
    position: relative;
    width: calc(50% - 12px);
    margin: 0 12px 12px 0; }
    .radio-group .radio:nth-last-child(1) {
      margin-right: 0; }
    .radio-group .radio input {
      position: absolute;
      opacity: 0;
      left: 0; }
    .radio-group .radio input + .content {
      font-weight: 400;
      color: #5F6E84;
      font-size: 1rem;
      border: solid 2px rgba(47, 89, 196, 0.2);
      transition: all 0.3s ease;
      background-color: #fff; }
      .radio-group .radio input + .content .text {
        font-weight: 500; }
      .radio-group .radio input + .content .icon img {
        margin: 0px auto 10px;
        height: 40px; }
        .radio-group .radio input + .content .icon img.default {
          display: block; }
        .radio-group .radio input + .content .icon img.selected {
          display: none; }
      .radio-group .radio input + .content::before, .radio-group .radio input + .content::after {
        box-sizing: border-box; }
      .radio-group .radio input + .content::after {
        content: "";
        position: absolute;
        display: block;
        width: 10px;
        height: 10px;
        top: 16px;
        left: 16px;
        border-radius: 8px;
        background: #FFFFFF;
        opacity: 0;
        transform: scale(0);
        transform-origin: 50%;
        transition: all 0.3s ease; }
      .radio-group .radio input + .content::before {
        content: "";
        position: absolute;
        display: block;
        width: 30px;
        height: 30px;
        top: 6px;
        left: 6px;
        border-radius: 16px;
        background: transparent;
        opacity: 1;
        border: 2px solid #E8ECF0;
        transition: all 0.3s ease; }
    .radio-group .radio input:checked + .content {
      color: #f05c38;
      border-color: #f05c38; }
      .radio-group .radio input:checked + .content .icon img.default {
        display: none; }
      .radio-group .radio input:checked + .content .icon img.selected {
        display: block; }
      .radio-group .radio input:checked + .content::before {
        background: #f05c38;
        border-color: #f05c38;
        opacity: 1; }
      .radio-group .radio input:checked + .content::after {
        opacity: 1;
        transform: scale(1); }
    .radio-group .radio.is-icon-box {
      text-align: center;
      display: flex;
      width: calc(50% - 6px); }
      .radio-group .radio.is-icon-box:nth-child(2n) {
        margin-right: 0; }
      .radio-group .radio.is-icon-box .content {
        flex-direction: column;
        text-align: left;
        flex: 1 1;
        height: auto;
        display: flex;
        align-items: flex-end;
        padding: 24px 12px 24px 46px; }
        .radio-group .radio.is-icon-box .content .icon img {
          height: 100px; }
      .radio-group .radio.is-icon-box .content::after {
        margin: auto;
        top: 34px; }
      .radio-group .radio.is-icon-box .content::before {
        margin: auto;
        top: 24px; }
      @media screen and (min-width: 960px) {
        .radio-group .radio.is-icon-box .content {
          justify-items: center; }
          .radio-group .radio.is-icon-box .content .icon img {
            height: 160px; } }

@media screen and (min-width: 960px) {
  .radio-group {
    margin-right: -12px; } }

.form-group .checkbox.is-2-in-row:nth-of-type(odd) {
  margin: 0 12px 12px 0; }

.form-group .checkbox.is-2-in-row .radio-group .radio {
  width: calc( 50% - 6px); }
  .form-group .checkbox.is-2-in-row .radio-group .radio:nth-last-child(1) {
    margin-right: 0; }

@media screen and (max-width: 960px) {
  .form-group .checkbox.is-2-in-row:nth-of-type(odd) {
    margin-right: 0; } }

.fui-radio-cards {
  margin: 0 -6px;
  display: flex;
  flex-wrap: wrap;
  position: relative; }
  .fui-radio-cards .radio-card {
    position: relative;
    margin: 0 6px 12px;
    width: calc(50% - 12px);
    display: flex;
    flex-grow: 1; }
    .fui-radio-cards .radio-card input {
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0; }
    .fui-radio-cards .radio-card .content {
      display: flex;
      flex-direction: column;
      position: relative;
      width: 100%;
      color: #202020;
      border: 2px solid #E8ECF0;
      padding: 54px 12px 30px; }
      .fui-radio-cards .radio-card .content::before {
        content: "";
        position: absolute;
        left: 12px;
        top: 12px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        box-sizing: border-box;
        border: 2px solid rgba(47, 89, 196, 0.2);
        transition: border 0.3s ease; }
      .fui-radio-cards .radio-card .content h5 {
        font-size: 1rem;
        line-height: 1.3;
        flex: 1 1;
        flex-grow: 1;
        margin-top: 24px;
        margin-bottom: 12px; }
      .fui-radio-cards .radio-card .content .body {
        font-size: 14px; }
      .fui-radio-cards .radio-card .content .ic-plan {
        position: relative;
        width: 100px;
        height: 100px;
        text-align: center;
        color: #FFFFFF;
        padding-top: 20px;
        font-size: 26px;
        background: url(/DigService/resources/cbu/e-service/images/ic-plan-unselected.png) no-repeat center;
        background-size: contain;
        margin: 0 auto; }
        .fui-radio-cards .radio-card .content .ic-plan small {
          font-size: 8px; }
    .fui-radio-cards .radio-card input:checked + .content {
      border-color: #f05c38; }
      .fui-radio-cards .radio-card input:checked + .content .ic-plan {
        background: url(/DigService/resources/cbu/e-service/images/ic-plan-selected.png) no-repeat center;
        background-size: contain; }
      .fui-radio-cards .radio-card input:checked + .content::before {
        border-width: 10px;
        border-color: #f05c38; }
  @media screen and (min-width: 960px) {
    .fui-radio-cards .radio-card {
      width: calc(25% - 12px); }
      .fui-radio-cards .radio-card .content {
        padding: 35px 24px 30px; }
        .fui-radio-cards .radio-card .content .ic-plan {
          width: 160px;
          height: 160px;
          font-size: 48px; }
          .fui-radio-cards .radio-card .content .ic-plan small {
            font-size: 14px; } }

.roaming-plan-radio label {
  display: none; }

.roaming-plan-radio .radio-group .radio {
  width: 100%;
  margin-right: 0; }
  .roaming-plan-radio .radio-group .radio .content {
    padding: 30px 30px 30px 72px;
    color: #202020;
    flex-direction: row;
    justify-content: space-between; }
    .roaming-plan-radio .radio-group .radio .content .content-info {
      border-right: 2px solid #ebebeb; }
      .roaming-plan-radio .radio-group .radio .content .content-info > div {
        margin-bottom: 6px; }
      .roaming-plan-radio .radio-group .radio .content .content-info .country a {
        color: #202020; }
        .roaming-plan-radio .radio-group .radio .content .content-info .country a:hover, .roaming-plan-radio .radio-group .radio .content .content-info .country a:focus {
          color: #f05c38; }
        .roaming-plan-radio .radio-group .radio .content .content-info .country a:after {
          display: none !important; }
    .roaming-plan-radio .radio-group .radio .content .content-price {
      height: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      align-items: center; }
    .roaming-plan-radio .radio-group .radio .content .body-2 {
      font-size: 14px; }
    .roaming-plan-radio .radio-group .radio .content .title {
      margin-bottom: 1rem; }
    .roaming-plan-radio .radio-group .radio .content .tips img {
      margin-left: 10px;
      vertical-align: middle; }
    .roaming-plan-radio .radio-group .radio .content .action {
      margin-top: 24px;
      cursor: pointer;
      display: inline-block; }
    .roaming-plan-radio .radio-group .radio .content .unit {
      margin-right: 5px;
      margin-top: 18px; }
    .roaming-plan-radio .radio-group .radio .content .price {
      margin-bottom: 0; }
  .roaming-plan-radio .radio-group .radio input:checked + .content {
    color: #202020; }

.roaming-plan-radio:last-child {
  margin-bottom: 0; }

@media screen and (min-width: 960px) {
  .radio-group .radio {
    width: calc(33.3333333% - 12px); }
    .radio-group .radio.is-icon-box .content {
      align-items: center; }
      .radio-group .radio.is-icon-box .content::before {
        top: 12px;
        left: 12px;
        bottom: auto; }
      .radio-group .radio.is-icon-box .content::after {
        top: 22px;
        left: 22px;
        bottom: auto; } }

.radio-with-input {
  width: 185px;
  display: inline-flex; }
  .radio-with-input .radio-buttons .radio-group .radio-button input:checked ~ .checkmark {
    background-color: #f05c38;
    border-color: #f05c38; }
  .radio-with-input .radio-button {
    margin-right: 0; }
    .radio-with-input .radio-button:nth-of-type(1) {
      margin-bottom: 46px; }
  .radio-with-input .form-group:nth-last-child(1) {
    position: absolute;
    margin: 0;
    top: 110px;
    width: 492px; }
  .radio-with-input .form-group {
    margin-bottom: 24px; }
  @media screen and (max-width: 960px) {
    .radio-with-input {
      width: 100%;
      display: block; }
      .radio-with-input .form-group {
        width: 100%;
        margin-bottom: 0; }
        .radio-with-input .form-group:nth-last-child(1) {
          width: 100%; }
      .radio-with-input .position-relative .form-group {
        position: relative;
        top: 0; }
      .radio-with-input .radio-buttons .radio-button {
        width: 100%; }
        .radio-with-input .radio-buttons .radio-button:nth-of-type(1) {
          margin-bottom: 24px; } }

.radio-to-bottom .form-group,
.radio-to-bottom .radio-buttons {
  margin-bottom: 0 !important; }

.radio-to-bottom .radio-buttons {
  margin-top: 40px; }

.radio-buttons {
  display: flex;
  margin-bottom: 0; }
  .radio-buttons .receipt-radio.radio-group {
    align-items: flex-start;
    flex-direction: column;
    flex: 1 1;
    flex-grow: 1; }
    .radio-buttons .receipt-radio.radio-group .fui-select {
      width: 100%; }
    .radio-buttons .receipt-radio.radio-group > div {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%; }
      .radio-buttons .receipt-radio.radio-group > div .radio-button {
        min-width: 164px;
        width: auto;
        white-space: nowrap;
        margin: 18px 12px 18px 0; }
        .radio-buttons .receipt-radio.radio-group > div .radio-button + div {
          flex: 1 1;
          flex-grow: 1; }
      .radio-buttons .receipt-radio.radio-group > div .search-code {
        position: absolute;
        right: 0px;
        top: 20px; }
  .radio-buttons .radio-group {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    align-items: center; }
    .radio-buttons .radio-group p {
      display: inline-block;
      margin: 0; }
  .radio-buttons.invoice-options .radio-group {
    flex-direction: column;
    align-items: flex-start; }
  .radio-buttons.invoice-options .radio-button {
    display: flex;
    width: 100%;
    margin-right: 0;
    align-items: flex-start; }
    .radio-buttons.invoice-options .radio-button .content {
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: flex-start; }
      .radio-buttons.invoice-options .radio-button .content span {
        margin-bottom: 4px; }
      .radio-buttons.invoice-options .radio-button .content .fui-select {
        min-width: 40%;
        max-width: 100%; }
      .radio-buttons.invoice-options .radio-button .content .text-input {
        min-width: 40%;
        max-width: 100%;
        height: 50px; }
      .radio-buttons.invoice-options .radio-button .content .text-input input {
        opacity: 1; }
    .radio-buttons.invoice-options .radio-button input + .content::before {
      content: '';
      position: absolute;
      display: block;
      left: 0;
      top: 2px;
      width: 20px;
      height: 20px;
      border: 2px solid #202020;
      border-radius: 50%; }
    .radio-buttons.invoice-options .radio-button input:checked + .content::before {
      border-color: #f05c38; }
    .radio-buttons.invoice-options .radio-button input:checked + .content::after {
      content: '';
      position: absolute;
      display: block;
      left: 6px;
      top: 8px;
      margin: auto;
      width: 12px;
      height: 12px;
      background: #f05c38;
      border-radius: 50%; }
  @media screen and (min-width: 960px) {
    .radio-buttons {
      margin-bottom: 38px; }
      .radio-buttons .radio-group.receipt-radio > div {
        position: relative;
        align-items: center;
        align-content: center;
        flex-direction: row; }
        .radio-buttons .radio-group.receipt-radio > div .search-code {
          position: absolute;
          right: -100px;
          height: 20px;
          top: 0;
          bottom: 0;
          margin: auto;
          z-index: 2; }
      .radio-buttons .radio-group.receipt-radio .radio-button {
        width: calc( 25% - 12px); } }

.radio-button {
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: flex;
  align-items: center;
  margin-right: 72px;
  font-weight: 400; }
  .radio-button input {
    position: absolute;
    opacity: 0;
    left: 0;
    cursor: pointer; }
  .radio-button:hover input ~ .checkmark {
    background-color: #E8ECF0; }
  .radio-button input:checked ~ .checkmark {
    background-color: #f05c38;
    border: 2px solid #f05c38; }
  .radio-button input:checked ~ .checkmark:after {
    display: block; }
  .radio-button .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    border: 2px solid #BFBFBF; }
    .radio-button .checkmark:after {
      content: "";
      position: absolute;
      display: none;
      top: 8px;
      left: 8px;
      width: 11px;
      height: 11px;
      border-radius: 50%;
      background: white; }

.radio-sex .radio-buttons {
  margin-top: 55px; }

.is-ebu .radio-button input:checked ~ .checkmark,
.is-help-center .radio-button input:checked ~ .checkmark {
  background-color: #2F59C4;
  border: 2px solid #2F59C4; }

.is-ebu .radio-group .radio input:checked + .content,
.is-help-center .radio-group .radio input:checked + .content {
  color: #2F59C4;
  border-color: #2F59C4; }
  .is-ebu .radio-group .radio input:checked + .content::before,
  .is-help-center .radio-group .radio input:checked + .content::before {
    background: #2F59C4; }

@media screen and (max-width: 960px) {
  .roaming-plan-radio .radio-group {
    margin-right: 0; }
    .roaming-plan-radio .radio-group .radio .content {
      padding: 40px 20px 0 20px; }
      .roaming-plan-radio .radio-group .radio .content:before {
        top: 12px;
        left: 12px; }
      .roaming-plan-radio .radio-group .radio .content .content-info {
        border-right: 0; }
      .roaming-plan-radio .radio-group .radio .content .content-price {
        margin-top: -65px;
        align-items: baseline; }
      .roaming-plan-radio .radio-group .radio .content:after {
        top: 22px;
        left: 22px; }
    .roaming-plan-radio .radio-group .radio input:checked + .content::after {
      top: 22px;
      left: 22px; } }

@media screen and (max-width: 768px) {
  .radio-sex .radio-buttons {
    margin-top: 0; }
    .radio-sex .radio-buttons .radio-button {
      width: calc( 50% - 12px); } }

.radio-textInput {
  display: flex;
  align-items: baseline; }
  .radio-textInput label {
    flex: 1 1 150px;
    margin-right: 30px;
    width: 100%; }
  .radio-textInput .radio-textInput--300 {
    flex: 1 1 300px; }
  @media screen and (max-width: 960px) {
    .radio-textInput {
      flex-direction: column;
      margin-bottom: 24px; }
      .radio-textInput label, .radio-textInput .radio-textInput--300 {
        flex: 1 1 auto; }
        .radio-textInput label a, .radio-textInput .radio-textInput--300 a {
          position: absolute;
          right: 0;
          top: 0; } }

.has-more-tab {
  position: relative;
  display: flex;
  z-index: 9; }
  .has-more-tab .tab-wrapper {
    display: flex;
    width: 100%;
    white-space: nowrap; }
    .has-more-tab .tab-wrapper .active {
      color: #D3060F;
      border-bottom: 6px solid #EF3123; }
  .has-more-tab .tab {
    flex: 1 1 auto;
    padding: 18px 4px 12px 4px;
    font-weight: 500;
    display: inline-block;
    cursor: pointer;
    border-bottom: 6px solid #D6D6D6;
    text-align: center;
    transition: all 0.5s; }
    .has-more-tab .tab:hover {
      color: #D3060F; }
  .has-more-tab .fui-dropdown {
    flex: 1 1 auto;
    display: flex;
    height: auto;
    height: initial; }
    .has-more-tab .fui-dropdown.active button {
      border-bottom: 6px solid #EF3123 !important; }
    .has-more-tab .fui-dropdown button {
      color: #000 !important;
      font-weight: 500;
      width: 100%;
      white-space: nowrap;
      border-bottom: 6px solid #D6D6D6;
      padding-top: 6px;
      margin-bottom: 0; }
      .has-more-tab .fui-dropdown button:hover {
        transition: all 0.5s; }
        .has-more-tab .fui-dropdown button:hover span, .has-more-tab .fui-dropdown button:hover i {
          color: #D3060F; }
      .has-more-tab .fui-dropdown button a {
        text-align-last: left; }
    .has-more-tab .fui-dropdown .fui-menu {
      top: 56px !important;
      z-index: 1; }
      .has-more-tab .fui-dropdown .fui-menu .fui-item {
        background-color: #fff;
        text-align: left;
        padding: 8px 18px; }
    .has-more-tab .fui-dropdown .fui-dropdown-item {
      display: block; }
    .has-more-tab .fui-dropdown .menu-wrapper {
      width: 100%;
      margin: 5px 0 0 0;
      right: 0; }
  .has-more-tab .fui-dropdown.active {
    position: relative; }
    .has-more-tab .fui-dropdown.active .fui-dropdown-item {
      border-bottom: 0;
      flex: 1 1 auto; }
      .has-more-tab .fui-dropdown.active .fui-dropdown-item span, .has-more-tab .fui-dropdown.active .fui-dropdown-item i {
        color: #D3060F; }
    .has-more-tab .fui-dropdown.active .menu-wrapper .active {
      border: 0; }

@media screen and (max-width: 960px) {
  .has-more-tab {
    padding-right: -15px;
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: 12px; }
    .has-more-tab * {
      vertical-align: top; }
    .has-more-tab .tab-wrapper {
      flex: 1 1 auto;
      display: blcok;
      overflow-x: auto;
      overflow-y: hidden; }
      .has-more-tab .tab-wrapper .tab {
        padding: 12px 12px; }
    .has-more-tab .fui-dropdown {
      width: 100px;
      white-space: nowrap;
      display: inline-block;
      position: static;
      position: initial; }
      .has-more-tab .fui-dropdown button {
        padding: 10px 16px 11px; }
      .has-more-tab .fui-dropdown .fui-menu {
        width: 100%;
        top: 40px;
        position: absolute; }
      .has-more-tab .fui-dropdown .menu-wrapper {
        width: calc(100% - 30px);
        margin: 5px 15px 0 15px; }
    .has-more-tab .fui-dropdown.active {
      position: static; }
      .has-more-tab .fui-dropdown.active::after {
        display: none; }
      .has-more-tab .fui-dropdown.active button {
        transition: all 0s; }
        .has-more-tab .fui-dropdown.active button::after {
          content: "";
          position: absolute;
          bottom: -6px;
          z-index: 10;
          height: 6px;
          left: 0;
          width: 100%;
          background-color: #EF3123; } }

@keyframes modalIn {
  0% {
    opacity: 0;
    z-index: 99; }
  100% {
    opacity: 1;
    z-index: 99; } }

@keyframes modalOut {
  0% {
    opacity: 1; }
  99% {
    opacity: 0; }
  100% {
    display: none;
    opacity: 0;
    z-index: -1; } }

html.modal-open {
  overflow: hidden; }
  html.modal-open main {
    z-index: auto;
    z-index: initial; }
  html.modal-open header,
  html.modal-open .emma-service {
    z-index: 0; }
  html.modal-open .fui-breadcrumb-container,
  html.modal-open .fui-banner,
  html.modal-open .fui-nav-tab {
    z-index: 0 !important; }

.ReactModalPortal {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  z-index: 2999;
  pointer-events: none !important;
  display: none; }
  .ReactModalPortal .ReactModal__Overlay {
    pointer-events: all;
    background-color: rgba(32, 32, 32, 0.2) !important; }
  .ReactModalPortal .ReactModal__Content {
    position: absolute;
    display: flex;
    width: 100vw;
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden; }
    .ReactModalPortal .ReactModal__Content.address-input .fui-prompt-modal-body {
      padding: 30px; }
      .ReactModalPortal .ReactModal__Content.address-input .fui-prompt-modal-body .form-group.modal-radio {
        margin-bottom: 14px; }
        .ReactModalPortal .ReactModal__Content.address-input .fui-prompt-modal-body .form-group.modal-radio input {
          z-index: 11;
          top: 0; }
      .ReactModalPortal .ReactModal__Content.address-input .fui-prompt-modal-body h5 {
        margin-bottom: 32px; }
      .ReactModalPortal .ReactModal__Content.address-input .fui-prompt-modal-body .radio-button {
        margin-bottom: 30px; }
        .ReactModalPortal .ReactModal__Content.address-input .fui-prompt-modal-body .radio-button .dropdown-wrapper {
          margin-bottom: 24px;
          flex-direction: row; }
        .ReactModalPortal .ReactModal__Content.address-input .fui-prompt-modal-body .radio-button p {
          margin-bottom: 20px; }
        .ReactModalPortal .ReactModal__Content.address-input .fui-prompt-modal-body .radio-button .menu-wrapper span {
          color: #202020; }
      .ReactModalPortal .ReactModal__Content.address-input .fui-prompt-modal-body button.fui-button {
        margin-bottom: 0;
        max-width: 190px; }
    .ReactModalPortal .ReactModal__Content .fui-prompt-modal-body {
      align-self: center;
      background: #FFFFFF;
      width: 90%;
      max-width: 600px;
      left: 0;
      right: 0;
      margin: auto;
      padding: 30px 15px;
      text-align: center;
      box-shadow: 0 18px 36px 0 rgba(75, 88, 105, 0.18); }
      .ReactModalPortal .ReactModal__Content .fui-prompt-modal-body table {
        display: table; }
      .ReactModalPortal .ReactModal__Content .fui-prompt-modal-body .scrollable-modal-body {
        -ms-overflow-style: none;
        overflow-x: hidden !important;
        max-height: 60vh;
        overflow: scroll;
        background-color: #fafafa;
        margin-left: -30px;
        margin-right: -30px;
        padding: 30px; }
        .ReactModalPortal .ReactModal__Content .fui-prompt-modal-body .scrollable-modal-body table th, .ReactModalPortal .ReactModal__Content .fui-prompt-modal-body .scrollable-modal-body table td {
          white-space: normal;
          word-break: break-all; }
        @media screen and (max-width: 960px) {
          .ReactModalPortal .ReactModal__Content .fui-prompt-modal-body .scrollable-modal-body {
            margin-left: -14px;
            margin-right: -14px;
            padding: 15px; } }
      .ReactModalPortal .ReactModal__Content .fui-prompt-modal-body .description {
        position: relative;
        width: 100%;
        justify-content: space-between;
        display: flex;
        flex-direction: row;
        height: 160px;
        margin-bottom: 24px; }
        .ReactModalPortal .ReactModal__Content .fui-prompt-modal-body .description::before {
          content: "";
          border-top: 2px solid #D6D6D6;
          position: absolute;
          width: 100%;
          top: 2px; }
        .ReactModalPortal .ReactModal__Content .fui-prompt-modal-body .description .mark-1, .ReactModalPortal .ReactModal__Content .fui-prompt-modal-body .description .mark-2, .ReactModalPortal .ReactModal__Content .fui-prompt-modal-body .description .mark-3 {
          height: 100px;
          border-left: 2px dashed #D6D6D6;
          position: relative;
          margin-bottom: 16px; }
          .ReactModalPortal .ReactModal__Content .fui-prompt-modal-body .description .mark-1::before, .ReactModalPortal .ReactModal__Content .fui-prompt-modal-body .description .mark-2::before, .ReactModalPortal .ReactModal__Content .fui-prompt-modal-body .description .mark-3::before {
            content: "•";
            position: absolute;
            top: -10px;
            font-size: 58px;
            color: #D6D6D6;
            left: -10px;
            font-family: "Arial"; }
        .ReactModalPortal .ReactModal__Content .fui-prompt-modal-body .description .mark-2 {
          width: 2px;
          margin: 0 auto 16px; }
          .ReactModalPortal .ReactModal__Content .fui-prompt-modal-body .description .mark-2::before {
            font-size: 124px;
            color: #5F6E84;
            left: -23px; }
        .ReactModalPortal .ReactModal__Content .fui-prompt-modal-body .description .mark-3 {
          border-right: 2px dashed #D6D6D6;
          border-left: 0; }
          .ReactModalPortal .ReactModal__Content .fui-prompt-modal-body .description .mark-3::before {
            left: auto;
            right: -10px; }
      .ReactModalPortal .ReactModal__Content .fui-prompt-modal-body .usage-graph {
        height: 48px;
        width: 50%;
        position: absolute;
        top: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #FFFFFF;
        font-size: 18px;
        background-image: linear-gradient(to right, #98b2b7 18%, #5f7b80 90%); }
      .ReactModalPortal .ReactModal__Content .fui-prompt-modal-body .success {
        max-width: 144px; }
      .ReactModalPortal .ReactModal__Content .fui-prompt-modal-body h5 {
        margin: 0 0 12px; }
      .ReactModalPortal .ReactModal__Content .fui-prompt-modal-body .fui-buttons.two-button {
        display: flex; }
        .ReactModalPortal .ReactModal__Content .fui-prompt-modal-body .fui-buttons.two-button .fui-button {
          flex: 1 1;
          flex-grow: 1; }
          .ReactModalPortal .ReactModal__Content .fui-prompt-modal-body .fui-buttons.two-button .fui-button:last-child {
            margin: 0; }
      @media screen and (max-width: 600px) {
        .ReactModalPortal .ReactModal__Content .fui-prompt-modal-body .collapse-body {
          margin-top: 4px;
          margin-left: 0;
          margin-right: 0; } }
    .ReactModalPortal .ReactModal__Content.is-large .fui-prompt-modal-body {
      text-align: left;
      max-width: 1080px;
      padding: 30px; }
  @media screen and (min-width: 600px) {
    .ReactModalPortal .ReactModal__Content .fui-prompt-modal-body {
      padding: 30px 40px; } }
  @media screen and (max-width: 960px) {
    .ReactModalPortal .ReactModal__Content.is-large .fui-prompt-modal-body {
      text-align: center;
      padding: 30px 40px; }
      .ReactModalPortal .ReactModal__Content.is-large .fui-prompt-modal-body .description {
        height: 114px; }
      .ReactModalPortal .ReactModal__Content.is-large .fui-prompt-modal-body .mark-1, .ReactModalPortal .ReactModal__Content.is-large .fui-prompt-modal-body .mark-2, .ReactModalPortal .ReactModal__Content.is-large .fui-prompt-modal-body .mark-3 {
        height: 62px; }
      .ReactModalPortal .ReactModal__Content.is-large .fui-prompt-modal-body p.body {
        text-align: left;
        font-size: 18px; }
      .ReactModalPortal .ReactModal__Content.is-large .fui-prompt-modal-body .usage-graph {
        height: 30px;
        font-size: 12px;
        top: 30px; }
      .ReactModalPortal .ReactModal__Content.is-large .fui-prompt-modal-body .body {
        font-size: 16px; } }

.modal-open .ReactModalPortal,
.ReactModal__Body--open .ReactModalPortal {
  display: block; }

.ReactModal__Body--open .ril__image {
  right: 50%; }

.ReactModalPortal .fui-query-modal > div {
  position: relative;
  margin: 0 auto;
  width: 100%; }

.ReactModalPortal .fui-query-modal .close {
  position: absolute;
  display: block;
  width: 30px;
  height: 30px;
  right: 10px;
  top: 60px;
  z-index: 29; }
  .ReactModalPortal .fui-query-modal .close i {
    font-size: 30px; }

.ReactModalPortal .fui-query-modal div.query-table-content {
  width: 100%;
  max-width: 980px;
  margin: 0 auto;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  position: relative;
  display: flex;
  align-items: center;
  height: 100%; }
  .ReactModalPortal .fui-query-modal div.query-table-content .close {
    top: -42px;
    background: white;
    right: 6px;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center; }
  .ReactModalPortal .fui-query-modal div.query-table-content .table {
    width: 100%;
    overflow-y: auto;
    background: #FFFFFF; }
  .ReactModalPortal .fui-query-modal div.query-table-content .close {
    top: -42px;
    background: #fff;
    right: 6px;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center; }
  .ReactModalPortal .fui-query-modal div.query-table-content .w-100.position-relative {
    position: relative;
    margin: 80px 0; }

.ReactModalPortal .fui-query-modal div.query-table-content > table {
  border: 2px solid #202020;
  color: #202020;
  margin: 50px auto;
  background: #FFFFFF; }
  .ReactModalPortal .fui-query-modal div.query-table-content > table > tbody > tr > td {
    padding: 5px !important; }

.ReactModalPortal .fui-query-modal td[style="padding-top:5px;padding-bottom:0px"] > table[width="970"] > tbody > tr > td {
  border: 1px solid #202020; }

.ReactModalPortal .fui-query-modal table[width="120"] {
  width: 120px !important; }

.ReactModalPortal .fui-query-modal table[width="300"] {
  width: 300px !important; }

.ReactModalPortal .fui-query-modal table {
  border-collapse: separate;
  border-width: 2px; }
  .ReactModalPortal .fui-query-modal table > table {
    margin: 0;
    padding: 5px;
    border: 1px solid #202020; }
  .ReactModalPortal .fui-query-modal table *[bgcolor="#E0E0E0"] {
    background: #E0E0E0; }
  .ReactModalPortal .fui-query-modal table tr:nth-child(2n) {
    background: none; }
  .ReactModalPortal .fui-query-modal table tr th {
    background: #E0E0E0;
    font-weight: bold; }
  .ReactModalPortal .fui-query-modal table tr th,
  .ReactModalPortal .fui-query-modal table tr td {
    padding: 0 !important;
    border: 0;
    text-align: left;
    color: #202020;
    height: auto;
    height: auto !important; }

.fui-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(32, 32, 32, 0.3);
  display: none;
  z-index: -1; }
  .fui-modal .check-modal {
    width: 600px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #FFFFFF;
    padding: 30px; }
    .fui-modal .check-modal .checkbox {
      margin: 0; }
    .fui-modal .check-modal .clean {
      cursor: pointer; }
    .fui-modal .check-modal h6 {
      margin: 12px 0 0; }
  .fui-modal .error-modal {
    width: 392px;
    height: 282px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    display: flex;
    background-color: #fff;
    padding: 60px 88px; }
  .fui-modal .fui-close {
    position: absolute;
    right: 20px;
    top: 20px;
    border: 0;
    color: #FFFFFF;
    width: 30px;
    height: 30px;
    background: none;
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer; }
    .fui-modal .fui-close i {
      font-size: 20px; }
  .fui-modal .fui-modal-content {
    position: absolute;
    width: 100%;
    max-width: 80%;
    min-height: 600px;
    max-height: 90%;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    opacity: 0;
    transform-origin: 50%;
    transform: scale(0.8);
    transition: all 0.3s ease; }
  .fui-modal .fui-player iframe {
    width: 100%; }
  .fui-modal.is-open {
    opacity: 0;
    display: block;
    z-index: 9999;
    position: fixed;
    animation: modalIn 0.5s ease forwards; }
    .fui-modal.is-open:not(.is-closing) .fui-modal-content {
      transition-delay: 0.5s; }
    .fui-modal.is-open .fui-modal-content {
      opacity: 1;
      transform: scale(1);
      display: flex;
      justify-content: center;
      align-items: center; }
    .fui-modal.is-open .fui-modal-body {
      width: 90vw;
      position: relative;
      display: flex;
      justify-content: center; }
      .fui-modal.is-open .fui-modal-body .fui-player {
        width: 100%; }
  .fui-modal.is-closing {
    opacity: 1;
    display: block;
    z-index: 9999;
    animation: modalOut 0.5s 0.2s ease forwards; }
    .fui-modal.is-closing .fui-modal-content {
      opacity: 0;
      transform: scale(0.8); }
  .fui-modal .tableBtn-modal {
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #fff;
    padding: 30px; }

@media screen and (max-width: 960px) {
  .fui-modal .error-modal {
    padding: 60px 0; }
  .fui-modal .check-modal {
    padding: 30px 15px; }
    .fui-modal .check-modal .list-container {
      max-height: 412px;
      overflow: scroll; }
    .fui-modal .check-modal .fui-button {
      margin-bottom: 0; }
    .fui-modal .check-modal h6 {
      margin: 16px 0 0;
      font-size: 16px; }
    .fui-modal .check-modal label .content {
      font-size: 16px; }
    .fui-modal .check-modal a.fui-button {
      width: 100%; }
  .ReactModalPortal .ReactModal__Content.address-input .fui-prompt-modal-body {
    padding: 30px 15px; }
    .ReactModalPortal .ReactModal__Content.address-input .fui-prompt-modal-body .form-group.modal-radio {
      margin-bottom: 0; }
    .ReactModalPortal .ReactModal__Content.address-input .fui-prompt-modal-body .radio-button {
      padding-left: 0; }
      .ReactModalPortal .ReactModal__Content.address-input .fui-prompt-modal-body .radio-button .dropdown-wrapper {
        flex-direction: column;
        margin-bottom: 0; }
        .ReactModalPortal .ReactModal__Content.address-input .fui-prompt-modal-body .radio-button .dropdown-wrapper .fui-dropdown {
          margin-bottom: 24px; }
      .ReactModalPortal .ReactModal__Content.address-input .fui-prompt-modal-body .radio-button p {
        padding-left: 35px; }
    .ReactModalPortal .ReactModal__Content.address-input .fui-prompt-modal-body button.fui-button {
      max-width: 100%;
      min-width: auto; } }

@media screen and (max-width: 480px) {
  .fui-modal.is-open .fui-modal-body {
    width: calc(100vw - 20px);
    max-width: 100%;
    height: 80vh;
    align-items: center; }
  .fui-modal .fui-modal-content {
    max-width: 100%; }
  .fui-modal .tableBtn-modal {
    padding: 30px 15px; }
    .fui-modal .tableBtn-modal button.fui-button {
      width: 100%; } }

.modal-open .fui-modal:not(.is-open) {
  display: none !important; }

.fui-pagination {
  padding: 0;
  margin: 48px 0;
  display: block;
  list-style: none;
  text-align: center; }
  .fui-pagination li {
    display: inline-block;
    vertical-align: middle;
    margin: 0 12px; }
    .fui-pagination li.disabled {
      pointer-events: none; }
    .fui-pagination li span {
      height: 30px;
      line-height: 30px; }
    .fui-pagination li .current-page {
      display: inline-block;
      font-size: 0.875rem;
      padding: 0 10px;
      min-width: 30px;
      height: 30px;
      line-height: 30px;
      color: #FFFFFF;
      background: #6a798e;
      border-radius: 20px; }
      .fui-pagination li .current-page::after {
        content: "";
        display: inline-block;
        width: 1px;
        height: 12px;
        vertical-align: middle;
        background: #bfbfbf;
        margin-left: 30px;
        margin-right: -30px;
        transform: rotate(40deg); }
      .fui-pagination li .current-page + span {
        margin-left: 40px; }
    .fui-pagination li a,
    .fui-pagination li a:link {
      font-size: 0.875rem;
      padding: 0 10px;
      min-width: 30px;
      height: 30px;
      line-height: 30px;
      display: block;
      color: #202020 !important;
      border-radius: 20px; }
      .fui-pagination li a:hover,
      .fui-pagination li a:link:hover {
        color: #202020;
        background: #E8ECF0; }
    .fui-pagination li.disabled > a,
    .fui-pagination li.disabled > a:link,
    .fui-pagination li.disabled > a:hover {
      color: #BFBFBF !important;
      background: none;
      cursor: default; }
    .fui-pagination li.previous, .fui-pagination li.next {
      margin: 0 3px; }
    .fui-pagination li.previous a,
    .fui-pagination li.next a {
      width: 30px;
      height: 30px;
      line-height: 30px;
      padding: 0;
      text-align: center;
      display: flex;
      align-content: center;
      align-items: center;
      justify-content: center;
      justify-items: center; }
      .fui-pagination li.previous a *,
      .fui-pagination li.next a * {
        font-size: 1.5rem; }
      .fui-pagination li.previous a:hover,
      .fui-pagination li.next a:hover {
        background: none; }
    .fui-pagination li.selected > a,
    .fui-pagination li.selected > a:link,
    .fui-pagination li.selected > a:hover,
    .fui-pagination li.selected > a:focus {
      color: #FFFFFF !important;
      background: #6a798e; }
  @media screen and (max-width: 430px) {
    .fui-pagination {
      display: flex;
      justify-content: space-around; }
      .fui-pagination li {
        margin: 0 3px; } }

.load-more {
  text-align: center;
  position: relative;
  cursor: pointer;
  margin: 53px 0 118px 0; }
  .load-more a.expand, .load-more p.expand {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #202020;
    font-weight: 500; }
    .load-more a.expand::after, .load-more p.expand::after {
      display: none; }
    .load-more a.expand i, .load-more p.expand i {
      font-size: 24px;
      margin-left: 5px; }
  .load-more a {
    color: #202020;
    font-weight: 500; }
    .load-more a::after {
      display: none; }

@media screen and (max-width: 960px) {
  .load-more {
    margin-bottom: 66px; } }

.carousel-banner-3 {
  height: 360px;
  background-color: #8A8A8A;
  background-position: center;
  margin-bottom: 60px; }
  .carousel-banner-3 .slick-dots {
    position: absolute;
    bottom: 30px;
    z-index: 1;
    list-style: none;
    margin: 0 auto;
    padding: 0;
    left: 0;
    right: 0;
    text-align: center; }
    .carousel-banner-3 .slick-dots .slick-active {
      background: #FFFFFF; }
      .carousel-banner-3 .slick-dots .slick-active button {
        opacity: 0;
        background-color: #fff; }
    .carousel-banner-3 .slick-dots li {
      position: relative;
      display: inline-block;
      margin-right: 6px;
      background-color: #8A8A8A;
      height: 4px;
      width: 50px; }
      .carousel-banner-3 .slick-dots li button {
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0; }
      .carousel-banner-3 .slick-dots li:nth-last-child(1) {
        margin-right: 0; }
        .carousel-banner-3 .slick-dots li:nth-last-child(1) button {
          cursor: pointer;
          width: 50px;
          height: 4px;
          border: 0;
          vertical-align: top; }
  .carousel-banner-3 .slide {
    position: relative; }
  .carousel-banner-3 .bg-img {
    position: absolute;
    width: 100%;
    height: 100%;
    filter: brightness(0.8); }
  .carousel-banner-3 .content {
    height: 360px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-left: 100px; }
    .carousel-banner-3 .content p {
      font-weight: 600; }
    .carousel-banner-3 .content .subtitle, .carousel-banner-3 .content .fui-card.box .fui-card-content .fui-card-title, .fui-card.box .fui-card-content .carousel-banner-3 .content .fui-card-title {
      margin-top: 30px;
      margin-bottom: 0; }
    .carousel-banner-3 .content h1 {
      margin-top: 10px;
      margin-bottom: 22px; }
    .carousel-banner-3 .content a {
      border-color: #fff !important;
      margin-right: 0; }

@media screen and (max-width: 960px) {
  .carousel-banner-3 {
    height: 320px;
    margin-bottom: 0; }
    .carousel-banner-3 .content {
      padding-left: 0;
      text-align: center;
      display: block; }
      .carousel-banner-3 .content .subtitle, .carousel-banner-3 .content .fui-card.box .fui-card-content .fui-card-title, .fui-card.box .fui-card-content .carousel-banner-3 .content .fui-card-title {
        margin-top: 60px; }
      .carousel-banner-3 .content h1 {
        margin-bottom: 80px; }
      .carousel-banner-3 .content a {
        min-width: 190px;
        padding: 2px 16px; }
    .carousel-banner-3 .slick-dots {
      position: relative;
      bottom: 124px; }
    .carousel-banner-3 .bg-img {
      height: 320px; } }

@keyframes closeWindow {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.ril__outer {
  background-color: rgba(0, 0, 0, 0.8);
  outline: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  -ms-content-zooming: none;
  -ms-user-select: none;
  -ms-touch-select: none;
  touch-action: none;
  z-index: 0; }

.ril__outerClosing {
  opacity: 0; }

.ril__inner {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px; }

.ril__image,
.ril__imagePrev,
.ril__imageNext {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  transform-origin: left center;
  margin: auto;
  max-width: none;
  -ms-content-zooming: none;
  -ms-user-select: none;
  -ms-touch-select: none;
  touch-action: none; }

.ril__imageDiscourager {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain; }

.ril__navButtons {
  border: none;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 20px;
  height: 34px;
  padding: 40px 30px;
  margin: auto;
  cursor: pointer;
  opacity: 0.7; }

.ril__navButtons:hover {
  opacity: 1; }

.ril__navButtons:active {
  opacity: 0.7; }

.ril__navButtonPrev {
  left: 0;
  background: rgba(0, 0, 0, 0.2) url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjM0Ij48cGF0aCBkPSJtIDE5LDMgLTIsLTIgLTE2LDE2IDE2LDE2IDEsLTEgLTE1LC0xNSAxNSwtMTUgeiIgZmlsbD0iI0ZGRiIvPjwvc3ZnPg==") no-repeat center; }

.ril__navButtonNext {
  right: 0;
  background: rgba(0, 0, 0, 0.2) url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjM0Ij48cGF0aCBkPSJtIDEsMyAyLC0yIDE2LDE2IC0xNiwxNiAtMSwtMSAxNSwtMTUgLTE1LC0xNSB6IiBmaWxsPSIjRkZGIi8+PC9zdmc+") no-repeat center; }

.ril__downloadBlocker {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7");
  background-size: cover; }

.ril__caption,
.ril__toolbar {
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between; }

.ril__caption {
  bottom: 0;
  max-height: 150px;
  overflow: auto; }

.ril__captionContent {
  padding: 10px 20px;
  color: #fff; }

.ril__toolbar {
  top: 0;
  height: 50px; }

.ril__toolbarSide {
  height: 50px;
  margin: 0; }

.ril__toolbarLeftSide {
  padding-left: 20px;
  padding-right: 0;
  flex: 0 1 auto;
  overflow: hidden;
  text-overflow: ellipsis; }

.ril__toolbarRightSide {
  padding-left: 0;
  padding-right: 20px;
  flex: 0 0 auto; }

.ril__toolbarItem {
  display: inline-block;
  line-height: 50px;
  padding: 0;
  color: #fff;
  font-size: 120%;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: 12px; }

.ril__toolbarItemChild {
  vertical-align: middle; }

.ril__builtinButton {
  width: 40px;
  height: 35px;
  cursor: pointer;
  border: none;
  opacity: 0.7; }

.ril__builtinButton:hover {
  opacity: 1; }

.ril__builtinButton:active {
  outline: none; }

.ril__builtinButtonDisabled {
  cursor: default;
  opacity: 0.5; }

.ril__builtinButtonDisabled:hover {
  opacity: 0.5; }

.ril__closeButton {
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjIwIj48cGF0aCBkPSJtIDEsMyAxLjI1LC0xLjI1IDcuNSw3LjUgNy41LC03LjUgMS4yNSwxLjI1IC03LjUsNy41IDcuNSw3LjUgLTEuMjUsMS4yNSAtNy41LC03LjUgLTcuNSw3LjUgLTEuMjUsLTEuMjUgNy41LC03LjUgLTcuNSwtNy41IHoiIGZpbGw9IiNGRkYiLz48L3N2Zz4=") no-repeat center 45%;
  background-size: 20px; }

.ril__zoomInButton {
  position: relative;
  background: none; }
  .ril__zoomInButton::after {
    content: '';
    position: absolute;
    width: 22px;
    height: 22px;
    box-sizing: border-box;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: 50%;
    border: 2px solid #FFFFFF; }
  .ril__zoomInButton:before {
    content: '\e914';
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    font-family: 'icomoon' !important;
    font-size: 1rem;
    speak: none;
    color: #FFFFFF;
    font-style: normal;
    font-weight: normal;
    font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 20px;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.ril__zoomOutButton {
  position: relative;
  background: none; }
  .ril__zoomOutButton::after {
    content: '';
    position: absolute;
    width: 22px;
    height: 22px;
    box-sizing: border-box;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: 50%;
    border: 2px solid #FFFFFF; }
  .ril__zoomOutButton:before {
    content: '\e911';
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    font-family: 'icomoon' !important;
    font-size: 1rem;
    speak: none;
    color: #FFFFFF;
    font-style: normal;
    font-weight: normal;
    font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 20px;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.ril__outerAnimating {
  animation-name: closeWindow; }

@keyframes pointFade {
  0%,
  19.999%,
  100% {
    opacity: 0; }
  20% {
    opacity: 1; } }

.ril__loadingCircle {
  width: 60px;
  height: 60px;
  position: relative; }

.ril__loadingCirclePoint {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0; }

.ril__loadingCirclePoint::before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 11%;
  height: 30%;
  background-color: #fff;
  border-radius: 30%;
  animation: pointFade 800ms infinite ease-in-out both; }

.ril__loadingCirclePoint:nth-of-type(1) {
  transform: rotate(0deg); }

.ril__loadingCirclePoint:nth-of-type(7) {
  transform: rotate(180deg); }

.ril__loadingCirclePoint:nth-of-type(1)::before,
.ril__loadingCirclePoint:nth-of-type(7)::before {
  animation-delay: -800ms; }

.ril__loadingCirclePoint:nth-of-type(2) {
  transform: rotate(30deg); }

.ril__loadingCirclePoint:nth-of-type(8) {
  transform: rotate(210deg); }

.ril__loadingCirclePoint:nth-of-type(2)::before,
.ril__loadingCirclePoint:nth-of-type(8)::before {
  animation-delay: -666ms; }

.ril__loadingCirclePoint:nth-of-type(3) {
  transform: rotate(60deg); }

.ril__loadingCirclePoint:nth-of-type(9) {
  transform: rotate(240deg); }

.ril__loadingCirclePoint:nth-of-type(3)::before,
.ril__loadingCirclePoint:nth-of-type(9)::before {
  animation-delay: -533ms; }

.ril__loadingCirclePoint:nth-of-type(4) {
  transform: rotate(90deg); }

.ril__loadingCirclePoint:nth-of-type(10) {
  transform: rotate(270deg); }

.ril__loadingCirclePoint:nth-of-type(4)::before,
.ril__loadingCirclePoint:nth-of-type(10)::before {
  animation-delay: -400ms; }

.ril__loadingCirclePoint:nth-of-type(5) {
  transform: rotate(120deg); }

.ril__loadingCirclePoint:nth-of-type(11) {
  transform: rotate(300deg); }

.ril__loadingCirclePoint:nth-of-type(5)::before,
.ril__loadingCirclePoint:nth-of-type(11)::before {
  animation-delay: -266ms; }

.ril__loadingCirclePoint:nth-of-type(6) {
  transform: rotate(150deg); }

.ril__loadingCirclePoint:nth-of-type(12) {
  transform: rotate(330deg); }

.ril__loadingCirclePoint:nth-of-type(6)::before,
.ril__loadingCirclePoint:nth-of-type(12)::before {
  animation-delay: -133ms; }

.ril__loadingCirclePoint:nth-of-type(7) {
  transform: rotate(180deg); }

.ril__loadingCirclePoint:nth-of-type(13) {
  transform: rotate(360deg); }

.ril__loadingCirclePoint:nth-of-type(7)::before,
.ril__loadingCirclePoint:nth-of-type(13)::before {
  animation-delay: 0ms; }

.ril__loadingContainer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

.ril__imagePrev .ril__loadingContainer,
.ril__imageNext .ril__loadingContainer {
  display: none; }

.ril__errorContainer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff; }

.ril__imagePrev .ril__errorContainer,
.ril__imageNext .ril__errorContainer {
  display: none; }

.ril__loadingContainer__icon {
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%); }

.fui-collapse-menu {
  cursor: pointer; }
  .fui-collapse-menu.help-center .collapse-menu-header {
    background: none !important; }
    .fui-collapse-menu.help-center .collapse-menu-header h6.group-name {
      background: url(/DigService/resources/cbu/discount/collapse-menu-bg-help-center@2x.png) no-repeat left center !important; }
    @media screen and (max-width: 960px) {
      .fui-collapse-menu.help-center .collapse-menu-header h6.group-name {
        background: none !important; } }
  .fui-collapse-menu.is-dropdown .fui-dropdown {
    width: 100%; }
    .fui-collapse-menu.is-dropdown .fui-dropdown button.fui-dropdown-item {
      text-align: left;
      width: 100%;
      height: 50px;
      border: 2px solid #E8ECF0;
      margin-top: 3px; }
      .fui-collapse-menu.is-dropdown .fui-dropdown button.fui-dropdown-item i {
        float: right; }
    .fui-collapse-menu.is-dropdown .fui-dropdown .fui-menu {
      width: 100%; }
      .fui-collapse-menu.is-dropdown .fui-dropdown .fui-menu .fui-item {
        text-align: left;
        padding-left: 16px;
        padding-right: 16px; }
  .fui-collapse-menu .group-name {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-image: url(/DigService/resources/cbu/product/images/bg-pattern.png);
    height: 60px;
    width: 100%;
    color: #fff;
    padding: 15px;
    font-weight: 500;
    margin: 0; }
    .fui-collapse-menu .group-name i {
      font-size: 24px;
      display: none; }
  .fui-collapse-menu ul {
    list-style: none;
    padding: 0;
    margin: 0; }
    .fui-collapse-menu ul li.menu-active .body1 i {
      transform: rotate(180deg); }
    .fui-collapse-menu ul li .body1 {
      margin: 0;
      padding: 15px;
      border-bottom: 2px solid #E2E4E6;
      display: flex;
      align-items: center;
      justify-content: space-between; }
    .fui-collapse-menu ul li .more-btn * {
      vertical-align: middle; }
    .fui-collapse-menu ul li p,
    .fui-collapse-menu ul li a:link,
    .fui-collapse-menu ul li a:visited {
      color: #202020;
      font-weight: 500;
      padding: 15px 15px 15px 15px;
      margin: 0;
      border-bottom: 2px solid #E2E4E6; }
      .fui-collapse-menu ul li p i,
      .fui-collapse-menu ul li a:link i,
      .fui-collapse-menu ul li a:visited i {
        font-size: 24px;
        transition: all 0.3s; }
      .fui-collapse-menu ul li p.active, .fui-collapse-menu ul li p:hover,
      .fui-collapse-menu ul li a:link.active,
      .fui-collapse-menu ul li a:link:hover,
      .fui-collapse-menu ul li a:visited.active,
      .fui-collapse-menu ul li a:visited:hover {
        color: #3966DA; }
    .fui-collapse-menu ul li .item-group {
      position: relative;
      transform: rotateX(90deg);
      transform-origin: top;
      transition: all 0.3s;
      height: 0;
      opacity: 0; }
      .fui-collapse-menu ul li .item-group a, .fui-collapse-menu ul li .item-group p {
        display: block;
        padding-left: 24px; }
      .fui-collapse-menu ul li .item-group::after {
        content: "";
        top: 0;
        left: 12px;
        height: 0;
        position: absolute;
        border-left: 12px solid #3966DA;
        border-right: 12px solid transparent;
        border-top: 36px solid transparent;
        border-bottom: 36px solid transparent;
        transform: translateX(-12px);
        z-index: 3; }
      .fui-collapse-menu ul li .item-group::before {
        content: "";
        left: 0;
        top: -1px;
        width: 12px;
        height: 100%;
        background-color: #0F3B81;
        position: absolute;
        transition: all 0.3s; }
    .fui-collapse-menu ul li:hover .body1 {
      color: #3966DA; }
    .fui-collapse-menu ul .menu-active .item-group {
      transform: rotateX(0);
      height: auto;
      opacity: 1; }
      .fui-collapse-menu ul .menu-active .item-group::after {
        height: 0%; }
    .fui-collapse-menu ul .active i {
      transform: rotate(180deg); }
    .fui-collapse-menu ul .active .body1 {
      color: #3966DA; }
    .fui-collapse-menu ul .active p.active {
      color: #3966DA; }
  .fui-collapse-menu .current-select-item {
    display: none;
    cursor: auto;
    cursor: initial; }
    .fui-collapse-menu .current-select-item p {
      margin: 0;
      padding: 15px;
      border-bottom: 2px solid #E2E4E6;
      display: flex;
      align-items: center;
      font-weight: 500; }

@media screen and (max-width: 960px) {
  .fui-collapse-menu .current-select-item {
    display: block; }
  .fui-collapse-menu .group-name {
    background-image: none;
    color: #000;
    border-bottom: 2px solid #E2E4E6; }
    .fui-collapse-menu .group-name i {
      display: block; }
  .fui-collapse-menu ul {
    transform: rotateX(90deg);
    transform-origin: top;
    max-height: 0;
    opacity: 0;
    transition: all 0.3s ease-out; }
  .fui-collapse-menu .collapse-menu-group.menu-active ul {
    transform: rotateX(0deg);
    height: auto;
    opacity: 1;
    max-height: 100%; }
    .fui-collapse-menu .collapse-menu-group.menu-active ul li > a:hover,
    .fui-collapse-menu .collapse-menu-group.menu-active ul li > a:focus {
      color: #202020; } }

.news-wrapper {
  padding-top: 60px;
  margin-bottom: -20px; }
  .news-wrapper p {
    font-size: 18px; }
  .news-wrapper .fui-container .fui-cards {
    padding: 0px 0px 60px 0px; }
  .news-wrapper .fui-card-action {
    width: 100%;
    height: 270px; }
  @media screen and (min-width: 768px) {
    .news-wrapper .fui-cards .fui-card .fui-card-content .fui-card-title {
      -webkit-line-clamp: 3; } }

@media screen and (max-width: 960px) {
  .news-wrapper {
    padding-top: 60px; }
  .fui-container .fui-cards {
    padding: 0 15px 48px 15px; } }

@media screen and (max-width: 768px) {
  .news-wrapper .fui-card {
    width: 268px; }
    .news-wrapper .fui-card .fui-card-action,
    .news-wrapper .fui-card a.fui-card-action {
      height: auto; }
  .tech .fui-card {
    margin: 0; } }

.keyword-container {
  margin-top: 24px;
  font-size: 0.875rem;
  z-index: 0;
  padding-right: 0;
  margin-right: -15px; }
  .keyword-container label {
    color: #5F6E84; }
  .keyword-container .keyword-list {
    display: flex;
    align-items: center;
    align-content: center;
    justify-items: center;
    justify-content: center; }
    .keyword-container .keyword-list * {
      overflow: hidden;
      white-space: nowrap; }
    .keyword-container .keyword-list .keyword-action {
      align-items: center;
      align-content: center;
      display: none; }
      .keyword-container .keyword-list .keyword-action button.fui-button {
        margin: 0;
        display: inline-block;
        padding: 0; }
        .keyword-container .keyword-list .keyword-action button.fui-button:hover i {
          margin-left: 6px; }
        .keyword-container .keyword-list .keyword-action button.fui-button.disabled {
          opacity: 0.3; }
    .keyword-container .keyword-list .keyword-content {
      position: relative;
      flex: 1 1;
      overflow-y: hidden;
      overflow-x: auto;
      -ms-overflow-style: none; }
      .keyword-container .keyword-list .keyword-content::-webkit-scrollbar {
        display: none; }
      .keyword-container .keyword-list .keyword-content .keyword-group {
        display: inline-block;
        white-space: nowrap; }
    .keyword-container .keyword-list .fui-button.is-label {
      padding: 0 12px;
      height: 36px;
      margin: 0 3px 0;
      line-height: 32px; }
    .keyword-container .keyword-list * {
      vertical-align: top; }
    .keyword-container .keyword-list .label {
      vertical-align: middle;
      display: inline-block;
      margin-right: 12px; }

@media screen and (min-width: 960px) {
  .keyword-container {
    margin-right: 0px; }
    .keyword-container .keyword-list {
      position: relative; }
      .keyword-container .keyword-list.is-scroller::before {
        left: 30px; }
      .keyword-container .keyword-list.is-scroller::after {
        right: 50px; }
      .keyword-container .keyword-list .keyword-content {
        overflow: hidden; }
      .keyword-container .keyword-list .keyword-action {
        display: flex; } }

section.subscribe-wrapper {
  height: 200px;
  padding-top: 42px; }
  section.subscribe-wrapper h4 {
    margin: 0 0 30px 0;
    font-size: 22px; }
  section.subscribe-wrapper .subscription {
    width: 100%;
    top: 0; }
    section.subscribe-wrapper .subscription .email-pending {
      pointer-events: none;
      height: 50px;
      position: absolute;
      right: 0;
      width: 158px;
      opacity: 1;
      animation-name: pending;
      animation-duration: 2s;
      display: flex;
      animation-fill-mode: forwards; }
    section.subscribe-wrapper .subscription .email-done {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50px;
      background-color: #5cc3d2;
      position: absolute;
      left: 0;
      width: 100%;
      opacity: 0;
      animation-duration: 2s;
      animation-name: show-up;
      animation-fill-mode: forwards;
      z-index: 3; }
      section.subscribe-wrapper .subscription .email-done span {
        color: #fff; }
    section.subscribe-wrapper .subscription .fui-action {
      cursor: pointer; }
    section.subscribe-wrapper .subscription button.disabled {
      background-color: #D3060F !important;
      opacity: 1;
      color: #fff !important;
      pointer-events: all; }
  section.subscribe-wrapper form {
    margin: 0 auto; }
  section.subscribe-wrapper .form-group {
    margin: 0; }
  section.subscribe-wrapper .error-message {
    position: absolute;
    bottom: 0;
    top: auto !important;
    top: initial !important;
    left: 0; }

@media screen and (max-width: 960px) {
  section.subscribe-wrapper {
    min-height: 0;
    min-height: initial;
    padding: 24px 0 24px 0;
    margin: 0;
    height: auto; }
    section.subscribe-wrapper h4 {
      font-size: 20px;
      margin-bottom: 12px; }
    section.subscribe-wrapper .subscription input {
      padding: 6px 12px;
      height: 50px; }
    section.subscribe-wrapper .subscription .fui-button.is-primary {
      height: 40px;
      padding: 0 24px; }
    section.subscribe-wrapper .subscription .email-done {
      height: 40px; } }

@media screen and (max-width: 768px) {
  section.subscribe-wrapper .subscription input {
    height: 40px; } }

@media screen and (max-width: 381px) {
  section.subscribe-wrapper h4 {
    max-width: 197px; } }

@keyframes pending {
  0% {
    width: 158px; }
  99% {
    width: 100%;
    opacity: 1; }
  100% {
    width: 100%;
    opacity: 1; } }

@keyframes show-up {
  0% {
    opacity: 0; }
  99% {
    opacity: 1; }
  100% {
    opacity: 1; } }

.social-media {
  margin: 54px 0 10px; }
  .social-media i {
    color: #000;
    font-size: 36px;
    margin-right: 10px; }

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(0px); }
  100% {
    opacity: 1;
    transform: translateY(10px); } }

.fui-tooltip-container {
  display: inline; }
  .fui-tooltip-container .fui-button.is-text {
    margin: 0;
    padding: 0;
    vertical-align: middle; }
    .fui-tooltip-container .fui-button.is-text i {
      font-size: 1.5rem;
      margin: 0;
      color: #0A3476; }
    .fui-tooltip-container .fui-button.is-text:hover i {
      margin: 0; }

.fui-nav-anchor ~ .fui-tooltip,
.fui-nav-tab ~ .fui-tooltip {
  transform: translateY(-45px); }

@media screen and (max-width: 960px) {
  .fui-nav-anchor ~ .fui-tooltip.is-nest,
  .fui-nav-tab ~ .fui-tooltip.is-nest {
    width: calc( 100% - 30px);
    max-width: 260px;
    margin-left: 0; } }

.fui-nav-anchor.is-fixed ~ .fui-tooltip,
.fui-nav-tab.is-fixed ~ .fui-tooltip {
  transform: translateY(-90px); }

@media screen and (min-width: 960px) {
  .fui-nav-anchor.is-fixed ~ .fui-tooltip,
  .fui-nav-tab.is-fixed ~ .fui-tooltip {
    transform: translateY(-45px); } }

.fui-tooltip {
  position: absolute;
  display: none;
  top: 100%;
  left: 0;
  width: 100%;
  background: #FFFFFF;
  border: solid 1px #BFBFBF;
  z-index: 29; }
  .fui-tooltip.is-nest {
    width: 240px;
    margin-left: -109px; }
    .fui-tooltip.is-nest::before {
      left: 0;
      right: 0;
      margin: auto; }
  .fui-tooltip .fui-tooltip-body {
    position: static;
    display: block;
    color: #5F6E84;
    padding: 24px;
    max-height: 360px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    line-height: 1.75; }
    .fui-tooltip .fui-tooltip-body::-webkit-scrollbar {
      width: 6px;
      opacity: 1;
      display: block; }
    .fui-tooltip .fui-tooltip-body::-webkit-scrollbar-thumb {
      background: #D6D6D6;
      border-radius: 10px; }
  .fui-tooltip ol, .fui-tooltip ul {
    padding-left: 15px; }
  @media screen and (min-width: 960px) {
    .fui-tooltip::before {
      content: "";
      position: absolute;
      display: block;
      width: 12px;
      height: 12px;
      right: 20px;
      top: -7px;
      background: #FFFFFF;
      border-top: 1px solid #BFBFBF;
      border-left: 1px solid #BFBFBF;
      transform: rotate(45deg); } }
  @media screen and (min-width: 960px) {
    .fui-tooltip.is-nest {
      width: 388px;
      margin-left: -183px; } }

.image-slider {
  z-index: 1; }
  .image-slider .slick-list .img-card {
    height: 460px;
    width: 100%;
    background-size: cover;
    background-position: center; }

.fui-segments {
  background: #FFFFFF;
  box-shadow: 0 6px 12px 0 rgba(75, 88, 105, 0.12); }
  .fui-segments .fui-segment:not(:last-of-type) {
    border-bottom: 1px solid #E8ECF0; }
  .fui-segments .fui-segment h5 {
    font-weight: 400; }
  .fui-segments .fui-segment .fui-collapse {
    background: none; }
    .fui-segments .fui-segment .fui-collapse .collapse-body {
      padding-left: 0;
      padding-right: 0; }
  .fui-segments .rate-plan-box {
    background: #f1f3f5;
    padding: 18px;
    height: 100%; }
    .fui-segments .rate-plan-box h1, .fui-segments .rate-plan-box h2 {
      margin: 0; }
    .fui-segments .rate-plan-box h4, .fui-segments .rate-plan-box h5 {
      margin-top: 0;
      margin-bottom: 0;
      color: #5F6E84; }
    @media screen and (min-width: 960px) {
      .fui-segments .rate-plan-box {
        padding: 30px; }
        .fui-segments .rate-plan-box .rate-plan-box {
          padding: 24px 18px; } }
  .fui-segments .fui-collapse {
    color: #5F6E84;
    background: #fafafa;
    font-size: 0.875rem; }
    .fui-segments .fui-collapse .collapse-header {
      font-size: 1rem;
      padding: 30px 30px; }
      .fui-segments .fui-collapse .collapse-header::before, .fui-segments .fui-collapse .collapse-header::after {
        right: 30px; }
    .fui-segments .fui-collapse .collapse-body {
      padding: 0 30px; }
      .fui-segments .fui-collapse .collapse-body > table tbody tr td {
        background: #FFFFFF; }
      .fui-segments .fui-collapse .collapse-body ol {
        padding-left: 20px;
        margin-top: 0; }
    @media screen and (min-width: 960px) {
      .fui-segments .fui-collapse .fui-collapse {
        padding: 0 30px; }
      .fui-segments .fui-collapse .collapse-header {
        padding: 30px 60px; }
        .fui-segments .fui-collapse .collapse-header::before, .fui-segments .fui-collapse .collapse-header::after {
          right: 0px; }
      .fui-segments .fui-collapse .collapse-body {
        padding: 0 60px; }
        .fui-segments .fui-collapse .collapse-body article {
          padding-bottom: 40px; } }

.fui-segment-title {
  padding: 30px 24px 0; }
  .fui-segment-title h4 {
    margin: 0; }

.fui-segment {
  padding: 30px 24px; }
  .fui-segment .fui-boards.MuiGrid-container {
    margin-bottom: 60px; }
    .fui-segment .fui-boards.MuiGrid-container .MuiGrid-root.MuiGrid-item {
      width: 100%; }
  .fui-segment .fui-item .content {
    font-weight: 500; }
  .fui-segment .fui-board {
    background: #f1f3f5;
    padding: 18px; }
    .fui-segment .fui-board h5 {
      color: #5F6E84;
      font-weight: 400;
      font-size: 20px;
      margin: 0; }
    .fui-segment .fui-board h3 {
      margin: 12px 0 0; }
      .fui-segment .fui-board h3 .body1 {
        font-size: 1rem;
        font-weight: 400; }
  @media screen and (min-width: 960px) {
    .fui-segment .fui-board h5 {
      font-size: 22px; }
    .fui-segment .fui-boards.MuiGrid-container {
      flex-wrap: nowrap; }
      .fui-segment .fui-boards.MuiGrid-container .MuiGrid-root.MuiGrid-item {
        flex: 1 1; } }

.react-datepicker-wrapper {
  width: 100%; }
  .react-datepicker-wrapper .react-datepicker__input-container {
    width: 100%; }
  .react-datepicker-wrapper input[disabled] {
    background-color: #f1f3f5; }
  .react-datepicker-wrapper .react-datepicker__close-icon::after {
    background-color: #202020 !important; }

.react-datepicker-popper,
.react-datepicker__month-container {
  width: 100%;
  max-width: 492px; }

.react-datepicker {
  width: 100%;
  padding: 30px 0;
  border: 0 !important;
  border-radius: 0 !important;
  box-shadow: 0 18px 36px 0 rgba(75, 88, 105, 0.24); }
  .react-datepicker .react-datepicker__navigation {
    top: 30px;
    width: 25px;
    height: 25px;
    border: 0 !important; }
    .react-datepicker .react-datepicker__navigation::after {
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      content: "";
      display: block;
      position: absolute;
      width: 8px;
      height: 8px;
      transform: rotate(-45deg); }
    .react-datepicker .react-datepicker__navigation.react-datepicker__navigation--previous::after {
      border-left: 2px solid #202020;
      border-top: 2px solid #202020; }
    .react-datepicker .react-datepicker__navigation.react-datepicker__navigation--next::after {
      border-right: 2px solid #202020;
      border-bottom: 2px solid #202020; }
  .react-datepicker .react-datepicker__triangle {
    left: auto;
    right: 16px;
    margin: 0;
    border: 0;
    border-bottom-color: #FFFFFF !important; }
    .react-datepicker .react-datepicker__triangle::before {
      border-bottom-color: #FFFFFF !important; }
  .react-datepicker .react-datepicker__day-names {
    display: flex; }
    .react-datepicker .react-datepicker__day-names .react-datepicker__day-name {
      flex: 1 1;
      flex-grow: 1;
      font-size: 18px;
      font-weight: 500; }
  .react-datepicker .react-datepicker__header {
    background: none;
    padding: 0;
    border: 0; }
    .react-datepicker .react-datepicker__header .react-datepicker__current-month {
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 16px; }
  .react-datepicker .react-datepicker__month .react-datepicker__week {
    display: flex; }
    .react-datepicker .react-datepicker__month .react-datepicker__week .react-datepicker__day {
      flex: 1 1;
      flex-grow: 1;
      font-size: 18px;
      padding: 6px 0;
      font-weight: 500; }
      .react-datepicker .react-datepicker__month .react-datepicker__week .react-datepicker__day:hover {
        border-radius: 0; }
    .react-datepicker .react-datepicker__month .react-datepicker__week .react-datepicker__day--selected {
      background: #D3060F;
      border-radius: 0; }

.fui-progress-bar {
  position: relative;
  background: #f1f3f5;
  height: 30px;
  overflow: hidden; }
  .fui-progress-bar .fui-progress-number {
    position: absolute;
    text-align: right;
    width: 100%;
    min-width: 30px;
    height: 100%;
    line-height: 30px;
    color: #FFFFFF;
    font-size: 18px; }
    .fui-progress-bar .fui-progress-number.is-zero {
      color: #5F6E84;
      right: -60px; }
  .fui-progress-bar .fui-progress {
    position: absolute;
    left: -24px;
    top: 0;
    width: 0%;
    height: 100%;
    display: block;
    box-shadow: 0 6px 12px 0 rgba(75, 88, 105, 0.12);
    background-image: linear-gradient(to right, #9EDFE9, #5CC3D2 97%);
    transition: width 0.3s ease; }
    .fui-progress-bar .fui-progress::after {
      content: "";
      position: absolute;
      display: block;
      width: 0;
      height: 0;
      border-bottom: 15px solid #5CC3D2;
      border-left: 12px solid #5CC3D2;
      border-right: 12px solid transparent;
      border-top: 15px solid transparent;
      top: 0;
      right: -24px; }
    .fui-progress-bar .fui-progress.is-blue {
      background-image: linear-gradient(to right, #9EDFE9, #5CC3D2 97%); }
    .fui-progress-bar .fui-progress.is-red {
      background-image: linear-gradient(to left, #FC7B66 83%, #FFA188); }
      .fui-progress-bar .fui-progress.is-red::after {
        border-bottom: 15px solid #FC7B66;
        border-left: 10px solid #FC7B66; }
  .fui-progress-bar.is-bold {
    height: 48px; }
    .fui-progress-bar.is-bold .fui-progress-number {
      line-height: 48px; }
    .fui-progress-bar.is-bold .fui-progress {
      left: -40px; }
    .fui-progress-bar.is-bold .fui-progress::after {
      right: -40px;
      border-top-width: 24px;
      border-bottom-width: 24px;
      border-left-width: 20px;
      border-right-width: 20px; }

.fui-progress-box {
  border: solid 2px #E8ECF0;
  padding: 24px 30px;
  margin-bottom: 12px; }
  .fui-progress-box .fui-progress-bar {
    margin-top: 20px; }
  .fui-progress-box .body1 {
    line-height: 28px; }
  .fui-progress-box .last-block {
    float: right; }
    .fui-progress-box .last-block .last {
      font-size: 28px;
      color: #EF3123;
      font-weight: 500; }

@media screen and (max-width: 760px) {
  .fui-progress-bar {
    height: 20px; }
    .fui-progress-bar.is-bold {
      height: 30px; }
      .fui-progress-bar.is-bold .fui-progress-number {
        line-height: 30px; }
      .fui-progress-bar.is-bold .fui-progress {
        left: -20px; }
        .fui-progress-bar.is-bold .fui-progress::after {
          right: -20px;
          border-bottom-width: 15px;
          border-top-width: 15px;
          border-left-width: 10px;
          border-right-width: 10px; }
    .fui-progress-bar .fui-progress {
      left: -16px; }
      .fui-progress-bar .fui-progress::after {
        right: -16px;
        border-bottom-width: 10px;
        border-top-width: 10px;
        border-left-width: 8px;
        border-right-width: 8px; }
    .fui-progress-bar .fui-progress-number {
      line-height: 20px;
      font-size: 1rem; }
  .fui-progress-box {
    position: relative;
    padding-bottom: 84px;
    font-size: 1rem; }
    .fui-progress-box h5, .fui-progress-box h6 {
      margin-bottom: 9.6px; }
    .fui-progress-box .last-block {
      position: absolute;
      padding: 0 18px;
      right: 0;
      bottom: 30px; }
      .fui-progress-box .last-block .last {
        font-size: 24px; } }

section.banner-with-link {
  position: relative; }
  section.banner-with-link .fui-banner {
    min-height: auto;
    height: 295px; }
  section.banner-with-link .fui-banner.is-product.is-style-2 .caption {
    top: 0; }
    section.banner-with-link .fui-banner.is-product.is-style-2 .caption h1 {
      font-size: 28px; }
    section.banner-with-link .fui-banner.is-product.is-style-2 .caption p.subtitle, section.banner-with-link .fui-banner.is-product.is-style-2 .caption .fui-card.box .fui-card-content p.fui-card-title, .fui-card.box .fui-card-content section.banner-with-link .fui-banner.is-product.is-style-2 .caption p.fui-card-title {
      max-width: 390px; }
  section.banner-with-link .paper {
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer; }
    section.banner-with-link .paper a {
      color: #202020; }

@media screen and (max-width: 960px) {
  section.banner-with-link .fui-banner.is-product {
    height: 360px; }
    section.banner-with-link .fui-banner.is-product .caption {
      padding-top: 138px; }
      section.banner-with-link .fui-banner.is-product .caption h1 {
        font-size: 24px; }
      section.banner-with-link .fui-banner.is-product .caption p {
        font-size: 16px; }
      section.banner-with-link .fui-banner.is-product .caption a.fui-button {
        width: 100%;
        height: 36px; }
        section.banner-with-link .fui-banner.is-product .caption a.fui-button span {
          height: 36px;
          display: flex;
          align-items: center;
          justify-content: center; } }

@media screen and (max-width: 640px) {
  section.banner-with-link .caption .subtitle, section.banner-with-link .caption .fui-card.box .fui-card-content .fui-card-title, .fui-card.box .fui-card-content section.banner-with-link .caption .fui-card-title {
    margin-bottom: 0; } }

.NotificationBar {
  max-height: 0;
  line-height: 40px;
  color: white;
  font-size: 13px;
  line-height: 18px;
  position: fixed;
  transition: max-height 0.4s linear;
  overflow: hidden;
  background: #231f20;
  z-index: 99;
  width: 100%; }
  .NotificationBar.open {
    height: 100%;
    max-height: 100px; }
  .NotificationBar ~ header {
    transition: all 0.3s ease; }
  .NotificationBar.app-download.open ~ header {
    transform: translateY(100px) !important; }

.NotificationBar__message {
  font-size: 0.875rem;
  line-height: 1.2em;
  max-width: 1440px;
  padding: 12px 48px 12px 15px;
  margin: 0 auto;
  color: white; }
  .NotificationBar__message::after {
    content: '';
    position: absolute;
    left: 0px;
    top: 0;
    width: 6px;
    height: 100%;
    background: #EF3123;
    z-index: 0; }
  .NotificationBar__message::before {
    content: '';
    position: absolute;
    left: 6px;
    top: 0;
    border-bottom: 36px solid #EF3123;
    border-left: 18px solid #EF3123;
    border-right: 18px solid transparent;
    border-top: 36px solid transparent;
    z-index: 0; }
  @media screen and (min-width: 960px) {
    .NotificationBar__message {
      font-size: 1rem;
      padding: 16px 15px; } }
  .NotificationBar__message span {
    position: relative;
    z-index: 2; }
  .NotificationBar__message a,
  .NotificationBar__message a:link {
    font-weight: 500;
    color: #FFFFFF !important;
    text-decoration: none; }

.NotificationBar__close {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translate(0, -50%);
  line-height: 0;
  cursor: pointer;
  text-decoration: none;
  display: block; }
  .NotificationBar__close i {
    font-size: 1.5rem;
    color: #FFFFFF; }
  .NotificationBar__close svg path {
    fill: white; }

.NotificationBar.image-bulletin {
  height: 72px; }
  .NotificationBar.image-bulletin .NotificationBar__message {
    padding: 0;
    max-width: none !important; }
    .NotificationBar.image-bulletin .NotificationBar__message::before, .NotificationBar.image-bulletin .NotificationBar__message::after {
      display: none; }
    .NotificationBar.image-bulletin .NotificationBar__message > a {
      position: absolute;
      width: 100%;
      height: 100%;
      display: block;
      z-index: 0; }
    .NotificationBar.image-bulletin .NotificationBar__message img {
      position: absolute;
      width: 100%;
      height: auto;
      top: -100%;
      bottom: -100%;
      left: -100%;
      right: -100%;
      margin: auto; }
  @media screen and (max-width: 320px) {
    .NotificationBar.image-bulletin .NotificationBar__message img {
      height: 72px;
      width: auto; } }
  @media screen and (min-width: 960px) {
    .NotificationBar.image-bulletin {
      height: 60px; }
      .NotificationBar.image-bulletin .NotificationBar__message img {
        width: auto;
        height: 60px; } }
  @media screen and (min-width: 1440px) {
    .NotificationBar.image-bulletin .NotificationBar__message img {
      width: 100%;
      height: auto; } }

.NotificationBar.app-download {
  padding: 0; }
  .NotificationBar.app-download .NotificationBar__message::before,
  .NotificationBar.app-download .NotificationBar__message::after {
    display: none; }
  .NotificationBar.app-download img {
    width: 64px;
    height: 64px; }
  .NotificationBar.app-download .NotificationBar__close {
    right: auto;
    left: 11px; }
    .NotificationBar.app-download .NotificationBar__close i {
      font-size: 0.75rem; }
  .NotificationBar.app-download .NotificationBar__message {
    color: #FFFFFF;
    font-size: 0.875rem;
    text-align: left; }
    .NotificationBar.app-download .NotificationBar__message small {
      font-size: 0.75rem;
      line-height: 1.3;
      color: rgba(255, 255, 255, 0.5); }
    .NotificationBar.app-download .NotificationBar__message .app-download-container {
      display: flex;
      padding-left: 24px; }
      .NotificationBar.app-download .NotificationBar__message .app-download-container img {
        width: 64px; }
      .NotificationBar.app-download .NotificationBar__message .app-download-container .content {
        flex: 1 1;
        flex-grow: 1;
        padding-left: 8px; }
      .NotificationBar.app-download .NotificationBar__message .app-download-container .extra {
        display: flex;
        padding: 0 10px;
        align-items: center;
        align-content: center; }
        .NotificationBar.app-download .NotificationBar__message .app-download-container .extra a:link {
          text-decoration: none;
          color: #FFFFFF; }

.fui-cookie {
  position: fixed;
  width: 100%;
  bottom: 0;
  background: rgba(32, 32, 32, 0.9);
  box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.3);
  color: #FFFFFF;
  z-index: 38;
  padding: 30px 0; }
  .fui-cookie .fui-container {
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: column; }
    .fui-cookie .fui-container .content {
      flex: 1 1 auto;
      flex-grow: 1; }
      .fui-cookie .fui-container .content h5 {
        margin: 0; }
    .fui-cookie .fui-container .extra .fui-button {
      width: 100%;
      margin: 16px 0 0; }
    @media screen and (min-width: 960px) {
      .fui-cookie .fui-container {
        flex-direction: row; }
        .fui-cookie .fui-container .extra {
          display: flex;
          align-content: center;
          align-items: center;
          padding-left: 60px; }
          .fui-cookie .fui-container .extra .fui-button {
            width: auto;
            margin: 0; } }

.fui-menu-drawer {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #FFFFFF;
  z-index: 9;
  transform: translateX(100%);
  transition: transform 0.5s ease; }
  .fui-menu-drawer .fui-menu-drawer-head {
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    box-shadow: 0 2px 0 0 #e9e9e9;
    padding: 0 15px;
    z-index: 1; }
    .fui-menu-drawer .fui-menu-drawer-head div[role="button"] {
      display: flex;
      width: 30px;
      height: 30px;
      align-items: center;
      align-content: center; }
      .fui-menu-drawer .fui-menu-drawer-head div[role="button"] i[class*='icon-'] {
        font-size: 1.5rem; }
    .fui-menu-drawer .fui-menu-drawer-head .drawer-bar-center {
      flex: 1 1;
      flex-grow: 1;
      font-size: 1.125rem;
      font-weight: 500;
      text-align: center; }
  .fui-menu-drawer .fui-menu-drawer-body {
    position: absolute;
    display: block;
    width: 100%;
    top: 50px;
    height: calc(100% - 50px); }
    .fui-menu-drawer .fui-menu-drawer-body .fui-menu-drawer-sidemenu {
      position: absolute;
      display: block;
      width: 116px;
      height: 100%;
      overflow-x: hidden;
      overflow-y: auto;
      background: #fafafa;
      box-shadow: 2px 0 0 0 #EBEBEB;
      -ms-overflow-style: none; }
      .fui-menu-drawer .fui-menu-drawer-body .fui-menu-drawer-sidemenu::-webkit-scrollbar {
        display: none; }
      .fui-menu-drawer .fui-menu-drawer-body .fui-menu-drawer-sidemenu .fui-drawer-item {
        position: relative;
        text-align: center;
        padding: 20px 15px; }
        .fui-menu-drawer .fui-menu-drawer-body .fui-menu-drawer-sidemenu .fui-drawer-item img.icon {
          width: 30px;
          height: 30px;
          display: block;
          margin: 0 auto; }
          .fui-menu-drawer .fui-menu-drawer-body .fui-menu-drawer-sidemenu .fui-drawer-item img.icon.is-active {
            display: none; }
        .fui-menu-drawer .fui-menu-drawer-body .fui-menu-drawer-sidemenu .fui-drawer-item .text {
          white-space: wrap;
          font-size: 1rem;
          font-weight: 500; }
        .fui-menu-drawer .fui-menu-drawer-body .fui-menu-drawer-sidemenu .fui-drawer-item.is-active {
          background: #FFFFFF;
          color: #EF3123; }
          .fui-menu-drawer .fui-menu-drawer-body .fui-menu-drawer-sidemenu .fui-drawer-item.is-active img.icon {
            display: none; }
          .fui-menu-drawer .fui-menu-drawer-body .fui-menu-drawer-sidemenu .fui-drawer-item.is-active img.icon.is-active {
            display: block; }
          .fui-menu-drawer .fui-menu-drawer-body .fui-menu-drawer-sidemenu .fui-drawer-item.is-active::before {
            content: "";
            position: absolute;
            width: 8px;
            height: 100%;
            left: 0;
            top: 0;
            display: block;
            background: #EF3123; }
    .fui-menu-drawer .fui-menu-drawer-body .fui-menu-drawer-container {
      position: absolute;
      display: block;
      margin-left: 116px;
      left: 0;
      right: 0;
      height: 100%;
      overflow-x: hidden;
      overflow-y: auto;
      display: none;
      -webkit-overflow-scrolling: touch; }
      .fui-menu-drawer .fui-menu-drawer-body .fui-menu-drawer-container.is-active {
        display: block; }
      .fui-menu-drawer .fui-menu-drawer-body .fui-menu-drawer-container > a.fui-item {
        height: auto;
        padding: 0;
        box-shadow: inset 0 -1px 0 0 #E8ECF0; }
        .fui-menu-drawer .fui-menu-drawer-body .fui-menu-drawer-container > a.fui-item > span {
          padding: 24px 28px;
          font-size: 1.125rem; }
      .fui-menu-drawer .fui-menu-drawer-body .fui-menu-drawer-container .fui-collapse {
        width: 100%;
        box-shadow: inset 0 -1px 0 0 #E8ECF0; }
        .fui-menu-drawer .fui-menu-drawer-body .fui-menu-drawer-container .fui-collapse .collapse-body {
          margin: 0; }
          .fui-menu-drawer .fui-menu-drawer-body .fui-menu-drawer-container .fui-collapse .collapse-body article {
            padding: 0; }
            .fui-menu-drawer .fui-menu-drawer-body .fui-menu-drawer-container .fui-collapse .collapse-body article a.fui-item {
              height: 52px;
              padding-left: 28px;
              padding-right: 28px;
              box-shadow: inset 0 -1px 0 0 #E8ECF0; }
        .fui-menu-drawer .fui-menu-drawer-body .fui-menu-drawer-container .fui-collapse .collapse-header {
          padding: 24px 64px 24px 28px !important;
          box-shadow: inset 0 -1px 0 0 #E8ECF0;
          margin: 0; }
          .fui-menu-drawer .fui-menu-drawer-body .fui-menu-drawer-container .fui-collapse .collapse-header::before, .fui-menu-drawer .fui-menu-drawer-body .fui-menu-drawer-container .fui-collapse .collapse-header::after {
            right: 30px; }

.menu-open .fui-menu-drawer {
  transform: translateX(0%); }

@keyframes slideUp {
  0% {
    height: calc(100vh - 100px); }
  100% {
    height: 0px; } }

.promotion-open {
  overflow: hidden; }
  .promotion-open header.fui-header {
    z-index: 40; }
  .promotion-open.modal-open .fui-splash-promotion {
    z-index: 99;
    overflow: visible; }
    .promotion-open.modal-open .fui-splash-promotion .fui-modal iframe {
      height: 100%; }

.fui-splash-promotion {
  position: fixed;
  width: 100%;
  height: calc(100vh - 100px);
  padding: 0;
  margin: 0;
  z-index: 39;
  background: #FFFFFF;
  overflow: hidden; }
  .fui-splash-promotion.is-collapse {
    height: calc(100vh - 100px);
    animation: slideUp 0.5s ease forwards; }
    .fui-splash-promotion.is-collapse .caption {
      position: absolute !important; }
  .fui-splash-promotion.is-close {
    height: 0 !important; }
    .fui-splash-promotion.is-close .caption {
      position: absolute !important; }
  .fui-splash-promotion .video,
  .fui-splash-promotion .image {
    position: absolute;
    width: 100%;
    height: calc(100% - 120px);
    background-size: cover;
    background-position: center; }
    .fui-splash-promotion .video img,
    .fui-splash-promotion .image img {
      position: absolute;
      width: auto;
      min-height: 344px;
      height: 100%;
      left: -100%;
      right: -100%;
      top: -100%;
      bottom: -100%;
      margin: auto; }
  .fui-splash-promotion .video::before, .fui-splash-promotion .video::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 2; }
  .fui-splash-promotion .video::before {
    width: 60px;
    height: 60px;
    background: #FFFFFF;
    opacity: 0.84;
    border-radius: 30px;
    box-shadow: 0 11px 48px 0 rgba(32, 32, 32, 0.3); }
  .fui-splash-promotion .video::after {
    left: 20px;
    width: 0;
    height: 0;
    border-left: 16px solid #D3060F;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
    border-right: 16px solid transparent; }
  .fui-splash-promotion .caption {
    position: absolute;
    display: flex;
    align-content: center;
    justify-content: center;
    width: 100%;
    height: 120px;
    bottom: 0;
    left: 0;
    background: #FFFFFF;
    padding: 24px 0;
    box-shadow: 0 6px 12px 0 rgba(75, 88, 105, 0.12), 0 -2px 0 0 rgba(75, 88, 105, 0.06); }
    .fui-splash-promotion .caption .fui-container {
      width: 100%;
      max-width: 1440px;
      display: flex;
      align-content: center;
      align-items: center; }
      .fui-splash-promotion .caption .fui-container .action {
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center; }
        .fui-splash-promotion .caption .fui-container .action .fui-button {
          margin: 0 6px 0; }
      .fui-splash-promotion .caption .fui-container .content {
        flex: 1 1; }
  @media screen and (max-width: 1024px) and (orientation: portrait) {
    .fui-splash-promotion {
      height: calc(100vh - 50px); }
      .fui-splash-promotion .video,
      .fui-splash-promotion .image {
        top: 174px;
        height: calc(100% - 174px); }
        .fui-splash-promotion .video img,
        .fui-splash-promotion .image img {
          width: auto;
          height: 100%;
          margin: auto; }
      .fui-splash-promotion .caption {
        position: fixed;
        top: 100px;
        bottom: auto;
        height: 174px;
        padding: 16px 15px; }
        .fui-splash-promotion .caption .fui-container {
          flex-direction: column; }
          .fui-splash-promotion .caption .fui-container .subtitle, .fui-splash-promotion .caption .fui-container .fui-card.box .fui-card-content .fui-card-title, .fui-card.box .fui-card-content .fui-splash-promotion .caption .fui-container .fui-card-title {
            display: none; }
          .fui-splash-promotion .caption .fui-container .action {
            width: 100%;
            margin: 16px -6px 0; } }
  @media screen and (max-width: 960px) {
    .fui-splash-promotion {
      height: calc(100vh - 50px); }
      .fui-splash-promotion .video,
      .fui-splash-promotion .image {
        top: 174px;
        height: calc(100% - 174px); }
        .fui-splash-promotion .video img,
        .fui-splash-promotion .image img {
          width: auto;
          height: 100%;
          margin: auto; }
      .fui-splash-promotion .caption {
        position: fixed;
        top: 50px;
        bottom: auto;
        height: 174px;
        padding: 16px 15px; }
        .fui-splash-promotion .caption .fui-container {
          flex-direction: column; }
          .fui-splash-promotion .caption .fui-container .subtitle, .fui-splash-promotion .caption .fui-container .fui-card.box .fui-card-content .fui-card-title, .fui-card.box .fui-card-content .fui-splash-promotion .caption .fui-container .fui-card-title {
            display: none; }
          .fui-splash-promotion .caption .fui-container .action {
            width: 100%;
            margin: 16px -6px 0; } }

.fui-ruler {
  background-color: #f1f3f5;
  width: 100%;
  overflow: hidden; }
  .fui-ruler.main .percentage {
    height: 48px;
    background-image: linear-gradient(to left, #fc7b66 0%, #ffa188); }
    .fui-ruler.main .percentage span {
      font-size: 22px;
      margin-top: 10px; }
      .fui-ruler.main .percentage span::after {
        border-color: transparent transparent transparent #fc7a66;
        border-width: 48px 0 0 48px;
        top: -10px;
        right: -48px; }
  .fui-ruler.fetnet-coin .percentage {
    height: 48px;
    background-image: linear-gradient(to left, #f64b53 0%, #d3060f); }
    .fui-ruler.fetnet-coin .percentage span {
      font-size: 22px;
      margin-top: 10px; }
      .fui-ruler.fetnet-coin .percentage span::after {
        border-color: transparent transparent transparent #f64b53;
        border-width: 48px 0 0 48px;
        top: -10px;
        right: -48px; }
  .fui-ruler .percentage {
    height: 30px;
    background-image: linear-gradient(to right, #9edfe9, #5cc3d2 97%);
    position: relative; }
    .fui-ruler .percentage span {
      position: absolute;
      right: 0;
      font-size: 18px;
      color: #fff;
      font-weight: 500;
      margin-top: 2px; }
      .fui-ruler .percentage span div {
        z-index: 1;
        position: relative; }
      .fui-ruler .percentage span.zero {
        left: 10px;
        color: #5f6e84;
        text-align: left; }
        .fui-ruler .percentage span.zero div {
          margin-right: -5px; }
        .fui-ruler .percentage span.zero::after {
          display: none; }
      .fui-ruler .percentage span::after {
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        border-color: transparent transparent transparent #5cc3d2;
        position: absolute;
        border-width: 30px 0 0 30px;
        top: -2px;
        right: -30px; }

.multi-ruler {
  margin-bottom: 60px; }
  .multi-ruler .fui-ruler.main {
    height: 48px; }
  .multi-ruler .fui-ruler .percentage {
    display: inline-block;
    transform: skewX(36deg);
    background-image: none;
    margin-left: -1px;
    color: #5F6E84;
    font-weight: 500; }
    .multi-ruler .fui-ruler .percentage .first-block {
      display: block;
      position: absolute;
      left: 0;
      width: 50px;
      height: 100%;
      left: -30px; }
    .multi-ruler .fui-ruler .percentage .last-block {
      display: block;
      position: absolute;
      right: 0;
      width: 50px;
      height: 100%;
      right: -30px; }
    .multi-ruler .fui-ruler .percentage span::after {
      top: -10px;
      display: none; }
  .multi-ruler .number-block {
    background: transparent none repeat 0 0 / auto auto padding-box border-box scroll;
    background: initial;
    display: flex; }
    .multi-ruler .number-block .percentage {
      background: transparent none repeat 0 0 / auto auto padding-box border-box scroll;
      background: initial;
      transform: none;
      transform: initial; }

.analysis-list .list-block {
  display: inline-flex;
  color: #5F6E84;
  align-items: center;
  margin-right: 60px;
  font-weight: 500;
  margin-bottom: 10px; }
  .analysis-list .list-block .rectangle {
    width: 15px;
    height: 15px;
    margin-right: 6px; }

.bill-ruler {
  margin-bottom: 60px; }
  .bill-ruler .bill-ruler-wrapper {
    display: flex;
    align-items: center; }
    .bill-ruler .bill-ruler-wrapper .middle-border::before {
      content: '';
      position: absolute;
      left: 33.3%;
      height: 100px;
      border-left: 1px solid #EBEBEB;
      top: -10px;
      z-index: 1; }
    .bill-ruler .bill-ruler-wrapper .middle-border::after {
      content: '';
      position: absolute;
      left: 66.6%;
      height: 100px;
      border-left: 1px solid #EBEBEB;
      top: -10px;
      z-index: 1; }
    .bill-ruler .bill-ruler-wrapper h5 {
      display: inline-block;
      width: 130px;
      margin: 0; }
    .bill-ruler .bill-ruler-wrapper .fui-ruler {
      width: calc( 100% - 130px);
      background-color: transparent;
      background-color: initial;
      display: inline-block;
      position: relative;
      overflow: hidden;
      padding-bottom: 15px;
      padding-top: 15px;
      padding-right: 48px; }
      .bill-ruler .bill-ruler-wrapper .fui-ruler.main .percentage span::after {
        border-color: transparent transparent transparent #fc7b66; }
      .bill-ruler .bill-ruler-wrapper .fui-ruler.main .percentage.full-width span::after {
        background-color: #fc7b66; }
      .bill-ruler .bill-ruler-wrapper .fui-ruler.fetnet-coin .percentage span::after {
        border-color: transparent transparent transparent #f64b53; }
      .bill-ruler .bill-ruler-wrapper .fui-ruler.fetnet-coin .percentage.full-width span::after {
        background-color: #f64b53; }
      .bill-ruler .bill-ruler-wrapper .fui-ruler .percentage {
        height: 48px; }
        .bill-ruler .bill-ruler-wrapper .fui-ruler .percentage.full-width span::after {
          border: 0;
          background-color: #5cc3d2;
          width: 48px;
          height: 48px; }
        .bill-ruler .bill-ruler-wrapper .fui-ruler .percentage .zero {
          margin-top: 8px;
          width: 40px; }
        .bill-ruler .bill-ruler-wrapper .fui-ruler .percentage span {
          font-size: 22px;
          margin-top: 10px;
          width: 100%;
          text-align: right; }
          .bill-ruler .bill-ruler-wrapper .fui-ruler .percentage span div {
            white-space: pre; }
          .bill-ruler .bill-ruler-wrapper .fui-ruler .percentage span::after {
            border-color: transparent transparent transparent #5cc3d2;
            border-width: 48px 0 0 48px;
            top: -10px;
            right: -48px; }
      .bill-ruler .bill-ruler-wrapper .fui-ruler::before {
        content: '';
        position: absolute;
        left: 0;
        height: 100px;
        border-left: 1px solid #EBEBEB;
        top: -10px; }
      .bill-ruler .bill-ruler-wrapper .fui-ruler::after {
        content: '';
        position: absolute;
        right: 0;
        height: 100px;
        border-right: 1px solid #EBEBEB;
        top: -10px; }
    .bill-ruler .bill-ruler-wrapper:last-of-type .fui-ruler {
      border-bottom: 1px solid #EBEBEB;
      padding-bottom: 14px; }

@media screen and (max-width: 960px) {
  .fui-ruler.main .percentage {
    height: 30px; }
    .fui-ruler.main .percentage span {
      font-size: 16px;
      margin-top: 4px; }
  .fui-ruler .percentage {
    height: 20px; }
    .fui-ruler .percentage span {
      margin-top: 0;
      font-size: 14px; }
  .multi-ruler {
    margin-bottom: 48px; }
    .multi-ruler .fui-ruler.main {
      height: 30px; }
      .multi-ruler .fui-ruler.main .percentage {
        height: 30px; }
        .multi-ruler .fui-ruler.main .percentage span {
          font-size: 16px;
          margin-top: 4px; }
    .multi-ruler .fui-ruler .percentage {
      height: 20px; }
      .multi-ruler .fui-ruler .percentage span {
        margin-top: 0;
        font-size: 14px; }
  .analysis-list .list-block {
    margin-right: 0;
    width: 50%; }
  .bill-ruler {
    margin-bottom: 30px; }
    .bill-ruler .bill-ruler-wrapper h5 {
      width: 85px;
      min-width: 85px;
      margin-right: 12px; }
    .bill-ruler .bill-ruler-wrapper .fui-ruler {
      width: calc( 100% - 30px);
      padding-right: 30px; }
      .bill-ruler .bill-ruler-wrapper .fui-ruler::before, .bill-ruler .bill-ruler-wrapper .fui-ruler::after {
        height: 100px; }
      .bill-ruler .bill-ruler-wrapper .fui-ruler .middle-border::before, .bill-ruler .bill-ruler-wrapper .fui-ruler .middle-border::after {
        height: 100px; }
      .bill-ruler .bill-ruler-wrapper .fui-ruler .percentage {
        height: 30px; }
        .bill-ruler .bill-ruler-wrapper .fui-ruler .percentage.full-width span::after {
          height: 30px;
          width: 30px; }
        .bill-ruler .bill-ruler-wrapper .fui-ruler .percentage span {
          font-size: 14px;
          margin-top: 6px; }
          .bill-ruler .bill-ruler-wrapper .fui-ruler .percentage span::after {
            border-width: 30px 0 0 30px;
            right: -30px;
            top: -6px; } }

.fui-instruction-list {
  width: 100%;
  margin-bottom: 90px; }
  .fui-instruction-list h2 {
    margin-bottom: 60px;
    margin-top: 0; }
  .fui-instruction-list .fui-card {
    cursor: pointer;
    background: #FFFFFF;
    width: 100%;
    height: 100%;
    padding-bottom: 30px;
    padding-top: 26px; }
    .fui-instruction-list .fui-card img {
      max-width: 160px; }
  @media screen and (max-width: 960px) {
    .fui-instruction-list {
      margin-bottom: 50px; }
      .fui-instruction-list h2 {
        margin-bottom: 36px; }
      .fui-instruction-list .fui-card {
        padding: 32px 12px 18px; }
        .fui-instruction-list .fui-card .fui-card-content {
          padding: 0; }
        .fui-instruction-list .fui-card img {
          max-width: 100px; }
        .fui-instruction-list .fui-card .heading-6 {
          font-size: 16px; } }

.fui-number-selector-container {
  display: inline-block; }
  .fui-number-selector-container + .fui-button {
    margin-left: 12px; }
  .fui-number-selector-container .fui-number-selector {
    display: inline-block; }
    .fui-number-selector-container .fui-number-selector > * {
      display: inline-block;
      width: 50px;
      font-size: 1.25rem;
      text-align: center;
      vertical-align: top;
      border-top: 2px solid #E8ECF0;
      border-bottom: 2px solid #E8ECF0; }
      .fui-number-selector-container .fui-number-selector > * input {
        border: 0;
        font-size: 1rem;
        width: 50px;
        height: 46px;
        line-height: 46px;
        text-align: center;
        -webkit-appearance: none; }
    .fui-number-selector-container .fui-number-selector [role='button'] {
      border: 2px solid #E8ECF0;
      height: 50px;
      line-height: 50px; }
      .fui-number-selector-container .fui-number-selector [role='button'].disabled i {
        opacity: 0.25; }
  .fui-number-selector-container .is-text-error {
    padding: 6px 0;
    font-size: 0.875rem; }

.fui-product-section,
section.fui-product-section {
  padding-top: 30px;
  background-image: url(/DigService/resources/cbu/img/bg-cbu-fcoin-pattern-m@2x.png);
  background-repeat: no-repeat;
  background-size: 100% auto !important; }
  .fui-product-section h4,
  section.fui-product-section h4 {
    font-weight: 500;
    margin-bottom: 30px;
    margin-top: 0; }
    @media screen and (max-width: 960px) {
      .fui-product-section h4,
      section.fui-product-section h4 {
        margin: 30px 0 36px; } }
  .fui-product-section .product-spec,
  section.fui-product-section .product-spec {
    width: 100%;
    margin-bottom: 24px; }
  .fui-product-section .slick-slider.fui-product-slider .slick-arrow,
  section.fui-product-section .slick-slider.fui-product-slider .slick-arrow {
    cursor: pointer;
    position: absolute;
    width: 32px;
    height: 32px;
    top: 0;
    bottom: 0;
    margin: auto;
    color: transparent;
    border: 0;
    background: none;
    z-index: 9; }
    .fui-product-section .slick-slider.fui-product-slider .slick-arrow.slick-disabled,
    section.fui-product-section .slick-slider.fui-product-slider .slick-arrow.slick-disabled {
      opacity: 0.25; }
    .fui-product-section .slick-slider.fui-product-slider .slick-arrow::after,
    section.fui-product-section .slick-slider.fui-product-slider .slick-arrow::after {
      position: absolute;
      font-family: "icomoon" !important;
      speak: none;
      color: #202020;
      width: 40px;
      height: 40px;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      font-size: 32px;
      font-style: normal;
      font-weight: normal;
      font-feature-settings: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1; }
    .fui-product-section .slick-slider.fui-product-slider .slick-arrow.slick-prev,
    section.fui-product-section .slick-slider.fui-product-slider .slick-arrow.slick-prev {
      left: 10%; }
      .fui-product-section .slick-slider.fui-product-slider .slick-arrow.slick-prev::after,
      section.fui-product-section .slick-slider.fui-product-slider .slick-arrow.slick-prev::after {
        content: ""; }
    .fui-product-section .slick-slider.fui-product-slider .slick-arrow.slick-next,
    section.fui-product-section .slick-slider.fui-product-slider .slick-arrow.slick-next {
      right: 10%; }
      .fui-product-section .slick-slider.fui-product-slider .slick-arrow.slick-next::after,
      section.fui-product-section .slick-slider.fui-product-slider .slick-arrow.slick-next::after {
        content: ""; }
  .fui-product-section .slick-slider.fui-product-slider ul.slick-dots,
  section.fui-product-section .slick-slider.fui-product-slider ul.slick-dots {
    display: none !important;
    text-align: center;
    list-style: none;
    margin: 32px 0 0;
    padding: 0; }
    .fui-product-section .slick-slider.fui-product-slider ul.slick-dots li,
    section.fui-product-section .slick-slider.fui-product-slider ul.slick-dots li {
      position: relative;
      display: inline-block;
      width: 36px;
      height: 4px;
      vertical-align: top;
      background: #202020;
      opacity: 0.5;
      margin: 0 3px; }
      .fui-product-section .slick-slider.fui-product-slider ul.slick-dots li.slick-active,
      section.fui-product-section .slick-slider.fui-product-slider ul.slick-dots li.slick-active {
        opacity: 1; }
      .fui-product-section .slick-slider.fui-product-slider ul.slick-dots li button,
      section.fui-product-section .slick-slider.fui-product-slider ul.slick-dots li button {
        opacity: 0;
        position: absolute;
        width: 100%;
        cursor: pointer;
        height: 100%;
        left: 0;
        top: 0; }
  .fui-product-section .slick-slider.fui-product-slider .slick-list .slick-track .slick-slide,
  section.fui-product-section .slick-slider.fui-product-slider .slick-list .slick-track .slick-slide {
    text-align: center; }
    .fui-product-section .slick-slider.fui-product-slider .slick-list .slick-track .slick-slide img,
    section.fui-product-section .slick-slider.fui-product-slider .slick-list .slick-track .slick-slide img {
      display: block;
      width: 100%;
      max-width: 180px;
      height: auto;
      margin: 0 auto; }
  .fui-product-section .price-section,
  section.fui-product-section .price-section {
    margin-bottom: 18px; }
    .fui-product-section .price-section h4,
    section.fui-product-section .price-section h4 {
      margin: 3px 0 12px;
      color: #f05c38; }
      .fui-product-section .price-section h4 del,
      section.fui-product-section .price-section h4 del {
        color: #8A8A8A; }
  .fui-product-section .form-group,
  section.fui-product-section .form-group {
    margin-bottom: 30px; }
    .fui-product-section .form-group label,
    section.fui-product-section .form-group label {
      font-size: 16px;
      margin-bottom: 0; }
  .fui-product-section .fui-discount-bar,
  section.fui-product-section .fui-discount-bar {
    display: flex;
    font-size: 0.875rem; }
    .fui-product-section .fui-discount-bar > a.fui-discount-link:link,
    .fui-product-section .fui-discount-bar > a.fui-discount-link:visited,
    section.fui-product-section .fui-discount-bar > a.fui-discount-link:link,
    section.fui-product-section .fui-discount-bar > a.fui-discount-link:visited {
      justify-content: space-between;
      display: flex;
      color: #5f7b80;
      align-content: center;
      align-items: center;
      width: 100%; }
      .fui-product-section .fui-discount-bar > a.fui-discount-link:link::after,
      .fui-product-section .fui-discount-bar > a.fui-discount-link:visited::after,
      section.fui-product-section .fui-discount-bar > a.fui-discount-link:link::after,
      section.fui-product-section .fui-discount-bar > a.fui-discount-link:visited::after {
        display: none !important; }
      .fui-product-section .fui-discount-bar > a.fui-discount-link:link > span,
      .fui-product-section .fui-discount-bar > a.fui-discount-link:visited > span,
      section.fui-product-section .fui-discount-bar > a.fui-discount-link:link > span,
      section.fui-product-section .fui-discount-bar > a.fui-discount-link:visited > span {
        display: flex;
        align-content: center;
        align-items: center; }
      .fui-product-section .fui-discount-bar > a.fui-discount-link:link > span.content,
      .fui-product-section .fui-discount-bar > a.fui-discount-link:visited > span.content,
      section.fui-product-section .fui-discount-bar > a.fui-discount-link:link > span.content,
      section.fui-product-section .fui-discount-bar > a.fui-discount-link:visited > span.content {
        display: block;
        flex: 1 1 auto; }
      .fui-product-section .fui-discount-bar > a.fui-discount-link:link i[class*='icon-'],
      .fui-product-section .fui-discount-bar > a.fui-discount-link:visited i[class*='icon-'],
      section.fui-product-section .fui-discount-bar > a.fui-discount-link:link i[class*='icon-'],
      section.fui-product-section .fui-discount-bar > a.fui-discount-link:visited i[class*='icon-'] {
        color: #202020;
        font-size: 1.5rem; }
    .fui-product-section .fui-discount-bar .discount-ribbon,
    section.fui-product-section .fui-discount-bar .discount-ribbon {
      position: relative;
      width: 64px;
      line-height: 28px;
      height: 28px;
      color: #FFFFFF;
      background: #95a3a4;
      padding-left: 6px;
      margin-right: 20px;
      display: inline-block; }
      .fui-product-section .fui-discount-bar .discount-ribbon::before, .fui-product-section .fui-discount-bar .discount-ribbon::after,
      section.fui-product-section .fui-discount-bar .discount-ribbon::before,
      section.fui-product-section .fui-discount-bar .discount-ribbon::after {
        content: "";
        position: absolute;
        right: -14px;
        width: 0;
        height: 0; }
      .fui-product-section .fui-discount-bar .discount-ribbon::before,
      section.fui-product-section .fui-discount-bar .discount-ribbon::before {
        top: 0;
        border-top: 7px solid transparent;
        border-right: 7px solid transparent;
        border-bottom: 7px solid #95a3a4;
        border-left: 7px solid #95a3a4; }
      .fui-product-section .fui-discount-bar .discount-ribbon::after,
      section.fui-product-section .fui-discount-bar .discount-ribbon::after {
        bottom: 0;
        border-top: 7px solid #95a3a4;
        border-left: 7px solid #95a3a4;
        border-right: 7px solid transparent;
        border-bottom: 7px solid transparent; }
  .fui-product-section .fui-notice,
  section.fui-product-section .fui-notice {
    margin-top: 12px; }
    .fui-product-section .fui-notice .fui-item,
    section.fui-product-section .fui-notice .fui-item {
      padding: 4px 0;
      font-size: 0.875rem; }
      .fui-product-section .fui-notice .fui-item .more,
      .fui-product-section .fui-notice .fui-item a:not(.sub-link-title):not(.fui-button):link,
      .fui-product-section .fui-notice .fui-item a:not(.sub-link-title):not(.fui-button):visited,
      section.fui-product-section .fui-notice .fui-item .more,
      section.fui-product-section .fui-notice .fui-item a:not(.sub-link-title):not(.fui-button):link,
      section.fui-product-section .fui-notice .fui-item a:not(.sub-link-title):not(.fui-button):visited {
        position: relative;
        font-weight: 500;
        color: #202020;
        margin-left: 6px; }
        .fui-product-section .fui-notice .fui-item .more::after,
        .fui-product-section .fui-notice .fui-item a:not(.sub-link-title):not(.fui-button):link::after,
        .fui-product-section .fui-notice .fui-item a:not(.sub-link-title):not(.fui-button):visited::after,
        section.fui-product-section .fui-notice .fui-item .more::after,
        section.fui-product-section .fui-notice .fui-item a:not(.sub-link-title):not(.fui-button):link::after,
        section.fui-product-section .fui-notice .fui-item a:not(.sub-link-title):not(.fui-button):visited::after {
          content: "";
          position: absolute;
          width: 100%;
          height: 2px;
          background: #202020 !important;
          bottom: -2px;
          left: 0; }
  .fui-product-section .radio-group,
  section.fui-product-section .radio-group {
    margin: 0 -6px; }
    .fui-product-section .radio-group .radio.is-icon-box,
    section.fui-product-section .radio-group .radio.is-icon-box {
      width: calc(50% - 12px);
      margin: 6px 6px 0; }
      @media screen and (max-width: 768px) {
        .fui-product-section .radio-group .radio.is-icon-box .content::before,
        section.fui-product-section .radio-group .radio.is-icon-box .content::before {
          left: 6px;
          bottom: auto;
          top: 6px;
          width: 24px;
          height: 24px; }
        .fui-product-section .radio-group .radio.is-icon-box .content::after,
        section.fui-product-section .radio-group .radio.is-icon-box .content::after {
          left: 14px;
          top: 14px;
          bottom: auto; }
        .fui-product-section .radio-group .radio.is-icon-box input:checked + .content:after,
        section.fui-product-section .radio-group .radio.is-icon-box input:checked + .content:after {
          width: 8px;
          height: 8px; } }
  .fui-product-section .fui-color-selector-container .fui-color-selector-items,
  section.fui-product-section .fui-color-selector-container .fui-color-selector-items {
    margin: 0.875rem 0 23px; }
    .fui-product-section .fui-color-selector-container .fui-color-selector-items label.fui-color-selector-item,
    section.fui-product-section .fui-color-selector-container .fui-color-selector-items label.fui-color-selector-item {
      position: relative;
      display: inline-block;
      vertical-align: top; }
      .fui-product-section .fui-color-selector-container .fui-color-selector-items label.fui-color-selector-item input,
      section.fui-product-section .fui-color-selector-container .fui-color-selector-items label.fui-color-selector-item input {
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0; }
        .fui-product-section .fui-color-selector-container .fui-color-selector-items label.fui-color-selector-item input:checked + .color-item,
        section.fui-product-section .fui-color-selector-container .fui-color-selector-items label.fui-color-selector-item input:checked + .color-item {
          box-shadow: 0 0 0 2px #f05c38; }
    .fui-product-section .fui-color-selector-container .fui-color-selector-items .color-item,
    section.fui-product-section .fui-color-selector-container .fui-color-selector-items .color-item {
      display: block;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: solid 4px #FFFFFF;
      box-shadow: 0 0 0 2px rgba(47, 89, 196, 0.2);
      margin-right: 30px; }
  @media screen and (max-width: 768px) {
    .fui-product-section .slick-initialized .slick-slide,
    section.fui-product-section .slick-initialized .slick-slide {
      height: 100%; }
    .fui-product-section .subtitle.down-more, .fui-product-section .fui-card.box .fui-card-content .down-more.fui-card-title, .fui-card.box .fui-card-content .fui-product-section .down-more.fui-card-title,
    section.fui-product-section .subtitle.down-more,
    section.fui-product-section .fui-card.box .fui-card-content .down-more.fui-card-title,
    .fui-card.box .fui-card-content section.fui-product-section .down-more.fui-card-title {
      font-size: 1rem; } }
  @media screen and (min-width: 960px) {
    .fui-product-section,
    section.fui-product-section {
      padding-top: 90px;
      background-image: url(/DigService/resources/cbu/img/bg-cbu-fcoin-pattern-1920-x-1200@2x.png); }
      .fui-product-section .product-spec,
      section.fui-product-section .product-spec {
        width: 230px;
        margin-bottom: 56px; }
      .fui-product-section .page-action a.fui-button,
      section.fui-product-section .page-action a.fui-button {
        width: 230px; }
      .fui-product-section h2,
      section.fui-product-section h2 {
        margin-bottom: 24px; }
      .fui-product-section .fui-tag,
      section.fui-product-section .fui-tag {
        margin-right: 6px;
        font-size: 0.875rem; }
      .fui-product-section .MuiGrid-root.MuiGrid-container,
      section.fui-product-section .MuiGrid-root.MuiGrid-container {
        width: calc(100% + 60px);
        margin: 0 -30px; }
        .fui-product-section .MuiGrid-root.MuiGrid-container .MuiGrid-root.MuiGrid-item,
        section.fui-product-section .MuiGrid-root.MuiGrid-container .MuiGrid-root.MuiGrid-item {
          padding: 0 30px; }
      .fui-product-section .slick-slider.fui-product-slider ul.slick-dots,
      section.fui-product-section .slick-slider.fui-product-slider ul.slick-dots {
        display: block !important; }
        .fui-product-section .slick-slider.fui-product-slider ul.slick-dots li,
        section.fui-product-section .slick-slider.fui-product-slider ul.slick-dots li {
          width: 50px; }
      .fui-product-section .slick-slider.fui-product-slider .slick-list .slick-track .slick-slide img,
      section.fui-product-section .slick-slider.fui-product-slider .slick-list .slick-track .slick-slide img {
        max-width: 360px; } }

.captcha .form-group {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  width: 100%; }
  .captcha .form-group input {
    min-width: auto;
    margin-right: 6px; }
  .captcha .form-group .captcha-number {
    margin-top: 6px; }
  .captcha .form-group .captcha-box {
    display: flex;
    align-items: flex-start;
    flex-direction: row; }
  .captcha .form-group .captcha-reload {
    border: 0; }
  .captcha .form-group .form-control {
    margin-right: 6px;
    flex-direction: column;
    width: calc( 100% - 207px); }
    .captcha .form-group .form-control .text-input {
      min-width: auto;
      width: 100%; }
  .captcha .form-group button {
    background-color: transparent; }
    .captcha .form-group button img {
      height: 36px;
      margin-top: 6px; }

@media screen and (max-width: 480px) {
  .captcha .form-group {
    flex-flow: column wrap; }
    .captcha .form-group .form-control {
      order: 1;
      margin-top: 12px;
      max-width: 100%;
      width: 100%; }
    .captcha .form-group .captcha-box {
      order: 0; } }

.receipt-loader {
  border: 2px solid #E8ECF0;
  margin: 30px 0; }
  .receipt-loader--header {
    border-bottom: 2px solid #E8ECF0;
    background-color: #f1f3f5;
    padding: 12px 30px;
    width: 100%; }
    .receipt-loader--header h4 {
      margin: 0; }
  .receipt-loader--body {
    padding: 30px; }
    .receipt-loader--body p {
      margin: 0; }

.newsletter-check {
  margin-bottom: 0; }
  .newsletter-check .header {
    display: flex;
    justify-content: space-between; }
    .newsletter-check .header input {
      width: 30px;
      height: 30px;
      min-width: auto; }
      .newsletter-check .header input:checked {
        border-color: #f05c38;
        color: #f05c38; }
      .newsletter-check .header input::before {
        content: '';
        transition: all 0.3s ease; }
      .newsletter-check .header input:checked::before {
        background: #f05c38;
        opacity: 1;
        border-color: #f05c38; }
      .newsletter-check .header input:checked::after {
        content: "";
        font-family: "icomoon" !important;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-feature-settings: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        font-size: 1rem;
        display: block;
        width: 10px;
        height: 10px;
        color: #FFFFFF;
        top: 8px;
        left: 8px;
        opacity: 0;
        transform: scale(0);
        transform-origin: 50%;
        transition: all 0.3s ease; }
    .newsletter-check .header p {
      margin-left: 8px; }
    .newsletter-check .header .checkbox-group {
      align-items: center; }
  .newsletter-check .checkbox-group.disabled .checkbox {
    opacity: .7; }
  .newsletter-check .checkbox-group .checkbox {
    width: calc( 50% - 6px);
    margin: 0 12px 12px 0; }
    .newsletter-check .checkbox-group .checkbox .content {
      width: 100%;
      padding: 62px 30px 30px;
      display: inline-flex;
      align-items: flex-start;
      color: #202020; }
      .newsletter-check .checkbox-group .checkbox .content img {
        width: 206px;
        min-width: 206px;
        display: inline-block;
        margin-right: 18px; }
      .newsletter-check .checkbox-group .checkbox .content h5 {
        margin: 0 0 6px; }
      .newsletter-check .checkbox-group .checkbox .content .d-inline-block {
        text-align: left; }
        .newsletter-check .checkbox-group .checkbox .content .d-inline-block a {
          color: #202020;
          text-decoration: underline; }
        .newsletter-check .checkbox-group .checkbox .content .d-inline-block p {
          margin: 10px 0 18px;
          line-height: 1.5; }
    .newsletter-check .checkbox-group .checkbox input:checked + .content {
      color: #202020; }
    .newsletter-check .checkbox-group .checkbox:nth-of-type(2n) {
      margin-right: 0; }
    .newsletter-check .checkbox-group .checkbox:nth-of-type(3n) {
      margin: 0 12px 12px 0; }
  @media screen and (max-width: 960px) {
    .newsletter-check .header .content {
      width: 70px;
      margin-left: 40px; }
    .newsletter-check .header .checkbox {
      margin-top: 0; }
    .newsletter-check .checkbox-group .checkbox {
      width: 100%;
      margin-right: 0; }
      .newsletter-check .checkbox-group .checkbox .content {
        display: block; }
        .newsletter-check .checkbox-group .checkbox .content h5 {
          margin-top: 12px; }
        .newsletter-check .checkbox-group .checkbox .content img {
          width: 100%;
          max-width: 480px; }
    .newsletter-check .checkbox-group .checkbox input + .content::before {
      bottom: auto;
      top: 12px;
      left: 12px; }
    .newsletter-check .checkbox-group .checkbox input + .content::after {
      bottom: auto;
      top: 20px;
      left: 18px; } }

.collapse-info .fui-collapse {
  background: #FFFFFF;
  overflow: hidden; }
  .collapse-info .fui-collapse .fui-collapse-body {
    padding: 30px; }

.collapse-info .extra {
  padding: 12px;
  background-color: #fafafa;
  box-shadow: 0 6px 12px 0 rgba(75, 88, 105, 0.12);
  font-weight: 500; }
  .collapse-info .extra .expand-btn {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center; }
  .collapse-info .extra i {
    margin-left: 6px;
    font-size: 24px; }

.collapse-info .fui-collapse {
  background: #FFFFFF;
  overflow: hidden; }
  .collapse-info .fui-collapse .fui-collapse-body {
    padding: 30px; }

.collapse-info .extra {
  padding: 12px;
  background-color: #fafafa;
  box-shadow: 0 6px 12px 0 rgba(75, 88, 105, 0.12);
  font-weight: 500; }
  .collapse-info .extra .expand-btn {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center; }
  .collapse-info .extra i {
    margin-left: 6px;
    font-size: 24px; }

.radio-carousel-container {
  position: relative; }
  .radio-carousel-container .no-result {
    text-align: center;
    display: flex;
    justify-content: center;
    align-content: center;
    color: #8A8A8A; }
    .radio-carousel-container .no-result img {
      margin: 0 auto 12px;
      line-height: 0; }
  .radio-carousel-container > div {
    width: 100%; }
  .radio-carousel-container .radio-buttons.is-number-group {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -6px;
    width: calc(100% + 12px) !important; }
    .radio-carousel-container .radio-buttons.is-number-group label.radio-button {
      position: relative;
      display: inline-flex;
      width: calc(50% - 12px);
      margin: 6px;
      min-height: 76px;
      height: 76px;
      height: 76px;
      background: #FFFFFF; }
      .radio-carousel-container .radio-buttons.is-number-group label.radio-button input[type="radio"] {
        position: absolute;
        opacity: 0; }
      .radio-carousel-container .radio-buttons.is-number-group label.radio-button input[type="radio"]:checked + .content,
      .radio-carousel-container .radio-buttons.is-number-group label.radio-button input[type="radio"][checked] + .content {
        color: #f05c38;
        border-color: #f05c38; }
        .radio-carousel-container .radio-buttons.is-number-group label.radio-button input[type="radio"]:checked + .content .fui-tag,
        .radio-carousel-container .radio-buttons.is-number-group label.radio-button input[type="radio"][checked] + .content .fui-tag {
          background: #f05c38; }
      .radio-carousel-container .radio-buttons.is-number-group label.radio-button input[type="radio"]:checked + .content + .checkmark,
      .radio-carousel-container .radio-buttons.is-number-group label.radio-button input[type="radio"][checked] + .content + .checkmark {
        border-color: #f05c38; }
      .radio-carousel-container .radio-buttons.is-number-group label.radio-button .checkmark {
        border-color: rgba(47, 89, 196, 0.2);
        left: 12px;
        top: 12px; }
      .radio-carousel-container .radio-buttons.is-number-group label.radio-button .content {
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: #5F6E84;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        text-align: center;
        font-size: 1rem;
        font-weight: 500;
        border: 2px solid rgba(47, 89, 196, 0.2);
        padding: 12px 30px; }
        .radio-carousel-container .radio-buttons.is-number-group label.radio-button .content .phone-number {
          position: relative;
          margin-bottom: 6px;
          line-height: 28px; }
        .radio-carousel-container .radio-buttons.is-number-group label.radio-button .content .text-sm {
          font-weight: 400;
          text-align: center; }
      .radio-carousel-container .radio-buttons.is-number-group label.radio-button .fui-tag {
        position: absolute;
        top: 0;
        margin: auto;
        right: 0px;
        left: 70%;
        font-size: 0.875rem;
        color: #FFFFFF;
        font-weight: 400;
        background: #95a3a4;
        padding: 0 12px;
        height: 30px;
        line-height: 30px;
        border-radius: 15px;
        min-width: 70px; }
  @media screen and (min-width: 1440px) {
    .radio-carousel-container .radio-buttons.is-number-group label.radio-button {
      width: calc(33.33333% - 12px); } }
  @media screen and (max-width: 768px) {
    .radio-carousel-container .radio-buttons.is-number-group {
      display: flex !important; }
    .radio-carousel-container .radio-buttons.is-number-group label.radio-button {
      padding: 0;
      min-height: auto;
      height: auto; }
      .radio-carousel-container .radio-buttons.is-number-group label.radio-button .content {
        position: relative;
        padding: 24px 8px 12px; }
        .radio-carousel-container .radio-buttons.is-number-group label.radio-button .content .phone-number {
          display: flex;
          flex-direction: column;
          text-align: center;
          justify-content: center;
          align-content: center;
          right: auto;
          top: auto;
          bottom: auto; }
          .radio-carousel-container .radio-buttons.is-number-group label.radio-button .content .phone-number > div {
            display: block;
            width: 100%;
            text-align: center; }
          .radio-carousel-container .radio-buttons.is-number-group label.radio-button .content .phone-number .fui-tag {
            position: static;
            width: auto;
            min-width: 70px;
            bottom: auto;
            display: inline-block;
            top: 0;
            height: 24px;
            line-height: 24px;
            margin-top: 4px; }
          .radio-carousel-container .radio-buttons.is-number-group label.radio-button .content .phone-number + .text-sm {
            font-size: 12px;
            text-align: center; }
      .radio-carousel-container .radio-buttons.is-number-group label.radio-button .checkmark {
        width: 24px;
        height: 24px;
        left: 6px;
        top: 6px; }
        .radio-carousel-container .radio-buttons.is-number-group label.radio-button .checkmark::after {
          top: 6px;
          left: 6px;
          width: 8px;
          height: 8px; } }

.fui-tab-pane .fui-checkbox-cards-container {
  padding-top: 12px; }

.fui-radio-cards-container .tag-filter {
  margin-bottom: 18px; }
  .fui-radio-cards-container .tag-filter .fui-button.is-label {
    height: 36px; }
    .fui-radio-cards-container .tag-filter .fui-button.is-label .text {
      height: 36px;
      line-height: 32px; }
    .fui-radio-cards-container .tag-filter .fui-button.is-label.is-active {
      border-color: #202020; }

.fui-radio-cards-container .slick-slider .slick-arrow {
  display: block !important;
  position: absolute;
  width: 40px;
  height: 40px;
  background: #FFFFFF;
  border-radius: 20px;
  overflow: hidden;
  border: 0;
  z-index: 1;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  color: transparent;
  top: 0;
  bottom: 0;
  margin: auto;
  opacity: 1;
  transition: opacity 0.3s ease; }
  .fui-radio-cards-container .slick-slider .slick-arrow.slick-disabled {
    opacity: 0; }
  .fui-radio-cards-container .slick-slider .slick-arrow::after {
    position: absolute;
    font-family: "icomoon" !important;
    speak: none;
    color: #202020;
    width: 28px;
    height: 28px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    font-size: 28px;
    font-style: normal;
    font-weight: normal;
    font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    z-index: 0; }
  .fui-radio-cards-container .slick-slider .slick-arrow.slick-prev {
    left: -10px; }
    .fui-radio-cards-container .slick-slider .slick-arrow.slick-prev::after {
      content: ""; }
  .fui-radio-cards-container .slick-slider .slick-arrow.slick-next {
    right: -10px; }
    .fui-radio-cards-container .slick-slider .slick-arrow.slick-next::after {
      content: ""; }

.fui-radio-cards-container .slick-slider {
  margin: 0 -6px; }

.fui-radio-cards-container .slick-slider .slick-track,
.fui-radio-cards-container .slick-slider .slick-list {
  padding-bottom: 24px; }
  .fui-radio-cards-container .slick-slider .slick-track .slick-slide,
  .fui-radio-cards-container .slick-slider .slick-list .slick-slide {
    padding: 0 6px; }

.fui-radio-cards-container .slick-slider .slick-track {
  white-space: nowrap; }
  .fui-radio-cards-container .slick-slider .slick-track .slick-slide {
    display: inline-block;
    float: none; }

.fui-radio-cards-container .slick-slider .slick-list {
  overflow: visible; }

.fui-radio-cards-container .fui-card {
  position: relative;
  border: solid 2px rgba(47, 89, 196, 0.2);
  box-shadow: none !important;
  background: #FFFFFF;
  display: inline-flex;
  flex-direction: column;
  padding-top: 0; }
  .fui-radio-cards-container .fui-card label.fui-card-action {
    position: relative;
    padding-top: 48px;
    background: none;
    z-index: 1;
    display: flex;
    flex: 1 1 auto;
    flex-grow: 1;
    flex-direction: column; }
  .fui-radio-cards-container .fui-card.is-radio {
    padding-top: 0; }
    .fui-radio-cards-container .fui-card.is-radio input[type='radio'] {
      position: absolute;
      opacity: 0; }
    .fui-radio-cards-container .fui-card.is-radio::before {
      content: "";
      position: absolute;
      display: block;
      width: 30px;
      height: 30px;
      color: transparent;
      display: block;
      top: 12px;
      left: 12px;
      border: solid 2px rgba(47, 89, 196, 0.2);
      text-align: center;
      line-height: 30px;
      box-sizing: border-box;
      z-index: 0;
      border-radius: 50%; }
    .fui-radio-cards-container .fui-card.is-radio::after {
      content: "";
      position: absolute;
      display: block;
      width: 10px;
      height: 10px;
      color: transparent;
      display: block;
      top: 22px;
      left: 22px;
      text-align: center;
      box-sizing: border-box;
      background: #FFFFFF;
      z-index: 0;
      border-radius: 50%; }
  .fui-radio-cards-container .fui-card.is-checked {
    border-color: #f05c38; }
    .fui-radio-cards-container .fui-card.is-checked::before {
      border-color: #f05c38;
      background: #f05c38; }
    .fui-radio-cards-container .fui-card.is-checked::after {
      display: block; }
  .fui-radio-cards-container .fui-card.is-plan {
    height: 380px;
    display: flex !important;
    flex-direction: column; }
    .fui-radio-cards-container .fui-card.is-plan .fui-card-extra {
      padding-bottom: 30px; }
    .fui-radio-cards-container .fui-card.is-plan .fui-card-action {
      flex: 1 1 auto;
      padding-top: 48px; }
      .fui-radio-cards-container .fui-card.is-plan .fui-card-action .fui-card-plan {
        width: 100%;
        height: 141px;
        text-align: center;
        color: #FFFFFF;
        background-size: auto 141px;
        background-position: center;
        background-repeat: no-repeat; }
        .fui-radio-cards-container .fui-card.is-plan .fui-card-action .fui-card-plan h1, .fui-radio-cards-container .fui-card.is-plan .fui-card-action .fui-card-plan h4 {
          margin: 0; }
        .fui-radio-cards-container .fui-card.is-plan .fui-card-action .fui-card-plan h1 {
          line-height: 63px; }
        .fui-radio-cards-container .fui-card.is-plan .fui-card-action .fui-card-plan h4 {
          margin-top: -12px; }
        .fui-radio-cards-container .fui-card.is-plan .fui-card-action .fui-card-plan.is-friday {
          background-image: url(/DigService/resources/cbu/estore-product/addition-plan-friday@2x.png); }
        .fui-radio-cards-container .fui-card.is-plan .fui-card-action .fui-card-plan.is-music {
          background-image: url(/DigService/resources/cbu/estore-product/addition-plan-music@2x.png); }
      .fui-radio-cards-container .fui-card.is-plan .fui-card-action .fui-card-caption .fui-card-title {
        font-size: 20px;
        height: auto;
        font-weight: 400; }
      .fui-radio-cards-container .fui-card.is-plan .fui-card-action .fui-card-caption .fui-card-price {
        margin-top: 12px; }
  .fui-radio-cards-container .fui-card .fui-card-caption {
    padding: 24px 30px 12px; }
    .fui-radio-cards-container .fui-card .fui-card-caption .fui-card-title {
      font-size: 20px;
      height: 56px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: block;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      white-space: normal;
      -ms-text-overflow: ellipsis; }
      .fui-radio-cards-container .fui-card .fui-card-caption .fui-card-title span.tag {
        display: inline-block;
        line-height: 18px;
        height: 20px;
        padding: 0 6px;
        font-size: 0.875rem;
        border: 1px solid #202020;
        border-radius: 3px;
        margin-right: 6px;
        vertical-align: middle;
        font-weight: 500; }
    .fui-radio-cards-container .fui-card .fui-card-caption .fui-card-price * {
      font-size: 1rem; }
    .fui-radio-cards-container .fui-card .fui-card-caption .fui-card-price .label {
      margin-right: 6px; }
    .fui-radio-cards-container .fui-card .fui-card-caption .fui-card-price del {
      color: #8A8A8A; }
    .fui-radio-cards-container .fui-card .fui-card-caption .fui-card-price .price {
      margin-left: 3px; }
  .fui-radio-cards-container .fui-card .fui-card-extra {
    padding-left: 30px;
    padding-right: 30px; }
    .fui-radio-cards-container .fui-card .fui-card-extra > * {
      font-size: 0.875rem;
      font-weight: 500; }
    .fui-radio-cards-container .fui-card .fui-card-extra .is-text-underline {
      display: inline-block; }
      .fui-radio-cards-container .fui-card .fui-card-extra .is-text-underline::after {
        content: "";
        display: block;
        width: 100%;
        height: 2px;
        background: #202020; }
  .fui-radio-cards-container .fui-card .fui-number-selector-container {
    padding: 0 30px 30px;
    width: 100%; }
    .fui-radio-cards-container .fui-card .fui-number-selector-container .fui-number-selector {
      width: 100%;
      display: table; }
      .fui-radio-cards-container .fui-card .fui-number-selector-container .fui-number-selector > div {
        display: table-cell;
        width: calc(100% - 80px);
        margin: 0 40px;
        line-height: 0;
        height: 40px;
        box-sizing: border-box;
        vertical-align: middle; }
        .fui-radio-cards-container .fui-card .fui-number-selector-container .fui-number-selector > div input {
          padding: 0;
          height: 36px;
          line-height: 36px; }
      .fui-radio-cards-container .fui-card .fui-number-selector-container .fui-number-selector [role="button"] {
        width: 40px;
        line-height: 36px; }

.fui-radio-cards-container .option-selector {
  display: flex;
  align-items: center;
  margin-top: 16px; }
  .fui-radio-cards-container .option-selector span.label {
    white-space: nowrap;
    margin-right: 16px; }

@media screen and (max-width: 1024px) {
  .fui-radio-cards-container .slick-slider .slick-track .slick-slide {
    min-width: 302px;
    max-width: 302px; } }

@media screen and (max-width: 960px) {
  .fui-radio-cards-container .slick-list {
    overflow: visible; }
  .fui-radio-cards-container .fui-cards.no-scrollbar .fui-card {
    width: calc(50% - 12px); }
  .fui-radio-cards-container .fui-card {
    width: 100%; }
    .fui-radio-cards-container .fui-card.is-plan {
      min-width: 290px;
      width: 290px;
      max-width: 290px; }
    .fui-radio-cards-container .fui-card.is-checkbox::after {
      width: 24px;
      height: 24px;
      line-height: 20px; }
    .fui-radio-cards-container .fui-card .fui-card-caption .fui-card-title {
      height: 46px;
      font-size: 1rem; }
      .fui-radio-cards-container .fui-card .fui-card-caption .fui-card-title span.tag {
        font-size: 0.75rem; }
    .fui-radio-cards-container .fui-card .fui-card-extra,
    .fui-radio-cards-container .fui-card .fui-card-caption {
      padding-left: 12px;
      padding-right: 12px; }
    .fui-radio-cards-container .fui-card .fui-card-action {
      padding-top: 24px; }
    .fui-radio-cards-container .fui-card .fui-number-selector-container {
      padding-left: 12px;
      padding-right: 12px;
      padding-bottom: 12px; }
      .fui-radio-cards-container .fui-card .fui-number-selector-container .fui-number-selector > div {
        position: relative; }
        .fui-radio-cards-container .fui-card .fui-number-selector-container .fui-number-selector > div input {
          left: 0;
          top: 0;
          width: 100%; } }

.tag-list {
  display: inline-block; }
  .tag-list .tag {
    background-color: #5F6E84;
    color: #FFFFFF;
    padding: 4px;
    font-size: 16px;
    padding: 2px 16px;
    border-radius: 25px;
    margin-right: 6px;
    display: inline-flex;
    margin-bottom: 6px; }

.react-select-container .react-select__control {
  border: 2px solid #D6D6D6;
  border-radius: 0;
  height: 50px;
  box-shadow: none !important; }
  .react-select-container .react-select__control.react-select__control--is-focused {
    border-color: #5F6E84 !important; }

.react-select-container .react-select__value-container .react-select__placeholder {
  color: #202020; }

.react-select-container .react-select__indicator {
  padding: 0;
  margin-right: 6px;
  font-family: 'icomoon' !important;
  speak: none;
  color: #202020;
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
  .react-select-container .react-select__indicator::before {
    content: '\e906'; }
  .react-select-container .react-select__indicator svg {
    display: none;
    visibility: hidden;
    width: 28px;
    height: 28px;
    fill: #202020; }

.react-select-container .react-select__indicator-separator {
  background: none; }

.react-select-container .react-select__menu {
  border: 0;
  margin-top: 0;
  box-shadow: 0 24px 48px 0 rgba(75, 88, 105, 0.36); }
  .react-select-container .react-select__menu .react-select__menu-list .react-select__option.react-select__option--is-focused,
  .react-select-container .react-select__menu .react-select__menu-list .react-select__option:hover {
    background: #E8ECF0; }
  .react-select-container .react-select__menu .react-select__menu-list .react-select__option.react-select__option--is-selected {
    color: #EF3123; }

.emma-service .emma-customize {
  display: block;
  font-weight: 500;
  height: 46px;
  line-height: 20px;
  padding: 3px 0;
  padding-left: 16px; }

@media screen and (min-width: 960px) {
  .emma-service .emma-customize {
    height: 54px;
    line-height: 24px;
    padding: 3px 0;
    padding-left: 24px; } }

.is-green {
  color: green !important; }

.is-orange {
  color: orange !important; }

.is-red {
  color: red !important; }

.is-green-bg {
  background-color: green !important; }

.is-orange-bg {
  background-color: orange !important; }

.is-red-bg {
  background-color: red !important; }

article.mainBoxHd {
  position: relative;
  height: 300px;
  background: #d5d5d5; }
  article.mainBoxHd .kvComplex {
    position: absolute;
    top: 0;
    left: 50%;
    width: 1920px;
    height: 300px;
    margin: 0 0 0 -960px; }
  article.mainBoxHd .mainBox {
    position: absolute;
    top: 86px;
    bottom: 40px;
    left: 50%;
    width: 1240px;
    margin: 0 0 0 -620px; }
    article.mainBoxHd .mainBox.postpaid, article.mainBoxHd .mainBox.globalRoaming {
      top: 60px;
      bottom: 60px; }
    article.mainBoxHd .mainBox .mainTitle {
      position: absolute;
      top: 0;
      left: 0;
      padding: 0;
      color: #fff;
      font-size: 40px;
      font-weight: bold;
      line-height: 1.2em;
      text-shadow: 0 0 5px rgba(0, 0, 0, 0.35);
      z-index: 1; }
    article.mainBoxHd .mainBox.postpaid .mainTitle, article.mainBoxHd .mainBox.globalRoaming .mainTitle {
      position: relative;
      top: auto;
      left: auto;
      margin: 0 0 10px 0;
      color: #666;
      text-shadow: 0 0 0 rgba(0, 0, 0, 0); }
    article.mainBoxHd .mainBox .subTitle {
      margin: 0 0 5px 0;
      color: #eee;
      font-size: 24px;
      font-weight: bold;
      line-height: 1.2em;
      text-shadow: 0 0 5px rgba(0, 0, 0, 0.35);
      clear: both; }
    article.mainBoxHd .mainBox .mainBoxBg {
      position: absolute;
      top: 0;
      left: 0;
      width: 470px;
      padding: 30px 15px;
      background: rgba(255, 255, 255, 0.8);
      box-sizing: border-box;
      z-index: 1; }
    article.mainBoxHd .mainBox .text {
      color: #666;
      font-size: 15px;
      line-height: 1.3em; }
    article.mainBoxHd .mainBox .selectBox {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1; }
      article.mainBoxHd .mainBox .selectBox:after {
        content: "";
        display: table;
        clear: both; }
      article.mainBoxHd .mainBox .selectBox .selectClass {
        float: left;
        width: 790px; }
      article.mainBoxHd .mainBox .selectBox .btnComplex, article.mainBoxHd .mainBox .selectBox .boxComplex {
        float: left; }
      article.mainBoxHd .mainBox .selectBox .selectClass .boxComplex {
        width: 186px; }
        article.mainBoxHd .mainBox .selectBox .selectClass .boxComplex:nth-child(3) {
          width: 330px; }
        article.mainBoxHd .mainBox .selectBox .selectClass .boxComplex .selectboxit-container .selectboxit-options {
          max-height: 240px !important; }
      article.mainBoxHd .mainBox .selectBox .selectClass .btnComplex {
        position: relative;
        left: -1px;
        width: 82px;
        z-index: 1; }
      article.mainBoxHd .mainBox .selectBox .selectKeywords {
        float: right;
        width: 410px; }
        article.mainBoxHd .mainBox .selectBox .selectKeywords .boxComplex {
          width: 325px; }
      article.mainBoxHd .mainBox .selectBox .schKeywords .btnComplex, article.mainBoxHd .mainBox .selectBox .selectKeywords .btnComplex {
        position: relative;
        left: -1px;
        width: 82px;
        z-index: 1; }
  article.mainBoxHd .selectboxit-container span {
    height: 50px;
    line-height: 50px; }
  article.mainBoxHd .selectboxit .selectboxit-option-icon-container {
    margin-left: 10px; }
  article.mainBoxHd .selectboxit-arrow-container {
    width: 46px; }
  article.mainBoxHd .selectboxit-text {
    color: #666;
    font-size: 16px; }
  article.mainBoxHd .selectboxit-default-arrow {
    display: none; }
  article.mainBoxHd .mainBox .selectBox input[type="text"] {
    width: 100%;
    color: #666;
    font-size: 16px;
    line-height: 50px;
    height: 50px;
    text-indent: 10px;
    border: 0;
    padding: 0;
    margin: 0;
    background: #fff;
    -webkit-appearance: none;
    border-radius: 0; }
  article.mainBoxHd .mainBox .selectBox input[type="submit"] {
    width: 100%;
    color: #e1e1e1;
    font-size: 16px;
    line-height: 50px;
    height: 50px;
    border: 0;
    padding: 0;
    margin: 0;
    cursor: pointer;
    background: #909090;
    -webkit-appearance: none;
    border-radius: 0; }
    article.mainBoxHd .mainBox .selectBox input[type="submit"]:hover {
      background: #666; }
  article.mainBoxHd .mainBox .selectBox .schKeywords {
    width: 810px;
    margin: 0 auto; }
    article.mainBoxHd .mainBox .selectBox .schKeywords .boxComplex {
      width: 720px;
      height: 40px;
      vertical-align: middle;
      background: #fff; }
    article.mainBoxHd .mainBox .selectBox .schKeywords .btnComplex {
      height: 40px;
      vertical-align: middle; }
  article.mainBoxHd.single {
    height: auto;
    padding: 15px 0; }
    article.mainBoxHd.single .mainBox {
      position: relative;
      top: auto;
      bottom: auto;
      left: auto;
      width: 1280px;
      margin: 0 auto; }
      article.mainBoxHd.single .mainBox .selectBox {
        position: relative; }
      article.mainBoxHd.single .mainBox .subTitle {
        float: left;
        height: 40px;
        line-height: 40px;
        margin: 0 20px 0 0;
        color: #555;
        font-size: 20px;
        text-shadow: 0 0 0 rgba(0, 0, 0, 0); }
    article.mainBoxHd.single .selectboxit-container span {
      height: 40px;
      line-height: 40px; }
    article.mainBoxHd.single .mainBox .selectBox input[type="text"], article.mainBoxHd.single .mainBox .selectBox input[type="submit"] {
      vertical-align: middle; }
    article.mainBoxHd.single .mainBox .selectBox input[type="text"] {
      height: 40px;
      line-height: 38px; }
    article.mainBoxHd.single .mainBox .selectBox input[type="submit"] {
      height: 40px;
      line-height: 40px; }
    article.mainBoxHd.single .mainBox .selectBox .selectKeywords {
      width: 360px; }
  article.mainBoxHd .mainBox .selectBox .selectKeywords .boxComplex {
    width: 275px; }
  article.mainBoxHd.single .mainBox .relatedBox {
    width: 810px;
    margin: 10px auto 5px auto;
    color: #333;
    font-size: 15px;
    line-height: 1.4em; }
    article.mainBoxHd.single .mainBox .relatedBox a {
      font-weight: bold; }
    article.mainBoxHd.single .mainBox .relatedBox b {
      color: #e71a0f; }

article.recommendBox {
  margin: 30px auto 0 auto; }
  article.recommendBox:first-child {
    margin: 0 auto; }
  article.recommendBox:after {
    content: "";
    display: table;
    clear: both; }
  article.recommendBox.first {
    border-top: 3px solid #e71a0f; }

article .tabs > ul {
  margin: 0 0 15px 0;
  border-bottom: 2px #e71a0f solid; }

article .tabs.unit > ul {
  margin: 0 0 15px 0;
  border-bottom: 3px #c7c6c6 solid; }

article .tabs.unit > ul li {
  margin: 0 0 -3px 0; }

article .tabs > ul li {
  position: relative;
  padding: 10px 0;
  display: inline-block;
  line-height: 20px;
  margin: 0 0 -2px 0; }

article.recommendBox .tabs .slickRetail.slick-slider {
  border-bottom: 0;
  margin: 0 0 10px 0;
  height: 300px;
  overflow: hidden; }

article .tabs ul.slickRetail {
  border-bottom: 0;
  margin: 0 0 10px 0;
  height: 300px;
  overflow: hidden; }
  article .tabs ul.slickRetail li {
    padding: 0;
    line-height: normal;
    line-height: initial;
    margin: 0; }

article .tabs.unit {
  position: relative; }
  article .tabs.unit > ul ul.slick-dots li {
    padding: 0;
    margin: 0 7px;
    line-height: normal;
    line-height: initial; }
  article .tabs.unit > ul li a {
    font-size: 18px; }
  article .tabs.unit > ul li.ui-tabs-active {
    border-bottom: 3px #e71a0f solid; }
  article .tabs.unit > ul li.ui-tabs-active:after {
    display: none; }
  article .tabs.unit .language {
    text-align: right; }
    article .tabs.unit .language a {
      display: inline-block;
      width: 30px;
      height: 30px;
      line-height: 30px;
      margin: 0 0 0 10px;
      text-align: center;
      color: #000;
      font-size: 15px;
      background: #ffffff; }
      article .tabs.unit .language a:hover {
        color: #e71a0f;
        background: #d2d2d2; }
      article .tabs.unit .language a.on {
        color: #e71a0f;
        background: #ffffff; }
        article .tabs.unit .language a.on:hover {
          color: #e71a0f;
          background: #ffffff; }

article .tabs > ul li.arrow {
  padding: 10px 20px 10px 0; }

article .tabs > ul li a {
  display: block;
  font-size: 16px;
  font-weight: bold;
  padding: 0 20px;
  border-left: 1px #c7c6c6 solid; }

article .tabs > ul li:nth-child(1) a {
  border-left: 0; }

article .tabs > ul li.arrow a {
  background-size: 16px auto; }

article .tabs > ul li.ui-tabs-active {
  border-bottom: 2px #e71a0f solid; }
  article .tabs > ul li.ui-tabs-active a {
    color: #e71a0f; }

article .tabs > ul li.arrow a:hover {
  background-size: 16px auto; }

article .tabs > ul li.arrow.ui-tabs-active a {
  background-size: 16px auto; }
  article .tabs > ul li.arrow.ui-tabs-active a:hover {
    background-size: 16px auto; }

article .tabs > ul li.ui-tabs-active:after {
  bottom: 0;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-bottom-color: #e71a0f;
  border-width: 8px;
  border-left-width: 10px;
  border-right-width: 10px;
  margin-left: -10px; }

article .tabs ol.subtab {
  margin: -15px 0 15px 0;
  padding: 10px;
  border-bottom: 1px #c7c7c7 solid;
  background: #fff;
  box-sizing: border-box; }
  article .tabs ol.subtab li {
    position: relative;
    display: inline-block;
    padding: 5px 10px;
    margin: 0 10px 0 0; }

article .tabs ol.subTabMin li {
  position: relative;
  display: inline-block;
  padding: 5px 10px;
  margin: 0 10px 0 0; }

article .tabs ol.subtab li:after, article .tabs ol.subTabMin li:after {
  position: absolute;
  top: 10px;
  left: 2px;
  content: "";
  width: 3px;
  height: 3px;
  background: #333;
  border-radius: 1.5px; }

article .tabs ol.subtab li a {
  font-size: 16px; }

article .tabs ol.subTabMin li a {
  font-size: 15px; }

article .tabs ol.subtab li.active a, article .tabs ol.subTabMin li.active a {
  color: #e71a0f; }

article .tabs ol.subtab li.active:after {
  background: #e71a0f; }

article .tabs ol.subTabMin {
  margin: -25px 0 20px 0;
  padding: 5px 10px;
  border-bottom: 1px #c7c7c7 solid;
  background: #f4f4f4;
  box-sizing: border-box; }
  article .tabs ol.subTabMin li.active:after {
    background: #e71a0f; }

article.locationBox .toolBox {
  width: 100%;
  margin: 0 0 10px 0; }

article.recommendBox .toolBox {
  width: 100%;
  margin: 0 0 10px 0; }
  article.recommendBox .toolBox.bottom {
    margin: 20px 0 0 0; }

article.locationBox .toolBox:after, article.recommendBox .toolBox:after {
  content: "";
  display: table;
  clear: both; }

article.locationBox .toolBox .searchHead, article.recommendBox .toolBox .searchHead {
  position: relative;
  float: left;
  width: 458px;
  padding: 2px 0 2px 10px;
  font-size: 16px;
  line-height: 24px;
  color: #333; }

article.locationBox .toolBox .searchHead:after, article.recommendBox .toolBox .searchHead:after {
  position: absolute;
  top: 8%;
  left: 0;
  content: "";
  width: 3px;
  height: 82%;
  background: #e71a0f; }

article.locationBox .toolBox .searchHead b {
  color: #e71a0f; }

article.recommendBox .toolBox .searchHead b {
  color: #e71a0f; }

article.recommendBox .toolBox .text {
  clear: both;
  padding: 20px 0 0 0;
  font-size: 15px;
  line-height: 1.6em; }
  article.recommendBox .toolBox .text p {
    margin: 0 0 10px 0; }

article.recommendBox .toolBox .sortbyBox {
  float: left;
  font-size: 15px;
  line-height: 30px;
  margin: 0 20px 0 0; }
  article.recommendBox .toolBox .sortbyBox span {
    display: inline-block; }
    article.recommendBox .toolBox .sortbyBox span img {
      max-width: 16px;
      vertical-align: middle; }
  article.recommendBox .toolBox .sortbyBox a {
    position: relative;
    padding: 0 16px 0 0;
    margin: 0 10px 0 0; }
    article.recommendBox .toolBox .sortbyBox a:after {
      top: 50%;
      right: 0;
      border: solid transparent;
      content: "";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-top-color: #666666;
      border-width: 7px;
      border-left-width: 6px;
      border-right-width: 6px;
      margin-top: -4px; }
    article.recommendBox .toolBox .sortbyBox a.active, article.recommendBox .toolBox .sortbyBox a:hover:after, article.recommendBox .toolBox .sortbyBox a.active:after {
      color: #ef3123;
      border-top-color: #ef3123; }

article.recommendBox .mainBox {
  text-align: center; }
  article.recommendBox .mainBox.left {
    position: relative;
    float: left;
    width: 360px;
    height: 570px;
    padding: 10px 18px 160px 18px; }
  article.recommendBox .mainBox.right {
    float: right;
    width: 906px;
    height: 570px;
    padding: 40px 15px 0 15px; }
  article.recommendBox .mainBox .photo {
    position: relative;
    display: table;
    width: 250px;
    height: 250px;
    text-align: center;
    margin: 0 auto 10px auto; }
    article.recommendBox .mainBox .photo > a {
      display: table-cell;
      vertical-align: middle; }
    article.recommendBox .mainBox .photo .msgBox {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 70%;
      height: 40px;
      line-height: 40px;
      margin: auto;
      font-size: 13px;
      text-align: center;
      color: #fff;
      background: rgba(0, 0, 0, 0.6);
      box-sizing: border-box;
      z-index: 1; }
    article.recommendBox .mainBox .photo img {
      display: inline;
      height: 100%;
      max-height: 250px;
      max-width: 250px; }
  article.recommendBox .mainBox.right .photo {
    max-width: 230px;
    max-height: 230px; }

article.recommendBox ul.listBox.mainBox .photo, article.recommendBox .mainBox.right .photo img, article.recommendBox ul.listBox.mainBox .photo img {
  max-width: 230px;
  max-height: 230px; }

article.recommendBox .mainBox .line {
  padding: 20px 0 10px 0;
  border: 1px #d8d8d8 solid; }

article.recommendBox .mainBox .iconBox {
  position: relative;
  height: 54px;
  padding: 10px 0 20px 0; }
  article.recommendBox .mainBox .iconBox .notifyBox {
    position: absolute;
    bottom: 62px;
    left: 7px;
    width: 258px;
    height: 68px; }
    article.recommendBox .mainBox .iconBox .notifyBox .notifyBoxBd {
      height: 58px;
      padding: 8px 40px 8px 10px;
      font-size: 15px;
      line-height: 1.4em;
      text-align: left;
      color: #fff;
      background: #835935;
      box-sizing: border-box;
      z-index: 1; }

article.recommendBox .mainBox.listBox .iconBox .notifyBox {
  bottom: 72px; }

article.recommendBox .mainBox.left .iconBox .notifyBox {
  bottom: 72px;
  width: 312px; }

article.recommendBox .mainBox .iconBox .notifyBox span.buy {
  padding: 0 15px 0 5px;
  font-weight: bold;
  background-size: 20px auto; }

article.recommendBox .mainBox .iconBox .notifyBox a {
  color: #fff; }
  article.recommendBox .mainBox .iconBox .notifyBox a.close {
    position: absolute;
    top: 7px;
    right: 7px;
    display: inline-block;
    width: 20px;
    height: 20px;
    font-size: 0;
    cursor: pointer;
    background-size: 20px auto; }
  article.recommendBox .mainBox .iconBox .notifyBox a:hover {
    color: #fff; }

article.recommendBox .mainBox .iconBox ul {
  padding: 0 4px 0 0;
  text-align: center; }
  article.recommendBox .mainBox .iconBox ul li {
    display: inline-block;
    padding: 2px 4px;
    margin: 0 -4px 0 0;
    border-left: 1px #c3a78a solid; }
    article.recommendBox .mainBox .iconBox ul li:nth-child(1) {
      border: 0; }
    article.recommendBox .mainBox .iconBox ul li:hover {
      background: #e5e5e5; }
    article.recommendBox .mainBox .iconBox ul li > a {
      position: relative;
      display: block; }
    article.recommendBox .mainBox .iconBox ul li.msgComplex > a:after {
      top: -10px;
      left: 21px;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-top-color: #835935;
      border-width: 8px;
      border-left-width: 9px;
      border-right-width: 9px;
      filter: alpha(opacity=0);
      -moz-opacity: 0;
      opacity: 0;
      transition: all 0.4s; }
    article.recommendBox .mainBox .iconBox ul li.msgComplex.arrow > a:after {
      filter: alpha(opacity=100);
      -moz-opacity: 1;
      opacity: 1;
      transition: all 0.4s; }

article.recommendBox .mainBox .iconBox img {
  display: inline;
  width: 54px; }

article.recommendBox .mainBox.right .iconBox {
  padding: 10px 0 10px 0; }

article.recommendBox .mainBox .title {
  position: relative;
  width: 100%;
  margin: 0 auto 10px auto;
  padding: 10px 0 0 0;
  border-top: 1px #d8d8d8 solid;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5em;
  height: 3em;
  vertical-align: middle;
  z-index: 1;
  height: 48px;
  overflow: hidden; }
  article.recommendBox .mainBox .title .limitHigh {
    width: 100%;
    display: table;
    height: 48px; }

article.recommendBox .mainBox.left .iconBox {
  padding: 0 0 20px 0; }

article.recommendBox .mainBox.right .title {
  width: 90%;
  border-top: 0; }

article.recommendBox .mainBox .title span {
  display: table-cell;
  vertical-align: top; }

article.recommendBox .mainBox.left .title span {
  vertical-align: middle; }

article.recommendBox .mainBox .title a.icon {
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 25px;
  height: 24px; }
  article.recommendBox .mainBox .title a.icon img {
    position: absolute;
    top: 0;
    left: 0;
    max-width: 25px;
    max-height: 24px; }
    article.recommendBox .mainBox .title a.icon img.link {
      display: block; }

article.recommendBox .mainBox .title a:hover.icon img.hover {
  display: block; }

article.recommendBox .mainBox .title a.icon img.hover, article.recommendBox .mainBox .title a:hover.icon img.link {
  display: none; }

article.recommendBox .mainBox .specs {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 10px 0;
  height: 140px;
  color: #fff;
  text-align: left;
  background: #999999; }

article.recommendBox .mainBox.left .specs {
  padding: 10px; }

article.recommendBox .mainBox.listBox .specs {
  position: relative;
  height: 160px;
  color: #666;
  background: none;
  border-top: 1px solid #d8d8d8; }

article.recommendBox .mainBox .specs table {
  margin: 0 -4px; }

article.recommendBox .mainBox.left .specs table {
  margin: 0; }

article.recommendBox .mainBox .specs th, article.recommendBox .mainBox .specs td {
  font-weight: normal;
  padding: 4px 4px;
  word-wrap: break-word;
  word-break: normal;
  word-break: break-all; }

article.recommendBox .mainBox .specs .limitHigh {
  max-height: 16px;
  overflow: hidden; }
  article.recommendBox .mainBox .specs .limitHigh.rows2 {
    max-height: 32px; }
  article.recommendBox .mainBox .specs .limitHigh.rows3 {
    max-height: 48px; }

article.recommendBox .mainBox .specs th {
  width: 55px; }

article.recommendBox .mainBox .specs.dePad th {
  width: 85px; }

article.recommendBox .mainBox .slickLi {
  margin: 0 9px; }

article.recommendBox .slick-slider .slick-prev {
  left: -30px; }

article.recommendBox .slick-slider .slick-next {
  right: -30px; }

article.recommendBox .seqComplex {
  position: relative;
  width: 100%;
  height: 300px;
  max-width: 100%;
  overflow: hidden;
  margin: 0 auto;
  padding: 0; }
  article.recommendBox .seqComplex .seqBoxComplex {
    position: relative;
    height: 100%; }

/* single */
/**
 **
 ** recommendBox
 **
 ** */
/**
 **
 ** toolBox
 **
 ** */
/*-----------------------------------------------------------------------------
 *sequencejs jQuery Plugin
 *----------------------------------------------------------------------------- */
.seqComplex .seq-screen, .seqComplex .seq-canvas, .seqComplex .seq-pagination {
  margin: 0;
  padding: 0;
  list-style: none; }

.seqComplex .seq-canvas > * {
  margin: 0;
  padding: 0;
  list-style: none;
  height: 100%;
  width: 100%; }

.seqComplex .seq-screen {
  height: 100%;
  width: 100%; }

.seqComplex .seq-canvas {
  height: 100%;
  width: 100%; }
  .seqComplex .seq-canvas > * {
    position: absolute;
    transition-property: left, opacity; }
    .seqComplex .seq-canvas > *:before {
      content: "";
      display: inline-block;
      vertical-align: middle;
      height: 100%; }

.seqComplex .seq-preloader {
  background: #f8f8f8; }

.seqComplex .navigationBox {
  position: absolute;
  left: 0;
  z-index: 10;
  bottom: 20px;
  width: 100%; }

.seqComplex .seq-pagination {
  max-width: 1246px;
  padding: 0 60px;
  margin: 0 auto;
  border: none;
  text-align: center; }
  .seqComplex .seq-pagination a {
    display: block;
    width: 100%;
    height: 100%; }
  .seqComplex .seq-pagination .seq-current {
    background: #333; }
  .seqComplex .seq-pagination > * {
    margin: 0 4px;
    padding: 0;
    display: inline-block;
    zoom: 1;
    width: 10px;
    height: 10px;
    cursor: pointer;
    font: 0 / 0 a;
    text-shadow: none;
    color: transparent;
    background: #999;
    transition: all 0.3s;
    border-radius: 10px;
    border: 1px rgba(255, 255, 255, 0) solid;
    box-shadow: 0 0 0 rgba(255, 255, 255, 0);
    border: 1px #fff solid;
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.7); }

.seqComplex.seq-active {
  overflow: hidden; }
  .seqComplex.seq-active .seq-pagination {
    display: block; }
  .seqComplex.seq-active .seq-preloader, .seqComplex.seq-active .seq-pagination {
    visibility: visible; }
  .seqComplex.seq-active .seq-preloader.seq-preloaded {
    visibility: hidden; }

article.recommendBox .slickRetail {
  border-top: 3px #e71a0f solid; }
  article.recommendBox .slickRetail.slick-slider {
    margin: 0; }
  article.recommendBox .slickRetail .slick-dots {
    bottom: 30px; }
  article.recommendBox .slickRetail .retailBox {
    padding: 0 160px 0 60px;
    background: #fff; }
    article.recommendBox .slickRetail .retailBox:after {
      content: "";
      display: table;
      clear: both; }
    article.recommendBox .slickRetail .retailBox > a {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: block;
      border: 0;
      z-index: 1;
      background-color: rgba(0, 0, 0, 0);
      transition: all 200ms linear; }
      article.recommendBox .slickRetail .retailBox > a:hover {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: block;
        border: 0;
        background-color: rgba(0, 0, 0, 0.1); }
    article.recommendBox .slickRetail .retailBox .iconRestriction {
      position: absolute;
      right: 30px;
      bottom: 15px;
      width: 180px;
      height: 180px; }
    article.recommendBox .slickRetail .retailBox .photo {
      float: left;
      width: 300px;
      height: 300px;
      text-align: center; }
      article.recommendBox .slickRetail .retailBox .photo img {
        max-width: 250px;
        max-height: 300px;
        margin: 0 auto; }
    article.recommendBox .slickRetail .retailBox .conBoxComplex {
      float: right;
      width: 710px;
      height: 300px;
      padding: 40px 0 60px 0;
      box-sizing: border-box; }
      article.recommendBox .slickRetail .retailBox .conBoxComplex .title {
        font-size: 24px;
        font-weight: bold;
        line-height: 1.4em;
        color: #333; }
      article.recommendBox .slickRetail .retailBox .conBoxComplex .text {
        width: 550px;
        margin: 15px 0 0 0;
        font-size: 18px;
        line-height: 1.4em;
        color: #666; }
      article.recommendBox .slickRetail .retailBox .conBoxComplex .date {
        margin: 15px 0 0 0;
        font-size: 18px;
        line-height: 1.4em;
        color: #e71a0f; }

article.recommendBox ul.listBox.mainBox {
  margin: -0.6%; }
  article.recommendBox ul.listBox.mainBox:after {
    content: "";
    display: table;
    clear: both; }
  article.recommendBox ul.listBox.mainBox > li {
    position: relative;
    float: left;
    width: 23.8%;
    margin: 1% 0.6% 0 0.6%;
    padding: 18px 18px 0 18px;
    box-sizing: border-box; }
  article.recommendBox ul.listBox.mainBox li .label {
    position: absolute;
    top: 0;
    right: 0;
    width: 66px;
    height: 64px;
    z-index: 1; }
  article.recommendBox ul.listBox.mainBox .title span {
    vertical-align: middle; }

article.rateBox .moreBottom, article.recommendBox .moreBottom {
  height: 44px;
  margin: 30px auto 0 auto;
  text-align: center; }

article.rateBox .moreBottom .btn, article.recommendBox .moreBottom .btn {
  display: inline-block;
  vertical-align: middle; }

article.rateBox .moreBottom .btn a, article.recommendBox .moreBottom .btn a {
  display: block;
  width: 164px;
  height: 44px;
  line-height: 44px;
  padding: 0 15px 0 10px;
  color: #fff;
  font-size: 15px;
  border: 1px #333 solid;
  background: #666; }

article.rateBox .moreBottom .btn.down a, article.recommendBox .moreBottom .btn.down a, article.rateBox .moreBottom .btn a:hover, article.recommendBox .moreBottom .btn a:hover {
  background: #666; }

article.rateBox .moreBottom .btn.down a img, article.recommendBox .moreBottom .btn.down a img {
  border: 0 none;
  height: 16px;
  position: relative;
  top: -2px;
  width: 16px; }

article.posRecBox {
  margin: 40px auto 0 auto;
  max-width: 1280px; }
  article.posRecBox:first-child {
    margin: 0 auto; }
  article.posRecBox:after {
    content: "";
    display: table;
    clear: both; }
  article.posRecBox .listBox {
    width: 100%; }
    article.posRecBox .listBox .slickLi:after {
      content: "";
      display: table;
      clear: both; }
    article.posRecBox .listBox.slick-slider {
      margin-bottom: 10px; }
    article.posRecBox .listBox .slick-dots {
      bottom: 0; }
  article.posRecBox .slick-slider .slick-prev, article.posRecBox .slick-slider .slick-next {
    margin-top: -45px; }
  article.posRecBox .slick-slider .slick-prev {
    left: -30px; }
  article.posRecBox .slick-slider .slick-next {
    right: -30px; }
  article.posRecBox .listBox .slickLi {
    position: relative;
    margin: 0 0 20px 0; }
  article.posRecBox .listBox.slickLoyalty .slickLi {
    margin: 0 0 25px 0; }
  article.posRecBox .listBox .slickLi:last-child {
    margin: 0; }
  article.posRecBox .listBox .slickLi > a {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 5; }
  article.posRecBox .listBox .slickLi .photo {
    position: relative;
    float: left;
    width: 952px;
    height: 232px;
    overflow: hidden; }
    article.posRecBox .listBox .slickLi .photo img {
      max-width: 950px;
      max-height: 230px;
      border: 1px #e2e2e2 solid; }
  article.posRecBox .listBox .slickLi .conBox {
    float: right;
    width: 328px;
    height: 232px;
    padding: 36px 32px;
    box-sizing: border-box; }
    article.posRecBox .listBox .slickLi .conBox .title {
      font-size: 20px;
      font-weight: bold;
      line-height: 1.2em;
      max-height: 50px;
      overflow: hidden; }
    article.posRecBox .listBox .slickLi .conBox .text {
      border-top: 1px #e1e1e1 solid;
      padding: 15px 0 0 0;
      margin: 15px 0 0 0;
      color: #666;
      font-size: 15px;
      line-height: 1.4em;
      height: 85px;
      overflow: hidden; }

article.posRecBoxV2 {
  margin: 22px auto;
  max-width: 1280px; }
  article.posRecBoxV2:first-child {
    margin: 0 auto 35px; }
  article.posRecBoxV2:last-child {
    margin: 22px auto 0; }
  article.posRecBoxV2:after {
    content: "";
    display: table;
    clear: both; }
  article.posRecBoxV2 .listBox {
    width: 100%;
    margin: -16px 0;
    padding: 0 24px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
    box-sizing: border-box; }
    article.posRecBoxV2 .listBox .slickLi:after {
      content: "";
      display: table;
      clear: both; }
    article.posRecBoxV2 .listBox .posRecItem {
      position: relative;
      width: 380px;
      margin: 16px 14px;
      border: 1px solid #e1e1e1;
      overflow: hidden;
      transition: all 0.3s linear; }
      article.posRecBoxV2 .listBox .posRecItem a {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
        z-index: 1; }
    article.posRecBoxV2 .listBox .photo {
      position: relative; }
      article.posRecBoxV2 .listBox .photo img {
        width: 100%; }
      article.posRecBoxV2 .listBox .photo .shadow {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        background-color: #000;
        transition: all 0.3s linear; }
    article.posRecBoxV2 .listBox .conBox {
      padding: 15px 20px;
      position: absolute;
      width: 380px;
      right: 0;
      left: 0;
      top: 172px;
      font-size: 15px;
      line-height: 20px;
      color: #666;
      opacity: 0.9;
      background-color: #fff;
      transition: all 0.3s linear;
      box-sizing: border-box; }
      article.posRecBoxV2 .listBox .conBox .title {
        height: 1.2em;
        margin-bottom: 10px;
        font-weight: bold;
        color: #333;
        overflow: hidden; }
      article.posRecBoxV2 .listBox .conBox .text {
        max-height: 4em;
        overflow: hidden; }
    article.posRecBoxV2 .listBox .posRecItem:hover .photo .shadow {
      opacity: 0.5; }
    article.posRecBoxV2 .listBox .posRecItem:hover .conBox {
      opacity: 1; }

article.roamBox {
  margin: 0 auto;
  max-width: 1280px; }
  article.roamBox .selectBox:after {
    content: "";
    display: table;
    clear: both; }
  article.roamBox .selectBox .selectClass {
    position: relative;
    background: #d5d5d5;
    box-sizing: border-box; }
  article.roamBox .selectBox .selectKeywords {
    position: relative;
    background: #d5d5d5;
    box-sizing: border-box;
    display: table-cell; }
  article.roamBox .selectBox .selectClass {
    display: table-cell;
    width: 802px;
    padding: 15px 15px 15px 35px; }
    article.roamBox .selectBox .selectClass:after {
      position: absolute;
      top: 50%;
      left: -25px;
      content: "or";
      width: 44px;
      height: 44px;
      line-height: 44px;
      text-align: center;
      color: #fff;
      font-size: 26px;
      font-weight: bold;
      margin: -22px 0 0 0;
      background: #adadad;
      border-radius: 22px;
      z-index: 1; }
  article.roamBox .selectBox .selectKeywords {
    width: 480px;
    padding: 15px 30px 15px 15px;
    border-right: 5px #fff solid; }
  article.roamBox .selectBox .selectClass .chauCon, article.roamBox .selectBox .selectKeywords .chauCon {
    position: absolute;
    top: 90px;
    left: 20px;
    width: 440px;
    margin: -1px;
    max-height: 460px;
    border: 1px #e5302a solid;
    background: #fff;
    z-index: 10;
    display: none; }
  article.roamBox .selectBox .selectClass .chauCon {
    left: 200px; }
    article.roamBox .selectBox .selectClass .chauCon .chauHd {
      position: relative;
      padding: 15px 20px;
      font-size: 20px;
      color: #fff;
      background: #e5302a; }
  article.roamBox .selectBox .selectKeywords .chauCon .chauHd {
    position: relative;
    padding: 15px 20px;
    font-size: 20px;
    color: #fff;
    background: #e5302a; }
  article.roamBox .selectBox .selectClass .chauCon .chauHd:after, article.roamBox .selectBox .selectKeywords .chauCon .chauHd:after {
    position: absolute;
    left: 50%;
    top: 0;
    width: 0;
    height: 0;
    content: "";
    margin: -10px 0 0 0;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent #e5302a transparent; }
  article.roamBox .selectBox .selectClass .chauCon .chauHd a, article.roamBox .selectBox .selectKeywords .chauCon .chauHd a {
    position: absolute;
    right: 15px;
    top: 50%;
    display: block;
    width: 28px;
    height: 28px;
    margin: -14px 0 0 0;
    background-size: 28px auto; }
  article.roamBox .selectBox .selectClass .chauCon .chauBd, article.roamBox .selectBox .selectKeywords .chauCon .chauBd {
    padding: 20px 0 20px 20px; }
  article.roamBox .selectBox .selectClass .chauCon .chauBd p {
    font-size: 15px; }
  article.roamBox .selectBox .selectKeywords .chauCon .chauBd p {
    font-size: 15px; }
  article.roamBox .selectBox .selectKeywords .chauCon .chauBd .scroll {
    max-height: 375px;
    overflow: scroll;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch; }
  article.roamBox .selectBox .selectKeywords .chauCon .chauBd ul {
    display: inline-block;
    vertical-align: top;
    width: 50%;
    margin: 0 -2px; }
    article.roamBox .selectBox .selectKeywords .chauCon .chauBd ul li {
      padding: 5px 10px;
      font-size: 14px;
      line-height: 18px;
      box-sizing: border-box; }
      article.roamBox .selectBox .selectKeywords .chauCon .chauBd ul li.selected a, article.roamBox .selectBox .selectKeywords .chauCon .chauBd ul li a:hover {
        color: #e5302a; }

/* media
 **---------------------------------------------------------------------------- */
/**
 **
 ** Desktops
 **
 ** */
@media only screen and (max-width: 1280px) {
  article.recommendBox .slick-slider .slick-prev {
    left: -10px; }
  article.recommendBox .slick-slider .slick-next {
    right: -10px; } }

/**
 **
 ** posRecBox
 **
 ** */
/**
 **
 ** posRecBox  v2
 **
 ** */
/* hover effect */
/**
 **
 ** rateBox
 **
 ** */
.selectKeywords .suggestCon {
  position: absolute;
  top: 56px;
  left: 150px;
  width: 209px;
  max-height: 209px;
  padding: 0;
  border-top: 1px #dcdcdc solid;
  background: #fff;
  display: none;
  z-index: 11;
  *visibility: hidden; }
  .selectKeywords .suggestCon ul li a {
    display: block;
    padding: 0 5px;
    color: #333;
    font: 13px / 30px "source-han-sans-traditional", "Noto Sans CJK TC", "Microsoft JhengHei", Helvetica, Arial, sans-serif;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden; }
  .selectKeywords .suggestCon ul li.selected a, .selectKeywords .suggestCon ul li a:hover {
    color: #333;
    background: #dddddd; }

article.roamBox .selectBox .subTitle {
  float: left;
  height: 40px;
  line-height: 40px;
  margin: 0 15px 0 0;
  color: #666;
  font-size: 15px;
  text-shadow: 0 0 0 rgba(0, 0, 0, 0); }

article.roamBox .selectBox .error {
  margin: 8px 0 0 120px;
  color: #e71a0f; }

article.roamBox .selectBox .box, article.roamBox .selectBox .btn {
  display: table-cell;
  vertical-align: middle; }

article.roamBox .selectBox .selectClass .box {
  width: 240px; }
  article.roamBox .selectBox .selectClass .box:nth-child(3) {
    width: 310px; }

article.roamBox .selectBox .selectKeywords .box {
  width: 210px; }

article.roamBox .selectBox .btn {
  position: relative;
  left: -1px;
  width: 82px;
  z-index: 1; }

article.roamBox .selectboxit-container span {
  height: 40px;
  line-height: 40px; }

article.roamBox .selectboxit .selectboxit-option-icon-container {
  margin-left: 10px; }

article.roamBox .selectboxit-arrow-container {
  width: 46px; }

article.roamBox .selectboxit-text {
  color: #666;
  font-size: 16px; }

article.roamBox .selectboxit-default-arrow {
  display: none; }

article.roamBox .selectBox input[type="text"] {
  width: 100%;
  color: #666;
  font-size: 16px;
  line-height: 38px;
  height: 38px;
  text-indent: 10px;
  border: 0;
  background: #fff;
  -webkit-appearance: none;
  border-radius: 0; }

article.roamBox .selectBox input[type="submit"] {
  width: 100%;
  color: #e1e1e1;
  font-size: 16px;
  line-height: 40px;
  border: 0;
  cursor: pointer;
  background: #909090;
  -webkit-appearance: none;
  border-radius: 0; }

article.roamBox .globalRoamingMap {
  position: relative;
  width: 1280px;
  height: 550px;
  background: #fff;
  overflow: hidden;
  opacity: 1;
  transition: opacity 0.5s; }
  article.roamBox .globalRoamingMap .schKeyMask {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    opacity: 0.7;
    z-index: 8;
    display: none; }
  article.roamBox .globalRoamingMap .mapBg {
    position: absolute;
    top: 0;
    left: 0;
    width: 1280px;
    height: 550px;
    background-size: 1280px auto; }
    article.roamBox .globalRoamingMap .mapBg div {
      position: absolute;
      top: 0;
      left: 0;
      width: 1280px;
      height: 550px;
      display: none; }
      article.roamBox .globalRoamingMap .mapBg div img {
        max-width: 1280px; }
  article.roamBox .globalRoamingMap.Africa .mapBg div.Africa, article.roamBox .globalRoamingMap.Europe .mapBg div.Europe, article.roamBox .globalRoamingMap.America .mapBg div.America, article.roamBox .globalRoamingMap.Asia .mapBg div.Asia, article.roamBox .globalRoamingMap.MiddleEast .mapBg div.MiddleEast {
    display: block; }
  article.roamBox .globalRoamingMap .mapBg div.Africa.on, article.roamBox .globalRoamingMap .mapBg div.Europe.on, article.roamBox .globalRoamingMap .mapBg div.America.on, article.roamBox .globalRoamingMap .mapBg div.Asia.on, article.roamBox .globalRoamingMap .mapBg div.MiddleEast.on {
    display: block; }
  article.roamBox .globalRoamingMap .chau {
    position: absolute;
    cursor: pointer;
    z-index: 5;
    transition: all 0.5s; }
    article.roamBox .globalRoamingMap .chau.Africa {
      top: 260px;
      left: 230px; }
    article.roamBox .globalRoamingMap .chau.Europe {
      top: 50px;
      left: 220px; }
    article.roamBox .globalRoamingMap .chau.America {
      top: 80px;
      left: 790px; }
    article.roamBox .globalRoamingMap .chau.Asia {
      top: 80px;
      left: 380px; }
    article.roamBox .globalRoamingMap .chau.MiddleEast {
      top: 160px;
      left: 280px; }
    article.roamBox .globalRoamingMap .chau .point {
      position: absolute;
      bottom: 0;
      left: 50%;
      z-index: 2;
      width: 40px;
      height: 40px;
      margin: -20px 0 0 -20px;
      background-color: rgba(144, 144, 144, 0.5);
      border-radius: 50%;
      transition: width 0.5s, height 0.5s, margin 0.5s; }
      article.roamBox .globalRoamingMap .chau .point:after {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        content: "";
        width: 12px;
        height: 12px;
        border: 5px #909090 solid;
        background: #fff;
        border-radius: 50%; }
    article.roamBox .globalRoamingMap .chau .place {
      position: relative;
      padding: 15px 20px;
      margin: 0 0 42px;
      color: #fff;
      font-size: 16px;
      background-color: #909090;
      z-index: 3; }
      article.roamBox .globalRoamingMap .chau .place:after {
        position: absolute;
        bottom: -10px;
        left: 50%;
        width: 0;
        height: 0;
        content: "";
        margin: 0 0 0 -10px;
        border-style: solid;
        border-width: 10px 10px 0 10px;
        border-color: #909090 transparent transparent transparent; }
  article.roamBox .globalRoamingMap .chauCon {
    position: absolute;
    top: 30px;
    right: -540px;
    width: 500px;
    height: 460px;
    border: 1px #e5302a solid;
    background: #fff;
    z-index: 10;
    transition: all 0.5s; }
  article.roamBox .globalRoamingMap .chauHdBox {
    position: relative;
    top: 0;
    left: 0;
    transition: all 0.5s; }
  article.roamBox .globalRoamingMap .mapBg {
    top: 0;
    transform: scale(1);
    transition: all 0.5s; }
  article.roamBox .globalRoamingMap.Africa .chau.Africa .point, article.roamBox .globalRoamingMap.Europe .chau.Europe .point, article.roamBox .globalRoamingMap.America .chau.America .point, article.roamBox .globalRoamingMap.Asia .chau.Asia .point, article.roamBox .globalRoamingMap.MiddleEast .chau.MiddleEast .point {
    width: 60px;
    height: 60px;
    margin: -30px 0 -10px -30px;
    background-color: rgba(228, 31, 31, 0.5);
    transition: width 0.5s, height 0.5s, margin 0.5s; }
  article.roamBox .globalRoamingMap .chau.Africa:hover .point, article.roamBox .globalRoamingMap .chau.Europe:hover .point, article.roamBox .globalRoamingMap .chau.America:hover .point, article.roamBox .globalRoamingMap .chau.Asia:hover .point, article.roamBox .globalRoamingMap .chau.MiddleEast:hover .point {
    background-color: rgba(228, 31, 31, 0.5); }
  article.roamBox .globalRoamingMap.Africa .chau.Africa .point:after, article.roamBox .globalRoamingMap.Europe .chau.Europe .point:after, article.roamBox .globalRoamingMap.America .chau.America .point:after, article.roamBox .globalRoamingMap.Asia .chau.Asia .point:after, article.roamBox .globalRoamingMap.MiddleEast .chau.MiddleEast .point:after {
    border: 5px #e41f1f solid; }
  article.roamBox .globalRoamingMap .chau.Africa:hover .point:after, article.roamBox .globalRoamingMap .chau.Europe:hover .point:after, article.roamBox .globalRoamingMap .chau.America:hover .point:after, article.roamBox .globalRoamingMap .chau.Asia:hover .point:after, article.roamBox .globalRoamingMap .chau.MiddleEast:hover .point:after {
    border: 5px #e41f1f solid; }
  article.roamBox .globalRoamingMap.Africa .chau.Africa .place, article.roamBox .globalRoamingMap.Europe .chau.Europe .place, article.roamBox .globalRoamingMap.America .chau.America .place, article.roamBox .globalRoamingMap.Asia .chau.Asia .place, article.roamBox .globalRoamingMap.MiddleEast .chau.MiddleEast .place {
    background-color: #e41f1f; }
  article.roamBox .globalRoamingMap .chau.Africa:hover .place, article.roamBox .globalRoamingMap .chau.Europe:hover .place, article.roamBox .globalRoamingMap .chau.America:hover .place, article.roamBox .globalRoamingMap .chau.Asia:hover .place, article.roamBox .globalRoamingMap .chau.MiddleEast:hover .place {
    background-color: #e41f1f; }
  article.roamBox .globalRoamingMap.Africa .chau.Africa .place:after, article.roamBox .globalRoamingMap.Europe .chau.Europe .place:after, article.roamBox .globalRoamingMap.America .chau.America .place:after, article.roamBox .globalRoamingMap.Asia .chau.Asia .place:after, article.roamBox .globalRoamingMap.MiddleEast .chau.MiddleEast .place:after {
    border-color: #e41f1f transparent transparent transparent; }
  article.roamBox .globalRoamingMap .chau.Africa:hover .place:after, article.roamBox .globalRoamingMap .chau.Europe:hover .place:after, article.roamBox .globalRoamingMap .chau.America:hover .place:after, article.roamBox .globalRoamingMap .chau.Asia:hover .place:after, article.roamBox .globalRoamingMap .chau.MiddleEast:hover .place:after {
    border-color: #e41f1f transparent transparent transparent; }
  article.roamBox .globalRoamingMap.Africa .chauHdBox, article.roamBox .globalRoamingMap.Africa .mapBg {
    top: -40px;
    left: 140px;
    transition: all 0.5s; }
  article.roamBox .globalRoamingMap.Europe .chauHdBox, article.roamBox .globalRoamingMap.Europe .mapBg {
    top: 160px;
    left: 80px;
    transition: all 0.5s; }
  article.roamBox .globalRoamingMap.America .chauHdBox, article.roamBox .globalRoamingMap.America .mapBg {
    top: 120px;
    left: -500px;
    transition: all 0.5s; }
  article.roamBox .globalRoamingMap.Asia .chauHdBox, article.roamBox .globalRoamingMap.Asia .mapBg {
    top: 120px;
    left: -60px;
    transition: all 0.5s; }
  article.roamBox .globalRoamingMap.MiddleEast .chauHdBox, article.roamBox .globalRoamingMap.MiddleEast .mapBg {
    top: 80px;
    left: 60px;
    transition: all 0.5s; }
  article.roamBox .globalRoamingMap.Africa .mapBg, article.roamBox .globalRoamingMap.Europe .mapBg, article.roamBox .globalRoamingMap.America .mapBg, article.roamBox .globalRoamingMap.Asia .mapBg, article.roamBox .globalRoamingMap.MiddleEast .mapBg {
    transform: scale(1.2);
    transition: all 0.5s; }
  article.roamBox .globalRoamingMap.Africa .chauCon.Africa, article.roamBox .globalRoamingMap.Europe .chauCon.Europe, article.roamBox .globalRoamingMap.America .chauCon.America, article.roamBox .globalRoamingMap.Asia .chauCon.Asia, article.roamBox .globalRoamingMap.MiddleEast .chauCon.MiddleEast {
    right: 30px;
    transition: all 0.5s; }
  article.roamBox .globalRoamingMap.Africa .chau.Africa, article.roamBox .globalRoamingMap.Europe .chau.Africa, article.roamBox .globalRoamingMap.America .chau.Africa, article.roamBox .globalRoamingMap.Asia .chau.Africa, article.roamBox .globalRoamingMap.MiddleEast .chau.Africa {
    top: 265px;
    left: 165px;
    transition: all 0.5s; }
  article.roamBox .globalRoamingMap.Africa .chau.Europe, article.roamBox .globalRoamingMap.Europe .chau.Europe, article.roamBox .globalRoamingMap.America .chau.Europe, article.roamBox .globalRoamingMap.Asia .chau.Europe, article.roamBox .globalRoamingMap.MiddleEast .chau.Europe {
    top: 10px;
    left: 160px;
    transition: all 0.5s; }
  article.roamBox .globalRoamingMap.Africa .chau.America, article.roamBox .globalRoamingMap.Europe .chau.America, article.roamBox .globalRoamingMap.America .chau.America, article.roamBox .globalRoamingMap.Asia .chau.America, article.roamBox .globalRoamingMap.MiddleEast .chau.America {
    top: 40px;
    left: 840px;
    transition: all 0.5s; }
  article.roamBox .globalRoamingMap.Africa .chau.Asia, article.roamBox .globalRoamingMap.Europe .chau.Asia, article.roamBox .globalRoamingMap.America .chau.Asia, article.roamBox .globalRoamingMap.Asia .chau.Asia, article.roamBox .globalRoamingMap.MiddleEast .chau.Asia {
    top: 50px;
    left: 355px;
    transition: all 0.5s; }
  article.roamBox .globalRoamingMap.Africa .chau.MiddleEast, article.roamBox .globalRoamingMap.Europe .chau.MiddleEast, article.roamBox .globalRoamingMap.America .chau.MiddleEast, article.roamBox .globalRoamingMap.Asia .chau.MiddleEast, article.roamBox .globalRoamingMap.MiddleEast .chau.MiddleEast {
    top: 145px;
    left: 230px;
    transition: all 0.5s; }
  article.roamBox .globalRoamingMap .chauCon .chauHd {
    position: relative;
    padding: 15px 20px;
    font-size: 20px;
    color: #fff;
    background: #e5302a; }
    article.roamBox .globalRoamingMap .chauCon .chauHd:after {
      position: absolute;
      left: -10px;
      top: 50%;
      width: 0;
      height: 0;
      content: "";
      margin: -10px 0 0 0;
      border-style: solid;
      border-width: 10px 10px 10px 0;
      border-color: transparent #e5302a transparent transparent; }
    article.roamBox .globalRoamingMap .chauCon .chauHd a {
      position: absolute;
      right: 15px;
      top: 50%;
      display: block;
      width: 28px;
      height: 28px;
      margin: -14px 0 0 0;
      background-size: 28px auto; }
  article.roamBox .globalRoamingMap .chauCon .chauBd {
    padding: 20px 0 20px 20px; }
    article.roamBox .globalRoamingMap .chauCon .chauBd .scroll {
      height: 375px;
      overflow: scroll;
      overflow-x: hidden;
      -webkit-overflow-scrolling: touch; }
    article.roamBox .globalRoamingMap .chauCon .chauBd ul {
      display: inline-block;
      vertical-align: top;
      width: 50%;
      margin: 0 -2px; }
      article.roamBox .globalRoamingMap .chauCon .chauBd ul li {
        padding: 5px 10px;
        font-size: 14px;
        line-height: 18px;
        box-sizing: border-box; }

article.rateBox {
  margin: 30px auto 0 auto;
  max-width: 1280px; }
  article.rateBox:first-child {
    margin: 0 auto; }
  article.rateBox .listBox {
    margin: -11px; }
    article.rateBox .listBox:after {
      content: "";
      display: table;
      clear: both; }
    article.rateBox .listBox .slickLi {
      position: relative;
      float: left;
      width: 412px;
      margin: 11px;
      padding: 20px; }
      article.rateBox .listBox .slickLi .rateBoxBd {
        position: relative; }
        article.rateBox .listBox .slickLi .rateBoxBd > a {
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 5; }
      article.rateBox .listBox .slickLi .photo {
        position: relative;
        width: 412px;
        height: 302px;
        margin: -20px -20px 0 -20px;
        overflow: hidden; }
        article.rateBox .listBox .slickLi .photo img {
          border: 1px #e2e2e2 solid;
          max-width: 410px;
          max-height: 300px; }
      article.rateBox .listBox .slickLi .conBox {
        height: 120px; }
      article.rateBox .listBox .slickLi .textHd {
        font-size: 16px;
        font-weight: bold;
        line-height: 1.2em;
        padding: 20px 0 10px 0;
        width: 22em; }
      article.rateBox .listBox .slickLi .text {
        color: #666;
        font-size: 15px;
        line-height: 1.4em;
        height: 42px;
        overflow: hidden; }
      article.rateBox .listBox .slickLi .rateBoxFt {
        margin: 20px 0 0 0;
        background-size: 100% 13px; }
      article.rateBox .listBox .slickLi .list {
        padding: 15px 0 15px 0;
        border-top: 1px #d8d8d8 solid;
        background-size: 16px auto; }
        article.rateBox .listBox .slickLi .list:last-child {
          padding: 15px 0 0 0; }
        article.rateBox .listBox .slickLi .list a {
          font-size: 16px;
          font-weight: bold;
          line-height: 1.2em;
          display: inline-block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 22em; }

article.otherBoxFt {
  margin: 30px auto 0 auto;
  padding: 0 20px;
  max-width: 1280px; }
  article.otherBoxFt:first-child {
    margin: 0 auto; }
  article.otherBoxFt .slickUl {
    margin: 0 15px 10px 15px; }
    article.otherBoxFt .slickUl:after {
      content: "";
      display: table;
      clear: both; }
    article.otherBoxFt .slickUl .slickLi {
      position: relative;
      float: none;
      display: inline-block;
      vertical-align: top;
      width: 16%;
      margin: 0 2px; }
      article.otherBoxFt .slickUl .slickLi a {
        bottom: 0;
        display: block;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 5; }
      article.otherBoxFt .slickUl .slickLi .photo {
        position: relative;
        display: table;
        text-align: center;
        width: 160px;
        height: 160px;
        margin: 0 auto;
        overflow: hidden; }
  article.otherBoxFt.product .slickUl .slickLi .photo {
    padding: 10px 0 10px 0; }
  article.otherBoxFt .slickUl .slickLi .photo span {
    display: table-cell;
    vertical-align: middle; }
  article.otherBoxFt .slickUl .slickLi .photo img {
    display: inline;
    max-width: 160px;
    max-height: 160px; }
  article.otherBoxFt .slickUl .slickLi .title {
    width: 100%;
    max-height: 28px;
    padding: 0 10px;
    overflow: hidden;
    background: #999;
    box-sizing: border-box; }
  article.otherBoxFt.product .slickUl .slickLi .title {
    padding: 8px 10px;
    max-height: 52px;
    overflow: hidden; }
  article.otherBoxFt .slickUl .slickLi .title .limitHigh {
    display: table;
    width: 100%;
    height: 28px;
    line-height: 28px;
    text-align: center;
    font-size: 16px;
    color: #fff; }
  article.otherBoxFt.product .slickUl .slickLi .title .limitHigh {
    display: block;
    height: 36px;
    line-height: 1.2em;
    overflow: hidden; }
    article.otherBoxFt.product .slickUl .slickLi .title .limitHigh .limitHighBox {
      display: table;
      width: 100%;
      text-align: center;
      height: 36px; }
  article.otherBoxFt .slickUl .slickLi .title span {
    display: table-cell;
    vertical-align: middle; }
  article.otherBoxFt .slick-slider .slick-prev {
    left: -40px; }
  article.otherBoxFt .slick-slider .slick-next {
    right: -40px; }

article.proDetailBox {
  position: relative;
  margin: 0 auto 30px auto;
  padding: 20px;
  max-width: 1280px; }
  article.proDetailBox.prepaid, article.proDetailBox.postpaid {
    margin: 0 auto 0 auto;
    padding: 10px 0 0 0; }
  article.proDetailBox .detailBoxHd {
    padding: 0 0 30px 0;
    border-bottom: 1px #d8d8d8 solid; }
  article.proDetailBox.prepaid .detailBoxHd, article.proDetailBox.postpaid .detailBoxHd {
    padding: 0;
    border-bottom: 1px #d8d8d8 solid; }
    article.proDetailBox.prepaid .detailBoxHd .detailBox h3.font24b, article.proDetailBox.postpaid .detailBoxHd .detailBox h3.font24b {
      font-size: 36px !important; }
  article.proDetailBox .detailBoxHd:after {
    content: "";
    display: table;
    clear: both; }
  article.proDetailBox .shareBox {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1; }
  article.proDetailBox .languageBox .shareBox {
    right: -5px; }
  article.proDetailBox .tabsBox .shareBox {
    right: 0; }
  article.proDetailBox .shareBox .title {
    padding: 10px 0 5px 0;
    font-size: 15px;
    text-align: center;
    color: #999; }
  article.proDetailBox .shareBox ul li {
    margin: 5px 0;
    width: 42px;
    height: 42px; }
    article.proDetailBox .shareBox ul li a {
      position: relative;
      display: block;
      width: 42px;
      height: 42px;
      font-size: 0; }
  article.proDetailBox .albumsBox {
    position: relative;
    float: left;
    width: 640px;
    min-height: 590px;
    margin: 0 0 0 20px; }
  article.proDetailBox.prepaid .albumsBox, article.proDetailBox.postpaid .albumsBox {
    position: relative;
    float: left;
    width: 445px;
    min-height: initial;
    min-height: auto;
    margin: 0; }
  article.proDetailBox.prepaid .albumsBox {
    width: 750px; }
    article.proDetailBox.prepaid .albumsBox .photo img {
      max-width: 750px; }
  article.proDetailBox .albumsBox .label {
    position: absolute;
    top: 1px;
    left: 1px;
    height: 76px;
    z-index: 1; }
    article.proDetailBox .albumsBox .label a.icon {
      position: relative;
      display: inline-block;
      vertical-align: top;
      width: 68px;
      height: 76px; }
      article.proDetailBox .albumsBox .label a.icon img {
        position: absolute;
        top: 0;
        left: 0;
        max-width: 68px;
        max-height: 76px; }
        article.proDetailBox .albumsBox .label a.icon img.link {
          display: block; }
    article.proDetailBox .albumsBox .label a:hover.icon img.hover {
      display: block; }
    article.proDetailBox .albumsBox .label a.icon img.hover, article.proDetailBox .albumsBox .label a:hover.icon img.link {
      display: none; }
  article.proDetailBox .albumsBox .slickAlbums .photo {
    display: table;
    overflow: hidden;
    width: 640px;
    height: 480px;
    margin: 0 auto;
    text-align: center;
    box-sizing: border-box; }
    article.proDetailBox .albumsBox .slickAlbums .photo span {
      display: table-cell;
      vertical-align: middle;
      border: 1px #f0f0f0 solid; }
    article.proDetailBox .albumsBox .slickAlbums .photo img {
      display: inline-block;
      max-width: 638px;
      max-height: 478px; }
  article.proDetailBox.postpaid .albumsBox .photo {
    width: 445px;
    border: 0; }
    article.proDetailBox.postpaid .albumsBox .photo img {
      max-width: 445px; }
  article.proDetailBox .albumsBox .slickAlbumsNav {
    width: 600px;
    margin: 0 auto; }
    article.proDetailBox .albumsBox .slickAlbumsNav .slickLi {
      display: table;
      width: 105px;
      height: 79px;
      text-align: center;
      margin: 0 5px;
      border: 1px #f0f0f0 solid; }
      article.proDetailBox .albumsBox .slickAlbumsNav .slickLi > a {
        display: table-cell;
        vertical-align: middle; }
      article.proDetailBox .albumsBox .slickAlbumsNav .slickLi img {
        max-width: 105px;
        max-height: 79px;
        display: inline-block; }
      article.proDetailBox .albumsBox .slickAlbumsNav .slickLi:hover {
        border: 1px #bebebe solid; }
    article.proDetailBox .albumsBox .slickAlbumsNav .slick-current, article.proDetailBox .albumsBox .slickAlbumsNav .slickLi.slick-center {
      border: 1px #bebebe solid; }
  article.proDetailBox .albumsBox .slick-slider .slick-prev {
    left: -30px; }
  article.proDetailBox .albumsBox .slick-slider .slick-next {
    right: -30px; }
  article.proDetailBox .albumsBox .slick-slider .slick-prev {
    width: 44px;
    height: 60px;
    margin-top: -30px;
    background-size: 30px auto; }
  article.proDetailBox .albumsBox .slick-slider .slick-next {
    width: 44px;
    height: 60px;
    margin-top: -30px;
    background-size: 30px auto; }
    article.proDetailBox .albumsBox .slick-slider .slick-next:hover {
      background-color: #999; }
  article.proDetailBox .albumsBox .slick-slider .slick-prev:hover {
    background-color: #999; }
  article.proDetailBox .tabs.singleColumn .languageBox {
    height: 42px;
    margin: -30px -45px 20px 0; }
  article.proDetailBox .tabs.singleColumn .tabsBox .languageBox {
    position: relative;
    margin: -25px -5px 20px 0; }
  article.proDetailBox .languageBox .shareBox .title {
    padding: 10px 5px 5px 20px;
    font-size: 15px;
    text-align: center;
    color: #999; }
  article.proDetailBox .languageBox .shareBox ul li {
    float: left;
    margin: 0 5px;
    width: 42px;
    height: 42px; }
  article.proDetailBox .languageBox .language {
    display: table-cell;
    vertical-align: middle; }
  article.proDetailBox .languageBox .shareBox {
    display: table-cell;
    vertical-align: middle; }
    article.proDetailBox .languageBox .shareBox .title, article.proDetailBox .languageBox .shareBox ul {
      display: table-cell;
      vertical-align: middle; }
  article.proDetailBox .detailBox {
    position: relative;
    float: right;
    width: 460px;
    margin: 0 70px 0 50px;
    padding: 0 0 305px 0; }
  article.proDetailBox.prepaid .detailBox, article.proDetailBox.postpaid .detailBox {
    width: 740px;
    margin: 0 70px 0 0;
    padding: 40px;
    box-sizing: border-box; }
  article.proDetailBox.prepaid .detailBox {
    width: 530px;
    margin: 0;
    padding: 30px 100px 30px 30px;
    min-height: 360px;
    background: #dfeaab; }
  article.proDetailBox.prepaid.wifi .detailBox {
    background: #fff; }
  article.proDetailBox.prepaid .detailBox.editBox h3 {
    margin: 0 0 10px; }
  article.proDetailBox.prepaid .detailBox.editBox h4 {
    margin: 0 0 10px;
    color: #666; }
  article.proDetailBox.prepaid .detailBox.editBox ul {
    margin: 0 0 0 10px; }
    article.proDetailBox.prepaid .detailBox.editBox ul li {
      padding: 0 0 0 15px;
      color: #666; }
      article.proDetailBox.prepaid .detailBox.editBox ul li:after {
        top: 9px;
        width: 5px;
        height: 5px;
        background: #a4ad7e;
        border-radius: 2.5px; }
  article.proDetailBox.postpaid .detailBox.editBox .btnDownBox {
    text-align: left;
    margin: 25px 0 0; }
  article.proDetailBox.prepaid .preDetailBox {
    display: table;
    width: 100%; }
    article.proDetailBox.prepaid .preDetailBox:after {
      content: "";
      display: table;
      clear: both; }
    article.proDetailBox.prepaid .preDetailBox .photo, article.proDetailBox.prepaid .preDetailBox .conBox {
      display: table-cell;
      vertical-align: top; }
    article.proDetailBox.prepaid .preDetailBox .photo {
      position: relative;
      width: 450px;
      text-align: center;
      box-sizing: border-box; }
      article.proDetailBox.prepaid .preDetailBox .photo img {
        max-width: 480px; }
      article.proDetailBox.prepaid .preDetailBox .photo .flag {
        position: absolute;
        top: 0;
        left: 12px;
        height: 62px;
        z-index: 1; }
        article.proDetailBox.prepaid .preDetailBox .photo .flag img {
          margin: 0 -10px 0 0;
          border: 0; }
      article.proDetailBox.prepaid .preDetailBox .photo.w400 {
        width: 400px;
        padding: 35px 0 0 0;
        background: none; }
        article.proDetailBox.prepaid .preDetailBox .photo.w400 > img {
          max-width: 400px;
          border-top: 2px #777eb9 solid; }
    article.proDetailBox.prepaid .preDetailBox .conBox {
      padding: 30px;
      box-sizing: border-box; }
    article.proDetailBox.prepaid .preDetailBox .editBox .conBox h3, article.proDetailBox.prepaid .preDetailBox .editBox .conBox h4 {
      margin: 0 0 10px 0; }
  article.proDetailBox.prepaid .detailBox.editBox *, article.proDetailBox.prepaid .preDetailBox .editBox .conBox * {
    color: #333; }
  article.proDetailBox.prepaid.wifi .detailBox.editBox .icon {
    margin: 0 0 10px 0; }
  article.proDetailBox.prepaid .preDetailBox .editBox .conBox ul li:after {
    top: 9px;
    width: 5px;
    height: 5px;
    background: #a4ad7e;
    border-radius: 2.5px; }
  article.proDetailBox.prepaid .preDetailBox .conBox .summaryBox {
    margin: 0 0 10px 0; }
    article.proDetailBox.prepaid .preDetailBox .conBox .summaryBox span {
      display: inline-block;
      margin: 0 12px 0 0;
      padding: 0 0 0 20px;
      font-size: 18px;
      color: #333;
      background-size: 16px auto; }
  article.proDetailBox.prepaid .preDetailBox .conBox ul.stepBox {
    margin: 10px 0 0 0; }
    article.proDetailBox.prepaid .preDetailBox .conBox ul.stepBox li {
      padding: 3px 0;
      font-size: 16px;
      line-height: 32px;
      color: #666; }
      article.proDetailBox.prepaid .preDetailBox .conBox ul.stepBox li span {
        position: relative;
        top: -2px;
        display: inline-block;
        margin: 0 10px 0 0;
        width: 32px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        color: #fff;
        font-size: 20px;
        font-weight: bold;
        vertical-align: middle;
        background: #fe7e22;
        border-radius: 16px; }
      article.proDetailBox.prepaid .preDetailBox .conBox ul.stepBox li:after {
        display: none; }
  article.proDetailBox.prepaid .preDetailBox .conBox p {
    margin: 0 0 5px 0;
    font-size: 16px;
    color: #666; }
  article.proDetailBox.prepaid .preDetailBox .conBox .picBox {
    margin: 10px 0; }
  article.proDetailBox.prepaid .relatedBox .picBox img, article.proDetailBox.prepaid .preDetailBox .conBox .picBox img {
    border: 0; }
  article.proDetailBox.prepaid .relatedBox {
    text-align: center; }
  article.proDetailBox.prepaid .editBox .stepStyle1 {
    margin: 0 20px; }
    article.proDetailBox.prepaid .editBox .stepStyle1:after {
      content: "";
      display: table;
      clear: both; }
    article.proDetailBox.prepaid .editBox .stepStyle1 .stepBox {
      position: relative;
      float: left;
      width: 172px;
      height: 172px;
      margin: 15px 60px 15px 0;
      padding: 20px;
      color: #fff;
      font-size: 15px;
      box-sizing: border-box;
      background: #fe7e22;
      border-radius: 10px; }
      article.proDetailBox.prepaid .editBox .stepStyle1 .stepBox:after {
        position: absolute;
        top: 50%;
        right: -50px;
        content: "";
        width: 37px;
        height: 37px;
        margin: -28px 0 0 0; }
      article.proDetailBox.prepaid .editBox .stepStyle1 .stepBox em {
        position: absolute;
        right: -8px;
        bottom: -18px;
        display: block;
        width: 60px;
        height: 60px;
        line-height: 60px;
        text-align: center;
        color: #fe7e22;
        font-size: 40px;
        font-weight: bold;
        border-radius: 30px;
        background: #fff; }
      article.proDetailBox.prepaid .editBox .stepStyle1 .stepBox.last {
        margin: 10px 0; }
        article.proDetailBox.prepaid .editBox .stepStyle1 .stepBox.last:after {
          top: 0;
          right: 0;
          content: "";
          width: 0;
          height: 0;
          margin: 0;
          background: none; }
      article.proDetailBox.prepaid .editBox .stepStyle1 .stepBox a {
        color: #fff;
        text-decoration: underline; }
  article.proDetailBox.prepaid .editBox .stepStyle2 .stepBox a {
    color: #fff;
    text-decoration: underline; }
  article.proDetailBox.prepaid .editBox .stepStyle1 .stepBox b {
    color: #fff;
    font-size: 16px; }
  article.proDetailBox.prepaid .editBox .stepStyle2 {
    margin: 0 20px; }
    article.proDetailBox.prepaid .editBox .stepStyle2 .stepBox b {
      color: #fff;
      font-size: 16px; }
    article.proDetailBox.prepaid .editBox .stepStyle2:after {
      content: "";
      display: table;
      clear: both; }
    article.proDetailBox.prepaid .editBox .stepStyle2 .stepBox {
      position: relative;
      float: left;
      width: 172px;
      height: 172px;
      margin: 15px 60px 15px 0;
      padding: 20px;
      text-align: center;
      color: #fff;
      font-size: 15px;
      box-sizing: border-box;
      background: #fe7e22; }
      article.proDetailBox.prepaid .editBox .stepStyle2 .stepBox:after {
        position: absolute;
        top: 50%;
        right: -50px;
        content: "";
        width: 37px;
        height: 37px;
        margin: -28px 0 0 0; }
      article.proDetailBox.prepaid .editBox .stepStyle2 .stepBox.last {
        margin: 15px 0 15px 0; }
        article.proDetailBox.prepaid .editBox .stepStyle2 .stepBox.last:after {
          top: 0;
          right: 0;
          content: "";
          width: 0;
          height: 0;
          margin: 0;
          background: none; }
      article.proDetailBox.prepaid .editBox .stepStyle2 .stepBox .icon {
        width: 140px;
        margin: 0 auto 15px auto;
        padding: 0 0 20px 0;
        border-bottom: 1px #fff solid; }
        article.proDetailBox.prepaid .editBox .stepStyle2 .stepBox .icon img {
          border: 0; }
  article.proDetailBox.prepaid .editBox .stepStyle3 {
    margin: 20px 20px 0; }
    article.proDetailBox.prepaid .editBox .stepStyle3:after {
      content: "";
      display: table;
      clear: both; }
    article.proDetailBox.prepaid .editBox .stepStyle3 .stepBox {
      position: relative;
      padding: 20px 20px 20px 100px;
      margin: 0 0 40px 0;
      border-top: 2px #f4d3bb solid;
      font-size: 15px;
      line-height: 1.4em;
      vertical-align: middle;
      box-sizing: border-box;
      background: #faeee5; }
      article.proDetailBox.prepaid .editBox .stepStyle3 .stepBox .icon {
        position: absolute;
        top: -5px;
        left: 6px;
        bottom: 10px;
        width: 80px;
        padding: 10px 0 0 0;
        text-align: center;
        box-sizing: border-box;
        background: #fe7e22; }
        article.proDetailBox.prepaid .editBox .stepStyle3 .stepBox .icon:after {
          bottom: -30px;
          left: 0;
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
          border-top-color: #fe7e22;
          border-width: 15px;
          border-left-width: 40px;
          border-right-width: 40px; }
        article.proDetailBox.prepaid .editBox .stepStyle3 .stepBox .icon > div {
          display: table;
          vertical-align: middle;
          width: 100%;
          height: 100%; }
          article.proDetailBox.prepaid .editBox .stepStyle3 .stepBox .icon > div span {
            display: table-cell;
            vertical-align: middle;
            color: #fff;
            font-size: 28px;
            font-style: normal;
            font-weight: bold; }
      article.proDetailBox.prepaid .editBox .stepStyle3 .stepBox .text {
        display: table-cell;
        vertical-align: middle; }
      article.proDetailBox.prepaid .editBox .stepStyle3 .stepBox > span {
        height: 50px;
        width: 1px;
        display: table-cell;
        vertical-align: middle; }
  article.proDetailBox.prepaid .editBox .stepStyle4 {
    margin: 0 20px; }
  article.proDetailBox.prepaid .editBox .depositBox .stepStyle4 {
    padding: 0 40px 0 0; }
  article.proDetailBox.prepaid .editBox .stepStyle4 .stepBox {
    position: relative;
    margin: 0 0 0 40px;
    padding: 15px 0;
    font-size: 15px;
    border-bottom: 1px #ccc dotted;
    box-sizing: border-box; }
    article.proDetailBox.prepaid .editBox .stepStyle4 .stepBox em {
      position: absolute;
      top: 50%;
      left: -40px;
      display: inline-block;
      vertical-align: middle;
      width: 32px;
      height: 32px;
      line-height: 32px;
      margin-top: -16px;
      text-align: center;
      font-size: 40px;
      color: #fff;
      font-size: 20px;
      background: #fe7e22;
      border-radius: 16px; }
  article.proDetailBox.prepaid .editBox .depositBox {
    margin: 0 40px 40px 40px; }
    article.proDetailBox.prepaid .editBox .depositBox .logo, article.proDetailBox.prepaid .editBox .depositBox .logoBox, article.proDetailBox.prepaid .editBox .depositBox .stepStyle4 {
      display: table-cell;
      vertical-align: middle; }
    article.proDetailBox.prepaid .editBox .depositBox .logo img {
      border: 0; }
  article.proDetailBox.prepaid .editBox .qaBoxComplex {
    margin: 30px 0 0 0;
    padding: 15px 20px;
    background: #f4f4f4;
    box-sizing: border-box; }
    article.proDetailBox.prepaid .editBox .qaBoxComplex:first-child {
      margin: 0; }
    article.proDetailBox.prepaid .editBox .qaBoxComplex .question {
      font-size: 16px;
      font-weight: bold;
      color: #333;
      padding: 0 0 10px 0;
      margin: 0 0 10px 0;
      border-bottom: 1px #d9d9d9 solid; }
      article.proDetailBox.prepaid .editBox .qaBoxComplex .question b, article.proDetailBox.prepaid .editBox .qaBoxComplex .question a {
        font-size: 16px;
        font-weight: bold; }
    article.proDetailBox.prepaid .editBox .qaBoxComplex .answer {
      font-size: 15px;
      color: #666; }
  article.proDetailBox .detailBox .mainTitle {
    padding: 15px 0 15px 0;
    margin: 0 0 15px 0;
    font-size: 24px;
    font-weight: bold;
    line-height: 1.3em;
    border-bottom: 1px #dfdfdf solid; }
  article.proDetailBox.postpaid .detailBox h3 {
    padding: 0;
    margin: 0 0 15px 0;
    border-bottom: 0; }
  article.proDetailBox.postpaid .detailBox h4 {
    color: #333;
    margin: 0 0 10px 0;
    line-height: 1.3em; }
  article.proDetailBox.postpaid .detailBox .text {
    font-size: 15px;
    line-height: 1.4em;
    color: #666; }
  article.proDetailBox .detailBox .specs table {
    width: 100%;
    font-size: 15px;
    color: #666; }
  article.proDetailBox .detailBox .specs th, article.proDetailBox .detailBox .specs td {
    font-weight: normal;
    padding: 6px 4px; }
  article.proDetailBox .detailBox .specs th {
    width: 70px;
    text-align: left; }
  article.proDetailBox .detailBox .specs td {
    line-height: 1.2em; }
  article.proDetailBox .detailBox .relatedComplex {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 150px;
    height: 115px;
    margin: 0; }
    article.proDetailBox .detailBox .relatedComplex .bgComplex {
      position: absolute;
      left: 170px;
      right: 170px;
      top: 0;
      bottom: 0;
      background: #f5f5f5; }
    article.proDetailBox .detailBox .relatedComplex.slickUl_3 .bgComplex {
      left: 50%;
      right: 50px; }
    article.proDetailBox .detailBox .relatedComplex.slickUl_2 .bgComplex {
      left: 50px;
      right: 50px; }
    article.proDetailBox .detailBox .relatedComplex.slickUl_1 .bgComplex {
      left: 50px;
      right: 50px; }
    article.proDetailBox .detailBox .relatedComplex.slickUl_1 .slickRelated .slickLi {
      cursor: default; }
      article.proDetailBox .detailBox .relatedComplex.slickUl_1 .slickRelated .slickLi .title {
        font-size: 16px;
        font-weight: bold;
        color: #333; }
      article.proDetailBox .detailBox .relatedComplex.slickUl_1 .slickRelated .slickLi .price {
        font-size: 18px;
        font-weight: bold;
        color: #e71a0f; }
      article.proDetailBox .detailBox .relatedComplex.slickUl_1 .slickRelated .slickLi .time {
        color: #333; }
    article.proDetailBox .detailBox .relatedComplex .msgComplex {
      position: absolute;
      right: 0;
      top: -30px;
      width: 80px;
      height: 20px; }
      article.proDetailBox .detailBox .relatedComplex .msgComplex a {
        display: block;
        width: 80px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        color: #fff;
        font-size: 12px;
        background: #aaa;
        border-radius: 4px; }
        article.proDetailBox .detailBox .relatedComplex .msgComplex a:hover {
          background: #999; }
    article.proDetailBox .detailBox .relatedComplex .descComplex {
      padding: 10px 5px 0 5px;
      font-size: 13px;
      color: #666;
      text-align: center; }
  article.proDetailBox .detailBox .slickRelated {
    position: relative;
    color: #999;
    text-align: center;
    margin: 0; }
    article.proDetailBox .detailBox .slickRelated:after, article.proDetailBox .detailBox .slickRelated:before {
      position: absolute;
      top: 0;
      bottom: 0;
      content: "";
      width: 80px;
      z-index: 1; }
    article.proDetailBox .detailBox .slickRelated:after {
      left: 0; }
    article.proDetailBox .detailBox .slickRelated:before {
      right: 0; }
    article.proDetailBox .detailBox .slickRelated .slickLi {
      padding: 10px 10px;
      border-right: 1px #e3e3e3 solid;
      cursor: pointer; }
    article.proDetailBox .detailBox .slickRelated .price {
      height: 20px;
      font-size: 15px;
      line-height: 1.2em; }
    article.proDetailBox .detailBox .slickRelated .title {
      height: 40px;
      font-size: 15px;
      line-height: 1.2em;
      overflow: hidden; }
    article.proDetailBox .detailBox .slickRelated .time {
      font-size: 13px;
      height: 16px;
      margin: 10px 0 10px 0;
      overflow: hidden; }
    article.proDetailBox .detailBox .slickRelated .slickLi.slick-center {
      cursor: default; }
      article.proDetailBox .detailBox .slickRelated .slickLi.slick-center .title {
        font-size: 16px;
        font-weight: bold;
        color: #333; }
      article.proDetailBox .detailBox .slickRelated .slickLi.slick-center .price {
        font-size: 18px;
        font-weight: bold;
        color: #e71a0f; }
      article.proDetailBox .detailBox .slickRelated .slickLi.slick-center .time {
        color: #333; }
  article.proDetailBox .detailBox .iconBox {
    position: relative;
    height: 50px;
    padding: 10px 0 0 0; }
    article.proDetailBox .detailBox .iconBox .notifyBox {
      position: absolute;
      left: 0;
      bottom: 50px;
      width: 450px;
      height: 48px; }
      article.proDetailBox .detailBox .iconBox .notifyBox .notifyBoxBd {
        height: 38px;
        padding: 8px 40px 8px 10px;
        font-size: 15px;
        line-height: 1.4em;
        text-align: left;
        color: #fff;
        background: #835935;
        box-sizing: border-box;
        z-index: 1; }
      article.proDetailBox .detailBox .iconBox .notifyBox a {
        color: #fff; }
        article.proDetailBox .detailBox .iconBox .notifyBox a:hover {
          color: #fff; }
      article.proDetailBox .detailBox .iconBox .notifyBox span.buy {
        padding: 0 15px 0 5px;
        font-weight: bold;
        background-size: 20px auto; }
      article.proDetailBox .detailBox .iconBox .notifyBox a.close {
        position: absolute;
        top: 7px;
        right: 7px;
        display: inline-block;
        width: 20px;
        height: 20px;
        font-size: 0;
        cursor: pointer;
        background-size: 20px auto; }
    article.proDetailBox .detailBox .iconBox ul {
      float: left;
      left: 50%;
      position: relative;
      text-align: center; }
      article.proDetailBox .detailBox .iconBox ul li {
        position: relative;
        float: left;
        right: 50%;
        margin: 0 3px;
        text-align: center;
        background: #e1c7a6; }
        article.proDetailBox .detailBox .iconBox ul li:hover {
          background: #e5e5e5; }
        article.proDetailBox .detailBox .iconBox ul li a {
          display: block; }
        article.proDetailBox .detailBox .iconBox ul li.msgComplex > a:after {
          top: -10px;
          left: 21px;
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
          border-top-color: #835935;
          border-width: 8px;
          border-left-width: 9px;
          border-right-width: 9px;
          filter: alpha(opacity=0);
          -moz-opacity: 0;
          opacity: 0;
          transition: all 0.3s; }
        article.proDetailBox .detailBox .iconBox ul li.msgComplex.arrow > a:after {
          filter: alpha(opacity=100);
          -moz-opacity: 1;
          opacity: 1;
          transition: all 0.3s; }
    article.proDetailBox .detailBox .iconBox img {
      display: inline;
      width: 108px; }
  article.proDetailBox .detailBox .howBuyBox {
    position: absolute;
    left: -3px;
    right: -3px;
    bottom: 0; }
    article.proDetailBox .detailBox .howBuyBox .title {
      border-top: 1px #dfdfdf solid;
      margin: 0 8px;
      padding: 20px 0 0 0;
      font-size: 16px;
      color: #666; }
  article.proDetailBox .main {
    margin: 40px -20px 0 -20px; }
  article.proDetailBox article.otherBoxFt .slickUl {
    margin: 0 -3px; }
    article.proDetailBox article.otherBoxFt .slickUl .slickLi {
      width: 16%; }
  article.proDetailBox .detailBoxBd {
    padding: 30px 0 0 0; }
    article.proDetailBox .detailBoxBd:after {
      content: "";
      display: table;
      clear: both; }
    article.proDetailBox .detailBoxBd .tabs {
      float: left;
      width: 980px; }
      article.proDetailBox .detailBoxBd .tabs.minComplex {
        width: 940px;
        margin: 0 20px 0 20px; }
      article.proDetailBox .detailBoxBd .tabs .tabsBox {
        position: relative;
        padding: 10px 0; }
      article.proDetailBox .detailBoxBd .tabs.singleColumn {
        float: none;
        width: auto;
        margin: 0 auto; }
  article.proDetailBox.postpaid .detailBoxBd .tabs .tabsBox {
    padding: 20px 0 0 0; }
  article.proDetailBox.prepaid.wifi .detailBoxBd .wifiBoxComplex {
    margin: 30px 50px; }
  article.proDetailBox.prepaid.wifi .detailBoxBd .wifiBoxHd {
    margin: 5px 0 40px 0; }
  article.proDetailBox.prepaid.wifi .detailBoxBd .iosStepBox {
    width: 1000px;
    margin: 0 auto;
    text-align: center; }
  article.proDetailBox.prepaid.wifi .detailBoxBd .androidMainBox .iosStepBox {
    text-align: left; }
  article.proDetailBox.prepaid.wifi .detailBoxBd .iosStepBox:after, article.proDetailBox.prepaid.wifi .detailBoxBd .storeList:after, article.proDetailBox.prepaid.wifi .detailBoxBd .wifiBoxHd:after {
    content: "";
    display: table;
    clear: both; }
  article.proDetailBox.prepaid.wifi .detailBoxBd .wifiBoxComplex .wifiBoxLeft, article.proDetailBox.prepaid.wifi .detailBoxBd .wifiBoxComplex .wifiBoxRight {
    width: 530px;
    min-height: 200px;
    padding: 20px 40px;
    border: 1px #e1e1e1 solid;
    box-sizing: border-box; }
  article.proDetailBox.prepaid.wifi .detailBoxBd .storeList {
    margin: 10px 0 0 0; }
  article.proDetailBox.prepaid.wifi .detailBoxBd .stepBox {
    position: relative;
    float: left;
    width: 280px;
    min-height: 370px;
    text-align: center;
    padding: 20px 0;
    margin: 0 25px 0 0;
    border: 1px #e1e1e1 solid;
    box-sizing: border-box; }
    article.proDetailBox.prepaid.wifi .detailBoxBd .stepBox.step4 {
      margin: 0; }
    article.proDetailBox.prepaid.wifi .detailBoxBd .stepBox:after {
      position: absolute;
      top: 50%;
      right: -18px;
      content: "";
      width: 37px;
      height: 37px;
      margin: -18px 0 0 0;
      z-index: 1; }
    article.proDetailBox.prepaid.wifi .detailBoxBd .stepBox.step4:after {
      position: absolute;
      top: 50%;
      right: -18px;
      content: "";
      width: 37px;
      height: 37px;
      margin: -18px 0 0 0;
      background: none;
      z-index: 1; }
  article.proDetailBox.prepaid.wifi .detailBoxBd .iosStepBox .boxComplex {
    position: relative;
    display: inline-block;
    min-width: 300px;
    min-height: 570px;
    box-sizing: border-box; }
    article.proDetailBox.prepaid.wifi .detailBoxBd .iosStepBox .boxComplex .text {
      display: table;
      width: 240px;
      margin: 0 auto;
      text-align: left;
      height: 50px; }
      article.proDetailBox.prepaid.wifi .detailBoxBd .iosStepBox .boxComplex .text span {
        display: table-cell;
        vertical-align: bottom; }
  article.proDetailBox.prepaid.wifi .detailBoxBd .stepTitle {
    font-size: 20px;
    font-weight: bold;
    color: #8a5f3c; }
  article.proDetailBox.prepaid.wifi .detailBoxBd .storeBox {
    position: relative;
    float: left;
    width: 200px;
    min-height: 200px;
    margin: 0 20px 0 0;
    border: 1px #e1e1e1 solid;
    box-sizing: border-box; }
  article.proDetailBox.prepaid.wifi .detailBoxBd .storeList.max .storeBox {
    width: 240px;
    min-height: 240px;
    margin: 0 30px 0 0; }
  article.proDetailBox.prepaid.wifi .detailBoxBd .storeBox:last-child {
    margin: 0; }
  article.proDetailBox.prepaid.wifi .detailBoxBd .storeList.max .storeBox:last-child {
    margin: 0; }
  article.proDetailBox.prepaid.wifi .detailBoxBd .storeList.max .storeBox .logo {
    width: 240px;
    height: 200px; }
  article.proDetailBox.prepaid.wifi .detailBoxBd .storeBox:hover {
    border: 1px #e71a0f solid; }
  article.proDetailBox.prepaid.wifi .detailBoxBd .storeBox .logo {
    display: table;
    text-align: center;
    width: 200px;
    height: 160px; }
    article.proDetailBox.prepaid.wifi .detailBoxBd .storeBox .logo span {
      display: table-cell;
      vertical-align: middle; }
  article.proDetailBox.prepaid.wifi .detailBoxBd .storeBox .title {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    color: #333;
    height: 46px;
    line-height: 46px;
    overflow: hidden;
    background: #f2f2f2; }
  article.proDetailBox.prepaid.wifi .detailBoxBd .storeBox > a {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1; }
  article.proDetailBox.prepaid.wifi .detailBoxBd .stepBox .text {
    display: table;
    width: 100%;
    text-align: center;
    height: 50px; }
  article.proDetailBox.prepaid.wifi .detailBoxBd .stepBox.step1 .icon article.proDetailBox.prepaid.wifi .detailBoxBd .stepBox.step2 .icon article.proDetailBox.prepaid.wifi .detailBoxBd .stepBox.step3 .icon {
    display: table;
    width: 90%;
    margin: 10px auto 0 auto;
    text-align: center;
    height: 230px;
    border-top: 1px #f0f0f0 solid; }
  article.proDetailBox.prepaid.wifi .detailBoxBd .stepBox.step4 .icon {
    width: 90%;
    border-top: 1px #f2f2f2 solid;
    margin: 10px auto 0 auto;
    padding: 10px 0 0 0; }
  article.proDetailBox.prepaid.wifi .detailBoxBd .stepBox .text span, article.proDetailBox.prepaid.wifi .detailBoxBd .stepBox.step1 .icon span, article.proDetailBox.prepaid.wifi .detailBoxBd .stepBox.step2 .icon span, article.proDetailBox.prepaid.wifi .detailBoxBd .stepBox.step3 .icon span {
    display: inline-block;
    vertical-align: middle; }
  article.proDetailBox.prepaid.wifi .detailBoxBd .stepBox .boxComplex {
    text-align: left;
    margin: 10px 0 0 30px; }
    article.proDetailBox.prepaid.wifi .detailBoxBd .stepBox .boxComplex > div:first-child {
      margin: 0 0 8px 0; }
    article.proDetailBox.prepaid.wifi .detailBoxBd .stepBox .boxComplex .text {
      text-align: left;
      line-height: 1.2em !important; }
      article.proDetailBox.prepaid.wifi .detailBoxBd .stepBox .boxComplex .text a {
        line-height: 1.2em !important; }
    article.proDetailBox.prepaid.wifi .detailBoxBd .stepBox .boxComplex .subTitle {
      padding: 0 0 0 10px;
      margin: 0;
      font-size: 16px; }
      article.proDetailBox.prepaid.wifi .detailBoxBd .stepBox .boxComplex .subTitle:after {
        top: 0; }
  article.proDetailBox.prepaid.wifi .detailBoxBd .stepBox .title {
    font-size: 32px;
    font-weight: bold;
    color: #8a5f3c; }
  article.proDetailBox.prepaid.wifi .detailBoxBd .wifiBoxComplex .wifiBoxLeft {
    float: left; }
  article.proDetailBox.prepaid.wifi .detailBoxBd .wifiBoxComplex .wifiBoxRight {
    float: right; }
  article.proDetailBox.prepaid.wifi .detailBoxBd .wifiBoxComplex .wifiBoxHd .title {
    font-size: 18px;
    font-weight: bold;
    color: #333; }
  article.proDetailBox.prepaid.wifi .detailBoxBd .stepBox img {
    border: 0; }
  article.proDetailBox.prepaid.wifi .detailBox.editBox .icon img {
    border: 0; }
  article.proDetailBox.prepaid.wifi .detailBoxBd .wifiBoxComplex .wifiBoxHd .title img, article.proDetailBox.prepaid.wifi .detailBoxBd .storeBox .logo span img, article.proDetailBox.prepaid.wifi .detailBoxBd .iosStepBox .boxComplex .icon img {
    border: 0; }
  article.proDetailBox.prepaid.wifi .detailBoxBd .wifiBoxComplex .wifiBoxHd ul {
    margin: 15px 0 0 0;
    padding: 15px 0 0 0;
    border-top: 1px #e1e1e1 solid;
    font-size: 15px;
    color: #666; }
    article.proDetailBox.prepaid.wifi .detailBoxBd .wifiBoxComplex .wifiBoxHd ul li {
      display: inline-block;
      width: 45%; }
  article.proDetailBox.prepaid.wifi .detailBoxBd .editBox ul li:after {
    position: absolute;
    top: 9px;
    left: 8px;
    content: "";
    width: 4px;
    height: 4px;
    background: #666;
    border-radius: 2px; }
  article.proDetailBox.prepaid.wifi .detailBoxBd .wifiBoxFt {
    text-align: center;
    padding: 20px 20px;
    font-size: 18px;
    color: #333;
    vertical-align: middle;
    box-sizing: border-box;
    background: #f8f2eb; }
    article.proDetailBox.prepaid.wifi .detailBoxBd .wifiBoxFt a.button {
      display: inline-block;
      color: #fff;
      font-size: 13px;
      height: 35px;
      line-height: 35px;
      padding: 0 15px;
      margin: 0 5px;
      text-align: center;
      background: #666;
      border: 1px #333 solid; }
      article.proDetailBox.prepaid.wifi .detailBoxBd .wifiBoxFt a.button:hover {
        background: #888; }
      article.proDetailBox.prepaid.wifi .detailBoxBd .wifiBoxFt a.button img {
        height: 16px;
        position: relative;
        top: -1px;
        width: 16px;
        border: 0; }
  article.proDetailBox .detailBoxBd .sidebar {
    float: right;
    width: 200px;
    margin: 0 30px; }

article.recommendBox .detailBoxBd .sidebar {
  float: right;
  width: 200px;
  margin: 0 30px;
  margin: auto; }

article.proDetailBox.postpaid .detailBoxBd .sidebar {
  margin: auto; }

article.proDetailBox .detailBoxBd .sidebar .slickLi {
  padding: 20px 20px 10px 20px;
  margin: 0 0 20px 0;
  text-align: center;
  border: 1px #e1e1e1 solid; }
  article.proDetailBox .detailBoxBd .sidebar .slickLi a {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: block;
    z-index: 5; }

article.proDetailBox .detailBoxBd .sidebar .photo {
  position: relative;
  display: table;
  height: 160px;
  width: 160px;
  margin: 0 auto 10px auto; }
  article.proDetailBox .detailBoxBd .sidebar .photo span {
    display: table-cell;
    vertical-align: middle; }
  article.proDetailBox .detailBoxBd .sidebar .photo img {
    height: 100%;
    max-height: 160px;
    max-width: 160px; }

article.proDetailBox .detailBoxBd .sidebar .title {
  display: table;
  vertical-align: middle;
  padding: 10px 0 0 0;
  width: 100%;
  line-height: 1.3em;
  height: 2.6em;
  font-size: 16px;
  border-top: 1px #ececec solid; }
  article.proDetailBox .detailBoxBd .sidebar .title span {
    display: table-cell;
    vertical-align: middle; }

article.recommendBox .detailBoxBd {
  padding: 30px 40px;
  margin: 20px 0 0 0; }
  article.recommendBox .detailBoxBd .sidebar .box {
    margin: 0 0 18px 0; }
  article.recommendBox .detailBoxBd .sidebar a.button {
    display: block;
    color: #fff;
    font-size: 16px;
    height: 42px;
    line-height: 42px;
    text-align: center;
    background: #666;
    border: 1px #333 solid; }
    article.recommendBox .detailBoxBd .sidebar a.button.important {
      border: 1px #e71a0f solid;
      background: #e71a0f; }
      article.recommendBox .detailBoxBd .sidebar a.button.important:hover {
        border: 1px #f23c32 solid;
        background: #f23c32; }
    article.recommendBox .detailBoxBd .sidebar a.button.delComplex {
      color: #e1e1e1;
      border: 1px #999999 solid;
      background: #999999;
      cursor: default; }
      article.recommendBox .detailBoxBd .sidebar a.button.delComplex:hover {
        color: #e1e1e1;
        border: 1px #999999 solid;
        background: #999999;
        cursor: default; }
    article.recommendBox .detailBoxBd .sidebar a.button:hover {
      background: #888; }
    article.recommendBox .detailBoxBd .sidebar a.button img {
      height: 16px;
      position: relative;
      top: -2px;
      width: 16px; }
  article.recommendBox .detailBoxBd .sidebar .box.max {
    padding: 25px 16px 30px 16px;
    text-align: center;
    border: 1px #e1e1e1 solid; }
    article.recommendBox .detailBoxBd .sidebar .box.max .mainTitle {
      font-size: 20px;
      font-weight: bold;
      margin: 0 0 10px 0;
      vertical-align: bottom; }
      article.recommendBox .detailBoxBd .sidebar .box.max .mainTitle img {
        vertical-align: bottom; }
    article.recommendBox .detailBoxBd .sidebar .box.max p {
      color: #666;
      font-size: 15px;
      line-height: 1.4em;
      margin: 0 0 10px 0; }
    article.recommendBox .detailBoxBd .sidebar .box.max a.button {
      height: 32px;
      line-height: 32px; }
  article.recommendBox .detailBoxBd.first {
    margin: 0; }
  article.recommendBox .detailBoxBd:after {
    content: "";
    display: table;
    clear: both; }
  article.recommendBox .detailBoxBd.lineup {
    border-top: 3px solid #da1b0d; }
  article.recommendBox .detailBoxBd .leftColumn {
    float: left;
    width: 980px; }
  article.recommendBox .detailBoxBd .partbox {
    position: relative;
    margin: 30px 0 10px;
    border-bottom: 1px #c1c1c1 solid; }
    article.recommendBox .detailBoxBd .partbox:first-child {
      margin: 0; }
    article.recommendBox .detailBoxBd .partbox .unfoldBox {
      padding: 20px;
      margin: 10px 0 0 0;
      background: #f4f4f4; }
    article.recommendBox .detailBoxBd .partbox .unfold {
      max-height: 70px;
      overflow: hidden;
      transition: all 0.3s; }
      article.recommendBox .detailBoxBd .partbox .unfold.on {
        max-height: 2000px;
        overflow: hidden;
        transition: all 0.3s; }

article.empowerBox .descbox, article.recommendBox .detailBoxBd .descbox {
  position: relative;
  margin: 30px 0 0 0;
  padding: 30px 40px;
  background: #fcfaf4;
  clear: both; }

article.winnerBox .detailBoxBd .descbox {
  position: relative;
  margin: 30px -40px -30px -40px;
  padding: 30px 40px;
  background: #fcfaf4;
  clear: both; }

article.empowerBox .descbox {
  margin: 0; }

article.recommendBox .detailBoxBd .accordion .descbox {
  margin: 0; }

article.recommendBox .detailBoxBd .descbox.topNoMargin {
  margin: 0; }

article.recommendBox .detailBoxBd .descbox.negativeMargin {
  margin: 30px -40px -40px -40px; }

article.empowerBox .descbox.line, article.winnerBox .detailBoxBd .descbox.line {
  border-bottom: 1px #c1c1c1 solid; }

article.recommendBox .detailBoxBd .caseBox .descbox, article.recommendBox .detailBoxBd .descbox.line {
  border-bottom: 1px #c1c1c1 solid; }

article.empowerBox .descbox .unfold, article.winnerBox .detailBoxBd .descbox .unfold, article.recommendBox .detailBoxBd .descbox .unfold {
  max-height: 60px;
  overflow: hidden;
  transition: all 0.3s; }

article.empowerBox .descbox .unfold ol li, article.winnerBox .detailBoxBd .descbox .unfold ol li {
  font-size: 13px; }

article.empowerBox .descbox .unfold ul li, article.winnerBox .detailBoxBd .descbox .unfold ul li {
  font-size: 13px; }
  article.empowerBox .descbox .unfold ul li *, article.winnerBox .detailBoxBd .descbox .unfold ul li * {
    font-size: 13px; }

article.empowerBox .descbox .unfold ol li *, article.winnerBox .detailBoxBd .descbox .unfold ol li * {
  font-size: 13px; }

article.recommendBox .detailBoxBd .descbox .unfold ul li, article.recommendBox .detailBoxBd .descbox .unfold ol li, article.recommendBox .detailBoxBd .descbox .unfold ul li *, article.recommendBox .detailBoxBd .descbox .unfold ol li * {
  font-size: 13px; }

article.empowerBox .descbox .unfold.on, article.winnerBox .detailBoxBd .descbox .unfold.on, article.recommendBox .detailBoxBd .descbox .unfold.on {
  max-height: 2000px;
  overflow: hidden;
  transition: all 0.3s; }

article.empowerBox .descbox .mainTitle, article.winnerBox .detailBoxBd .descbox .mainTitle, article.recommendBox .detailBoxBd .descbox .mainTitle {
  font-size: 15px;
  font-weight: bold;
  padding: 0 0 10px 0;
  margin: 0 0 15px 0;
  border-bottom: 1px #e1e1e1 solid;
  color: #333; }

article.empowerBox .partbox a.close, article.empowerBox .descbox a.close {
  position: absolute;
  right: 40px;
  top: 25px;
  display: block;
  width: 30px;
  height: 30px;
  font-size: 0;
  transform: rotate(45deg); }

article.winnerBox .detailBoxBd .partbox a.close, article.winnerBox .detailBoxBd .descbox a.close {
  position: absolute;
  right: 40px;
  top: 25px;
  display: block;
  width: 30px;
  height: 30px;
  font-size: 0;
  transform: rotate(45deg); }

article.recommendBox .detailBoxBd .partbox a.close, article.recommendBox .detailBoxBd .descbox a.close {
  position: absolute;
  right: 40px;
  top: 25px;
  display: block;
  width: 30px;
  height: 30px;
  font-size: 0;
  transform: rotate(45deg); }

article.recommendBox .detailBoxBd .partbox a.close {
  right: 0;
  top: 0; }

article.empowerBox .partbox a.close.odd, article.empowerBox .descbox a.close.odd {
  transform: rotate(0deg); }

article.winnerBox .detailBoxBd .partbox a.close.odd, article.winnerBox .detailBoxBd .descbox a.close.odd {
  transform: rotate(0deg); }

article.recommendBox .detailBoxBd .partbox a.close.odd, article.recommendBox .detailBoxBd .descbox a.close.odd {
  transform: rotate(0deg); }

article.empowerBox .partbox a.close, article.empowerBox .descbox a.close {
  background-size: 30px auto; }
  article.empowerBox .partbox a.close:hover, article.empowerBox .descbox a.close:hover {
    background-size: 30px auto; }
  article.empowerBox .partbox a.close.odd, article.empowerBox .descbox a.close.odd {
    background-size: 30px auto; }
    article.empowerBox .partbox a.close.odd:hover, article.empowerBox .descbox a.close.odd:hover {
      background-size: 30px auto; }

article.recommendBox .detailBoxBd .partbox a.close, article.recommendBox .detailBoxBd .descbox a.close {
  background-size: 30px auto; }
  article.recommendBox .detailBoxBd .partbox a.close:hover, article.recommendBox .detailBoxBd .descbox a.close:hover {
    background-size: 30px auto; }
  article.recommendBox .detailBoxBd .partbox a.close.odd, article.recommendBox .detailBoxBd .descbox a.close.odd {
    background-size: 30px auto; }
    article.recommendBox .detailBoxBd .partbox a.close.odd:hover, article.recommendBox .detailBoxBd .descbox a.close.odd:hover {
      background-size: 30px auto; }

article.winnerBox .detailBoxBd .descbox a.close {
  background-size: 30px auto; }
  article.winnerBox .detailBoxBd .descbox a.close:hover {
    background-size: 30px auto; }
  article.winnerBox .detailBoxBd .descbox a.close.odd {
    background-size: 30px auto; }
    article.winnerBox .detailBoxBd .descbox a.close.odd:hover {
      background-size: 30px auto; }

article.empowerBox .partbox a.open, article.empowerBox .descbox a.open, article.empowerBox .accordion a.open {
  position: absolute;
  left: 50%;
  bottom: -15px;
  display: block;
  width: 26px;
  height: 26px;
  margin-left: -13px;
  font-size: 0;
  background-size: 26px auto;
  border-radius: 13px;
  z-index: 1; }

article.recommendBox .detailBoxBd .partbox a.open, article.recommendBox .detailBoxBd .descbox a.open, article.recommendBox .detailBoxBd .accordion a.open {
  position: absolute;
  left: 50%;
  bottom: -15px;
  display: block;
  width: 26px;
  height: 26px;
  margin-left: -13px;
  font-size: 0;
  background-size: 26px auto;
  border-radius: 13px;
  z-index: 1; }

article.winnerBox .detailBoxBd .descbox a.open {
  position: absolute;
  left: 50%;
  bottom: -15px;
  display: block;
  width: 26px;
  height: 26px;
  margin-left: -13px;
  font-size: 0;
  background-size: 26px auto;
  border-radius: 13px;
  z-index: 1; }

article.recommendBox .detailBoxBd .accordion a.open {
  display: none; }

article.empowerBox .partbox a.open.odd, article.empowerBox .descbox a.open.odd, article.empowerBox .accordion.open a.open {
  display: block;
  transform: rotate(180deg); }

article.recommendBox .detailBoxBd .partbox a.open.odd, article.recommendBox .detailBoxBd .descbox a.open.odd, article.recommendBox .detailBoxBd .accordion.open a.open {
  display: block;
  transform: rotate(180deg); }

article.winnerBox .detailBoxBd .descbox a.open.odd {
  display: block;
  transform: rotate(180deg); }

article.recommendBox .detailBoxBd .singleColumn {
  margin: 40px 0 0 0; }

article.recommendBox .detailBoxBd .first {
  margin: 0; }

article.recommendBox .detailBoxBd .mainTitle {
  margin: 0 0 0.4em;
  color: #333;
  font-size: 24px;
  font-weight: bold;
  line-height: 1.4em; }
  article.recommendBox .detailBoxBd .mainTitle .selectCoupon {
    display: inline-block;
    vertical-align: middle;
    width: 170px;
    margin: -4px 0 0 10px; }
    article.recommendBox .detailBoxBd .mainTitle .selectCoupon > .selectboxit-container {
      border: 1px #c5c5c5 solid; }
    article.recommendBox .detailBoxBd .mainTitle .selectCoupon .selectboxit-container span {
      height: 30px;
      line-height: 30px; }
    article.recommendBox .detailBoxBd .mainTitle .selectCoupon .selectboxit-container * {
      font-size: 15px; }
    article.recommendBox .detailBoxBd .mainTitle .selectCoupon .selectboxit .selectboxit-option-icon-container {
      margin-left: 10px; }
    article.recommendBox .detailBoxBd .mainTitle .selectCoupon .selectboxit-arrow-container {
      background-size: 12px auto; }
      article.recommendBox .detailBoxBd .mainTitle .selectCoupon .selectboxit-arrow-container > i {
        display: none; }

article.recommendBox .detailBoxBd .editBox .evenBox .mainTitle {
  font-size: 20px; }

article.recommendBox .detailBoxBd .subTitle {
  position: relative;
  margin: 0 0 0.2em;
  padding: 0 0 0 15px;
  color: #333;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.4em; }
  article.recommendBox .detailBoxBd .subTitle:after {
    position: absolute;
    top: 3px;
    left: 0;
    content: "";
    width: 3px;
    height: 20px;
    background: #999; }
  article.recommendBox .detailBoxBd .subTitle.max {
    font-size: 32px; }
    article.recommendBox .detailBoxBd .subTitle.max:after {
      top: 8px;
      height: 28px; }
  article.recommendBox .detailBoxBd .subTitle img {
    margin: -2px 5px 0 0;
    max-width: 70px; }

article.recommendBox .detailBoxBd .singleColumn .texthd {
  color: #333;
  line-height: 1.4em; }

article.recommendBox .detailBoxBd .singleColumn .caseBox {
  padding: 40px 40px 40px 40px; }
  article.recommendBox .detailBoxBd .singleColumn .caseBox:last-child {
    padding: 0 40px 40px 40px; }

article.recommendBox .detailBoxBd .linkbox {
  font-size: 15px;
  margin: 0 0 30px -10px; }
  article.recommendBox .detailBoxBd .linkbox a {
    padding: 0 10px; }

article.recommendBox .detailBoxBd .siteMapBox {
  padding: 20px 0 0 0;
  margin: 0 0 80px 0;
  min-height: 100px;
  border-top: 2px #e71a0f solid; }
  article.recommendBox .detailBoxBd .siteMapBox:last-child {
    margin: 0; }
  article.recommendBox .detailBoxBd .siteMapBox ul li img {
    max-width: 30px; }
  article.recommendBox .detailBoxBd .siteMapBox ul.liwidthany li {
    display: table-cell;
    vertical-align: top;
    padding: 0 50px 0 0; }
  article.recommendBox .detailBoxBd .siteMapBox ul li a.hd, article.recommendBox .detailBoxBd .siteMapBox ul li a.st {
    font-size: 16px;
    font-weight: bold;
    color: #333; }
  article.recommendBox .detailBoxBd .siteMapBox ul.liwidthany li a.hd {
    line-height: 30px; }
    article.recommendBox .detailBoxBd .siteMapBox ul.liwidthany li a.hd img {
      position: relative;
      top: -1px; }
  article.recommendBox .detailBoxBd .siteMapBox ul li a {
    display: block;
    font-size: 15px;
    color: #666; }
    article.recommendBox .detailBoxBd .siteMapBox ul li a:hover {
      color: #e71a0f; }
  article.recommendBox .detailBoxBd .siteMapBox ul.liwidth20 {
    border-top: 1px #e1e1e1 solid;
    padding: 20px 0 0 0;
    margin: 20px 0 0 0; }
    article.recommendBox .detailBoxBd .siteMapBox ul.liwidth20:first-child {
      border-top: 0;
      padding: 0;
      margin: 0; }
    article.recommendBox .detailBoxBd .siteMapBox ul.liwidth20:after {
      content: "";
      display: table;
      clear: both; }
    article.recommendBox .detailBoxBd .siteMapBox ul.liwidth20 li {
      float: left;
      width: 19.2%;
      margin: 0 1% 0 0;
      box-sizing: border-box; }
      article.recommendBox .detailBoxBd .siteMapBox ul.liwidth20 li:last-child {
        margin: 0; }
    article.recommendBox .detailBoxBd .siteMapBox ul.liwidth20 a {
      padding: 5px 0 5px 14px; }
      article.recommendBox .detailBoxBd .siteMapBox ul.liwidth20 a.hd {
        position: relative;
        display: block;
        padding: 0 0 0 14px;
        margin: 0 0 8px 0; }
      article.recommendBox .detailBoxBd .siteMapBox ul.liwidth20 a.st {
        position: relative;
        display: block;
        padding: 0 0 0 14px;
        margin: 0 0 8px 0; }
        article.recommendBox .detailBoxBd .siteMapBox ul.liwidth20 a.st:after {
          position: absolute;
          top: 50%;
          left: 1px;
          content: "";
          width: 6px;
          height: 6px;
          margin-top: -3px;
          background: #e71a0f;
          border-radius: 3px; }
      article.recommendBox .detailBoxBd .siteMapBox ul.liwidth20 a.hd:after {
        position: absolute;
        top: 50%;
        left: 1px;
        content: "";
        width: 6px;
        height: 6px;
        margin-top: -3px;
        background: #e71a0f;
        border-radius: 3px; }
      article.recommendBox .detailBoxBd .siteMapBox ul.liwidth20 a.rd {
        position: relative;
        display: block;
        padding: 0 0 0 46px;
        margin: 4px 0 8px 0; }
        article.recommendBox .detailBoxBd .siteMapBox ul.liwidth20 a.rd::after {
          position: absolute;
          top: 50%;
          left: 34px;
          content: "";
          width: 4px;
          height: 4px;
          margin-top: -3px;
          background: #999;
          border-radius: 2px; }

article.prepaidBoxHd {
  margin: 40px auto 0 auto;
  max-width: 1280px; }
  article.prepaidBoxHd:first-child {
    margin: 0 auto; }
  article.prepaidBoxHd .listBox {
    margin: -14px -14px 0; }
    article.prepaidBoxHd .listBox:after {
      content: "";
      display: table;
      clear: both; }
    article.prepaidBoxHd .listBox .slickLi {
      float: left;
      width: 408px;
      margin: 14px 14px 0; }
      article.prepaidBoxHd .listBox .slickLi .conBox:after {
        content: "";
        display: table;
        clear: both; }
      article.prepaidBoxHd .listBox .slickLi .mainTitle {
        position: relative;
        padding: 20px 20px;
        height: 80px;
        font-size: 16px;
        line-height: 1.3em;
        font-weight: bold;
        box-sizing: border-box;
        background: #dedede; }
        article.prepaidBoxHd .listBox .slickLi .mainTitle img {
          position: absolute;
          top: 0;
          right: 0; }
        article.prepaidBoxHd .listBox .slickLi .mainTitle span {
          display: inline-block;
          vertical-align: middle; }
        article.prepaidBoxHd .listBox .slickLi .mainTitle:after {
          display: inline-block;
          vertical-align: middle;
          content: "";
          height: 100%; }
      article.prepaidBoxHd .listBox .slickLi .conBox {
        margin: 20px 0; }
        article.prepaidBoxHd .listBox .slickLi .conBox .box {
          position: relative;
          float: left;
          width: 50%;
          padding: 0 20px;
          box-sizing: border-box; }
          article.prepaidBoxHd .listBox .slickLi .conBox .box.line:after {
            position: absolute;
            top: 0;
            right: 0;
            content: "";
            width: 1px;
            height: 100%;
            background: #d8d8d8; }
        article.prepaidBoxHd .listBox .slickLi .conBox a {
          position: relative;
          display: block;
          padding: 5px 0 5px 15px;
          font-size: 15px;
          line-height: 1.2em; }
          article.prepaidBoxHd .listBox .slickLi .conBox a:after {
            position: absolute;
            top: 10px;
            left: 2px;
            content: "";
            width: 5px;
            height: 5px;
            background: #333;
            border-radius: 2.5px; }
          article.prepaidBoxHd .listBox .slickLi .conBox a.hd {
            padding: 5px 0;
            font-size: 16px;
            font-weight: bold; }
        article.prepaidBoxHd .listBox .slickLi .conBox span.hd {
          position: relative;
          display: block;
          line-height: 1.2em;
          padding: 5px 0;
          font-size: 16px;
          font-weight: bold; }
        article.prepaidBoxHd .listBox .slickLi .conBox a.hd:after {
          display: none; }

article.appComplexBox {
  margin: 20px auto 0 auto;
  max-width: 1280px; }
  article.appComplexBox:first-child {
    margin: 0 auto; }
  article.appComplexBox:after {
    content: "";
    display: table;
    clear: both; }
  article.appComplexBox .listBox {
    margin: -14px -7px 0 -7px; }
    article.appComplexBox .listBox:after {
      content: "";
      display: table;
      clear: both; }
    article.appComplexBox .listBox .conComplexBox {
      float: left;
      width: 633px;
      margin: 14px 7px 0;
      padding: 30px 35px;
      box-sizing: border-box; }
    article.appComplexBox .listBox .conBoxBd {
      position: relative; }
      article.appComplexBox .listBox .conBoxBd > a {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1; }
    article.appComplexBox .listBox .conComplexBox .photo, article.appComplexBox .listBox .conComplexBox .conBox {
      display: table-cell;
      vertical-align: top; }
    article.appComplexBox .listBox .conComplexBox .photo {
      width: 100px; }
      article.appComplexBox .listBox .conComplexBox .photo img {
        max-width: 100px; }
    article.appComplexBox .listBox .conComplexBox .conBox {
      padding: 5px 0 0 30px; }
    article.appComplexBox .listBox .conComplexBox .title {
      font-size: 16px;
      font-weight: bold;
      color: #333;
      line-height: 1.4em;
      margin: 0 0 5px 0; }
    article.appComplexBox .listBox .conComplexBox .conBoxBd:hover .title {
      color: #e71a0f; }
    article.appComplexBox .listBox .conComplexBox .text {
      font-size: 15px;
      color: #666;
      line-height: 1.4em; }
    article.appComplexBox .listBox .conBoxFt {
      margin: 20px 0 0 0;
      padding: 20px 0 0 0;
      border-top: 1px #e1e1e1 solid; }
      article.appComplexBox .listBox .conBoxFt .slickLi {
        padding: 0 20px 0 0;
        display: table-cell;
        vertical-align: top; }

article.prepaidBoxBd {
  margin: 20px auto 0 auto;
  max-width: 1280px; }
  article.prepaidBoxBd:first-child {
    margin: 0 auto; }
  article.prepaidBoxBd:after {
    content: "";
    display: table;
    clear: both; }
  article.prepaidBoxBd .listBox {
    margin: -14px -7px; }
    article.prepaidBoxBd .listBox:after {
      content: "";
      display: table;
      clear: both; }
    article.prepaidBoxBd .listBox > .conBox {
      float: left;
      width: 633px;
      margin: 14px 7px 0;
      border-top: 3px #e71a0f solid;
      padding: 20px 35px;
      box-sizing: border-box; }
  article.prepaidBoxBd .mainTitle {
    font-size: 15px;
    font-weight: bold;
    margin: 0 0 15px 0; }
  article.prepaidBoxBd .listBox .conBox .conBoxBd {
    position: relative;
    padding: 0 0 20px 0;
    margin: 0 0 20px 0;
    border-bottom: 1px #d8d8d8 solid; }
  article.prepaidBoxBd .listBox .conBoxBd .photo, article.prepaidBoxBd .listBox .conBoxBd .conBox {
    display: table-cell;
    vertical-align: top; }
  article.prepaidBoxBd .listBox .conBoxFt .photo, article.prepaidBoxBd .listBox .conBoxFt .conBox {
    display: table-cell;
    vertical-align: top; }
  article.prepaidBoxBd .listBox .conBoxBd .photo {
    position: relative;
    width: 350px; }
    article.prepaidBoxBd .listBox .conBoxBd .photo img {
      width: 100%;
      max-width: 350px;
      border: 1px solid #f7f7f7;
      box-sizing: border-box; }
  article.prepaidBoxBd .listBox .conBoxBd .conBox {
    padding: 0 0 0 20px; }
    article.prepaidBoxBd .listBox .conBoxBd .conBox .title {
      font-size: 20px;
      font-weight: bold;
      line-height: 1.3em;
      margin: 0 0 15px 0; }
    article.prepaidBoxBd .listBox .conBoxBd .conBox .text {
      font-size: 15px;
      color: #666;
      line-height: 1.3em; }
  article.prepaidBoxBd .listBox .conBoxFt .slick-slider {
    margin-bottom: 0; }
  article.prepaidBoxBd .listBox .conBoxFt .slickLi {
    position: relative; }
  article.prepaidBoxBd .listBox .conBoxBd a {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 5; }
  article.prepaidBoxBd .listBox .conBoxFt a {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 5; }
  article.prepaidBoxBd .listBox .conBoxFt .photo {
    position: relative;
    width: 150px; }
    article.prepaidBoxBd .listBox .conBoxFt .photo img {
      width: 100%;
      max-width: 150px;
      border: 1px solid #f7f7f7;
      box-sizing: border-box; }
  article.prepaidBoxBd .listBox .conBoxFt .conBox {
    padding: 0 15px 0 15px; }
    article.prepaidBoxBd .listBox .conBoxFt .conBox .title {
      font-size: 16px;
      font-weight: bold;
      line-height: 1.3em; }
  article.prepaidBoxBd .conBoxFt .slick-track {
    margin: 0;
    margin: initial; }
  article.prepaidBoxBd .conBoxFt .slick-slider .slick-prev {
    left: -35px; }
  article.prepaidBoxBd .conBoxFt .slick-slider .slick-next {
    right: -35px; }
  article.prepaidBoxBd .conBoxFt .slick-slider .slick-prev, article.prepaidBoxBd .conBoxFt .slick-slider .slick-next {
    height: 60px;
    margin-top: -30px;
    width: 44px;
    background-size: 30px auto; }
  article.prepaidBoxBd .conBoxFt .slick-dots {
    bottom: -30px; }

article.ratePlanHd {
  position: relative;
  width: 1280px;
  height: 350px;
  margin: 25px auto 0 auto; }
  article.ratePlanHd .kvComplex {
    position: absolute;
    top: 0;
    left: 0;
    width: 1280px;
    height: 350px; }
  article.ratePlanHd .mainBox {
    position: absolute;
    top: 56px;
    bottom: 40px;
    left: 50%;
    width: 1240px;
    margin: 0 0 0 -620px;
    z-index: 1; }
    article.ratePlanHd .mainBox .mainTitle {
      color: #fff;
      font-size: 32px;
      font-weight: bold;
      margin: 0 0 15px 0; }
    article.ratePlanHd .mainBox .text {
      color: #fff;
      font-size: 15px;
      line-height: 1.6em;
      margin: 0 0 15px 0; }
    article.ratePlanHd .mainBox .start span {
      position: relative;
      padding: 10px 0 8px 0;
      color: #fff;
      font-size: 15px;
      line-height: 1.4em;
      border-bottom: 1px #fff solid; }
      article.ratePlanHd .mainBox .start span:after {
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        bottom: -12px;
        left: 50%;
        position: absolute;
        pointer-events: none;
        border-top-color: #fff;
        border-width: 6px;
        border-left-width: 5px;
        border-right-width: 5px;
        margin-left: -3px; }

article .tabs.tabRatePlan {
  position: relative;
  margin: -130px 0 0 0;
  z-index: 1; }
  article .tabs.tabRatePlan > ul {
    width: 578px;
    margin: 0 auto 35px auto;
    border-bottom: 0;
    text-align: center; }
    article .tabs.tabRatePlan > ul li {
      position: relative;
      width: 160px;
      height: 185px;
      padding: 0;
      display: inline-block;
      vertical-align: top;
      text-align: center;
      margin: 0 14px;
      border: 1px #e1e1e1 solid;
      border-bottom: 4px #afafaf solid;
      background: #fff; }
      article .tabs.tabRatePlan > ul li a {
        display: table;
        width: 100%;
        height: 100%;
        color: #333;
        font-size: 16px;
        font-weight: bold;
        padding: 130px 10px 0 10px;
        border-left: 0;
        box-sizing: border-box; }
        article .tabs.tabRatePlan > ul li a span.link {
          display: block;
          position: absolute;
          top: 20px;
          left: 32px;
          z-index: 1; }
        article .tabs.tabRatePlan > ul li a span.hover {
          display: block;
          position: absolute;
          top: 20px;
          left: 32px;
          z-index: 1;
          display: none; }
      article .tabs.tabRatePlan > ul li.ui-tabs-active a span.link {
        display: none; }
      article .tabs.tabRatePlan > ul li.ui-tabs-active a span.hover {
        display: block; }
      article .tabs.tabRatePlan > ul li a span.text {
        display: table-cell;
        vertical-align: middle; }
      article .tabs.tabRatePlan > ul li a img {
        max-width: 100px; }
      article .tabs.tabRatePlan > ul li:nth-child(1) a {
        border-left: 0; }
      article .tabs.tabRatePlan > ul li.arrow a {
        background-size: 16px auto; }
      article .tabs.tabRatePlan > ul li:hover, article .tabs.tabRatePlan > ul li.ui-tabs-active {
        border: 1px #e71a0f solid;
        border-bottom: 4px #e71a0f solid; }
      article .tabs.tabRatePlan > ul li.arrow a:hover {
        background-size: 16px auto; }
      article .tabs.tabRatePlan > ul li.arrow.ui-tabs-active a {
        background-size: 16px auto; }
        article .tabs.tabRatePlan > ul li.arrow.ui-tabs-active a:hover {
          background-size: 16px auto; }
      article .tabs.tabRatePlan > ul li.ui-tabs-active:after {
        bottom: 0;
        left: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-bottom-color: #e71a0f;
        border-width: 8px;
        border-left-width: 10px;
        border-right-width: 10px;
        margin-left: -10px; }

article.ratePlanBd .ratePlanBox {
  margin: 0 0 20px 0; }
  article.ratePlanBd .ratePlanBox .mainTitle {
    height: 50px;
    line-height: 50px;
    color: #333;
    font-size: 20px;
    background: #cfcfcf; }
    article.ratePlanBd .ratePlanBox .mainTitle .number {
      position: relative;
      float: left;
      width: 70px;
      padding: 0 0 0 10px;
      height: 50px;
      line-height: 50px;
      margin: 0 40px 0 0;
      text-align: center;
      color: #fff;
      font-size: 24px;
      font-weight: bold;
      background: #b6b6b6; }
      article.ratePlanBd .ratePlanBox .mainTitle .number:after {
        top: 0;
        left: 100%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-left-color: #b6b6b6;
        border-width: 15px;
        border-top-width: 25px;
        border-bottom-width: 25px;
        margin-left: 0; }
    article.ratePlanBd .ratePlanBox .mainTitle.on .number {
      background: #e71a0f; }
      article.ratePlanBd .ratePlanBox .mainTitle.on .number:after {
        border-left-color: #e71a0f; }
  article.ratePlanBd .ratePlanBox ul {
    display: table;
    margin: 0 -10px; }
    article.ratePlanBd .ratePlanBox ul li {
      width: 20%;
      display: table-cell;
      vertical-align: top; }
      article.ratePlanBd .ratePlanBox ul li .boxComplex {
        position: relative;
        padding: 20px;
        margin: 15px 10px;
        height: 110px;
        font-size: 15px;
        line-height: 1.4em;
        text-align: center;
        box-sizing: border-box;
        border: 1px #dbdbdb solid;
        background: #fff; }
      article.ratePlanBd .ratePlanBox ul li.active .boxComplex:after {
        position: absolute;
        top: 0;
        left: 0;
        content: " ";
        height: 37px;
        width: 37px; }
      article.ratePlanBd .ratePlanBox ul li .boxComplex a {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: 1; }
      article.ratePlanBd .ratePlanBox ul li:hover .boxComplex {
        border: 1px #e71a0f solid; }
      article.ratePlanBd .ratePlanBox ul li.active .boxComplex {
        border: 1px #e71a0f solid;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3); }
      article.ratePlanBd .ratePlanBox ul li .boxComplex .vertical {
        display: table;
        width: 100%;
        height: 70px; }
      article.ratePlanBd .ratePlanBox ul li .boxComplex p {
        color: #666;
        display: table-cell;
        vertical-align: middle; }
      article.ratePlanBd .ratePlanBox ul li .boxComplex span {
        color: #e71a0f; }

article.ratePlanBd .resultBox {
  border: 1px #e71a0f solid;
  border-top: 3px #e71a0f solid; }
  article.ratePlanBd .resultBox:after {
    content: "";
    display: table;
    clear: both; }
  article.ratePlanBd .resultBox .leftColumn {
    float: left;
    width: 475px;
    padding: 30px 30px 40px 30px;
    min-height: 465px;
    color: #333;
    background: #e4e4e4;
    box-sizing: border-box; }
    article.ratePlanBd .resultBox .leftColumn .mainTitle {
      padding: 12px 0 0 47px;
      margin: 0 0 10px 0;
      font-size: 24px;
      font-weight: bold;
      line-height: 1.8em; }
    article.ratePlanBd .resultBox .leftColumn .text {
      position: relative;
      padding: 0 30px 0 50px;
      font-size: 15px;
      line-height: 1.8em;
      min-height: 320px; }
      article.ratePlanBd .resultBox .leftColumn .text:after {
        position: absolute;
        top: 0;
        left: 0;
        content: "";
        width: 40px;
        height: 40px; }
      article.ratePlanBd .resultBox .leftColumn .text:before {
        position: absolute;
        bottom: 0;
        right: -10px;
        content: "";
        width: 40px;
        height: 40px; }
  article.ratePlanBd .resultBox .rightColumn {
    position: relative;
    float: right;
    width: 800px;
    padding: 40px;
    min-height: 465px;
    color: #666;
    box-sizing: border-box; }
    article.ratePlanBd .resultBox .rightColumn .subTitle {
      font-size: 16px;
      margin: 0 0 15px 0; }
    article.ratePlanBd .resultBox .rightColumn .mainTitle {
      margin: 0 0 10px 0;
      color: #e71a0f;
      font-size: 20px;
      font-weight: bold;
      line-height: 1.4em; }
    article.ratePlanBd .resultBox .rightColumn .text {
      padding: 0 0 20px 0;
      font-size: 15px;
      line-height: 1.8em;
      border-bottom: 1px #e1e1e1 solid; }
    article.ratePlanBd .resultBox .rightColumn.editBox .btnDownBox {
      margin: 30px 0 0 0; }
    article.ratePlanBd .resultBox .rightColumn .desc {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 20px;
      width: 100%;
      color: #999;
      font-size: 13px;
      text-align: center; }

article.ratePlanBd .detailBoxBd {
  margin: 0;
  padding: 0; }

article.searchBox {
  margin: 20px auto 0 auto;
  max-width: 1280px; }
  article.searchBox:first-child {
    margin: 0 auto; }
  article.searchBox:after {
    content: "";
    display: table;
    clear: both; }
  article.searchBox .schLeftColumn {
    float: left;
    width: 1000px;
    min-height: 500px; }
    article.searchBox .schLeftColumn .schresulttxt {
      margin: 0 0 20px 0;
      padding: 0 0 0 10px;
      border-left: 3px #e92f20 solid;
      font-size: 15px; }
    article.searchBox .schLeftColumn .schnonetxt {
      margin: 0 0 20px 0;
      font-size: 18px;
      color: #666; }
    article.searchBox .schLeftColumn ul.noneMsg {
      font-size: 15px;
      color: #666;
      margin: 0 0 0 20px; }
      article.searchBox .schLeftColumn ul.noneMsg li {
        position: relative;
        padding: 4px 0 4px 18px; }
        article.searchBox .schLeftColumn ul.noneMsg li:after {
          position: absolute;
          top: 8px;
          left: 2px;
          content: "";
          width: 7px;
          height: 7px;
          background: #666;
          border-radius: 3.5px; }
    article.searchBox .schLeftColumn .txtAD {
      width: 640px;
      height: 100px;
      overflow: hidden;
      margin: 40px 0 0 0;
      border-top: 1px #dedede solid; }
    article.searchBox .schLeftColumn ul.noneDesc {
      width: 640px;
      height: 100px;
      padding: 20px 0 20px 10px;
      font-size: 13px;
      background: #f2f2f2; }
      article.searchBox .schLeftColumn ul.noneDesc li {
        position: relative;
        padding: 4px 0 4px 18px; }
        article.searchBox .schLeftColumn ul.noneDesc li:after {
          position: absolute;
          top: 8px;
          left: 4px;
          content: "";
          width: 4px;
          height: 4px;
          background: #333;
          border-radius: 2px; }

article.recommendBox .detailBoxBd .accordion {
  position: relative;
  padding: 0 35px 0;
  margin: 17px 0 0 0;
  border: 1px #dfdfdf solid; }
  article.recommendBox .detailBoxBd .accordion.open:first-child {
    margin: 0 0 40px 0; }
  article.recommendBox .detailBoxBd .accordion:first-child {
    margin: 0 0 17px 0; }
  article.recommendBox .detailBoxBd .accordion.open {
    margin-bottom: 40px;
    border-bottom: 1px #c1c1c1 solid; }

article.searchBox .schLeftColumn b, article.recommendBox .detailBoxBd .accordion .mainTitle b {
  color: #e71a0f; }

article.searchBox .detailBoxBd {
  margin: 20px 0 0;
  padding: 20px 20px; }

article.searchBox .schLeftColumn .schmainbox {
  margin: 20px 0 0;
  padding: 20px 0 0;
  border-top: 1px #dedede solid; }
  article.searchBox .schLeftColumn .schmainbox:first-child {
    margin: 0;
    padding: 0;
    border-top: 0; }

article.searchBox .schLeftColumn .mainTitle {
  position: relative;
  color: #333;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.2em;
  z-index: 1; }

article.recommendBox .detailBoxBd .accordion > .mainTitle {
  position: relative;
  color: #333;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.2em;
  z-index: 1;
  font-size: 16px; }

article.searchBox .schLeftColumn .mainTitle span, article.recommendBox .detailBoxBd .accordion .mainTitle span {
  padding: 0 10px;
  border-left: 2px #333 solid; }

article.searchBox .schLeftColumn .mainTitle span:first-child, article.recommendBox .detailBoxBd .accordion .mainTitle span:first-child {
  padding: 0 10px 0 0;
  border-left: 0; }

article.searchBox .schLeftColumn .mainTitle a.trigger, article.recommendBox .detailBoxBd .leftColumn .mainTitle a.trigger {
  position: absolute;
  right: 15px;
  top: 50%;
  display: block;
  width: 30px;
  height: 30px;
  font-size: 0;
  margin: -15px 0 0 0;
  transform: rotate(45deg); }

article.searchBox .schLeftColumn .mainTitle a.trigger.odd {
  transform: rotate(0deg); }

article.recommendBox .detailBoxBd .leftColumn .mainTitle a.trigger {
  background-size: 30px auto; }
  article.recommendBox .detailBoxBd .leftColumn .mainTitle a.trigger.odd {
    transform: rotate(0deg); }
  article.recommendBox .detailBoxBd .leftColumn .mainTitle a.trigger:hover {
    background-size: 30px auto; }
  article.recommendBox .detailBoxBd .leftColumn .mainTitle a.trigger.odd {
    background-size: 30px auto; }
    article.recommendBox .detailBoxBd .leftColumn .mainTitle a.trigger.odd:hover {
      background-size: 30px auto; }

article.searchBox .schLeftColumn .mainTitle a.trigger {
  background-size: 30px auto; }
  article.searchBox .schLeftColumn .mainTitle a.trigger:hover {
    background-size: 30px auto; }
  article.searchBox .schLeftColumn .mainTitle a.trigger.odd {
    background-size: 30px auto; }
    article.searchBox .schLeftColumn .mainTitle a.trigger.odd:hover {
      background-size: 30px auto; }

article.searchBox .schLeftColumn .schBoxHd {
  margin: -20px;
  padding: 15px;
  padding: 15px 60px 15px 15px;
  background: #dfdfdf;
  cursor: pointer; }

article.recommendBox .detailBoxBd .leftColumn .schBoxHd {
  margin: -20px;
  padding: 15px;
  padding: 15px 60px 15px 15px;
  background: #dfdfdf;
  cursor: pointer; }

article.recommendBox .detailBoxBd .mainTitle.o2orrHd {
  background: #f2f2f2;
  border-bottom: 1px #999 solid;
  box-shadow: 0 3px 3px rgba(100, 100, 100, 0.15); }

article.recommendBox .detailBoxBd .leftColumn .schBoxHd {
  margin: 0 -35px 0; }

article.searchBox .schLeftColumn .accordion.open .schBoxHd {
  margin: -20px -20px 20px; }

article.recommendBox .detailBoxBd .leftColumn .accordion.open .schBoxHd {
  margin: 0 -35px 20px; }

article.searchBox .schLeftColumn .schmainbox .text {
  margin: 10px 0 0 0;
  color: #666;
  font-size: 15px;
  line-height: 1.4em; }
  article.searchBox .schLeftColumn .schmainbox .text a {
    color: #666; }
    article.searchBox .schLeftColumn .schmainbox .text a:hover {
      color: #e71a0f; }

article.searchBox .schLeftColumn .slickLi {
  margin: 0 9px;
  text-align: center; }

article.searchBox .schLeftColumn .slick-slider .slick-prev {
  left: -40px; }

article.searchBox .schLeftColumn .slick-slider .slick-next {
  right: -40px; }

article.searchBox .schLeftColumn .slick-dots {
  bottom: -38px; }

article.searchBox .schLeftColumn .slickDevice .photo {
  position: relative;
  width: 230px;
  height: 230px;
  text-align: center;
  margin: 0 auto 10px auto; }

article.searchBox .schLeftColumn .photo .msgBox {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 50%;
  height: 40px;
  line-height: 40px;
  margin: auto;
  font-size: 13px;
  text-align: center;
  color: #fff;
  background: rgba(0, 0, 0, 0.6);
  box-sizing: border-box;
  z-index: 1; }

article.searchBox .schLeftColumn .slickDevice .photo img {
  display: inline;
  height: 100%;
  max-height: 250px;
  max-width: 250px;
  max-width: 230px;
  max-height: 230px; }

article.searchBox .schLeftColumn .slickOther .photo {
  position: relative;
  width: 220px;
  height: 220px;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  margin: 0 auto 10px auto; }
  article.searchBox .schLeftColumn .slickOther .photo a {
    display: inline-block;
    vertical-align: middle; }
    article.searchBox .schLeftColumn .slickOther .photo a:after {
      width: 0;
      height: 100%;
      vertical-align: middle; }
  article.searchBox .schLeftColumn .slickOther .photo.imgShadow .shadow {
    bottom: 15px;
    left: 15px;
    right: 15px;
    top: 15px; }
  article.searchBox .schLeftColumn .slickOther .photo img {
    display: inline;
    max-width: 190px;
    max-height: 190px; }
  article.searchBox .schLeftColumn .slickOther .photo.imgShadow .icon {
    position: absolute;
    left: 15px;
    bottom: 15px;
    right: 15px;
    text-align: center;
    height: 22px;
    color: #fff;
    font: 12px / 22px "Arial"; }

article.searchBox .schLeftColumn .line {
  position: relative;
  border: 1px solid #d8d8d8;
  padding: 30px 0 10px; }
  article.searchBox .schLeftColumn .line .label {
    position: absolute;
    top: 0;
    right: 0;
    width: 66px;
    height: 64px;
    z-index: 1; }

article.searchBox .schLeftColumn .slickOther .line {
  padding: 0 0 10px; }

article.searchBox .schLeftColumn .iconBox {
  position: relative;
  height: 54px;
  padding: 10px 0 20px 0;
  z-index: 2; }
  article.searchBox .schLeftColumn .iconBox .notifyBox {
    position: absolute;
    bottom: 72px;
    left: 7px;
    right: 7px;
    height: 68px; }
    article.searchBox .schLeftColumn .iconBox .notifyBox .notifyBoxBd {
      height: 58px;
      padding: 8px 40px 8px 10px;
      font-size: 15px;
      line-height: 1.4em;
      text-align: left;
      color: #fff;
      background: #835935;
      box-sizing: border-box;
      z-index: 1; }
    article.searchBox .schLeftColumn .iconBox .notifyBox span.buy {
      padding: 0 15px 0 5px;
      font-weight: bold;
      background-size: 20px auto; }
    article.searchBox .schLeftColumn .iconBox .notifyBox a {
      color: #fff; }
      article.searchBox .schLeftColumn .iconBox .notifyBox a.close {
        position: absolute;
        top: 7px;
        right: 7px;
        display: inline-block;
        width: 20px;
        height: 20px;
        font-size: 0;
        cursor: pointer;
        background-size: 20px auto; }
      article.searchBox .schLeftColumn .iconBox .notifyBox a:hover {
        color: #fff; }
  article.searchBox .schLeftColumn .iconBox ul {
    padding: 0 4px 0 0;
    text-align: center; }
    article.searchBox .schLeftColumn .iconBox ul li {
      display: inline-block;
      padding: 2px 4px;
      margin: 0 -4px 0 0;
      border-left: 1px #aaa solid; }

article.searchBox .schLeftColumn .slickDevice .iconBox ul li {
  border-left: 1px #c3a78a solid; }

article.searchBox .schLeftColumn .iconBox ul li:nth-child(1) {
  border: 0; }

article.searchBox .schLeftColumn .iconBox ul li:hover {
  background: #e5e5e5; }

article.searchBox .schLeftColumn .iconBox ul li > a {
  position: relative;
  display: block; }

article.searchBox .schLeftColumn .iconBox ul li.msgComplex > a:after {
  top: -10px;
  left: 21px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-top-color: #835935;
  border-width: 8px;
  border-left-width: 9px;
  border-right-width: 9px;
  filter: alpha(opacity=0);
  -moz-opacity: 0;
  opacity: 0;
  transition: all 0.4s; }

article.searchBox .schLeftColumn .iconBox ul li.msgComplex.arrow > a:after {
  filter: alpha(opacity=100);
  -moz-opacity: 1;
  opacity: 1;
  transition: all 0.4s; }

article.searchBox .schLeftColumn .iconBox img {
  display: inline;
  width: 54px; }

article.searchBox .schLeftColumn .schinner {
  max-height: 0;
  transition: max-height 0.2s; }

article.recommendBox .detailBoxBd .leftColumn .schinner, article.searchBox .schLeftColumn .schinner .schconBox {
  max-height: 0;
  transition: max-height 0.2s; }

article.recommendBox .detailBoxBd .leftColumn .schinner {
  overflow: hidden; }

article.searchBox .schLeftColumn .schinner .schconBox {
  margin: 0 -30px;
  padding: 0 30px;
  overflow: hidden; }

article.searchBox .schLeftColumn .accordion.open .schinner {
  max-height: 999999px;
  transition: max-height 0.3s; }

article.recommendBox .detailBoxBd .leftColumn .accordion.open .schinner, article.searchBox .schLeftColumn .accordion.open .schinner .schconBox {
  max-height: 999999px;
  transition: max-height 0.3s; }

article.recommendBox .detailBoxBd .leftColumn .accordion.open .schinner {
  margin: 0 0 30px 0; }

article.searchBox .schLeftColumn .schinner .title {
  position: relative;
  width: 90%;
  border-top: 0;
  margin: 10px auto;
  padding: 10px 0 0 0;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5em;
  height: 3em;
  display: table;
  vertical-align: middle;
  z-index: 1; }

article.searchBox .schLeftColumn .schinner .slickOther .title {
  padding: 0; }

article.searchBox .schLeftColumn .schinner .title span {
  display: table-cell;
  vertical-align: middle; }

article.searchBox .schLeftColumn .schinner .slickOther .title span a {
  display: block; }
  article.searchBox .schLeftColumn .schinner .slickOther .title span a.txtComplex {
    font-weight: normal;
    color: #666;
    font-size: 15px; }
  article.searchBox .schLeftColumn .schinner .slickOther .title span a:hover.txtComplex {
    color: #e71a0f; }

article.searchBox .schLeftColumn .schinner .title a.icon {
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 25px;
  height: 24px; }
  article.searchBox .schLeftColumn .schinner .title a.icon img {
    position: absolute;
    top: 0;
    left: 0;
    max-width: 25px;
    max-height: 24px; }
    article.searchBox .schLeftColumn .schinner .title a.icon img.hover {
      display: none; }

article.searchBox .schLeftColumn .schinner .title a:hover.icon img.link {
  display: none; }

article.searchBox .schLeftColumn .schinner .title a.icon img.link, article.searchBox .schLeftColumn .schinner .title a:hover.icon img.hover {
  display: block; }

article.searchBox .schLeftColumn .schmoer {
  margin: -5px 0 5px 0;
  text-align: right; }
  article.searchBox .schLeftColumn .schmoer:after {
    content: "";
    display: table;
    clear: both; }
  article.searchBox .schLeftColumn .schmoer a {
    position: relative;
    float: right;
    display: block;
    padding: 0 16px 0 0;
    font-size: 13px;
    background-size: 16px auto;
    z-index: 1; }
    article.searchBox .schLeftColumn .schmoer a:hover {
      color: #e71a0f;
      background-size: 16px auto; }

article.searchBox .schSidebar {
  float: right;
  width: 250px; }
  article.searchBox .schSidebar .schhotbox {
    font-size: 15px; }
    article.searchBox .schSidebar .schhotbox.line {
      border: 1px #d5d5d5 solid; }
    article.searchBox .schSidebar .schhotbox .mainTitle {
      padding: 8px 12px;
      color: #666;
      background: #dfdfdf;
      box-sizing: border-box; }
    article.searchBox .schSidebar .schhotbox ul {
      padding: 8px 12px;
      color: #333;
      box-sizing: border-box; }
      article.searchBox .schSidebar .schhotbox ul li {
        padding: 4px 0; }
  article.searchBox .schSidebar .fetAD {
    margin: 20px 0 0 0;
    min-height: 222px; }
    article.searchBox .schSidebar .fetAD .mainTitle {
      font-weight: bold;
      line-height: 18px; }
    article.searchBox .schSidebar .fetAD .text {
      line-height: 16px;
      color: #666; }
      article.searchBox .schSidebar .fetAD .text a {
        color: #666; }
        article.searchBox .schSidebar .fetAD .text a:hover {
          color: #e71a0f; }
    article.searchBox .schSidebar .fetAD .fetADBox {
      min-height: 222px;
      padding: 8px 12px;
      box-sizing: border-box;
      background: #dfdfdf; }
    article.searchBox .schSidebar .fetAD ul {
      font-size: 12px;
      color: #333;
      width: 206px;
      height: 190px;
      margin: 8px auto 0 auto;
      overflow: hidden; }
      article.searchBox .schSidebar .fetAD ul li {
        padding: 6px 0; }

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  article.roamBox .selectboxit-container span {
    height: 38px;
    line-height: 38px; }
  article.roamBox .selectBox input[type="text"] {
    line-height: 36px;
    height: 36px; } }

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  article.roamBox .selectBox input[type="text"] {
    height: 30px;
    line-height: 30px;
    text-indent: 0; } }

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  article.roamBox .selectBox input[type="submit"] {
    line-height: 35px; } }

/**
 **
 ** rateBox
 **
 ** */
/**
 **
 ** brandBox
 **
 ** */
/**
 **
 ** brandBox
 **
 ** */
/**
 **
 ** stepStyle1
 **
 ** */
/**
 **
 ** stepStyle2
 **
 ** */
/**
 **
 ** stepStyle3
 **
 ** */
/**
 **
 ** stepStyle4
 **
 ** */
/**
 **
 ** qaBoxComplex
 **
 ** */
/**
 **
 ** sidebar
 **
 ** */
/* 20180129
 *article.proDetailBox.prepaid.wifi .detailBoxBd .wifiBoxComplex .wifiBoxHd ul li:nth-child(3n) {
 *    width: 16%;
 *} */
/**
 **
 ** sidebar
 **
 ** */
/**
 **
 ** prepaidBoxHd
 **
 ** */
/**
 **
 ** prepaidBoxBd
 **
 ** */
/**
 **
 ** prepaidBoxBd
 **
 ** */
/**
 **
 ** mainBoxHd
 **
 ** */
/**
 **
 ** searchBox
 **
 ** */
/** styled table with links */
.tableBox {
  color: #666;
  font-size: 15px;
  line-height: 1.6em; }
  .tableBox * {
    color: #666;
    font-size: 15px;
    line-height: 1.6em; }
  .tableBox th, .tableBox td {
    padding: 8px 10px;
    font-size: 15px; }
  .tableBox th {
    color: #333;
    background: #f2e6d6; }
    .tableBox th.left {
      text-align: left !important; }
  .tableBox td.left {
    text-align: left !important; }
  .tableBox th.center, .tableBox td.center {
    text-align: center !important; }
  .tableBox th.right, .tableBox td.right {
    text-align: right !important; }
  .tableBox th.top, .tableBox td.top {
    vertical-align: top !important; }
  .tableBox table th img, .tableBox table td img {
    border: 0; }
  .tableBox table.AlistTable {
    margin: 20px 0 0 0; }
    .tableBox table.AlistTable:first-child {
      margin: 0; }
    .tableBox table.AlistTable th, .tableBox table.AlistTable td {
      padding: 15px 15px;
      text-align: center;
      vertical-align: middle;
      border: 1px #e1e1e1 solid; }
    .tableBox table.AlistTable tr {
      cursor: pointer; }
      .tableBox table.AlistTable tr:hover {
        background: #f3f3f3; }
    .tableBox table.AlistTable th {
      border: 0;
      border-bottom: 1px #e1e1e1 solid; }
    .tableBox table.AlistTable td {
      border: 0;
      border-bottom: 1px #e1e1e1 solid; }
      .tableBox table.AlistTable td.linkTile {
        color: #333;
        font-weight: bold;
        padding-right: 100px; }
        .tableBox table.AlistTable td.linkTile:hover {
          color: #e71a0f; }
        .tableBox table.AlistTable td.linkTile > a {
          display: inline-block; }
    .tableBox table.AlistTable tr.firstrow td {
      padding-top: 10px; }

/** winnerBox */
article.winnerBox {
  width: 1280px;
  margin: 0 auto; }

article.proDetailBox.winner {
  margin: 0 auto;
  padding: 0; }

article.winnerBox .detailBoxBd {
  padding: 0; }

article.winnerBox .mainBoxHd {
  position: relative;
  width: 1278px;
  border: 1px #e1e1e1 solid; }
  article.winnerBox .mainBoxHd img {
    width: 100%; }
  article.winnerBox .mainBoxHd .mainTitle {
    position: absolute;
    width: 1200px;
    height: 38px;
    left: 50%;
    top: 50%;
    margin-left: -600px;
    margin-top: -19px;
    font-size: 40px;
    font-weight: bold;
    line-height: 1.2em;
    color: #666; }

article.winnerBox .singleColumn {
  width: 1200px;
  margin: 0 auto;
  padding: 30px 0; }
  article.winnerBox .singleColumn .mainTitle {
    margin-top: 35px; }
    article.winnerBox .singleColumn .mainTitle a {
      text-decoration: none; }
  article.winnerBox .singleColumn .subTitle {
    position: relative;
    margin: 0 0 0.2em;
    padding: 0 0 0 15px;
    color: #333;
    font-size: 18px;
    font-weight: bold;
    line-height: 1.4em; }
    article.winnerBox .singleColumn .subTitle:after {
      position: absolute;
      top: 3px;
      left: 0;
      content: "";
      width: 3px;
      height: 20px;
      background: #999; }
  article.winnerBox .singleColumn h3.font20b a {
    font-size: 20px !important;
    font-weight: bold !important; }
  article.winnerBox .singleColumn .tdBox {
    display: table-cell;
    vertical-align: top; }
    article.winnerBox .singleColumn .tdBox a {
      display: block;
      color: #e71a0f; }
  article.winnerBox .singleColumn .winList {
    margin: 15px 0 0 0; }

article.ppsalBox {
  width: 1280px;
  margin: 0 auto; }
  article.ppsalBox article.proDetailBox, article.ppsalBox .detailBoxBd {
    padding: 0; }
  article.ppsalBox article.proDetailBox {
    margin: 0 auto; }
  article.ppsalBox .mainBoxHd {
    padding: 15px 30px;
    background: #dfdfdf; }
    article.ppsalBox .mainBoxHd .mainTitle {
      font-size: 16px;
      font-weight: bold;
      color: #333; }
    article.ppsalBox .mainBoxHd .reminder {
      font-size: 13px;
      height: 19px;
      padding: 15px 0 0 0;
      color: #d62a1e; }
      article.ppsalBox .mainBoxHd .reminder em {
        position: relative;
        top: 3px;
        padding: 0 3px;
        color: #d62a1e; }
  article.ppsalBox .detailBoxBd .ppsalFormbox {
    padding: 15px 0 30px 0; }

/** ppsalBox */
.ppsalFormbox fieldset.formContent {
  width: 1100px;
  margin: 0 auto; }

.ppsalFormbox fieldset .box {
  padding: 15px 0;
  border-top: 1px solid #e1e1e1; }
  .ppsalFormbox fieldset .box.first {
    padding-top: 20px;
    border-top: 0; }
  .ppsalFormbox fieldset .box.odd {
    padding: 5px 0;
    border-top: 0; }
  .ppsalFormbox fieldset .box:after {
    content: "";
    display: table;
    clear: both; }
  .ppsalFormbox fieldset .box.error .txt {
    margin: 5px 0 0 0;
    font-size: 13px;
    color: #d42c27; }
  .ppsalFormbox fieldset .box .text b {
    font-size: 13px;
    font-weight: normal;
    color: #e71a0f; }
  .ppsalFormbox fieldset .box .hfBox {
    width: 50%; }
    .ppsalFormbox fieldset .box .hfBox.left {
      float: left; }
    .ppsalFormbox fieldset .box .hfBox.right {
      float: right; }
  .ppsalFormbox fieldset .box .fullBox {
    width: 100%; }

.ppsalFormbox fieldset label {
  position: relative;
  width: 110px;
  padding: 0 0 0 10px; }
  .ppsalFormbox fieldset label:after {
    position: absolute;
    top: 14px;
    left: 0;
    display: block;
    content: "";
    width: 6px;
    height: 6px;
    background: #bbb; }

.ppsalFormbox fieldset .box label {
  float: left;
  font-size: 15px;
  line-height: 36px;
  color: #666; }

.ppsalFormbox fieldset .box.reminder {
  font-size: 13px;
  height: 19px;
  padding: 15px 0 0 0;
  color: #d62a1e; }

.ppsalFormbox fieldset .box .con {
  float: left;
  font-size: 16px;
  color: #333; }

.ppsalFormbox fieldset.comfirm .box .con {
  line-height: 1.6em;
  padding: 5px 0; }

.ppsalFormbox fieldset .box .con.rowComplex_2 .boxComplex label {
  float: none;
  cursor: pointer;
  font-size: 16px; }
  .ppsalFormbox fieldset .box .con.rowComplex_2 .boxComplex label:after {
    display: none; }

.ppsalFormbox fieldset .box .hfBox .con {
  width: 390px;
  margin-right: 30px; }

.ppsalFormbox fieldset .box .fullBox .con {
  width: 950px;
  margin-right: 30px; }
  .ppsalFormbox fieldset .box .fullBox .con textarea {
    width: 940px; }

.ppsalFormbox fieldset .box p {
  min-height: 34px;
  line-height: 34px;
  font-size: 16px;
  color: #333; }
  .ppsalFormbox fieldset .box p.font15 {
    min-height: auto;
    line-height: 1.6em;
    color: #666; }

.ppsalFormbox fieldset .box span.note {
  display: inline-block;
  width: 100%;
  position: relative;
  top: -10px; }

.ppsalFormbox fieldset .box .con .code {
  margin: 0 0 10px 0; }
  .ppsalFormbox fieldset .box .con .code img {
    vertical-align: bottom; }
  .ppsalFormbox fieldset .box .con .code a.renew {
    padding: 0 20px 0 0;
    background-size: 16px auto; }
  .ppsalFormbox fieldset .box .con .code a:hover.renew {
    background-size: 16px auto; }

.ppsalFormbox fieldset em {
  position: relative;
  top: 3px;
  padding: 0 3px;
  color: #d62a1e; }

.ppsalFormbox fieldset input[type="text"], .ppsalFormbox fieldset input[type="email"], .ppsalFormbox fieldset input[type="password"] {
  width: 100%;
  padding: 0;
  margin: 0;
  height: 32px;
  line-height: 32px;
  font-size: 14px;
  font-family: Arial, "Heiti TC", "Microsoft JhengHei", Helvetica, sans-serif;
  text-indent: 10px;
  vertical-align: middle;
  color: #000;
  background: #fff;
  border: 1px solid #adadad;
  box-sizing: border-box; }

.ppsalFormbox fieldset textarea {
  width: 100%;
  padding: 0;
  margin: 0;
  height: 32px;
  line-height: 32px;
  font-size: 14px;
  font-family: Arial, "Heiti TC", "Microsoft JhengHei", Helvetica, sans-serif;
  text-indent: 10px;
  vertical-align: middle;
  color: #000;
  background: #fff;
  border: 1px solid #adadad;
  box-sizing: border-box;
  min-height: 64px;
  line-height: 1.2em;
  padding: 6px 10px;
  text-indent: 0;
  resize: vertical; }

.ppsalFormbox fieldset input[type="text"]:focus, .ppsalFormbox fieldset input[type="email"]:focus, .ppsalFormbox fieldset input[type="password"]:focus {
  background: #fcfaf4;
  border: 1px solid #ab5f00; }

.ppsalFormbox fieldset textarea:focus {
  background: #fcfaf4;
  border: 1px solid #ab5f00; }

.ppsalFormbox fieldset .errorMsgBd {
  width: 100%;
  max-width: 680px;
  min-width: 510px;
  padding: 10px 0;
  text-align: center;
  font-size: 15px;
  color: #d42c27;
  background: #fff4e5;
  margin: 10px auto; }
  .ppsalFormbox fieldset .errorMsgBd img {
    width: 21px;
    height: 18px;
    vertical-align: bottom; }

.ppsalFormbox fieldset .btnBox {
  clear: both;
  margin: 20px auto;
  text-align: center; }
  .ppsalFormbox fieldset .btnBox > div {
    position: relative;
    display: inline-block;
    vertical-align: middle; }
  .ppsalFormbox fieldset .btnBox input[type="button"], .ppsalFormbox fieldset .btnBox input[type="submit"], .ppsalFormbox fieldset .btnBox input[type="reset"] {
    position: relative;
    margin: 0 5px;
    padding: 0 20px;
    color: #000;
    font-size: 13px;
    line-height: 2em;
    vertical-align: middle;
    border: 1px #afafaf solid;
    cursor: pointer;
    border-radius: 3px;
    background: #f3f3f3;
    background: linear-gradient(to bottom, #f3f3f3 0%, #e0e0e0 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f3f3f3', endColorstr='#e0e0e0', GradientType=0); }
  .ppsalFormbox fieldset .btnBox input[type="button"]:hover, .ppsalFormbox fieldset .btnBox input[type="submit"]:hover, .ppsalFormbox fieldset .btnBox input[type="reset"]:hover {
    border: 1px #ab5f00 solid; }

.ppsalFormbox fieldset .msgDescBox ul li {
  position: relative;
  padding: 0 0 0 10px;
  font-size: 15px;
  line-height: 1.6em;
  color: #666; }
  .ppsalFormbox fieldset .msgDescBox ul li:after {
    position: absolute;
    top: 8px;
    left: 2px;
    content: "";
    width: 5px;
    height: 5px;
    background: #999;
    border-radius: 2.5px; }

.ppsalFormbox fieldset .box .con.rowComplex_2 {
  max-width: 720px;
  line-height: 1em;
  margin: 5px 0; }
  .ppsalFormbox fieldset .box .con.rowComplex_2 .boxComplex {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    word-wrap: break-word;
    color: #666; }

.ppsalFormbox fieldset .box .con.rowComplex_4 {
  width: 950px;
  margin-right: 30px; }
  .ppsalFormbox fieldset .box .con.rowComplex_4 input[type="text"] {
    width: 200px;
    height: 46px;
    line-height: 46px; }
  .ppsalFormbox fieldset .box .con.rowComplex_4 .code {
    display: inline-block;
    vertical-align: top; }

.ppsalFormbox fieldset .box .fileBox {
  float: left;
  position: relative;
  width: 31.3%;
  height: 200px;
  margin: 10px 0;
  margin-right: 1%;
  background: #e1e1e1;
  text-align: center;
  font-size: 16px;
  line-height: 1.4em;
  color: #666; }
  .ppsalFormbox fieldset .box .fileBox.highlight {
    transition: background linear 0.6s;
    -moz-transition: background linear 0.6s;
    -webkit-transition: background linear 0.6s;
    -o-transition: background linear 0.6s;
    background: #bfbebe;
    color: #333; }
  .ppsalFormbox fieldset .box .fileBox .uploadArea a, .ppsalFormbox fieldset .box .fileBox .uploadArea .txt {
    color: #666; }
  .ppsalFormbox fieldset .box .fileBox.highlight .uploadArea a, .ppsalFormbox fieldset .box .fileBox.highlight .uploadArea .txt {
    color: #333; }
  .ppsalFormbox fieldset .box .fileBox.uploaded .uploadArea a, .ppsalFormbox fieldset .box .fileBox.uploaded .uploadArea .txt {
    color: #e71a0f; }
  .ppsalFormbox fieldset .box .fileBox .lable {
    position: relative;
    width: 0;
    height: 0;
    padding-bottom: 50px;
    padding-left: 50px;
    overflow: hidden; }
    .ppsalFormbox fieldset .box .fileBox .lable:after {
      content: "";
      display: block;
      width: 0;
      height: 0;
      margin-left: -50px;
      border-bottom: 50px solid transparent;
      border-left: 50px solid #e71a0f; }
    .ppsalFormbox fieldset .box .fileBox .lable > span {
      position: absolute;
      left: 6px;
      top: 6px;
      display: inline-block;
      font-size: 24px;
      line-height: 1em;
      color: #fff; }
  .ppsalFormbox fieldset .box .fileBox .uploadArea {
    position: absolute;
    left: 0;
    top: 0;
    display: inline-block;
    width: 100%;
    height: 100%;
    vertical-align: middle; }
    .ppsalFormbox fieldset .box .fileBox .uploadArea > span.uploadAreaBox {
      position: absolute;
      left: 0;
      top: 0;
      display: inline-block;
      width: 100%;
      height: 100%;
      vertical-align: middle;
      cursor: pointer; }
  .ppsalFormbox fieldset .box .fileBox.uploaded .uploadArea .txt > a {
    position: relative;
    display: inline-block;
    padding: 0 0 0 30px;
    margin: 1px 0 0 10px;
    color: #333;
    background-size: 16px auto;
    filter: alpha(opacity=60);
    -moz-opacity: 0.6;
    opacity: 0.6;
    z-index: 9; }
    .ppsalFormbox fieldset .box .fileBox.uploaded .uploadArea .txt > a:after {
      position: absolute;
      top: 3px;
      left: 0;
      bottom: 3px;
      width: 1px;
      content: "";
      background: #333; }
    .ppsalFormbox fieldset .box .fileBox.uploaded .uploadArea .txt > a:hover {
      filter: alpha(opacity=100);
      -moz-opacity: 1;
      opacity: 1; }
  .ppsalFormbox fieldset .box .fileBox .uploadArea .obj {
    width: 100%;
    display: inline-block; }
  .ppsalFormbox fieldset .box .fileBox .uploadArea .icon {
    height: 128px; }
    .ppsalFormbox fieldset .box .fileBox .uploadArea .icon img {
      position: absolute;
      top: 38%;
      left: 50%;
      width: 52px;
      height: 70px;
      margin-top: -35px;
      margin-left: -26px;
      transition: all linear 0.1s;
      -moz-transition: all linear 0.1s;
      -webkit-transition: all linear 0.1s;
      -o-transition: all linear 0.1s; }
  .ppsalFormbox fieldset .box .fileBox.highlight .uploadArea .icon img {
    width: 62px;
    height: 84px;
    margin-top: -42px;
    margin-left: -31px; }

/** locationBox */
article.locationBox {
  width: 1280px;
  margin: 0 auto; }
  article.locationBox .textBox {
    padding: 160px 0 0 0;
    text-align: center; }
  article.locationBox article.proDetailBox .detailBoxBd {
    padding: 30px;
    min-height: 500px; }
  article.locationBox table {
    border-bottom: 1px #e1e1e1 solid; }
    article.locationBox table td {
      height: 40px; }
    article.locationBox table tr.infoBox:hover {
      background: #f3f3f3; }
    article.locationBox table .btn_pin {
      width: 22px;
      height: 40px;
      display: inline-block;
      vertical-align: middle; }
    article.locationBox table .distance {
      width: 105px;
      display: inline-block;
      vertical-align: middle; }
    article.locationBox table tr.infoBox.on {
      background: #f3f3f3; }
    article.locationBox table tr.mapBox td {
      padding: 0;
      line-height: normal;
      height: 0;
      overflow: hidden;
      border: 0;
      transition: height 0.3s; }
    article.locationBox table td #googlemapBox {
      position: relative; }
    article.locationBox table tr.mapBox.on td {
      height: 490px;
      border: 1px solid #e1e1e1;
      transition: height 0.3s; }
    article.locationBox table tr.mapBox .mapContent {
      height: 0;
      overflow: hidden;
      transition: height 0.3s; }
    article.locationBox table tr.mapBox.on .mapContent {
      height: 490px;
      overflow: inherit;
      transition: height 0.3s; }
    article.locationBox table tr.mapBox .topArrows {
      position: relative;
      width: 100%;
      height: 3px;
      background: #d1d1d1;
      display: none; }
    article.locationBox table tr.mapBox.on .topArrows {
      display: block; }
    article.locationBox table tr.mapBox .topArrows .aw {
      position: absolute;
      top: -10px;
      right: 24px;
      width: 20px;
      height: 20px; }
      article.locationBox table tr.mapBox .topArrows .aw > span {
        width: 0;
        height: 0;
        overflow: hidden; }
        article.locationBox table tr.mapBox .topArrows .aw > span:after {
          content: "";
          display: block;
          width: 0;
          height: 0;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-bottom: 10px solid #d1d1d1; }

article.regulationBox {
  width: 1280px;
  margin: 0 auto;
  display: table; }
  article.regulationBox article.leftDetailBox {
    display: table-cell;
    width: 240px;
    padding: 20px;
    border-top: 3px #bebebe solid; }
  article.regulationBox article.midBlockBox {
    display: table-cell;
    vertical-align: top;
    max-width: 20px;
    padding: 10px; }
  article.regulationBox article.rightDetailBox {
    display: table-cell;
    vertical-align: top;
    max-width: 940px;
    padding: 35px; }
  article.regulationBox .tabsContent {
    display: block;
    width: 240px;
    height: auto;
    min-height: 50px; }
    article.regulationBox .tabsContent ul li {
      padding: 7px 0;
      word-wrap: break-word;
      line-height: 1.2em; }
      article.regulationBox .tabsContent ul li:after {
        width: 0;
        height: 0; }
      article.regulationBox .tabsContent ul li a {
        line-height: 1.2em;
        text-decoration: none; }
    article.regulationBox .tabsContent .floating-widget.floating {
      position: fixed;
      top: 110px;
      width: 240px; }
    article.regulationBox .tabsContent .floating-widget.pinned-bottom {
      position: absolute;
      bottom: 40px;
      _position: static; }

article.newVerlocationBox {
  width: 1280px;
  margin: 0 auto; }
  article.newVerlocationBox .toolBox {
    width: 100%;
    margin: 0 0 10px 0; }
    article.newVerlocationBox .toolBox:after {
      content: "";
      display: table;
      clear: both; }
    article.newVerlocationBox .toolBox .searchHead {
      position: relative;
      float: left;
      width: 458px;
      padding: 2px 0 2px 10px;
      font-size: 16px;
      line-height: 24px;
      color: #333; }
      article.newVerlocationBox .toolBox .searchHead:after {
        position: absolute;
        top: 8%;
        left: 0;
        content: "";
        width: 3px;
        height: 82%;
        background: #e71a0f; }
  article.newVerlocationBox .recentBox .toolBox .searchHead {
    padding: 2px 0 2px 0;
    cursor: pointer;
    font-size: 17px;
    text-decoration: underline;
    line-height: 24px;
    color: #e81a0f; }
    article.newVerlocationBox .recentBox .toolBox .searchHead:after {
      display: none; }
  article.newVerlocationBox .noneBox {
    min-height: 300px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center; }
    article.newVerlocationBox .noneBox .main {
      margin: 0; }
    article.newVerlocationBox .noneBox .icon img {
      max-width: 120px; }
    article.newVerlocationBox .noneBox .text {
      margin: 15px 0 0 0;
      font-size: 16px;
      line-height: 1.4em;
      color: #333; }
  article.newVerlocationBox .toolBox .searchHead b {
    color: #e71a0f; }
  article.newVerlocationBox article.proDetailBox .detailBoxBd {
    padding: 30px;
    min-height: 500px; }
  article.newVerlocationBox .editBox table.posttable {
    margin: 0 0 40px 0; }
    article.newVerlocationBox .editBox table.posttable:first-child {
      margin: 0 0 40px 0; }
    article.newVerlocationBox .editBox table.posttable:last-child {
      margin: 0 0 20px 0; }
  article.newVerlocationBox table {
    border-bottom: 3px #e8cca7 solid; }
    article.newVerlocationBox table .btn_pin {
      width: 22px;
      height: 40px;
      display: inline-block;
      vertical-align: middle; }
      article.newVerlocationBox table .btn_pin img {
        max-width: 20px; }
  article.newVerlocationBox .editBox table.posttable tr.infoBox th {
    position: relative;
    padding: 15px 20px;
    text-align: center;
    vertical-align: middle;
    border: 0;
    border-top: 3px #e8cca7 solid;
    background-color: #f8f2eb;
    font-size: 18px;
    color: #333; }
  article.newVerlocationBox .editBox table.posttable th .mapBox {
    position: absolute;
    top: 12px;
    right: 20px;
    font-size: 16px;
    font-weight: normal;
    color: #333; }
    article.newVerlocationBox .editBox table.posttable th .mapBox .distance {
      position: relative;
      top: -2px;
      display: inline-block;
      vertical-align: middle; }
  article.newVerlocationBox .editBox table.posttable tr.infoBox:hover th {
    cursor: pointer;
    background: #e2e2e2; }
    article.newVerlocationBox .editBox table.posttable tr.infoBox:hover th .mapBox .distance {
      color: #e81a0f; }
  article.newVerlocationBox .editBox table.posttable tr.infoBoxHd th {
    padding: 8px 20px;
    text-align: center;
    vertical-align: middle;
    border: 0;
    border-bottom: 3px #fff solid;
    background-color: #edd9bf;
    font-size: 19px;
    color: #333; }
  article.newVerlocationBox .editBox table.posttable td {
    position: relative;
    padding: 20px 20px;
    text-align: center;
    vertical-align: middle;
    border: 0;
    font-size: 16px;
    color: #666;
    box-sizing: border-box; }
    article.newVerlocationBox .editBox table.posttable td:nth-child(1), article.newVerlocationBox .editBox table.posttable td:nth-child(2) {
      width: 320px; }
    article.newVerlocationBox .editBox table.posttable td:nth-child(3) {
      width: 160px; }
    article.newVerlocationBox .editBox table.posttable td:nth-child(4), article.newVerlocationBox .editBox table.posttable td:nth-child(5) {
      width: 240px;
      max-width: 240px;
      min-width: 240px; }
    article.newVerlocationBox .editBox table.posttable td > a.button {
      display: inline-block;
      width: 168px;
      height: 38px;
      line-height: 38px;
      text-align: center;
      text-decoration: none;
      font-size: 15px;
      color: #666;
      border: 1px #848484 solid; }
      article.newVerlocationBox .editBox table.posttable td > a.button.fb:before, article.newVerlocationBox .editBox table.posttable td > a.button.grant:before {
        float: left;
        width: 38px;
        height: 38px;
        content: ""; }
      article.newVerlocationBox .editBox table.posttable td > a.button.fb:before {
        background-size: 100% auto; }
      article.newVerlocationBox .editBox table.posttable td > a.button.grant:before {
        background-size: 100% auto; }
    article.newVerlocationBox .editBox table.posttable td > a:hover.button.fb {
      color: #3959a2;
      border: 1px #3959a2 solid; }
    article.newVerlocationBox .editBox table.posttable td > a:hover.button.grant {
      color: #c38f5d;
      border: 1px #c38f5d solid; }
    article.newVerlocationBox .editBox table.posttable td > a:hover.button.fb:before {
      background-color: #3959a2; }
    article.newVerlocationBox .editBox table.posttable td > a:hover.button.grant:before {
      background-color: #c38f5d; }
  article.newVerlocationBox .selectBox {
    position: relative;
    text-align: center;
    margin: 20px 0 50px 0; }
    article.newVerlocationBox .selectBox .selectClass {
      display: inline-block;
      vertical-align: middle;
      border: 1px #d2d2d2 solid; }
      article.newVerlocationBox .selectBox .selectClass .boxComplex {
        width: 280px; }
    article.newVerlocationBox .selectBox .boxComplex {
      position: relative;
      float: left; }
      article.newVerlocationBox .selectBox .boxComplex:first-child:after {
        position: absolute;
        top: 3px;
        right: 0;
        bottom: 3px;
        width: 1px;
        content: "";
        background-color: #b5b5b5; }
    article.newVerlocationBox .selectBox .selectClass .boxComplex .selectboxit-container .selectboxit-options {
      max-height: 240px !important; }
  article.newVerlocationBox .selectboxit-container span {
    height: 38px;
    line-height: 38px; }
  article.newVerlocationBox .selectboxit .selectboxit-option-icon-container {
    margin-left: 10px; }
  article.newVerlocationBox .selectboxit-text {
    color: #666;
    font-size: 16px; }
  article.newVerlocationBox .selectboxit-arrow-container {
    width: 46px; }
  article.newVerlocationBox .selectboxit-default-arrow {
    display: none; }
  article.newVerlocationBox .selectboxit-container * {
    font-size: 14px; }
  article.newVerlocationBox .selectboxit-options .selectboxit-disabled {
    opacity: 1;
    filter: alpha(opacity=100);
    box-shadow: none;
    cursor: default;
    background-color: #dddddd; }
  article.newVerlocationBox .selectboxit.selectboxit-disabled {
    opacity: 1;
    filter: alpha(opacity=100);
    box-shadow: none;
    cursor: default;
    background-color: #dddddd; }
    article.newVerlocationBox .selectboxit.selectboxit-disabled .selectboxit-arrow-container {
      opacity: 0.4; }
    article.newVerlocationBox .selectboxit.selectboxit-disabled .selectboxit-text {
      color: #aaa; }
  article.newVerlocationBox .subTitle {
    display: inline-block;
    vertical-align: middle;
    height: 38px;
    line-height: 38px;
    margin: 0 20px 0 0;
    color: #555;
    font-size: 18px;
    text-shadow: 0 0 0 rgba(0, 0, 0, 0); }

article.mainBoxHd.single .selectboxit-container span {
  height: 38px;
  line-height: 38px; }

article.globalNavBox {
  max-width: 1280px;
  margin: 0 auto; }
  article.globalNavBox .detailBox {
    float: left;
    width: 618px;
    height: 234px;
    padding: 30px 45px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: flex-start; }
    article.globalNavBox .detailBox h2 {
      font-size: 20px;
      color: #333;
      margin: 0 0 20px 0; }
    article.globalNavBox .detailBox ul {
      max-height: 120px;
      overflow: hidden; }
      article.globalNavBox .detailBox ul li {
        position: relative;
        font-size: 15px;
        line-height: 1.6em;
        color: #666;
        padding: 0 0 0 15px; }
        article.globalNavBox .detailBox ul li:after {
          position: absolute;
          left: 0;
          top: 9px;
          width: 5px;
          height: 5px;
          content: "";
          background: #a0a0a0;
          border-radius: 2.5px; }
  article.globalNavBox .navBox {
    float: right;
    width: 662px;
    height: 234px; }
    article.globalNavBox .navBox h2 {
      font-size: 20px;
      line-height: 30px;
      font-weight: normal;
      color: #fff; }
      article.globalNavBox .navBox h2 span {
        display: block;
        font-size: 28px;
        font-weight: bold; }
    article.globalNavBox .navBox p {
      font-size: 15px;
      line-height: 24px;
      color: #fff; }
    article.globalNavBox .navBox > div {
      padding: 32px 0 0 40px;
      box-sizing: border-box; }
      article.globalNavBox .navBox > div a {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 1; }
    article.globalNavBox .navBox .roamingBox {
      float: left;
      width: 426px;
      height: 234px; }
    article.globalNavBox .navBox .aircraftBox {
      position: relative;
      float: right;
      width: 236px;
      height: 117px; }
    article.globalNavBox .navBox .maritimeBox {
      position: relative;
      float: right;
      width: 236px;
      height: 117px; }
    article.globalNavBox .navBox > div.active:before {
      position: absolute;
      left: 50%;
      bottom: 3px;
      content: "";
      width: 0;
      height: 0;
      margin: 0 0 0 -10px;
      border-style: solid;
      border-width: 0 10px 10px 10px;
      border-color: transparent transparent #e71a0f transparent;
      z-index: 1; }
    article.globalNavBox .navBox > div.active:after {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 3px;
      content: "";
      background: #e71a0f;
      z-index: 1; }
    article.globalNavBox .navBox .roamingBox.active:before {
      left: 820px;
      margin: 0; }
    article.globalNavBox .navBox .roamingBox a {
      left: 618px;
      right: 236px; }

article.recommendBox .detailBoxBd .countryHdBox {
  margin: 0 0 20px 0; }
  article.recommendBox .detailBoxBd .countryHdBox .countryName {
    padding: 0 0 10px 0;
    border-bottom: 2px #ab5f00 solid; }
  article.recommendBox .detailBoxBd .countryHdBox .countryName .vertical {
    color: #825938;
    font-size: 32px;
    line-height: 40px; }
  article.recommendBox .detailBoxBd .countryHdBox .countryList ul {
    margin: 0 -7px; }
    article.recommendBox .detailBoxBd .countryHdBox .countryList ul li {
      width: 286px;
      height: 200px;
      padding: 0;
      display: inline-block;
      vertical-align: top;
      margin: 7px 7px 0 7px;
      text-align: center;
      border: 1px #ecd6ba solid;
      position: relative;
      box-shadow: 0 2px 3px 1px rgba(150, 150, 150, 0.15);
      background: #fff;
      box-sizing: border-box; }
      article.recommendBox .detailBoxBd .countryHdBox .countryList ul li .vertical {
        display: flex;
        align-items: center;
        justify-content: center; }
      article.recommendBox .detailBoxBd .countryHdBox .countryList ul li > a {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1; }
      article.recommendBox .detailBoxBd .countryHdBox .countryList ul li:hover {
        border: 1px #a85d00 solid;
        background: #fafafa; }
      article.recommendBox .detailBoxBd .countryHdBox .countryList ul li .vertical {
        width: 286px;
        height: 200px; }
      article.recommendBox .detailBoxBd .countryHdBox .countryList ul li span {
        display: block;
        font-size: 16px;
        line-height: 20px;
        color: #333; }
        article.recommendBox .detailBoxBd .countryHdBox .countryList ul li span.tel {
          font-size: 13px;
          color: #666; }

article.recommendBox .detailBoxBd .singleColumn.editBox .countryList ul li img {
  border: 0; }

article.recommendBox .detailBoxBd .trafficBox {
  display: table;
  width: 100%;
  padding: 25px 30px;
  margin: 0 0 25px 0;
  background-color: #f9f9f9;
  box-sizing: border-box; }
  article.recommendBox .detailBoxBd .trafficBox ul.trafficList {
    display: table-cell;
    vertical-align: top;
    width: 50%;
    padding: 0 20px 0 0;
    box-sizing: border-box; }
    article.recommendBox .detailBoxBd .trafficBox ul.trafficList:last-child {
      padding: 0 0 0 20px; }
    article.recommendBox .detailBoxBd .trafficBox ul.trafficList li {
      padding: 10px 5px; }
      article.recommendBox .detailBoxBd .trafficBox ul.trafficList li:after {
        display: none; }

article.recommendBox .detailBoxBd .countryHdBox .countryList ul li:after {
  display: none; }

article.recommendBox .detailBoxBd .trafficBox ul.trafficList li > div {
  display: table-cell;
  vertical-align: middle; }

article.recommendBox .detailBoxBd .trafficBox ul.trafficList li .icon {
  padding: 0 15px 0 0; }

article.recommendBox .detailBoxBd .trafficBox ul.trafficList li .text {
  font-size: 15px;
  line-height: 26px;
  color: #666; }

article.recommendBox .detailBoxBd .tabCountryList {
  margin: 0 -40px 35px -40px;
  padding: 0 0 30px 0;
  border-bottom: 2px #da1b0d solid; }
  article.recommendBox .detailBoxBd .tabCountryList ul {
    width: 1076px;
    margin: 0 auto; }
    article.recommendBox .detailBoxBd .tabCountryList ul li {
      position: relative;
      display: inline-block;
      width: 340px;
      height: 120px;
      margin: 0 8px; }
      article.recommendBox .detailBoxBd .tabCountryList ul li a {
        display: block;
        width: 340px;
        height: 120px;
        padding: 32px 0 0 30px;
        font-size: 32px;
        line-height: 32px;
        color: #444;
        text-decoration: none;
        background-color: #ededed;
        background-position: right;
        background-repeat: no-repeat;
        box-sizing: border-box; }
      article.recommendBox .detailBoxBd .tabCountryList ul li.active:before {
        position: absolute;
        left: 50%;
        bottom: -30px;
        content: "";
        width: 0;
        height: 0;
        margin: 0 0 0 -10px;
        border-style: solid;
        border-width: 0 10px 10px 10px;
        border-color: transparent transparent #e71a0f transparent;
        z-index: 1; }
      article.recommendBox .detailBoxBd .tabCountryList ul li a span {
        display: block;
        font-size: 24px;
        line-height: 24px; }
      article.recommendBox .detailBoxBd .tabCountryList ul li.active a, article.recommendBox .detailBoxBd .tabCountryList ul li a:hover {
        color: #333;
        background-color: #ccc; }

article.recommendBox .detailBoxBd .globalDirections {
  background-color: #f9f9f9; }
  article.recommendBox .detailBoxBd .globalDirections ol {
    position: relative; }
    article.recommendBox .detailBoxBd .globalDirections ol:before, article.recommendBox .detailBoxBd .globalDirections ol:after {
      position: absolute;
      top: 25px;
      bottom: 25px;
      content: "";
      display: block;
      width: 1px;
      background-color: #d8d8d8; }
    article.recommendBox .detailBoxBd .globalDirections ol:before {
      left: 33%; }
    article.recommendBox .detailBoxBd .globalDirections ol:after {
      right: 33%; }
    article.recommendBox .detailBoxBd .globalDirections ol > li {
      display: inline-block;
      vertical-align: middle;
      width: 33%;
      padding: 20px 35px;
      font-size: 20px;
      line-height: 30px;
      box-sizing: border-box; }
    article.recommendBox .detailBoxBd .globalDirections ol li .title {
      font-weight: bold;
      font-size: 20px;
      line-height: 30px; }
    article.recommendBox .detailBoxBd .globalDirections ol li span.text {
      font-size: 20px;
      font-weight: normal; }
    article.recommendBox .detailBoxBd .globalDirections ol li:nth-child(1) > div {
      margin: 8px 0; }
    article.recommendBox .detailBoxBd .globalDirections ol li:nth-child(1) .title {
      font-size: 36px;
      line-height: 40px; }
    article.recommendBox .detailBoxBd .globalDirections ol li:nth-child(1) .text {
      font-size: 20px;
      line-height: 30px; }
    article.recommendBox .detailBoxBd .globalDirections ol li .desc {
      font-size: 13px;
      line-height: 20px;
      color: #666; }
    article.recommendBox .detailBoxBd .globalDirections ol li .gdbox {
      margin: 10px 0; }
      article.recommendBox .detailBoxBd .globalDirections ol li .gdbox ul li {
        font-size: 15px;
        line-height: 22px; }
        article.recommendBox .detailBoxBd .globalDirections ol li .gdbox ul li span {
          font-size: 13px;
          color: #666; }
    article.recommendBox .detailBoxBd .globalDirections ol > li:nth-child(1) {
      text-align: center;
      color: #333; }

article.recommendBox .detailBoxBd .terminalBox {
  width: 924px;
  margin: 0 auto 10px auto; }
  article.recommendBox .detailBoxBd .terminalBox > div {
    background-color: #f1f1f1;
    box-sizing: border-box; }
  article.recommendBox .detailBoxBd .terminalBox .firstTerminal, article.recommendBox .detailBoxBd .terminalBox .secondTerminal {
    width: 452px;
    margin: 0 0 24px 0;
    text-align: center; }
  article.recommendBox .detailBoxBd .terminalBox .firstTerminal {
    margin: 0 20px 24px 0;
    float: left; }
  article.recommendBox .detailBoxBd .terminalBox .secondTerminal {
    float: right; }
  article.recommendBox .detailBoxBd .terminalBox .terminalHd {
    height: 66px;
    line-height: 66px;
    font-size: 20px;
    color: #333;
    background-color: #d8d8d8; }
  article.recommendBox .detailBoxBd .terminalBox .terminalBd {
    padding: 20px 0;
    text-align: center; }
    article.recommendBox .detailBoxBd .terminalBox .terminalBd img {
      border: 1px #cfcfcf solid; }
  article.recommendBox .detailBoxBd .terminalBox .terminalFt {
    padding: 0 0 20px 0;
    font-size: 16px;
    color: #333; }
  article.recommendBox .detailBoxBd .terminalBox .locationMap img {
    max-width: 100%;
    border: 0; }
  article.recommendBox .detailBoxBd .terminalBox .secondTerminalMsg {
    position: relative;
    padding: 30px 25px;
    clear: both; }
    article.recommendBox .detailBoxBd .terminalBox .secondTerminalMsg .title {
      font-weight: bold;
      margin: 0 0 15px 0; }
    article.recommendBox .detailBoxBd .terminalBox .secondTerminalMsg:before {
      position: absolute;
      left: 50%;
      top: -18px;
      content: "";
      width: 0;
      height: 0;
      margin: 0 0 0 280px;
      border-style: solid;
      border-width: 0 18px 18px 18px;
      border-color: transparent transparent #f1f1f1 transparent;
      z-index: 1; }

article.recommendBox .detailBoxBd .grWFServicebox {
  margin: -30px -40px;
  padding: 0 0 130px 0;
  border-top: 3px #da1b0d solid; }
  article.recommendBox .detailBoxBd .grWFServicebox ul {
    padding: 40px 0 20px 0;
    text-align: center; }
    article.recommendBox .detailBoxBd .grWFServicebox ul li {
      position: relative;
      display: inline-block;
      vertical-align: top;
      width: 280px;
      height: 436px;
      margin-left: 12px;
      margin-right: 12px; }
      article.recommendBox .detailBoxBd .grWFServicebox ul li .title {
        font-size: 20px;
        line-height: 24px;
        color: #fff;
        text-align: left;
        padding: 20px 22px 0 22px; }
      article.recommendBox .detailBoxBd .grWFServicebox ul li .text {
        min-height: 150px;
        font-size: 16px;
        line-height: 24px;
        color: #fff;
        padding: 15px 22px 20px 22px;
        text-align: justify;
        box-sizing: border-box; }
      article.recommendBox .detailBoxBd .grWFServicebox ul li:nth-child(2), article.recommendBox .detailBoxBd .grWFServicebox ul li:nth-child(4) {
        margin: 30px 12px 0; }
      article.recommendBox .detailBoxBd .grWFServicebox ul li:nth-child(1) {
        background-color: #fe5959; }
      article.recommendBox .detailBoxBd .grWFServicebox ul li:nth-child(2) {
        background-color: #7989c6; }
      article.recommendBox .detailBoxBd .grWFServicebox ul li:nth-child(3) {
        background-color: #53b0d2; }
      article.recommendBox .detailBoxBd .grWFServicebox ul li:nth-child(4) {
        background-color: #73a664; }
    article.recommendBox .detailBoxBd .grWFServicebox ul .icon2 {
      position: absolute;
      right: 0;
      bottom: -50px; }
  article.recommendBox .detailBoxBd .grWFServicebox .editBox .btnDownBox .box {
    margin: 0 8px; }

article.recommendBox .detailBoxBd .grWFdiscountMsg {
  margin: -30px -40px 40px -40px;
  padding: 0 40px;
  font-size: 18px;
  font-weight: normal;
  line-height: 60px;
  color: #fff;
  background: #c5a88b; }

article.recommendBox .detailBoxBd .grWFdiscountBox {
  position: relative;
  margin: 0 0 40px 0; }
  article.recommendBox .detailBoxBd .grWFdiscountBox .discountBox {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 47%;
    padding: 0 3% 0 0;
    margin: 0 -2px; }
    article.recommendBox .detailBoxBd .grWFdiscountBox .discountBox:last-child {
      padding: 0 0 0 3%; }
    article.recommendBox .detailBoxBd .grWFdiscountBox .discountBox .icon {
      float: left;
      width: 200px;
      height: 200px;
      border-radius: 50%;
      width: 200px;
      height: 200px;
      overflow: hidden;
      border-radius: 50%; }
    article.recommendBox .detailBoxBd .grWFdiscountBox .discountBox .discountBoxR {
      float: right;
      width: 340px;
      padding: 0 0 60px 0;
      text-align: left; }
      article.recommendBox .detailBoxBd .grWFdiscountBox .discountBox .discountBoxR .text b {
        color: #e71a0f; }
      article.recommendBox .detailBoxBd .grWFdiscountBox .discountBox .discountBoxR .number {
        padding: 8px 10px;
        margin: 10px 0 0 0;
        color: #d42c27;
        font-size: 15px;
        background: #fff4e5; }
  article.recommendBox .detailBoxBd .grWFdiscountBox:before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    content: "";
    width: 1px;
    background-color: #ccc;
    z-index: 1; }
  article.recommendBox .detailBoxBd .grWFdiscountBox .discountBox .discountBoxR .btnDownBox {
    position: absolute;
    bottom: 10px;
    right: 30px;
    margin: 0; }
  article.recommendBox .detailBoxBd .grWFdiscountBox .discountBox:last-child .discountBoxR .btnDownBox {
    right: 0; }
  article.recommendBox .detailBoxBd .grWFdiscountBox .discountBox .discountBoxR .btnDownBox .box {
    width: 120px; }
  article.recommendBox .detailBoxBd .grWFdiscountBox .discountBox .discountBoxR .btnDownBox a.button {
    font-size: 13px;
    height: 30px;
    line-height: 30px; }

article.recommendBox .detailBoxBd .grWFdiscountFt .box {
  display: table;
  width: 100%;
  margin: 0 0 30px 0; }
  article.recommendBox .detailBoxBd .grWFdiscountFt .box > div {
    padding: 25px 0;
    display: table-cell;
    vertical-align: middle; }
  article.recommendBox .detailBoxBd .grWFdiscountFt .box .labelBox {
    position: relative;
    width: 164px;
    color: #333;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    background-color: #e0e0e0; }
    article.recommendBox .detailBoxBd .grWFdiscountFt .box .labelBox:after {
      position: absolute;
      top: 50%;
      right: -15px;
      width: 0;
      height: 0;
      margin: -15px 0 0 0;
      content: "";
      border-style: solid;
      border-width: 15px 0 15px 15px;
      border-color: transparent transparent transparent #e0e0e0;
      z-index: 1; }
    article.recommendBox .detailBoxBd .grWFdiscountFt .box .labelBox img {
      margin: 0 5px 0 0;
      border: 0; }
  article.recommendBox .detailBoxBd .grWFdiscountFt .box .conBox {
    padding: 25px 35px;
    border: 1px #d9d9d9 solid; }
    article.recommendBox .detailBoxBd .grWFdiscountFt .box .conBox a {
      text-decoration: none; }

article.recommendBox .detailBoxBd .imgMargin {
  margin: 0 0 30px 0; }

article.wifi .detailBoxBd .minComplex .wifiBoxHd {
  margin: 5px 0 40px 0; }
  article.wifi .detailBoxBd .minComplex .wifiBoxHd img {
    border: 0; }

article.wifi .detailBoxBd .stepBox {
  position: relative;
  float: left;
  text-align: center;
  padding: 20px 0;
  margin: 0 25px 0 0;
  border: 1px #e1e1e1 solid;
  box-sizing: border-box; }

article.wifi .detailBoxBd #NTTDoCoMo .stepBox {
  width: 280px;
  min-height: 710px; }

article.wifi .detailBoxBd #Trustive .stepBox {
  width: 586px;
  min-height: 630px; }
  article.wifi .detailBoxBd #Trustive .stepBox:nth-child(1) {
    margin: 0 25px 25px 0; }
  article.wifi .detailBoxBd #Trustive .stepBox:nth-child(2) {
    margin: 0 0 25px 0; }

article.wifi .detailBoxBd #other .stepBox {
  width: 382px;
  min-height: 670px; }
  article.wifi .detailBoxBd #other .stepBox:nth-child(1), article.wifi .detailBoxBd #other .stepBox:nth-child(2) {
    margin: 0 25px 25px 0; }
  article.wifi .detailBoxBd #other .stepBox:nth-child(3) {
    margin: 0 25px 25px 0;
    margin: 0 0 25px 0; }
  article.wifi .detailBoxBd #other .stepBox:nth-child(4), article.wifi .detailBoxBd #other .stepBox:nth-child(5) {
    width: 586px;
    min-height: 680px; }
  article.wifi .detailBoxBd #other .stepBox:nth-child(4) {
    width: 586px;
    margin: 0 25px 25px 0; }
  article.wifi .detailBoxBd #other .stepBox:nth-child(5) {
    width: 586px;
    margin: 0 0 25px 0; }

article.wifi .detailBoxBd #NTTDoCoMo .stepBox.step4:after {
  background: none; }

article.wifi .detailBoxBd #Trustive .stepBox.step2:after, article.wifi .detailBoxBd #Trustive .stepBox.step4:after {
  background: none; }

article.wifi .detailBoxBd #other .stepBox.step3:after, article.wifi .detailBoxBd #other .stepBox.step5:after {
  background: none; }

article.wifi .detailBoxBd .stepBox.step4 {
  margin: 0; }

article.wifi .detailBoxBd .stepBox:after {
  position: absolute;
  top: 50%;
  right: -18px;
  content: "";
  width: 37px;
  height: 37px;
  margin: -18px 0 0 0;
  z-index: 1; }

article.wifi .detailBoxBd .stepBox > .text {
  position: relative;
  width: 100%;
  text-align: center;
  height: 60px;
  margin: 0 0 20px 0;
  display: flex;
  align-items: center;
  justify-content: center; }
  article.wifi .detailBoxBd .stepBox > .text:before {
    position: absolute;
    left: 20px;
    right: 20px;
    bottom: 0;
    content: "";
    height: 1px;
    background-color: #e5e5e5;
    z-index: 1; }

article.wifi .detailBoxBd .stepBox.step1 .icon article.wifi .detailBoxBd .stepBox.step2 .icon article.wifi .detailBoxBd .stepBox.step3 .icon {
  display: table;
  width: 90%;
  margin: 10px auto 0 auto;
  text-align: center;
  height: 230px;
  border-top: 1px #f0f0f0 solid; }

article.wifi .detailBoxBd .stepBox.step4 .icon {
  width: 90%;
  border-top: 1px #f2f2f2 solid;
  margin: 10px auto 0 auto;
  padding: 10px 0 0 0; }

article.wifi .detailBoxBd .stepBox .text span, article.wifi .detailBoxBd .stepBox.step1 .icon span, article.wifi .detailBoxBd .stepBox.step2 .icon span, article.wifi .detailBoxBd .stepBox.step3 .icon span {
  display: inline-block;
  vertical-align: middle;
  padding: 0 20px; }

article.wifi .detailBoxBd .stepBox .boxComplex {
  text-align: left;
  margin: 20px 25px 0 25px; }
  article.wifi .detailBoxBd .stepBox .boxComplex > div:first-child {
    margin: 0 0 8px 0; }
  article.wifi .detailBoxBd .stepBox .boxComplex .text {
    font-size: 15px;
    color: #666;
    text-align: left;
    line-height: 1.4em !important; }
    article.wifi .detailBoxBd .stepBox .boxComplex .text a {
      line-height: 1.4em !important; }
  article.wifi .detailBoxBd .stepBox .boxComplex .subTitle {
    padding: 0 0 0 10px;
    margin: 0;
    font-size: 16px; }
    article.wifi .detailBoxBd .stepBox .boxComplex .subTitle:after {
      top: 0; }

article.wifi .detailBoxBd .stepBox .title {
  font-size: 32px;
  font-weight: bold;
  color: #8a5f3c; }

/**
 **
 ** newVerlocationBox
 **
 ** */
/* single */
/* article.newVerlocationBox .relatedBox {
 *    width: 810px;
 *    margin: 10px auto 5px auto;
 *    color: #333;
 *    font-size: 15px;
 *    line-height: 1.4em;
 *}
 *article.newVerlocationBox .relatedBox a {
 *    font-weight: bold;
 *}
 *article.newVerlocationBox .relatedBox b {
 *    color: #e71a0f;
 *} */
/**
 **
 ** globalNavBox
 **
 ** */
/**
 **
 ** countryHdBox
 **
 ** */
/**
 **
 ** countryHdBox
 **
 ** */
/**
 **
 ** tabCountryList
 **
 ** */
/**
 **
 ** globalDirections
 **
 ** */
/**
 **
 ** globalDirections
 **
 ** */
/**
 **
 ** globalDirections
 **
 ** */
/**
 **
 ** globalDirections
 **
 ** */
/**
 **
 ** empowerBox
 **
 ** */
/* position sticky overflow no use */
.wrapperPage section.posSticky {
  overflow: inherit;
  overflow: initial; }

/* position sticky overflow no use end */
article.empKvListBox, article.empSelectBox, article.empowerBox, article.empActiveBox, article.empToolBox {
  margin: 0 auto;
  max-width: 1280px; }

article.empKvListBox *, article.empSelectBox *, article.empActiveBox *, article.empToolBox * {
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  font-family: Arial, "Noto Sans TC", "Microsoft JhengHei", Helvetica, sans-serif; }

article.empActiveBox > a, article.empToolBox > div > a, article.empSelectBox .priceBox.on .btn > a {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1; }

article.empKvListBox {
  position: relative; }
  article.empKvListBox .sticky-box {
    position: sticky;
    top: 84px;
    height: 500px;
    overflow: hidden; }
    article.empKvListBox .sticky-box .stickyTitle {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 60px;
      margin: auto;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: bottom 0.3s; }
      article.empKvListBox .sticky-box .stickyTitle .title {
        font-size: 48px;
        font-weight: 400;
        color: #333;
        line-height: 1.2em;
        text-shadow: 0px 0px 20px #f2f2f2, 0px 0px 20px #f2f2f2, 0px 0px 20px #f2f2f2; }
      article.empKvListBox .sticky-box .stickyTitle .text {
        margin: 10px 0 0 0;
        font-size: 18px;
        color: #333;
        line-height: 1.4em; }
      article.empKvListBox .sticky-box .stickyTitle .btn {
        width: 140px;
        margin: 10px auto 0 auto; }
        article.empKvListBox .sticky-box .stickyTitle .btn a {
          display: block;
          height: 50px;
          font-size: 20px;
          color: #fff;
          line-height: 50px;
          border-radius: 8px;
          background-color: #e51f20;
          box-shadow: inset 0px 0px 0px 0px #aa1616;
          transition: all 200ms linear; }
        article.empKvListBox .sticky-box .stickyTitle .btn:hover a {
          box-shadow: inset 0px 0px 200px 0px #aa1616; }

/**
 **
 ** empKvListBox
 **
 ** */
@media only screen and (max-height: 737px) {
  article.empKvListBox .sticky-box .stickyTitle {
    bottom: 100px; } }

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  article.empKvListBox .sticky-box {
    opacity: 1 !important;
    transform: translate3d(0, 0, 0) rotateX(0deg) rotateY(0deg) rotateZ(0deg) scale3d(1, 1, 1) !important; }
    article.empKvListBox .sticky-box .stickyTitle {
      opacity: 1 !important;
      transform: translate3d(0, 0, 0) rotateX(0deg) rotateY(0deg) rotateZ(0deg) scale3d(1, 1, 1) !important; } }

/**
 **
 ** stickySumFixed
 **
 ** */
.stickyMinHeight {
  min-height: 500px; }

.stickySumFixed {
  position: fixed;
  width: 1280px;
  top: calc(100vh - (100vh - 735px));
  left: 0;
  right: 0;
  overflow: inherit;
  overflow: initial;
  margin: auto;
  background-color: #f2f2f2;
  z-index: 2;
  transition: all 0.5s; }
  .stickySumFixed.soptSticky, .stickySumFixed.stickySum {
    position: -webkit-relative;
    position: relative;
    top: auto; }
  .stickySumFixed .stickySortBox {
    position: relative;
    height: 250px;
    min-height: 250px;
    background-color: #fff;
    z-index: 2;
    display: flex;
    align-items: stretch;
    justify-content: center; }
    .stickySumFixed .stickySortBox:after {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 50%;
      width: 1px;
      content: "";
      background-color: #e0e0e0;
      z-index: 3; }
    .stickySumFixed .stickySortBox .tagBox {
      position: absolute;
      top: -22px;
      left: 50%;
      width: 274px;
      height: 42px;
      line-height: 42px;
      text-align: center;
      margin: 0 0 0 -127px;
      font-size: 18px;
      color: #666;
      border: 1px #e0e0e0 solid;
      background-color: #fff;
      border-radius: 21px;
      z-index: 9; }
    .stickySumFixed .stickySortBox > div {
      position: relative;
      width: 50%;
      text-align: center; }
      .stickySumFixed .stickySortBox > div > a {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 5; }
      .stickySumFixed .stickySortBox > div .icon img {
        max-width: 60px; }
      .stickySumFixed .stickySortBox > div .title {
        margin: 30px 0 0 0;
        font-size: 30px;
        color: #333; }
      .stickySumFixed .stickySortBox > div .text {
        margin: 20px 0 0 0;
        font-size: 16px;
        line-height: 16px;
        color: #999; }
        .stickySumFixed .stickySortBox > div .text a {
          color: #99654c;
          margin: 0 5px; }
    .stickySumFixed .stickySortBox .useWebBox:after, .stickySumFixed .stickySortBox .useTelBox:after {
      position: absolute;
      top: 50%;
      content: "";
      width: 0;
      height: 0;
      margin: -14px 0 0 0; }
    .stickySumFixed .stickySortBox .useWebBox:after {
      right: 1px;
      border-style: solid;
      border-width: 14px 0 14px 20px;
      border-color: transparent transparent transparent #fff;
      transition: right 0.5s; }
    .stickySumFixed .stickySortBox .useWebBox.open:after {
      right: -20px;
      z-index: 5; }
    .stickySumFixed .stickySortBox .useTelBox:after {
      left: 1px;
      border-style: solid;
      border-width: 14px 20px 14px 0;
      border-color: transparent #fff transparent transparent;
      transition: left 0.5s; }
    .stickySumFixed .stickySortBox .useTelBox.open:after {
      left: -20px;
      z-index: 5; }
    .stickySumFixed .stickySortBox .useWebBox .text a:after, .stickySumFixed .stickySortBox .useTelBox .text a:before {
      position: relative;
      top: -2px;
      display: inline-block;
      vertical-align: middle;
      width: 14px;
      height: 16px;
      content: ""; }
    .stickySumFixed .stickySortBox .msgBox {
      position: relative;
      width: 100%;
      height: 100%;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 2; }
    .stickySumFixed .stickySortBox .useWebBox .detailBox, .stickySumFixed .stickySortBox .useTelBox .detailBox {
      position: absolute;
      top: 0;
      width: 100%;
      bottom: 0;
      background-color: #e3e3e3;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: space-around;
      -ms-box-sizing: border-box;
      box-sizing: border-box; }
    .stickySumFixed .stickySortBox .useWebBox .detailBox {
      padding: 0 20px 0 40px;
      left: 0;
      transition: left 0.5s; }
    .stickySumFixed .stickySortBox .useTelBox .detailBox {
      padding: 0 40px 0 20px;
      right: 0;
      transition: right 0.5s; }
    .stickySumFixed .stickySortBox .useWebBox.open .detailBox {
      left: 100%;
      z-index: 3; }
    .stickySumFixed .stickySortBox .useTelBox.open .detailBox {
      right: 100%;
      z-index: 3; }
    .stickySumFixed .stickySortBox .useWebBox.open .msgBox, .stickySumFixed .stickySortBox .useTelBox.open .msgBox {
      z-index: 4; }
    .stickySumFixed .stickySortBox .detailBox .subTitle {
      font-size: 30px;
      color: #333;
      line-height: 1.2em; }
      .stickySumFixed .stickySortBox .detailBox .subTitle span {
        display: block;
        font-size: 36px; }
    .stickySumFixed .stickySortBox .detailBox .subText {
      width: 300px;
      font-size: 18px;
      color: #333;
      line-height: 1.2em; }
      .stickySumFixed .stickySortBox .detailBox .subText > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 10px 0 0 0; }
      .stickySumFixed .stickySortBox .detailBox .subText .sum {
        margin: 15px 0 0 0;
        padding: 15px 0 0 0;
        border-top: 1px #333 solid; }
    .stickySumFixed .stickySortBox .useWebBox .text a:after {
      background-size: 100% auto; }
    .stickySumFixed .stickySortBox .useTelBox .text a:before {
      background-size: 100% auto;
      transform: rotate(180deg); }

@media only screen and (max-height: 737px) {
  .stickySumFixed .stickySortBox {
    position: relative;
    top: auto; } }

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .stickySumFixed .stickySortBox {
    position: sticky;
    top: auto; } }

@media all and (-ms-high-contrast: none) {
  article.empKvListBox .sticky-box {
    position: -webkit-relative !important;
    position: relative !important;
    top: auto !important; }
  .stickySumFixed .stickySortBox {
    position: -webkit-relative !important;
    position: relative !important;
    top: auto !important; }
    .stickySumFixed .stickySortBox.stickySum {
      position: -webkit-relative !important;
      position: relative !important;
      top: auto !important; } }

/**
 **
 ** empSelectBox
 **
 ** */
article.empSelectBox .selBox {
  position: relative;
  margin: 20px -10px 0 -10px;
  padding: 0 0 0 160px; }

article.empSelectBox .selTitle {
  position: relative;
  line-height: 30px;
  text-align: center; }
  article.empSelectBox .selTitle:after {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 1px;
    background-color: #bbb;
    content: ""; }
  article.empSelectBox .selTitle span {
    position: relative;
    padding: 0 20px;
    color: #333;
    font-size: 30px;
    background-color: #f2f2f2;
    z-index: 1; }

article.empSelectBox .selBox .title {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 148px;
  margin: 10px;
  color: #fff;
  font-size: 22px;
  font-weight: bold;
  line-height: 1.4em;
  background-color: #99654c;
  display: flex;
  align-items: center;
  justify-content: center; }

article.empSelectBox .selBox .con > div {
  display: table;
  width: 100%;
  border-collapse: separate;
  border-spacing: 10px;
  margin: -10px -10px -10px 0; }
  article.empSelectBox .selBox .con > div label {
    position: relative;
    width: 270px;
    height: 80px;
    color: #666;
    font-size: 22px;
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    background-color: #fff;
    border: 1px #e0e0e0 solid;
    overflow: hidden;
    cursor: pointer; }
    article.empSelectBox .selBox .con > div label:hover {
      background-color: #f9f9f9; }
    article.empSelectBox .selBox .con > div label.on {
      border: 3px #99654c solid; }
      article.empSelectBox .selBox .con > div label.on:hover {
        background-color: #fff; }
    article.empSelectBox .selBox .con > div label input {
      position: absolute;
      outline: none;
      visibility: hidden;
      width: 0;
      height: 0;
      z-index: -1; }
    article.empSelectBox .selBox .con > div label span {
      padding: 0 15px; }
      article.empSelectBox .selBox .con > div label span.price {
        position: relative;
        color: #d8130b; }
        article.empSelectBox .selBox .con > div label span.price span {
          padding: 0; }
        article.empSelectBox .selBox .con > div label span.price:after {
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          width: 1px;
          content: "";
          background-color: #e0e0e0; }

article.empSelectBox .priceBox {
  margin: 40px 0 30px 0;
  text-align: center;
  font-size: 30px;
  color: #333;
  line-height: 1.2em; }
  article.empSelectBox .priceBox > div {
    display: inline-block;
    vertical-align: middle; }
  article.empSelectBox .priceBox .btn {
    position: relative;
    margin: 0 0 0 10px; }
    article.empSelectBox .priceBox .btn span {
      display: block;
      min-width: 140px;
      height: 50px;
      font-size: 20px;
      color: #fff;
      line-height: 50px;
      background: #666;
      border-radius: 8px;
      box-shadow: inset 0px 0px 0px 0px #bbbbbb;
      transition: all 200ms linear; }
  article.empSelectBox .priceBox.on .btn span {
    background-color: #e51f20;
    box-shadow: inset 0px 0px 0px 0px #aa1616; }
  article.empSelectBox .priceBox.on .btn:hover span {
    box-shadow: inset 0px 0px 200px 0px #aa1616; }
  article.empSelectBox .priceBox .btn a {
    display: none; }
  article.empSelectBox .priceBox.on .btn a {
    display: block; }

article.empActiveBox {
  position: relative;
  display: table;
  width: 100%;
  border: 1px #e1e1e1 solid;
  background-color: #fff; }
  article.empActiveBox > div {
    display: table-cell;
    vertical-align: top; }
  article.empActiveBox .photo {
    position: relative;
    border-right: 1px #e1e1e1 solid; }
  article.empActiveBox .con {
    position: relative;
    padding: 35px; }
  article.empActiveBox .title {
    font-size: 20px;
    line-height: 1.2em;
    font-weight: bold;
    color: #333; }
  article.empActiveBox .text {
    margin: 20px 0 0 0;
    font-size: 15px;
    line-height: 1.4em;
    color: #666; }
  article.empActiveBox .btn {
    position: absolute;
    right: 35px;
    bottom: 20px;
    text-align: right;
    font-size: 15px;
    line-height: 1.4em;
    color: #b98c23; }
  article.empActiveBox:hover .btn {
    color: #e71a0f; }

article.empToolBox {
  display: flex;
  align-items: center;
  justify-content: space-between; }
  article.empToolBox > div {
    position: relative; }
  article.empToolBox .programBox > div, article.empToolBox .rateBox > div {
    display: table-cell;
    vertical-align: middle; }
  article.empToolBox .programBox, article.empToolBox .rateBox {
    display: table;
    width: calc(50% - 4px); }
  article.empToolBox .programBox .con, article.empToolBox .rateBox .con {
    padding: 40px; }
  article.empToolBox .title {
    position: relative;
    min-width: 160px;
    background-color: #666666; }
    article.empToolBox .title > div {
      font-size: 24px;
      line-height: 1.4em;
      font-weight: 400;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center; }
  article.empToolBox .text {
    font-size: 16px;
    line-height: 1.4em;
    color: #fff; }
  article.empToolBox .btn {
    margin: 10px 0 0 0;
    font-size: 20px;
    line-height: 1.4em;
    color: #fff; }
    article.empToolBox .btn span.arrow {
      position: relative;
      left: 0;
      top: -2px;
      margin: 0 0 0 5px;
      display: inline-block;
      vertical-align: middle;
      width: 24px;
      height: 24px;
      background-size: 100% auto;
      transition: left 0.5s; }
  article.empToolBox > div:hover .btn span.arrow {
    left: 5px; }

article.fpfKvListBox, article.fpfPlanList, article.classifyList {
  margin: 0 auto;
  max-width: 1280px; }

article.fpfKvListBox *, article.classifyList * {
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  font-family: Arial, "Noto Sans TC", "Microsoft JhengHei", Helvetica, sans-serif; }

article.fpfPlanList .selTitle span {
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  font-family: Arial, "Noto Sans TC", "Microsoft JhengHei", Helvetica, sans-serif; }

article.fpfPlanList ul li > a {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1; }

article.classifyList ul li > a {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1; }

article.fpfKvListBox .sticky-box {
  position: sticky;
  top: 84px;
  height: 420px;
  overflow: hidden; }
  article.fpfKvListBox .sticky-box .kvImg {
    position: relative; }
  article.fpfKvListBox .sticky-box .stickyTitle {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1; }
    article.fpfKvListBox .sticky-box .stickyTitle .title {
      font-size: 48px;
      font-weight: 400;
      color: #333;
      line-height: 1.2em;
      text-shadow: 0px 0px 20px #f2f2f2, 0px 0px 20px #f2f2f2, 0px 0px 20px #f2f2f2; }
    article.fpfKvListBox .sticky-box .stickyTitle .text {
      font-size: 18px;
      color: #333;
      line-height: 2em;
      text-shadow: 0px 0px 15px #f2f2f2, 0px 0px 15px #f2f2f2; }
    article.fpfKvListBox .sticky-box .stickyTitle .btn {
      width: 176px;
      margin: 20px auto 0 auto; }
      article.fpfKvListBox .sticky-box .stickyTitle .btn a {
        display: block;
        height: 50px;
        font-size: 20px;
        color: #fff;
        line-height: 50px;
        border-radius: 8px;
        background-color: #e51f20;
        box-shadow: inset 0px 0px 0px 0px #aa1616;
        transition: all 200ms linear; }
      article.fpfKvListBox .sticky-box .stickyTitle .btn:hover a {
        box-shadow: inset 0px 0px 200px 0px #aa1616; }

article.classifyList .selTitle {
  position: relative;
  line-height: 30px;
  text-align: center;
  margin: 10px 0 0 0; }
  article.classifyList .selTitle:after {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 1px;
    background-color: #bbb;
    content: ""; }
  article.classifyList .selTitle span {
    position: relative;
    padding: 0 20px;
    color: #333;
    font-size: 30px;
    background-color: #f2f2f2;
    z-index: 1; }

article.classifyList ul {
  display: inline-flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 0 6px; }
  article.classifyList ul li {
    position: relative;
    width: 600px;
    margin: 15px 17px;
    background-color: #fff;
    border-radius: 8px;
    font-size: 0;
    overflow: hidden;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0);
    transition: all 200ms linear; }
    article.classifyList ul li:hover {
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.2); }
    article.classifyList ul li .photo, article.classifyList ul li .con {
      display: inline-block;
      vertical-align: middle;
      width: 50%; }
    article.classifyList ul li .photo img {
      width: 100%;
      vertical-align: middle; }
    article.classifyList ul li .con {
      padding: 0 30px; }
      article.classifyList ul li .con .title {
        color: #333;
        font-size: 20px;
        font-weight: bold;
        line-height: 1.4em;
        max-height: 2.8em;
        overflow: hidden;
        transition: color 200ms linear; }
    article.classifyList ul li:hover .con .title {
      color: #e71a0f; }
    article.classifyList ul li .con .text {
      margin: 20px 0 0 0;
      color: #666;
      font-size: 16px;
      line-height: 1.4em;
      height: 2.8em;
      overflow: hidden; }

article.fpfPlanList .selBox {
  position: relative;
  margin: 20px -10px 0 -10px;
  padding: 0 0 0 160px; }

article.fpfPlanList .selTitle {
  position: relative;
  line-height: 30px;
  text-align: center;
  margin: 10px 0 0 0; }
  article.fpfPlanList .selTitle:after {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 1px;
    background-color: #bbb;
    content: ""; }
  article.fpfPlanList .selTitle span {
    position: relative;
    padding: 0 20px;
    color: #333;
    font-size: 30px;
    background-color: #f2f2f2;
    z-index: 1; }

article.fpfPlanList ul {
  display: inline-flex;
  flex-wrap: wrap;
  width: 100%; }
  article.fpfPlanList ul li {
    position: relative;
    width: 382px;
    margin: 15px 21px;
    border: #e1e1e1 1px solid;
    background-color: #fff; }
    article.fpfPlanList ul li .photo img {
      width: 100%;
      vertical-align: middle; }
    article.fpfPlanList ul li .con {
      z-index: 2;
      padding: 20px 20px 70px 20px; }
      article.fpfPlanList ul li .con .title {
        color: #333;
        font-size: 16px;
        font-weight: bold;
        line-height: 1.4em;
        height: 1.4em;
        overflow: hidden;
        transition: color 200ms linear; }
    article.fpfPlanList ul li:hover .con .title {
      color: #e71a0f; }
    article.fpfPlanList ul li .con .text {
      margin: 5px 0 0 0;
      color: #666;
      font-size: 15px;
      line-height: 1.4em;
      height: 2.8em;
      overflow: hidden; }
    article.fpfPlanList ul li .more {
      position: absolute;
      right: 0;
      bottom: 18px;
      width: 100px;
      height: 30px;
      text-align: center;
      color: #fff;
      font-size: 13px;
      font-weight: 600;
      line-height: 30px;
      background-color: #666666;
      transition: background-color 200ms linear; }

article.classifyList ul li .more {
  position: absolute;
  right: 0;
  bottom: 18px;
  width: 100px;
  height: 30px;
  text-align: center;
  color: #fff;
  font-size: 13px;
  font-weight: 600;
  line-height: 30px;
  background-color: #666666;
  transition: background-color 200ms linear;
  right: auto;
  left: 0; }

article.fpfPlanList ul li:hover .more, article.classifyList ul li:hover .more {
  background-color: #e71a0f; }

article.fpfPlanList ul li .more img, article.classifyList ul li .more img {
  position: relative;
  top: -2px;
  max-width: 14px; }

.font18 {
  font-size: 18px !important;
  font-weight: normal !important; }

/**
 **
 ** empActiveBox
 **
 ** */
/**
 **
 ** empToolBox
 **
 ** */
/**
 **
 ** empowerBox
 **
 ** */
/**
 **
 ** fpfKvListBox
 **
 ** */
/**
 **
 ** classifyList
 **
 ** */
/**
 **
 ** fpfPlanList
 **
 ** */
.wrapperPage {
  background: #fff;
  padding: 30px;
  margin-bottom: 12px;
  box-shadow: 0 6px 12px 0 rgba(75, 88, 105, 0.12); }
  .wrapperPage section {
    padding: 0; }

.fui-panel-information .schinner.editBox > table {
  display: block;
  width: 100% !important;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch; }

.wrapperPage section article.recommendBox * > ::-webkit-scrollbar {
  display: none;
  overflow: auto;
  -ms-overflow-style: none; }

.wrapperPage section article.recommendBox .tabs article.proDetailBox {
  padding: 0; }
  .wrapperPage section article.recommendBox .tabs article.proDetailBox .detailBoxBd {
    box-shadow: none;
    background-color: none;
    padding: 0; }
    .wrapperPage section article.recommendBox .tabs article.proDetailBox .detailBoxBd .descbox {
      border: 0; }
      .wrapperPage section article.recommendBox .tabs article.proDetailBox .detailBoxBd .descbox .unfold {
        max-height: 66px; }
        .wrapperPage section article.recommendBox .tabs article.proDetailBox .detailBoxBd .descbox .unfold.on {
          max-height: 100%; }
    .wrapperPage section article.recommendBox .tabs article.proDetailBox .detailBoxBd .mainTitle {
      padding: 30px 48px 24px 30px;
      margin: 0;
      border: 0;
      background-color: transparent;
      background-color: initial; }
      .wrapperPage section article.recommendBox .tabs article.proDetailBox .detailBoxBd .mainTitle .close {
        font-size: 0;
        background-image: url(/DigService/content/dam/fetnet/resources/cbu/images/plus.svg);
        transform: none;
        right: 48px;
        top: 30px; }
        .wrapperPage section article.recommendBox .tabs article.proDetailBox .detailBoxBd .mainTitle .close.odd {
          background-image: url(/DigService/content/dam/fetnet/resources/cbu/images/minus.svg); }
      .wrapperPage section article.recommendBox .tabs article.proDetailBox .detailBoxBd .mainTitle .subTitle {
        font-size: 20px;
        margin: 0;
        padding: 0; }
        .wrapperPage section article.recommendBox .tabs article.proDetailBox .detailBoxBd .mainTitle .subTitle::after {
          display: none; }
    .wrapperPage section article.recommendBox .tabs article.proDetailBox .detailBoxBd .open {
      display: none; }
  .wrapperPage section article.recommendBox .tabs article.proDetailBox .leftColumn {
    width: calc( 100% - 270px);
    overflow: scroll; }
    .wrapperPage section article.recommendBox .tabs article.proDetailBox .leftColumn .accordion {
      padding: 0;
      border: 0;
      background-color: #fff;
      border: 2px solid #F1F3F5; }
      .wrapperPage section article.recommendBox .tabs article.proDetailBox .leftColumn .accordion:first-child {
        margin-top: 20px; }
      .wrapperPage section article.recommendBox .tabs article.proDetailBox .leftColumn .accordion.open {
        display: block; }
      .wrapperPage section article.recommendBox .tabs article.proDetailBox .leftColumn .accordion .open {
        display: none; }
      .wrapperPage section article.recommendBox .tabs article.proDetailBox .leftColumn .accordion .descbox .mainTitle {
        background-color: none; }
      .wrapperPage section article.recommendBox .tabs article.proDetailBox .leftColumn .accordion .mainTitle {
        background-color: #fff;
        border: 0;
        box-shadow: none;
        padding: 30px;
        margin: 0; }
        .wrapperPage section article.recommendBox .tabs article.proDetailBox .leftColumn .accordion .mainTitle .trigger {
          right: 30px;
          background-image: url(/DigService/content/dam/fetnet/resources/cbu/images/plus.svg);
          transform: rotate(0); }
        .wrapperPage section article.recommendBox .tabs article.proDetailBox .leftColumn .accordion .mainTitle .odd {
          background-image: url(/DigService/content/dam/fetnet/resources/cbu/images/minus.svg); }
        .wrapperPage section article.recommendBox .tabs article.proDetailBox .leftColumn .accordion .mainTitle a {
          display: flex;
          flex-direction: column; }
          .wrapperPage section article.recommendBox .tabs article.proDetailBox .leftColumn .accordion .mainTitle a b {
            color: #5f7b80;
            font-size: 16px;
            display: block;
            margin-bottom: 12px;
            font-weight: 400; }
          .wrapperPage section article.recommendBox .tabs article.proDetailBox .leftColumn .accordion .mainTitle a span {
            border: 0;
            font-size: 20px;
            color: #202020;
            padding: 0;
            line-height: 1.78; }
  .wrapperPage section article.recommendBox .tabs article.proDetailBox .schinner {
    overflow: scroll;
    margin: 0 30px 30px 30px; }
    .wrapperPage section article.recommendBox .tabs article.proDetailBox .schinner table {
      border: 2px solid #E8ECF0;
      font-size: 16px;
      width: 100% !important; }
      .wrapperPage section article.recommendBox .tabs article.proDetailBox .schinner table strong {
        color: #5f6e84;
        font-size: 16px; }
      .wrapperPage section article.recommendBox .tabs article.proDetailBox .schinner table td {
        font-size: 16px;
        vertical-align: middle;
        padding: 12px;
        color: #5f6e84;
        background-color: #fff;
        border: 2px solid #e8ecf0; }
      .wrapperPage section article.recommendBox .tabs article.proDetailBox .schinner table tr:nth-child(2n) td {
        background-color: #fff; }
    .wrapperPage section article.recommendBox .tabs article.proDetailBox .schinner .descbox {
      margin-bottom: 30px;
      background-color: #fafafa;
      color: #5f6e84;
      font-size: 14px;
      line-height: 1.71; }
      .wrapperPage section article.recommendBox .tabs article.proDetailBox .schinner .descbox li {
        color: #5f6e84; }
      .wrapperPage section article.recommendBox .tabs article.proDetailBox .schinner .descbox .mainTitle {
        background: none;
        color: #5f6e84;
        padding-left: 0; }
  .wrapperPage section article.recommendBox .tabs article.proDetailBox .sidebar {
    width: 220px; }
    .wrapperPage section article.recommendBox .tabs article.proDetailBox .sidebar .box {
      background-image: url(/DigService/content/dam/fetnet/resources/cbu/images/result.svg);
      padding: 30px 20px;
      border: 0;
      text-align: left;
      box-shadow: none;
      background-size: contain;
      background-position: right bottom;
      background-color: #EFF4F1;
      background-repeat: no-repeat; }
      .wrapperPage section article.recommendBox .tabs article.proDetailBox .sidebar .box .mainTitle {
        font-size: 24px;
        display: flex;
        flex-direction: column;
        padding: 0; }
        .wrapperPage section article.recommendBox .tabs article.proDetailBox .sidebar .box .mainTitle::before {
          content: "";
          background-image: url(/DigService/content/dam/fetnet/resources/cbu/images/calculator.svg);
          width: 30px;
          height: 30px;
          position: relative;
          margin-bottom: 12px; }
        .wrapperPage section article.recommendBox .tabs article.proDetailBox .sidebar .box .mainTitle img {
          display: none; }
      .wrapperPage section article.recommendBox .tabs article.proDetailBox .sidebar .box p {
        font-size: 16px;
        color: #202020;
        margin-bottom: 30px; }
      .wrapperPage section article.recommendBox .tabs article.proDetailBox .sidebar .box a {
        font-size: 16px;
        padding: 12px;
        background: none;
        color: #202020;
        height: auto;
        border: 2px solid #202020;
        font-weight: 500;
        line-height: normal;
        line-height: initial; }
        .wrapperPage section article.recommendBox .tabs article.proDetailBox .sidebar .box a::after {
          display: none; }
        .wrapperPage section article.recommendBox .tabs article.proDetailBox .sidebar .box a img {
          display: none; }

.wrapperPage section article.recommendBox .tabPagerBox {
  margin: 36px 0 60px 0; }
  .wrapperPage section article.recommendBox .tabPagerBox .tab-pager a {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    font-size: 0.875rem;
    font-weight: 500; }
    .wrapperPage section article.recommendBox .tabPagerBox .tab-pager a:hover {
      color: #202020; }
  .wrapperPage section article.recommendBox .tabPagerBox .tab-pager li.mb {
    display: none;
    margin: 0 12px; }
  .wrapperPage section article.recommendBox .tabPagerBox .tab-pager li.next a, .wrapperPage section article.recommendBox .tabPagerBox .tab-pager li.prev a, .wrapperPage section article.recommendBox .tabPagerBox .tab-pager li.last a, .wrapperPage section article.recommendBox .tabPagerBox .tab-pager li.first a {
    filter: brightness(0); }
  .wrapperPage section article.recommendBox .tabPagerBox .tab-pager li.next a:hover, .wrapperPage section article.recommendBox .tabPagerBox .tab-pager li.prev a:hover, .wrapperPage section article.recommendBox .tabPagerBox .tab-pager li.last a:hover, .wrapperPage section article.recommendBox .tabPagerBox .tab-pager li.first a:hover {
    filter: brightness(1); }
  .wrapperPage section article.recommendBox .tabPagerBox .tab-pager li.disabled {
    opacity: 0.3; }
    .wrapperPage section article.recommendBox .tabPagerBox .tab-pager li.disabled a {
      color: #d6d6d6;
      pointer-events: none; }
  .wrapperPage section article.recommendBox .tabPagerBox .tab-pager li.active a {
    background-color: #6a798e;
    color: #fff; }
  .wrapperPage section article.recommendBox .tabPagerBox .tab-pager .goTo {
    display: none; }

.wrapperPage section article.recommendBox .detailBoxBd .editBox {
  overflow: scroll; }
  .wrapperPage section article.recommendBox .detailBoxBd .editBox a.body {
    display: flex; }
  .wrapperPage section article.recommendBox .detailBoxBd .editBox a {
    display: inline-block;
    color: #202020;
    cursor: pointer;
    text-decoration: none; }
    .wrapperPage section article.recommendBox .detailBoxBd .editBox a::after {
      display: none !important; }
    .wrapperPage section article.recommendBox .detailBoxBd .editBox a:hover {
      color: #e71a0f;
      transition: color 0.3s ease-in; }
      .wrapperPage section article.recommendBox .detailBoxBd .editBox a:hover::after {
        display: none !important; }
  .wrapperPage section article.recommendBox .detailBoxBd .editBox * {
    font-size: 16px;
    line-height: 1.75;
    white-space: normal; }
  .wrapperPage section article.recommendBox .detailBoxBd .editBox table tbody tr:hover td {
    background-color: none; }
    .wrapperPage section article.recommendBox .detailBoxBd .editBox table tbody tr:hover td:hover {
      tr: nth-child(3n) td;
        tr-tr-background: #f1f3f5; }
  .wrapperPage section article.recommendBox .detailBoxBd .editBox table tbody tr:nth-child(1) th {
    background: #f1f3f5; }
    .wrapperPage section article.recommendBox .detailBoxBd .editBox table tbody tr:nth-child(1) th:nth-child(1) {
      background-color: none; }
  .wrapperPage section article.recommendBox .detailBoxBd .editBox table.posttable tr th, .wrapperPage section article.recommendBox .detailBoxBd .editBox table.posttable tr td {
    color: #5f6e84;
    background-color: transparent;
    background-color: initial;
    border: 2px solid #e8ecf0; }
  .wrapperPage section article.recommendBox .detailBoxBd .editBox table.posttable tr th span, .wrapperPage section article.recommendBox .detailBoxBd .editBox table.posttable tr td span {
    color: #5f6e84; }
  .wrapperPage section article.recommendBox .detailBoxBd .editBox table.posttable tr th strong, .wrapperPage section article.recommendBox .detailBoxBd .editBox table.posttable tr td strong {
    color: #202020; }
  .wrapperPage section article.recommendBox .detailBoxBd .editBox table.posttable tr:hover td {
    color: #5f6e84; }
  .wrapperPage section article.recommendBox .detailBoxBd .editBox table.posttable td.hd {
    background: transparent none repeat 0 0 / auto auto padding-box border-box scroll;
    background: initial; }

.wrapperPage section article.recommendBox .btnDownBox .box {
  display: inline-block;
  margin: 0;
  border: 0; }
  .wrapperPage section article.recommendBox .btnDownBox .box a.button {
    padding: 30px 0;
    color: #202020;
    text-align: left;
    background-color: transparent;
    background-color: initial;
    border: 0;
    font-weight: 500;
    display: flex;
    align-items: center; }
    .wrapperPage section article.recommendBox .btnDownBox .box a.button::before {
      content: "";
      background: url(https://www.fetnet.net/images/icon/icon_pagerPrev.png) no-repeat center;
      height: 20px;
      filter: brightness(0);
      display: inline-block;
      background-size: contain;
      width: 20px;
      margin-right: 6px; }

@media screen and (max-width: 960px) {
  .wrapperPage section article.recommendBox .tabs article.proDetailBox .leftColumn {
    width: 100%; }
    .wrapperPage section article.recommendBox .tabs article.proDetailBox .leftColumn .accordion .mainTitle.schBoxHd span {
      padding-right: 30px; }
  .wrapperPage section article.recommendBox .tabs article.proDetailBox .sidebar {
    width: 100%;
    margin-top: 36px; }
  .wrapperPage section article.recommendBox .tabs article.proDetailBox .tabPagerBox .tab-pager li {
    margin: 0; }
    .wrapperPage section article.recommendBox .tabs article.proDetailBox .tabPagerBox .tab-pager li.page {
      display: none; }
    .wrapperPage section article.recommendBox .tabs article.proDetailBox .tabPagerBox .tab-pager li.active, .wrapperPage section article.recommendBox .tabs article.proDetailBox .tabPagerBox .tab-pager li.mb {
      display: block; }
  .wrapperPage section article.recommendBox .detailBoxBd .editBox table.posttable {
    width: 100%;
    overflow: scroll;
    white-space: nowrap;
    display: block; } }

.detailBoxBd .editBox.leftColumn {
  width: 100%;
  overflow: scroll;
  margin-bottom: 16px; }

@media screen and (max-width: 960px) {
  article.proDetailBox.postpaid .leftColumn .tabsBox {
    overflow: scroll; } }

article.recommendBox .detailBoxBd .mainTitle {
  margin-bottom: 12px; }
  article.recommendBox .detailBoxBd .mainTitle .selectCoupon {
    margin-left: 40px; }
    article.recommendBox .detailBoxBd .mainTitle .selectCoupon .selectboxit {
      height: auto;
      padding: 6px 0 6px 12px; }
      article.recommendBox .detailBoxBd .mainTitle .selectCoupon .selectboxit i {
        display: none; }
    article.recommendBox .detailBoxBd .mainTitle .selectCoupon .selectboxit-container {
      border-color: #e8ecf0;
      border: 2px solid #e8ecf0; }
      article.recommendBox .detailBoxBd .mainTitle .selectCoupon .selectboxit-container span {
        font-size: 16px;
        height: auto; }
      article.recommendBox .detailBoxBd .mainTitle .selectCoupon .selectboxit-container .selectboxit-arrow-container {
        height: 30px;
        background-image: url(/DigService/content/dam/fetnet/resources/cbu/images/arrow-down.svg);
        background-size: auto;
        margin-right: 12px; }
      article.recommendBox .detailBoxBd .mainTitle .selectCoupon .selectboxit-container .selectboxit-focus a {
        background-color: #e8ecf0; }
      article.recommendBox .detailBoxBd .mainTitle .selectCoupon .selectboxit-container .selectboxit-options {
        outline: 2px solid #e8ecf0;
        border: 0;
        box-shadow: 0 18px 36px 0 rgba(75, 88, 105, 0.18); }
        article.recommendBox .detailBoxBd .mainTitle .selectCoupon .selectboxit-container .selectboxit-options li.selectboxit-option {
          height: auto; }
          article.recommendBox .detailBoxBd .mainTitle .selectCoupon .selectboxit-container .selectboxit-options li.selectboxit-option i {
            display: none; }
          article.recommendBox .detailBoxBd .mainTitle .selectCoupon .selectboxit-container .selectboxit-options li.selectboxit-option a.selectboxit-option-anchor {
            font-size: 16px;
            padding: 8px 18px;
            height: auto; }

article.recommendBox .editBox table#rental, article.recommendBox .editBox table#prepaid {
  display: none;
  margin-top: 12px; }

article.recommendBox .editBox table#rental.active, article.recommendBox .editBox table#prepaid.active {
  display: block; }

article.recommendBox .ui-selectmenu-button {
  display: inline-block;
  padding: 14px 14px;
  border-radius: 0;
  border: solid 2px #e8ecf0;
  background-color: #fff;
  width: 200px;
  cursor: pointer;
  position: relative; }
  article.recommendBox .ui-selectmenu-button .ui-selectmenu-text {
    font-weight: 500; }
  article.recommendBox .ui-selectmenu-button::after {
    content: "";
    background-image: url(/DigService/content/dam/fetnet/resources/cbu/images/arrow-down.svg);
    background-size: auto;
    height: 24px;
    width: 24px;
    position: absolute;
    right: 20px;
    top: 14px; }

.ui-selectmenu-icon {
  display: none; }

.ui-selectmenu-menu {
  display: none; }
  .ui-selectmenu-menu.ui-selectmenu-open {
    display: block; }

.ui-widget.ui-widget-content {
  padding-bottom: 12px !important;
  border: 0;
  box-shadow: 0 18px 36px 0 rgba(75, 88, 105, 0.18); }
  .ui-widget.ui-widget-content .ui-menu-item-wrapper {
    padding: 8px 24px 8px 12px;
    font-weight: 500;
    border: 0; }
  .ui-widget.ui-widget-content .ui-state-active {
    margin: 0;
    border: 0;
    color: #202020;
    background-color: #fff; }

@media screen and (max-width: 960px) {
  .wrapperPage {
    padding: 20px; } }

article.proDetailBox.postpaid .leftColumn .tabs {
  width: 100%; }

article.proDetailBox.postpaid .leftColumn .tabsBox .partbox {
  border: 0;
  background-color: #fff;
  border: 2px solid #e8ecf0;
  margin: 12px 0 0 0; }
  article.proDetailBox.postpaid .leftColumn .tabsBox .partbox .unfoldBox {
    background-color: #fff;
    padding: 0 48px 0px 30px;
    margin: 0; }
    article.proDetailBox.postpaid .leftColumn .tabsBox .partbox .unfoldBox p {
      font-size: 18px;
      color: #202020; }
    article.proDetailBox.postpaid .leftColumn .tabsBox .partbox .unfoldBox .unfold {
      max-height: 0;
      width: 100%;
      position: relative;
      overflow: scroll; }
      article.proDetailBox.postpaid .leftColumn .tabsBox .partbox .unfoldBox .unfold.on {
        max-height: none;
        max-height: initial;
        padding-bottom: 60px; }
      article.proDetailBox.postpaid .leftColumn .tabsBox .partbox .unfoldBox .unfold table tr td {
        text-align: left; }
        article.proDetailBox.postpaid .leftColumn .tabsBox .partbox .unfoldBox .unfold table tr td * {
          white-space: normal; }
      article.proDetailBox.postpaid .leftColumn .tabsBox .partbox .unfoldBox .unfold table tr:hover * {
        color: #fff; }
  article.proDetailBox.postpaid .leftColumn .tabsBox .partbox .mainTitle {
    padding: 30px 48px 24px 30px;
    margin: 0; }
    article.proDetailBox.postpaid .leftColumn .tabsBox .partbox .mainTitle .close {
      font-size: 0;
      background-image: url(/DigService/content/dam/fetnet/resources/cbu/images/plus.svg);
      transform: none;
      transform: initial;
      right: 48px;
      top: 30px; }
      article.proDetailBox.postpaid .leftColumn .tabsBox .partbox .mainTitle .close.odd {
        background-image: url(/DigService/content/dam/fetnet/resources/cbu/images/minus.svg); }
    article.proDetailBox.postpaid .leftColumn .tabsBox .partbox .mainTitle .subTitle {
      font-size: 20px;
      margin: 0;
      padding: 0; }
      article.proDetailBox.postpaid .leftColumn .tabsBox .partbox .mainTitle .subTitle::after {
        display: none; }
  article.proDetailBox.postpaid .leftColumn .tabsBox .partbox .open {
    display: none; }

article .tabs ul.ui-tabs-nav {
  border-bottom: 6px solid #d6d6d6;
  display: flex;
  align-content: center;
  justify-content: space-around; }
  article .tabs ul.ui-tabs-nav li {
    text-align: center;
    width: 100%; }
    article .tabs ul.ui-tabs-nav li a {
      border: 0; }
    article .tabs ul.ui-tabs-nav li.ui-state-active {
      border-bottom: 6px solid #ef3123;
      margin-bottom: -6px; }
      article .tabs ul.ui-tabs-nav li.ui-state-active::after {
        display: none; }

@media screen and (max-width: 480px) {
  .wrapperPage section article.recommendBox .detailBoxBd .editBox table.posttable tr th, .wrapperPage section article.recommendBox .detailBoxBd .editBox table.posttable tr td {
    white-space: nowrap; } }

@media screen and (max-width: 960px) {
  article .tabs ul.ui-tabs-nav {
    display: flex;
    overflow-x: scroll;
    width: 100%;
    white-space: nowrap;
    border: 0;
    padding-bottom: 12px;
    justify-content: normal; }
    article .tabs ul.ui-tabs-nav li.ui-state-active {
      border-bottom: 6px solid #ef3123;
      margin-bottom: 0;
      width: 100%;
      border-bottom: 6px solid #d6d6d6;
      margin: 0; }
  article.proDetailBox.postpaid .leftColumn .tabsBox .partbox .mainTitle .close {
    right: 15px; } }

.shareBox iframe.line-it-button {
  margin-top: 0;
  background-image: url(/DigService/content/dam/fetnet/resources/cbu/images/line-share.svg) !important;
  background-size: contain !important; }

.shareBox a.facebook {
  background-image: url(/DigService/content/dam/fetnet/resources/cbu/images/facebook-share.svg) !important;
  background-size: contain !important; }

.shareBox a.line[style] {
  background-image: url(/DigService/content/dam/fetnet/resources/cbu/images/line-share.svg) !important;
  background-size: contain !important; }

.shareBox .title {
  font-size: 16px;
  color: #202020; }

article.proDetailBox.postpaid .detailBoxHd {
  display: flex;
  flex-wrap: wrap;
  box-shadow: none;
  border: 0; }
  article.proDetailBox.postpaid .detailBoxHd .shareBox iframe.line-it-button {
    margin-top: 0;
    background-image: url(/DigService/content/dam/fetnet/resources/cbu/images/line-share.svg) !important;
    background-size: contain; }
  article.proDetailBox.postpaid .detailBoxHd .shareBox a.facebook {
    background-image: url(/DigService/content/dam/fetnet/resources/cbu/images/facebook-share.svg);
    background-size: contain; }
  article.proDetailBox.postpaid .detailBoxHd .shareBox a.line[style] {
    background-image: url(/DigService/content/dam/fetnet/resources/cbu/images/line-share.svg) !important;
    background-size: contain; }
  article.proDetailBox.postpaid .detailBoxHd .shareBox .title {
    font-size: 16px;
    color: #202020; }
  article.proDetailBox.postpaid .detailBoxHd .albumsBox {
    display: none; }
    article.proDetailBox.postpaid .detailBoxHd .albumsBox .photo {
      width: auto;
      display: flex; }
      article.proDetailBox.postpaid .detailBoxHd .albumsBox .photo img {
        width: 100%; }
  article.proDetailBox.postpaid .detailBoxHd .detailBox {
    padding: 0;
    width: calc( 100% - 236px);
    margin-bottom: 4px;
    margin-top: 48px; }
    article.proDetailBox.postpaid .detailBoxHd .detailBox .text {
      line-height: 1.78; }
    article.proDetailBox.postpaid .detailBoxHd .detailBox h3.font24b {
      font-size: 36px !important; }

@media screen and (max-width: 960px) {
  article.proDetailBox.postpaid .detailBoxHd .detailBox {
    width: 100%; }
    article.proDetailBox.postpaid .detailBoxHd .detailBox h3.font24b {
      margin-bottom: 24px; }
  article.proDetailBox.postpaid .detailBoxHd .shareBox {
    right: -10px;
    top: -20px; } }

html {
  font-size: 100%; }

body iframe {
  height: calc( 100vh + 1.125rem);
  margin-top: -1.125rem; }

#wrapper {
  min-width: 0;
  min-width: initial;
  width: 100%; }
  #wrapper section {
    overflow: scroll;
    min-width: 0;
    min-width: initial; }

.main.even.min {
  display: none !important; }

.all-plan-bg {
  background-image: url(/DigService/content/dam/fetnet/resources/cbu/images/cbu-bg-pattern.svg);
  background-size: auto;
  background-position: left top;
  width: 223px;
  height: 312px;
  margin-top: 0px;
  position: absolute; }

.all-plan-bg + .fui-container {
  padding-top: 120px;
  padding-bottom: 100px; }

article.kv-wrapper {
  position: relative;
  margin-bottom: 80px;
  height: 240px;
  display: flex;
  align-items: center; }
  article.kv-wrapper .img {
    width: 100%;
    position: absolute;
    height: 240px;
    background-position: center right;
    background-size: cover; }
  article.kv-wrapper h1 {
    position: relative;
    margin-left: 96px; }

.other-rate-plan-wrapper {
  display: flex;
  flex-direction: row; }
  .other-rate-plan-wrapper .list {
    display: flex;
    flex-direction: column;
    padding-right: 80px; }
    .other-rate-plan-wrapper .list h4 {
      color: #f05c38;
      font-size: 24px !important;
      margin-bottom: 24px; }
    .other-rate-plan-wrapper .list a.body {
      font-weight: 500;
      margin-bottom: 6px;
      display: flex;
      align-items: center; }
      .other-rate-plan-wrapper .list a.body i {
        font-size: 22px !important;
        color: #202020; }
      .other-rate-plan-wrapper .list a.body:hover {
        transition: all 0.5s ease-in; }
        .other-rate-plan-wrapper .list a.body:hover i {
          color: #d3060f; }

@media screen and (max-width: 960px) {
  .other-rate-plan-wrapper {
    flex-direction: column;
    margin-bottom: 60px !important; } }

article.crumbBox {
  z-index: 1;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  background: none;
  padding-top: 100px;
  padding-left: 0; }
  article.crumbBox .mainBox {
    color: #202020;
    margin-left: 0; }
    article.crumbBox .mainBox a {
      font-size: 14px;
      color: #202020;
      margin: 0 0.75rem;
      font-weight: 400; }
      article.crumbBox .mainBox a:nth-of-type(1) {
        margin-left: 0; }
    article.crumbBox .mainBox h1.mainHead {
      font-size: 14px;
      color: #202020;
      margin-left: 0.75rem;
      font-weight: 600;
      line-height: normal;
      line-height: initial; }

iframe[src*='otherservice.html'] ~ .wrapperPage {
  background: red; }

.wrapperPage section .main.top10downNo {
  padding: 0; }
  .wrapperPage section .main.top10downNo .tabs.unit ul#lv3TabDiv {
    border-bottom: 0;
    margin: 0; }
    .wrapperPage section .main.top10downNo .tabs.unit ul#lv3TabDiv li {
      margin-top: 2.25rem;
      display: none; }
      .wrapperPage section .main.top10downNo .tabs.unit ul#lv3TabDiv li a {
        font-size: 0.875rem;
        color: #fff; }
  .wrapperPage section .main.top10downNo .subtab {
    display: none; }
  .wrapperPage section .main.top10downNo .leftColumn .subtab {
    display: block; }
  .wrapperPage section .main.top10downNo article.ratePlanHd {
    margin: 0;
    width: 100%; }
    .wrapperPage section .main.top10downNo article.ratePlanHd .mainBox {
      text-align: left;
      margin: 0;
      left: 0px;
      width: 100%;
      top: 84px; }
      .wrapperPage section .main.top10downNo article.ratePlanHd .mainBox .mainTitle {
        color: #fff;
        font-size: 2.625rem;
        margin-bottom: 1.75rem;
        margin-left: 1.875rem; }
      .wrapperPage section .main.top10downNo article.ratePlanHd .mainBox .text {
        color: #fff;
        font-size: 1.125rem;
        font-weight: 600;
        margin-left: 1.875rem;
        margin-bottom: 112px; }
      .wrapperPage section .main.top10downNo article.ratePlanHd .mainBox .start {
        margin-bottom: 1.125rem; }
        .wrapperPage section .main.top10downNo article.ratePlanHd .mainBox .start span {
          color: #202020;
          font-size: 1.25rem;
          font-weight: 600;
          border: 0; }
          .wrapperPage section .main.top10downNo article.ratePlanHd .mainBox .start span::after {
            display: none; }
    .wrapperPage section .main.top10downNo article.ratePlanHd .kvComplex {
      overflow: hidden;
      height: 240px;
      width: 100%;
      filter: brightness(0.7); }
      .wrapperPage section .main.top10downNo article.ratePlanHd .kvComplex img {
        min-width: 100%;
        min-height: 100%;
        transform: translate(-50%, -50%);
        position: relative;
        left: 50%;
        top: 50%;
        margin-bottom: 1.125rem; }
  .wrapperPage section .main.top10downNo article.ratePlanBd {
    margin-top: 118px; }
    .wrapperPage section .main.top10downNo article.ratePlanBd ul.rateItem {
      width: 100%;
      display: flex;
      margin-bottom: 3rem;
      border: 0;
      justify-content: flex-start; }
      .wrapperPage section .main.top10downNo article.ratePlanBd ul.rateItem:nth-last-child(1) {
        margin-right: 0; }
      .wrapperPage section .main.top10downNo article.ratePlanBd ul.rateItem li {
        width: 32.6%;
        border: solid 2px #f05c38 !important;
        margin: 0 12px 0 0;
        height: auto; }
        .wrapperPage section .main.top10downNo article.ratePlanBd ul.rateItem li a.ui-tabs-anchor {
          display: flex;
          justify-content: center;
          padding: 24px 0; }
          .wrapperPage section .main.top10downNo article.ratePlanBd ul.rateItem li a.ui-tabs-anchor span.hover {
            display: none; }
          .wrapperPage section .main.top10downNo article.ratePlanBd ul.rateItem li a.ui-tabs-anchor span.text {
            color: #f05c38;
            font-size: 1rem;
            font-weight: 500; }
          .wrapperPage section .main.top10downNo article.ratePlanBd ul.rateItem li a.ui-tabs-anchor::after {
            content: ""; }
        .wrapperPage section .main.top10downNo article.ratePlanBd ul.rateItem li::after {
          display: none; }
    .wrapperPage section .main.top10downNo article.ratePlanBd .ratePlanBox {
      margin-bottom: 3.375rem; }
      .wrapperPage section .main.top10downNo article.ratePlanBd .ratePlanBox .mainTitle {
        background-color: transparent;
        background-color: initial;
        font-weight: 600;
        height: 3.125rem; }
      .wrapperPage section .main.top10downNo article.ratePlanBd .ratePlanBox .number {
        display: none; }
      .wrapperPage section .main.top10downNo article.ratePlanBd .ratePlanBox .boxComplex {
        border: 2px solid #e8ecf0 !important;
        box-shadow: none; }
        .wrapperPage section .main.top10downNo article.ratePlanBd .ratePlanBox .boxComplex::after {
          position: absolute;
          top: 0.75rem;
          left: 6px;
          content: "";
          height: 1.875rem;
          width: 1.875rem;
          border-radius: 50%;
          border: 1px solid #e8ecf0; }
      .wrapperPage section .main.top10downNo article.ratePlanBd .ratePlanBox ul#answerList {
        display: flex;
        flex-wrap: wrap;
        margin: 0; }
        .wrapperPage section .main.top10downNo article.ratePlanBd .ratePlanBox ul#answerList .boxComplex {
          margin: 0;
          padding: 1.625rem 3.75rem; }
        .wrapperPage section .main.top10downNo article.ratePlanBd .ratePlanBox ul#answerList li {
          margin: 0 0.75rem 0.75rem 0;
          width: calc( (100% - 24px)/3); }
          .wrapperPage section .main.top10downNo article.ratePlanBd .ratePlanBox ul#answerList li:nth-child(3n) {
            margin-right: 0;
            display: flex;
            flex-wrap: wrap; }
      .wrapperPage section .main.top10downNo article.ratePlanBd .ratePlanBox ul li {
        width: calc( (100% - 24px)/3);
        margin-right: 0.75rem;
        margin-bottom: 0.75rem;
        display: flex; }
        .wrapperPage section .main.top10downNo article.ratePlanBd .ratePlanBox ul li:nth-last-child(1) {
          margin-right: 0; }
        .wrapperPage section .main.top10downNo article.ratePlanBd .ratePlanBox ul li .boxComplex {
          margin: 0.5rem 0 0 0;
          padding: 1.625rem 3.75rem;
          height: auto;
          width: 100%; }
        .wrapperPage section .main.top10downNo article.ratePlanBd .ratePlanBox ul li .vertical {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%; }
          .wrapperPage section .main.top10downNo article.ratePlanBd .ratePlanBox ul li .vertical p {
            font-size: 1rem;
            color: #5f6e84;
            font-weight: 500; }
        .wrapperPage section .main.top10downNo article.ratePlanBd .ratePlanBox ul li.active .boxComplex {
          box-shadow: none;
          border: 2px solid #f05c38 !important;
          padding: 1.5rem 3.75rem; }
          .wrapperPage section .main.top10downNo article.ratePlanBd .ratePlanBox ul li.active .boxComplex p {
            color: #f05c38; }
          .wrapperPage section .main.top10downNo article.ratePlanBd .ratePlanBox ul li.active .boxComplex::before {
            border: 1px solid #f05c38;
            width: 1.875rem;
            height: 1.875rem;
            background: #f05c38;
            left: 6px;
            top: 12px;
            content: "";
            border-radius: 50%;
            position: absolute; }
          .wrapperPage section .main.top10downNo article.ratePlanBd .ratePlanBox ul li.active .boxComplex::after {
            width: 10px;
            height: 10px;
            background: #fff;
            left: 16px;
            top: 22px; }
    .wrapperPage section .main.top10downNo article.ratePlanBd .resultBox {
      border: none;
      box-shadow: 0 6px 12px 0 rgba(75, 88, 105, 0.12);
      margin-top: 5.625rem; }
      .wrapperPage section .main.top10downNo article.ratePlanBd .resultBox .leftColumn {
        background-image: url(/DigService/content/dam/fetnet/resources/cbu/images/result.svg);
        background-position: right bottom;
        background-size: contain;
        background-repeat: no-repeat;
        background-color: #EFF4F1;
        height: 386px;
        min-height: 0;
        min-height: initial;
        width: 35%;
        padding: 60px 30px 40px 30px; }
        .wrapperPage section .main.top10downNo article.ratePlanBd .resultBox .leftColumn .mainTitle {
          font-weight: 500;
          background-image: url(/DigService/content/dam/fetnet/resources/cbu/images/calculator.svg);
          padding: 42px 0 0 0; }
        .wrapperPage section .main.top10downNo article.ratePlanBd .resultBox .leftColumn .text {
          font-size: 1rem;
          padding: 0 30px 0 0; }
          .wrapperPage section .main.top10downNo article.ratePlanBd .resultBox .leftColumn .text::before, .wrapperPage section .main.top10downNo article.ratePlanBd .resultBox .leftColumn .text::after {
            display: none; }
      .wrapperPage section .main.top10downNo article.ratePlanBd .resultBox .rightColumn {
        height: 386px;
        min-height: 0;
        min-height: initial;
        width: 65%; }
        .wrapperPage section .main.top10downNo article.ratePlanBd .resultBox .rightColumn .subTitle {
          font-size: 1.25rem;
          color: #202020;
          font-weight: 600;
          margin-bottom: 1.75rem; }
        .wrapperPage section .main.top10downNo article.ratePlanBd .resultBox .rightColumn .mainTitle a {
          font-size: 36px !important;
          color: #f05c38 !important;
          margin-bottom: 1.625rem !important;
          text-decoration: none; }
        .wrapperPage section .main.top10downNo article.ratePlanBd .resultBox .rightColumn .text {
          font-size: 1rem;
          border-bottom: 0;
          color: #202020; }
        .wrapperPage section .main.top10downNo article.ratePlanBd .resultBox .rightColumn .btnDownBox {
          display: flex;
          margin: 3.375rem 0 0 0; }
          .wrapperPage section .main.top10downNo article.ratePlanBd .resultBox .rightColumn .btnDownBox .box {
            order: 1;
            margin: 0 0.75rem 0 0; }
            .wrapperPage section .main.top10downNo article.ratePlanBd .resultBox .rightColumn .btnDownBox .box a {
              background-color: #fff;
              color: #202020;
              border: 0;
              height: 40px; }
              .wrapperPage section .main.top10downNo article.ratePlanBd .resultBox .rightColumn .btnDownBox .box a.reSet {
                outline: #202020 solid 2px; }
            .wrapperPage section .main.top10downNo article.ratePlanBd .resultBox .rightColumn .btnDownBox .box:nth-last-child(1) {
              order: 0; }
              .wrapperPage section .main.top10downNo article.ratePlanBd .resultBox .rightColumn .btnDownBox .box:nth-last-child(1) a.button {
                background-color: #d3060f;
                color: #fff;
                border: medium none currentColor;
                border: initial; }
        .wrapperPage section .main.top10downNo article.ratePlanBd .resultBox .rightColumn .desc {
          text-align: left;
          left: 2.625rem; }
  .wrapperPage section .main.top10downNo article.empowerBox .descbox, .wrapperPage section .main.top10downNo article.recommendBox .detailBoxBd .descbox {
    background-color: #fafafa;
    padding: 30px; }
  .wrapperPage section .main.top10downNo article.empowerBox .descbox .mainTitle, .wrapperPage section .main.top10downNo article.recommendBox .detailBoxBd .descbox .mainTitle {
    color: #5f6e84; }
  .wrapperPage section .main.top10downNo article.empowerBox .descbox ol > li, .wrapperPage section .main.top10downNo article.recommendBox .detailBoxBd .descbox ol > li {
    color: #5f6e84;
    font-size: 14px;
    line-height: 24px; }
  .wrapperPage section .main.top10downNo article.empowerBox .descbox .mainTitle, .wrapperPage section .main.top10downNo article.recommendBox .detailBoxBd .descbox .mainTitle {
    padding-left: 14px; }

@media screen and (max-width: 960px) {
  article.kv-wrapper {
    margin-top: 20px;
    margin-bottom: 20px;
    height: 212px; }
    article.kv-wrapper .img {
      height: 212px; }
    article.kv-wrapper h1 {
      margin-left: 16px; }
  .all-plan-bg {
    background-image: url(/DigService/content/dam/fetnet/resources/cbu/images/cbu-bg-pattern-mb.svg); }
  .all-plan-bg + .fui-container {
    padding-top: 90px;
    padding-bottom: 60px; }
  .wrapperPage section article.crumbBox {
    display: none; }
  .wrapperPage section .main.top10downNo article.ratePlanHd {
    padding: 1.875rem 0px;
    width: auto;
    height: auto; }
    .wrapperPage section .main.top10downNo article.ratePlanHd .mainBox {
      margin: 0;
      top: 0;
      left: 0;
      width: auto;
      position: relative; }
      .wrapperPage section .main.top10downNo article.ratePlanHd .mainBox .mainTitle {
        font-size: 32px;
        margin-left: 1.25rem;
        line-height: 1.5;
        margin-bottom: 0; }
      .wrapperPage section .main.top10downNo article.ratePlanHd .mainBox .text {
        margin-left: 1.25rem;
        margin-bottom: 96px; }
      .wrapperPage section .main.top10downNo article.ratePlanHd .mainBox .start {
        margin-left: 1.25rem;
        padding-top: 48px; }
  .wrapperPage section .main.top10downNo article.ratePlanBd {
    padding: 1rem;
    margin-top: 88px; }
    .wrapperPage section .main.top10downNo article.ratePlanBd ul.rateItem li {
      width: calc( (100% - 12px)/2) !important;
      margin: 0px 0.75rem 0.75rem 0 !important; }
    .wrapperPage section .main.top10downNo article.ratePlanBd .ratePlanBox ul li .boxComplex {
      padding: 0.75rem 0.875rem 0.75rem 2.625rem !important;
      min-height: 76px; }
      .wrapperPage section .main.top10downNo article.ratePlanBd .ratePlanBox ul li .boxComplex .vertical p {
        width: 100%;
        text-align: left;
        width: 100%;
        margin-bottom: 36px; }
    .wrapperPage section .main.top10downNo article.ratePlanBd .ratePlanBox .text {
      min-height: 0;
      min-height: initial; }
    .wrapperPage section .main.top10downNo article.ratePlanBd .ratePlanBox ul#answerList .boxComplex {
      padding: 0.75rem 0.875rem 0.75rem 2.625rem;
      display: flex;
      min-height: 76px; }
    .wrapperPage section .main.top10downNo article.ratePlanBd .ratePlanBox ul li {
      width: calc( (100% - 12px)/2) !important;
      margin: 0px 0.75rem 0.75rem 0 !important; }
      .wrapperPage section .main.top10downNo article.ratePlanBd .ratePlanBox ul li .boxComplex {
        padding: 0.75rem 0.875rem 0.75rem 2.625rem !important; }
        .wrapperPage section .main.top10downNo article.ratePlanBd .ratePlanBox ul li .boxComplex .vertical {
          text-align: left; }
          .wrapperPage section .main.top10downNo article.ratePlanBd .ratePlanBox ul li .boxComplex .vertical p {
            width: 100%; }
      .wrapperPage section .main.top10downNo article.ratePlanBd .ratePlanBox ul li:nth-child(2n) {
        margin-right: 0 !important; }
      .wrapperPage section .main.top10downNo article.ratePlanBd .ratePlanBox ul li:nth-child(3n) {
        margin-right: 12px; }
  .wrapperPage section ul#answerList {
    flex-wrap: wrap; }
    .wrapperPage section ul#answerList li:nth-child(2n) {
      margin-right: 0 !important; }
    .wrapperPage section ul#answerList li .boxComplex {
      padding: 0.75rem 0.875rem 0.75rem 2.625rem !important;
      display: flex;
      min-height: 76px; }
  .wrapperPage section .main.top10downNo .tabs.unit article.ratePlanBd .resultBox {
    margin-top: 0; }
    .wrapperPage section .main.top10downNo .tabs.unit article.ratePlanBd .resultBox .leftColumn {
      width: 100%;
      height: auto !important; }
      .wrapperPage section .main.top10downNo .tabs.unit article.ratePlanBd .resultBox .leftColumn .text {
        min-height: 0;
        min-height: initial; }
    .wrapperPage section .main.top10downNo .tabs.unit article.ratePlanBd .resultBox .rightColumn {
      width: 100%;
      height: auto;
      padding: 1.875rem 1.25rem;
      box-shadow: 0 6px 0.75rem 0 rgba(75, 88, 105, 0.12); }
      .wrapperPage section .main.top10downNo .tabs.unit article.ratePlanBd .resultBox .rightColumn .mainTitle[style] {
        font-size: 1.25rem; }
      .wrapperPage section .main.top10downNo .tabs.unit article.ratePlanBd .resultBox .rightColumn .btnDownBox {
        margin-top: 10px;
        margin-bottom: 3.75rem; }
      .wrapperPage section .main.top10downNo .tabs.unit article.ratePlanBd .resultBox .rightColumn .desc {
        position: relative;
        left: 0; }
  .wrapperPage section .main.top10downNo .detailBoxBd .descbox {
    margin-top: 3.75rem;
    padding: 1.875rem 1.875rem 3.75rem 1.875rem; }
  .wrapperPage .unfold ol li {
    white-space: normal;
    white-space: initial; }
  article.proDetailBox.postpaid .detailBoxHd .detailBox {
    width: 100%;
    margin-right: 0; } }

.fui-app-trial-modal .fui-app-trial-body {
  max-height: 80vh;
  max-width: 800px;
  background: #FFFFFF;
  text-align: center;
  margin: auto 10px;
  box-shadow: 0 6px 12px 0 rgba(75, 88, 105, 0.12) !important; }
  .fui-app-trial-modal .fui-app-trial-body img {
    width: 100%; }
  .fui-app-trial-modal .fui-app-trial-body .fui-buttons {
    margin: 24px 0 30px; }
    .fui-app-trial-modal .fui-app-trial-body .fui-buttons .fui-button {
      margin: 0 6px; }

@media screen and (min-width: 960px) {
  .fui-app-trial-modal .fui-app-trial-body {
    margin: auto; } }

.fui-plan-table {
  margin: 12px -15px 0; }
  .fui-plan-table .fui-plan-table-item {
    height: 52px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    font-size: 0.875rem;
    font-weight: 500; }
    .fui-plan-table .fui-plan-table-item.more-height,
    .fui-plan-table .fui-plan-table-item.more-height .fui-plan-table-label {
      height: 62px !important; }
  .fui-plan-table .fui-plan-table-body {
    margin-left: 96px;
    box-shadow: 0 6px 12px 0 rgba(75, 88, 105, 0.12) !important; }
    .fui-plan-table .fui-plan-table-body .slick-slider .slick-arrow {
      display: none !important;
      position: absolute; }
      .fui-plan-table .fui-plan-table-body .slick-slider .slick-arrow.slick-disabled {
        display: none; }
    .fui-plan-table .fui-plan-table-body .slick-slider .fui-plan-table-segment {
      text-align: center;
      min-width: 180px;
      border-top: solid 3px #FFFFFF;
      border-bottom: solid 3px #FFFFFF;
      box-shadow: inset -1px 0 0 0 #EBEBEB; }
      .fui-plan-table .fui-plan-table-body .slick-slider .fui-plan-table-segment.is-select {
        border: solid 3px #FD8768; }
      .fui-plan-table .fui-plan-table-body .slick-slider .fui-plan-table-segment .fui-plan-table-item {
        background: #FFFFFF;
        color: #5F6E84;
        text-align: center;
        box-shadow: inset 0 1px 0 0 #EBEBEB, inset -1px 0 0 0 #EBEBEB; }
        .fui-plan-table .fui-plan-table-body .slick-slider .fui-plan-table-segment .fui-plan-table-item.is-hover {
          background: #eff4f1; }
        .fui-plan-table .fui-plan-table-body .slick-slider .fui-plan-table-segment .fui-plan-table-item > div {
          display: flex;
          height: 100%;
          flex: 1 1;
          text-align: center;
          align-content: center;
          align-items: center;
          justify-content: center; }
          .fui-plan-table .fui-plan-table-body .slick-slider .fui-plan-table-segment .fui-plan-table-item > div + div {
            box-shadow: -1px 0 0 0 #EBEBEB; }
        .fui-plan-table .fui-plan-table-body .slick-slider .fui-plan-table-segment .fui-plan-table-item:last-child {
          box-shadow: inset 0 1px 0 0 #EBEBEB, inset -1px 0 0 0 #EBEBEB;
          height: 49px; }
      .fui-plan-table .fui-plan-table-body .slick-slider .fui-plan-table-segment .fui-plan-table-title + .fui-plan-table-item {
        color: #202020; }
      .fui-plan-table .fui-plan-table-body .slick-slider .fui-plan-table-segment .fui-plan-table-title {
        background: #FFFFFF;
        height: 120px;
        padding: 12px 20px;
        box-shadow: inset -1px 0 0 0 #EBEBEB; }
        .fui-plan-table .fui-plan-table-body .slick-slider .fui-plan-table-segment .fui-plan-table-title h1 {
          margin: 0;
          font-size: 42px;
          line-height: 63px; }
          .fui-plan-table .fui-plan-table-body .slick-slider .fui-plan-table-segment .fui-plan-table-title h1 small {
            font-size: 0.875rem; }
          .fui-plan-table .fui-plan-table-body .slick-slider .fui-plan-table-segment .fui-plan-table-title h1 .price {
            margin-right: 6px; }
            .fui-plan-table .fui-plan-table-body .slick-slider .fui-plan-table-segment .fui-plan-table-title h1 .price small {
              font-size: 20px;
              vertical-align: super; }
        .fui-plan-table .fui-plan-table-body .slick-slider .fui-plan-table-segment .fui-plan-table-title .fui-button {
          margin: 0;
          height: 36px; }
          .fui-plan-table .fui-plan-table-body .slick-slider .fui-plan-table-segment .fui-plan-table-title .fui-button.is-primary .text {
            height: 36px;
            line-height: 36px; }
          .fui-plan-table .fui-plan-table-body .slick-slider .fui-plan-table-segment .fui-plan-table-title .fui-button .text {
            height: 32px;
            line-height: 32px; }
  .fui-plan-table .fui-plan-table-header {
    position: absolute;
    padding-top: 123px; }
    .fui-plan-table .fui-plan-table-header .fui-plan-table-item {
      background: #FFFFFF;
      justify-content: flex-start; }
      .fui-plan-table .fui-plan-table-header .fui-plan-table-item:hover {
        background: #eff4f1; }
    .fui-plan-table .fui-plan-table-header .fui-plan-table-item .fui-plan-table-label {
      display: flex;
      width: 96px;
      height: 52px;
      line-height: 52px;
      font-size: 0.875rem;
      padding: 0 12px;
      color: #FFFFFF;
      background: #5f7b80;
      align-content: center;
      box-shadow: inset 0 -1px 0 0 #95a3a4; }
  @media screen and (min-width: 960px) {
    .fui-plan-table {
      margin: 12px 0 0; }
      .fui-plan-table .fui-plan-table-item {
        font-size: 1rem;
        height: 62px; }
        .fui-plan-table .fui-plan-table-item.more-height,
        .fui-plan-table .fui-plan-table-item.more-height .fui-plan-table-label {
          height: 72px !important; }
      .fui-plan-table .fui-plan-table-header {
        padding-top: 154px; }
        .fui-plan-table .fui-plan-table-header .fui-plan-table-item:first-child,
        .fui-plan-table .fui-plan-table-header .fui-plan-table-item:first-child .fui-plan-table-label {
          height: 52px;
          line-height: 52px; }
        .fui-plan-table .fui-plan-table-header .fui-plan-table-item .fui-plan-table-label {
          font-size: 1rem;
          height: 62px;
          line-height: 62px;
          width: 144px; }
      .fui-plan-table .fui-plan-table-body {
        margin-left: 144px; }
        .fui-plan-table .fui-plan-table-body .slick-slider .slick-arrow {
          display: block !important;
          position: absolute;
          width: 40px;
          height: 40px;
          background: #FFFFFF;
          border-radius: 20px;
          overflow: hidden;
          border: 0;
          z-index: 1;
          box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
          color: transparent;
          top: 0;
          bottom: 0;
          margin: auto;
          opacity: 1;
          transition: opacity 0.3s ease; }
          .fui-plan-table .fui-plan-table-body .slick-slider .slick-arrow.slick-disabled {
            opacity: 0; }
          .fui-plan-table .fui-plan-table-body .slick-slider .slick-arrow::after {
            position: absolute;
            font-family: "icomoon" !important;
            speak: none;
            color: #202020;
            width: 28px;
            height: 28px;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            font-size: 28px;
            font-style: normal;
            font-weight: normal;
            font-feature-settings: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1; }
          .fui-plan-table .fui-plan-table-body .slick-slider .slick-arrow.slick-prev {
            left: -20px; }
            .fui-plan-table .fui-plan-table-body .slick-slider .slick-arrow.slick-prev::after {
              content: ""; }
          .fui-plan-table .fui-plan-table-body .slick-slider .slick-arrow.slick-next {
            right: -20px; }
            .fui-plan-table .fui-plan-table-body .slick-slider .slick-arrow.slick-next::after {
              content: ""; }
        .fui-plan-table .fui-plan-table-body .slick-slider .fui-plan-table-segment .fui-plan-table-title {
          height: 151px;
          padding: 12px 12px 24px; }
          .fui-plan-table .fui-plan-table-body .slick-slider .fui-plan-table-segment .fui-plan-table-title h1 {
            margin-bottom: 12px; }
        .fui-plan-table .fui-plan-table-body .slick-slider .fui-plan-table-segment .fui-plan-table-title + .fui-plan-table-item {
          height: 52px; }
        .fui-plan-table .fui-plan-table-body .slick-slider .fui-plan-table-segment .fui-plan-table-item:last-child {
          height: 59px; } }

.fui-nav-tab.is-box-tab .fui-tab {
  box-shadow: 0 18px 24px 0 rgba(75, 88, 105, 0.12); }

.fui-nav-tab.is-box-tab.with-scrollbar .fui-tab {
  white-space: nowrap;
  overflow-x: auto;
  display: block;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch; }
  .fui-nav-tab.is-box-tab.with-scrollbar .fui-tab .fui-tab-item {
    display: inline-flex;
    width: 112px; }
    .fui-nav-tab.is-box-tab.with-scrollbar .fui-tab .fui-tab-item.is-active {
      box-shadow: 0 18px 36px 0 rgba(75, 88, 105, 0.18); }

@media screen and (min-width: 960px) {
  .fui-nav-tab.is-box-tab.with-scrollbar .fui-tab {
    display: flex; }
    .fui-nav-tab.is-box-tab.with-scrollbar .fui-tab .fui-tab-item {
      width: auto;
      flex: 1 1; } }

section.fui-app-trial {
  background-color: #eff1f2;
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: 100% auto;
  color: #5f7b80;
  padding-bottom: 124px; }
  section.fui-app-trial h2 {
    margin-bottom: 6px; }
  section.fui-app-trial .description {
    font-size: 1rem;
    margin-bottom: 18px; }
  section.fui-app-trial .content > div {
    display: block;
    padding: 3px 0; }
    section.fui-app-trial .content > div * {
      display: inline-block;
      vertical-align: middle; }
    section.fui-app-trial .content > div > img {
      width: 36px;
      margin-right: 12px; }
  section.fui-app-trial .fui-action {
    margin: 30px -6px 0;
    display: flex; }
    section.fui-app-trial .fui-action .fui-button {
      width: 50%;
      margin: 0 6px 0; }
  @media screen and (min-width: 960px) {
    section.fui-app-trial {
      background-position: center;
      background-size: cover;
      padding-bottom: 60px; }
      section.fui-app-trial h2 {
        margin-bottom: 12px; }
      section.fui-app-trial .description {
        margin-bottom: 36px; }
      section.fui-app-trial .content > div {
        padding: 6px 0; }
      section.fui-app-trial .fui-action .fui-button {
        width: auto; } }

.slick-slider.horizontal-cards.is-rate-plan {
  margin-bottom: 0; }
  .slick-slider.horizontal-cards.is-rate-plan .fui-card {
    position: relative; }
    .slick-slider.horizontal-cards.is-rate-plan .fui-card .fui-card-extra {
      padding-left: 0;
      padding-right: 0; }
    .slick-slider.horizontal-cards.is-rate-plan .fui-card.is-promotion {
      background-image: url(/DigService/resources/cbu/exclusive/card-promotion-pattern-02@2x.png);
      background-size: cover; }
      .slick-slider.horizontal-cards.is-rate-plan .fui-card.is-promotion .fui-card-action {
        background: none;
        display: flex;
        height: 100%; }
      .slick-slider.horizontal-cards.is-rate-plan .fui-card.is-promotion .fui-card-content {
        background: none;
        color: #FFFFFF;
        display: flex;
        flex-direction: column;
        justify-content: center; }
        .slick-slider.horizontal-cards.is-rate-plan .fui-card.is-promotion .fui-card-content .fui-card-title {
          font-size: 22px; }
    .slick-slider.horizontal-cards.is-rate-plan .fui-card .fui-card-action {
      height: 100%; }
    .slick-slider.horizontal-cards.is-rate-plan .fui-card .fui-card-caption {
      height: 100%;
      padding: 48px 20px 36px; }
      .slick-slider.horizontal-cards.is-rate-plan .fui-card .fui-card-caption img {
        height: 90px;
        display: block;
        margin: 0 auto; }
      .slick-slider.horizontal-cards.is-rate-plan .fui-card .fui-card-caption .fui-card-title {
        margin-top: 18px;
        text-align: left;
        display: inline-flex; }
      .slick-slider.horizontal-cards.is-rate-plan .fui-card .fui-card-caption .fui-list {
        display: inline-flex;
        flex-direction: column; }
        .slick-slider.horizontal-cards.is-rate-plan .fui-card .fui-card-caption .fui-list .item-with-tooltip {
          width: auto;
          display: inline-flex;
          align-items: center;
          align-content: center; }
          .slick-slider.horizontal-cards.is-rate-plan .fui-card .fui-card-caption .fui-list .item-with-tooltip .fui-tooltip-container {
            margin-left: 6px; }
        .slick-slider.horizontal-cards.is-rate-plan .fui-card .fui-card-caption .fui-list .fui-item {
          width: auto;
          padding: 2px 0; }
          .slick-slider.horizontal-cards.is-rate-plan .fui-card .fui-card-caption .fui-list .fui-item .prefix i {
            color: #D3060F; }
          .slick-slider.horizontal-cards.is-rate-plan .fui-card .fui-card-caption .fui-list .fui-item .content {
            font-size: 1rem;
            text-align: left;
            font-weight: 500;
            color: #5F6E84; }
      .slick-slider.horizontal-cards.is-rate-plan .fui-card .fui-card-caption .fui-card-extra {
        padding-bottom: 0;
        margin-top: 24px; }
        .slick-slider.horizontal-cards.is-rate-plan .fui-card .fui-card-caption .fui-card-extra .fui-button {
          margin: 0; }
      .slick-slider.horizontal-cards.is-rate-plan .fui-card .fui-card-caption .fui-card-content {
        padding: 0; }
      .slick-slider.horizontal-cards.is-rate-plan .fui-card .fui-card-caption .fui-card-ribbon {
        position: absolute;
        left: 0;
        top: 0;
        color: #FFFFFF;
        background: #FC7B66;
        font-size: 0.875rem;
        height: 28px;
        line-height: 28px;
        padding: 0 12px;
        z-index: 9; }
        .slick-slider.horizontal-cards.is-rate-plan .fui-card .fui-card-caption .fui-card-ribbon::after {
          content: "";
          float: right;
          display: block;
          margin-right: -40px;
          width: 0px;
          height: 0px;
          border-left: 14px solid #FC7B66;
          border-top: 14px solid #FC7B66;
          border-right: 14px solid transparent;
          border-bottom: 14px solid transparent; }
  @media screen and (min-width: 960px) {
    .slick-slider.horizontal-cards.is-rate-plan .fui-card.is-promotion .fui-card-content .fui-card-title {
      font-size: 24px; }
    .slick-slider.horizontal-cards.is-rate-plan .fui-card .fui-card-caption img {
      height: 132px; }
    .slick-slider.horizontal-cards.is-rate-plan .fui-card .fui-card-caption .fui-list .fui-item {
      padding: 4px 0; }
      .slick-slider.horizontal-cards.is-rate-plan .fui-card .fui-card-caption .fui-list .fui-item .content {
        font-size: 1.125rem; }
    .slick-slider.horizontal-cards.is-rate-plan .fui-card .fui-card-caption .fui-card-extra {
      margin-top: 32px; } }

@media (max-width: 600px) {
  .jcaptcha {
    width: 50%; }
  .jcaptcha-frame {
    display: flex;
    align-items: flex-end; }
  .jcaptcha-reloadWord {
    margin-left: auto; } }

@media (min-width: 600px) {
  .jcaptcha-frame {
    display: flex;
    align-items: flex-end; }
  .jcaptcha-reloadWord {
    margin-left: auto; } }

.fui-number-selector-container {
  display: inline-block; }
  .fui-number-selector-container + .fui-button {
    margin-left: 12px; }
  .fui-number-selector-container .fui-number-selector {
    display: inline-block; }
    .fui-number-selector-container .fui-number-selector > * {
      display: inline-block;
      width: 50px;
      font-size: 1.25rem;
      text-align: center;
      vertical-align: top;
      border-top: 2px solid #E8ECF0;
      border-bottom: 2px solid #E8ECF0; }
      .fui-number-selector-container .fui-number-selector > * input {
        border: 0;
        font-size: 1rem;
        width: 50px;
        height: 46px;
        line-height: 46px;
        text-align: center;
        -webkit-appearance: none; }
    .fui-number-selector-container .fui-number-selector [role='button'] {
      border: 2px solid #E8ECF0;
      height: 50px;
      line-height: 50px; }
      .fui-number-selector-container .fui-number-selector [role='button'].disabled i {
        opacity: 0.25; }
  .fui-number-selector-container .is-text-error {
    padding: 6px 0;
    font-size: 0.875rem; }

.fui-product-section,
section.fui-product-section {
  padding-top: 30px;
  background-image: url(/DigService/resources/cbu/img/bg-cbu-fcoin-pattern-m@2x.png);
  background-repeat: no-repeat;
  background-size: 100% auto !important; }
  .fui-product-section .product-spec,
  section.fui-product-section .product-spec {
    width: 100%;
    margin-bottom: 24px; }
  .fui-product-section .tag,
  section.fui-product-section .tag {
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    color: #FFFFFF;
    font-size: 14px;
    padding: 4px 12px;
    background-color: #FC7B66; }
    .fui-product-section .tag::after,
    section.fui-product-section .tag::after {
      border-top: 28px solid #FC7B66;
      top: 0px;
      content: "";
      width: 0;
      height: 0;
      border-right: 28px solid transparent;
      position: absolute;
      right: -28px;
      bottom: 0; }
  .fui-product-section h2,
  section.fui-product-section h2 {
    margin-bottom: 54px; }
  .fui-product-section .inline-tag,
  section.fui-product-section .inline-tag {
    font-size: 0.85rem;
    border: 1px solid #202020;
    padding: 0 6px;
    line-height: 21px;
    border-radius: 3px;
    margin-right: 12px;
    vertical-align: middle;
    font-weight: 400; }
  .fui-product-section .slick-slider.fui-product-slider .slick-arrow,
  section.fui-product-section .slick-slider.fui-product-slider .slick-arrow {
    cursor: pointer;
    position: absolute;
    width: 32px;
    height: 32px;
    top: 0;
    bottom: 0;
    margin: auto;
    color: transparent;
    border: 0;
    background: none;
    z-index: 9; }
    .fui-product-section .slick-slider.fui-product-slider .slick-arrow.slick-disabled,
    section.fui-product-section .slick-slider.fui-product-slider .slick-arrow.slick-disabled {
      opacity: 0.25; }
    .fui-product-section .slick-slider.fui-product-slider .slick-arrow::after,
    section.fui-product-section .slick-slider.fui-product-slider .slick-arrow::after {
      position: absolute;
      font-family: "icomoon" !important;
      speak: none;
      color: #202020;
      width: 40px;
      height: 40px;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      font-size: 32px;
      font-style: normal;
      font-weight: normal;
      font-feature-settings: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1; }
    .fui-product-section .slick-slider.fui-product-slider .slick-arrow.slick-prev,
    section.fui-product-section .slick-slider.fui-product-slider .slick-arrow.slick-prev {
      left: 10%; }
      .fui-product-section .slick-slider.fui-product-slider .slick-arrow.slick-prev::after,
      section.fui-product-section .slick-slider.fui-product-slider .slick-arrow.slick-prev::after {
        content: ""; }
    .fui-product-section .slick-slider.fui-product-slider .slick-arrow.slick-next,
    section.fui-product-section .slick-slider.fui-product-slider .slick-arrow.slick-next {
      right: 10%; }
      .fui-product-section .slick-slider.fui-product-slider .slick-arrow.slick-next::after,
      section.fui-product-section .slick-slider.fui-product-slider .slick-arrow.slick-next::after {
        content: ""; }
  .fui-product-section .slick-slider.fui-product-slider ul.slick-dots,
  section.fui-product-section .slick-slider.fui-product-slider ul.slick-dots {
    display: none !important;
    text-align: center;
    list-style: none;
    margin: 32px 0 0;
    padding: 0; }
    .fui-product-section .slick-slider.fui-product-slider ul.slick-dots li,
    section.fui-product-section .slick-slider.fui-product-slider ul.slick-dots li {
      position: relative;
      display: inline-block;
      width: 36px;
      height: 4px;
      vertical-align: top;
      background: #202020;
      opacity: 0.5;
      margin: 0 3px; }
      .fui-product-section .slick-slider.fui-product-slider ul.slick-dots li.slick-active,
      section.fui-product-section .slick-slider.fui-product-slider ul.slick-dots li.slick-active {
        opacity: 1; }
      .fui-product-section .slick-slider.fui-product-slider ul.slick-dots li button,
      section.fui-product-section .slick-slider.fui-product-slider ul.slick-dots li button {
        opacity: 0;
        position: absolute;
        width: 100%;
        cursor: pointer;
        height: 100%;
        left: 0;
        top: 0; }
  .fui-product-section .slick-slider.fui-product-slider .slick-list .slick-track .slick-slide,
  section.fui-product-section .slick-slider.fui-product-slider .slick-list .slick-track .slick-slide {
    text-align: center; }
    .fui-product-section .slick-slider.fui-product-slider .slick-list .slick-track .slick-slide img,
    section.fui-product-section .slick-slider.fui-product-slider .slick-list .slick-track .slick-slide img {
      display: block;
      width: 100%;
      max-width: 180px;
      height: auto;
      margin: 0 auto; }
  .fui-product-section .price-section,
  section.fui-product-section .price-section {
    margin-bottom: 18px; }
    .fui-product-section .price-section h4,
    section.fui-product-section .price-section h4 {
      margin: 3px 0 12px;
      color: #f05c38; }
      .fui-product-section .price-section h4 del,
      section.fui-product-section .price-section h4 del {
        color: #8A8A8A; }
  .fui-product-section .form-group,
  section.fui-product-section .form-group {
    margin-bottom: 30px; }
    .fui-product-section .form-group label,
    section.fui-product-section .form-group label {
      font-size: 16px;
      margin-bottom: 0; }
  .fui-product-section .fui-discount-bar,
  section.fui-product-section .fui-discount-bar {
    display: flex;
    font-size: 0.875rem; }
    .fui-product-section .fui-discount-bar > a.fui-discount-link:link,
    .fui-product-section .fui-discount-bar > a.fui-discount-link:visited,
    section.fui-product-section .fui-discount-bar > a.fui-discount-link:link,
    section.fui-product-section .fui-discount-bar > a.fui-discount-link:visited {
      justify-content: space-between;
      display: flex;
      color: #5f7b80;
      align-content: center;
      align-items: center;
      width: 100%; }
      .fui-product-section .fui-discount-bar > a.fui-discount-link:link::after,
      .fui-product-section .fui-discount-bar > a.fui-discount-link:visited::after,
      section.fui-product-section .fui-discount-bar > a.fui-discount-link:link::after,
      section.fui-product-section .fui-discount-bar > a.fui-discount-link:visited::after {
        display: none !important; }
      .fui-product-section .fui-discount-bar > a.fui-discount-link:link > span,
      .fui-product-section .fui-discount-bar > a.fui-discount-link:visited > span,
      section.fui-product-section .fui-discount-bar > a.fui-discount-link:link > span,
      section.fui-product-section .fui-discount-bar > a.fui-discount-link:visited > span {
        display: flex;
        align-content: center;
        align-items: center; }
      .fui-product-section .fui-discount-bar > a.fui-discount-link:link > span.content,
      .fui-product-section .fui-discount-bar > a.fui-discount-link:visited > span.content,
      section.fui-product-section .fui-discount-bar > a.fui-discount-link:link > span.content,
      section.fui-product-section .fui-discount-bar > a.fui-discount-link:visited > span.content {
        display: block;
        flex: 1 1 auto; }
      .fui-product-section .fui-discount-bar > a.fui-discount-link:link i[class*='icon-'],
      .fui-product-section .fui-discount-bar > a.fui-discount-link:visited i[class*='icon-'],
      section.fui-product-section .fui-discount-bar > a.fui-discount-link:link i[class*='icon-'],
      section.fui-product-section .fui-discount-bar > a.fui-discount-link:visited i[class*='icon-'] {
        color: #202020;
        font-size: 1.5rem; }
    .fui-product-section .fui-discount-bar .discount-ribbon,
    section.fui-product-section .fui-discount-bar .discount-ribbon {
      position: relative;
      width: 64px;
      line-height: 28px;
      height: 28px;
      color: #FFFFFF;
      background: #95a3a4;
      padding-left: 6px;
      margin-right: 20px;
      display: inline-block; }
      .fui-product-section .fui-discount-bar .discount-ribbon::before, .fui-product-section .fui-discount-bar .discount-ribbon::after,
      section.fui-product-section .fui-discount-bar .discount-ribbon::before,
      section.fui-product-section .fui-discount-bar .discount-ribbon::after {
        content: "";
        position: absolute;
        right: -14px;
        width: 0;
        height: 0; }
      .fui-product-section .fui-discount-bar .discount-ribbon::before,
      section.fui-product-section .fui-discount-bar .discount-ribbon::before {
        top: 0;
        border-top: 7px solid transparent;
        border-right: 7px solid transparent;
        border-bottom: 7px solid #95a3a4;
        border-left: 7px solid #95a3a4; }
      .fui-product-section .fui-discount-bar .discount-ribbon::after,
      section.fui-product-section .fui-discount-bar .discount-ribbon::after {
        bottom: 0;
        border-top: 7px solid #95a3a4;
        border-left: 7px solid #95a3a4;
        border-right: 7px solid transparent;
        border-bottom: 7px solid transparent; }
  .fui-product-section .fui-notice,
  section.fui-product-section .fui-notice {
    margin-top: 12px; }
    .fui-product-section .fui-notice .fui-item,
    section.fui-product-section .fui-notice .fui-item {
      padding: 4px 0;
      font-size: 0.875rem; }
      .fui-product-section .fui-notice .fui-item .more,
      .fui-product-section .fui-notice .fui-item a:not(.sub-link-title):not(.fui-button):link,
      .fui-product-section .fui-notice .fui-item a:not(.sub-link-title):not(.fui-button):visited,
      section.fui-product-section .fui-notice .fui-item .more,
      section.fui-product-section .fui-notice .fui-item a:not(.sub-link-title):not(.fui-button):link,
      section.fui-product-section .fui-notice .fui-item a:not(.sub-link-title):not(.fui-button):visited {
        position: relative;
        font-weight: 500;
        color: #202020;
        margin-left: 6px; }
        .fui-product-section .fui-notice .fui-item .more::after,
        .fui-product-section .fui-notice .fui-item a:not(.sub-link-title):not(.fui-button):link::after,
        .fui-product-section .fui-notice .fui-item a:not(.sub-link-title):not(.fui-button):visited::after,
        section.fui-product-section .fui-notice .fui-item .more::after,
        section.fui-product-section .fui-notice .fui-item a:not(.sub-link-title):not(.fui-button):link::after,
        section.fui-product-section .fui-notice .fui-item a:not(.sub-link-title):not(.fui-button):visited::after {
          content: "";
          position: absolute;
          width: 100%;
          height: 2px;
          background: #202020 !important;
          bottom: -2px;
          left: 0; }
  .fui-product-section .radio-group,
  section.fui-product-section .radio-group {
    margin: 0 -6px; }
    .fui-product-section .radio-group .radio.is-icon-box,
    section.fui-product-section .radio-group .radio.is-icon-box {
      width: calc(50% - 12px);
      margin: 6px 6px 0; }
      @media screen and (max-width: 768px) {
        .fui-product-section .radio-group .radio.is-icon-box .content::before,
        section.fui-product-section .radio-group .radio.is-icon-box .content::before {
          left: 6px;
          bottom: auto;
          top: 6px;
          width: 24px;
          height: 24px; }
        .fui-product-section .radio-group .radio.is-icon-box .content::after,
        section.fui-product-section .radio-group .radio.is-icon-box .content::after {
          left: 14px;
          top: 14px;
          bottom: auto; }
        .fui-product-section .radio-group .radio.is-icon-box input:checked + .content:after,
        section.fui-product-section .radio-group .radio.is-icon-box input:checked + .content:after {
          width: 8px;
          height: 8px; } }
  .fui-product-section .fui-color-selector-container .fui-color-selector-items,
  section.fui-product-section .fui-color-selector-container .fui-color-selector-items {
    margin: 0.875rem 0 23px; }
    .fui-product-section .fui-color-selector-container .fui-color-selector-items label.fui-color-selector-item,
    section.fui-product-section .fui-color-selector-container .fui-color-selector-items label.fui-color-selector-item {
      position: relative;
      display: inline-block;
      vertical-align: top; }
      .fui-product-section .fui-color-selector-container .fui-color-selector-items label.fui-color-selector-item input,
      section.fui-product-section .fui-color-selector-container .fui-color-selector-items label.fui-color-selector-item input {
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0; }
        .fui-product-section .fui-color-selector-container .fui-color-selector-items label.fui-color-selector-item input:checked + .color-item,
        section.fui-product-section .fui-color-selector-container .fui-color-selector-items label.fui-color-selector-item input:checked + .color-item {
          box-shadow: 0 0 0 2px #f05c38; }
    .fui-product-section .fui-color-selector-container .fui-color-selector-items .color-item,
    section.fui-product-section .fui-color-selector-container .fui-color-selector-items .color-item {
      display: block;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: solid 4px #FFFFFF;
      box-shadow: 0 0 0 2px rgba(47, 89, 196, 0.2);
      margin-right: 30px; }
  @media screen and (max-width: 768px) {
    .fui-product-section .slick-initialized .slick-slide,
    section.fui-product-section .slick-initialized .slick-slide {
      height: 100%; }
    .fui-product-section .subtitle.down-more, .fui-product-section .fui-card.box .fui-card-content .down-more.fui-card-title, .fui-card.box .fui-card-content .fui-product-section .down-more.fui-card-title,
    section.fui-product-section .subtitle.down-more,
    section.fui-product-section .fui-card.box .fui-card-content .down-more.fui-card-title,
    .fui-card.box .fui-card-content section.fui-product-section .down-more.fui-card-title {
      font-size: 1rem; } }
  @media screen and (min-width: 960px) {
    .fui-product-section,
    section.fui-product-section {
      padding-top: 90px;
      background-image: url(/DigService/resources/cbu/img/bg-cbu-fcoin-pattern-1920-x-1200@2x.png); }
      .fui-product-section .product-spec,
      section.fui-product-section .product-spec {
        width: 230px;
        margin-bottom: 56px; }
      .fui-product-section .page-action a.fui-button,
      section.fui-product-section .page-action a.fui-button {
        width: 230px; }
      .fui-product-section h2,
      section.fui-product-section h2 {
        margin-bottom: 24px; }
      .fui-product-section .fui-tag,
      section.fui-product-section .fui-tag {
        margin-right: 6px;
        font-size: 0.875rem; }
      .fui-product-section .MuiGrid-root.MuiGrid-container,
      section.fui-product-section .MuiGrid-root.MuiGrid-container {
        width: calc(100% + 60px);
        margin: 0 -30px; }
        .fui-product-section .MuiGrid-root.MuiGrid-container .MuiGrid-root.MuiGrid-item,
        section.fui-product-section .MuiGrid-root.MuiGrid-container .MuiGrid-root.MuiGrid-item {
          padding: 0 30px; }
      .fui-product-section .slick-slider.fui-product-slider ul.slick-dots,
      section.fui-product-section .slick-slider.fui-product-slider ul.slick-dots {
        display: block !important; }
        .fui-product-section .slick-slider.fui-product-slider ul.slick-dots li,
        section.fui-product-section .slick-slider.fui-product-slider ul.slick-dots li {
          width: 50px; }
      .fui-product-section .slick-slider.fui-product-slider .slick-list .slick-track .slick-slide img,
      section.fui-product-section .slick-slider.fui-product-slider .slick-list .slick-track .slick-slide img {
        max-width: 320px; } }

main.happy-go .transfer--list {
  margin-bottom: 12px;
  border: 1px solid #E8ECF0;
  padding: 30px; }
  main.happy-go .transfer--list h5 {
    margin: 12px 0 0; }

@media screen and (max-width: 960px) {
  main.happy-go .transfer--list {
    padding: 8px 16px; }
    main.happy-go .transfer--list h5 {
      margin-top: 4px; }
  main.happy-go .fui-pagination {
    margin: 32px 0 0; } }

.block-list .block-url-item {
  display: flex;
  padding: 12px;
  align-items: center;
  align-content: center;
  justify-items: space-between;
  justify-content: space-between;
  background: white;
  border: 2px solid #EFF1F2;
  margin-bottom: 8px; }
  @media screen and (min-width: 600px) {
    .block-list .block-url-item {
      padding: 16px; } }
  .block-list .block-url-item .action img {
    vertical-align: middle; }
  .block-list .block-url-item .item {
    flex: 1 1;
    flex-grow: 1;
    margin-right: 12px;
    word-break: break-all; }

.unsubscribe .form-group {
  margin-bottom: 16px; }
  .unsubscribe .form-group .checkbox-buttons {
    display: flex;
    flex-direction: column; }

.MuiGrid-root.MuiGrid-container .MuiGrid-root.MuiGrid-item .paper {
  display: flex;
  flex-direction: column;
  height: 100%; }
  .MuiGrid-root.MuiGrid-container .MuiGrid-root.MuiGrid-item .paper .content {
    flex: 1 1;
    flex-grow: 1; }
  .MuiGrid-root.MuiGrid-container .MuiGrid-root.MuiGrid-item .paper .fui-button {
    margin: 0; }

table.fui-table.unsubscribe-table tbody > tr {
  background: white;
  border-left: 2px solid #E8ECF0;
  border-right: 2px solid #E8ECF0; }
  table.fui-table.unsubscribe-table tbody > tr > td {
    border-left: 0;
    border-right: 0;
    color: #202020;
    width: auto !important;
    max-width: none !important;
    padding: 24px 32px;
    font-weight: 700; }
    table.fui-table.unsubscribe-table tbody > tr > td:first-child {
      text-align: left; }
    table.fui-table.unsubscribe-table tbody > tr > td:last-child {
      text-align: right; }
  @media screen and (max-width: 600px) {
    table.fui-table.unsubscribe-table tbody > tr > td {
      padding: 12px 16px;
      width: auto !important;
      max-width: none !important;
      min-width: 0px !important; } }

.fui-banner.is-product.is-style-2.allot-report-banner {
  min-height: 360px; }
  .fui-banner.is-product.is-style-2.allot-report-banner .caption {
    top: 32px;
    padding-top: 0;
    color: #202020; }
    .fui-banner.is-product.is-style-2.allot-report-banner .caption * {
      color: #202020; }
  @media screen and (min-width: 768px) {
    .fui-banner.is-product.is-style-2.allot-report-banner .caption {
      top: 80px;
      padding-left: 0; } }

.phone-overview,
.accounts-overview,
.accounts-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center; }
  @media screen and (max-width: 374px) {
    .phone-overview,
    .accounts-overview,
    .accounts-item {
      flex-direction: column;
      align-items: flex-start;
      align-content: flex-start; } }

.account-selector {
  z-index: 9; }

*.is-block-segment {
  opacity: .5 !important;
  pointer-events: none; }

.account-selector-radio .form-group {
  margin-bottom: 16px; }
  .account-selector-radio .form-group .radio-buttons .radio-group .radio-button {
    width: 100%; }
    .account-selector-radio .form-group .radio-buttons .radio-group .radio-button.is-disabled {
      opacity: .5;
      pointer-events: none; }
      .account-selector-radio .form-group .radio-buttons .radio-group .radio-button.is-disabled input:checked ~ .checkmark {
        border-color: #BFBFBF;
        background-color: #BFBFBF; }
    .account-selector-radio .form-group .radio-buttons .radio-group .radio-button:last-child {
      margin-bottom: 16px; }

.ReactModalPortal .ReactModal__Content .fui-prompt-modal-body.account-selector-modal-body {
  max-width: 445px !important;
  padding: 24px 20px; }
  .ReactModalPortal .ReactModal__Content .fui-prompt-modal-body.account-selector-modal-body h5 {
    font-size: 22px; }
  .ReactModalPortal .ReactModal__Content .fui-prompt-modal-body.account-selector-modal-body .fui-buttons.two-button {
    margin: 0 -10px; }
    .ReactModalPortal .ReactModal__Content .fui-prompt-modal-body.account-selector-modal-body .fui-buttons.two-button .fui-button {
      margin: 0 10px; }

.fui-footer-promo.allot-promotion {
  padding: 32px 0;
  min-height: 150px;
  height: 200px; }
  @media screen and (min-width: 768px) {
    .fui-footer-promo.allot-promotion {
      height: 150px; } }
  .fui-footer-promo.allot-promotion .promotion-container {
    max-width: 1170px;
    display: flex;
    justify-items: center;
    justify-content: center;
    align-items: center; }
    .fui-footer-promo.allot-promotion .promotion-container > * {
      align-items: flex-start;
      align-content: flex-start; }
      .fui-footer-promo.allot-promotion .promotion-container > * .promotion-button {
        width: auto; }
    .fui-footer-promo.allot-promotion .promotion-container h4.fui-section-promo-title {
      text-align: left; }
  .fui-footer-promo.allot-promotion a {
    min-height: 0;
    height: auto;
    padding-top: 0;
    padding-bottom: 0; }

.control-list {
  border: 2px solid #EFF1F2;
  border-bottom: none;
  display: flex;
  padding: 12px 16px;
  padding-bottom: 0; }
  .control-list:last-child {
    border-bottom: 2px solid #EFF1F2; }
  .control-list .content {
    display: flex;
    flex-direction: column; }
  .control-list .title-container {
    display: flex;
    align-items: center;
    margin-right: 24px; }
  .control-list .title {
    color: #202020;
    font-family: 'Noto Sans TC';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 178%;
    margin-right: 15px; }
    @media screen and (min-width: 600px) {
      .control-list .title {
        font-size: 22px;
        line-height: 150%; } }
  .control-list .desc {
    color: #5F6E84;
    font-family: 'Noto Sans TC';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px; }
    @media screen and (min-width: 600px) {
      .control-list .desc {
        font-weight: 500;
        font-size: 16px;
        line-height: 175%; } }
  .control-list .edit-icon {
    cursor: pointer; }
  .control-list .divider {
    border-top: 1px solid #EFF1F2; }
  .control-list .switch-container {
    margin-left: auto; }
    .control-list .switch-container .fui-switch input + div.content span.on {
      color: #202020 !important; }
    .control-list .switch-container .fui-switch input + div.content span.off {
      color: #f05c38 !important; }
    .control-list .switch-container .fui-switch input:checked + div.content span.on {
      color: #f05c38 !important; }
    .control-list .switch-container .fui-switch input:checked + div.content span.off {
      color: #202020 !important; }
  .control-list.edit {
    padding: 12px 16px;
    padding-bottom: 0; }
    .control-list.edit .title {
      font-size: 20px;
      line-height: 150%; }
    .control-list.edit .desc {
      color: #4F5E72;
      font-weight: 400;
      font-size: 12px;
      line-height: 175%; }
  @media screen and (min-width: 600px) {
    .control-list {
      padding: 16px 32px; }
      .control-list.edit {
        padding: 12px 32px; }
        .control-list.edit .title {
          font-size: 24px;
          line-height: 150%; }
        .control-list.edit .desc {
          font-weight: 500;
          font-size: 16px;
          line-height: 175%; } }

.time-editor .close-icon {
  cursor: pointer;
  position: absolute;
  right: 24px;
  top: 20px; }

.time-editor i[class*='icon-'] {
  font-size: 22px; }

.time-editor .day-container {
  border-bottom: 2px solid #EFF1F2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1 1;
  padding: 20px; }
  .time-editor .day-container:last-child {
    border-bottom: none; }
  .time-editor .day-container .checkbox-container {
    cursor: pointer;
    flex: 1 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative; }
    .time-editor .day-container .checkbox-container input[type='checkbox'] {
      cursor: pointer;
      margin: 0;
      opacity: 0;
      width: 30px;
      height: 30px;
      position: absolute;
      left: 0;
      bottom: 0; }
      .time-editor .day-container .checkbox-container input[type='checkbox']:checked + .checkbox-ui {
        background: #f05c38;
        border-color: #f05c38; }
        .time-editor .day-container .checkbox-container input[type='checkbox']:checked + .checkbox-ui::after {
          opacity: 1;
          transform: scale(1); }
    .time-editor .day-container .checkbox-container .checkbox-ui {
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      pointer-events: none;
      border: 2px solid #E8ECF0;
      transition: all 0.3s ease;
      margin: 0;
      width: 30px;
      height: 30px; }
      @media screen and (min-width: 960px) {
        .time-editor .day-container .checkbox-container .checkbox-ui {
          margin-top: 10px; } }
      .time-editor .day-container .checkbox-container .checkbox-ui::after {
        box-sizing: border-box;
        display: block;
        content: "";
        color: #FFFFFF;
        font-family: "icomoon" !important;
        font-style: normal;
        font-size: 1rem;
        line-height: 1;
        opacity: 0;
        text-transform: none;
        transform: scale(0);
        transform-origin: 50%;
        transition: all 0.3s ease; }
  @media screen and (min-width: 960px) {
    .time-editor .day-container {
      border-bottom: none;
      padding: 12px 0; } }

/*
 * Retina Backgrounds for Compass
 * by:              Gaya Kessler
 * last update:     03/11/14
 *
 * Usage:
 * 1. create background image for pixel ratio 1 screens and put it somewhere in your images folder.
 * 2. create background image for pixel ratio 2 screens and put it somewhere in your images folder.
 * 3. include the background-image property in your Sass/Scss using: '@include background-retina(<ratio-1-imagefile>, <ratio-2-imagefile>)'
 */
body.is-en {
  font-size: 18px;
  line-height: 28px;
  font-family: "SF Pro", "Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif; }
  body.is-en .fui-button .text {
    line-height: 40px; }
  body.is-en .fui-button.is-large .text {
    line-height: 40px; }
  body.is-en .fui-button.is-small .text {
    line-height: 30px; }
  body.is-en .heading-display {
    font-size: 52px;
    line-height: 68px;
    font-weight: 600; }
  body.is-en h1,
  body.is-en .heading-1 {
    font-size: 34px;
    line-height: 44px;
    font-weight: 600; }
  body.is-en h2,
  body.is-en .heading-2 {
    font-size: 28px;
    line-height: 42px;
    font-weight: 600; }
  body.is-en h3,
  body.is-en .heading-3 {
    font-size: 24px;
    line-height: 36px;
    font-weight: 500; }
  body.is-en h4,
  body.is-en .heading-4 {
    font-size: 22px;
    line-height: 33px;
    font-weight: 600; }
  body.is-en h5,
  body.is-en .heading-5 {
    font-size: 20px;
    line-height: 30px;
    font-weight: 600; }
  body.is-en h6,
  body.is-en .heading-6 {
    font-size: 18px;
    line-height: 27px;
    font-weight: 600; }
  body.is-en .subtitle {
    font-size: 18px;
    line-height: 27px; }
  body.is-en .subtitle-2 {
    font-size: 18px;
    line-height: 30px; }
  body.is-en p,
  body.is-en .text,
  body.is-en .body {
    font-size: 18px;
    line-height: 28px;
    font-weight: 500; }
  body.is-en .body-1 {
    font-size: 16px;
    line-height: 26px; }
  body.is-en small,
  body.is-en .text-sm,
  body.is-en .body-2 {
    font-size: 14px;
    line-height: 22px; }
  body.is-en .caption,
  body.is-en .text-mini {
    font-size: 12px;
    line-height: 18px; }
  body.is-en .is-text-bold {
    font-weight: 600; }
  body.is-en .is-text-medium {
    font-weight: 500; }
  body.is-en .is-text-regular {
    font-weight: 400; }
  body.is-en .is-text-light {
    font-weight: 300; }
  body.is-en .fui-panel h3.fui-panel-title {
    font-size: 24px; }
  body.is-en .fui-panel .fui-list .fui-item {
    font-weight: 400; }
  @media screen and (min-width: 960px) {
    body.is-en .heading-display {
      font-size: 56px;
      line-height: 80px;
      font-weight: 600; }
    body.is-en h1,
    body.is-en .heading-1 {
      font-size: 42px;
      line-height: 63px; }
    body.is-en h2,
    body.is-en .heading-2 {
      font-size: 36px;
      line-height: 54px; }
    body.is-en h3,
    body.is-en .heading-3 {
      font-size: 28px;
      line-height: 42px; }
    body.is-en h4,
    body.is-en .heading-4 {
      font-size: 24px;
      line-height: 36px; }
    body.is-en h5,
    body.is-en .heading-5 {
      font-size: 22px;
      line-height: 33px; }
    body.is-en h6,
    body.is-en .heading-6 {
      font-size: 20px;
      line-height: 30px; }
    body.is-en .subtitle {
      font-size: 18px;
      line-height: 27px; }
    body.is-en .subtitle-2 {
      display: block; }
    body.is-en section.fui-content h3 {
      font-size: 42px;
      font-weight: 500; } }

.is-text-darkgray50 a.help-center {
  color: #EF3123 !important; }

.is-text-darkgray50 a.remote-road {
  color: #EF3123 !important; }

/*
 * Retina Backgrounds for Compass
 * by:              Gaya Kessler
 * last update:     03/11/14
 *
 * Usage:
 * 1. create background image for pixel ratio 1 screens and put it somewhere in your images folder.
 * 2. create background image for pixel ratio 2 screens and put it somewhere in your images folder.
 * 3. include the background-image property in your Sass/Scss using: '@include background-retina(<ratio-1-imagefile>, <ratio-2-imagefile>)'
 */
@keyframes fadeOut {
  0% {
    display: block;
    opacity: 1;
    z-index: 99; }
  99% {
    display: block;
    opacity: 0;
    z-index: 99; }
  100% {
    display: none;
    opacity: 0;
    z-index: -1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes zoomIn {
  0% {
    opacity: 0;
    transform: scale(1.1);
    transform-origin: 50% 50vh; }
  100% {
    opacity: 1;
    transform: scale(1);
    transform-origin: 50% 50vh; }
  100% {
    opacity: 1;
    transform: none;
    transform-origin: none; } }

@keyframes trapzoidalSlideIn {
  0% {
    transform: translateX(-100vw) translateY(100vh); }
  100% {
    transform: translateX(0vw) translateY(0vh); } }

@keyframes loadingOutBorder {
  0% {
    border-right: 150vw solid #FFF;
    border-left: 150vw solid transparent;
    border-top: 100vh solid #FFF;
    border-bottom: 100vh solid transparent; }
  100% {
    border: 0; } }

@keyframes loadingOut {
  0% {
    transform: translateX(0%);
    width: 100%;
    height: 100%;
    z-index: 99;
    display: block; }
  98% {
    transform: translateX(400vw);
    width: 100%;
    height: 100%;
    z-index: 99;
    display: block; }
  99% {
    transform: translateX(0vw);
    width: 100%;
    height: 100%;
    opacity: 1;
    display: block;
    z-index: 99; }
  100% {
    transform: translateX(0vw);
    opacity: 0;
    width: 0;
    height: 0;
    display: none;
    z-index: -1; } }

@keyframes loadingOut300 {
  0% {
    transform: translateX(0%);
    width: 100%;
    height: 100%;
    z-index: 99;
    display: block; }
  98% {
    transform: translateX(300vw);
    width: 100%;
    height: 100%;
    z-index: 99;
    display: block; }
  99% {
    transform: translateX(300vw);
    width: 100%;
    height: 100%;
    opacity: 1;
    display: block;
    z-index: 99; }
  99% {
    transform: translateX(0vw);
    opacity: 0;
    width: 0;
    height: 0;
    display: block;
    z-index: -1; }
  100% {
    transform: translateX(0vw);
    opacity: 0;
    width: 0;
    height: 0;
    display: none;
    z-index: -1; } }

html.fly-pass,
html.fade-out {
  height: 100vh !important;
  overflow: hidden !important; }

section.loading {
  padding: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #FFFFFF;
  transition: all 0.3s;
  pointer-events: auto;
  display: none;
  z-index: 99;
  animation: fadeOut 0.2s ease forwards; }
  section.loading main {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh !important;
    padding: 0 !important;
    margin: 0 !important; }

html.is-loading {
  overflow: hidden;
  height: 100vh; }
  html.is-loading .loading {
    position: fixed;
    display: block;
    width: 100vw;
    height: 100vh;
    opacity: 1;
    left: 0;
    top: 0;
    background: #FFF;
    z-index: 100;
    overflow: visible;
    animation: fadeIn 0.2s ease forwards; }
    html.is-loading .loading main {
      padding: 0 !important;
      margin: 0 !important; }

@media screen and (max-width: 960px) {
  body.is-loading section.fui-banner .fui-tab-body,
  body.is-loading section.fui-banner .fui-tab-body .kv-bg,
  body.is-loading section.fui-banner .fui-tab-body .kv-arrow,
  body.is-loading-block section.fui-banner .fui-tab-body,
  body.is-loading-block section.fui-banner .fui-tab-body .kv-bg,
  body.is-loading-block section.fui-banner .fui-tab-body .kv-arrow,
  body.is-loading-show section.fui-banner .fui-tab-body,
  body.is-loading-show section.fui-banner .fui-tab-body .kv-bg,
  body.is-loading-show section.fui-banner .fui-tab-body .kv-arrow {
    opacity: 0; } }

