.fourOfour-wrapper {
    min-height: initial; }

.fourOfour {
    display: flex;
    flex-direction: row;
    padding: 60px 0 80px 0;

    &--left {
        flex-basis: 50%;

        h2 {
            margin-top: 60px;
            margin-bottom: 12px; }

        .subtitle {
            margin: 0; }

        .body1 {
            margin-bottom: 48px;
            margin-top: 0; } }

    &--right {
        flex-basis: 50%;

        img {
            width: 100%; } } }
@media screen and (max-width: 960px) {
    .fourOfour {
        flex-direction: column;
        padding: 24px 0 44px 0;

        &--left {
            flex-basis: 100%;
            order: 1;
            text-align: center;

            h2 {
                margin-top: 24px; }

            .body1 {
                margin-bottom: 24px; } }

        &--right {
            flex-basis: 100%;
            order: 0;
            text-align: center;

            img {
                width: 186px; } } } }
