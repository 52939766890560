.react-select-container {
  .react-select__control {
    border: 2px solid $gray50;
    border-radius: 0;
    height: 50px;
    box-shadow: none !important;
    &.react-select__control--is-focused {
      border-color: $darkgray50 !important; } }
  .react-select__value-container .react-select__placeholder {
    color: $black50; }
  .react-select__indicator {
    padding: 0;
    margin-right: 6px;
    font-family: 'icomoon' !important;
    speak: none;
    color: $black50;
    font-size: 24px;
    font-style: normal;
    font-weight: normal;
    font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    &::before {
      content: '\e906'; }
    svg {
      display: none;
      visibility: hidden;
      width: 28px;
      height: 28px;
      fill: $black50; } }
  .react-select__indicator-separator {
    background: none; }
  .react-select__menu {
    border: 0;
    margin-top: 0;
    box-shadow: 0 24px 48px 0 rgba(75, 88, 105, .36);
    .react-select__menu-list {
      .react-select__option.react-select__option--is-focused,
      .react-select__option:hover {
        background: $lightgray100; }
      .react-select__option.react-select__option--is-selected {
        color: $accent; } } } }
