.load-more {
    text-align: center;
    position: relative;
    cursor: pointer;
    margin: 53px 0 118px 0;

    a.expand,p.expand {
        display: flex;
        align-items: center;
        justify-content: center;
        color: $black50;
        font-weight: 500;
        &::after {
            display: none; }

        i {
            font-size: 24px;
            margin-left: 5px; } }

    a {
        color: $black50;
        font-weight: 500;
        &::after {
            display: none; } } }

@media screen and (max-width: 960px) {
    .load-more {
        margin-bottom: 66px; } }
