@charset "UTF-8";

@import "./utils/variable";
@import "./utils/retina-background";

@keyframes fadeOut {
    0% {
        display: block;
        opacity: 1;
        z-index: 99; }

    99% {
        display: block;
        opacity: 0;
        z-index: 99; }

    100% {
        display: none;
        opacity: 0;
        z-index: -1; } }

@keyframes fadeIn {
    0% {
        opacity: 0; }

    100% {
        opacity: 1; } }

@keyframes zoomIn {
    0% {
        opacity: 0;
        transform: scale(1.1);
        transform-origin: 50% 50vh; }

    100% {
        opacity: 1;
        transform: scale(1);
        transform-origin: 50% 50vh; }

    100% {
        opacity: 1;
        transform: none;
        transform-origin: none; } }

@keyframes trapzoidalSlideIn {
    0% {
        transform: translateX(-100vw) translateY(100vh); }

    100% {
        transform: translateX(0vw) translateY(0vh); } }

@keyframes loadingOutBorder {
    0% {
        border-right: 150vw solid #FFF;
        border-left: 150vw solid transparent;
        border-top: 100vh solid #FFF;
        border-bottom: 100vh solid transparent; }

    100% {
        border: 0; } }

@keyframes loadingOut {
    0% {
        transform: translateX(0%);
        width: 100%;
        height: 100%;
        z-index: 99;
        display: block; }

    98% {
        transform: translateX(400vw);
        width: 100%;
        height: 100%;
        z-index: 99;
        display: block; }

    99% {
        transform: translateX(0vw);
        width: 100%;
        height: 100%;
        opacity: 1;
        display: block;
        z-index: 99; }

    100% {
        transform: translateX(0vw);
        opacity: 0;
        width: 0;
        height: 0;
        display: none;
        z-index: -1; } }

@keyframes loadingOut300 {
    0% {
        transform: translateX(0%);
        width: 100%;
        height: 100%;
        z-index: 99;
        display: block; }

    98% {
        transform: translateX(300vw);
        width: 100%;
        height: 100%;
        z-index: 99;
        display: block; }

    99% {
        transform: translateX(300vw);
        width: 100%;
        height: 100%;
        opacity: 1;
        display: block;
        z-index: 99; }

    99% {
        transform: translateX(0vw);
        opacity: 0;
        width: 0;
        height: 0;
        display: block;
        z-index: -1; }

    100% {
        transform: translateX(0vw);
        opacity: 0;
        width: 0;
        height: 0;
        display: none;
        z-index: -1; } }

html.fly-pass,
html.fade-out {
    height: 100vh !important;
    overflow: hidden !important; }

section.loading {
    padding: 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: $white;
    transition: all 0.3s;
    pointer-events: auto;
    display: none;
    z-index: 99;
    animation: fadeOut 0.2s ease forwards;

    main {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100vh !important;
        padding: 0 !important;
        margin: 0 !important; } }

html.is-loading {
    overflow: hidden;
    height: 100vh;

    .loading {
        position: fixed;
        display: block;
        width: 100vw;
        height: 100vh;
        opacity: 1;
        left: 0;
        top: 0;
        background: #FFF;
        z-index: 100;
        overflow: visible;
        animation: fadeIn 0.2s ease forwards;

        main {
            padding: 0 !important;
            margin: 0 !important; } } }

@media screen and (max-width: 960px) {
    body.is-loading,
    body.is-loading-block,
    body.is-loading-show {
        section.fui-banner {
            .fui-tab-body,
            .fui-tab-body .kv-bg,
            .fui-tab-body .kv-arrow {
                opacity: 0; } } } }
