h1,
.heading-1 {
    font-size: 32px;
    line-height: 3rem;
    font-weight: $font-bold; }

h2,
.heading-2 {
    font-size: 28px;
    line-height: 42px;
    font-weight: $font-bold; }

h3,
.heading-3 {
    font-size: 24px;
    line-height: 36px;
    font-weight: $font-bold; }

h4,
.heading-4 {
    font-size: 22px;
    line-height: 33px;
    font-weight: $font-bold; }

h5,
.heading-5 {
    font-size: 20px;
    line-height: 30px;
    font-weight: $font-bold; }

h6,
.heading-6 {
    font-size: 18px;
    line-height: 27px;
    font-weight: $font-bold; }

.subtitle {
    font-size: 18px;
    line-height: 27px; }

.subtitle-2 {
    font-size: 18px;
    line-height: 32px; }

.body {
    font-size: 18px;
    line-height: 1.75rem; }
p,
.text,
.body-1 {
    font-size: 1rem;
    line-height: 1.75rem; }

small,
.text-sm,
.body-2 {
    font-size: 0.875rem;
    line-height: 1.5rem; }

.caption {}
.text-mini {
    font-size: .75rem; }

.is-text-bold {
    font-weight: $font-bold; }
.is-text-medium {
    font-weight: $font-medium; }
.is-text-regular {
    font-weight: $font-regular; }
.is-text-light {
    font-weight: $font-light; }

@mixin ellipsis($row) {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: $row;
    -webkit-box-orient: vertical;
    white-space: normal;
    -ms-text-overflow: ellipsis; }


@media screen and (min-width: 960px) {
    h1,
    .heading-1 {
        font-size: 2.625rem;
        line-height: 3.9rem; }

    h2,
    .heading-2 {
        font-size: 2.25rem;
        line-height: 54px; }

    h3,
    .heading-3 {
        font-size: 1.75rem;
        line-height: 42px; }

    h4,
    .heading-4 {
        font-size: 1.5rem;
        line-height: 36px; }

    h5,
    .heading-5 {
        font-size: 1.375rem;
        line-height: 33px; }

    h6,
    .heading-6 {
        font-size: 1.25rem;
        line-height: 30px; }

    .subtitle {
        font-size: 1.125rem;
        line-height: 27px; }

    .subtitle-2 {
        display: block; } }
