.fui-collapse-group {
    .fui-collapse {
        border-bottom: 1px solid $darkgray30;
        padding: 13px 0;

        &:first-child:last-child {
            border: 0; } }

    @media screen and (min-width: 960px) {
        .fui-collapse {
            padding: 18px 0; } } }

.fui-collapse-container {
    color: $darkgray50;
    background: $lightgray50;
    padding: 30px 15px;
    margin-top: 15px;

    .fui-collapse {
        .collapse-header {
            font-size: 1rem; }

        .collapse-body {
            * {
                font-size: 0.875rem; }

            article {
                padding-top: 0; } } } }

.form-group .fui-collapse.is-collapse-checkbox {
    label {
        position: relative;
        display: inline-block;
        margin: 0;

        &::after {
            margin-left: 0; } } }

.fui-collapse-paper {
    padding: 0;

    .fui-collapse {
        box-shadow: 0 6px 12px 0 rgba(75, 88, 105, 0.12);
        border: 0;
        background-color: #fff;
        margin-bottom: 12px;
        padding: 30px;

        .collapse-header {
            h6 {
                margin: 0; } }

        section.fui-section-collapse {
            background-color: $lightgray50;
            padding: 0;

            .fui-collapse {
                background: none;
                box-shadow: none; } } } }

.fui-collapse {
    margin: 0;

    &.with-trigger {
        border: 0;
        background: none;
        margin: 30px -30px 0;

        .collapse-body {
            box-sizing: content-box;
            padding: 0 ($space * 3.75);
            border: 0;
            box-shadow: none;
            font-size: 1rem !important;
            padding: 0 20px;

            h4 {
                font-weight: $font-regular;
                margin: ($space * 3) 0 ($space * 3); } }

        .collapse-trigger {
            background: $lightgray50;
            text-align: center;
            height: 52px;
            line-height: 52px;
            font-weight: $font-medium;
            cursor: pointer;

            * {
                vertical-align: middle; }

            [class*='icon-'] {
                font-size: 30px; } } }

    .has-date {
        position: relative;

        .collapse-header {
            order: 2;
            position: absolute;
            right: 0;
            top: 0;
            width: 50px;
            height: 100%; }

        .has-date-body {
            width: calc( 100% - 40px ); }

        // &+ .collapse-body
        //     overflow: visible
        @media screen and (max-width: 960px) {
            .collapse-header {
                order: 0; } } }

    .collapse-body {
        overflow: hidden;
        transition: all 0.3s 0.1s ease;

        a.fui-button {
            white-space: normal;

            .text {
                line-height: 1.75; }

 }            // text-align: left

        article {
            font-size: 16px; }

        ol {
            padding-left: 20px; } }

    &.is-collapse-checkbox {
        font-size: 0.875rem;
        margin: ($space * 3) 0;

        input {
            display: none; }

        .checkbox {
            margin: 0;

            &.is-agreement::before {
                margin-right: $space !important; }

            .content {
                font-size: 0.875rem;
                line-height: 1.5rem;
                display: inline-flex !important;
                align-items: center !important; } }

        .collapse-trigger {
            vertical-align: middle;
            display: inline-block;
            line-height: 1em;
            margin-top: -3px;

            // height: 30px
            margin-left: $space;
            font-weight: $font-medium;
            cursor: pointer;

            * {
                height: 1;
                line-height: 1;
                vertical-align: middle; }

            i[class*='icon'] {
                font-size: 24px; } }

        .collapse-body {
            overflow: hidden;
            transition: height 0.3s ease;

            > article {
                color: $darkgray50;
                padding: 24px 30px;
                background: #ebebeb;
                font-size: 16px;

                p {
                    font-size: 14px;
                    margin: 0; } } } }

    .collapse-header {
        position: relative;
        font-size: 1.125rem;
        display: flex;
        padding: 9px 30px 9px 0;
        margin: 0;

        .content {
            flex: 1 1 auto; }

        + .collapse-body {
            transition: all 0.3s 0.1s ease;
            overflow: hidden;
            height: 0;

            > * {
                padding: 15px 0; } }

        + .fui-menu {
            display: none;
            padding: 15px 0; }

        &::after,
        &::before {
            content: "";
            font-family: "icomoon" !important;
            speak: none;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            position: absolute;
            width: 24px;
            height: 24px;
            font-size: 24px;
            display: block;
            color: $black50;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto; }

        &::before {
            content: ""; }

        &::after {
            display: none;
            content: ""; } }

    &.is-open {
        > .mobile-collapse {
            display: block; }

        .has-date {
            margin-bottom: 36px;

            & + .collapse-body {
                overflow: visible; } }

        .collapse-header {
            margin-bottom: 16px;

            &::before {
                display: none; }

            &::after {
                display: block; } }

        .collapse-body {
            article {
                li {
                    line-break: anywhere; } } } }

    &.response-collapse.is-open .collapse-header {
        margin-bottom: 0; } }

.fui-read-more-info {
    .append-content {
        transition: all 0.5s;
        transform: rotateX(90deg);
        transform-origin: top;
        height: 0;

        &.appended {
            transform: rotateX(0deg);
            height: auto; } } }

@media screen and (min-width: 960px) {
    .fui-collapse.is-collapse-checkbox {
        margin: ($space * 4) 0 12px;

        .collapse-body {
            margin-top: $space * 3; } }

    .fui-collapse.response-collapse .collapse-header {
        margin-top: 0;
        margin-bottom: 0 !important;

        // margin-bottom: $space*3
        font-size: 1.25rem;

        &::before,
        &::after {
            display: none; }

        + .fui-menu {
            display: block !important; } }

    .fui-collapse-container {
        padding: 30px;
        margin-top: 30px; } }

@media screen and (max-width: 960px) {
    .fui-collapse-paper {
        margin-left: 0;
        margin-right: 0; }

    .fui-collapse.with-trigger {
        margin: 30px -20px 0; } }
