.fui-tip {
    display: block;
    padding: $space * 3;
    background: #FFFFFF;
    border: 1px solid #E8ECF0;
    margin: 12px 0;
    box-shadow: 0 12px 18px 0 #{"rgba(75, 88, 105, 0.06)"};
    color: $black50;
    letter-spacing: 0.2px;
    line-height: 28px;

    + .fui-tip {
        margin-top: 6px; }

    * {
        font-size: 0.875rem; }

    .fui-tip-title {
        font-weight: $font-medium;
        padding-left: $space * 5;
        padding-right: $space * 5;

        &::before {
            content: "";
            float: left;
            display: block;
            margin-left: -$space * 5;
            margin-top: $space / 2;
            width: 28px;
            height: 24px;
            background: url(#{$publicURL}/resources/common/images/tip-icon-blue.png) no-repeat;
            background-size: contain; } }

    .fui-tip-body {
        display: block;

        * {
            font-weight: $font-regular;
            padding-top: $space * 2; } }

    &.is-collapse {
        .fui-tip-title::after {
            content: "";
            float: right;
            font-size: 20px;
            margin-right: -40px;
            font-family: "icomoon" !important;
            speak: none;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale; }

        &.is-open .fui-tip-title::after {
            content: ""; }

        .fui-tip-body {
            transition: all 0.3s ease;
            overflow: hidden; } } }
