.performance {
    padding-top: 62px;
    background-image: url(#{$publicURL}/resources/ebu/images/lightgray-website@2x.jpg);
    background-size: contain;

    .periodical {
        color: $gray100 !important; }

    .article-img {
        width: 216px; }

    .article-content {
        h4 {
            margin: 12px 0 6px 0; }

        p {
            height: 60px;
            margin: 0; } }

    .video-container {
        margin-top: -40px; }

    .fui-container .fui-cards.is-video {
        overflow-x: auto;
        padding: 30px 15px; } }

.video-container {
    padding: 0;

    .video-head {
        button {
            display: none; } } }

.performance-mb-ad {
    display: none; }

@media screen and (max-width: 960px) {
    .hide-mb {
        display: none; }

    .performance {
        .load-more {
 }            // display: none

        .hide-mb {
            display: block; }

        .selected-article {
            margin: 0 -15px; } }

    .video-container {
        margin: 0 0 -24px 0;
        padding: 0;

        .load-more {
            display: none; }

        .video-head {
            margin-bottom: 20px;
            display: flex;
            align-content: center;
            justify-content: space-between;

            h2 {
                margin: 0; }

            button {
                padding: 0;
                text-align: right;
                margin: 0;
                cursor: pointer;
                display: none;

                i {
                    font-size: 24px; } } } } }

@media screen and (max-width: 640px) {
    .performance {
        background-image: none;
        padding-top: 52px;

        .article-img {
            width: 120px;
            min-width: initial; } }

    .performance-mb-ad {
        display: none;
        margin-top: -15px;

        img {
            width: 100%; } } }
