// .fui-modal-content
//     height: auto
//     max-height: 560px
//     width: 90%
//     max-width: 600px
//     margin: 70px auto 0

//     > div
//         padding: 30px 0
//         background: $white

//     .fui-modal-foot
//         text-align: center
//         padding: 30px 0

//         &.two-buttons
//             .fui-button
//                 margin: 0 6px

//         .fui-button
//             margin: 0

//     .fui-modal-body
//         padding: 12px 30px 0
//         max-height: 390px
//         overflow-x: hidden
//         overflow-y: auto

//     .fui-modal-head
//         padding-bottom: 0
//         text-align: center
//         display: inline-block
//         width: 100%

//         h5
//             margin: 0 0 ($space / 2)

.fui-number-selector-container {
    display: inline-block;

    + .fui-button {
        margin-left: $space * 1.5; }

    .fui-number-selector {
        display: inline-block;

        > * {
            display: inline-block;
            width: 50px;
            font-size: 1.25rem;
            text-align: center;
            vertical-align: top;
            border-top: 2px solid $lightgray100;
            border-bottom: 2px solid $lightgray100;

            input {
                border: 0;
                font-size: 1rem;
                width: 50px;
                height: 46px;
                line-height: 46px;
                text-align: center;
                -webkit-appearance: none; } }

        [role='button'] {
            border: 2px solid $lightgray100;
            height: 50px;
            line-height: 50px;

            &.disabled i {
                opacity: 0.25; } } }

    .is-text-error {
        padding: 6px 0;
        font-size: 0.875rem; } }

.fui-product-section,
section.fui-product-section {
    padding-top: 30px;
    background-image: url(#{$publicURL}/resources/cbu/img/bg-cbu-fcoin-pattern-m@2x.png);
    background-repeat: no-repeat;
    background-size: 100% auto !important;
    h4 {
        font-weight: 500;
        margin-bottom: 30px;
        margin-top: 0;
        @media screen and (max-width: 960px) {
            margin: 30px 0 36px; } }
    .product-spec {
        width: 100%;
        margin-bottom: 24px; }


    .slick-slider.fui-product-slider {
        .slick-arrow {
            cursor: pointer;
            position: absolute;
            width: 32px;
            height: 32px;
            top: 0;
            bottom: 0;
            margin: auto;
            color: transparent;
            border: 0;
            background: none;
            z-index: 9;

            &.slick-disabled {
                opacity: 0.25; }

            &::after {
                position: absolute;
                font-family: "icomoon" !important;
                speak: none;
                color: $black50;
                width: 40px;
                height: 40px;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
                font-size: 32px;
                font-style: normal;
                font-weight: normal;
                font-variant: normal;
                text-transform: none;
                line-height: 1; }

            &.slick-prev {
                left: 10%;

                &::after {
                    content: ""; } }

            &.slick-next {
                right: 10%;

                &::after {
                    content: ""; } } }

        ul.slick-dots {
            display: none!important;
            text-align: center;
            list-style: none;
            margin: ($space * 4) 0 0;
            padding: 0;

            li {
                position: relative;
                display: inline-block;
                width: 36px;
                height: 4px;
                vertical-align: top;
                background: $black50;
                opacity: 0.5;
                margin: 0 3px;

                &.slick-active {
                    opacity: 1; }

                button {
                    opacity: 0;
                    position: absolute;
                    width: 100%;
                    cursor: pointer;
                    height: 100%;
                    left: 0;
                    top: 0; } } }

        .slick-list .slick-track {
            .slick-slide {
                text-align: center;

                img {
                    display: block;
                    width: 100%;
                    max-width: 180px;
                    height: auto;
                    margin: 0 auto; } } } }

    .price-section {
        margin-bottom: 18px;

        h4 {
            margin: 3px 0 ($space * 1.5);
            color: $error;

            del {
                color: $gray100; } } }

    .form-group {
        margin-bottom: 30px;

        label {
            font-size: 16px;
            margin-bottom: 0; } }

    .fui-discount-bar {
        display: flex;
        font-size: 0.875rem;

        > a.fui-discount-link:link,
        > a.fui-discount-link:visited {
            justify-content: space-between;
            display: flex;
            color: $darkgreen;
            align-content: center;
            align-items: center;
            width: 100%;

            &::after {
                display: none !important; }

            > span {
                display: flex;
                align-content: center;
                align-items: center; }

            > span.content {
                display: block;
                flex: 1 1 auto; }

            i[class*='icon-'] {
                color: $black50;
                font-size: 1.5rem; } }

        .discount-ribbon {
            position: relative;
            width: 64px;
            line-height: 28px;
            height: 28px;
            color: $white;
            background: #95a3a4;
            padding-left: 6px;
            margin-right: 20px;
            display: inline-block;

            &::before,
            &::after {
                content: "";
                position: absolute;
                right: -14px;
                width: 0;
                height: 0; }

            &::before {
                top: 0;
                border-top: 7px solid transparent;
                border-right: 7px solid transparent;
                border-bottom: 7px solid #95a3a4;
                border-left: 7px solid #95a3a4; }

            &::after {
                bottom: 0;
                border-top: 7px solid #95a3a4;
                border-left: 7px solid #95a3a4;
                border-right: 7px solid transparent;
                border-bottom: 7px solid transparent; } } }

    .fui-notice {
        margin-top: $space * 1.5;

        .fui-item {
            padding: 4px 0;
            font-size: 0.875rem;

            .more,
            a:not(.sub-link-title):not(.fui-button):link,
            a:not(.sub-link-title):not(.fui-button):visited {
                position: relative;
                font-weight: $font-medium;
                color: $black50;
                margin-left: 6px;

                &::after {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 2px;
                    background: $black50 !important;
                    bottom: -2px;
                    left: 0; } } } }

    .radio-group {
        margin: 0 -6px;

        .radio.is-icon-box {
            width: calc(50% - 12px);
            margin: 6px 6px 0;

            @media screen and (max-width: 768px) {
                .content::before {
                    left: 6px;
                    bottom: auto;
                    top: 6px;
                    width: 24px;
                    height: 24px; }

                .content::after {
                    left: 14px;
                    top: 14px;
                    bottom: auto; }

                input:checked + .content:after {
                    width: 8px;
                    height: 8px; } } } }

    .fui-color-selector-container .fui-color-selector-items {
        margin: 0.875rem 0 ($space * 2.875);

        label.fui-color-selector-item {
            position: relative;
            display: inline-block;
            vertical-align: top;

            input {
                position: absolute;
                left: 0;
                top: 0;
                opacity: 0;

                &:checked + .color-item {
                    box-shadow: 0 0 0 2px $error; } } }

        .color-item {
            display: block;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            border: solid 4px $white;
            box-shadow: 0 0 0 2px rgba(47, 89, 196, 0.2);
            margin-right: $space * 3.75; } }

    @media screen and (max-width: 768px) {
        .slick-initialized .slick-slide {
            height: 100%; }
        .subtitle.down-more {
            font-size: 1rem; } }

    @media screen and (min-width: 960px) {
        padding-top: 90px;
        background-image: url(#{$publicURL}/resources/cbu/img/bg-cbu-fcoin-pattern-1920-x-1200@2x.png);
        .product-spec {
            width: 230px;
            margin-bottom: 56px; }
        .page-action {
            a.fui-button {
                width: 230px; } }
        h2 {
            margin-bottom: 24px; }
        .fui-tag {
            margin-right: 6px;
            font-size: 0.875rem; }

        .MuiGrid-root.MuiGrid-container {
            width: calc(100% + 60px);
            margin: 0 -30px;

            .MuiGrid-root.MuiGrid-item {
                padding: 0 30px; } }

        .slick-slider.fui-product-slider {
            ul.slick-dots {
                display: block!important;
                li {
                    width: 50px; } }

            .slick-list .slick-track {
                .slick-slide {
                    img {
                        max-width: 360px; } } } } } }
