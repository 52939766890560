.receipt-loader {
  border: 2px solid $lightgray100;
  margin: 30px 0;
  &--header {
    border-bottom: 2px solid $lightgray100;
    background-color: $lightgray70;
    padding: 12px 30px;
    width: 100%;
    h4 {
      margin: 0; } }
  &--body {
    padding: 30px;
    p {
      margin: 0; } } }
