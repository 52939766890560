.control-list {
  border: 2px solid #EFF1F2;
  border-bottom: none;
  display: flex;
  padding: 12px 16px;
  padding-bottom: 0;

  &:last-child {
    border-bottom: 2px solid #EFF1F2; }

  .content {
    display: flex;
    flex-direction: column; }

  .title-container {
    display: flex;
    align-items: center;
    margin-right: $space*3; }

  .title {
    color: #202020;
    font-family: 'Noto Sans TC';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 178%;
    margin-right: 15px;
    @media screen and (min-width: 600px) {
      font-size: 22px;
      line-height: 150%; } }

  .desc {
    color: #5F6E84;
    font-family: 'Noto Sans TC';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    @media screen and (min-width: 600px) {
      font-weight: 500;
      font-size: 16px;
      line-height: 175%; } }

  .edit-icon {
    cursor: pointer; }

  .divider {
    border-top: 1px solid #EFF1F2; }

  .switch-container {
    margin-left: auto;
    .fui-switch {
      input {
        + div.content span.on {
          color: $black50 !important; }
        + div.content span.off {
          color: $error !important; } }
      input:checked {
        + div.content span.on {
          color: $error !important; }
        + div.content span.off {
          color: $black50 !important; } } } }

  &.edit {
    padding: 12px 16px;
    padding-bottom: 0;
    .title {
      font-size: 20px;
      line-height: 150%; }
    .desc {
      color: #4F5E72;
      font-weight: 400;
      font-size: 12px;
      line-height: 175%; } }

  @media screen and (min-width: 600px) {
    padding: 16px 32px;

    &.edit {
      padding: 12px 32px;
      .title {
        font-size: 24px;
        line-height: 150%; }
      .desc {
        font-weight: 500;
        font-size: 16px;
        line-height: 175%; } } } }
