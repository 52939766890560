.fui-breadcrumb-container + .news-wrapper {
    padding-top: 100px; }

.ebu-news.pt-90 {
    padding-top: 90px; }

.news-wrapper {
    .fui-cards .fui-card .fui-card-content .fui-card-title {
        .text {
            font-size: 24px; } } }

.ebu-news {
    position: relative;

    .fui-cards .fui-card .fui-card-content .fui-card-title {
        -webkit-line-clamp: 3; }

    .quote-content {
        @include ellipsis(5); }

    .social {
        text-align: left;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        width: 100%;
        margin: (-10px) 42px 36px 42px;
        justify-content: flex-start;

        .view {
            color: $gray100;
            display: flex;
            align-items: center; }

        i {
            font-size: 36px;
            margin-right: 6px; }

        a {
            color: #000; } }

    hr {
        border-width: 2px; }

    .link {
        margin: 0px 42px 50px 42px;
        display: flex;
        align-items: center;

        .icon-business-customer-center {
            font-size: 36px;
            margin-right: 10px; }

        i {
            display: inline-block;
            font-size: 24px; }

        p {
            display: inline-block;
            margin: 0 6px 0 0 !important; } }

    .quote {
        position: relative;
        margin-top: 20px;

        .comma {
            font-family: "PingFangTC-Medium";
            font-size: 60px;
            color: #5e748e;
            left: 0;
            top: 0;
            color: $accent;
            padding: 0;
            position: absolute;

 }            // margin: -20px 0 0 0

        .comma:nth-last-child(1) {
            right: 0px;
            bottom: 0px;
            left: initial;
            top: initial; }

        h4 {
            padding: 20px 100px 40px 30px;
            position: relative; } }

    .news-list {
        margin-top: 0; }

    .news-wrapper {
        padding-bottom: 40px;
        padding-top: 100px;

        h2 {
            margin: 0 0 30px 0;

            @include ellipsis(2); }

        h3 {
            color: $business50;
            margin: 0 42px 24px 42px; }

        p {
            margin: 0 42px 42px 42px; }

        p:nth-last-child(1) {}

        img {
            padding: 0 0px 0px 0px;
            width: 100%;
            margin-bottom: 24px; } }

    .list-wrapper {
        border-bottom: 1px solid $lightgray100;
        display: flex;
        align-items: center;
        padding: 15px 0;
        margin: 0;
        cursor: pointer;

        p, h5 {
            margin: 0; }

        p {
            color: $darkgray50; }

        .date {
            color: $gray100;
            display: inline-block;
            margin-right: 48px; }

        &:hover {
            h5 {
                color: $business50; } }

        a {
            color: $black50; } }

    .list-title {
        margin-bottom: 16px;

        small {
            display: inline-block;
            color: $darkgray50; }

        p {
            font-size: 16px;
            font-weight: 500;
            line-height: 1.75;
            letter-spacing: 0.2px;
            color: $darkgray50;
            padding-right: 12px; } }

    .list-wrapper:nth-last-child(1) {
        border-bottom: 0;
        padding-bottom: 30px;

        a {
            color: $black50;
            font-weight: 500; } }

    .list-container::after {
        content: "";
        width: 100%;
        height: 176px;
        background-image: radial-gradient(circle at 50% -158%, #E8ECF0, rgba(250, 250, 250, 0) 70%);
        display: flex;
        position: absolute;
        pointer-events: none; }

    .news-filter {
        margin-bottom: 34px;
        display: flex; }

    .fui-dropdown {
        display: inline-block;
        background-color: #fff;
        padding: 12px 5px;
        border: 2px solid $lightgray100;
        width: 200px;

        button {
            display: flex;
            align-items: center;
            width: 100%;
            justify-content: space-between;

            span, i {
                color: $black50;
                font-weight: 500; } } }

    .filter-list {
        margin-right: 42px;
        margin-bottom: 12px; }

    .fui-nav-anchor {
        background-color: #fff; }

    &::after {
        content: "";
        background-image: url(#{$publicURL}/resources/ebu/images/news-banner.png);
        background-size: cover;
        width: 100%;
        background-repeat: no-repeat;
        position: absolute;
        top: 0;
        left: 0;
        height: 40vw;
        z-index: -1; }

    .mb-date {
        display: none;
        line-height: 1.75;
        letter-spacing: 0.2px;
        color: $gray100; }

    .fui-card-date {
        font-weight: 500; }

    .read-more {
        padding-top: 30px;
        margin-bottom: $space * 2;
        border-top: 1px solid $lightgray100;
        margin-left: 42px;

        &:not(.two-buttons) button {
            margin-right: 12px;
            width: 100%; }

        button:nth-last-child(1) {
            margin-bottom: 0; } } }

.news-content {
    &::after {
        content: "";
        background-image: url(#{$publicURL}/resources/ebu/images/news-banner.png);
        background-size: cover;
        width: 100%;
        background-repeat: no-repeat;
        position: absolute;
        top: 0;
        left: 0;
        height: 40vw;
        z-index: -1; } }

.fui-nav-anchor.is-fixed + .fui-breadcrumb-container + .news-wrapper {
    padding-top: 165px; }

@media screen and (max-width: 1200px) {
    .ebu-news {
        .fui-card {
            max-width: none; } } }

@media screen and (max-width: 960px) {
    .fui-breadcrumb-container + .news-wrapper {
        padding-top: 60px; }

    .fui-nav-anchor.is-fixed + .fui-breadcrumb-container + .news-wrapper {
        padding-top: 105px; }

    .ebu-news.news-content {
        .news-wrapper {
            padding-bottom: 0; } }

    .ebu-news {
        .news-list {
            margin-top: 0; }

        .filter-list {
            margin-right: 12px;
            display: inline-block;

            button {
                padding: 0; } }

        .news-wrapper {
            img {
                padding: 0; }

            p, h3 {
                margin-left: 0;
                margin-right: 0; }

            .quote {
                h4 {
                    padding: 20px 0 40px 0; } } }

        .mb-date {
            display: block;
            margin-left: 12px; }

        .list-wrapper:nth-last-child(1) {
            border-bottom: 0;
            padding-bottom: 48px !important; }

        .read-more {
            margin-bottom: 72px; }

        .news-filter {
            margin-bottom: 3px;
            display: flex; }

        .list-title {
            margin-bottom: 6px; }

        .link {
            margin: 0 0 50px 0; }

        .social {
            margin-left: 0;
            margin-bottom: 30px; }

        &::after {
            display: none;

            a {
                min-width: initial !important;
                width: 100%; } }

        .two-buttons {
            a:nth-last-child(1) {
                margin-right: 0; } } }

    .ebu-news.pt-90 {
        padding-top: 0; }

    .news-content {
        .read-more {
            margin-left: 0; } } }

@media screen and (max-width: 768px) {
    .ebu-news {
        .filter-list {
            margin-bottom: 12px;
            width: 100%;

            .fui-dropdown {
                width: 100%; }

            &:last-child {
                margin-right: 0; } }

        .read-more {
            margin-left: 0;
            display: flex;
            justify-content: center;

            a {
                width: 100%;
                min-width: initial !important; } }

        .list-wrapper {
            p {
                display: inline-block; }

            .mb-date {
                margin-left: 12px; } }

        .news-filter {
            display: flex; }

        .list-container::after {
            width: calc(100% - 30px);
            height: 120px;
            background-image: radial-gradient(circle at 50% -158%, #E8ECF0, rgba(250, 250, 250, 0) 90%); } } }
