
.m-auto {
    margin: auto; }

.my-auto {
    margin-top: auto;
    margin-bottom: auto; }

.mx-auto {
    margin-left: auto;
    margin-right: auto; }

@for $i from 0 through 10 {
    // Margin
    .m-#{$i} {
        margin: $space*$i !important; }
    .my-#{$i} {
        margin-top: $space*$i !important;
        margin-bottom: $space*$i !important; }
    .mx-#{$i} {
        margin-left: $space*$i !important;
        margin-right: $space*$i !important; }
    .mt-#{$i} {
        margin-top: $space*$i !important; }
    .ml-#{$i} {
        margin-left: $space*$i !important; }
    .mr-#{$i} {
        margin-right: $space*$i !important; }
    .mb-#{$i} {
        margin-bottom: $space*$i !important; }

    // Padding
    .p-#{$i} {
        padding: $space*$i !important; }
    .py-#{$i} {
        padding-top: $space*$i !important;
        padding-bottom: $space*$i !important; }
    .px-#{$i} {
        padding-left: $space*$i !important;
        padding-right: $space*$i !important; }
    .pt-#{$i} {
        padding-top: $space*$i !important; }
    .pl-#{$i} {
        padding-left: $space*$i !important; }
    .pr-#{$i} {
        padding-right: $space*$i !important; }
    .pb-#{$i} {
        padding-bottom: $space*$i !important; } }

@media screen and (min-width: 768px) {
    @for $i from 0 through 10 {
        // Margin
        .m-md-#{$i} {
            margin: $space*$i !important; }
        .my-md-#{$i} {
            margin-top: $space*$i !important;
            margin-bottom: $space*$i !important; }
        .mx-md-#{$i} {
            margin-left: $space*$i !important;
            margin-right: $space*$i !important; }
        .mt-md-#{$i} {
            margin-top: $space*$i !important; }
        .ml-md-#{$i} {
            margin-left: $space*$i !important; }
        .mr-md-#{$i} {
            margin-right: $space*$i !important; }
        .mb-md-#{$i} {
            margin-bottom: $space*$i !important; }

        // Padding
        .p-md-#{$i} {
            padding: $space*$i !important; }
        .py-md-#{$i} {
            padding-top: $space*$i !important;
            padding-bottom: $space*$i !important; }
        .px-md-#{$i} {
            padding-left: $space*$i !important;
            padding-right: $space*$i !important; }
        .pt-md-#{$i} {
            padding-top: $space*$i !important; }
        .pl-md-#{$i} {
            padding-left: $space*$i !important; }
        .pr-md-#{$i} {
            padding-right: $space*$i !important; }
        .pb-md-#{$i} {
            padding-bottom: $space*$i !important; } } }

@media screen and (min-width: 960px) {
    @for $i from 0 through 10 {
        // Margin
        .m-lg-#{$i} {
            margin: $space*$i !important; }
        .my-lg-#{$i} {
            margin-top: $space*$i !important;
            margin-bottom: $space*$i !important; }
        .mx-lg-#{$i} {
            margin-left: $space*$i !important;
            margin-right: $space*$i !important; }
        .mt-lg-#{$i} {
            margin-top: $space*$i !important; }
        .ml-lg-#{$i} {
            margin-left: $space*$i !important; }
        .mr-lg-#{$i} {
            margin-right: $space*$i !important; }
        .mb-lg-#{$i} {
            margin-bottom: $space*$i !important; }

        // Padding
        .p-lg-#{$i} {
            padding: $space*$i !important; }
        .py-lg-#{$i} {
            padding-top: $space*$i !important;
            padding-bottom: $space*$i !important; }
        .px-lg-#{$i} {
            padding-left: $space*$i !important;
            padding-right: $space*$i !important; }
        .pt-lg-#{$i} {
            padding-top: $space*$i !important; }
        .pl-lg-#{$i} {
            padding-left: $space*$i !important; }
        .pr-lg-#{$i} {
            padding-right: $space*$i !important; }
        .pb-lg-#{$i} {
            padding-bottom: $space*$i !important; } } }

@media screen and (min-width: 1200px) {
    @for $i from 0 through 10 {
        // Margin
        .m-xl-#{$i} {
            margin: $space*$i !important; }
        .my-xl-#{$i} {
            margin-top: $space*$i !important;
            margin-bottom: $space*$i !important; }
        .mx-xl-#{$i} {
            margin-left: $space*$i !important;
            margin-right: $space*$i !important; }
        .mt-xl-#{$i} {
            margin-top: $space*$i !important; }
        .ml-xl-#{$i} {
            margin-left: $space*$i !important; }
        .mr-xl-#{$i} {
            margin-right: $space*$i !important; }
        .mb-xl-#{$i} {
            margin-bottom: $space*$i !important; }

        // Padding
        .p-xl-#{$i} {
            padding: $space*$i !important; }
        .py-xl-#{$i} {
            padding-top: $space*$i !important;
            padding-bottom: $space*$i !important; }
        .px-xl-#{$i} {
            padding-left: $space*$i !important;
            padding-right: $space*$i !important; }
        .pt-xl-#{$i} {
            padding-top: $space*$i !important; }
        .pl-xl-#{$i} {
            padding-left: $space*$i !important; }
        .pr-xl-#{$i} {
            padding-right: $space*$i !important; }
        .pb-xl-#{$i} {
            padding-bottom: $space*$i !important; } } }
