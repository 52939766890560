.fui-container .promotion-container {
    margin: 0 -15px; }

.section-promotion-5 {
    position: relative;
    height: 100%;
    margin: 0;

    .desktop > *,
    .mobile > * {
        position: relative;
        z-index: 1; }

    > a:link,
    > a:visited {
        // height: 100%
        display: block;

        // background-color: #F1F3F3
        color: $black50 !important;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: top center;

        &::after {
            display: none !important; }

        &:hover {
            color: $black50 !important; } }

    .desktop {
        padding: 300px 24px 30px;
        position: relative;
        overflow: hidden;
        min-height: 700px;
        background-repeat: no-repeat;
        background-position: center top;
        background-size: contain;

        h3 {
            font-size: 22px;
            font-weight: 700;
            margin: 0 0 12px 0;
            line-height: 1.3;
            color: $black50; }

        a.fui-button.is-secondary {
            border: 2px solid #333333;
            padding: 0;
            margin: 30px 0 0; } }

    h3 {
        font-size: 22px; }

    h6 {
        margin: 0 0 12px 0;
        font-weight: normal;
        font-size: 18px;
        z-index: 2;
        position: relative; }

    .w-100 {
        width: 100%; }

    .mobile {
        height: 270px;
        padding: 30px 24px;

        .promotion-button {
            display: inline-block;
            min-width: 140px;
            color: $white;
            border-color: $white; } }

    .promotion-button {
        border: 2px solid $black50;
        color: $black50;
        width: 100%;
        height: 40px;
        line-height: 36px;
        text-align: center;
        margin: 16px 0 0; }

    button {
        z-index: 2; }

    @media screen and (max-width: 959px) {
        width: 100%;
        height: auto;
        color: $white;
        background-size: cover;

        .fui-button.is-secondary {
            border-color: $white;
            color: $white;

            &:hover {
                border-color: $white;
                background: $white;
                color: $darkgray100; } }

        &::after {
            background: $black50;
            opacity: 0.3; }

        h3 {
            margin-bottom: 15px;
            z-index: 2;
            position: relative; }

        h6 {
            margin: 0 0 30px 0;
            font-weight: normal;
            z-index: 2;
            position: relative; }

        .w-100 {
            width: 100%; }

        button {
            z-index: 2; } }

    .mobile {
        display: none; } }

@media screen and (max-width: 960px) {
    .section-promotion-5 {
        .desktop {
            display: none; }

        .mobile {
            display: block;
            background-size: cover;
            background-position: bottom center;
            position: relative;
            padding: 20px 30px;
            color: #fff;
            overflow: hidden;
            text-align: center;

            h3 {
                margin: 0 0 0 0;
                color: #fff;
                font-size: 20px; }

            h6 {
                font-weight: 500;
                font-size: 1rem;
                margin-bottom: 0;
                font-weight: $font-regular; }

            h4 {
                margin: 0 0 18px 0;
                position: relative; }

            .bg-img {
                filter: brightness(0.8); }

            img {
                width: 100%;
                height: auto;
                top: -100%;
                bottom: -100%;
                margin: auto; }

            button {
                position: relative;
                border: 0;
                margin: 0;
                border: 2px solid #fff;
                height: 40px;
                padding: 0;
                min-width: 45%;
                width: 100%; } } } }
