.fui-container .fui-marquee-box {
    margin-left: -15px;
    margin-right: -15px;
    width: calc(100% + 30px); }

.fui-marquee-box {
    width: 100%;
    height: 150px;
    position: relative;
    top: 0;
    left: 0;
    overflow: hidden; }

.fui-marquee-box .marquee-landscape-wrap {
    position: absolute;
    left: 0;
    top: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center; }

.fui-marquee-box .marquee-landscape-wrap.no-marquee > .marquee-landscape-item {
    margin: auto;
    float: none; }

.fui-marquee-box .marquee-landscape-wrap .marquee-landscape-item {
    float: left;
    height: 100%;
    white-space: nowrap; }

.fui-marquee-box .marquee-landscape-wrap .marquee-landscape-item .marquee-landscape-txt {
    padding: 0 10px;
    white-space: nowrap;
    display: inline-flex;
    height: 100%;
    align-content: center;
    align-items: center;

    img {
        vertical-align: middle;
        height: 120px;
        opacity: 0.5; } }

.fui-marquee-box .marquee-vertical-wrap {
    width: 100%;
    height: 100%;
    overflow: hidden; }

.fui-marquee-box .marquee-vertical-wrap .marquee-vertical-item .marquee-vertical-txt {
    height: 60px;
    line-height: 60px; }

@media screen and (min-width: 768px) {
    .fui-marquee-box .marquee-landscape-wrap .marquee-landscape-item {
        float: none;
        margin: auto;

        + .marquee-landscape-item {
            display: none; } } }
