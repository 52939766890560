.is-eservice {
    section.fui-login-section {
        background: url(#{$publicURL}/resources/cbu/e-service/images/img-cbu-arrow-pattern.png) no-repeat top 0px left 0px;
        background-size: 49px 70px;
        padding-top: 60px;

        h2 {
            margin-top: 0; }

        .tooltip-container-box {
            position: relative; }

        .tooltip-container {
            right: 0;
            position: absolute; }

        form button.fui-button {
            width: 100%; }

        .fui-segments .fui-segment {
            background: none;
            padding: 0px;

            .MuiGrid-root.MuiGrid-container {
                margin-top: 0;
                margin-bottom: 0;

                .MuiGrid-root.MuiGrid-item {
                    padding-top: 0;
                    padding-bottom: 0;

                    &:not(:first-child) {
                        line-height: 0; } } }

            form {
                padding: ($space * 3) 20px; } }

        .promotion {
            position: relative;
            line-height: 0;

            img {
                width: 100%;
                line-height: 0; }

            .content {
                position: absolute;
                width: 100%;
                height: 100%;
                padding: 30px;
                left: 0;
                top: 0;

                .fui-button {
                    width: 100%; } } }

        .tooltip-container {
            + .form-group {
                display: block;

                .text-input {
                    width: 100%; } } }

        .fui-tab-pane {
            padding-top: 10px; } }

    @media screen and (max-width: 768px) {
        section.fui-login-section ~ .fui-tooltip {
            right: 16px !important;
            left: auto !important;
            margin: auto !important;

            &::before {
                left: auto;
                right: $space * 3.5; } } }

    @media screen and (min-width: 960px) {
        section.fui-login-section {
            background: url(#{$publicURL}/resources/cbu/e-service/images/img-cbu-arrow-pattern.png) no-repeat top 100px left 0px;
            background-size: 101px 144px;
            padding-top: 115px;

            .fui-segments .fui-segment {
                padding: 60px;

                form {
                    padding: 0;

                    button.fui-button {
                        width: auto; } } }

            .promotion .content {
                padding: 60px;

                .fui-button {
                    width: auto; } } } } }
