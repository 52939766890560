input, textarea {
    border-radius: 0; }

.keyword-input-container {
    position: relative;
    z-index: 2;
    box-shadow: 0 2px 6px 0 rgba(75, 88, 105, 0.2);

    &:hover,
    &:focus {
        box-shadow: 0 6px 12px 0 rgba(75, 88, 105, 0.24); }

    .fui-input-group.is-keyword,
    .fui-input-group.is-keyword:hover,
    .fui-input-group.is-keyword:focus {
        box-shadow: none; }

    + .keyword-container {
        z-index: 0; } }

.fui-input-group {
    position: relative;
    display: flex;
    box-shadow: 0 18px 36px 0 #{"rgba(75, 88, 105, 0.12)"};
    z-index: 2;

    .error-message {
        text-align: left;
        color: $red30;
        font-weight: 500;
        position: absolute;
        bottom: -32px;
        left: 0; }

    .fui-input {
        flex: 1 1 auto;

        input {
            display: block;
            width: 100%;
            height: 50px;
            border: 0;
            font-size: 1rem;
            border: 0;
            -webkit-appearance: none;
            box-shadow: none;
            padding: ($space * 1.5) ($space * 2.1);

            &::placeholder {
                color: $gray70; } } }

    &.is-keyword {
        position: relative;
        z-index: 9;
        box-shadow: 0 2px 6px 0 #{"rgba(75, 88, 105, 0.24)"};
        transition: all 0.3s ease;

        &:hover,
        &:focus,
        &.is-focus {
            box-shadow: 0 6px 12px 0 #{"rgba(75, 88, 105, 0.24)"}; }

        .fui-input {
            position: relative;

            input {
                height: 40px;
                border: solid 2px $white;
                padding-right: 120px;

                // &:focus
 }                //     border: solid 2px $grayblue

            .reset {
                position: absolute;
                right: 81px;
                top: 0;
                bottom: 0;
                width: 32px;
                height: 24px;
                margin: auto;
                color: $black50;

                i {
                    font-size: 24px;
                    margin-top: 2px; } } }

        .fui-action {
            position: absolute;
            right: 0;
            top: 0;
            width: 81px;

            .fui-button.is-primary {
                width: 100%;
                height: 41px;

                .text {
                    line-height: 41px; } } }


        + .fui-dropdown {
            position: absolute;
            width: 100%;
            top: 100%;
            background: $white;
            box-shadow: 0 12px 15px 0 #{"rgba(32, 32, 32, 0.3)"};
            z-index: 9;
            display: block;
            padding: 14px 0;
            overflow-x: hidden;
            overflow-y: auto;
            max-height: 294px;

            .fui-item {
                padding-left: $space * 1.5;
                padding-right: $space * 1.5;

                &.is-selected {
                    background: $lightgray100; }

                .prefix {
                    margin-right: 6px;

                    i {
                        opacity: 0.5;
                        font-size: 24px;
                        color: $black50; } }

                .highlight {
                    color: $accent; } } } }

    .subscribe {
        position: absolute;
        width: 100%;

        .email-pending {
            width: 105px;
            min-width: 0px;
            position: absolute;
            right: 0;
            height: 50px; }

        .email-done {} }

    .fui-action {
        .fui-button.is-primary {
            margin: 0 !important;
            min-width: 80px;
            width: 80px;
            height: 50px;
            white-space: nowrap;

            &::after {
                display: none; } }

        &.only-icon {
            width: auto;
            .fui-button {
                width: auto;
                min-width: auto; } } } }


.fui-input-group.no-shadow {
    box-shadow: none;

    @media screen and (min-width: 769px) {
        .fui-action {
            .fui-button.is-primary {
                height: 50px;
                width: 105px; } } } }

@media screen and (min-width: 960px) {
    .fui-input-group.is-keyword {
        .fui-input {
            input {
                height: 50px; }

            .reset {
                right: 105px; } }

        .fui-action {
            width: 105px;

            .fui-button.is-primary {
                height: 50px;
                width: 105px;

                .text {
                    height: 50px;
                    line-height: 50px; } }

            &.only-icon {
                width: auto;
                .fui-button {
                    width: auto; } } } } }


input {
    -webkit-appearance: none; }

input:focus,
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    background: #FFF !important;
    color: $black50 !important;
    box-shadow: 0 0 0 1000px white inset;
    -webkit-box-shadow: 0 0 0 1000px white inset !important;
    -webkit-text-fill-color: #333 !important; }



.fui-input-group.is-keyword + .fui-dropdown {
    .fui-item {
        cursor: pointer;
        &:hover,
        &:focus {
            color: #202020 !important; } } }
