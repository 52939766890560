.captcha {
  .form-group {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    width: 100%;
    input {
      min-width: auto;
      margin-right: 6px; }
    .captcha-number {
      // margin-right: 6px
      margin-top: 6px; }
    .captcha-box {
      display: flex;
      align-items: flex-start;
      flex-direction: row; }
    .captcha-reload {
      border: 0; }
    .form-control {
      // max-width: 286px
      margin-right: 6px;
      flex-direction: column;
      width: calc( 100% - 207px );
      .text-input {
        min-width: auto;
        width: 100%; } }
    button {
      background-color: transparent;
      img {
        height: 36px;
        margin-top: 6px; } } }
  @media screen and (max-width: 960px) {
    .form-group {
      .form-control {} } }
  @media screen and (max-width: 480px) {
    .form-group {
      flex-flow: column wrap;
      .form-control {
        order: 1;
        margin-top: 12px;
        max-width: 100%;
        width: 100%; }
      .captcha-box {
        order: 0; } } } }
