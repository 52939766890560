.newsletter-check {
    margin-bottom: 0;
    .header {
        display: flex;
        justify-content: space-between;
        input {
            width: 30px;
            height: 30px;
            min-width: auto;
            &:checked {
                border-color: $error;
                color: $error; }
            &::before {
                content: '';
                transition: all 0.3s ease; }
            &:checked::before {
                background: $error;
                opacity: 1;
                border-color: $error; }
            &:checked::after {
                content: "";
                font-family: "icomoon" !important;
                speak: none;
                font-style: normal;
                font-weight: normal;
                font-feature-settings: normal;
                font-variant: normal;
                text-transform: none;
                line-height: 1;
                font-size: 1rem;
                display: block;
                width: 10px;
                height: 10px;
                color: #FFFFFF;
                top: 8px;
                left: 8px;
                opacity: 0;
                transform: scale(0);
                transform-origin: 50%;
                transition: all 0.3s ease; } }
        p {
            margin-left: 8px; }
        .checkbox-group {
            align-items: center; } }
    .checkbox-group {
        &.disabled {
            .checkbox {
                opacity: .7; } }
        .checkbox {
            width: calc( 50% - 6px );
            margin: 0 12px 12px 0;
            .content {
                width: 100%;
                padding: 62px 30px 30px;
                display: inline-flex;
                align-items: flex-start;
                color: $black50;
                img {
                    width: 206px;
                    min-width: 206px;
                    display: inline-block;
                    margin-right: 18px; }
                h5 {
                    margin: 0 0 6px; }
                .d-inline-block {
                    text-align: left;
                    a {
                        color: $black50;
                        text-decoration: underline; }
                    p {
                        margin: 10px 0 18px;
                        line-height: 1.5; } } }
            input:checked + .content {
                color: $black50; }
            &:nth-of-type(2n) {
                margin-right: 0; }
            &:nth-of-type(3n) {
                margin: 0 12px 12px 0; } } }
    @media screen and (max-width: 960px) {
        .header {
            .content {
                width: 70px;
                margin-left: 40px; }
            .checkbox {
                margin-top: 0; } }
        .checkbox-group {
            .checkbox {
                width: 100%;
                margin-right: 0;
                .content {
                    display: block;
                    h5 {
                        margin-top: 12px; }
                    img {
                        width: 100%;
                        max-width: 480px; } } }
            .checkbox input + .content::before {
                bottom: auto;
                top: 12px;
                left: 12px; }
            .checkbox input + .content::after {
                bottom: auto;
                top: 20px;
                left: 18px; } } } }
