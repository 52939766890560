.done-manage-newsletter {
    text-align: center;
    margin-top: 196px;

    img {
        margin-bottom: 24px;
        width: 220px; }

    h4 {
        margin: 0 0 12px 0; }

    p {
        margin: 0 0 24px 0; }

    .is-secondary {
        margin-right: 15px; } }

@media screen and (max-width: 960px) {
    .done-manage-newsletter {
        .button-group {
            button {
                width: 100%;
                max-width: 300px;
                margin-bottom: 12px;
                margin-right: 12px; }

            .is-secondary {
                margin-right: 12px;
                height: 50px; } } } }
