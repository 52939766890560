article {
    &.mainBoxHd {
        position: relative;
        height: 300px;
        background: #d5d5d5;

        .kvComplex {
            position: absolute;
            top: 0;
            left: 50%;
            width: 1920px;
            height: 300px;
            margin: 0 0 0 -960px; }

        .mainBox {
            position: absolute;
            top: 86px;
            bottom: 40px;
            left: 50%;
            width: 1240px;
            margin: 0 0 0 -620px;

            &.postpaid, &.globalRoaming {
                top: 60px;
                bottom: 60px; }

            .mainTitle {
                position: absolute;
                top: 0;
                left: 0;
                padding: 0;
                color: #fff;
                font-size: 40px;
                font-weight: bold;
                line-height: 1.2em;
                text-shadow: 0 0 5px rgba(0, 0, 0, 0.35);
                z-index: 1; }

            &.postpaid .mainTitle, &.globalRoaming .mainTitle {
                position: relative;
                top: auto;
                left: auto;
                margin: 0 0 10px 0;
                color: #666;
                text-shadow: 0 0 0 rgba(0, 0, 0, 0); }

            .subTitle {
                margin: 0 0 5px 0;
                color: #eee;
                font-size: 24px;
                font-weight: bold;
                line-height: 1.2em;
                text-shadow: 0 0 5px rgba(0, 0, 0, 0.35);
                clear: both; }

            .mainBoxBg {
                position: absolute;
                top: 0;
                left: 0;
                width: 470px;
                padding: 30px 15px;
                background: rgba(255, 255, 255, 0.8);
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                box-sizing: border-box;
                z-index: 1; }

            .text {
                color: #666;
                font-size: 15px;
                line-height: 1.3em; }

            .selectBox {
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 1;

                &:after {
                    content: "";
                    display: table;
                    clear: both; }

                .selectClass {
                    float: left;
                    width: 790px; }

                .btnComplex, .boxComplex {
                    float: left; }

                .selectClass {
                    .boxComplex {
                        width: 186px;

                        &:nth-child(3) {
                            width: 330px; }

                        .selectboxit-container .selectboxit-options {
                            max-height: 240px !important; } }

                    .btnComplex {
                        position: relative;
                        left: -1px;
                        width: 82px;
                        z-index: 1; } }

                .selectKeywords {
                    float: right;
                    width: 410px;

                    .boxComplex {
                        width: 325px; } }

                .schKeywords .btnComplex, .selectKeywords .btnComplex {
                    position: relative;
                    left: -1px;
                    width: 82px;
                    z-index: 1; } } }

        .selectboxit-container span {
            height: 50px;
            line-height: 50px; }

        .selectboxit .selectboxit-option-icon-container {
            margin-left: 10px; }

        .selectboxit-arrow-container {
            width: 46px; }

        .selectboxit-text {
            color: #666;
            font-size: 16px; }

        .selectboxit-arrow-container {
 }            // background: #fff url(../images/page/icon_selectArrow.png) no-repeat right center

        .selectboxit-default-arrow {
            display: none; }

        .mainBox .selectBox {
            input {
                &[type="text"] {
                    width: 100%;
                    color: #666;
                    font-size: 16px;
                    line-height: 50px;
                    height: 50px;
                    text-indent: 10px;
                    border: 0;
                    padding: 0;
                    margin: 0;
                    background: #fff;
                    -webkit-appearance: none;
                    border-radius: 0; }

                &[type="submit"] {
                    width: 100%;
                    color: #e1e1e1;
                    font-size: 16px;
                    line-height: 50px;
                    height: 50px;
                    border: 0;
                    padding: 0;
                    margin: 0;
                    cursor: pointer;
                    background: #909090;
                    -webkit-appearance: none;
                    border-radius: 0;

                    &:hover {
                        background: #666; } } }

            .schKeywords {
                width: 810px;
                margin: 0 auto;

                .boxComplex {
                    width: 720px;
                    height: 40px;
                    vertical-align: middle;
                    background: #fff; }

                .btnComplex {
                    height: 40px;
                    vertical-align: middle; } } }

        &.single {
            height: auto;
            padding: 15px 0;

            .mainBox {
                position: relative;
                top: auto;
                bottom: auto;
                left: auto;
                width: 1280px;
                margin: 0 auto;

                .selectBox {
                    position: relative; }

                .subTitle {
                    float: left;
                    height: 40px;
                    line-height: 40px;
                    margin: 0 20px 0 0;
                    color: #555;
                    font-size: 20px;
                    text-shadow: 0 0 0 rgba(0, 0, 0, 0); } }

            .selectboxit-container span {
                height: 40px;
                line-height: 40px; }

            .mainBox .selectBox {
                input {
                    &[type="text"], &[type="submit"] {
                        vertical-align: middle; }

                    &[type="text"] {
                        height: 40px;
                        line-height: 38px; }

                    &[type="submit"] {
                        height: 40px;
                        line-height: 40px; } }

                .selectKeywords {
                    width: 360px; } } }

        .mainBox .selectBox .selectKeywords .boxComplex {
            width: 275px; }

        &.single .mainBox .relatedBox {
            width: 810px;
            margin: 10px auto 5px auto;
            color: #333;
            font-size: 15px;
            line-height: 1.4em;

            a {
                font-weight: bold; }

            b {
                color: #e71a0f; } } }

    &.recommendBox {
        margin: 30px auto 0 auto;

        &:first-child {
            margin: 0 auto; }

        &:after {
            content: "";
            display: table;
            clear: both; }

        &.first {
            border-top: 3px solid #e71a0f; } }

    .tabs {
        > ul {
            margin: 0 0 15px 0;
            border-bottom: 2px #e71a0f solid; }

        &.unit {
            > ul {
                margin: 0 0 15px 0;
                border-bottom: 3px #c7c6c6 solid; }

            > ul li {
                margin: 0 0 -3px 0; } }

        > ul li {
            position: relative;
            padding: 10px 0;
            display: inline-block;
            line-height: 20px;
            margin: 0 0 -2px 0; } }

    &.recommendBox .tabs .slickRetail.slick-slider {
        border-bottom: 0;
        margin: 0 0 10px 0;
        height: 300px;
        overflow: hidden; }

    .tabs {
        ul.slickRetail {
            border-bottom: 0;
            margin: 0 0 10px 0;
            height: 300px;
            overflow: hidden;

            li {
                padding: 0;
                line-height: initial;
                margin: 0; } }

        &.unit {
            > ul ul.slick-dots li {
                padding: 0;
                margin: 0 7px;
                line-height: initial; }

            > ul li a {
                font-size: 18px; }

            > ul li.ui-tabs-active {
                border-bottom: 3px #e71a0f solid; }

            > ul li.ui-tabs-active:after {
                display: none; }

            position: relative;

            .language {
                text-align: right;

                a {
                    display: inline-block;
                    width: 30px;
                    height: 30px;
                    line-height: 30px;
                    margin: 0 0 0 10px;
                    text-align: center;
                    color: #000;
                    font-size: 15px;
                    background: #ffffff;

                    &:hover {
                        color: #e71a0f;
                        background: #d2d2d2; }

                    &.on {
                        color: #e71a0f;
                        background: #ffffff;

                        &:hover {
                            color: #e71a0f;
                            background: #ffffff; } } } } }

        > ul li {
            &.arrow {
                padding: 10px 20px 10px 0; }

            a {
                display: block;
                font-size: 16px;
                font-weight: bold;
                padding: 0 20px;
                border-left: 1px #c7c6c6 solid; } }

        > ul li {
            &:nth-child(1) a {
                border-left: 0; }

            &.arrow a {
                // background: url(../images/icon/icon_subtab.png) no-repeat right center
                -moz-background-size: 16px auto;
                -webkit-background-size: 16px auto;
                -o-background-size: 16px auto;
                background-size: 16px auto; } }

        > ul li {
            &.ui-tabs-active {
                border-bottom: 2px #e71a0f solid;

                a {
                    color: #e71a0f; } }

            &.arrow {
                a:hover {
                    // background: url(../images/icon/icon_subtabHover.png) no-repeat right center
                    -moz-background-size: 16px auto;
                    -webkit-background-size: 16px auto;
                    -o-background-size: 16px auto;
                    background-size: 16px auto; }

                &.ui-tabs-active a {
                    // background: url(../images/icon/icon_subtabHover.png) no-repeat right center
                    -moz-background-size: 16px auto;
                    -webkit-background-size: 16px auto;
                    -o-background-size: 16px auto;
                    background-size: 16px auto;

                    &:hover {
                        // background: url(../images/icon/icon_subtabHover.png) no-repeat right center
                        -moz-background-size: 16px auto;
                        -webkit-background-size: 16px auto;
                        -o-background-size: 16px auto;
                        background-size: 16px auto; } } }

            &.ui-tabs-active:after {
                bottom: 0;
                left: 50%;
                border: solid transparent;
                content: " ";
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
                border-bottom-color: #e71a0f;
                border-width: 8px;
                border-left-width: 10px;
                border-right-width: 10px;
                margin-left: -10px; } }

        ol {
            &.subtab {
                margin: (-15px) 0 15px 0;
                padding: 10px;
                border-bottom: 1px #c7c7c7 solid;
                background: #fff;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                box-sizing: border-box;

                li {
                    position: relative;
                    display: inline-block;
                    padding: 5px 10px;
                    margin: 0 10px 0 0; } }

            &.subTabMin li {
                position: relative;
                display: inline-block;
                padding: 5px 10px;
                margin: 0 10px 0 0; }

            &.subtab li:after, &.subTabMin li:after {
                position: absolute;
                top: 10px;
                left: 2px;
                content: "";
                width: 3px;
                height: 3px;
                background: #333;
                -webkit-border-radius: 1.5px;
                -moz-border-radius: 1.5px;
                border-radius: 1.5px; }

            &.subtab li a {
                font-size: 16px; }

            &.subTabMin li a {
                font-size: 15px; }

            &.subtab li.active a, &.subTabMin li.active a {
                color: #e71a0f; }

            &.subtab li.active:after {
                background: #e71a0f; }

            &.subTabMin {
                li.active:after {
                    background: #e71a0f; }

                margin: (-25px) 0 20px 0;
                padding: 5px 10px;
                border-bottom: 1px #c7c7c7 solid;
                background: #f4f4f4;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                box-sizing: border-box; } } }

    &.locationBox .toolBox {
        width: 100%;
        margin: 0 0 10px 0; }

    &.recommendBox .toolBox {
        width: 100%;
        margin: 0 0 10px 0;

        &.bottom {
            margin: 20px 0 0 0; } }

    &.locationBox .toolBox:after, &.recommendBox .toolBox:after {
        content: "";
        display: table;
        clear: both; }

    &.locationBox .toolBox .searchHead, &.recommendBox .toolBox .searchHead {
        position: relative;
        float: left;
        width: 458px;
        padding: 2px 0 2px 10px;
        font-size: 16px;
        line-height: 24px;
        color: #333; }

    &.locationBox .toolBox .searchHead:after, &.recommendBox .toolBox .searchHead:after {
        position: absolute;
        top: 8%;
        left: 0;
        content: "";
        width: 3px;
        height: 82%;
        background: #e71a0f; }

    &.locationBox .toolBox .searchHead b {
        color: #e71a0f; }

    &.recommendBox {
        .toolBox {
            .searchHead b {
                color: #e71a0f; }

            .text {
                clear: both;
                padding: 20px 0 0 0;
                font-size: 15px;
                line-height: 1.6em;

                p {
                    margin: 0 0 10px 0; } }

            .sortbyBox {
                float: left;
                font-size: 15px;
                line-height: 30px;
                margin: 0 20px 0 0;

                span {
                    display: inline-block;

                    img {
                        max-width: 16px;
                        vertical-align: middle; } }

                a {
                    position: relative;
                    padding: 0 16px 0 0;
                    margin: 0 10px 0 0;

                    &:after {
                        top: 50%;
                        right: 0;
                        border: solid transparent;
                        content: "";
                        height: 0;
                        width: 0;
                        position: absolute;
                        pointer-events: none;
                        border-top-color: #666666;
                        border-width: 7px;
                        border-left-width: 6px;
                        border-right-width: 6px;
                        margin-top: -4px; }

                    &.active, &:hover:after, &.active:after {
                        color: #ef3123;
                        border-top-color: #ef3123; } } } }

        .mainBox {
            text-align: center;

            &.left {
                position: relative;
                float: left;
                width: 360px;
                height: 570px;
                padding: 10px 18px 160px 18px; }

            &.right {
                float: right;
                width: 906px;
                height: 570px;
                padding: 40px 15px 0 15px; }

            .photo {
                position: relative;
                display: table;
                width: 250px;
                height: 250px;
                text-align: center;
                margin: 0 auto 10px auto;

                > a {
                    display: table-cell;
                    vertical-align: middle; }

                .msgBox {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    width: 70%;
                    height: 40px;
                    line-height: 40px;
                    margin: auto;
                    font-size: 13px;
                    text-align: center;
                    color: #fff;
                    background: rgba(0, 0, 0, 0.6);
                    -webkit-box-sizing: border-box;
                    -moz-box-sizing: border-box;
                    box-sizing: border-box;
                    z-index: 1; }

                img {
                    display: inline;
                    height: 100%;
                    max-height: 250px;
                    max-width: 250px; } }

            &.right .photo {
                max-width: 230px;
                max-height: 230px; } }

        ul.listBox.mainBox .photo, .mainBox.right .photo img, ul.listBox.mainBox .photo img {
            max-width: 230px;
            max-height: 230px; }

        .mainBox {
            .line {
                padding: 20px 0 10px 0;
                border: 1px #d8d8d8 solid; }

            .iconBox {
                position: relative;
                height: 54px;
                padding: 10px 0 20px 0;

                .notifyBox {
                    position: absolute;
                    bottom: 62px;
                    left: 7px;
                    width: 258px;
                    height: 68px;

                    .notifyBoxBd {
                        height: 58px;
                        padding: 8px 40px 8px 10px;
                        font-size: 15px;
                        line-height: 1.4em;
                        text-align: left;
                        color: #fff;
                        background: #835935;
                        -webkit-box-sizing: border-box;
                        -moz-box-sizing: border-box;
                        box-sizing: border-box;
                        z-index: 1; } } }

            &.listBox .iconBox .notifyBox {
                bottom: 72px; }

            &.left .iconBox .notifyBox {
                bottom: 72px;
                width: 312px; }

            .iconBox {
                .notifyBox {
                    span.buy {
                        padding: 0 15px 0 5px;
                        font-weight: bold;

                        // background: url(../images/icon/icon_moreBottom.png) no-repeat right center
                        -moz-background-size: 20px auto;
                        -webkit-background-size: 20px auto;
                        -o-background-size: 20px auto;
                        background-size: 20px auto; }

                    a {
                        &.close {
                            position: absolute;
                            top: 7px;
                            right: 7px;
                            display: inline-block;
                            width: 20px;
                            height: 20px;
                            font-size: 0;
                            cursor: pointer;

                            // background: url(../images/icon/icon_close.png) no-repeat
                            -moz-background-size: 20px auto;
                            -webkit-background-size: 20px auto;
                            -o-background-size: 20px auto;
                            background-size: 20px auto; }

                        color: #fff;

                        &:hover {
                            color: #fff; } } }

                ul {
                    padding: 0 4px 0 0;
                    text-align: center;

                    li {
                        display: inline-block;
                        padding: 2px 4px;
                        margin: 0 -4px 0 0;
                        border-left: 1px #c3a78a solid;

                        &:nth-child(1) {
                            border: 0; }

                        &:hover {
                            background: #e5e5e5; }

                        > a {
                            position: relative;
                            display: block; }

                        &.msgComplex {
                            > a:after {
                                top: -10px;
                                left: 21px;
                                border: solid transparent;
                                content: " ";
                                height: 0;
                                width: 0;
                                position: absolute;
                                pointer-events: none;
                                border-top-color: #835935;
                                border-width: 8px;
                                border-left-width: 9px;
                                border-right-width: 9px;
                                filter: alpha(opacity = 0);
                                -moz-opacity: 0;
                                opacity: 0;
                                -webkit-transition: all 0.4s;
                                -moz-transition: all 0.4s;
                                -o-transition: all 0.4s;
                                transition: all 0.4s; }

                            &.arrow > a:after {
                                filter: alpha(opacity = 100);
                                -moz-opacity: 1;
                                opacity: 1;
                                -webkit-transition: all 0.4s;
                                -moz-transition: all 0.4s;
                                -o-transition: all 0.4s;
                                transition: all 0.4s; } } } }

                img {
                    display: inline;
                    width: 54px; } }

            &.right .iconBox {
                padding: 10px 0 10px 0; }

            .title {
                position: relative;
                width: 100%;
                margin: 0 auto 10px auto;
                padding: 10px 0 0 0;
                border-top: 1px #d8d8d8 solid;
                font-size: 16px;
                font-weight: bold;
                line-height: 1.5em;
                height: 3em;
                vertical-align: middle;
                z-index: 1;
                height: 48px;
                overflow: hidden;

                .limitHigh {
                    width: 100%;
                    display: table;
                    height: 48px; } }

            &.left .iconBox {
                padding: 0 0 20px 0; }

            &.right .title {
                width: 90%;
                border-top: 0; }

            .title span {
                display: table-cell;
                vertical-align: top; }

            &.left .title span {
                vertical-align: middle; }

            .title a {
                &.icon {
                    position: relative;
                    display: inline-block;
                    vertical-align: top;
                    width: 25px;
                    height: 24px;

                    img {
                        position: absolute;
                        top: 0;
                        left: 0;
                        max-width: 25px;
                        max-height: 24px;

                        &.link {
                            display: block; } } }

                &:hover.icon img.hover {
                    display: block; }

                &.icon img.hover, &:hover.icon img.link {
                    display: none; } }

            .specs {
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                padding: 10px 0;
                height: 140px;
                color: #fff;
                text-align: left;
                background: #999999; }

            &.left .specs {
                padding: 10px; }

            &.listBox .specs {
                position: relative;
                height: 160px;
                color: #666;
                background: none;
                border-top: 1px solid #d8d8d8; }

            .specs table {
                margin: 0 -4px; }

            &.left .specs table {
                margin: 0; }

            .specs {
                th, td {
                    font-weight: normal;
                    padding: 4px 4px;
                    word-wrap: break-word;
                    word-break: normal;
                    word-break: break-all; }

                .limitHigh {
                    max-height: 16px;
                    overflow: hidden;

                    &.rows2 {
                        max-height: 32px; }

                    &.rows3 {
                        max-height: 48px; } }

                th {
                    width: 55px; }

                &.dePad th {
                    width: 85px; } }

            .slickLi {
                margin: 0 9px; } }

        .slick-slider {
            .slick-prev {
                left: -30px; }

            .slick-next {
                right: -30px; } }

        .seqComplex {
            position: relative;
            width: 100%;
            height: 300px;
            max-width: 100%;
            overflow: hidden;
            margin: 0 auto;
            padding: 0;

            // background: #fff url(../images/icon/loading.gif) no-repeat center

            .seqBoxComplex {
                position: relative;
                height: 100%;

 } } } }                // background: #fff url(../images/icon/icon_tabRetail.png) no-repeat right bottom

/* single */

/**
 **
 ** recommendBox
 **
 ** */

/**
 **
 ** toolBox
 **
 ** */

/*-----------------------------------------------------------------------------
 *sequencejs jQuery Plugin
 *----------------------------------------------------------------------------- */

.seqComplex {
    .seq-screen, .seq-canvas, .seq-pagination {
        margin: 0;
        padding: 0;
        list-style: none; }

    .seq-canvas > * {
        margin: 0;
        padding: 0;
        list-style: none;
        height: 100%;
        width: 100%; }

    .seq-screen {
        height: 100%;
        width: 100%; }

    .seq-canvas {
        height: 100%;
        width: 100%;

        > * {
            position: absolute;
            -webkit-transition-property: left, opacity;
            -moz-transition-property: left, opacity;
            -ms-transition-property: left, opacity;
            -o-transition-property: left, opacity;
            transition-property: left, opacity;

            &:before {
                content: "";
                display: inline-block;
                vertical-align: middle;
                height: 100%; } } }

    .seq-preloader {
        background: #f8f8f8; }

    .navigationBox {
        position: absolute;
        left: 0;
        z-index: 10;
        bottom: 20px;
        width: 100%; }

    .seq-pagination {
        max-width: 1246px;
        padding: 0 60px;
        margin: 0 auto;
        border: none;
        text-align: center;

        a {
            display: block;
            width: 100%;
            height: 100%; }

        .seq-current {
            background: #333; }

        > * {
            margin: 0 4px;
            padding: 0;
            display: inline-block;
            zoom: 1;
            width: 10px;
            height: 10px;
            cursor: pointer;
            font: 0 / 0 a;
            text-shadow: none;
            color: transparent;
            background: #999;
            -webkit-transition: all 0.3s;
            -moz-transition: all 0.3s;
            -o-transition: all 0.3s;
            transition: all 0.3s;
            -webkit-border-radius: 10px;
            -moz-border-radius: 10px;
            border-radius: 10px;
            border: 1px rgba(255, 255, 255, 0) solid;
            -webkit-box-shadow: 0 0 0 rgba(255, 255, 255, 0);
            -moz-box-shadow: 0 0 0 rgba(255, 255, 255, 0);
            box-shadow: 0 0 0 rgba(255, 255, 255, 0);
            border: 1px #fff solid;
            -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.7);
            -moz-box-shadow: 0 0 1px rgba(255, 255, 255, 0.7);
            box-shadow: 0 0 1px rgba(255, 255, 255, 0.7); } }

    &.seq-active {
        overflow: hidden;

        .seq-pagination {
            display: block; }

        .seq-preloader, .seq-pagination {
            visibility: visible; }

        .seq-preloader.seq-preloaded {
            visibility: hidden; } } }

article {
    &.recommendBox {
        .slickRetail {
            border-top: 3px #e71a0f solid;

            &.slick-slider {
                margin: 0; }

            .slick-dots {
                bottom: 30px; }

            .retailBox {
                padding: 0 160px 0 60px;
                background: #fff;

                &:after {
                    content: "";
                    display: table;
                    clear: both; }

                > a {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    display: block;
                    border: 0;
                    z-index: 1;
                    background-color: rgba(0, 0, 0, 0);
                    -webkit-transition: all 200ms linear;
                    transition: all 200ms linear;

                    &:hover {
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        display: block;
                        border: 0;
                        background-color: rgba(0, 0, 0, 0.1); } }

                .iconRestriction {
                    position: absolute;
                    right: 30px;
                    bottom: 15px;
                    width: 180px;
                    height: 180px; }

                .photo {
                    float: left;
                    width: 300px;
                    height: 300px;
                    text-align: center;

                    img {
                        max-width: 250px;
                        max-height: 300px;
                        margin: 0 auto; } }

                .conBoxComplex {
                    float: right;
                    width: 710px;
                    height: 300px;
                    padding: 40px 0 60px 0;
                    -webkit-box-sizing: border-box;
                    -moz-box-sizing: border-box;
                    box-sizing: border-box;

                    .title {
                        font-size: 24px;
                        font-weight: bold;
                        line-height: 1.4em;
                        color: #333; }

                    .text {
                        width: 550px;
                        margin: 15px 0 0 0;
                        font-size: 18px;
                        line-height: 1.4em;
                        color: #666; }

                    .date {
                        margin: 15px 0 0 0;
                        font-size: 18px;
                        line-height: 1.4em;
                        color: #e71a0f; } } } }

        ul.listBox.mainBox {
            margin: -0.6%;

            &:after {
                content: "";
                display: table;
                clear: both; }

            > li {
                position: relative;
                float: left;
                width: 23.8%;
                margin: 1% 0.6% 0 0.6%;
                padding: 18px 18px 0 18px;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                box-sizing: border-box; }

            li .label {
                position: absolute;
                top: 0;
                right: 0;
                width: 66px;
                height: 64px;
                z-index: 1; }

            .title span {
                vertical-align: middle; } } }

    &.rateBox .moreBottom, &.recommendBox .moreBottom {
        height: 44px;
        margin: 30px auto 0 auto;
        text-align: center; }

    &.rateBox .moreBottom .btn, &.recommendBox .moreBottom .btn {
        display: inline-block;
        vertical-align: middle; }

    &.rateBox .moreBottom .btn a, &.recommendBox .moreBottom .btn a {
        display: block;
        width: 164px;
        height: 44px;
        line-height: 44px;
        padding: 0 15px 0 10px;
        color: #fff;
        font-size: 15px;
        border: 1px #333 solid;
        background: #666; }

    &.rateBox .moreBottom .btn.down a, &.recommendBox .moreBottom .btn.down a, &.rateBox .moreBottom .btn a:hover, &.recommendBox .moreBottom .btn a:hover {
        background: #666; }

    &.rateBox .moreBottom .btn.down a img, &.recommendBox .moreBottom .btn.down a img {
        border: 0 none;
        height: 16px;
        position: relative;
        top: -2px;
        width: 16px; }

    &.posRecBox {
        margin: 40px auto 0 auto;
        max-width: 1280px;

        &:first-child {
            margin: 0 auto; }

        &:after {
            content: "";
            display: table;
            clear: both; }

        .listBox {
            .slickLi:after {
                content: "";
                display: table;
                clear: both; }

            width: 100%;

            &.slick-slider {
                margin-bottom: 10px; }

            .slick-dots {
                bottom: 0; } }

        .slick-slider {
            .slick-prev, .slick-next {
                margin-top: -45px; }

            .slick-prev {
                left: -30px; }

            .slick-next {
                right: -30px; } }

        .listBox {
            .slickLi {
                position: relative;
                margin: 0 0 20px 0; }

            &.slickLoyalty .slickLi {
                margin: 0 0 25px 0; }

            .slickLi {
                &:last-child {
                    margin: 0; }

                > a {
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    z-index: 5; }

                .photo {
                    position: relative;
                    float: left;
                    width: 952px;
                    height: 232px;
                    overflow: hidden;

                    img {
                        max-width: 950px;
                        max-height: 230px;
                        border: 1px #e2e2e2 solid; } }

                .conBox {
                    float: right;
                    width: 328px;
                    height: 232px;
                    padding: 36px 32px;
                    -webkit-box-sizing: border-box;
                    -moz-box-sizing: border-box;
                    box-sizing: border-box;

                    .title {
                        font-size: 20px;
                        font-weight: bold;
                        line-height: 1.2em;
                        max-height: 50px;
                        overflow: hidden; }

                    .text {
                        border-top: 1px #e1e1e1 solid;
                        padding: 15px 0 0 0;
                        margin: 15px 0 0 0;
                        color: #666;
                        font-size: 15px;
                        line-height: 1.4em;
                        height: 85px;
                        overflow: hidden; } } } } }

    &.posRecBoxV2 {
        margin: 22px auto;
        max-width: 1280px;

        &:first-child {
            margin: 0 auto 35px; }

        &:last-child {
            margin: 22px auto 0; }

        &:after {
            content: "";
            display: table;
            clear: both; }

        .listBox {
            .slickLi:after {
                content: "";
                display: table;
                clear: both; }

            width: 100%;
            margin: (-16px) 0;
            padding: 0 24px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-content: flex-start;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;

            .posRecItem {
                position: relative;
                width: 380px;
                margin: 16px 14px;
                border: 1px solid #e1e1e1;
                overflow: hidden;
                -webkit-transition: all 0.3s linear;
                -moz-transition: all 0.3s linear;
                -ms-transition: all 0.3s linear;
                -o-transition: all 0.3s linear;
                transition: all 0.3s linear;

                a {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    width: 100%;
                    height: 100%;
                    cursor: pointer;
                    z-index: 1; } }

            .photo {
                position: relative;

                img {
                    width: 100%; }

                .shadow {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    width: 100%;
                    height: 100%;
                    opacity: 0;
                    background-color: #000;
                    -webkit-transition: all 0.3s linear;
                    -moz-transition: all 0.3s linear;
                    -ms-transition: all 0.3s linear;
                    -o-transition: all 0.3s linear;
                    transition: all 0.3s linear; } }

            .conBox {
                padding: 15px 20px;
                position: absolute;
                width: 380px;
                right: 0;
                left: 0;
                top: 172px;
                font-size: 15px;
                line-height: 20px;
                color: #666;
                opacity: 0.9;
                background-color: #fff;
                -webkit-transition: all 0.3s linear;
                -moz-transition: all 0.3s linear;
                -ms-transition: all 0.3s linear;
                -o-transition: all 0.3s linear;
                transition: all 0.3s linear;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                box-sizing: border-box;

                .title {
                    height: 1.2em;
                    margin-bottom: 10px;
                    font-weight: bold;
                    color: #333;
                    overflow: hidden; }

                .text {
                    max-height: 4em;
                    overflow: hidden; } }

            .posRecItem:hover {
                .photo .shadow {
                    opacity: 0.5; }

                .conBox {
                    opacity: 1; } } } }

    &.roamBox {
        margin: 0 auto;
        max-width: 1280px;

        .selectBox {
            &:after {
                content: "";
                display: table;
                clear: both; }

            .selectClass {
                position: relative;
                background: #d5d5d5;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                box-sizing: border-box; }

            .selectKeywords {
                position: relative;
                background: #d5d5d5;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                box-sizing: border-box;
                display: table-cell; }

            .selectClass {
                display: table-cell;
                width: 802px;
                padding: 15px 15px 15px 35px;

                &:after {
                    position: absolute;
                    top: 50%;
                    left: -25px;
                    content: "or";
                    width: 44px;
                    height: 44px;
                    line-height: 44px;
                    text-align: center;
                    color: #fff;
                    font-size: 26px;
                    font-weight: bold;
                    margin: (-22px) 0 0 0;
                    background: #adadad;
                    -webkit-border-radius: 22px;
                    -moz-border-radius: 22px;
                    border-radius: 22px;
                    z-index: 1; } }

            .selectKeywords {
                width: 480px;
                padding: 15px 30px 15px 15px;
                border-right: 5px #fff solid; }

            .selectClass .chauCon, .selectKeywords .chauCon {
                position: absolute;
                top: 90px;
                left: 20px;
                width: 440px;
                margin: -1px;
                max-height: 460px;
                border: 1px #e5302a solid;
                background: #fff;
                z-index: 10;
                display: none; }

            .selectClass .chauCon {
                left: 200px;

                .chauHd {
                    position: relative;
                    padding: 15px 20px;
                    font-size: 20px;
                    color: #fff;
                    background: #e5302a; } }

            .selectKeywords .chauCon .chauHd {
                position: relative;
                padding: 15px 20px;
                font-size: 20px;
                color: #fff;
                background: #e5302a; }

            .selectClass .chauCon .chauHd:after, .selectKeywords .chauCon .chauHd:after {
                position: absolute;
                left: 50%;
                top: 0;
                width: 0;
                height: 0;
                content: "";
                margin: (-10px) 0 0 0;
                border-style: solid;
                border-width: 0 10px 10px 10px;
                border-color: transparent transparent #e5302a transparent; }

            .selectClass .chauCon .chauHd a, .selectKeywords .chauCon .chauHd a {
                position: absolute;
                right: 15px;
                top: 50%;
                display: block;
                width: 28px;
                height: 28px;
                margin: (-14px) 0 0 0;

                // background: url(../images/icon/icon_close_white.png) no-repeat
                -webkit-background-size: 28px auto;
                background-size: 28px auto; }

            .selectClass .chauCon .chauBd, .selectKeywords .chauCon .chauBd {
                padding: 20px 0 20px 20px; }

            .selectClass .chauCon .chauBd p {
                font-size: 15px; }

            .selectKeywords .chauCon .chauBd {
                p {
                    font-size: 15px; }

                .scroll {
                    max-height: 375px;
                    overflow: scroll;
                    overflow-x: hidden;
                    -webkit-overflow-scrolling: touch; }

                ul {
                    display: inline-block;
                    vertical-align: top;
                    width: 50%;
                    margin: 0 -2px;

                    li {
                        padding: 5px 10px;
                        font-size: 14px;
                        line-height: 18px;
                        -webkit-box-sizing: border-box;
                        -moz-box-sizing: border-box;
                        box-sizing: border-box;

                        &.selected a, a:hover {
                            color: #e5302a; } } } } } } }

/* media
 **---------------------------------------------------------------------------- */
/**
 **
 ** Desktops
 **
 ** */
@media only screen and (max-width: 1280px) {
    article.recommendBox .slick-slider {
        .slick-prev {
            left: -10px; }

        .slick-next {
            right: -10px; } } }

/**
 **
 ** posRecBox
 **
 ** */

/**
 **
 ** posRecBox  v2
 **
 ** */

/* hover effect */

/**
 **
 ** rateBox
 **
 ** */

.selectKeywords .suggestCon {
    position: absolute;
    top: 56px;
    left: 150px;
    width: 209px;
    max-height: 209px;
    padding: 0;
    border-top: 1px #dcdcdc solid;
    background: #fff;
    display: none;
    z-index: 11;
    *visibility: hidden;

    ul li {
        a {
            display: block;
            padding: 0 5px;
            color: #333;
            font: 13px / 30px "source-han-sans-traditional", "Noto Sans CJK TC", "Microsoft JhengHei", Helvetica, Arial, sans-serif;
            text-decoration: none;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden; }

        &.selected a, a:hover {
            color: #333;
            background: #dddddd; } } }

article {
    &.roamBox {
        .selectBox {
            .subTitle {
                float: left;
                height: 40px;
                line-height: 40px;
                margin: 0 15px 0 0;
                color: #666;
                font-size: 15px;
                text-shadow: 0 0 0 rgba(0, 0, 0, 0); }

            .error {
                margin: 8px 0 0 120px;
                color: #e71a0f; }

            .box, .btn {
                display: table-cell;
                vertical-align: middle; }

            .selectClass .box {
                width: 240px;

                &:nth-child(3) {
                    width: 310px; } }

            .selectKeywords .box {
                width: 210px; }

            .btn {
                position: relative;
                left: -1px;
                width: 82px;
                z-index: 1; } }

        .selectboxit-container span {
            height: 40px;
            line-height: 40px; }

        .selectboxit .selectboxit-option-icon-container {
            margin-left: 10px; }

        .selectboxit-arrow-container {
            width: 46px; }

        .selectboxit-text {
            color: #666;
            font-size: 16px; }

        .selectboxit-arrow-container {
 }            // background: url(../images/page/icon_selectArrow.png) no-repeat right center

        .selectboxit-default-arrow {
            display: none; }

        .selectBox input {
            &[type="text"] {
                width: 100%;
                color: #666;
                font-size: 16px;
                line-height: 38px;
                height: 38px;
                text-indent: 10px;
                border: 0;
                background: #fff;
                -webkit-appearance: none;
                border-radius: 0; }

            &[type="submit"] {
                width: 100%;
                color: #e1e1e1;
                font-size: 16px;
                line-height: 40px;
                border: 0;
                cursor: pointer;
                background: #909090;
                -webkit-appearance: none;
                border-radius: 0; } }

        .globalRoamingMap {
            position: relative;
            width: 1280px;
            height: 550px;
            background: #fff;
            overflow: hidden;
            opacity: 1;
            -webkit-transition: opacity 0.5s;
            transition: opacity 0.5s;

            .schKeyMask {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: #fff;
                opacity: 0.7;
                z-index: 8;
                display: none; }

            .mapBg {
                position: absolute;
                top: 0;
                left: 0;
                width: 1280px;
                height: 550px;

                // background: url(../images/page/globalRoaming_map.gif) no-repeat right center
                -webkit-background-size: 1280px auto;
                background-size: 1280px auto;

                div {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 1280px;
                    height: 550px;
                    display: none;

                    img {
                        max-width: 1280px; } } }

            &.Africa .mapBg div.Africa, &.Europe .mapBg div.Europe, &.America .mapBg div.America, &.Asia .mapBg div.Asia, &.MiddleEast .mapBg div.MiddleEast {
                display: block; }

            .mapBg div {
                &.Africa.on, &.Europe.on, &.America.on, &.Asia.on, &.MiddleEast.on {
                    display: block; } }

            .chau {
                position: absolute;
                cursor: pointer;
                z-index: 5;
                -webkit-transition: all 0.5s;
                transition: all 0.5s;

                &.Africa {
                    top: 260px;
                    left: 230px; }

                &.Europe {
                    top: 50px;
                    left: 220px; }

                &.America {
                    top: 80px;
                    left: 790px; }

                &.Asia {
                    top: 80px;
                    left: 380px; }

                &.MiddleEast {
                    top: 160px;
                    left: 280px; }

                .point {
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    z-index: 2;
                    width: 40px;
                    height: 40px;
                    margin: (-20px) 0 0 -20px;
                    background-color: rgba(144, 144, 144, 0.5);
                    -webkit-border-radius: 50%;
                    border-radius: 50%;
                    -webkit-transition: width 0.5s, height 0.5s, margin 0.5s;
                    transition: width 0.5s, height 0.5s, margin 0.5s;

                    &:after {
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        margin: auto;
                        content: "";
                        width: 12px;
                        height: 12px;
                        border: 5px #909090 solid;
                        background: #fff;
                        -webkit-border-radius: 50%;
                        border-radius: 50%; } }

                .place {
                    position: relative;
                    padding: 15px 20px;
                    margin: 0 0 42px;
                    color: #fff;
                    font-size: 16px;
                    background-color: #909090;
                    z-index: 3;

                    &:after {
                        position: absolute;
                        bottom: -10px;
                        left: 50%;
                        width: 0;
                        height: 0;
                        content: "";
                        margin: 0 0 0 -10px;
                        border-style: solid;
                        border-width: 10px 10px 0 10px;
                        border-color: #909090 transparent transparent transparent; } } }

            .chauCon {
                position: absolute;
                top: 30px;
                right: -540px;
                width: 500px;
                height: 460px;
                border: 1px #e5302a solid;
                background: #fff;
                z-index: 10;
                -webkit-transition: all 0.5s;
                transition: all 0.5s; }

            .chauHdBox {
                position: relative;
                top: 0;
                left: 0;
                -webkit-transition: all 0.5s;
                transition: all 0.5s; }

            .mapBg {
                top: 0;
                -webkit-transform: scale(1);
                transform: scale(1);
                -webkit-transition: all 0.5s;
                transition: all 0.5s; }

            &.Africa .chau.Africa .point, &.Europe .chau.Europe .point, &.America .chau.America .point, &.Asia .chau.Asia .point, &.MiddleEast .chau.MiddleEast .point {
                width: 60px;
                height: 60px;
                margin: (-30px) 0 -10px -30px;
                background-color: rgba(228, 31, 31, 0.5);
                -webkit-transition: width 0.5s, height 0.5s, margin 0.5s;
                transition: width 0.5s, height 0.5s, margin 0.5s; }

            .chau {
                &.Africa:hover .point, &.Europe:hover .point, &.America:hover .point, &.Asia:hover .point, &.MiddleEast:hover .point {
                    background-color: rgba(228, 31, 31, 0.5); } }

            &.Africa .chau.Africa .point:after, &.Europe .chau.Europe .point:after, &.America .chau.America .point:after, &.Asia .chau.Asia .point:after, &.MiddleEast .chau.MiddleEast .point:after {
                border: 5px #e41f1f solid; }

            .chau {
                &.Africa:hover .point:after, &.Europe:hover .point:after, &.America:hover .point:after, &.Asia:hover .point:after, &.MiddleEast:hover .point:after {
                    border: 5px #e41f1f solid; } }

            &.Africa .chau.Africa .place, &.Europe .chau.Europe .place, &.America .chau.America .place, &.Asia .chau.Asia .place, &.MiddleEast .chau.MiddleEast .place {
                background-color: #e41f1f; }

            .chau {
                &.Africa:hover .place, &.Europe:hover .place, &.America:hover .place, &.Asia:hover .place, &.MiddleEast:hover .place {
                    background-color: #e41f1f; } }

            &.Africa .chau.Africa .place:after, &.Europe .chau.Europe .place:after, &.America .chau.America .place:after, &.Asia .chau.Asia .place:after, &.MiddleEast .chau.MiddleEast .place:after {
                border-color: #e41f1f transparent transparent transparent; }

            .chau {
                &.Africa:hover .place:after, &.Europe:hover .place:after, &.America:hover .place:after, &.Asia:hover .place:after, &.MiddleEast:hover .place:after {
                    border-color: #e41f1f transparent transparent transparent; } }

            &.Africa {
                .chauHdBox, .mapBg {
                    top: -40px;
                    left: 140px;
                    -webkit-transition: all 0.5s;
                    transition: all 0.5s; } }

            &.Europe {
                .chauHdBox, .mapBg {
                    top: 160px;
                    left: 80px;
                    -webkit-transition: all 0.5s;
                    transition: all 0.5s; } }

            &.America {
                .chauHdBox, .mapBg {
                    top: 120px;
                    left: -500px;
                    -webkit-transition: all 0.5s;
                    transition: all 0.5s; } }

            &.Asia {
                .chauHdBox, .mapBg {
                    top: 120px;
                    left: -60px;
                    -webkit-transition: all 0.5s;
                    transition: all 0.5s; } }

            &.MiddleEast {
                .chauHdBox, .mapBg {
                    top: 80px;
                    left: 60px;
                    -webkit-transition: all 0.5s;
                    transition: all 0.5s; } }

            &.Africa .mapBg, &.Europe .mapBg, &.America .mapBg, &.Asia .mapBg, &.MiddleEast .mapBg {
                -webkit-transform: scale(1.2);
                transform: scale(1.2);
                -webkit-transition: all 0.5s;
                transition: all 0.5s; }

            &.Africa .chauCon.Africa, &.Europe .chauCon.Europe, &.America .chauCon.America, &.Asia .chauCon.Asia, &.MiddleEast .chauCon.MiddleEast {
                right: 30px;
                -webkit-transition: all 0.5s;
                transition: all 0.5s; }

            &.Africa .chau.Africa, &.Europe .chau.Africa, &.America .chau.Africa, &.Asia .chau.Africa, &.MiddleEast .chau.Africa {
                top: 265px;
                left: 165px;
                -webkit-transition: all 0.5s;
                transition: all 0.5s; }

            &.Africa .chau.Europe, &.Europe .chau.Europe, &.America .chau.Europe, &.Asia .chau.Europe, &.MiddleEast .chau.Europe {
                top: 10px;
                left: 160px;
                -webkit-transition: all 0.5s;
                transition: all 0.5s; }

            &.Africa .chau.America, &.Europe .chau.America, &.America .chau.America, &.Asia .chau.America, &.MiddleEast .chau.America {
                top: 40px;
                left: 840px;
                -webkit-transition: all 0.5s;
                transition: all 0.5s; }

            &.Africa .chau.Asia, &.Europe .chau.Asia, &.America .chau.Asia, &.Asia .chau.Asia, &.MiddleEast .chau.Asia {
                top: 50px;
                left: 355px;
                -webkit-transition: all 0.5s;
                transition: all 0.5s; }

            &.Africa .chau.MiddleEast, &.Europe .chau.MiddleEast, &.America .chau.MiddleEast, &.Asia .chau.MiddleEast, &.MiddleEast .chau.MiddleEast {
                top: 145px;
                left: 230px;
                -webkit-transition: all 0.5s;
                transition: all 0.5s; }

            .chauCon {
                .chauHd {
                    position: relative;
                    padding: 15px 20px;
                    font-size: 20px;
                    color: #fff;
                    background: #e5302a;

                    &:after {
                        position: absolute;
                        left: -10px;
                        top: 50%;
                        width: 0;
                        height: 0;
                        content: "";
                        margin: (-10px) 0 0 0;
                        border-style: solid;
                        border-width: 10px 10px 10px 0;
                        border-color: transparent #e5302a transparent transparent; }

                    a {
                        position: absolute;
                        right: 15px;
                        top: 50%;
                        display: block;
                        width: 28px;
                        height: 28px;
                        margin: (-14px) 0 0 0;

                        // background: url(../images/icon/icon_close_white.png) no-repeat
                        -webkit-background-size: 28px auto;
                        background-size: 28px auto; } }

                .chauBd {
                    padding: 20px 0 20px 20px;

                    .scroll {
                        height: 375px;
                        overflow: scroll;
                        overflow-x: hidden;
                        -webkit-overflow-scrolling: touch; }

                    ul {
                        display: inline-block;
                        vertical-align: top;
                        width: 50%;
                        margin: 0 -2px;

                        li {
                            padding: 5px 10px;
                            font-size: 14px;
                            line-height: 18px;
                            -webkit-box-sizing: border-box;
                            -moz-box-sizing: border-box;
                            box-sizing: border-box; } } } } } }

    &.rateBox {
        margin: 30px auto 0 auto;
        max-width: 1280px;

        &:first-child {
            margin: 0 auto; }

        .listBox {
            &:after {
                content: "";
                display: table;
                clear: both; }

            margin: -11px;

            .slickLi {
                position: relative;
                float: left;
                width: 412px;
                margin: 11px;
                padding: 20px;

                .rateBoxBd {
                    position: relative;

                    > a {
                        display: block;
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        z-index: 5; } }

                .photo {
                    position: relative;
                    width: 412px;
                    height: 302px;
                    margin: (-20px) -20px 0 -20px;
                    overflow: hidden;

                    img {
                        border: 1px #e2e2e2 solid;
                        max-width: 410px;
                        max-height: 300px; } }

                .conBox {
                    height: 120px; }

                .textHd {
                    font-size: 16px;
                    font-weight: bold;
                    line-height: 1.2em;
                    padding: 20px 0 10px 0;
                    width: 22em; }

                .text {
                    color: #666;
                    font-size: 15px;
                    line-height: 1.4em;
                    height: 42px;
                    overflow: hidden; }

                .rateBoxFt {
                    margin: 20px 0 0 0;

                    // background: url(../images/page/line_top.png) no-repeat
                    -moz-background-size: 100% 13px;
                    -webkit-background-size: 100% 13px;
                    -o-background-size: 100% 13px;
                    background-size: 100% 13px; }

                .list {
                    padding: 15px 0 15px 0;
                    border-top: 1px #d8d8d8 solid;

                    // background: url(../images/icon/icon_more.png) no-repeat 100% 15px
                    -moz-background-size: 16px auto;
                    -webkit-background-size: 16px auto;
                    -o-background-size: 16px auto;
                    background-size: 16px auto;

                    &:last-child {
                        padding: 15px 0 0 0; }

                    a {
                        font-size: 16px;
                        font-weight: bold;
                        line-height: 1.2em;
                        display: inline-block;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        width: 22em; } } } } }

    &.otherBoxFt {
        margin: 30px auto 0 auto;
        padding: 0 20px;
        max-width: 1280px;

        &:first-child {
            margin: 0 auto; }

        .slickUl {
            margin: 0 15px 10px 15px;

            &:after {
                content: "";
                display: table;
                clear: both; }

            .slickLi {
                position: relative;
                float: none;
                display: inline-block;
                vertical-align: top;
                width: 16%;
                margin: 0 2px;

                a {
                    bottom: 0;
                    display: block;
                    left: 0;
                    position: absolute;
                    right: 0;
                    top: 0;
                    z-index: 5; }

                .photo {
                    position: relative;
                    display: table;
                    text-align: center;
                    width: 160px;
                    height: 160px;
                    margin: 0 auto;
                    overflow: hidden; } } }

        &.product .slickUl .slickLi .photo {
            padding: 10px 0 10px 0; }

        .slickUl .slickLi {
            .photo {
                span {
                    display: table-cell;
                    vertical-align: middle; }

                img {
                    display: inline;
                    max-width: 160px;
                    max-height: 160px; } }

            .title {
                width: 100%;
                max-height: 28px;
                padding: 0 10px;
                overflow: hidden;
                background: #999;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                box-sizing: border-box; } }

        &.product .slickUl .slickLi .title {
            padding: 8px 10px;
            max-height: 52px;
            overflow: hidden; }

        .slickUl .slickLi .title .limitHigh {
            display: table;
            width: 100%;
            height: 28px;
            line-height: 28px;
            text-align: center;
            font-size: 16px;
            color: #fff; }

        &.product .slickUl .slickLi .title .limitHigh {
            display: block;
            height: 36px;
            line-height: 1.2em;
            overflow: hidden;

            .limitHighBox {
                display: table;
                width: 100%;
                text-align: center;
                height: 36px; } }

        .slickUl .slickLi .title span {
            display: table-cell;
            vertical-align: middle; }

        .slick-slider {
            .slick-prev {
                left: -40px; }

            .slick-next {
                right: -40px; } } }

    &.proDetailBox {
        position: relative;
        margin: 0 auto 30px auto;
        padding: 20px;
        max-width: 1280px;

        &.prepaid, &.postpaid {
            margin: 0 auto 0 auto;
            padding: 10px 0 0 0; }

        .detailBoxHd {
            padding: 0 0 30px 0;
            border-bottom: 1px #d8d8d8 solid; }

        &.prepaid .detailBoxHd, &.postpaid .detailBoxHd {
            padding: 0;
            border-bottom: 1px #d8d8d8 solid;
            .detailBox h3.font24b {
                 font-size: 36px!important; } }

        .detailBoxHd:after {
            content: "";
            display: table;
            clear: both; }

        .shareBox {
            position: absolute;
            top: 0;
            right: 0;
            z-index: 1; }

        .languageBox .shareBox {
            right: -5px; }

        .tabsBox .shareBox {
            right: 0; }

        .shareBox {
            .title {
                padding: 10px 0 5px 0;
                font-size: 15px;
                text-align: center;
                color: #999; }

            ul li {
                margin: 5px 0;
                width: 42px;
                height: 42px;

                a {
                    position: relative;
                    display: block;
                    width: 42px;
                    height: 42px;
                    font-size: 0;

                    &.facebook {
 }                        // background: url(../images/icon/share/icon_detail_facebook.jpg) no-repeat

                    &.line {
 }                        // background: url(../images/icon/share/icon_detail_line.jpg) no-repeat

                    &.google {
 }                        // background: url(../images/icon/share/icon_detail_google.jpg) no-repeat

                    &:hover {
                        &.facebook {
 }                            // background: url(../images/icon/share/icon_detail_facebook_hover.jpg) no-repeat

                        &.line {
 }                            // background: url(../images/icon/share/icon_detail_line_hover.jpg) no-repeat

                        &.google {
 } } } } }                            // background: url(../images/icon/share/icon_detail_google_hover.jpg) no-repeat

        .albumsBox {
            position: relative;
            float: left;
            width: 640px;
            min-height: 590px;
            margin: 0 0 0 20px; }

        &.prepaid .albumsBox, &.postpaid .albumsBox {
            position: relative;
            float: left;
            width: 445px;
            min-height: initial;
            min-height: auto;
            margin: 0; }

        &.prepaid .albumsBox {
            width: 750px;

            .photo img {
                max-width: 750px; } }

        .albumsBox {
            .label {
                position: absolute;
                top: 1px;
                left: 1px;
                height: 76px;
                z-index: 1;

                a {
                    &.icon {
                        position: relative;
                        display: inline-block;
                        vertical-align: top;
                        width: 68px;
                        height: 76px;

                        img {
                            position: absolute;
                            top: 0;
                            left: 0;
                            max-width: 68px;
                            max-height: 76px;

                            &.link {
                                display: block; } } }

                    &:hover.icon img.hover {
                        display: block; }

                    &.icon img.hover, &:hover.icon img.link {
                        display: none; } } }

            .slickAlbums .photo {
                display: table;
                overflow: hidden;
                width: 640px;
                height: 480px;
                margin: 0 auto;
                text-align: center;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                box-sizing: border-box;

                span {
                    display: table-cell;
                    vertical-align: middle;
                    border: 1px #f0f0f0 solid; }

                img {
                    display: inline-block;
                    max-width: 638px;
                    max-height: 478px; } } }

        &.postpaid .albumsBox .photo {
            width: 445px;
            border: 0;

            img {
                max-width: 445px; } }

        .albumsBox {
            .slickAlbumsNav {
                width: 600px;
                margin: 0 auto;

                .slickLi {
                    display: table;
                    width: 105px;
                    height: 79px;
                    text-align: center;
                    margin: 0 5px;
                    border: 1px #f0f0f0 solid;

                    > a {
                        display: table-cell;
                        vertical-align: middle; }

                    img {
                        max-width: 105px;
                        max-height: 79px;
                        display: inline-block; }

                    &:hover {
                        border: 1px #bebebe solid; } }

                .slick-current, .slickLi.slick-center {
                    border: 1px #bebebe solid; } }

            .slick-slider {
                .slick-prev {
                    left: -30px;

 }                    // background: url(../images/icon/icon_slickprev.png) no-repeat center

                .slick-next {
                    right: -30px;

 }                    // background: url(../images/icon/icon_slicknext.png) no-repeat center

                .slick-prev {
                    width: 44px;
                    height: 60px;
                    margin-top: -30px;
                    -moz-background-size: 30px auto;
                    -webkit-background-size: 30px auto;
                    -o-background-size: 30px auto;
                    background-size: 30px auto; }

                .slick-next {
                    width: 44px;
                    height: 60px;
                    margin-top: -30px;
                    -moz-background-size: 30px auto;
                    -webkit-background-size: 30px auto;
                    -o-background-size: 30px auto;
                    background-size: 30px auto;

                    &:hover {
                        background-color: #999; } }

                .slick-prev:hover {
                    background-color: #999; } } }

        .tabs.singleColumn {
            .languageBox {
                height: 42px;
                margin: (-30px) -45px 20px 0; }

            .tabsBox .languageBox {
                position: relative;
                margin: (-25px) -5px 20px 0; } }

        .languageBox {
            .shareBox {
                .title {
                    padding: 10px 5px 5px 20px;
                    font-size: 15px;
                    text-align: center;
                    color: #999; }

                ul li {
                    float: left;
                    margin: 0 5px;
                    width: 42px;
                    height: 42px; } }

            .language {
                display: table-cell;
                vertical-align: middle; }

            .shareBox {
                display: table-cell;
                vertical-align: middle;

                .title, ul {
                    display: table-cell;
                    vertical-align: middle; } } }

        .detailBox {
            position: relative;
            float: right;
            width: 460px;
            margin: 0 70px 0 50px;
            padding: 0 0 305px 0; }

        &.prepaid .detailBox, &.postpaid .detailBox {
            width: 740px;
            margin: 0 70px 0 0;
            padding: 40px;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box; }

        &.prepaid {
            .detailBox {
                width: 530px;
                margin: 0;
                padding: 30px 100px 30px 30px;
                min-height: 360px;
                background: #dfeaab; }

            &.wifi .detailBox {
                background: #fff; }

            .detailBox.editBox {
                h3 {
                    margin: 0 0 10px; }

                h4 {
                    margin: 0 0 10px;
                    color: #666; }

                ul {
                    margin: 0 0 0 10px;

                    li {
                        padding: 0 0 0 15px;
                        color: #666;

                        &:after {
                            top: 9px;
                            width: 5px;
                            height: 5px;
                            background: #a4ad7e;
                            -webkit-border-radius: 2.5px;
                            -moz-border-radius: 2.5px;
                            border-radius: 2.5px; } } } } }

        &.postpaid .detailBox.editBox .btnDownBox {
            text-align: left;
            margin: 25px 0 0; }

        &.prepaid {
            .preDetailBox {
                display: table;
                width: 100%;

                &:after {
                    content: "";
                    display: table;
                    clear: both; }

                .photo, .conBox {
                    display: table-cell;
                    vertical-align: top; }

                .photo {
                    position: relative;
                    width: 450px;
                    text-align: center;
                    -webkit-box-sizing: border-box;
                    -moz-box-sizing: border-box;
                    box-sizing: border-box;

                    img {
                        max-width: 480px; }

                    .flag {
                        position: absolute;
                        top: 0;
                        left: 12px;
                        height: 62px;
                        z-index: 1;

                        img {
                            margin: 0 -10px 0 0;
                            border: 0; } }

                    &.w400 {
                        width: 400px;
                        padding: 35px 0 0 0;
                        background: none;

                        > img {
                            max-width: 400px;
                            border-top: 2px #777eb9 solid; } } }

                .conBox {
                    padding: 30px;
                    -webkit-box-sizing: border-box;
                    -moz-box-sizing: border-box;
                    box-sizing: border-box; }

                .editBox .conBox {
                    h3, h4 {
                        margin: 0 0 10px 0; } } }

            .detailBox.editBox *, .preDetailBox .editBox .conBox * {
                color: #333; }

            &.wifi .detailBox.editBox .icon {
                margin: 0 0 10px 0; }

            .preDetailBox {
                .editBox .conBox ul li:after {
                    top: 9px;
                    width: 5px;
                    height: 5px;
                    background: #a4ad7e;
                    -webkit-border-radius: 2.5px;
                    -moz-border-radius: 2.5px;
                    border-radius: 2.5px; }

                .conBox {
                    .summaryBox {
                        margin: 0 0 10px 0;

                        span {
                            display: inline-block;
                            margin: 0 12px 0 0;
                            padding: 0 0 0 20px;
                            font-size: 18px;
                            color: #333;

                            // background: url(../images/icon/icon_tick_orange.png) no-repeat left center
                            -moz-background-size: 16px auto;
                            -webkit-background-size: 16px auto;
                            -o-background-size: 16px auto;
                            background-size: 16px auto; } }

                    ul.stepBox {
                        margin: 10px 0 0 0;

                        li {
                            padding: 3px 0;
                            font-size: 16px;
                            line-height: 32px;
                            color: #666;

                            span {
                                position: relative;
                                top: -2px;
                                display: inline-block;
                                margin: 0 10px 0 0;
                                width: 32px;
                                height: 32px;
                                line-height: 32px;
                                text-align: center;
                                color: #fff;
                                font-size: 20px;
                                font-weight: bold;
                                vertical-align: middle;
                                background: #fe7e22;
                                -webkit-border-radius: 16px;
                                -moz-border-radius: 16px;
                                border-radius: 16px; }

                            &:after {
                                display: none; } } }

                    p {
                        margin: 0 0 5px 0;
                        font-size: 16px;
                        color: #666; }

                    .picBox {
                        margin: 10px 0; } } }

            .relatedBox .picBox img, .preDetailBox .conBox .picBox img {
                border: 0; }

            .relatedBox {
                text-align: center; }

            .editBox {
                .stepStyle1 {
                    margin: 0 20px;

                    &:after {
                        content: "";
                        display: table;
                        clear: both; }

                    .stepBox {
                        position: relative;
                        float: left;
                        width: 172px;
                        height: 172px;
                        margin: 15px 60px 15px 0;
                        padding: 20px;
                        color: #fff;
                        font-size: 15px;
                        -webkit-box-sizing: border-box;
                        -moz-box-sizing: border-box;
                        box-sizing: border-box;
                        background: #fe7e22;
                        -webkit-border-radius: 10px;
                        -moz-border-radius: 10px;
                        border-radius: 10px;

                        &:after {
                            position: absolute;
                            top: 50%;
                            right: -50px;
                            content: "";
                            width: 37px;
                            height: 37px;
                            margin: (-28px) 0 0 0;

 }                            // background: url(../images/icon/icon_stepArrow.png) no-repeat

                        em {
                            position: absolute;
                            right: -8px;
                            bottom: -18px;
                            display: block;
                            width: 60px;
                            height: 60px;
                            line-height: 60px;
                            text-align: center;
                            color: #fe7e22;
                            font-size: 40px;
                            font-weight: bold;
                            -webkit-border-radius: 30px;
                            -moz-border-radius: 30px;
                            border-radius: 30px;
                            background: #fff; }

                        &.last {
                            margin: 10px 0;

                            &:after {
                                top: 0;
                                right: 0;
                                content: "";
                                width: 0;
                                height: 0;
                                margin: 0;
                                background: none; } }

                        a {
                            color: #fff;
                            text-decoration: underline; } } }

                .stepStyle2 .stepBox a {
                    color: #fff;
                    text-decoration: underline; }

                .stepStyle1 .stepBox b {
                    color: #fff;
                    font-size: 16px; }

                .stepStyle2 {
                    .stepBox b {
                        color: #fff;
                        font-size: 16px; }

                    margin: 0 20px;

                    &:after {
                        content: "";
                        display: table;
                        clear: both; }

                    .stepBox {
                        position: relative;
                        float: left;
                        width: 172px;
                        height: 172px;
                        margin: 15px 60px 15px 0;
                        padding: 20px;
                        text-align: center;
                        color: #fff;
                        font-size: 15px;
                        -webkit-box-sizing: border-box;
                        -moz-box-sizing: border-box;
                        box-sizing: border-box;
                        background: #fe7e22;

                        &:after {
                            position: absolute;
                            top: 50%;
                            right: -50px;
                            content: "";
                            width: 37px;
                            height: 37px;
                            margin: (-28px) 0 0 0;

 }                            // background: url(../images/icon/icon_stepArrow.png) no-repeat

                        &.last {
                            margin: 15px 0 15px 0;

                            &:after {
                                top: 0;
                                right: 0;
                                content: "";
                                width: 0;
                                height: 0;
                                margin: 0;
                                background: none; } }

                        .icon {
                            width: 140px;
                            margin: 0 auto 15px auto;
                            padding: 0 0 20px 0;
                            border-bottom: 1px #fff solid;

                            img {
                                border: 0; } } } }

                .stepStyle3 {
                    margin: 20px 20px 0;

                    &:after {
                        content: "";
                        display: table;
                        clear: both; }

                    .stepBox {
                        position: relative;
                        padding: 20px 20px 20px 100px;
                        margin: 0 0 40px 0;
                        border-top: 2px #f4d3bb solid;
                        font-size: 15px;
                        line-height: 1.4em;
                        vertical-align: middle;
                        -webkit-box-sizing: border-box;
                        -moz-box-sizing: border-box;
                        box-sizing: border-box;
                        background: #faeee5;

                        .icon {
                            position: absolute;
                            top: -5px;
                            left: 6px;
                            bottom: 10px;
                            width: 80px;
                            padding: 10px 0 0 0;
                            text-align: center;
                            -webkit-box-sizing: border-box;
                            -moz-box-sizing: border-box;
                            box-sizing: border-box;
                            background: #fe7e22;

                            &:after {
                                bottom: -30px;
                                left: 0;
                                border: solid transparent;
                                content: " ";
                                height: 0;
                                width: 0;
                                position: absolute;
                                pointer-events: none;
                                border-top-color: #fe7e22;
                                border-width: 15px;
                                border-left-width: 40px;
                                border-right-width: 40px; }

                            > div {
                                display: table;
                                vertical-align: middle;
                                width: 100%;
                                height: 100%;

                                span {
                                    display: table-cell;
                                    vertical-align: middle;
                                    color: #fff;
                                    font-size: 28px;
                                    font-style: normal;
                                    font-weight: bold; } } }

                        .text {
                            display: table-cell;
                            vertical-align: middle; }

                        > span {
                            height: 50px;
                            width: 1px;
                            display: table-cell;
                            vertical-align: middle; } } }

                .stepStyle4 {
                    margin: 0 20px; }

                .depositBox .stepStyle4 {
                    padding: 0 40px 0 0; }

                .stepStyle4 .stepBox {
                    position: relative;
                    margin: 0 0 0 40px;
                    padding: 15px 0;
                    font-size: 15px;
                    border-bottom: 1px #ccc dotted;
                    -webkit-box-sizing: border-box;
                    -moz-box-sizing: border-box;
                    box-sizing: border-box;

                    em {
                        position: absolute;
                        top: 50%;
                        left: -40px;
                        display: inline-block;
                        vertical-align: middle;
                        width: 32px;
                        height: 32px;
                        line-height: 32px;
                        margin-top: -16px;
                        text-align: center;
                        font-size: 40px;
                        color: #fff;
                        font-size: 20px;
                        background: #fe7e22;
                        -webkit-border-radius: 16px;
                        -moz-border-radius: 16px;
                        border-radius: 16px; } }

                .depositBox {
                    margin: 0 40px 40px 40px;

                    .logo, .logoBox, .stepStyle4 {
                        display: table-cell;
                        vertical-align: middle; }

                    .logo img {
                        border: 0; } }

                .qaBoxComplex {
                    margin: 30px 0 0 0;
                    padding: 15px 20px;
                    background: #f4f4f4;
                    -webkit-box-sizing: border-box;
                    -moz-box-sizing: border-box;
                    box-sizing: border-box;

                    &:first-child {
                        margin: 0; }

                    .question {
                        font-size: 16px;
                        font-weight: bold;
                        color: #333;
                        padding: 0 0 10px 0;
                        margin: 0 0 10px 0;
                        border-bottom: 1px #d9d9d9 solid;

                        b, a {
                            font-size: 16px;
                            font-weight: bold; } }

                    .answer {
                        font-size: 15px;
                        color: #666; } } } }

        .detailBox .mainTitle {
            padding: 15px 0 15px 0;
            margin: 0 0 15px 0;
            font-size: 24px;
            font-weight: bold;
            line-height: 1.3em;
            border-bottom: 1px #dfdfdf solid; }

        &.postpaid .detailBox {
            h3 {
                padding: 0;
                margin: 0 0 15px 0;
                border-bottom: 0; }

            h4 {
                color: #333;
                margin: 0 0 10px 0;
                line-height: 1.3em; }

            .text {
                font-size: 15px;
                line-height: 1.4em;
                color: #666; } }

        .detailBox {
            .specs {
                table {
                    width: 100%;
                    font-size: 15px;
                    color: #666; }

                th, td {
                    font-weight: normal;
                    padding: 6px 4px; }

                th {
                    width: 70px;
                    text-align: left; }

                td {
                    line-height: 1.2em; } }

            .relatedComplex {
                position: absolute;
                left: 0;
                right: 0;
                bottom: 150px;
                height: 115px;
                margin: 0;

                .bgComplex {
                    position: absolute;
                    left: 170px;
                    right: 170px;
                    top: 0;
                    bottom: 0;
                    background: #f5f5f5; }

                &.slickUl_3 .bgComplex {
                    left: 50%;
                    right: 50px; }

                &.slickUl_2 .bgComplex {
                    left: 50px;
                    right: 50px; }

                &.slickUl_1 {
                    .bgComplex {
                        left: 50px;
                        right: 50px; }

                    .slickRelated .slickLi {
                        cursor: default;

                        .title {
                            font-size: 16px;
                            font-weight: bold;
                            color: #333; }

                        .price {
                            font-size: 18px;
                            font-weight: bold;
                            color: #e71a0f; }

                        .time {
                            color: #333; } } }

                .msgComplex {
                    position: absolute;
                    right: 0;
                    top: -30px;
                    width: 80px;
                    height: 20px;

                    a {
                        display: block;
                        width: 80px;
                        height: 20px;
                        line-height: 20px;
                        text-align: center;
                        color: #fff;
                        font-size: 12px;
                        background: #aaa;
                        -webkit-border-radius: 4px;
                        -moz-border-radius: 4px;
                        border-radius: 4px;

                        &:hover {
                            background: #999; } } }

                .descComplex {
                    padding: 10px 5px 0 5px;
                    font-size: 13px;
                    color: #666;
                    text-align: center; } }

            .slickRelated {
                position: relative;
                color: #999;
                text-align: center;
                margin: 0;

                &:after, &:before {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    content: "";
                    width: 80px;
                    z-index: 1; }

                &:after {
                    left: 0;

 }                    // background: url(../images/page/bg_proposal_left.png) repeat-y left

                &:before {
                    right: 0;

 }                    // background: url(../images/page/bg_proposal_right.png) repeat-y right

                .slickLi {
                    padding: 10px 10px;
                    border-right: 1px #e3e3e3 solid;
                    cursor: pointer; }

                .price {
                    height: 20px;
                    font-size: 15px;
                    line-height: 1.2em; }

                .title {
                    height: 40px;
                    font-size: 15px;
                    line-height: 1.2em;
                    overflow: hidden; }

                .time {
                    font-size: 13px;
                    height: 16px;
                    margin: 10px 0 10px 0;
                    overflow: hidden; }

                .slickLi.slick-center {
                    cursor: default;

                    .title {
                        font-size: 16px;
                        font-weight: bold;
                        color: #333; }

                    .price {
                        font-size: 18px;
                        font-weight: bold;
                        color: #e71a0f; }

                    .time {
                        color: #333; } } }

            .iconBox {
                position: relative;
                height: 50px;
                padding: 10px 0 0 0;

                .notifyBox {
                    position: absolute;
                    left: 0;
                    bottom: 50px;
                    width: 450px;
                    height: 48px;

                    .notifyBoxBd {
                        height: 38px;
                        padding: 8px 40px 8px 10px;
                        font-size: 15px;
                        line-height: 1.4em;
                        text-align: left;
                        color: #fff;
                        background: #835935;
                        -webkit-box-sizing: border-box;
                        -moz-box-sizing: border-box;
                        box-sizing: border-box;
                        z-index: 1; }

                    a {
                        color: #fff;

                        &:hover {
                            color: #fff; } }

                    span.buy {
                        padding: 0 15px 0 5px;
                        font-weight: bold;

                        // background: url(../images/icon/icon_moreBottom.png) no-repeat right center
                        -moz-background-size: 20px auto;
                        -webkit-background-size: 20px auto;
                        -o-background-size: 20px auto;
                        background-size: 20px auto; }

                    a.close {
                        position: absolute;
                        top: 7px;
                        right: 7px;
                        display: inline-block;
                        width: 20px;
                        height: 20px;
                        font-size: 0;
                        cursor: pointer;

                        // background: url(../images/icon/icon_close.png) no-repeat
                        -moz-background-size: 20px auto;
                        -webkit-background-size: 20px auto;
                        -o-background-size: 20px auto;
                        background-size: 20px auto; } }

                ul {
                    float: left;
                    left: 50%;
                    position: relative;
                    text-align: center;

                    li {
                        position: relative;
                        float: left;
                        right: 50%;
                        margin: 0 3px;
                        text-align: center;
                        background: #e1c7a6;

                        &:hover {
                            background: #e5e5e5; }

                        a {
                            display: block; }

                        &.msgComplex {
                            > a:after {
                                top: -10px;
                                left: 21px;
                                border: solid transparent;
                                content: " ";
                                height: 0;
                                width: 0;
                                position: absolute;
                                pointer-events: none;
                                border-top-color: #835935;
                                border-width: 8px;
                                border-left-width: 9px;
                                border-right-width: 9px;
                                filter: alpha(opacity = 0);
                                -moz-opacity: 0;
                                opacity: 0;
                                -webkit-transition: all 0.3s;
                                -moz-transition: all 0.3s;
                                -o-transition: all 0.3s;
                                transition: all 0.3s; }

                            &.arrow > a:after {
                                filter: alpha(opacity = 100);
                                -moz-opacity: 1;
                                opacity: 1;
                                -webkit-transition: all 0.3s;
                                -moz-transition: all 0.3s;
                                -o-transition: all 0.3s;
                                transition: all 0.3s; } } } }

                img {
                    display: inline;
                    width: 108px; } }

            .howBuyBox {
                position: absolute;
                left: -3px;
                right: -3px;
                bottom: 0;

                .title {
                    border-top: 1px #dfdfdf solid;
                    margin: 0 8px;
                    padding: 20px 0 0 0;
                    font-size: 16px;
                    color: #666; } } }

        .main {
            margin: 40px -20px 0 -20px; }

        article.otherBoxFt .slickUl {
            margin: 0 -3px;

            .slickLi {
                width: 16%; } }

        .detailBoxBd {
            padding: 30px 0 0 0;

            &:after {
                content: "";
                display: table;
                clear: both; }

            .tabs {
                float: left;
                width: 980px;

                &.minComplex {
                    width: 940px;
                    margin: 0 20px 0 20px; }

                .tabsBox {
                    position: relative;
                    padding: 10px 0; }

                &.singleColumn {
                    float: none;
                    width: auto;
                    margin: 0 auto; } } }

        &.postpaid .detailBoxBd .tabs .tabsBox {
            padding: 20px 0 0 0; }

        &.prepaid.wifi {
            .detailBoxBd {
                .wifiBoxComplex {
                    margin: 30px 50px; }

                .wifiBoxHd {
                    margin: 5px 0 40px 0; }

                .iosStepBox {
                    width: 1000px;
                    margin: 0 auto;
                    text-align: center; }

                .androidMainBox .iosStepBox {
                    text-align: left; }

                .iosStepBox:after, .storeList:after, .wifiBoxHd:after {
                    content: "";
                    display: table;
                    clear: both; }

                .wifiBoxComplex {
                    .wifiBoxLeft, .wifiBoxRight {
                        width: 530px;
                        min-height: 200px;
                        padding: 20px 40px;
                        border: 1px #e1e1e1 solid;
                        -webkit-box-sizing: border-box;
                        -moz-box-sizing: border-box;
                        box-sizing: border-box; } }

                .storeList {
                    margin: 10px 0 0 0; }

                .stepBox {
                    position: relative;
                    float: left;
                    width: 280px;
                    min-height: 370px;
                    text-align: center;
                    padding: 20px 0;
                    margin: 0 25px 0 0;
                    border: 1px #e1e1e1 solid;
                    -webkit-box-sizing: border-box;
                    -moz-box-sizing: border-box;
                    box-sizing: border-box;

                    &.step4 {
                        margin: 0; }

                    &:after {
                        position: absolute;
                        top: 50%;
                        right: -18px;
                        content: "";
                        width: 37px;
                        height: 37px;
                        margin: (-18px) 0 0 0;

                        // background: url(../images/icon/icon_WiFi_stepArrow.png) no-repeat
                        z-index: 1; }

                    &.step4:after {
                        position: absolute;
                        top: 50%;
                        right: -18px;
                        content: "";
                        width: 37px;
                        height: 37px;
                        margin: (-18px) 0 0 0;
                        background: none;
                        z-index: 1; } }

                .iosStepBox .boxComplex {
                    position: relative;
                    display: inline-block;
                    min-width: 300px;
                    min-height: 570px;
                    -webkit-box-sizing: border-box;
                    -moz-box-sizing: border-box;
                    box-sizing: border-box;

                    .text {
                        display: table;
                        width: 240px;
                        margin: 0 auto;
                        text-align: left;
                        height: 50px;

                        span {
                            display: table-cell;
                            vertical-align: bottom; } } }

                .stepTitle {
                    font-size: 20px;
                    font-weight: bold;
                    color: #8a5f3c; }

                .storeBox {
                    position: relative;
                    float: left;
                    width: 200px;
                    min-height: 200px;
                    margin: 0 20px 0 0;
                    border: 1px #e1e1e1 solid;
                    -webkit-box-sizing: border-box;
                    -moz-box-sizing: border-box;
                    box-sizing: border-box; }

                .storeList.max .storeBox {
                    width: 240px;
                    min-height: 240px;
                    margin: 0 30px 0 0; }

                .storeBox:last-child {
                    margin: 0; }

                .storeList.max .storeBox {
                    &:last-child {
                        margin: 0; }

                    .logo {
                        width: 240px;
                        height: 200px; } }

                .storeBox {
                    &:hover {
                        border: 1px #e71a0f solid; }

                    .logo {
                        display: table;
                        text-align: center;
                        width: 200px;
                        height: 160px;

                        span {
                            display: table-cell;
                            vertical-align: middle; } }

                    .title {
                        text-align: center;
                        font-size: 16px;
                        font-weight: bold;
                        color: #333;
                        height: 46px;
                        line-height: 46px;
                        overflow: hidden;
                        background: #f2f2f2; }

                    > a {
                        display: block;
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        z-index: 1; } }

                .stepBox {
                    .text {
                        display: table;
                        width: 100%;
                        text-align: center;
                        height: 50px; }

                    &.step1 .icon article.proDetailBox.prepaid.wifi .detailBoxBd .stepBox.step2 .icon article.proDetailBox.prepaid.wifi .detailBoxBd .stepBox.step3 .icon {
                        display: table;
                        width: 90%;
                        margin: 10px auto 0 auto;
                        text-align: center;
                        height: 230px;
                        border-top: 1px #f0f0f0 solid; }

                    &.step4 .icon {
                        width: 90%;
                        border-top: 1px #f2f2f2 solid;
                        margin: 10px auto 0 auto;
                        padding: 10px 0 0 0; }

                    .text span, &.step1 .icon span, &.step2 .icon span, &.step3 .icon span {
                        display: inline-block;
                        vertical-align: middle; }

                    .boxComplex {
                        text-align: left;
                        margin: 10px 0 0 30px;

                        > div:first-child {
                            margin: 0 0 8px 0; }

                        .text {
                            text-align: left;
                            line-height: 1.2em !important;

                            a {
                                line-height: 1.2em !important; } }

                        .subTitle {
                            padding: 0 0 0 10px;
                            margin: 0;
                            font-size: 16px;

                            &:after {
                                top: 0; } } }

                    .title {
                        font-size: 32px;
                        font-weight: bold;
                        color: #8a5f3c; } }

                .wifiBoxComplex {
                    .wifiBoxLeft {
                        float: left; }

                    .wifiBoxRight {
                        float: right; }

                    .wifiBoxHd .title {
                        font-size: 18px;
                        font-weight: bold;
                        color: #333; } }

                .stepBox img {
                    border: 0; } }

            .detailBox.editBox .icon img {
                border: 0; }

            .detailBoxBd {
                .wifiBoxComplex .wifiBoxHd .title img, .storeBox .logo span img, .iosStepBox .boxComplex .icon img {
                    border: 0; }

                .wifiBoxComplex .wifiBoxHd ul {
                    margin: 15px 0 0 0;
                    padding: 15px 0 0 0;
                    border-top: 1px #e1e1e1 solid;
                    font-size: 15px;
                    color: #666;

                    li {
                        display: inline-block;
                        width: 45%; } }

                .editBox ul li:after {
                    position: absolute;
                    top: 9px;
                    left: 8px;
                    content: "";
                    width: 4px;
                    height: 4px;
                    background: #666;
                    -webkit-border-radius: 2px;
                    -moz-border-radius: 2px;
                    border-radius: 2px; }

                .wifiBoxFt {
                    text-align: center;
                    padding: 20px 20px;
                    font-size: 18px;
                    color: #333;
                    vertical-align: middle;
                    -webkit-box-sizing: border-box;
                    -moz-box-sizing: border-box;
                    box-sizing: border-box;
                    background: #f8f2eb;

                    a.button {
                        display: inline-block;
                        color: #fff;
                        font-size: 13px;
                        height: 35px;
                        line-height: 35px;
                        padding: 0 15px;
                        margin: 0 5px;
                        text-align: center;
                        background: #666;
                        border: 1px #333 solid;

                        &:hover {
                            background: #888; }

                        img {
                            height: 16px;
                            position: relative;
                            top: -1px;
                            width: 16px;
                            border: 0; } } } } }

        .detailBoxBd .sidebar {
            float: right;
            width: 200px;
            margin: 0 30px; } }

    &.recommendBox .detailBoxBd .sidebar {
        float: right;
        width: 200px;
        margin: 0 30px;
        margin: auto; }

    &.proDetailBox {
        &.postpaid .detailBoxBd .sidebar {
            margin: auto; }

        .detailBoxBd .sidebar {
            .slickLi {
                padding: 20px 20px 10px 20px;
                margin: 0 0 20px 0;
                text-align: center;
                border: 1px #e1e1e1 solid;

                a {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    display: block;
                    z-index: 5; } }

            .photo {
                position: relative;
                display: table;
                height: 160px;
                width: 160px;
                margin: 0 auto 10px auto;

                span {
                    display: table-cell;
                    vertical-align: middle; }

                img {
                    height: 100%;
                    max-height: 160px;
                    max-width: 160px; } }

            .title {
                display: table;
                vertical-align: middle;
                padding: 10px 0 0 0;
                width: 100%;
                line-height: 1.3em;
                height: 2.6em;
                font-size: 16px;
                border-top: 1px #ececec solid;

                span {
                    display: table-cell;
                    vertical-align: middle; } } } }

    &.recommendBox .detailBoxBd {
        .sidebar {
            .box {
                margin: 0 0 18px 0; }

            a.button {
                display: block;
                color: #fff;
                font-size: 16px;
                height: 42px;
                line-height: 42px;
                text-align: center;
                background: #666;
                border: 1px #333 solid;

                &.important {
                    border: 1px #e71a0f solid;
                    background: #e71a0f;

                    &:hover {
                        border: 1px #f23c32 solid;
                        background: #f23c32; } }

                &.delComplex {
                    &:hover {
                        color: #e1e1e1;
                        border: 1px #999999 solid;
                        background: #999999;
                        cursor: default; }

                    color: #e1e1e1;
                    border: 1px #999999 solid;
                    background: #999999;
                    cursor: default; }

                &:hover {
                    background: #888; }

                img {
                    height: 16px;
                    position: relative;
                    top: -2px;
                    width: 16px; } }

            .box.max {
                padding: 25px 16px 30px 16px;
                text-align: center;
                border: 1px #e1e1e1 solid;

                .mainTitle {
                    font-size: 20px;
                    font-weight: bold;
                    margin: 0 0 10px 0;
                    vertical-align: bottom;

                    img {
                        vertical-align: bottom; } }

                p {
                    color: #666;
                    font-size: 15px;
                    line-height: 1.4em;
                    margin: 0 0 10px 0; }

                a.button {
                    height: 32px;
                    line-height: 32px; } } }

        padding: 30px 40px;
        margin: 20px 0 0 0;

        &.first {
            margin: 0; }

        &:after {
            content: "";
            display: table;
            clear: both; }

        &.lineup {
            border-top: 3px solid #da1b0d; }

        .leftColumn {
            float: left;
            width: 980px; }

        .partbox {
            position: relative;
            margin: 30px 0 10px;
            border-bottom: 1px #c1c1c1 solid;

            &:first-child {
                margin: 0; }

            .unfoldBox {
                padding: 20px;
                margin: 10px 0 0 0;
                background: #f4f4f4; }

            .unfold {
                max-height: 70px;
                overflow: hidden;
                -webkit-transition: all 0.3s;
                -moz-transition: all 0.3s;
                -o-transition: all 0.3s;
                transition: all 0.3s;

                &.on {
                    max-height: 2000px;
                    overflow: hidden;
                    -webkit-transition: all 0.3s;
                    -moz-transition: all 0.3s;
                    -o-transition: all 0.3s;
                    transition: all 0.3s; } } } }

    &.empowerBox .descbox, &.recommendBox .detailBoxBd .descbox {
        position: relative;
        margin: 30px 0 0 0;
        padding: 30px 40px;
        background: #fcfaf4;
        clear: both; }

    &.winnerBox .detailBoxBd .descbox {
        position: relative;
        margin: 30px -40px -30px -40px;
        padding: 30px 40px;
        background: #fcfaf4;
        clear: both; }

    &.empowerBox .descbox {
        margin: 0; }

    &.recommendBox .detailBoxBd {
        .accordion .descbox {
            margin: 0; }

        .descbox {
            &.topNoMargin {
                margin: 0; }

            &.negativeMargin {
                margin: 30px -40px -40px -40px; } } }

    &.empowerBox .descbox.line, &.winnerBox .detailBoxBd .descbox.line {
        border-bottom: 1px #c1c1c1 solid; }

    &.recommendBox .detailBoxBd {
        .caseBox .descbox, .descbox.line {
            border-bottom: 1px #c1c1c1 solid; } }

    &.empowerBox .descbox .unfold, &.winnerBox .detailBoxBd .descbox .unfold, &.recommendBox .detailBoxBd .descbox .unfold {
        max-height: 60px;
        overflow: hidden;
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s; }

    &.empowerBox .descbox .unfold, &.winnerBox .detailBoxBd .descbox .unfold {
        ol li {
            font-size: 13px; }

        ul li {
            font-size: 13px;

            * {
                font-size: 13px; } }

        ol li * {
            font-size: 13px; } }

    &.recommendBox .detailBoxBd .descbox .unfold {
        ul li, ol li, ul li *, ol li * {
            font-size: 13px; } }

    &.empowerBox .descbox .unfold.on, &.winnerBox .detailBoxBd .descbox .unfold.on, &.recommendBox .detailBoxBd .descbox .unfold.on {
        max-height: 2000px;
        overflow: hidden;
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s; }

    &.empowerBox .descbox .mainTitle, &.winnerBox .detailBoxBd .descbox .mainTitle, &.recommendBox .detailBoxBd .descbox .mainTitle {
        font-size: 15px;
        font-weight: bold;
        padding: 0 0 10px 0;
        margin: 0 0 15px 0;
        border-bottom: 1px #e1e1e1 solid;
        color: #333; }

    &.empowerBox {
        .partbox a.close, .descbox a.close {
            position: absolute;
            right: 40px;
            top: 25px;
            display: block;
            width: 30px;
            height: 30px;
            font-size: 0;

            // background: url(../images/icon/icon_close.png) no-repeat
            -ms-transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
            -o-transform: rotate(45deg);
            transform: rotate(45deg); } }

    &.winnerBox .detailBoxBd {
        .partbox a.close, .descbox a.close {
            position: absolute;
            right: 40px;
            top: 25px;
            display: block;
            width: 30px;
            height: 30px;
            font-size: 0;

            // background: url(../images/icon/icon_close.png) no-repeat
            -ms-transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
            -o-transform: rotate(45deg);
            transform: rotate(45deg); } }

    &.recommendBox .detailBoxBd {
        .partbox a.close, .descbox a.close {
            position: absolute;
            right: 40px;
            top: 25px;
            display: block;
            width: 30px;
            height: 30px;
            font-size: 0;

            // background: url(../images/icon/icon_close.png) no-repeat
            -ms-transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
            -o-transform: rotate(45deg);
            transform: rotate(45deg); }

        .partbox a.close {
            right: 0;
            top: 0; } }

    &.empowerBox {
        .partbox a.close:hover, .descbox a.close:hover {
 } }            // background: url(../images/icon/icon_descboxClose.png) no-repeat

    &.winnerBox .detailBoxBd {
        .partbox a.close:hover, .descbox a.close:hover {
 } }            // background: url(../images/icon/icon_descboxClose.png) no-repeat

    &.recommendBox .detailBoxBd {
        .partbox a.close:hover, .descbox a.close:hover {
 } }            // background: url(../images/icon/icon_descboxClose.png) no-repeat

    &.empowerBox {
        .partbox a.close.odd, .descbox a.close.odd {
            // background: url(../images/icon/icon_close_notifyBox.png) no-repeat
            -webkit-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg); } }

    &.winnerBox .detailBoxBd {
        .partbox a.close.odd, .descbox a.close.odd {
            // background: url(../images/icon/icon_close_notifyBox.png) no-repeat
            -webkit-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg); } }

    &.recommendBox .detailBoxBd {
        .partbox a.close.odd, .descbox a.close.odd {
            // background: url(../images/icon/icon_close_notifyBox.png) no-repeat
            -webkit-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg); } }

    &.empowerBox {
        .partbox a.close.odd:hover, .descbox a.close.odd:hover {
 } }            // background: url(../images/icon/icon_close_notifyBoxHover.png) no-repeat

    &.winnerBox .detailBoxBd .partbox a.close.odd:hover {
 }        // background: url(../images/icon/icon_close_notifyBoxHover.png) no-repeat

    &.recommendBox .detailBoxBd {
        .partbox a.close.odd:hover, .descbox a.close.odd:hover {
 } }            // background: url(../images/icon/icon_close_notifyBoxHover.png) no-repeat

    &.empowerBox {
        .partbox a.close, .descbox a.close {
            -moz-background-size: 30px auto;
            -webkit-background-size: 30px auto;
            -o-background-size: 30px auto;
            background-size: 30px auto;

            &:hover {
                -moz-background-size: 30px auto;
                -webkit-background-size: 30px auto;
                -o-background-size: 30px auto;
                background-size: 30px auto; }

            &.odd {
                -moz-background-size: 30px auto;
                -webkit-background-size: 30px auto;
                -o-background-size: 30px auto;
                background-size: 30px auto;

                &:hover {
                    -moz-background-size: 30px auto;
                    -webkit-background-size: 30px auto;
                    -o-background-size: 30px auto;
                    background-size: 30px auto; } } } }

    &.recommendBox .detailBoxBd {
        .partbox a.close, .descbox a.close {
            -moz-background-size: 30px auto;
            -webkit-background-size: 30px auto;
            -o-background-size: 30px auto;
            background-size: 30px auto;

            &:hover {
                -moz-background-size: 30px auto;
                -webkit-background-size: 30px auto;
                -o-background-size: 30px auto;
                background-size: 30px auto; }

            &.odd {
                -moz-background-size: 30px auto;
                -webkit-background-size: 30px auto;
                -o-background-size: 30px auto;
                background-size: 30px auto;

                &:hover {
                    -moz-background-size: 30px auto;
                    -webkit-background-size: 30px auto;
                    -o-background-size: 30px auto;
                    background-size: 30px auto; } } } }

    &.winnerBox .detailBoxBd .descbox a.close {
        -moz-background-size: 30px auto;
        -webkit-background-size: 30px auto;
        -o-background-size: 30px auto;
        background-size: 30px auto;

        &:hover {
            -moz-background-size: 30px auto;
            -webkit-background-size: 30px auto;
            -o-background-size: 30px auto;
            background-size: 30px auto; }

        &.odd {
            -moz-background-size: 30px auto;
            -webkit-background-size: 30px auto;
            -o-background-size: 30px auto;
            background-size: 30px auto;

            &:hover {
                -moz-background-size: 30px auto;
                -webkit-background-size: 30px auto;
                -o-background-size: 30px auto;
                background-size: 30px auto; } } }

    &.empowerBox {
        .partbox a.open, .descbox a.open, .accordion a.open {
            position: absolute;
            left: 50%;
            bottom: -15px;
            display: block;
            width: 26px;
            height: 26px;
            margin-left: -13px;
            font-size: 0;

            // background: #9f9f9f url(../images/icon/icon_descboxOpen.png) no-repeat
            -moz-background-size: 26px auto;
            -webkit-background-size: 26px auto;
            -o-background-size: 26px auto;
            background-size: 26px auto;
            -webkit-border-radius: 13px;
            -moz-border-radius: 13px;
            border-radius: 13px;
            z-index: 1; } }

    &.recommendBox .detailBoxBd {
        .partbox a.open, .descbox a.open, .accordion a.open {
            position: absolute;
            left: 50%;
            bottom: -15px;
            display: block;
            width: 26px;
            height: 26px;
            margin-left: -13px;
            font-size: 0;

            // background: #9f9f9f url(../images/icon/icon_descboxOpen.png) no-repeat
            -moz-background-size: 26px auto;
            -webkit-background-size: 26px auto;
            -o-background-size: 26px auto;
            background-size: 26px auto;
            -webkit-border-radius: 13px;
            -moz-border-radius: 13px;
            border-radius: 13px;
            z-index: 1; } }

    &.winnerBox .detailBoxBd .descbox a.open {
        position: absolute;
        left: 50%;
        bottom: -15px;
        display: block;
        width: 26px;
        height: 26px;
        margin-left: -13px;
        font-size: 0;

        // background: #9f9f9f url(../images/icon/icon_descboxOpen.png) no-repeat
        -moz-background-size: 26px auto;
        -webkit-background-size: 26px auto;
        -o-background-size: 26px auto;
        background-size: 26px auto;
        -webkit-border-radius: 13px;
        -moz-border-radius: 13px;
        border-radius: 13px;
        z-index: 1; }

    &.recommendBox .detailBoxBd .accordion a.open {
        display: none; }

    &.empowerBox {
        .partbox a.open.odd, .descbox a.open.odd, .accordion.open a.open {
            display: block;
            -webkit-transform: rotate(180deg);
            -o-transform: rotate(180deg);
            transform: rotate(180deg); } }

    &.recommendBox .detailBoxBd {
        .partbox a.open.odd, .descbox a.open.odd, .accordion.open a.open {
            display: block;
            -webkit-transform: rotate(180deg);
            -o-transform: rotate(180deg);
            transform: rotate(180deg); } }

    &.winnerBox .detailBoxBd .descbox a.open.odd {
        display: block;
        -webkit-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        transform: rotate(180deg); }

    &.recommendBox .detailBoxBd {
        .singleColumn {
            margin: 40px 0 0 0; }

        .first {
            margin: 0; }

        .mainTitle {
            margin: 0 0 0.4em;
            color: #333;
            font-size: 24px;
            font-weight: bold;
            line-height: 1.4em;

            .selectCoupon {
                display: inline-block;
                vertical-align: middle;
                width: 170px;
                margin: (-4px) 0 0 10px;

                > .selectboxit-container {
                    border: 1px #c5c5c5 solid; }

                .selectboxit-container {
                    span {
                        height: 30px;
                        line-height: 30px; }

                    * {
                        font-size: 15px; } }

                .selectboxit .selectboxit-option-icon-container {
                    margin-left: 10px; }

                .selectboxit-arrow-container {
                    // background: url(../images/icon/icon_subtabHover.png) no-repeat center
                    -moz-background-size: 12px auto;
                    -webkit-background-size: 12px auto;
                    -o-background-size: 12px auto;
                    background-size: 12px auto;

                    > i {
                        display: none; } } } }

        .editBox .evenBox .mainTitle {
            font-size: 20px; }

        .subTitle {
            position: relative;
            margin: 0 0 0.2em;
            padding: 0 0 0 15px;
            color: #333;
            font-size: 20px;
            font-weight: bold;
            line-height: 1.4em;

            &:after {
                position: absolute;
                top: 3px;
                left: 0;
                content: "";
                width: 3px;
                height: 20px;
                background: #999; }

            &.max {
                font-size: 32px;

                &:after {
                    top: 8px;
                    height: 28px; } }

            img {
                margin: (-2px) 5px 0 0;
                max-width: 70px; } }

        .singleColumn {
            .texthd {
                color: #333;
                line-height: 1.4em; }

            .caseBox {
                padding: 40px 40px 40px 40px;

                &:last-child {
                    padding: 0 40px 40px 40px; } } }

        .linkbox {
            font-size: 15px;
            margin: 0 0 30px -10px;

            a {
                padding: 0 10px; } }

        .siteMapBox {
            padding: 20px 0 0 0;
            margin: 0 0 80px 0;
            min-height: 100px;
            border-top: 2px #e71a0f solid;

            &:last-child {
                margin: 0; }

            ul {
                li img {
                    max-width: 30px; }

                &.liwidthany li {
                    display: table-cell;
                    vertical-align: top;
                    padding: 0 50px 0 0; }

                li a {
                    &.hd, &.st {
                        font-size: 16px;
                        font-weight: bold;
                        color: #333; } }

                &.liwidthany li a.hd {
                    line-height: 30px;

                    img {
                        position: relative;
                        top: -1px; } }

                li a {
                    display: block;
                    font-size: 15px;
                    color: #666;

                    &:hover {
                        color: #e71a0f; } }

                &.liwidth20 {
                    border-top: 1px #e1e1e1 solid;
                    padding: 20px 0 0 0;
                    margin: 20px 0 0 0;

                    &:first-child {
                        border-top: 0;
                        padding: 0;
                        margin: 0; }

                    &:after {
                        content: "";
                        display: table;
                        clear: both; }

                    li {
                        float: left;
                        width: 19.2%;
                        margin: 0 1% 0 0;
                        -webkit-box-sizing: border-box;
                        -moz-box-sizing: border-box;
                        box-sizing: border-box;

                        &:last-child {
                            margin: 0; } }

                    a {
                        &.hd {
                            position: relative;
                            display: block;
                            padding: 0 0 0 14px;
                            margin: 0 0 8px 0; }

                        &.st {
                            position: relative;
                            display: block;
                            padding: 0 0 0 14px;
                            margin: 0 0 8px 0;

                            &:after {
                                position: absolute;
                                top: 50%;
                                left: 1px;
                                content: "";
                                width: 6px;
                                height: 6px;
                                margin-top: -3px;
                                background: #e71a0f;
                                -webkit-border-radius: 3px;
                                -moz-border-radius: 3px;
                                border-radius: 3px; } }

                        &.hd:after {
                            position: absolute;
                            top: 50%;
                            left: 1px;
                            content: "";
                            width: 6px;
                            height: 6px;
                            margin-top: -3px;
                            background: #e71a0f;
                            -webkit-border-radius: 3px;
                            -moz-border-radius: 3px;
                            border-radius: 3px; }

                        &.rd {
                            position: relative;
                            display: block;
                            padding: 0 0 0 46px;
                            margin: 4px 0 8px 0;

                            &::after {
                                position: absolute;
                                top: 50%;
                                left: 34px;
                                content: "";
                                width: 4px;
                                height: 4px;
                                margin-top: -3px;
                                background: #999;
                                -webkit-border-radius: 2px;
                                -moz-border-radius: 2px;
                                border-radius: 2px; } }

                        padding: 5px 0 5px 14px; } } } } }

    &.prepaidBoxHd {
        margin: 40px auto 0 auto;
        max-width: 1280px;

        &:first-child {
            margin: 0 auto; }

        .listBox {
            margin: (-14px) -14px 0;

            &:after {
                content: "";
                display: table;
                clear: both; }

            .slickLi {
                .conBox:after {
                    content: "";
                    display: table;
                    clear: both; }

                float: left;
                width: 408px;
                margin: 14px 14px 0;

                .mainTitle {
                    position: relative;
                    padding: 20px 20px;
                    height: 80px;
                    font-size: 16px;
                    line-height: 1.3em;
                    font-weight: bold;
                    -webkit-box-sizing: border-box;
                    -moz-box-sizing: border-box;
                    box-sizing: border-box;
                    background: #dedede;

                    img {
                        position: absolute;
                        top: 0;
                        right: 0; }

                    span {
                        display: inline-block;
                        vertical-align: middle; }

                    &:after {
                        display: inline-block;
                        vertical-align: middle;
                        content: "";
                        height: 100%; } }

                .conBox {
                    margin: 20px 0;

                    .box {
                        position: relative;
                        float: left;
                        width: 50%;
                        padding: 0 20px;
                        -webkit-box-sizing: border-box;
                        -moz-box-sizing: border-box;
                        box-sizing: border-box;

                        &.line:after {
                            position: absolute;
                            top: 0;
                            right: 0;
                            content: "";
                            width: 1px;
                            height: 100%;
                            background: #d8d8d8; } }

                    a {
                        position: relative;
                        display: block;
                        padding: 5px 0 5px 15px;
                        font-size: 15px;
                        line-height: 1.2em;

                        &:after {
                            position: absolute;
                            top: 10px;
                            left: 2px;
                            content: "";
                            width: 5px;
                            height: 5px;
                            background: #333;
                            -webkit-border-radius: 2.5px;
                            -moz-border-radius: 2.5px;
                            border-radius: 2.5px; }

                        &.hd {
                            padding: 5px 0;
                            font-size: 16px;
                            font-weight: bold; } }

                    span.hd {
                        position: relative;
                        display: block;
                        line-height: 1.2em;
                        padding: 5px 0;
                        font-size: 16px;
                        font-weight: bold; }

                    a.hd:after {
                        display: none; } } } } }

    &.appComplexBox {
        margin: 20px auto 0 auto;
        max-width: 1280px;

        &:first-child {
            margin: 0 auto; }

        &:after {
            content: "";
            display: table;
            clear: both; }

        .listBox {
            &:after {
                content: "";
                display: table;
                clear: both; }

            margin: (-14px) -7px 0 -7px;

            .conComplexBox {
                float: left;
                width: 633px;
                margin: 14px 7px 0;
                padding: 30px 35px;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                box-sizing: border-box; }

            .conBoxBd {
                position: relative;

                > a {
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    z-index: 1; } }

            .conComplexBox {
                .photo, .conBox {
                    display: table-cell;
                    vertical-align: top; }

                .photo {
                    width: 100px;

                    img {
                        max-width: 100px; } }

                .conBox {
                    padding: 5px 0 0 30px; }

                .title {
                    font-size: 16px;
                    font-weight: bold;
                    color: #333;
                    line-height: 1.4em;
                    margin: 0 0 5px 0; }

                .conBoxBd:hover .title {
                    color: #e71a0f; }

                .text {
                    font-size: 15px;
                    color: #666;
                    line-height: 1.4em; } }

            .conBoxFt {
                margin: 20px 0 0 0;
                padding: 20px 0 0 0;
                border-top: 1px #e1e1e1 solid;

                .slickLi {
                    padding: 0 20px 0 0;
                    display: table-cell;
                    vertical-align: top; } } } }

    &.prepaidBoxBd {
        margin: 20px auto 0 auto;
        max-width: 1280px;

        &:first-child {
            margin: 0 auto; }

        &:after {
            content: "";
            display: table;
            clear: both; }

        .listBox {
            &:after {
                content: "";
                display: table;
                clear: both; }

            margin: (-14px) -7px;

            > .conBox {
                float: left;
                width: 633px;
                margin: 14px 7px 0;
                border-top: 3px #e71a0f solid;
                padding: 20px 35px;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                box-sizing: border-box; } }

        .mainTitle {
            font-size: 15px;
            font-weight: bold;
            margin: 0 0 15px 0; }

        .listBox {
            .conBox .conBoxBd {
                position: relative;
                padding: 0 0 20px 0;
                margin: 0 0 20px 0;
                border-bottom: 1px #d8d8d8 solid; }

            .conBoxBd {
                .photo, .conBox {
                    display: table-cell;
                    vertical-align: top; } }

            .conBoxFt {
                .photo, .conBox {
                    display: table-cell;
                    vertical-align: top; } }

            .conBoxBd {
                .photo {
                    position: relative;
                    width: 350px;

                    img {
                        width: 100%;
                        max-width: 350px;
                        border: 1px solid #f7f7f7;
                        -webkit-box-sizing: border-box;
                        -moz-box-sizing: border-box;
                        box-sizing: border-box; } }

                .conBox {
                    padding: 0 0 0 20px;

                    .title {
                        font-size: 20px;
                        font-weight: bold;
                        line-height: 1.3em;
                        margin: 0 0 15px 0; }

                    .text {
                        font-size: 15px;
                        color: #666;
                        line-height: 1.3em; } } }

            .conBoxFt {
                .slick-slider {
                    margin-bottom: 0; }

                .slickLi {
                    position: relative; } }

            .conBoxBd a {
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 5; }

            .conBoxFt {
                a {
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    z-index: 5; }

                .photo {
                    position: relative;
                    width: 150px;

                    img {
                        width: 100%;
                        max-width: 150px;
                        border: 1px solid #f7f7f7;
                        -webkit-box-sizing: border-box;
                        -moz-box-sizing: border-box;
                        box-sizing: border-box; } }

                .conBox {
                    padding: 0 15px 0 15px;

                    .title {
                        font-size: 16px;
                        font-weight: bold;
                        line-height: 1.3em; } } } }

        .conBoxFt {
            .slick-track {
                margin: initial; }

            .slick-slider {
                .slick-prev {
                    left: -35px; }

                .slick-next {
                    right: -35px; }

                .slick-prev, .slick-next {
                    height: 60px;
                    margin-top: -30px;
                    width: 44px;
                    -moz-background-size: 30px auto;
                    -webkit-background-size: 30px auto;
                    -o-background-size: 30px auto;
                    background-size: 30px auto; } }

            .slick-dots {
                bottom: -30px; } } }

    &.ratePlanHd {
        position: relative;
        width: 1280px;
        height: 350px;
        margin: 25px auto 0 auto;

        .kvComplex {
            position: absolute;
            top: 0;
            left: 0;
            width: 1280px;
            height: 350px; }

        .mainBox {
            position: absolute;
            top: 56px;
            bottom: 40px;
            left: 50%;
            width: 1240px;
            margin: 0 0 0 -620px;
            z-index: 1;

            .mainTitle {
                color: #fff;
                font-size: 32px;
                font-weight: bold;
                margin: 0 0 15px 0; }

            .text {
                color: #fff;
                font-size: 15px;
                line-height: 1.6em;
                margin: 0 0 15px 0; }

            .start span {
                position: relative;
                padding: 10px 0 8px 0;
                color: #fff;
                font-size: 15px;
                line-height: 1.4em;
                border-bottom: 1px #fff solid;

                &:after {
                    border: solid transparent;
                    content: " ";
                    height: 0;
                    width: 0;
                    bottom: -12px;
                    left: 50%;
                    position: absolute;
                    pointer-events: none;
                    border-top-color: #fff;
                    border-width: 6px;
                    border-left-width: 5px;
                    border-right-width: 5px;
                    margin-left: -3px; } } } }

    .tabs.tabRatePlan {
        position: relative;
        margin: (-130px) 0 0 0;
        z-index: 1;

        > ul {
            width: 578px;
            margin: 0 auto 35px auto;
            border-bottom: 0;
            text-align: center;

            li {
                position: relative;
                width: 160px;
                height: 185px;
                padding: 0;
                display: inline-block;
                vertical-align: top;
                text-align: center;
                margin: 0 14px;
                border: 1px #e1e1e1 solid;
                border-bottom: 4px #afafaf solid;
                background: #fff;

                a {
                    display: table;
                    width: 100%;
                    height: 100%;
                    color: #333;
                    font-size: 16px;
                    font-weight: bold;
                    padding: 130px 10px 0 10px;
                    border-left: 0;
                    -webkit-box-sizing: border-box;
                    -moz-box-sizing: border-box;
                    box-sizing: border-box;

                    span {
                        &.link {
                            display: block;
                            position: absolute;
                            top: 20px;
                            left: 32px;
                            z-index: 1; }

                        &.hover {
                            display: block;
                            position: absolute;
                            top: 20px;
                            left: 32px;
                            z-index: 1;
                            display: none; } } }

                &.ui-tabs-active a span {
                    &.link {
                        display: none; }

                    &.hover {
                        display: block; } }

                a {
                    span.text {
                        display: table-cell;
                        vertical-align: middle; }

                    img {
                        max-width: 100px; } }

                &:nth-child(1) a {
                    border-left: 0; }

                &.arrow a {
                    // background: url(../images/icon/icon_subtab.png) no-repeat right center
                    -moz-background-size: 16px auto;
                    -webkit-background-size: 16px auto;
                    -o-background-size: 16px auto;
                    background-size: 16px auto; }

                &:hover, &.ui-tabs-active {
                    border: 1px #e71a0f solid;
                    border-bottom: 4px #e71a0f solid; }

                &.arrow {
                    a:hover {
                        // background: url(../images/icon/icon_subtabHover.png) no-repeat right center
                        -moz-background-size: 16px auto;
                        -webkit-background-size: 16px auto;
                        -o-background-size: 16px auto;
                        background-size: 16px auto; }

                    &.ui-tabs-active a {
                        // background: url(../images/icon/icon_subtabHover.png) no-repeat right center
                        -moz-background-size: 16px auto;
                        -webkit-background-size: 16px auto;
                        -o-background-size: 16px auto;
                        background-size: 16px auto;

                        &:hover {
                            // background: url(../images/icon/icon_subtabHover.png) no-repeat right center
                            -moz-background-size: 16px auto;
                            -webkit-background-size: 16px auto;
                            -o-background-size: 16px auto;
                            background-size: 16px auto; } } }

                &.ui-tabs-active:after {
                    bottom: 0;
                    left: 50%;
                    border: solid transparent;
                    content: " ";
                    height: 0;
                    width: 0;
                    position: absolute;
                    pointer-events: none;
                    border-bottom-color: #e71a0f;
                    border-width: 8px;
                    border-left-width: 10px;
                    border-right-width: 10px;
                    margin-left: -10px; } } } }

    &.ratePlanBd {
        .ratePlanBox {
            margin: 0 0 20px 0;

            .mainTitle {
                height: 50px;
                line-height: 50px;
                color: #333;
                font-size: 20px;
                background: #cfcfcf;

                .number {
                    position: relative;
                    float: left;
                    width: 70px;
                    padding: 0 0 0 10px;
                    height: 50px;
                    line-height: 50px;
                    margin: 0 40px 0 0;
                    text-align: center;
                    color: #fff;
                    font-size: 24px;
                    font-weight: bold;
                    background: #b6b6b6;

                    &:after {
                        top: 0;
                        left: 100%;
                        border: solid transparent;
                        content: " ";
                        height: 0;
                        width: 0;
                        position: absolute;
                        pointer-events: none;
                        border-left-color: #b6b6b6;
                        border-width: 15px;
                        border-top-width: 25px;
                        border-bottom-width: 25px;
                        margin-left: 0; } }

                &.on .number {
                    background: #e71a0f;

                    &:after {
                        border-left-color: #e71a0f; } } }

            ul {
                display: table;
                margin: 0 -10px;

                li {
                    width: 20%;
                    display: table-cell;
                    vertical-align: top;

                    .boxComplex {
                        position: relative;
                        padding: 20px;
                        margin: 15px 10px;
                        height: 110px;
                        font-size: 15px;
                        line-height: 1.4em;
                        text-align: center;
                        -webkit-box-sizing: border-box;
                        -moz-box-sizing: border-box;
                        box-sizing: border-box;
                        border: 1px #dbdbdb solid;
                        background: #fff; }

                    &.active .boxComplex:after {
                        position: absolute;
                        top: 0;
                        left: 0;
                        content: " ";
                        height: 37px;
                        width: 37px;

 }                        // background: url(../images/icon/icon_selectTick.png) no-repeat

                    .boxComplex a {
                        display: block;
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        width: 100%;
                        height: 100%;
                        z-index: 1; }

                    &:hover .boxComplex {
                        border: 1px #e71a0f solid; }

                    &.active .boxComplex {
                        border: 1px #e71a0f solid;
                        -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
                        -moz-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
                        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3); }

                    .boxComplex {
                        .vertical {
                            display: table;
                            width: 100%;
                            height: 70px; }

                        p {
                            color: #666;
                            display: table-cell;
                            vertical-align: middle; }

                        span {
                            color: #e71a0f; } } } } }

        .resultBox {
            border: 1px #e71a0f solid;
            border-top: 3px #e71a0f solid;

            &:after {
                content: "";
                display: table;
                clear: both; }

            .leftColumn {
                float: left;
                width: 475px;
                padding: 30px 30px 40px 30px;
                min-height: 465px;
                color: #333;
                background: #e4e4e4;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                box-sizing: border-box;

                .mainTitle {
                    padding: 12px 0 0 47px;
                    margin: 0 0 10px 0;
                    font-size: 24px;
                    font-weight: bold;
                    line-height: 1.8em;

 }                    // background: url(../images/icon/icon_rateRed.png) no-repeat

                .text {
                    position: relative;
                    padding: 0 30px 0 50px;
                    font-size: 15px;
                    line-height: 1.8em;
                    min-height: 320px;

                    &:after {
                        position: absolute;
                        top: 0;
                        left: 0;
                        content: "";
                        width: 40px;
                        height: 40px;

 }                        // background: url(../images/icon/icon_symbolLeft.png) no-repeat

                    &:before {
                        position: absolute;
                        bottom: 0;
                        right: -10px;
                        content: "";
                        width: 40px;
                        height: 40px;

 } } }                        // background: url(../images/icon/icon_symbolRight.png) no-repeat

            .rightColumn {
                position: relative;
                float: right;
                width: 800px;
                padding: 40px;
                min-height: 465px;
                color: #666;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                box-sizing: border-box;

                .subTitle {
                    font-size: 16px;
                    margin: 0 0 15px 0; }

                .mainTitle {
                    margin: 0 0 10px 0;
                    color: #e71a0f;
                    font-size: 20px;
                    font-weight: bold;
                    line-height: 1.4em; }

                .text {
                    padding: 0 0 20px 0;
                    font-size: 15px;
                    line-height: 1.8em;
                    border-bottom: 1px #e1e1e1 solid; }

                &.editBox .btnDownBox {
                    margin: 30px 0 0 0; }

                .desc {
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 20px;
                    width: 100%;
                    color: #999;
                    font-size: 13px;
                    text-align: center; } } }

        .detailBoxBd {
            margin: 0;
            padding: 0; } }

    &.searchBox {
        margin: 20px auto 0 auto;
        max-width: 1280px;

        &:first-child {
            margin: 0 auto; }

        &:after {
            content: "";
            display: table;
            clear: both; }

        .schLeftColumn {
            float: left;
            width: 1000px;
            min-height: 500px;

            .schresulttxt {
                margin: 0 0 20px 0;
                padding: 0 0 0 10px;
                border-left: 3px #e92f20 solid;
                font-size: 15px; }

            .schnonetxt {
                margin: 0 0 20px 0;
                font-size: 18px;
                color: #666; }

            ul.noneMsg {
                font-size: 15px;
                color: #666;
                margin: 0 0 0 20px;

                li {
                    position: relative;
                    padding: 4px 0 4px 18px;

                    &:after {
                        position: absolute;
                        top: 8px;
                        left: 2px;
                        content: "";
                        width: 7px;
                        height: 7px;
                        background: #666;
                        -webkit-border-radius: 3.5px;
                        -moz-border-radius: 3.5px;
                        border-radius: 3.5px; } } }

            .txtAD {
                width: 640px;
                height: 100px;
                overflow: hidden;
                margin: 40px 0 0 0;
                border-top: 1px #dedede solid; }

            ul.noneDesc {
                width: 640px;
                height: 100px;
                padding: 20px 0 20px 10px;
                font-size: 13px;
                background: #f2f2f2;

                li {
                    position: relative;
                    padding: 4px 0 4px 18px;

                    &:after {
                        position: absolute;
                        top: 8px;
                        left: 4px;
                        content: "";
                        width: 4px;
                        height: 4px;
                        background: #333;
                        -webkit-border-radius: 2px;
                        -moz-border-radius: 2px;
                        border-radius: 2px; } } } } }

    &.recommendBox .detailBoxBd .accordion {
        position: relative;
        padding: 0 35px 0;
        margin: 17px 0 0 0;
        border: 1px #dfdfdf solid;

        &.open:first-child {
            margin: 0 0 40px 0; }

        &:first-child {
            margin: 0 0 17px 0; }

        &.open {
            margin-bottom: 40px;
            border-bottom: 1px #c1c1c1 solid; } }

    &.searchBox .schLeftColumn b, &.recommendBox .detailBoxBd .accordion .mainTitle b {
        color: #e71a0f; }

    &.searchBox {
        .detailBoxBd {
            margin: 20px 0 0;
            padding: 20px 20px; }

        .schLeftColumn {
            .schmainbox {
                margin: 20px 0 0;
                padding: 20px 0 0;
                border-top: 1px #dedede solid;

                &:first-child {
                    margin: 0;
                    padding: 0;
                    border-top: 0; } }

            .mainTitle {
                position: relative;
                color: #333;
                font-size: 18px;
                font-weight: bold;
                line-height: 1.2em;
                z-index: 1; } } }

    &.recommendBox .detailBoxBd .accordion > .mainTitle {
        position: relative;
        color: #333;
        font-size: 18px;
        font-weight: bold;
        line-height: 1.2em;
        z-index: 1;
        font-size: 16px; }

    &.searchBox .schLeftColumn .mainTitle span, &.recommendBox .detailBoxBd .accordion .mainTitle span {
        padding: 0 10px;
        border-left: 2px #333 solid; }

    &.searchBox .schLeftColumn .mainTitle span:first-child, &.recommendBox .detailBoxBd .accordion .mainTitle span:first-child {
        padding: 0 10px 0 0;
        border-left: 0; }

    &.searchBox .schLeftColumn .mainTitle a.trigger, &.recommendBox .detailBoxBd .leftColumn .mainTitle a.trigger {
        position: absolute;
        right: 15px;
        top: 50%;
        display: block;
        width: 30px;
        height: 30px;
        font-size: 0;
        margin: (-15px) 0 0 0;

        // background: url(../images/icon/icon_close.png) no-repeat
        -ms-transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg); }

    &.searchBox .schLeftColumn .mainTitle a.trigger:hover, &.recommendBox .detailBoxBd .leftColumn .mainTitle a.trigger:hover {
 }        // background: url(../images/icon/icon_descboxClose.png) no-repeat

    &.searchBox .schLeftColumn .mainTitle a.trigger.odd {
        // background: url(../images/icon/icon_close_notifyBoxHover.png) no-repeat
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg); }

    &.recommendBox .detailBoxBd .leftColumn .mainTitle a.trigger {
        &.odd {
            // background: url(../images/icon/icon_close_notifyBoxHover.png) no-repeat
            -webkit-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg); }

        -moz-background-size: 30px auto;
        -webkit-background-size: 30px auto;
        -o-background-size: 30px auto;
        background-size: 30px auto;

        &:hover {
            -moz-background-size: 30px auto;
            -webkit-background-size: 30px auto;
            -o-background-size: 30px auto;
            background-size: 30px auto; }

        &.odd {
            -moz-background-size: 30px auto;
            -webkit-background-size: 30px auto;
            -o-background-size: 30px auto;
            background-size: 30px auto;

            &:hover {
                -moz-background-size: 30px auto;
                -webkit-background-size: 30px auto;
                -o-background-size: 30px auto;
                background-size: 30px auto; } } }

    &.searchBox .schLeftColumn {
        .mainTitle a.trigger {
            -moz-background-size: 30px auto;
            -webkit-background-size: 30px auto;
            -o-background-size: 30px auto;
            background-size: 30px auto;

            &:hover {
                -moz-background-size: 30px auto;
                -webkit-background-size: 30px auto;
                -o-background-size: 30px auto;
                background-size: 30px auto; }

            &.odd {
                -moz-background-size: 30px auto;
                -webkit-background-size: 30px auto;
                -o-background-size: 30px auto;
                background-size: 30px auto;

                &:hover {
                    -moz-background-size: 30px auto;
                    -webkit-background-size: 30px auto;
                    -o-background-size: 30px auto;
                    background-size: 30px auto; } } }

        .schBoxHd {
            margin: -20px;
            padding: 15px;
            padding: 15px 60px 15px 15px;
            background: #dfdfdf;
            cursor: pointer; } }

    &.recommendBox .detailBoxBd {
        .leftColumn .schBoxHd {
            margin: -20px;
            padding: 15px;
            padding: 15px 60px 15px 15px;
            background: #dfdfdf;
            cursor: pointer; }

        .mainTitle.o2orrHd {
            background: #f2f2f2;
            border-bottom: 1px #999 solid;
            -webkit-box-shadow: 0 3px 3px rgba(100, 100, 100, 0.15);
            -moz-box-shadow: 0 3px 3px rgba(100, 100, 100, 0.15);
            box-shadow: 0 3px 3px rgba(100, 100, 100, 0.15); }

        .leftColumn .schBoxHd {
            margin: 0 -35px 0; } }

    &.searchBox .schLeftColumn .accordion.open .schBoxHd {
        margin: (-20px) -20px 20px; }

    &.recommendBox .detailBoxBd .leftColumn .accordion.open .schBoxHd {
        margin: 0 -35px 20px; }

    &.searchBox .schLeftColumn {
        .schmainbox .text {
            margin: 10px 0 0 0;
            color: #666;
            font-size: 15px;
            line-height: 1.4em;

            a {
                color: #666;

                &:hover {
                    color: #e71a0f; } } }

        .slickLi {
            margin: 0 9px;
            text-align: center; }

        .slick-slider {
            .slick-prev {
                left: -40px; }

            .slick-next {
                right: -40px; } }

        .slick-dots {
            bottom: -38px; }

        .slickDevice .photo {
            position: relative;
            width: 230px;
            height: 230px;
            text-align: center;
            margin: 0 auto 10px auto; }

        .photo .msgBox {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 50%;
            height: 40px;
            line-height: 40px;
            margin: auto;
            font-size: 13px;
            text-align: center;
            color: #fff;
            background: rgba(0, 0, 0, 0.6);
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            z-index: 1; }

        .slickDevice .photo img {
            display: inline;
            height: 100%;
            max-height: 250px;
            max-width: 250px;
            max-width: 230px;
            max-height: 230px; }

        .slickOther .photo {
            position: relative;
            width: 220px;
            height: 220px;
            display: table-cell;
            vertical-align: middle;
            text-align: center;
            margin: 0 auto 10px auto;

            a {
                display: inline-block;
                vertical-align: middle;

                &:after {
                    width: 0;
                    height: 100%;
                    vertical-align: middle; } }

            &.imgShadow .shadow {
                bottom: 15px;
                left: 15px;
                right: 15px;
                top: 15px; }

            img {
                display: inline;
                max-width: 190px;
                max-height: 190px; }

            &.imgShadow .icon {
                position: absolute;
                left: 15px;
                bottom: 15px;
                right: 15px;
                text-align: center;
                height: 22px;
                color: #fff;
                font: 12px / 22px "Arial";

 } }                // background: url(../images/icon/bg_through.png) repeat

        .line {
            position: relative;
            border: 1px solid #d8d8d8;
            padding: 30px 0 10px;

            .label {
                position: absolute;
                top: 0;
                right: 0;
                width: 66px;
                height: 64px;
                z-index: 1; } }

        .slickOther .line {
            padding: 0 0 10px; }

        .iconBox {
            position: relative;
            height: 54px;
            padding: 10px 0 20px 0;
            z-index: 2;

            .notifyBox {
                position: absolute;
                bottom: 72px;
                left: 7px;
                right: 7px;
                height: 68px;

                .notifyBoxBd {
                    height: 58px;
                    padding: 8px 40px 8px 10px;
                    font-size: 15px;
                    line-height: 1.4em;
                    text-align: left;
                    color: #fff;
                    background: #835935;
                    -webkit-box-sizing: border-box;
                    -moz-box-sizing: border-box;
                    box-sizing: border-box;
                    z-index: 1; }

                span.buy {
                    padding: 0 15px 0 5px;
                    font-weight: bold;

                    // background: url(../images/icon/icon_moreBottom.png) no-repeat right center
                    -moz-background-size: 20px auto;
                    -webkit-background-size: 20px auto;
                    -o-background-size: 20px auto;
                    background-size: 20px auto; }

                a {
                    &.close {
                        position: absolute;
                        top: 7px;
                        right: 7px;
                        display: inline-block;
                        width: 20px;
                        height: 20px;
                        font-size: 0;
                        cursor: pointer;

                        // background: url(../images/icon/icon_close.png) no-repeat
                        -moz-background-size: 20px auto;
                        -webkit-background-size: 20px auto;
                        -o-background-size: 20px auto;
                        background-size: 20px auto; }

                    color: #fff;

                    &:hover {
                        color: #fff; } } }

            ul {
                padding: 0 4px 0 0;
                text-align: center;

                li {
                    display: inline-block;
                    padding: 2px 4px;
                    margin: 0 -4px 0 0;
                    border-left: 1px #aaa solid; } } }

        .slickDevice .iconBox ul li {
            border-left: 1px #c3a78a solid; }

        .iconBox {
            ul li {
                &:nth-child(1) {
                    border: 0; }

                &:hover {
                    background: #e5e5e5; }

                > a {
                    position: relative;
                    display: block; }

                &.msgComplex {
                    > a:after {
                        top: -10px;
                        left: 21px;
                        border: solid transparent;
                        content: " ";
                        height: 0;
                        width: 0;
                        position: absolute;
                        pointer-events: none;
                        border-top-color: #835935;
                        border-width: 8px;
                        border-left-width: 9px;
                        border-right-width: 9px;
                        filter: alpha(opacity = 0);
                        -moz-opacity: 0;
                        opacity: 0;
                        -webkit-transition: all 0.4s;
                        -moz-transition: all 0.4s;
                        -o-transition: all 0.4s;
                        transition: all 0.4s; }

                    &.arrow > a:after {
                        filter: alpha(opacity = 100);
                        -moz-opacity: 1;
                        opacity: 1;
                        -webkit-transition: all 0.4s;
                        -moz-transition: all 0.4s;
                        -o-transition: all 0.4s;
                        transition: all 0.4s; } } }

            img {
                display: inline;
                width: 54px; } }

        .schinner {
            max-height: 0;
            -webkit-transition: max-height 0.2s;
            -moz-transition: max-height 0.2s;
            -o-transition: max-height 0.2s;
            transition: max-height 0.2s; } }

    &.recommendBox .detailBoxBd .leftColumn .schinner, &.searchBox .schLeftColumn .schinner .schconBox {
        max-height: 0;
        -webkit-transition: max-height 0.2s;
        -moz-transition: max-height 0.2s;
        -o-transition: max-height 0.2s;
        transition: max-height 0.2s; }

    &.recommendBox .detailBoxBd .leftColumn .schinner {
        overflow: hidden; }

    &.searchBox .schLeftColumn {
        .schinner .schconBox {
            margin: 0 -30px;
            padding: 0 30px;
            overflow: hidden; }

        .accordion.open .schinner {
            max-height: 999999px;
            -webkit-transition: max-height 0.3s;
            -moz-transition: max-height 0.3s;
            -o-transition: max-height 0.3s;
            transition: max-height 0.3s; } }

    &.recommendBox .detailBoxBd .leftColumn .accordion.open .schinner, &.searchBox .schLeftColumn .accordion.open .schinner .schconBox {
        max-height: 999999px;
        -webkit-transition: max-height 0.3s;
        -moz-transition: max-height 0.3s;
        -o-transition: max-height 0.3s;
        transition: max-height 0.3s; }

    &.recommendBox .detailBoxBd .leftColumn .accordion.open .schinner {
        margin: 0 0 30px 0; }

    &.searchBox {
        .schLeftColumn {
            .schinner {
                .title {
                    position: relative;
                    width: 90%;
                    border-top: 0;
                    margin: 10px auto;
                    padding: 10px 0 0 0;
                    font-size: 16px;
                    font-weight: bold;
                    line-height: 1.5em;
                    height: 3em;
                    display: table;
                    vertical-align: middle;
                    z-index: 1; }

                .slickOther .title {
                    padding: 0; }

                .title span {
                    display: table-cell;
                    vertical-align: middle; }

                .slickOther .title span a {
                    display: block;

                    &.txtComplex {
                        font-weight: normal;
                        color: #666;
                        font-size: 15px; }

                    &:hover.txtComplex {
                        color: #e71a0f; } }

                .title a {
                    &.icon {
                        position: relative;
                        display: inline-block;
                        vertical-align: top;
                        width: 25px;
                        height: 24px;

                        img {
                            position: absolute;
                            top: 0;
                            left: 0;
                            max-width: 25px;
                            max-height: 24px;

                            &.hover {
                                display: none; } } }

                    &:hover.icon img.link {
                        display: none; }

                    &.icon img.link, &:hover.icon img.hover {
                        display: block; } } }

            .schmoer {
                margin: (-5px) 0 5px 0;
                text-align: right;

                &:after {
                    content: "";
                    display: table;
                    clear: both; }

                a {
                    position: relative;
                    float: right;
                    display: block;
                    padding: 0 16px 0 0;
                    font-size: 13px;

                    // background: url(../images/icon/icon_more.png) no-repeat right center
                    -webkit-background-size: 16px auto;
                    -o-background-size: 16px auto;
                    background-size: 16px auto;
                    z-index: 1;

                    &:hover {
                        color: #e71a0f;

                        // background: url(../images/icon/icon_moreHover.png) no-repeat right center
                        -webkit-background-size: 16px auto;
                        -o-background-size: 16px auto;
                        background-size: 16px auto; } } } }

        .schSidebar {
            float: right;
            width: 250px;

            .schhotbox {
                font-size: 15px;

                &.line {
                    border: 1px #d5d5d5 solid; }

                .mainTitle {
                    padding: 8px 12px;
                    color: #666;
                    background: #dfdfdf;
                    -webkit-box-sizing: border-box;
                    -moz-box-sizing: border-box;
                    box-sizing: border-box; }

                ul {
                    padding: 8px 12px;
                    color: #333;
                    -webkit-box-sizing: border-box;
                    -moz-box-sizing: border-box;
                    box-sizing: border-box;

                    li {
                        padding: 4px 0; } } }

            .fetAD {
                margin: 20px 0 0 0;
                min-height: 222px;

                .mainTitle {
                    font-weight: bold;
                    line-height: 18px; }

                .text {
                    line-height: 16px;
                    color: #666;

                    a {
                        color: #666;

                        &:hover {
                            color: #e71a0f; } } }

                .fetADBox {
                    min-height: 222px;
                    padding: 8px 12px;
                    -webkit-box-sizing: border-box;
                    -moz-box-sizing: border-box;
                    box-sizing: border-box;
                    background: #dfdfdf; }

                ul {
                    font-size: 12px;
                    color: #333;
                    width: 206px;
                    height: 190px;
                    margin: 8px auto 0 auto;
                    overflow: hidden;

                    li {
                        padding: 6px 0; } } } } } }

@media screen and (-webkit-min-device-pixel-ratio: 0) {
    article.roamBox {
        .selectboxit-container span {
            height: 38px;
            line-height: 38px; }

        .selectBox input[type="text"] {
            line-height: 36px;
            height: 36px; } } }

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    article.roamBox .selectBox input[type="text"] {
        height: 30px;
        line-height: 30px;
        text-indent: 0; } }

@media screen and (-webkit-min-device-pixel-ratio: 0) {
    article.roamBox .selectBox input[type="submit"] {
        line-height: 35px; } }

/**
 **
 ** rateBox
 **
 ** */

/**
 **
 ** brandBox
 **
 ** */

/**
 **
 ** brandBox
 **
 ** */

/**
 **
 ** stepStyle1
 **
 ** */

/**
 **
 ** stepStyle2
 **
 ** */

/**
 **
 ** stepStyle3
 **
 ** */

/**
 **
 ** stepStyle4
 **
 ** */

/**
 **
 ** qaBoxComplex
 **
 ** */

/**
 **
 ** sidebar
 **
 ** */

/* 20180129
 *article.proDetailBox.prepaid.wifi .detailBoxBd .wifiBoxComplex .wifiBoxHd ul li:nth-child(3n) {
 *    width: 16%;
 *} */

/**
 **
 ** sidebar
 **
 ** */

/**
 **
 ** prepaidBoxHd
 **
 ** */

/**
 **
 ** prepaidBoxBd
 **
 ** */

/**
 **
 ** prepaidBoxBd
 **
 ** */

/**
 **
 ** mainBoxHd
 **
 ** */

/**
 **
 ** searchBox
 **
 ** */

/** styled table with links */

.tableBox {
    color: #666;
    font-size: 15px;
    line-height: 1.6em;

    * {
        color: #666;
        font-size: 15px;
        line-height: 1.6em; }

    th, td {
        padding: 8px 10px;
        font-size: 15px; }

    th {
        color: #333;
        background: #f2e6d6;

        &.left {
            text-align: left !important; } }

    td.left {
        text-align: left !important; }

    th.center, td.center {
        text-align: center !important; }

    th.right, td.right {
        text-align: right !important; }

    th.top, td.top {
        vertical-align: top !important; }

    table {
        th img, td img {
            border: 0; }

        &.AlistTable {
            margin: 20px 0 0 0;

            &:first-child {
                margin: 0; }

            th, td {
                padding: 15px 15px;
                text-align: center;
                vertical-align: middle;
                border: 1px #e1e1e1 solid; }

            tr {
                &:hover {
                    background: #f3f3f3; }

                cursor: pointer; }

            th {
                border: 0;
                border-bottom: 1px #e1e1e1 solid; }

            td {
                border: 0;
                border-bottom: 1px #e1e1e1 solid;

                &.linkTile {
                    color: #333;
                    font-weight: bold;
                    padding-right: 100px;

                    &:hover {
                        color: #e71a0f; }

                    > a {
                        display: inline-block; } } }

            tr.firstrow td {
                padding-top: 10px; } } } }

/** winnerBox */

article {
    &.winnerBox {
        width: 1280px;
        margin: 0 auto; }

    &.proDetailBox.winner {
        margin: 0 auto;
        padding: 0; }

    &.winnerBox {
        .detailBoxBd {
            padding: 0; }

        .mainBoxHd {
            position: relative;
            width: 1278px;
            border: 1px #e1e1e1 solid;

            img {
                width: 100%; }

            .mainTitle {
                position: absolute;
                width: 1200px;
                height: 38px;
                left: 50%;
                top: 50%;
                margin-left: -600px;
                margin-top: -19px;
                font-size: 40px;
                font-weight: bold;
                line-height: 1.2em;
                color: #666; } }

        .singleColumn {
            width: 1200px;
            margin: 0 auto;
            padding: 30px 0;

            .mainTitle {
                margin-top: 35px;

                a {
                    text-decoration: none; } }

            .subTitle {
                position: relative;
                margin: 0 0 0.2em;
                padding: 0 0 0 15px;
                color: #333;
                font-size: 18px;
                font-weight: bold;
                line-height: 1.4em;

                &:after {
                    position: absolute;
                    top: 3px;
                    left: 0;
                    content: "";
                    width: 3px;
                    height: 20px;
                    background: #999; } }

            h3.font20b a {
                font-size: 20px !important;
                font-weight: bold !important; }

            .tdBox {
                display: table-cell;
                vertical-align: top;

                a {
                    display: block;
                    color: #e71a0f; } }

            .winList {
                margin: 15px 0 0 0; } } }

    &.ppsalBox {
        width: 1280px;
        margin: 0 auto;

        article.proDetailBox, .detailBoxBd {
            padding: 0; }

        article.proDetailBox {
            margin: 0 auto; }

        .mainBoxHd {
            padding: 15px 30px;
            background: #dfdfdf;

            .mainTitle {
                font-size: 16px;
                font-weight: bold;
                color: #333; }

            .reminder {
                font-size: 13px;
                height: 19px;
                padding: 15px 0 0 0;
                color: #d62a1e;

                em {
                    position: relative;
                    top: 3px;
                    padding: 0 3px;
                    color: #d62a1e; } } }

        .detailBoxBd .ppsalFormbox {
            padding: 15px 0 30px 0; } } }

/** ppsalBox */

.ppsalFormbox fieldset {
    &.formContent {
        width: 1100px;
        margin: 0 auto; }

    .box {
        padding: 15px 0;
        border-top: 1px solid #e1e1e1;

        &.first {
            padding-top: 20px;
            border-top: 0; }

        &.odd {
            padding: 5px 0;
            border-top: 0; }

        &:after {
            content: "";
            display: table;
            clear: both; }

        &.error .txt {
            margin: 5px 0 0 0;
            font-size: 13px;
            color: #d42c27; }

        .text b {
            font-size: 13px;
            font-weight: normal;
            color: #e71a0f; }

        .hfBox {
            width: 50%;

            &.left {
                float: left; }

            &.right {
                float: right; } }

        .fullBox {
            width: 100%; } }

    label {
        position: relative;
        width: 110px;
        padding: 0 0 0 10px;

        &:after {
            position: absolute;
            top: 14px;
            left: 0;
            display: block;
            content: "";
            width: 6px;
            height: 6px;
            background: #bbb; } }

    .box {
        label {
            float: left;
            font-size: 15px;
            line-height: 36px;
            color: #666; }

        &.reminder {
            font-size: 13px;
            height: 19px;
            padding: 15px 0 0 0;
            color: #d62a1e; }

        .con {
            float: left;
            font-size: 16px;
            color: #333; } }

    &.comfirm .box .con {
        line-height: 1.6em;
        padding: 5px 0; }

    .box {
        .con.rowComplex_2 .boxComplex label {
            float: none;
            cursor: pointer;
            font-size: 16px;

            &:after {
                display: none; } }

        .hfBox .con {
            width: 390px;
            margin-right: 30px; }

        .fullBox .con {
            width: 950px;
            margin-right: 30px;

            textarea {
                width: 940px; } }

        p {
            min-height: 34px;
            line-height: 34px;
            font-size: 16px;
            color: #333;

            &.font15 {
                min-height: auto;
                line-height: 1.6em;
                color: #666; } }

        span.note {
            display: inline-block;
            width: 100%;
            position: relative;
            top: -10px; }

        .con .code {
            margin: 0 0 10px 0;

            img {
                vertical-align: bottom; }

            a {
                &.renew {
                    padding: 0 20px 0 0;

                    // background: url(../images/icon/icon_refresh.png) no-repeat right center
                    -moz-background-size: 16px auto;
                    -webkit-background-size: 16px auto;
                    -o-background-size: 16px auto;
                    background-size: 16px auto; }

                &:hover.renew {
                    // background: url(../images/icon/icon_refreshHover.png) no-repeat right center
                    -moz-background-size: 16px auto;
                    -webkit-background-size: 16px auto;
                    -o-background-size: 16px auto;
                    background-size: 16px auto; } } } }

    em {
        position: relative;
        top: 3px;
        padding: 0 3px;
        color: #d62a1e; }

    input {
        &[type="text"], &[type="email"], &[type="password"] {
            width: 100%;
            padding: 0;
            margin: 0;
            height: 32px;
            line-height: 32px;
            font-size: 14px;
            font-family: Arial, "Heiti TC", "Microsoft JhengHei", Helvetica, sans-serif;
            text-indent: 10px;
            vertical-align: middle;
            color: #000;
            background: #fff;
            border: 1px solid #adadad;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box; } }

    textarea {
        width: 100%;
        padding: 0;
        margin: 0;
        height: 32px;
        line-height: 32px;
        font-size: 14px;
        font-family: Arial, "Heiti TC", "Microsoft JhengHei", Helvetica, sans-serif;
        text-indent: 10px;
        vertical-align: middle;
        color: #000;
        background: #fff;
        border: 1px solid #adadad;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        min-height: 64px;
        line-height: 1.2em;
        padding: 6px 10px;
        text-indent: 0;
        resize: vertical; }

    input {
        &[type="text"]:focus, &[type="email"]:focus, &[type="password"]:focus {
            background: #fcfaf4;
            border: 1px solid #ab5f00; } }

    textarea:focus {
        background: #fcfaf4;
        border: 1px solid #ab5f00; }

    .errorMsgBd {
        width: 100%;
        max-width: 680px;
        min-width: 510px;
        padding: 10px 0;
        text-align: center;
        font-size: 15px;
        color: #d42c27;
        background: #fff4e5;
        margin: 10px auto;

        img {
            width: 21px;
            height: 18px;
            vertical-align: bottom; } }

    .btnBox {
        clear: both;
        margin: 20px auto;
        text-align: center;

        > div {
            position: relative;
            display: inline-block;
            vertical-align: middle; }

        input {
            &[type="button"], &[type="submit"], &[type="reset"] {
                position: relative;
                margin: 0 5px;
                padding: 0 20px;
                color: #000;
                font-size: 13px;
                line-height: 2em;
                vertical-align: middle;
                border: 1px #afafaf solid;
                cursor: pointer;
                -webkit-border-radius: 3px;
                -moz-border-radius: 3px;
                border-radius: 3px;
                background: #f3f3f3;
                background: -moz-linear-gradient(top, #f3f3f3 0%, #e0e0e0 100%);
                background: -webkit-linear-gradient(top, #f3f3f3 0%, #e0e0e0 100%);
                background: linear-gradient(to bottom, #f3f3f3 0%, #e0e0e0 100%);
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f3f3f3', endColorstr='#e0e0e0', GradientType=0); }

            &[type="button"]:hover, &[type="submit"]:hover, &[type="reset"]:hover {
                border: 1px #ab5f00 solid; } } }

    .msgDescBox ul li {
        position: relative;
        padding: 0 0 0 10px;
        font-size: 15px;
        line-height: 1.6em;
        color: #666;

        &:after {
            position: absolute;
            top: 8px;
            left: 2px;
            content: "";
            width: 5px;
            height: 5px;
            background: #999;
            -webkit-border-radius: 2.5px;
            -moz-border-radius: 2.5px;
            border-radius: 2.5px; } }

    .box {
        .con {
            &.rowComplex_2 {
                .boxComplex {
                    position: relative;
                    display: inline-block;
                    vertical-align: middle;
                    word-wrap: break-word;

                    // -moz-binding: url("js/wordwrap.xml#wordwrap")
                    color: #666; }

                max-width: 720px;
                line-height: 1em;
                margin: 5px 0; }

            &.rowComplex_4 {
                width: 950px;
                margin-right: 30px;

                input[type="text"] {
                    width: 200px;
                    height: 46px;
                    line-height: 46px; }

                .code {
                    display: inline-block;
                    vertical-align: top; } } }

        .fileBox {
            float: left;
            position: relative;
            width: 31.3%;
            height: 200px;
            margin: 10px 0;
            margin-right: 1%;
            background: #e1e1e1;
            text-align: center;
            font-size: 16px;
            line-height: 1.4em;
            color: #666;

            &.highlight {
                transition: background linear 0.6s;
                -moz-transition: background linear 0.6s;
                -webkit-transition: background linear 0.6s;
                -o-transition: background linear 0.6s;
                background: #bfbebe;
                color: #333; }

            .uploadArea {
                a, .txt {
                    color: #666; } }

            &.highlight .uploadArea {
                a, .txt {
                    color: #333; } }

            &.uploaded .uploadArea {
                a, .txt {
                    color: #e71a0f; } }

            .lable {
                position: relative;
                width: 0;
                height: 0;
                padding-bottom: 50px;
                padding-left: 50px;
                overflow: hidden;

                &:after {
                    content: "";
                    display: block;
                    width: 0;
                    height: 0;
                    margin-left: -50px;
                    border-bottom: 50px solid transparent;
                    border-left: 50px solid #e71a0f; }

                > span {
                    position: absolute;
                    left: 6px;
                    top: 6px;
                    display: inline-block;
                    font-size: 24px;
                    line-height: 1em;
                    color: #fff; } }

            .uploadArea {
                position: absolute;
                left: 0;
                top: 0;
                display: inline-block;
                width: 100%;
                height: 100%;
                vertical-align: middle;

                > span.uploadAreaBox {
                    position: absolute;
                    left: 0;
                    top: 0;
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                    vertical-align: middle;
                    cursor: pointer; } }

            &.uploaded .uploadArea .txt > a {
                position: relative;
                display: inline-block;
                padding: 0 0 0 30px;
                margin: 1px 0 0 10px;
                color: #333;

                // background: url(../images/icon/icon_uploaded_close.png) no-repeat 10px 3px
                -moz-background-size: 16px auto;
                -webkit-background-size: 16px auto;
                -o-background-size: 16px auto;
                background-size: 16px auto;
                filter: alpha(opacity = 60);
                -moz-opacity: 0.6;
                opacity: 0.6;
                z-index: 9;

                &:after {
                    position: absolute;
                    top: 3px;
                    left: 0;
                    bottom: 3px;
                    width: 1px;
                    content: "";
                    background: #333; }

                &:hover {
                    filter: alpha(opacity = 100);
                    -moz-opacity: 1;
                    opacity: 1; } }

            .uploadArea {
                .obj {
                    width: 100%;
                    display: inline-block; }

                .icon {
                    height: 128px;

                    img {
                        position: absolute;
                        top: 38%;
                        left: 50%;
                        width: 52px;
                        height: 70px;
                        margin-top: -35px;
                        margin-left: -26px;
                        transition: all linear 0.1s;
                        -moz-transition: all linear 0.1s;
                        -webkit-transition: all linear 0.1s;
                        -o-transition: all linear 0.1s; } } }

            &.highlight .uploadArea .icon img {
                width: 62px;
                height: 84px;
                margin-top: -42px;
                margin-left: -31px; } } } }

/** locationBox */

article {
    &.locationBox {
        width: 1280px;
        margin: 0 auto;

        .textBox {
            padding: 160px 0 0 0;
            text-align: center; }

        article.proDetailBox .detailBoxBd {
            padding: 30px;
            min-height: 500px; }

        table {
            border-bottom: 1px #e1e1e1 solid;

            td {
                height: 40px; }

            tr.infoBox {
                &:hover {
                    background: #f3f3f3; }

                td:last-child {} }

            .btn_pin {
                width: 22px;
                height: 40px;
                display: inline-block;
                vertical-align: middle; }

            .distance {
                width: 105px;
                display: inline-block;
                vertical-align: middle; }

            tr {
                &.infoBox.on {
                    background: #f3f3f3; }

                &.mapBox td {
                    padding: 0;
                    line-height: normal;
                    height: 0;
                    overflow: hidden;
                    border: 0;
                    -webkit-transition: height 0.3s;
                    -moz-transition: height 0.3s;
                    -o-transition: height 0.3s;
                    transition: height 0.3s; } }

            td #googlemapBox {
                position: relative; }

            tr.mapBox {
                &.on td {
                    height: 490px;
                    border: 1px solid #e1e1e1;
                    -webkit-transition: height 0.3s;
                    -moz-transition: height 0.3s;
                    -o-transition: height 0.3s;
                    transition: height 0.3s; }

                .mapContent {
                    height: 0;
                    overflow: hidden;
                    -webkit-transition: height 0.3s;
                    -moz-transition: height 0.3s;
                    -o-transition: height 0.3s;
                    transition: height 0.3s; }

                &.on .mapContent {
                    height: 490px;
                    overflow: inherit;
                    -webkit-transition: height 0.3s;
                    -moz-transition: height 0.3s;
                    -o-transition: height 0.3s;
                    transition: height 0.3s; }

                .topArrows {
                    position: relative;
                    width: 100%;
                    height: 3px;
                    background: #d1d1d1;
                    display: none; }

                &.on .topArrows {
                    display: block; }

                .topArrows .aw {
                    position: absolute;
                    top: -10px;
                    right: 24px;
                    width: 20px;
                    height: 20px;

                    > span {
                        width: 0;
                        height: 0;
                        overflow: hidden;

                        &:after {
                            content: "";
                            display: block;
                            width: 0;
                            height: 0;
                            border-left: 10px solid transparent;
                            border-right: 10px solid transparent;
                            border-bottom: 10px solid #d1d1d1; } } } } } }

    &.regulationBox {
        width: 1280px;
        margin: 0 auto;
        display: table;

        article {
            &.leftDetailBox {
                display: table-cell;
                width: 240px;
                padding: 20px;
                border-top: 3px #bebebe solid; }

            &.midBlockBox {
                display: table-cell;
                vertical-align: top;
                max-width: 20px;
                padding: 10px; }

            &.rightDetailBox {
                display: table-cell;
                vertical-align: top;
                max-width: 940px;
                padding: 35px; } }

        .tabsContent {
            ul li {
                padding: 7px 0;
                word-wrap: break-word;
                line-height: 1.2em;

                &:after {
                    width: 0;
                    height: 0; }

                a {
                    line-height: 1.2em;
                    text-decoration: none; } }

            display: block;
            width: 240px;
            height: auto;
            min-height: 50px;

            .floating-widget {
                &.floating {
                    position: fixed;
                    top: 110px;
                    width: 240px; }

                &.pinned-bottom {
                    position: absolute;
                    bottom: 40px;
                    _position: static; } } } }

    &.newVerlocationBox {
        width: 1280px;
        margin: 0 auto;

        .toolBox {
            width: 100%;
            margin: 0 0 10px 0;

            &:after {
                content: "";
                display: table;
                clear: both; }

            .searchHead {
                position: relative;
                float: left;
                width: 458px;
                padding: 2px 0 2px 10px;
                font-size: 16px;
                line-height: 24px;
                color: #333;

                &:after {
                    position: absolute;
                    top: 8%;
                    left: 0;
                    content: "";
                    width: 3px;
                    height: 82%;
                    background: #e71a0f; } } }

        .recentBox .toolBox .searchHead {
            padding: 2px 0 2px 0;
            cursor: pointer;
            font-size: 17px;
            text-decoration: underline;
            line-height: 24px;
            color: #e81a0f;

            &:after {
                display: none; } }

        .noneBox {
            min-height: 300px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;

            .main {
                margin: 0; }

            .icon img {
                max-width: 120px; }

            .text {
                margin: 15px 0 0 0;
                font-size: 16px;
                line-height: 1.4em;
                color: #333; } }

        .toolBox .searchHead b {
            color: #e71a0f; }

        article.proDetailBox .detailBoxBd {
            padding: 30px;
            min-height: 500px; }

        .editBox table.posttable {
            margin: 0 0 40px 0;

            &:first-child {
                margin: 0 0 40px 0; }

            &:last-child {
                margin: 0 0 20px 0; } }

        table {
            border-bottom: 3px #e8cca7 solid;

            .btn_pin {
                width: 22px;
                height: 40px;
                display: inline-block;
                vertical-align: middle;

                img {
                    max-width: 20px; } } }

        .editBox table.posttable {
            tr.infoBox th {
                position: relative;
                padding: 15px 20px;
                text-align: center;
                vertical-align: middle;
                border: 0;
                border-top: 3px #e8cca7 solid;
                background-color: #f8f2eb;
                font-size: 18px;
                color: #333; }

            th .mapBox {
                position: absolute;
                top: 12px;
                right: 20px;
                font-size: 16px;
                font-weight: normal;
                color: #333;

                .distance {
                    position: relative;
                    top: -2px;
                    display: inline-block;
                    vertical-align: middle; } }

            tr {
                &.infoBox:hover th {
                    .mapBox .distance {
                        color: #e81a0f; }

                    cursor: pointer;
                    background: #e2e2e2; }

                &.infoBoxHd th {
                    padding: 8px 20px;
                    text-align: center;
                    vertical-align: middle;
                    border: 0;
                    border-bottom: 3px #fff solid;
                    background-color: #edd9bf;
                    font-size: 19px;
                    color: #333; } }

            td {
                position: relative;
                padding: 20px 20px;
                text-align: center;
                vertical-align: middle;
                border: 0;
                font-size: 16px;
                color: #666;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                box-sizing: border-box;

                &:nth-child(1), &:nth-child(2) {
                    width: 320px; }

                &:nth-child(3) {
                    width: 160px; }

                &:nth-child(4), &:nth-child(5) {
                    width: 240px;
                    max-width: 240px;
                    min-width: 240px; }

                > a {
                    &.button {
                        display: inline-block;
                        width: 168px;
                        height: 38px;
                        line-height: 38px;
                        text-align: center;
                        text-decoration: none;
                        font-size: 15px;
                        color: #666;
                        border: 1px #848484 solid;

                        &.fb:before, &.grant:before {
                            float: left;
                            width: 38px;
                            height: 38px;
                            content: ""; }

                        &.fb:before {
                            // background: #848484 url(../images/icon/icon_newVerlocationBox_fb.png) no-repeat center
                            background-size: 100% auto; }

                        &.grant:before {
                            // background: #848484 url(../images/icon/icon_newVerlocationBox_gift.png) no-repeat center
                            background-size: 100% auto; } }

                    &:hover.button {
                        &.fb {
                            color: #3959a2;
                            border: 1px #3959a2 solid; }

                        &.grant {
                            color: #c38f5d;
                            border: 1px #c38f5d solid; }

                        &.fb:before {
                            background-color: #3959a2; }

                        &.grant:before {
                            background-color: #c38f5d; } } } } }

        .selectBox {
            position: relative;
            text-align: center;
            margin: 20px 0 50px 0;

            .selectClass {
                display: inline-block;
                vertical-align: middle;
                border: 1px #d2d2d2 solid;

                .boxComplex {
                    width: 280px; } }

            .boxComplex {
                position: relative;
                float: left;

                &:first-child:after {
                    position: absolute;
                    top: 3px;
                    right: 0;
                    bottom: 3px;
                    width: 1px;
                    content: "";
                    background-color: #b5b5b5; } }

            .selectClass .boxComplex .selectboxit-container .selectboxit-options {
                max-height: 240px !important; } }

        .selectboxit-container span {
            height: 38px;
            line-height: 38px; }

        .selectboxit .selectboxit-option-icon-container {
            margin-left: 10px; }

        .selectboxit-text {
            color: #666;
            font-size: 16px; }

        .selectboxit-arrow-container {
            width: 46px;

 }            // background: url(../images/page/icon_selectArrow2.png) no-repeat right center

        .selectboxit-default-arrow {
            display: none; }

        .selectboxit-container * {
            font-size: 14px; }

        .selectboxit-options .selectboxit-disabled {
            opacity: 1;
            filter: alpha(opacity = 100);
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;
            cursor: default;
            background-color: #dddddd; }

        .selectboxit.selectboxit-disabled {
            opacity: 1;
            filter: alpha(opacity = 100);
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;
            cursor: default;
            background-color: #dddddd;

            .selectboxit-arrow-container {
                opacity: 0.4; }

            .selectboxit-text {
                color: #aaa; } }

        .subTitle {
            display: inline-block;
            vertical-align: middle;
            height: 38px;
            line-height: 38px;
            margin: 0 20px 0 0;
            color: #555;
            font-size: 18px;
            text-shadow: 0 0 0 rgba(0, 0, 0, 0); } }

    &.mainBoxHd.single .selectboxit-container span {
        height: 38px;
        line-height: 38px; }

    &.globalNavBox {
        max-width: 1280px;
        margin: 0 auto;

        .detailBox {
            float: left;
            width: 618px;
            height: 234px;
            padding: 30px 45px;

            // background: url(../images/page/globalRoaming_globalNavBox.jpg) no-repeat
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            h2 {
                font-size: 20px;
                color: #333;
                margin: 0 0 20px 0; }

            ul {
                max-height: 120px;
                overflow: hidden;

                li {
                    position: relative;
                    font-size: 15px;
                    line-height: 1.6em;
                    color: #666;
                    padding: 0 0 0 15px;

                    &:after {
                        position: absolute;
                        left: 0;
                        top: 9px;
                        width: 5px;
                        height: 5px;
                        content: "";
                        background: #a0a0a0;
                        -webkit-border-radius: 2.5px;
                        border-radius: 2.5px; } } } }

        .navBox {
            float: right;
            width: 662px;
            height: 234px;

            h2 {
                font-size: 20px;
                line-height: 30px;
                font-weight: normal;
                color: #fff;

                span {
                    display: block;
                    font-size: 28px;
                    font-weight: bold; } }

            p {
                font-size: 15px;
                line-height: 24px;
                color: #fff; }

            > div {
                padding: 32px 0 0 40px;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;

                a {
                    position: absolute;
                    left: 0;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    z-index: 1; } }

            .roamingBox {
                float: left;
                width: 426px;
                height: 234px;

                // background: url(../images/page/globalRoaming_roamingBox.jpg) no-repeat

                &:hover, &.active {
 } }                    // background: url(../images/page/globalRoaming_roamingBox_active.jpg) no-repeat

            .aircraftBox {
                position: relative;
                float: right;
                width: 236px;
                height: 117px;

                // background: url(../images/page/globalRoaming_aircraftBox.jpg) no-repeat

                &:hover, &.active {
 } }                    // background: url(../images/page/globalRoaming_aircraftBox_active.jpg) no-repeat

            .maritimeBox {
                position: relative;
                float: right;
                width: 236px;
                height: 117px;

                // background: url(../images/page/globalRoaming_maritimeBox.jpg) no-repeat

                &:hover, &.active {
 } }                    // background: url(../images/page/globalRoaming_maritimeBox_active.jpg) no-repeat

            > div.active {
                &:before {
                    position: absolute;
                    left: 50%;
                    bottom: 3px;
                    content: "";
                    width: 0;
                    height: 0;
                    margin: 0 0 0 -10px;
                    border-style: solid;
                    border-width: 0 10px 10px 10px;
                    border-color: transparent transparent #e71a0f transparent;
                    z-index: 1; }

                &:after {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                    height: 3px;
                    content: "";
                    background: #e71a0f;
                    z-index: 1; } }

            .roamingBox {
                &.active:before {
                    left: 820px;
                    margin: 0; }

                a {
                    left: 618px;
                    right: 236px; } } } }

    &.recommendBox .detailBoxBd {
        .countryHdBox {
            margin: 0 0 20px 0;

            .countryName {
                padding: 0 0 10px 0;
                border-bottom: 2px #ab5f00 solid; }

            .vertical {}

            .countryName .vertical {
                color: #825938;
                font-size: 32px;
                line-height: 40px; }

            .countryList ul {
                margin: 0 -7px;

                li {
                    width: 286px;
                    height: 200px;
                    padding: 0;
                    display: inline-block;
                    vertical-align: top;
                    margin: 7px 7px 0 7px;
                    text-align: center;
                    border: 1px #ecd6ba solid;
                    position: relative;
                    -webkit-box-shadow: 0 2px 3px 1px rgba(150, 150, 150, 0.15);
                    box-shadow: 0 2px 3px 1px rgba(150, 150, 150, 0.15);
                    background: #fff;
                    -webkit-box-sizing: border-box;
                    box-sizing: border-box;

                    .vertical {
                        display: flex;
                        align-items: center;
                        justify-content: center; }

                    > a {
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        z-index: 1; }

                    &:hover {
                        border: 1px #a85d00 solid;
                        background: #fafafa; }

                    .vertical {
                        width: 286px;
                        height: 200px; }

                    span {
                        display: block;
                        font-size: 16px;
                        line-height: 20px;
                        color: #333;

                        &.tel {
                            font-size: 13px;
                            color: #666; } } } } }

        .singleColumn.editBox .countryList ul li img {
            border: 0; }

        .trafficBox {
            display: table;
            width: 100%;
            padding: 25px 30px;
            margin: 0 0 25px 0;
            background-color: #f9f9f9;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;

            ul.trafficList {
                display: table-cell;
                vertical-align: top;
                width: 50%;
                padding: 0 20px 0 0;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;

                &:last-child {
                    padding: 0 0 0 20px; }

                li {
                    padding: 10px 5px;

                    &:after {
                        display: none; } } } }

        .countryHdBox .countryList ul li:after {
            display: none; }

        .trafficBox ul.trafficList li {
            > div {
                display: table-cell;
                vertical-align: middle; }

            .icon {
                padding: 0 15px 0 0; }

            .text {
                font-size: 15px;
                line-height: 26px;
                color: #666; } }

        .tabCountryList {
            margin: 0 -40px 35px -40px;
            padding: 0 0 30px 0;
            border-bottom: 2px #da1b0d solid;

            ul {
                width: 1076px;
                margin: 0 auto;

                li {
                    position: relative;
                    display: inline-block;
                    width: 340px;
                    height: 120px;
                    margin: 0 8px;

                    a {
                        display: block;
                        width: 340px;
                        height: 120px;
                        padding: 32px 0 0 30px;
                        font-size: 32px;
                        line-height: 32px;
                        color: #444;
                        text-decoration: none;
                        background-color: #ededed;
                        background-position: right;
                        background-repeat: no-repeat;
                        -webkit-box-sizing: border-box;
                        box-sizing: border-box; }

                    &.active:before {
                        position: absolute;
                        left: 50%;
                        bottom: -30px;
                        content: "";
                        width: 0;
                        height: 0;
                        margin: 0 0 0 -10px;
                        border-style: solid;
                        border-width: 0 10px 10px 10px;
                        border-color: transparent transparent #e71a0f transparent;
                        z-index: 1; }

                    a span {
                        display: block;
                        font-size: 24px;
                        line-height: 24px; }

                    &.active a, a:hover {
                        color: #333;
                        background-color: #ccc; }

                    &.america a {
 }                        // background-image: url(../images/icon/icon_GR_bgAmerica.gif)

                    &.canada a {
 }                        // background-image: url(../images/icon/icon_GR_bgCanada.gif)

                    &.china a {
 }                        // background-image: url(../images/icon/icon_GR_bgChina.gif)

                    &.america {
                        &.active a, a:hover {
 } }                            // background-image: url(../images/icon/icon_GR_bgAmerica_hover.gif)

                    &.canada {
                        &.active a, a:hover {
 } }                            // background-image: url(../images/icon/icon_GR_bgCanada_hover.gif)

                    &.china {
                        &.active a, a:hover {
 } } } } }                            // background-image: url(../images/icon/icon_GR_bgChina_hover.gif)

        .globalDirections {
            background-color: #f9f9f9;

            ol {
                position: relative;

                &:before, &:after {
                    position: absolute;
                    top: 25px;
                    bottom: 25px;
                    content: "";
                    display: block;
                    width: 1px;
                    background-color: #d8d8d8; }

                &:before {
                    left: 33%; }

                &:after {
                    right: 33%; }

                > li {
                    display: inline-block;
                    vertical-align: middle;
                    width: 33%;
                    padding: 20px 35px;
                    font-size: 20px;
                    line-height: 30px;
                    -webkit-box-sizing: border-box;
                    box-sizing: border-box; }

                li {
                    .title {
                        font-weight: bold;
                        font-size: 20px;
                        line-height: 30px; }

                    span.text {
                        font-size: 20px;
                        font-weight: normal; }

                    &:nth-child(1) {
                        > div {
                            margin: 8px 0; }

                        .title {
                            font-size: 36px;
                            line-height: 40px; }

                        .text {
                            font-size: 20px;
                            line-height: 30px; } }

                    .desc {
                        font-size: 13px;
                        line-height: 20px;
                        color: #666; }

                    .gdbox {
                        margin: 10px 0;

                        ul li {
                            font-size: 15px;
                            line-height: 22px;

                            span {
                                font-size: 13px;
                                color: #666; } } } }

                > li:nth-child(1) {
                    text-align: center;
                    color: #333; } } }

        .terminalBox {
            width: 924px;
            margin: 0 auto 10px auto;

            > div {
                background-color: #f1f1f1;
                -webkit-box-sizing: border-box;
                box-sizing: border-box; }

            .firstTerminal, .secondTerminal {
                width: 452px;
                margin: 0 0 24px 0;
                text-align: center; }

            .firstTerminal {
                margin: 0 20px 24px 0;
                float: left; }

            .secondTerminal {
                float: right; }

            .terminalHd {
                height: 66px;
                line-height: 66px;
                font-size: 20px;
                color: #333;
                background-color: #d8d8d8; }

            .terminalBd {
                padding: 20px 0;
                text-align: center;

                img {
                    border: 1px #cfcfcf solid; } }

            .terminalFt {
                padding: 0 0 20px 0;
                font-size: 16px;
                color: #333; }

            .locationMap img {
                max-width: 100%;
                border: 0; }

            .secondTerminalMsg {
                position: relative;
                padding: 30px 25px;
                clear: both;

                .title {
                    font-weight: bold;
                    margin: 0 0 15px 0; }

                &:before {
                    position: absolute;
                    left: 50%;
                    top: -18px;
                    content: "";
                    width: 0;
                    height: 0;
                    margin: 0 0 0 280px;
                    border-style: solid;
                    border-width: 0 18px 18px 18px;
                    border-color: transparent transparent #f1f1f1 transparent;
                    z-index: 1; } } }

        .grWFServicebox {
            margin: (-30px) -40px;
            padding: 0 0 130px 0;
            border-top: 3px #da1b0d solid;

            // background: url(../images/page/bg_grWFServicebox.gif) no-repeat bottom

            ul {
                padding: 40px 0 20px 0;
                text-align: center;

                li {
                    position: relative;
                    display: inline-block;
                    vertical-align: top;
                    width: 280px;
                    height: 436px;
                    margin-left: 12px;
                    margin-right: 12px;

                    .title {
                        font-size: 20px;
                        line-height: 24px;
                        color: #fff;
                        text-align: left;
                        padding: 20px 22px 0 22px; }

                    .text {
                        min-height: 150px;
                        font-size: 16px;
                        line-height: 24px;
                        color: #fff;
                        padding: 15px 22px 20px 22px;
                        text-align: justify;
                        -webkit-box-sizing: border-box;
                        box-sizing: border-box; }

                    &:nth-child(2), &:nth-child(4) {
                        margin: 30px 12px 0; }

                    &:nth-child(1) {
                        background-color: #fe5959; }

                    &:nth-child(2) {
                        background-color: #7989c6; }

                    &:nth-child(3) {
                        background-color: #53b0d2; }

                    &:nth-child(4) {
                        background-color: #73a664; } }

                .icon2 {
                    position: absolute;
                    right: 0;
                    bottom: -50px; } }

            .editBox .btnDownBox .box {
                margin: 0 8px; } }

        .grWFdiscountMsg {
            margin: (-30px) -40px 40px -40px;
            padding: 0 40px;
            font-size: 18px;
            font-weight: normal;
            line-height: 60px;
            color: #fff;
            background: #c5a88b; }

        .grWFdiscountBox {
            position: relative;
            margin: 0 0 40px 0;

            .discountBox {
                position: relative;
                display: inline-block;
                vertical-align: top;
                width: 47%;
                padding: 0 3% 0 0;
                margin: 0 -2px;

                &:last-child {
                    padding: 0 0 0 3%; }

                .icon {
                    float: left;
                    width: 200px;
                    height: 200px;
                    -webkit-border-radius: 50%;
                    border-radius: 50%;
                    width: 200px;
                    height: 200px;
                    overflow: hidden;
                    -webkit-border-radius: 50%;
                    border-radius: 50%; }

                .discountBoxR {
                    float: right;
                    width: 340px;
                    padding: 0 0 60px 0;
                    text-align: left;

                    .text b {
                        color: #e71a0f; }

                    .number {
                        padding: 8px 10px;
                        margin: 10px 0 0 0;
                        color: #d42c27;
                        font-size: 15px;
                        background: #fff4e5; } } }

            &:before {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 50%;
                content: "";
                width: 1px;
                background-color: #ccc;
                z-index: 1; }

            .discountBox {
                .discountBoxR .btnDownBox {
                    position: absolute;
                    bottom: 10px;
                    right: 30px;
                    margin: 0; }

                &:last-child .discountBoxR .btnDownBox {
                    right: 0; }

                .discountBoxR .btnDownBox {
                    .box {
                        width: 120px; }

                    a.button {
                        font-size: 13px;
                        height: 30px;
                        line-height: 30px; } } } }

        .grWFdiscountFt .box {
            display: table;
            width: 100%;
            margin: 0 0 30px 0;

            > div {
                padding: 25px 0;
                display: table-cell;
                vertical-align: middle; }

            .labelBox {
                position: relative;
                width: 164px;
                color: #333;
                font-size: 20px;
                font-weight: bold;
                text-align: center;
                background-color: #e0e0e0;

                &:after {
                    position: absolute;
                    top: 50%;
                    right: -15px;
                    width: 0;
                    height: 0;
                    margin: (-15px) 0 0 0;
                    content: "";
                    border-style: solid;
                    border-width: 15px 0 15px 15px;
                    border-color: transparent transparent transparent #e0e0e0;
                    z-index: 1; }

                img {
                    margin: 0 5px 0 0;
                    border: 0; } }

            .conBox {
                padding: 25px 35px;
                border: 1px #d9d9d9 solid;

                a {
                    text-decoration: none; } } }

        .imgMargin {
            margin: 0 0 30px 0; } }

    &.wifi .detailBoxBd {
        .minComplex .wifiBoxHd {
            margin: 5px 0 40px 0;

            img {
                border: 0; } }

        .stepBox {
            position: relative;
            float: left;
            text-align: center;
            padding: 20px 0;
            margin: 0 25px 0 0;
            border: 1px #e1e1e1 solid;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box; }

        #NTTDoCoMo .stepBox {
            width: 280px;
            min-height: 710px; }

        #Trustive .stepBox {
            width: 586px;
            min-height: 630px;

            &:nth-child(1) {
                margin: 0 25px 25px 0; }

            &:nth-child(2) {
                margin: 0 0 25px 0; } }

        #other .stepBox {
            width: 382px;
            min-height: 670px;

            &:nth-child(1), &:nth-child(2) {
                margin: 0 25px 25px 0; }

            &:nth-child(3) {
                margin: 0 25px 25px 0;
                margin: 0 0 25px 0; }

            &:nth-child(4), &:nth-child(5) {
                width: 586px;
                min-height: 680px; }

            &:nth-child(4) {
                width: 586px;
                margin: 0 25px 25px 0; }

            &:nth-child(5) {
                width: 586px;
                margin: 0 0 25px 0; } }

        #NTTDoCoMo .stepBox.step4:after {
            background: none; }

        #Trustive .stepBox {
            &.step2:after, &.step4:after {
                background: none; } }

        #other .stepBox {
            &.step3:after, &.step5:after {
                background: none; } }

        .stepBox {
            &.step4 {
                margin: 0; }

            &:after {
                position: absolute;
                top: 50%;
                right: -18px;
                content: "";
                width: 37px;
                height: 37px;
                margin: (-18px) 0 0 0;

                // background: url(../images/icon/icon_WiFi_stepArrow.png) no-repeat
                z-index: 1; }

            > .text {
                position: relative;
                width: 100%;
                text-align: center;
                height: 60px;
                margin: 0 0 20px 0;
                display: flex;
                align-items: center;
                justify-content: center;

                &:before {
                    position: absolute;
                    left: 20px;
                    right: 20px;
                    bottom: 0;
                    content: "";
                    height: 1px;
                    background-color: #e5e5e5;
                    z-index: 1; } }

            &.step1 .icon article.wifi .detailBoxBd .stepBox.step2 .icon article.wifi .detailBoxBd .stepBox.step3 .icon {
                display: table;
                width: 90%;
                margin: 10px auto 0 auto;
                text-align: center;
                height: 230px;
                border-top: 1px #f0f0f0 solid; }

            &.step4 .icon {
                width: 90%;
                border-top: 1px #f2f2f2 solid;
                margin: 10px auto 0 auto;
                padding: 10px 0 0 0; }

            .text span, &.step1 .icon span, &.step2 .icon span, &.step3 .icon span {
                display: inline-block;
                vertical-align: middle;
                padding: 0 20px; }

            .boxComplex {
                text-align: left;
                margin: 20px 25px 0 25px;

                > div:first-child {
                    margin: 0 0 8px 0; }

                .text {
                    font-size: 15px;
                    color: #666;
                    text-align: left;
                    line-height: 1.4em !important;

                    a {
                        line-height: 1.4em !important; } }

                .subTitle {
                    padding: 0 0 0 10px;
                    margin: 0;
                    font-size: 16px;

                    &:after {
                        top: 0; } } }

            .title {
                font-size: 32px;
                font-weight: bold;
                color: #8a5f3c; } } } }

/**
 **
 ** newVerlocationBox
 **
 ** */

/* single */

/* article.newVerlocationBox .relatedBox {
 *    width: 810px;
 *    margin: 10px auto 5px auto;
 *    color: #333;
 *    font-size: 15px;
 *    line-height: 1.4em;
 *}
 *article.newVerlocationBox .relatedBox a {
 *    font-weight: bold;
 *}
 *article.newVerlocationBox .relatedBox b {
 *    color: #e71a0f;
 *} */

/**
 **
 ** globalNavBox
 **
 ** */

/**
 **
 ** countryHdBox
 **
 ** */

/**
 **
 ** countryHdBox
 **
 ** */

/**
 **
 ** tabCountryList
 **
 ** */

/**
 **
 ** globalDirections
 **
 ** */

/**
 **
 ** globalDirections
 **
 ** */

/**
 **
 ** globalDirections
 **
 ** */

/**
 **
 ** globalDirections
 **
 ** */

/**
 **
 ** empowerBox
 **
 ** */
/* position sticky overflow no use */

.wrapperPage section.posSticky {
    overflow: inherit;
    overflow: initial; }

/* position sticky overflow no use end */

article {
    &.empKvListBox, &.empSelectBox, &.empowerBox, &.empActiveBox, &.empToolBox {
        margin: 0 auto;
        max-width: 1280px; }

    &.empKvListBox *, &.empSelectBox *, &.empActiveBox *, &.empToolBox * {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        -ms-box-sizing: border-box;
        box-sizing: border-box;
        font-family: Arial, "Noto Sans TC", "Microsoft JhengHei", Helvetica, sans-serif; }

    &.empActiveBox > a, &.empToolBox > div > a, &.empSelectBox .priceBox.on .btn > a {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1; }

    &.empKvListBox {
        position: relative;

        .sticky-box {
            position: -webkit-sticky;
            position: sticky;
            top: 84px;
            height: 500px;
            overflow: hidden;

            .stickyTitle {
                position: absolute;
                left: 0;
                right: 0;
                bottom: 60px;
                margin: auto;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                -webkit-transition: bottom 0.3s;
                -moz-transition: bottom 0.3s;
                transition: bottom 0.3s;

                .title {
                    font-size: 48px;
                    font-weight: 400;
                    color: #333;
                    line-height: 1.2em;
                    text-shadow: 0px 0px 20px #f2f2f2, 0px 0px 20px #f2f2f2, 0px 0px 20px #f2f2f2; }

                .text {
                    margin: 10px 0 0 0;
                    font-size: 18px;
                    color: #333;
                    line-height: 1.4em; }

                .btn {
                    width: 140px;
                    margin: 10px auto 0 auto;

                    a {
                        display: block;
                        height: 50px;
                        font-size: 20px;
                        color: #fff;
                        line-height: 50px;
                        -moz-border-radius: 8px;
                        -webkit-border-radius: 8px;
                        border-radius: 8px;
                        background-color: #e51f20;
                        box-shadow: inset 0px 0px 0px 0px rgba(170, 22, 22, 1);
                        -webkit-transition: all 200ms linear;
                        transition: all 200ms linear; }

                    &:hover a {
                        box-shadow: inset 0px 0px 200px 0px rgba(170, 22, 22, 1); } } } } } }

/**
 **
 ** empKvListBox
 **
 ** */

@media only screen and (max-height: 737px) {
    article.empKvListBox .sticky-box .stickyTitle {
        bottom: 100px; } }

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    article.empKvListBox .sticky-box {
        opacity: 1 !important;
        transform: translate3d(0, 0, 0) rotateX(0deg) rotateY(0deg) rotateZ(0deg) scale3d(1, 1, 1) !important;

        .stickyTitle {
            opacity: 1 !important;
            transform: translate3d(0, 0, 0) rotateX(0deg) rotateY(0deg) rotateZ(0deg) scale3d(1, 1, 1) !important; } } }

/**
 **
 ** stickySumFixed
 **
 ** */

.stickyMinHeight {
    min-height: 500px; }

.stickySumFixed {
    position: fixed;
    width: 1280px;
    top: calc(100vh - (100vh - 735px));
    left: 0;
    right: 0;
    overflow: inherit;
    overflow: initial;
    margin: auto;
    background-color: #f2f2f2;
    z-index: 2;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;

    &.soptSticky, &.stickySum {
        position: -webkit-relative;
        position: relative;
        top: auto; }

    .stickySortBox {
        position: relative;
        height: 250px;
        min-height: 250px;
        background-color: #fff;
        z-index: 2;
        display: flex;
        align-items: stretch;
        justify-content: center;

        &:after {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 50%;
            width: 1px;
            content: "";
            background-color: #e0e0e0;
            z-index: 3; }

        .tagBox {
            position: absolute;
            top: -22px;
            left: 50%;
            width: 274px;
            height: 42px;
            line-height: 42px;
            text-align: center;
            margin: 0 0 0 -127px;
            font-size: 18px;
            color: #666;
            border: 1px #e0e0e0 solid;
            background-color: #fff;
            -moz-border-radius: 21px;
            -webkit-border-radius: 21px;
            border-radius: 21px;
            z-index: 9; }

        > div {
            position: relative;
            width: 50%;
            text-align: center;

            > a {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 5; }

            .icon img {
                max-width: 60px; }

            .title {
                margin: 30px 0 0 0;
                font-size: 30px;
                color: #333; }

            .text {
                margin: 20px 0 0 0;
                font-size: 16px;
                line-height: 16px;
                color: #999;

                a {
                    color: #99654c;
                    margin: 0 5px; } } }

        .useWebBox:after, .useTelBox:after {
            position: absolute;
            top: 50%;
            content: "";
            width: 0;
            height: 0;
            margin: (-14px) 0 0 0; }

        .useWebBox {
            &:after {
                right: 1px;
                border-style: solid;
                border-width: 14px 0 14px 20px;
                border-color: transparent transparent transparent #fff;
                -webkit-transition: right 0.5s;
                -moz-transition: right 0.5s;
                transition: right 0.5s; }

            &.open:after {
                right: -20px;
                z-index: 5; } }

        .useTelBox {
            &:after {
                left: 1px;
                border-style: solid;
                border-width: 14px 20px 14px 0;
                border-color: transparent #fff transparent transparent;
                -webkit-transition: left 0.5s;
                -moz-transition: left 0.5s;
                transition: left 0.5s; }

            &.open:after {
                left: -20px;
                z-index: 5; } }

        .useWebBox .text a:after, .useTelBox .text a:before {
            position: relative;
            top: -2px;
            display: inline-block;
            vertical-align: middle;
            width: 14px;
            height: 16px;
            content: ""; }

        .msgBox {
            position: relative;
            width: 100%;
            height: 100%;
            background-color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 2; }

        .useWebBox .detailBox, .useTelBox .detailBox {
            position: absolute;
            top: 0;
            width: 100%;
            bottom: 0;
            background-color: #e3e3e3;
            z-index: 1;
            display: flex;
            align-items: center;
            justify-content: space-around;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            -ms-box-sizing: border-box;
            box-sizing: border-box; }

        .useWebBox .detailBox {
            padding: 0 20px 0 40px;
            left: 0;
            -webkit-transition: left 0.5s;
            -moz-transition: left 0.5s;
            transition: left 0.5s; }

        .useTelBox .detailBox {
            padding: 0 40px 0 20px;
            right: 0;
            -webkit-transition: right 0.5s;
            -moz-transition: right 0.5s;
            transition: right 0.5s; }

        .useWebBox.open .detailBox {
            left: 100%;
            z-index: 3; }

        .useTelBox.open .detailBox {
            right: 100%;
            z-index: 3; }

        .useWebBox.open .msgBox, .useTelBox.open .msgBox {
            z-index: 4; }

        .detailBox {
            .subTitle {
                font-size: 30px;
                color: #333;
                line-height: 1.2em;

                span {
                    display: block;
                    font-size: 36px; } }

            .subText {
                width: 300px;
                font-size: 18px;
                color: #333;
                line-height: 1.2em;

                > div {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin: 10px 0 0 0; }

                .sum {
                    margin: 15px 0 0 0;
                    padding: 15px 0 0 0;
                    border-top: 1px #333 solid; } } }

        .useWebBox .text a:after {
            // background: url(../images/icon/icon_sortBoxArrow.png) no-repeat center
            background-size: 100% auto; }

        .useTelBox .text a:before {
            // background: url(../images/icon/icon_sortBoxArrow.png) no-repeat center
            background-size: 100% auto;
            -moz-transform: rotate(180deg);
            -webkit-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
            transform: rotate(180deg); } } }

@media only screen and (max-height: 737px) {
    .stickySumFixed .stickySortBox {
        position: relative;
        top: auto; } }

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .stickySumFixed .stickySortBox {
        position: -webkit-sticky;
        position: sticky;
        top: auto; } }

@media all and (-ms-high-contrast: none) {
    article.empKvListBox .sticky-box {
        position: -webkit-relative !important;
        position: relative !important;
        top: auto !important; }

    .stickySumFixed .stickySortBox {
        position: -webkit-relative !important;
        position: relative !important;
        top: auto !important;

        &.stickySum {
            position: -webkit-relative !important;
            position: relative !important;
            top: auto !important; } } }

/**
 **
 ** empSelectBox
 **
 ** */

article {
    &.empSelectBox {
        .selBox {
            position: relative;
            margin: 20px -10px 0 -10px;
            padding: 0 0 0 160px; }

        .selTitle {
            position: relative;
            line-height: 30px;
            text-align: center;

            &:after {
                position: absolute;
                top: 50%;
                left: 0;
                right: 0;
                height: 1px;
                background-color: #bbb;
                content: ""; }

            span {
                position: relative;
                padding: 0 20px;
                color: #333;
                font-size: 30px;
                background-color: #f2f2f2;
                z-index: 1; } }

        .selBox {
            .title {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                width: 148px;
                margin: 10px;
                color: #fff;
                font-size: 22px;
                font-weight: bold;
                line-height: 1.4em;
                background-color: #99654c;
                display: flex;
                align-items: center;
                justify-content: center; }

            .con > div {
                display: table;
                width: 100%;
                border-collapse: separate;
                border-spacing: 10px;
                margin: (-10px) -10px -10px 0;

                label {
                    position: relative;
                    width: 270px;
                    height: 80px;
                    color: #666;
                    font-size: 22px;
                    text-align: center;
                    display: table-cell;
                    vertical-align: middle;
                    background-color: #fff;
                    border: 1px #e0e0e0 solid;
                    overflow: hidden;
                    cursor: pointer;

                    &:hover {
                        background-color: #f9f9f9; }

                    &.on {
                        &:hover {
                            background-color: #fff; }

                        border: 3px #99654c solid; }

                    input {
                        position: absolute;
                        outline: none;
                        visibility: hidden;
                        width: 0;
                        height: 0;
                        z-index: -1; }

                    span {
                        padding: 0 15px;

                        &.price {
                            position: relative;
                            color: #d8130b;

                            span {
                                padding: 0; }

                            &:after {
                                position: absolute;
                                left: 0;
                                top: 0;
                                bottom: 0;
                                width: 1px;
                                content: "";
                                background-color: #e0e0e0; } } } } } }

        .priceBox {
            margin: 40px 0 30px 0;
            text-align: center;
            font-size: 30px;
            color: #333;
            line-height: 1.2em;

            > div {
                display: inline-block;
                vertical-align: middle; }

            .btn {
                position: relative;
                margin: 0 0 0 10px;

                span {
                    display: block;
                    min-width: 140px;
                    height: 50px;
                    font-size: 20px;
                    color: #fff;
                    line-height: 50px;
                    background: #666;
                    -moz-border-radius: 8px;
                    -webkit-border-radius: 8px;
                    border-radius: 8px;
                    box-shadow: inset 0px 0px 0px 0px rgba(187, 187, 187, 1);
                    -webkit-transition: all 200ms linear;
                    transition: all 200ms linear; } }

            &.on .btn {
                span {
                    background-color: #e51f20;
                    box-shadow: inset 0px 0px 0px 0px rgba(170, 22, 22, 1); }

                &:hover span {
                    box-shadow: inset 0px 0px 200px 0px rgba(170, 22, 22, 1); } }

            .btn a {
                display: none; }

            &.on .btn a {
                display: block; } } }

    &.empActiveBox {
        position: relative;
        display: table;
        width: 100%;
        border: 1px #e1e1e1 solid;
        background-color: #fff;

        > div {
            display: table-cell;
            vertical-align: top; }

        .photo {
            position: relative;
            border-right: 1px #e1e1e1 solid; }

        .con {
            position: relative;
            padding: 35px; }

        .title {
            font-size: 20px;
            line-height: 1.2em;
            font-weight: bold;
            color: #333; }

        .text {
            margin: 20px 0 0 0;
            font-size: 15px;
            line-height: 1.4em;
            color: #666; }

        .btn {
            position: absolute;
            right: 35px;
            bottom: 20px;
            text-align: right;
            font-size: 15px;
            line-height: 1.4em;
            color: #b98c23; }

        &:hover .btn {
            color: #e71a0f; } }

    &.empToolBox {
        display: flex;
        align-items: center;
        justify-content: space-between;

        > div {
            position: relative; }

        .programBox > div, .rateBox > div {
            display: table-cell;
            vertical-align: middle; }

        .programBox, .rateBox {
            display: table;
            width: calc(50% - 4px); }

        .programBox .con, .rateBox .con {
            padding: 40px; }

        .programBox .con {
 }            // background: #d1848a url(../images/icon/bg_empToolBox_programBox.gif) no-repeat right bottom

        .rateBox .con {
 }            // background: #87b2bf url(../images/icon/bg_empToolBox_rateBox.gif) no-repeat right bottom

        .title {
            position: relative;
            min-width: 160px;
            background-color: #666666;

            > div {
                font-size: 24px;
                line-height: 1.4em;
                font-weight: 400;
                color: #fff;
                display: flex;
                align-items: center;
                justify-content: center; } }

        .text {
            font-size: 16px;
            line-height: 1.4em;
            color: #fff; }

        .btn {
            margin: 10px 0 0 0;
            font-size: 20px;
            line-height: 1.4em;
            color: #fff;

            span.arrow {
                position: relative;
                left: 0;
                top: -2px;
                margin: 0 0 0 5px;
                display: inline-block;
                vertical-align: middle;
                width: 24px;
                height: 24px;

                // background: url(../images/icon/icon_empToolBoxArrow.png) no-repeat center
                background-size: 100% auto;
                -webkit-transition: left 0.5s;
                -moz-transition: left 0.5s;
                transition: left 0.5s; } }

        > div:hover .btn span.arrow {
            left: 5px; } }

    &.fpfKvListBox, &.fpfPlanList, &.classifyList {
        margin: 0 auto;
        max-width: 1280px; }

    &.fpfKvListBox *, &.classifyList * {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        -ms-box-sizing: border-box;
        box-sizing: border-box;
        font-family: Arial, "Noto Sans TC", "Microsoft JhengHei", Helvetica, sans-serif; }

    &.fpfPlanList {
        .selTitle span {
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            -ms-box-sizing: border-box;
            box-sizing: border-box;
            font-family: Arial, "Noto Sans TC", "Microsoft JhengHei", Helvetica, sans-serif; }

        ul li > a {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1; } }

    &.classifyList ul li > a {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1; }

    &.fpfKvListBox .sticky-box {
        position: -webkit-sticky;
        position: sticky;
        top: 84px;
        height: 420px;
        overflow: hidden;

        .kvImg {
            position: relative; }

        .stickyTitle {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 1;

            .title {
                font-size: 48px;
                font-weight: 400;
                color: #333;
                line-height: 1.2em;
                text-shadow: 0px 0px 20px #f2f2f2, 0px 0px 20px #f2f2f2, 0px 0px 20px #f2f2f2; }

            .text {
                font-size: 18px;
                color: #333;
                line-height: 2em;
                text-shadow: 0px 0px 15px #f2f2f2, 0px 0px 15px #f2f2f2; }

            .btn {
                width: 176px;
                margin: 20px auto 0 auto;

                a {
                    display: block;
                    height: 50px;
                    font-size: 20px;
                    color: #fff;
                    line-height: 50px;
                    -moz-border-radius: 8px;
                    -webkit-border-radius: 8px;
                    border-radius: 8px;
                    background-color: #e51f20;
                    box-shadow: inset 0px 0px 0px 0px rgba(170, 22, 22, 1);
                    -webkit-transition: all 200ms linear;
                    transition: all 200ms linear; }

                &:hover a {
                    box-shadow: inset 0px 0px 200px 0px rgba(170, 22, 22, 1); } } } }

    &.classifyList {
        .selTitle {
            position: relative;
            line-height: 30px;
            text-align: center;
            margin: 10px 0 0 0;

            &:after {
                position: absolute;
                top: 50%;
                left: 0;
                right: 0;
                height: 1px;
                background-color: #bbb;
                content: ""; }

            span {
                position: relative;
                padding: 0 20px;
                color: #333;
                font-size: 30px;
                background-color: #f2f2f2;
                z-index: 1; } }

        ul {
            display: inline-flex;
            flex-wrap: wrap;
            width: 100%;
            padding: 0 6px;

            li {
                position: relative;
                width: 600px;
                margin: 15px 17px;
                background-color: #fff;
                -moz-border-radius: 8px;
                -webkit-border-radius: 8px;
                border-radius: 8px;
                font-size: 0;
                overflow: hidden;
                box-shadow: 0 0 0 rgba(0, 0, 0, 0);
                -webkit-transition: all 200ms linear;
                transition: all 200ms linear;

                &:hover {
                    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2); }

                .photo, .con {
                    display: inline-block;
                    vertical-align: middle;
                    width: 50%; }

                .photo img {
                    width: 100%;
                    vertical-align: middle; }

                .con {
                    padding: 0 30px;

                    .title {
                        color: #333;
                        font-size: 20px;
                        font-weight: bold;
                        line-height: 1.4em;
                        max-height: 2.8em;
                        overflow: hidden;
                        -webkit-transition: color 200ms linear;
                        transition: color 200ms linear; } }

                &:hover .con .title {
                    color: #e71a0f; }

                .con .text {
                    margin: 20px 0 0 0;
                    color: #666;
                    font-size: 16px;
                    line-height: 1.4em;
                    height: 2.8em;
                    overflow: hidden; } } } }

    &.fpfPlanList {
        .selBox {
            position: relative;
            margin: 20px -10px 0 -10px;
            padding: 0 0 0 160px; }

        .selTitle {
            position: relative;
            line-height: 30px;
            text-align: center;
            margin: 10px 0 0 0;

            &:after {
                position: absolute;
                top: 50%;
                left: 0;
                right: 0;
                height: 1px;
                background-color: #bbb;
                content: ""; }

            span {
                position: relative;
                padding: 0 20px;
                color: #333;
                font-size: 30px;
                background-color: #f2f2f2;
                z-index: 1; } }

        ul {
            display: inline-flex;
            flex-wrap: wrap;
            width: 100%;

            li {
                position: relative;
                width: 382px;
                margin: 15px 21px;
                border: #e1e1e1 1px solid;
                background-color: #fff;

                .photo img {
                    width: 100%;
                    vertical-align: middle; }

                .con {
                    z-index: 2;
                    padding: 20px 20px 70px 20px;

                    .title {
                        color: #333;
                        font-size: 16px;
                        font-weight: bold;
                        line-height: 1.4em;
                        height: 1.4em;
                        overflow: hidden;
                        -webkit-transition: color 200ms linear;
                        transition: color 200ms linear; } }

                &:hover .con .title {
                    color: #e71a0f; }

                .con .text {
                    margin: 5px 0 0 0;
                    color: #666;
                    font-size: 15px;
                    line-height: 1.4em;
                    height: 2.8em;
                    overflow: hidden; }

                .more {
                    position: absolute;
                    right: 0;
                    bottom: 18px;
                    width: 100px;
                    height: 30px;
                    text-align: center;
                    color: #fff;
                    font-size: 13px;
                    font-weight: 600;
                    line-height: 30px;
                    background-color: #666666;
                    -webkit-transition: background-color 200ms linear;
                    transition: background-color 200ms linear; } } } }

    &.classifyList ul li .more {
        position: absolute;
        right: 0;
        bottom: 18px;
        width: 100px;
        height: 30px;
        text-align: center;
        color: #fff;
        font-size: 13px;
        font-weight: 600;
        line-height: 30px;
        background-color: #666666;
        -webkit-transition: background-color 200ms linear;
        transition: background-color 200ms linear;
        right: auto;
        left: 0; }

    &.fpfPlanList ul li:hover .more, &.classifyList ul li:hover .more {
        background-color: #e71a0f; }

    &.fpfPlanList ul li .more img, &.classifyList ul li .more img {
        position: relative;
        top: -2px;
        max-width: 14px; } }

.font18 {
    font-size: 18px !important;
    font-weight: normal !important; }
/**
 **
 ** empActiveBox
 **
 ** */

/**
 **
 ** empToolBox
 **
 ** */

/**
 **
 ** empowerBox
 **
 ** */

/**
 **
 ** fpfKvListBox
 **
 ** */

/**
 **
 ** classifyList
 **
 ** */

/**
 **
 ** fpfPlanList
 **
 ** */
