.wrapperPage {
    background: #fff;
    padding: 30px;
    margin-bottom: 12px;
    box-shadow: 0 6px 12px 0 rgba(75, 88, 105, 0.12);

    section {
        padding: 0; } }

.fui-panel-information {
    .schinner.editBox {
        > table {
            display: block;
            width: 100% !important;
            overflow-x: auto;
            overflow-y: hidden;
            -webkit-overflow-scrolling: touch; } } }

.wrapperPage section article.recommendBox {
    // background-color: #fff
    // padding: 30px
    // box-shadow: 0 6px 12px 0 rgba(75, 88, 105, 0.12)

    * {
        > ::-webkit-scrollbar {
            display: none;
            overflow: auto;
            -ms-overflow-style: none; } }

    .tabs article.proDetailBox {
        padding: 0;

        .detailBoxBd {
            box-shadow: none;
            background-color: none;
            padding: 0;

            .descbox {
                border: 0;

                .unfold {
                    max-height: 66px;

                    &.on {
                        max-height: 100%; } } }

            .mainTitle {
                padding: 30px 48px 24px 30px;
                margin: 0;
                border: 0;
                background-color: initial;

                .close {
                    font-size: 0;
                    background-image: url(#{$publicURL}/content/dam/fetnet/resources/cbu/images/plus.svg);
                    transform: none;
                    right: 48px;
                    top: 30px;

                    &.odd {
                        background-image: url(#{$publicURL}/content/dam/fetnet/resources/cbu/images/minus.svg); } }

                .subTitle {
                    font-size: 20px;
                    margin: 0;
                    padding: 0;

                    &::after {
                        display: none; } } }

            .open {
                display: none; } }

        .leftColumn {
            width: calc( 100% - 270px );
            overflow: scroll;

            .accordion {
                padding: 0;
                border: 0;
                background-color: #fff;
                border: 2px solid #F1F3F5;

                &:first-child {
                    margin-top: 20px; }

                &.open {
                    display: block; }

                .open {
                    display: none; }

                .descbox .mainTitle {
                    background-color: none; }

                .mainTitle {
                    background-color: #fff;
                    border: 0;
                    box-shadow: none;
                    padding: 30px;
                    margin: 0;

                    .trigger {
                        right: 30px;
                        background-image: url(#{$publicURL}/content/dam/fetnet/resources/cbu/images/plus.svg);
                        transform: rotate(0); }

                    .odd {
                        background-image: url(#{$publicURL}/content/dam/fetnet/resources/cbu/images/minus.svg); }

                    a {
                        display: flex;
                        flex-direction: column;

                        b {
                            color: #5f7b80;
                            font-size: 16px;
                            display: block;
                            margin-bottom: 12px;
                            font-weight: 400; }

                        span {
                            border: 0;
                            font-size: 20px;
                            color: #202020;
                            padding: 0;
                            line-height: 1.78; } } } } }

        .schinner {
            overflow: scroll;
            margin: 0 30px 30px 30px;

            table {
                border: 2px solid #E8ECF0;
                font-size: 16px;
                width: 100% !important;

                strong {
                    color: #5f6e84;
                    font-size: 16px; }

                td {
                    font-size: 16px;
                    vertical-align: middle;
                    padding: 12px;
                    color: #5f6e84;
                    background-color: #fff;
                    border: 2px solid #e8ecf0; }

                tr:nth-child(2n) td {
                    background-color: #fff; } }

            .descbox {
                margin-bottom: 30px;
                background-color: #fafafa;
                color: #5f6e84;
                font-size: 14px;
                line-height: 1.71;

                li {
                    color: #5f6e84; }

                .mainTitle {
                    background: none;
                    color: #5f6e84;
                    padding-left: 0; } } }

        .sidebar {
            width: 220px;

            .box {
                background-image: url(#{$publicURL}/content/dam/fetnet/resources/cbu/images/result.svg);
                padding: 30px 20px;
                border: 0;
                text-align: left;
                box-shadow: none;
                background-size: contain;
                background-position: right bottom;
                background-color: #EFF4F1;
                background-repeat: no-repeat;

                .mainTitle {
                    font-size: 24px;
                    display: flex;
                    flex-direction: column;
                    padding: 0;

                    &::before {
                        content: "";
                        background-image: url(#{$publicURL}/content/dam/fetnet/resources/cbu/images/calculator.svg);
                        width: 30px;
                        height: 30px;
                        position: relative;
                        margin-bottom: 12px; }

                    img {
                        display: none; } }

                p {
                    font-size: 16px;
                    color: #202020;
                    margin-bottom: 30px; }

                a {
                    font-size: 16px;
                    padding: 12px;
                    background: none;
                    color: #202020;
                    height: auto;
                    border: 2px solid #202020;
                    font-weight: 500;
                    line-height: initial;

                    &::after {
                        display: none; }

                    img {
                        display: none; } } } } }

    .tabPagerBox {
        margin: 36px 0 60px 0;

        .tab-pager {
            a {
                border-radius: 50%;
                width: 30px;
                height: 30px;
                font-size: 0.875rem;
                font-weight: 500;

                &:hover {
                    color: #202020; } }

            li {
                &.mb {
                    display: none;
                    margin: 0 12px; }

                &.next a, &.prev a, &.last a, &.first a {
                    filter: brightness(0); }

                &.next a:hover, &.prev a:hover, &.last a:hover, &.first a:hover {
                    filter: brightness(1); }

                &.disabled {
                    opacity: 0.3;

                    a {
                        color: #d6d6d6;
                        pointer-events: none; } }

                &.active a {
                    background-color: #6a798e;
                    color: #fff; } }

            .goTo {
                display: none; } } }

    .detailBoxBd .editBox {
        overflow: scroll;

        a.body {
            display: flex; }

        a {
            display: inline-block;
            color: #202020;
            cursor: pointer;
            text-decoration: none;

            &::after {
                display: none !important; }

            &:hover {
                color: #e71a0f;
                transition: color 0.3s ease-in;

                &::after {
                    display: none !important; } } }

        * {
            font-size: 16px;
            line-height: 1.75;
            white-space: normal; }

        table {
            tbody tr {
                &:hover td {
                    background-color: none;

                    &:hover {
                        tr: nth-child(3n) td {
                            tr-background: #f1f3f5; } } }

                &:nth-child(1) th {
                    background: #f1f3f5;

                    &:nth-child(1) {
                        background-color: none; } } }

            &.posttable {
                tr {
                    th, td {
                        color: #5f6e84;
                        background-color: initial;
                        border: 2px solid #e8ecf0; }

                    th span, td span {
                        color: #5f6e84; }

                    th strong, td strong {
                        color: #202020; }

                    &:hover td {
                        color: #5f6e84; } }

                td.hd {
                    background: initial; } } } }

    .btnDownBox .box {
        display: inline-block;

        // float: left
        margin: 0;
        border: 0;

        a.button {
            padding: 30px 0;
            color: #202020;
            text-align: left;
            background-color: initial;
            border: 0;
            font-weight: 500;
            display: flex;
            align-items: center;

            &::before {
                content: "";
                background: url(https://www.fetnet.net/images/icon/icon_pagerPrev.png) no-repeat center;
                height: 20px;
                filter: brightness(0);
                display: inline-block;
                background-size: contain;
                width: 20px;
                margin-right: 6px; } } } }

@media screen and (max-width: 960px) {
    .wrapperPage section article.recommendBox {
        .tabs article.proDetailBox {
            .leftColumn {
                width: 100%;

                .accordion .mainTitle.schBoxHd span {
                    padding-right: 30px; } }

            .sidebar {
                width: 100%;
                margin-top: 36px; }

            .tabPagerBox .tab-pager li {
                margin: 0;

                &.page {
                    display: none; }

                &.active, &.mb {
                    display: block; } } }

        .detailBoxBd .editBox table.posttable {
            width: 100%;
            overflow: scroll;
            white-space: nowrap;
            display: block; } } }

.detailBoxBd .editBox.leftColumn {
    width: 100%;
    overflow: scroll;
    margin-bottom: 16px; }

@media screen and (max-width: 960px) {
    article.proDetailBox.postpaid .leftColumn .tabsBox {
        overflow: scroll; } }

article.recommendBox {
    .detailBoxBd .mainTitle {
        margin-bottom: 12px;

        .selectCoupon {
            margin-left: 40px;

            .selectboxit {
                height: auto;
                padding: 6px 0 6px 12px;

                i {
                    display: none; } }

            .selectboxit-container {
                border-color: #e8ecf0;
                border: 2px solid #e8ecf0;

                span {
                    font-size: 16px;
                    height: auto; }

                .selectboxit-arrow-container {
                    height: 30px;
                    background-image: url(#{$publicURL}/content/dam/fetnet/resources/cbu/images/arrow-down.svg);
                    background-size: auto;
                    margin-right: 12px; }

                .selectboxit-focus a {
                    background-color: #e8ecf0; }

                .selectboxit-options {
                    outline: 2px solid #e8ecf0;
                    border: 0;
                    box-shadow: 0 18px 36px 0 rgba(75, 88, 105, 0.18);

                    li.selectboxit-option {
                        height: auto;

                        i {
                            display: none; }

                        a.selectboxit-option-anchor {
                            font-size: 16px;
                            padding: 8px 18px;
                            height: auto; } } } } } }

    .editBox table {
        &#rental, &#prepaid {
            display: none;
            margin-top: 12px; }

        &#rental.active, &#prepaid.active {
            display: block; } }

    .ui-selectmenu-button {
        display: inline-block;
        padding: 14px 14px;
        border-radius: 0;
        border: solid 2px #e8ecf0;
        background-color: #fff;
        width: 200px;
        cursor: pointer;
        position: relative;

        .ui-selectmenu-text {
            font-weight: 500; }

        &::after {
            content: "";
            background-image: url(#{$publicURL}/content/dam/fetnet/resources/cbu/images/arrow-down.svg);
            background-size: auto;
            height: 24px;
            width: 24px;
            position: absolute;
            right: 20px;
            top: 14px; } } }

.ui-selectmenu-icon {
    display: none; }

.ui-selectmenu-menu {
    display: none;

    &.ui-selectmenu-open {
        display: block; } }

.ui-widget.ui-widget-content {
    padding-bottom: 12px !important;
    border: 0;
    box-shadow: 0 18px 36px 0 rgba(75, 88, 105, 0.18);

    .ui-menu-item-wrapper {
        padding: 8px 24px 8px 12px;
        font-weight: 500;
        border: 0; }

    .ui-state-active {
        margin: 0;
        border: 0;
        color: #202020;
        background-color: #fff; } }

@media screen and (max-width: 960px) {
    .wrapperPage {
        padding: 20px; }

    // .wrapperPage section article.recommendBox
 }    //     padding: 20px

article {
    &.proDetailBox.postpaid .leftColumn {
        .tabs {
            width: 100%; }

        .tabsBox .partbox {
            border: 0;
            background-color: #fff;
            border: 2px solid #e8ecf0;
            margin: 12px 0 0 0;

            .unfoldBox {
                background-color: #fff;
                padding: 0 48px 0px 30px;
                margin: 0;

                p {
                    font-size: 18px;
                    color: #202020; }

                .unfold {
                    max-height: 0;
                    width: 100%;
                    position: relative;
                    overflow: scroll;

                    &.on {
                        max-height: initial;
                        padding-bottom: 60px; }

                    table tr {
                        td {
                            text-align: left;

                            * {
                                white-space: normal; } }

                        &:hover * {
                            color: #fff; } } } }

            .mainTitle {
                padding: 30px 48px 24px 30px;
                margin: 0;

                .close {
                    font-size: 0;
                    background-image: url(#{$publicURL}/content/dam/fetnet/resources/cbu/images/plus.svg);
                    transform: initial;
                    right: 48px;
                    top: 30px;

                    &.odd {
                        background-image: url(#{$publicURL}/content/dam/fetnet/resources/cbu/images/minus.svg); } }

                .subTitle {
                    font-size: 20px;
                    margin: 0;
                    padding: 0;

                    &::after {
                        display: none; } } }

            .open {
                display: none; } } }

    .tabs ul.ui-tabs-nav {
        border-bottom: 6px solid #d6d6d6;
        display: flex;
        align-content: center;
        justify-content: space-around;

        li {
            text-align: center;
            width: 100%;

            a {
                border: 0; }

            &.ui-state-active {
                border-bottom: 6px solid #ef3123;
                margin-bottom: -6px;

                &::after {
                    display: none; } } } } }

@media screen and (max-width: 480px) {
    .wrapperPage section article.recommendBox .detailBoxBd .editBox table.posttable tr {
        th, td {
            white-space: nowrap; } } }

@media screen and (max-width: 960px) {
    article {
        .tabs ul.ui-tabs-nav {
            display: flex;
            overflow-x: scroll;
            width: 100%;
            white-space: nowrap;
            border: 0;
            padding-bottom: 12px;
            justify-content: normal;

            li {
                &.ui-state-active {
                    border-bottom: 6px solid #ef3123;
                    margin-bottom: 0;
                    width: 100%;
                    border-bottom: 6px solid #d6d6d6;
                    margin: 0; } } }

        &.proDetailBox.postpaid .leftColumn .tabsBox .partbox .mainTitle .close {
            right: 15px; } } }

.shareBox {
    iframe.line-it-button {
        margin-top: 0;
        background-image: url(#{$publicURL}/content/dam/fetnet/resources/cbu/images/line-share.svg) !important;
        background-size: contain !important; }

    a {
        &.facebook {
            background-image: url(#{$publicURL}/content/dam/fetnet/resources/cbu/images/facebook-share.svg) !important;
            background-size: contain !important; }

        &.line[style] {
            background-image: url(#{$publicURL}/content/dam/fetnet/resources/cbu/images/line-share.svg) !important;
            background-size: contain !important; } }

    .title {
        font-size: 16px;
        color: #202020; } }

article.proDetailBox.postpaid .detailBoxHd {
    display: flex;
    flex-wrap: wrap;
    box-shadow: none;
    border: 0;

    .shareBox {
        iframe.line-it-button {
            margin-top: 0;
            background-image: url(#{$publicURL}/content/dam/fetnet/resources/cbu/images/line-share.svg) !important;
            background-size: contain; }

        a {
            &.facebook {
                background-image: url(#{$publicURL}/content/dam/fetnet/resources/cbu/images/facebook-share.svg);
                background-size: contain; }

            &.line[style] {
                background-image: url(#{$publicURL}/content/dam/fetnet/resources/cbu/images/line-share.svg) !important;
                background-size: contain; } }

        .title {
            font-size: 16px;
            color: #202020; } }

    .albumsBox {
        display: none;

        .photo {
            width: auto;
            display: flex;

            img {
                width: 100%; } } }

    .detailBox {
        padding: 0;
        width: calc( 100% - 236px );
        margin-bottom: 4px;
        margin-top: 48px;

        .text {
            line-height: 1.78; }

        h3.font24b {
            font-size: 36px !important; } } }

@media screen and (max-width: 960px) {
    article.proDetailBox.postpaid .detailBoxHd {
        .detailBox {
            width: 100%;

            h3.font24b {
                margin-bottom: 24px; } }

        .shareBox {
            right: -10px;
            top: -20px; } } }

html {
    font-size: 100%; }

body iframe {
    height: calc( 100vh + 1.125rem );
    margin-top: -1.125rem; }

#wrapper {
    min-width: initial;
    width: 100%;

    section {
        overflow: scroll;
        min-width: initial; } }

.main.even.min {
    display: none !important; }

.all-plan-bg {
    background-image: url(#{$publicURL}/content/dam/fetnet/resources/cbu/images/cbu-bg-pattern.svg);
    background-size: auto;
    background-position: left top;
    width: 223px;
    height: 312px;
    margin-top: 0px;
    position: absolute; }

.all-plan-bg + .fui-container {
    padding-top: 120px;
    padding-bottom: 100px; }

article.kv-wrapper {
    position: relative;
    margin-bottom: 80px;
    height: 240px;
    display: flex;
    align-items: center;

    .img {
        width: 100%;
        position: absolute;
        height: 240px;
        background-position: center right;
        background-size: cover; }

    h1 {
        position: relative;
        margin-left: 96px; } }

.other-rate-plan-wrapper {
    display: flex;
    flex-direction: row;

    .list {
        display: flex;
        flex-direction: column;
        padding-right: 80px;

        h4 {
            color: #f05c38;
            font-size: 24px !important;
            margin-bottom: 24px; }

        a.body {
            font-weight: 500;
            margin-bottom: 6px;
            display: flex;
            align-items: center;

            i {
                font-size: 22px !important;
                color: #202020; }

            &:hover {
                transition: all 0.5s ease-in;

                i {
                    color: #d3060f; } } } } }

@media screen and (max-width: 960px) {
    .other-rate-plan-wrapper {
        flex-direction: column;
        margin-bottom: 60px !important; } }

article.crumbBox {
    z-index: 1;
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    background: none;
    padding-top: 100px;
    padding-left: 0;

    .mainBox {
        color: #202020;
        margin-left: 0;

        a {
            font-size: 14px;
            color: #202020;
            margin: 0 0.75rem;
            font-weight: 400;

            &:nth-of-type(1) {
                margin-left: 0; } }

        h1.mainHead {
            font-size: 14px;
            color: #202020;
            margin-left: 0.75rem;
            font-weight: 600;
            line-height: initial; } } }

iframe[src*='otherservice.html'] ~ .wrapperPage {
    background: red; }

.wrapperPage section .main.top10downNo {
    // margin-top: 60px
    padding: 0;

    .tabs.unit ul#lv3TabDiv {
        border-bottom: 0;
        margin: 0;

        li {
            margin-top: 2.25rem;
            display: none;

            a {
                font-size: 0.875rem;
                color: #fff; } } }

    .subtab {
        display: none; }

    .leftColumn .subtab {
        display: block; }

    article {
        &.ratePlanHd {
            margin: 0;
            width: 100%;

            .mainBox {
                text-align: left;
                margin: 0;
                left: 0px;
                width: 100%;
                top: 84px;

                .mainTitle {
                    color: #fff;
                    font-size: 2.625rem;
                    margin-bottom: 1.75rem;
                    margin-left: 1.875rem; }

                .text {
                    color: #fff;
                    font-size: 1.125rem;
                    font-weight: 600;
                    margin-left: 1.875rem;
                    margin-bottom: 112px; }

                .start {
                    margin-bottom: 1.125rem;

                    span {
                        color: #202020;
                        font-size: 1.25rem;
                        font-weight: 600;
                        border: 0;

                        &::after {
                            display: none; } } } }

            .kvComplex {
                overflow: hidden;
                height: 240px;
                width: 100%;
                filter: brightness(0.7);

                img {
                    min-width: 100%;
                    min-height: 100%;
                    transform: translate(-50%, -50%);
                    position: relative;
                    left: 50%;
                    top: 50%;
                    margin-bottom: 1.125rem; } } }

        &.ratePlanBd {
            margin-top: 118px;

            ul.rateItem {
                width: 100%;
                display: flex;
                margin-bottom: 3rem;
                border: 0;
                justify-content: flex-start;

                &:nth-last-child(1) {
                    margin-right: 0; }

                li {
                    width: 32.6%;
                    border: solid 2px #f05c38 !important;
                    margin: 0 12px 0 0;
                    height: auto;

                    a.ui-tabs-anchor {
                        display: flex;
                        justify-content: center;
                        padding: 24px 0;

                        span {
                            &.hover {
                                display: none; }

                            &.text {
                                color: #f05c38;
                                font-size: 1rem;
                                font-weight: 500; } }

                        &::after {
                            content: ""; } }

                    &::after {
                        display: none; } } }

            .ratePlanBox {
                margin-bottom: 3.375rem;

                .mainTitle {
                    background-color: initial;
                    font-weight: 600;
                    height: 3.125rem; }

                .number {
                    display: none; }

                .boxComplex {
                    border: 2px solid #e8ecf0 !important;
                    box-shadow: none;

                    &::after {
                        position: absolute;
                        top: 0.75rem;
                        left: 6px;
                        content: "";
                        height: 1.875rem;
                        width: 1.875rem;
                        border-radius: 50%;
                        border: 1px solid #e8ecf0; } }

                ul {
                    &#answerList {
                        display: flex;
                        flex-wrap: wrap;
                        margin: 0;

                        .boxComplex {
                            margin: 0;
                            padding: 1.625rem 3.75rem; }

                        li {
                            margin: 0 0.75rem 0.75rem 0;
                            width: calc( (100% - 24px)/3 );

                            &:nth-child(3n) {
                                margin-right: 0;
                                display: flex;
                                flex-wrap: wrap; } } }

                    li {
                        width: calc( (100% - 24px)/3 );
                        margin-right: 0.75rem;
                        margin-bottom: 0.75rem;
                        display: flex;

                        &:nth-last-child(1) {
                            margin-right: 0; }

                        .boxComplex {
                            margin: 0.5rem 0 0 0;
                            padding: 1.625rem 3.75rem;
                            height: auto;
                            width: 100%; }

                        .vertical {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            height: 100%;

                            p {
                                font-size: 1rem;
                                color: #5f6e84;
                                font-weight: 500; } }

                        &.active .boxComplex {
                            box-shadow: none;
                            border: 2px solid #f05c38 !important;
                            padding: 1.5rem 3.75rem;

                            p {
                                color: #f05c38; }

                            &::before {
                                border: 1px solid #f05c38;
                                width: 1.875rem;
                                height: 1.875rem;
                                background: #f05c38;
                                left: 6px;
                                top: 12px;
                                content: "";
                                border-radius: 50%;
                                position: absolute; }

                            &::after {
                                width: 10px;
                                height: 10px;
                                background: #fff;
                                left: 16px;
                                top: 22px; } } } } }

            .resultBox {
                border: none;
                box-shadow: 0 6px 12px 0 rgba(75, 88, 105, 0.12);
                margin-top: 5.625rem;

                .leftColumn {
                    background-image: url(#{$publicURL}/content/dam/fetnet/resources/cbu/images/result.svg);
                    background-position: right bottom;
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-color: #EFF4F1;
                    height: 386px;
                    min-height: initial;
                    width: 35%;
                    padding: 60px 30px 40px 30px;

                    .mainTitle {
                        font-weight: 500;
                        background-image: url(#{$publicURL}/content/dam/fetnet/resources/cbu/images/calculator.svg);
                        padding: 42px 0 0 0; }

                    .text {
                        font-size: 1rem;
                        padding: 0 30px 0 0;

                        &::before, &::after {
                            display: none; } } }

                .rightColumn {
                    height: 386px;
                    min-height: initial;
                    width: 65%;

                    .subTitle {
                        font-size: 1.25rem;
                        color: #202020;
                        font-weight: 600;
                        margin-bottom: 1.75rem; }

                    .mainTitle a {
                        font-size: 36px !important;
                        color: #f05c38 !important;
                        margin-bottom: 1.625rem !important;
                        text-decoration: none; }

                    .text {
                        font-size: 1rem;
                        border-bottom: 0;
                        color: #202020; }

                    .btnDownBox {
                        display: flex;
                        margin: 3.375rem 0 0 0;

                        .box {
                            order: 1;
                            margin: 0 0.75rem 0 0;

                            a {
                                background-color: #fff;
                                color: #202020;
                                border: 0;
                                height: 40px;

                                &.reSet {
                                    outline: #202020 solid 2px; } }

                            &:nth-last-child(1) {
                                order: 0;

                                a.button {
                                    background-color: #d3060f;
                                    color: #fff;
                                    border: initial; } } } }

                    .desc {
                        text-align: left;
                        left: 2.625rem; } } } }

        &.empowerBox .descbox, &.recommendBox .detailBoxBd .descbox {
            background-color: #fafafa;
            padding: 30px; }

        &.empowerBox .descbox .mainTitle, &.recommendBox .detailBoxBd .descbox .mainTitle {
            color: #5f6e84; }

        &.empowerBox .descbox ol > li, &.recommendBox .detailBoxBd .descbox ol > li {
            color: #5f6e84;
            font-size: 14px;
            line-height: 24px; }

        &.empowerBox .descbox .mainTitle, &.recommendBox .detailBoxBd .descbox .mainTitle {
            padding-left: 14px; } } }

@media screen and (max-width: 960px) {
    article.kv-wrapper {
        margin-top: 20px;
        margin-bottom: 20px;
        height: 212px;

        .img {
            height: 212px; }

        h1 {
            margin-left: 16px; } }

    .all-plan-bg {
        background-image: url(#{$publicURL}/content/dam/fetnet/resources/cbu/images/cbu-bg-pattern-mb.svg); }

    .all-plan-bg + .fui-container {
        padding-top: 90px;
        padding-bottom: 60px; }

    .wrapperPage {
        section {
            article.crumbBox {
                display: none; }

            .main.top10downNo {
                // margin-top: 30px

                article {
                    &.ratePlanHd {
                        padding: 1.875rem 0px;
                        width: auto;
                        height: auto;

                        .mainBox {
                            margin: 0;
                            top: 0;
                            left: 0;
                            width: auto;
                            position: relative;

                            .mainTitle {
                                font-size: 32px;
                                margin-left: 1.25rem;
                                line-height: 1.5;
                                margin-bottom: 0; }

                            .text {
                                margin-left: 1.25rem;
                                margin-bottom: 96px; }

                            .start {
                                margin-left: 1.25rem;
                                padding-top: 48px; } } }

                    &.ratePlanBd {
                        padding: 1rem;
                        margin-top: 88px;

                        ul.rateItem li {
                            width: calc( (100% - 12px)/2 ) !important;
                            margin: 0px 0.75rem 0.75rem 0 !important; }

                        .ratePlanBox {
                            ul li .boxComplex {
                                padding: 0.75rem 0.875rem 0.75rem 2.625rem !important;
                                min-height: 76px;

                                .vertical p {
                                    width: 100%;
                                    text-align: left;
                                    width: 100%;
                                    margin-bottom: 36px; } }

                            .text {
                                min-height: initial; }

                            ul {
                                &#answerList .boxComplex {
                                    padding: 0.75rem 0.875rem 0.75rem 2.625rem;
                                    display: flex;
                                    min-height: 76px; }

                                li {
                                    width: calc( (100% - 12px)/2 ) !important;
                                    margin: 0px 0.75rem 0.75rem 0 !important;

                                    .boxComplex {
                                        padding: 0.75rem 0.875rem 0.75rem 2.625rem !important;

                                        .vertical {
                                            text-align: left;

                                            p {
                                                width: 100%; } } }

                                    &:nth-child(2n) {
                                        margin-right: 0 !important; }

                                    &:nth-child(3n) {
                                        margin-right: 12px; } } } } } } }

            ul#answerList {
                flex-wrap: wrap;

                li {
                    &:nth-child(2n) {
                        margin-right: 0 !important; }

                    .boxComplex {
                        padding: 0.75rem 0.875rem 0.75rem 2.625rem !important;
                        display: flex;
                        min-height: 76px; } } }

            .main.top10downNo {
                .tabs.unit article.ratePlanBd .resultBox {
                    margin-top: 0;

                    .leftColumn {
                        width: 100%;
                        height: auto !important;

                        .text {
                            min-height: initial; } }

                    .rightColumn {
                        width: 100%;
                        height: auto;
                        padding: 1.875rem 1.25rem;
                        box-shadow: 0 6px 0.75rem 0 rgba(75, 88, 105, 0.12);

                        .mainTitle[style] {
                            font-size: 1.25rem; }

                        .btnDownBox {
                            margin-top: 10px;
                            margin-bottom: 3.75rem; }

                        .desc {
                            position: relative;
                            left: 0; } } }

                .detailBoxBd .descbox {
                    margin-top: 3.75rem;
                    padding: 1.875rem 1.875rem 3.75rem 1.875rem; } } }

        .unfold ol li {
            white-space: initial; } }

    article.proDetailBox.postpaid .detailBoxHd .detailBox {
        width: 100%;
        margin-right: 0; } }
