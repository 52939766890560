.block-list {
  .block-url-item {
    display: flex;
    padding: $space*1.5;
    align-items: center;
    align-content: center;
    justify-items: space-between;
    justify-content: space-between;
    background: white;
    border: 2px solid #EFF1F2;
    margin-bottom: $space;

    @media screen and (min-width: 600px) {
      padding: $space*2; }

    .action img {
      vertical-align: middle; }

    .item {
      flex: 1;
      flex-grow: 1;
      margin-right: $space*1.5;
      word-break: break-all; } } }

.unsubscribe {
  .form-group {
    margin-bottom: $space*2;
    .checkbox-buttons {
      display: flex;
      flex-direction: column; } } }

.MuiGrid-root.MuiGrid-container .MuiGrid-root.MuiGrid-item {
  .paper {
    display: flex;
    flex-direction: column;
    height: 100%;
    .content {
      flex: 1;
      flex-grow: 1; }

    .fui-button {
      margin: 0; } } }

table.fui-table.unsubscribe-table {
  tbody {
    width: {} }
  tbody > tr {
    background: white;
    border-left: 2px solid $lightgray100;
    border-right: 2px solid $lightgray100;

    > td {
      border-left: 0;
      border-right: 0;
      color: $black50;
      width: auto !important;
      max-width: none !important;
      padding: $space*3 $space*4;
      font-weight: 700;

      &:first-child {
        text-align: left; }

      &:last-child {
        text-align: right; } }

    @media screen and (max-width: 600px) {
      > td {
        padding: $space*1.5 $space*2;
        width: auto !important;
        max-width: none !important;
        min-width: 0px !important; } } } }

.fui-banner.is-product.is-style-2.allot-report-banner {
  min-height: 360px;

  .caption {
    top: $space*4;
    padding-top: 0;
    color: $black50;
    * {
      color: $black50; } }

  @media screen and (min-width: 768px) {
    .caption {
      top: $space*10;
      padding-left: 0; } } }

.phone-overview,
.accounts-overview,
.accounts-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;

  @media screen and (max-width: 374px) {
    flex-direction: column;
    align-items: flex-start;
    align-content: flex-start; } }

.account-selector {
  z-index: 9; }

*.is-block-segment {
  opacity: .5 !important;
  pointer-events: none; }

.account-selector-radio .form-group {
  margin-bottom: $space*2;
  .radio-buttons .radio-group .radio-button {
    width: 100%;

    &.is-disabled {
      opacity: .5;
      pointer-events: none;

      input:checked ~ .checkmark {
        border-color: $gray70;
        background-color: $gray70; } }

    &:last-child {
      margin-bottom: $space*2; } } }

.ReactModalPortal .ReactModal__Content {
  .fui-prompt-modal-body.account-selector-modal-body {
    max-width: 445px !important;
    padding: $space*3 $space*2.5;

    h5 {
      font-size: 22px; }

    .fui-buttons.two-button {
      margin: 0 -10px;
      .fui-button {
        margin: 0 10px; } } } }

.fui-footer-promo.allot-promotion {
  padding: $space*4 0;
  min-height: 150px;
  height: 200px;

  @media screen and (min-width: 768px) {
    height: 150px; }

  .promotion-container {
    max-width: 1170px;
    display: flex;
    justify-items: center;
    justify-content: center;
    align-items: center;

    > * {
      align-items: flex-start;
      align-content: flex-start;
      .promotion-button {
        width: auto; } }

    h4.fui-section-promo-title {
      text-align: left; } }
  a {
    min-height: 0;
    height: auto;
    padding-top: 0;
    padding-bottom: 0; } }
