.keyword-container {
    margin-top: $space * 3;
    font-size: 0.875rem;
    z-index: 0;
    padding-right: 0;
    margin-right: -15px;

    label {
        color: $darkgray50; }

    .keyword-list {
        display: flex;
        align-items: center;
        align-content: center;
        justify-items: center;
        justify-content: center;

        // &::before
        //     content: ""
        //     position: absolute
        //     left: 50px
        //     top: 0
        //     width: 36px
        //     height: 100%
        //     z-index: 2
        //     opacity: 1
        //     transition: opacity .2s ease
        //     background-image: linear-gradient(to left, rgba(240, 245, 249, 0) 9%, #f0f4f8 76%)
        // &::after
        //     content: ""
        //     position: absolute
        //     right: 16px
        //     top: 0
        //     width: 36px
        //     height: 100%
        //     opacity: 1
        //     transition: opacity .2s ease
        //     background-image: linear-gradient(to right, rgba(240, 245, 249, 0) 9%, $lightgray100 76%)
        // &::before
        //     opacity: 0
        // &.is-show-before::before
        //     opacity: 1
        // &.is-hide-after::after
        //     opacity: 0

        * {
            overflow: hidden;
            white-space: nowrap; }

        .keyword-action {
            align-items: center;
            align-content: center;
            display: none;

            button.fui-button {
                margin: 0;
                display: inline-block;
                padding: 0;

                &:hover i {
                    margin-left: 6px; }

                &.disabled {
                    opacity: 0.3; } } }

        .keyword-content {
            position: relative;
            flex: 1 1 0;
            overflow-y: hidden;
            overflow-x: auto;
            -ms-overflow-style: none;

            &::-webkit-scrollbar {
                display: none; }

            .keyword-group {
                display: inline-block;
                white-space: nowrap; } }

        .fui-button.is-label {
            padding: 0 12px;
            height: 36px;
            margin: 0 3px 0;
            line-height: 32px; }

        * {
            vertical-align: top; }

        .label {
            vertical-align: middle;
            display: inline-block;
            margin-right: $space * 1.5; } } }

@media screen and (min-width: 960px) {
    .keyword-container {
        margin-right: 0px;

        .keyword-list {
            position: relative;

            &.is-scroller {
                &::before {
                    left: 30px; }

                &::after {
                    right: 50px; } }

            .keyword-content {
                overflow: hidden; }

            .keyword-action {
                display: flex; } } } }
