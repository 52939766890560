.fui-progress-bar {
    position: relative;
    background: $lightgray70;
    height: 30px;
    overflow: hidden;

    .fui-progress-number {
        position: absolute;
        text-align: right;
        width: 100%;
        min-width: 30px;
        height: 100%;
        line-height: 30px;
        color: $white;
        font-size: 18px;

        &.is-zero {
            color: $darkgray50;
            right: -60px; } }

    .fui-progress {
        position: absolute;
        left: -24px;
        top: 0;
        width: 0%;
        height: 100%;
        display: block;
        box-shadow: 0 6px 12px 0 rgba(75, 88, 105, 0.12);
        background-image: linear-gradient(to right, $blue30, $blue100 97%);
        transition: width 0.3s ease;

        &::after {
            content: "";
            position: absolute;
            display: block;
            width: 0;
            height: 0;
            border-bottom: 15px solid $blue100;
            border-left: 12px solid $blue100;
            border-right: 12px solid transparent;
            border-top: 15px solid transparent;
            top: 0;
            right: -24px; }

        &.is-blue {
            background-image: linear-gradient(to right, $blue30, $blue100 97%); }

        &.is-red {
            background-image: linear-gradient(to left, $coral100 83%, $coral30);

            &::after {
                border-bottom: 15px solid $coral100;
                border-left: 10px solid $coral100; } } }

    &.is-bold {
        height: 48px;

        .fui-progress-number {
            line-height: 48px; }

        .fui-progress {
            left: -40px; }

        .fui-progress::after {
            right: -40px;
            border-top-width: 24px;
            border-bottom-width: 24px;
            border-left-width: 20px;
            border-right-width: 20px; } } }

.fui-progress-box {
    border: solid 2px $lightgray100;
    padding: ($space * 3) ($space * 3.75);
    margin-bottom: $space * 1.5;

    .fui-progress-bar {
        margin-top: 20px; }

    .body1 {
        line-height: 28px; }

    .last-block {
        float: right;

        .last {
            font-size: 28px;
            color: $accent;
            font-weight: $font-medium; } } }

@media screen and (max-width: 760px) {
    .fui-progress-bar {
        height: 20px;

        &.is-bold {
            height: 30px;

            .fui-progress-number {
                line-height: 30px; }

            .fui-progress {
                left: -20px;

                &::after {
                    right: -20px;
                    border-bottom-width: 15px;
                    border-top-width: 15px;
                    border-left-width: 10px;
                    border-right-width: 10px; } } }

        .fui-progress {
            left: -16px;

            &::after {
                right: -16px;
                border-bottom-width: 10px;
                border-top-width: 10px;
                border-left-width: 8px;
                border-right-width: 8px; } }

        .fui-progress-number {
            line-height: 20px;
            font-size: 1rem; } }

    .fui-progress-box {
        position: relative;
        padding-bottom: 84px;
        font-size: 1rem;

        h5, h6 {
            margin-bottom: $space * 1.2; }

        .last-block {
            position: absolute;
            padding: 0 18px;
            right: 0;
            bottom: 30px;

            .last {
                font-size: 24px; } } } }
