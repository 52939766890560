@charset "UTF-8";

@import "./utils/variable";
@import "./utils/retina-background";

body.is-en {
    font-size: 18px;
    line-height: 28px;
    font-family: "SF Pro", "Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif;

    .fui-button {
        .text {
            line-height: 40px; }

        &.is-large .text {
            line-height: 40px; }

        &.is-small .text {
            line-height: 30px; } }

    .heading-display {
        font-size: 52px;
        line-height: 68px;
        font-weight: $font-bold; }

    h1,
    .heading-1 {
        font-size: 34px;
        line-height: 44px;
        font-weight: $font-bold; }

    h2,
    .heading-2 {
        font-size: 28px;
        line-height: 42px;
        font-weight: $font-bold; }

    h3,
    .heading-3 {
        font-size: 24px;
        line-height: 36px;
        font-weight: $font-medium; }

    h4,
    .heading-4 {
        font-size: 22px;
        line-height: 33px;
        font-weight: $font-bold; }

    h5,
    .heading-5 {
        font-size: 20px;
        line-height: 30px;
        font-weight: $font-bold; }

    h6,
    .heading-6 {
        font-size: 18px;
        line-height: 27px;
        font-weight: $font-bold; }

    .subtitle {
        font-size: 18px;
        line-height: 27px; }

    .subtitle-2 {
        font-size: 18px;
        line-height: 30px; }

    p,
    .text,
    .body {
        font-size: 18px;
        line-height: 28px;
        font-weight: 500; }

    .body-1 {
        font-size: 16px;
        line-height: 26px; }

    small,
    .text-sm,
    .body-2 {
        font-size: 14px;
        line-height: 22px; }

    .caption,
    .text-mini {
        font-size: 12px;
        line-height: 18px; }

    .is-text-bold {
        font-weight: $font-bold; }

    .is-text-medium {
        font-weight: $font-medium; }

    .is-text-regular {
        font-weight: $font-regular; }

    .is-text-light {
        font-weight: $font-light; }

    .fui-panel {
        h3.fui-panel-title {
            font-size: 24px; }

        .fui-list .fui-item {
            font-weight: $font-regular; } }

    @media screen and (min-width: 960px) {
        .heading-display {
            font-size: 56px;
            line-height: 80px;
            font-weight: $font-bold; }

        h1,
        .heading-1 {
            font-size: 42px;
            line-height: 63px; }

        h2,
        .heading-2 {
            font-size: 36px;
            line-height: 54px; }

        h3,
        .heading-3 {
            font-size: 28px;
            line-height: 42px; }

        h4,
        .heading-4 {
            font-size: 24px;
            line-height: 36px; }

        h5,
        .heading-5 {
            font-size: 22px;
            line-height: 33px; }

        h6,
        .heading-6 {
            font-size: 20px;
            line-height: 30px; }

        .subtitle {
            font-size: 18px;
            line-height: 27px; }

        .subtitle-2 {
            display: block; }

        section.fui-content {
            h3 {

                font-size: 42px;
                font-weight: $font-medium; } } } }
