.fiveg-event {
  .arrow-wrapper {
    position: absolute;
    margin-top: -300px;
    right: 0; }
  .opacity0 {
      opacity: 0;
      position: absolute; }
  .fui-video-card-container .fui-cards.is-video-list .fui-card.is-video {
    width: 100%;
    .fui-card-caption {
      display: none; } } }
