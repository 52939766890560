section.subscribe-wrapper {
    height: 200px;
    padding-top: 42px;

    h4 {
        margin: 0 0 30px 0;
        font-size: 22px; }

    .subscription {
        width: 100%;
        top: 0;

        .email-pending {
            pointer-events: none;
            height: 50px;
            position: absolute;
            right: 0;
            width: 158px;
            opacity: 1;
            animation-name: pending;
            animation-duration: 2s;
            display: flex;
            -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards; }

        .email-done {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 50px;
            background-color: #5cc3d2;
            position: absolute;
            left: 0;
            width: 100%;
            opacity: 0;
            animation-duration: 2s;
            animation-name: show-up;
            -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
            z-index: 3;

            span {
                color: #fff; } }

        .fui-action {
            cursor: pointer; }

        button.disabled {
            background-color: $main !important;
            opacity: 1;
            color: #fff !important;
            pointer-events: all; } }

    form {
        margin: 0 auto; }

    .form-group {
        margin: 0; }
    .error-message {
        position: absolute;
        bottom: 0;
        top: initial!important;
        left: 0; } }

@media screen and (max-width: 960px) {
    section.subscribe-wrapper {
        min-height: initial;
        padding: 24px 0 24px 0;
        margin: 0;
        height: auto;

        h4 {
            font-size: 20px;
            margin-bottom: 12px; }

        .subscription {
            input {
                padding: 6px 12px;
                height: 50px; }

            .fui-button.is-primary {
                height: 40px;
                padding: 0 24px; }

            .email-done {
                height: 40px; } } } }

@media screen and (max-width: 768px) {
    section.subscribe-wrapper {
        .subscription {
            input {
                height: 40px; } } } }

@media screen and (max-width: 381px) {
    section.subscribe-wrapper {
        h4 {
            max-width: 197px; } } }

@keyframes pending {
    0% {
        width: 158px; }

    99% {
        width: 100%;
        opacity: 1; }

    100% {
        width: 100%;
        opacity: 1; } }

@keyframes show-up {
    0% {
        opacity: 0; }

    99% {
        opacity: 1; }

    100% {
        opacity: 1; } }
