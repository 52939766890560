.business-login {
    position: relative;
    min-height: calc( 100vh - 107px);
    margin-top: 0;

    h2 {
        margin-top: 64px;
        margin-bottom: 10px;
        color: $black50; }

    p {
        margin: 0;
        font-weight: 500;
        margin-bottom: 30px;
        color: $black50; }

    .fui-breadcrumb-container {
        position: relative;

        .fui-container {
            margin: 0;
            padding: 0; } }

    .side-banner {
        height: 100%; }

    .id-input {
        margin-bottom: 48px; }

    .fui-button {
        height: 50px; }

    .tag-group {
        button {
            margin-right: 24px;
            margin-bottom: 24px; } }

    .side-banner {
        position: absolute;
        right: 0;
        top: 0;
        z-index: -1;

        img {
            height: 100%; } } }

header.is-fixed + .business-login {
    margin-top: 0; }

@media screen and (max-width: 960px) {
    .business-login {
        min-height: calc( 100vh - 144px);

        .text-input {
            margin-bottom: 24px; }

        .fui-button {
            height: 40px; }

        .side-banner {
            display: none; } } }

@media screen and (max-width: 640px) {
    .business-login {
        button {
            width: 100%; }

        h2 {
            text-align: center; } } }
