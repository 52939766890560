figure {
    width: auto;
    margin: ($space * 3) 0;
    overflow-x: auto;
    text-align: center;

    &.is-inline {
        position: relative;
        display: inline-block;
        width: 100%; }

    > img {
        width: 100%;
        height: auto;
        margin: 0; }

    figcaption {
        display: inline-block;
        max-width: 894px;
        font-size: 1rem;
        text-align: left;
        padding: 12px 12px 12px 42px;
        margin-top: 18px;
        color: $navy50;
        background: $lightgray50;
        min-width: 285px;

        // border: 2px solid $lightgray100

        &::before {
            content: "";
            float: left;
            margin-left: -20px;
            margin-top: 3px;
            border-top: 7px solid transparent;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-bottom: 7px solid $navy50; } } }

.fui-gallery {
    display: flex;
    flex-wrap: wrap;
    margin: ($space * 3) -6px;

    .fui-figure {
        width: calc(50% - 12px);
        margin: 6px;

        a:link::after,
        a:visited::after {
            display: none !important; }

        img {
            width: 100%;
            margin: 0; } } }

@media screen and (min-width: 960px) {
    figure.fui-figure {
        &.is-inline {
            position: relative;
            display: inline-block;
            width: calc(50% - 32px);
            margin-left: $space * 2;
            margin-right: $space * 2; }

        > img {
            max-width: 900px;
            height: auto;
            margin: 0 auto 6px !important; }

        figcaption {
            margin-bottom: $space * 6; } }

    .fui-gallery {
        margin: 0 -6px;

        a:link::after,
        a:visited::after {
            display: none; }

        .fui-figure,
        &.three-column .fui-figure {
            width: calc(33.3333% - 12px); }

        &.four-column {
            .fui-figure {
                width: calc(25% - 12px); } } } }
