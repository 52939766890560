@media (max-width: 600px) {
    .jcaptcha {
        width: 50%; }
    .jcaptcha-frame {
        display: flex;
        align-items: flex-end; }

    .jcaptcha-reloadWord {
        margin-left: auto; } }

@media (min-width: 600px) {
    .jcaptcha-frame {
        display: flex;
        align-items: flex-end; }

    .jcaptcha-reloadWord {
        margin-left: auto; } }
