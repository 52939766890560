main header.fui-header {
    top: 0; }

div[data-cq-data-path] > section.fui-content:not(.video-container) {
    background-size: auto 200px;
    background-image: url(#{$publicURL}/resources/common/images/section-bkg-pattern-2.png); }

div[data-cq-data-path*="panelcontent"] + div[data-cq-data-path*="panelbutton"] {
    margin-top: -40px;
    margin-bottom: 40px; }

div[data-cq-data-path*="breadcrumb"] + div section.fui-content:not(.video-container),
div[data-cq-data-path*="banner"] + div section.fui-content:not(.video-container) {
    background-size: auto 160px;
    background-image: url(#{$publicURL}/resources/common/images/section-bkg-pattern-1.png);

    @media screen and (min-width: 960px) {
        background-size: auto 300px; } }

section.fui-content div[data-cq-data-path]:not(:first-child) h2 {
    margin-top: 60px; }

.section.new.aem-Grid-newComponent {
    margin: 0;
    height: auto; }
