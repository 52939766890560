.fui-table-response {
    position: relative;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    z-index: 0;

    table {
        display: table; } }
*:not(.show-scrollbar) {
    &.tableRight {
        -ms-overflow-style: none;
        &::-webkit-scrollbar {
            display: none;
            scrollbar-width: none;
            td:nth-of-type(1) {
                border-left: 0; }
            td:nth-last-child(1) {
                border-right: 0; } } } }
.show-scrollbar {
    table {
        overflow: auto;
        display: block; } }

*:not(.fui-query-modal) table {
    border-collapse: collapse;
    width: 100%;

    // overflow: hidden

    &.fui-table thead tr > th:first-child {}

    &.fui-table tbody tr > td:first-child {
        min-width: 400px;
        width: 400px;
        max-width: 400px;
        white-space: normal;

        @media screen and (min-width: 960px) {
            min-width: 60%;
            width: 60%;
            max-width: 60%;
            white-space: normal; } }

    tr {
        background: $white;

        th, td {
            font-size: 0.875rem;
            white-space: nowrap;
            text-align: center;
            border: 2px solid $lightgray100;
            color: $darkgray50;
            height: 44px;
            line-height: 1.8;
            padding: (0.5 * $space) $space; }

        &[rowspan="2"] {
            height: 40px * 2; }

        th {
            background: #FFF; } }

    > tr:nth-child(2n) {
        background: #f1f3f5; }

    thead {
        background: $lightgray70;

        tr {
            background: none;

            th {
                cursor: default;
                background: none; } }

        &.is-hover {
            color: $white;
            background: transparentize($business100, 0.2); } }

    tbody tr {
        background: $white;

        td {
            cursor: default;
            position: relative; }

        &:nth-child(2n) {
            background: #f1f3f5; }

        th .MuiGrid-root.MuiGrid-item {
            display: flex;

            &.note {
                font-size: 0.875rem; }

            span {
                align-self: center; }

            .float-right {
                width: 100%;
                display: block;
                text-align: right; } } }

    &.payment-table {
        display: table;

        tbody tr th {
            color: $black50;
            height: 88px;
            font-size: 18px;
            padding-left: 18px;
            padding-right: 18px;
            white-space: normal;

            .float-right {
                font-size: 20px; }

            h2.float-right {
                font-size: 28px; }

            .happygo.MuiGrid-root.MuiGrid-container {
                width: calc(100% + 16px);
                margin: 0 (-1 * $space);

                .MuiGrid-root.MuiGrid-item {
                    padding: 0 $space; }

                .note {
                    font-weight: $font-regular;
                    text-align: left; } }

            @media screen and (min-width: 768px) {
                padding-left: 30px;
                padding-right: 30px;
                height: 92px;
                font-size: 20px;

                .float-right {
                    font-size: 22px; }

                h2.float-right {
                    font-size: 36px; }

                .happygo.MuiGrid-root.MuiGrid-container {
                    .note.MuiGrid-root.MuiGrid-item {
                        text-align: right;
                        font-size: 0.875rem;

                        span {
                            width: 100%;
                            display: block; } } } } } }

    &.text-wrap {
        display: table;
        tr th,
        tr td {
            white-space: normal; } }

    &.option-table {
        .nowrap {
            td {
                white-space: nowrap; } }

        tr th {}

        &.name,
        &.description {
            min-width: 200px; }

        tr td {
            white-space: normal;
            text-align: left;
            padding: $space * 1.5; }

        &:not(:first-child) {
            min-width: 120px; }

        label {
            font-size: 1rem;
            margin: 0; }

        input {
            position: absolute;
            border: 0;
            opacity: 0; }

        .radio {
            position: relative;
            width: 30px;
            height: 30px;
            border-radius: 15px;
            border: solid 2px $lightgray100;
            background: $white; }

        input:checked + .radio {
            background: $error;
            border-color: $error;

            &::after {
                content: "";
                width: 10px;
                height: 10px;
                border-radius: 5px;
                background: $white;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto; } } }

    &.with-hover {
        td.is-hover {
            &:hover td {
                background-color: #{"rgba(22, 66, 177, 0.15)"}; }

            &:hover {
                color: $white;
                background: transparentize($business100, 0.2);

                .emphasize {
                    color: $white; } } } }

    &.center-table {
        tr {
            th, td {
                text-align: center; }

            td:not(:first-child) {
                min-width: auto; } } }

    &.modal-table {
        width: 100%;

        tr {}

        th {
            width: 40%;
            background: $lightgray70; } } }

table {
    position: relative;
    overflow-x: auto;
    display: table;
    -webkit-overflow-scrolling: touch;
    z-index: 0;
    width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    tbody {
        // display: table
        width: 100%; }

    &.table-285 {
        tr {
            td {
                font-size: 16px;
                padding: 12px 24px; }

            td:nth-child(1) {
                width: 285px; } } }

    &.table-md-285 {
        tr {
            td {
                font-size: 16px;
                padding: 12px 24px; }

            td:nth-child(1) {
                width: 285px; } } }

    &.odd {
        tbody tr {
            background-color: $white !important; }

        tbody tr:nth-child(odd) {
            background-color: $lightgray70 !important; } }

    &.table-gray-head {
        th {
            border-right: 2px solid $lightgray100 !important; }

        tbody {
            tr {
                background-color: $white !important;

                &:nth-of-type(1) {
                    background-color: $lightgray70 !important;

                    th {
                        background-color: initial; } } } } }

    &.table-fixed {
        table-layout: fixed;
        display: table;

        td {
            line-break: anywhere;
            background-clip: padding-box; } }

    &.gray-th {
        tr {
            th {
                background-color: $lightgray70;
                font-weight: 500; }

            td {
                background-color: $white; } } }

    &.w-142 {
        .tableLeft {
            width: 142px; }

        .tableRight {
            padding-left: 140px; }

        @media screen and (max-width: 960px) {
            .tableLeft {
                width: 90px !important; }

            .tableRight {
                padding-left: 88px !important; } } } }

@media screen and (max-width: 960px) {
    table {
        display: block;

        &.table-285 {
            tr {
                td {
                    font-size: 14px;
                    padding: 6px; }

                td:nth-child(1) {
                    width: 76px; } } }

        &.table-md-285 {
            tr {
                td {
                    font-size: 14px;
                    padding: 6px; }

                td:nth-child(1) {
                    width: auto; } } } } }

.compareTable {
    position: relative;

    .tableHead {
        th {
            border-right: 0; } }

    .tableLeft {
        position: absolute;
        left: 0;
        right: 0;
        width: 180px;

        // height: 100%
        z-index: 1;

        // border-right: 2px solid $lightgray100
        // border-left: 2px solid $lightgray100
        // margin-left: -2px

        table {
            height: 100%;
            display: table; }

        th {
            white-space: normal; }

        .colspan2 {
            height: 88px; } }

    .tableRight {
        padding-left: 178px;
        overflow-x: scroll;
        -webkit-overflow-scrolling: touch;
        // -ms-overflow-style: none
        border-right: 0;
        &::after {
            content: '';
            width: 1px;
            height: 100%;
            position: absolute;
            right: 1px;
            top: 0;
            background-color: $lightgray100; }
        &.auto-width {
            tr {
                td {
                    min-width: auto!important; } } }

        &.w-200 {
            td {
                width: 200px;
                min-width: 200px; } }

        &::-webkit-scrollbar {
            display: none;
            scrollbar-width: none;

            td:nth-of-type(1) {
                border-left: 0; }

            td:nth-last-child(1) {
                border-right: 0; } }
        &.w-164 {
            td {
                min-width: 164px; } } }
    .compareList {
        font-weight: 500;
        outline: 2px solid $lightgray100;
        outline-offset: -2px;

        tbody {
            width: 100%;
            display: table; }

        .body1 {
            display: block; } }

    .emphasize {
        color: $business50;
        display: inline-block;
        margin: 0;

        table tr td {
            white-space: nowrap; } }

    &.w-142 {
        .tableLeft {
            width: 142px; }

        .tableRight {
            padding-left: 140px; }

        @media screen and (max-width: 960px) {
            .tableLeft {
                width: 90px !important; }

            .tableRight {
                padding-left: 88px !important; } } }


    &.stick-to-right {
        .tableRight {
            margin-right: -22px;
            &::after {
                display: none; } } }

    @media screen and (min-width: 960px) {
        .tableLeft {
            width: calc( 25% + 2px ); }

        .tableRight {
            padding-left: calc( 25% - 2px );
            border-right: 0;

            table {
                tr {
                    th:first-child, td:first-child {
 } } } }                        // border-left: 0

        &.stick-to-right {
            .tableRight {
                margin-right: 0; } } } }

table.confirm-table {
    tr {
        th, td {
            color: #5f6e84;
            font-size: 16px;
            white-space: normal; }

        th {
            width: 50%;
            text-align: center;
            background: #fff;
            font-weight: 600; }

        td {
            padding: 4px 60px;
            text-align: left; }

        &:nth-child(odd) {
            td {
                background: #f1f3f5; } }

        &:nth-child(even) {
            td {
                background: #fff; } } } }

table.history-detail-table {
    tr {
        th, td {
            color: #5f6e84;
            font-size: 16px;
            white-space: normal; }

        th {
            width: 30%;
            text-align: center;
            background: #fff;
            font-weight: 600; }

        td {
            padding: 4px 30px;
            text-align: left; }

        &:nth-child(odd) {
            th,
            td {
                background: #f1f3f5; } }

        &:nth-child(even) {
            th,
            td {
                background: #fff; } } } }

table.modal-roaming-country-table {
    tr {
        th, td {
            white-space: normal; }

        th {
            width: 50%; }

        td {
            background: #fff; } } }

table.modal-roaming-package-table {
    tr {
        th, td {
            white-space: normal;
            text-align: left; }

        th {
            width: 25%; }

        &:nth-child(odd) {
            td {
                background: #f1f3f5; } }

        &:nth-child(even) {
            td {
                background: #fff; } } } }

// table

//     .w-50

//         width: 50%

//     .w-100

//         width: 100%

@media screen and (min-width: 1200px) {
    .compareTable {
        .tableLeft {
            .colspan2 {
                height: 104px; } } }

    *:not(.fui-query-modal) {
        table {
            tr {
                th, td {
                    font-size: 1rem;
                    height: 52px; } } } } }

@media screen and (max-width: 768px) {
    .compareTable {
        .tableLeft {
            width: 120px; }

        .tableRight {
            padding-left: 120px; }

        .compareList {
            min-width: 400px; } }

    *:not(.fui-query-modal) table {
        &.modal-table {
            tr {
                td {
                    text-align: left; } } } }

    table.confirm-table {
        margin-bottom: 12px;

        tr {
            th, td {
                font-size: 14px; }

            td {
                padding: 4px 8px; } } }

    table.modal-roaming-package-table {
        tr {
            th {
                width: 40%; } } } }

@media all and (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
    table {
        &::-webkit-scrollbar {
            display: none; } } }


*:not(.fui-query-modal) table.defaultTable {
    font-family: serif;
    display: table !important;
    tr, th, td {
        color: #000 !important;
        border: 0 !important;
        background: none !important; }

    thead {
        background: none !important;
        th {
            font-size: 20px !important;
            border-top: 1px solid #000 !important;
            border-bottom: 1px solid #000 !important; } } }
