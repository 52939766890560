.fui-cookie {
    position: fixed;
    width: 100%;
    bottom: 0;
    background: rgba(32, 32, 32, 0.9);
    box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.3);
    color: $white;
    z-index: 38;
    padding: 30px 0;

    .fui-container {
        width: 100%;
        max-width: 1200px;
        display: flex;
        flex-direction: column;

        .content {
            flex: 1 1 auto;
            flex-grow: 1;

            h5 {
                margin: 0; } }

        .extra {
            .fui-button {
                width: 100%;
                margin: ($space * 2) 0 0; } }

        @media screen and (min-width: 960px) {
            flex-direction: row;

            .extra {
                display: flex;
                align-content: center;
                align-items: center;
                padding-left: 60px;

                .fui-button {
                    width: auto;
                    margin: 0; } } } } }
