.fui-section-header {
    position: relative;
    z-index: 9; }

.fui-panel {
    background: #FFF;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px !important;
    padding-bottom: 20px !important;
    font-size: 18px;
    margin-bottom: $space * 1.5;
    box-shadow: 0 24px 48px 0 rgba(75, 88, 105, 0.12);

    .text,
    .body-1, p {
        font-size: 16px; }

    h4 {
        font-weight: $font-regular;
        margin: ($space * 3) 0 ($space * 1.5); }

    .fui-html-content {
        margin-bottom: $space * 6;

        &:last-child {
            margin-bottom: 0; } }

    .social-media a:not(.sub-link-title):not(.fui-button),
    .social-media a:not(.sub-link-title):not(.fui-button):link,
    .social-media a:not(.sub-link-title):not(.fui-button):visited {
        &::after {
            display: none; }
        &:hover {
            opacity: 0.75;

            &::after {
                display: none; } } }

    .fui-panel-button {
        .fui-button {
            white-space: normal;
            text-align: left;

            &.is-secondary {
                text-align: center;

                &::after {
                    display: none !important; } }

            * {
                display: inline; }

            .text {
                display: inline;
                height: auto;
                line-height: 1.5; } } }

    .panel-button-list {
        margin-bottom: $space * 3;

        .fui-panel-button {
            margin-bottom: $space;

            > .fui-button {
                margin-bottom: 0;

                @media screen and (max-width: 768px) {
                    i[class*='icon'] {}
                    vertical-align: sub; } } } }

    .sub-link-title,
    a.sub-link-title {
        color: $black50;
        font-size: 22px;
        margin-top: 30;
        display: block;

        > * {
            display: inline-block;
            font-weight: $font-regular;

            &::after {
                content: "";
                display: block;
                width: 100%;
                height: 2px;
                background: transparent; } }

        &:hover {
            > * {
                color: $black50;

                &::after {
                    content: "";
                    display: block;
                    width: 100%;
                    height: 2px;
                    background: $black50; } } } }

    .fui-flow-container {
        margin-top: $space * 5;
        margin-bottom: $space * 5;

        .fui-step {
            position: relative;
            padding-left: 52px;

            &:last-child::before {
                display: none; }

            &::before {
                content: "";
                display: block;
                position: absolute;
                width: 1px;
                top: 50px;
                left: 20px;
                height: calc(100% - 60px);
                background: $lightgray100; }

            .fui-step-header {
                background: #FFF;
                margin: 0 0;
                padding: 0;

                h2 {
                    position: absolute;
                    left: 0;
                    top: 0;
                    font-size: 24px;
                    line-height: 40px;
                    text-align: center;
                    color: $business50;
                    font-weight: $font-bold;
                    margin: 0 0 0;
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    background: $lightgray100;

                    small {
                        display: none;
                        font-size: 0.875rem;
                        font-weight: $font-medium; } }

                .text {
                    display: block;
                    line-height: 40px; } }

            .fui-step-content {
                font-size: 0.875rem;
                color: $darkgray50;
                padding-bottom: 45px; } } }

    .fui-panel-tab {
        margin: ($space * 3) 0;

        .fui-tab {
            overflow-x: auto;

            &::-webkit-scrollbar {
                display: none; } }

        .fui-tab-body {
            padding-top: $space * 2; } }

    .fui-table + .fui-panel-information {
        margin-top: $space * 1.5; }

    .fui-panel-information {
        background: $lightgray50;
        .fui-collapse {
            padding: 15px;

            .collapse-body article {
                padding-bottom: 0; }

            .collapse-header {
                font-size: 16px;
                color: $darkgray50;
                font-weight: 500;
                letter-spacing: 0.2px; }

            .collapse-body {
                font-size: 14px;
                line-height: 24px;
                color: $darkgray50; }

            &.is-open {
                .collapse-body {
                    margin-bottom: 30px; } } } }

    .text a,
    .text a:link,
    .text a:visited {
        color: $business50;

        &:hover,
        &:focus {
            color: $business100; } }

    .fui-panel-title {
        margin-top: $space * 3;
        margin-bottom: $space * 3;

        &:first-child {
            margin-top: 0; }

        img.fui-title-icon {
            width: auto;
            height: 50px;
            margin: 0 0 ($space * 1.5); } }

    .fui-item {
        .content {
            font-weight: 500; } }

    .panel-layout-expand.panel-layout {
        overflow: visible;

        .fui-collapse-body article {
            overflow: visible;
            -webkit-line-clamp: initial; }

        .expand-wrapper {
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: center; } }

    .panel-layout {
        margin-top: $space * 3;
        margin-bottom: $space * 3;

        img {
            width: 100%;
            margin: 0; }

        .fui-collapse-body {
            transition: height 0.3s 0.05s ease;
            margin-bottom: $space * 2;
            overflow: hidden;

            .fui-list {
                margin-bottom: $space * 6.5;

                .fui-item {
                    padding: 3px 0; } }

            article {
                line-height: 1.5;

                @include ellipsis(8); }

            &.is-open article {
                -webkit-line-clamp: inherit; } }

        .fui-collapse-action {
            .fui-button {
                padding-left: 0;
                text-align: left; } } }

    &.is-table {
        padding: 15px; }

    &.is-collapse.is-open .fui-collapse-action .fui-button {
        color: #FFF;
        background: $darkgray70; }

    &.is-collapse .fui-collapse-body {
        * {
            color: $white; }

        .text {
            color: $white !important; }

        .fui-panel-title {
            margin-top: 60px;
            padding: 0;
            font-size: 20px;

            img {
                height: 48px; }

            &:first-child {
                margin-top: 0; } }

        .fui-list {
            margin-bottom: $space * 6.5;

            .fui-item {
                padding: 3px 0;

                .prefix i {
                    color: $blue50; } } } }

    &.is-article,
    &.is-article.is-collapse {
        padding: 30px;

        .MuiGrid-root.MuiGrid-container {
            flex-direction: row-reverse; }

        &.is-right {
            .MuiGrid-root.MuiGrid-container {
                flex-direction: row; } }

        img {
            width: 100%; }

        .fui-panel-title {
            margin: 0 0 ($space * 3); }

        .fui-panel-title,
        .fui-collapse-body {
            padding: 0; }

        .fui-collapse-action {
            margin-top: $space * 3;

            .fui-button {
                width: auto;
                text-align: left;
                padding-left: 0;
                background: none;

                i {
                    margin-left: $space; } } }

        .fui-collapse-body {
            background: none;
            height: 190px;

            article {
                @include ellipsis(7); }

            * {
                color: $black50;
                padding: 0;
                line-height: 28px; } }

        &.is-open .fui-collapse-body article {
            height: auto;
            -webkit-line-clamp: initial;
            overflow: visible; } }

    &.is-collapse {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0 !important;

        &.is-open .fui-collapse-body {
            display: block; }

        > .fui-panel-title,
        > .fui-panel-body {
            padding-left: 30px;
            padding-right: 30px; }

        .fui-panel-body {
            padding-bottom: 30px !important; }

        .fui-collapse-body > * {
            padding: 30px; }

        .fui-collapse-body {
            color: $white;
            padding: 0;
            background: $darkgray50 url(#{$publicURL}/resources/common/images/darkgray-style-2.png) repeat-y left top;
            background-size: 100% auto;
            height: 0;
            display: block;
            overflow: hidden;
            transition: all 0.3s 0.1s ease; }

        .fui-collapse-action {
            .fui-button {
                display: block;
                margin: 0;
                width: 100%;
                background: $lightgray50;
                color: $business50;
                text-align: center; } } } }

.fui-panel-title {
    color: $business50; }

* + .fui-panel-title {
    margin-top: $space * 5; }

@media screen and (max-width: 960px) {
    .fui-panel .panel-layout {
        .MuiGrid-root.MuiGrid-item {
            width: 100%;

            + .MuiGrid-root.MuiGrid-item {
                padding-top: 0; } } } }

@media screen and (min-width: 960px) {
    .fui-panel {
        padding-top: 60px !important;
        padding-bottom: 60px !important;
        padding-left: 60px;
        padding-right: 60px;

        .text,
        .body-1, p {
            font-size: 18px; }

        .fui-panel-title {
            img.fui-title-icon {
                width: auto;
                height: 70px; } }

        .fui-panel-information {
            .fui-collapse {
                padding: 30px; } }

        &.is-table {
            padding: 30px; }

        &.is-collapse {
            > .fui-panel-title,
            > .fui-panel-body {
                padding-left: 60px;
                padding-right: 60px;
                padding-bottom: 0px !important; }

            .fui-panel-body {
                padding-bottom: 60px !important; }

            .fui-collapse-body > * {
                padding: 40px 100px 20px; } }

        &.is-article,
        &.is-article.is-collapse {
            padding: 60px; }

        .fui-flow-container .fui-step {
            padding-left: 120px;

            &::before {
                left: 30px;
                top: 70px;
                height: calc(100% - 80px); }

            .fui-step-header h2 {
                line-height: 60px;
                width: 60px;
                height: 60px;
                font-size: 28px;

                + .text {
                    line-height: 60px; } }

            .fui-step-content {
                padding-bottom: 55px; } } } }
