.time-editor {
  .close-icon {
    cursor: pointer;
    position: absolute;
    right: 24px;
    top: 20px; }

  i[class*='icon-'] {
    font-size: 22px; }

  .day-container {
    border-bottom: 2px solid #EFF1F2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    padding: 20px;

    &:last-child {
      border-bottom: none; }

    .checkbox-container {
      cursor: pointer;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;

      input[type='checkbox'] {
        cursor: pointer;
        margin: 0;
        opacity: 0;
        width: 30px;
        height: 30px;
        position: absolute;
        left: 0;
        bottom: 0;

        &:checked + .checkbox-ui {
          background: #f05c38;
          border-color: #f05c38;
          &::after {
            opacity: 1;
            transform: scale(1); } } }

      .checkbox-ui {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        pointer-events: none;
        border: 2px solid #E8ECF0;
        transition: all 0.3s ease;
        margin: 0;
        width: 30px;
        height: 30px;
        @media screen and (min-width: 960px) {
          margin-top: 10px; }

        &::after {
          box-sizing: border-box;
          display: block;
          content: "";
          color: #FFFFFF;
          font-family: "icomoon" !important;
          font-style: normal;
          font-size: 1rem;
          line-height: 1;
          opacity: 0;
          text-transform: none;
          transform: scale(0);
          transform-origin: 50%;
          transition: all 0.3s ease; } } }

    @media screen and (min-width: 960px) {
      border-bottom: none;
      padding: 12px 0; } } }
