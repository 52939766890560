.fui-estore-promotion {
    padding: 30px 0;
    background-size: cover;

    .promotion-container {
        width: 100%;
        max-width: 1000px;
        margin: 0 auto;
        padding: 0 15px;
        color: $white;

        h2 {
            margin-top: 3px;
            margin-bottom: 12px; }

        .fui-button {
            margin: 0; } }

    @media screen and (min-width: 960px) {
        padding: 110px 0;

        h2 {
            margin-top: 6px;
            margin-bottom: 30px; } } }

section.fui-promo-prod-section {
    .fui-tab,
    .fui-tab.with-scroller {
        a.fui-tab-item::before,
        .fui-tab-item::before {
            height: 4px; }

        .active-bar {
            height: 4px; } }

    .fui-cards.is-product {
        margin: 0 -6px;
        padding: 0;

        .fui-card {
            position: relative;
            width: calc(50% - 12px);
            margin: 0 6px 12px;
            padding-top: $space * 4.5;
            background: $white;

            .fui-card-image img {
                width: 150px;
                height: auto; }

            .fui-card-action:hover {
                .fui-card-image img {
                    transform: scale(1); } }

            .fui-card-caption {
                padding: 18px 12px;

                .fui-card-meta {
                    color: $darkgreen;
                    font-size: 0.875rem;
                    margin-bottom: 3px; }

                .body {
                    .tag {
                        display: inline-block;
                        line-height: 20px;
                        height: 20px;
                        padding: 0 6px;
                        font-size: 0.75rem;
                        border: 1px solid $black50;
                        border-radius: 3px;
                        margin-right: 6px;
                        vertical-align: middle;
                        font-weight: $font-medium; } }

                .fui-card-price {
                    margin-top: 18px;
                    font-size: 0.875rem;

                    > div {
                        margin-bottom: 3px; }

                    .label {
                        margin-right: 6px; }

                    del {
                        color: $gray100; }

                    .price.is-text-error {
                        font-size: 1rem; } } }

            .ribbon {
                position: absolute;
                left: 0;
                top: 0;
                color: $white;
                height: 26px;
                padding: 0 6px 0;
                line-height: 26px;
                font-size: 0.75rem;
                background-color: $coral100;
                z-index: 1;

                > * {
                    position: relative;
                    z-index: 1; }

                &::before {
                    content: "";
                    position: absolute;
                    right: -26px;
                    z-index: 0;
                    border-left: 13px solid $coral100;
                    border-top: 13px solid $coral100;
                    border-bottom: 13px solid transparent;
                    border-right: 13px solid transparent; } } } }

    @media screen and (min-width: 960px) {
        .fui-cards.is-product {
            .fui-card {
                padding-top: $space * 6;
                width: calc(25% - 12px);

                .fui-card-image img {
                    width: 240px;
                    max-width: 100%; }

                .ribbon {
                    height: 28px;
                    line-height: 28px;
                    padding: 0 12px;
                    font-size: 0.875rem; }

                .fui-card-price {
                    font-size: 1rem;
                    margin-top: 12px; }

                .fui-card-caption {
                    padding: ($space * 3) 30px 30px;

                    .body {
                        font-size: 20px;

                        .tag {
                            height: 24px;
                            line-height: 24px; } }

                    .fui-card-meta {
                        margin-bottom: 6px; }

                    .fui-card-meta,
                    .body .tag {
                        font-size: 0.875rem; } } } } } }
