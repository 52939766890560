.fui-container {
    position: relative;
    width: 100%;
    max-width: 1200px;
    padding: 0 15px;
    margin: 0 auto;
    &.mb-120 {
        margin-bottom: 120px; }
    @media screen and (min-width: 960px) {
        width: 90%; } }
