.NotificationBar {
  max-height: 0;
  line-height: 40px;
  color: white;
  font-size: 13px;
  line-height: 18px;
  position: fixed;
  transition: max-height 0.4s linear;
  overflow: hidden;
  background: #231f20;
  z-index: 99;
  width: 100%;

  &.open {
    height: 100%;
    max-height: 100px;
  }
  ~ header {
    transition: all 0.3s ease;
  }
  &.app-download.open ~ header {
    transform: translateY(100px) !important;
  }
}

.NotificationBar__message {
  font-size: 0.875rem;
  line-height: 1.2em;
  max-width: 1440px;
  padding: 12px 48px 12px 15px;
  margin: 0 auto;
  color: white;

  &::after {
    content: '';
    position: absolute;
    left: 0px;
    top: 0;
    width: 6px;
    height: 100%;
    background: $accent;
    z-index: 0;
  }
  &::before {
    content: '';
    position: absolute;
    left: 6px;
    top: 0;
    border-bottom: 36px solid $accent;
    border-left: 18px solid $accent;
    border-right: 18px solid transparent;
    border-top: 36px solid transparent;
    z-index: 0;
  }

  @media screen and (min-width: 960px) {
    font-size: 1rem;
    padding: 16px 15px;
  }

  span {
    position: relative;
    z-index: 2;
  }

  a,
  a:link {
    font-weight: $font-medium;
    color: $white !important;
    text-decoration: none;
  }
}

.NotificationBar__close {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translate(0, -50%);
  line-height: 0;
  cursor: pointer;
  text-decoration: none;
  display: block;
  i {
    font-size: 1.5rem;
    color: $white;
  }
  svg path {
    fill: white;
  }
}

.NotificationBar.image-bulletin {
  height: 72px;
  .NotificationBar__message {
    padding: 0;
    max-width: none !important;
    &::before,
    &::after {
      display: none;
    }

    > a {
      position: absolute;
      width: 100%;
      height: 100%;
      display: block;
      z-index: 0;
    }

    img {
      position: absolute;
      width: 100%;
      height: auto;
      top: -100%;
      bottom: -100%;
      left: -100%;
      right: -100%;
      margin: auto;
    }
  }

  @media screen and (max-width: 320px) {
    .NotificationBar__message img {
      height: 72px;
      width: auto;
    }
  }

  @media screen and (min-width: 960px) {
    height: 60px;
    .NotificationBar__message {
      img {
        width: auto;
        height: 60px;
      }
    }
  }

  @media screen and (min-width: 1440px) {
    .NotificationBar__message {
      img {
        width: 100%;
        height: auto;
      }
    }
  }
}

.NotificationBar.app-download {
  padding: 0;
  .NotificationBar__message::before,
  .NotificationBar__message::after {
    display: none;
  }

  img {
    width: 64px;
    height: 64px;
  }
  .NotificationBar__close {
    right: auto;
    left: 11px;
    i {
      font-size: 0.75rem;
    }
  }

  .NotificationBar__message {
    color: $white;
    font-size: 0.875rem;
    text-align: left;

    small {
      font-size: 0.75rem;
      line-height: 1.3;
      color: rgba(255, 255, 255, 0.5);
    }
    .app-download-container {
      display: flex;
      padding-left: $space * 3;

      img {
        width: 64px;
      }
      .content {
        flex: 1;
        flex-grow: 1;
        padding-left: $space;
      }
      .extra {
        display: flex;
        padding: 0 10px;
        align-items: center;
        align-content: center;

        a:link {
          text-decoration: none;
          color: $white;
        }
      }
    }
  }
}
