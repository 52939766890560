.search-bar {
    position: relative;
    background: #eff1f2;
    z-index: 2;
    height: 60px;

    .fui-container {
        padding: 0;
        height: 60px; }

    form {
        position: absolute;
        width: 100%;
        display: flex;
        flex-direction: column;
        background: #eff1f2;
        padding: 15px;

        &.with-shadow {
            box-shadow: 0 18px 36px 0 rgba(75, 88, 105, 0.24); } }

    .search-body {
        flex: 1 1 auto;
        display: flex;
        margin-top: $space * 3;
        flex-direction: column;
        width: 100%;
        left: 0;
        padding: 0 0 20px;
        top: 100%;
        justify-content: space-between;
        align-content: center;
        align-items: center;

        .search-groups {
            display: flex;
            flex-direction: column-reverse; }

        .form-group {
            max-width: 404px;
            margin-bottom: $space * 1.5;

            .reset {
                right: 15px; }

            label {
                font-size: 18px;
                margin-bottom: $space * 1.5; } }

        .fui-dropdown {
            min-width: 120px;
            margin: 0 0 $space;
            width: 100%;

            button.fui-dropdown-item {
                width: 100%;
                height: 50px;
                display: flex;
                align-items: center;
                justify-content: space-between; } }

        .fui-buttons {
            margin: 0 -6px;

            .fui-button {
                width: calc(50% - 6px);
                margin: 0 6px;

                &:first-child {
                    margin-left: 0; }

                &:last-child {
                    margin-right: 0; } } }

        > div {
            width: 100%;
            min-height: 50px; } }

    .search-head {
        width: 100%;
        height: 40px;
        display: flex;
        align-content: center;
        align-items: center;

        i {
            font-size: 24px; }

        [role="button"] {
            position: absolute;
            right: 0; }

        .form-group {
            margin-bottom: 0; } }

    .fui-switch {
        margin-left: 12px;
        margin-bottom: 0;

        input + .content {
            display: flex;

            &::before {
                display: block; }

            span.off {
                left: -12px; } } }

    .search-group {
        position: relative;

        .reset {
            right: 50px; }

        .fui-button {
            position: absolute;
            right: 0;
            margin: 0;
            padding: 0;
            width: 50px;
            height: 50px;
            text-align: center;

            i {
                width: 50px;
                height: 50px;
                transition: none;
                margin: 0 !important; }

            &:hover i {
                margin: 0 !important; } } }

    @media screen and (min-width: 960px) {
        height: 80px;

        .fui-container {
            height: 80px;
            padding: 0 15px; }

        form {
            position: relative;
            flex-direction: row;
            padding: 0;
            box-shadow: none !important;
            height: 80px;
            align-items: center; }

        .search-head {
            width: auto;
            height: 50px;

            .fui-switch {
                margin-right: 94px; } }

        .search-body {
            flex-direction: row;
            margin-top: 0px;
            padding: 0;

            .search-groups {
                justify-content: space-between;
                align-content: center;
                align-items: center;
                flex-direction: row; }

            .form-group {
                margin: 0;

                .reset {
                    right: 50px; } }

            .fui-dropdown {
                margin: 0;
                max-width: 120px; } } } }

.fui-banner.is-find-product {
    min-height: 200px;
    height: 200px;
    display: flex;
    background-size: cover;
    background-position: center;
    color: $white;
    padding-top: 60px;
    align-items: center;
    align-content: center;

    .fui-container {
        padding: 0 15px;
        display: flex;
        width: 100%;
        max-width: 1200px;

        h1 {
            margin-bottom: 0; } }

    @media screen and (min-width: 960px) {
        min-height: 360px;
        height: 360px;

        .fui-container {
            width: 90%; } } }

.fui-search-tag-list {
    position: relative;
    display: block;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    margin: (-70px) -15px 8px;
    z-index: 3;
    padding-left: 15px;
    padding-right: 15px;

    .fui-search-tag-item {
        display: inline-block;
        position: relative;
        width: 120px;
        height: 62px;
        border: solid 2px $white;
        background-color: rgba(255, 255, 255, 0.24);
        margin-right: $space * 1.5;
        padding: 8px 12px;
        font-size: 18px;
        color: $white;
        font-weight: $font-medium;

        i.icon-check {
            color: $accent;
            display: none;
            font-size: 24px; }

        &.is-active {
            background: $white;
            color: $black50;

            i.icon-check {
                position: absolute;
                width: 30px;
                height: 30px;
                right: 6px;
                bottom: 6px;
                display: block;
                text-align: center;
                line-height: 30px; } } }

    @media screen and (min-width: 960px) {
        margin-top: -130px;
        margin-bottom: $space * 3;
        overflow-x: visible;

        .fui-search-tag-item {
            font-size: 22px;
            width: calc(100% / 6 - 12px);
            height: 106px;
            padding: 18px;

            i.icon-check {
                right: 18px;
                bottom: 18px; } } } }

.fui-cards.is-product {
    .fui-card.is-prod-promo {
        width: calc(50% - 12px);
        margin: 0 6px 12px; }

    @media screen and (min-width: 960px) {
        &.four-card .fui-card.is-prod-promo {
            width: calc(25% - 12px); } } }

.fui-card.is-prod-promo {
    .fui-card-image {
        img {
            width: auto;
            height: 100px; } }

    .fui-card-image {
        height: 160px; }

    .fui-card-caption {
        padding: 12px;
        background-image: linear-gradient(301deg, #c4d3d6 20%, #f4f9fa);

        .fui-card-title {
            color: $darkgreen;
            font-size: 18px; } }

    @media screen and (min-width: 960px) {
        .fui-card-image {
            height: 244px;

            img {
                width: auto;
                height: 160px; } }

        .fui-card-caption {
            padding: 24px 30px;

            .fui-card-title {
                font-size: 22px; } } } }

.fui-card.is-promotion {
    .fui-card-action {
        // background-image: url(#{$publicURL}/resources/cbu/exclusive/card-promotion-pattern-02@2x.png) !important
        background-size: cover !important;
        display: flex;
        height: 100%;

        .fui-card-content {
            background: none;
            color: $white;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-left: $space * 1.5;
            padding-right: $space * 1.5;

            .fui-card-title {
                font-size: 22px; }

            .fui-card-extra {
                padding: 0;

                .fui-button {
                    min-width: 0;
                    width: 100%; } } } }

    @media screen and (min-width: 960px) {
        .fui-card-action .fui-card-content {
            padding-left: $space * 3;
            padding-right: $space * 3;

            .fui-card-extra .fui-button {
                min-width: 190px;
                width: auto; } } } }
.fui-card.bg-red {
    .fui-card-action {
        background-image: url(#{$publicURL}/resources/cbu/exclusive/cbu-feature-card-red-390x638.jpg) !important; } }
