main.happy-go {
  .transfer {
    &--list {
      margin-bottom: 12px;
      border: 1px solid $lightgray100;
      padding: 30px;
      h5 {
        margin: 12px 0 0; } } }
  @media screen and ( max-width: 960px ) {
    .transfer {
      &--list {
        padding: 8px 16px;
        h5 {
          margin-top: 4px; } } }
    .fui-pagination {
      margin: 32px 0 0; } } }
