.avatar {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 60px;
    overflow: hidden;
    border-radius: 30px;

    img {
        position: absolute;
        left: -100%;
        right: -100%;
        top: -100%;
        bottom: -100%;
        width: 100%;
        margin: auto; } }

.emma-service {
    position: fixed;
    right: $space * 3;
    bottom: $space * 3;
    z-index: 30;

    * {
        box-sizing: border-box; }

    .iframe-container {
        position: fixed;
        width: 0px;
        height: 0px;
        bottom: 0;
        right: 0;
        z-index: 29;
        border-radius: 6px 6px 0 0;
        overflow: hidden;
        opacity: 0;
        box-shadow: 0 12px 24px 0 rgba(75, 88, 105, 0.3);
        transition: all 0.3s ease;

        .iframe-header {
            position: absolute;
            background: $black50 !important;
            color: $white;
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-content: center;
            align-items: center;
            height: 56px;
            padding: 0 24px;
            z-index: 2; }

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            border: 0;
            width: 100%;
            height: 100%;
            margin: 0;
            z-index: 0;
            border-radius: 6px; } }

    &.iframe-open {
        transform: none !important;
        z-index: 40;

        .iframe-container {
            opacity: 1;
            width: 100%;
            height: calc(100% - 50px);
            max-height: 600px;

            @media screen and (max-width: 959px) {
                max-height: none; } } }

    @media screen and (max-width: 959px) {
        .fui-go-top.is-show + &.iframe-open {
            .iframe-container {
                width: 100vw;
                height: calc(100vh - 50px);
                transform: translate(24px, 78px); } } }

    .emma-service-button {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 46px;
        height: 46px;
        border-radius: 30px;
        background: #FFFFFF;
        box-shadow: 0 14px 28px 0 #{"rgba(32, 32, 32, 0.3)"};
        z-index: 19;
        cursor: pointer;
        overflow: hidden;
        transition: width 0.3s ease;

        .text {
            color: $black50 !important; } }

    a {
        display: block;

        .text {
            opacity: 0; } }

    .emma-service-button:hover,
    &.is-open .emma-service-button {
        width: 124px;

        .avatar {
            border-radius: 0 30px 30px 0; }

        .text {
            font-size: 1rem;
            color: $business50;
            transition: all 0.3s ease;
            opacity: 1; } }

    &.is-inline {
        position: relative;
        display: inline-block;
        transition: none;
        right: auto;
        bottom: auto; }

    .icon,
    .avatar {
        width: 46px;
        height: 46px; }

    .icon {
        float: left;
        text-align: center;
        line-height: 46px;

        * {
            vertical-align: middle; } }

    .avatar {
        float: right; }

    .text {
        display: block;
        font-weight: $font-medium;
        height: 46px;
        line-height: 46px;
        padding-left: $space * 1.5; } }

.is-en .emma-service {
    .emma-service-button:hover,
    &.is-open .emma-service-button {
        width: 180px; } }

@media screen and (min-width: 960px) {
    .is-ebu .emma-service.is-open,
    .emma-service {
        .iframe-container {
            position: absolute;
            top: auto;
            bottom: 66px;
            border-radius: 6px; }

        .emma-service-button {
            width: 54px;
            height: 54px;
            border-radius: 35px; }

        &.iframe-open .iframe-container {
            width: 378px;
            height: calc(100vh - 100px);
            max-height: 600px; } }

    .fui-go-top.is-show + .emma-service.iframe-open .iframe-container {
        height: calc(100vh - 180px); }

    .is-ebu .emma-service,
    .emma-service {
        .emma-service-button:hover,
        &.is-open .emma-service-button {
            width: 140px; }

        &.is-ebu.is-open .emma-service-button,
        &.is-help-center.is-open .emma-service-button {
            width: 140px;

            .icon {
                width: 54px; } }

        .icon {
            line-height: 54px; }

        .icon,
        .avatar {
            width: 54px;
            height: 54px; }

        .text {
            height: 54px;
            line-height: 54px;
            padding-left: $space * 1.5; } }

    .is-ebu.is-en .emma-service {
        .emma-service-button:hover,
        &.is-open .emma-service-button {
            width: 190px; } } }
