@import "./section/_done-manage-newsletter";
@import "./section/_manage-newsletter";
@import "./section/_news";
@import "./section/_search";
@import "./section/_performance";
@import "./section/_tech";
@import "./section/_promotion";
@import "./section/_business-login";
@import "./section/_404";
@import "./section/help-center";
@import "./section/_google-map";
@import "./section/_5g";
@import "./section/_5g-event";
@import "./section/_store-finder";
@import "./section/e-service";
@import "./section/e-service-pay";
@import "./section/rate-plan";
@import "./section/cbu";
@import "./section/estore";
@import "./section/find-product";

main {
    position: relative;
    transition: padding 0.3s ease; }

section {
    position: relative;
    padding: 60px 0;

    h1, h2, h3, h4 {
        margin-top: 0; }

    .section-bg {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0; }

    .fui-button.fui-more,
    a.fui-more:link,
    a.fui-more:visited {
        color: $black50;
        font-weight: $font-medium;
        margin-top: 25px;

        .text {
            line-height: 1.75rem;
            height: 40px;
            margin-right: 5px; }

        &::after {
            display: none; }

        i[class*='icon-'] {
            font-size: 24px;
            height: 40px;
            vertical-align: top; } }

    .fui-container {
        position: relative;
        z-index: 1;

        .signature-container {
            position: relative;
            display: block;
            max-width: 600px;

            input {
                position: absolute;
                opacity: 0;
                top: 0;
                left: 0;
                margin: 0;
                padding: 0;
                border: 0;
                + .error-message {
                    margin-bottom: $space*2; } }
            .clear-signature {
                position: absolute;
                right: 0;
                top: 0;
                z-index: 9;

                .text {
                    height: 26px;
                    line-height: 26px; }

                + div {
                    line-height: 0;
                    border: 2px solid transparent;

                    canvas {
                        margin: 0;
                        padding: 0;
                        line-height: 0;
                        border: 0; } } }

            &.with-invalid .clear-signature + div {
                border: 2px solid $error; }

            .signature-note {
                bottom: 7*$space;
                text-align: center;
                font-size: .875rem;
                color: $darkgray30;
                z-index: 19;
                pointer-events: none; }

            .signature-action {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;

                .signature-note span {
                    vertical-align: middle; }

                .radius-icon {
                    display: inline-flex;
                    align-content: center;
                    align-items: center;
                    justify-content: center;
                    justify-items: center;
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    background: $grayblue;
                    margin-right: $space/2;

                    i[class*='icon-'] {}
                    font-size: 1rem;
                    display: block;
                    color: white;
                    transform: rotate(180deg); }

                .fui-button .text {
                    height: 26px;
                    line-height: 26px; } } }
        .sig-container {
            position: relative;
            background: #DEDEDE;
            text-align: center;
            margin-bottom: 0 !important;
            max-width: 600px; }

        .signature-result {
            position: relative;
            text-align: center;
            margin-bottom: $space*3;
            max-width: 600px;

            img {
                border: 1px solid $gray100; }

            .signature-note {
                position: absolute;
                display: block;
                margin-left: auto;
                margin-right: auto;
                text-align: center;
                left: 0;
                right: 0;
                margin: auto;
                bottom: -6px;
                span {
                    font-size: .75rem;
                    height: 24px;
                    line-height: 24px;
                    color: $gray100;
                    background: white; } } } }

    .section-title {
        font-size: 28px;
        margin-bottom: 24px; }

    .section-title,
    .fui-section-header {
        a.fui-button.is-text:link,
        a.fui-button.is-text:visited {
            text-align: right;

            i {
                margin-right: 0; } } }

    + .fui-tab {
        margin-top: -12px;

        + .fui-cards {
            // margin-left: -18px
            margin-top: -$space; } }

    > .MuiContainer-root {
        position: relative;
        z-index: 1; }

    .fui-section-header {
        margin: 0 0 ($space * 3);
        display: flex;
        justify-items: space-between;
        justify-content: space-between;
        align-items: center;
        align-content: center;

        h1, h2, h3, h4, h5, h6 {
            margin: 0; }

        .fui-button {
            color: $black100;
            margin: 0 !important;

            * {
                vertical-align: middle; } }

        + .fui-tab {
            margin-bottom: $space * 2; } }

    .animate-red-arrow {
        position: absolute;
        left: -50px;
        bottom: -50px;
        z-index: 0;
        width: 100%;
        max-width: 705px; }

    .animate-arrow-left {
        position: absolute;
        top: -210px;
        left: 0;
        z-index: 0;
        width: auto;
        height: 530px;

        svg {
            width: 100%;
            height: 100%; } }

    .animate-arrow-right {
        position: absolute;
        right: -100px;
        top: 167px;
        z-index: 0;
        height: 530px;

        svg {
            width: 100%;
            height: 100%; } }
    @media screen and ( max-width: 960px ) {
        padding: 30px 0 60px; } }
.switcher {
    text-align: right;
    position: fixed;
    bottom: 100px;
    right: 0;
    z-index: 100;
    zoom: 0.7;
    opacity: 0.5;

    &:hover {
        opacity: 1; }

    .fui-button {
        background-color: #fff !important;
        margin-bottom: 12px !important;
        margin-right: 0 !important; } }
main.four-point-five {
    @media screen and (max-width: 960px) {
        #autoConnection {
            .d-flex {
                flex-direction: column; } } } }

section.is-section-list.fui-content-image {
    .fui-item {
        span.prefix {
            color: $business50; } } }

section.fui-cards-collapse {
    background: $lightgray100 url(#{$publicURL}/resources/cbu/common/images/gray-pattern.png);
    background-size: cover; }

section.fui-content-image {
    background-color: $lightgray100;
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: 100% auto;
    color: $darkgray50;
    display: flex;
    flex-direction: column-reverse;
    padding-bottom: 311px;

    .fui-item {
        align-items: flex-start;

        > * {
            color: $darkgray50; } }

    > img {
        position: absolute;
        float: right;
        width: auto;
        height: 281px;
        right: 0;
        bottom: 0;
        margin: 0 0 0 auto;
        display: block; } }

section.fui-banner.is-solution + .fui-nav-tab.is-box-tab {
    height: auto;
    margin-top: 0;
    margin-bottom: 60px;
    margin-top: -45px;

    .text {
        font-size: 0.875rem; }

    .icon img {
        height: 30px; } }

.fui-nav-anchor.is-fixed + section.fui-breadcrumb-container + section + .fui-nav-tab.is-box-tab {
    margin-top: 0; }

section.fui-solution-tab {
    position: relative;
    padding: 0 !important;
    z-index: 1;

    .fui-nav-tab.is-box-tab {
        margin-bottom: 76px; }

    .animate-arrow-left {
        top: -310px;
        left: -200px; }

    .fui-tab {
        margin-bottom: $space * 1.5; }

    .fui-tab-pane > img {
        margin-bottom: $space * 1.5; }

    .fui-tab-pane .fui-card .fui-card-content {
        .fui-card-title .text {
            font-size: 22px;
            line-height: 33px;
            max-height: 4.5rem;

            @include ellipsis(2); }

        .fui-card-description {
            -webkit-line-clamp: 3;
            height: auto;
            max-height: 5rem; } }

    img.store-map {
        margin: 0 auto;
        max-width: 100%;
        display: block; }

    @media screen and (min-width: 960px) {
        .fui-nav-tab.is-box-tab {
            margin-top: -45px;
            margin-bottom: 98px;

            .text {
                font-size: 1rem; } }

        img.store-map {
            max-height: 320px;
            margin-bottom: $space * 3; } } }

section.fui-corp-content {
    background: $darkgray50;
    color: $white;
    text-align: center;
    background-attachment: fixed;
    background-image: url(#{$publicURL}/resources/cbu/product/images/bg-corp.png);
    background-size: cover;

    @media screen and (-webkit-min-device-pixel-ratio: 0) and (min-color-index: 0) {
        background-attachment: initial; }

    @media all and (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
        background-attachment: initial; }

    &.is-style-2 {
        background: none;
        text-align: left;
        color: $black50; }

    .fui-marquee-box .marquee-landscape-item .marquee-landscape-txt {
        padding: 0;
        margin: 0 18px;

        img {
            opacity: 1;
            width: auto;
            max-height: 70px; } } }

section.query-index {
    min-height: 560px;

    h1 {
        .fui-tooltip-container {
            i {
                font-size: 32px !important; } } }

    .member-login {
        .text-input {
            max-width: 492px; } }
    i.icon-information {
        cursor: pointer; }
    .fui-dropdown.is-button {
        max-width: 208px; }

    .paper {
        padding-top: 30px;
        padding-bottom: 60px;

        &.merged {
            margin-bottom: 0;
            border-bottom: 1px solid $lightgray100; }

        h4 {
            margin-bottom: 16px;
            margin-top: 40px; }

        .border {
            border: 2px solid $lightgray100; } } }
.paper .MuiGrid-root.MuiGrid-item.d-flex > div {
    flex: 1; }

.frammer {
    margin: 30px 0;

    &--list {
        border: 2px solid #eff1f2;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 30px;
        margin-top: -2px;

        h5, h4, h2 {
            margin: 0; } } }
main.receipt {
    section.fui-faq-collapse {
        padding: 0; } }

@media screen and (max-width: 960px) {
    section .receipt-img-wrapper {}
    flex-direction: column;

    .receipt-img {
        width: 190px;
        margin-right: auto; }

    section.query-index {
        padding-top: 30px;
        padding-bottom: 60px;
        min-height: auto;

        .body {
            font-size: 16px; }

        .fui-dropdown.is-button {
            max-width: none;
            margin: 0 0 30px; }

        .paper {
            padding-bottom: 30px;

            h4 {
                margin-top: 0; }

            .MuiGrid-item:nth-last-child(1) {
                padding-bottom: 0; } }
        a.fui-button.is-small {
            height: auto;
            margin-bottom: 0;

            span {
                font-size: 12px; } } }

    .frammer {
        &--list {
            padding: 32px 18px;

            h5 {
                max-width: 132px;
                font-size: 18px; }
            h2 {
                text-align: right; } } }
    section.usage-rate {
        .fui-button.is-small {
            height: auto;
            margin-bottom: 0;
            span {
                font-size: 12px; } }
        .rate-group {
            margin-top: 0; } } }

@media screen and (max-width: 768px) {
    section.query-index {
        a.fui-button.is-primary {
            width: 100%; }
        .page-action {
            a.fui-button.is-primary {
                width: auto; } } } }
@media screen and (max-width: 640px) {
    section.query-index {
        .page-action {
            a.fui-button.is-primary {
                width: 100%; } } } }
section.fui-section-collapse {
    padding: 60px 0;
    background: $lightgray50;
    background: #ebebeb;

    // margin-top: $space * 2

    .fui-collapse {
        color: $darkgray50;

        .collapse-header {
            font-weight: $font-medium;

            &::after,
            &::before {
                color: $darkgray50; } }

        .collapse-body {
            article {
                font-size: 0.875rem;
                line-height: 1.5rem;
                padding: 0 0 ($space * 3);
                p {
                    display: inline; } }
            p {
                font-size: 0.875rem; } } }

    &.is-static {
        padding: 30px 28px;

        .collapse-header {
            &::after {
                display: none; } }

        .collapse-body {
            height: auto;
            overflow: visible;

            ol > li {}
            line-break: anywhere; } } }

@media screen and (max-width: 960px) {
    section.fui-section-collapse {
        padding: 30px 0; } }

section.fui-faq-collapse {
    padding: 60px 0;
    background: $lightgray70;

    .fui-collapse-group {
        margin-top: 18px;
        margin-bottom: 18px; }

    .fui-collapse {
        color: $darkgray50;

        .collapse-header {
            font-size: 1rem;
            font-weight: $font-medium;
            padding: 0 ($space * 4) 0 0;

            &::after,
            &::before {
                color: $darkgray30; } }

        .collapse-body {
            p {
                font-size: 0.875rem;
                display: inline; }

            article {
                padding: 0; } } } }

section.fui-content {
    position: relative;
    padding-top: 60px;
    padding-bottom: 60px;

    &.is-section-feature {
        background-image: none !important;
        padding-bottom: 0; }

    &.is-image {
        display: block;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch; }

    &.bg-default {
        background: $lightgray100; }

    &.bg-arrow {
        background-size: auto 160px;
        background-image: url(#{$publicURL}/resources/common/images/section-bkg-pattern-1.png);
        background-repeat: no-repeat;
        background-position: top left;
        padding: 60px 0 0;

        &::after,
        &::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: 1;
            display: block;
            pointer-events: none; }

        &::before {
            opacity: 0.4;
            background-image: linear-gradient(0deg, rgba(250, 250, 250, 0) 0%, #D9E0E8 100%);
            z-index: -1; } }

    // &.bg-arrow
    //     &:nth-child(1)
    //         padding: 60px 0 36px 0
    ~ section.fui-content.bg-arrow {
        background-size: auto 200px;
        background-image: url(#{$publicURL}/resources/common/images/section-bkg-pattern-2.png);

        &::before {
            display: none; } }

    > * {
        margin-bottom: $space * 3; }

    .subtitle {
        margin-bottom: $space * 3; }

    .fui-container > div.text {
        color: $darkgray50; }

    .fui-inline-items {
        margin-top: $space * 3;

        .fui-item {
            span.prefix {
                color: $business50; } } } }

section.product-map {
    padding: 48px 0 60px;
    background: #dbe4ee;

    .fui-collapse {
        .collapse-trigger {
            display: none; }

        .product-map-list {
            display: flex;
            flex-direction: column; }

        .fui-item .content {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis; } }

    @media screen and (max-width: 960px) {
        .fui-collapse .collapse-body {
            height: auto !important; } } }

section.service-tags {
    padding: 48px 0 60px;
    background-color: $lightgray100;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(#{$publicURL}/resources/cbu/img/web-pattern-01.png);
    background-size: cover;
    color: $darkgray50;

    h3 {
        margin-top: 0; }

    .service-tag-container {
        overflow-x: auto;
        overflow-y: hidden;
        margin: 0;

        .fui-button.is-tag {
            min-width: 0;
            padding: 0 18px;
            margin-right: $space * 1.5; } } }

section.fui-horzonal-cards {
    overflow: hidden;
    z-index: 1;

    .section-title {
        max-width: calc( 100% - 90px ); }

    .section-bg img {
        height: 154px; }

    &.no-more {
        .horizontal-cards {
            .slick-arrow {
                display: none !important; } } }

    &.is-flexible {
        .collapse {
            overflow: hidden;
            height: auto;
            margin-bottom: $space * 2;
            transition: height 0.3s ease; }

        .fui-card .fui-card-description {
            font-size: 1rem;
            height: 5em;
            -webkit-line-clamp: 3; } }

    &.is-narrow {
        z-index: 0;
        padding: 60px 0;

        + .is-narrow {
            padding-top: 0; } }

    .slick-dots {
        display: none; }

    .bg {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;

        img {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            margin: auto; } } }

section.service-entry {
    .fui-cards.four-card {
        white-space: inherit !important;
        overflow: visible; } }

section.demo-shop {
    padding-top: $space * 6;
    padding-bottom: 0;
    overflow: hidden;

    .animate-arrow-left {
        z-index: -1; }

    .MuiContainer-root {
        position: relative;
        z-index: 1; }

    .shops-logo {
        padding: ($space * 4) 0;

        > span {
            opacity: 0.5;
            margin: 0 ($space * 5); } }

    @media screen and (min-width: 960px) {
        padding-bottom: 90px;

        .fui-marquee-box {
            margin-bottom: -90px; } } }

.fui-container section.fui-section-promo {
    margin: 0 -15px; }

.global-banner-ad {
    a {
        width: 100%; } }

section.fui-section-promo {
    position: relative;
    background-color: $black50;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    color: white;
    z-index: 2;
    padding: 30px 14px;
    min-height: 156px;
    overflow: hidden;
    display: flex;
    align-content: center;
    align-items: center;

    + section {
        z-index: 0 !important; }

    .fui-container {
        padding: 0; }

    &[class*='bg-pattern'] {
        background-position: left top;
        background-size: 100% auto; }

    &.bg-pattern-gray {
        background-color: $grayblue;
        background-image: url(#{$publicURL}/resources/common/images/patternd-darkgray-banner-sm.png); }

    &.bg-pattern-green {
        background-color: #8da0a4;
        background-image: url(#{$publicURL}/resources/common/images/cbu_banner_bg_750x522.jpg); }

    &.bg-pattern-blue {
        background-color: $navy50;
        background-image: url(#{$publicURL}/resources/common/images/patternd-blue-banner-sm.png); }

    &.with-pattern {
        &::after {
            display: none; } }

    .text-input {
        margin-bottom: 0; }

    .fui-input-group {
        position: relative; }

    .fui-button.is-secondary::after {
        display: none !important; }

    img.fui-section-promo-image {
        position: absolute;
        width: 100%;
        height: auto;
        left: -100%;
        right: -100%;
        top: -100%;
        bottom: -100%;
        margin: auto;
        opacity: 0.7; }

    .MuiGrid-root.MuiGrid-item {
        position: relative;
        display: flex;

        &.action {
            align-content: flex-start;
            align-items: flex-start; }

        h4.fui-section-promo-title {
            margin: 0;

            + p {
                margin: 6px 0 0; } }

        .fui-button {
            // padding: 0 $space*5
            margin: ($space * 2) 6px 0 0; } }

    &.is-edm {
        color: $black50;
        padding-left: 15px;
        padding-right: 15px;
        z-index: 0;
        background-image: url(#{$publicURL}/resources/cbu/img/web-pattern-01.png);
        background-position: left center;
        background-repeat: repeat-x;
        background-size: auto 100%;

        &::before {
            display: none; }

        .form-group.is-invalid {
            margin-bottom: 30px;

            .error-message {
                top: 40px; } }

        .MuiGrid-root.MuiGrid-item {
            padding: 0 ($space * 2); }

        .fui-action .fui-button {
            width: 100%; }

        .fui-section-promo-title {
            font-size: 18px;
            margin-top: 0;
            margin-bottom: $space * 3; } }

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        .content, .action {
            min-height: 100px; } } }

section.promotion-article {
    padding-top: 0;
    padding-bottom: 0;
    z-index: 2;

    .animate-arrow-right {
        top: -360px; }

    .promotion-article-container {
        margin-bottom: 60px; }

    .MuiGrid-item {
        display: flex;
        flex-direction: column;

        .MuiCard-root {
            flex: 1 1 0;
            flex-grow: 1;
            margin-bottom: $space * 1.5; } } }

.selected-article + .hide-mb,
.selected-article + .promotion-container {
    margin: 0 -15px;

    @media screen and (min-width: 960px) {
        margin: 0;
        height: 620px; } }

.news-content {
    .fui-nav-anchor + .fui-breadcrumb-container {
        position: relative;
        top: 0; }

    .news-subtitle {
        color: $gray100;
        margin-bottom: 12px;
        font-size: 18px;

        span {
            color: #5F6E84;
            font-weight: 500; } }

    .selected-article {
        margin-top: 0; } }

section.is-screw {
    .slick-center {
        width: 75%;

        img {
            width: 340px;
            position: absolute;
            right: 0; } } }
ul.step-list-small {
    list-style-type: none;
    padding: 0;
    margin-top: 0px;

    li {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 18px;
        font-size: 16px;
        line-height: 1.75;

        &:nth-last-child(1) {
            .circle {
                &::before {
                    display: none; } } } }

    h6 {
        color: $black50;
        font-weight: normal;
        margin: 0; }

    .circle {
        background-color: $ice;
        min-width: 22px;
        min-height: 22px;
        border-radius: 50%;
        margin-right: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #5f7b80;
        font-size: 16px;
        font-weight: 400;
        position: relative; } }
ul.step-list {
    list-style-type: none;
    padding: 0;
    margin-top: 48px;

    li {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 64px;
        align-items: baseline;
        padding-bottom: 28px;
        overflow: hidden;
        margin-bottom: 12px;

        &:nth-last-child(1) {
            .circle {
                &::before {
                    display: none; } } } }

    h6 {
        color: $black50;
        font-weight: normal;
        margin: 0; }

    .circle {
        background-color: $ice;
        min-width: 60px;
        min-height: 60px;
        border-radius: 50%;
        margin-right: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #5f7b80;
        font-size: 24px;
        font-weight: 400;
        position: relative;
        line-height: 60px;
        &::before {
            top: 36px;
            left: 18px;
            height: 100vh;
            content: "";
            height: 30px;
            width: 0px;
            border: 1px solid $lightgray100;
            position: absolute;
            left: 30px;
            top: 76px; } } }

ol.is-decimal {
    list-style-type: decimal;
    padding-left: 20px;
    margin-bottom: 32px;
    line-height: 28px;
    @media screen and (max-width: 960px) {
        &.h-100 {
            &::before {
                height: 100vh; } } } }
ul.step-list {
    @media screen and ( max-width: 960px ) {
        margin-top: 0;
        li {
            align-items: flex-end;
            .circle {
                min-width: 40px;
                min-height: 40px;
                font-size: 24px;
                height: 40px;
                width: 40px;
                line-height: 40px;
                &::before {
                    height: 100%;
                    left: 18px;
                    top: 46px;
                    min-height: 72px; } } } } }
ul.green-dot-list {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 32px;

    li {
        font-size: 16px;
        line-height: 24px;
        padding-left: 30px;
        position: relative;
        margin-bottom: 6px;
        line-height: 1.75;

        &::before {
            content: "•";
            color: #5F7B80;
            font-weight: bold;
            font-size: 28px;
            line-height: 0px;
            margin-top: 0px;
            margin-right: 10px;
            vertical-align: sub;
            position: absolute;
            top: 12px;
            left: 6px; } } }

ul.check-list {
    list-style-type: none;
    padding-left: 0;

    li {
        font-size: 16px;
        line-height: 2.6;
        color: $error;

        &::before {
            content: "";
            font-family: "icomoon" !important;
            color: $accent;
            font-weight: 400;
            font-size: 20px;
            line-height: 0px;
            margin-top: 0px;
            margin-right: 6px;
            vertical-align: sub;
            position: relative;
            top: 2px; } } }

ul.green-dot-list li ol {
    list-style-type: none;
    padding: 12px 0 0 0;

    li {
        display: flex;
        flex-direction: row;
        align-items: center;
        line-height: 1.75;
        margin-bottom: 8px;
        padding-left: 0;

        &::before {
            display: none; }

        li {
            display: flex;
            flex-direction: row;
            align-items: center;
            line-height: 1;
            margin-bottom: 8px;
            padding-left: 0;

            &::before {
                display: none; }

            .circle {
                font-size: 14px;
                background-color: $ice;
                width: 24px;
                height: 24px;
                border-radius: 50%;
                margin-right: 12px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #5f7b80;
                font-weight: 400;
                position: relative; } } }
    ul.is-decimal {
        list-style-type: decimal;
        list-style-position: outside;
        padding-left: 20px;
        li {
            line-height: 28px;
            padding-left: 0;
            &::before {
                display: none;
                justify-content: center;
                color: #5f7b80;
                font-weight: 400;
                position: relative; } } } }
ol.order-list {
    list-style-type: none;
    padding-left: 32px !important;
    list-style-position: outside;

    li {
        font-size: 16px;
        line-height: 1.75;
        counter-increment: custom;
        position: relative;
        margin-bottom: 10px;

        &:first-child {
            counter-reset: custom; }

        .decimal {
            position: absolute;
            left: -28px;
            top: 3px;
            color: $darkgreen;
            font-weight: 500;
            font-size: 16px;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            background-color: $ice;
            display: inline-flex;
            background-color: #eff4f1;
            align-items: center;
            justify-content: center; } } }
.list-info {
    img {
        width: 160px;
        height: 160px;
        display: inline-block; }
    .d-inline-block {
        vertical-align: top; }
    .check-list {
        p {
            color: #5f7b80; }
        li {
            display: flex;
            align-items: baseline; } }
    .uncheck-list {
        li::before {
            content: "\e903";
            font-weight: 600; } }
    @media screen and (max-width: 960px) {
        img {
            width: 130px;
            height: 130px; }
        .d-inline-block {
            width: calc( 100% - 130px );
            .check-list {
                align-items: baseline;
                .d-inline-block {
                    width: 100%; }
                li::before {
                    bottom: -2px;
                    position: relative; } }
            .uncheck-list {
                margin-bottom: 0; } } } }
main.app-list-page {
    margin-top: 50px;

    section.fui-breadcrumb-container {
        position: relative;
        top: 0; }

    .list-wrapper {
        margin-top: 0;
        padding-top: 42px;

        .paper {
            margin: 0;

            img {
                width: 100px;
                height: 100px;
                margin-right: 30px; }

            .body {
                font-weight: 500;
                margin-bottom: 10px; }

            p {
                font-size: 14px;
                color: #3a485d;
                line-height: normal;
                margin: 0;

                @include ellipsis(3); }

            a {
                img {
                    width: 148px;
                    height: 45px;
                    margin-right: 12px; } }

            hr {
                margin: 24px 0; }

            ul {
                list-style-type: none;
                padding-left: 0;
                margin: 0;

                li {
                    font-size: 14px;
                    padding-left: 30px;
                    position: relative;
                    color: #3a485d;

                    &::before {
                        content: "•";
                        color: #5F7B80;
                        font-weight: bold;
                        font-size: 28px;
                        line-height: 0px;
                        margin-top: 0px;
                        margin-right: 10px;
                        vertical-align: sub;
                        position: absolute;
                        top: 9px;
                        left: 6px; } } } } }

    @media screen and (max-width: 960px) {
        .list-wrapper {
            margin: 0 0 30px !important;

            .paper {
                img {
                    width: 70px;
                    height: 70px;
                    margin-right: 18px; }

                .body {
                    margin-bottom: 0; }

                p {
                    @include ellipsis(3); }

                a {
                    img {
                        width: 118px;
                        height: 36px; } } } } } }

main.profile-page {
    background-image: url(#{$publicURL}/resources/cbu/img/bg-pattern-ribbon@2x.png);
    background-position: 0px 780px;
    background-size: cover; }

section.happy-go {
    .fui-card {
        a.fui-card-action {
            min-height: 153px;

            .fui-card-content {
                display: flex;
                flex-direction: column; }

            .fui-card-extra {
                display: none; }

            p.fui-card-description {
                height: auto;
                font-size: 18px;
                font-weight: $font-medium;
                align-content: flex-end;

                * {
                    vertical-align: middle; } } } }

    @media screen and (max-width: 768px) {
        .fui-card {
            a.fui-card-action .fui-card-content {
                padding: 12px 12px 0 12px;

                h5.fui-card-title {
                    font-size: 18px; } } } } }

section.sitemap-tab {
    background: $ice; }

@media screen and (max-width: 768px) {
    section .fui-cards.is-happy-go {}
    .fui-card {
        width: calc(50% - 12px);
        margin-bottom: 12px; } }

@media screen and (max-width: 960px) {
    section.fui-help-shortcut {
        .d-flex {
            margin-top: $space * 6;
            flex-wrap: wrap;

            .fui-shortcut-item {
                .text {
                    flex: 1 1 0;
                    flex-grow: 1; }

                img {
                    width: 60px; } } } } }

section.prepaid-roaming {
    .receipt {
        .form-group {
            .form-group {
                margin-bottom: 0; } } } }

section.usage-rate {
    background-image: url(#{$publicURL}/resources/common/images/bg-section-pattern@2x.png);
    background-color: $ice;
    background-position: left top;
    background-size: 100% auto;
    background-repeat: no-repeat;
    min-height: 500px;
    .min-height-64 {
        min-height: 64px; }

    .fui-segments {
        .action {
            bottom: 30px; }
        .fui-pagination {
            margin-bottom: 30px; }

        .program-name + .bill-table {
            margin-top: 60px; }

        .compareTable {
            i {
                transition: all 0.5s;
                display: inline-block; }

            tr.is-bg-lightgray70 td {
                white-space: nowrap; }

            .rotate180 {
                transform: rotate(180deg);
                transition: all 0.5s; }

            .icon-chevron-down {
                font-size: 24px;
                vertical-align: text-top; }

            .compareList {
                min-width: auto;
                font-weight: 400;

                &.odd-table {
                    tr:nth-child(odd) {
                        background-color: $lightgray70; } } }

            .tableLeft {
                border-right: 0px solid $lightgray100;
                border-left: 0px solid $lightgray100;
                margin: 0;
                font-weight: 500;

                tr {
                    background-color: $white; } }

            .tableRight {
                tr {
                    background-color: $white;

                    td {
                        min-width: 160px; }
                    &:nth-of-type(1) {
                        td {
                            font-weight: 500; } } } }
            &.w-200 {
                .tableRight {
                    @media screen and (min-width: 960px) {
 }                        // border-right: 2px solid $lightgray100
                    // .tableBody
 } }                    //     margin-right: -3px
            &.w-116 {
                .tableLeft {
                    width: 116px; }
                .tableRight {
                    padding-left: 114px;

                    @media screen and (min-width: 960px) {
 }                        // border-right: 2px solid $lightgray100
                    .tableBody {
                        margin-right: -3px; } } } }
        .sum-table {
            margin-top: -2px;

            td {
                min-width: 152px;

                &:nth-last-child(1) {
                    width: 35.85%; } } }

        @media screen and (max-width: 960px) {
            .fui-pagination {
                margin: 30px 0 0; }

            .compareTable {
                .icon-chevron-down {
                    vertical-align: middle; }

                .compareList {
                    // table-layout: fixed
                    min-width: 152px;

                    td {
                        min-width: 154px; } }

                .not-fixed {
                    table-layout: auto; }

                .tableLeft {
                    width: 154px; }

                .tableRight {
                    padding-left: 152px;
                    // border-right: 2px solid $lightgray100
                    // .compareList
                    //     outline: 2px solid $lightgray100
 }                    //     outline-offset: -2px
                &.w-200 {
                    .tableLeft {
                        width: 200px; }
                    .tableRight {
                        padding-left: 198px; } } }

            .sum-table {
                margin-right: -21px;

                td {
                    &:nth-last-child(1) {
                        width: calc(( 100vw - 224px)/2); } } } }

        @media screen and (max-width: 540px) {
            .compareTable {
                .tableRight {
                    margin-right: -21px;
                    &::after {
                        display: none; } } }
            .sum-table {
                margin-right: -21px;
                td {
                    &:nth-last-child(1) {
                        width: 154px; } } } }

        .fui-collapse {
            .collapse-header {
                padding: 30px;
                margin-bottom: 0;
                font-weight: normal; }
            .is-agreement {
                margin-bottom: 16px; }
            .collapse-body {
                // padding: 0 30px
                padding: 0;
                margin: 0;

                article {
 }                    // padding-top: 0

                small {
                    margin-bottom: 12px;
                    display: block; } } } }

    table.bill-table {
        margin-top: 30px;
        table-layout: fixed;

        th {
            background-color: $lightgray70; }

        td {
            background-color: $white; } }

    &.no-bg {
        background-image: none;
        padding-top: 0;
        background-color: transparent;

        .rate-group {
            margin-top: 0;

            .item-wrapper {
                display: flex;
                justify-content: space-around;
                margin-top: 64px; }

            .rate-item {
                &:nth-last-child(1) {
                    margin-bottom: 0; }

                .number {
                    margin-left: -32px;

                    h6 {
                        &::before {
                            right: 8px;
                            border-bottom: 26px solid $black50;
                            border-right: 36px solid $black50;
                            border-top-width: 26px; }

                        &::after {
                            border-bottom: 26px solid $white;
                            border-right: 36px solid $white; } } } } }

        @media screen and (max-width: 768px) {
            &.no-bg {
                .item-wrapper {
                    display: block; }

                .paper {
                    margin-bottom: 30px; }

                .rate-group .rate-item {
                    .number {
                        margin-left: auto;

                        h1 {
                            margin-bottom: 10px; }

                        h6 {
                            &::before {
                                border-right: 32px solid $black50; }

                            &::after {
                                border-top-width: 30px; } } } } } } }

    .rate-group {
        display: block;
        width: 100%;
        margin-top: $space * 5;
        margin-bottom: 60px;

        .rate-item {
            display: inline-block;
            width: 200px;
            vertical-align: top;
            text-align: center;

            .number {
                position: relative;
                display: inline-block;
                margin: 0 auto ($space * 1.5);

                h1, h6 {
                    margin: 0; }

                h1 {
                    font-size: 60px;
                    text-align: center;
                    display: inline-block; }

                h6 {
                    position: absolute;
                    right: -36px;
                    bottom: -4px;
                    display: block;
                    z-index: 2;

                    &::after,
                    &::before {
                        content: "";
                        position: absolute;
                        border-top: 26px solid transparent;
                        border-bottom: 26px solid $ice;
                        border-right: 36px solid $ice;
                        border-left: 36px solid transparent;
                        right: 4px;
                        bottom: 0;
                        z-index: -1; }

                    &::before {
                        right: 8px;
                        border-bottom: 26px solid $black50;
                        border-right: 36px solid $black50; } }

                &.is-text-error h6::before {
                    border-bottom: 26px solid $error;
                    border-right: 36px solid $error; } } } }

    @media screen and (max-width: 768px) {
        background-image: url(#{$publicURL}/resources/common/images/bg-section-pattern-m@2x.png);

        table.bill-table {
            margin-top: 30px;

            .fui-tooltip-container {
                display: none; } }

        .rate-group {
            margin-bottom: 0;

            .rate-item {
                margin-bottom: $space * 7;
                width: 50%;

                .number {
                    h1 {
                        font-size: 3rem; }

                    h6 {
                        right: -30px;

                        &::after,
                        &::before {
                            border-top-width: 22px;
                            border-bottom-width: 22px;
                            border-right-width: 30px;
                            border-left-width: 30px; }

                        &::after {
                            right: 2px;
                            border-bottom-color: #ebefef;
                            border-right-color: #ebefef;
                            bottom: -1px; }

                        &::before {
                            right: 4px; } } } } }

        .fui-buttons {
            .fui-button {
                display: block;
                width: 100%;
                margin-left: 0;
                margin-right: 0; } } } }

section {
    .fcoin-record-list {
        .fui-record-item {
            border: solid 2px $lightgray100;
            display: flex;
            padding: 30px;
            margin-bottom: $space * 1.5;

            .extra {
                text-align: right;

                > .fcoin {
                    font-size: 28px;
                    font-weight: $font-medium;

                    * {
                        vertical-align: middle; }

                    img {
                        width: 24px;
                        margin-right: 6px; } } }

            .content {
                flex: 1;
                flex-grow: 1;

                h4, h5 {
                    margin: 0 0; }

                h5 {
                    margin-top: 6px; } } } }

    .fui-cards.is-recommand-activity {
        flex-wrap: wrap;
        align-items: initial;

        .fui-card {
            box-shadow: none !important;
            height: auto;

            a.fui-card-action:link,
            a.fui-card-action:visited {
                color: $black50;
                border: solid 2px $lightgray100;
                display: flex;
                flex-direction: row;
                padding: ($space * 2) ($space * 1.5) ($space * 2) ($space * 2);
                min-height: 172px;
                align-items: flex-end;

                &::after {
                    display: none; }

                .fui-card-extra {
                    padding: 0;
                    display: flex;
                    align-content: center;
                    align-items: center;
                    justify-content: center;
                    justify-items: center;

                    i[class*='icon'] {}

                    font-size: 24px;
                    width: 30px;
                    height: 30px; }

                .fui-card-content {
                    flex: 1;
                    flex-grow: 1;
                    padding: 0;

                    h5 {
                        font-size: 22px; }

                    .fui-card-description {
                        margin-top: 6px;
                        height: auto;
                        overflow: visible; }

                    .description {
                        * {
                            vertical-align: middle; }

                        img {
                            width: 30px;
                            margin-right: 6px; } } } } } }

    @media screen and (max-width: 960px) {
        .fcoin-record-list .fui-record-item {
            flex-direction: column;
            padding: 30px 18px;
            min-height: 216px;

            .content {
                h4, h5 {
                    font-size: 20px; } }

            .extra {
                display: flex;
                justify-content: space-between;
                align-content: center;
                align-items: center;

                .fcoin {
                    font-size: 24px; } } }

        .fui-cards.is-recommand-activity {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;

            .fui-card {
                width: 100%;
                margin-bottom: 12px;

                .fui-card-action {
                    min-height: 154px; } } } } }

section.fcoin-dashboard {
    padding-top: 152px;
    padding-bottom: 80px;
    background-image: url(#{$publicURL}/resources/cbu/img/bg-cbu-fcoin-pattern-1920-x-1200@2x.png);
    background-size: cover;
    // min-height: 460px

    h3 {
        margin-bottom: $space * 3; }

    h1.fcoin-number {
        font-size: 90px;
        color: $accent;
        margin-top: $space * 3;

        img {
            margin-right: 6px; } }

    .gray-block {
        padding: $space * 3;
        background: $gray30;
        height: 135px;
        margin-top: $space * 4;

        h2 {
            margin-bottom: 0; } }

    @media screen and (max-width: 960px) {
        padding-top: $space * 6;
        padding-bottom: 20px;
        background: $white url(#{$publicURL}/resources/cbu/img/bg-personal-right-pattern-all-m@2x.png) no-repeat top center;
        background-size: 100% auto;

        .fcoin-container {
            text-align: center;

            h3 {
                font-weight: $font-regular;
                margin-bottom: 6px;
                font-size: 22px; }

            h1.fcoin-number {
                font-size: 60px;
                line-height: 63px;
                margin-top: 0;
                margin-bottom: 0;

                i {
                    font-size: 24px;
                    vertical-align: middle; }

                img {
                    width: 36px; } } }

        .gray-block {
            padding: 18px;
            margin: 0 20px 6px;
            min-height: 0;
            height: auto; } } }

section.login-recommend {
    padding-top: 120px;
    padding-bottom: 80px;
    background-image: url(#{$publicURL}/resources/cbu/img/bg-cbu-fcoin-pattern-1920-x-1200@2x.png);
    background-size: cover;
    width: 100%;
    .fetnet-coin {
        .percentage {
            height: 30px; } }

    .level-info {
        .d-inline-flex {
            display: flex;
            align-items: center;

            h1 {
                margin: 0; }

            img {
                width: 30px;
                height: 30px;
                margin-right: 6px; } }

        .body {
            margin-top: 20px; } }

    .member-badge {
        padding: 30px 12px 30px 30px;
        display: flex;
        word-break: break-all;
        img {
            max-width: 100px;
            width: 100%;
            height: 100%;
            margin-right: 20px; }
        h1 {
            margin: 0; } }

    .ruler {
        background-color: #f1f3f5;
        width: 100%;
        margin-bottom: 6px;
        overflow: hidden;

        &.main {
            .percentage {
                height: 48px;
                background-image: linear-gradient(to left, #fc7b66 0%, #ffa188);

                span {
                    font-size: 22px;
                    margin-top: 10px;

                    &::after {
                        border-color: transparent transparent transparent #fc7a66;
                        border-width: 48px 0 0 48px;
                        top: -9px;
                        right: -48px; } } } }

        .percentage {
            height: 30px;
            background-image: linear-gradient(to right, #ffdf9e, #ffa72d 97%);
            position: relative;

            span {
                position: absolute;
                right: 0;
                font-size: 18px;
                color: #fff;
                font-weight: 500;
                margin-top: 2px;

                &.zero {
                    left: 10px;
                    color: #5f6e84;

                    &::after {
                        display: none; } }

                &::after {
                    content: "";
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-color: transparent transparent transparent #ffa72d;
                    position: absolute;
                    border-width: 30px 0 0 30px;
                    top: -2px;
                    right: -30px; } } } }

    h4 {
        margin-top: 12px;
        margin-bottom: 48px;

        a.fui-button.is-text {
            font-size: 24px;
            color: $accent;
            line-height: normal;
            margin: 0; }

        span.member-info {
            border-left: 1px solid $gray50;
            padding: 0 6px 0 15px;

            &:first-child {
                padding-left: 0;
                border-left: 0; }

            * {
                vertical-align: middle; }

            img {
                width: 36px;
                height: auto;
                margin-right: 6px; }

            a:link,
            a:visited {
                color: $black50; } } }

    .fui-card {
        img {
            max-width: 236px;
            width: 100%;
            height: auto;
            margin: 0 auto 24px; }

        .fui-card-extra {
            padding: 0; }

        &.is-guest .fui-card-content {
            padding: 60px 30px 24px;
            display: flex;
            flex-direction: column;

            h3 {
                flex: 1 auto;
                flex-grow: 1 auto; } }

        &.is-postpaid .fui-card-content {
            padding: 30px ($space * 3) ($space * 3);

            h4,
            h1 {
                margin: 0; }

            .columns {
                display: flex;
                margin-bottom: ($space * 3) !important;

                > .column {
                    flex: 1;
                    flex-grow: 1;
                    padding-left: 12px;
                    border-left: 1px solid $gray50;

                    .description {
                        margin-bottom: 0 !important; }

                    &:first-child {
                        border-left: 0;
                        padding-left: 0;
                        padding-right: 20px; } } }

            .description {
                * {
                    vertical-align: middle;
                    margin: 0; }

                img.icon {
                    width: auto;
                    margin-right: 6px; } } }

        h3 {
            margin-bottom: 30px; } }

    .is-promotion {
        background-image: url(#{$publicURL}/resources/cbu/img/subtitle-title-content-copy-2@2x.png);
        background-size: cover;

        .fui-card-action {
            color: $white;
            background: none;

            .fui-card-content {
                padding-top: 0;
                padding-bottom: 0;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start; } }

        h4 {
            width: 100%;
            display: block;
            margin: 0 0 30px; }

        a.fui-button {
            margin: 0; } }

    @media screen and (max-width: 960px) {
        padding-top: 60px;
        padding-bottom: 0;
        background: $white url(#{$publicURL}/resources/cbu/img/bg-personal-right-pattern-all-m@2x.png) no-repeat top center;
        background-size: 100% auto;

        .level-info {
            .body {
                margin-top: $space; } }

        .fui-cards {
            .fui-card {
                width: calc(100% - 12px);

                .fui-card-extra, .fui-button {
                    width: 100%; } }

            .fui-card.is-postpaid {
                .fui-card-content {
                    .fui-button {
                        width: 100%; } } }

            .fui-card.is-guest {
                img {
                    width: 160px;
                    height: auto; }

                .fui-card-content {
                    padding: ($space * 6) ($space * 3) ($space * 4); }

                // &.is-promotion .fui-card-action .fui-card-content h4
 } } } }                //     margin: ($space * 6) 0

main.life-circle-app {
    background-image: url(#{$publicURL}/resources/cbu/img/bg-cbu-fcoin-pattern-1920-x-1200@2x.png);
    background-size: contain;
    width: 100%;

    .video {
        margin-top: 30px;

        h1 {
            margin-bottom: 6px; }

        img {
            max-width: 800px; } } }

@media screen and (max-width: 960px) {
    main.life-circle-app {
        background-image: url(#{$publicURL}/resources/cbu/img/bg-cbu-fcoin-pattern-m@2x.png);
        background-position-y: 30px;
        background-size: contain;

        .video {
            padding-top: 0;

            h3 {
                margin-bottom: 60px; }

            img {
                width: 100%; } } } }

@media screen and (max-width: 960px) {
    .discount-layout {
        .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-8 .MuiGrid-root.MuiGrid-item {
            padding-top: $space * 1.5;
            padding-bottom: $space * 1.5; } } }

section.partner {
    background-image: url(#{$publicURL}/resources/cbu/img/carousel-partner-bkg@2x.png);
    height: 550px;
    background-size: cover;
    padding-bottom: 60px;

    h5 {
        margin: 0; }

    .brands-wrapper {
        div {
            display: inline-block;
            padding: 0 90px;
            width: 33%;

            img {
                max-width: 186px;
                margin: 0 auto; } } }

    .horizontal-cards.slick-slider {
        height: 100%; }

    .slick-dots {
        position: absolute;
        bottom: 56px;
        z-index: 1;
        list-style: none;
        margin: 0 auto;
        padding: 0;
        left: 0;
        right: 0;
        text-align: center;

        .slick-active {
            background: $white;

            button {
                opacity: 0;
                background-color: #fff; } }

        li {
            position: relative;
            display: inline-block;
            margin-right: 6px;
            background-color: $gray100;
            height: 4px;
            width: 50px;

            button {
                position: absolute;
                width: 100%;
                height: 100%;
                opacity: 0;
                left: 0;
                cursor: pointer; }

            &:nth-last-child(1) {
                margin-right: 0;

                button {
                    cursor: pointer;
                    width: 50px;
                    height: 4px;
                    border: 0;
                    vertical-align: top; } } } }

    .dark-dot {
        .slick-dots {
            .slick-active {
                background-color: $black100; } } } }

@media screen and (max-width: 960px) {
    section.partner {
        height: 390px;
        background-image: url(#{$publicURL}/resources/cbu/img/carousel-partner-bkg-m@2x.png);

        .brands-wrapper div {
            padding: 0;

            img {
                width: 100%;
                max-width: 100px; } } } }

.w-100 {
    width: 100%; }

main.my-order {
    position: relative;

    .fui-section-collapse {
        padding: 40px 0;

        .collapse-body {
            margin-bottom: 0; } }

    .my-order-wrapper {
        padding-top: 112px; }

    .order-status {
        .paper {
            margin-bottom: 12px;

            h3 {
                @include ellipsis(2); }

            h5 {
                font-weight: normal;
                margin-bottom: 0; }

            .has-price {
                h3, h5 {
                    margin: 0; } } }

        .subtitle {
            margin: 0;
            font-weight: normal;
            margin-bottom: 42px; }

        .img-wrapper {
            flex: 0 0 150px;
            margin-right: 40px;

            img {
                width: 100%; } }

        h2 {
            margin-bottom: 18px; } }

    .status-list {
        display: flex;
        justify-content: space-between;
        position: relative;

        &::before {
            content: "";
            border-bottom: 2px solid $gray70;
            width: calc( 100% - 46px );
            left: 20px;
            position: absolute;
            top: 6px; }

        .d-flex {
            width: 100%; }

        .subtitle {
            margin: 36px 0 0;
            text-align: center;
            line-height: 1.78;
            max-width: 64px;
            color: $gray70;
            position: relative;

            &.active {
                color: $darkgray50;

                &::before {
                    color: $darkgray50;
                    font-size: 124px;
                    line-height: 70px;
                    left: -4px;
                    font-family: "Arial";
                    top: -65px; } }

            &::before {
                content: "•";
                font-size: 64px;
                position: absolute;
                top: -85px;
                left: 0;
                width: 100%;
                font-family: "Arial"; } } }

    @media all and (-ms-high-contrast: none) {
        .status-list {
            .subtitle::before {
                min-width: 0; } } }

    &.has-bg {
        background-image: url(#{$publicURL}/resources/cbu/img/bg-cbu-colorslash-pattern-1440-x-725.svg);
        background-size: cover;
        background-repeat: no-repeat;
        height: initial;

        h2::before {
            content: "";
            background-image: url(#{$publicURL}/resources/cbu/img/img-cbu-arrow-pattern.svg);
            background-size: contain;
            position: absolute;
            left: -100px;
            top: 0;
            display: block;
            width: 100px;
            height: 144px; } }

    .fui-breadcrumb-container {
        margin-bottom: 48px; }

    h2 {
        margin-bottom: 48px; }

    .paper {
        padding: 60px 30px;
        width: 100%;
        margin-bottom: 0;

        h2 {
            margin-top: 0;
            margin-bottom: 0; }

        h5 {
            margin-top: 15px;
            margin-bottom: 60px; }

        h6 {
            margin-bottom: 18px; }

        .input-wrapper {
            padding-bottom: 60px;

            .error-message {
                display: block;
                position: absolute; }

            &.invalid {
                input {
                    border: 2px solid $error; } }

            input {
                padding: 12px;
                border: 2px solid $lightgray100;
                width: 100%;
                font-size: 16px; } }

         ::placeholder {
            color: $gray70; } } }

@media screen and (max-width: 960px) {
    main.my-order {
        .my-order-wrapper {
            padding-top: 60px; }

        .order-status {
            padding-top: 30px;
            min-height: calc( 100vh - 597px );

            .img-wrapper {
                flex: 0 0 92px;
                margin-right: 12px; }

            .subtitle {
                margin-bottom: 0; } }

        .paper {
            padding: 30px 20px;
            margin: 0;

            h5 {
                margin-bottom: 42px; }

            h6 {
                margin-bottom: 10px; }

            input {
                margin-bottom: 42px; }

            .fui-button {
                min-width: 227px; }

            .d-inline {
                .d-inline-flex {
                    flex-direction: column; } }

            p {
                margin-bottom: 0;
                font-size: 14px;
                line-height: 1.7; }

            .MuiGrid-spacing-xs-6 > .MuiGrid-item {
                padding: 12px 24px;

                &.d-flex {
                    margin-top: 30px; } }

            h3 {
                margin: 0;
                font-size: 20px;
                line-height: 30px; }

            a.fui-button {
                margin: 0;
                font-size: 14px;
                padding: 0;
                height: 22px; }

            .fui-button {
                padding: 0 10px;
                height: auto;
                min-width: 227px;

                span {
                    font-size: 12px; } }

            h5 {
                font-size: 14px;
                margin: 0;
                line-height: 1.7; } }

        .status-list {
            flex-direction: column;
            margin-top: 30px;

            &::before {
                left: 14px;
                position: absolute;
                top: 20px;
                width: 2px;
                height: calc( 100% - 50px);
                border-left: 2px solid $gray70;
                border-bottom: 0; }

            .d-flex {
                flex-direction: column; }

            .subtitle {
                margin: 0 0 32px 42px;
                max-width: 100%;
                text-align: left;

                &.active::before {
                    left: -49px;
                    top: -21px; }

                br {
                    display: none; }

                &::before {
                    top: -40px;
                    left: -38px; }

                &:nth-last-child(1) {
                    margin-bottom: 12px; } } }

        &.has-bg {
            padding-bottom: 30px;
            background-image: url(#{$publicURL}/resources/cbu/img/bg-cbu-colorslash-pattern-375-x-704.svg);

            h2 {
                &::before {
                    width: 49px;
                    height: 70px;
                    left: 0px;
                    top: -60px; } } }

        .fui-breadcrumb-container {
            margin-bottom: 0;

            .fui-container {
                &::before {} } } } }

.sitemap, #product, #program, #lifeCircle, #personal, #helpCenter, #discount {
    a.fui-button.is-text {
        // white-space: pre

        .icon-chevron-right {
            margin-right: 0; } } }

main.store {
    margin-top: 50px;

    .fui-nav-anchor {
        top: 50px;

        &.is-fixed {
            top: 0; } }

    .fui-nav-anchor + section.fui-breadcrumb-container {
        top: 100px; }

    @media screen and (max-width: 960px) {
        .fui-button.fui-more, section a.fui-more:link, section a.fui-more:visited {
            margin-top: 10px; } } }

.fui-member-info.ribbon-bg,
.fcoin-tab-wrapper.ribbon-bg {
    background-image: url(#{$publicURL}/resources/cbu/prepaid/images/ribbon-pattern-1920.jpg);
    background-size: cover;
    background-repeat-x: no-repeat;
    z-index: 2;
    width: 100%;
    height: auto;
    position: relative;

    @media screen and (max-width: 960px) {
        background-image: url(#{$publicURL}/resources/cbu/prepaid/images/cbu_ribbon_pattern_bkg@2x.jpg); } }

.fui-member-info {
    padding-bottom: 0;

    .member-levels {
        margin: ($space * 5) 0;

        img {
            width: 120px; } }

    .member-benefits-content {
        .member-benefits-item {
            > .icon {
                float: left;

                > img {
                    width: 30px; } }

            > .content {
                display: block;
                margin-left: 48px;
                line-height: 28px;

                .is-text-darkgray50 .fui-list .fui-item {
                    color: $darkgray50;

                    .prefix i {
                        color: $darkgray50;
                        background-color: $darkgray50; } }

                h5 {
                    font-weight: $font-regular;
                    margin-bottom: $space * 2; } } } }

    @media screen and (max-width: 600px) {
        .member-levels {
            margin: 30px 0;

            img {
                width: 20%; } }

        .member-benefits-content {
            .member-benefits-item {
                > .content {
                    margin-left: 36px;

                    h5 {
                        margin-bottom: $space * 1.5; } } } } } }

.ribbon-bg {
    background-image: url(#{$publicURL}/resources/cbu/prepaid/images/ribbon-pattern-1920.jpg);
    background-size: 100% auto;
    background-repeat-x: no-repeat;
    width: 100%;
    height: auto;
    position: relative; }

main.prepaid {
    position: relative;

    #faq {
        width: 100%;
        padding: 0 5px; }

    .mt-30 {
        margin-top: -30px; }

    .compareTable {
        .tableLeft {
            width: 100px; }

        .tableRight {
            padding-left: 98px; } }

    .ribbon-bg-wrapper {
        .fui-panel {
            background-image: url(#{$publicURL}/resources/cbu/prepaid/images/ribbon-pattern-1920.jpg);
            background-size: cover;
            background-repeat-x: no-repeat;
            width: 100%;
            margin: 0; } }

    #howTo {
        padding-bottom: 0;

        figure figcaption {}
        color: $darkgray50;

        &::before {
            border-bottom: 7px solid $accent; } }

    section.fui-faq-collapse {
        padding-top: 30px;
        padding-bottom: 30px; }

    .program-internet-mb {
        display: none; }

    .fui-tab-body {
        section.fui-faq-collapse {
            padding: 0;

            .fui-collapse {
                padding: 18px 0;

                ol {
                    padding-left: 20px; } }

            .fui-collapse-group {
                margin-bottom: 36px; } } }

    .fui-tab-pane {
        z-index: 18; }

    .fui-collapse {
        .collapse-body {
            margin-bottom: 0;

            article {
                padding: 0;

                ol {
                    margin: 0;
                    line-height: 1.7;
                    font-size: 14px; } }

            table {
                margin-top: 18px; } }

        .collapse-header {
            padding: 0 30px 0 0; }

        &.is-open {
            .collapse-header {
                margin-bottom: 24px; } } }

    section.fui-section-collapse {
        .collapse-header {
            font-size: 16px; }

        .collapse-body {
            margin-bottom: 0;

            article {
                margin-top: 21px;
                padding-bottom: 0; } } }

    .fui-nav-tab + .fui-breadcrumb-container, .fui-nav-anchor + .fui-breadcrumb-container {
        top: 45px; }

    .is-text-bold {
        font-weight: $font-bold; }

    .is-text-medium {
        font-weight: $font-medium; }

    .is-text-regular {
        font-weight: $font-regular; }

    .is-text-light {
        font-weight: $font-light; }

    h6.paper-h6 {
        margin-top: 0;
        margin-bottom: 24px;
        font-weight: 400; }

    h6.is-text-darkgreen {
        margin: 0;
        font-weight: 400; }

    .paper {
        &.fui-collapse {
            margin-bottom: 12px; }

        & + .fui-button {
            margin-top: 20px; }

        & + h2 {
            margin-top: 88px; }

        .h6-title {
            margin: 0;
            font-weight: 500; }

        .rate-plan-box {
            height: 100%; }

        section.fui-section-collapse {
            padding: 30px;
            background-color: $lightgray50;

            .fui-container {
                padding: 0;
                margin: 0;
                width: 100%; } }

        h4 {
            font-weight: 500; }

        .fui-button {
            i {
                font-size: 30px !important;
                font-weight: 400; }

            img {
                margin-left: 8px;
                margin-bottom: 12px; } } }

    section.fui-solution-tab {
        .tab-content {
            padding-bottom: 70px;

            .slick-slider {
                button::after {
                    font-size: 36px; } }

            h1 {
                font-size: 36px; } }

        .slick-slide {
            .fui-card {
                ul {
                    padding-bottom: 60px; }

                .form-group {
                    position: absolute;
                    bottom: 36px; }

                img {
                    max-width: 240px; } } } }

    .fui-horzonal-cards {
        .fui-more {
            margin-top: 12px; } }

    .fui-faq-collapse {
        background: $lightgray70; }

    .fui-panel-information {
        padding: 60px 0;
        position: relative;
        margin: 0;

        .collapse-header {
            font-weight: 500; }

        .collapse-header, .collapse-body {
            color: $darkgray50;

            ol {
                padding-left: 20px; } } }

    .airport-info {
        display: flex;
        align-items: center;
        margin-top: 44px;
        justify-content: center;

        img {
            max-width: 400px;
            margin-right: 60px; } }

    .select-lang {
        position: relative;
        width: 100%;
        margin-bottom: -62px;
        display: flex;
        z-index: 29;
        padding-top: 24px;

        .fui-dropdown {
            width: 135px;
            background-color: rgba(0, 0, 0, 0.2);

            button {
                height: 40px;
                margin: 0 auto;

                i {
                    margin-left: 10px; } }

            .fui-item {
                span.content {
                    text-align: left;
                    margin-left: 12px; } } } }

    .fui-nav-tab.is-box-tab {
        margin-top: (-36px) !important;
        margin-bottom: 60px; } }

#faq-007 {
    .fui-faq-collapse {
        padding: 0;
        background-color: $lightgray70; } }

main.OO7 {
    .ribbon-bg {
        background-image: url(#{$publicURL}/resources/cbu/prepaid/images/ribbon-pattern-1920.jpg);
        background-size: 100% auto;
        background-repeat-x: no-repeat;
        width: 100%;
        margin: 0; }

    .paper {
        padding: 60px 30px;

        .body {
            margin-bottom: 48px; }

        .fui-button {
            margin-bottom: 36px; }

        .fui-dropdown {
            margin-bottom: 36px; } }

    .search-country {
        padding-top: 40px;

        .fui-dropdown.is-button {
            width: 272px; } }

    .collapse-body {
        article {
            line-height: 1.5; } } }

@media screen and (max-width: 960px) {
    main.OO7 {
        .ribbon-bg {
            display: block; }

        .paper {
            padding: 30px 30px;

            p.body {
                margin-bottom: 30px; }

            .fui-button {
                margin-bottom: 6px; } }

        .fui-banner {
            min-height: 360px; }

        .MuiGrid-spacing-xs-6 > .MuiGrid-item {
            padding-top: 0 !important; }

        .search-country {
            padding-top: 28px; } } }

@media screen and (max-width: 480px) {
    main.OO7 {
        .search-country {
            .fui-dropdown.is-button {
                width: 100%; } } } }

section.resend-bill {
    .fui-dropdown.is-button {
        max-width: max-content;
        min-width: 300px; }

    .email-form {
        border: 2px solid $lightgray100;
        display: flex;
        margin-bottom: 60px;

        &--left {
            padding: 12px 24px;
            color: $darkgray50;
            font-weight: 500;
            flex: 0 0 285px;
            text-align: center; }

        &--right {
            padding: 12px 24px 12px 70px;
            background-color: $lightgray70;
            color: $darkgray50;
            width: 100%; } }

    @media screen and (max-width: 960px) {
        .email-form {
            margin-bottom: 30px;

            &--left {
                flex: 0 0 76px;
                font-size: 14px; }

            &--right {
                font-size: 14px;
                padding-left: 6px;
                padding-right: 6px; } } } }

section.why-prepaid {
    padding-top: 0;

    .head {
        font-size: 36px;
        font-weight: 500;
        margin-bottom: 60px; }

    ul {
        list-style-type: none;
        display: flex;
        flex-direction: row;
        padding: 0;
        justify-content: space-between;

        li {
            display: flex;
            flex-direction: row;
            width: 100%;

            img {
                max-width: 160px;
                width: 100%; }

            .info {
                ul {
                    display: flex;
                    flex-direction: column;

                    li {
                        font-size: 18px;
                        color: $darkgray50;
                        align-items: center;
                        margin-bottom: 10px;

                        i {
                            margin-right: 10px; } } } } } } }

section.apply-steps {
    background-position: right;

    // background-image: url("/resources/cbu/prepaid/images/cbu-prepaid-step-1920x470.jpg")
    background-size: cover;
    padding-bottom: 48px;

    h2 {
        color: #5f7b80;
        margin-bottom: 12px; }

    .subtitle {
        color: #8da0a4;
        margin-top: 0;
        font-weight: 500; }

    ul {
        list-style-type: none;
        padding: 0;
        margin-top: 36px;

        li {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 12px;

            .body {
                color: #5f7b80;
                font-weight: normal;
                margin: 0; }

            .circle {
                border: 2px solid #5f7b80;
                min-width: 36px;
                min-height: 36px;
                width: 36px;
                height: 36px;
                border-radius: 50%;
                margin-right: 12px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #5f7b80;
                font-size: 24px;
                font-weight: 500; } } } }

section.foreign-prepaid {
    margin-top: 100px; }

section.how-to-dial {
    h4 {
        font-weight: 500;
        margin-bottom: 24px; }

    ul {
        list-style-type: none;
        padding-left: 0;

        li {
            font-size: 16px;
            line-height: 1.9;

            &::before {
                content: "•";
                color: #5F7B80;
                font-weight: bold;
                font-size: 28px;
                line-height: 0px;
                margin-top: 0px;
                margin-right: 6px;
                vertical-align: sub; } } }

    .fui-panel {
        margin-bottom: 0; }

    .fui-panel-information {
        padding: 30px 60px;
        background: #fafafa;
        box-shadow: 0 6px 12px 0 rgba(75, 88, 105, 0.12); }

    .wrapper {
        display: flex;
        flex-direction: row;
        width: 100%;
        white-space: nowrap;

        .d-column {
            .d-row {
                display: flex;
                flex-direction: row;
                justify-content: space-around;
                white-space: nowrap; }

            .d-flex {
                flex-direction: column;

                &:nth-child(1) {
                    margin-right: 12px; } }

            &:nth-child(1) {
                margin-right: 12px; } }

        h4 {
            font-weight: normal;
            margin: 18px 0 12px 0; }

        .bg-gray {
            background-color: #eff1f2;
            padding: 24px 18px; }

        > div {
            width: 100%;

            span {
                font-size: 18px;
                font-weight: normal; } } }

    @media screen and (max-width: 1140px) {
        .wrapper {
            h2 {
                font-size: 28px; } } } }

main.notice {
    position: relative;

    .fui-panel {
        width: 100%; }

    .fui-breadcrumb-container {
        position: relative; }

    .sidebar {
        .box {
            background-image: url(#{$publicURL}/resources/cbu/e-service/images/result-bg.svg);
            padding: 30px 20px;
            border: 0;
            text-align: left;
            box-shadow: none;
            background-size: contain;
            background-position: right bottom;
            background-color: #EFF4F1;
            background-repeat: no-repeat; }

        .mainTitle {
            font-size: 24px;
            display: flex;
            flex-direction: column;
            padding: 0;
            font-weight: 500;

            &::before {
                content: "";
                background-image: url(#{$publicURL}/resources/cbu/e-service/images/calculator.svg);
                width: 30px;
                height: 30px;
                position: relative;
                margin-bottom: 12px;

                img {
                    margin-right: auto; } } }

        p {
            font-size: 18px;
            color: $black50;
            margin-bottom: 30px; }

        a {
            font-size: 16px;
            min-width: auto;
            padding: 12px;
            background: none;
            height: auto;
            border: 2px solid $black50;
            font-weight: 500;
            width: 100%;
            line-height: initial; } }

    .fui-panel-information {
        background-color: $white;
        box-shadow: 0 6px 12px 0 rgba(75, 88, 105, 0.12);
        margin-bottom: 12px;

        .collapse-header {
            flex-direction: column; }

        .descbox {
            background-color: #fafafa;
            padding: 30px;
            margin-top: 24px;

            .mainTitle {
                color: #5f6e84;
                font-size: 16px;
                font-weight: 500; }

            .unfold {
                margin-top: 6px; }

            ol {
                padding-left: 16px;

                li {
                    color: #5f6e84;
                    font-size: 14px;
                    line-height: 24px;
                    font-weight: normal; } }

            .mainTitle {
                padding-left: 14px; } } }

    .fui-pagination {
        li {
            &.selected {
                a {
                    color: $white !important; } }

            a {
                color: $black50 !important;
                font-weight: 500;

                &::after {
                    display: none !important; } } } }

    a.fui-button {
        i {
            font-size: 24px !important;
            font-weight: 400; } } }

@media screen and (max-width: 960px) {
    .fui-ebu.fui-banner {
        .fui-container {
            height: 100%; } }

    .fui-ebu.fui-banner .fui-tab-container .fui-tab-body {
        bottom: 30px;

        .tab-content {
            padding-bottom: 0;

            .tab-title {
                max-width: 350px; }

            .action {
                width: 100%;
                display: flex;
                max-width: 62vw;

                .is-secondary {
                    background-color: $white; } } } }

    .fui-banner .fui-tab-container .fui-tab-body .fui-tab-pane a.fui-button {
        max-width: initial;
        width: 100%;
        min-width: initial;

        &:nth-last-child(1) {
            margin-right: 0; } }

    .fui-cbu.fui-banner .fui-tab-container .fui-tab-body .fui-tab-pane a.fui-button {
        min-width: 160px;
        width: auto;
        display: inline-block; }

    main.prepaid {
        .ribbon-bg {
            display: block; }

        .fui-banner.is-product.is-style-2 {
            min-height: auto;
            height: 360px;

            .caption {
                top: 0; }

            p.subtitle {
                h6 {
                    margin: 0 !important; } } }

        .fui-tab-pane {
            section {
                padding: 0; }

            .fui-figure {
                max-width: 300px;
                margin: 0 auto; } }

        .program-internet-mb {
            display: block; }

        .program-internet {
            display: none; }

        .fui-panel {
            .fui-container {
                padding: 0; } }

        .fui-tab-pane.is-active {
            z-index: 19; }

        .fui-collapse.is-open {
            .collapse-header {
                margin-bottom: 30px; } }

        .paper {
            .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-2 {
                display: block; }

            .rate-plan-box {
                padding: 20px;
                margin-bottom: 12px;

                .d-flex {
                    flex-direction: column; } }

            & + .fui-button {
                margin-top: 8px; }

            & + h2 {
                margin-top: 60px; }

            img.w-100 {
                width: 100%; }

            h6.paper-h6 {
                margin-bottom: 0; }

            h6.is-text-darkgreen {
                margin-top: 18px; }

            a.fui-button {
                margin-top: 20px; } }

        ul.green-dot-list {
            margin: 18px 0 30px 0; }

        ul.step-list {
            margin-top: 18px;

            li {
                align-items: baseline;
                padding-bottom: 28px;
                overflow: hidden;
                margin-bottom: 0;

                .circle {
                    min-width: 40px;
                    min-height: 40px;
                    width: 40px;
                    height: 40px;
                    font-size: 24px;
                    border: 4px solid #fff;

                    &::before {
                        top: 36px;
                        left: 18px;
                        height: 100vh; }

                    &::after {
                        content: "";
                        border: 4px solid #ffffff;
                        width: 36px;
                        height: 36px;
                        border-radius: 50%;
                        z-index: 11;
                        display: inline-block;
                        position: absolute;
                        left: -4px;
                        top: -4px; } } } }

        .fui-horzonal-cards {
 }            // background-image: url("/resources/cbu/prepaid/images/cbu_ribbon_pattern_bkg@2x.jpg")

        section.fui-solution-tab {
            // background-image: url("/resources/cbu/prepaid/images/cbu_ribbon_pattern_bkg@2x.jpg")
            .tab-content {
                padding-bottom: 0;

                small {
                    color: $black50; }

                h1 {
                    max-width: 80%;
                    font-size: 28px;
                    line-height: 1.5;

                    &.add-value-title {
                        max-width: 100%; } } }

            .slick-slide .fui-card {
                .subtitle {
                    font-size: 16px; }

                img {
                    max-width: 168px; }

                .form-group {
                    .fui-button {
                        padding: 0;
                        margin: 0; }

                    &.two-buttons {
                        padding: 0 20px;

                        .fui-button {
                            margin-right: 12px;

                            &:nth-last-child(1) {
                                margin-right: 0; } } } } } }

        .fui-panel {
            padding: 30px 20px !important; }

        .fui-panel-tab {
            // margin: 18px 0
            margin: 0; }

        .fui-faq-collapse {
            padding: 0; }

        .fui-horzonal-cards.is-narrow {
            .slick-arrow {
                display: none !important; } }

        section.why-prepaid {
            // background-image: url("/resources/cbu/prepaid/images/cbu_ribbon_pattern_bkg@2x.jpg")
            background-size: cover;

            .head {
                font-size: 28px; }

            ul {
                flex-direction: column;

                li {
                    justify-content: center;

                    img {
                        max-width: 130px;
                        margin-right: 28px; } }

                .info {
                    h4 {
                        margin-bottom: 18px; } } } }

        section.apply-steps {
            background-color: #eff1f2;

            // background-image: url("/resources/cbu/prepaid/images/cbu-prepaid-step-mobile.jpg")
            background-size: contain;
            padding-bottom: 72px;
            background-position: bottom;
            background-repeat: no-repeat;

            .subtitle {
                font-size: 16px; }

            ul {
                margin-bottom: 0;
                margin-top: 8px;

                li {
                    font-size: 16px;
                    line-height: 1.75;

                    .circle {
                        font-size: 20px; }

                    .body {
                        font-size: 16px; } } } }

        section.how-to-dial {
            .wrapper {
                flex-direction: column;

                .d-column {
                    .d-row {
                        flex-direction: column; } } } }

        .fui-panel-information {
            padding: 30px 20px; }

        .airport-info {
            flex-direction: column;
            margin-top: 30px;

            img {
                margin-right: 0;
                max-width: 100%; } } }

    .ribbon-bg {
        background-image: url(#{$publicURL}/resources/cbu/prepaid/images/cbu_ribbon_pattern_bkg@2x.jpg); }

    main.notice {
        .fui-panel {
            flex-direction: column; }

        .sidebar {
            .box {
                a {
                    max-width: 190px; } } } } }

@media screen and (max-width: 480px) {
    .fui-ebu.fui-banner .fui-tab-container .fui-tab-body .tab-content .action {
        max-width: 100%; } }

.is-en {
    * {
        font-family: ‘SF Pro’, ‘Segoe UI’, Helvetica Neue, Helvetica, Arial, sans-serif; }

    .fui-horzonal-cards, .fui-corp-content {
        padding: 40px 0; }

    @media screen and (max-width: 960px) {
        .service-entry {
            .fui-container {
                .fui-card {
                    .fui-card-action {
                        height: auto; } } } }

        .fui-banner .fui-tab-container .fui-tab-header .fui-tab .fui-tab-item {
            width: 33.333%; }

        .tab-content {
            .action {
                width: 100%;
                display: flex; } }

        .fui-banner .fui-tab-container .fui-tab-body {
            bottom: 30px;

            .fui-tab-pane {
                a.fui-button {
                    max-width: initial;
                    width: 100%;
                    min-width: initial !important;

                    &:nth-last-child(1) {
                        margin-right: 0; } } } } }

    @media screen and (max-width: 768px) {
        .fui-horzonal-cards {
            padding: 60px 0 0 0;

            .fui-corp-content {
                padding: 40px 0 0; }

            .service-entry {
                .fui-container {
                    .fui-card {
                        .fui-card-action {
                            height: 76px;

                            .fui-card-content {
                                padding: 12px;
                                display: flex;
                                align-items: center;
                                justify-content: center;

                                h4.fui-card-title {
                                    line-height: 26px; } } } } } } } }

    @media screen and (max-width: 400px) {
        .fui-banner .fui-tab-container .fui-tab-body {
            width: 100%;

            .fui-tab-pane {
                a.fui-button {
                    padding: 0 20px; }

                h3.tab-title {
                    max-width: 340px;
                    font-size: 30px;
                    line-height: 36px; } } } }

    @media screen and (max-width: 599px) {
        .fui-banner .fui-banner-bg .main-image {
            width: 100%;
            height: auto;
            top: -40px; }

        .fui-banner .fui-tab-container .fui-tab-body {
            .fui-tab-pane {
                a.fui-button {
                    padding: 0 20px; }

                h3.tab-title {
                    max-width: 340px;
                    font-size: 30px;
                    line-height: 36px; } } } } }

@media screen and (max-width: 767px) {
    section.service-tags {
        .service-tag-container {
            .fui-button.is-tag {
                padding-left: $space * 3;
                padding-right: $space * 3;
                height: 30px;
                line-height: 26px;
                min-width: 0;
                margin-right: $space * 1.5;
                margin-bottom: $space * 1.5;
                font-size: 0.875rem; } } } }

@media screen and (min-width: 768px) {
    section.fui-content {
        padding-top: 60px;
        padding-bottom: 90px;

        img {
            display: block;
            margin: 30px auto; } }

    .fui-container section.fui-section-promo {
        margin-left: 0;
        margin-right: 0;

        &:first-child {
            margin-top: 60px; } }

    section {
        padding: ($space * 5) 0; }

    section.service-tags {
        z-index: 1;
        background-size: cover;

        .service-tag-container .service-scroller {
            width: 100%;
            text-align: center;

            .fui-button {
                line-height: 36px; } } } }

@media screen and (max-width: 768px) and (min-width: 600px) {
    section.fui-content-image {
        padding-bottom: 170px; } }

@media screen and (min-width: 769px) {
    section.fui-content-image {
        > img {
            position: absolute;
            width: auto;
            height: 100%; }

        &.is-image-left > img {
            left: 0; }

        &.is-image-right > img {
            right: 0; } } }

@media only screen and (device-width: 812px) and (device-height: 375px) and (orientation: landscape) {
    section.fui-content-image {
        padding-bottom: 190px;

        > img {
            height: 281px; } } }

@media screen and (min-width: 960px) {
    section.fui-nav-tab {
        position: relative; }

    section.fui-horzonal-cards {
        .section-title {
            max-width: 100%; }

        .section-bg img {
            height: 312px; }

        .fui-card .fui-card-action {
            .fui-card-content {
                min-height: 240px; }

            > .fui-card-image + .fui-card-caption > .fui-card-content {
                min-height: 0; } }

        .bg {
            top: auto;
            bottom: 0; } }

    section.fui-content-image {
        display: block;
        padding-bottom: 60px; }

    section.fui-corp-content {
        .fui-marquee-box .marquee-landscape-item .marquee-landscape-txt {
            padding: 0;
            margin: 0 45px;

            img {
                width: auto;
                max-height: 100px; } }

        &.is-style-2 {
            .fui-marquee-box .marquee-landscape-item .marquee-landscape-txt {
                padding: 0;
                margin: 0 15px;

                img {
                    width: 160px;
                    height: auto;
                    max-height: none;
                    opacity: 0.7; } } } }

    section.fui-content {
        ~ section.fui-content.bg-arrow,
        &.bg-arrow {
            background-size: auto 300px;
            padding: 60px 0; } }

    section {
        padding: 60px 0 90px;

        .section-title,
        .fui-section-header {
            font-size: 36px;
            margin-bottom: 36px; }

        .section-title + .fui-cards,
        .fui-section-header + .fui-cards {
            margin-top: -$space; } }

    section.service-tag,
    section.product-map {
        padding: 60px 0; }

    section.product-map {
        .fui-collapse {
            .collapse-body {
                height: 256px;
                overflow: hidden; }

            .collapse-trigger {
                display: block;
                margin-top: $space * 2; }

            .product-map-list {
                flex-direction: row;
                flex-flow: flow;

                .produc-map-item {
                    flex: 1;
                    flex-grow: 1;
                    max-width: 25%;

                    .fui-item {
                        .cotent {
                            display: block;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis; } } } } } }

    section.fui-section-promo {
        min-height: 156px;
        padding: 40px 15px;

        &[class*="bg-pattern"] {
            background-size: auto 100%; }

        &.bg-pattern-gray {
            background-image: url(#{$publicURL}/resources/common/images/patternd-darkgray-banner.png); }

        &.bg-pattern-green {
            background-image: url(#{$publicURL}/resources/common/images/cbu-pattern-banner-1920x156.jpg); }

        &.bg-pattern-blue {
            background-image: url(#{$publicURL}/resources/common/images/patternd-blue-banner.jpg); }

        &.with-pattern {
            &::after {
                content: "";
                width: 50%;
                background-image: url(#{$publicURL}/resources/common/images/patternd-darkgray-banner.png);
                background-size: auto 100%; }

            .MuiGrid-root.MuiGrid-item.action {
                display: flex; } }

        .MuiGrid-root.MuiGrid-item {
            align-items: center;
            align-content: center; }

        .MuiGrid-root.MuiGrid-item.action .fui-button {
            margin-top: 0; }

        &.is-edm {
            padding-top: 42px;
            padding-bottom: 42px;
            background-size: cover;

            .fui-section-promo-title {
                font-size: 20px;
                margin-bottom: 30px; } }

        .MuiGrid-root.MuiGrid-item.action {
            justify-content: flex-end;
            align-content: center;
            align-items: center;

            .fui-button {
                min-width: 190px; } } } }

section.form-status {
    img {
        width: 232px; }

    .fui-success-title {
        margin-top: $space * 3;
        margin-bottom: 0; }

    .fui-success-description {
        margin-top: $space * 1.5;
        margin-bottom: $space * 3; }

    @media screen and (min-width: 960px) {
        padding-top: 90px; } }

.fui-footer-promo {
    position: relative;
    background-color: $black50;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    color: white;
    z-index: 2;
    height: 270px;

    // min-height: 188px
    padding: 0;
    overflow: hidden;
    display: flex;

    .promotion-container {
        display: flex;
        flex: 1 auto;
        flex-direction: column;
        width: 90%;
        max-width: 1200px;
        margin: 0 auto;
        text-align: center; }

    h4.fui-section-promo-title {
        margin: 0;
        font-size: 20px;

        + p {
            margin: 6px 0 0; } }

    .promotion-container > * {
        position: relative;
        display: flex;
        align-items: center;
        align-content: center;
        width: 100%;
        padding: 0;

        h4.fui-section-promo-title {
            margin: 0;

            + p {
                margin: 6px 0 0; } }

        .promotion-button {
            border: 2px solid $white;
            color: $white;
            margin: $space 0;
            padding: 0 $space;
            line-height: 36px;
            width: 100%;
            height: 40px;
            text-align: center;
            min-width: 192px; } }

    > a:link,
    > a:visited {
        display: flex;
        color: $white;
        width: 100%;
        padding: 20px 30px;
        min-height: 156px;
        cursor: pointer;
        z-index: 2;

        &:hover {
            color: $white;
            opacity: 1 !important; } }

    @media screen and (min-width: 768px) {
        height: 156px;

        .promotion-container {
            flex-direction: row;

            > * {
                width: 50%;
                padding: 0 ($space * 2); }

            h4.fui-section-promo-title {
                font-size: 24px;
                text-align: left; }

            > .action {
                display: flex;
                align-content: center;
                align-items: center;
                justify-content: flex-end;

                .promotion-button {
                    width: auto;
                    min-width: 190px; } } } } }
