@keyframes slideUp {
    0% {
        height: calc(100vh - 100px); }

    100% {
        height: 0px; } }

.promotion-open {
    overflow: hidden;

    header.fui-header {
        z-index: 40; }

    &.modal-open {
        .fui-splash-promotion {
            z-index: 99;
            overflow: visible;

            .fui-modal iframe {
                height: 100%; } } } }

.fui-splash-promotion {
    position: fixed;
    width: 100%;
    height: calc(100vh - 100px);
    padding: 0;
    margin: 0;
    z-index: 39;
    background: $white;
    overflow: hidden;

    // transition: height 0.3s ease

    &.is-collapse {
        height: calc(100vh - 100px);
        animation: slideUp 0.5s ease forwards;

        .caption {
            position: absolute !important; } }

    &.is-close {
        height: 0 !important;

        .caption {
            position: absolute !important; } }

    .video,
    .image {
        position: absolute;
        width: 100%;
        height: calc(100% - 120px);
        background-size: cover;
        background-position: center;

        img {
            position: absolute;
            width: auto;
            min-height: 344px;
            height: 100%;
            left: -100%;
            right: -100%;
            top: -100%;
            bottom: -100%;
            margin: auto; } }

    .video {
        &::before,
        &::after {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            z-index: 2; }

        &::before {
            width: 60px;
            height: 60px;
            background: $white;
            opacity: 0.84;
            border-radius: 30px;
            box-shadow: 0 11px 48px 0 rgba(32, 32, 32, 0.3); }

        &::after {
            left: 20px;
            width: 0;
            height: 0;
            border-left: 16px solid $main;
            border-top: 12px solid transparent;
            border-bottom: 12px solid transparent;
            border-right: 16px solid transparent; } }

    .caption {
        position: absolute;
        display: flex;
        align-content: center;
        justify-content: center;
        width: 100%;
        height: 120px;
        bottom: 0;
        left: 0;
        background: $white;
        padding: ($space * 3) 0;
        box-shadow: 0 6px 12px 0 rgba(75, 88, 105, 0.12), 0 -2px 0 0 rgba(75, 88, 105, 0.06);

        .fui-container {
            width: 100%;
            max-width: 1440px;
            display: flex;
            align-content: center;
            align-items: center;

            .action {
                display: flex;
                justify-content: center;
                align-content: center;
                align-items: center;

                .fui-button {
                    margin: 0 6px 0; } }

            .content {
                flex: 1; } } }

    @media screen and (max-width: 1024px) and (orientation: portrait) {
        height: calc(100vh - 50px);

        .video,
        .image {
            top: 174px;
            height: calc(100% - 174px);

            img {
                width: auto;
                height: 100%;
                margin: auto; } }

        .caption {
            position: fixed;
            top: 100px;
            bottom: auto;
            height: 174px;
            padding: ($space * 2) 15px;

            .fui-container {
                flex-direction: column;

                .subtitle {
                    display: none; }

                .action {
                    width: 100%;
                    margin: ($space * 2) -6px 0; } } } }

    @media screen and (max-width: 960px) {
        height: calc(100vh - 50px);

        .video,
        .image {
            top: 174px;
            height: calc(100% - 174px);

            img {
                width: auto;
                height: 100%;
                margin: auto; } }

        .caption {
            position: fixed;
            top: 50px;
            bottom: auto;
            height: 174px;
            padding: ($space * 2) 15px;

            .fui-container {
                flex-direction: column;

                .subtitle {
                    display: none; }

                .action {
                    width: 100%;
                    margin: ($space * 2) -6px 0; } } } } }
