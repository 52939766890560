.extra-link-card {
    background-color: #fff;
    box-shadow: 0 24px 48px 0 #{'rgba(75, 88, 105, 0.12)'};
    height: 100%;
    .main-body {
        height: 100%;
        display: flex; }

    .img-wrapper {
        height: 100%;
        position: relative;

        .periotic-pattern {
            position: absolute;
            left: 0;
            bottom: 0;
            width: initial; }

        .periodic-content {
            position: absolute;
            left: 0;
            bottom: 0;

            h1 {
                color: #9EDFE9;
                display: inline-block;
                font-size: 36px;
                margin: 0 0 -8px 16px; }

            .period {
                color: #9EDFE9;
                display: inline-block;
                font-size: 12px;
                margin: 0; }

            .date {
                color: #fff;
                font-size: 12px;
                line-height: 12px;
                margin: 0 0 16px 16px; } }

        .main-bg {
            display: flex;
            width: 100%;
            height: 100%;
            background-size: cover;
            background-position: center center; } }

    h4 {
        margin: 0 0 18px 0; }

    .card-bottom {
        display: flex;

        a {
            width: 80px; }

        a.is-arrow {
            span {
                color: $accent; } } }

    .card-wrapper {
        height: 100%;
        background: #fff;

        .card-body {
            padding: 18px 18px 20px 18px;
            border-bottom: 2px solid #EBEBEB;
 } }            // height: calc( 100% - 106px )

    .social {
        display: flex;
        align-items: center;
        font-size: 14px;
        width: 100%;
        justify-content: flex-end;

        .view {
            color: $gray100;
            display: flex;
            align-items: center; }

        i {
            font-size: 30px;
            margin-left: 6px;
            cursor: pointer; } }

    .card-list {
        list-style-type: none;
        padding: 0;
        margin: 0;

        li {
            padding: 15px;
            background-color: #fff;
            border-bottom: 2px solid #EBEBEB;
            display: flex;
            align-items: center;
            cursor: pointer;

            a:link,
            a:visited, {
                color: $black50;
                &::hover,
                &::focus {
                    color: $black50; }
                &::after {
                    display: none !important; } }

            i {
                font-size: 24px;
                display: inline-block; }

            p {
                margin: 0;
                width: 100%;
                display: inline-block; } }

        li:nth-last-child(1) {
            border-bottom: 0; } } }

// 直列
.extra-link-card.flex-column {
    flex-direction: column;
    display: flex;

    .vertical {
        display: none; }

    .card-body {
        h4 {
            @include ellipsis(2);
            min-height: 72px;
            height: 72px; } }
    .card-list {
        a {
            @include ellipsis(1);
            height: 22px; } } }

// 橫列
.extra-link-card.flex-row {
    flex-direction: row;
    display: flex;

    .horizontal {
        display: none; }

    .card-body {
        height: initial;
        h4 {
            @include ellipsis(2);
            min-height: 72px;
            height: 72px; } }

    .img-wrapper {
        width: 50%; }

    .card-wrapper {
        width: 50%;
        background-color: #fff; }

    .card-list {
        a {
            @include ellipsis(1);
            &::after {
                display: none; } } } }

@media screen and (max-width: 960px) {
    .extra-link-card.flex-row {

        .card-wrapper {
            width: 100%; } }

    .extra-link-card.flex-column {
        height: auto;
        .card-body {
            height: auto; }
        .img-wrapper {
            height: 310px; } } }

@media screen and (max-width: 640px) {
    .extra-link-card {
        .img-wrapper {
            height: 194px; } }
    .extra-link-card.flex-row {
        flex-direction: column !important;
        .img-wrapper {
            width: 100%; }

        .vertical {
            display: none; }

        .horizontal {
            display: block; } }

    .extra-link-card.flex-column {
        .img-wrapper {
            height: 194px; }
        h4 {
            margin: 0; } } }
