.is-e-service-section {
    position: relative;
    padding: 60px 0;
    height: auto;
    min-height: 0;

    img.arrow,
    img.bg {
        position: absolute;
        z-index: 1; }

    img.arrow {
        left: 0;
        top: 0;
        height: 70px; }

    img.bg {
        width: 100%;
        height: auto;
        right: 0;
        top: auto;
        bottom: 0;
        z-index: 0; }

    .fui-container {
        position: relative;
        height: auto;
        padding: 0 15px;
        z-index: 2; }

    .fui-card {
        background: $white; }

    @media screen and (min-width: 961px) {
        padding: 115px 0 95px;

        img.bg {
            width: 100%;
            height: auto;
            top: 0;
            bottom: auto; }

        img.arrow {
            top: 30px; } }

    @media screen and (max-width: 1024px) {
        img.bg {
            height: 100%;
            width: auto; } }

    @media screen and (min-width: 1280px) {
        img.arrow {
            top: 99px;
            height: 144px; } } }

@media screen and (min-width: 960px) {
    .is-eservice,
    .is-cbu {
        .fui-collapse-menu {
            .group-name {
                background: url(#{$publicURL}/resources/cbu/discount/collapse-menu-bg@2x.png) no-repeat;
                background-size: cover; } } } }

.fui-nav-anchor + .fui-banner.is-form {
    margin-top: 0; }

#root {
    overflow: hidden; }

.page-body {
    z-index: 10; }

.fui-faq-collapse {
    a, a:link {
        color: $accent; } }

.form-group label {
    display: flex;

    .fui-tooltip-container {
        display: inline-flex; }

    @media screen and (max-width: 960px) {
        font-size: 18px; } }

.fui-image-content-layout {
    position: relative;
    background: url(#{$publicURL}/resources/cbu/e-service/images/bg-ribbon.png) repeat-y top left;
    background-size: 100% auto;

    .bg {
        position: absolute;
        left: -350px;
        top: 0; }

    section.fui-image-content {
        padding-top: 30px;
        padding-bottom: 0;

        + section.fui-image-content {
            padding-top: 0; }

        h3 {
            margin-top: 0px; }

        img {
            width: calc(100% + 30px);
            margin-left: -15px;
            margin-right: -15px; } }

    @media screen and (min-width: 600px) and (max-width: 1200px) {
        padding: 0 ($space * 2); }

    @media screen and (min-width: 768px) {
        section.fui-image-content {
            padding-top: 60px; } }

    @media screen and (min-width: 960px) {
        section.fui-image-content {
            h3 {
                margin-top: 80px; }

            + section.fui-image-content {
                padding-top: 60px; } } } }

section.fui-section-board {
    background: $ice; }

.car-information-body .radio-group {
    display: flex;

    .radio {
        display: flex;

        .content {
            width: 100%; }

        img {
            width: 64px;
            height: 64px; }

        @media screen and (min-width: 600px) {
            width: calc(25% - 12px) !important; } } }

.car-information-list .car-information-item {
    border: 2px solid $lightgray100;
    margin-bottom: $space * 1.5;

    .car-information-header {
        padding: ($space * 1.5) 30px;
        background: $lightgray70;
        border-bottom: 2px solid $lightgray100;
        display: flex;
        align-content: center;
        align-items: center;

        > .content {
            font-weight: $font-medium;
            color: $darkgray50;
            font-size: 24px;
            flex: 1;
            flex-grow: 1; }

        .action {
            display: flex;
            align-items: center;
            align-content: center;

            .checkbox {
                margin: 0;

                .content {
                    padding-top: 0;
                    color: $black50;
                    line-height: 30px;

                    &::before {
                        border-color: transparentize($business50, 0.8);
                        margin-bottom: 0; } }

                .error-message {
                    display: none; } } } }

    .car-information-body {
        padding: 15px;
        margin-bottom: 0;

        .form-group {
            margin-bottom: 0;

            > div {
                display: flex;
                align-items: center;
                align-content: center;
                justify-items: center;
                justify-content: center;

                > .fui-select,
                > input {
                    flex: 1;
                    flex-grow: 1; }

                > span {
                    padding: 0 ($space / 2); } } }

        .input-group {
            display: flex;

            input {
                width: auto;
                min-width: 0; } }

        .fui-button {
            min-width: 0;
            width: 100%; } }

    @media screen and (min-width: 768px) {
        .car-information-body {
            padding: 30px; } }

    @media screen and (max-width: 640px) {
        .page-action {
            flex-direction: column; } } }

.car-information-action {
    .fui-button[role="button"] {
        width: 100%;
        color: $black50;
        border: 2px solid $lightgray100;

        &::after {
            display: none; } } }

.fui-banner.is-parkgin-fee {
    position: relative;
    padding: 0;
    z-index: 0;
    min-height: 360px;

    > img {
        width: 100%; }

    .caption {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        padding: $space * 6;
        color: $white;

        .fui-container {
            width: 90%; }

        h1 {
            margin-bottom: $space * 3; }

        .fui-list {
            margin-bottom: $space * 5; }

        .fui-item {
            padding-top: $space / 2;
            padding-bottom: $space / 2;

            img {
                width: 24px;
                height: 24px;
                margin-right: $space; }

            .content {
                color: $white; } }

        img {
            vertical-align: middle;
            margin-right: $space * 1.5; }

        div.subtitle {
            margin-bottom: $space * 3; }

        .fui-container {
            padding: 0 ($space * 2); } }

    @media screen and (min-width: 600px) and (max-width: 1024px) {
        > img {
            position: absolute;
            height: 100%;
            width: auto;
            left: -45%;
            right: 0;
            bottom: 0;
            margin: auto; } }

    @media screen and (min-width: 768px) {
        min-height: 460px;
        height: 460px;

        .caption {
            padding: 90px;
            height: 460px;

            .fui-item {
                img {
                    width: 32px;
                    height: 32px; } } } }

    @media screen and (min-width: 960px) and (max-width: 1024px) {
        > img {
            left: -20%; } }

    @media screen and (min-width: 1025px) {
        > img {
            left: 0;
            height: 100%;
            width: auto; } }

    @media screen and (min-width: 1920px) {
        > img {
            width: 100%;
            height: auto; } } }

.parking-area-selector {
    .checkbox-group {
        margin-right: 0;
        .checkbox.is-icon-box {
            width: 100%;
            margin: 0 0 12px;

            input:disabled + .content::before {
                display: none; }

            input:checked + .content > .extra .fui-label {
                background: $error; }

            .content {
                display: flex;
                min-height: $space * 11;
                padding: ($space * 1.5) ($space * 1.5) ($space * 1.5) ($space * 6);
                text-align: left;
                align-items: flex-start;
                align-content: flex-start;

                &::after {
                    top: 20px;
                    bottom: auto; }

                &::before {
                    top: 12px;
                    bottom: auto; }

                .main {
                    flex: 1 auto;
                    flex-grow: 1 auto; }

                .extra {
                    align-self: center; }

                .text {
                    text-align: left;
                    color: $black50; }

                .description {
                    color: $gray100;
                    font-size: 0.875rem; }

                .fui-label {
                    font-size: 0.875rem;
                    background: #95a3a4;
                    border-radius: 30px;
                    padding: 0 ($space * 1.5);

                    .text {
                        white-space: nowrap;
                        line-height: 30px;
                        color: $white; } } } } }

    @media screen and (min-width: 600px) {
        .checkbox-group {
            margin-right: -12px;
            .checkbox.is-icon-box {
                width: calc(50% - 12px);
                margin: 0 12px 12px 0 !important;
                &:nth-of-type(2n) {
                    margin: 0 0 12px 0; } } } }

    @media screen and (min-width: 960px) {
        .checkbox-group {
            .checkbox.is-icon-box {
                width: calc(33.3333% - 12px);
                &:nth-of-type(3n) {
                    margin: 0 0 12px 0; } } } } }

.e-service-result {
    text-align: center;
    padding-top: $space * 3;

    &.is-success {
        .content {
            .body1 {
                margin: 0 auto;
                max-width: 600px;
                padding: 0 20px; } } }

    table {
        tr {
            > th {
                background: $lightgray100; }

            > td {
                background: $white; } } }

    img {
        width: 186px;
        margin: 0 auto;
        line-height: 0; }

    .action {
        margin-top: $space * 3;

        > .fui-button {
            width: 100%;
            display: block;
            margin: 0 auto $space; } }

    @media screen and (min-width: 600px) {
        .action > .fui-button {
            min-width: 202px;
            width: auto;
            display: inline-block;
            margin: 0 $space $space 0; } }

    @media screen and (min-width: 960px) {
        text-align: left;
        min-height: 640px;
        padding: 60px;

        p.subtitle {
            margin-top: $space * 1.5; }

        h2 {
            margin-top: 60px; }

        .body1 {
            margin-top: $space * 1.5; }

        img {
            width: 540px;
            float: right;
            margin-top: -60px;
            margin-left: 40px; }

        .action {
            margin-top: $space * 9; }

        &.is-success {
            padding: 60px;
            text-align: center;

            h2 {
                margin-top: $space * 3; }

            img {
                float: none;
                width: 220px;
                margin: 0; }

            .action {
                margin-top: $space * 3; } } } }

.e-service-result {
    text-align: center;
    padding-top: $space * 3;

    &.is-success {
        .content {
            .body1 {
                margin: 0 auto;
                max-width: 600px;
                padding: 0 20px; } } }

    img {
        width: 186px;
        margin: 0 auto;
        line-height: 0; }

    .action {
        margin-top: $space * 3;

        > .fui-button {
            width: 100%;
            display: block;
            margin: 0 auto $space; } }

    @media screen and (min-width: 600px) {
        .action > .fui-button {
            min-width: 202px;
            width: auto;
            display: inline-block;
            margin: 0 $space $space 0; } }

    @media screen and (min-width: 960px) {
        text-align: left;
        min-height: 640px;
        padding: 60px;

        p.subtitle {
            margin-top: $space * 1.5; }

        h2 {
            margin-top: 60px; }

        .body1 {
            margin-top: $space * 1.5; }

        img {
            width: 540px;
            float: right;
            margin-top: -60px;
            margin-left: 40px; }

        .action {
            margin-top: $space * 9; }

        &.is-success {
            padding: 60px;
            text-align: center;

            h2 {
                margin-top: $space * 3; }

            img {
                float: none;
                width: 220px;
                margin: 0; }

            .action {
                margin-top: $space * 3; } } } }

.page-header {
    padding: 30px 0 0; }

.page-body {
    padding: ($space * 6) 0;

    a:link,
    a:visited {
        color: $accent; }

    @media screen and (min-width: 960px) {
        padding: 60px 0; } }

.page-action {
    display: flex;
    flex-direction: column;
    margin-top: $space * 4;

    &.action-reverse {
        flex-direction: column-reverse; }

    .fui-button {
        width: 100%; }

    @media screen and (min-width: 640px) {
        flex-direction: row;

        &.action-reverse {
            flex-direction: row-reverse;
            justify-content: flex-end; }

        .fui-button {
            width: auto; } } }

.form-section {
    z-index: 9;

    .fui-list .fui-item,
    .fui-list a.fui-item,
    .fui-list a.fui-item:link,
    .fui-list a.fui-item:visited {
        .prefix {
            color: $darkgray50;

            i.number {
                background: $ice; }

            .bulleted-dot {
                background-color: $darkgray50; } }

        .content {
            font-weight: $font-medium; } } }

.paper {
    background: $white;
    padding: 30px ($space * 2.5);
    margin-bottom: $space * 1.5;
    box-shadow: 0 6px 12px 0 rgba(75, 88, 105, 0.12);
    position: relative;
    z-index: 1;

    ~ .page-action {
        position: relative;
        z-index: 0; }

    &.merged {
        margin-bottom: 0;
        border-bottom: 1px solid $lightgray100; }

    a, a:link, a:visited {
        color: $accent; }

    &:last-child {
        margin-bottom: $space * 7.5; }

    &:last-child.fui-collapse {
        margin-bottom: 0; }

    .payment-price {
        font-size: 28px;
        color: $error; }

    .barcode-img {
        width: 100%;
        max-width: 300px;
        margin: auto;
        display: block; }

    .rate-plan-box {
        background: $lightgray70;
        padding: 18px;

        h1, h2 {
            margin: 0; }

        h4, h5 {
            margin-top: 0;
            margin-bottom: 0;
            color: $darkgray50; } }

    &.merged {
        margin-bottom: 0;
        border-bottom: solid 1px $lightgray100; }

    @media screen and (max-width: 768px) {
        .fui-table-response {
            padding-right: 20px;
            margin-right: -20px; } }

    @media screen and (min-width: 960px) {
        padding: 30px;

        .rate-plan-box {
            padding: 24px 18px; } } }

.receipt-header {
    display: flex;
    flex-direction: column;
    margin-bottom: $space * 3;

    button.is-small {
        padding: 0 10px;

        span {
            font-size: 14px; } }

    .main {
        flex: 1;
        flex-grow: 1;

        .fui-button {
            margin: 0; } }

    .extra {
        margin-top: -10px;

        .fui-dropdown {
            width: 100%;
            margin-bottom: 10px; } }

    @media screen and (min-width: 768px) {
        flex-direction: row;

        .extra {
            display: flex;
            margin-left: $space * 1.5;

            .fui-dropdown {
                flex: 1;
                flex-grow: 1;

                + .fui-dropdown {
                    flex: none;
                    width: 210px;
                    max-width: none;
                    margin-left: $space;

                    span {
                        white-space: nowrap; } } } } } }

.receipt-list {
    .receipt-collapse {
        margin-bottom: $space * 1.2;
        border: 1px solid $lightgray100;
        padding: 30px 18px;

        .is-underline:hover {
            text-decoration: underline; }

        .fui-item {
            .content {
                font-weight: $font-medium; } }

        h4.date {
            color: $darkgray50;
            margin: 0; }

        h4, h5 {
            margin: 0; }

        .meta {
            margin-top: $space * 1.5;
            margin-bottom: $space * 1.5;

            span {
                &::after {
                    content: ""; }

                &:last-child::after {
                    display: none; } } }

        .extra {
            display: flex;
            flex-direction: column;

            div[role='button'] {
                font-weight: $font-medium; }

            .main {
                flex: 1;
                flex-grow: 1; }

            .extra {
                text-align: right; } } }

    .receipt-item {
        margin-bottom: $space * 1.2;
        border: 1px solid $lightgray100;
        padding: 30px 18px;

        h4 {
            margin: 0;
            color: $darkgray50; }

        .main {
            display: flex;
            flex-direction: column;

            h5,
            h6 {
                margin: 6px 0 0;
                flex: 1;
                flex-grow: 1; } } }

    .price {
        font-size: 0.875rem;
        text-align: right;
        margin-top: 12px;

        .amount {
            color: $accent;
            font-size: 24px;
            font-weight: $font-bold;
            margin-left: $space; } }

    @media screen and (min-width: 768px) {
        .receipt-collapse {
            padding: 30px;

            .extra {
                margin-top: $space * 3;
                flex-direction: row; }

            .meta {
                span {
                    &::after {
                        content: " | "; } } } }

        .price .amount {
            font-size: 28px; }

        .receipt-item {
            padding: 30px;

            .main {
                flex-direction: row;

                .price {
                    margin-top: 0px; } } } } }

.is-eservice {
    .fui-collapse.is-collapse-checkbox {
        .collapse-body {
            max-height: 168px;
            overflow-y: auto; } }

    .fui-collapse {
        &.collapse-table {
            .collapse-header {
                padding: 0;

                &::after {
                    right: 0; }

                &::before {
                    right: 0; } }

            .collapse-body {
                padding: 0; } }

        &.is-open {
            .collapse-header {
                &::after {
                    right: 0; } }

            .collapse-body {
                // margin-bottom: 30px
                margin-top: 16px;

                article {
                    margin-top: 0; } } }

        .paper {
            margin-bottom: $space * 1.5; }

        .collapse-header {
            font-weight: $font-medium; }

        .collapse-body {
            margin-bottom: 0;

            .subtitle {
                padding-top: $space * 4; } } }

    .form-group .fui-collapse.is-collapse-checkbox {
        margin-top: 0; }

    &.is-help-center {
        section.coverage-section {
            padding-top: 120px;
            background: url(#{$publicURL}/resources/cbu/e-service/images/help-center.png) left top no-repeat;
            background-size: 150px 200px;

            .fui-table-response.coverage-list {
                margin-top: 30px;
                padding: 0 15px;

                > div {
                    display: inline-block;
                    margin-right: 15px; }

                table {
                    tr th {
                        white-space: normal;
                        background: $lightgray70;
                        border: 2px solid #E8ECF0; }

                    tbody tr th {
                        width: 77px; }

                    tr td {
                        white-space: nowrap;
                        background: $white;
                        border: 2px solid #E8ECF0; } }

                div[class*="level-"] {
                    height: 15px;
                    width: 90px;
                    margin: 0 auto; }

                .level-1 {
                    background: #0eb5be !important; }

                .level-2 {
                    background: #fd7927 !important; }

                .level-3 {
                    background: #00ff00 !important; }

                .level-4 {
                    background: #0000ff !important; }

                .level-5 {
                    background: #ffff00 !important; }

                .level-6 {
                    background: #ff0000 !important; }

                .level-7 {
                    background: #aaaaaa !important; } }

            .search-bar {
                background: #eff1f2;
                padding: 15px;
                margin: 0 -15px;
                height: auto;

                .fui-select {
                    .fui-dropdown.is-button button {
                        background: none;
                        border: 0; } }

                form {
                    position: relative; }

                .form-group {
                    margin-bottom: 0; }

                .fui-button {
                    min-width: 0;
                    width: 100%;
                    margin: 0; } } }

        .map-contianer {
            margin: 0 -15px;
            display: block;
            height: 740px;
            background: $lightgray50; } }

    @media screen and (max-width: 768px) {
        .fui-collapse {
            &.is-open {
                .collapse-body {
                    margin-bottom: 0; }

                .collapse-header {
                    margin-bottom: 0; } } }

        .fui-collapse.is-collapse-checkbox {
            .collapse-body {
                max-height: 240px;
                margin: 0 15px;

                article {
                    padding: 20px 12px; } } }

        &.is-help-center {
            .map-contianer {
                height: 320px; }

            section.coverage-section {
                padding-top: 30px;
                background: url(#{$publicURL}/resources/cbu/e-service/images/help-center-sm.png) no-repeat;
                background-size: auto 98px;
                background-position: top right;

                .fui-table-response.coverage-list {
                    margin-left: -15px;
                    margin-right: -15px;

                    div[class*="level-"] {
                        width: 48px;
                        height: 9px;
                        margin: 0 auto; }

                    table {
                        tr th {
                            min-width: 77px;
                            background: $lightgray70;
                            border: 2px solid #E8ECF0; }

                        tr td {
                            width: 97px; }

                        tr th,
                        tbody tr td {
                            font-size: 12px;
                            padding: 14px 4px; } } } } }

        // .compareTable
        //     .tableLeft
        //         width: 180px

        //     .tableRight
 } }        //         padding-left: 178px

.react-datepicker-wrapper {
    input {
        background: url(#{$publicURL}/resources/cbu/e-service/images/icon-calendar.svg) 96% 9px no-repeat; } }

.datepicker-divider {
    margin: 0 ($space * 2);
    font-size: 20px;
    line-height: 50px;
    font-weight: $font-bold; }

.login-paper {
    padding: 60px;
    background: $white;
    margin-bottom: $space * 1.5;
    box-shadow: 0 6px 12px 0 rgba(75, 88, 105, 0.12);

    .login-ad {
        width: 100%;
        height: 478px;
        padding: 60px;
        background: url(#{$publicURL}/resources/cbu/e-service/images/bg-login-ad.png) no-repeat; }

    .tooltip-container {
        & + .form-group {
            width: 100%;
            position: relative;
            top: -23px; } } }

.fui-article-panel-button {
    .fui-button {
        margin-right: 0;
        color: $accent !important;
        position: relative;

        .text {
            color: $accent;
            opacity: 1 !important; }

        &:after {
            width: 30px !important;
            height: 30px !important;
            position: absolute !important;
            left: 95% !important;
            transform: translateX(100%) !important;
            content: "" !important;
            background: url(#{$publicURL}/resources/common/images/btn-is-arrow.svg) no-repeat !important; } } }

.roaming-plan {
    padding-top: 60px;
    background-image: url(#{$publicURL}/resources/cbu/img/bg-cbu-fcoin-pattern-1920-x-1200@2x.png);
    background-size: 100%;
    width: 100%;
    min-height: 600px;
    background-repeat-y: no-repeat;

    &.no-bg {
        background: none; }

    .checkbox input + .content {
        font-size: 1rem; }

    .sub-head {
        display: flex;
        margin-bottom: 1.5rem; }

    .search-bar {
        .search-head {
            .fui-switch {
                margin-right: 24px; } } }

    .main-card {
        overflow: visible;

        .fui-cards {
            padding: 0; }

        .fui-card-title {
            font-size: 24px; }

        .left-card,
        .right-card {
            overflow: visible;
            margin: 0;

            .fui-card {
                margin: 0; }

            .fui-card-content {
                padding: 64px 24px;
                min-height: 304px; }

            .fui-card-extra {
                padding: 0;
                margin: 0; } }

        .left-card {
            .fui-card-title {
                margin-bottom: 6px; } }

        .right-card {
            margin-right: 0;

            .fui-card-title {
                margin-bottom: 30px; }

            .fui-card-content {
                background: url(#{$publicURL}/resources/cbu/e-service/images/bg-login-ad.png) no-repeat;
                background-size: 102%; } } }

    .service-entry {
        .animate-red-arrow {
            display: none; } }

    section.service-entry {
        padding-bottom: 30px; }

    .action-arrow {
        border-left: 1px solid #D6D6D6;
        padding-left: 15px;
        margin-left: 15px;
        font-size: 16px;

        span {
            display: block; }

        img {
            // margin-left: 5px
            vertical-align: middle; } }

    .is-apply-card {
        padding-bottom: 10px !important;

        .fui-card {
            margin-bottom: 0; }

        .action-arrow {
            border-left: 0;
            display: flex;
            justify-content: flex-end; } }

    .keyword-container {
        margin-top: 0;
        margin-right: 0;

        .keyword-list {
            .keyword-content {
                .keyword-group {
                    white-space: normal; } }

            .label {
                display: none; }

            .fui-button.is-label {
                margin-bottom: 10px;
                min-width: auto;

                &.is-active {
                    border: solid 2px #202020; } } } }

    &.roaming-plan-search {
        padding-top: 100px;
        padding-bottom: 0;
        background-repeat: no-repeat;

        .fui-faq-collapse {
            .fui-tab {
                overflow-x: auto; } } }

    &.roaming-plan-apply {
        a.hover-underline-link {
            color: $black50 !important;

            &:after {
                display: none !important; }

            &:hover {
                &:after {
                    display: none !important; } } }

        .fui-panel {
            .text,
            p {
                font-size: 16px; } } } }

.menu-autocomplete {
    .fui-item {
        color: #202020 !important;

        &:after {
            display: none !important; }

        &:hover,
        &:focus {
            color: $error !important;
            opacity: 1 !important;

            &:after {
                display: none !important; } } }

    .menu-header {
        .fui-tab {
            .fui-tab-item {
                display: block; } } }

    .fui-menu-body {
        position: absolute;
        top: 50px;
        left: 0;
        background: #fff;
        box-shadow: 0 16px 32px 0 rgba(75, 88, 105, 0.3);

        .menu-header {
            background: #fafafa;

            .menu-content {
                flex: 1 1;
                flex-grow: 1;
                padding-top: 0;

                .fui-item {
                    padding: 12px 24px;
                    margin-bottom: 0 !important;

                    &.is-active {
                        background: #fff; } } } }

        .menu-body {
            .menu-content {
                max-height: 300px;
                overflow-y: scroll;
                overflow-x: hidden;
                padding: 0 30px 0 30px;

                .category-hot {
                    margin-top: 1rem;
                    margin-bottom: 0; }

                .category-all {
                    margin-top: 2rem;
                    margin-bottom: 0; }

                .fui-item {
                    padding: 12px 0; } } } } }

.fui-nav-anchor.is-fixed + .fui-breadcrumb-container + .fui-banner + .origin-number-roaming {
    margin-top: 45px !important; }

.origin-number-roaming {
    .fui-horzonal-cards {
        .section-bg {
            display: none; } }

    &.with-bg {
        background: url(#{$publicURL}/resources/cbu/e-service/images/bg-ribbon.png) repeat-y top left;
        background-size: 100%;
        width: 100%; }

    &.index {
        padding-bottom: 0;

        .overflow-hidden {
            .fui-horzonal-cards {
                .fui-container {
                    width: 100%;
                    overflow: hidden; } } }

        .is-section-feature {
            padding-top: 0; } }

    &.prepaid {
        padding-bottom: 0;

        section.apply-steps,
        .apply-steps {
            background-size: cover;
            background-position: top left;
            background-repeat: no-repeat; } }

    &.country {
        .collapse-roaming-block {}

        .other-info {
            ul {
                li {
                    display: inline-flex;
                    width: 33%; } } }

        .empty-block {
            margin-bottom: 60px;
            padding: 30px;
            background: $lightgray70; } }

    &.remind {
        .fui-tab-pane {
            margin: 12px 0;

            .paper {
                &:last-child {
                    margin-bottom: 12px; } } }

        figure {
            figcaption {
                display: block; } } }

    &.local-card {
        .card-multi-step {
            padding-bottom: 0 !important;
            background: #f0f1f3; } }

    &.buy-local-card {
        .fui-banner {
            padding-right: 0 !important;

            .caption {
                .subtitle {
                    margin-bottom: 36px !important; } } } } }

// 生活圈
.life-circle {
    padding-top: 0 !important;
    background: url(#{$publicURL}/resources/cbu/life-circle-drama/images/background-pattern-light.png) repeat-y;
    background-size: 100% !important;

    .w-100 {
        width: 100%; }

    .has-more-tab {
        font-size: 18px;

        .fui-dropdown {
            button {
                font-size: 18px; }

            .fui-menu {
                .fui-item {
                    &:hover,
                    &:focus {
                        opacity: 1;
                        background: #fafafa !important;

                        .content {
                            color: #d3060f; } }

                    &.active {
                        .content {
                            color: #202020; }

                        &:hover,
                        &:focus {
                            .content {
                                color: #d3060f; } } } } } } }

    .vertical-anchor-nav {
        position: fixed;
        top: 25%;
        right: 0;
        display: flex;
        flex-direction: column;
        z-index: 4;
        padding-left: 0;

        li {
            list-style: none; }

        li,
        a {
            position: relative;
            width: 140px;
            height: 60px;

            span {
                display: none; }

            &:before,
            &:after {
                content: "";
                position: absolute; }

            &:before {
                top: 15px;
                right: 20px;
                width: 10px;
                height: 10px;
                border-radius: 10px;
                background: #f05c38; }

            &:after {
                top: 36px;
                right: 25px;
                height: 30px;
                border-left: 1px solid #ff644d; }

            &:last-child {
                &:after {
                    display: none; } }

            &.active {
                background: url(#{$publicURL}/resources/cbu/life-circle-drama/images/time-trangle.svg) no-repeat;

                span {
                    display: block;
                    color: #fff;
                    font-size: 16px;
                    letter-spacing: 0.2px;
                    line-height: 28px;
                    position: relative;
                    top: 6px;
                    padding-right: 40px;
                    text-align: right; }

                &:before {
                    background: #fff; } } } }

    section.fui-section-promo {
        .fui-container {
            padding: 0 15px; } }

    .fui-tab-pane {
        padding-top: 12px;

        img {
            max-width: 100%; }

        .MuiGrid-container {
            .MuiGrid-item + .MuiGrid-item {
                padding: 20px 30px 20px 40px;
                background: #eff1f2;
                display: flex;
                justify-content: center;
                align-items: flex-start;
                flex-direction: column; }

            h2.is-text-regular {
                margin-bottom: 90px; } } }

    .fui-bancard {
        .MuiGrid-container {
            .MuiGrid-item + .MuiGrid-item {
                padding: 20px 30px 20px 40px;
                background: #eff1f2;
                display: flex;
                justify-content: center;
                align-items: flex-start;
                flex-direction: column; } }

        .fui-bancard-image {
            display: flex;

            img {
                width: 100%; } }

        .fui-bancard-content {
            width: 100%; }

        // .img
        //     display: flex
        // img
        //     max-width: 100%

        .checklist {
            margin-bottom: 90px;

            img {
                margin-right: 5px;
                vertical-align: bottom; } } }

    section.fui-breadcrumb-container {
        top: 145px; }

    .h-0 {
        height: 0 !important; }

    .banner {
        position: relative;
        height: 470px;

        .img {
            position: relative; }

        .text {
            position: relative;

            // top: -380px
            z-index: 2;
            transition-delay: 1s; }

        &.img-right {
            &:before {
                content: "";
                position: absolute;
                top: -1px;
                left: 0;
                width: 0;
                height: 472px;
                background: rgb(255, 255, 255);
                background: linear-gradient(270deg, rgba(255, 255, 255, 1) 0%, rgba(250, 250, 250, 1) 100%);
                clip-path: polygon(0 0, 57% 0, 100% 100%, 0% 100%);
                z-index: 2;
                transition: all 0.5s ease; }

            .img {
                img {
                    position: absolute;
                    right: 0; } }

            .text {
                top: 90px;
                opacity: 0;
                transition-delay: 1s;
                transition: all 1s ease; } }

        &.img-left {
            &:before {
                content: "";
                position: absolute;
                top: -1px;
                right: 0;
                width: 0;
                height: 472px;
                background: rgb(255, 255, 255);
                background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(250, 250, 250, 1) 100%);
                clip-path: polygon(0 0, 100% 0, 100% 100%, 43% 100%);
                z-index: 2;
                transition: all 0.5s ease; }

            .text {
                top: -380px;
                opacity: 0;
                transition-delay: 1s;
                transition: all 1s ease; } } }

    .visible {
        .banner {
            transition: all 0.5s ease;

            &.img-right {
                &:before {
                    content: "";
                    position: absolute;
                    top: -1px;
                    left: 0;
                    width: 60%;
                    height: 472px;
                    background: rgb(255, 255, 255);
                    background: linear-gradient(270deg, rgba(255, 255, 255, 1) 0%, rgba(250, 250, 250, 1) 100%);
                    clip-path: polygon(0 0, 57% 0, 100% 100%, 0% 100%);
                    z-index: 2;
                    transition: all 0.5s ease; }

                .img {
                    img {
                        position: absolute;
                        right: 0; } }

                .text {
                    top: 90px;
                    opacity: 1;
                    transition-delay: 1s;
                    transition: all 1s ease; } }

            &.img-left {
                &:before {
                    content: "";
                    position: absolute;
                    top: -1px;
                    right: 0;
                    width: 60%;
                    height: 472px;
                    background: rgb(255, 255, 255);
                    background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(250, 250, 250, 1) 100%);
                    clip-path: polygon(0 0, 100% 0, 100% 100%, 43% 100%);
                    z-index: 2;
                    transition: all 0.5s ease; }

                .text {
                    opacity: 1;
                    transition-delay: 1s;
                    transition: all 1s ease; } } } }

    .more {
        padding: 48px 0 0 0; }

    .fui-card {
        min-width: auto;

        .fui-card-image {
            padding-bottom: 0;

            img {
                width: 100%;
                position: relative;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0; } }

        .fui-card-content {
            .fui-card-title {
                .text {
                    line-height: 1.6; } } } }

    .drama-list {
        .horizontal-cards.slick-slider {
            .slick-list {
                .slick-slide {
                    padding: 0 30px; }

                .fui-card-content {
                    padding: 12px 18px;
                    background: #fff;
                    min-height: 132px; }

                .name {
                    margin: 0 0 6px 0;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical; }

                p {
                    margin: 0;
                    color: $gray100; } } } }

    .justify-lg-flex-end {
        justify-content: flex-end; }

    .horizontal-cards.slick-slider {
        .slick-arrow.slick-prev {
            left: 92%; }

        .fui-card {
            .fui-card-description {
                margin-top: 0; } } }

    .news-list {
        .fui-card-subtitle {
            color: $gray100; } }

    .songs-list {
        padding-bottom: 18px;

        .fui-horzonal-cards {
            &:first-child {
                padding-bottom: 0 !important; }

            &:nth-child(2) {
                padding-top: 0 !important; } }

        & + .songs-list {
            padding-top: 0;
            padding-bottom: 60px; }

        .more {
            padding-top: 18px; }

        .horizontal-cards.slick-slider {
            .slick-list {
                .slick-slide {
                    display: block; }

                .fui-card {
                    margin-right: 12px;
                    margin-bottom: 12px; }

                .fui-card-action {
                    flex-direction: row;
                    min-height: 0; }

                .fui-card-image {
                    width: auto; }

                img {
                    width: auto;
                    margin: 0 !important; }

                // .fui-card-caption
                //   width: 100%
                .fui-card-content {
                    padding: 12px;
                    position: relative; }

                .fui-card-title {
                    margin: 0;
                    font-size: 18px; }

                .fui-card-description {
                    margin: 0;
                    color: $gray100; }

                .fui-button {
                    float: right;
                    position: absolute;
                    right: 12px;
                    bottom: 10px;
                    min-width: 0 !important;
                    margin-bottom: 0 !important;
                    height: 30px !important;
                    padding: 0 18px !important;
                    margin: 0 !important;

                    .text {
                        height: 30px !important;
                        line-height: 27px !important; } } } } }

    .goods-list {
        .fui-card-content {
            padding-bottom: 30px; }

        .fui-card-title {
            min-height: 60px;

            .text {
                font-size: 1.25rem !important; } }

        .fui-card-price {
            margin: 0; }

        .fui-card-description {
            min-height: 56px; } }

    .tools-list {
        .with-comma {
            position: relative;
            display: inline-block;

            &:before,
            &:after {
                position: absolute;
                content: "";
                width: 30px;
                height: 30px;
                background: url(#{$publicURL}/resources/cbu/life-circle-drama/images/quotation-white.svg) no-repeat; }

            &.is-text-white {
                &:before,
                &:after {
                    background: url(#{$publicURL}/resources/cbu/life-circle-drama/images/quotation-white.svg) no-repeat; } }

            &:before {
                top: -10px;
                left: -35px;
                transform: rotate(180deg); }

            &:after {
                bottom: 0;
                right: -30px; } }

        .border-bottom {
            position: relative;
            display: inline-block;
            margin: 0 5px;

            &:after {
                content: "";
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                border-bottom: 12px solid rgba(240, 92, 56, 0.4); }

            &.is-blue30 {
                &:after {
                    border-bottom: 21px solid rgba(155, 223, 223, 0.4); } }

            &.is-green30 {
                &:after {
                    border-bottom: 21px solid rgba(0, 255, 218, 0.4); } } }

        @media screen and (max-width: 960px) {
            .border-bottom.is-blue30 {
                &:after {
                    border-bottom: 19px solid rgba(155, 223, 223, 0.4); } } }

        .tools-item {
            padding: 50px 50px 0 50px;

            .MuiGrid-container {
                justify-content: center; }

            .MuiGrid-item {
                width: calc(100% / 7);
                text-align: center;

                img {
                    margin-bottom: 10px;
                    transition: all 0.6s;
                    position: relative;
                    top: 0; }

                .body-1 {
                    color: $gray100; } }

            .slick-slider {
                .slick-list {
                    .slick-slide {
                        padding: 0 20px; } } }

            .slide {
                img {
                    transition: transform 0.3s ease; }

                &:hover {
                    img {
                        transform: translateY(-6px); } } } } }

    .promo-list {
        .fui-container {
            width: 100%;
            padding: 0;
            overflow: hidden; }

        .section-title {
            margin-bottom: 0 !important; }

        .horizontal-cards.slick-slider {
            padding-top: 36px;

            .slick-arrow.slick-prev,
            .slick-arrow.slick-next {
                top: -36px; } } }

    .circle-list {
        .fui-card-image,
        .fui-card-content {
            max-width: 300px; }

        img {
            max-width: 300px; }

        .fui-card-content {
            padding: 24px 0 0 0; }

        .fui-card.fui-card-life-circle {
            background: none !important;
            box-shadow: none !important; }

        .fui-card-image {
            padding-bottom: 0;

            img {
                width: 100%;
                position: relative;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0; } }

        .fui-card-caption {
            position: relative;

            .fui-card-title {
                position: absolute;
                width: 300px;
                top: -160px;
                left: 0;
                text-align: center;
                color: #fff; } }

        &:hover,
        &:focus {
            box-shadow: none !important;

            .fui-card-image {
                img {
                    transform: scale(1); } } }

        .fui-card-action,
        a.fui-card-action {
            background: none;

            &:hover,
            &:focus {
                background: none;

                .fui-card-image {
                    img {
                        transform: scale(1); } } } } }

    &.index {
        section.fui-breadcrumb-container ul.fui-breadcrumb li,
        section.fui-breadcrumb-container ul.fui-breadcrumb li a {
            color: #fff; }

        section.fui-breadcrumb-container ul.fui-breadcrumb li::after {
            background: #fff; }

        section:not(.fui-section-promo) {
            padding: 0px 0 60px; }

        section.fui-breadcrumb-container {
            padding: 24px 0; }

        .main-banner {
            // min-height: 400px
            .slick-slide {
                min-width: 300px !important; }

            .fui-tab-pane {
                padding-top: 0; }

            .slide {
                position: relative; }

            .slick-arrow {
                width: 36px;
                height: 36px;
                position: absolute;
                z-index: 2;
                border: 0;
                background: none;
                text-indent: -9999px;
                cursor: pointer; }

            .slick-prev {
                top: 45%;
                left: 20px;
                background: url(#{$publicURL}/resources/cbu/life-circle-index/images/slick-prev.svg) no-repeat;
                transition: transform 0.3s ease;

                &:hover {
                    transform: translateX(-10px); } }

            .slick-next {
                top: 45%;
                right: 20px;
                background: url(#{$publicURL}/resources/cbu/life-circle-index/images/slick-next.svg) no-repeat;
                transition: transform 0.3s ease;

                &:hover {
                    transform: translateX(10px); } }

            .slick-arrow-prev-wording,
            .slick-arrow-next-wording {
                position: relative;
                width: 100%;
                height: 100%;
                display: contents;

                .slick-list {
                    max-width: 300px;
                    max-height: 32px;
                    position: absolute;
                    z-index: 2;

                    p {
                        margin: 0;
                        font-size: 18px;
                        color: #fff; } }

                .slick-dots {
                    display: none !important; } }

            .slick-arrow-prev-wording {
                .slick-list {
                    top: 46%;
                    left: 60px; } }

            .slick-arrow-next-wording {
                .slick-list {
                    top: 46%;
                    right: 60px;
                    text-align: right; } }

            .img {
                height: 470px;
                position: relative;
                z-index: 1;

                img {
                    height: 470px;
                    max-width: inherit; } }

            .wording {
                width: 50%;
                margin: 0 25%;
                position: absolute;
                top: 65px;
                z-index: 2;
                text-align: left;

                .title {
                    font-size: 56px;
                    margin-bottom: 30px; }

                .subtitle {
                    margin-bottom: 48px; }

                .with-comma {
                    position: relative;

                    &:before {
                        content: "";
                        position: absolute;
                        top: -5px;
                        left: -35px;
                        width: 30px;
                        height: 30px;
                        background: url(#{$publicURL}/resources/cbu/life-circle-index/images/quotation.svg) no-repeat; } }

                .is-last {
                    text-align: center;
                    margin: 0 auto;
                    padding-top: 140px; } }

            .slick-dots {
                width: 100%;
                padding: 0;
                margin: 0;
                position: absolute;
                top: 80%;
                text-align: center;

                li {
                    margin: 0 3px;
                    display: inline;

                    button {
                        border: 0;
                        width: 50px;
                        height: 4px;
                        background: rgba(0, 0, 0, 0.5); }

                    &.slick-active {
                        button {
                            background: #fff; } } } }

            & + .fui-nav-tab {
                top: -40px;
                z-index: 12; } }

        .tools-list-carousel {
            padding-top: 20px !important;

            .fui-container {
                width: 100%;
                padding: 0;
                overflow: hidden;

                .fui-container {
                    padding: 0 15px; } }

            .tools-item {
                padding: 0;

                .slide {
                    text-align: center; }

                img {
                    margin: 0 auto 10px auto; }

                .body-1 {
                    color: $gray100; }

                .slick-slider {
                    .slick-list {
                        .slick-slide {
                            padding: 0 15px; } } }

                .slide {
                    img {
                        transition: transform 0.3s ease; }

                    &:hover {
                        img {
                            transform: translateY(-6px); } } } } }

        .ad-carousel {
            padding-bottom: 40px;

            .slick-slide {
                .img {
                    img {
                        width: 100%; } } }

            .img {
                display: flex;

                img {
                    min-height: 240px; } }

            .wording {
                height: 100%;
                min-height: 240px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 30px 200px 30px 30px;
                position: relative;
                background: rgb(255, 100, 77);
                background: linear-gradient(0deg, rgba(255, 100, 77, 1) 0%, rgba(211, 6, 15, 1) 100%);

                &:before {
                    content: "";
                    width: 340px;
                    height: 240px;
                    position: absolute;
                    top: 0;
                    left: 0;
                    background: url(#{$publicURL}/resources/cbu/life-circle-index/images/path-copy-31.png) no-repeat;
                    background-size: 100%; }

                > div {
                    z-index: 2; }

                img {
                    margin-right: 10px;
                    display: inline;
                    vertical-align: middle; } }

            .slick-dots {
                text-align: center;
                margin: 0;
                position: relative;
                top: -50px;

                li {
                    margin: 0 3px;
                    display: inline;

                    button {
                        border: 0;
                        width: 50px;
                        height: 4px;
                        background: rgba(0, 0, 0, 0.5); }

                    &.slick-active {
                        button {
                            background: #fff; } } } } }

        .fui-card {
            .fui-card-content {
                .fui-card-title {
                    margin-top: 0; } } }

        .coupon-list {
            .slick-slide {
                .fui-card {
                    .fui-card-content {
                        .fui-card-title {
                            .text {
                                font-size: 20px; }

                            .text,
                            h5 {
                                margin: 0; } } } } } }

        .fCoin-carousel {
            .fui-card {
                .fui-card-content {
                    padding: 24px 24px 12px 24px; } }

            .slick-slide {
                .fui-card {
                    .fui-card-content {
                        .fui-card-title {
                            .text {
                                font-size: 20px; }

                            .text,
                            h5 {
                                margin: 0; } } } }

                .fui-card-caption {
                    padding-bottom: 40px; }

                .fui-card-price {
                    position: absolute;
                    bottom: 24px;

                    img {
                        margin-right: 10px;
                        display: inline;
                        vertical-align: middle; }

                    h6 {
                        margin: 0;
                        display: inline; } } } }

        .goods-list {
            .slick-slide {
                .tag {
                    padding: 4px 0px 0px 12px;
                    height: 28px;
                    position: absolute;
                    top: 0;
                    left: 8px;
                    font-size: 14px;
                    z-index: 2; }

                .tag-green {
                    color: #fff;
                    background: #5f7b80;

                    &:after {
                        content: "";
                        position: relative;
                        top: 24px;
                        left: 28px;
                        width: 0;
                        height: 0;
                        border-style: solid;
                        border-width: 28px 28px 0 0;
                        border-color: #5f7b80 transparent transparent transparent; } } } } }

    // 策展
    &.curation {
        .fui-tab-pane {
            .extra-link-card.fui-card {
                width: 100%; } }

        .fui-tab-pane .MuiGrid-container .MuiGrid-item + .MuiGrid-item {
            padding: 8px !important;
            background: none; }

        .fui-breadcrumb-container {
            margin-bottom: 0; }

        .extra-link-card {
            .img-wrapper {
                .periodic-content {
                    padding: 0 0 10px 15px;
                    min-width: 160px;
                    height: 105px;
                    background: url(#{$publicURL}/resources/cbu/life-circle-curation/images/cbu-article-label-bkg.png) no-repeat;

                    > div {
                        position: absolute;
                        bottom: 10px; } } } }

        .group-extra-link-card {
            .fui-card-extra {
                .fui-button {
                    &:hover,
                    &:focus {
                        color: $error; } } } }

        .selected-article {
            .head {
                background: url(#{$publicURL}/resources/cbu/life-circle-curation/images/cbu-article-sidebar-bkg.png) no-repeat;
                background-size: cover; } }

        .fui-container {
            .promotion-container {
                margin: 0; } }

        #tech-content {
            padding-bottom: 20px !important; } }

    // 文章
    &.article {
        .fui-panel {
            // padding-top: 40px !important
            // padding-bottom: 40px !important
            padding-left: 0 !important;
            padding-right: 0 !important;
            padding-bottom: 0 !important;
            margin-bottom: 0;
            box-shadow: none;

            .fui-container {
                padding: 0 200px; }

            img {
                max-width: 100%; } }

        .fui-container-article {
            padding: 0 200px; }

        .article-banner {
            position: relative;

            .img {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;

                img {
                    height: 100%;
                    position: relative;
                    display: block; } }

            .wording {
                position: relative;
                top: 0;
                left: 0;
                z-index: 2; }

            h1 {
                margin-bottom: 0; } }

        .article-panel {
            background: none;

 }            // padding-bottom: 0 !important

        .article-intro-panel {
            padding-bottom: 60px !important;
            margin-bottom: 0;
            background: url(#{$publicURL}/resources/cbu/life-circle-article/images/article-opener-bkg.jpg) no-repeat;
            background-size: 100%;
            box-shadow: none;

            p {
                color: #5f7b80; } }

        figure.fui-figure {
            figcaption {}

            min-width: 0;
            margin-top: 0;
            margin-bottom: 36px;
            color: $darkgray50;

            &:before {
                margin-top: 1px;
                border-bottom: 7px solid $accent;
                font-size: 18px; } }

        .article-author-tags-panel {
            padding-top: 30px !important;
            padding-bottom: 60px !important;
            background: none;

            .border-author {
                padding-left: 15px;
                margin-bottom: 48px;
                border-left: 6px solid $darkgreen;
                color: $darkgreen;
                font-size: 16px; }

            .tags {
                display: flex;
                flex-direction: row;

                .item {
                    margin-right: 10px;
                    padding: 6px 18px;
                    color: $darkgray50;
                    font-size: 16px;
                    border: 2px solid $darkgray50;
                    border-radius: 20px;

                    &::after {
                        display: none; } } } }

        .article-author-panel {
            min-height: 224px;
            padding-bottom: 60px !important;
            background: url(#{$publicURL}/resources/cbu/life-circle-article/images/article-author-bkg.jpg) no-repeat;
            background-size: cover;

            .fui-container {
                display: flex; }

            .img,
            .wording {
                float: left; }

            .img {
                width: 120px;

                img {
                    max-width: 100px;
                    height: auto; } }

            .wording {
                width: calc(100% - 120px);

                * {
                    color: #465a5e; }

                p {
                    margin-bottom: 0; } } }

        .goods-list {
            padding-top: 100px !important;
            padding-bottom: 60px !important; }

        .article-share-panel {
            padding-bottom: 60px !important;
            background: none;

            .fui-container {
                display: flex;
                flex-direction: row;
                align-items: center; }

            .img,
            .wording {
                float: left; }

            a:hover,
            a:focus {
                &:after {
                    display: none !important; } } }

        .highlight.border-bottom {
            position: relative;
            display: inline-block;
            margin: 0 5px;

            &:after {
                content: "";
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                border-bottom: 12px solid rgba(240, 92, 56, 0.4); } }

        iframe {
            width: 100%;
            height: auto;
            min-height: 400px;
            margin: 0; }

        .article-citation-panel {
            margin-top: 60px;
            padding-bottom: 60px !important;
            background: #eff1f2;

            .single-comma {
                position: relative;
                top: 0;
                left: 0;
                padding-left: 52px;

                &:before {
                    content: "";
                    position: absolute;
                    top: -20px;
                    left: 0;
                    width: 48px;
                    height: 48px;
                    background: url(#{$publicURL}/resources/cbu/life-circle-article/images/article-quote-mark.svg) no-repeat; } } }

        // .fui-tab-pane
        //     .img
 }        //         display: flex

    .icon-facebook-share {
        width: 48px;
        height: 48px;
        display: block;
        background: url(#{$publicURL}/resources/common/images/facebook-share.svg) no-repeat; }

    .icon-line-share {
        width: 48px;
        height: 48px;
        display: block;
        background: url(#{$publicURL}/resources/common/images/line-share.svg) no-repeat; } }

@media screen and (max-width: 1440px) {
    .life-circle {
        .banner {
            &.img-right {
                .img {
                    img {
                        right: -200px; } } }

            &.img-left {
                .img {
                    img {
                        position: relative;
                        left: -200px; } } } }

        .visible {
            .banner {
                &.img-right {
                    .img {
                        img {
                            right: -200px; } } }

                &.img-left {
                    .img {
                        img {
                            position: relative;
                            left: -200px; } } } } }

        .drama-list {
            .horizontal-cards.slick-slider {
                .slick-list {
                    .slick-slide {
                        padding: 0 20px; } } } }

        &.article {
            .article-banner {
                .img {
                    img {
                        left: -200px; } } } } } }

@media screen and (max-width: 1280px) {
    .life-circle {
        .banner {
            &.img-right {
                .img {
                    img {
                        right: -300px; } } }

            &.img-left {
                .img {
                    img {
                        position: relative;
                        left: -300px; } } } }

        .visible {
            .banner {
                &.img-right {
                    .img {
                        img {
                            right: -300px; } } }

                &.img-left {
                    .img {
                        img {
                            position: relative;
                            left: -300px; } } } } }

        .drama-list {
            .horizontal-cards.slick-slider {
                .slick-list {
                    .slick-slide {
                        padding: 0 20px; } } } }

        &.article {
            .article-banner {
                .img {
                    img {
                        left: -300px; } } }

            .article-author-tags-panel {
                .border-author {
                    margin-bottom: 36px; }

                .tags {
                    display: inline-block;

                    .item {
                        margin: 0 10px 10px 0;
                        float: left; } } } } }

    .roaming-plan {
        .main-card {
            .left-card,
            .right-card {
                .fui-card-content {
                    min-height: 340px;
                    height: 100%; } }

            .right-card {
                .fui-card-content {
                    background-size: cover; } } } }

    .origin-number-roaming.prepaid {
        section.apply-steps,
        .apply-steps {
            background-position: top center; } } }

@media screen and (max-width: 960px) {
    .life-circle {
        section.fui-breadcrumb-container {
            display: block;
            position: absolute;
            z-index: 29;
            top: 85px !important; }

        .horizontal-cards.slick-slider {
            .slick-arrow.slick-prev {
                left: 85%; } }

        &.article {
            .fui-panel {
                .fui-container {
                    padding: 0 15px; } }

            .article-panel {
                .fui-container {
                    padding: 0 15px; } }

            .fui-container-article {
                padding: 0 15px; }

            .article-author-tags-panel {
                .tags {
                    display: inline-block;

                    .item {
                        margin: 0 10px 10px 0;
                        float: left; } } } }

        &.index {
            .main-banner {
                .wording {
                    .title {
                        font-size: 42px;
                        margin-bottom: 30px; }

                    .subtitle {
                        margin-bottom: 30px; }

                    .is-last {
                        padding-top: 120px; } }

                .img {
                    position: relative;
                    overflow: hidden;

                    img {
                        position: absolute;
                        left: -300px; } }

                & + .fui-nav-tab {
                    top: 0; } } }

        &.curation {
            .pb-md-0 {
                padding-bottom: 0; }

            .has-more-tab {
                .tab-wrapper {
                    .tab {
                        padding: 11px 12px; } } }

            .fui-container {
                .promotion-container {
                    margin: 0 -15px; } }

            .info {
                padding-top: 20px !important; }

            .section-promotion-5 {
                .mobile {
                    background-position: center top;

                    .promotion-button {
                        width: 100%;
                        margin-top: 0; } } }

            .load-more {
                margin: 30px 0; } } }

    .roaming-plan {
        .main-card {
            .left-card,
            .right-card {
                .fui-card-content {
                    min-height: auto;
                    height: 100%; } }

            .right-card {
                .fui-card {
                    margin-bottom: 20px; }

                .fui-card-content {
                    background-size: cover; } } }

        .fui-cards.is-switch-card {
            margin: 12px 0;
            padding: 0 0px 48px 0px;

            .fui-card {
                margin-left: 0;
                margin-right: 0; } }

        .fui-cards.is-apply-card {
            margin: 12px 0;
            padding: 0 0px 48px 0px;

            .fui-card {
                margin-left: 0;
                margin-right: 0; } } }

    .origin-number-roaming {
        &.country {
            .other-info {
                ul {
                    li {
                        width: 49%; } } } } }

    .service-entry {
        .fui-cards.four-card {
            margin: 0;
            padding: 0 0px 48px 0px;

            .fui-card {
                margin-left: 0;
                margin-right: 20px;
                width: calc(25% - 15px);

                &:last-child {
                    margin-right: 0; } } } } }

@media screen and (max-width: 768px) {
    .react-datepicker-popper,
    .react-datepicker__month-container {
        max-width: calc(100vw - 40px) !important; }

    .datepicker-divider {
        width: 100%;
        margin: 0;
        text-align: center;
        line-height: 20px;

        // transform: rotate(90deg)
        position: relative;
        top: -$space * 2; }

    .parkingfeePage {
        .scroll-table {
            width: calc(160vw); } }

    .roaming-plan {
        padding-top: 30px;

        .main-card {
            .left-card,
            .right-card {
                margin: 0;

                .fui-card {
                    margin: 0; }

                .fui-card-content {
                    padding: 30px 20px;
                    min-height: auto; } }

            .right-card {
                margin-bottom: 10px !important; } }

        .is-apply-card {
            .action-arrow {
                padding-left: 0;
                margin-left: 0; } }

        .is-switch-card {
            .search-bar {
                .search-head {
                    justify-content: flex-end; } } }

        // .button-group
        //     .two-buttons
        //         .fui-button
        //             margin-right: 0

        .menu-autocomplete {
            z-index: 9;

            .fui-menu-body {
                z-index: 9;

                .menu-header {
                    .menu-content {
                        .fui-item {
                            padding: 12px 15px; } } }

                .menu-body {
                    .menu-content {
                        max-height: 400px;
                        padding: 0 20px 12px 20px; } } } }

        section.service-entry {
            padding: 20px 0 0 0; }

        &.roaming-plan-search {
            padding-top: 40px; } }

    .service-entry {
        .fui-cards.four-card {
            margin: 0;
            padding: 0 0px 48px 0px;

            .fui-card {
                margin-left: 0;
                margin-right: 16px;
                width: calc(50% - 8px);

                &:nth-child(even) {
                    margin-right: 0; } } } }

    .life-circle {
        background: url(#{$publicURL}/resources/cbu/life-circle-drama/images/background-pattern-light-m.png) repeat-y;

        .banner {
            &.img-right {
                .img {}

                img {
                    right: -400px; } }

            &.img-left {
                .img {}

                img {
                    position: relative;
                    left: -400px; } } }

        .visible {
            .banner {
                &.img-right {
                    .img {}

                    img {
                        right: 0;

 } }                        // right: -400px

                &.img-left {
                    .img {}

                    img {
                        position: relative;
                        left: 0;

 } } } }                        // left: -400px

        .vertical-anchor-nav {
            display: none !important; }

        .horizontal-cards.slick-slider {
            .slick-arrow.slick-prev {
                left: 85%; } }

        .fui-tab {
            overflow: scroll;

            a.fui-tab-item,
            .fui-tab-item {
                padding: 6px 12px 12px; } }

        .fui-tab-pane {
            .MuiGrid-container {
                .MuiGrid-item + .MuiGrid-item {
                    padding: 30px 20px; }

                h2.is-text-regular {
                    margin-bottom: 30px; }

                .checklist {
                    margin-bottom: 30px; }

                .fui-button {
                    margin-bottom: 0; } } }

        .fui-bancard {
            .MuiGrid-container {
                .MuiGrid-item + .MuiGrid-item {
                    padding: 30px 20px; } }

            .checklist {
                margin-bottom: 30px; }

            .fui-button {
                margin-bottom: 0; } }

        section.fui-breadcrumb-container {
            display: none;

 }            // top: 95px

        .drama-list {
            .horizontal-cards.slick-slider {
                .slick-list {
                    .slick-slide {
                        // width: 120px
                        width: 100%;
                        padding: 0 6px; } } }

            .fui-card-content {
                padding: 10px !important; }

            // .fui-card
            //     .fui-card-image
            //         img
            //             width: 90px !important
 }            //             height: 133px !important

        .news-list {
            .horizontal-cards.slick-slider {
                .slick-list {
                    .slick-slide {
                        width: 270px; } } } }

        .goods-list,
        .coupon-list,
        .fCoin-carousel {
            .horizontal-cards.slick-slider {
                .slick-list {
                    .slick-slide {
                        width: 170px; } } }

            .fui-card-title {
                .text {
                    font-size: 18px !important; } } }

        .coupon-list {
            .fui-card {
                .fui-card-content {
                    padding: 12px; } } }

        .songs-list {
            .horizontal-cards.slick-slider {
                .slick-list {
                    .slick-slide {
                        width: calc(100vw - 40px); } } } }

        .promo-list {
            .horizontal-cards.slick-slider {
                .slick-list {
                    .slick-slide {
                        width: 270px; } } } }

        .tools-list {
            padding-bottom: 0;

            .align-center {
                padding: 0 40px; }

            .tools-item {
                padding: 36px 24px;
                overflow: scroll;

                .MuiGrid-container {
                    margin: 0 10px;
                    overflow: scroll;
                    width: calc(100vw);
                    flex-wrap: nowrap; }

                .MuiGrid-item {
                    width: auto;
                    min-width: 120px;
                    margin: 0 15px; }

                .slick-slider {
                    .slick-list {
                        .slick-slide {
                            padding: 0 15px; } } } } }

        .circle-list {
            .fui-card-image,
            .fui-card-content {
                max-width: 200px; }

            .horizontal-cards.slick-slider {
                .slick-list {
                    .slick-slide {
                        padding-right: 20px; } } }

            .fui-card-caption {
                .fui-card-title {
                    width: 200px;
                    top: -120px; } } }

        &.article {
            .fui-panel {
                padding-top: 40px !important;
                padding-bottom: 0px !important;

                .fui-container {
                    padding: 0 15px; } }

            .fui-article-panel-button {
                .fui-button {
                    opacity: 1 !important;

                    .text {
                        opacity: 1 !important; }

                    &:after {
                        left: 105% !important; } } }

            figure {
                margin-bottom: 0; }

            figure.fui-figure {
                figcaption {}

                margin-bottom: 0; }

            .article-panel {
                .fui-container {
                    padding: 0 15px; } }

            .fui-container-article {
                padding: 0 15px; }

            .article-banner {
                .img {
                    img {
                        left: -400px; } }

                h1 {
                    margin-bottom: 1.5rem; } }

            .article-intro-panel {
                padding: 40px 0 !important;
                background: url(#{$publicURL}/resources/cbu/life-circle-article/images/article-opener-bkg_m.jpg) no-repeat;
                background-size: cover; }

            .article-gallery-panel {
                padding-bottom: 40px !important; }

            .article-citation-panel {
                padding-bottom: 40px !important;

                .single-comma {
                    padding-left: 0;
                    top: 30px;

                    &:before {
                        top: -50px; } } }

            .article-gallery-panel {
                padding-bottom: 40px !important; }

            .article-author-tags-panel {
                padding-bottom: 40px !important;

                .tags {
                    display: inline-block;

                    .item {
                        margin: 0 10px 10px 0;
                        float: left; } } }

            .article-author-panel {
                padding-bottom: 40px !important;
                background: url(#{$publicURL}/resources/cbu/life-circle-article/images/bg-auther-m.png) no-repeat;
                background-size: cover; }

            .article-share-panel {
                padding-bottom: 40px !important; } }

        &.index {
            section {
                padding: 0px 0 40px; }

            .main-banner {
                .img {
                    height: 360px;
                    position: relative;
                    overflow: hidden;

                    img {
                        height: 360px;
                        position: absolute;
                        left: -480px; } }

                .wording {
                    top: 35px;
                    width: 80%;
                    margin: 0 10%;

                    .subtitle {
                        text-overflow: ellipsis;
                        display: block;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        white-space: normal;
                        overflow: hidden; } }

                .slick-prev {
                    left: 0; }

                .slick-next {
                    right: 0; }

                .slick-arrow-prev-wording,
                .slick-arrow-next-wording {
                    display: none; }

                .slick-dots {
                    top: 85%;

                    li {
                        button {
                            width: 30px; } } } }

            .horizontal-cards.slick-slider {
                .slick-list {
                    .slick-slide {
                        padding: 0 6px; } } }

            .fui-nav-tab.is-box-tab {
                .fui-tab {
                    overflow-x: scroll;

                    .fui-tab-item {
                        min-width: calc(100vw / 3.5); }

                    &.with-title {
                        .fui-tab-item {
                            background: none; } } } }

            .ad-carousel {
                .slide {
                    .MuiGrid-container {
                        position: relative; }

                    .MuiGrid-item + .MuiGrid-item {
                        position: absolute;
                        top: 0;
                        left: 0; }

                    .img {
                        img {
                            min-height: auto; } }

                    .wording {
                        min-height: auto;
                        background: none;
                        padding: 24px 30px;
                        display: block;

                        &:before {
                            display: none; }

                        h2 {
                            margin-bottom: 10px; }

                        .fui-button {
                            margin-top: 10px;

                            .text {
                                line-height: 26px; } } } }

                .slick-dots {
                    padding: 0;
                    top: -40px; } }

            .fCoin-carousel {
                // .slick-slider
                //     .slick-list
                //         .slick-slide
                //             width: 100%
                .fui-card {
                    .fui-card-content {
                        padding: 12px 12px 0 12px; }

                    .fui-card-description {
                        margin-bottom: 0; }

                    .fui-card-extra {
                        padding-left: 12px; } } }

            .coupon-list {
                .fui-card {
                    .fui-card-description {
                        margin-bottom: 0; }

                    .fui-card-extra {
                        padding-left: 12px; } } }

            .goods-list {
                .slick-slide {
                    .tag {
                        display: none; } } } }

        section.fui-section-promo {
            padding: 30px 15px; } } }

@media screen and (max-width: 600px) {
    .react-datepicker-popper,
    .react-datepicker__month-container {
        max-width: calc(100vw - 40px) !important; }

    .datepicker-divider {
        width: 100%;
        margin: 0;
        text-align: center;
        line-height: 20px;
        transform: rotate(90deg);
        position: relative;
        top: -$space * 2; }

    .parkingfeePage {
        .scroll-table {
            width: calc(160vw); } }

    .login-paper {
        padding: 30px 15px;

        .login-ad {
            width: 100%;
            height: auto;
            padding: 30px;
            background-size: cover; } }

    .life-circle {
        section {
            padding: 30px 0; }

        .justify-flex-start {
            justify-content: flex-start !important; }

        .more {
            padding: 18px 0 0 0; }

        section.fui-breadcrumb-container {
            display: none;

            // padding-top: 10px
 }            // top: 95px

        .vertical-anchor-nav {
            display: none !important; }

        .horizontal-cards.slick-slider {
            .slick-arrow.slick-prev,
            .slick-arrow.slick-next {
                right: -10px; } }

        .banner {
            &.img-right,
            &.img-left {
                &:before {
                    width: 100%;
                    transition: all 0.5s ease;
                    clip-path: polygon(0 0, 0% 0, 147% 100%, 0% 100%);
                    background: linear-gradient(270deg, rgba(255, 255, 255, 1) 0%, rgba(250, 250, 250, 1) 100%); } }

            &.img-right {
                .text {
                    top: 140px; }

                .img {
                    img {
                        right: -310px;
                        top: 0px;
                        height: 320px; } } }

            &.img-left {
                .text {
                    top: -130px; }

                .img {
                    img {
                        left: -190px;
                        top: 0px;
                        height: 320px;
                        position: relative; } } } }

        .banner.main-banner {
            &.img-right {
                .text {
                    top: 45px !important; } } }

        .visible {
            .banner {
                &.img-right,
                &.img-left {
                    &:before {
                        width: 100%;
                        transition: all 0.5s ease;
                        clip-path: polygon(0 0, 0% 0, 147% 100%, 0% 100%);
                        background: linear-gradient(270deg, rgba(255, 255, 255, 1) 0%, rgba(250, 250, 250, 1) 100%); } }

                &.img-right {
                    .text {
                        top: 140px; }

                    .img {
                        img {
                            right: -310px;
                            top: 0px;
                            height: 320px; } } }

                &.img-left {
                    .text {
                        top: -130px; }

                    .img {
                        img {
                            left: -190px;
                            top: 0px;
                            height: 320px;
                            position: relative; } } } }

            .banner.main-banner {
                &.img-right {
                    .text {
                        top: 45px !important; } } } }

        .fui-tab {
            overflow: scroll;

            a.fui-tab-item,
            .fui-tab-item {
                padding: 6px 12px 12px; } }

        .drama-list {
            .horizontal-cards.slick-slider {
                .slick-arrow {
                    display: none !important;

                    .slick-slide {
                        padding: 0 10px; } } }

            // .fui-card
            //     .fui-card-image
            //         img
            //             width: 90px !important
 }            //             height: 133px !important

        .horizontal-cards.slick-slider {
            .slick-arrow.slick-prev {
                left: 80%; } }

        .songs-list {
            .horizontal-cards.slick-slider {
                .slick-list {}

                .slick-slide {
                    .fui-card {
                        min-width: 100%; } } } }

        .goods-list {
            .fui-card-content {
                padding: 10px 10px 20px 10px; }

            .fui-card-title {
                .text {
                    font-size: 18px !important; } } }

        .tools-list {
            padding-bottom: 0;

            .align-center {
                padding: 0 40px; }

            .tools-item {
                padding: 36px 24px;
                overflow: scroll;

                .MuiGrid-container {
                    margin: 0 10px;
                    overflow: scroll;
                    width: calc(100vw);
                    flex-wrap: nowrap; }

                .MuiGrid-item {
                    width: auto;
                    min-width: 120px;
                    margin: 0 15px; }

                .slick-slider {
                    .slick-list {
                        .slick-slide {
                            padding: 0 15px; } } } } }

        .promo-list {
            > .fui-container {
                padding: 0 15px; } }

        .circle-list {
            .fui-card-image,
            .fui-card-content {
                max-width: 200px; }

            .horizontal-cards.slick-slider .slick-list .slick-slide {
                padding-right: 20px; }

            .fui-card-caption .fui-card-title {
                width: 200px;
                top: -120px; } }

        &.article {
            .article-author-tags-panel .tags {
                display: inline-block;

                .item {
                    margin: 0 10px 10px 0;
                    float: left; } }

            iframe {
                min-height: 240px;
                width: auto; } } } }

// IE css hack
// IE10 and IE11
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .life-circle {
        .visible {
            .banner {
                &.img-right {
                    &:before {
                        left: -240px;
                        transform: skew(35deg); } }

                &.img-left {
                    &:before {
                        right: -150px;
                        transform: skew(-35deg); } } } } } }

// Edge 12+
@supports (-ms-ime-align: auto) {
    .life-circle {
        .visible {
            .banner {
                &.img-right {
                    &:before {
                        left: -240px;
                        transform: skew(35deg); } }

                &.img-left {
                    &:before {
                        right: -150px;
                        transform: skew(-35deg); } } } } } }
