.MuiGrid-container {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box; }

.MuiGrid-spacing-xs-2 {
    width: calc(100% + 16px);
    margin: -$space;

    > .MuiGrid-item {
        padding: $space; } }

.MuiGrid-spacing-xs-3 {
    width: calc(100% + 24px);
    margin: -$space * 1.5;

    > .MuiGrid-item {
        padding: $space * 1.5; } }

.MuiGrid-spacing-xs-4 {
    width: calc(100% + 32px);
    margin: -$space * 2;

    > .MuiGrid-item {
        padding: $space * 2; } }

.MuiGrid-spacing-xs-5 {
    width: calc(100% + 40px);
    margin: -$space * 2.5;

    > .MuiGrid-item {
        padding: $space * 2.5; } }

.MuiGrid-spacing-xs-5 {
    width: calc(100% + 48px);
    margin: -$space * 3;

    > .MuiGrid-item {
        padding: $space * 3; } }

.MuiGrid-item {
    margin: 0;
    box-sizing: border-box; }

.MuiGrid-grid-12 {
    flex-grow: 0;
    max-width: 100%;
    flex-basis: 100%; }

.MuiGrid-grid-1 {
    flex-grow: 0;
    max-width: 8.33333%;
    flex-basis: 8.33333%; }

.MuiGrid-grid-2 {
    flex-grow: 0;
    max-width: 16.66667%;
    flex-basis: 16.66667%; }

.MuiGrid-grid-3 {
    flex-grow: 0;
    max-width: 25%;
    flex-basis: 25%; }

.MuiGrid-grid-4 {
    flex-grow: 0;
    max-width: 33.33333%;
    flex-basis: 33.33333%; }

.MuiGrid-grid-5 {
    flex-grow: 0;
    max-width: 41.66667%;
    flex-basis: 41.66667%; }

.MuiGrid-grid-6 {
    flex-grow: 0;
    max-width: 50%;
    flex-basis: 50%; }

.MuiGrid-grid-7 {
    flex-grow: 0;
    max-width: 58.33333%;
    flex-basis: 58.33333%; }

.MuiGrid-grid-8 {
    flex-grow: 0;
    max-width: 66.66667%;
    flex-basis: 66.66667%; }

.MuiGrid-grid-9 {
    flex-grow: 0;
    max-width: 75%;
    flex-basis: 75%; }

.MuiGrid-grid-10 {
    flex-grow: 0;
    max-width: 83.33333%;
    flex-basis: 83.33333%; }

.MuiGrid-grid-11 {
    flex-grow: 0;
    max-width: 91.66667%;
    flex-basis: 91.66667%; }

.MuiGrid-grid-12 {
    flex-grow: 0;
    max-width: 100%;
    flex-basis: 100%; }

.MuiGrid-grid-xs-12 {
    flex-grow: 0;
    max-width: 100%;
    flex-basis: 100%; }

.MuiGrid-grid-xs-1 {
    flex-grow: 0;
    max-width: 8.33333%;
    flex-basis: 8.33333%; }

.MuiGrid-grid-xs-2 {
    flex-grow: 0;
    max-width: 16.66667%;
    flex-basis: 16.66667%; }

.MuiGrid-grid-xs-3 {
    flex-grow: 0;
    max-width: 25%;
    flex-basis: 25%; }

.MuiGrid-grid-xs-4 {
    flex-grow: 0;
    max-width: 33.33333%;
    flex-basis: 33.33333%; }

.MuiGrid-grid-xs-5 {
    flex-grow: 0;
    max-width: 41.66667%;
    flex-basis: 41.66667%; }

.MuiGrid-grid-xs-6 {
    flex-grow: 0;
    max-width: 50%;
    flex-basis: 50%; }

.MuiGrid-grid-xs-7 {
    flex-grow: 0;
    max-width: 58.33333%;
    flex-basis: 58.33333%; }

.MuiGrid-grid-xs-8 {
    flex-grow: 0;
    max-width: 66.66667%;
    flex-basis: 66.66667%; }

.MuiGrid-grid-xs-9 {
    flex-grow: 0;
    max-width: 75%;
    flex-basis: 75%; }

.MuiGrid-grid-xs-10 {
    flex-grow: 0;
    max-width: 83.33333%;
    flex-basis: 83.33333%; }

.MuiGrid-grid-xs-11 {
    flex-grow: 0;
    max-width: 91.66667%;
    flex-basis: 91.66667%; }

.MuiGrid-grid-xs-12 {
    flex-grow: 0;
    max-width: 100%;
    flex-basis: 100%; }

@media screen and (min-width: 600px) {
    .MuiGrid-grid-sm-1 {
        flex-grow: 0;
        max-width: 8.33333%;
        flex-basis: 8.33333%; }

    .MuiGrid-grid-sm-2 {
        flex-grow: 0;
        max-width: 16.66667%;
        flex-basis: 16.66667%; }

    .MuiGrid-grid-sm-3 {
        flex-grow: 0;
        max-width: 25%;
        flex-basis: 25%; }

    .MuiGrid-grid-sm-4 {
        flex-grow: 0;
        max-width: 33.33333%;
        flex-basis: 33.33333%; }

    .MuiGrid-grid-sm-5 {
        flex-grow: 0;
        max-width: 41.66667%;
        flex-basis: 41.66667%; }

    .MuiGrid-grid-sm-6 {
        flex-grow: 0;
        max-width: 50%;
        flex-basis: 50%; }

    .MuiGrid-grid-sm-7 {
        flex-grow: 0;
        max-width: 58.33333%;
        flex-basis: 58.33333%; }

    .MuiGrid-grid-sm-8 {
        flex-grow: 0;
        max-width: 66.66667%;
        flex-basis: 66.66667%; }

    .MuiGrid-grid-sm-9 {
        flex-grow: 0;
        max-width: 75%;
        flex-basis: 75%; }

    .MuiGrid-grid-sm-10 {
        flex-grow: 0;
        max-width: 83.33333%;
        flex-basis: 83.33333%; }

    .MuiGrid-grid-sm-11 {
        flex-grow: 0;
        max-width: 91.66667%;
        flex-basis: 91.66667%; }

    .MuiGrid-grid-sm-12 {
        flex-grow: 0;
        max-width: 100%;
        flex-basis: 100%; } }

@media screen and (min-width: 960px) {
    .MuiGrid-grid-md-1 {
        flex-grow: 0;
        max-width: 8.33333%;
        flex-basis: 8.33333%; }

    .MuiGrid-grid-md-2 {
        flex-grow: 0;
        max-width: 16.66667%;
        flex-basis: 16.66667%; }

    .MuiGrid-grid-md-3 {
        flex-grow: 0;
        max-width: 25%;
        flex-basis: 25%; }

    .MuiGrid-grid-md-4 {
        flex-grow: 0;
        max-width: 33.33333%;
        flex-basis: 33.33333%; }

    .MuiGrid-grid-md-5 {
        flex-grow: 0;
        max-width: 41.66667%;
        flex-basis: 41.66667%; }

    .MuiGrid-grid-md-6 {
        flex-grow: 0;
        max-width: 50%;
        flex-basis: 50%; }

    .MuiGrid-grid-md-7 {
        flex-grow: 0;
        max-width: 58.33333%;
        flex-basis: 58.33333%; }

    .MuiGrid-grid-md-8 {
        flex-grow: 0;
        max-width: 66.66667%;
        flex-basis: 66.66667%; }

    .MuiGrid-grid-md-9 {
        flex-grow: 0;
        max-width: 75%;
        flex-basis: 75%; }

    .MuiGrid-grid-md-10 {
        flex-grow: 0;
        max-width: 83.33333%;
        flex-basis: 83.33333%; }

    .MuiGrid-grid-md-11 {
        flex-grow: 0;
        max-width: 91.66667%;
        flex-basis: 91.66667%; }

    .MuiGrid-grid-md-12 {
        flex-grow: 0;
        max-width: 100%;
        flex-basis: 100%; } }
