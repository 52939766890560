.collapse-info {
    .fui-collapse {
        background: $white;
        overflow: hidden;
        .fui-collapse-body {
            padding: 30px; } }
    .extra {
        padding: 12px;
        background-color: $lightgray50;
        box-shadow: 0 6px 12px 0 rgba(75, 88, 105, 0.12);
        font-weight: 500;
        .expand-btn {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center; }
        i {
            margin-left: 6px;
            font-size: 24px; } } }
