.store-finder {
    z-index: 10;
    padding-top: 95px;

    .infobox {
        position: relative; }

    .store-wrapper {
        -ms-overflow-style: none;

        & > * {
            -ms-overflow-style: none; }
        & .menu-wrapper > .fui-item > .content {
            text-align: center; } }

    .fui-container {
        position: relative;

        &::before {
            content: "";
            width: 150px;
            height: 200px;
            background-image: url(#{$publicURL}/resources/cbu/img/help-center.svg);
            position: absolute;
            background-repeat: no-repeat;
            left: -120px;
            top: -110px;
            z-index: 1; } }

    .no-place {
        padding: 30px;

        img {
            width: 30px;
            height: 30px; }

        h5 {
            margin-top: 8px;
            margin-bottom: 0; }

        p {
            margin-top: 8px;
            margin-bottom: 24px; } }

    .map {
        width: 100%;
        height: 740px;

        @media screen and (max-width: 768px) {
            height: 250px; }

        .marker {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            border: 2px solid $white;
            background-color: $accent;
            cursor: pointer;

            &:hover {
                background-image: url(#{$publicURL}/resources/cbu/img/icon-pin-hover.svg);
                width: 46px;
                height: 55px;
                border: none;
                background-color: initial;
                margin-left: -12px;
                margin-top: -26px;
                border-radius: 0; }

            &.active {
                width: 46px;
                height: 55px;
                border: none;
                border-radius: 0;
                margin-left: -12px;
                margin-top: -26px;
                background-color: initial;
                background-image: url(#{$publicURL}/resources/cbu/img/icon-pin-seleted.svg); } } }

    .filter {
        background-color: #eff1f2;
        padding: 16px 30px;

        &-mrt,  &-city {
            width: calc(100% - 200px);
            display: inline-block; }

        .fui-dropdown {
            width: 175px;
            margin: 0 5px;
            display: inline-block;
            align-items: center;

            &.is-invalid {
                border: 2px solid $error;
                color: $error;
                & > .fui-dropdown-item {
                    color: $error; } }

            span {
                text-align: left; }

            &.disabled {
                pointer-events: none;
                opacity: 0.3; }

            button {
                height: 50px;
                display: flex;
                align-items: center;
                width: 100%;
                justify-content: space-between;

                i {
                    text-align: right; } } }

        .fui-button.is-secondary {
            height: 50px;
            min-width: 160px;
            margin: 0; }

        .search-input {
            display: inline-block;
            width: calc(100% - 185px * 2);

            .filter-button {
                img {
                    display: none; } }

            .input-wrapper {
                display: inline-block;
                vertical-align: middle;
                position: relative;
                margin-right: 25px;
                width: calc(100% - 185px);

                input {
                    width: 100%;
                    height: 50px;
                    font-size: 16px;
                    padding: 12px;
                    border: none; }

                i {
                    font-size: 24px;
                    right: 1.5%;
                    top: 14px;
                    position: absolute;
                    cursor: pointer; } } } }

    .searchbox {
        height: 740px;
        background: $white;
        overflow-x: hidden;
        overflow-y: auto;
        position: relative;

        hr {
            margin: 24px 0; }

        .list {
            padding: 30px;
            border-bottom: 1px solid $gray30;
            cursor: pointer;

            h4 {
                margin-bottom: 6px;
                font-weight: 500;
                margin-top: 0; }

            i {
                font-size: 22px; }

            p {
                margin: 0; }

            .contact {
                margin-top: 6px;
                align-items: center;

                p {
                    margin-right: 12px;
                    padding-right: 12px;
                    border-right: 1px solid #ebebeb; }

                img {
                    width: 36px;
                    height: 36px;
                    margin-right: 10px; } } }

        &.open {
            position: static; } }
    .more-info {
        position: absolute;
        left: 0;

        // top: -54px
        top: 0;
        background: $white;
        padding: 48px 30px;
        width: 100%;
        height: 100%;
        overflow-y: scroll;
        overflow-x: hidden;
        z-index: 1;

        .services {
            margin-bottom: 24px;
            display: flex;
            flex-direction: row;

            .item {
                align-items: flex-start;
                width: 50%;
                min-width: 50%;
                vertical-align: top;
                display: inline-flex;
                line-height: 26px;

                &:nth-child(odd) {
                    padding-right: 12px; }

                img {
                    margin-right: 12px;
                    width: 24px;
                    height: 24px;
                    min-width: 24px; } }

            .services-list {
                width: 100%; } }

        .fui-button.is-secondary {
            width: 100%;
            margin-top: 24px;
            padding-bottom: 24px;
            min-width: auto; }

        .close {
            cursor: pointer;

            i {
                font-weight: 400; } }

        .wrapper {
            p {
                margin: 0 30px 0 0;
                line-height: 1.75;
                min-width: 92px; } }

        p {
            margin: 12px 0 8px; }

        .contact {
            img {
                height: 36px;
                margin-right: 6px; }

            .subtitle {
                margin: 0;
                border-right: 1px solid $gray30;
                margin-right: 12px;
                padding-right: 12px;

                &::after {
                    content: "";
                    display: block;
                    width: 0;
                    height: 2px;
                    background: #ef3123 !important;
                    -webkit-transition: width 0.3s ease;
                    transition: width 0.3s ease; }

                &:hover::after {
                    width: 100%; } } } } }

@media screen and (max-width: 959px) {
    .store-finder {
        padding-top: 30px;

        .store-wrapper {
            margin: 0 -15px;

            .menu-wrapper > .fui-item > .content {
                padding: 0 6px;
                text-align: left; } }

        .searchbox {
            // max-height: 740px
            height: auto;
            position: relative;

            &.open {
                height: 984px; } }

        .more-info {
            padding: 24px 15px;
            width: 100%;
            position: relative;
            height: auto;
            // height: calc( 100% + 54px )
            background-color: $white;

            .time-table {
                flex-direction: column; }

            .services {
                flex-direction: column; }

            .button-group {
                flex-direction: column;
                margin: 0;
                padding: 0;

                a.fui-button.is-secondary {
                    margin: 0;
                    padding: 0;

                    &:nth-last-child(1) {
                        margin-top: 12px; } } } }

        .infobox {
 }            // margin-top: -195px

        .fui-container {
            &::before {
                right: -40px;
                left: initial;
                top: -62px;
                height: 143px;
                background-size: contain; } }

        .filter {
            height: auto;
            display: inline-block;
            width: 100%;
            padding: 12px 1.25%;

            &-mrt {
                width: 100%; }

            button.is-secondary.filter-button {
                width: 30px;
                border: 0;
                min-width: auto;
                padding: 0;
                margin-left: -8px;

                img {
                    display: block; }

                span {
                    display: none; } }

            .fui-dropdown {
                width: 100%;
                margin: 0; }

            .search-input {
                width: 100%;

                .input-wrapper {
                    width: calc(100% - 55px); } } } } }

@media screen and (max-width: 1120px) {
    .store-finder {
        .filter {
            &-city {
                display: block;
                width: 100%; } } } }

// Override styles "cbu-react.min.css" (REACT_APP_CBU_REACT_CSS_URL)
.store-finder.__override__ {
    .filter {
        height: auto;
        min-height: 80px;

        .filter-city {
            display: flex;
            flex-wrap: wrap;

            .search-input {
                display: flex;
                flex: 1;
                float: none;
                margin-left: auto;
                width: auto;
                margin-right: 0;

                .input-wrapper {
                    width: auto;
                    flex: 1;

                    input {
                        position: static; }

                    i.icon-search {
                        right: 14px; } } } } } }
